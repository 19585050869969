import React from 'react';
import { SCREEN_DISPATCH_SETTING } from '../../../../helpers/constants';
import './style.scss'

const ButtonActiveSetting = ({ color, active = 'order-information', onClickActive = () => {} }) => {
  return (
    <div className="my-1">
      {Object.values(SCREEN_DISPATCH_SETTING).map((item, index) => (
        <button
          className={`btn mr-2 form-btn form-btn-custom ${active === item.value ? 'button-table-active button-table-active-custom' : 'button-table'}`}
          key={index}
          onClick={() => onClickActive(item)}
        >
          {item.label}
        </button>
      ))}
      {/*<button className="btn button-table-active mr-3">受注情報</button>*/}
      {/*<button className="btn button-table mr-3">当日現場情報</button>*/}
      {/*<button className="btn button-table mr-3">worker設定</button>*/}
      {/*<button className="btn button-table mr-3">報告書回収情報</button>*/}
    </div>
  );
};

export default ButtonActiveSetting;
