import React, { useEffect, useState } from 'react';
import { translate } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, withRouter, useHistory } from 'react-router-dom';
import { compose } from 'redux';
import '../../../../assets/scss/page/contract/index.scss';
import Footer from '../../../../components/Footer';
import HeaderMasterSetting from '../../../../components/HeaderMasterSetting';
import LoadingNew from '../../../../components/LoadingNew';
import MemoHistory from '../../../../components/MemoHistory';
import { pushToast } from '../../../../components/Toast';
import { CONTRACT_ENDPOINTS } from '../../../../constants/endpoints';
import { SCREEN_NAME } from '../../../../helpers/constants';
import ModalBillMaster from './ModalBillMaster';
import ModalPaymentMaster from './ModalPaymentMaster';

const BillingMaster = (props) => {
  let tariffId = process.browser && new URL(window.location.href).searchParams.get('tariffId');
  const colorScreen = useSelector((store) => store.utils.colorMe);
  const history = useHistory();
  const [isOpenBillMaster, setIsOpenBillMaster] = useState(false);
  const [listItem, setListItem] = useState([]);
  const [idUpdate, setIdUpdate] = useState();
  const [valueBilling, setValueBilling] = useState('');
  const [loading, setLoading] = useState(false);

  const getListTariffSettingBillingMaster = async ({ tariffId = '' }) => {
    setLoading(true);
    try {
      const data = await CONTRACT_ENDPOINTS.GET_TARIFF_SETTING_BILLING_MASTER({
        tariffId: tariffId,
      });
      if (data) {
        setLoading(false);
        setListItem(data?.data?.tariffSetting);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getListTariffSettingBillingMaster({ tariffId: tariffId });
  }, [tariffId]);

  const handleBillMaster = (claim, tariffSettingId) => {
    setIdUpdate(tariffSettingId);
    setValueBilling(claim);
    setIsOpenBillMaster(!isOpenBillMaster);
  };

  const toggleModalBillMaster = () => {
    setIsOpenBillMaster(!isOpenBillMaster);
  };

  const updateTariffSettingClaim = async ({ body = [], tariffSettingId = '' }) => {
    try {
      const data = await CONTRACT_ENDPOINTS.UPDATE_TARIFF_SETTING_CLAIM({
        body: body,
        tariffSettingId: tariffSettingId,
      });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
      } else {
        pushToast('success', 'Update billing success');
        getListTariffSettingBillingMaster({ tariffId: tariffId });
        setIsOpenBillMaster(false);
      }
    } catch (errorMessage) {
      console.log('errorMessage: ', errorMessage);
    }
  };

  const submitUpdate = (dataCreate) => {
    updateTariffSettingClaim({ body: dataCreate, tariffSettingId: idUpdate });
  };

  return (
    <>
      <div className="main">
        <LoadingNew loading={loading} />
        <HeaderMasterSetting color={colorScreen?.code} colorText={colorScreen?.colorText} checkName="/contract" />
        <div className="block-contract">
          <div className="list-table">
            <Link to={`/contract/master-tarif-edit?tariffId=${tariffId}`}>
              <button className="btn-back">戻る</button>
            </Link>
            <div className="table-list-contract">
              <div className="title-recruitment">請求マスタ</div>
              <div className="list-button-contact">
                <Link to={`/contract/billing-master?tariffId=${tariffId}`}>
                  <button className="item">請求マスタ</button>
                </Link>
                <Link to={`/contract/payment-master?tariffId=${tariffId}`}>
                  <button className="item">支払マスタ</button>
                </Link>
              </div>
              <table className="table table-bordered">
                <tbody>
                  <tr>
                    <td>Project</td>
                    <td>Product</td>
                    <td>Service</td>
                    <td>請求項目</td>
                    <td>丸丸商事</td>
                  </tr>
                  {listItem?.map((itemIn, index) => {
                    return (
                      <tr key={index}>
                        <td>{itemIn.projectName}</td>
                        <td>{itemIn?.productName}</td>
                        <td>{itemIn?.serviceName}</td>
                        <td>{itemIn?.title}</td>
                        <td onClick={() => handleBillMaster(itemIn?.claim, itemIn?.tariffSettingId)}>
                          <p className="shadow rounded bg-white">{itemIn?.claim || '...'}</p>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <div className="list-button-contact text-right">
                <button className="item  mr-0">設定を保存</button>
              </div>
            </div>
          </div>
          {isOpenBillMaster && (
            <ModalBillMaster
              modal={isOpenBillMaster}
              toggle={toggleModalBillMaster}
              submitUpdate={submitUpdate}
              valueBilling={valueBilling}
            />
          )}
        </div>
        <MemoHistory colors={colorScreen?.code} colorText={colorScreen?.colorText} screenName={SCREEN_NAME.CONTRACT_MANAGEMENT}/>
        <Footer color={colorScreen?.code} colorText={colorScreen?.colorText} />
      </div>
    </>
  );
};

export default compose(translate('translations'), withRouter)(BillingMaster);
