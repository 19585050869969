import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { compose } from 'redux';

const ButtonMasterSettingCustom = ({
  subTitle = 'クライアント',
  title = 'CLIENT MASTER',
  color = 'blue',
  icon = 'icon-user-secret',
  link = '/center_master_all',
}) => {
  return (
    <div className="wrapper-button-master-setting pl-3 mb-3">
      <NavLink activeClassName="active-menu-master-setting" to={link}>
        <div className={`${color} w-100  d-flex wrapper-button d-flex align-content-center`}>
          <div className="icon-master ">
            <i className="fas fa-th-list ml-2 mr-2" />
          </div>
        </div>
      </NavLink>
    </div>
  );
};

export default compose(withRouter)(ButtonMasterSettingCustom);
