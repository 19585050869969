import { ACTIONS } from '../actions/utils';

export const utilsState = {
    loading: false,
    paramSearch: {},
    colorMe: {},
    logo: {}
};

export default (state = utilsState, action) => {
    switch (action.type) {
        case ACTIONS.SHOW_LOADING:
            return {
                ...state,
                loading: true,
            };
        case ACTIONS.HIDE_LOADING:
            return {
                ...state,
                loading: false,
            };

        case ACTIONS.GET_PARAM_SEARCH:
            return {
                ...state,
                loading: false,
                paramSearch: action.payload
            };
        case ACTIONS.GET_COLOR_ME:
            return {
                ...state,
                colorMe: action.payload
            };
        case ACTIONS.GET_LOGO:
            return {
                ...state,
                logo: action.payload
            };
        default:
            return state;
    }
};
