import React from 'react';
import './style.scss';

const data = {
    header : ['更新日時','工程名','システム名','画面名','更新者名','作業内容','ファイル名'],
    body : [
        {
            更新日時:'2020/04/15 20:42:02',
            工程名:'宅内工事',
            システム名:'center',
            画面名:'報告書回収情報',
            更新者名:'管理01',
            作業内容:'center',
            ファイル名:'変更履歴:【報告書納品日】→2020-04-15 20:41:58',
        },
        {
            更新日時:'2020/04/15 20:42:02',
            工程名:'宅内工事',
            システム名:'center',
            画面名:'報告書回収情報',
            更新者名:'管理01',
            作業内容:'center',
            ファイル名:'変更履歴:【報告書納品日】→2020-04-15 20:41:58',
        },
    ]
}

const renderItem = (item, index) => {
    return (
        <tr key={index}>
            {data.header.map((element, key) => {
                return (
                    <>
                        <td className="text-center" style={{fontSize:11}} key={key}>{item[element]}</td>
                    </>
                );
            })}
        </tr>
    );
};

const TableUpdateHistory = () => {
    return (
        <div className="wrapper-monitor">
            <div className="list">
                <table className={'monitor'}>
                    <thead>
                    <tr>
                        {data.header.map((item, index) => (
                            <th  className="blue text-center" scope="col" key={index}>
                                {item}
                            </th>
                        ))}
                    </tr>
                    </thead>
                    <tbody>{data.body.map((listData, index) => renderItem(listData, index))}</tbody>
                </table>
            </div>
        </div>
    );
};

export default TableUpdateHistory;
