import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import DetailOrganization from 'components/DetailOrganization';
import HeaderMasterSetting from 'components/HeaderMasterSetting';
import BorderGroupForm from 'components/BorderGroupForm';
import { getRoleOrganization } from '../../../helpers/helpers';
import { DATA_ORGANIZATION, DATA_ORGANIZATION_PARTNER, ROLE, SCREEN_NAME } from '../../../helpers/constants';
import { ORGANIZATION_ENDPOINT } from '../../../constants/endpoints';
import LoadingNew from '../../../components/LoadingNew';
import { pushToast } from '../../../components/Toast';
import FooterMaintenance from "components/FooterMaintenance";
import _ from 'lodash';
import MemoHistory from '../../../components/MemoHistory';

const EditOrganization = () => {
  const colorScreen = useSelector((store) => store.utils.colorMe);
  let id = (process.browser && new URL(window.location.href).searchParams.get('sid')) || null;
  let role = (process.browser && new URL(window.location.href).searchParams.get('role')) || null;
  const [data, setData] = useState();
  const [roleOrganization, setRoleOrganization] = useState();
  const [loading, setLoading] = useState('');
  const [dataOrganizationLogin, setDataOrganizationLogin] = useState();
  const newRole = localStorage.getItem('role');

  const getModify = async ({ id = '' }) => {
    setLoading(true);
    try {
      const data = await (roleOrganization === ROLE.PARTNERGROUP && role === 'monitoring'
        ? ORGANIZATION_ENDPOINT.GET_DETAIL_CLIENT({ id })
        : ORGANIZATION_ENDPOINT.GET_DETAIL_PARTNER({ id }));
      const dataLogin = await ORGANIZATION_ENDPOINT.GET_ORAGINIZATION_LOGIN();
      if (dataLogin) {
        setDataOrganizationLogin(dataLogin?.data);
      }
      if (data && dataLogin) {
        let newData = {
          ...data?.data,
          companyName: dataLogin?.data?.companyName,
          organizationName: dataLogin?.data?.name,
        };
        setData(newData);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  useEffect(() => {
    if (newRole) {
      setRoleOrganization(getRoleOrganization({ idRole: newRole }));
    }
  }, []);

  useEffect(() => {
    if (id && roleOrganization) {
      getModify({ id });
    }
  }, [id, roleOrganization]);

  const updateOrganization = async ({ body, id = '' }) => {
    try {
      if(role !== 'management') {
        delete body.getQualified
      }
      const data = await (roleOrganization === ROLE.PARTNERGROUP && role === 'management'
        ? ORGANIZATION_ENDPOINT.UPDATE_ORGANIZATION_PARTNER({ body, id })
        : roleOrganization === ROLE.PARTNERGROUP && role === 'monitoring'
        ? ORGANIZATION_ENDPOINT.UPDATE_ORGANIZATION_CLIENT({ body, id })
        : ORGANIZATION_ENDPOINT.UPDATE_ORGANIZATION({ body, id }));
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
      } else {
        pushToast('success', 'Update info success');
        getModify({ id });
      }
    } catch (errorMessage) {
      console.log('errorMessage: ', errorMessage);
    }
  };

  const handleText = (typeRole) => {
    switch (typeRole) {
      case 'monitoring':
        return '発注企業情報編集';
      case 'erp':
        return '契約企業利用者編集';
      case 'management':
        return '協力企業情報編集'
      default:
        return '';
    }
  }

  const UpdateOrganizationData = (dataOrganization) => {
    if (roleOrganization === ROLE.PARTNERGROUP && id) {
      updateOrganization({
        body: { ..._.pick({...dataOrganization, updateTitle: `更新 ${handleText(role)} ${data?.companyName || ''}`}, ['management', 'monitoring'].includes(role) ?  DATA_ORGANIZATION_PARTNER : DATA_ORGANIZATION), screenName: SCREEN_NAME.ORGANIZATION },
        id,
      });
    }
  };

  return (
    <div className="main-modifly-organization ">
      <LoadingNew loading={loading} />
      <HeaderMasterSetting color={colorScreen?.code} colorText={colorScreen?.colorText} nameRole={role}/>
      <BorderGroupForm color={colorScreen?.code}>
        <DetailOrganization
          dataOrganizationLogin={dataOrganizationLogin}
          roleOrganization={roleOrganization}
          data={data}
          onClickSubmit={UpdateOrganizationData}
          nameRole={role}
          newRole={newRole}
          id={id}
        />
      </BorderGroupForm>
      <MemoHistory colors={colorScreen?.code} colorText={colorScreen?.colorText} screenName={SCREEN_NAME.ORGANIZATION} />
      <FooterMaintenance />
    </div>
  );
};

export default EditOrganization;
