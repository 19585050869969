import React from 'react';
import { Button, Modal, ModalFooter, ModalHeader } from 'reactstrap';
import './style.scss';

const ModalConfirm = (props) => {
  const {
    toggle = () => {},
    modal = false,
    handleConfirm = () => {}
  } = props;

  const onCLickSubmit = () => {
    handleConfirm();
  };

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Want to delete?</ModalHeader>
        <ModalFooter>
          <Button className='btn btn-success' onClick={() => onCLickSubmit()}>Submit</Button>
          <Button color="secondary" onClick={toggle}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ModalConfirm;
