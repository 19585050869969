export const ACTIONS = {
  GET_LIST_ROOM: 'GET_LIST_ROOM',
  SAVE_LIST_ROOM: 'SAVE_LIST_ROOM',
  UPDATE_LIST_ROOM: 'UPDATE_LIST_ROOM',

  GET_LIST_MESSAGE_IN_ROOM: 'GET_LIST_MESSAGE_IN_ROOM',
  SAVE_LIST_MESSAGE_IN_ROOM: 'SAVE_LIST_MESSAGE_IN_ROOM',
  UPDATE_LIST_MESSAGE_IN_ROOM: 'UPDATE_LIST_MESSAGE_IN_ROOM',

  GET_LIST_USER_IN_ROOM: 'GET_LIST_USER_IN_ROOM',
  SAVE_LIST_USER_IN_ROOM: 'SAVE_LIST_USER_IN_ROOM',
  UPDATE_LIST_USER_IN_ROOM: 'UPDATE_LIST_USER_IN_ROOM',
  REMOVE_USER_IN_ROOM: 'REMOVE_USER_IN_ROOM',
  UPDATE_MULTIPLE_USER: 'UPDATE_MULTIPLE_USER',

  GET_LIST_CHAT_USER_BY_USER: 'GET_LIST_CHAT_USER_BY_USER',
  SAVE_LIST_CHAT_USER_BY_USER: 'SAVE_LIST_CHAT_USER_BY_USER',

  GET_LIST_MESSAGE_IN_USER_BY_USER: 'GET_LIST_MESSAGE_IN_USER_BY_USER',
  SAVE_LIST_MESSAGE_IN_USER_BY_USER: 'SAVE_LIST_MESSAGE_IN_USER_BY_USER',
  UPDATE_LIST_MESSAGE_IN_USER_BY_USER: 'UPDATE_LIST_MESSAGE_IN_USER_BY_USER',
};

export const getListRoom = (payload) => {
  return { type: ACTIONS.GET_LIST_ROOM, payload };
};
export const updateListRoom = (payload) => {
  return { type: ACTIONS.UPDATE_LIST_ROOM, payload };
};


export const getListMessageInRoom = (payload) => {
  return { type: ACTIONS.GET_LIST_MESSAGE_IN_ROOM, payload };
};
export const updateListMessageInRoom = (payload) => {
  return { type: ACTIONS.UPDATE_LIST_MESSAGE_IN_ROOM, payload };
};


export const getListUserInRoom = (payload) => {
  return { type: ACTIONS.GET_LIST_USER_IN_ROOM, payload };
};

export const updateListUserInRoom = (payload) => {
  return { type: ACTIONS.UPDATE_LIST_USER_IN_ROOM, payload };
};

export const removeUserInRoom = (payload) => {
  return { type: ACTIONS.REMOVE_USER_IN_ROOM, payload };
};

export const updateMultipleUser = (payload) => {
  return { type: ACTIONS.UPDATE_MULTIPLE_USER, payload };
};

export const getListChatUserByUser = (payload) => {
  return { type: ACTIONS.GET_LIST_CHAT_USER_BY_USER, payload };
};
export const saveListChatUserByUser = (payload) => {
  return { type: ACTIONS.SAVE_LIST_CHAT_USER_BY_USER, payload };
};

export const getListMessageInUserByUser = (payload) => {
  return { type: ACTIONS.GET_LIST_MESSAGE_IN_USER_BY_USER, payload };
};
export const updateListMessageInUserByUser = (payload) => {
  return { type: ACTIONS.UPDATE_LIST_MESSAGE_IN_USER_BY_USER, payload };
};
