import React, { useEffect, useState } from 'react';
import './style.scss';
import FooterMaintenance from 'components/FooterMaintenance';
import HeaderMasterSetting from 'components/HeaderMasterSetting';
import { pushToast } from 'components/Toast';
// import { getRole } from 'helpers/helpers';
import { VERSION_SERVICE } from 'constants/endpoints';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { genId } from 'helpers/helpers';
import ModalConfirm from 'components/ModalConfirm';
import { Link, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import MemoHistory from '../../../components/MemoHistory';
import { SCREEN_NAME, DATE_JP_FORMAT } from '../../../helpers/constants';
import Loading from 'components/Loading/Loading';
import moment from 'moment';
import { getDataHistoryMasterSetting } from 'actions/authentication';


const VersionService = (props) => {
  const dispatch = useDispatch();
  let productId = (new URL(window.location.href).searchParams.get('productId')) || '';
  let serviceId = (new URL(window.location.href).searchParams.get('serviceId')) || '';
  let serviceName = decodeURIComponent(new URL(window.location.href).searchParams.get('serviceName')) || '';
  let productName = decodeURIComponent(new URL(window.location.href).searchParams.get('productName')) || '';
  const colorScreen = useSelector((store) => store.utils.colorMe);
  const [color, setColor] = useState('');
  const [idRole, setIdRole] = useState('');
  const [listItem, setListItem] = useState([]);
  const [modalConfirm, setModalConfirm] = useState(false);
  const [itemDelete, setItemDelete] = useState({});
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const toggle = (item) => {
    setModalConfirm(!modalConfirm);
    setItemDelete(item)
  };

  const getListVersionService = async ({ serviceId = '', productId = ''}) => {
    setLoading(true);
    try {
      const data = await VERSION_SERVICE.GET_LIST_VERSION_SERVICE({
        serviceId: serviceId,
        productId: productId,
      });
      if (data) {
        setListItem(data?.data);
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  useEffect(() => {
    const newRole = localStorage.getItem('role');
    setIdRole(newRole);
    getListVersionService({ serviceId, productId});
    // if (newRole) {
    //   setRole(getRole({ idRole: newRole }));
    // }
  }, []);

  const deleteListItem = (item) => {
    if (!item.hasOwnProperty('serviceProductId')) {
      setListItem(listItem.filter(elm => elm.sortKey !== item.sortKey));
    } else {
      toggle(item);
    }
  };

  const addListItem = () => {
    setListItem([...listItem, {sortKey: genId(), effectiveness:false}]);
  };

  const handleChangeItem = (e, sortKey) => {
    const valueField = e.target.value;
    const newData = listItem ?.map(item => {
      if (item.sortKey === sortKey) {
        return { ...item, version: valueField }
      } else {
        return item;
      }
    });
    setListItem(newData);
  };

  const handleChangeDate = async (date, name, sortKey, version) => {
    if (version) {
      const newData = await listItem ?.map(item => {
        if (item.sortKey === sortKey) {
          return { ...item, [name]: date }
        } else {
          return item;
        }
      });
      setListItem(newData);
    }
  };

  const handleChangeItemCheckBox = (sortKey, version) => {
    if (version) {
      const newData = listItem ?.map(item => {
        if (item.sortKey === sortKey) {
          return { ...item, effectiveness: !item.effectiveness }
        } else {
          return item;
        }
      });
      setListItem(newData);
    }
  };

  const editVersionService = async ({ body }) => {
    try {
      const data = await VERSION_SERVICE.EDIT_VERSION_SERVICE({ body });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
      } else {
        pushToast('success', 'Edit version service success');
        getListVersionService({ serviceId, productId});
        dispatch(getDataHistoryMasterSetting({ logId: '', screenName: SCREEN_NAME.WORKER_SETTINGS }));
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleEditVersionService = () => {
    const newListItem = listItem ?.map(item => {
      if (!item.hasOwnProperty('serviceProductId')) {
        delete item.sortKey;
        return item;
      } else {
        return {
          "version": item.version,
          "startAt": item.startAt,
          "endAt": item.endAt,
          "effectiveness": item.effectiveness,
          "serviceProductId": item.serviceProductId,
          "sortKey": item.sortKey,
        }
      }
    });

    const body = {
      "versionServices": newListItem,
      serviceId,
      productId,
      updateTitle: `Worker設定 ${productName} ${serviceName}`,
      screenName : SCREEN_NAME.WORKER_SETTINGS
    };
    editVersionService({ body });
  };

  const onReturn = () => {
    history.push(`/configuration-worker`);
  }

  const handleDeleteVersionService = async () => {
    try {
        const data = await VERSION_SERVICE.DELETE_VERSION_SERVICE({ sortKey: itemDelete.sortKey, serviceProductId: itemDelete.serviceProductId});
        if (data && data.code !== 0) {
            if (data && data.message && data.message.extra) {
                pushToast('error', data?.message?.extra);
            } else {
                pushToast('error', data?.message?.errorMessage);
            }
        } else {
          pushToast('success', 'delete success');
          getListVersionService({ serviceId, productId });
          toggle();
        }
    } catch (errorMessage) {
        console.log('errorMessage: ', errorMessage);
    }
  };

  const handleConfirm = () => {
    handleDeleteVersionService()
  };

  const showListVersion = () => {
    return listItem && listItem.map((item, index) => {
      return (
        <tr className="item-worker" key={index}>
          <td>
            <div className="icon"
              style={{ borderColor: `${color}`, color: `${color}` }}
              onClick={() => deleteListItem(item)}
            >
              <i className="fas fa-times"></i>
            </div>
          </td>
          <td>
            <input
              type="text" className="text-input version" name="version"
              style={{ borderColor: `${color}`, color: `${color}` }}
              value={item ?.version}
              onChange={(e)=> handleChangeItem(e,item.sortKey)}
            />
          </td>
          <td>
            <div className="form-date">
              <DatePicker
                selected={item?.startAt ? new Date(item?.startAt) : ''}
                onChange={(date) => handleChangeDate(date ? moment(new Date(date)).format(DATE_JP_FORMAT) : '', 'startAt', item.sortKey, item ?.version)}
                locale="ja"
                name="startAt"
                dateFormat="yyyy/MM/dd"
                dateFormatCalendar="yyyy年 MM月"
                className={`text-input date-picker`}
              />
              <DatePicker
                selected={item?.endAt ? new Date(item?.endAt) : ''}
                onChange={(date) => handleChangeDate(date ? moment(new Date(date)).format(DATE_JP_FORMAT) : '', 'endAt', item.sortKey, item ?.version)}
                locale="ja"
                name="endAt"
                dateFormat="yyyy/MM/dd"
                dateFormatCalendar="yyyy年 MM月"
                className={`text-input date-picker`}
              />
            </div>
          </td>

          <td>
            <label
              className="container-checkbox"
            >
              <input
                type="checkbox" name="effectiveness"
                checked={item ?.effectiveness}
                onChange={()=> handleChangeItemCheckBox(item.sortKey, item ?.version)}
                />
              <span className="checkmark"></span>
            </label>
          </td>

          <td>
            <div className="item-button">
              <Link to={`/configuration-worker/worker-menu-setting?sortKey=${item?.sortKey}&serviceProductId=${item?.serviceProductId}&productName=${productName}&serviceName=${serviceName}&version=${item?.version}`}>
              <button
                  style={{ backgroundColor: `${color}` }}
                  disabled={!item.hasOwnProperty('serviceProductId')}
              >編集</button>
              </Link>

            </div>
          </td>
        </tr>
      )
    })
  };

  return (
    <div className="wrapper-master-setting">
      <Loading loading={loading} />
      <HeaderMasterSetting checkName='/configuration-worker' color={colorScreen?.code} colorText={colorScreen?.colorText}/>
      <div className="body pb-1">
        <button className="mypage_ab_custom" onClick={onReturn}>戻る</button>
        <div className="wrapper-main">
          <div className="wrapper-result">
            <div className="result-title text-center mb-4 mt-2">
              {`作業手順設定＞${productName || ''}＞${serviceName || ''}`}
            </div>
            <table className="form-worker">
              {
                listItem?.length > 0 &&
                <tr>
                  <th></th>
                  <th className="text-center">バージョン情報</th>
                  <th className="text-center">期間</th>
                  <th className="text-center">表示</th>
                  <th className="text-center">作業手順編集</th>
                </tr>
              }

              <tbody>
                {showListVersion()}
                <tr className="item-worker">
                  <td>
                  <div
                    className="icon"
                    style={{ borderColor: `${color}`, color: `${color}` }}
                    onClick={() => addListItem()}
                  >
                    <i className="fas fa-plus"></i>
                  </div>
                  </td>
                </tr>
              </tbody>
            </table>

          </div>
          <div className="d-flex justify-content-end align-items-center group-button">
            <button
              style={{ backgroundColor: `${color}` }}
              onClick={() => handleEditVersionService()}
            >保存</button>
          </div>
        </div>
      </div>
      <MemoHistory colors={colorScreen?.code} colorText={colorScreen?.colorText} screenName={SCREEN_NAME.WORKER_SETTINGS} />
      <FooterMaintenance />
      <ModalConfirm toggle={toggle} modal={modalConfirm} handleConfirm={handleConfirm}/>
    </div>
  );
};

export default VersionService;
