import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import HeaderMasterSetting from '../../../../components/HeaderMasterSetting';
import LoadingNew from '../../../../components/LoadingNew';
import MemoHistory from '../../../../components/MemoHistory';
import { pushToast } from '../../../../components/Toast';
import { CONTRACT_ENDPOINTS } from '../../../../constants/endpoints';
import { SCREEN_NAME, ROLE } from '../../../../helpers/constants';
import AboutUs from './AboutUs';
import Credit from './Credit';
import Estimates from './Estimates';
import NDAContract from './NDAContract';
import StatusContract from './StatusContract';
import PartnerCompanies from './PartnerCompanies';
import PastPerformance from './PastPerformance';
import { getRole } from '../../../../helpers/helpers';
import { getDataHistoryMasterSetting } from 'actions/authentication';
import ParticipatingCompany from '../../../../components/ParticipatingCompany';

const RegistrationContract = () => {
  const history = useHistory();
  const dispatch = useDispatch()
  const colorScreen = useSelector((store) => store.utils.colorMe);
  const [recruitmentContractId, setRecruitmentContractId] = useState(
    new URL(window.location.href).searchParams.get('recruitmentContractId')
  );
  const [recruitmentEstimateId, setRecruitmentEstimateId] = useState(
    new URL(window.location.href).searchParams.get('recruitmentEstimateId')
  );
  const [dataDetailCredit, setDataDetailCredit] = useState({});
  const [dataDetailEstimates, setDataDetailEstimates] = useState({});
  const [dataDetailNda, setDataDetailNda] = useState({});
  const [dataDetailContractStatus, setDataDetailContractStatus] = useState({});
  const [stepPermission, setStepPermission] = useState(0);
  const [dataQualificationContract, setDataQualificationContract] = useState([]);
  const [idOrganization, setIdOrganization] = useState(
    new URL(window.location.href).searchParams.get('organizationId') || ''
  );
  const [dataService, setDataService] = useState({});
  const [partnerId, setPartnerId] = useState('');
  const [dataUploadFile, setDataUploadFile] = useState({});
  const [loading, setLoading] = useState(false);
  const checkRole = getRole({ idRole: localStorage.getItem('role') });
  const idProvider = localStorage.getItem('idProvide');
  const screenBack = new URL(window.location.href).searchParams.get('onBack') || '';
  useEffect(() => {
    if (recruitmentEstimateId) {
      getDetailEstimate({ recruitmentEstimateId: recruitmentEstimateId });
    }
  }, [recruitmentEstimateId]);

  useEffect(() => {
    if (partnerId && idOrganization) {
      getPartnerByRecruitment({ partnerId: partnerId, organizationId: idOrganization });
    }
  }, [partnerId]);

  const getDetailEstimate = async ({ recruitmentEstimateId = '' }) => {
    setLoading(true);
    try {
      const data = await CONTRACT_ENDPOINTS.GET_DETAIL_ESTIMATE({ recruitmentEstimateId: recruitmentEstimateId });
      if (data?.data) {
        setDataDetailCredit(data?.data?.contractEstimate?.credit);
        setDataDetailEstimates(data?.data?.contractEstimate?.estimate);
        setDataDetailNda(data?.data?.contractEstimate?.nda);
        setDataDetailContractStatus(data?.data?.contractEstimate?.contractStatus);
        setStepPermission(data?.data?.contractEstimate?.step);
        setIdOrganization(data?.data?.contractEstimate?.organizationId);
        setDataService(data?.data?.contractEstimate);
        setPartnerId(data?.data?.contractEstimate?.partnerId);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const getPartnerByRecruitment = async ({ partnerId = '', organizationId = '' }) => {
    setLoading(true);
    try {
      const data = await CONTRACT_ENDPOINTS.GET_PARTNERS_BY_RECRUITMENT({
        partnerId: partnerId,
        organizationId: organizationId,
      });
      if (data?.data) {
        setDataQualificationContract(data?.data);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const onReturn = () => {
    if (screenBack === 'application') {
      history.push(`/contract/application-site`);
    } else {
      history.push(`/contract`);
    }
  };

  const onHandleSubmit = (data, textSubmit, updateTitle) => {
    onUpdateAndCreate({
      body: {
        ...data,
        contractEstimateId: recruitmentEstimateId,
        organizationId: idOrganization,
        updateTitle: updateTitle
      },
      textSubmit,
    });
  };

  const onUpdateAndCreate = async ({ body = '', textSubmit = '' }) => {
    try {
      let data = {};
      switch (textSubmit) {
        case 'credit':
          data = await CONTRACT_ENDPOINTS.CREATE_CREDIT_IN_RECRUITMENT_CONTRACT({ body });
          break;
        case 'estimates':
          data = await CONTRACT_ENDPOINTS.CREATE_ESTIMATES_IN_RECRUITMENT_CONTRACT({ body });
          break;
        case 'nda':
          data = await CONTRACT_ENDPOINTS.CREATE_NDA_IN_RECRUITMENT_CONTRACT({ body });
          break;
        case 'contract_status':
          data = await CONTRACT_ENDPOINTS.CREATE_CONTRACT_STATUS_IN_RECRUITMENT_CONTRACT({ body });
          break;
        case 'serviceContract':
          data = await CONTRACT_ENDPOINTS.CREATE_CONTRACT_UPDATE_SERVICES({
            body: {
              services: body?.services,
              organizationId: body?.organizationId,
              screenName: body?.screenName,
              updateTitle : body?.updateTitle
            },
            contractEstimateId: body?.contractEstimateId,
          });
        default:
          break;
      }
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
      } else {
        pushToast('success', 'Success.');
        getDetailEstimate({ recruitmentEstimateId: recruitmentEstimateId });
        dispatch(getDataHistoryMasterSetting({ logId:'', screenName: SCREEN_NAME.CONTRACT_MANAGEMENT }));
      }
    } catch (e) {
      console.log(e);
    }
  };

  const removeFileContractRecruitment = async ({ body, recruitmentEstimateId }) => {
    try {
      const data = await CONTRACT_ENDPOINTS.REMOVE_FILE_ESTIMATE({
        body,
        recruitmentEstimateId: recruitmentEstimateId,
      });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else if (data.message.errorMessage) {
          pushToast('error', data?.message?.errorMessage);
        } else {
          pushToast('error', data?.message);
        }
      } else {
        pushToast('success', 'Upload success');
        setDataUploadFile(data?.data?.Attributes);
      }
    } catch (errorMessage) {
      console.log('errorMessage: ', errorMessage);
    }
  };

  const removeFileStep = (body) => {
    removeFileContractRecruitment({ body: { ...body, organizationId: idOrganization }, recruitmentEstimateId });
  };

  const uploadCsv = async ({ body }) => {
    try {
      const data = await CONTRACT_ENDPOINTS.UPLOAD_FILE_RECRUITMENT_ESTIMATE({ body });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else if (data.message.errorMessage) {
          pushToast('error', data?.message?.errorMessage);
        } else {
          pushToast('error', data?.message);
        }
      } else {
        pushToast('success', 'Upload success');
        setDataUploadFile(data?.data?.Attributes);
      }
    } catch (errorMessage) {
      console.log('errorMessage: ', errorMessage);
    }
  };

  const submitUpload = (body) => {
    uploadCsv({ body: { ...body, organizationId: idOrganization } });
  };

  return (
    <div className="wrapper-registration-contract-detail">
      <LoadingNew loading={loading} />
      <div className="main">
        <HeaderMasterSetting color={colorScreen?.code} colorText={colorScreen?.colorText} checkName="/contract" />
        <div className="body-wrapper ">
          <div className="block-contract">
            <div className="list-table">
              <button className="btn-back" onClick={onReturn}>
                戻る
              </button>
              <div className="table-list-contract">
                <div className="title-recruitment mb-3">登録画面</div>
                {idProvider === idOrganization && <ParticipatingCompany recruitmentContractId={recruitmentContractId} isPageContract={true} partnerId={dataService?.partnerId}/>}
                <AboutUs dataService={dataService} onHandleSubmit={onHandleSubmit} />
                {((idProvider === idOrganization || (idProvider !== idOrganization && !dataService?.isChildCompany)) && checkRole !== ROLE.PARTNER) && (
                  <Credit
                    recruitmentEstimateId={recruitmentEstimateId}
                    recruitmentContractId={recruitmentContractId}
                    dataDetailCredit={dataDetailCredit}
                    onHandleSubmit={onHandleSubmit}
                    stepPermission={stepPermission}
                    submitUpload={submitUpload}
                    dataUploadFile={dataUploadFile}
                    removeFileStep={removeFileStep}
                  />
                )}
                <NDAContract
                  recruitmentEstimateId={recruitmentEstimateId}
                  dataDetailNda={dataDetailNda}
                  onHandleSubmit={onHandleSubmit}
                  stepPermission={stepPermission}
                  submitUpload={submitUpload}
                  dataUploadFile={dataUploadFile}
                  removeFileStep={removeFileStep}
                  isChildCompany={dataService?.isChildCompany}
                />
                <Estimates
                  recruitmentEstimateId={recruitmentEstimateId}
                  dataDetailEstimates={dataDetailEstimates}
                  onHandleSubmit={onHandleSubmit}
                  stepPermission={stepPermission}
                  submitUpload={submitUpload}
                  dataUploadFile={dataUploadFile}
                  removeFileStep={removeFileStep}
                  isChildCompany={dataService?.isChildCompany}
                />
                <StatusContract
                  recruitmentEstimateId={recruitmentEstimateId}
                  dataDetailContractStatus={dataDetailContractStatus}
                  onHandleSubmit={onHandleSubmit}
                  stepPermission={stepPermission}
                  submitUpload={submitUpload}
                  dataUploadFile={dataUploadFile}
                  removeFileStep={removeFileStep}
                  isChildCompany={dataService?.isChildCompany}
                />
                {((idProvider === idOrganization || (idProvider != idOrganization && !dataService?.isChildCompany)) && checkRole !== ROLE.PARTNER) && (
                  <PartnerCompanies
                    recruitmentEstimateId={recruitmentEstimateId}
                    dataQualificationContract={dataQualificationContract}
                  />
                )}
                {((idProvider === idOrganization || (idProvider != idOrganization && !dataService?.isChildCompany)) && checkRole !== ROLE.PARTNER) && (
                  <PastPerformance recruitmentId={recruitmentContractId} organizationId={idOrganization} partnerId={dataService?.partnerId}/>
                )}
              </div>
            </div>
          </div>
        </div>
        <MemoHistory
          colors={colorScreen?.code}
          colorText={colorScreen?.colorText}
          screenName={SCREEN_NAME.CONTRACT_MANAGEMENT}
        />
      </div>
    </div>
  );
};

export default RegistrationContract;
