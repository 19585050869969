import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { pushToast } from 'components/Toast';
import { ORDER } from 'constants/endpoints';
import 'assets/scss/page/DetailDeliveredInfo/style.scss';
import { useDispatch } from 'react-redux';
import { getDataHistory } from 'actions/authentication';
import { SCREEN_NAME } from '../../../helpers/constants';

const DetailReportErp = ({ checkUpload = [], data, orderId, organizationId, partnerId, documentSetting = [], type, p ,nameForm, nameFileReportUploadInfo= [], screenName = '' }) => {
  const dispatch = useDispatch();
  const [selectedFile, setSelectedFile] = useState({});
  const [selectedName, setSelectedName] = useState({});
  const [dataUpload, setDataUpload] = useState({});
  const [dataDocumentSetting, setDataDocumentSetting] = useState({});

  useEffect(() => {
    setDataUpload(data)
  },[data]);

  // useEffect(() => {
  //   const timestampDocumentSetitngs = screenName === SCREEN_NAME.DELIVERED_INFO && !data.statusReportUploadInfo ? nameFileReportUploadInfo : documentSetting;
  //   setDataDocumentSetting(timestampDocumentSetitngs);
  // }, [nameFileReportUploadInfo])

  useEffect(() => {
    setDataDocumentSetting(documentSetting);
  }, [documentSetting]);

  const uploadFile = async ({ body = '', orderId = '', key }) => {
    try {
      const data = await ORDER.UPLOAD_FILE_BEFORE_CONFIRM({ body: body, orderId, organizationId });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else if (data.message.errorMessage) {
          pushToast('error', data?.message?.errorMessage);
        } else {
          pushToast('error', data?.message);
        }
      } else {
        pushToast('success', 'Upload success');
        setDataUpload({...dataUpload, ...data?.data})
        dispatch(getDataHistory({ orderId: orderId, screenName: screenName, organizationId }));
      }
    } catch (errorMessage) {
      console.log('errorMessage: ', errorMessage);
    }
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const onClickHandler = async (e, numberOfMaterials) => {
    const key = e.target.name;
    if (selectedFile[key]) {
      const body = {
        content: selectedFile[key] ? await toBase64(selectedFile[key]) : '',
        screenName: screenName,
        fileName: selectedName[key] ? selectedName[key] : '',
        keyFile: key,
        numberOfMaterials,
        nameApplication: nameForm,
        updateTitle : 'アップロード',
        companyName : localStorage.getItem('companyName') || ''
      };
      uploadFile({ body, orderId, key });
    }
  };

  const onChangeHandler = (e) => {
    setSelectedFile({ ...selectedFile, [e.target.name]: e.target.files[0] });
    setSelectedName({ ...selectedName, [e.target.name]: e?.target?.files[0]?.name });
  };

  const isRequired = ({ type, elm }) => {
    return elm?.isRequired && !(!selectedName?.[`${type}${elm.timestamp}`] ? dataUpload?.[`${type}${elm.timestamp}`]?.name : selectedName?.[`${type}${elm.timestamp}`])
  };

  return (
    <div className="detail-delivered-table-wrapper">
      <div className="title-absolute">{nameForm || '報告書提出情報'}</div>
      <div className="detail__delivered">
        <table className="table__delivered">
          <tbody>
            {
              !['preparation_info', 'receive_all', 'receive_info'].includes(p) &&
              <tr>
                <th className="table__delivered--font table__delivered--size" colSpan="2" style={{ width: '16%' }}>
                  Worker報告書
                </th>
                <td style={{ width: '22%' }}>
                  <Link to={`/report-order?orderId=${orderId}&organizationId=${organizationId}&partnerId=${partnerId}&menuName=workOnTheDay`}>
                    <button className="table__delivered--submit">Worker確認</button>
                  </Link>
                </td>
                <td></td>
                <td></td>
              </tr>
            }

            {
              dataDocumentSetting?.length > 0 ?
                dataDocumentSetting.map((elm, index) => {
                  if (elm?.uploadFile && !checkUpload.includes(elm.documentSettingId) && screenName !== SCREEN_NAME.DELIVERED_INFO ) {
                    return (
                      <tr key={index}>
                        <th className="table__delivered--font table__delivered--size" colSpan="2" style={{ width: '16%' }}>
                          {elm?.numberOfMaterials}
                        </th>
                        <td style={{ width: '22%' }}>
                          <label className="cursor-pointer table__delivered--label" htmlFor={`${type}${elm.timestamp}`}>
                            ファイルを指定
                        </label>
                          <input type="file" name={`${type}${elm.timestamp}`} id={`${type}${elm.timestamp}`} className="d-none" onChange={onChangeHandler} />
                        </td>

                        <td style={{ width: '30%' }}>
                          <input
                            className={`content__input--w100 ${isRequired({ type, elm }) && 'content__input--required'}`} type="text"
                            disabled
                            value={!selectedName?.[`${type}${elm.timestamp}`] ? dataUpload?.[`${type}${elm.timestamp}`]?.name : selectedName?.[`${type}${elm.timestamp}`]} />
                        </td>
                        <td className="table__delivered--th" style={{ width: '22%' }}>
                          <button className="table__delivered--submit" name={`${type}${elm.timestamp}`} onClick={(e) =>onClickHandler(e, elm?.numberOfMaterials)}>
                            アップロード
                        </button>
                        </td>
                        {/* {
                          type === "reportInformation" && !_.isEmpty(dataUpload?.[`${type}${elm.timestamp}`]?.url) &&
                          <td className="table__delivered--th" style={{ width: '22%' }}>
                            <button
                              className="table__delivered--submit"
                              onClick={() => window.open(`${dataUpload?.[`${type}${elm.timestamp}`]?.url}`)}
                            > ダウンロード
                          </button>
                          </td>
                        } */}
                      </tr>
                    )
                  } else {
                    return (<tr key={index}>
                      <th className="table__delivered--font table__delivered--size" colSpan="2" style={{ width: '16%' }}>
                        {elm?.numberOfMaterials}
                      </th>
                      <td><a href={`${dataUpload?.[`${type}${elm.timestamp}`]?.url}`} target="_blank" >{dataUpload?.[`${type}${elm.timestamp}`]?.name}</a></td>
                      <th className="table__delivered--th" style={{ width: '30%' }}>
                        <button
                          className="table__delivered--submit"
                          disabled={_.isEmpty(dataUpload?.[`${type}${elm.timestamp}`]?.name)}
                          onClick={() => window.open(`${dataUpload?.[`${type}${elm.timestamp}`]?.url}`)}
                        > ダウンロード
                            </button>
                      </th>
                      <td>

                        {/* {
                          (_.isEmpty(dataUpload?.[`${type}${elm.timestamp}`]) || !dataUpload?.[`${type}${elm.timestamp}`]?.status) ?
                            <input
                              disabled
                              className="w-75 table__delivered--input"
                              value={dataUpload?.[`${type}${elm.timestamp}`]?.comment}
                            ></input> : <div className="empty-div"></div>
                        } */}
                      </td>
                    </tr>)
                  }
                }) : ''
            }
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DetailReportErp;
