import React, { useState } from 'react';
import './style.scss';

const data = {
  header: ['見出名', '区分', '内容', '項目種類', '操作'],
  body: [
    {
      見出名: 'a',
      区分: 'b',
      内容: 'v',
      項目種類: 'd',
      操作: 'e'
    },
    {
      見出名: '1',
      区分: '2',
      内容: '3',
      項目種類: '4',
      操作: '5'
    }
  ]
};

const optionItem = [
  { value: '---', label: '---' },
  { value: 'radio', label: 'ラジオボタン' },
  { value: 'checkbox', label: 'チェックボックス' },
  { value: 'text:textinteger', label: '整数' },
  { value: 'text:textdecimal', label: '数値（小数有り）' },
  { value: 'text:textnormal', label: 'テキスト' },
  { value: 'textarea', label: 'テキストエリア' },
  { value: 'text:textdate', label: '日付' }
];

const SelectOption = () => {
  const [selectOption, setSelectOption] = useState('');
  const [inputList, setInputList] = useState([{ firstName: '済' }]);

  const handleSelect = e => {
    let index = e.target.selectedIndex;
    let text = e.target[index].value;
    setSelectOption(text);
  };

  const handleInputChange = () => {
    setInputList(preState => [...preState, { firstName: '' }]);
  };

  return (
    <div>
      <select name="filedtype1[new1]" onChange={handleSelect}>
        {optionItem.map(item => (
          <option value={item.value}>{item.label}</option>
        ))}
      </select>
      <br />
      {(selectOption === 'radio' || selectOption === 'checkbox') && (
        <>
          {' '}
          {inputList.map((item, index) => {
            return (
              <>
                <input
                  type="text"
                  name="filedvalue1[new1][]"
                  className="filedvalue1"
                  value={item.firstName}
                  disabled=""
                />{' '}
                <br />
              </>
            );
          })}
          <button onClick={handleInputChange}>追加</button> <br />
        </>
      )}
      {(selectOption === 'text:textinteger' || selectOption === 'text:textdecimal') && (
        <>
          <input type="text" name="filedvalue1[new1][]" className="filedvalue1 filedvalue1orign" placeholder="単位" />
          <br />
        </>
      )}

      <input type="checkbox" name="delete[1]" id="delete1" value="1" className="ml-1" />
      <label className="mb-0 ml-1" htmlFor="delete1">
        必須
      </label>
    </div>
  );
};

const renderItem = (item, index, handleRemoveClick, onClickUp, onClickDown) => {
  return (
    <tr key={index}>
      {data.header.map((element, key) => {
        return element === '操作' ? (
          <td>
            <div>
              <button type="button" className="delete_btn delete_btn_new" onClick={() => handleRemoveClick(index)}>
                <i className="fa fa-trash" aria-hidden="true" />
              </button>
              <button type="button" className="up_btn" onClick={() => onClickUp(index)}>
                <i className="fa fa-chevron-up" aria-hidden="true" />
              </button>
              <button type="button" className="down_btn" onClick={() => onClickDown(index)}>
                <i className="fa fa-chevron-down" aria-hidden="true" />
              </button>
            </div>
          </td>
        ) : element === '項目種類' ? (
          <td>
            <SelectOption />
          </td>
        ) : element === '内容' ? (
          <td>
            <div>
              内容1 <br />
              <textarea className="texttarae" name="micro_section[new1]" value={item[element]} cols="30" rows="3" />
              <br />
              内容2 <br />
              <textarea className="texttarae" name="comment1[new1]" value={item[element]} cols="30" rows="2" />
            </div>
          </td>
        ) : element === '区分' ? (
          <td>
            <div className="input-table">
              <input type="text" value={item[element]} />
            </div>
          </td>
        ) : (
          <td key={key}>
            <div className="input-table">
              <input type="text" value={item[element]} />
            </div>
          </td>
        );
      })}
    </tr>
  );
};

const TablePartnerStaffFiledMaster = () => {
  const [add, setAdd] = useState(data.body);

  const addNew = () => {
    setAdd(preState => [
      ...preState,
      {
        見出名: '',
        区分: '',
        内容: '',
        項目種類: '',
        操作: ''
      }
    ]);
  };
  const handleRemoveClick = index => {
    const list = [...add];
    list.splice(index, 1);
    setAdd(list);
  };

  function array_move(arr, old_index, new_index) {
    while (old_index < 0) {
      old_index += arr.length;
    }
    while (new_index < 0) {
      new_index += arr.length;
    }
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    setAdd(arr);
    return arr;
  }

  const onClickUp = index => {
    if (index > 0) {
      let newArr = [...add];
      setAdd(array_move(newArr, index, index - 1));
    }
  };
  const onClickDown = index => {
    if (index >= 0) {
      let newArr = [...add];
      setAdd(array_move(newArr, index, index + 1));
    }
  };

  return (
    <>
      <div className="table-partner-staff-file-master table-responsive mt-3 ">
        <table className={`table mb-0 z`}>
          <thead>
            <tr>
              {data.header.map((item, index) => (
                <th scope="col" key={index}>
                  {item}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {add.map((listItem, index) => renderItem(listItem, index, handleRemoveClick, onClickUp, onClickDown))}
          </tbody>
        </table>
        <div className=" d-flex ">
          <button className={`btn`} onClick={addNew}>
            <i className="fa fa-plus" />
            追加
          </button>
        </div>
        <div className=" button-submit-item align-items-center text-center">
          <button className="btn_submit">保存</button>
        </div>
      </div>
    </>
  );
};

export default TablePartnerStaffFiledMaster;
