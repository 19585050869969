import DateTimePicker from '../../DateTimePicker';
import { Collapse } from 'reactstrap';
import React, { useState } from 'react';

const dataSearchMock = {
  page: 1,
  limit: 10,
  caseName: '',
  managementCode: '',
  process: '',
};

const CollapseExample = ({ isOpen, color, getList = () => {}, colorText }) => {
  const [hideshow2, setHideshow2] = useState(false);
  const toggleHide = () => setHideshow2(!hideshow2);
  const [dataSearch, setDataSearch] = useState(dataSearchMock);

  const handleChangeSearch = (e) => {
    const typeName = e.target.name;
    let newData = { ...dataSearch, [typeName]: e.target.value };
    setDataSearch(newData);
  };

  const handleReset = () => {
    Array.from(document.querySelectorAll('input')).forEach((input) => (input.value = ''));
    setDataSearch({
      itemvalues: [{}],
    });
  };

  const onSubmit = () => {
    getList(dataSearch);
  };
  return (
    <>
      <Collapse isOpen={isOpen}>
        <table style={{ width: '100%' }} className="table-search">
          <tbody>
            <tr>
              <th style={{ backgroundColor: `${color}`, color: `${colorText}` }}>工事名</th>
              <td>
                <input type="text" name="caseName" value={dataSearch?.caseName} onChange={handleChangeSearch} />
              </td>

              <th style={{ backgroundColor: `${color}`, color: `${colorText}` }}>管理コード</th>
              <td>
                <input
                  type="text"
                  name="managementCode"
                  value={dataSearch?.managementCode}
                  onChange={handleChangeSearch}
                />
              </td>
            </tr>

            <tr>
              <th style={{ backgroundColor: `${color}`, color: `${colorText}` }}>物件名</th>
              <td>
                <input
                  type="text"
                  name="visitedSiteName"
                  value={dataSearch?.installationSiteName}
                  onChange={handleChangeSearch}
                />
              </td>

              <th style={{ backgroundColor: `${color}`, color: `${colorText}` }}>指定訪問日時</th>
              <td>
                <div className="d-flex align-items-center">
                  <DateTimePicker /> ～
                  <DateTimePicker />
                </div>
              </td>
            </tr>

            <tr>
              <th style={{ backgroundColor: `${color}`, color: `${colorText}` }}>申込会社名</th>
              <td>
                <input
                  type="text"
                  name="contractant_company"
                  value={dataSearch?.managementCode}
                  onChange={handleChangeSearch}
                />
              </td>

              <th style={{ backgroundColor: `${color}`, color: `${colorText}` }}>依頼日</th>
              <td>
                <div className="d-flex align-items-center">
                  <DateTimePicker /> ～
                  <DateTimePicker />
                </div>
              </td>
            </tr>

            <tr>
              <th style={{ backgroundColor: `${color}`, color: `${colorText}` }}>稼働結果</th>
              <td>
                <input type="checkbox" name="pref[]" value="完了" />
                完了
                <input type="checkbox" name="pref[]" value="未完了" />
                未完了
                <input type="checkbox" name="pref[]" value="当日ＣＡＮ" />
                当日ＣＡＮ
                <input type="checkbox" name="pref[]" value="却下" />
                却下
              </td>

              <th style={{ backgroundColor: `${color}`, color: `${colorText}` }}>承認日</th>
              <td>
                <div className="d-flex align-items-center">
                  <DateTimePicker /> ～
                  <DateTimePicker />
                </div>
              </td>
            </tr>

            <tr>
              <th style={{ backgroundColor: `${color}`, color: `${colorText}` }}>報告書状況</th>
              <td>
                <input type="checkbox" name="pref[]" value="未提出" />
                未提出
                <input type="checkbox" name="pref[]" value="提出済み" />
                提出済み
                <input type="checkbox" name="pref[]" value="却下" />
                却下
                <input type="checkbox" name="pref[]" value="納品済み" />
                納品済み
              </td>

              <th style={{ backgroundColor: `${color}`, color: `${colorText}` }}>完了日</th>
              <td>
                <div className="d-flex align-items-center">
                  <DateTimePicker /> ～
                  <DateTimePicker />
                </div>
              </td>
            </tr>

            <tr>
              <th style={{ backgroundColor: `${color}`, color: `${colorText}` }}>承認状況</th>
              <td>
                <input type="checkbox" name="pref[]" value="未承認" />
                未承認
                <input type="checkbox" name="pref[]" value="承認済み" />
                承認済み
              </td>

              <th style={{ backgroundColor: `${color}`, color: `${colorText}` }}>報告書納品日</th>
              <td>
                <div className="d-flex align-items-center">
                  <DateTimePicker /> ～
                  <DateTimePicker />
                </div>
              </td>
            </tr>

            <tr>
              <th style={{ backgroundColor: `${color}`, color: `${colorText}` }}>アポイント状況</th>
              <td>
                <input type="checkbox" name="pref[]" value="未設定" />
                未設定
                <input type="checkbox" name="pref[]" value="設定済み" />
                設定済み
              </td>

              <th style={{ backgroundColor: `${color}`, color: `${colorText}` }}>キャンセル</th>
              <td>
                <div className="d-flex align-items-center">
                  <DateTimePicker /> ～
                  <DateTimePicker />
                </div>
              </td>
            </tr>

            <tr>
              <th style={{ backgroundColor: `${color}`, color: `${colorText}` }}>日程調整状況</th>
              <td>
                <input type="checkbox" name="pref[]" value="未設定" />
                未設定
                <input type="checkbox" name="pref[]" value="設定済み" />
                設定済み
              </td>

              <th style={{ backgroundColor: `${color}`, color: `${colorText}` }}></th>
              <td></td>
            </tr>

            <tr>
              <th style={{ backgroundColor: `${color}`, color: `${colorText}` }}>納品状況</th>
              <td>
                <input type="checkbox" name="pref[]" value="未設定" />
                未納品
                <input type="checkbox" name="pref[]" value="設定済み" />
                納品済み
              </td>

              <th style={{ backgroundColor: `${color}`, color: `${colorText}` }}></th>
              <td></td>
            </tr>
          </tbody>
        </table>
        <div className="SearchButton" align="center">
          <button className="btn btnS btnSubmit mr-2" onClick={() => onSubmit()}>
            <i className="icon-search" />検 索
          </button>
          <button type="button" className="btn btnS btnReset" onClick={handleReset}>
            <i className="icon-arrows-cw" /> 一括解除
          </button>
        </div>
      </Collapse>
    </>
  );
};

export default CollapseExample;
