import { combineReducers } from 'redux';
import user from './user';
import utils from './utils';
import order from './order';
import authentication from './authentication';
import chat from './chat';

export default combineReducers({
  user,
  utils,
  order,
  authentication,
  chat
});
