
import React, { useState, useEffect } from 'react';
import { ORGANIZATION_ENDPOINT } from '../../../../constants/endpoints';
import '../../../../assets/scss/page/center/TableOrganization/style.scss';
import PaginationCustom from 'components/PaginationCustom';
import moment               from 'moment';

const TableOrganizations = ({changeProvider , color , colorText, onLoading}) => {
  const [listOrganization, setlistOrganization] = useState([]);
  const [listData, setListData] = useState([]);
  const [activePage, setActivePage] = React.useState(1);

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  useEffect(() => {
    getListOrganization({page: activePage})
  }, []);

  useEffect(() => {
    getListOrganization({ page: activePage });
  }, [activePage]);

  const getListOrganization = async ({page = 1}) => {
    onLoading(true);
    try {
      const data = await ORGANIZATION_ENDPOINT.GET_LIST_ORAGINIZATION({page: page});
      if (data && data?.code === 0) {
        setlistOrganization(data?.data?.result);
        setListData(data);
        onLoading(false);
      }
    } catch (e) {
      console.log(e);
      onLoading(false);
    }
  };

  const handleOrganization = (e) => {
    changeProvider(e.target.dataset.organizationid);
  }

  return (
    <div className="table-list-wrapper table-responsive table-organization">
      <table className="table table-striped">
        <thead>
          <tr style={{ backgroundColor: `${color}` }}>
            <th scope="col" style={{color : `${colorText}`}}>CloudID</th>
            <th scope="col" style={{color : `${colorText}`}}>会社名</th>
            <th scope="col" style={{color : `${colorText}}`}}>作成日時</th>
            <th scope="col" style={{color : `${colorText}`}}>更新日時</th>
            <th scope="col" style={{color : `${colorText}`}}>操作</th>
          </tr>
        </thead>

        <tbody>
          {
            listOrganization?.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{item?.organizationId}</td>
                  <td>{item?.companyName}</td>
                  <td>{moment(item?.createdAt).format('YYYY-MM-DD HH:mm')}</td>
                  <td>{moment(item?.updatedAt).format('YYYY-MM-DD HH:mm')}</td>


                  <td>
                    <button
                      className="link_button d-flex" data-organizationid={item?.organizationId}
                      onClick={(e) => handleOrganization(e)}
                    >
                      <i className="icon-export" data-organizationid={item?.organizationId} />
                      閲覧
                    </button>
                  </td>
                </tr>
              )
            })
          }
        </tbody>
      </table>
      {listData?.data && listData?.data?.totalCount > 10 && (
        <PaginationCustom
            activePage={activePage}
            itemsCountPerPage={10}
            totalItemsCount={listData?.data && listData?.data?.totalCount}
            pageRangeDisplayed={5}
            handlePageChange={handlePageChange}
        />
      )}
    </div>
  )
}

export default TableOrganizations;
