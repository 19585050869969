import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { translate } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useHistory, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import '../../../../assets/scss/page/contract/index.scss';
import Footer from '../../../../components/Footer';
import HeaderMasterSetting from '../../../../components/HeaderMasterSetting';
import { genId } from '../../../../helpers/helpers';
import moment from 'moment';
import { CONTRACT_ENDPOINTS } from '../../../../constants/endpoints';
import { pushToast } from '../../../../components/Toast';
import _ from 'lodash';
import MemoHistory from '../../../../components/MemoHistory';
import { SCREEN_NAME } from '../../../../helpers/constants';

const MasterTarif = (props) => {
  const colorScreen = useSelector((store) => store.utils.colorMe);
  const history = useHistory();
  const [listItem, setListItem] = useState([]);
  const [dataSelect, setDataSelect] = useState([]);
  const [dataError, setDataError] = useState([]);
  const [submit, setSubmit] = useState(false);
  const [dataSelectNew, setDataSelectNew] = useState(['']);

  const onReturn = () => {
    history.goBack();
  };

  const getListTariffList = async ({ page = 1, limit = 10 }) => {
    try {
      const data = await CONTRACT_ENDPOINTS.GET_TARIFF_LIST({
        page: page,
        limit: limit,
      });
      if (data) {
        const newData = data?.data?.tariffs?.map((item, index) => {
          item.sortKey = index + 1;
          return item;
        });
        setListItem(newData);
        const dataNew = data?.data?.tariffs?.map((item) => {
          return item.recruitmentContractId;
        });
        setDataSelectNew(dataNew);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getAllContract = async ({ page = 1, limit = 10 }) => {
    try {
      const data = await CONTRACT_ENDPOINTS.GET_ALL_CONTRACT({
        page: page,
        limit: limit,
      });
      if (data) {
        setDataSelect(data?.data?.contracts);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getListTariffList({ page: 0, limit: 10 });
    getAllContract({ page: 0, limit: 10 });
  }, []);

  const addListItem = () => {
    setListItem([...listItem, { sortKey: genId(), effectiveness: false }]);
  };

  const deleteListItem = (index) => {
    const list = [...listItem];
    list.splice(index, 1);
    setListItem(list);
  };

  const handleChangeDate = async (date, name, index, version) => {
    if (date) {
      const newData = await listItem?.map((item, ele) => {
        if (index === ele) {
          return { ...item, [name]: moment(date).format('YYYY/MM/DD') };
        } else {
          return item;
        }
      });
      setListItem(newData);
    } else {
      const newData = await listItem?.map((item, ele) => {
        if (index === ele) {
          return { ...item, [name]: '' };
        } else {
          return item;
        }
      });
      setListItem(newData);
    }
  };

  const handleChangeItemCheckBox = (index, version) => {
    const newData = listItem?.map((item, ele) => {
      if (index === ele) {
        return { ...item, effectiveness: !item.effectiveness };
      } else {
        return item;
      }
    });
    setListItem(newData);
  };

  const handleSelectItem = (e, indexs) => {
    let index = e.target.selectedIndex;
    let optionElement = e.target.childNodes[index];
    let name = optionElement.getAttribute('value-name');
    let valueItem = e.target.value;
    // let arrTmp = dataSelectNew || [];
    const dataNew =
      listItem &&
      listItem.map((item, ele) => {
        if (ele === indexs) {
          return { ...item, recruitmentContractId: valueItem, tariffName: name };
        }
        return item;
      });
    setListItem(dataNew);
    // arrTmp.push(valueItem);
    // setDataSelectNew(arrTmp);

    // const dataSelects = dataSelect && dataSelect.filter((item) => item.recruitmentContractId !== valueItem);
    // setDataSelect(dataSelects);
  };

  const updateTariff = async (body) => {
    try {
      const data = await CONTRACT_ENDPOINTS.UPDATE_TARIFF(body);
      setDataError(data?.data);
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
      } else {
        pushToast('success', 'Create user success');
        getListTariffList({ page: 0, limit: 10 });
      }
    } catch (errorMessage) {
      console.log('errorMessage: ', errorMessage);
    }
  };

  const onClickUpdateTariff = () => {
    const newData =
      listItem &&
      listItem.map((item) => {
        delete item.createdAt;
        delete item.createdBy;
        delete item.sortKey;
        delete item.updatedAt;
        delete item.updatedBy;
        delete item.organizationId;
        return item;
      });
    updateTariff({ tariffs: newData , screenName : SCREEN_NAME.CONTRACT_MANAGEMENT });
  };
  useEffect(() => {
    let checkDate = listItem?.map((item) => item?.startAt).includes('');
    let checkData = listItem?.map((item) => item?.tariffName).includes(null);
    if (checkDate || checkData) {
      setSubmit(false);
    } else {
      setSubmit(true);
    }
  }, [listItem]);

  const showListVersion = () => {
    return (
      listItem &&
      listItem.map((item, index) => {
        // let newArrry =
        //   dataSelect && dataSelect.filter((items) => !selectedPlayers.includes(items.recruitmentContractId));
        let valueError = dataError && dataError.find((i) => i.tariffName === item?.tariffName);
        return (
          <tr className="item-worker" key={index}>
            <td>
              {!_.isEmpty(item?.tariffId) ? (
                ''
              ) : (
                <div className="icon" onClick={() => deleteListItem(index)}>
                  <i className="fas fa-times"></i>
                </div>
              )}
            </td>
            <td>
              <select
                className={
                  valueError && valueError.requestId
                    ? `text-input-error version`
                    : !_.isEmpty(item?.recruitmentContractId)
                    ? `text-input version`
                    : `text-input-error version`
                }
                onChange={(e) => handleSelectItem(e, index)}
                value={item.recruitmentContractId}
              >
                <option value="">---</option>
                {/* {!_.isEmpty(item?.tariffId) && <option value={item.recruitmentContractId}>{item.tariffName}</option>} */}
                {dataSelect &&
                  dataSelect?.map((ele, eleI) => {
                    return (
                      <option
                        key={eleI}
                        value={ele?.recruitmentContractId}
                        // disabled={dataSelectNew.includes(ele.recruitmentContractId)}
                        value-name={ele?.title}
                      >
                        {ele?.title}
                      </option>
                    );
                  })}
              </select>
            </td>
            <td>
              <div className="form-date">
                <DatePicker
                  selected={item?.startAt ? new Date(item?.startAt) : ''}
                  onChange={(date) => handleChangeDate(date, 'startAt', index, item?.tariffName)}
                  locale="ja"
                  name=""
                  dateFormat="yyyy/MM/dd"
                  dateFormatCalendar="yyyy年 MM月"
                  className={
                    valueError && valueError.requestId
                      ? `text-input-error date-picker`
                      : !_.isEmpty(item?.startAt)
                      ? `text-input date-picker`
                      : `text-input-error version`
                  }
                />
                <DatePicker
                  selected={item?.endAt ? new Date(item?.endAt) : ''}
                  onChange={(date) => handleChangeDate(date, 'endAt', index, item?.tariffName)}
                  locale="ja"
                  name=""
                  dateFormat="yyyy/MM/dd"
                  dateFormatCalendar="yyyy年 MM月"
                  className={
                    valueError && valueError.requestId ? `text-input-error date-picker` : `text-input date-picker`
                  }
                />
              </div>
            </td>
            <td className="align-middle">
              <label className="container-checkbox">
                <input
                  type="checkbox"
                  name="effectiveness"
                  checked={item?.effectiveness}
                  onChange={() => handleChangeItemCheckBox(index, item?.tariffName)}
                />{' '}
                <span className="checkmark"></span>
              </label>
            </td>
            {!_.isEmpty(item?.tariffId) && (
              <>
                <td className="align-middle">
                  <div className="item-button">
                    <Link to={`/contract/master-tarif-configuration?tariffId=${item?.tariffId}`} className="item-next">
                      設定
                    </Link>
                  </div>
                </td>
                <td className="align-middle">
                  <div className="item-button">
                    <Link to={`/contract/master-tarif-edit?tariffId=${item?.tariffId}`} className="item-next">
                      編集
                    </Link>
                  </div>
                </td>
              </>
            )}
          </tr>
        );
      })
    );
  };

  return (
    <>
      <div className="main">
        <HeaderMasterSetting color={colorScreen?.code} colorText={colorScreen?.colorText} checkName="/contract" />
        <div className="block-contract">
          <div className="list-table">
            <button className="btn-back" onClick={onReturn}>
              戻る
            </button>
            <div className="table-list-contract">
              <div className="title-recruitment">タリフマスタ登録</div>
              <div className=" wrapper-master-setting wrapper-master-setting-custom-contract">
                <table className="form-worker">
                  {listItem?.length > 0 && (
                    <thead>
                      <tr>
                        <th style={{ width: '50px' }}></th>
                        <th className="text-center">タリフ名</th>
                        <th className="text-center">期間</th>
                        <th className="text-center">表示</th>
                        <th className="text-center">設定</th>
                        <th className="text-center">編集</th>
                      </tr>
                    </thead>
                  )}
                  <tbody>
                    {showListVersion()}
                    <tr className="item-worker">
                      <td>
                        <div className="icon" onClick={() => addListItem()}>
                          <i className="fas fa-plus"></i>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className="list-button-contact text-right">
                  <button
                    className={`${submit ? 'item' : 'item-button'} mr-0`}
                    disabled={!submit}
                    onClick={() => onClickUpdateTariff()}
                  >
                    設定を保存
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <MemoHistory colors={colorScreen?.code} colorText={colorScreen?.colorText} screenName={SCREEN_NAME.CONTRACT_MANAGEMENT}/>
        <Footer color={colorScreen?.code} colorText={colorScreen?.colorText} />
      </div>
    </>
  );
};

export default compose(translate('translations'), withRouter)(MasterTarif);
