import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import BasicInfo from '../BasicInfo';
import './style.scss';
import TableCenterUser from './TableCenterUser';
import AddNewUserCenter from './AddNewUserCenter';
import { PRODUCT_ENDPOINT, USER_ENDPOINT } from '../../../../constants/endpoints';
import { pushToast } from '../../../../components/Toast';


const Modify4 = () => {
  const [allUserCenter, setAllUserCenter] = useState([]);
  const [allUserClient, setAllUserClient] = useState([]);
  const [listChooseCenter, setListChooseCenter] = useState([]);
  const [listChooseClient, setListChooseClient] = useState([]);
  const [productInfo, setProductInfo] = useState({});
  const productId = (process.browser && new URL(window.location.href).searchParams.get('productId')) || null;

  const getProductInfo = async ({ productId = '' }) => {
    try {
      const data = await PRODUCT_ENDPOINT.GET_PRODUCT_INFO({ productId: productId });
      if (data) {
        setProductInfo(data?.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (productId) {
      getProductInfo({ productId: productId });
    }
  }, [productId]);

  const handleChoose = ({ idUser, checked, role = 'center' }) => {
    if (checked) {
      if (role === 'center') {
        let newListCenter = [...listChooseCenter];
        newListCenter.push(idUser);
        setListChooseCenter(newListCenter);
      } else if (role === 'client') {
        let newListClient = [...listChooseClient];
        newListClient.push(idUser);
        setListChooseClient(newListClient);
      }
    } else {
      if (role === 'center') {
        let newListCenter = [...listChooseCenter];
        newListCenter = newListCenter.filter((item) => item !== idUser);
        setListChooseCenter(newListCenter);
      } else if (role === 'client') {
        let newListClient = [...listChooseClient];
        newListClient = newListClient.filter((item) => item !== idUser);
        setListChooseClient(newListClient);
      }
    }
  };

  const createStep4 = async (body) => {
    try {
      const data = await PRODUCT_ENDPOINT.CREATE_PRODUCT_STEP_FOUR(body);
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
      } else {
        pushToast('success', 'Update success');
      }
    } catch (errorMessage) {
      console.log('errorMessage: ', errorMessage);
    }
  };

  const getAlluser = async ({ role = 'center' }) => {
    try {
      const data = await USER_ENDPOINT.GET_ALL_USER({ role: role });
      if (data) {
        if (role === 'center') {
          setAllUserCenter(data.data);
        } else if (role === 'client') {
          setAllUserClient(data.data);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getAlluser({ role: 'center' });
    getAlluser({ role: 'client' });
  }, []);

  const createSucces = ({ role = 'center' }) => {
    if (role === 'center') {
      getAlluser({ role: 'center' });
    } else if (role === 'client') {
      getAlluser({ role: 'client' });
    }
  };

  const onClickSubmitUser = ({ role = 'center' }) => {
    if (role === 'center') {
      createStep4({
        productId: productId,
        centerViewer: listChooseCenter
      });
    } else if (role === 'client') {
      createStep4({
        productId: productId,
        clientViewer: listChooseClient
      });
    }
  };


  return (
    <div className="wrapper-modify-4">
      <BasicInfo productInfo={productInfo}/>
      <div className="wrapper-title mt-3">センターユーザー閲覧設定</div>
      <div className="wrapper-center-user">
        <TableCenterUser data={allUserCenter} handle={handleChoose} role='center'/>
        <div className="text-center py-3">
          <button className="btn button-succes px-2"
                  onClick={() => onClickSubmitUser({ role: 'center' })}>センターユーザー閲覧登録
          </button>
        </div>
      </div>
      <div className="wrapper-title">新規センターユーザー追加</div>
      <AddNewUserCenter role="center" createSucces={createSucces}/>
      <div className="wrapper-title-sub mt-3">クライアントユーザー閲覧設定</div>
      <div className="wrapper-client-user">
        <TableCenterUser data={allUserClient} handle={handleChoose} role='client'/>
        <div className="d-flex justify-content-center py-3">
          <button className="btn btn-success-blue"
                  onClick={() => onClickSubmitUser({ role: 'client' })}>クライアントユーザー閲覧登録
          </button>
        </div>
      </div>
      <div className="wrapper-title-sub">新規クライアントユーザー追加</div>
      <AddNewUserCenter role="client" createSucces={createSucces}/>
    </div>
  );
};

export default compose(withRouter)(Modify4);
