import React, { useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import 'assets/scss/page/AnkenMasterNew/ModalCreateService.scss';
import PopupConfirmCustom from 'components/popupConfirmCustom';
import { PRODUCT_ENDPOINT } from '../../../../constants/endpoints';
import { useEffect } from 'react';
import _ from 'lodash';
import PaginationCustom from 'components/PaginationCustom';
import Loading from 'components/Loading/Loading';
import { pushToast } from 'components/Toast';
import { notUpdateRecored , checkTypeDate, checkTypeDateTime, time , checkVisitDateTo } from '../../../../helpers/constants';
import moment from 'moment';
import DatePicker from 'react-datepicker';

const INIT_PAGINATION = {
  page: 1,
  limit: 10,
};

const ModalFix = (props) => {
  const { toggle = () => { }, modal = false, productId, serviceId , t } = props;
  const [showWorkflow, setShowWorkflow] = useState(false);
  const [checkShow, setCheckShow] = useState(false);
  const [listOrderManagerMent, setListOrderManagerment] = useState([])
  const [activePage, setActivePage] = useState(1);
  const [dataSearch, setDataSearch] = useState('')
  const [loading, setLoading] = useState(false);
  const [typeUpdate, setTypeUpdate] = useState('');
  const [valueOrder, setValueOrder] = useState('');
  const [keyUpdate, setKeyUpdate] = useState('');
  const [valueUpdate, setValueUpdate] = useState('');
  const [orderId, setOrderId] = useState('');
  const [dataOption,setDataOption] = useState([]);
  const [valueCurrentStep,setValueCurrentStep] = useState('');
  const [valueUpdateCurrentStep , setValueUpdateCurrentStep] = useState('');
  const [itemOrder,setItemOrder] = useState({})

  const showModalUpdate = ({check, type = '', value = '', key = '', orderId = '' , option = [] , currentStep = '' , item = {}}) => {
    setOrderId(orderId)
    setTypeUpdate(type);
    setValueOrder(value);
    setKeyUpdate(key);
    setShowWorkflow(!showWorkflow);
    setCheckShow(check);
    setValueUpdate(value);
    setDataOption([currentStep, ...option]);
    setValueCurrentStep(currentStep)
    setValueUpdateCurrentStep(currentStep)
    setItemOrder(item);
  }

  const handleChangePage = (pageNumber) => {
    setActivePage(pageNumber);
    getOrderOfManagementGroup({ page: pageNumber, productId, serviceId });
  };

  const handleChangeInput = (e , checkValue = false) => {
    checkValue ? setValueCurrentStep(e?.target?.value)  : setValueUpdate(e?.target?.value)
  }

  const getOrderOfManagementGroup = async ({
    page = INIT_PAGINATION.page,
    limit = INIT_PAGINATION.limit,
    productId = '',
    serviceId = '',
    managementCode = '',
    fieldSort = '',
    typeSort = '',
    optionSort = '',
  }) => {
    setLoading(true)
    try {
      const data = await PRODUCT_ENDPOINT.GET_ORDER_OF_MANAGEMENT_GROUP({
        page: page,
        limit: limit,
        productId: productId,
        serviceId: serviceId,
        managementCode: managementCode,
        fieldSort: fieldSort,
        typeSort: typeSort,
        optionSort: optionSort,
      });
      setLoading(false)
      if (data && data.data) {
        setListOrderManagerment(data.data);
      }
    } catch (e) {
      setLoading(false)
      console.log(e);
    }
  };

  useEffect(() => {
    getOrderOfManagementGroup({ productId, serviceId })
  }, [productId, serviceId])

  const updateOrderManagermentGroupAdmin = async ({ body = {}, orderId = '' }) => {
    try {
      const data = await PRODUCT_ENDPOINT.UPDATE_ORDER_MANAGERMENT_GROUP_ADMIN({ body: body, orderId: orderId });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
      } else {
        pushToast('success', 'Update field order success');
        let dataUpdate = listOrderManagerMent?.data?.map((item) => {
          if (orderId === item?.orderId) {
            if (body?.type === 'primary') {
              return {
                orderId: item?.orderId,
                primary: { ...item?.primary, [body?.key]: body?.value },
                secondary: item?.secondary,
                backSteps : item?.backSteps,
                currentStep : item?.currentStep,
              }
            } else if (body?.type === 'secondary') {
              return {
                orderId: item?.orderId,
                secondary: { ...item?.secondary, [body?.key]: body?.value },
                primary: item?.primary,
                backSteps : item?.backSteps,
                currentStep : item?.currentStep,
              }
            }
          } else {
            return item
          }
        });
        setListOrderManagerment({ ...listOrderManagerMent, 'data': dataUpdate })
      }
    } catch (errorMessage) {
      console.log('errorMessage: ', errorMessage);
    }
  };

  const updateBackStep = async ({ body = {}, orderId = '' }) => {
    try {
      const data = await PRODUCT_ENDPOINT.UPDATE_BACK_STEP({ body: body, orderId: orderId });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
      } else {
        pushToast('success', 'Update back order success');
        getOrderOfManagementGroup({ page: activePage, productId, serviceId });
      }
    } catch (errorMessage) {
      console.log('errorMessage: ', errorMessage);
    }
  };

  const onUpdateOrder = () => {
    if (!checkShow && !notUpdateRecored?.includes(keyUpdate)) {
      let body = {
        key: keyUpdate,
        value: valueUpdate,
        type: typeUpdate,
        screenName: "ManagementGroup_運用管理者_レコード編集"
      }
      updateOrderManagermentGroupAdmin({ body, orderId })
    }
    if (checkShow) {
      let body = {
        currentStep: valueUpdateCurrentStep,
        backStep: valueCurrentStep,
        screenName: 'ManagementGroup_運用管理者_レコード編集',
      };
      valueUpdateCurrentStep !== valueCurrentStep && updateBackStep({ body, orderId });
    }
  }

  const renderItem = (item, index) => {
    return (
      <tr key={index}>
        <td className='pr-2'>
        <button
            className={`${ _.compact(item?.backSteps).length < 1 ? 'button__btn-load-disabled' : 'button__btn-load'}  form--mgLeft_0 form--w_65`}
            disabled={_.compact(item?.backSteps).length < 1}
            onClick={() =>
              showModalUpdate({
                check: true,
                orderId: item?.orderId,
                option: item?.backSteps,
                currentStep: item?.currentStep,
              })
            }
          >
            巻戻し
          </button>
        </td>
        {listOrderManagerMent?.header?.primary?.map((element, eI) => {
          return (
            <td
              className="form--tbTh cursor-pointer"
              onClick={() =>
                showModalUpdate({
                  check: false,
                  type: 'primary',
                  value: item?.primary[element],
                  key: element,
                  orderId: item?.orderId,
                  item : item,
                })
              }
              key={eI}
            >
              {item?.primary[element]}
            </td>
          );
        })}
        {listOrderManagerMent?.header?.secondary?.map((element, eI) => {
          return (
            <td
              className="form--tbTh cursor-pointer"
              onClick={() =>
                showModalUpdate({
                  check: false,
                  type: 'secondary',
                  value: item?.secondary[element],
                  key: element,
                  orderId: item?.orderId,
                  item : item,
                })
              }
              key={eI}
            >
              {item?.secondary[element]}
            </td>
          );
        })}
      </tr>
    )
  }

  const handleSearch = (e) => {
    setDataSearch(e?.target?.value)
  }

  const onSubmitSearch = () => {
    getOrderOfManagementGroup({ managementCode: dataSearch, page: 1, limit: 10, productId, serviceId })
  }

  const handleSortOrder = ({ fieldSort = '' , typeSort = '' , optionSort = ''} ) => {
    getOrderOfManagementGroup({ page: activePage, productId, serviceId, fieldSort, typeSort, optionSort });
  }

  const handleChangeDate = (date) => {
    setValueUpdate(date ? moment(date).format('YYYY/MM/DD') : '' );
  };

  const hangdeChangeSelect = ({ e ,keyVisitDate = '' }) => {
    let value = e?.target?.value;
    if(typeUpdate === 'primary' ) {
        setValueUpdate(`${!_.isEmpty(itemOrder?.primary?.[keyVisitDate]) ? `${(itemOrder?.primary?.[keyVisitDate])} ${value}`  : `${moment(new Date()).format('YYYY/MM/DD')} ${value}`}`)
    }
    if(typeUpdate === 'secondary' ) {
      setValueUpdate(`${!_.isEmpty(itemOrder?.secondary?.[keyVisitDate]) ? `${(itemOrder?.secondary?.[keyVisitDate])} ${value}`  : `${moment(new Date()).format('YYYY/MM/DD')} ${value}`}`)
    }
  }

  const changeNameVisit = (name) => {
    switch (name) {
      case '希望訪問日時①（開始時間帯_to）':
        return '訪問希望日①';
      case '希望訪問日時②（開始時間帯_to）':
        return '訪問希望日②';
      case '希望訪問日時③（開始時間帯_to）':
        return '訪問希望日③';
      case '希望訪問日時④（開始時間帯_to）':
        return '訪問希望日④';
      case '希望訪問日時⑤（開始時間帯_to）':
        return '訪問希望日⑤';
      default:
        return '';
    }
  };

  return (
    <div>
      <Loading loading={loading} />
      <Modal className="modal-dialog--w1000" isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>レコード修正</ModalHeader>
        <ModalBody>
          <div className="form-body-service">
            <div className='d-flex justify-content-between'>
              <div className='d-flex'>
                <div className='form--bgBlue form--pd_4_70' >検索
                </div>
                <div >
                  <select className='form--pd_4_70'>
                    <option>稼働待ち</option>
                  </select>
                </div>
              </div>
              <div className='d-flex'>
                <div className='form--pd_3_30 form--bgBlue' >管理コード
                </div>
                <div >
                  <input className='form--pd_2' type='text' value={dataSearch} onChange={handleSearch} />
                </div>
                <div>
                  <button className='button__btn-load' onClick={() => onSubmitSearch()}
                  >検索</button>
                </div>
              </div>
            </div>
            <div className='my-2 form--scroll_x form--w_918 form--scroll_y form--h_600'>
              {listOrderManagerMent.count > 0 && (
                <table className='w-100 form--border-collapse'>
                  <thead>
                    <tr>
                      <th className='w-5 form--sticky0'></th>
                      {listOrderManagerMent &&
                        listOrderManagerMent?.header?.primary?.map((item, index) => {
                          return (
                            <th key={index} className="form--tbTh text-center fz-14 form--sticky0 form--minW_105" >
                              {item}
                              <br />
                              <a className='cursor-pointer' onClick={() => handleSortOrder({fieldSort : item , typeSort : 'primary' , optionSort : 'asc'})}>▲</a>
                              <a className='cursor-pointer' onClick={() => handleSortOrder({fieldSort : item , typeSort : 'primary' , optionSort : 'desc'})}>▼</a>
                            </th>
                          );
                        })}

                      {listOrderManagerMent &&
                        listOrderManagerMent?.header?.secondary?.map((item, index) => {
                          return (
                            <th key={index} className="form--tbTh text-center form--sticky0 fz-14 form--minW_105">
                              {item}
                              <br />
                              <a className='cursor-pointer' onClick={() => handleSortOrder({fieldSort : item , typeSort : 'secondary' , optionSort : 'asc'})}>▲</a>
                              <a className='cursor-pointer' onClick={() => handleSortOrder({fieldSort : item , typeSort : 'secondary' , optionSort : 'desc'})}>▼</a>
                            </th>
                          );
                        })}
                    </tr>
                  </thead>
                  <tbody>
                    {listOrderManagerMent?.data?.map((listData, index) => renderItem(listData, index))}
                  </tbody>
                </table>
              )}
            </div>
            {listOrderManagerMent?.count > 10 && (
              <PaginationCustom
                activePage={activePage}
                itemsCountPerPage={10}
                totalItemsCount={listOrderManagerMent?.count}
                pageRangeDisplayed={5}
                handlePageChange={handleChangePage}
              />
            )}
            <PopupConfirmCustom
              headerTitle={`${checkShow ? 'ワークフロー' : 'フィールド名'}`}
              rederBody={() => {
                return (
                  <div className='table--w95'>
                    {checkShow ? (
                      <select
                        className="form-control"
                        onChange={(e) => handleChangeInput(e, true)}
                        value={valueCurrentStep}
                      >
                        {_.compact(dataOption)?.map((item) => (
                          <option value={item}>{t(`list_screen_export_setting.${item}`)}</option>
                        ))}
                      </select>
                    ) : (
                      <>
                        <input className="form-control mb-2" value={valueOrder} disabled />
                        {checkTypeDate?.includes(keyUpdate) ? (
                          <DatePicker
                            selected={valueUpdate ? new Date(valueUpdate) : ''}
                            onChange={(date) => handleChangeDate(date)}
                            locale="ja"
                            dateFormat="yyyy/MM/dd"
                            dateFormatCalendar="yyyy年 MM月"
                            className="form-control w-100"
                          />
                        ) : checkTypeDateTime?.includes(keyUpdate) ? (
                          <select className="form-control w-100" value={valueUpdate} onChange={handleChangeInput}>
                            {time?.map((item) => (
                              <option value={item}>{item}</option>
                            ))}
                          </select>
                        ) : checkVisitDateTo?.includes(keyUpdate) ? (
                          <select
                            className="form-control w-100"
                            value={valueUpdate?.slice(-5)}
                            onChange={(e) => hangdeChangeSelect({ e, keyVisitDate: changeNameVisit(keyUpdate) })}
                          >
                            {time?.map((item) => (
                              <option value={item}>{item}</option>
                            ))}
                          </select>
                        ) : (
                          <input
                            className="form-control"
                            disabled={notUpdateRecored?.includes(keyUpdate)}
                            value={valueUpdate}
                            onChange={handleChangeInput}
                          />
                        )}
                      </>
                    )}
                  </div>
                );
              }}
              okText="保存"
              onConfirm={() => { onUpdateOrder(); setShowWorkflow(false) }}
              cancelText="キャンセル"
              onCancel={() => {
                setShowWorkflow(false);
              }}
              showPopup={showWorkflow}
              onToggle={() => setShowWorkflow(!showWorkflow)}
            />
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ModalFix;
