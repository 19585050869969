import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import '../../../../assets/scss/page/CsvMaster/index.scss';
import { CONTRACT_ENDPOINTS, ANKEN_MASTER_NEW } from '../../../../constants/endpoints';
import Loading from 'components/Loading/Loading';
import _ from 'lodash';

const mockData = {
  providerId: '',
  provider: 'project',
  monitorId: '',
};

const ModalProject = (props) => {
  const { toggle = () => {}, modal = false, recruitmentContractId, onSubmit, monitorId, dataTree = {} } = props;
  const [dataProject, setDataProject] = useState(mockData);
  const [listProject, setListProject] = useState([]);
  const [listTree, setListTree] = useState([]);
  const [loading, setLoading] = useState(false);
  const [listProductId, setListProductId] = useState([]);

  useEffect(() => {
    let objectTmp = dataProject;
    if (monitorId) {
      objectTmp = { ...objectTmp, monitorId };
    }
    if (recruitmentContractId) {
      objectTmp = { ...objectTmp, recruitmentContractId };
    }
    setDataProject(objectTmp);
    getListProject({ recruitmentContractId });
  }, []);

  const getListProject = async ({ recruitmentContractId }) => {
    try {
      setLoading(true);
      const data = await CONTRACT_ENDPOINTS.GET_LIST_PROJECT({ recruitmentContractId });
      if (data) {
        setListProject(
          _.filter(data?.data, function (elm) {
            return !Object.keys(dataTree[monitorId].projects || {}).includes(elm.projectCode);
          })
        );
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const getTree = async ({ clientId }) => {
    setLoading(true);
    try {
      const data = await ANKEN_MASTER_NEW.GET_PRODUCT_NONE_PROJECT({ clientId });
      if (data) {
        setListTree(data?.data);
      }
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (monitorId) {
      getTree({ clientId: monitorId });
    }
  }, [monitorId]);

  const addProject = () => {
    onProjectSubmit({ ...dataProject, lisProduct: listProductId });
  };

  const onProjectSubmit = async (body) => {
    onSubmit(body, 'project');
  };

  const handleAddProductID = ({ item, id }) => {
    item = { productId: item?.productId, name: item?.name };
    if (listProductId?.map((elm) => elm.productId).includes(id)) {
      const newList = listProductId.filter((ale) => ale.productId !== id);
      setListProductId(newList);
    } else {
      setListProductId([...listProductId, item]);
    }
  };

  const handleSelect = (e) => {
    let newData = {
      ...dataProject,
      providerId: e.target.value,
      providerName: e.target.options[e.target.selectedIndex].text,
    };
    setDataProject(newData);
  };

  return (
    <Modal className="form-modal-contract" isOpen={modal} toggle={() => toggle('project')}>
      <Loading loading={loading} />
      <ModalHeader toggle={() => toggle('project')}>Project新規登録</ModalHeader>
      <ModalBody>
        <div className="block-content">
          <div className="item-content">
            <label>プロジェクト名</label>
            <select onChange={handleSelect}>
              <option>---</option>
              {listProject?.map((item, index) => {
                return (
                  <option key={index} value={item.projectCode}>
                    {item.projectName}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        {listTree && listTree?.length > 0 && (
          <div className="form-tree">
            <p className="d-flex flex-direction-row">
              <div className="title-project">一般</div>
            </p>
            <ul>
              {listTree.map((item, index) => {
                return (
                  <li key={index}>
                    <p>
                      {item.name}
                      <label className="container-checkbox">
                        <input
                          type="checkbox"
                          name="required"
                          checked={listProductId?.map((item) => item.productId).includes(item.productId)}
                          onChange={() => handleAddProductID({ item: item, id: item.productId })}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </p>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
        <button
          className="btn-update"
          disabled={!(listProductId?.length > 0 && !_.isEmpty(dataProject?.providerId))}
          onClick={() => addProject()}
        >
          登録
        </button>
      </ModalBody>
    </Modal>
  );
};

export default ModalProject;
