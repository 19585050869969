import React, { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import PaginationCustom from '../../../../components/PaginationCustom';
import { pushToast } from '../../../../components/Toast';
import { DEVICE_ENDPOINT } from '../../../../constants/endpoints';
import { ROLE, SCREEN_NAME } from '../../../../helpers/constants';
import './style.scss';
import Encoding from 'encoding-japanese';
import ModalAddFieldCsv from './ModalAddFieldCsv';
import ModalUpdateDevice from './ModalUpdateDevice';
import { getNoOrder } from 'helpers/helpers';


const mockData = {
  nameDevice: '',
  productSchema: [],
  screenName : SCREEN_NAME.ITEM_MANAGEMENT
};

const TableListEquipment = ({ role = ROLE.PARTNER, color, roleDetail, t, p, idRole }) => {
  const [activePagess, setActivePagess] = useState(1);
  const [listDevice, setListDevice] = useState({});
  const [listProductForOrder, setListProductForOrder] = useState({});
  const [dataItemDevice, setDataItemDevice] = useState(mockData);
  const [arrProduct, setArrProduct] = useState([]);
  const [modalAdd, setModalAdd] = useState(false);
  const [idDevice, setIdDevice] = useState("");
  const [modalUpdate, setModalUpdate] = useState(false);
  const [idDeviceUpdate, setIdDeviceUpdate] = useState("");

  useEffect(() => {
    setActivePagess(1);
  }, [p]);

  const handlePageChangess = (pageNumber) => {
    setActivePagess(pageNumber);
  };

  const getListEquipment = async ({ page = 1, limit = 10 }) => {
    try {
      const data = await DEVICE_ENDPOINT.GET_LIST_DEVICE_INFO({
        page: page,
        limit: limit,
      });
      if (data) {
        setListDevice(data?.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getListProductForOrder = async () => {
    try {
      const data = await DEVICE_ENDPOINT.GET_LIST_PRODUCT_BY_PERMISSION({});
      if (data) {
        setListProductForOrder(data?.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getListEquipment({ page: activePagess });
    getListProductForOrder({});
  }, []);
  useEffect(() => {
    getListEquipment({ page: activePagess });
  }, [activePagess]);

  const renderItem = (item, index, header) => {
    return (
      <tr key={index}>
        <td>{getNoOrder({ index: index, limit: 10, activePage: activePagess })}</td>
        {header.map((element, key) => {
          if(Array.isArray(item[element])) {
            return <td key={key}>{item[element].join()}</td>;
          }
          return <td key={key}>{item[element]}</td>;
        })}
        <td className="btn-td">
          <div className="upload-csv">
            <label htmlFor={`upload-file-${index}`}>CSV取込</label>
            <input id={`upload-file-${index}`} type="file" onChange={(e) => handleChangeFile(item.deviceId, e)} />
          </div>
        </td>
        <td className="btn-td">
          <button className="mr-2" onClick={() => openModalUpdate(item.deviceId)}>項日追加</button>
          <button onClick={() => openModal(item.deviceId)}>信報編</button>
        </td>
      </tr>
    );
  };

  const handleChangeText = (e) => {
    let newData = { ...dataItemDevice, nameDevice: e.target.value };
    setDataItemDevice(newData);
  };

  const handleChangeCheck = (e) => {
    let arrTmp = arrProduct;
    let target = e.target;
    let value = target.value;
    if (target.checked) {
      arrTmp.push(value);
    } else {
      arrTmp.splice(arrTmp.indexOf(value), 1);
    }
    setArrProduct(arrTmp);
    let newData = { ...dataItemDevice, productSchema: arrProduct };
    setDataItemDevice(newData);
  };

  const onSubmit = () => {
    createDevice(dataItemDevice);
  };

  const createDevice = async (body) => {
    try {
      const data = await DEVICE_ENDPOINT.CREATE_DEVICE(body);
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
      } else {
        pushToast('success', 'Create data success');
        setDataItemDevice(mockData);
        document.querySelectorAll('input[type=checkbox]').forEach( el => el.checked = false );
        getListEquipment({ page: activePagess });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleChangeFile = async (id, event) => {
    const file = event.target.files[0];
    let reader = new FileReader();
    reader.onload = function (e) {
      let codes = new Uint8Array(e.target.result);
      let encoding = Encoding.detect(codes);
      let unicodeString = Encoding.convert(codes, {
        to: 'UNICODE',
        from: ['SJIS', 'UTF8'].includes(encoding) ? encoding : 'SJIS',
        type: 'string',
      });
      const str_code = Encoding.stringToCode(unicodeString);
      const sjis_array = Encoding.convert(str_code, 'UTF8', 'UNICODE');
      const unit8Array = new Uint8Array(sjis_array);
      const file_affer = new File([unit8Array], { type: 'text/csv;charset=utf-8' });
      const data = new FormData();
      data.append('file', file_affer);
      uploadCsv(data, id);
    };
    reader.readAsArrayBuffer(file);
  };

  const uploadCsv = async (body = '', id = '') => {
    try {
      const data = await DEVICE_ENDPOINT.UPLOAD_FILE({ body: body, id: id });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else if (data.message.errorMessage) {
          pushToast('error', data?.message?.errorMessage);
        } else {
          pushToast('error', data?.message);
        }
      } else {
        pushToast('success', 'Upload CSV success');
      }
    } catch (errorMessage) {
      console.log('errorMessage: ', errorMessage);
    }
  };

  const toggleAdd = () => {
    setModalAdd(!modalAdd);
  };

  const openModal = (id) => {
    setModalAdd(!modalAdd);
    setIdDevice(id);
  };

  const openModalUpdate = (id) => {
    setModalUpdate(!modalUpdate);
    setIdDeviceUpdate(id);
  }

  const toggleUpdate = () => {
    setModalUpdate(!modalUpdate);
  };

  const loadListDevice = () => {
    getListEquipment({ page: activePagess });
  }

  return (
    <div className="table-menu-wrapper">
      <div className="create-equipment">
        <table className="table table-create table-bordered">
          <thead>
            <tr>
              <th>管理名称</th>
              <th>
                <input
                  type="text"
                  name="nameDevice"
                  value={dataItemDevice.nameDevice}
                  className="form-control"
                  onChange={handleChangeText}
                />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>対象案件</td>
              <td>
                {listProductForOrder &&
                  listProductForOrder.body &&
                  listProductForOrder.body.map((item, index) => {
                    return (
                      <span key={index}>
                        <input type="checkbox" value={item.productId} onChange={handleChangeCheck} />
                        {item.name}
                      </span>
                    );
                  })}
              </td>
            </tr>
          </tbody>
        </table>
        <div className="create-equipment-submit">
          <button className="btn-submit" onClick={onSubmit}>
            保存
          </button>
        </div>
      </div>
      <ModalAddFieldCsv modal={modalAdd} toggle={toggleAdd} id={idDevice} loadListDevice={loadListDevice}/>
      <ModalUpdateDevice modal={modalUpdate} toggle={toggleUpdate} id={idDeviceUpdate} loadListDevice={loadListDevice}/>
      <div className="table-list-equipment">
        <table className="table table-list table-bordered">
          <thead>
            <tr>
              <th>No</th>
              {listDevice &&
                listDevice.header &&
                listDevice.header.map((item, index) => (
                  <th key={index}>{item != '' ? t(`table_device_list.${item}`) : ''}</th>
                ))}
              <th>CSV取込</th>
              <th>関数</th>
            </tr>
          </thead>
          <tbody>
            {listDevice &&
              listDevice.body &&
              listDevice.body.map((listData, index) =>
                renderItem(listData, index, listDevice && listDevice.header)
              )}
          </tbody>
        </table>
      </div>
      {
        listDevice && listDevice.count > 10 ?
        <PaginationCustom
          activePage={activePagess}
          itemsCountPerPage={10}
          totalItemsCount={listDevice && listDevice.count}
          pageRangeDisplayed={5}
          handlePageChange={handlePageChangess}
        /> : ''
      }

    </div>
  );
};

export default compose(translate('translations'), withRouter, connect())(TableListEquipment);
