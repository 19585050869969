export const HEADER_MENU = {
  RECEIVE_ALL: 'receive_all',
  RECEIVE_INFO: 'receive_info',
  APPOINT_INFO: 'appoint_info',
  CONDITION_INFO: 'condition_info',
  PREPARATION_INFO: 'preparation_info',
  SITE_INFO: 'site_info',
  REPORT_UPLOAD_INFO: 'report_upload_info',
  DELIVERED_INFO: 'delivered_info',
  CANCEL_MENU: 'cancel_menu',
  CANCEL_INFO: 'cancel_info',
  RECEIVE_CONFIRM_LIST: 'receive_confirm_list',
  UNOPERATION_LIST: 'unoperation_list',
  REPORT_LIST: 'report_list',
  TOMORROW_INFO: 'tomorrow_info',
  EQUIPMENT_MANAGEMENT: 'equipment-management'
};

export const MASTER_MENU = {
  MASTER_SETTING: 'master_setting',
  ORGANIZATION_MASTER: 'organization_master',
  USERS_MASTER: 'user_master',
  PROJECT_MASTER: 'project_master',
  PRODUCT_MASTER: 'product_master',
  SERVICE_MASTER: 'service_master',
  CSV_EXPORT: 'csv_export',
  PAGE_SHOW: 'page_show',
  FIELD_NAME: 'field_name',
  SPREAD_SHEET: 'spread_sheet',
  MASTER_MONITOR: 'master_monitor',
  MASTER_STAFF: 'master_staff',
};

export const WORKER_TYPE = {
  RADIO_BUTTON: 'ラジオボタン',
  CHECKBOX: 'チェックボックス',
  INTEGER: '整数',
  FLOAT: '数値',
  TEXT_FIELD: 'テキスト',
  TEXT_AREA: 'テキストエリア',
  DATE: '日付',
  TIME: '時間',
  ATTACHMENT_FILE: 'ファイル添付',
  SIGNATURE: '手書きサイン',
  IMAGE: '写真',
  BARCODE_IDENTIFICATION: 'バーコード識別',
  FORM_INPUT_DATETIME: 'フィールド反映',
  SHOW_NAME : 'フィールド参照',
  COMPARE_NAME : '自動判定',
  LINK_URL : 'リンクボタン',
  TIME_STAMP : '時間_ボタン',
  // FIELD_REFLECTION: 'フィールド反映',
  // FIELD_REFERENCE: 'フィールド参照',
};

export const listTableInformation = [
  { label: 'サービス設定', value: 'order-information' },
  { label: 'ワーカーマスタ設定', value: 'site-information' },
  { label: 'ワーカースキル設定', value: 'worker-information' },
  { label: 'シフト設定', value: 'staff-information' },
  { label: '並べ替え設定', value: 'worker-priority' },
  // { label: '報告書回収情報', value: 'collection-information' },
];

export const SCREEN_DISPATCH_SETTING = {
  orderInformation: {label: 'サービス設定', value: 'order-information' },
  siteInformation: { label: 'ワーカーマスタ設定', value: 'site-information' },
  workerInformation: { label: 'ワーカースキル設定', value: 'worker-information' },
  // staffInformation: { label: 'シフト設定', value: 'staff-information' },
  priorityPattern: { label: '優先パターン', value: 'priority-pattern' },
  workerPriority: { label: '並べ替え設定', value: 'worker-priority' },
};

export const dataStep = [
  {
    index: 0,
    name: 'pre',
    workName: 'pre',
    label: '前日確認',
    active: true,
    fullName: '事前確認',
  },
  {
    index: 1,
    name: 'today',
    workName: 'today',
    fullName: '本日',
    label: '本日',
    active: false,
  },
  {
    index: 2,
    name: 'nextday',
    workName: 'nextday',
    fullName: '翌日以降',
    label: '翌日以降',
    active: false,
  },
];

export const SCREEN_WORKER_MENU = {
  PRE : '前日確認',
  TODAY : '本日',
  NEXT_DAY : '翌日以降'
}

export const WORKER_MENU = {
  ENTER_PAGE: 'enter_page',
  REPORT004_CHECK: 'report004_check',
  REPORT003: 'report003',
  LEAVE_PAGE: 'leave_page',
}

export const WORKER_LIST = {
  DAY_BEFORE: 'pre',
  TODAY: 'today',
  NEXT_DAY: 'nextday',
}

export const ROLE = {
  PARTNER: 'PARTNER',
  PARTNERGROUP: 'PARTNERGROUP',
  CLIENT: 'CLIENT',
  CENTER: 'CENTER',
  WORKER: 'WORKER',
  NEOMARS: 'NEOMARS'
};

export const enumPluginSetting = {
  CREATE_NEW_ORDER: 'create_new_order',
  ORDER_EXECUTION_APPROVAL: 'order_execution_approval',
  APPOINTMENT: 'appointment',
  OPERATION_ADJUSTMENT: 'operation_adjustment',
  WORKER_ALLOCATION: 'worker_allocation',
  CONFIRMED_THE_DAY_BEFORE: 'confirmed_the_day_before',
  CONFIRMED: 'confirmed',
  REPORT_COLLECTION: 'report_collection',
  REPORT_DELIVERY: 'report_delivery',
  ACCEPTANCE_CONFIRMATION: 'acceptance_confirmation',
};

export const enumOrderStatus = {
  PARTNER_APPROVED: 'partner_approved',
  CENTER_APPROVED: 'center_approved',
  CLIENT_APPROVED: 'client_approved',
  MANAGEMENT_GROUP_APPROVED: 'managementGroup_approved',
  CENTER_REJECT: 'center_reject',
  CLIENT_REJECT: 'client_reject',
  MANAGEMENT_GROUP_REJECT: 'managementGroup_reject',
  CENTER_SAVE: 'center_save',
  PARTNER_SAVE: 'partner_save',
  MANAGEMENT_GROUP_SAVE: 'managementGroup_save'
};

export const NAME_FILE_EXPORT_DISPATCH = {
  dispatchResult: 'ディスパッチ結果',
  movingDistance: '移動距離'
}

export const EXTRA_DATA_STATUS = {
  USE: 'use',
  UNUSED: 'unused',
  OPTION: 'option',
  OFF: 'off',
};

export const ENUM_ROLE = {
  NEOMARS: '1',
  ERP_ONE: '2',
  ERP_TWO: '3',
  ERP_THREE: '4',
  MANAGEMENT_GROUP_ONE: '5',
  MANAGEMENT_GROUP_TWO: '6',
  MANAGEMENT_GROUP_THREE: '7',
  MANAGEMENT_ONE: '8',
  MANAGEMENT_TWO: '9',
  MANAGEMENT_THREE: '10',
  MONITORING_ONE: '11',
  MONITORING_TWO: '12',
  MONITORING_THREE: '13',
  WORK: '14'
};

export const ROLE_ENUM = {
  1: 'NEOMARS Cloud管理者',
  2: 'ERP 契約管理者',
  3: 'ERP 運用管理者',
  4: 'ERP 運用者',
  5: 'ManagementGroup 契約管理者',
  6: 'ManagementGroup 運用管理者',
  7: 'ManagementGroup 運用者',
  8: 'Partner 契約管理者',
  9: 'Partner 運用管理者',
  10: 'Partner 運用者',
  12: 'Client 運用管理者',
  13: 'Client 運用者',
  14: 'Worker'
};

export const ROLE_TYPE_ENUM = {
  1: '管理者',
  2: '契約管理者',
  3: '運用管理者',
  4: '運用者',
  5: '契約管理者',
  6: '運用管理者',
  7: '運用者',
  8: '契約管理者',
  9: '運用管理者',
  10: '運用者',
  11: '契約管理者',
  12: '運用管理者',
  13: '運用者',
  14: 'Worker'
};

export const ROLE_NAME_ENUM = {
  1: 'NEOMARS Cloud',
  2: 'ERP',
  3: 'ERP',
  4: 'ERP',
  5: 'Biz ERP',
  6: 'Biz ERP',
  7: 'Biz ERP',
  8: 'Partner',
  9: 'Partner',
  10: 'Partner',
  11: 'Client',
  12: 'Client',
  13: 'Client',
  14: 'Partner'
};

export const ROLE_ENUM_SELECT = [
  { value: '1', label: 'NEOMARS Cloud管理者' },
  { value: '2', label: 'ERP 契約管理者' },
  { value: '3', label: 'ERP 運用管理者' },
  { value: '4', label: 'ERP 運用者' },
  { value: '5', label: 'ManagementGroup 契約管理者' },
  { value: '6', label: 'ManagementGroup 運用管理者' },
  { value: '7', label: 'ManagementGroup 運用者' },
  { value: '8', label: 'Partner 契約管理者' },
  { value: '9', label: 'Partner 運用管理者' },
  { value: '10', label: 'Partner 運用者' },
  { value: '12', label: 'Client 運用管理者' },
  { value: '13', label: 'Client 運用者' },
  { value: '14', label: 'Worker' },
];

export const PERMISSION = {
  PARTNER_CONTRACT_MANAGER: ['5', '8'],
  PERMISSION_DISPATCH: ['2', '3', '5', '6', '8', '9', '11', '12'],
  PARTNER_GROUP_CONTRACT_MANAGER: ['5'],
  PARTNER_GROUP_OPERATION_MANAGER: ['6'],
  PARTNER_OPERATION_MANAGER: ['9'],
  MONITORING_GROUP_CONTRACT_MANAGER: ['11'],
  MONITORING_GROUP_OPERATION_MANAGER: ['12'],
  ERP_CONTRACT_MANAGER: ['2'],
  ERP_OPERATION_MANAGER: ['3'],
  CLIENT_CONTRACT_MANAGER: ['11'],
  NEOMARS: ['1'],
  NEOMARS_ORGANIZATION: ['1', '2', '3', '5', '6',],
  PERMISSION_MYCORP: ['1', '2', '5', '8', '11'],
  PERMISSION_ORGANIZATION: ['1', '2', '3', '5', '6'],
  PERMISSION_ANKENMASTER: ['2', '3', '5', '6'],
  PERMISSION_CONTRACT: ['2', '5', '8', '11'],
  PERMISSION_SETTINGORDER: ['3', '6'],
  PERMISSION_SETTINGWORKER: ['3', '6'],
  PERMISSION_DISPATCHSETTING: ['3', '6'],
  PERMISSION_CSVMASTER: ['2', '3', '5', '6', '8', '11'],
  PERMISSION_PAYMENTDATE: ['2'],
  PERMISSION_USERMASTER: ['1', '2', '3', '5', '6', '8', '11', '12'],
  PERMISSION_CENTER_MAINTAIN: ['1', '2', '3'],
  PERMISSION_CENTER_MYCORP: ['1', '2'],
  PERMISSION_MAINTAIN_MYCORP: ['1', '2', '5', '11'],
  PERMISSION_MAINTAIN_STAFF: ['3', '6'],
  PERMISSION_OPERATION_PARTNER: ['9'],
  PERMISSION_MAINTAIN: ['1', '2', '3', '5', '6', '8', '9', '11', '12'],
  PERMISSION_PATH_MAINTAIN: ['master-setting', 'configuration-worker', 'organizations/modify_organization',
    'ImportCsv-pri-sco', 'anken-master', 'aggregate', 'dispatch-settings', 'csv-master', 'contract', 'staff_master',
    'branch-master', 'users', 'register-user', 'edit-user', 'organizations', 'payment-date'],
  PERMISSION_PATH_LIST: ['list'],
  PERMISSION_PARTNER_CONTRACT: ['8']
}

export const DISABLE_MENU_LIST = {
  ROLE_ERP: ['4', '7'],
  ROLE_PARTNER: ['10'],
  ROLE_MONITORING: ['13'],
}

export const ROLE_TYPE_ORGANIZATION = [
  {
    name: 'ERP',
    key: 'erp',
    type: 'ERP',
    permission: ''
  },
  {
    name: 'ManagementGroup',
    key: 'management-group',
    type: 'ManagementGroup',
    permission: ''
  },
  {
    name: 'Partner',
    key: 'management',
    type: 'Partner',
    permission: 'partnerId'
  },
  {
    name: 'Client',
    key: 'monitoring',
    type: 'Client',
    permission: 'clientId'
  },
]

export const ROLE_ERP_ORGANIZATION = [
  {
    name: 'ERP：契約企業',
    key: 'erp',
    type: 'ERP',
    permission: ''
  },
  {
    name: 'ManagementGroup',
    key: 'management-group',
    type: 'managementGroup',
    permission: ''
  },
]

export const validateVistDateFromTo = [
  'desiredDateAndTimeOfVisitTo5',
  'desiredDateAndTimeOfVisitFrom5',
  'desiredDateAndTimeOfVisitFrom4',
  'desiredDateAndTimeOfVisitTo4',
  'desiredDateAndTimeOfVisitFrom3',
  'desiredDateAndTimeOfVisitTo3',
  'desiredDateAndTimeOfVisitFrom2',
  'desiredDateAndTimeOfVisitTo2',
  'desiredVisitDateAndTime1',
  'desiredDateAndTimeOfVisit1',
];

export const validateVistDateUpdate = [
  'desiredDateAndTimeOfVisitTo5',
  'desiredDateAndTimeOfVisitFrom5',
  'desiredDateAndTimeOfVisitFrom4',
  'desiredDateAndTimeOfVisitTo4',
  'desiredDateAndTimeOfVisitFrom3',
  'desiredDateAndTimeOfVisitTo3',
  'desiredDateAndTimeOfVisitFrom2',
  'desiredDateAndTimeOfVisitTo2',
  'desiredVisitDateAndTime1',
  'desiredDateAndTimeOfVisit1',
  'desiredVisitDate5From',
  'desiredVisitDate4From',
  'desiredVisitDate3From',
  'desiredVisitDate2From',
  'desiredVisitDate1From',
];

export const validateVistDateUpdateTo = [
  'desiredDateAndTimeOfVisitTo5',
  'desiredDateAndTimeOfVisitTo4',
  'desiredDateAndTimeOfVisitTo3',
  'desiredDateAndTimeOfVisitTo2',
  'desiredDateAndTimeOfVisit1'
];

export const validateVistDate = [
  'desiredVisitDate5From',
  'desiredVisitDate4From',
  'desiredVisitDate3From',
  'desiredVisitDate2From',
  'desiredVisitDate1From'
];

export const notUpdateCSV =  [
  'visitDate',
  'visitDateTo',
  'visitDateFrom',
  'productId',
  'serviceName',
  'partnerId',
  'worker1Id',
  'worker2Id',
  'worker3Id',
  'productName',
  'projectName',
  'partnerName',
  'staffName',
  'orderId',
  'projectName',
  'clientName',
  'caseName',
  'managementCode',
]

export const notUpdateRecored =  [
  '訪問日',
  '終了予定',
  '開始予定',
  'productId',
  '工程',
  'パートナーID',
  '作業員1_ID',
  '作業員2_ID',
  '作業員3_ID',
  'productName',
  '案件名	',
  'partnerName',
  'staffName',
  'orderId',
  'projectName',
  'clientName',
  '訪問日時（開始時間帯_from) ',
  '訪問日時（開始時間帯_to）',
  '管理コード（半角入力)'
]

export const checkTypeDate =  [
  '訪問希望日①',
  '訪問希望日②',
  '訪問希望日③',
  '訪問希望日④',
  '訪問希望日⑤',
]

export const checkTypeDateTime =  [
  '希望訪問日時①（開始時間帯_from）',
  '希望訪問日時②（開始時間帯_from）',
  '希望訪問日時③（開始時間帯_from）',
  '希望訪問日時④（開始時間帯_from）',
  '希望訪問日時⑤（開始時間帯_from）',
]

export const checkVisitDateTo =  [
  '希望訪問日時①（開始時間帯_to）',
  '希望訪問日時②（開始時間帯_to）',
  '希望訪問日時③（開始時間帯_to）',
  '希望訪問日時④（開始時間帯_to）',
  '希望訪問日時⑤（開始時間帯_to）',
]

export const ROLE_ORGANIZATION_CHECK = {
  MONITORING: 'monitoring',
  ERP: 'erp',
  MANAGERMENT: 'management',
  MANAGERMENTGROUP: 'managementGroup',
}

export const ROLE_ORGANIZATION = [
  {
    name: 'Partner：協力企業',
    key: 'management',
    type: 'management',
    permission: 'partnerId',
    role:'partner'
  },
  {
    name: 'Client：発注企業',
    key: 'monitoring',
    type: 'monitoring',
    permission: 'clientId',
    role:'client'
  },
]

export const NEOMARS_USERS = [
  {
    name: 'ManagementGroup',
    color: '#00b050',
    btn_create: 'ManagementGroup作成',
    api: 'management-group'
  },
  {
    name: 'ERP：契約企業',
    color: '#002060',
    btn_create: 'ERP作成',
    api: 'erp'
  },
]

export const ERP_USERS = [
  {
    name: 'Worker',
    color: '#e36c09',
    btn_create: 'Worker作成',
    api: 'work'
  },
  {
    name: 'Partner：協力企業',
    color: '#92d050',
    btn_create: 'Management作成',
    api: 'management'
  },
  {
    name: 'Client：発注企業',
    color: '#00b0f0',
    btn_create: 'Monitoring作成',
    api: 'monitoring'
  },
  {
    name: 'ManagementGroup',
    color: '#00b050',
    btn_create: 'ManagementGroup作成',
    api: 'management-group'
  },
  {
    name: 'ERP：契約企業',
    color: '#002060',
    btn_create: 'ERP作成',
    api: 'erp'
  },
]

export const MANAGERGROUP_USERS = [
  {
    name: 'Worker',
    color: '#e36c09',
    btn_create: 'Worker作成',
    api: 'work'
  },
  {
    name: 'Partner：協力企業',
    color: '#92d050',
    btn_create: 'Management作成',
    api: 'management'
  },
  {
    name: 'Client：発注企業',
    color: '#00b0f0',
    btn_create: 'Monitoring作成',
    api: 'monitoring'
  },
  {
    name: 'ManagementGroup',
    color: '#00b050',
    btn_create: 'ManagementGroup作成',
    api: 'management-group'
  },
]

export const MANAGER_USERS = [
  {
    name: 'Worker',
    color: '#e36c09',
    btn_create: 'Worker作成',
    api: 'work'
  },
  {
    name: 'Partner：協力企業',
    color: '#92d050',
    btn_create: 'Management作成',
    api: 'management'
  },
]

export const MONITORING_USERS = [
  {
    name: 'Client：発注企業',
    color: '#e36c09',
    btn_create: 'Monitoring作成',
    api: 'monitoring'
  },
]

export const SCREEN_NAME = {
  RECEIVE_ALL: '全情報',
  RECEIVE_INFO: '受注情報',
  APPOINT_INFO: 'アポイント情報',
  CONDITION_INFO: '稼働調整情報',
  CANCEL_INFO: 'キャンセル情報',
  CANCEL_MENU: 'キャンセル依頼',
  SITE_INFO: '当日現場情報',
  PREPARATION_INFO: '稼働待ち情報',
  REPORT_UPLOAD_INFO: '報告書回収情報',
  DELIVERED_INFO: '報告書納品情報',
  RECEIVE_CONFIRM_LIST: '稼働調整',
  UNOPERATION_LIST: '稼働不可一覧',
  REPORT_LIST: '報告書未提出',
  HONKADO_LIST: '当日稼働状況',
  SPORT_ACCEPT: '依頼一覧',
  DELIVERED_CLIENT: '報告書納品一覧',
  TODAY: '本日',
  TOMORROW_INFO: '事前確認',
  PAYMENT_DATE: 'paymentDate',
  USER_MASTER : '利用者登録',
  CONTRACT_MANAGEMENT : '契約管理',
  MY_CORP : '基本情報',
  ORGANIZATION : '組織',
  CASE_MASTA : 'ワークフロー設定',
  ORDER_SETTINGS : 'Order設定',
  WORKER_SETTINGS : 'Worker設定',
  DISPATCH_SETTINGS : 'ディスパッチ',
  AGGREGATE_SUMMARY_SETTINGS : '集計サマリ設定',
  CSV_OUTPUT_SETTINGS : 'CSV出力設定',
  STAFF_MASTER: 'Worker利用者登録',
  BRANCH_MASTER: '拠点登録',
  ITEM_MANAGEMENT: '物品管理',
  STAFF_WORKING_SCHEDULE: '稼働予定',
  PREPARATION_INFO_PARTNER: '受注一覧',
  CHAT: 'チャット',
  CANCEL_MENU_CLIENT : 'キャンセル依頼',
  WORKING_SCHEDULE : 'スケジュール',
  WORKING_SCHEDULE_NEO : 'カレンダーマスタ',
  WORKER_DETAIL : '工事依頼詳細',
  DISPATCH_SETTINGS_ORDER : 'ディスパッチOrder',
};

export const time = [
  '00:00',
  '00:15',
  '00:30',
  '00:45',
  '01:00',
  '01:15',
  '01:30',
  '01:45',
  '02:00',
  '02:15',
  '02:30',
  '02:45',
  '03:00',
  '03:15',
  '03:30',
  '03:45',
  '04:00',
  '04:15',
  '04:30',
  '04:45',
  '05:00',
  '05:15',
  '05:30',
  '05:45',
  '06:00',
  '06:15',
  '06:30',
  '06:45',
  '07:00',
  '07:15',
  '07:30',
  '07:45',
  '08:00',
  '08:15',
  '08:30',
  '08:45',
  '09:00',
  '09:15',
  '09:30',
  '09:45',
  '10:00',
  '10:15',
  '10:30',
  '10:45',
  '11:00',
  '11:15',
  '11:30',
  '11:45',
  '12:00',
  '12:15',
  '12:30',
  '12:45',
  '13:00',
  '13:15',
  '13:30',
  '13:45',
  '14:00',
  '14:15',
  '14:30',
  '14:45',
  '15:00',
  '15:15',
  '15:30',
  '15:45',
  '16:00',
  '16:15',
  '16:30',
  '16:45',
  '17:00',
  '17:15',
  '17:30',
  '17:45',
  '18:00',
  '18:15',
  '18:30',
  '18:45',
  '19:00',
  '19:15',
  '19:30',
  '19:45',
  '20:00',
  '20:15',
  '20:30',
  '20:45',
  '21:00',
  '21:15',
  '21:30',
  '21:45',
  '22:00',
  '22:15',
  '22:30',
  '22:45',
  '23:00',
  '23:15',
  '23:30',
  '23:45'
];

export const PEOPLE = ['1', '2', '3', '4', '5', '6', '7', '8'];
export const LIST_COLOR = [
  { code: '#b0ca71', colorText: '#000000' },
  { code: '#88c70f', colorText: '#fff' },
  { code: '#bce2e8', colorText: '#000000' },
  { code: '#a0d8ef', colorText: '#000000' },
  { code: '#cca6bf', colorText: '#000000' },
  { code: '#f0908d', colorText: '#000000' },
  { code: '#b88884', colorText: '#000000' },
  { code: '#c38743', colorText: '#000000' },
  { code: '#bce2e8', colorText: '#000000' },
  { code: '#e49e61', colorText: '#000000' },
  { code: '#f8e58c', colorText: '#000000' },
  { code: '#ebd842', colorText: '#000000' },
  { code: '#f3f3f3', colorText: '#000000' },
  { code: '#9ea1a3', colorText: '#000000' },
]

export const COLORS = [
  { label: '#b0ca71', value: '#b0ca71' },
  { label: '#88c70f', value: '#88c70f' },
  { label: '#bce2e8', value: '#bce2e8' },
  { label: '#a0d8ef', value: '#a0d8ef' },
  { label: '#cca6bf', value: '#cca6bf' },
  { label: '#f0908d', value: '#f0908d' },
  { label: '#b88884', value: '#b88884' },
  { label: '#c38743', value: '#c38743' },
  { label: '#bce2e8', value: '#bce2e8' },
  { label: '#e49e61', value: '#e49e61' },
  { label: '#f8e58c', value: '#f8e58c' },
  { label: '#ebd842', value: '#ebd842' },
  { label: '#f3f3f3', value: '#f3f3f3' },
  { label: '#9ea1a3', value: '#9ea1a3' },
]

export const DEFAULT_OPTIONS = ['option1', 'option2', 'option3', 'option4', 'option5']
export const DEFAULT_PARAMS = [
  'serviceId', 'option1', 'option2', 'option3', 'option4', 'option5', 'workTime', 'people', 'labelSetting', 'productServiceId', 'sortNum',
]
// export const DEFAULT_ROLES = ['ERP', 'managementGroup', 'management', 'monitoring']
export const DEFAULT_ROLES = ['ERP', 'ManagementGroup', 'Partner', 'Client']

export const PERMITTED_PARAM_RECREATE = [
  'orderId', 'serviceId', 'contactPersonInCharge', 'clientStaffEmail', 'clientStaffTel', 'applicantCompanyName', 'managementCode',
  'phoneNumber1', 'visitedSiteName', 'personInChargeOfVisiting', 'phoneNumber01', 'mailNumber1', 'prefecturesVisited',
  'visitedBaseAddress', 'visitedBuildingName', 'scheduledInstallationLocation', 'visitDate', 'visitDateFrom',
  'personInChargeOfApplication', 'visitDateTo', 'usePartner', 'erpMattersToBeSent', 'preferredDate'
]

export const DATA_ORGANIZATION = [
  'nameKana',
  'name',
  'officialName',
  'officialNameKana',
  'postalCode',
  'prefecture',
  'address',
  'addressKana',
  'screenName',
  'abbreviationName',
  'abbreviation',
  'phoneNumber',
  'areas',
  'latitude',
  'longitude',
  'prefectureAreas',
  'getQualified',
  'updateTitle'
];

export const DATA_ORGANIZATION_PARTNER = [
  'nameKana',
  'name',
  'officialName',
  'officialNameKana',
  'postalCode',
  'prefecture',
  'address',
  'addressKana',
  'screenName',
  'abbreviationName',
  'abbreviation',
  'phoneNumber',
  'areas',
  'latitude',
  'longitude',
  'prefectureAreas',
  'pdfPassword',
  'getQualified',
  'updateTitle'
];

export const PERMITTED_PARAM_USER = [
  'firstNameKana', 'lastNameKana', 'firstName', 'lastName', 'phoneNumber', 'roles', 'organizationId', 'permissionId', 'screenName'
]

export const PERMITTED_UPDATE_USER = [
  'email', 'firstNameKana', 'lastNameKana', 'firstName', 'lastName', 'phoneNumber', 'roles', 'organizationId', 'permissionId', 'screenName', 'clientId',
  'partnerId', 'passwordExpirationDate', 'areas', 'movingSpeed', 'distanceRange', 'latitude', 'longitude', 'address', 'addressKana', 'postalCode',
  'prefectures', 'primaryRole', 'updateTitle'
]

export const PERMITTED_PARAM_USER_ERP = [
  'firstNameKana', 'lastNameKana', 'firstName', 'lastName', 'phoneNumber', 'roles', 'organizationId', 'screenName'
]

export const PERMITTED_PARAM_BEFORE_CONFIRM = [
  'admissionApplicationCompletionDate', 'admissionApplicationDeadline', 'roadsUePermitApplicationDeadline', 'statusMonitoring',
    'beforeConfirmationDeadline', 'statusWorker', 'priorConfirmationDate', 'beforeConfirmMemo', 'approvalRequestMemo', 'priorConfirmationDeadline', 'screenName',
    'visitDate', 'visitDateFrom', 'visitDateTo', 'callLog', 'beforeConfirmDocumentSetting' , 'staffId', 'companyName', 'updateTitle'
]

export const MONTH = [
  { month: '1' },
  { month: '2' },
  { month: '3' },
  { month: '4' },
  { month: '5' },
  { month: '6' },
  { month: '7' },
  { month: '8' },
  { month: '9' },
  { month: '10' },
  { month: '11' },
  { month: '12' }
];

export const TimeDispatch = [
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
  29,
  30,
  31,
  32,
  33,
  34
];

export const TimeDispatchAppoint = [
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23',
  '24',
  '25',
  '26',
  '27',
  '28',
  '29',
  '30',
  '31'
];

export const STATUS_GOODS = [
  'pending',
  'in_progress',
  'finish',
  'cancel'
];

export const CHECKED_MENU = [
  'clientCompany', 'applicationCompany', 'document', 'detailedInformation'
];

export const NAME_MASTER_SETTING = {
  PLUGIN_SETTING: 'plugin-setting',
  ADDRESS_JAPAN: 'address-japan',
  BODY_ORDER: 'body-order',
  HEADER_ORDER: 'header-order',
};

export const SAVE_STATUS = {
  PARTNER_CONFIRM: 'partner_confirmed',
  NULL: ''
}

export const statusPartnerContract = ['未契約', '契約対応中', '契約中', '契約終了', '契約停止'];

export const DATE_JP_RENDER = 'yyyy/MM/dd';
export const DATE_MONTH_YEAR = 'YYYY/MM';
export const DATE_JP_FORMAT = 'YYYY/MM/DD';
export const DATETIME_JP_FORMAT = 'YYYY/MM/DD HH:mm:ss';
export const DATETIME_EXPORT_JP_FORMAT = 'YYYYMMDD_HHmmss';

export const CREATE_RECRUIMENT_CONTRACT = ['2', '5', '8'];
export const CREATE_RECRUIMENT = ['2', '5', '11'];
export const AREAS = ['hokkaidoAndTohoku', 'kanto', 'koshinetsuAndHokuriku', 'tokai', 'kansai', 'china', 'shikoku', 'kyushuAndOkinawa'];
export const DEFAULT_WORKER_DETAIL = ['resultCancel', 'completionResult', 'settingCompletionResult', 'screenName', 'statusSiteInfo', 'updateTitle', 'companyName'];
export const DEFAULT_ADDRESS = [
  '北海道',
  '青森県',
  '岩手県',
  '宮城県',
  '秋田県',
  '山形県',
  '福島県',
  '東京都',
  '神奈川県',
  '埼玉県',
  '千葉県',
  '茨城県',
  '栃木県',
  '群馬県',
  '山梨県',
  '新潟県',
  '長野県',
  '石川県',
  '富山県',
  '福井県',
  '愛知県',
  '岐阜県',
  '静岡県',
  '三重県',
  '大阪府',
  '兵庫県',
  '京都府',
  '滋賀県',
  '奈良県',
  '和歌山県',
  '鳥取県',
  '島根県',
  '岡山県',
  '広島県',
  '山口県',
  '徳島県',
  '香川県',
  '愛媛県',
  '高知県',
  '福岡県',
  '佐賀県',
  '長崎県',
  '熊本県',
  '大分県',
  '宮崎県',
  '鹿児島県',
  '沖縄県',
];
export const DATA_POSITIVE_NUM = ['受注情報', 'アポイント情報', '稼働調整情報', '報告書回収情報', '稼働調整', '報告書未提出'];
export const DATA_NEGATIVE_NUM = ['稼働待ち情報', '受注一覧'];
export const DATA_POSITIVE_MIN = ['業務終了'];
export const DATA_NEGATIVE_MIN = ['業務開始'];
export const DEFAULT_POSITIVE_NUM = [
  {value: '+0', name: '+0'},
  {value: '+1', name: '+1'},
  {value: '+2', name: '+2'},
  {value: '+3', name: '+3'},
  {value: '+4', name: '+4'},
  {value: '+5', name: '+5'},
  {value: '+6', name: '+6'},
  {value: '+7', name: '+7'},
  {value: '+8', name: '+8'},
  {value: '+9', name: '+9'},
  {value: '+10', name: '+10'},
];
export const DEFAULT_POSITIVE_MINUTES = [
  {value: '+00:00', name: '+00:00'},
  {value: '+00:05', name: '+00:05'},
  {value: '+00:10', name: '+00:10'},
  {value: '+00:15', name: '+00:15'},
  {value: '+00:20', name: '+00:20'},
  {value: '+00:25', name: '+00:25'},
  {value: '+00:30', name: '+00:30'},
  {value: '+00:35', name: '+00:35'},
  {value: '+00:40', name: '+00:40'},
  {value: '+00:45', name: '+00:45'},
  {value: '+00:50', name: '+00:50'},
  {value: '+00:55', name: '+00:55'},
  {value: '+01:00', name: '+01:00'},
  {value: '+01:05', name: '+01:05'},
  {value: '+02:10', name: '+01:10'},
  {value: '+02:15', name: '+01:15'},
  {value: '+02:20', name: '+01:20'},
  {value: '+02:25', name: '+01:25'},
  {value: '+02:30', name: '+01:30'},
  {value: '+02:35', name: '+01:35'},
  {value: '+02:40', name: '+01:40'},
  {value: '+02:45', name: '+01:45'},
  {value: '+02:50', name: '+01:50'},
  {value: '+02:55', name: '+01:55'},
  {value: '+02:00', name: '+02:00'},
];
export const DEFAULT_NEGATIVE_NUM = [
  {value: '-0', name: '-0'},
  {value: '-1', name: '-1'},
  {value: '-2', name: '-2'},
  {value: '-3', name: '-3'},
  {value: '-4', name: '-4'},
  {value: '-5', name: '-5'},
  {value: '-6', name: '-6'},
  {value: '-7', name: '-7'},
  {value: '-8', name: '-8'},
  {value: '-9', name: '-9'},
  {value: '-10', name: '-10'},
];
export const DEFAULT_NEGATIVE_MINUTES = [
  {value: '-00:00', name: '-00:00'},
  {value: '-00:05', name: '-00:05'},
  {value: '-00:10', name: '-00:10'},
  {value: '-00:15', name: '-00:15'},
  {value: '-00:20', name: '-00:20'},
  {value: '-00:25', name: '-00:25'},
  {value: '-00:30', name: '-00:30'},
  {value: '-00:35', name: '-00:35'},
  {value: '-00:40', name: '-00:40'},
  {value: '-00:45', name: '-00:45'},
  {value: '-00:50', name: '-00:50'},
  {value: '-00:55', name: '-00:55'},
  {value: '-01:00', name: '-01:00'},
  {value: '-01:05', name: '-01:05'},
  {value: '-02:10', name: '-01:10'},
  {value: '-02:15', name: '-01:15'},
  {value: '-02:20', name: '-01:20'},
  {value: '-02:25', name: '-01:25'},
  {value: '-02:30', name: '-01:30'},
  {value: '-02:35', name: '-01:35'},
  {value: '-02:40', name: '-01:40'},
  {value: '-02:45', name: '-01:45'},
  {value: '-02:50', name: '-01:50'},
  {value: '-02:55', name: '-01:55'},
  {value: '-02:00', name: '-02:00'},
];

export const OPTION_DOCUMENT_SETTING = [
  { value: 'ERP：契約企業', label: 'ERP' },
  { value: 'Partner：協力企業', label: 'Partner' },
  { value: 'Client：発注企業', label: 'Client' }
];

export const OPTION_FROM_TO_ANKEN_MASTER = [
  {value: 'ERP：契約企業', label: 'ERP'},
  {value: 'Partner：協力企業', label: 'Partner'},
  {value: 'Client：発注企業', label: 'Client'},
]

export const OPTION_FROM_TO_ANKEN_MASTER_MANAGEMENTGROUP = [
  {value: 'ERP：契約企業', label: 'ManagementGroup'},
  {value: 'Partner：協力企業', label: 'Partner'},
  {value: 'Client：発注企業', label: 'Client'},
]

export const OPTION_DOCUMENT_SETTINGS = [
  { value: 1, label: 'From_ERP：ドキュメント設定' },
  { value: 2, label: 'From_ERP：受注情報・稼働待ち情報／To_Partner：稼働調整・受注一覧' },
  { value: 3, label: 'From_Partner：報告書未提出／To_ERP：報告書回収・報告書納品' },
  { value: 4, label: 'From_ERP：報告書回収／To_Client：報告書一覧・ERP報告書納品' },
  { value: 5, label: 'From_ERP_Partner: 事前確認/To_ERP_Partner: 事前確認' },
  { value: 6, label: 'From_Client: 依頼一覧/To_ERP: 受注情報・稼働待ち情報' },
];

export const SECURITY_OPTION =
  {
    0: '高：8文字以上（数字、英語大文字、英語小文字、記号の組合せ）',
    1: '中：8文字以上（数字、英語大文字、英語小文字の組合せ）',
    2: '低：6文字以上（数字、英語大文字、英語小文字の組合せ）',
    '0': '高：8文字以上（数字、英語大文字、英語小文字、記号の組合せ）',
    '1': '中：8文字以上（数字、英語大文字、英語小文字の組合せ）',
    '2': '低：6文字以上（数字、英語大文字、英語小文字の組合せ）',
  };

export const EXPORT_CSV_MASTER = [
  { name: 'ERP：契約企業', label: 'center' },
  { name: 'Partner：協力企業', label: 'partner' },
  { name: 'Client：発注企業', label: 'client' }
];

export const OPTION_SCREEN_ANKEN_MASTER = [
  'メニュー画面', '受注情報・稼働待ち情報・依頼一覧', '事前確認', '報告書未提出', '報告書回収'
];

export const POPUP_ADD_PARTNER = [
  '/detail-receive-confirm', '/detail-honkado-list', '/detail-preparation-info', '/detail-site-info', '/detail-preparation-info-partner'
];

export const PART_NAME_DETAIL_ADD_STAFF = [
  '/detail-preparation-info', '/detail-site-info', '/detail-preparation-info-partner', '/detail-honkado-list'
];

export const STATUS_CONFIRM_PARTNER = {
  confirmedJP: '稼働可',
  confirmedEng: 'confirmed',
  waitingJP: '打診中',
  waitingEng: 'waiting',
  rejectJP: '稼働不可',
  rejectEng: 'reject',
  checked: '待機',
  empty: '未打診',
  emptyEL: 'empty',
  secure: '確保',
  sendRequest: '確定待ち',
};

export const STATUS_ORDER_CONDITION = {
  WAITING: 'waiting',
  CONFIRMED: 'confirmed',
  REJECT: 'reject',
  EMPTY: 'empty',
};

export const STATUS_CONDITION_CSV = {
  unConsulted: '未打診',
  waitingForOperationReply: '稼働回答待ち',
  operable: '稼働可',
  notOperational: '稼働不可',
  securingOperation: '稼働確保',
};

export const NAME_ROLE = {
  monitoring: 'Client',
  rep: 'ERP',
  management: 'Partner',
  managementGroup: 'ManagementGroup',
};

export const PERMITTED_PARAM_USER_MONITORING = [
  'email', 'passwordExpirationDate', 'firstName', 'lastName', 'firstNameKana', 'lastNameKana', 'phoneNumber', 'roles', 'permissionId', 'screenName', 'organizationId', 'primaryRole', 'updateTitle'
];

export const PERMITTED_PARAM_USER_MONITORING_CREATE = [
  'passwordExpirationDate', 'firstName', 'lastName', 'firstNameKana', 'lastNameKana', 'phoneNumber', 'roles',
  'permissionId', 'screenName', 'organizationId', 'loginId', 'loginPassword', 'email', 'clientId','primaryRole'
];

export const PARAMS_REQUIRED_STAFF = [
  'surNameKana', 'nameKana', 'surName', 'name', 'address', 'latitude', 'longitude', 'passwordExpirationDate', 'loginPassword'
];

export const PARAMS_REQUIRED_USER = [
  'firstNameKana', 'firstName', 'lastNameKana', 'lastName', 'email', 'loginPassword', 'roles', 'passwordExpirationDate', 'address', 'latitude', 'longitude', 'primaryRole'
];

export const PARAMS_REQUIRED_ORRGANIZATION = [
  'name', 'nameKana', 'typeRole', 'officialName', 'officialNameKana', 'address', 'latitude', 'longitude', 'abbreviationName', 'abbreviation'
];

export const PARAMS_PERMITTED_ORRGANIZATION = [
  'companyName', 'organizationName', 'addressKana', 'areas', 'phoneNumber', 'postalCode', 'prefecture', 'screenName', 'screenNamePermission', 'type', 'name', 'nameKana', 'officialName', 'officialNameKana', 'address', 'latitude', 'longitude', 'abbreviationName', 'abbreviation', 'prefectureAreas', 'updateTitle', 'isAllAnkenJoin'
];

export const PARAMS_REQUIRED_BRANCH = [
  'branchName', 'branchNameKana', 'branchAbbreviation', 'branchAbbreviationJP', 'longitude', 'latitude', 'residence', 'representativeName', 'representativeNameKana'
];

export const PERMITTED_SECURITY = [
  'accountValidityPeriod', 'howToLogIn', 'passwordGenerationManagement', 'passwordSettingValue', 'screenName', 'securityBatchSetting', 'timeOut', 'pdfPassword'
];

export const PERMITTED_SECURITY_CREATE = [
  'accountValidityPeriod', 'howToLogIn', 'passwordGenerationManagement', 'passwordSettingValue', 'organizationId', 'screenName', 'securityBatchSetting', 'timeOut', 'pdfPassword'
];

export const PARAMS_REQUIRED_ORGANIZATION = [
  'code', 'organizationName', 'companyName', 'name', 'nameKana', 'typeRole', 'officialName', 'officialNameKana', 'address', 'latitude', 'longitude', 'abbreviationName', 'abbreviation'
];

export const PERMISSTED_PARAMS = [
  'messageForPartnerCommunication', 'callStatus', 'newCallDate', 'visitDate', 'visitDateFrom', 'visitDateTo', 'visitDateTemp', 'visitDateFromTemp', 'visitDateToTemp',
  'contactPersonInChargeStatus', 'personInChargeOfApplicationStatus', 'personInChargeAppoint', 'statusAppointInfo',
  'installationSiteName', 'confirm1',
  'confirm2', 'confirm3', 'confirm4', 'newCallDateTo', 'newCallDateFrom', 'preferredDate'
];

export const REGEX_PASSWORD = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])(?=.*[@$!%*^#?&]).{8,30}$/;
export const ERROR_PASSWORD = 'パスワードには、少なくても8桁（1つの小文字、1つの大文字、1つの数字、および特殊文字）が含まれる必要です。';
export const DAY_WEEK = { 1: '月', 2: '火', 3: '水', 4: '木', 5: '金', 6: '土', 0: '日' };

export const OPTION_IMG_REPORT = {
  REMOVE: 'remove',
  NOT_ALLOWED: 'not_allowed',
};

export const urlLogin = {
  'LCL': 'http://localhost:3000',
  'DEV': 'https://d2i4drg508spiw.cloudfront.net',
  'STG': 'https://stg-neo.stella-cloud.com',
  'PRO': 'https://neo.stella-cloud.com',
}
