import React from 'react';
import { Modal } from 'reactstrap';
import 'assets/scss/page/popupConfirmCustom/style.scss';

const PopupConfirmCustom = ({
  headerTitle,
  rederBody,
  okText,
  cancelText,
  onConfirm,
  onCancel,
  showPopup=true,
  onToggle = () => {}
}) => {

  return (
    <Modal isOpen={showPopup} centered toggle={onToggle}>
      <div className={`popup-confirm-custom ${showPopup && 'popup-confirm-custom--show'}`}>
        <header> {headerTitle} </header>
        <div className="popup-confirm-custom__body">
          {rederBody()}
          <div className="popup-confirm-custom__body__action">
            <button onClick={onConfirm}> {okText} </button>
            <button onClick={onCancel}> {cancelText}</button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default PopupConfirmCustom
