import React, { useState, useEffect } from 'react';
import { Collapse } from 'reactstrap';
import 'react-datepicker/dist/react-datepicker.css';
import './style.scss';
import { ROLE } from '../../helpers/constants';
import CollapseCenter from './CollapseCenter';
import CollapseClient from './CollapseClient';
import CollapsePartner from './CollapsePartner';
import CollapseExample from './CollapseExample';
import { ANKEN_MASTER_NEW } from '../../constants/endpoints';


const SearchMenu = ({ role = ROLE.PARTNER, color, getList, nameRoute, colorText }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [checkSearch,setCheckSearch] = useState('')
  const toggle = () => {
    setIsOpen(!isOpen)
    setCheckSearch(true);
  };
  const toggleHide = () => setHideshow2(!hideshow2);
  const [hideshow2, setHideshow2] = useState(false);
  const [dataService,setDataService] = useState('')

  const getAllSerivice = async ({ valueSearch = '' }) => {
    try {
      const data = await ANKEN_MASTER_NEW.GET_ALL_SERVICE_BY_ORGANIZATION({name: ''});
      if (data) {
        setDataService(data ?.data);
      }
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    getAllSerivice({})
  },[])

  return (
    <div className="search-menu-wrapper">
      <div className="wrapper-btn-colappse">
        <div onClick={toggle} className="mb-1 searchbar d-flex justify-content-between align-items-center">
          <div></div>
          <div style={{ color: `${colorText}`}}>
            <i className="icon-search"/>
            検索条件を表示
          </div>
          <div className="mr-2" style={{ color: `${colorText}`}}>
            {isOpen ? <i className="icon-up-open icon-searchbar"/> : <i className="icon-down-open icon-searchbar"/>}
          </div>
        </div>
      </div>
      {role && role === ROLE.CENTER && <CollapseCenter dataService={dataService} checkSearch={checkSearch} nameRoute={nameRoute} getList={getList} isOpen={isOpen} colorText={colorText} color={color}/>}
      {role && role === ROLE.CLIENT && <CollapseClient dataService={dataService} checkSearch = {checkSearch} getList={getList} isOpen={isOpen} color={color} colorText={colorText} nameRoute={nameRoute}/>}
      {role && role === ROLE.PARTNER && <CollapsePartner dataService={dataService} checkSearch = {checkSearch} getList={getList} isOpen={isOpen} color={color} colorText={colorText} nameRoute={nameRoute}/>}
      {role && role === ROLE.PARTNERGROUP && <CollapseCenter dataService={dataService} getList={getList} checkSearch = {checkSearch} isOpen={isOpen} color={color} colorText={colorText} nameRoute={nameRoute}/>}
      {role && role === 'EXAMPLE'&& <CollapseExample  isOpen={isOpen} color={color}/>}
    </div>
  );
};

export default SearchMenu;
