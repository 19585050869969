import React, { useState, useEffect } from 'react';
import { Collapse } from 'reactstrap';
import ButtonDetail from '../../ButtonDetail';
import { genId } from 'helpers/helpers';
import { ORDER } from 'constants/endpoints';
import { pushToast } from '../../Toast';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { getDataHistory } from 'actions/authentication';

const DetailResult = ({ orderId , screenName = '' }) => {
  let organizationId = process.browser && new URL(window.location.href).searchParams.get('organizationId') || '';
  const dispatch = useDispatch();
  const [isOpen2, setIsOpen2] = useState(true);
  const [acceptanceInforData, setAcceptanceInforData] = useState({listData: []});

  const onClickShow2 = () => {
    setIsOpen2(!isOpen2);
  };

  useEffect(() => {
    if (orderId) {
      getAcceptanceDetail({ orderId })
    }
  }, [orderId]);

  const getAcceptanceDetail = async({ orderId }) => {
    try {
      const res = await ORDER.GET_DETAIL_ACCEPTANCE({ orderId });
      if (res?.code === 0) {
        setAcceptanceInforData(_.isEmpty(res.data) ? {listData: []} : res.data);
      }
    } catch (error) {
      console.log(error);
      pushToast('error', error.message);
    }
  }

  const addListItem = () => {
    setAcceptanceInforData({
      ...acceptanceInforData,
      listData: [
        ...acceptanceInforData.listData,
        {
          id: genId(),
          actuarialProject: '',
          paymentQuantity: '',
          paymentActualCost: '',
          acceptanceQuantity: '',
          acceptanceActualCost: '',
          comment: ''
        }
      ]
    });
  };

  const deleteListItem = (item) => {
    setAcceptanceInforData({
      ...acceptanceInforData,
      listData: acceptanceInforData.listData.filter((elm) => elm.id !== item.id)
    });
  };

  const onSaveAcceptance = async () => {
    try {
      const res = await ORDER.SAVE_ACCEPTANCE_INFOR({ ...acceptanceInforData, screenName: screenName, updateTitle: '承認', orderId });
      if (res?.code === 0) {
        setAcceptanceInforData(res.data)
        pushToast('success', 'Save acceptance successfully')
      } else {
        pushToast('error', res?.message?.extra)
        dispatch(getDataHistory({ orderId, screenName, organizationId }));
      }
    } catch (error) {
      console.log(error);
      pushToast('error', error.message)
    }
  }

  const handleChangeAcceptanceValueItem = ({ e, item, index, name, value }) => {
    const fieldName = e && e.target.name || name
    item[fieldName] = e && e.target.value || value
    const listData = acceptanceInforData?.listData
    listData[index] = item
    setAcceptanceInforData({ ...acceptanceInforData, listData: listData })
  };

  return (
    <div>
      <div className="detail-erp-table-wrapper"　style={{display : 'none' }}>
        <div className="title-absolute" onClick={onClickShow2}>
          検収情報
          {isOpen2 ? (
            <i className="fas fa-arrow-circle-up ml-2"></i>
          ) : (
            <i className="fas fa-arrow-circle-down ml-2"></i>
          )}
        </div>
        <Collapse isOpen={isOpen2}>
          <div className="detail__managerment">
            <table className="detail__table detail__table--layout">
              <tbody>
                <tr>
                  <th rowSpan="2" style={{ width: '3%', background: 'white', border: 'none' }}></th>
                  <th className="detail__table--color table--w15" rowSpan="2">
                    精算項目
                  </th>
                  <th className="detail__table--color table--w30" colSpan="2">
                    支払
                  </th>
                  <th className="detail__table--color table--w30" colSpan="2">
                    検収
                  </th>
                  <th className="detail__table--color table--w15" rowSpan="2">
                    コメント
                  </th>
                </tr>
                <tr>
                  <th className="detail__table--color table--w15">数量</th>
                  <th className="detail__table--color table--w15">実費</th>
                  <th className="detail__table--color table--w15">数量</th>
                  <th className="detail__table--color table--w15">実費</th>
                </tr>
                {acceptanceInforData && acceptanceInforData?.listData?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td className="detail__table--color text-center table--w3">
                        <div className="icon" onClick={() => deleteListItem(item)}>
                          <i className="fas fa-times"></i>
                        </div>
                      </td>
                      <td className="detail__table--color table--w15">
                        <input className="w-100" value={item?.actuarialProject}
                          name='actuarialProject'
                          onChange={(e) => handleChangeAcceptanceValueItem({e, item, index})}
                        />
                      </td>
                      <td className="detail__table--color table--w15">
                        <input className="w-100" value={item?.paymentQuantity}
                          name='paymentQuantity'
                          onChange={(e) => handleChangeAcceptanceValueItem({e, item, index})}
                        />
                      </td>
                      <td className="detail__table--color table--w15">
                        <input className="w-100" value={item?.acceptanceActualCost}
                          name='acceptanceActualCost'
                          onChange={(e) => handleChangeAcceptanceValueItem({e, item, index})}
                        />
                      </td>
                      <td className="detail__table--color table--w15">
                        <input className="w-100" value={item?.acceptanceQuantity}
                          name='acceptanceQuantity'
                          onChange={(e) => handleChangeAcceptanceValueItem({e, item, index})}
                        />
                      </td>
                      <td className="detail__table--color table--w15">
                        <input
                          className="w-100" value={item?.paymentActualCost}
                          name='paymentActualCost'
                          onChange={(e) => handleChangeAcceptanceValueItem({e, item, index})}
                        />
                      </td>
                      <td className="detail__table--color table--w15">
                        <input
                          className="w-100"
                          value={item?.comment}
                          name='comment'
                          onChange={(e) => handleChangeAcceptanceValueItem({e, item, index})}
                        />
                      </td>
                    </tr>
                  );
                })}
                <tr>
                  <td className="detail__table--color text-center table--w3">
                    <div
                      className="icon"
                      // style={{ borderColor: `${color}`, color: `${color}` }}
                      onClick={() => addListItem()}
                    >
                      <i className="fas fa-plus"></i>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="detail-data-wrapper d-flex justify-content-center">
              <div>
                <ButtonDetail
                  text="承認"
                  onUpdateOrder={onSaveAcceptance}
                />
              </div>
              <div className="ml-2">
                <ButtonDetail
                  text="差戻"
                  onUpdateOrder={() => {}}
                />
              </div>
            </div>
          </div>
        </Collapse>
      </div>
    </div>
  );
};

export default DetailResult;
