import React from 'react';
const renderItem = (item, index) => {
  return (
    <tr key={index}>
      <td>{item?.writer}</td>
      <td>{item?.currentTime}</td>
      <td>{item?.statusLog}</td>
    </tr>
  );
};
const TableUpdateStaff = ({ dataLogStaff }) => {
  return (
    <div className="mt-4">
      <div align="center">
        <p>作業員マスター更新履歴</p>
      </div>
      <table width="100%" border="0" align="center" cellPadding="1" cellSpacing="1" className="discribeTable">
        <thead>
          <tr>
            <th scope="col" width="30%" style={{ fontSize: 11 }} className="green">
              更新者
            </th>
            <th scope="col" width="40%" style={{ fontSize: 11 }} className="green">
              更新日
            </th>
            <th scope="col" width="30%" style={{ fontSize: 11 }} className="green">
              更新区分
            </th>
          </tr>
        </thead>
        <tbody>{dataLogStaff?.length > 0 && dataLogStaff.map((listData, index) => renderItem(listData, index))}</tbody>
      </table>
    </div>
  );
};

export default TableUpdateStaff;
