import React, { useState, useEffect } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { translate } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import ModalAddRole from '../ModalAddRole';
import ModalAddRoleNext from '../ModalAddRoleNext';
import ModalDetailSetting from '../ModalDetailSetting';
import { getRole, getRoleNew } from 'helpers/helpers';
import './style.scss';
import '../../../../assets/scss/page/center/MasterSetting/style.scss';
import { MASTER_SETTING } from '../../../../constants/endpoints';
import { pushToast } from '../../../../components/Toast';
import _ from 'lodash';
import { SCREEN_NAME, ROLE } from '../../../../helpers/constants';
import { getDataHistoryMasterSetting } from 'actions/authentication';
import { useDispatch } from 'react-redux';

const ModuleManage = ({idRole, idProvide, colorId, moduleManager = {}, statusBeforeConfirm}) => {
  const dispatch = useDispatch();
  const [modalAdd, setModalAdd] = useState(false);
  const [modalAddNext, setModalAddNext] = useState(false);
  const [modalDetailSetting, setModalDetailSetting] = useState(false);
  const [routeList, setRouteList] = useState(null);
  const [arrRole, setArrRole] = useState([]);
  const [nameRole, setNameRole] = useState('');
  const [nameScreen, setNameScreen] = useState('');
  const [namePermission, setNamePermission] = useState('');
  const [dataInfo, setDataInfo] = useState([]);

  const toggleAdd = (routeRole) => {
    const roleName = getRole({ idRole: routeRole })?.toLowerCase();
    setNamePermission(roleName);
    setModalAdd(!modalAdd);
    setRouteList(routeRole);
  };

  const updateModuleManager = async () => {
    if(idRole !== '1') return false;
    try {
      const data = await MASTER_SETTING.UPDATE_MODULE_MANAGER({
        body: {...moduleManager , screenName : SCREEN_NAME.MY_CORP , updateTitle : '保存　メニュー表示設定'},
        colorId: colorId,
        idProvide },);
      if (data?.data) {
        pushToast('success', 'Update Setting Menu Success');
        dispatch(getDataHistoryMasterSetting({ logId: '', screenName: SCREEN_NAME.MY_CORP, idProvide }));
      } else {
        pushToast('error', data?.message?.extra || data?.message?.errorMessage);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const toggleAddNext = (arrRole, name) => {
    setArrRole(arrRole);
    setNameRole(name);
    setModalAdd(false);
    setModalAddNext(!modalAddNext);
    setNameScreen(name);
  };

  const toggleDetailSetting = (arrRole, name) => {
    setModalAdd(false);
    setModalDetailSetting(!modalDetailSetting);
    setArrRole(arrRole);
    setNameScreen(name);
  }

  const changeRadio = (e, roleName) => {
    if(idRole !== '1') return false;
    let value = e.target.value;
    moduleManager[roleName] = value === 'true';
    const dataModule = dataInfo.map (item => {
      if(item.roleName === roleName){
        return { ...item, status: value === 'true'}
      }else {
        return item;
      }
    });
    setDataInfo(dataModule)
  }

  const fetchData = [
    {
      name: 'ERP：契約企業',
      label1: '表示',
      label2: '非表示',
      btn: '表示設定',
      role: '2',
      roleName: 'center',
      status: true
    },
    {
      name: 'Partner：協力企業',
      label1: '表示',
      label2: '非表示',
      btn: '表示設定',
      role: '8',
      roleName: 'partner',
      status: true
    },
    {
      name: 'Client：発注企業',
      label1: '表示',
      label2: '非表示',
      btn: '表示設定',
      role: '11',
      roleName: 'client',
      status: true
    },
  ];

  useEffect(() => {
    if(!_.isEmpty(moduleManager)) {
      const isPartnerGroup = getRoleNew({ idRole: localStorage.getItem('role') }) === ROLE.PARTNERGROUP
      const dataModule = fetchData.map (item => {
        return { ...item, name: item.roleName === 'center' && isPartnerGroup ? 'ManagementGroup' : item.name ,status: moduleManager[item.roleName]}
      });
      setDataInfo(dataModule)
    }
  }, [moduleManager]);

  const renderTableInfo = () => {
    return dataInfo && dataInfo.map((item, index) => (
      <div className="item-table" key={index}>
        <div className="title">{item.name}</div>
        <div className="content">
          <div className="form-customize">
            <div>
              <input type="radio" value={true} name={item.name} checked={item.status} onChange={(e) => changeRadio(e, item.roleName)} readOnly={idRole !== '1'} disabled={idRole !== '1'} />
              <label>{item.label1}</label>
            </div>
            <div>
              <input type="radio" value={false} name={item.name} checked={!item.status} onChange={(e) => changeRadio(e, item.roleName)} readOnly={idRole !== '1'} disabled={idRole !== '1'} />
              <label>{item.label2}</label>
            </div>
            <button className="btn-customize btn-customize--w200" onClick={() => toggleAdd(item.role)}>
              {item.btn}
            </button>
          </div>
        </div>
      </div>
    ));
  };
  return (
    <div className="table-info-company">
      {renderTableInfo()}

      <button className="btn-submit" onClick={() => updateModuleManager()}>保存</button>

      <ModalAddRole
        routeList={routeList} modal={modalAdd} toggle={toggleAdd} statusBeforeConfirm={statusBeforeConfirm}
        ModalAddRoleNext={toggleAddNext} namePermission={namePermission}
        ModalDetailSetting={toggleDetailSetting} idProvide={idProvide} />
      <ModalAddRoleNext arrRole={arrRole} nameScreen={nameScreen} modalNext={modalAddNext} toggle={toggleAddNext} routeList={routeList} idProvide={idProvide} onBack={toggleAdd}/>
      <ModalDetailSetting arrRole={arrRole} nameScreen={nameScreen} modalNext={modalDetailSetting} toggle={toggleDetailSetting} routeList={routeList} idProvide={idProvide} onBack={toggleAdd}/>
    </div>
  );
};

export default compose(translate('translations'), withRouter)(ModuleManage);
