import React, { useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import 'assets/scss/page/AnkenMasterNew/ModalCreateService.scss';
import TableModalAlerts from './TableModalAlerts';

const ModalAlertSettings = (props) => {
  const { toggle = () => { }, modal = false, serviceAlert = null, productAlert = null, idRole = '', nameTitle } = props;
  const [statusGetList, setStatusGetList] = useState(false);

  return (
    <div>
      <Modal className="modal-dialog-custom" isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>アラート設定</ModalHeader>
        <ModalBody>
          <div className="form-body-service">
            <TableModalAlerts
              statusGetList={statusGetList}
              setStatusGetList={() =>
                setStatusGetList(false)
              }
              serviceAlert={serviceAlert}
              productAlert={productAlert}
              idRole={idRole}
              nameTitle={nameTitle}
            />
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ModalAlertSettings;
