import React, { useState } from 'react';
import { Collapse } from 'reactstrap';
import MemoTableMasterSetting from '../MemoTableMasterSetting';

const MemoHistory = ({ colors, colorText , screenName , logId , visitDate, idProvide = '' }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  return (
    <div className="setting-master-menu-wrapper">
      <div className="wrapper-btn-colappse">
        <div
          onClick={toggle}
          className="mb-1 searchbar d-flex justify-content-between align-items-center"
          style={{ background: `${colors}` }}
        >
          <div className="ml-2" style={{ color: `${colorText}` }}>
            操作履歴
          </div>
          <div className="mr-2" style={{ color: `${colorText}` }}>
            {isOpen ? <i className="icon-up-open icon-searchbar" /> : <i className="icon-down-open icon-searchbar" />}
          </div>
        </div>
      </div>
      <div>
        <Collapse isOpen={isOpen}>
          <div className="content-view-modal pd-0">
            <MemoTableMasterSetting screenName={screenName} logId={logId} visitDate={visitDate} idProvide={idProvide}/>
          </div>
        </Collapse>
      </div>
    </div>
  );
};

export default MemoHistory;
