import React, { useEffect, useState } from 'react';
import { getNoOrder } from '../../../../helpers/helpers';
import { Link } from 'react-router-dom';

const TableListOrderGroup = ({
  sortOrderBy,
  data = [],
  t,
  role,
  p,
  color,
  roleDetail,
  partnerId,
  partnerBranch,
  colorText,
  detailCheck,
  getListPreparationInfo,
  idProduct,
  activePageTable,
  activePageSearch,
  statusShowData,
  idRole
}) => {
  const [sortOrder, setSortOrder] = useState([]);
  useEffect(() => {
    setSortOrder(data?.body);
  }, [data]);

  const sortBy = (item, sortWith = true) => {
    sortWith = sortWith ? 'asc' : 'desc';
    sortOrderBy({ sortBy: item, sortWith, sortProductId: data.productId });
  };

  const renderItem = (
    item,
    detailCheck,
    index,
    data,
    role,
    p,
    // link,
    partnerId,
    partnerBranch,
    getListPreparationInfo,
    idProduct,
    activePageTable,
    activePageSearch,
    statusShowData
  ) => {
    const colorStatus = (status) => {
      switch (status) {
        case '受注処理期限切れ':
        case 'アポイント期限切れ':
        case '稼働調整期限切れ':
        case '作業員登録期限切れ':
        case '報告書回収期限切れ':
        case '入館連絡確認':
        case '稼働回答期限切れ':
        case '報告書提出期限切れ':
          return { background: '#ff0000', color: 'white', width: 'unset', animation: 'status-pulse-red 1s infinite' };
        case '入館待ち':
          return { background: '#92d050', color: 'black', width: 'unset', animation: 'status-pulse-green 1s infinite' };
        case '入館中':
          return { background: '#00b0f0', color: 'black', width: 'unset', animation: 'status-pulse-blue 1s infinite' };
        case '作業連絡確認':
          return {
            background: '#0070c0',
            color: 'white',
            width: 'unset',
            animation: 'status-pulse-blue-d 1s infinite',
          };
        case '作業終了':
          return { background: '#7f7f7f', color: 'white', width: 'unset', animation: 'status-pulse-gray 1s infinite' };
        case '当日CAN':
          return {
            background: '#ffff00',
            color: 'black',
            width: 'unset',
            animation: 'status-pulse-yellow 1s infinite',
          };
        default:
          return { border: 'none' };
      }
    };

    const newList = item?.partners?.filter((elm) => elm.partnerId === partnerId)?.[0];
    let partnerExtFeedback = true;
    if(item?.partnerExtFeedback){
       Object.keys(item?.partnerExtFeedback).map(function(key, index) {
        if(item?.partnerExtFeedback[key] == newList?.partnerId){
          partnerExtFeedback = false;
        }
      });
    }
    return (
      <tr key={index}>
        <td>{getNoOrder({ index, limit: 10, activePage: activePageTable })}</td>
        {data.header.map((element, key) => {
          return (
            <td key={key}>
              {element === 'visitDate'
                ? `${item?.visitDate ? item?.visitDate  : ''} ${item?.visitDateFrom ? `${item?.visitDateFrom} ~ ${item?.visitDateTo} ` : ''}
                  `
                : element === 'workerName' && Array.isArray(item[element])
                ? item[element].join(' ,').slice(0, 20)
                : item[element]}
            </td>
          );
        })}
        {statusShowData && (
          <>
            <td className="table--nowrap">
              <div className="status_process" style={colorStatus(item?.statusAlert)}>
                {item?.statusAlert}
              </div>
            </td>
            <td>
              {newList?.statusOrderCondition === 'waiting' || ( +idRole === 7 && partnerExtFeedback) ? (
                <Link to={`/detail-receive-confirm?orderId=${item?.orderId}&organizationId=${item.organizationId}&partnerId=${newList?.partnerId}`}>
                  <button className="link_button d-flex">
                    <i className="icon-export" />
                    閲覧
                  </button>
                </Link>
              ) : (
                <div>稼働可</div>
              )}
            </td>{' '}
          </>
        )}
      </tr>
    );
  };
  return (
    <div className="table-list-wrapper table-responsive">
      <table className="table table-striped">
        <thead>
          <tr style={{ backgroundColor: `${color}` }}>
            <th scope="col" style={{ color: `${colorText}` }}>
              No
            </th>
            {data?.header?.map((item, index) => (
              <th scope="col" key={index} style={{ color: `${colorText}` }}>
                {t(`table_order_list_partner.${item}`)}
                <br />
                <a onClick={() => sortBy(item, true)}>
                  ▲
                </a>
                <a onClick={() => sortBy(item, false)}>
                  ▼
                </a>
              </th>
            ))}
            {statusShowData && (
              <>
                <th scope="col" style={{ color: `${colorText}` }}>
                  状態
                </th>
                <th>操作</th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {sortOrder &&
            sortOrder?.map((listData, index) =>
              renderItem(
                listData,
                detailCheck,
                index,
                data,
                role,
                p,
                // getLinkDetail(p),
                partnerId,
                partnerBranch,
                getListPreparationInfo,
                idProduct,
                activePageTable,
                activePageSearch,
                statusShowData
              )
            )}
        </tbody>
      </table>
    </div>
  );
};

export default TableListOrderGroup;
