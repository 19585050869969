import React, { useState, useEffect } from 'react';
import { Collapse } from 'reactstrap';
import ModalDetailSettingOrder from 'containers/page/MasterSetting/ModalDetailSettingOrder';
import _ from 'lodash';
import ButtonRequest from '../ButtonRequest';
import { ORDER_PARTNER } from 'constants/endpoints';
import Loading from 'components/Loading/Loading';

const BeforeConfirmInfo = ({ data = {}, changeData, requestConfirm, screenName, isActive = false, isRole = 'erp', role }) => {
  const [isOpen, setIsOpen] = useState(isActive === '1');
  const [dataDetail, setDataDetail] = useState({});
  const [isModal, setIsModal] = useState(false);
  const [pluginSettingId, setPluginSettingId] = useState(null);
  const [nameSetting, setNameSetting] = useState(null);
  const [modal, setModal] = useState(false);
  const [listWorkerMenu, setListWorkerMenu] = useState([]);
  const [loading, setLoading] = useState(false);

  const options = [
    {
      label: '対応待ち',
      value: 'workerUnallocated',
    },
    {
      label: '対応中',
      value: 'workersAreBeingAssigned',
    },
    {
      label: '対応完了',
      value: 'preConfirmationCompleted',
    },
  ];

  useEffect(() => {
    setDataDetail(data);
  }, [data]);

  const onClickShow = () => {
    setIsOpen(!isOpen);
  };

  const changeVal = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    changeData({ name, value });
  };

  const toggleModal = (e, item) => {
    setPluginSettingId(item?.pluginSettingId);
    setNameSetting(item?.name);
    setModal(!modal);
    setIsModal(true);
  };

  const confirmStatus = (key, status) => {
    if (status) {
      requestConfirm({
        orderId: dataDetail?.orderId,
        body: { screenName, statusBeforeConfirm: status, namePlugin: key },
      });
    }
  };

  const calcLength = (calc) => {
    return calc < 5 ? 5 : calc;
  };

  useEffect(() => {
    if (data?.orderId) {
      getWorkerMenu({ orderId: dataDetail?.orderId, organizationId: dataDetail?.organizationId });
    }
  }, [dataDetail?.orderId]);

  const getWorkerMenu = async ({ orderId = '', organizationId = '' }) => {
    setLoading(true);
    try {
      const data = await ORDER_PARTNER.GET_WORKER_MENU({
        orderId,
        organizationId: organizationId || '',
        menuName: 'beforeConfirm',
      });
      if (!_.isEmpty(data?.data)) {
        let sortNum = 1;
         let dataCheckColor = data?.data.map((e) => {
            if (sortNum === e.sortNum && !e.isConfirm) {
                e.isConfirm = false;
            }
            if (e.sortNum === e.sortNum && e.isConfirm) {
                sortNum++;
            }
            return e;
        });
        setListWorkerMenu(dataCheckColor);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  return (
    <div className="wrapper-comfirm-status">
      <Loading loading={loading} />
      <div className="content__wrapper">
        <div className="title-absolute cursor-pointer" onClick={onClickShow}>
          作業員対応状況{' '}
          {isOpen ? <i className="fas fa-arrow-circle-up ml-2"></i> : <i className="fas fa-arrow-circle-down ml-2"></i>}
        </div>
        <Collapse isOpen={isOpen}>
          <div className="row col-12 content--pd0">
            <div className="row col-4">
              <div className="col-4">
                <label className="col-12 content__label">作業員対応状況</label>
              </div>
              <div className="row col-8">
                <div className="col-4">
                  <select
                    className="content__select--custom"
                    name="statusWorker"
                    value={dataDetail?.statusWorker}
                    onChange={changeVal}
                  >
                    <option value=""></option>
                    {options.map((option, index) => (
                      <option value={option.value} key={index}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>

            <div className="row col-6 content--pd0">
              <div className="row col-12 content--pd0">
                {listWorkerMenu?.map((item, index) => {
                  return (
                    <button
                      className={`btn content__button--custom content__button--mgr5 col-2 ${
                        !_.isUndefined(item?.isConfirm)
                          ? `${item?.isConfirm ? 'content__button--yellow' : 'content__button--yellow-00'}`
                          : 'content__button--yellow-bf'
                      }`}
                      disabled={_.isUndefined(item?.isConfirm)}
                      key={index}
                      onClick={() =>
                        (window.location.href = `report-order?workName=pre&orderId=${dataDetail?.orderId}&organizationId=${dataDetail?.organizationId}&backDetail=backDetail&beforeConfirmRole=${isRole}&menuName=beforeConfirm&menuSettingId=${item?.menuSettingId}`)
                      }
                    >
                      {item?.name}
                    </button>
                  );
                })}
              </div>
            </div>
            {/* <ButtonRequest
              data={dataDetail}
              state="statusBeforeStatus"
              confirmStatus={confirmStatus}
              role={role}
              classCustom="col-2 content--pd0"
              action="status"
            /> */}
          </div>
        </Collapse>
      </div>
      {isModal && (
        <ModalDetailSettingOrder
          orderId={dataDetail?.orderId || ''}
          productId={dataDetail?.productId || ''}
          serviceId={dataDetail?.serviceId || ''}
          nameSetting={nameSetting}
          pluginSettingId={pluginSettingId}
          modal={modal}
          toggle={toggleModal}
        />
      )}
    </div>
  );
};

export default BeforeConfirmInfo;
