import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { translate } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import '../../../assets/scss/page/contract/index.scss';
import Footer from '../../../components/Footer';
import HeaderMasterSetting from '../../../components/HeaderMasterSetting';
import LoadingNew from '../../../components/LoadingNew';
import PaginationCustom from '../../../components/PaginationCustom';
import { ACCOUNTING_ENDPOINTS } from '../../../constants/endpoints';
import CollapItem from './CollapItem';

const Contract = () => {
  const colorScreen = useSelector((store) => store.utils.colorMe);
  const [isOpen, setIsOpen] = useState(false);
  const [idRole, setIdRole] = useState(null);
  const toggle = () => setIsOpen(!isOpen);
  const [hideshow, setHideshow] = useState('');
  const [dataListAccount, setDataListAccount] = useState({});
  const [activePage, setActivePage] = useState(1);
  const [activePageAccount] = useState(1);
  const [loading, setLoading] = useState(false);

  const handleHide = (id) => {
    if (hideshow === id) {
      setHideshow('');
    } else {
      setHideshow(id);
    }
  };

  useEffect(() => {
    setIdRole(localStorage.getItem('role'));
    getListAcounting({ page: activePage, limit: 10, pageContract: activePageAccount, limitContract: 10 });
  }, []);

  const getListAcounting = async ({ page, limit, pageAccount, limitAccount }) => {
    setLoading(true);
    try {
      const data = await ACCOUNTING_ENDPOINTS.GET_LIST_ACCOUNTING({
        page,
        limit,
        pageAccount,
        limitAccount,
      });
      if (data?.data) {
        setDataListAccount(data?.data);
      }
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const handleChangePage = (pageNumber) => {
    setActivePage(pageNumber);
    getListAcounting({ page: pageNumber, limit: 10, pageAccount: activePageAccount, limitContract: 10 });
  };

  return (
    <>
      <div className="main">
        <LoadingNew loading={loading} />
        <HeaderMasterSetting color={colorScreen?.code} colorText={colorScreen?.colorText} />
        <div className="block-contract">
          <div className="search-menu-wrapper search-menu-wrapper-contract">
            <div className="wrapper-btn-colappse">
              <div
                onClick={toggle}
                className="mb-1 searchbar searchbar-contract d-flex justify-content-between align-items-center"
              >
                <div></div>
                <div>契約管理</div>
                <div className="mr-2">
                  {isOpen ? (
                    <i className="icon-up-open icon-searchbar" />
                  ) : (
                    <i className="icon-down-open icon-searchbar" />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="list-button-contact">
            <Link to="/accounting/list-acceptance" className="item">
              検収月一覧
            </Link>
            {!_.isEmpty(idRole) && idRole !== '1' && (
              <>
                <Link to="/accounting/list-payment" className="item">
                  支払月一覧
                </Link>
                <Link
                  className="item"
                >
                  精算完了一覧
                </Link>
              </>
            )}
          </div>
          <div className="list-table">
            {dataListAccount?.data?.map((item, index) => {
              return (
                <CollapItem
                  item={item}
                  key={index}
                  colors={colorScreen?.code}
                  colorText={colorScreen?.colorText}
                  handleHide={handleHide}
                  dataHeader={dataListAccount?.header}
                  hideshow={hideshow}
                  activePageAccount={activePageAccount}
                  roleId={idRole}
                />
              );
            })}
            {dataListAccount?.count > 10 && (
              <PaginationCustom
                activePage={activePage}
                itemsCountPerPage={10}
                totalItemsCount={dataListAccount?.count}
                pageRangeDisplayed={5}
                handlePageChange={handleChangePage}
              />
            )}
          </div>
        </div>
        <Footer color={colorScreen?.code} colorText={colorScreen?.colorText} />
      </div>
    </>
  );
};

export default compose(translate('translations'), withRouter)(Contract);
