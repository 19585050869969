import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { DISPATCH } from "constants/endpoints";
import Loading from "components/Loading/Loading";
import { translate } from 'react-i18next';
import { compose } from 'redux';
import { pushToast } from "components/Toast";
import _ from 'lodash';
import { SCREEN_DISPATCH_SETTING } from '../../../../helpers/constants';

const ModalAddOption = ({toggle = () => {},  modal = false, color='', productId='', handleHeaders, t, nameTitle}) => {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if(modal){
      getOptionDispatch({productId});
    }
  }, [modal]);

  const getOptionDispatch = async ({ productId }) => {
    try {
      setLoading(true);
      const data = await DISPATCH.GET_OPTION_DISPATCH({ productId });
      if (data) {
        const dataHandle = _.mapValues(data?.data, (value, key) => {
          return value.name
        })
        setOptions(dataHandle);
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const trimData = (data) => {
    return _.mapValues(data, function(o) {return (o.trim())});
  }

  const createOptionDispatch = async ({ body, productId }) => {
    try {
      setLoading(true);
      const data = await DISPATCH.CREATE_OPTION_DISPATCH({ body: trimData(body), productId });
      if (data && data.code !== 0) {
        if (data && data?.message && data?.message?.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
        setLoading(false);
      } else {
        getOptionDispatch({productId});
        pushToast("success", "Update Option Dispatch Success");
        handleHeaders(data?.data?.Attributes?.serviceOptions);
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      pushToast("error", "Update Option Dispatch Fail");
      setLoading(false);
    }
  };

  const changeOption = (e, key) => {
    const value = e.target.value;
    setOptions({...options, [key]: value});
  }

  const updateOption = () => {
    createOptionDispatch({
      body: {
        ...options,
        screenName: SCREEN_DISPATCH_SETTING.orderInformation.label,
        // updateTitle: `保存 フィールド ${nameTitle || ''}`
      },
      productId
    });
  }

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle}>
        <Loading loading={loading} />
        <ModalBody className="form__flex form__flex--col">
          {
            Object.keys(options)?.map((key, index) => {
              return (
                <div className="form__flex form__flex--center form__flex--mgb-10" key={index}>
                  <label className="label__custom" style={{ background: `${color}` }}>{t(`dispatch.${key}`)}</label>
                  <input type="text" onChange={e => changeOption(e, key)} value={options[key]} />
                </div>
              )
            })
          }
          <button className="button__edit button__edit--resize" style={{ background: `${color}` }} onClick={updateOption}>{t(`dispatch.save`)}</button>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>{t(`dispatch.cancel`)}</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default compose(translate('translations'))(ModalAddOption);
