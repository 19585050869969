import React, { useEffect, useState } from 'react';
import Header from 'components/Header';
import Footer from 'components/Footer';
import { ORDER } from 'constants/endpoints';
import { SCREEN_NAME } from 'helpers/constants';
import { getRole } from 'helpers/helpers';
import 'assets/scss/page/DetailDeliveredInfo/style.scss';
import DetailData from 'components/DetailCenter/DetailData';
import DetailDataList from 'components/DetailCenter/DetailDataList';
import DetailOrder from 'components/DetailCenter/DetailOrder';
import DetailListAllData from 'components/DetailCenter/DetailListAllData';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import DetailTreeDocumentSetting from 'components/DetailTreeDocumentSetting';
import _ from 'lodash';
import DetailSent from '../../../components/DetailOrderErp/DetailSent';
import DetailTimeWorker from '../../../components/DetailOrderErp/DetailTimeWorker';
import DetailResult from '../../../components/DetailOrderErp/DetailResult';
import DetailSite from '../../../components/DetailOrderErp/DetailSite';
import DetailReportErp from '../../../components/DetailOrderErp/DetailReportErp';
import { getRoleNew } from '../../../helpers/helpers';

const mockData = {
  screenName: SCREEN_NAME.DELIVERED_INFO,
};

const DetailDeliveredInfo = () => {
  const [nameRole, setNameRole] = React.useState(null);
  const [idRole, setIdRole] = React.useState(null);
  const [dataOrder, setDataOrder] = useState(mockData);
  const [data, setData] = useState([]);
  let orderId = process.browser && new URL(window.location.href).searchParams.get('orderId');
  let organizationId = process.browser && new URL(window.location.href).searchParams.get('organizationId') || '';
  let partnerId = process.browser && new URL(window.location.href).searchParams.get('partnerId');
  let name = (process.browser && new URL(window.location.href).searchParams.get('p')) || 'delivered_info';
  const colorScreen = useSelector((store) => store.utils.colorMe);
  const [p, setP] = React.useState(name);
  const [statusResult, setStatusResult] = useState([]);
  const [documentSetting, setDocumentSetting] = useState([]);
  const [dataDetailTimeWorker, setDataDetailTimeWorker] = useState({})
  const [listDetailScreen, setListDetailScreen] = useState([]);
  const [nameFileReportUploadInfo, setNameFileReportUploadInfo] = useState([]);
  const [checkUpload, setCheckUpload] = useState([]);

  useEffect(() => {
    getListRoleChild();
  }, []);

  const getListRoleChild = async () => {
    const newRole = localStorage.getItem('role');
    try {
      const data = await ORDER.GET_LIST_DETAIL_SETTING({
        arrRole: newRole,
        nameScreen: p,
        orderId,
        organizationId
      });
      if (data) {
        setCheckUpload(_.map(data?.data?.documentSettingDelivery, 'documentSettingId') || []);
        setListDetailScreen(data?.data?.detailScreen);
        let arrTmp = data?.data?.documentSettingUploadFile || [];
        arrTmp.push(data?.data?.documentSettingDelivery || []);
        setDocumentSetting(_.flatten(_.uniq(arrTmp)));
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    setP(name);
  }, [name]);

  const getOrderDetail = async ({ orderId = '' }) => {
    try {
      const data = await ORDER.GET_DETAIL_CANCEL_INFO({ orderId, screenName: 'delivered_info', organizationId });
      if (data) {
        setData(data?.data);
        const nameFileReport = data?.data?.nameFileReportUploadInfo || [];
        setNameFileReportUploadInfo(nameFileReport);
        setDataDetailTimeWorker(data?.data)
        let newData = data?.data;
        newData = { ...newData, screenName: SCREEN_NAME.DELIVERED_INFO };
        setDataOrder(newData);
        getStatusResult({
          serviceId: data?.data?.serviceId,
          productId: data?.data?.productId,
          orderId: data?.data?.orderId,
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    const newRole = localStorage.getItem('role');
    setIdRole(newRole);
    if (newRole) {
      setNameRole(getRoleNew({ idRole: newRole }));
    }

    if (orderId) {
      getOrderDetail({
        orderId: orderId,
      });
    }
  }, [orderId]);

  const getStatusResult = async ({ serviceId, productId, orderId, type = 'completionResultSetting' }) => {
    try {
      const data = await ORDER.GET_STATUS_ORDER({ serviceId, productId, orderId, type, organizationId });
      if (data) {
        setStatusResult(data?.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="wrapper-detail-delivered-info">
      <div className="main">
        <Header p={p} idRole={idRole} color={colorScreen?.code} colorText={colorScreen?.colorText} />
        <div className="body-wrapper">
          <div className="logout_btn">
            <Link to={`list?p=${p}`}>
              <span className="mypage_ab_custom">戻る</span>
            </Link>
          </div>
          {listDetailScreen?.map((item, index) => {
              if (item.status) {
                switch (item.name) {
                  case 'clientCompany':
                    return <DetailOrder data={data} p={p} key={index} />;
                  case 'applicationCompany':
                    return <DetailData data={data} p={p} link={false} key={index} />;
                  case 'mattersSent':
                    return <DetailSent data={data} isDisabledManagement={true} isDisabledErp={true} key={index} role={nameRole} />;
                  case 'visitDateWorker':
                    return <DetailTimeWorker screenName={SCREEN_NAME.DELIVERED_INFO} data={dataDetailTimeWorker} key={index} />;
                  case 'result':
                    return <DetailSite data={data} key={index} />;
                  case 'reportSubmissionInformation':
                    return <DetailReportErp
                      checkUpload={checkUpload}
                      data={data} organizationId={organizationId}
                      orderId={orderId} partnerId={partnerId} type={''}
                      key={index} documentSetting={documentSetting}
                      nameFileReportUploadInfo={nameFileReportUploadInfo}
                      screenName={SCREEN_NAME.DELIVERED_INFO}/>;
                  case 'acceptanceInformation1':
                    return <DetailResult orderId={orderId} key={index} />;
                  case 'detailInfo':
                    return <DetailListAllData data={data} screenName={SCREEN_NAME.DELIVERED_INFO} key={index} />;
                  case 'document':
                    return <DetailTreeDocumentSetting orderId={orderId} order={data} key={index} />;
                  case 'history':
                    return <DetailDataList datePick={false} screenName={SCREEN_NAME.DELIVERED_INFO} orderId={orderId} key={index} />;
                  default:
                    break;
                }
              }
            })}
        </div>
        <Footer color={colorScreen?.code} colorText={colorScreen?.colorText} />
      </div>
    </div>
  );
};

export default DetailDeliveredInfo;
