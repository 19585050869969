import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import 'assets/scss/page/AnkenMasterNew/ModalAddProductInProject.scss';
import { pushToast } from 'components/Toast';
import { ANKEN_MASTER_NEW } from 'constants/endpoints';
import { SCREEN_NAME } from '../../../../helpers/constants';
import Loading from 'components/Loading/Loading';

const ModalAddProductInProject = (props) => {
  const { toggle = () => { }, modal = false, idProjectChoose, createSuccess, projectName } = props;
  const [listProductId, setListProductId] = useState([]);
  const [listTree, setListTree] = useState([]);
  const [listTreeOrigin, setListTreeOrigin] = useState([]);
  const [valueSearch, setValueSearch] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!modal) {
      setListProductId([])
    }
    getTree();
  }, [modal]);

  const getTree = async () => {
    try {
      setLoading(true);
      const data = await ANKEN_MASTER_NEW.GET_PRODUCT_NONE_PROJECT();
      if (data) {
        setListTree(data?.data);
        setListTreeOrigin(data?.data);
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const handleAddProductID = (id) => {
    if (listProductId.includes(id)) {
      const newList = listProductId.filter((item) => item !== id )
      setListProductId(newList);
    } else {
      setListProductId([...listProductId, id]);
    }
  };


  const addProduct = () => {
    const body = {
      productIds: listProductId ,
      screenName : SCREEN_NAME.CASE_MASTA
    }
    handleAddProduct({body, idProject: idProjectChoose});
  };

  const handleAddProduct = async ({body, idProject}) => {
    try {
      setLoading(true);
      const data = await ANKEN_MASTER_NEW.ADD_PRODUCT_IN_PROJECT({ body, idProject });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
        setLoading(false);
      } else {
        pushToast('success', 'Add Product success');
        createSuccess();
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const changeValueSearch = (e) => {
    setValueSearch(e.target.value)
  };

  const handleSearch = () => {
    if (valueSearch) {
      const newList = listTree.filter(item => (item.name.toUpperCase().search(valueSearch.toUpperCase()) >= 0));
      setListTree(newList);
    }
    else {
      setListTree(listTreeOrigin);
    }
  };

  return (
    <div>
      <Loading loading={loading} />
      <Modal className="modal-dialog-custom" isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>{projectName}</ModalHeader>
        <ModalBody>
          <div className="form-body-add-service">
            <div className="form-get-code-add-product">
              <div className="group-input">
                <div className="title">検索条件</div>
                <input
                  type="text"
                  value={valueSearch}
                  onChange={changeValueSearch}
                />
              </div>
              <button
                onClick={() => handleSearch()}
              >検索</button>
            </div>

            <div className="form-tree">
              <p className="d-flex flex-direction-row">
                <div className="title-project">一般</div>
              </p>
              {listTree && listTree ?.length > 0 &&
                <ul>
                {listTree.map((item, index) => {
                  return (
                    <li key={index}>
                      <p>
                        {item.name}
                        <label className="container-checkbox">
                          <input
                            type="checkbox"
                            name="required"
                            checked={listProductId.includes(item.productId)}
                            onChange={() => handleAddProductID(item.productId)}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </p>
                    </li>
                  );
                })}
              </ul>
              }

            </div>

            <div className="form-btn-setting">
              <button
                onClick={() => addProduct()}
                disabled={listProductId.length > 0 ? false : true }
              >登録</button>
            </div>
          </div>

        </ModalBody>
      </Modal>
    </div>
  );
};

export default ModalAddProductInProject;
