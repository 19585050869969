import React, { useState, useEffect, useRef } from 'react';
import 'assets/scss/page/ChatPage/style.scss';
import { Tooltip } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { DATETIME_JP_FORMAT } from 'helpers/constants';
import moment from 'moment';
import { getListMessageInUserByUser } from 'actions/chat';
import { socket } from '../../../helpers/callApi';
import { pushToast } from 'components/Toast';
import Loading from 'components/Loading/Loading';
import _ from 'lodash';
import UserDefault from '../../../assets/img/user-default.png';

const FormChatUser = ({
  userActive,
  textMessage,
  setTextMessage,
  resetReadUser = () => {}
}) => {
  const dispatch = useDispatch();
  const { listMessageUserByUser } = useSelector(state => state.chat);
  const userId = useSelector(state => state.authentication?.userMe?.data?.userId);
  const { loading } = useSelector(store => store.utils);
  let messagesEndRef = useRef(null);
  const scrollToBottom = () => messagesEndRef.current ?.scrollIntoView({ behavior: 'smooth' });
  const nameUser = `${userActive?.companyName} ${userActive?.firstName} ${userActive?.lastName}`;

  useEffect(() => {
    if (_.filter(listMessageUserByUser, _.size)?.length) {
      socket.emit(`last-read-private`, { toUserId: userActive.userId, userId }, err =>
        pushToast('error', err ?.errorMessage)
      );
    }
    scrollToBottom();
  }, [listMessageUserByUser]);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown, false);
    return () => document.removeEventListener('keydown', handleKeyDown, false);
  }, [textMessage]);

  useEffect(() => {
    if (!_.isEmpty(userActive)) {
      dispatch(getListMessageInUserByUser({ userId: userActive.userId }));
      scrollToBottom();
    }
  }, [userActive]);

  const handleSendMessage = () => {
    if (textMessage) {
      const payload = {
        message: textMessage,
        fromUserId: userId,
        toUserId: userActive.userId
      };
      socket.emit('send-message', payload, err => pushToast('error', err?.errorMessage));
    }
  };

  const handleKeyDown = e => {
    const myElement = document.getElementById('send-message-user');
    if(myElement === document.activeElement){
      if (!!textMessage?.trim() && e.key === 'Enter') {
        e.preventDefault();
        handleSendMessage();
      }
    }
  };

  const handleChangeTextMessage = e => setTextMessage(e.target.value);

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  return !_.isEmpty(userActive) ? (
    <div className="col-8 chat__form--pdRow">
      <Loading loading={loading} />
      <div className="chat__form--groundb p-2">
        <div className="d-flex justify-content-between align-item-center mx-3 p-1">
          <p className="chat__form--fz20" title={nameUser}>{nameUser}</p>
        </div>
      </div>

      <div className="wrapper-chat">
        <div className="message" id="message">
          {listMessageUserByUser?.map((item, index) => {
            return item.fromUserId === userId ? (
              <div key={index} className="form-message right-message">
                <div className="message-body-wrapper">
                  <div className="d-flex right-message">
                    <span className="text-date">{moment(item?.createdAt).format(DATETIME_JP_FORMAT)}</span>
                  </div>
                  <div className="content-right">
                    {item.message}
                  </div>
                </div>
              </div>
            ) : (
              <div key={index} className="form-message">
                <div className="thumb">
                  <div className="avatar">
                    <img src={UserDefault} alt="tuyentc" className="avatar-image" />
                  </div>
                </div>

                <div className="message-body-wrapper">
                  <div className="d-flex">
                    <span className="user">{nameUser}</span>{' '}
                    <span className="text-date">{moment(item?.createdAt).format(DATETIME_JP_FORMAT)}</span>
                  </div>
                  <div className="">
                    {item.message}
                  </div>
                </div>
              </div>
            );
          })}
          <div style={{ float: 'left', clear: 'both' }} ref={messagesEndRef}></div>
        </div>
      </div>

      <div className="chat__form--bdR">
        <div className="input-chat">
          <textarea
            onChange={handleChangeTextMessage}
            value={textMessage}
            id='send-message-user'
            type="text"
            placeholder="Type a message"
            className="chat__form--text"
          />
        </div>

        <div className="form-send">
          {
            !!textMessage.trim() &&
            <>
              <i className="fa fa-paper-plane-o chat__form--icon" onClick={handleSendMessage} id="send" title="send"></i>
            </>
          }

        </div>
      </div>
    </div>
  ) : (
    ''
  );
};

export default FormChatUser;
