import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { ANKEN_MASTER_NEW } from 'constants/endpoints';
import { pushToast } from 'components/Toast';
import 'assets/scss/page/AnkenMasterNew/TableModal.scss';
import ButtonChecked from './ButtonChecked';
import { translate } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import Loading from 'components/Loading/Loading';
import _ from 'lodash';
import { SCREEN_NAME } from '../../../../../helpers/constants';
import { getDataHistoryMasterSetting } from 'actions/authentication';
import { useDispatch } from 'react-redux';

const TableModalCancels = ({serviceCancel, productCancel, color, nameTitle }) => {
  const dispatch = useDispatch();
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return dataCancel(result);
  };
  const dataCancel = (result) => result.map((i, index) => { i.sortNum = index; return i; });
  const [dataTable, setDataTable] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getCancelSettings({ serviceId: serviceCancel, productId: productCancel })
  }, [serviceCancel, productCancel]);

  const getCancelSettings = async ({ serviceId, productId }) => {
    try {
      setLoading(true);
      const data = await ANKEN_MASTER_NEW.GET_CANCEL_SETTINGS({ serviceId, productId });
      if (data) {
        setDataTable(_.sortBy(data?.data?.cancelSetting), 'sortNum');
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  }

  const addCancelSettings = async ({body, serviceId, productId}) => {
    try {
      setLoading(true);
      const data = await ANKEN_MASTER_NEW.ADD_CANCEL_SETTINGS({ body, serviceId, productId });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
        setLoading(false);
      } else {
        pushToast('success', 'Create Product success');
        dispatch(getDataHistoryMasterSetting({ logId: '', screenName: SCREEN_NAME.CASE_MASTA }));
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const items = reorder(dataTable, result.source.index, result.destination.index);
    setDataTable(items);
  };

  const onChangeChecked = ({ status = false, index }) => {
    const newData = [...dataTable];
    newData[index].status = status;
    setDataTable(newData);
  };

  const onChangeText = (e, index) => {
    const value = e.target.value;
    const name = e.target.name;
    const newData = [...dataTable];
    newData[index][name] = value;
    setDataTable(newData);
  }

  const addListItem = () => {
    const newData = [...dataTable, {status: false}];
    setDataTable(dataCancel(newData));
  };

  const deleteListItem = (item) => {
    if (item.hasOwnProperty('sortNum')) {
      setDataTable(dataCancel(dataTable.filter(elm => elm.sortNum !== item.sortNum)));
    }
  };

  const onUpdateCancelSettings = () => {
    addCancelSettings({ body: { cancelSetting: dataTable , screenName: SCREEN_NAME.CASE_MASTA, updateTitle: `登録 キャンセル結果設定 ${nameTitle || ''}` }, serviceId: serviceCancel, productId: productCancel });
  }

  const renderItem = (item, index) => {
    return (
      <Draggable key={`${index + 1}`} draggableId={`${index + 1}`} index={index}>
        {(provided, snapshot) => (
          <tr
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            key={index}
            className="item-worker"
          >
            <td>
              {
                item?.isDefault ? null : (
                  <div className="icon"
                    style={{ borderColor: `${color}`, color: `${color}` }}
                    onClick = {() => deleteListItem(item)}
                  >
                    <i className="fas fa-times"></i>
                  </div>
                )
              }
            </td>
            <td className="text-center">
              <select name="result" className="text-input select--result" disabled={item.isDefault} onChange={e => onChangeText(e, index)} value={item ?.result || ''}>
                <option defaultValue=""></option>
                <option value="前日CAN">前日CAN</option>
                <option value="事前CAN">事前CAN</option>
              </select>
            </td>
            <td className="text-center">
              <input
                type="text" className="text-input input-result" name="pullDownDisplay"
                style={{ borderColor: `${color}`, color: `${color}` }}
                value={item ?.pullDownDisplay || ''} onChange={e => onChangeText(e, index)}
              />
            </td>

            <td>
              <ButtonChecked color={color} status={item?.status || false} index={index} onChange={onChangeChecked} />
            </td>
          </tr>
        )}
      </Draggable>
    );
  };

  const renderTableInfo = (data) => {
    return (
      <table className="form-worker">
      {
        <tr>
          <th></th>
          <th className="text-center">結果</th>
          <th className="text-center">プルダウン表示</th>
          <th className="text-center">必要／不要</th>
        </tr>
      }
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <tbody
                {...provided.droppableProps} ref={provided.innerRef}
              className="scroll-table">
              {dataTable?.map((item, index) => {
                return renderItem(item, index);
              })}
            {provided.placeholder}
            <tr className="item-worker">
              <td>
                <div
                  className="icon"
                  style={{ borderColor: `${color}`, color: `${color}` }}
                  onClick={addListItem}
                >
                  <i className="fas fa-plus"></i>
                </div>
              </td>
            </tr>
            </tbody>
            )}
          </Droppable>
        </DragDropContext>
      </table>
    );
  };

  return (
    <div className="wrapper-master-setting wrapper-master-setting--w100">
      <Loading loading={loading} />
      <div className="body pb-4">
        <div className="wrapper-main">
          <div className="d-flex justify-content-end align-items-center group-button">
            <button className="button--blue button--resize" onClick={onUpdateCancelSettings}>登録</button>
          </div>
          <div className="wrapper-result">
            {renderTableInfo()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default compose(translate('translations'), withRouter)(TableModalCancels);
