import React, { useEffect, useState } from 'react';
import HeaderMasterSetting from '../../../../components/HeaderMasterSetting';
import { ORDER } from '../../../../constants/endpoints';
import { SCREEN_NAME, DATE_JP_FORMAT } from '../../../../helpers/constants';
import { pushToast } from '../../../../components/Toast';
import DetailData from '../../../../components/DetailCenter/DetailData';
import DetailOrder from '../../../../components/DetailCenter/DetailOrder';
import DetailListAllData from '../../../../components/DetailCenter/DetailListAllData';
import { Link } from 'react-router-dom';
import moment from 'moment';
import DateTimePicker from '../../../../components/DateTimePicker';
import { useSelector } from 'react-redux';
import { compose } from 'redux';
import { translate } from 'react-i18next';
import _ from 'lodash';
import MemoHistory from '../../../../components/MemoHistory';

const mockData = {
  screenName: SCREEN_NAME.PAYMENT_DATE,
};

const UpdatePaymentDate = ({ match, t }) => {
  const colorScreen = useSelector((store) => store.utils.colorMe);
  const [dataOrder, setDataOrder] = useState(mockData);
  const [data, setData] = useState([]);
  const [dataPaymenet, setDataPaymenet] = useState();
  const [submit, setSubmit] = useState(false);
  let orderId = match.params.id;

  useEffect(() => {
    if (data) {
      setDataOrder({
        ...dataOrder,
        paymentMonth: data?.paymentMonth || '',
        acceptanceMonth: data?.acceptanceMonth || '',
      });
    }
  }, [data]);

  const getOrderDetail = async ({ orderId = '' }) => {
    try {
      const data = await ORDER.GET_DETAIL_CANCEL_INFO({ orderId: orderId });
      if (data) {
        setData(data?.data);
        setDataPaymenet(data?.data?.paymentMonth);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (orderId) {
      getOrderDetail({
        orderId: orderId,
      });
    }
  }, [orderId]);

  const updateOrderInfo = async ({ body = {}, orderId = '' }) => {
    try {
      const data = await ORDER.UPDATE_ACCEPTANCE({ body: body, orderId: orderId });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
      } else {
        pushToast('success', 'Update 検収確認 success');
        getOrderDetail({ orderId });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onUpdateOrder = () => {
    updateOrderInfo({ body: dataOrder, orderId: orderId });
  };

  const changeAcceptanceMonth = (date) => {
    setDataOrder({ ...dataOrder, acceptanceMonth: date === null ? '' : moment(new Date(date)).format(DATE_JP_FORMAT) });
  };

  const changePaymentMonth = (date) => {
    setDataOrder({ ...dataOrder, paymentMonth: date === null ? '' : moment(new Date(date)).format(DATE_JP_FORMAT) });
  };

  useEffect(() => {
    if (!_.isEmpty(dataOrder?.paymentMonth) && !_.isEmpty(dataOrder?.acceptanceMonth)) {
      setSubmit(true);
    } else {
      setSubmit(false);
    }
  }, [dataOrder]);

  return (
    <div className="wrapper-detail-site-info">
      <div className="main">
        <HeaderMasterSetting color={colorScreen?.code} colorText={colorScreen?.colorText} />
        <div className="body-wrapper">
          <div className="logout_btn">
            <Link to={'/payment-date'}>
              <span className="mypage_ab_custom">{t('payment_date.back')}</span>
            </Link>
          </div>
          <DetailOrder data={data} />
          <DetailData data={data} link={false} />
          <div className="detail-site-table-wrapper">
            <div className="title-absolute">{t('payment_date.title')}</div>
            <table className="conditTab">
              <tbody>
                <tr>
                  <th width="150">{t('payment_date.acceptance_confirmation')}</th>
                  <td>
                    <DateTimePicker
                      date={data?.acceptanceMonth?.length > 0 ? new Date(data?.acceptanceMonth) : null}
                      onChangeDate={changeAcceptanceMonth}
                      disabled={!_.isEmpty(dataPaymenet)}
                    />
                  </td>
                </tr>
                <tr>
                  <th width="150">{t('payment_date.payment_date')}</th>
                  <td>
                    <DateTimePicker
                      date={data?.paymentMonth?.length > 0 ? new Date(data?.paymentMonth) : null}
                      onChangeDate={changePaymentMonth}
                      disabled={!_.isEmpty(dataPaymenet)}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="detail-data-wrapper">
              <div className={`button-detail-btn pb-1 text-center`}>
                <button
                  disabled={!submit}
                  className={`${submit ? 'btn-item' : 'btn-item-active'} `}
                  onClick={() => onUpdateOrder()}
                >
                  保存
                </button>
              </div>
            </div>
          </div>
          <DetailListAllData data={data} screenName={SCREEN_NAME.PAYMENT_DATE} />
          <MemoHistory colors={colorScreen?.code} logId={orderId} colorText={colorScreen?.colorText} screenName={SCREEN_NAME.PAYMENT_DATE}/>
        </div>
      </div>
    </div>
  );
};

export default compose(translate('translations'))(UpdatePaymentDate);
