import React, { useState, useEffect } from 'react';
import './style.scss';
import DateTimePicker from '../DateTimePicker';
import { SCREEN_NAME, ROLE, DATE_JP_FORMAT } from '../../helpers/constants';
import { ORDER, ORDER_PARTNER } from '../../constants/endpoints';
import { pushToast } from '../Toast';
import moment from 'moment';
import MemoTable from '../MemoTable';
import { Collapse } from 'reactstrap';

const DetailDataList = ({
  extraData = '',
  role,
  orderId,
  datePick = true,
  title_remark = '更新履歴',
  body,
  status,
  getDataBody,
  orderDataDetail,
  organizationId = null,
  screenName,
}) => {
  const [CompletionDay, setCompletionDay] = useState('');
  const [beforeConfirmation, setBeforeConfirmation] = useState('');
  const [approvalRequestText, setApprovalRequestText] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const onClickShow = () => {
    setIsOpen(!isOpen);
  };

  const updateDetailOrder = async ({ body = {}, status = {}, orderId = '' }) => {
    try {
      const data = await ((role === ROLE.CENTER || role === ROLE.PARTNERGROUP)
        ? ORDER.UPDATE_RECEIVE_ALL({ body: body, orderId: orderId }) &&
        ORDER.UPDATE_STATUS_ALL({ body: status, orderId: orderId })
        : ORDER_PARTNER.UPDATE_ORDER_PARTNER_DETAIL({ body: body, orderId: orderId, organizationId: organizationId }));
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
      } else {
        pushToast('success', 'Update tomorrow info success');
      }
      const dataStatus = await ((role === ROLE.CENTER || role === ROLE.PARTNERGROUP) && ORDER.UPDATE_STATUS_ALL({ body: status, orderId: orderId }));
      if (dataStatus && dataStatus.code !== 0) {
        if (dataStatus && dataStatus.message && dataStatus.message.extra) {
          pushToast('error', dataStatus?.message?.extra);
        } else {
          pushToast('error', dataStatus?.message?.errorMessage);
        }
      } else {
        pushToast('success', 'Update tomorrow status success');
      }
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    setBeforeConfirmation(orderDataDetail?.beforeConfirmationDeadline || '')
    setApprovalRequestText(orderDataDetail?.approvalRequestText || '')
    setCompletionDay(orderDataDetail?.confirmationCompletionDay || '')
  }, [orderDataDetail, orderId])
  const onChangeCompletionDay = (date) => {
    setCompletionDay(moment(new Date(date)).format(DATE_JP_FORMAT));
    getDataBody({ confirmationCompletionDay: moment(new Date(date)).format(DATE_JP_FORMAT) });
  };
  const beforeConfirmationDeadline = (e) => {
    setBeforeConfirmation(e.target.value);
    getDataBody({ beforeConfirmationDeadline: e.target.value });
  };
  const onUpdateOrder = () => {
    updateDetailOrder({
      body: {
        screenName: SCREEN_NAME.TOMORROW_INFO,
        // tomorrowDetailStatus: role === ROLE.CENTER ? 'center_approved' : 'partner_approved',
        ...body,
      },
      status: {
        screenName: SCREEN_NAME.TOMORROW_INFO,
        // tomorrowDetailStatus: role === ROLE.CENTER ? 'center_approved' : 'partner_approved',
        ...status,
      },
      orderId: orderId,
    });
  };
  const onChageRequestText = (e) => {
    setApprovalRequestText(e.target.value);
    getDataBody({ approvalRequestText: e.target.value });
  };
  return (
    <div className="wrapper-detail-data-list">
      {datePick && (
        <div className="detail-cancel-table-wrapper detail-cancel-table-wrapper-three detail-cancel-table-wrapper-four">
          <div className="title-absolute">前日確認期限</div>
          <div className="block-item">
            <div className="block-left mb-1">
              <label>前日確認期限</label>
              <div className="block-date">
                <DateTimePicker
                  date={
                    orderDataDetail?.admissionApplicationCompletionDate?.length > 0
                      ? new Date(orderDataDetail?.admissionApplicationCompletionDate)
                      : null
                  }
                  onChangeDate={onChangeCompletionDay}
                />
              </div>
            </div>
          </div>
          <div className="block-item">
            <div className="block-left mb-1">
              <label>前日確認完了日</label>
              <input
                type="number"
                value={orderDataDetail?.beforeConfirmationDeadline}
                onChange={beforeConfirmationDeadline}
              />
            </div>
          </div>
        </div>
      )}
      <div className="detail-cancel-table-wrapper">
        <div className="title-absolute cursor-pointer" onClick={onClickShow}>{title_remark}</div>
        <Collapse isOpen={isOpen}>
          <MemoTable orderId={orderId} screenName={screenName} />
        </Collapse>
      </div>
      <div className="block-btn-bottom pb-1 float-right">
        {extraData?.unused && role === ROLE.PARTNER && (
          <button className="btn-item" onClick={() => onUpdateOrder()}>
            保存
          </button>
        )}
        {extraData?.user && role === ROLE.PARTNER && (
          <button className="btn-item" onClick={() => onUpdateOrder()}>
            承認依頼
          </button>
        )}
      </div>
      {extraData?.optional === true && role === ROLE.PARTNER && (
        <div className="block-btn-bottom pb-1 justify-content-between">
          <button className="btn-item" onClick={() => onUpdateOrder()}>
            保存
          </button>
          <button className="btn-item" onClick={() => onUpdateOrder()}>
            承認依頼
          </button>
        </div>
      )}
      {(role === ROLE.CENTER || role === ROLE.PARTNERGROUP) && (
        <div className="block-btn-bottom pb-1 justify-content-between">
          <button className="btn-item">BACK</button>
          <button className="btn-item" onClick={() => onUpdateOrder()}>
            保存
          </button>
        </div>
      )}
    </div>
  );
};

export default DetailDataList;
