import React, { useState, useEffect } from 'react';
import { ORDER } from 'constants/endpoints';
import { pushToast } from 'components/Toast';
import { genId } from 'helpers/helpers';
import 'assets/scss/page/MasterSettings/style.scss';
import Loading from 'components/Loading/Loading';
import _ from 'lodash';
import { SCREEN_NAME } from '../../../../../helpers/constants';
import { getDataHistoryMasterSetting } from 'actions/authentication';
import { useDispatch } from 'react-redux';

const ModalDetailSettingCsvStella = ({ routeList, nameScreen, idProduct, nameTitle }) => {
  const dispatch = useDispatch();
  const [valueClass, setValueClass] = useState('csv');
  const [listDetail, setListDetail] = useState([]);
  const [optionStella, setOptionStella] = useState([]);
  const [optionCsv, setOptionCsv] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getDetailSetting();
  }, []);

  const getDetailSetting = async () => {
    try {
      setLoading(true);
      const data = await ORDER.GET_DETAIL_SETTING_CSV_STELLA({ role: routeList, nameScreen, productId: idProduct });
      if (data && data?.code === 0) {
        setOptionStella(_.compact(data?.data?.fieldMaster || []));
        setOptionCsv(_.compact(data?.data?.primary || []));
        setListDetail(
          _.sortBy((data?.data?.data || []), 'sortNum')?.map((k, i) => {
            k.key = genId();
            k.sortNum = k.sortNum || i;
            return k;
          })
        );
      }
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const addListItem = () => {
    setListDetail([
      ...listDetail,
      {
        type: valueClass,
        label: '',
        nameInStella: '',
        status: false,
        key: genId(),
        sortNum: (_.maxBy(listDetail, 'sortNum')?.sortNum || 0) + 1
      },
    ]);
  };

  const deleteListItem = ({ item }) => {
    const newData = _.compact(listDetail.filter((i) => i.key !== item.key))?.map((x, i) => {x.sortNum = i; return x});
    setListDetail(newData);
  };

  const updateDetailSetting = async ({ body }) => {
    try {
      setLoading(true);
      const data = await ORDER.UPDATE_DETAIL_SETTING_CSV_STELLA({ body });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else if (data.message.errorMessage) {
          pushToast('error', data?.message?.errorMessage);
        } else {
          pushToast('error', data?.message);
        }
      } else {
        pushToast('success', 'Update success');
        dispatch(getDataHistoryMasterSetting({ logId: '', screenName: SCREEN_NAME.CASE_MASTA }));
        getDetailSetting();
      }
      setLoading(false);
    } catch (errorMessage) {
      console.log('errorMessage: ', errorMessage);
      setLoading(false);
    }
  };

  const changeClass = (e) => {
    setValueClass(e.target.value);
  };

  const changeItemDetail = ({ e, item, i, check }) => {
    const it = { ...item };
    const fieldName = e.target.name;
    if (check) {
      it.status = !it.status;
    } else {
      if (it.type === 'csv') {
        let dataIt = optionCsv.find((elm) => handleData(elm, true) === e.target.value);
        it.label = dataIt?.customName.trim() || '';
        it.typeValidate = dataIt?.type || '';
      } else {
        it.label = optionStella.find((elm) => elm.nameInStella === e.target.value)?.label || '';
      }
      it.status = false;
      it[fieldName] = e.target.value;
    }
    const listData = [...listDetail];
    listData[i] = it;
    setListDetail(listData);
  };

  const updateListDetail = () => {
    const body = {
      updateTitle: `保存 設定 ${nameTitle || ''}`,
      screenNameLog: SCREEN_NAME.CASE_MASTA,
      productId: idProduct,
      screenName: nameScreen,
      role: routeList,
      detailCsv: _.sortBy(listDetail, 'sortNum').map((k, i) => {
        k.sortNum = i;
        return _.omit(k, ['key']);
      }),
    };
    updateDetailSetting({ body });
  };

  const handleData = (elm, isStella = false) => {
    if(isStella ? !!elm?.nameInStella : !!optionCsv?.find((i) => i.customName === elm.label)?.nameInStella) return elm.nameInStella?.trim();

    switch ((isStella ? elm.customName : elm.label).trim()) {
      case '作業員1_ID':
        return 'worker1Id'
      case '作業員2_ID':
        return 'worker2Id';
      case '作業員3_ID':
        return 'worker3Id';
      case 'パートナーID':
        return 'partnerId';
      default:
        return (isStella ? elm.customName : elm.label)?.trim() || '';
    }
  };

  return (
    <div>
      <Loading loading={loading} />
      <div className="form-add-modal-csv">
        <select value={valueClass} onChange={changeClass}>
          <option value="csv">csv</option>
          <option value="stellaCloud">stellaCloud</option>
        </select>
        <div className="icon-plus" onClick={() => addListItem()}>
          <i className="fas fa-plus"></i>
        </div>
      </div>

      <div className="form-list-detail">
        <table className="form-worker">
          <thead>
            <tr>
              <th className="w-10" scope="col"></th>
              <th className="text-center w-20" scope="col">
                区分
              </th>
              <th className="text-center w-60" scope="col">
                フィールド名
              </th>
              <th className="text-center w-10" scope="col">
                編集
              </th>
            </tr>
          </thead>
          <tbody>
            {_.sortBy((listDetail || []), 'sortNum')?.map((item, i) => (
              <tr className="item-worker" key={i}>
                <td className="w-10">
                  <div className="form-delete resize resize--pd12" onClick={() => deleteListItem({ item })}>
                    <i className="fas fa-times"></i>
                  </div>
                </td>
                <td className="text-center w-20">
                  <div className="resize">{item.type}</div>
                </td>
                <td className="w-60">
                  <select
                    className="form-select"
                    value={item.type === 'csv' ? handleData(item) : item.nameInStella}
                    name="nameInStella"
                    onChange={(e) => changeItemDetail({ e, item, i })}
                  >
                    <option value={null} key={i}>
                      {''}
                    </option>
                    {item.type === 'csv'
                      ? optionCsv.map((elm, i) => (
                          <option value={handleData(elm, true)} key={i}>
                            {elm.customName}
                          </option>
                        ))
                      : optionStella.map((elm, i) => (
                          <option value={elm.nameInStella} key={i}>
                            {elm.label}
                          </option>
                        ))}
                  </select>
                </td>
                <td className="w-10">
                  <label className="container-checkbox resize__checkbox">
                    <input
                      type="checkbox"
                      name="status"
                      checked={item.status}
                      disabled={item.type !== 'csv' || optionCsv?.find((elm) => elm.nameInStella === item.nameInStella)}
                      onChange={(e) => changeItemDetail({ e, item, i, check: true })}
                    />
                    <span className={`checkmark resize__checkbox--child ${(item.type !== 'csv' || optionCsv?.find((elm) => elm.nameInStella === item.nameInStella)) && "resize__checkbox--disabled"}`}></span>
                  </label>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="item-button">
          <button onClick={updateListDetail}>保存</button>
        </div>
      </div>
    </div>
  );
};

export default ModalDetailSettingCsvStella;
