import React, { useState, useEffect } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { translate } from 'react-i18next';
import { Collapse } from 'reactstrap';
import { compose } from 'redux';
import '../../../../assets/scss/page/CsvMaster/index.scss';
import { pushToast } from '../../../../components/Toast';
import { MASTER_SETTING } from '../../../../constants/endpoints';
import { SCREEN_NAME, EXPORT_CSV_MASTER } from '../../../../helpers/constants';
import ModalExport from '../ModalExport';
import { getDataHistoryMasterSetting } from 'actions/authentication';
import { useDispatch } from 'react-redux';

const CollapItem = ({
  colors,
  item,
  t,
  handleHide,
  hideshow,
  getListAgain,
  colorText,
  onLoading,
  statusBeforeConfirm,
  getListExportSetting,
  activePage,
}) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [modalExport, setModalExport] = useState(false);
  const [exportSetting, setExportSetting] = useState([]);
  const [fieldExport, setFielExport] = useState();
  const [nameRoleExport, setNameRoleExport] = useState('center');
  const idRole = localStorage.getItem('role');

  const toggle = () => {
    handleHide(item.productId);
    setIsOpen(!isOpen);
  };

  const toggleExport = () => {
    setModalExport(!modalExport);
    // getListAgain();
  };

  const openModalExport = (item) => {
    setFielExport(item?.name);
    setModalExport(!modalExport);
  };

  const handleOnChange = (e, name) => {
    const { value } = e.target;
    var isTrueSet = value == 'true';
    const tmpArr = exportSetting.map((item) => {
      if (item.name === name) {
        return { ...item, isDisplay: isTrueSet };
      }
      return item;
    });
    setExportSetting(tmpArr);
  };

  const onSubmitChange = () => {
    let isDisplay = exportSetting?.map((el, eleI) => {
      return {
        name: el?.name,
        isDisplay: el?.isDisplay,
        index: eleI,
      };
    });
    updateExportListScreen({
      productId: item.productId,
      body: { exports: isDisplay, screenName: SCREEN_NAME.CSV_OUTPUT_SETTINGS, nameRole: nameRoleExport, updateTitle: `保存 CSV出力設定 ${item.name ? item.name : ''}`},
    });
  };

  const updateExportListScreen = async ({ productId, body }) => {
    onLoading(true);
    try {
      const data = await MASTER_SETTING.UPDATE_EXPORT_DISPLAY({ body, productId });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
        onLoading(false);
      } else {
        onLoading(false);
        pushToast('success', 'Update success');
        dispatch(getDataHistoryMasterSetting({ logId: '', screenName: SCREEN_NAME.CSV_OUTPUT_SETTINGS }));
      }
    } catch (errorMessage) {
      console.log('errorMessage: ', errorMessage);
      onLoading(false);
    }
  };
  useEffect(() => {
    if (nameRoleExport === 'center') {
      const newList = item?.center?.exports;
      setExportSetting(newList);
    }
    if (nameRoleExport === 'partner') {
      const newList = item?.partner?.exports;
      setExportSetting(newList);
    }
    if (nameRoleExport === 'client') {
      let newList = item?.client?.exports?.filter(el => el.name !== 'tomorrow_info');
      setExportSetting(newList);
    }
  }, [item, nameRoleExport]);

  const onChangeSubmit = (ele, idProduct) => {
    setNameRoleExport(ele);
    getListExportSetting({ nameRole: ele, productId: idProduct });
  };

  useEffect(() => {
    setNameRoleExport('center');
  }, [activePage]);

  return (
    <div className="setting-master-menu-wrapper">
      <div className="wrapper-btn-colappse">
        <div
          onClick={toggle}
          className="mb-1 searchbar d-flex justify-content-between align-items-center"
          style={{ background: `${colors}` }}
        >
          <div className="ml-2" style={{ color: `${colorText}` }}>
            {item.name}
          </div>
          <div className="mr-2" style={{ color: `${colorText}` }}>
            {isOpen ? <i className="icon-up-open icon-searchbar" /> : <i className="icon-down-open icon-searchbar" />}
          </div>
        </div>
      </div>
      <div>
        <Collapse isOpen={hideshow === item.productId ? true : false}>
          <div className="table-export-list mb-1" style={{ border: `3px solid ${colors}` }}>
            <div className="d-flex">
              {EXPORT_CSV_MASTER.map((ele) => (
                <button
                  className={`${ele.label === nameRoleExport ? `button-export-submit` : `button-export`}  m-2`}
                  onClick={() => onChangeSubmit(ele.label, item.productId)}
                >
                  {['5', '6', '7'].includes(idRole) && ele?.name === 'ERP：契約企業' ? 'ManagementGroup' : ele?.name}
                </button>
              ))}
            </div>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>No</th>
                  <th>メニュー</th>
                  <th>ボタン表示</th>
                  <th>CSV出力設定</th>
                </tr>
              </thead>
              <tbody>
                {exportSetting?.filter(e => !(['client'].includes(nameRoleExport) && e?.name === 'receive_info'))?.map((itemChild, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>
                        {t(
                          `${
                            nameRoleExport === 'center'
                              ? 'list_screen_export_setting'
                              : nameRoleExport === 'partner'
                              ? 'list_screen_export_setting_partner'
                              : 'list_screen_export_setting_client'
                          }.${itemChild.name}`
                        )}
                      </td>
                      <td className="text-left">
                        <span className="mr-4">
                          <input
                            onChange={(e) => handleOnChange(e, itemChild.name)}
                            id={`check-display-${item.productId}-${index}`}
                            name={`${itemChild.name}-${item.productId}-${index}`}
                            type="radio"
                            value={true}
                            checked={itemChild.isDisplay}
                          />
                          <label htmlFor={`check-display-${item.productId}-${index}`}>表示</label>
                        </span>
                        <span>
                          <input
                            onChange={(e) => handleOnChange(e, itemChild.name)}
                            id={`check-not-display-${item.productId}-${index}`}
                            name={`${itemChild.name}-${item.productId}-${index}`}
                            type="radio"
                            value={false}
                            checked={!itemChild.isDisplay}
                          />
                          <label htmlFor={`check-not-display-${item.productId}-${index}`}>非表示</label>
                        </span>
                      </td>
                      <td>
                        <button onClick={() => openModalExport(itemChild)}>項目設定</button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <button className="btn-update-export-outsite" onClick={onSubmitChange}>
              保存
            </button>
          </div>
        </Collapse>
        {openModalExport ? (
          <ModalExport
            fieldExport={fieldExport}
            modal={modalExport}
            productId={item.productId}
            toggle={toggleExport}
            nameRoleExport={nameRoleExport}
            nameTitle={item.name}
          />
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default compose(translate('translations'))(CollapItem);
