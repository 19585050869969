import React from 'react';
import { Link } from 'react-router-dom';
import './style.scss';

const DetailData = ({ data, p = 'tomorrow_info', link = true, title = '現状情報', client = false }) => {
  return (
    <div className="detail-data-wrapper">
      {link && <div className="logout_btn">
        <Link to={`list?p=${p}`}>
          <span className="mypage_ab">戻る</span>
        </Link>
      </div>}
      <div className="detail-cancel-table-wrapper">
        <div className="title-absolute">{title}</div>
        <h1 className="float-none">詳細（参照)</h1>
      </div>
    </div>
  );
};

export default DetailData;
