import React, { useState } from 'react';
import './style.scss'
import { WORKER_ENDPOINT } from '../../../../constants/endpoints';
import { pushToast } from '../../../../components/Toast';

const ReportCheck = ({ workerId }) => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [ceilingHeight, setCeilingHeight] = useState('');
    const [textName, setTextName] = useState('');
    const [imgData, setImgData] = useState('')
    const [selectedFile2, setSelectedFile2] = useState('');
    const [imgData2, setImgData2] = useState('')

    const updateStepTwo = async ({ body = '', workerId = '' }) => {
        try {
            const data = await WORKER_ENDPOINT.UPDATE_STEP_TWO({ body: body, workerId: workerId });
            if (data && data.code !== 0) {
                if (data && data.message && data.message.extra) {
                    pushToast('error', data?.message?.extra);
                } else {
                    pushToast('error', data?.message?.errorMessage);
                }
            } else {
                pushToast('success', 'Update order success');
            }
        } catch (e) {
            console.log(e);
        }
    };

    const handleCeilingHeight = (e) => {
        setCeilingHeight(e?.target?.value)
    };
    const handleChangeText = (e) => {
        setTextName(e?.target?.value)
    }

    const onChangeHandler = (e) => {
        if (e.target.files[0]) {
            setSelectedFile(e.target.files[0]);
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                setImgData(reader.result);
            });
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    const onChangeHandler2 = (e) => {
        if (e.target.files[0]) {
            setSelectedFile2(e.target.files[0]);
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                setImgData2(reader.result);
            });
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    const onClickHandler = () => {
        const data = new FormData();
        data.append('reportImage1', selectedFile);
        data.append('reportImage2', selectedFile2);
        data.append('ceilingHeight', ceilingHeight);
        data.append('nameOfTheAttendee', textName);
        updateStepTwo({ body: data, workerId: workerId });
    };

    return (
        <div className="detail-report-check">
            <table className="basictab" cellSpacing="0" cellPadding="0" width="100%">
                <colgroup>
                    <col width="15%" />
                    <col width="4%" />
                    <col width="40%" />
                    <col width="25%" />
                    <col width="16%" />
                </colgroup>
                <tbody>
                    <tr>
                        <th colSpan="5" className="font16">宅内工事</th>
                    </tr>
                    <tr className="pcmenu">
                        <th>区分</th>
                        <th>No</th>
                        <th>内容1</th>
                        <th>内容2</th>
                        <th>操作</th>
                    </tr>
                    <tr>

                        <td rowSpan="5" className="">施工前</td>
                        <td className="rightalign">1</td>
                        <td align="right">天井高をご記入ください。</td>
                        <td>単位：mm</td>
                        <td>
                            <div className="ui-grid-a">
                                <div style={{ width: "5em" }} className="ui-block-a">
                                    <div className="ui-input-text ui-body-inherit ui-corner-all ui-shadow-inset">
                                        <input
                                            type="number"
                                            className="validate required"
                                            name="f0001" id="f0001_01"
                                            onChange={handleCeilingHeight}
                                            value={ceilingHeight} size="5" />
                                    </div>
                                </div>
                                <div style={{ width: "2em", paddingTop: "1em" }} className="ui-block-b">
                                    mm
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td className="rightalign">2</td>
                        <td align="right"><br />
                            <div className="imgInput" style={{ margin: "15px 0px", padding: "15px 0px" }}>
                                <div className="IOSview">
                                    {imgData && (<img src={imgData} style={{ width: 50, height: 50 }} alt="" className="camera_img" />)}
                                    <input type="file" name="reportImage1" id='upload-csv' className='' onChange={onChangeHandler} />
                                    <div className="checkbox_wrap" style={{ width: "50%", float: "right" }}>

                                        <div className="ui-checkbox">
                                            <label htmlFor="p0001_del" className="ui-btn ui-corner-all ui-btn-inherit ui-btn-icon-left ui-checkbox-off">
                                                写真削除
                                            </label>
                                            <input type="checkbox" className="checkbox" style={{ margin: "-11px 0 0 0" }} name="p0001_clearflag" id="p0001_del" value="1" />
                                        </div>

                                        <div className="ui-checkbox">
                                            <label htmlFor="p0001_ng" className="ui-btn ui-corner-all ui-btn-inherit ui-btn-icon-left ui-checkbox-off">
                                                写真撮影不可
                                            </label>
                                            <input type="checkbox" className="ban_check" style={{ margin: "-11px 0 0 0" }} name="p0001_banflag" id="p0001_ng" value="1" />
                                        </div>
                                    </div>
                                </div>
                                <canvas id="canvas_p0001" style={{ maxWidth: 128, height: 0 }}></canvas>
                                <input type="hidden" name="" className="canvas_rotation" id="canvas_rotate_p0001" value="" />
                                <div className="image-box p0001box">
                                </div>
                                <span className="reason_wrap" style={{ display: "none" }}>
                                    <br />写真撮影不可理由：
                                    <br />
                                    <div className="ui-input-text ui-body-inherit ui-corner-all ui-shadow-inset">
                                        <input type="text" name="p0001_banreason" className="ban_reason_text" value="" /></div>
                                </span>
                                <div>
                                    <input type="hidden" name="strpics" value="" />
                                    <div className="ui-input-text ui-body-inherit ui-corner-all ui-shadow-inset">
                                        <input type="file" style={{ display: "none" }} name="p0001" id="p0001" accept="image/jpeg,capture=camera" className="filesend p0001_file" onChange="canvasDraw('p0001',800);" />
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td>
                        </td>
                        <td></td>
                    </tr>

                    <tr>
                        <td className="rightalign">3</td>
                        <td align="right"><br />
                            <div className="imgInput" style={{ margin: "15px 0px", padding: "15px 0px" }}>
                                <div className="IOSview">
                                    {imgData2 && (<img src={imgData2} style={{ width: 50, height: 50 }} alt="" className="camera_img" />)}
                                    <input type="file" name="reportImage2" id='upload-csv2' className='' onChange={onChangeHandler2} />
                                    <div className="checkbox_wrap" style={{ width: "50%", float: "right" }}>
                                        <div className="ui-checkbox">
                                            <label htmlFor="p0001_del" className="ui-btn ui-corner-all ui-btn-inherit ui-btn-icon-left ui-checkbox-off">
                                                写真削除
                                            </label>
                                            <input type="checkbox" className="checkbox" style={{ margin: "-11px 0 0 0" }} name="p0001_clearflag" id="p0001_del" value="1" />
                                        </div>

                                        <div className="ui-checkbox">
                                            <label htmlFor="p0001_ng" className="ui-btn ui-corner-all ui-btn-inherit ui-btn-icon-left ui-checkbox-off">
                                                写真撮影不可
                                            </label>
                                            <input type="checkbox" className="ban_check" style={{ margin: "-11px 0 0 0" }} name="p0001_banflag" id="p0001_ng" value="1" />
                                        </div>
                                    </div>
                                </div>
                                <canvas id="canvas_p0001" style={{ maxWidth: 128, height: 0 }}></canvas>
                                <input type="hidden" name="" className="canvas_rotation" id="canvas_rotate_p0001" value="" />
                                <div className="image-box p0001box">
                                </div>
                                <span className="reason_wrap" style={{ display: "none" }}>
                                    <br />写真撮影不可理由：
                                    <br /><div className="ui-input-text ui-body-inherit ui-corner-all ui-shadow-inset">
                                        <input type="text" name="p0001_banreason" className="ban_reason_text" value="" /></div>
                                </span>
                                <div>
                                    <input type="hidden" name="strpics" value="" />
                                    <div className="ui-input-text ui-body-inherit ui-corner-all ui-shadow-inset">
                                        <input type="file" style={{ display: "none" }} name="p0001" id="p0001" accept="image/jpeg,capture=camera" className="filesend p0001_file" onChange="canvasDraw('p0001',800);" />
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td>
                        </td>
                        <td></td>
                    </tr>
                    <tr>
                        <td className="rightalign">4</td>
                        <td align="right">当日のお立会者様名をご記入ください。</td>
                        <td></td>
                        <td><div className="ui-input-text ui-body-inherit ui-corner-all ui-shadow-inset">
                            <input type="text"
                                className="validate required" name="nameOfTheAttendee"
                                onChange={handleChangeText}
                                value={textName} />
                        </div>
                        </td>
                    </tr>

                    <tr>
                        <td className="rightalign">5</td>
                        <td colSpan="2" align="right">
                            当日のお立会者様にサインをいただいてください。<br /><br />
                            {/* <img src="../../cloudfiles/CLOUD_SALESDEMO/worker/pics/sign_f95f28a9aa8a3d52cf1145a7b1714f51.jpg" alt="" width="100" style="border:1px solid #666666"> */}
                            <input type="hidden" name="s0001_sign" value="074d67f0dcb27818511282d2d0a2964b.jpg" />
                            <a href="javascript:void(0)" className="test-popup-link ui-link ui-btn ui-shadow ui-corner-all" links="sign_popup.php?rid=61&amp;pid=11&amp;fname=s0001&amp;TB_iframe=true&amp;step=4&amp;ankenmaster_id=1" data-ajax="false" data-role="button" role="button">サインを書く</a>
                            <div style={{ marginTop: "1.5em", marginBottom: "1em", color: "red" }}>※サイン実施前、変更内容を保存実施してください。</div>
                            <div style={{ marginTop: "1.5em", marginBottom: "1em" }}>※サインが表示されない場合は、ブラウザをリロードして下さい。</div>
                        </td>
                        <td></td>

                    </tr>

                </tbody>
            </table>
            <div align="center">
                <input type="button" id="report_anken_b" className="submitbutton" data-role="none" value="保存"
                    onClick={() => onClickHandler()}
                />
            </div>
        </div>
    )
};

export default ReportCheck;
