import React from 'react';

const listTableInformation = [
  { label: '受注情報', value: 'order-information' },
  { label: '当日現場情報', value: 'site-information' },
  { label: 'worker設定', value: 'worker-information' },
  // { label: '報告書回収情報', value: 'collection-information' },
];

const TableInformation = ({ active = 'order-information', onClickActive = () => {} }) => {
  return (
    <div className="d-flex mt-3">
      {listTableInformation.map((item, index) => (
        <button
          className={`btn mr-3 ${active === item.value ? 'button-table-active' : 'button-table'}`}
          key={index}
          onClick={() => onClickActive(item.value)}
        >
          {item.label}
        </button>
      ))}
      {/*<button className="btn button-table-active mr-3">受注情報</button>*/}
      {/*<button className="btn button-table mr-3">当日現場情報</button>*/}
      {/*<button className="btn button-table mr-3">worker設定</button>*/}
      {/*<button className="btn button-table mr-3">報告書回収情報</button>*/}
    </div>
  );
};

export default TableInformation;
