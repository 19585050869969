import React, { useState, useEffect } from 'react';
import { Modal, Button, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Loading from 'components/Loading/Loading';
import 'assets/scss/page/ChatPage/style.scss';
import { socket } from '../../../helpers/callApi';
import { pushToast } from 'components/Toast';
import { useSelector, useDispatch } from 'react-redux';
import { updateListUserInRoom, getListChatUserByUser, updateMultipleUser } from 'actions/chat';
import _ from 'lodash';

const ModalAddUserInRoom = props => {
  const dispatch = useDispatch();
  const { toggle = () => { }, modal = false, roomActive, userId } = props;
  const [listAllUser, setListAllUser] = useState([]);
  const [initListUser, setInitListUser] = useState([]);
  const [dataSearch, setDataSearch] = useState('');
  const { listUser, listChatUserByUser } = useSelector(state => state.chat);
  const [users, setUsers] = useState([]);
  const { loading } = useSelector((store) => store.utils);

  useEffect(() => {
    if (listChatUserByUser?.length > 0) {
      setListAllUser(listChatUserByUser);
      setInitListUser(listChatUserByUser);
    }
  }, [listChatUserByUser]);

  useEffect(() => {
    if (listUser?.length > 0) {
      setUsers(listUser);
    }
  }, [listUser])

  useEffect(() => {
    listenListUser()
    dispatch(getListChatUserByUser());
  }, [])

  const listenListUser = () => {
    socket.on('add-user-room', data => {
      dispatch(updateListUserInRoom(data));
    });
  };

  const handleAddUser = (item) => {
    if (!!listUser.find(elm => elm.userId === item.userId)
      && +listUser.find(elm => elm.userId === item.userId)?.statusRoom === 2) {
      const payload = {
        userId: item.userId,
        roomId: roomActive.roomId,
      };
      socket.emit('leave-room', payload, err => {
        pushToast('error', err?.errorMessage);
      });
    } else {
      const payload = {
        userId: item.userId,
        roomId: roomActive.roomId,
        creatorId: roomActive.creatorId,
      };
      socket.emit('join', payload, err => {
        pushToast('error', err?.errorMessage);
      });
    }
  };

  const handleMultUser = (e, item) => {
    let newData = [...users];
    const indexOrigin = _.findIndex(listUser, e => e?.userId === item?.userId);
    const index = _.findIndex(newData, e => e?.userId === item?.userId);
    if(e.target.checked) {
      index === -1 ? newData.push({...item, statusRoom: "2", isChange: true}) : newData.splice(index, 1, {...newData[index], statusRoom: "2", isChange: true});
    } else {
      if(indexOrigin !== -1) {
        newData.splice(index, 1, {...newData[index], statusRoom: "1", isChange: true})
      } else {
        _.remove(newData, {userId: item?.userId})
      }
    };
    setUsers(_.compact(newData));
  }

  const onSubmit = () => {
    const userIds = _.map(listUser, 'userId');
    const body = users.filter(e => e.isChange && !!listUser.find(k => ((k.userId === e.userId && k.statusRoom !== e.statusRoom) || !userIds.includes(e.userId))));
    const payload = body?.map(e => {
      return {
        userId: e.userId, roomId: roomActive.roomId, creatorId: roomActive.creatorId, statusRoom: +e.statusRoom
      }
    })
    socket.emit('joinRoomMulti', payload, data => {
      if(data?.data) {
        dispatch(updateMultipleUser(body));
        toggle();
        pushToast('success', "Update success");
      } else {
        pushToast('error', data?.errorMessage);
      }
    });
  }

  const onSearch = () => {
    const format = /[ *()+\-=\[\]{}\\|\/?]/;
    const newData = [...initListUser];
    if (format.test(dataSearch)) {
      setListAllUser(newData);
    } else {
      const data = _.filter(newData, function (elm) {
        return `${elm.firstName}${elm.lastName}`.search(new RegExp(`${dataSearch}`, "i")) !== -1
      });
      setListAllUser(data);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown, false);

    return () => {
      document.removeEventListener('keydown', handleKeyDown, false);
    };
  }, [dataSearch]);

  const handleKeyDown = e => {
    const myElement = document.getElementById('search-modal');
    if (myElement === document.activeElement) {
      if (!!dataSearch && e.key === 'Enter') {
        e.preventDefault();
        onSearch();
      }
    }
  };

  return (
    <div className="modal-add-user">
      <Modal isOpen={modal} toggle={toggle}>
        <Loading loading={loading} />

        <ModalHeader toggle={toggle}>メンバー追加</ModalHeader>
        <ModalBody>
          <div style={{ border: '2px solid #0e5a8d' }}>
            <div className="form-modal-add-user" >
              <span className="w-100 pt-1 pl-2 nameRoom truncate" title={roomActive.name}>{roomActive.name}</span>
              <input type="text" onChange={(e) => setDataSearch(e.target.value)}
                className="form-control h-100"
                id="search-modal" style={{ borderRadius: 0 }} />
              <button
                className="btn btn-style"
                onClick={() => onSearch()}
              >
                検索
                </button>
            </div>
            <div className="form-list-user">
              {
                listAllUser?.length > 0 && listAllUser.length > 0 && listAllUser.map((item, index) => {
                  if(item.userId === userId) return

                  return (
                    <div className="info-user" key={index}>
                      <label className="container">{`${item?.companyName} ${item?.firstName} ${item?.lastName}`}
                        <input
                          type="checkbox"
                          // disabled={
                          //   (!!listUser.find(elm => elm.userId === item.userId)
                          //     && +listUser.find(elm => elm.userId === item.userId)?.statusRoom === 2)}
                          checked={
                            (!!users.find(elm => elm.userId === item.userId)
                              && +users.find(elm => elm.userId === item.userId)?.statusRoom === 2)}
                          onChange={(e) => handleMultUser(e, item)}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                  )
                })
              }

            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button className="btn btn-style" onClick={onSubmit}>完了</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ModalAddUserInRoom;
