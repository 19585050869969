import ja from 'date-fns/locale/ja'; // the locale you want
import React, { useEffect, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './style.scss';
registerLocale('ja', ja);

const DateTimePickerCustom = ({ date = null, onChangeDate = () => {} }) => {
  const [startDate, setStartDate] = useState(date);

  useEffect(() => {
    if (date) {
      setStartDate(date);
    }
  }, [date]);

  const handleChange = (date) => {
    onChangeDate(date);
    setStartDate(date);
  };
  return (
    <div className="date-time-picker-wrapper date-time-picker-wrapper-custom">
      <DatePicker
        selected={startDate}
        onChange={(date) => handleChange(date)}
        locale="ja"
        dateFormat="yyyy/MM/dd"
        dateFormatCalendar="yyyy年 MM月"
        className="form-control"
      />
      <span className="icon-calender">
        <i className="fas fa-calendar-alt "></i>
      </span>
    </div>
  );
};

export default DateTimePickerCustom;
