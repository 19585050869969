import React, { useState, useEffect } from 'react';
import './style.scss';
import { ROLE, EXTRA_DATA_STATUS } from '../../helpers/constants';

const ConfirmStatus = ({ getDataBody, getStatusBody, orderDataDetail, orderId,role }) => {
  const [statusRequiredConfirm, setStatusRequiredConfirm] = useState(false);
  const [statusMonitoring, setStatusMonitoring] = useState('');

  useEffect(() => {
    setStatusRequiredConfirm(orderDataDetail?.statusRequiredConfirm || false);
    setStatusMonitoring(orderDataDetail?.statusMonitoring || '');
  }, [orderDataDetail, orderId]);

  const options = ([ROLE.CENTER, ROLE.PARTNERGROUP].includes(role)) ?
  [
    {
      label: '対応待ち',
      value: 'waitingForResponse',
    },
    {
      label: '対応中',
      value: 'underAdmissionApplication',
    },
    {
      label: '対応完了',
      value: 'correspondenceCompleted',
    },
    {
      label: 'キャンセル',
      value: 'cancel',
    },

    {
      label: '対応保留',
      value: 'withdrawal',
    },
  ]:
  [
    {
      label: '対応待ち',
      value: 'waitingForResponse',
    },
    {
      label: '対応中',
      value: 'underAdmissionApplication',
    },
    {
      label: '対応完了',
      value: 'correspondenceCompleted',
    },
    {
      label: 'キャンセル依頼',
      value: 'cancelRequest',
    },
    {
      label: '対応保留',
      value: 'withdrawal',
    },
  ];
  const getStatusMonitoring = (e) => {
    setStatusMonitoring(e.target.value);
    getDataBody({ statusMonitoring: e.target.value });
  };

  const setStatusRequiredOfConfirm = (e) => {
    let value = e.target?.value;
    if (value === 'true' || value === true) {
      value = true;
    } else {
      value = false;
    }
    setStatusRequiredConfirm(value);
    getStatusBody({ statusRequiredConfirm: value });
  };

  return (
    <div className="wrapper-comfirm-status">
      <div className="detail-cancel-table-wrapper detail-cancel-table-wrapper-two">
        <div className="title-absolute">前日確認ステータス</div>
        <div className="block-item">
          <div className="block-left">
            <select value={statusMonitoring} onChange={getStatusMonitoring}>
              {options.map((option) => (
                <option value={option.value}>{option.label}</option>
              ))}
            </select>
          </div>
          <div className="block-right">
            <span className="title">承認依頼</span>
            <span>
              <label>必要</label>
              <input
                type="radio"
                value="true"
                checked={statusRequiredConfirm === true}
                onChange={setStatusRequiredOfConfirm}
                name="statusRequiredConfirm"
              />
            </span>
            <label>不要</label>
            <span>
              <input
                type="radio"
                value="false"
                checked={statusRequiredConfirm === false}
                onChange={setStatusRequiredOfConfirm}
                name="statusRequiredConfirm"
              />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmStatus;
