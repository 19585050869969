import React from 'react';
import './style.scss';

const TableSelectUser = ({ data = [], handleChange = () => {}, selected = '' }) => {
  return (
    <div className="form-group h-100 w-100 wrapper-table-select-user">
      <select multiple className={`form-control w-100`} onChange={handleChange}>
        {data &&
          data.map((item, index) =>
            index === selected ? (
              <option key={index} selected={true}>
                {item.name}
              </option>
            ) : (
              <option key={index}>{item.name}</option>
            )
          )}
      </select>
    </div>
  );
};

export default TableSelectUser;
