export class DescribeInfo {
  constructor(describeInfo) {
    this.process = describeInfo?.process || '';
    this.applicantCompanyName = describeInfo?.applicantCompanyName || '';
    this.personInChargeOfApplication = describeInfo?.personInChargeOfApplication || '';
    this.contactPersonForApplication = describeInfo?.contactPersonForApplication || '';
    this.workerResponseRequired = describeInfo?.workerResponseRequired || '';
    this.appointmentCategory = describeInfo?.appointmentCategory || '';
    this.notificationItems = describeInfo?.notificationItems || '';
    this.visitedSiteName = describeInfo?.visitedSiteName || '';
    this.contactPersonInCharge = describeInfo?.contactPersonInCharge || '';
    this.contactInformation = describeInfo?.contactInformation || '';
    this.visitedPostalCode = describeInfo?.visitedPostalCode || '';
    this.visitedBaseAddress = describeInfo?.visitedBaseAddress || '';
    this.visitedBuildingName = describeInfo?.visitedBuildingName || '';
    this.scheduledInstallationLocation = describeInfo?.scheduledInstallationLocation || '';
    this.visitDate = describeInfo?.visitDate || '';
    this.messageForPartnerCommunication = describeInfo?.messageForPartnerCommunication || '';
    this.visitDateFrom = describeInfo?.visitDateFrom || '';
    this.visitDateTo = describeInfo?.visitDateTo || '';
    this.prefecturesVisited = describeInfo?.prefecturesVisited || '';
    this.managementCode = describeInfo?.managementCode || '';
    this.requestedDate = describeInfo?.requestedDate || '';
    this.serviceName = describeInfo?.serviceName || '';
  }
}

export class AppointInfo {
  constructor(describeInfo) {
    this.process = describeInfo?.process || '';
    this.applicantCompanyName = describeInfo?.applicantCompanyName || '';
    this.personInChargeOfApplication = describeInfo?.personInChargeOfApplication || '';
    this.contactPersonForApplication = describeInfo?.contactPersonForApplication || '';
    this.workerResponseRequired = describeInfo?.workerResponseRequired || '';
    this.appointmentCategory = describeInfo?.appointmentCategory || '';
    this.notificationItems = describeInfo?.notificationItems || '';
    this.visitedSiteName = describeInfo?.visitedSiteName || '';
    this.contactPersonInCharge = describeInfo?.contactPersonInCharge || '';
    this.contactInformation = describeInfo?.contactInformation || '';
    this.visitedPostalCode = describeInfo?.visitedPostalCode || '';
    this.visitedBaseAddress = describeInfo?.visitedBaseAddress || '';
    this.visitedBuildingName = describeInfo?.visitedBuildingName || '';
    this.scheduledInstallationLocation = describeInfo?.scheduledInstallationLocation || '';
    this.visitDate = describeInfo?.visitDate || '';
    this.messageForPartnerCommunication = describeInfo?.messageForPartnerCommunication || '';
    this.visitDateFrom = describeInfo?.visitDateFrom || '';
    this.visitDateTo = describeInfo?.visitDateTo || '';
    this.prefecturesVisited = describeInfo?.prefecturesVisited || '';
    this.managementCode = describeInfo?.managementCode || '';
    this.caseName = describeInfo?.caseName || '';
    this.callLog = describeInfo?.callLog || '';
    this.serviceName = describeInfo?.serviceName || '';
  }
}
