import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import HeaderMasterSetting from '../../../components/HeaderMasterSetting';
import BorderGroupForm from 'components/BorderGroupForm';
import 'assets/scss/page/organizationMaster/style.scss';
import { ROLE, SCREEN_NAME } from '../../../helpers/constants';
import { ORGANIZATION_ENDPOINT } from '../../../constants/endpoints';
import { getRoleOrganization } from '../../../helpers/helpers';
import { pushToast } from '../../../components/Toast';
import { useHistory } from 'react-router';
import DetailOrganization from '../../../components/DetailOrganization';
import FooterMaintenance from "components/FooterMaintenance";

const CreateOrganization = ({}) => {
  const history = useHistory();
  const colorScreen = useSelector((store) => store.utils.colorMe);
  let typeRole = (process.browser && new URL(window.location.href).searchParams.get('typeRole')) || null;
  const [dataOrganizationLogin, setDataOrganizationLogin] = useState();
  const [roleOrganization, setRoleOrganization] = useState();
  const newRole = localStorage.getItem('role');

  const getOrganizationLogin = async () => {
    try {
      const data = await ORGANIZATION_ENDPOINT.GET_ORAGINIZATION_LOGIN();
      if (data) {
        setDataOrganizationLogin(data?.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (newRole) {
      setRoleOrganization(getRoleOrganization({ idRole: newRole }));
    }
  }, []);

  const createOrganization = async (body) => {
    try {
      if(typeRole !== 'management') {
        delete body.getQualified;
      }
      const data = await (roleOrganization === ROLE.PARTNERGROUP && typeRole === 'management'
        ? ORGANIZATION_ENDPOINT.CREATE_PARTNER(body)
        : roleOrganization === ROLE.PARTNERGROUP && typeRole === 'monitoring'
        ? ORGANIZATION_ENDPOINT.CREATE_CLIENT(body)
        : ORGANIZATION_ENDPOINT.CREATE_ORAGINIZATION(body));
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
      } else {
        pushToast('success', 'Create Organization success');
        history.push('/organizations');
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleText = (typeRole) => {
    switch (typeRole) {
      case 'monitoring':
        return 'Monitoring_新規作成';
      case 'erp':
        return 'ERP_新規作成';
      case 'management':
        return 'Management_新規作成';
      case 'management-group':
        return 'ManagementGroup_新規作成';
      default:
        return '';
    }
  }

  const onClickSubmit = (data) => {
    createOrganization({ ...data, screenName: SCREEN_NAME.ORGANIZATION, updateTitle: `更新 ${handleText(typeRole)} ${dataOrganizationLogin?.officialName || ''}` });
  };

  useEffect(() => {
    if (roleOrganization === ROLE.PARTNERGROUP) {
      getOrganizationLogin();
    }
  }, [roleOrganization]);

  return (
    <div className="main-modifly-organization ">
      <HeaderMasterSetting color={colorScreen?.code} colorText={colorScreen?.colorText} nameRole={typeRole}/>
      <BorderGroupForm color={colorScreen?.code}>
        <DetailOrganization
          dataOrganizationLogin={dataOrganizationLogin}
          roleOrganization={roleOrganization}
          onClickSubmit={onClickSubmit}
          nameRole={typeRole}
          newRole={newRole}
        />
      </BorderGroupForm>
      <FooterMaintenance />
    </div>
  );
};

export default CreateOrganization;
