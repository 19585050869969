import React, { useEffect, useState } from 'react';
import './style.scss';
import { CLIENT_ENDPOINT, USER_ENDPOINT } from '../../../../../constants/endpoints';
import { pushToast } from '../../../../../components/Toast';
import { generatePassword } from 'helpers/helpers';

const mock_user_data = {
  loginId: '',
  loginPassword: '',
  firstNameKana: '',
  lastNameKana: '',
  firstName: '',
  lastName: '',
  phoneNumber: '',
  email: '',
};

const AddNewUserCenter = ({ role = 'center', createSucces }) => {
  const [userData, setUserData] = useState(mock_user_data);
  const [phoneNumber1, setPhoneNumber1] = useState(mock_user_data.phoneNumber.split('-')[0] || '');
  const [phoneNumber2, setPhoneNumber2] = useState(mock_user_data.phoneNumber.split('-')[1] || '');
  const [phoneNumber3, setPhoneNumber3] = useState(mock_user_data.phoneNumber.split('-')[2] || '');
  const [submit, setSubmit] = useState(false);
  const [productRole, setProductRole] = useState([]);
  const [allClient, setAllClient] = useState([]);
  const idProvider = localStorage.getItem('idProvide');

  const getAllClient = async () => {
    try {
      const data = await CLIENT_ENDPOINT.GET_ALL_CLIENT();
      if (data) {
        setAllClient(data?.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getAllClient();
  }, []);

  useEffect(() => {
    if (role) {
      if (role === 'center') {
        setProductRole('1');
      } else if (role === 'client') {
        setProductRole('11');
      }
    }
  }, [role]);

  const handleChangeText = (e) => {
    let name = e.target.name;
    let newUserData = { ...userData };
    newUserData = { ...newUserData, [name]: e.target.value };
    setUserData(newUserData);
  };

  const handleChangePhoneNumber = (e) => {
    if (e.target.name === 'phoneNumber1') {
      setPhoneNumber1(e.target.value);
      let newUserData = { ...userData };
      newUserData = { ...newUserData, phoneNumber: e.target.value + '-' + phoneNumber2 + '-' + phoneNumber3 };
      setUserData(newUserData);
    } else if (e.target.name === 'phoneNumber2') {
      setPhoneNumber2(e.target.value);
      let newUserData = { ...userData };
      newUserData = { ...newUserData, phoneNumber: phoneNumber1 + '-' + e.target.value + '-' + phoneNumber3 };
      setUserData(newUserData);
    } else if (e.target.name === 'phoneNumber3') {
      setPhoneNumber3(e.target.value);
      let newUserData = { ...userData };
      newUserData = { ...newUserData, phoneNumber: phoneNumber1 + '-' + phoneNumber2 + '-' + e.target.value };
      setUserData(newUserData);
    }
  };

  useEffect(() => {
    if (
      userData.loginId.length > 0 &&
      userData.loginPassword.length > 0 &&
      userData.firstNameKana.length > 0 &&
      userData.firstName.length > 0 &&
      userData.lastNameKana.length > 0 &&
      userData.lastName.length > 0 &&
      userData.email.length > 0
    ) {
      setSubmit(true);
    } else {
      setSubmit(false);
    }
  }, [userData]);

  const onClickMakePass = () => {
    let newPass = generatePassword();
    let newUserData = { ...userData };
    newUserData = { ...newUserData, loginPassword: newPass };
    setUserData(newUserData);
  };

  const updateRole = async ({ userId = '', body = {} }) => {
    try {
      const data = await USER_ENDPOINT.UP_DATE_USER_INFO({ userId: userId, body: body });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
      } else {
        setUserData(mock_user_data);
        createSucces({ role: role });
        pushToast('success', 'Create user success');
      }
    } catch (errorMessage) {
      console.log('errorMessage: ', errorMessage);
    }
  };

  const userRegister = async (body) => {
    try {
      const data = await USER_ENDPOINT.USER_REGISTER(body);
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
      } else {
        updateRole({
          userId: data?.data?.idUser,
          body: {
            organization: [
              {
                idOrganization: idProvider,
                idRole: productRole,
              },
            ],
          },
        });
      }
    } catch (errorMessage) {
      console.log('errorMessage: ', errorMessage);
    }
  };

  const onClickCreateUser = () => {
    if (userData) {
      userRegister(userData);
    }
  };

  return (
    <div className="wrapper-add-new-user-center">
      <div className="table-user-info d-flex flex-wrap">
        {role === 'client' ? (
          <>
            <div className="table-left col-2 p-2">
              <label className="mb-0">クライアント名</label>
            </div>
            <div className="table-right col-10 p-2">
              <div style={{ width: '207px' }}>
                <select className="form-control">
                  <option value="new">選択して下さい</option>
                  {allClient.map((item, index) => (
                    <option value={item?.userId} key={index}>
                      {item?.firstName}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
        <div className="table-left col-2 p-2">
          <label className="mb-0">利用者ID</label>
        </div>
        <div className="table-right col-10 p-2">
          <input
            className={`form-control mb-0 col-2 ${userData.loginId === '' ? 'input-err' : ''}`}
            name="loginId"
            onChange={handleChangeText}
            value={userData.loginId}
          />
        </div>
        <div className="table-left col-2 p-2">
          <label className="mb-0">ログインパスワード</label>
        </div>
        <div className="table-right col-10 p-2 d-flex">
          <input
            className={`form-control mb-0 col-2 ${userData.loginPassword === '' ? 'input-err' : ''}`}
            name="loginPassword"
            onChange={handleChangeText}
            value={userData.loginPassword}
          />
          <button className="btn ml-2 btn-light" onClick={() => onClickMakePass()}>
            pw自動生成
          </button>
        </div>
        <div className="table-left col-2 p-2">
          <label className="mb-0">フリガナ</label>
        </div>
        <div className="table-right col-10 p-2 d-flex">
          <label className="mb-0">セイ：</label>
          <input
            className={`form-control mb-0 col-2 ${userData.firstNameKana === '' ? 'input-err' : ''}`}
            name="firstNameKana"
            onChange={handleChangeText}
            value={userData.firstNameKana}
          />
          <label className="mb-0">メイ：</label>
          <input
            className={`form-control mb-0 col-2 ${userData.lastNameKana === '' ? 'input-err' : ''}`}
            name="lastNameKana"
            onChange={handleChangeText}
            value={userData.lastNameKana}
          />
        </div>
        <div className="table-left col-2 p-2">
          <label className="mb-0">利用者名</label>
        </div>
        <div className="table-right col-10 p-2 d-flex">
          <label className="mb-0">姓：</label>
          <input
            className={`form-control mb-0 col-2 ${userData.firstName === '' ? 'input-err' : ''}`}
            name="firstName"
            onChange={handleChangeText}
            value={userData.firstName}
          />
          <label className="mb-0">名：</label>
          <input
            className={`form-control mb-0 col-2 ${userData.lastName === '' ? 'input-err' : ''}`}
            name="lastName"
            onChange={handleChangeText}
            value={userData.lastName}
          />
        </div>
        <div className="table-left col-2 p-2">
          <label className="mb-0">ユーザー連絡先</label>
        </div>
        <div className="table-right col-10 p-2 d-flex">
          <input
            className="form-control mb-0 col-3"
            name="phoneNumber1"
            onChange={handleChangePhoneNumber}
            value={phoneNumber1}
            maxLength="4"
          />
          <label className="mb-0">-</label>
          <input
            className="form-control mb-0 col-3"
            name="phoneNumber2"
            onChange={handleChangePhoneNumber}
            value={phoneNumber2}
            maxLength="4"
          />
          <label className="mb-0">-</label>
          <input
            className="form-control mb-0 col-3"
            name="phoneNumber3"
            onChange={handleChangePhoneNumber}
            value={phoneNumber3}
            maxLength="4"
          />
        </div>
        <div className="table-left col-2 p-2">
          <label className="mb-0">ユーザーemail</label>
        </div>
        <div className="table-right col-10 p-2 d-flex">
          <input
            className={`form-control mb-0 col-3 ${userData.email === '' ? 'input-err' : ''}`}
            name="email"
            onChange={handleChangeText}
            value={userData.email}
          />
        </div>
      </div>
      <div className="d-flex justify-content-center py-3">
        <button
          className={`btn ${role === 'client' ? 'btn-success-blue' : 'button-succes'}`}
          disabled={!submit}
          onClick={() => onClickCreateUser()}
        >
          {role === 'client' ? '新規クライアントユーザー登録' : '新規センターユーザー登録'}
        </button>
      </div>
    </div>
  );
};

export default AddNewUserCenter;
