import React, { useEffect, useState } from 'react';
import { ANKEN_MASTER_NEW } from '../../../../../constants/endpoints';
import { pushToast } from '../../../../../components/Toast';
import Loading from 'components/Loading/Loading';
import 'assets/scss/page/AnkenMasterNew/TableAddRole.scss';
import { translate } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { getDataHistoryMasterSetting } from 'actions/authentication';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import { SCREEN_NAME } from '../../../../../helpers/constants';

const TableAddRoleNext = ({ routeList, nameScreen, t, idProvide = '', idProduct , namePermission, nameTitle }) => {
  const dispatch = useDispatch();
  const [ListOrderSetting, setListOrderSetting] = useState({});
  const [dataUpdate, setDataUpdate] = useState({});
  const [orderSettingId, setOrderSettingId] = useState('');
  const [loading, setLoading] = useState(false);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return dataResult(result);
  };
  const dataResult = (result) => result.map((i, index) => { i[1].key = index; return i; });

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const items = reorder(_.orderBy(_.toPairs(ListOrderSetting), 'key'), result.source.index, result.destination.index);
    setListOrderSetting(_.fromPairs(items));
  };

  useEffect(() => {
    if (idProduct) {
      getOrderSettingByProduct({role: routeList, nameScreen, idProduct});
    };
  }, [idProduct]);

  useEffect(() => {
    getOrderSettingByProduct({role: routeList, nameScreen, idProduct});
  }, []);

  const onChangeStatus = (value, item) => {
    let data = {
      ...ListOrderSetting,
      [item]: { ...ListOrderSetting[item], value: !value }
    }

    let onDataUpdate = {
      ...dataUpdate,
      [item]: { ...ListOrderSetting[item], value: !value}
    }
    setListOrderSetting(data);
    setDataUpdate(onDataUpdate);
  };

  const getOrderSettingByProduct = async ({role, nameScreen, idProduct}) => {
    try {
      setLoading(true);
      const data = await ANKEN_MASTER_NEW.GET_ORDER_SETTING_BY_PRODUCT({ role, nameScreen, idProduct });
      if (data) {
        setListOrderSetting(nameScreen === 'delivered_info' && [2, '2'].includes(role) ? _.omit(data?.data?.result || {}, 'timestampReportUploadInfo') : data?.data?.result);
        setOrderSettingId(data?.data?.orderSettingId);
      }
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const handleUpdateListRoleChild = async ({ body, nameScreen, idProduct }) => {
    try {
      setLoading(true);
      const data = await ANKEN_MASTER_NEW.SETTING_ORDER_HEADER_BY_PRODUCT({ body, idProduct, nameScreen });
      if (data && data.code !== 0) {
          if (data && data.message && data.message.extra) {
              pushToast('error', data?.message?.extra);
          } else {
              pushToast('error', data?.message?.errorMessage);
          }
      } else {
        pushToast('success', 'Update success');
        dispatch(getDataHistoryMasterSetting({ logId: '', screenName: SCREEN_NAME.CASE_MASTA }));
      }
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const onUpdateListRoleChild = () => {
    const body = {
      nameRole: namePermission,
      [nameScreen]: ListOrderSetting,
      updateTitle: `保存 表示項目設定 ${namePermission} ${nameTitle || ''}`,
      screenName: SCREEN_NAME.CASE_MASTA
    }
    handleUpdateListRoleChild({ body, nameScreen, idProduct });
  };

  const renderTableInfo = (data) => {
    return (
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>表示順</th>
            <th>項目名</th>
            <th>表示設定</th>
          </tr>
        </thead>

        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <tbody
                {...provided.droppableProps} ref={provided.innerRef}
              className="scroll-table">
              {data &&
                Object.keys(data)?.map((item, index) => {
                  return (
                    <Draggable key={`${index + 1}`} draggableId={`${index + 1}`} index={index}>
                      {(provided) => (
                        <tr
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        key={index}>
                        <td>{index + 1}</td>
                        <td className='td__item'>
                          {['center', 'partnergroup'].includes(namePermission)
                            ? t(`table_order_list_center.${item}`)
                            : namePermission === 'partner'
                            ? t(`table_order_list_partner.${item}`)
                            : namePermission === 'client'
                            ? t(`table_order_list_client.${item}`)
                            : ''}
                        </td>
                        <td className='td__check'>
                          <span className="input-check">
                            <input
                              type="radio"
                              checked={data[item].value}
                              onChange={() => onChangeStatus(data[item].value, item)}
                            />
                            <label>表示</label>
                          </span>
                          <span className="input-check">
                            <input
                              type="radio"
                              onChange={() => onChangeStatus(data[item].value, item)}
                              checked={!data[item].value} />
                            <label>非表示</label>
                          </span>
                        </td>

                      </tr>
                      )}
                  </Draggable>
                  );
                })}
                {provided.placeholder}
              </tbody>
            )}
          </Droppable>
        </DragDropContext>
      </table>
    );
  };

  return (
    <div className="table-info-company ">
      <Loading loading={loading} />
      {renderTableInfo(ListOrderSetting)}
      <button
        className="btn-submit"
        onClick={onUpdateListRoleChild}
        disabled={Object.keys(ListOrderSetting || {}).length === 0}
      >保存</button>
    </div>
  );
};

export default compose(translate('translations'), withRouter)(TableAddRoleNext);
