import React, { useState, useEffect } from 'react';
import { Collapse } from 'reactstrap';
import 'react-datepicker/dist/react-datepicker.css';
import 'assets/scss/page/center/Users/style.scss';
import { ORDER } from 'constants/endpoints';
import PaginationCustom from 'components/PaginationCustom';
import OrderList from '../OrderList';
import Loading from 'components/Loading/Loading';
import _ from 'lodash';

const ProductList = ({ colors, colorText, item = [], isShow, handleCollap, isShowAll }) => {
  const toggle = () => handleCollap(item.productId);
  const [activePage, setActivePage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [dataTable, setDataTable] = useState(item);
  const [isShowAlls, setIsShowAlls] = useState(false);

  const paginationDefault = {
    page: 1, limit: 10,
  }

  useEffect(() => {
    setDataTable(item);
  }, [item]);

  useEffect(() => {
    setIsShowAlls(item);
  }, [isShowAll]);

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    getListCancelInfo({ page: pageNumber, productId: item?.productId });
  };

  const getListCancelInfo = async ({
    page = paginationDefault.page,
    limit = paginationDefault.limit,
    pageProduct = paginationDefault.page,
    limitProduct = paginationDefault.limit,
    productId = '',
    productName = '',
    caseName = '',
    managementCode = '',
    serviceName = '',
    requestedDateTo = '',
    requestedDateFrom = '',
    operationAdjustmentDateTo = '',
    operationAdjustmentDateFrom = '',
    applicantCompanyName = '',
    resultsPreparation = '',
    visitDateTo = '',
    visitDateFrom = '',
    visitedBaseName = '',
    partnerName = '',
  }) => {
    setLoading(true);
    try {
      const data = await ORDER.GET_LIST_RENEW({
        page,
        limit,
        pageProduct: isShowAlls ? 0 : pageProduct,
        limitProduct,
        productName,
        caseName,
        managementCode,
        serviceName,
        productId,
        requestedDateTo,
        requestedDateFrom,
        operationAdjustmentDateTo,
        operationAdjustmentDateFrom,
        applicantCompanyName,
        resultsPreparation,
        visitDateTo,
        visitDateFrom,
        visitedBaseName,
        partnerName,
      });
      if (data) {
        setLoading(false);
        setDataTable(data?.data?.products?.[0])
      }
    } catch (e) {
      setLoading(true);
      console.log(e);
    }
  };

  return (
    <div className="setting-master-menu-wrapper">
      <Loading loading={loading} />
      <div className="wrapper-btn-colappse">
        <div onClick={toggle} className="mb-1 searchbar d-flex justify-content-between align-items-center" style = {{ background: `${colors}` }}>
          <div className="ml-2" style={{ color: `${colorText}` }}>{item?.caseName}</div>
          <div className="mr-2" style={{ color: `${colorText}` }}>
            {isShow === item.productId ? <i className="icon-up-open icon-searchbar" /> : <i className="icon-down-open icon-searchbar" />}
          </div>
        </div>
      </div>
      <div>
        <Collapse isOpen={isShow === item.productId}>
          <div className="form__content">
            <div className="form__flex form__flex--end">
              <OrderList colors={colors} headers={dataTable?.header} body={dataTable?.body} activePage={activePage} />
            </div>
              <div className="form__flex form__flex--mgt-10">
            </div>
            {
              !_.isEmpty(item) && (item?.count || 0) > paginationDefault.limit && (
                <PaginationCustom
                activePage={activePage}
                itemsCountPerPage={paginationDefault.limit}
                totalItemsCount={item?.count || 0}
                pageRangeDisplayed={5}
                handlePageChange={handlePageChange} />
              )
            }
          </div>
        </Collapse>
      </div>
    </div>
  );
};

export default ProductList;
