import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import './style.scss';
import TableSelectUser from './TableSelectUser';
import TableUpdateHistory from './TableUpdateHistory';
import { Helmet } from 'react-helmet';
import { USER_ENDPOINT } from '../../../constants/endpoints';
import { pushToast } from '../../../components/Toast';
import { generatePassword } from 'helpers/helpers';

const data_product = {
  data: {
    header: ['productId', 'endDate', 'name', 'customerCode', 'updatedDate', 'customerName', 'startDate'],
    body: [
      {
        productId: '77b06594-6ae4-466c-a7c3-1027d5514456',
        endDate: '2020/04/15',
        name: '［IPR］Etherアクセス（S）',
        customerCode: 'C0002',
        updatedDate: null,
        customerName: 'ソフトバンク株式会社',
        startDate: '2020/04/05'
      },
      {
        productId: '77b06594-6ae4-466c-a7c3-1027d5514459',
        endDate: '2020/04/15',
        name: '［IPR］光アクセス／プラス',
        customerCode: 'C0001',
        updatedDate: null,
        customerName: 'ソフトバンク株式会社',
        startDate: '2020/04/13'
      },
      {
        productId: '77b06594-6ae4-466c-a7c3-1027d5514465',
        endDate: '2020/04/15',
        name: 'Etherアクセス',
        customerCode: 'C0001',
        updatedDate: null,
        customerName: 'ソフトバンク株式会社',
        startDate: '2020/04/13'
      },
      {
        productId: '77b06594-6ae4-466c-a7c3-1027d5514451',
        endDate: '2020/04/15',
        name: 'Etherコネクト2',
        customerCode: 'C0010',
        updatedDate: null,
        customerName: 'ソフトバンク株式会社',
        startDate: '2020/04/14'
      },
      {
        productId: '77b06594-6ae4-466c-a7c3-1027d551445f',
        endDate: '2020/04/15',
        name: 'Gigaコネクト',
        customerCode: 'C0001',
        updatedDate: '2020/14/15',
        customerName: 'ソフトバンク株式会社',
        startDate: '2020/04/13'
      }
    ],
    count: 21
  },
  message: 'Success',
  code: 0,
  pagination: {
    page: 1,
    limit: 10
  }
};

const ENUM_ROLE = {
  NEOMARS: '1',
  ERP_ONE: '2',
  ERP_TWO: '3',
  ERP_THREE: '4',
  MANAGEMENT_GROUP_ONE: '5',
  MANAGEMENT_GROUP_TWO: '6',
  MANAGEMENT_GROUP_THREE: '7',
  MANAGEMENT_ONE: '8',
  MANAGEMENT_TWO: '9',
  MANAGEMENT_THREE: '10',
  MONITORING_ONE: '11',
  MONITORING_TWO: '12',
  MONITORING_THREE: '13',
  WORK: '14'
};

const mock_user_data = {
  loginId: '',
  loginPassword: '',
  firstNameKana: '',
  lastNameKana: '',
  firstName: '',
  lastName: '',
  phoneNumber: '',
  email: '',
  role: [],
  product: []
};

const data_right = [];

const ModifyStaff = (props) => {
  const { history } = props;
  const [itemLeftSelect, setItemLeftSelect] = useState(null);
  const [itemRightSelect, setItemRightSelect] = useState(null);

  const [dataLeft, setDataLeft] = useState(data_product.data.body);
  const [dataRight, setDataRight] = useState(data_right);

  const [userData, setUserData] = useState(mock_user_data);
  const [phoneNumber1, setPhoneNumber1] = useState(mock_user_data.phoneNumber.split('-')[0] || '');
  const [phoneNumber2, setPhoneNumber2] = useState(mock_user_data.phoneNumber.split('-')[1] || '');
  const [phoneNumber3, setPhoneNumber3] = useState(mock_user_data.phoneNumber.split('-')[2] || '');
  const [submit, setSubmit] = useState(false);
  // const [role, setRole] = useState(null);

  const handleChangePhoneNumber = (e) => {
    if (e.target.name === 'phoneNumber1') {
      setPhoneNumber1(e.target.value);
      let newUserData = { ...userData };
      newUserData = { ...newUserData, phoneNumber: e.target.value + '-' + phoneNumber2 + '-' + phoneNumber3 };
      setUserData(newUserData);
    } else if (e.target.name === 'phoneNumber2') {
      setPhoneNumber2(e.target.value);
      let newUserData = { ...userData };
      newUserData = { ...newUserData, phoneNumber: phoneNumber1 + '-' + e.target.value + '-' + phoneNumber3 };
      setUserData(newUserData);
    } else if (e.target.name === 'phoneNumber3') {
      setPhoneNumber3(e.target.value);
      let newUserData = { ...userData };
      newUserData = { ...newUserData, phoneNumber: phoneNumber1 + '-' + phoneNumber2 + '-' + e.target.value };
      setUserData(newUserData);
    }
  };

  useEffect(() => {
    if (
      userData.loginId.length > 0 &&
      userData.loginPassword.length > 0 &&
      userData.firstNameKana.length > 0 &&
      userData.firstName.length > 0 &&
      userData.lastNameKana.length > 0 &&
      userData.lastName.length > 0 &&
      userData.email.length > 0 &&
      userData.role.length > 0
    ) {
      setSubmit(true);
    } else {
      setSubmit(false);
    }
  }, [userData]);

  // let user_id = (process.browser && new URL(window.location.href).searchParams.get('sid')) || '';
  // let db = (process.browser && new URL(window.location.href).searchParams.get('db')) || '';
  //
  let type = (process.browser && new URL(window.location.href).searchParams.get('type')) || null;

  const handleChangeText = (e) => {
    let name = e.target.name;
    let newUserData = { ...userData };
    newUserData = { ...newUserData, [name]: e.target.value };
    setUserData(newUserData);
  };

  const handleChangeLeft = (event) => {
    setItemLeftSelect(event.target.selectedIndex);
    setItemRightSelect(null);
  };

  const handleChangeRight = (event) => {
    setItemRightSelect(event.target.selectedIndex);
    setItemLeftSelect(null);
  };

  const onClickInsert = (index) => {
    if (index !== null) {
      let newDataRight = [...dataRight];
      newDataRight.push(dataLeft[index]);
      setDataRight(newDataRight);
      let newDataLeft = [...dataLeft];
      newDataLeft.splice(index, 1);
      setDataLeft(newDataLeft);
      setItemLeftSelect(null);
      // let newUserData = { ...userData };
      let newUserData = { ...userData, product: [...userData.product, dataLeft[index].productId] };
      setUserData(newUserData);
    }
  };

  const onClickDel = (index) => {
    if (index !== null) {
      let newDataLeft = [...dataLeft];
      newDataLeft.push(dataRight[index]);
      setDataLeft(newDataLeft);
      let newDataRight = [...dataRight];
      newDataRight.splice(index, 1);
      setDataRight(newDataRight);
      setItemRightSelect(null);

      let newUserData = { ...userData };
      let newProduct = newUserData.product.filter((item) => item !== dataRight[index].productId);
      newUserData = { ...newUserData, product: newProduct };
      setUserData(newUserData);
    }
  };

  const onSelectRule = (e) => {
    if (e.target.value) {
      let newUserData = { ...userData, role: [e.target.value] };
      setUserData(newUserData);
    } else {
      let newUserData = { ...userData, role: [] };
      setUserData(newUserData);
    }
  };

  const onClickMakePass = () => {
    let newPass = generatePassword();
    let newUserData = { ...userData };
    newUserData = { ...newUserData, loginPassword: newPass };
    setUserData(newUserData);
  };

  const userRegister = async (body) => {
    try {
      const data = await USER_ENDPOINT.USER_REGISTER(body);
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
      } else {
        history.push('/center_staff_master?db=center&top_flag=1');
        console.log('dang ki thanh cong');
      }
    } catch (errorMessage) {
      console.log('errorMessage: ', errorMessage);
    }
  };

  const onClickSubmit = () => {
    if (userData) {
      userRegister(userData);
    }
  };

  return (
    <div className="wrapper-modify-staff">
      <Helmet>
        <title>ユーザー詳細</title>
      </Helmet>
      <p className="text-center mb-0">ユーザー詳細</p>
      <div className="table-user-info d-flex flex-wrap">
        <div className="table-left col-2 p-2">
          <label className="mb-0">Role</label>
        </div>
        <div className="table-right col-10 p-2">
          <select
            className={`form-control mb-0 col-3 ${userData.role.length > 0 ? '' : 'input-err'}`}
            onChange={onSelectRule}
          >
            <option value={null}></option>
            {Object.keys(ENUM_ROLE).map((item, index) => (
              <option key={index} value={ENUM_ROLE[item]}>
                {item}
              </option>
            ))}
          </select>
        </div>
        <div className="table-left col-2 p-2">
          <label className="mb-0">利用者ID</label>
        </div>
        <div className="table-right col-10 p-2">
          <input
            className={`form-control mb-0 col-2 ${userData.loginId === '' ? 'input-err' : ''}`}
            name="loginId"
            onChange={handleChangeText}
            value={userData.loginId}
          />
        </div>
        <div className="table-left col-2 p-2">
          <label className="mb-0">ログインパスワード</label>
        </div>
        <div className="table-right col-10 p-2 d-flex">
          <input
            className={`form-control mb-0 col-2 ${userData.loginPassword === '' ? 'input-err' : ''}`}
            name="loginPassword"
            onChange={handleChangeText}
            value={userData.loginPassword}
          />
          <label className="btn ml-2 btn-light" onClick={() => onClickMakePass()}>
            pw自動生成
          </label>
        </div>
        <div className="table-left col-2 p-2">
          <label className="mb-0">フリガナ</label>
        </div>
        <div className="table-right col-10 p-2 d-flex">
          <label className="mb-0">セイ：</label>
          <input
            className={`form-control mb-0 col-2 ${userData.firstNameKana === '' ? 'input-err' : ''}`}
            name="firstNameKana"
            onChange={handleChangeText}
            value={userData.firstNameKana}
          />
          <label className="mb-0">メイ：</label>
          <input
            className={`form-control mb-0 col-2 ${userData.lastNameKana === '' ? 'input-err' : ''}`}
            name="lastNameKana"
            onChange={handleChangeText}
            value={userData.lastNameKana}
          />
        </div>
        <div className="table-left col-2 p-2">
          <label className="mb-0">利用者名</label>
        </div>
        <div className="table-right col-10 p-2 d-flex">
          <label className="mb-0">姓：</label>
          <input
            className={`form-control mb-0 col-2 ${userData.firstName === '' ? 'input-err' : ''}`}
            name="firstName"
            onChange={handleChangeText}
            value={userData.firstName}
          />
          <label className="mb-0">名：</label>
          <input
            className={`form-control mb-0 col-2 ${userData.lastName === '' ? 'input-err' : ''}`}
            name="lastName"
            onChange={handleChangeText}
            value={userData.lastName}
          />
        </div>
        <div className="table-left col-2 p-2">
          <label className="mb-0">ユーザー連絡先</label>
        </div>
        <div className="table-right col-10 p-2 d-flex">
          <input
            className="form-control mb-0 col-3"
            name="phoneNumber1"
            onChange={handleChangePhoneNumber}
            value={phoneNumber1}
            maxLength="4"
          />
          <label className="mb-0">-</label>
          <input
            className="form-control mb-0 col-3"
            name="phoneNumber2"
            onChange={handleChangePhoneNumber}
            value={phoneNumber2}
            maxLength="4"
          />
          <label className="mb-0">-</label>
          <input
            className="form-control mb-0 col-3"
            name="phoneNumber3"
            onChange={handleChangePhoneNumber}
            value={phoneNumber3}
            maxLength="4"
          />
        </div>
        <div className="table-left col-2 p-2">
          <label className="mb-0">ユーザーemail</label>
        </div>
        <div className="table-right col-10 p-2 d-flex">
          <input
            className={`form-control mb-0 col-3 ${userData.email === '' ? 'input-err' : ''}`}
            name="email"
            onChange={handleChangeText}
            value={userData.email}
          />
          {!type && <label className="btn btn-light ml-2">ログイン情報を送信</label>}
        </div>
        <div className="table-left col-2 p-2">
          <label className="mb-0">稼働可能案件 </label>
        </div>
        <div className="table-right col-10 p-2 d-flex">
          <div className="wrapper-list-select d-flex w-100">
            <div className="list-of-cases col-5">
              <label className="mb-0">案件一覧</label>
              <TableSelectUser data={dataLeft} handleChange={handleChangeLeft} selected={itemLeftSelect}/>
            </div>
            <div className="col-1 d-flex justify-content-center align-items-center flex-column">
              <i
                className="fa fa-arrow-circle-right cursor-pointer my-1 btn-add"
                onClick={() => onClickInsert(itemLeftSelect)}
              />
              <i
                className="fa fa-arrow-circle-left cursor-pointer my-1 btn-add"
                onClick={() => onClickDel(itemRightSelect)}
              />
            </div>
            <div className="list-of-cases col-5">
              <label className="mb-0">稼働案件</label>
              <TableSelectUser data={dataRight} handleChange={handleChangeRight} selected={itemRightSelect}/>
            </div>
          </div>
        </div>
        {!type && (
          <>
            <div className="table-left col-2 p-2">
              <label className="mb-0">データ更新日 </label>
            </div>
            <div className="table-right col-10 p-2 d-flex"></div>
            <div className="table-left col-2 p-2">
              <label className="mb-0">データ更新日 </label>
            </div>
            <div className="table-right col-10 p-2 d-flex"></div>
          </>
        )}
      </div>
      <div className="d-flex align-items-center flex-column">
        <button
          className={`btn btn-light my-3 ${submit ? 'button-succes' : 'button-submit'}`}
          disabled={!submit}
          onClick={() => onClickSubmit()}
        >
          更新
        </button>
        {!type && <button className="btn button-submit button-succes mb-3">ユーザー利用停止</button>}
        <label className="mb-0">ユーザーマスター更新履歴</label>
      </div>

      <div className="wrapper-table-update-history">
        <TableUpdateHistory type={type}/>
      </div>
    </div>
  );
};

export default compose(withRouter)(ModifyStaff);
