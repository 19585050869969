import 'assets/scss/page/DispatchMap/TableListOrder/style.scss';
import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react'
import { useSelector } from 'react-redux';
import { ORDER } from 'constants/endpoints';
import Loading from '../../../../components/Loading/Loading';
import PaginationCustom from '../../../../components/PaginationCustom';
import _ from 'lodash';

const initListOrderConditionInfo = { header: [], listOrder: [], countSummary: {}, count: 0 }

const TableListOrder = ({ visitDate, translate }, ref) => {
  const colorScreen = useSelector((store) => store.utils.colorMe);
  const [listOrderConditionInfo, setListOrderConditionInfo] = useState(initListOrderConditionInfo)
  const [isLoading, setLoading] = useState(false);
  const [queryCondtion, setQueryCondition] = useState({ page: 1, limit: 10, visitDate, prefecturesVisited: '', staffLoginId: '' })

  useEffect(() => {
    getListOrderCondtionInfor();
  }, []);

  useImperativeHandle(ref, () => ({
    callGetListOrderCondtionInfor: (params = {}) => {
      getListOrderCondtionInfor(params)
    },
  }), []);

  const getListOrderCondtionInfor = async (params = {}) => {
    try {
      params = { ...queryCondtion, pageProduct: 0, ...params }
      if (_.isEmpty(params?.staffLoginId)) return;

      setLoading(true)
      const jsonResult = await ORDER.GET_LIST_ORDER_DISPATCH_MAP(params)
      if (jsonResult.code === 0) {
        const product = jsonResult?.data?.products[0]
        if (product) {
          setListOrderConditionInfo({
            ...listOrderConditionInfo,
            count: product.count || 0,
            header: product.header || [],
            listOrder: Array.isArray(product.body) ? product.body : [],
            countSummary: product.countSummary || {},
          })
        } else {
          setListOrderConditionInfo(initListOrderConditionInfo)
        }
      } else {
        setListOrderConditionInfo(initListOrderConditionInfo)
      }
      setLoading(false)
      setQueryCondition(params)
    } catch (error) {
      console.error(error);
      setLoading(false)
    }
  }

  const handlePageChange = (page) => {
    setQueryCondition({ ...queryCondtion, page })
    getListOrderCondtionInfor({ page })
  }

  const renderHeaderTable = () => {
    const styles = {
      backgroundColor: colorScreen.code || '#c38743',
      color: colorScreen.colorText || 'black',
    }
    return (
      <thead>
        <tr className="header-table" style={styles}>
          <th>No</th>
            <th>{translate('table_order_list.managementCode')}</th>
            <th>{translate('table_order_list.installationSiteName')}</th>
        </tr>
      </thead>
    )
  }

  const renderRowItem = (orderDetai, index) => {
    return (
      <tr key={index}>
        <td> {(queryCondtion.page - 1) * queryCondtion.limit + (index + 1)} </td>
        <td> {orderDetai?.managementCode} </td>
        <td> {orderDetai?.installationSiteName} </td>
      </tr>
    )
  }

  const renderTableContent = () => {
    return listOrderConditionInfo.listOrder.map((orderDetai, index) => {
      return renderRowItem(orderDetai, index)
    })
  }

  return (
    <div className="list-order-in-map-screen">
      <Loading loading={isLoading} />
      <table className='table-list-order'>
        { listOrderConditionInfo.header.length > 0 && renderHeaderTable()}
        <tbody className="body-table">
          {renderTableContent()}
        </tbody>
      </table>
      {listOrderConditionInfo?.count > 10 && (
        <PaginationCustom
          activePage={queryCondtion.page}
          itemsCountPerPage={queryCondtion.limit}
          totalItemsCount={listOrderConditionInfo?.count}
          pageRangeDisplayed={5}
          handlePageChange={handlePageChange}
        />
      )}
    </div>
  );
}

export default forwardRef(TableListOrder)
