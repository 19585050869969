import React, { useEffect, useState } from 'react';
import { BRANCH_MASTER_ENDPOINT } from '../../../../constants/endpoints';
import { Link } from 'react-router-dom'
import 'assets/scss/page/StaffMaster/TablieStaffMasterHeader/style.scss';

const dataSearchMock = {
  page: 1,
  limit: 10,
  searchName: '',
  contact: '',
  branchId: ''
};

const TableStaffMasterHeader = ({ getListStaff = () => {
} }) => {
  const [dataSearch, setDataSearch] = useState(dataSearchMock);
  const [listBranchMaster, setListBranchMaster] = useState([]);

  const getListBranchMaster = async () => {
    try {
        const data = await BRANCH_MASTER_ENDPOINT.GET_LIST_BRANCH_MASTER({
            page: 0,
        });
        if (data) {
            setListBranchMaster(data?.data?.body);
        }
    } catch (e) {
        console.log(e);
    }
  };

  useEffect(() => {
      getListBranchMaster();
  }, []);


  const handleChangeSearch = (e) => {
    const fieldName = e.target.name;
    let newData = { ...dataSearch, [fieldName]: e.target.value };
    setDataSearch(newData);
  };

  const onSubmit = () => {
    getListStaff(dataSearch);
  };


  return (
    <div className="Wrapper-table-staff-master-header">
      <div className="header-action-bar">
        <Link to="staff_master/create_staff_master">
          <button className="btn">Worker利用者登録</button>
        </Link>
        {/* <div className="action-right">
          <button className="btn">テンプレート</button>
          <button className="btn">CSV取込</button>
          <button className="btn">CSV出力</button>
        </div> */}
      </div>

      <div className="group-action-form-search">
        <select
          name="branchId"
          className="selection-branch"
          onChange={handleChangeSearch}
          value={dataSearch?.branchId}
        >
          <option value="">----</option>
          {
            listBranchMaster ?.map((item, index) => {
              return (
                <option value={item.branchId} key={index}>{item.branchName}</option>
              )
            })
          }
        </select>
        <div className="group-action-form-search--right">
          <label className="">検索条件</label>
          <input
            className="search-input"
            name="searchName"
            value={dataSearch?.searchName}
            onChange={handleChangeSearch}
          />
          <button
            className="btn btn-search"
            onClick={() => onSubmit()}
          >
            検索
          </button>
        </div>
      </div>
    </div>
  );
};

export default TableStaffMasterHeader;
