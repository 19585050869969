import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import '../../../../assets/scss/page/CsvMaster/index.scss';
import { CONTRACT_ENDPOINTS } from '../../../../constants/endpoints';
import Loading from 'components/Loading/Loading';
import _ from 'lodash';

const mockData = {
  providerId: '',
  provider: 'monitor',
};

const ModalMonitor = (props) => {
  const { toggle = () => {}, modal = false, onSubmit, recruitmentContractId, dataTree = {} } = props;
  const [listMonitor, setListMonitor] = useState([]);
  const [listProduct, setListProduct] = useState([]);
  const [dataMonitor, setDataMonitor] = useState(mockData);
  const [loading, setLoading] = useState(false);

  const handleSelect = (e) => {
    let newData = {
      ...dataMonitor,
      providerId: e.target.value,
      providerName: e.target.options[e.target.selectedIndex].text,
    };
    setDataMonitor(newData);
  };

  const handleSelectProduct = (e) => {
    let newData = {
      ...dataMonitor,
      productId: e.target.value,
      productName: e.target.options[e.target.selectedIndex].text,
    };
    setDataMonitor(newData);
  };

  useEffect(() => {
    if (recruitmentContractId) {
      setDataMonitor({ ...dataMonitor, recruitmentContractId: recruitmentContractId });
    }
    getListMonitor({ recruitmentContractId: recruitmentContractId });
  }, []);

  const getListMonitor = async ({ recruitmentContractId: recruitmentContractId }) => {
    try {
      setLoading(true);
      const data = await CONTRACT_ENDPOINTS.GET_LIST_MONITOR({ recruitmentContractId: recruitmentContractId });
      if (data) {
        setListMonitor(
          _.filter(data?.data, function (elm) {
            return !Object.keys(dataTree).includes(elm.clientId);
          })
        );
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const getListProduct = async ({ providerId }) => {
    setLoading(true);
    try {
      const data = await CONTRACT_ENDPOINTS.GET_LIST_PRODUCT({ providerId: providerId });
      if (data) {
        setListProduct(
          _.filter(data?.data, function (elm) {
            return !Object.keys(dataTree).includes(elm.productId);
          })
        );
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  useEffect(() => {
    if (dataMonitor?.providerId) {
      getListProduct({ providerId: dataMonitor?.providerId });
    }
  }, [dataMonitor?.providerId]);

  const addMonitor = () => {
    onMonitorSubmit(dataMonitor);
  };

  const onMonitorSubmit = async (body) => {
    onSubmit(body, 'monitor');
  };

  return (
    <Modal className="form-modal-contract" isOpen={modal} toggle={() => toggle('monitor')}>
      <Loading loading={loading} />
      <ModalHeader toggle={() => toggle('monitor')}>Monitoring新規登録</ModalHeader>
      <ModalBody>
        <div className="block-content">
          <div className="item-content">
            <label>Monitoring名</label>
            <select onChange={handleSelect}>
              <option value="">---</option>
              {listMonitor?.map((item, index) => {
                return (
                  <option key={index} value={item.clientId}>
                    {item.name}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="item-content">
            <label>工事名</label>
            <select onChange={handleSelectProduct}>
              <option option value="">
                ---
              </option>
              {listProduct?.map((item, index) => {
                return (
                  <option key={index} value={item.productId}>
                    {item.name}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <button
          className="btn-update"
          disabled={
            !_.isEmpty(dataMonitor?.productId) && !_.isEmpty(dataMonitor?.providerId)
              ? false
              : _.isEmpty(dataMonitor?.productId) && !_.isEmpty(dataMonitor?.providerId)
              ? false
              : true
          }
          onClick={() => addMonitor()}
        >
          登録
        </button>
      </ModalBody>
    </Modal>
  );
};

export default ModalMonitor;
