import React, { useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import 'assets/scss/page/AnkenMasterNew/ModalCreateService.scss';

import TableModalCancels from './TableModalCancels';


const ModalCancelSettings = (props) => {
  const { toggle = () => { }, modal = false, serviceCancel = null, productCancel = null, nameTitle } = props;
  const [statusGetList, setStatusGetList] = useState(false);

  return (
    <div>
      <Modal className="modal-dialog-custom" isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>キャンセル結果設定</ModalHeader>
        <ModalBody>
          <div className="form-body-service">
            <TableModalCancels
              statusGetList={statusGetList}
              setStatusGetList={() =>
                setStatusGetList(false)
              }
              serviceCancel={serviceCancel}
              productCancel={productCancel}
              nameTitle={nameTitle}
            />
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ModalCancelSettings;
