import React from 'react';

const DetailSite = ({ data }) => {
  return (
    <div>
      <div className="detail-erp-table-wrapper mt-1">
        <div className="title-absolute">作業結果</div>
        <div className="detail__site">
          <table className="detail__form">
            <tbody>
              <tr>
                <th className="detail__form--color text-center">入館・退館</th>
                <td className="detail__form--color--td" style={{ width: '12%' }}>
                  {data?.startTime}
                </td>
                <td className="detail__form--color--td" style={{ width: '12%' }}>
                  {data?.endTime}
                </td>
                <th className="detail__form--color text-center">作業結果</th>
                <td className="detail__form--color--td" style={{ width: '12%' }}>
                  {data?.settingCompletionResult?.pullDownDisplay}
                </td>
                <th className="detail__form--color text-center">結果備考</th>
                <td className="detail__form--color--td">{data?.resultCancel}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default DetailSite;
