import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { translate } from 'react-i18next';
import PaginationCustom from '../../../../components/PaginationCustom';
import TableList from '../../../../components/TableList';
import TableListOrderGroup from '../TableListOrderGroup';
const TableOrderGroup = ({
  getListProduct = () => {},
  sortOrderBy,
  p,
  color,
  colorText,
  role,
  roleDetail,
  t,
  idProduct,
  item,
  caseName,
  partnerId,
  partnerBranch,
  staffBranch,
  detailCheck,
  getListPreparationInfo,
  activePageSearch,
  summary,
  paramSearch,
  activePageTable,
  statusShowData,
  idRole,
}) => {
  const [listOrder, setListOrder] = useState([]);
  const [pageActive, setPageActive] = useState(1);

  useEffect(() => {
    setListOrder(item);
  }, [item]);

  useEffect(() => {
    setPageActive(1);
  }, [summary , paramSearch , activePageTable]);

  const handlePageChange = (pageNumber) => {
    setPageActive(pageNumber)
    const dataSearch = {
      page: pageNumber,
      limit: 10,
      pageProduct: activePageSearch,
      limitProduct: 10,
      productId: idProduct,
      countCheck : true,
    };
    getListProduct({
      dataSearch,
    });
  };

  return (
    <>
      <TableListOrderGroup
        idProduct={idProduct}
        sortOrderBy={sortOrderBy}
        caseName={caseName}
        data={listOrder}
        roleDetail={roleDetail}
        t={t}
        role={role}
        p={p}
        color={color}
        partnerId={partnerId}
        staffBranch={staffBranch}
        partnerBranch={partnerBranch}
        colorText={colorText}
        detailCheck={detailCheck}
        getListPreparationInfo={getListPreparationInfo}
        activePageSearch={activePageSearch}
        activePageTable={pageActive}
        statusShowData={statusShowData}
        idRole={idRole}
      />
      {listOrder?.count > 10 && (
        <PaginationCustom
          activePage={pageActive}
          itemsCountPerPage={10}
          totalItemsCount={listOrder && listOrder.count}
          pageRangeDisplayed={5}
          handlePageChange={handlePageChange}
        />
      )}
    </>
  );
};

export default compose(translate('translations'), withRouter)(TableOrderGroup);
