import React, { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { connect, shallowEqual, useSelector, useDispatch } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { logOut, getRoleMe } from '../../actions/authentication';
import { ROLE, ROLE_ENUM } from '../../helpers/constants';
import { getRole, getRoleColor } from '../../helpers/helpers';
import PartnerMenu from './PartnerMenu';
import MonitoringMenu from './MonitoringMenu';
import ErpMenu from './ErpMenu';
import ManagementGroupMenu from './ManagementGroupMenu';
import { PERMISSION, NAME_ROLE ,ROLE_ORGANIZATION_CHECK } from '../../helpers/constants';
import { MASTER_SETTING, CHAT, ORDER } from '../../constants/endpoints';
import { getLogo, getColorMe } from 'actions/utils';
import Loading from 'components/Loading/Loading';
import _ from 'lodash';
import './style.scss';
import { useHistory } from 'react-router-dom';

const HeaderMasterSetting = (props) => {
  const { p = '', logOut, checkName = '' , color = '#1c2a53', colorText= 'white', getRoleMe, nameRole = '', getListOption = () => {} } = props;
  const history = useHistory();
  const userMe = useSelector((store) => store.authentication.userMe, shallowEqual);
  const logo = useSelector((store) => store.utils.logo);
  const [role, setRole] = React.useState(null);
  const [idRole, setIdRole] = React.useState(null);
  const url = props.history.location.pathname;
  const [roleDetail, setRoleDetail] = useState('');
  const [detailCheck, setDetailCheck] = useState();
  const [checkPN, setCheckPN] = useState('');
  const [checkPathName, setCheckPathName] = useState(checkName !== '' ? checkName : props.history.location.pathname);
  const [totalMessages, setTotalMessages] = useState({total: ''});
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const dispatchAddLogoToCart = (message) => dispatch(getLogo(message));
  const dispatchAddToCart = (message) => dispatch(getColorMe(message));
  const newRole = localStorage.getItem('role');
  const [totalConfirm, setTotalConfirm] = useState();
  const organizationArr = userMe?.data?.organization || [];
  const organnizationCurrent = localStorage.getItem('idProvide');

  let isUserRole2 =
    _.find((organizationArr || []), { idOrganization: `${organnizationCurrent}`, idRole: '3' }) ||
    _.find((organizationArr || []), { idOrganization: `${organnizationCurrent}`, idRole: '6' });

  useEffect(() => {
    if (idRole) {
      setRoleDetail(getRole({ idRole: idRole }));
      setRole(getRole({ idRole: idRole }));
      !['1', 1, '14', 14].includes(idRole) && getTotalConfirm({});
    }
  }, [idRole]);

  useEffect(() => {
    setCheckPathName(checkName !== '' ? checkName : props.history.location.pathname);
  }, [checkName]);

  useEffect(() => {
    checkPath(checkPathName, newRole);
  }, [checkPathName]);

  useEffect(() => {
    getListOptionSetting()
    setIdRole(newRole);
    if (newRole) {
      setRole(getRole({ idRole: newRole }));
    }
    checkPath(checkPathName, newRole);
    const idProvide = localStorage.getItem('idProvide');
    getColorInfo({ idRole: newRole, idProvide });
    getTotalMessages({});
  }, []);

  const getLinkIcon = (role) => {
    switch (role) {
      case ROLE.PARTNER:
      case ROLE.PARTNERGROUP:
        return 'partner_';
      case ROLE.CLIENT:
        return 'client_';
      default:
        return '';
    }
  };

  const getTotalConfirm = async () => {
    setLoading(true);
    try {
      const data = await ORDER.GET_TOTAL_CONFIRM({});
      if (data) {
        setTotalConfirm(data?.data);
      };
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const getColorInfo = async ({ idRole, idProvide }) => {
    setLoading(true);
    try {
      const data = await MASTER_SETTING.GET_LIST_COLOR_BY_ORGANIZATION(1, idProvide);
      if (data) {
        if (data) {
          const color = data?.data?.[getRoleColor({ idRole })];
          const colorMaster = {
            code: color?.codeColor,
            colorText: color?.colorText,
            colorId: data?.data?.colorId,
          }
          if(idProvide === localStorage.getItem('idProvide')) {
            dispatchAddLogoToCart(data?.data);
            dispatchAddToCart(colorMaster);
          }
        }
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const getTotalMessages = async () => {
    setLoading(true);
    try {
      const data = await CHAT.GET_TOTAL_MESSAGES({});
      if (data) {
        setTotalMessages(data?.data);
      };
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const getListOptionSetting = async () => {
    const idProvider = localStorage.getItem('idProvide')
    try {
      const data = await MASTER_SETTING.GET_LIST_OPTION_SETTING(idProvider);
      if (data?.data) {
        setDetailCheck(data ?.data);
        getListOption({option: data ?.data})
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onClickLogOut = () => {
    logOut();
  };

  const checkPath = (check, newRole = '') => {
    switch (check) {
      case '/master-setting':
        return setCheckPN('基本情報');
      case '/configuration-worker':
        return setCheckPN('Worker設定');
      case '/aggregate':
        return setCheckPN('集計サマリ設定');
      case '/organizations':
        return setCheckPN('組織');
      case '/erp_organizations':
        return setCheckPN('ERP_新規作成');
      case '/managementGroup_organizations':
        return setCheckPN('ManagementGroup_新規作成');
      case '/edit_erp_organizations':
        return setCheckPN('ERP_編集');
      case '/edit_managementGroup_organizations':
        return setCheckPN('ManagementGroup_編集');
      case '/users':
        return setCheckPN('利用者登録');
      case '/project':
        return setCheckPN('Projectマスタ');
      case '/anken_master':
        return setCheckPN('Productマスタ');
      case '/importcsv-pri-sco':
        return setCheckPN('工事物件取込設定');
      case '/equipment-management':
        return setCheckPN('物品管理');
      case '/example-monitor':
        return setCheckPN('集計サマリ設定');
      case '/anken-master':
        return setCheckPN('ワークフロー設定');
      case '/organizations/edit-organization': {
        if (nameRole === ROLE_ORGANIZATION_CHECK.MONITORING) {
          return setCheckPN('発注企業情報編集');
        }
        if (nameRole === ROLE_ORGANIZATION_CHECK.MANAGERMENT) {
          return setCheckPN('協力企業情報編集');
        }
      }
      case '/organizations/create-organization': {
        if (nameRole === ROLE_ORGANIZATION_CHECK.MONITORING) {
          return setCheckPN('発注企業情報新規作成');
        }
        if (nameRole === ROLE_ORGANIZATION_CHECK.MANAGERMENT) {
          return setCheckPN('協力企業新規作成');
        }
      }
      case '/users/edit-user': {
        if (nameRole === NAME_ROLE.monitoring) {
          return setCheckPN('発注企業利用者編集');
        }
        if (nameRole === NAME_ROLE.rep) {
          return setCheckPN('契約企業利用者編集');
        }
        if (nameRole === NAME_ROLE.managementGroup) {
          return setCheckPN('ManagementGroup_User_編集');
        }
        return setCheckPN('協力企業利用者編集');
      }

      case '/users/register-user': {
        if (nameRole === NAME_ROLE.monitoring) {
          return setCheckPN('発注企業利用者新規作成');
        }
        if (nameRole === NAME_ROLE.rep) {
          return setCheckPN('契約企業利用者新規作成');
        }
        if (nameRole === NAME_ROLE.managementGroup) {
          return setCheckPN('ManagementGroup_User_新規作成');
        }
        return setCheckPN('協力企業利用者新規作成');
      }
      case '/dispatch-settings':
        return setCheckPN('ディスパッチ');
      case '/csv-master':
        return setCheckPN('CSV出力設定');
      case '/branch-master':
        return setCheckPN('拠点一覧');
      case '/staff_master':
        return setCheckPN('Worker利用者登録');
      case '/contract':
      case '/contract/filters':
        return setCheckPN('契約管理');
      case '/dispatch':
        return setCheckPN('Dispatch');
      case '/payment-date':
        return setCheckPN('検収確認');
      case '/calendar_master':
        return setCheckPN('カレンダーマスタ');
      case '/working-schedule':
        return setCheckPN('スケジュール');
      case '/working-schedule/staff-request':
        return setCheckPN('スケジュール');
      case '/record-correction':
        return setCheckPN('レコード修正');
      case '/confirm-page':
        return setCheckPN('承認依頼');
      case '/describe-receive-info':
        return setCheckPN('受注情報');
      case '/detail-before-confirm-erp':
        return setCheckPN('事前確認');
      case '/detail-report-upload-info':
        return setCheckPN('報告書回収情報');
      default:
        return setCheckPN('');
    }
  };

  const rootPath = () => {
    window.location.href = PERMISSION.PERMISSION_MAINTAIN_MYCORP.includes(idRole)
      ? '/master-setting'
      : PERMISSION.PERMISSION_PARTNER_CONTRACT.includes(idRole)
      ? '/contract'
      : '/anken-master';
  };

  const returnUrl = () => {
    history.push("/confirm-page");
    if(role !== 'WORKER') {
      localStorage.setItem('role', isUserRole2?.idRole);
    }
  }

  return (
    <div className="header-wrapper-master-setting">
      <Loading loading={loading} />
      <Helmet>{/* <title>{(listMenu && listMenu[activeMenu]?.label) || ''}</title> */}</Helmet>
      <div className="menu" style={{ backgroundColor: `${color}` }}>
        <span className="show-icon-custom cursor-pointer" onClick={() => rootPath()}>
          <img id="img-icon" src={_.isEmpty(logo) ? `/img/icons.png` : logo?.logo} />
        </span>
        {role && role === ROLE.PARTNER ? (
          <>
            <PartnerMenu idRole={idRole} colorText={colorText} />
          </>
        ) : (role === ROLE.CENTER) ? (
          <>
            <ErpMenu idRole={idRole} colorText={colorText} detailCheck={detailCheck} />
          </>
        ) : (role === ROLE.PARTNERGROUP) ? (
          <ManagementGroupMenu idRole={idRole} colorText={colorText} detailCheck={detailCheck} />
        ) : (
          <>
            <MonitoringMenu idRole={idRole} colorText={colorText}/>
          </>
        )}
      </div>

      <div className="submenu form__flex form__flex--end" style={{ backgroundImage: `url(/img/${getLinkIcon(role)}submenu1.jpg)` }}>
        <div className="login_msg form__flex form__flex--btn-header form__flex--center">
          {url !== '/select-role' && (
            <>
            {
              !['1', 1, '14', 14].includes(idRole) && (
                <div className="form-icon-chat">
                  <Link onClick={() => returnUrl()} className="css-icon">
                    <span className="css-icon--cricle">{totalConfirm || 0}</span>
                    <span className="css-icon--text fz-10">承認依頼</span>
                  </Link>
                </div>
              )
            }
              <div className="form-icon-chat">
                <Link to="/chat-page" className="fas fa-comment-alt iconchat" target="_blank">
                  <span className="chat--num">{totalMessages?.total ? (totalMessages?.total === 0 ? '' : totalMessages?.total) : ''}</span>
                </Link>
              </div>
            </>
          )
          }
          <div className="form__flex--w130 truncate" title={ROLE_ENUM[idRole]}>
            {ROLE_ENUM[idRole]}
          </div>
          <div className="form__flex--w130 truncate" title={`${userMe && userMe.data && userMe?.data?.firstName + ' ' + userMe?.data?.lastName}様`}>
            {userMe && userMe.data && userMe?.data?.firstName + ' ' + userMe?.data?.lastName}様
          </div>
          <div className="logout_btn form__flex--w20">
            <Link to="/select-role" onClick={() => getRoleMe()}>
              <span className="mypage_a">権限切替</span>
            </Link>
          </div>
          {url !== '/select-role' && (role === ROLE.CENTER || role === ROLE.PARTNERGROUP) ? (
            <div className="logout_btn form__flex--w20">
              <Link to="/my_page">
                <span className="mypage_a">マイページ</span>
              </Link>
            </div>
          ) : (
            <div className="logout_btn form__flex--w20"></div>
            )}
          <div className="logout_btn form__flex--w20" onClick={() => onClickLogOut()}>
            <span className="logout_a cursor-pointer">ログアウト</span>
          </div>
        </div>
      </div>
      <div className="classMapPipe mb-1 mt-1" style={{ borderColor: `${color}`, color : `${colorText}` }}>
        <span style={{ fontSize: '11px' }}>HOME ＞ {checkPN}</span>
        <div className="login_btn1 form__flex--btn-instruction">
          <a href="#" className="wrapper-help" target="_brank" style={{ color : `${colorText}`, display:'none'}}>
            <i className="icon-help-circled" />
            マニュアルはこちら
          </a>
        </div>
      </div>
      {window.location.pathname == '/dispatch-settings' || window.location.pathname == '/equipment-management' ? (
        ''
      ) : (
        <h1 className="ConditionTitle w-100" style={{ backgroundColor: `${color}` , color : `${colorText}`}}>
          {checkPN}
        </h1>
      )}
    </div>
  );
};

const mapActionToProps = {
  logOut, getRoleMe
};

export default compose(withRouter, connect(null, mapActionToProps))(HeaderMasterSetting);
