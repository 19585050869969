import React from 'react';
import './style.scss';
import TableWorkerRight from './TableWorkerRight';

const data = {
  open: ['前日確認', '入館前確認', '入館', '写真撮影', '報告書入力', '報告書添付', '退館前確認', '結果・退館'],
  construction: ['前日確認', '入館前確認', '入館', '写真撮影', '報告書入力', '報告書添付', '退館前確認', '結果・退館'],
};

const TableWorker = () => {
  return (
    <div className="wrapper-table-worker">
      <table>
        <tbody>
          {data.open &&
            data.open.map((item, index) =>
              index === 0 ? (
                <tr className="first_row">
                  <th rowSpan="8">3</th>
                  <th rowSpan="8">開通試験</th>
                  <td>
                    <TableWorkerRight value={item} id={`opem${item}`} />
                  </td>
                </tr>
              ) : (
                <tr>
                  <td>
                    <TableWorkerRight value={item} id={`opem${item}`} />
                  </td>
                </tr>
              )
            )}
          {data.construction &&
            data.construction.map((item, index) =>
              index === 0 ? (
                <tr className="first_row">
                  <th rowSpan="8">2</th>
                  <th rowSpan="8">宅内工事</th>
                  <td>
                    <TableWorkerRight value={item} id={`construction${item}`} />
                  </td>
                </tr>
              ) : (
                <tr>
                  <td>
                    <TableWorkerRight value={item} id={`construction${item}`} />
                  </td>
                </tr>
              )
            )}
          {/*<tr className="first_row">*/}
          {/*  <th rowSpan="8">3</th>*/}
          {/*  <th rowSpan="8">開通試験</th>*/}
          {/*  <td>*/}
          {/*    <TableWorkerRight value="前日確認" />*/}
          {/*  </td>*/}
          {/*</tr>*/}
          {/*<tr>*/}
          {/*  <td>*/}
          {/*    <TableWorkerRight value="入館前確認" />*/}
          {/*  </td>*/}
          {/*</tr>*/}
          {/*<tr>*/}
          {/*  <td>*/}
          {/*    <TableWorkerRight value="入館" />*/}
          {/*  </td>*/}
          {/*</tr>*/}
          {/*<tr>*/}
          {/*  <td>*/}
          {/*    <TableWorkerRight value="入館" />*/}
          {/*  </td>*/}
          {/*</tr>*/}
          {/*<tr>*/}
          {/*  <td>*/}
          {/*    <TableWorkerRight value="入館" />*/}
          {/*  </td>*/}
          {/*</tr>*/}
          {/*<tr>*/}
          {/*  <td>*/}
          {/*    <TableWorkerRight value="入館" />*/}
          {/*  </td>*/}
          {/*</tr>*/}
          {/*<tr>*/}
          {/*  <td>*/}
          {/*    <TableWorkerRight value="入館" />*/}
          {/*  </td>*/}
          {/*</tr>*/}
          {/*<tr>*/}
          {/*  <td>*/}
          {/*    <TableWorkerRight value="入館" />*/}
          {/*  </td>*/}
          {/*</tr>*/}
        </tbody>
      </table>
    </div>
  );
};

export default TableWorker;
