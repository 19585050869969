import { PERMISSION } from './constants';
import { PERMISSION_ROLE } from '../constants/endpoints';

const isVerifyRole = async () => {
  try {
    const data = await PERMISSION_ROLE.CHECK_ROLE({ roleId: localStorage.getItem('role') });
    if (data && data?.code === 0) {
      return data.data
    }
  } catch (e) {
    console.log(e);
  }
};

export const isPermissionMyCorp = () => {
  return isVerifyRole() && PERMISSION.PERMISSION_MYCORP.includes(localStorage.getItem('role'));
};

export const isPermissionOrganization = () => {
  return isVerifyRole() && PERMISSION.PERMISSION_ORGANIZATION.includes(localStorage.getItem('role'));
};

export const isPermissionCreateOrganization = () => {
  return isVerifyRole() && PERMISSION.NEOMARS_ORGANIZATION.includes(localStorage.getItem('role')) ;
};

export const isPermissionAnkenMaster = () => {
  return isVerifyRole() && PERMISSION.PERMISSION_ANKENMASTER.includes(localStorage.getItem('role'));
};

export const isPermissionContract = () => {
  return isVerifyRole() && PERMISSION.PERMISSION_CONTRACT.includes(localStorage.getItem('role'));
};

export const isPermissionSettingOrder = () => {
  return isVerifyRole() && PERMISSION.PERMISSION_SETTINGORDER.includes(localStorage.getItem('role'));
};

export const isPermissionSettingWorker = () => {
  return isVerifyRole() && PERMISSION.PERMISSION_SETTINGWORKER.includes(localStorage.getItem('role'));
};

export const isPermissionDispatchSetting = () => {
  return isVerifyRole() && PERMISSION.PERMISSION_DISPATCHSETTING.includes(localStorage.getItem('role'));
};

export const isPermissionCsvMaster = () => {
  return isVerifyRole() && PERMISSION.PERMISSION_CSVMASTER.includes(localStorage.getItem('role'));
};

export const isPermissionPaymentDate = () => {
  return isVerifyRole() && PERMISSION.PERMISSION_PAYMENTDATE.includes(localStorage.getItem('role'));
};

export const isPermissionUserMaster = () => {
  return isVerifyRole() && PERMISSION.PERMISSION_USERMASTER.includes(localStorage.getItem('role'));
};
