import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

const BasicInfo = ({ productInfo = {} }) => {
  return (
    <div className="wrapper-bacsic-info">
      <div className="d-flex">
        <div className="wrapper-column col-2" style={{ fontSize: '16px' }}>
          クライアント名
        </div>
        <div className="table-right col-4" style={{ fontSize: '16px' }}>
          {productInfo?.clientName}
        </div>
        <div className="wrapper-column col-2" style={{ fontSize: '16px' }}>
          案件名
        </div>
        <div className="table-right col-4" style={{ fontSize: '16px' }}>
          {productInfo?.productName}
        </div>
      </div>
    </div>
  );
};

export default compose(withRouter)(BasicInfo);
