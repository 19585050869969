import React, { useEffect, useState } from 'react';
import './style.scss';
import Table from './TableReceiveAllEdit/index';
import TableCondit from './TableConditTab';
import TabsTable from './TabsTable';
import TableUpdateHistory from './TableUpdateHistory';
import { ORDER } from '../../../constants/endpoints';
const ReceiveAllEdit = () => {
  let orderId = (process.browser && new URL(window.location.href).searchParams.get('orderId'));
  let organizationId = (process.browser && new URL(window.location.href).searchParams.get('organizationId'));

  const [dataOrder, setDataOrder] = useState('');

  const getOrderDetail = async ({ orderId = '' }) => {
    try {
      const data = await ORDER.GET_DETAIL_CANCEL_INFO({ orderId: orderId, organizationId });
      if (data) {
        setDataOrder(data?.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (orderId) {
      getOrderDetail({
        orderId: orderId
      });
    }

  }, [orderId]);

  return (
    <div className="wrapper_main_list">
      <div className="body_list wrapper-list ">
        <div className="my-1">
          <span style={{ fontSize: '11px' }}>HOME ＞　全情報閲覧</span>
        </div>
        <Table />
        <h1 className="ConditionTitle">基本情報</h1>
        <div className="list">
          <TableCondit dataOrder={dataOrder} />
          <TabsTable dataOrder={dataOrder}/>
          <TableUpdateHistory />
        </div>
      </div>
    </div>
  );
};

export default ReceiveAllEdit;
