import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { compose } from "redux";

const ButtonChecked = ({ status = false, onChange = () => {}, index }) => {
  const [active, setActive] = useState(status);

  const onClickButon = () => {
    onChange({ status: !active, index });
    setActive(!active);
  };

  useEffect(() => {
    setActive(status);
  }, [status]);

  return (
    <div className="item-button">
      <div className="btn-group btn-group-toggle" data-toggle="buttons">
        <button
          className={`button--mw60 ${active ? "button--blue" : "button--white"} button--border-blue`}
          onClick={() => onClickButon()}
        >
          表示
        </button>
        <button
          className={`button--mw60 ${!active ? "button--blue" : "button--white"} button--border-blue`}
          onClick={() => onClickButon()}
        >
          非表示
        </button>
      </div>
    </div>
  );
};

export default compose(withRouter)(ButtonChecked);
