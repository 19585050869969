import React, { useState } from 'react';

const EvenInput = ({ type = '', value = '', id = '', onClickDel = () => {} }) => {
  const [inputValue, setInputValue] = useState(value);
  const handleChange = (event) => {
    let newInputValue = event.target.value;
    setInputValue(newInputValue);
  };

  return (
    <>
      <div className="d-flex mb-1">
        <input type="text" className="form-control even-input" value={inputValue} onChange={handleChange} />
        <button
          className="btn button-delete ml-2"
          onClick={() => {
            onClickDel({ type: type, id: id });
          }}
        >
          削除
        </button>
      </div>
    </>
  );
};
export default EvenInput;
