import React, { useEffect, useState } from 'react';
import './style.scss';
import img_logo from '../../assets/img/img_logo.png';
import { Link, withRouter } from 'react-router-dom';
import { connect, shallowEqual, useSelector } from 'react-redux';
import { logOut } from '../../actions/authentication';
import { compose } from 'redux';

const HeaderMaintenance = (props) => {
  const { logOut, setFontSizeAll = () => {} } = props;
  const [fontSize, setFontSize] = useState('small');
  const userMe = useSelector((store) => store.authentication.userMe, shallowEqual);

  useEffect(() => {
    const myFontSize = localStorage.getItem('myFontSize');
    if (!myFontSize) {
      localStorage.setItem('myFontSize', 'small');
    } else {
      setFontSize(myFontSize);
    }
  }, []);

  const onClickLogOut = () => {
    logOut();
  };

  useEffect(() => {
    localStorage.setItem('myFontSize', fontSize);
    setFontSizeAll(fontSize);
  }, [fontSize]);

  const onClickChangeFontSize = (mySize) => {
    setFontSize(mySize);
  };

  return (
      <div className="header">
        <div className="wrapper">
          <h1>
            <Link to="/list">
              <img src={img_logo} alt="STELLA" />
            </Link>
          </h1>
          <button className={`btn btnLogout ${fontSize}`} onClick={() => onClickLogOut()}>
            ログアウト
          </button>
          <p className={`account ${fontSize} truncate`} title={`${userMe && userMe.data && userMe?.data?.firstName + ' ' + userMe?.data?.lastName}様`} >
            {userMe && userMe.data && userMe?.data?.firstName + ' ' + userMe?.data?.lastName}様
          </p>

          <ul className="fsChanger">
            <li>
              <a href="/#" onClick={() => onClickChangeFontSize('small')}>
                小
              </a>
            </li>
            <li>
              <a href="/#" onClick={() => onClickChangeFontSize('medium')}>
                中
              </a>
            </li>
            <li>
              <a href="/#" onClick={() => onClickChangeFontSize('large')}>
                大
              </a>
            </li>
          </ul>
          <a
              href="/#"
              style={{ color: '#FFF', float: 'right', marginTop: '15px', display:'none' }}
              target="_brank"
              className="d-flex"
          >
            <i className={`fa pt-1 icon-help-circled ${fontSize}`} aria-hidden="true" />
            <span className={`${fontSize} `}>マニュアルはこちら</span>
          </a>
        </div>
      </div>
  );
};

const mapActionToProps = {
  logOut,
};

export default compose(withRouter, connect(null, mapActionToProps))(HeaderMaintenance);
