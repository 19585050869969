import React, { useEffect, useState, Fragment } from 'react';
import { Collapse } from 'reactstrap';
import { MASTER_SETTING, PLUGIN_SETTING } from '../../../../constants/endpoints';
import ModalSettingOrder from '../ModalSettingOrder';
import Loading from 'components/Loading/Loading';
import _ from 'lodash';
import './style.scss';

const CollaMasterViewTree = ({ colors, idProvide = '', colorText, statusBeforeConfirm, idRole }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [modal, setModal] = useState(false);
  const [isModal, setisModal] = useState(false);
  const [ListPlugin, setListPlugin] = useState([]);
  const [provider, setProvider] = useState('');
  const [idProvider, setIdProvider] = useState('');
  const [idService, setIdService] = useState('');
  const [productIdTree, setproductIdTree] = useState('');
  const [loading, setLoading] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const [listTreeProject, setListTreeProject] = useState([]);
  const [idClientShow, setIdClientShow] = useState([]);
  const [nameProduct, setNameProduct] = useState('');

  const toggleOpen = ({ provider, id, productId = '', name }) => {
    setproductIdTree(productId);
    setNameProduct(name);
    getListPluginSettings(id, provider, provider === 'product' ? '' : productId);
  };

  const toggleModal = () => {
    setModal(false);
  };

  useEffect(() => {
    getListViewTree();
  }, []);

  useEffect(() => {
    getListViewTree();
  }, [idProvide]);

  const getListViewTree = async () => {
    try {
      setLoading(true);
      const data = await MASTER_SETTING.GET_TREE_BY_PROJECT(idProvide);
      if (data) {
        setListTreeProject(data?.data?.clients);
        let dataClientId = data?.data?.clients?.map((ele) => {
          return ele?.clientId;
        });
        setIdClientShow(_.compact(dataClientId));
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const getListPluginSettings = async (providerId, provider, productId = '') => {
    setLoading(true);
    try {
      const data = await PLUGIN_SETTING.GET_LIST_PLUGIN_SETTINGS({
        idProvide,
        providerId,
        provider,
        productId: provider === 'product' ? '' : productId,
      });
      if (data) {
        changeStateModal(data?.data || [], provider, providerId);
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const changeStateModal = (data, provider, providerId) => {
    setListPlugin(data[0] || []);
    setProvider(provider);
    setIdProvider(providerId);
    setModal(!modal);
    setisModal(true);
  };

  const OpenClient = (id) => {
    if (idClientShow.includes(id)) {
      setIdClientShow(idClientShow?.filter((ele) => ele !== id));
    } else {
      setIdClientShow([...idClientShow, id]);
    }
  };

  const renderTreeProject = (client, i) => {
    return (
      <div className="form-tree form-tree--left mt-3" key={i}>
        <div className="d-flex flex-direction-row form-tree--top">
          <p className="title-project title-project--left">{client?.abbreviationName}</p>
        </div>
        <div className="form-tree--bottom">
          <div className="form-tree--show">
            <div className="form-tree--on cursor-pointer" onClick={() => OpenClient(client?.clientId)}>
              {idClientShow?.includes(client?.clientId) ? '-' : '+'}
            </div>
            {idClientShow?.includes(client?.clientId) && (
              <ul>
                {client &&
                  client?.projects.map((item, index) => {
                    return (
                      <Fragment key={index}>
                        {item?.product?.map((ele, i) => {
                          return (
                            <li key={i}>
                              <p>
                                {ele?.name && item.projectName
                                  ? `${ele?.name}-${item.projectName}`
                                  : ele?.name || item.projectName}
                                <button
                                  onClick={() => {
                                    toggleOpen({
                                      provider: 'product',
                                      id: ele.productId,
                                      productId: ele.productId,
                                      name:
                                        ele?.name && item.projectName
                                          ? `${ele?.name}-${item.projectName}`
                                          : ele?.name || item.projectName,
                                    });
                                    setIdService('');
                                  }}
                                >
                                  詳細設定
                                </button>
                              </p>
                              {ele?.service?.length > 0
                                ? itemTrees({
                                    array: ele?.service,
                                    productId: ele.productId,
                                    nameTitleService:
                                      ele?.name && item.projectName
                                        ? `${ele?.name}-${item.projectName}`
                                        : ele?.name || item.projectName,
                                  })
                                : ''}
                            </li>
                          );
                        })}
                      </Fragment>
                    );
                  })}
                {client &&
                  client?.nonProjects?.map((ele, elex) => {
                    return (
                      <li key={elex}>
                        <p>
                          {ele?.name}
                          <button
                            onClick={() => {
                              toggleOpen({
                                provider: 'product',
                                id: ele.productId,
                                productId: ele.productId,
                                name: ele?.name,
                              });
                              setIdService('');
                            }}
                          >
                            詳細設定
                          </button>
                        </p>
                        {ele?.service?.length > 0
                          ? itemTrees({ array: ele?.service, productId: ele.productId, nameTitleService: ele?.name })
                          : ''}
                      </li>
                    );
                  })}
              </ul>
            )}
          </div>
        </div>
      </div>
    );
  };

  const itemTrees = ({ array, productId, nameTitleService }) => {
    return (
      <ul>
        {array &&
          array.map((item, index) => {
            return (
              <li key={index}>
                <p>
                  {item.name}
                  <button
                    onClick={() => {
                      toggleOpen({
                        provider: 'service',
                        id: item.serviceId,
                        productId,
                        name: `${nameTitleService || ''}-${item?.name || ''}`,
                      });
                      setIdService(item.serviceId);
                    }}
                  >
                    詳細設定
                  </button>
                </p>
              </li>
            );
          })}
      </ul>
    );
  };

  return (
    <div className="setting-master-menu-wrapper">
      <Loading loading={loading} />
      <div className="wrapper-btn-colappse">
        <div
          onClick={toggle}
          className="mb-1 searchbar d-flex justify-content-between align-items-center "
          style={{ background: `${colors}` }}
        >
          <div className="ml-2" style={{ color: `${colorText}` }}>
          詳細設定
          </div>
          <div className="mr-2" style={{ color: `${colorText}` }}>
            {isOpen ? <i className="icon-up-open icon-searchbar" /> : <i className="icon-down-open icon-searchbar" />}
          </div>
        </div>
      </div>
      <div>
        <Collapse isOpen={isOpen}>
          <div className="content-view flex-column justify-content-between">
            {/* {renderTreeNone(listTreeNone)} */}
            {listTreeProject && listTreeProject.map((item, key) => renderTreeProject(item, key))}
          </div>
        </Collapse>
      </div>
      {isModal && (
        <ModalSettingOrder
          provider={provider}
          idProvider={idProvider}
          idService={idService}
          modal={modal}
          toggle={toggleModal}
          isReload={true}
          dataFetch={ListPlugin}
          colors={colors}
          productIdTree={productIdTree}
          idProvide={idProvide}
          statusBeforeConfirm={statusBeforeConfirm}
          idRole={idRole}
          nameProduct={nameProduct}
        />
      )}
    </div>
  );
};

export default CollaMasterViewTree;
