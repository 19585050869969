import React from 'react';
import 'assets/scss/page/StaffMaster/TableStaffMasterList/style.scss';
import { getNoOrder } from '../../../../helpers/helpers';
import { DATE_JP_FORMAT, ROLE, ROLE_ENUM,ENUM_ROLE } from '../../../../helpers/constants';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';


const renderItem = (item, index, activePage, role, history) => {
  return (
    <tr key={index}>
      <td>
        <div className="d-flex justify-content-center align-items-center font-weight-bolder no-style">
          {getNoOrder({ index: index, limit: 10, activePage: activePage })}

        </div>
      </td>
      <td>{item?.branchName}</td>
      <td>{item?.loginId}</td>
      <td>{item?.surName}{item?.name}</td>
      <td>{item?.surNameKana}{item?.nameKana}</td>
      <td>{item?.contact}</td>
      <td>{item?.createdAt ? moment(new Date(item?.createdAt)).format(DATE_JP_FORMAT) : ""}</td>
      <td>{item?.updatedAt ? moment(new Date(item?.updatedAt)).format(DATE_JP_FORMAT) : ""}</td>
      <td></td>
      {/*<td>{item?.usageStatus === 1 ? item?.statusUpdatedAt : ''}</td>*/}
      <td>{item?.primaryRole ? ROLE_ENUM[item?.primaryRole?.idRole] : ''}</td>
      <td>{item?.userId && item?.status !== 3 ? (item?.status === 1 ? '利用停止' : '利用中') : ''}</td>
      {
        role?.length > 0 && role === ROLE.PARTNER &&
        <td className="text-center">
          {(item.primaryRole && item.primaryRole.idRole != ENUM_ROLE.WORK) ? "":
            <button
              className="btn"
              onClick={() =>{
                history.push(`/staff_master/edit_staff_master?sid=${item.staffId}`);
              }}
            >
              編集
            </button>
          }
        </td>
      }
    </tr>
  );
};

const TableStaffMasterList = ({ listStaff, activePage, role }) => {
  const history = useHistory();

  return (
    <div className="wrapper-table-master-list mb-1">
      <div className="list">
        <table className="monitor table-staff-list">
          <thead>
            <tr>
              <th scope="col" className="text-left green" style={{ width: 50 }}>No .</th>
              <th scope="col" style={{ fontSize: 11 }} className="green text-left">
                拠点名
               </th>
              <th scope="col" style={{ fontSize: 11 }} className="green text-left">
                利用者ID
              </th>
              <th scope="col" style={{ fontSize: 11 }} className="green text-left">
                利用者名
              </th>
              <th scope="col" style={{ fontSize: 11 }} className="green text-left">
                フリガナ
              </th>
              <th scope="col" style={{ fontSize: 11 }} className="green text-left">
                連絡先
              </th>
              <th scope="col" style={{ fontSize: 11 }} className="green text-left">
                作成日
                </th>
              <th scope="col" style={{ fontSize: 11 }} className="green text-left">
                更新日
              </th>
              <th scope="col" style={{ fontSize: 11 }} className="green text-left">
                終了日
              </th>
              <th scope="col" style={{ fontSize: 11 }} className="green text-left">
                利用者権限
              </th>
              <th scope="col" style={{ fontSize: 11 }} className="green text-left">
                利用状況
              </th>
              {
                role === ROLE.PARTNER &&
                <th scope="col" className="green text-left">
                  操作
                </th>
              }
            </tr>
          </thead>
          <tbody>{listStaff?.length > 0 && listStaff.map((listData, index) => renderItem(listData, index, activePage, role, history))}</tbody>
        </table>
      </div>
    </div>
  );
};

export default TableStaffMasterList;
