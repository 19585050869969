import React, { useState } from 'react';
import HeaderMaintenance from '../../../components/HeaderMaintenance';
import BreadcrumbMaintenance from '../../../components/BreadMaintenance';
import './style.scss';
import TableFieldNameChange from './TableFieldNameChange';
import HeaderMasterSetting from '../../../components/HeaderMasterSetting';

const FieldNameChange = () => {
  const breadcrumb = [
    { name: 'CENTER MASTER', link: '/center_master_all' },
    { name: '表示項目設定', link: '/field_name_change' }
  ];
  const [fontSize, setFontSizeAll] = useState('small');
  return (
    <div className="wrapper-field-name">
      {/* <HeaderMaintenance setFontSizeAll={setFontSizeAll} /> */}
      <HeaderMasterSetting/>
      <div className="body pb-4">
        <div className="wrapper-main">
          {/* <BreadcrumbMaintenance breadcrumb={breadcrumb} fontSize={fontSize} /> */}
          <div className="wrapper-result">
            <div className="result-title my-3 ">
              <h2 className={`font-weight-bolder ${fontSize}`}>
                <i className="fa fa-list-alt" aria-hidden="true"></i>表示項目設定
              </h2>
            </div>
            <TableFieldNameChange fontSize={fontSize} />
          </div>

        </div>
      </div>
    </div>
  );
};

export default FieldNameChange;
