import React from 'react';
import { Modal } from 'reactstrap';
import 'assets/scss/page/popupConfirmCustom/style.scss';

const PopupRemarks = ({
  headerTitle,
  rederBody,
  okText,
  cancelText,
  onConfirm,
  onCancel,
  showPopup=true,
  onToggle = () => {}
}) => {

  return (
    <Modal isOpen={showPopup} centered toggle={onToggle}>
      <div className={`popup-confirm-custom ${showPopup && 'popup-confirm-custom--show'}`}>
        <header className="text-center">{headerTitle} </header>
        <div className="popup-confirm-custom__body">
          {rederBody()}
          <div className="popup-confirm-custom__body__action">
            <button onClick={onConfirm} className="popup-confirm-custom__body__action--w100"> {okText} </button>
            <button onClick={onCancel} className="popup-confirm-custom__body__action--w100"> {cancelText}</button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default PopupRemarks
