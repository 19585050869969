import React, { useEffect, useState } from 'react';
import HeaderMasterSetting from '../../../components/HeaderMasterSetting';
import './style.scss';
import TableWorkerMenuSetting from './TableWorkerMenuSetting';
import { useSelector } from 'react-redux';

const WorkerMenuSetting = () => {
  const colorScreen = useSelector((store) => store.utils.colorMe);
  const [statusBeforeConfirm, setStatusBeforeConfirm] = useState(true);

  const getListOption = ({ option }) => {
    setStatusBeforeConfirm(!!option ?.confirmedTheDayBefore)
  };
  return (
    <div className="wrapper-worker-menu-setting">
      <HeaderMasterSetting
        checkName='/configuration-worker' color={colorScreen ?.code}
        colorText={colorScreen ?.colorText} getListOption={ getListOption }/>
      <div className="wrapper-main ">
        <TableWorkerMenuSetting colorScreen={colorScreen} statusBeforeConfirm={statusBeforeConfirm}/>
      </div>
    </div>
  );
};

export default WorkerMenuSetting;
