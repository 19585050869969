import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { PRODUCT_ENDPOINT } from '../../../../constants/endpoints';
import PaginationCustom from 'components/PaginationCustom';
import _ from 'lodash';
import Loading from 'components/Loading/Loading';
import { pushToast } from 'components/Toast';

const INIT_PAGINATION = {
  page: 1,
  limit: 10,
};

const ModalDeletion = (props) => {
  const { toggle = () => { }, modal = false, productId, serviceId } = props;
  const [loading, setLoading] = useState(false)
  const [listOrderManagerMent, setListOrderManagerment] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [dataSearch, setDataSearch] = useState('');
  const [isCheckAll,setIsCheckAll] = useState(false);

  const getOrderOfManagementGroup = async ({
    page = INIT_PAGINATION.page,
    limit = INIT_PAGINATION.limit,
    productId = '',
    serviceId = '',
    managementCode = '',
    fieldSort = '',
    typeSort = '',
    optionSort = '',
  }) => {
    setLoading(true)
    try {
      const data = await PRODUCT_ENDPOINT.GET_ORDER_OF_MANAGEMENT_GROUP({
        page: page,
        limit: limit,
        productId: productId,
        serviceId: serviceId,
        managementCode: managementCode,
        fieldSort: fieldSort,
        typeSort: typeSort,
        optionSort: optionSort,
      });
      setLoading(false)
      if (data && data.data) {
        let dataNew = data?.data?.data?.map((item) => {
          return { ...item , isDelete : false }
        });
        setListOrderManagerment({...data.data , data: dataNew});
      }
    } catch (e) {
      setLoading(false)
      console.log(e);
    }
  };

  const handleChangePage = (pageNumber) => {
    setActivePage(pageNumber);
    getOrderOfManagementGroup({ page: pageNumber, productId, serviceId });
  };

  useEffect(() => {
    getOrderOfManagementGroup({ productId, serviceId })
  }, [productId, serviceId])

  const handleSearch = (e) => {
    setDataSearch(e?.target?.value)
  }

  const onSubmitSearch = () => {
    getOrderOfManagementGroup({ managementCode: dataSearch, page: 1, limit: 10, productId, serviceId })
  }

  const handleChangeChecked = (e, orderId) => {
    let dataUpdate = listOrderManagerMent?.data?.map((item) => {
      if (orderId === item?.orderId) {
        return { ...item, isDelete: e?.target?.checked }
      } else {
        return item
      }
    });
    setListOrderManagerment({ ...listOrderManagerMent, data: dataUpdate })
  }

  const handleCheckAll = (e) => {
    let dataCheckAll = listOrderManagerMent?.data?.map((item) => {
      return { ...item , isDelete : e?.target?.checked }
    })
    setListOrderManagerment({ ...listOrderManagerMent, data: dataCheckAll })
    setIsCheckAll(e?.target?.checked)
  }

  useEffect(() => {
    setIsCheckAll(!listOrderManagerMent?.data?.find(item => !item.isDelete));
  },[listOrderManagerMent])

  const deleteOrderManagermentGroup = async ({ body = {}, }) => {
    setLoading(true)
    try {
      const data = await PRODUCT_ENDPOINT.DELETE_ORDER_MANAGERMENT_GROUP({ body: body });
      setLoading(false)
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
      } else {
        pushToast('success', 'delete order success');
        let dataNotDelete = listOrderManagerMent?.data?.filter((e => e.isDelete !== true))
        setListOrderManagerment({ ...listOrderManagerMent, data: dataNotDelete })
        if (dataNotDelete?.length < 1) {
          if (activePage > 1) {
            setActivePage(activePage - 1);
            getOrderOfManagementGroup({ page: activePage - 1, productId, serviceId });
          } else {
            getOrderOfManagementGroup({ page: activePage, productId, serviceId });
          }
        }
       }
    } catch (errorMessage) {
      setLoading(false)
      console.log('errorMessage: ', errorMessage);
    }
  };

  const onSubmitDelete = () => {
    let data = listOrderManagerMent?.data?.filter((e => e.isDelete === true)).map(item => item?.orderId)
    deleteOrderManagermentGroup({ body: { orderIds: data, screenName: 'ManagementGroup_運用管理者_レコード編集' } })
  }

  const handleSortOrder = ({ fieldSort = '' , typeSort = '' , optionSort = ''} ) => {
    getOrderOfManagementGroup({ page: activePage, productId, serviceId, fieldSort, typeSort, optionSort });
  }

  const renderItem = (item, index) => {
    return (
      <tr key={index}>
        <td className='pr-2'>
          <input type='checkbox' className='form--h-25 form--w-25' checked={item?.isDelete || false} onChange={(e) => handleChangeChecked(e, item?.orderId)} />
        </td>
        {listOrderManagerMent?.header?.primary?.map((element, eI) => {
          return (
            <td className='form--tbTh cursor-pointer' key={eI}>{item?.primary[element]}</td>
          )
        })}
        {listOrderManagerMent?.header?.secondary?.map((element, eI) => {
          return (
            <td className='form--tbTh cursor-pointer' key={eI}>{item?.secondary[element]}</td>
          )
        })}
      </tr>
    )
  }

  return (
    <div>
      <Loading loading={loading} />
      <Modal className="modal-dialog--w1000" isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>レコード削除</ModalHeader>
        <ModalBody>
          <div className="form-body-service">
            <div >
              <select className='w-25 form-control mb-2'>
                <option>稼働待ち</option>
              </select>
            </div>
            <div className='d-flex justify-content-between'>
              <div className='d-flex'>
                <div className='form--bgBlue form--pd_4_70' >受注日
                </div>
                <div >
                  <input className='form--pd_2' />
                </div>
              </div>
              <div className='d-flex'>
                <div className='form--pd_4_45 form--bgBlue' >管理コード
                </div>
                <div >
                  <input className='form--pd_2' type='text' value={dataSearch} onChange={handleSearch} />
                </div>
                <div>
                  <button className='button__btn-load' onClick={() => onSubmitSearch()}
                  >検索</button>
                </div>
              </div>
            </div>
            <div className='my-2 form--scroll_x form--w_918 form--scroll_y form--h_600'>
              {listOrderManagerMent.count > 0 && (
                <table className='w-100 form--border-collapse'>
                  <thead>
                    <tr>
                      <th className='w-4 form--sticky0'>
                        <input type='checkbox' className='form--h-25 form--w-25' checked={isCheckAll} onChange={handleCheckAll}/>
                      </th>
                      {listOrderManagerMent &&
                        listOrderManagerMent?.header?.primary?.map((item, index) => {
                          return (
                            <th key={index} className="form--tbTh text-center fz-14 form--minW_105 form--sticky0">
                              {item}
                              <br />
                              <a className='cursor-pointer' onClick={() => handleSortOrder({fieldSort : item , typeSort : 'primary' , optionSort : 'asc'})}>▲</a>
                              <a className='cursor-pointer' onClick={() => handleSortOrder({fieldSort : item , typeSort : 'primary' , optionSort : 'desc'})}>▼</a>
                            </th>
                          );
                        })}

                      {listOrderManagerMent &&
                        listOrderManagerMent?.header?.secondary?.map((item, index) => {
                          return (
                            <th key={index} className="form--tbTh text-center fz-14 form--minW_105 form--sticky0">
                              {item}
                              <br />
                              <a className='cursor-pointer' onClick={() => handleSortOrder({fieldSort : item , typeSort : 'secondary' , optionSort : 'asc'})}>▲</a>
                              <a className='cursor-pointer' onClick={() => handleSortOrder({fieldSort : item , typeSort : 'secondary' , optionSort : 'asc'})}>▼</a>
                            </th>
                          );
                        })}
                    </tr>
                  </thead>
                  <tbody>
                    {listOrderManagerMent?.data?.map((listData, index) => renderItem(listData, index))}
                  </tbody>
                </table>
              )}
            </div>
            {listOrderManagerMent?.count > 10 && (
              <PaginationCustom
                activePage={activePage}
                itemsCountPerPage={10}
                totalItemsCount={listOrderManagerMent?.count}
                pageRangeDisplayed={5}
                handlePageChange={handleChangePage}
              />
            )}
            <div className='text-right'>
              {listOrderManagerMent?.count > 0 && (
                 <button className='btn btn-danger btn-sm mt-2' onClick={() => onSubmitDelete()}>
                 レコード削除
               </button>
              )}
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  )
};

export default ModalDeletion;