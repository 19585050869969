import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import HeaderMasterSetting from '../../../components/HeaderMasterSetting';
import './../../../assets/scss/page/contract/index.scss';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import PaginationCustom from '../../../components/PaginationCustom';
import Footer from '../../../components/Footer';
import CollapItemWorker from './CollapItemWorker';
import { PRODUCT_ENDPOINT } from '../../../constants/endpoints';
import LoadingNew from '../../../components/LoadingNew';

const mockData = [
  {
    id: 'aaskdak1',
    name: 'tuyenne',
    data: [
      {
        id: '1ac',
        Manag: 'a',
        operator: 'b',
        startingAddress: 'AAA',
        streetAddress: 'aaaaa',
        sortNum: 1,
      },
      {
        id: '2ac',
        Manag: 'a',
        operator: 'b',
        startingAddress: 'AAA',
        streetAddress: 'aaaaa',
        sortNum: 2,
      },
      {
        id: '3acaa',
        Manag: 'a',
        operator: 'b',
        startingAddress: 'AAA',
        streetAddress: 'aaaaa',
        sortNum: 3,
      },
    ],
  },
  {
    id: '123w1dsada',
    name: 'tuyentc',
  },
];

const WorkerPriority = ({  colorScreen }) => {
  const [idRole, setIdRole] = useState(null);
  const [hideshow, setHideshow] = useState('');
  const [dataList, setDataList] = useState(mockData);
  const [activePage, setActivePage] = useState(1);
  const [loading, setLoading] = useState(false);

  const handleHide = (id) => {
    if (hideshow === id) {
      setHideshow('');
    } else {
      setHideshow(id);
    }
  };

  const getListStaffByProduct = async ({ page = 1, limit = 10, productId = '', name = '' }) => {
    setLoading(true);
    try {
      const data = await PRODUCT_ENDPOINT.GET_LIST_STAFF_BY_PRODUCTT({
        page: page,
        limit: limit,
        productId: productId,
        name: name,
      });
      if (data) {
        setDataList(data?.data);
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const handleChangePage = (pageNumber) => {
    setActivePage(pageNumber);
    getListStaffByProduct({ page: pageNumber });
  };

  const changeLoading = (status) => {
    setLoading(status);
  };

  useEffect(() => {
    getListStaffByProduct({});
  }, []);

  return (
    <div>
      <LoadingNew loading={loading}/>
      <div className="block-contract">
        <div className="list-table">
          {dataList &&
            dataList?.products?.map((item, index) => {
              return (
                <CollapItemWorker
                  item={item}
                  key={index}
                  colors={colorScreen?.code}
                  colorText={colorScreen?.colorText}
                  handleHide={handleHide}
                  hideshow={hideshow}
                  activePage={activePage}
                  roleId={idRole}
                  getListStaffByProduct={getListStaffByProduct}
                  changeLoading={changeLoading}
                />
              );
            })}
          {dataList?.count > 10 && (
            <PaginationCustom
              activePage={activePage}
              itemsCountPerPage={10}
              totalItemsCount={dataList?.count}
              pageRangeDisplayed={5}
              handlePageChange={handleChangePage}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default WorkerPriority;
