import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { translate } from 'react-i18next';
import { connect, useSelector } from 'react-redux';
import { Link, useHistory, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { logOut, getRoleMe } from '../../../actions/authentication';
import DateTimePickerCustom from '../../../components/DateTimePickerCustom';
import { ORDER_PARTNER, STAFF_ENDPOINT, CHAT } from '../../../constants/endpoints';
import { DATE_JP_FORMAT, dataStep, DAY_WEEK } from '../../../helpers/constants';
import './style.scss';
import TableWorker from './TableWorker';
import moment from 'moment';
import PaginationCustom from '../../../components/PaginationCustom';
import MemoHistory from '../../../components/MemoHistory';
import Loading from 'components/Loading/Loading';
import FooterMaintenance from 'components/FooterMaintenance';
import _ from 'lodash';

const renderButtonStep = ({ key = 0, title = '', active = false, workName = 0, onClickActive = () => {} }) => {
  return (
    <div
      className={`d-flex alight-items-center  ${active ? ' wrapper-button-step-active' : 'wrapper-button-step'}`}
      onClick={() => onClickActive(workName)}
      key={key}
    >
      <div className="button-step d-flex justify-content-center align-items-center">{title}</div>
    </div>
  );
};

const ListWorker = ({ logOut, t, getRoleMe }) => {
  const userMeData = useSelector((store) => store.authentication.userMe);
  const [stepActive, setStepActive] = useState(null);
  const [activeMenu, setActiveMenu] = React.useState(0);
  const [screenNameWorker, setScreenNameWorker] = useState();
  const history = useHistory();
  const workName = (process.browser && new URL(window.location.href).searchParams.get('workName')) || null;
  const dataTimeNew = new Date().toLocaleDateString('ja-JP');
  const [listOrder, setListOrder] = useState('');
  const [dateFrom, setDateFrom] = useState('');
  const [dateTo, setDateTo] = useState('');
  const [activePage, setActivePage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [staff, setStaff] = useState({});
  const [totalMessages, setTotalMessages] = useState({total: ''});

  useEffect(() => {
    if (workName) {
      setStepActive(workName);
    };

    if (dataStep && workName) {
      const result = dataStep.filter((data) => data.name === workName);
      setActiveMenu(result[0].index);
      setScreenNameWorker(result[0].label);
    };

    if (workName === 'pre') {
      getListByStaff({ day: 'yesterday' });
    } else if (workName === 'today') {
      getListByStaff({ day: 'today' });
    } else if (workName === 'nextday') {
      getListByStaff({ day: 'tomorrow' });
    } else {
      getListByStaff({ day: '' });
    };

    if(userMeData?.data?.staffId) {
      getDetailStaff({ id: userMeData?.data?.staffId });
      getTotalMessages({});
    };
  }, [workName]);

  const onClickActive = (workName) => {
    setStepActive(workName);
    setActivePage(1);
    history.push(`/workerList?&workName=${workName}`);
  };

  const getListByStaff = async ({ page = '1', limit = '10', day = '', visitDateFrom = '', visitDateTo = '' }) => {
    try {
      setLoading(true);
      const data = await ORDER_PARTNER.GET_LIST_BY_STAFF({
        page: page,
        limit: limit,
        day: day,
        visitDateFrom: visitDateFrom,
        visitDateTo: visitDateTo,
      });
      if(data) {
        setLoading(true);
        setListOrder(data);
        setLoading(false);
      }else {

        setLoading(false);
        console.log(data);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const getTotalMessages = async () => {
    //setLoading(true);
    try {
      const data = await CHAT.GET_TOTAL_MESSAGES({});
      if (data) {
        setTotalMessages(data?.data);
      };
      //setLoading(false);
    } catch (e) {
      console.log(e);
      //setLoading(false);
    }
  };

  const getDetailStaff = async ({ id = '' }) => {
    try {
      const data = await STAFF_ENDPOINT.GET_DETAIL({ id });
      if(data) {
        setStaff(data?.data || {});
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onClickLogOut = () => {
    logOut();
  };

  const onChangeDateFrom = (date) => {
    const dateFromTmp = moment(new Date(date)).format(DATE_JP_FORMAT);
    setDateFrom(dateFromTmp);
  };

  const onChangeDateTo = (date) => {
    const dateToTmp = moment(new Date(date)).format(DATE_JP_FORMAT);
    setDateTo(dateToTmp);
  };

  const onSearch = () => {
    if (workName === 'pre') {
      getListByStaff({ day: 'yesterday', visitDateFrom: dateFrom, visitDateTo: dateTo });
    } else if (workName === 'today') {
      getListByStaff({ day: 'today', visitDateFrom: dateFrom, visitDateTo: dateTo });
    } else if (workName === 'nextday') {
      getListByStaff({ day: 'tomorrow', visitDateFrom: dateFrom, visitDateTo: dateTo });
    } else {
      getListByStaff({ day: '' });
    }
  };

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    if (workName === 'pre') {
      getListByStaff({ day: 'yesterday', page: pageNumber });
    } else if (workName === 'today') {
      getListByStaff({ day: 'today', page: pageNumber });
    } else if (workName === 'nextday') {
      getListByStaff({ day: 'tomorrow', page: pageNumber });
    } else {
      getListByStaff({ day: '', page: pageNumber });
    }
  };

  return (
    <div className="wrapper-work-list wrapper-work-list--wrap h-100 ">
      <Loading loading={loading} />
      <Helmet>
        <title>{(dataStep && dataStep[activeMenu]?.label) || ''}</title>
      </Helmet>
      <div className="block-link row mr-0 ml-0 wrapper-work-list--wrap-child d-flex">
        <div className="col-sm-0 col-md-4 col-lg-4 col-xl-4"></div>
        <div className="block-link-child col-sm-6 col-md-4 col-lg-4 col-xl-4">
          <Link to={`/workerList?&workName=today`} className="item">
            <span>一覧</span>
          </Link>{' '}
          <Link to={`/worker-map?workName=${workName}`} className="item">
            <span>マップ</span>
          </Link>
        </div>
        <div className="block-feature col-sm-6 col-md-4 col-lg-4 col-xl-4 pr-0">
          {
            <div className="form-icon-chat">
              <Link to="/chat-page" className="fas fa-comment-alt iconchat" target="_blank">
                <span className="chat--num">{totalMessages?.total ? (totalMessages?.total === 0 ? '' : totalMessages?.total) : ''}</span>
              </Link>
            </div>
          }
          <Link to="/select-role" className="mypage_a" onClick={() => getRoleMe()}>
            権限切替
          </Link>

          <Link to="/staff-working-schedule" className="mypage_a ml-2">
            マイページ
          </Link>

          <button onClick={() => onClickLogOut()} className="ml-2 logout mypage_a">
            ログアウト
          </button>
        </div>
      </div>

      <div className="wrapper-work-list--pdt-100">
        <div style={{ width: '100%' }} className="">
          <div style={{ fontSize: 30, textAlign: 'center' }} id="strdate">
            {dataTimeNew}({DAY_WEEK[new Date().getDay()]})
          </div>
          <div style={{ fontSize: 20, textAlign: 'center' }} className="my-3" id="str2">
            姓名： {staff.surName} {staff?.name}
          </div>
        </div>
        <div className="d-flex p-3 justify-content-center ">
          {dataStep.map((item, index) =>
            item.workName === stepActive
              ? renderButtonStep({
                  title: item.fullName,
                  active: true,
                  workName: item.workName,
                  onClickActive: onClickActive,
                  key: index,
                })
              : renderButtonStep({
                  title: item.fullName,
                  active: false,
                  workName: item.workName,
                  onClickActive: onClickActive,
                  key: index,
                })
          )}
        </div>
        {workName === 'nextday' && (
          <div className="my-3">
            <table>
              <colgroup>
                <col width="15%" />
                <col width="25%" />
                <col width="5%" />
                <col width="25%" />
                <col width="20%" />
              </colgroup>
              <tbody>
                <tr>
                  <td align="center">訪問日検索</td>
                  <td align="center">
                    <DateTimePickerCustom onChangeDate={onChangeDateFrom} />
                  </td>
                  <td align="center">〜</td>
                  <td align="center">
                    <DateTimePickerCustom onChangeDate={onChangeDateTo} />
                  </td>
                  <td align="center">
                    <div className="ui-btn ui-input-btn ui-corner-all ui-shadow ui-btn-inline ui-mini ui-btn-custom">
                      <input
                        type="submit"
                        onClick={onSearch}
                        value="検索"
                        data-inline="true"
                        className="btn btn-search"
                        data-mini="true"
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
        <div>
          <TableWorker data={listOrder && listOrder.data} t={t} workName={workName} />
          {!_.isEmpty(listOrder) && listOrder?.data?.count > 10 && (
            <PaginationCustom
              activePage={activePage}
              itemsCountPerPage={10}
              totalItemsCount={listOrder?.data?.count}
              pageRangeDisplayed={5}
              handlePageChange={handlePageChange}
            />
          )}
        </div>
        <MemoHistory colors={'rgb(230, 85, 41)'} colorText="white" screenName={screenNameWorker} />
        <div className="ui-footer ui-bar-inherit">
        <h3 className="ui-title" role="heading" aria-level="1">
          <a href="../terms_of_service.pdf" target="_brank" className="ui-link" data-ajax="false">
            利用規約
          </a>
        </h3>
      </div>
      </div>
      <FooterMaintenance/>
    </div>
  );
};
const mapActionToProps = {
  logOut,
  getRoleMe,
};

export default compose(translate('translations'), withRouter, connect(null, mapActionToProps))(ListWorker);
