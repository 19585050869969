import React from 'react';
import 'assets/scss/components/BorderGroupForm/style.scss';

const BorderGroupForm = ({ children, color = "#4F81BD" }) => {
  return (
    <div className="border-group-form" style={{ borderColor: `${color}` }}>
      <div className="border-group-form__body">
        {children}
      </div>
    </div>
  )
}

export default BorderGroupForm
