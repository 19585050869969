import React, { useEffect, useState } from 'react';
import { ORDER } from '../../../../../constants/endpoints';
import { pushToast } from '../../../../../components/Toast';
import Loading from 'components/Loading/Loading';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import 'assets/scss/page/AnkenMasterNew/TableAddRole.scss';
import { translate } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { useDispatch } from 'react-redux';
import { getDataHistoryMasterSetting } from 'actions/authentication';
import _ from 'lodash';
import { SCREEN_NAME } from '../../../../../helpers/constants';

const TableAddRoleNext = ({ routeList, nameScreen, t, idProvide = '' }) => {
  const dispatch = useDispatch();
  const [ListRoleFollowHeader, setListRoleFollowHeader] = useState({});
  const [orderSettingId, setOrderSettingId] = useState('');
  const [loading, setLoading] = useState(false);
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return dataResult(result);
  };

  const dataResult = (result) => result.map((i, index) => { i[1].key = index; return i; });

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const items = reorder(_.orderBy(_.toPairs(ListRoleFollowHeader), 'key'), result.source.index, result.destination.index);
    setListRoleFollowHeader(_.fromPairs(items));
  };

  useEffect(() => {
    getListRoleChild();
  }, []);

  useEffect(() => {
    getListRoleChild();
  }, [idProvide]);

  const onChangeStatus = (value, item) => {
    let data = {
      ...ListRoleFollowHeader,
      [item]: { ...ListRoleFollowHeader[item], value: !value }
    };

    setListRoleFollowHeader(data);
  };

  const getListRoleChild = async () => {
    try {
      setLoading(true);
      const data = await ORDER.GET_LIST_ROLE_FOLLOW_HEADER({
        arrRole: routeList,
        nameScreen, idProvide
      });
      if (data) {
        setListRoleFollowHeader(nameScreen === 'delivered_info' && [2, '2'].includes(routeList)  ? _.omit(data?.data?.result || {}, 'timestampReportUploadInfo') : data?.data?.result);
        setOrderSettingId(data?.data?.orderSettingId);
      }
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const handleUpdateListRoleChild = async ({ body, nameScreen, orderSettingId, updateTitle,screenName }) => {
    try {
      setLoading(true);
      const data = await ORDER.UPDATE_LIST_ROLE_FOLLOW_HEADER({ body, orderSettingId, nameScreen, idProvide, updateTitle, screenName });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
            pushToast('error', data?.message?.extra);
        } else {
            pushToast('error', data?.message?.errorMessage);
        }
      } else {
        pushToast('success', 'Update success');
        dispatch(getDataHistoryMasterSetting({ logId : '', screenName: SCREEN_NAME.MY_CORP }));
      }
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const onUpdateListRoleChild = () => {
    handleUpdateListRoleChild({ body: ListRoleFollowHeader, nameScreen, orderSettingId ,updateTitle: '保存 メニュー表示設定', screenName: SCREEN_NAME.MY_CORP });
  };

  const renderTableInfo = (data) => {
    return (
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>表示順</th>
            <th>項目名</th>
            <th>表示設定</th>
          </tr>
        </thead>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <tbody
                {...provided.droppableProps} ref={provided.innerRef}
              className="scroll-table">
              {data &&
                data?.map((item, index) => {
                  return (
                    <Draggable key={`${index + 1}`} draggableId={`${index + 1}`} index={index}>
                      {(provided) => (
                        <tr
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          key={index}
                        >
                        <td>{index + 1}</td>
                        <td className='td__item'>
                          {routeList === '2'
                            ? t(`table_order_list_center.${item?.[0]}`)
                            : routeList === '8'
                            ? t(`table_order_list_partner.${item?.[0]}`)
                            : routeList === '11'
                            ? t(`table_order_list_client.${item?.[0]}`)
                            : ''}
                        </td>
                        <td className='td__check'>
                          <span className="input-check">
                            <input
                              type="radio"
                              checked={item?.[1]?.value}
                              onChange={() => onChangeStatus(item?.[1]?.value, item?.[0])}
                            />
                            <label>表示</label>
                          </span>
                          <span className="input-check">
                            <input
                              type="radio"
                              onChange={() => onChangeStatus(item?.[1]?.value, item?.[0])}
                              checked={!item?.[1]?.value} />
                            <label>非表示</label>
                          </span>
                        </td>
                      </tr>
                      )}
                  </Draggable>
                  );
                })}
                {provided.placeholder}
              </tbody>
            )}
          </Droppable>
        </DragDropContext>
      </table>
    );
  };

  return (
    <div className="table-info-company ">
      <Loading loading={loading} />
      {renderTableInfo(_.orderBy(_.toPairs(ListRoleFollowHeader), 'key'))}
      <button
        className="btn-submit"
        onClick={onUpdateListRoleChild}
        disabled={Object.keys(ListRoleFollowHeader).length === 0}
      >保存</button>
    </div>
  );
};

export default compose(translate('translations'), withRouter)(TableAddRoleNext);
