import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { confirmDelete } from '../../../components/ConfirmDelete';
import { pushToast } from '../../../components/Toast';
import { ORDER_MANANGER } from '../../../constants/endpoints';
import { DATE_JP_FORMAT } from 'helpers/constants';
import './style.scss';

const ModalAdd = (props) => {
  const { toggle = () => { }, modal = false, dataSearch, getListAction } = props;
  const [listSearchSetting, setListSearchSetting] = useState([]);
  const [dataSearchAdd, setDataSearchAdd] = useState({});

  useEffect(() => {
    getListSearchSetting();
  }, []);

  useEffect(() => {
    setDataSearchAdd(dataSearch);
  }, [dataSearch]);

  const getListSearchSetting = async () => {
    try {
      const data = await ORDER_MANANGER.GET_LIST_SEARCH_SETTING();
      if (data) {
        setListSearchSetting(data?.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onDelete = (id) => {
    confirmDelete().then((res) => {
      if (res && res.value) {
        deleteSearch({ id: id });
      }
    });
  };

  const deleteSearch = async ({ id = '' }) => {
    try {
      const data = await ORDER_MANANGER.DELETE_SEARCH_SETTING({
        id: id,
      });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
      } else {
        pushToast('success', 'Delete user success');
        getListSearchSetting();
        getListAction();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onHandleText = (e) => {
    const name = e.target.name;
    let newData = { ...dataSearchAdd, [name]: e.target.value };
    setDataSearchAdd(newData);
  };

  const onClickSubmit = () => {
    let visitDateFrom1 = dataSearchAdd.visitDateFrom ? moment(new Date(dataSearchAdd.visitDateFrom)).format(DATE_JP_FORMAT) : '';
    let visitDateTo1 = dataSearchAdd.visitDateTo ? moment(new Date(dataSearchAdd.visitDateTo)).format(DATE_JP_FORMAT) : '';

    const tmpArr = {
      ...dataSearchAdd,
      visitDateFrom: visitDateFrom1,
      visitDateTo: visitDateTo1,
    };
    createSearch(tmpArr);
  };

  const createSearch = async (body) => {
    try {
      const data = await ORDER_MANANGER.CREATE_SEARCH_SETTING(body);
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
      } else {
        pushToast('success', 'Create data success');
        getListSearchSetting();
        getListAction();
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Modal className="form-add-goods-master" isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle}></ModalHeader>
      <ModalBody>
        <div className="summary-add">
          <div className="block-add row">
            <div className="col-3 text-center">
              <label>名所</label>
            </div>
            <div className="col-6">
              <input type="text" name="name" className="form-control" onChange={onHandleText} />
            </div>
            <div className="col-3 text-center">
              <button type="button" className="btn btn-add-search" onClick={onClickSubmit}>
                保存
              </button>
            </div>
          </div>
          <div className="block-list">
            {listSearchSetting &&
              listSearchSetting.map((item, index) => {
                return (
                  <p key={index} className="item">
                    <span className="icon-delete" onClick={() => onDelete(item.searchSettingId)}>
                      <i className="fas fa-times"></i>
                    </span>
                    {item.name}
                  </p>
                );
              })}
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalAdd;
