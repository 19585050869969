import React from 'react';
import './style.scss';
import _ from "lodash";

const TableDiv1 = ({ detailReceiveAll }) => {
	const listKeys = _.merge(detailReceiveAll?.CSVJP?.secondary?.data, detailReceiveAll?.CSVJP?.primary?.data);
	const headers = _.uniq((detailReceiveAll?.CSVJP?.primary?.headers || [])?.concat(detailReceiveAll?.CSVJP?.secondary?.headers || []));

	const renderRowItem = ({ firstColumn, secondColumn, thirdColumn, fourthColumn, key }) => {
		return (
			<tr key={key}>
				<td className='label'> { firstColumn } </td>
				<td className='value'> { secondColumn } </td>
				<td className='label'> { thirdColumn } </td>
				<td className='value'> { fourthColumn } </td>
			</tr>
		)
	}

  return (
    <table className='client-input-info'>
			<tbody>
				{headers.map((key, index) => {
					if (index % 2 === 0) {
						return renderRowItem({
							firstColumn: headers?.[index],
							secondColumn: listKeys?.[headers?.[index]],
							thirdColumn: headers?.[index + 1],
							fourthColumn: listKeys?.[headers?.[index + 1]],
							key,
						})
					}
				})}
			</tbody>
    </table>
  )
};

export default TableDiv1;
