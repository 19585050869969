import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { translate } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { pushToast } from '../../../../../components/Toast';
import { ORDER } from '../../../../../constants/endpoints';
import './style.scss';
import { CHECKED_MENU, SCREEN_NAME } from '../../../../../helpers/constants';
import _ from 'lodash';
import Loading from 'components/Loading/Loading';
import { getDataHistoryMasterSetting } from 'actions/authentication';
import { useDispatch } from 'react-redux';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const TableDetailSetting = ({ routeList, nameScreen, t, arrRole, idProvide = '' }) => {
  const dispatch = useDispatch();
  const [listDetailScreen, setListDetailScreen] = useState([]);
  const [detailId, setDetailId] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getListRoleChild();
  }, []);

  useEffect(() => {
    getListRoleChild();
  }, [idProvide]);

  const onChangeStatus = (elm) => {
    let data = listDetailScreen?.map((item) => {
      if (elm.name === item.name) {
        return { ...item, status: !elm.status };
      }
      return item;
    });
    setListDetailScreen(_.sortBy(data, 'sortNum'));
  };

  const getListRoleChild = async () => {
    try {
      setLoading(true);
      const data = await ORDER.GET_LIST_DETAIL_SETTING({
        arrRole: routeList,
        nameScreen: nameScreen,
        idProvide: idProvide,
      });
      if (data) {
        setListDetailScreen(_.sortBy(data?.data?.detailScreen, 'sortNum'));
        setDetailId(data?.data?.detailId);
      }
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const handleUpdateListRoleChild = async ({ body, detailId, nameScreen, updateTitle, screenNameLog }) => {
    try {
      setLoading(true);
      const data = await ORDER.UPDATE_LIST_DETAIL_SETTING({ body, detailId, nameScreen, idProvide, updateTitle, screenNameLog });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
      } else {
        dispatch(getDataHistoryMasterSetting({ logId: '', screenName: SCREEN_NAME.MY_CORP }));
        pushToast('success', 'Update success');
      }
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const onUpdateListRoleChild = () => {
    const arrTmp = listDetailScreen?.map((item, index) => {
      return { ...item, sortNum: index + 1 };
    });
    handleUpdateListRoleChild({ body: arrTmp, detailId, nameScreen ,updateTitle : '保存 メニュー表示設定', screenNameLog: SCREEN_NAME.MY_CORP });
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const item = reorder(listDetailScreen, result.source.index, result.destination.index);
    setListDetailScreen(item.map((i, index) => {i.sortNum = index; return i}));
  };

  const renderTableInfo = (data) => {
    return (
      <div className="model-content-detail-setting">
        <div className="row block-content block-content-top">
          <div className="col-6">オーダー明細設定</div>
          <div className="col-6">表示設定</div>
        </div>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {data &&
                  data?.filter(ele => ele?.name !== 'fileUpload')?.map((item, index) => {
                    if (item.name === "acceptanceInformation") return;
                    return (
                      <Draggable key={`${index + 1}`} draggableId={`${index + 1}`} index={index}>
                        {(provided, snapshot) => (
                          <div
                            key={index}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <div className="row block-content">
                              <div className="col-6">
                                {arrRole === 'center'
                                  ? t(`detail_title_list_order_center.${item.name}`)
                                  : arrRole === 'partnergroup'
                                  ? t(`detail_title_list_order_partner.${item.name}`)
                                  : arrRole === 'client'
                                  ? t(`detail_title_list_order_client.${item.name}`)
                                  : ''}
                              </div>
                              <div className="col-6">
                                <span className="input-check">
                                  <input
                                    id={`check-left-${index}`}
                                    type="radio"
                                    checked={item.status}
                                    onChange={() => onChangeStatus(item)}
                                    disabled={!CHECKED_MENU.includes(item.name)}
                                  />
                                  <label htmlFor={`check-left-${index}`}>表示</label>
                                </span>
                                <span className="input-check">
                                  <input
                                    id={`check-right-${index}`}
                                    type="radio"
                                    onChange={() => onChangeStatus(item)}
                                    checked={!item.status}
                                    disabled={!CHECKED_MENU.includes(item.name)}
                                  />
                                  <label htmlFor={`check-right-${index}`}>非表示</label>
                                </span>
                              </div>
                            </div>
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    );
  };

  return (
    <div className="table-info-company ">
      <Loading loading={loading} />
      {renderTableInfo(_.sortBy(listDetailScreen, 'sortNum'))}
      <button className="btn-submit" onClick={onUpdateListRoleChild}>
        保存
      </button>
    </div>
  );
};

export default compose(translate('translations'), withRouter)(TableDetailSetting);
