import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { DATE_JP_FORMAT, DATE_JP_RENDER, SCREEN_NAME, ROLE } from '../../../../../helpers/constants';
import { getRole } from '../../../../../helpers/helpers';

const Estimates = ({
  dataDetailEstimates,
  onHandleSubmit,
  stepPermission,
  recruitmentContractId,
  submitUpload,
  recruitmentEstimateId,
  dataUploadFile,
  removeFileStep,
  isChildCompany,
}) => {
  const organizationId = (process.browser && new URL(window.location.href).searchParams.get('organizationId')) || '';
  const idProvider = localStorage.getItem('idProvide');
  const [selectedName, setSelectedName] = useState('');
  const [selectedFile, setSelectedFile] = useState([]);
  const [dataEstimates, setDataEstimates] = useState({});
  const [listFileEstimates, setListFileEstimates] = useState([]);
  const checkRole = getRole({ idRole: localStorage.getItem('role') });
  const optionData = ['未契約', '契約対応中', '契約中', '契約終了', '契約停止'];
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (dataDetailEstimates) {
      setDataEstimates({
        comment: dataDetailEstimates?.comment || '',
        estimate: dataDetailEstimates?.estimate || '',
        state: dataDetailEstimates?.state || '',
        endDay: dataDetailEstimates?.endDay || '',
        addMessageERP: dataDetailEstimates?.addMessageERP || '',
        addMessagePartner: dataDetailEstimates?.addMessagePartner || '',
      });
      setListFileEstimates(dataDetailEstimates?.files);
      (checkRole === ROLE.PARTNER || (organizationId !== idProvider && isChildCompany)) &&
        setDisabled(['契約中', '契約終了', '契約停止'].includes(dataDetailEstimates?.state));
    }
  }, [dataDetailEstimates, isChildCompany]);

  useEffect(() => {
    if (dataUploadFile?.estimate) {
      setListFileEstimates(dataUploadFile?.estimate?.files);
      setSelectedFile([]);
    }
  }, [dataUploadFile]);

  const onChangeTextEstimates = (e) => {
    const { value, name } = e.target;
    setDataEstimates({ ...dataEstimates, [name]: value });
  };

  const onChangeOptionEstimates = (e) => {
    const { value, name } = e.target;
    setDataEstimates({ ...dataEstimates, [name]: value });
  };

  const onChangeHandler = async (e) => {
    const tmpFile = e?.target?.files;
    const base64Promises = [];
    Object.keys(tmpFile).map((item, index) => {
      base64Promises.push(toBase64(tmpFile[index]));
    });
    const base64s = await Promise.all(base64Promises);
    const tmpFileResult = Object.keys(tmpFile).map((item, index) => {
      return {
        fileName: tmpFile[index].name,
        buffer: base64s[index],
      };
    });
    setSelectedFile(tmpFileResult);
    setSelectedName(tmpFileResult.map((item) => item.fileName));
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handleUploadFile = () => {
    const body = {
      files: selectedFile,
      step: 2,
      contractEstimateId: recruitmentEstimateId,
      screenName: SCREEN_NAME.CONTRACT_MANAGEMENT,
    };
    setSelectedName('');
    document.getElementById('upload-csv-estimates').value = '';
    submitUpload(body);
  };

  const onChangeDateCredit = (date, text) => {
    if (date) {
      let newData = { ...dataEstimates, [text]: moment(new Date(date)).format(DATE_JP_FORMAT) };
      setDataEstimates(newData);
    } else {
      let newData = { ...dataEstimates, [text]: '' };
      setDataEstimates(newData);
    }
  };

  const onCreateAndUpdate = (textSubmit, status, updateTitle) => {
    onHandleSubmit(
      status === 'statusDone'
        ? { ...dataEstimates, screenName: SCREEN_NAME.CONTRACT_MANAGEMENT, isConfirm: true }
        : status === 'statusReject'
        ? { ...dataEstimates, screenName: SCREEN_NAME.CONTRACT_MANAGEMENT, isReject: true }
        : { ...dataEstimates, screenName: SCREEN_NAME.CONTRACT_MANAGEMENT },
      textSubmit,
      updateTitle
    );
  };

  const handleDeleteFile = (key) => {
    const body = {
      screenName: SCREEN_NAME.CONTRACT_MANAGEMENT,
      step: 2,
      key,
    };
    !disabled && removeFileStep(body);
  };

  return (
    <div className="detail-registration-contract">
      <div className="detail-basic-table-wrapper">
        <div className="title-absolute">
          見積{dataEstimates?.addMessageERP || dataEstimates?.addMessagePartner}
        </div>
        <div className="row">
          <div className="col-3">
            <label className="label-contract">見積</label>
          </div>
          <div className="col-9 d-flex">
            <input
              type="text"
              disabled={disabled}
              className={`${disabled ? 'input-contract-disabled' : ''} input-contract mr-2`}
              name="estimate"
              value={dataEstimates?.estimate}
              onChange={onChangeTextEstimates}
            />
          </div>
          <div className="col-3">
            <label className="label-contract">応募者ファイル</label>
          </div>
          <div className="col-9">
            <div className="row">
              <div className="col-3">
                <label
                  htmlFor="upload-csv-estimates"
                  className={`${disabled ? 'button-disable-contract' : ''} button-contract`}
                >
                  ファイル選択
                </label>
                <input
                  type="file"
                  name="file"
                  id="upload-csv-estimates"
                  disabled={disabled}
                  multiple
                  className="d-none input-contract"
                  onChange={onChangeHandler}
                />
              </div>
              <div className="col-6">
                <input
                  type="text"
                  className={`${disabled ? 'input-contract-disabled' : ''} input-contract`}
                  placeholder={selectedName}
                  disabled
                />
              </div>
              <div className="col-3">
                {!disabled ? (
                  <label className="button-contract" onClick={() => handleUploadFile()}>
                    アップロード
                  </label>
                ) : (
                  <label className={`${disabled ? 'button-disable-contract' : ''} button-contract`}>アップロード</label>
                )}
              </div>
            </div>
          </div>
        </div>
        {listFileEstimates?.map((item, index) => {
          return (
            <div key={index} className="row">
              <div className="col-3"></div>
              <div
                onClick={() => window.open(`${item?.url}`)}
                className={`${disabled ? 'disabled-text' : ''} col-4 text-left cursor-pointer`}
              >
                {item.name}
              </div>
              <div className={`${disabled ? 'disabled-text' : ''} col-4`} style={{ textAlign: 'end' }}>
                {item.timeUpload}
              </div>
              <div
                className={`${disabled ? 'disabled-text' : ''} col-1 cursor-pointer`}
                onClick={() => handleDeleteFile(item?.key)}
              >
                [X]
              </div>
            </div>
          );
        })}
        <div className="row">
          <div className="col-3">
            <label className="label-contract">コメント欄</label>
          </div>
          <div className="col-9">
            <input
              type="text"
              className={`${disabled ? 'input-contract-disabled' : ''} input-contract`}
              name="comment"
              value={dataEstimates?.comment}
              onChange={onChangeTextEstimates}
            />
          </div>
          <div className="col-3 text-center">
            <label className="label-contract">状態</label>
          </div>
          <div className="col-3">
            <select
              name="state"
              disabled={checkRole === ROLE.PARTNER || (organizationId !== idProvider && isChildCompany) || disabled}
              className="text-input select--result select--w100"
              onChange={onChangeOptionEstimates}
              value={dataEstimates?.state}
            >
              <option defaultValue=""></option>
              {optionData?.map((it, i) => {
                return (
                  <option value={it} key={i}>
                    {it}
                  </option>
                );
              })}
            </select>
            {/* <input
              type="text"
              className="input-contract"
              name="state"
              value={dataEstimates?.state}
              onChange={onChangeTextEstimates}
            /> */}
          </div>
          <div className="col-3">
            <label className="label-contract">完了日</label>
          </div>
          <div className="col-3">
            <DatePicker
              disabled={checkRole === ROLE.PARTNER || (organizationId !== idProvider && isChildCompany) || disabled}
              selected={!_.isEmpty(dataEstimates?.endDay) ? new Date(dataEstimates?.endDay) : ''}
              onChange={(date) => onChangeDateCredit(date, 'endDay')}
              locale="ja"
              dateFormat={DATE_JP_RENDER}
              dateFormatCalendar="yyyy年 MM月"
              className={`${checkRole === ROLE.PARTNER || (organizationId !== idProvider && isChildCompany) || disabled ? 'input-contract-disabled' : ''} input-contract`}
            />
          </div>
        </div>
        {checkRole === ROLE.CENTER || checkRole === ROLE.PARTNERGROUP ? (
          (idProvider === organizationId || (idProvider !== organizationId && !isChildCompany))  ? (
            <div className="justify-content-between align-items-center d-flex mb-2">
              <div>
                <button
                  disabled={disabled}
                  className={`${disabled ? 'button-disable-contract' : ''} button-submit-contract`}
                  onClick={() => {
                    let updateTitle = `完了 見積 ${dataEstimates?.state}`;
                    onCreateAndUpdate('estimates', 'statusDone', updateTitle);
                  }}
                >
                  完了
                </button>
              </div>
              <div>
                <button
                  disabled={disabled}
                  className={`${disabled ? 'button-disable-contract' : ''} button-submit-contract`}
                  onClick={() => {
                    let updateTitle = `差戻 見積 ${dataEstimates?.state}`;
                    onCreateAndUpdate('estimates', 'statusReject', updateTitle);
                  }}
                >
                  差戻
                </button>
              </div>
              <div>
                <button
                  disabled={disabled}
                  className={`${disabled ? 'button-disable-contract' : ''} button-submit-contract`}
                  onClick={() => {
                    let updateTitle = `保存 見積 ${dataEstimates?.state}`;
                    onCreateAndUpdate('estimates', '', updateTitle);
                  }}
                >
                  保存
                </button>
              </div>
            </div>
          ) : (
            <div className="justify-content-between align-items-center d-flex mb-2">
              <div></div>
              <div>
                {stepPermission === 2 ? (
                  <button
                    disabled={disabled}
                    className={`${disabled ? 'button-disable-contract' : ''} button-submit-contract`}
                    onClick={() => {
                      let updateTitle = `保存 見積 ${dataEstimates?.state}`;
                      onCreateAndUpdate('estimates', 'statusDone', updateTitle);
                    }}
                  >
                    保存
                  </button>
                ) : (
                  <button
                    disabled={disabled}
                    className={`${disabled ? 'button-disable-contract' : ''} button-submit-contract`}
                    onClick={() => {
                      let updateTitle = `保存 見積 ${dataEstimates?.state}`;
                      onCreateAndUpdate('estimates', '', updateTitle);
                    }}
                  >
                    保存
                  </button>
                )}
              </div>
            </div>
          )
        ) : (
          <div className="justify-content-between align-items-center d-flex mb-2">
            <div></div>
            <div>
              {stepPermission === 2 ? (
                <button
                  disabled={disabled}
                  className={`${disabled ? 'button-disable-contract' : ''} button-submit-contract`}
                  onClick={() => {
                    let updateTitle = `保存 見積 ${dataEstimates?.state}`;
                    onCreateAndUpdate('estimates', 'statusDone', updateTitle);
                  }}
                >
                  保存
                </button>
              ) : (
                <button
                  disabled={disabled}
                  className={`${disabled ? 'button-disable-contract' : ''} button-submit-contract`}
                  onClick={() => {
                    let updateTitle = `保存 見積 ${dataEstimates?.state}`;
                    onCreateAndUpdate('estimates', '', updateTitle);
                  }}
                >
                  保存
                </button>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Estimates;
