import React, { useEffect, useState } from 'react';
import 'assets/scss/page/CsvMaster/index.scss';
import FooterMaintenance from 'components/FooterMaintenance';
import HeaderMasterSetting from 'components/HeaderMasterSetting';
import PaginationCustom from 'components/PaginationCustom';
import { MASTER_SETTING } from 'constants/endpoints';
import CollapItem from './CollapItem';
import { useSelector } from 'react-redux';
import MemoHistory from '../../../components/MemoHistory';
import { SCREEN_NAME } from '../../../helpers/constants';
import Loading from 'components/Loading/Loading';

const CsvMaster = () => {
  const [listExportSetting, setListExportSetting] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [count, setCount] = useState('');
  const [hideshow, setHideshow] = useState('');
  const colorScreen = useSelector((store) => store.utils.colorMe);
  const [loading, setLoading] = useState(false);
  const [statusBeforeConfirm, setStatusBeforeConfirm] = useState(true);

  useEffect(() => {
    getListExportSetting({ page: activePage, limit: 10, nameRole: 'center' });
  }, [activePage]);

  const getListExportSetting = async ({ page = 1, limit = 10, nameRole = 'center', productId = '' }) => {
    setLoading(true);
    try {
      const data = await MASTER_SETTING.GET_LIST_EXPORT_CSV({
        page: page,
        limit: limit,
        nameRole: nameRole,
        productId: productId,
      });
      if (data) {
        if (productId !== '') {
          const tmp1 = listExportSetting;
          const tmp2 = tmp1.map((item) => {
            if (item.productId === data?.data?.products[0]?.productId) {
              return {
                ...item,
                partner: data?.data?.products[0]?.partner,
                center: data?.data?.products[0]?.center,
                client: data?.data?.products[0]?.client,
              };
            }
            return item;
          });
          setListExportSetting(tmp2);
          setCount(count);
        } else {
          setListExportSetting(data?.data?.products);
          setCount(data?.data?.count);
        }
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const handleHide = (id) => {
    if (hideshow === id) {
      setHideshow('');
    } else {
      setHideshow(id);
    }
  };

  const getListAgain = () => {
    getListExportSetting({ page: activePage, limit: 10 });
  };

  const onLoading = (status) => {
    setLoading(status);
  };

  const getListOption = ({ option }) => {
    setStatusBeforeConfirm(!!option?.confirmedTheDayBefore);
  };

  return (
    <div className="wrapper-master-import-csv">
      <Loading loading={loading} />
      <HeaderMasterSetting color={colorScreen?.code} colorText={colorScreen?.colorText} getListOption={getListOption} />
      <div className="body">
        <div className="wrapper-main">
          <div className="wrapper-result">
            {listExportSetting?.map((item, index) => {
              return (
                <CollapItem
                  item={item}
                  key={index}
                  colors={colorScreen?.code}
                  colorText={colorScreen?.colorText}
                  handleHide={handleHide}
                  hideshow={hideshow}
                  getListAgain={getListAgain}
                  onLoading={onLoading}
                  statusBeforeConfirm={statusBeforeConfirm}
                  activePage={activePage}
                  getListExportSetting={getListExportSetting}
                />
              );
            })}
            {count > 10 && (
              <PaginationCustom
                activePage={activePage}
                itemsCountPerPage={10}
                totalItemsCount={count}
                pageRangeDisplayed={5}
                handlePageChange={handlePageChange}
              />
            )}
          </div>
        </div>
      </div>
      <MemoHistory
        colors={colorScreen?.code}
        colorText={colorScreen?.colorText}
        screenName={SCREEN_NAME.CSV_OUTPUT_SETTINGS}
      />
      <FooterMaintenance />
    </div>
  );
};

export default CsvMaster;
