import React from 'react';
import { List_STEP } from './formatStepsByJson';
import './style.scss';
import { DATE_JP_FORMAT, STATUS_ORDER_CONDITION, STATUS_CONDITION_CSV } from 'helpers/constants';
import _ from 'lodash';
import moment from 'moment';
import { translate } from 'react-i18next';
import {formatInputSelectDate } from 'helpers/helpers';
import { compose } from 'redux';

const TableDiv2 = ({ t, detailReceiveAll }) => {
  const renderFormValue = ({ label, key, value }) => {
    // let statusConfirmed;
    // let statusRejectTmp;
    // let statusWaiting;
    // let statusReject;
    // if (detailReceiveAll?.partners) {
    //   if (Array.isArray(detailReceiveAll?.partners)) {
    //     statusConfirmed = detailReceiveAll?.partners?.some(element => element.statusOrderCondition === 'confirmed');
    //     statusRejectTmp = detailReceiveAll?.partners?.filter(element => element.statusOrderCondition === 'reject');
    //     statusWaiting = detailReceiveAll?.partners?.some(element => element.statusOrderCondition === 'waiting');
    //     statusReject = detailReceiveAll?.partners && (statusRejectTmp?.length === detailReceiveAll?.partners?.length)
    //   } else {
    //     var arrTmp = Object.keys(detailReceiveAll?.partners).map(key => {
    //       return detailReceiveAll?.partners[key];
    //     })
    //     statusConfirmed = arrTmp.some(element => element.statusOrderCondition === 'confirmed');
    //     statusRejectTmp = arrTmp.filter(element => element.statusOrderCondition === 'reject');
    //     statusWaiting = arrTmp.some(element => element.statusOrderCondition === 'waiting');
    //     statusReject = detailReceiveAll?.partners && (statusRejectTmp?.length === arrTmp.length)
    //   }
    // } else {
    //   statusConfirmed = false;
    //   statusRejectTmp = false;
    //   statusWaiting = false;
    //   statusReject = false;
    // }

    const handleStatus = () => {
      let statusCondition = '';
      if(detailReceiveAll?.partnerId) {
        statusCondition = STATUS_CONDITION_CSV.securingOperation;
      } else {
        if (!detailReceiveAll.partners || _.isEmpty(detailReceiveAll.partners)) {
          statusCondition = STATUS_CONDITION_CSV.unConsulted;
        }

        if (!_.isEmpty(detailReceiveAll.partners)) {
          if (
            detailReceiveAll.partners.find(
              e => e.statusOrderCondition === STATUS_ORDER_CONDITION.WAITING,
            ) &&
            !detailReceiveAll.partners.find(
              e => e.statusOrderCondition === STATUS_ORDER_CONDITION.CONFIRMED,
            )
          ) {
            statusCondition = STATUS_CONDITION_CSV.waitingForOperationReply;
          }

          if (
            detailReceiveAll.partners.find(
              e => e.statusOrderCondition === STATUS_ORDER_CONDITION.CONFIRMED,
            )
          ) {
            statusCondition = STATUS_CONDITION_CSV.operable;
          }

          if (
            detailReceiveAll.partners.filter(
              e => e.statusOrderCondition === STATUS_ORDER_CONDITION.REJECT,
            ).length === detailReceiveAll.partners.length
          ) {
            statusCondition = STATUS_CONDITION_CSV.notOperational;
          }
        }
      }
      return statusCondition
    };

    switch (key) {
      case 'visitDateFrom':
      case 'visitDateTo':
        return <div className="value"> {moment(`${formatInputSelectDate(detailReceiveAll?.[key])}`, `${DATE_JP_FORMAT}`).isValid() ? detailReceiveAll[key] || '' : `${detailReceiveAll?.visitDate || ''} ${detailReceiveAll[key] || ''}`}</div>
      case 'requestedDate':
      case 'visitDate':
      case 'reportReceiptDate':
      case 'roadsUePermitApplicationCompletionDate':
      case 'timestampReceiveInfo':
      case 'timeAddPartner':
      case 'timestampConditionInfo':
      case 'timeAddStaff':
      case 'timeBeforeConfirmFinish':
      case 'timestampRequestConfirm':
      case 'startTime':
      case 'endTime':
      case 'timestampBeforeConfirmWorkerRequest':
      case 'timePartnerReport':
      case 'timestampReportUploadInfo':
      case 'reportReturnDate':
      case 'timePartnerConfirm':
        return <div className="value"> {detailReceiveAll[key] || ''}</div>

      case 'timestampAppointInfo':
        return <div className="value"> {detailReceiveAll?.statusAppointInfo === false ? (detailReceiveAll[key] || '') : ''}</div>
      case 'adjustmentStatus':
        return <textarea
          className="value"
          onChange={() => {}}
          rows={1}
          value={handleStatus()}
        />
      case 'statusMonitoring':
        return <div className="value"> {t(`statusBeforeConfirm.${detailReceiveAll?.[key]}`)}</div>
      case 'priorConfirmationDate':
        if(label === '共架申請完了日') {
          const newData = _.find(detailReceiveAll?.beforeConfirmDocumentSetting, {name: '共架申請'});
          return <div className="value"> {newData?.[key] || ''}</div>
        } else if (label === 'GC局入局申請') {
          const newData = _.find(detailReceiveAll?.beforeConfirmDocumentSetting, {name: 'GC局入局申請'});
          return <div className="value"> {newData?.[key] || ''}</div>
        } else if (label === '鍵借用申請完了日') {
          const newData = _.find(detailReceiveAll?.beforeConfirmDocumentSetting, {name: '鍵借用申請'});
          return <div className="value"> {newData?.[key] || ''}</div>
        } else if (label === '道路使用許可申請') {
          const newData = _.find(detailReceiveAll?.beforeConfirmDocumentSetting, {name: '道路使用許可申請'});
          return <div className="value"> {newData?.[key] || ''}</div>
        } else if (label === '入館申請完了日') {
          const newData = _.find(detailReceiveAll?.beforeConfirmDocumentSetting, {name: '入館申請書類'});
          return <div className="value"> {newData?.[key] || ''}</div>
        } else {
          return <div className="value"> {detailReceiveAll?.[key] || ''}</div>
        }
      default:
        return <textarea
          className="value"
          rows={1}
          value={detailReceiveAll[key] || ''}
          onChange={() => {}}
        />
      }
  }

  const renderRowItems = ({ rows }) => {
    return <>
      {
        rows.map((row, index) => {
          return <div className="step-row-item" key={index}>
            {
              row.map(({ label, key, value }, indexKey) => {
                return <React.Fragment key={indexKey}>
                  <div className="label"> {label} </div>
                  {
                    renderFormValue({ label, key, value })
                  }
                </React.Fragment>
              })
            }
          </div>
        })
      }
    </>
  }

  const renderStepItem = ({ headerTitle, ...item }, index = 0) => {
    return (
      <div className="progress-step-item" key={index}>
        <div className="step-header-title"> {headerTitle} </div>
        <div className="step-content">
          {renderRowItems(item)}
        </div>
      </div>
    )
  }

  return (
    <div className="center-progress-tab">
      {List_STEP.map((item, index) => {
        return renderStepItem(item, index)
      })}
    </div>
  );
};

export default compose(translate('translations'))(TableDiv2);
