import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import FooterMaintenance from 'components/FooterMaintenance';
import HeaderMasterSetting from 'components/HeaderMasterSetting';
import { pushToast } from 'components/Toast';
import { MASTER_SETTING } from 'constants/endpoints';
import 'assets/scss/page/AggregateMaintenance/style.scss';
import { useSelector } from 'react-redux';
import MemoHistory from '../../../components/MemoHistory';
import { SCREEN_NAME } from '../../../helpers/constants';

const mockData = {
  nameScreenSetting: '',
  productIds: [],
  serviceIds: [],
  codeInStella: [],
};

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const AggregateMaintenance = (props) => {
  const colorScreen = useSelector((store) => store.utils.colorMe);
  const [listProducts, setListProducts] = useState([]);
  const [listProductMain, setListProductMain] = useState([]);
  const [dataMain, setDataMain] = useState(mockData);
  const [arrProduct, setArrProduct] = useState([]);
  const [id, setId] = useState('');
  const [listProductDetail, setListProductDetail] = useState(null);
  const [activeClass, setActiveClass] = useState('active-click');
  const [listServices, setListServices] = useState([]);
  const [listFieldMasters, setListFieldMasters] = useState([]);
  const [tmpListServices, setTmpListServices] = useState([]);
  const [tmpListFieldMasters, setTmpListFieldMasters] = useState([]);

  useEffect(() => {
    getDataScreenSetting();
    getListScreenSettingByProvider();
  }, []);

  const getDataScreenSetting = async () => {
    try {
      const data = await MASTER_SETTING.GET_DATA_SCREEN_SETTING();
      if (data) {
        setListProducts(data?.data?.products);
        setListServices(data?.data?.services);
        const tmpListService = data?.data?.services.map((item) => {
          return { ...item, statusCheck: false };
        });
        setTmpListServices(tmpListService);
        setListFieldMasters(data?.data?.fieldMasters);
        const tmpListFieldMaster = data?.data?.fieldMasters.map((item) => {
          return { ...item, statusCheck: false };
        });
        setTmpListFieldMasters(tmpListFieldMaster);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getListScreenSettingByProvider = async () => {
    try {
      const data = await MASTER_SETTING.GET_LIST_SCREEN_SETTING_BY_PROVIDER();
      if (data) {
        setListProductMain(data?.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (id) {
      getDetailScreenSetting({ id });
    } else {
      setDataMain(mockData);
      document.querySelectorAll('input[type=checkbox]').forEach((el) => (el.checked = false));
      getDataScreenSetting();
      getListScreenSettingByProvider();
    }
  }, [id]);

  const getDetailScreenSetting = async ({ id = '' }) => {
    try {
      const data = await MASTER_SETTING.GET_DETAIL_SCREEN_SETTING({ id: id });
      if (data?.data !== null) {
        setListProductDetail(data?.data?.productIds);
        let tmpListServicesDetail = tmpListServices.map((item) => {
          return item.serviceId;
        });
        let tmp = tmpListServicesDetail.filter((item) => {
          return !data?.data?.serviceIds.includes(item);
        });
        const tmpServiceFirst = data?.data?.serviceIds.map((item) => {
          return { serviceId: item, statusCheck: true };
        });
        const tmpServiceLast = tmp.map((item) => {
          return { serviceId: item, statusCheck: false };
        });
        const tmpServiceMain = tmpServiceFirst.concat(tmpServiceLast);
        const tmpMain = [];
        tmpServiceMain.forEach((e) => {
          const checkExit = listServices.find((i) => i && i.serviceId === e.serviceId);
          if (checkExit) {
            checkExit.statusCheck = e.statusCheck;
            tmpMain.push(checkExit);
          }
        });
        setTmpListServices(tmpMain);
        let tmpListFieldMastersDetail = tmpListFieldMasters.map((item) => {
          return item.codeInStella;
        });
        let tmpFieldMaster = tmpListFieldMastersDetail.filter((item) => {
          return !data?.data?.codeInStella.includes(item);
        });
        const tmpFieldMasterFirst = data?.data?.codeInStella.map((item) => {
          return { codeInStella: item, statusCheck: true };
        });
        const tmpFieldMasterLast = tmpFieldMaster.map((item) => {
          return { codeInStella: item, statusCheck: false };
        });
        const tmpFieldMasterMain = tmpFieldMasterFirst.concat(tmpFieldMasterLast);
        const tmpMainFieldMaster = [];
        tmpFieldMasterMain.forEach((e) => {
          const checkExitFieldMaster = listFieldMasters.find((i) => i && i.codeInStella === e.codeInStella);
          if (checkExitFieldMaster) {
            checkExitFieldMaster.statusCheck = e.statusCheck;
            tmpMainFieldMaster.push(checkExitFieldMaster);
          }
        });
        setTmpListFieldMasters(tmpMainFieldMaster);
        let newData = {
          ...dataMain,
          nameScreenSetting: data?.data?.nameScreenSetting,
          productIds: data?.data?.productIds,
          serviceIds: data?.data?.serviceIds,
          codeInStella: data?.data?.codeInStella,
        };
        setDataMain(newData);
      } else {
        setDataMain(mockData);
        document.querySelectorAll('input[type=checkbox]').forEach((el) => (el.checked = false));
        getDataScreenSetting();
        getListScreenSettingByProvider();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleChangeText = (e) => {
    const { value, name } = e.target;
    let newData = { ...dataMain, [name]: value };
    setDataMain(newData);
  };

  const handleChangeCheck = (e) => {
    let arrTmp = [];
    if (id) {
      arrTmp = listProductDetail;
    } else {
      arrTmp = arrProduct;
    }
    let target = e.target;
    let value = target.value;
    if (target.checked) {
      arrTmp.push(value);
    } else {
      arrTmp.splice(arrTmp.indexOf(value), 1);
    }
    setArrProduct(arrTmp);
    let newData = { ...dataMain, productIds: arrTmp };
    setDataMain(newData);
  };

  const handleChangeCheckService = (idService, e) => {
    let { value } = e.target;
    let isTrueSet = value == 'true';
    const tmp = tmpListServices.map((item) => {
      if (idService === item.serviceId) {
        return { ...item, statusCheck: !isTrueSet };
      }
      return item;
    });
    setTmpListServices(tmp);
  };

  const handleChangeCheckFieldMaster = (idFieldMaster, e) => {
    let { value } = e.target;
    let isTrueSet = value == 'true';
    const tmp = tmpListFieldMasters.map((item) => {
      if (idFieldMaster === item.codeInStella) {
        return { ...item, statusCheck: !isTrueSet };
      }
      return item;
    });
    setTmpListFieldMasters(tmp);
  };

  const onSubmit = () => {
    const tmpService = tmpListServices
      .filter((item) => {
        return item.statusCheck === true;
      })
      .map((item2) => {
        return item2.serviceId;
      });
    const tmpFieldMaster = tmpListFieldMasters
      .filter((item) => {
        return item.statusCheck === true;
      })
      .map((item2) => {
        return item2.codeInStella;
      });
    if (id) {
      updateProduct({ dataMain: { ...dataMain, serviceIds: tmpService, codeInStella: tmpFieldMaster , screenName : SCREEN_NAME.AGGREGATE_SUMMARY_SETTINGS }, id: id });
    } else {
      createProduct({ ...dataMain, serviceIds: tmpService, codeInStella: tmpFieldMaster , screenName : SCREEN_NAME.AGGREGATE_SUMMARY_SETTINGS });
    }
  };

  const updateProduct = async ({ dataMain = {}, id = '' }) => {
    try {
      const data = await MASTER_SETTING.UPDATE_LIST_SCREEN_SETTING({ dataMain: dataMain, id: id });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
      } else {
        pushToast('success', 'Update data success');
        getListScreenSettingByProvider();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const createProduct = async (body) => {
    try {
      const data = await MASTER_SETTING.CREATE_LIST_SCREEN_SETTING(body);
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
      } else {
        pushToast('success', 'Create data success');
        setDataMain(mockData);
        document.querySelectorAll('input[type=checkbox]').forEach((el) => (el.checked = false));
        setArrProduct([]);
        getDataScreenSetting();
        getListScreenSettingByProvider();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleDetailProduct = (idProduct, e) => {
    setActiveClass(e.target.id);
    setId(idProduct);
  };

  const onDragEndService = (result) => {
    if (!result.destination) {
      return;
    }
    const itemServiceTmp = reorder(tmpListServices, result.source.index, result.destination.index);
    setTmpListServices(itemServiceTmp);
    const itemService = reorder(listServices, result.source.index, result.destination.index);
    setListServices(itemService);
  };

  const onDragEndFieldMaster = (result) => {
    if (!result.destination) {
      return;
    }
    const itemFieldMasterTmp = reorder(tmpListFieldMasters, result.source.index, result.destination.index);
    setTmpListFieldMasters(itemFieldMasterTmp);
    const itemFieldMaster = reorder(listFieldMasters, result.source.index, result.destination.index);
    setListFieldMasters(itemFieldMaster);
  };

  const handleChangeCheckAllService = (e) => {
    const target = e.target;
    if (target.checked) {
      const tmpService = tmpListServices.map((item) => {
        return { ...item, statusCheck: true };
      });
      setTmpListServices(tmpService);
    } else {
      const tmpService = tmpListServices.map((item) => {
        return { ...item, statusCheck: false };
      });
      setTmpListServices(tmpService);
    }
  };
  const handleChangeCheckAllFieldMaster = (e) => {
    const target = e.target;
    if (target.checked) {
      const tmpFieldMaster = tmpListFieldMasters.map((item) => {
        return { ...item, statusCheck: true };
      });
      setTmpListFieldMasters(tmpFieldMaster);
    } else {
      const tmpFieldMaster = tmpListFieldMasters.map((item) => {
        return { ...item, statusCheck: false };
      });
      setTmpListFieldMasters(tmpFieldMaster);
    }
  };

  return (
    <div className="wrapper-master-setting">
      <HeaderMasterSetting  color={colorScreen?.code} colorText={colorScreen?.colorText} />
      <div className="body pb-2">
        <div className="wrapper-main">
          <div className="aggregate">
            <div className="block-btn">
              <span
                className={`btn-item ${activeClass === 'active-click' ? 'btn-item-active' : ''}`}
                id="active-click"
                onClick={(e) => handleDetailProduct(null, e)}
              >
                新規
              </span>
              {listProductMain &&
                listProductMain.map((item, index) => {
                  return (
                    <span
                      key={index}
                      className={`btn-item ${activeClass === `active-click-${index}` ? 'btn-item-active' : ''}`}
                      id={`active-click-${index}`}
                      onClick={(e) => handleDetailProduct(item.screenSettingId, e)}
                    >
                      {item.nameScreenSetting}
                    </span>
                  );
                })}
            </div>
            <table className="table table-bordered">
              <tbody>
                <tr>
                  <td>
                    サマリ名称 <br /> リンク名称に使用します。10文字以下推奨
                  </td>
                  <td>
                    <input
                      type="text"
                      name="nameScreenSetting"
                      value={dataMain.nameScreenSetting}
                      className="form-control"
                      onChange={handleChangeText}
                    />
                  </td>
                </tr>
                <tr>
                  <td>対象案件</td>
                  <td>
                    {listProducts &&
                      listProducts.map((item, index) => {
                        return (
                          <span key={index} className="item-check">
                            {id ? (
                              <input
                                type="checkbox"
                                value={item.productId}
                                onChange={handleChangeCheck}
                                checked={listProductDetail && listProductDetail.includes(item.productId)}
                              />
                            ) : (
                              <input type="checkbox" value={item.productId} onChange={handleChangeCheck} />
                            )}
                            <label>{item.name}</label>
                          </span>
                        );
                      })}
                  </td>
                </tr>
                <tr>
                  <td>対象工程</td>
                  <td>
                    <div className="item-all">
                      <input type="checkbox" onChange={handleChangeCheckAllService} />
                      <label>Check all</label>
                    </div>
                    <DragDropContext onDragEnd={onDragEndService}>
                      <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                          <div
                            className="block-check-service-field-master"
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                          >
                            {tmpListServices &&
                              tmpListServices.map((item, index) => {
                                return (
                                  <Draggable key={`${index + 1}`} draggableId={`${index + 1}`} index={index}>
                                    {(provided, snapshot) => (
                                      <div
                                        key={index}
                                        className="item"
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                      >
                                        <input
                                          name="listService[]"
                                          type="checkbox"
                                          value={item.statusCheck}
                                          onChange={(e) => handleChangeCheckService(item.serviceId, e)}
                                          checked={item.statusCheck}
                                        />
                                        <label>{item.name}</label>
                                      </div>
                                    )}
                                  </Draggable>
                                );
                              })}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </td>
                </tr>
                <tr>
                  <td>行カラム</td>
                  <td>
                    <div className="item-all">
                      <input type="checkbox" onChange={handleChangeCheckAllFieldMaster} />
                      <label>Check all</label>
                    </div>
                    <DragDropContext onDragEnd={onDragEndFieldMaster}>
                      <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                          <div
                            className="block-check-service-field-master"
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                          >
                            {tmpListFieldMasters &&
                              tmpListFieldMasters.map((item, index) => {
                                return (
                                  <Draggable key={`${index + 1}`} draggableId={`${index + 1}`} index={index}>
                                    {(provided, snapshot) => (
                                      <div
                                        key={index}
                                        className="item"
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                      >
                                        <input
                                          name="listFieldMaster[]"
                                          type="checkbox"
                                          value={item.statusCheck}
                                          onChange={(e) => handleChangeCheckFieldMaster(item.codeInStella, e)}
                                          checked={item.statusCheck}
                                        />
                                        <label>{item.label}</label>
                                      </div>
                                    )}
                                  </Draggable>
                                );
                              })}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="btn-submit-block">
              <button className="btn btn-submit" onClick={onSubmit}>
                保存
              </button>
            </div>
          </div>
        </div>
      </div>
      <MemoHistory colors={colorScreen?.code} colorText={colorScreen?.colorText} screenName={SCREEN_NAME.AGGREGATE_SUMMARY_SETTINGS}/>
      <FooterMaintenance />
    </div>
  );
};

export default AggregateMaintenance;
