import React from 'react';
import './style.scss';
import { Link } from 'react-router-dom';

const ButtonClient = ({ subTitle, color, icon, width, font }) => {
  return (
    <Link to = '/organizations/modify_organization?sid=new' style={{color:"white " ,textDecoration : 'none'}}>
      <div className="wrapper-button-client-master-all">
        <div
          className={`wrapper-button-client-master d-flex align-content-center ${color}`}
          style={{ width: width }}
        >
          <i className={`icon-master ${icon}`} />
          <div className="d-flex align-items-center justify-content-center flex-column">
            <p className="mb-0 title" style={{ fontSize: font }}>
              {subTitle}
            </p>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default ButtonClient;
