import React, { useState, useEffect, Fragment } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import 'assets/scss/page/AnkenMasterNew/ModalDocumentSettings.scss';
import { pushToast } from 'components/Toast';
import { ANKEN_MASTER_NEW } from 'constants/endpoints';
import ModalAddOptionDocument from '../ModalAddOptionDocument';
import { OPTION_FROM_TO_ANKEN_MASTER, OPTION_FROM_TO_ANKEN_MASTER_MANAGEMENTGROUP, OPTION_SCREEN_ANKEN_MASTER, SCREEN_NAME, OPTION_DOCUMENT_SETTINGS } from 'helpers/constants';
import _ from 'lodash';
import CollapseTreeDocument from './CollapseTreeDocument';
import Select from 'react-select';
import Loading from 'components/Loading/Loading';
import { getDataHistoryMasterSetting } from 'actions/authentication';
import { useDispatch } from 'react-redux';

const listColumn = [
  {name: '',  width: '3%'},
  {name: '種別',  width: '5%'},
  {name: '資料種別',  width: '5%'},
  {name: '資料名',  width: '8%'},
  {name: 'プロジェクト名',  width: '8%'},
  {name: '工事名',  width: '8%'},
  {name: '工程名',  width: '8%'},
  {name: 'アップロードルール（From/To）',  width: '27%'},
  {name: '制限',  width: '5%'},
  {name: '参照範囲',  width: '20%'},
];

const bodyCreateDefaulf = {
  materialTypeId: '',
  typeChildId: '',
  numberOfMaterials: '',
  projectId: '',
  productId: '',
  serviceId: '',
  uploadFrom: [],
  uploadFromTo: [],
  uploadTo: [],
  screen: '',
  isRequired: '',
  reference: [],
  materialName:''
};

const ModalDocumentSettings = (props) => {
  const { toggle = () => { }, modal = false, dataDocumentSetting, dataTreeOption, colors, statusBeforeConfirm, nameTitle } = props;
  const dispatch = useDispatch();
  const [modalAddOptionDocument, setModalAddOptionDocument] = useState(false);
  const [listSelectMaterial, setListSelectMaterial] = useState([]);
  const [listTypeChild, setListTypeChild] = useState([]);
  const [bodyCreate, setBodyCreate] = useState(bodyCreateDefaulf);
  const [statusProject, setStatusProject] = useState(false);
  const [statusProduct, setStatusProduct] = useState(false);
  const [optionProject, setOptionProject] = useState([]);
  const [optionProduct, setOptionProduct] = useState([]);
  const [optionService, setOptionService] = useState([]);
  const [optionAllProduct, setOptionAllProduct] = useState([]);
  const [optionAllService, setOptionAllService] = useState([]);
  const [listDocumentSetting, setListDocumentSetting] = useState([]);
  const [serviceProduct, setServiceProduct] = useState({});
  const roleId = localStorage.getItem('role');
  const [listTree, setListTree] = useState({});
  const [loading, setLoading] = useState(false);

  const getTreeDocumentSetting = async ({ projectId = '', productId = '' }) => {
    try {
      setLoading(true);
      const data = await ANKEN_MASTER_NEW.GET_TREE_DOCUMENT_SETTING({
        projectId, productId
      });
      if (data) {
        setListTree(data?.data);
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  useEffect (() => {
    if (optionAllProduct) {
      const serviceGroupByProduct = {};
      const services = optionAllProduct.map(product => {
        serviceGroupByProduct[product.productId] = product?.serviceSchema || []
      })
      setServiceProduct(serviceGroupByProduct)
    }
  }, [optionAllProduct])

  const toggleCloseModal = () => {
    setModalAddOptionDocument(false);
    getData()
    // getListSelectMaterial({ ...dataDocumentSetting });
  };

  const toggleOpenModalDocumentSettings = () => {
    setModalAddOptionDocument(!modalAddOptionDocument)
  };

  const getListSelectMaterial = async ({ projectId = '', productId = '' }) => {
    try {
      setLoading(true);
      const data = await ANKEN_MASTER_NEW.GET_LIST_SELECT_MATERIAL({
        projectId, productId
      });
      if (data) {
        setListSelectMaterial(data?.data);
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const getListDocumentSetting = async ({ projectId = '', productId = '' }) => {
    try {
      setLoading(true);
      const data = await ANKEN_MASTER_NEW.GET_LIST_DOCUMENT_SETTING({
        projectId, productId
      });
      if (data) {
        setListDocumentSetting(data?.data);
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const getData = () => {
    if (!_.isEmpty(dataDocumentSetting.productId)  && !_.isEmpty(dataDocumentSetting.projectId )) {
      const data = { productId: dataDocumentSetting.productId , projectId: dataDocumentSetting.projectId}
      getTreeDocumentSetting({  productId: dataDocumentSetting.productId });
      getListSelectMaterial(data);
      getListDocumentSetting(data);
    }
    if (!_.isEmpty(dataDocumentSetting.productId) && _.isEmpty(dataDocumentSetting.projectId)) {
      const data = { productId: dataDocumentSetting.productId }
      getTreeDocumentSetting({ productId: dataDocumentSetting.productId });
      getListDocumentSetting(data);
      getListSelectMaterial(data);
    }
    dispatch(getDataHistoryMasterSetting({ logId: '', screenName: SCREEN_NAME.CASE_MASTA }));
  }

  useEffect(() => {
    // getListSelectMaterial({ ...dataDocumentSetting });
    // getListDocumentSetting({ ...dataDocumentSetting });
    getData()
    if(!_.isEmpty(dataDocumentSetting.projectId) && !_.isEmpty(dataDocumentSetting.productId)) {
      setStatusProject(true);
      setStatusProduct(true);
      setBodyCreate({
        ...bodyCreate,
        projectId: dataDocumentSetting.projectId,
        productId: dataDocumentSetting.productId,
      });
      setOptionProject([dataTreeOption]);
      setOptionProduct(dataTreeOption?.product);
      setOptionAllProduct(dataTreeOption.product);
      setOptionAllService(_.uniqBy(_.flatten(dataTreeOption.product.map(i => i.service)), 'serviceId'));
    }
    if(!_.isEmpty(dataDocumentSetting.productId) && _.isEmpty(dataDocumentSetting.projectId)) {
      setStatusProject(true);
      setStatusProduct(true);
      setBodyCreate({
        ...bodyCreate,
        productId: dataDocumentSetting.productId,
      });
      setOptionProduct([dataTreeOption]);
      setOptionAllProduct([dataTreeOption]);
      setOptionService(dataTreeOption.service);
      setOptionAllService(dataTreeOption.service);
    }
    // if {
    //   setStatusProject(true);
    //   setStatusProduct(true);
    //   setBodyCreate({
    //     ...bodyCreate, ...dataDocumentSetting
    //   });
    //   setOptionProject([{
    //     ...dataTreeOption,
    //     projectCode: dataTreeOption.idProject
    //   }]);
    //   setOptionProduct([dataTreeOption]);
    //   setOptionAllProduct([dataTreeOption]);
    //   setOptionService(dataTreeOption.service);
    //   setOptionAllService(dataTreeOption.service);
    // }
  }, []);

  useEffect(() => {
    if (bodyCreate.materialTypeId) {
      setBodyCreate({
        ...bodyCreate,
        typeChildId: '',
      });
    }
  }, [bodyCreate.materialTypeId]);

  useEffect(() => {
    if (bodyCreate.productId) {
      setOptionService(dataTreeOption?.service);
      setBodyCreate({
        ...bodyCreate,
        serviceId: '',
      });
    }
    else {
      setOptionService([]);
    }
  }, [bodyCreate.productId]);

  const handleChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    if (name === 'materialName') {
      const elm = listSelectMaterial ?.find((i) => i.name === value);
      setBodyCreate({
        ...bodyCreate,
        [name]: value,
        materialTypeId: elm.materialTypeId
      })
    } else {
      setBodyCreate({
        ...bodyCreate,
        [name]: value
      })
    }
  };

  const handleChangeSelect = (selected, name) => {
    const value = selected?.map(i => i.value) || [];
    setBodyCreate({ ...bodyCreate, [name]: value });
  };

  const handleChangeSelectFromTo = (selected, name) => {
    const fromTo = checkUploadFromTo(selected);
    setBodyCreate({ ...bodyCreate, [name]: [selected.value] || [], uploadFrom: fromTo.from, uploadTo: fromTo.to, screen: fromTo.screen });
  };

  const checkUploadFromTo = (elm) => {
    switch (elm.value) {
      case 1:
        return {from: ['ERP'], to: ['ERP', 'Partner', 'Monitoring'], screen: 'All'};
      case 2:
        return {from: ['ERP'], to: ['Partner'], screen: '受注情報・稼働待ち情報・依頼一覧'};
      case 3:
        return {from: ['Partner'], to: ['ERP'], screen: '報告書未提出'};
      case 4:
        return {from: ['ERP'], to: ['Monitoring'], screen: '報告書回収'};
      case 5:
        return {from: ['ERP', 'Partner'], to: ['ERP', 'Partner'], screen: '事前確認'};
      case 6:
        return {from: ['Monitoring'], to: ['ERP'], screen: '依頼一覧'};
      default:
        return {from: [], to: [], screen: ''};
    }
  };

  useEffect(() => {
    if (bodyCreate.materialTypeId) {
      const optionTypeChild = listSelectMaterial ?.filter(item => item.materialTypeId === bodyCreate.materialTypeId)[0];
      setListTypeChild(optionTypeChild.typeChild);
    }
    if (bodyCreate.productId && !_.isEmpty(dataDocumentSetting.productId) && !_.isEmpty(dataDocumentSetting.projectId)) {
      const optionService = dataTreeOption?.product ?.filter(item => item.productId === bodyCreate.productId)[0];
      setOptionService(optionService?.service);
    }
  }, [bodyCreate]);

  const handleAddDocumentSetting = async ({ body }) => {
    try {
      setLoading(true);
      const data = await ANKEN_MASTER_NEW.ADD_DOCUMENT_SETTING({ body });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
        setLoading(false);
      } else {
        pushToast('success', 'Add Document setting success');
        // getListDocumentSetting({ ...dataDocumentSetting });
        getData();
        setBodyCreate({
          ...bodyCreateDefaulf,
          projectId: dataDocumentSetting.projectId,
          productId: dataDocumentSetting.productId});
        setLoading(false);
        setListTypeChild([]);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const handleAddDocument = () => {
    const newData = {...bodyCreate};
    delete newData.materialName;
    handleAddDocumentSetting({
      body: {
        ...newData,
        screenName: SCREEN_NAME.CASE_MASTA,
        updateTitle: `追加 ドキュメント設定 ${nameTitle ? nameTitle : ''}`,
      },
    })
  };

  const deleteDocument = ({ documentSettingId }) => {
    handleDeleteDocumentSetting({documentSettingId})
  };

  const handleDeleteDocumentSetting = async ({ documentSettingId = '' }) => {
    try {
      setLoading(true);
      const data = await ANKEN_MASTER_NEW.DELETE_DOCUMENT_SETTING({documentSettingId});
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
        setLoading(false);
      } else {
        pushToast('success', 'Delete Document Setting success');
        // getListDocumentSetting({ ...dataDocumentSetting });
        getData();
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const handleChangeDocument = ({e, documentSettingId}) => {
    const name = e.target.name;
    const value = e.target.value;
    const newList = listDocumentSetting ?.map(item => {
      if (item.documentSettingId === documentSettingId) {
        if (name === 'materialName') {
          const elm = listSelectMaterial ?.find((i) => i.name === value);
          return {
            ...item,
            materialTypeId: elm.materialTypeId,
            materialName: value,
            typeChildId: ''
          }
        } else {
          return {
            ...item,
            [name]: value,
          }
        }

      } else {
        return item
      }
    });
    setListDocumentSetting(newList)
  }

  const handleChangeDocumentSelection = ({e, documentSettingId, name}) => {
    const value = e?.map(i => i.value) || [];
    const newList = listDocumentSetting ?.map(item => {
      if (item.documentSettingId === documentSettingId) {
        return {
          ...item,
          [name]: value,
        }
      } else {
        return item
      }
    });
    setListDocumentSetting(newList)
  };

  const handleChangeDocumentFromTo = ({e, documentSettingId, name}) => {
    const fromTo = checkUploadFromTo(e);
    const newList = listDocumentSetting ?.map(item => {
      if (item.documentSettingId === documentSettingId) {
        return {
          ...item, [name]: [e.value] || [], uploadFrom: fromTo.from, uploadTo: fromTo.to, screen: fromTo.screen
        }
      } else {
        return item
      }
    });
    setListDocumentSetting(newList)
  };

  const handleValue = (value = []) => OPTION_DOCUMENT_SETTINGS.filter((i) => value?.includes(i.value))
  const handleValueReference = (value = []) => (['5', '6', '7', 5, 6, 7].includes(roleId) ? OPTION_FROM_TO_ANKEN_MASTER_MANAGEMENTGROUP : OPTION_FROM_TO_ANKEN_MASTER).filter((i) => value?.includes(i.value))
  const renderBodyDocument = (item, index) => {
    return (
      <Fragment key={index}>
        <div className="row-table">
          <div
            className="item-content right-margin"
            style={{ width: `3%` }}>
          <div
              className="icon"
              onClick={() => deleteDocument({documentSettingId: item.documentSettingId})}
          >
            <i className="fas fa-times"></i>
          </div>
        </div>
          <div
            className="item-content right-margin"
            style={{ width: `5%` }}
          >
          <select
            name="materialName"
            id="materialTypeId"
            onChange={(e)=>handleChangeDocument({e, documentSettingId: item.documentSettingId})}
            value={item.materialName}
            title={listSelectMaterial.filter(elm => item.materialTypeId === elm.materialTypeId)[0] ?.name}
            style={{
              backgroundColor: colorBtn(item.materialName),
              color: item.materialName === '展開資料' ? `#fff` : `#000`
            }}
            className="item-update">
            <option disabled value=''> --- </option>
            {
              listSelectMaterial ?.map((elm,index) => {
                return (
                  <option
                    key={index} value={elm.materialName}
                    style={{
                      backgroundColor: colorBtn(elm.name),
                      color: elm.name === '展開資料' ? `#fff` : `#000`
                    }}
                  >{elm.name}</option>
                )
              })
            }
          </select>
        </div>

          <div
          className="item-content right-margin"
          style={{ width: `5%` }}>
          <select
            name="typeChildId"
            id="typeChildId"
            className="item-update"
            value={item.typeChildId}
            onChange={(e)=>handleChangeDocument({e, documentSettingId: item.documentSettingId})}
            title={listSelectMaterial.filter(elm =>
              item.materialTypeId === elm.materialTypeId)[0] ?.typeChild ?.filter(value =>
                value.id === item.typeChildId)[0]?.name}
          >
            <option disabled value=''> --- </option>
            {
              listSelectMaterial ?.filter(value => value.materialTypeId === item.materialTypeId)[0]?.typeChild ?.map((elm, index) => {
                return (
                  <option key={index} value={elm.id}>{elm.name}</option>
                )
              })
            }
          </select>
        </div>

          <div
          className="item-content right-margin"
          style={{ width: `8%` }}>
          <input
            name="numberOfMaterials"
            id="numberOfMaterials"
            value={item.numberOfMaterials}
            title={item.numberOfMaterials}
            onChange={(e)=>handleChangeDocument({e, documentSettingId: item.documentSettingId})}
            className="item-update"
          />
        </div>

          <div
          className="item-content right-margin"
          style={{ width: `8%` }}>
          <select
            disabled
            name="projectId"
            id="projectId"
            value={item.projectId}
            title={optionProject.filter(elm => item.projectId === elm.projectCode)[0]?.projectName}
            className="item-update">
            <option value=''> --- </option>
            {
              optionProject ?.map((item, index) => {
                return (
                  <option value={item.projectCode} key={index}>{item.projectName}</option>
                )
              })
            }
          </select>
        </div>

        <div
          className="item-content right-margin"
          style={{ width: `8%` }}>
          <select
            disabled
            value={item.productId}
            title={optionAllProduct?.find(elm => item.productId === elm.productId)?.name}
            name="productId"
            id="productId"
            className="item-update">
            <option value=''> --- </option>
            {
              optionAllProduct ?.map((item, index) => {
                return (
                  <option value={item.productId} key={index}>{item.name}</option>
                )
              })
            }
          </select>
        </div>
          <div
          className="item-content right-margin"
          style={{ width: `8%` }}>
          <select
            disabled
            value={item.serviceId}
            title={optionAllService?.filter(elm => item?.serviceId === elm?.serviceId)[0]?.name}
            name="serviceId"
            id="serviceId"
            className="item-update">
            <option value=''> --- </option>
            {
              optionAllService ?.map((item, index) => {
                return (
                  <option value={item.serviceId} key={index}>{item.name}</option>
                )
              })
            }
          </select>
        </div>

        <div
          className="item-content right-margin"
          style={{ width: `27%` }}>
          <div className="item-update">
            <Select
              value={handleValue(item.uploadFromTo)}
              name="uploadFromTo"
              options={OPTION_DOCUMENT_SETTINGS}
              className="basic-multi-select select--w100"
              classNamePrefix="select"
              onChange={(e) => handleChangeDocumentFromTo({e, documentSettingId: item.documentSettingId, name: 'uploadFromTo'})}
            />
          </div>
        </div>

        <div className="item-content right-margin" style={{ width: `5%` }}>
          <select
            name="isRequired"
            className="item-update"
            value={item.isRequired}
            onChange={(e)=>handleChangeDocument({e, documentSettingId: item.documentSettingId})}
            id="isRequired">
            <option value=''></option>
            <option value={true}>制限</option>
          </select>
        </div>

          <div
          className="item-content right-margin"
          style={{ width: `20%` }}>
          <Select
            value={handleValueReference(item.reference)}
            isMulti
            name="reference"
            options={(['5', '6', '7', 5, 6, 7].includes(roleId) ? OPTION_FROM_TO_ANKEN_MASTER_MANAGEMENTGROUP : OPTION_FROM_TO_ANKEN_MASTER)}
            className="basic-multi-select select--w100"
            classNamePrefix="select"
            onChange={(e) => handleChangeDocumentSelection({e, documentSettingId: item.documentSettingId, name: 'reference'})}
          />
        </div>
        </div>
        {
          item?.uploadFromTo?.includes(1) && (
            <div className="form-upload">
              <label htmlFor={`upload-${item.documentSettingId}`} className="button-modal">
                ファイル選択
              </label>
              <input
                type="file"
                name="file"
                id={`upload-${item.documentSettingId}`}
                className="d-none input-contract"
                onChange={(e) => onChangeHandler({e, documentSettingId: item.documentSettingId})}
              />
              <input type="text" disabled value={item?.documentTitle?.name || ''}/>
              <button
                id={`upload-${item.documentSettingId}`}
                className="button-modal"
                disabled={!item?.documentTitle?.files}
                onClick={() => handleUpload(
                  {
                    documentSettingId: item.documentSettingId,
                    fileName: item.documentTitle.name,
                    files: item.documentTitle.files
                  })}
              >アップロード</button>
            </div>
          )
        }

      </Fragment>

    )
  };

  const handleUpload = async({documentSettingId, fileName, files}) => {
    const body = {
      fileName,
      content: await toBase64(files),
      screenName: SCREEN_NAME.CASE_MASTA
    };
    handleUploadDocument({body, documentSettingId})
  };

  const handleUploadDocument = async ({ body, documentSettingId }) => {
    try {
      setLoading(true);
      const data = await ANKEN_MASTER_NEW.UPLOAD_FILE_DOCUMENT_SETTING({ body, documentSettingId });
      if (data && data.code !== 0) {
        // getListDocumentSetting({ ...dataDocumentSetting });
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
        setLoading(false);
      } else {
        pushToast('success', 'Upload success');
        // getListDocumentSetting({ ...dataDocumentSetting });
        getData();
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

  const onChangeHandler = async ({e, documentSettingId}) => {
    const fileName = e ?.target ?.files[0] ?.name;
    const files = e ?.target ?.files[0];
    const newList = listDocumentSetting ?.map(item => {
      if (item.documentSettingId === documentSettingId) {
        return {
          ...item,
          documentTitle: {
            ...item.documentTitle,
            name: fileName,
            files
          }
        }
      } else {
        return item
      }
    });
    setListDocumentSetting(newList)
  };

  const handleUpdateDocumentSetting = async ({ body }) => {
    try {
      setLoading(true);
      const data = await ANKEN_MASTER_NEW.UPDATE_DOCUMENT_SETTING({ body });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
        setLoading(false);
      } else {
        pushToast('success', 'Update document success');
        // getListDocumentSetting({ ...dataDocumentSetting });
        getData();
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const handleUpdate = () => {
    const list = listDocumentSetting.map(item => {
      return {
        materialTypeId: item.materialTypeId,
        typeChildId: item.typeChildId,
        numberOfMaterials: item.numberOfMaterials,
        projectId: item.projectId,
        productId: item.productId,
        serviceId: serviceProduct[item.productId].includes(item.serviceId) ? item.serviceId : '',
        uploadFrom: item.uploadFrom || [],
        uploadFromTo: item.uploadFromTo || [],
        uploadTo: item.uploadTo || [],
        screen: item.screen,
        isRequired: item.isRequired,
        reference: typeof(item.reference) === 'string' ? (_.isEmpty(item.reference) ? [] : [item.reference])  : item.reference,
        documentSettingId: item.documentSettingId
      }
    });

    const body = {
      documentSettings: list,
      screenName: SCREEN_NAME.CASE_MASTA,
      updateTitle: `保存 ドキュメント設定 ${nameTitle ? nameTitle : ''}`
    };
    handleUpdateDocumentSetting({body})    ;
  };

  const colorBtn = (materialTypeName) => {
    // const item = listSelectMaterial ?.find((i) => i.name === materialTypeId);
    switch (materialTypeName) {
      case '共通資料':
        return '#fec001';
      case '展開資料':
        return '#002060';
      case '作成資料':
        return '#0eb0f0';
      case '回収資料':
        return '#0fb050';
      default:
        return '#387ca9';
    }
  };

  return (
    <div>
      <Loading loading={loading} />
      <Modal className="modal-dialog-custom modal-dialog-custom--max-width" isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>ドキュメント設定</ModalHeader>
        <ModalBody>
          <div className="form-document-settings">
            <div className="form-top">
              <CollapseTreeDocument
                dataDocumentSetting={dataDocumentSetting}
                listTree={listTree}
              />
                  <div className='form-btn-top'>
                    {
                      roleId === '1' && (
                        <button className='button-modal'
                          onClick={() => toggleOpenModalDocumentSettings()}
                        >
                          追加資料種別
                        </button>
                      )
                    }
                  </div>
              <div className='form-create'>
                <div className="row-create row-left">

                  <div className="form-item-create table--w15">
                    <div className="header-form-create">種別</div>
                    <div className="item-form-create">
                      <select
                        name="materialName"
                        id="materialTypeId"
                        onChange={handleChange}
                        className="select--h100 select--w100"
                        value={bodyCreate.materialName}
                        style={{
                          backgroundColor: colorBtn(bodyCreate.materialName),
                          color: bodyCreate.materialName === '展開資料' ? `#fff` : `#000`
                        }}
                      >
                        <option disabled value=''> --- </option>
                        {
                          listSelectMaterial ?.map((item, index) => {
                            return (
                              <option
                                key={index} value={item.materialName}
                                style={{
                                  backgroundColor: colorBtn(item.name),
                                  color: item.name === '展開資料' ? `#fff` : `#000`
                                }}
                              >{item.name}</option>
                            )
                          })
                        }
                      </select>
                    </div>
                  </div>

                  <div className="form-item-create table--w15">
                    <div className="header-form-create">資料種別</div>
                    <div className="item-form-create">
                      <select
                        name="typeChildId"
                        id="typeChildId"
                        onChange={handleChange}
                        className="select--h100 select--w100"
                        value={bodyCreate.typeChildId}
                      >
                        <option disabled value=''> --- </option>
                        {
                          listTypeChild ?.map((item, index) => {
                            return (
                              <option key={index} value={item.id}>{item.name}</option>
                            )
                          })
                        }
                      </select>
                    </div>
                  </div>

                  <div className="form-item-create table--w15">
                    <div className="header-form-create">資料名</div>
                    <div className="item-form-create">
                      <input
                        name="numberOfMaterials"
                        id="numberOfMaterials"
                        className="select--h100 select--w100"
                        onChange={handleChange}
                        value={bodyCreate.numberOfMaterials}
                      />
                    </div>
                  </div>

                  <div className="form-item-create table--w15">
                    <div className="header-form-create">プロジェクト名</div>
                    <div className="item-form-create">
                      <select
                        name="projectId"
                        id="projectId"
                        onChange={handleChange}
                        disabled={statusProject}
                        className="select--h100 select--w100"
                        value={bodyCreate.projectId}
                      >
                        <option disabled value=''> --- </option>
                        {
                          optionProject ?.map((item, index) => {
                            return (
                              <option value={item.projectCode} key={index}>{item.projectName}</option>
                            )
                          })
                        }
                      </select>
                    </div>
                  </div>

                  <div className="form-item-create table--w15">
                    <div className="header-form-create">工事名</div>
                    <div className="item-form-create">
                      <select
                        name="productId"
                        onChange={handleChange}
                        id="productId"
                        value={bodyCreate.productId}
                        className="select--h100 select--w100"
                        disabled={statusProduct}>
                        <option value=''> --- </option>
                        {
                          optionProduct ?.map((item, index) => {
                            return (
                              <option value={item.productId} key={index}>{item.name}</option>
                            )
                          })
                        }
                      </select>
                    </div>
                  </div>

                  <div className="form-item-create table--w15">
                    <div className="header-form-create">工程名</div>
                    <div className="item-form-create">
                      <select
                        name="serviceId"
                        onChange={handleChange}
                        value={bodyCreate.serviceId}
                        className="select--h100 select--w100"
                        id="serviceId">
                        <option value=''> --- </option>
                        {
                          optionService ?.map((item, index) => {
                            return (
                              <option value={item.serviceId} key={index}>{item.name}</option>
                            )
                          })
                        }
                      </select>
                    </div>
                  </div>
                </div>

                <div className="row-create row-right">
                  <div className="form-item-create table--w50">
                    <div className="header-form-create">アップロードルール（From/To）</div>
                    <div className="item-form-create select--hauto">
                      <Select
                        value={handleValue(bodyCreate.uploadFromTo)}
                        name="uploadFromTo"
                        options={OPTION_DOCUMENT_SETTINGS}
                        className="basic-multi-select select--w100"
                        classNamePrefix="select"
                        onChange={(e) => handleChangeSelectFromTo(e, 'uploadFromTo')}
                      />
                      {/* <Select
                        value={handleValue(bodyCreate.uploadTo)}
                        isMulti
                        name="uploadTo"
                        options={OPTION_DOCUMENT_SETTING}
                        className="basic-multi-select select--w50"
                        classNamePrefix="select"
                        onChange={(e) => handleChangeSelect(e, 'uploadTo')}
                      /> */}
                    </div>
                  </div>

                  {/* <div className="form-item-create table--w10">
                    <div className="header-form-create">処理画面</div>
                    <div className="item-form-create select--h60">
                      <select
                        name="screen"
                        onChange={handleChange}
                        value={bodyCreate.screen}
                        id="screen"
                        className="select--h100 select--w100"
                        title={bodyCreate.screen}
                      >
                        <option disabled value=''> --- </option>
                        {
                          OPTION_SCREEN_ANKEN_MASTER.filter(elm => { return (statusBeforeConfirm || elm !== '事前確認') }) ?.map((item, index) => {
                            return (
                              <option value={item} key={index}>{item}</option>
                            )
                          })
                        }
                      </select>
                    </div>
                  </div> */}

                  <div className="form-item-create table--w10">
                    <div className="header-form-create">制限</div>
                    <div className="item-form-create select--h60">
                      <select
                        name="isRequired"
                        onChange={handleChange}
                        value={bodyCreate.isRequired}
                        className="select--h100 select--w100"
                        title={bodyCreate.isRequired}
                        id="isRequired">
                        <option value=''></option>
                        <option value={true}>制限</option>

                      </select>
                    </div>
                  </div>

                  <div className="form-item-create table--w30">
                    <div className="header-form-create">参照範囲</div>
                    <div className="item-form-create select--h60">
                      <Select
                        value={handleValueReference(bodyCreate.reference)}
                        isMulti
                        name="reference"
                        options={(['5', '6', '7', 5, 6, 7].includes(roleId) ? OPTION_FROM_TO_ANKEN_MASTER_MANAGEMENTGROUP : OPTION_FROM_TO_ANKEN_MASTER)}
                        className="basic-multi-select select--w100"
                        classNamePrefix="select"
                        onChange={(e) => handleChangeSelect(e, 'reference')}
                      />
                      {/* <select
                        name="reference"
                        onChange={handleChange}
                        value={bodyCreate.reference}
                        className="select--h100 select--w100"
                        title={bodyCreate.reference}
                        id="reference">
                        <option disabled value=''> --- </option>
                        {
                          (['5', '6', '7', 5, 6, 7].includes(roleId) ? OPTION_FROM_TO_ANKEN_MASTER_MANAGEMENTGROUP : OPTION_FROM_TO_ANKEN_MASTER) ?.map((item, index) => {
                            return (
                              <option value={item} key={index}>{item}</option>
                            )
                          })
                        }
                      </select> */}
                    </div>
                  </div>

                  <div className="form-item-create d-flex align-self-end table--w10">
                    <div className="item-form-create">
                      <button
                        className='button-modal'
                        onClick={()=> handleAddDocument()}
                      >
                        追加
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="form-update-document">
              <div className="form-table">
                <div className="row-table">
                {
                  listColumn.map((item, index) => {
                    return (
                      <div style={{ width: item.width }} className={index !== 0 ? 'header-form-create right-margin' : ''} key={index}>
                        <p>{item.name}</p>
                      </div>
                    )
                  })
                }
                </div>
                {
                  listDocumentSetting ?.map((item, index) => {
                    return (
                      renderBodyDocument(item, index)
                    )
                  })
                }
                <div className="form-btn-update">
                  <button className="button-modal"
                    onClick={()=> handleUpdate()}
                  >
                    保存
                  </button>
                </div>
              </div>
            </div>
          </div>

        </ModalBody>
      </Modal>
      {modalAddOptionDocument ?
        <ModalAddOptionDocument
          modal={modalAddOptionDocument}
          toggle={toggleCloseModal}
          dataDocumentSetting={dataDocumentSetting}
        /> : ''}

    </div>
  );
};

export default ModalDocumentSettings;
