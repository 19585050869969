import React from 'react';
import './style.scss';
import TablePartnerStaffFiledMaster from './TablePartnerStaffFiledMaster';

const PartnerStaffFiledMaster = () => {
  return (
    <div className="body-partner-staff-filed-master">
      <h1 style={{ backgroundColor: '#ce8500', width: '100%', padding: 10, fontSize: 16 }}>
        <a href="#" style={{ borderRadius: 5, backgroundColor: 'orange', color: '#FFF', padding: 5 }}>
          <i className="fa fa-reply" aria-hidden="true"></i>更新中案件に戻る
        </a>
      </h1>
      <h3 className="font-weight-bolder mx-1" style={{ fontSize: 32 }}>workerメニュー設定 {'>'} 当日作業</h3>
      <TablePartnerStaffFiledMaster />
    </div>
  );
};

export default PartnerStaffFiledMaster;
