import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { ROLE } from "helpers/constants";
import { STAFF_ENDPOINT } from "constants/endpoints";
import PaginationCustom from "components/PaginationCustom";
import StaffSkill from "../StaffSkill";
import Loading from "components/Loading/Loading";
import _ from 'lodash';

const INIT_AFFILIATE_PAGINATION = {
  page: 1,
  limit: 10,
};
const TableStaffSkill = ({ role = ROLE.PARTNER, color, roleDetail, t, p, idRole, active, colorText }) => {
  const [activePagess, setActivePagess] = useState(1);
  const [listStaff, setListStaff] = useState("");
  const [product, setProduct] = useState("");
  const [loading, setLoading] = useState(false);
  const [hideshow, setHideshow] = useState("");

  const getAllStaff = async ({
    page = INIT_AFFILIATE_PAGINATION.page,
    limit = INIT_AFFILIATE_PAGINATION.limit,
  }) => {
    setLoading(true);
    try {
      const data = await STAFF_ENDPOINT.GET_ALL_STAFF({ page: page, limit: limit });
      if (data) {
        setListStaff(data?.data?.result);
        setProduct(data?.data?.count);
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllStaff({ page: activePagess, limit: 10 });
  }, [activePagess]);

  useEffect(() => {
    setActivePagess(1);
  }, [p]);

  const handlePageChangess = (pageNumber) => {
    setActivePagess(pageNumber);
  };

  const handleHide = (id) => {
    if (hideshow === id) {
      setHideshow("");
    } else {
      setHideshow(id);
    }
  };

  const reload = () => {
    getAllStaff({ page: activePagess, limit: 10 });
  }
  return (
    <div className="table-menu-wrapper">
      <Loading loading={loading} />
      {listStaff &&
        listStaff.map((item, index) => {
          return (
            <StaffSkill
              active={active}
              role={role}
              key={index}
              item={item}
              color={color}
              handleHide={handleHide}
              hideshow={hideshow}
              colorText={colorText}
              roleDetail={roleDetail}
              idRole={idRole}
              t={t}
              reload={reload}
            />
          );
        })}
        {!_.isEmpty(listStaff) && product > 10 && (
          <PaginationCustom
            activePage={activePagess}
            itemsCountPerPage={10}
            totalItemsCount={product}
            pageRangeDisplayed={5}
            handlePageChange={handlePageChangess}
          />
        )}

    </div>
  );
};

export default TableStaffSkill;
