import React, { useState } from 'react';
import { Collapse } from 'reactstrap';
import 'react-datepicker/dist/react-datepicker.css';
import 'assets/scss/page/center/Users/style.scss';
import { DISPATCH } from 'constants/endpoints';
import TableDispatch from './TableDispatch';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import Loading from 'components/Loading/Loading';
import { SCREEN_NAME } from '../../../helpers/constants';
import { useDispatch } from 'react-redux';
import { getDataHistoryMasterSetting } from 'actions/authentication';
import { pushToast } from 'components/Toast';

import { DATETIME_EXPORT_JP_FORMAT, NAME_FILE_EXPORT_DISPATCH } from 'helpers/constants';

const CollapseDispatch = ({
  dataWorkingSchedule,
  organizationId,
  visitDate,
  onReload,
  pushDescription,
  taskActive,
  colors,
  colorText,
  isShow,
  handleCollap,
  serviceDrag,
  k }) => {
  const toggle = () => handleCollap(k);
  const dispatch  = useDispatch();
  const [defaultCsv, setDefaultCsv] = useState([]);
  const [loading, setLoading] = useState(false);

  const onDownload = (e) => {
    e.stopPropagation();
  };

  const handleExportCSV = async ({ type, visitDate, productId, screenName, updateTitle }) => {
    setLoading(true)
    try {
      const data = await DISPATCH.EXPORT_CSV_DISPATCH({ type, visitDate, productId, screenName, updateTitle });
      if (data ?.data) {
        setLoading(false)
        setDefaultCsv(data?.data);
        document.getElementById(`download-csv-dispatchResult-${productId}`).click();
        pushToast('success', 'Download CSV Product success');
        dispatch(getDataHistoryMasterSetting({ logId: '', screenName: SCREEN_NAME.DISPATCH_SETTINGS_ORDER, idProvide: '' }));
      }
    } catch (errorMessage) {
      setLoading(false)
      console.log('errorMessage: ', errorMessage);
    }
  };

  const onDownLoadCSV = ({ e, id }) => {
    handleExportCSV({ type: 'dispatchResult', visitDate, productId: id, updateTitle: 'ディスパッチ結果CSV', screenName: SCREEN_NAME.DISPATCH_SETTINGS_ORDER })
    e.stopPropagation();
  };

  return (
    <div className="">
      <Loading loading={loading} />

      <div className="wrapper-btn-colappse">
        <div onClick={toggle} className="mb-1 bar collap-item d-flex justify-content-between align-items-center" style = {{background :`${colors}`}}>
          <div className="ml-2 d-flex" style={{ color: `${colorText}` }}>
            {dataWorkingSchedule?.caseName}

            <div>
              <button
                onClick={(e) => onDownLoadCSV({e, id: dataWorkingSchedule?.productId})}
                name={`dispatchResult`}
                className="btn-export-csv">
                ディスパッチ結果CSV{' '}
              </button>

              <CSVLink
                className="text-decoration-none"
                data={defaultCsv}
                filename={`${NAME_FILE_EXPORT_DISPATCH.dispatchResult}${moment().format(DATETIME_EXPORT_JP_FORMAT)}.csv`}
              >
                <span onClick={onDownload} id={`download-csv-dispatchResult-${dataWorkingSchedule?.productId}`}></span>
              </CSVLink>
            </div>
          </div>
          <div className="mr-2" style={{ color: `${colorText}` }}>
            {isShow === k ? <i className="icon-up-open icon-searchbar" /> : <i className="icon-down-open icon-searchbar" />}
          </div>
        </div>
      </div>
      <Collapse isOpen={isShow === k}>
        <TableDispatch
          dataWorkingSchedule={dataWorkingSchedule}
          organizationId={organizationId}
          visitDate={visitDate}
          onReload={onReload}
          colors={colors}
          pushDescription={pushDescription}
          taskActive={taskActive}
          serviceDrag={serviceDrag}
        />
      </Collapse>
    </div>
  );
};

export default CollapseDispatch;
