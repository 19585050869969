import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import TableAddRole from './TableAddRole';

const ModalAddRole = (props) => {
  const {
    toggle = () => { }, modal = false, ModalAddRoleNext = () => { }, statusBeforeConfirm,
    routeList, namePermission, ModalDetailSetting = () => { }, idProvide, idProduct } = props;
  return (
    <div>
      <Modal className="modal-dialog-custom" isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}></ModalHeader>
        <ModalBody>
          <TableAddRole
            routeList={routeList} ModalAddRoleNextChild={ModalAddRoleNext}
            namePermission={namePermission} ModalDetailSetting={ModalDetailSetting}
            idProvide={idProvide} idProduct={idProduct} statusBeforeConfirm={statusBeforeConfirm}/>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ModalAddRole;
