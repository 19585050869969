import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import TableDetailSetting from './TableDetailSetting';

const ModalAddRoleNext = ({ modalNext = false, toggle, routeList, nameScreen, arrRole, idProvide, onBack = () => {} }) => {
  const Back = () => {
    toggle();
    onBack(routeList);
  }
  return (
    <div>
      <Modal className="modal-dialog-custom" isOpen={modalNext} toggle={toggle}>
        <ModalHeader toggle={toggle}>
        <button onClick={Back} className="button__back">
        戻る
        </button>
        </ModalHeader>
        <ModalBody>
          <TableDetailSetting routeList={routeList} nameScreen={nameScreen} arrRole={arrRole} idProvide={idProvide} />
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ModalAddRoleNext;
