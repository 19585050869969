import React, { useEffect, useState } from 'react';
import Button from 'reactstrap/es/Button';
import TextField from '@material-ui/core/TextField';
import { login } from '../../../actions/authentication';
import { compose } from 'redux';
import { translate } from 'react-i18next';
import { Redirect, withRouter } from 'react-router-dom';
import { connect, shallowEqual, useSelector } from 'react-redux';
import Loading from '../../../components/Loading/Loading';
import Toast from 'components/Toast';
import { MASTER_SETTING } from '../../../constants/endpoints';
import _ from 'lodash';

const LoginCross = (props) => {
  const { login, history } = props;
  const loading = useSelector((store) => store.utils.loading);
  const userMeData = useSelector((store) => store.authentication.userMe, shallowEqual);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [error, setError] = useState(false);
  const [isLogged, setIsLogged] = useState(false);
  const [htmlString, setHtmlString] = useState('');
  const [loadings, setLoadings] = useState(false);

  useEffect(() => {
    window.myfunc = handleSubmit;
    return () => (window.myfunc = null);
  }, []);

  const handleSubmit = () => {
    const userLogin = document.getElementById('username')?.value;
    const userPassWord = document.getElementById('password')?.value;
    handleLogin(userLogin, userPassWord);
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress, false);
    return () => {
      document.removeEventListener('keydown', handleKeyPress, false);
    }
  }, [htmlString]);

  const handleLogin = (userLogin, userPassWord) => {
    if (userLogin && userPassWord) {
      login({ username: userLogin, password: userPassWord, history });
    } else {
      setIsLogged(true);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      const userLogin = document.getElementById('username')?.value;
      const userPassWord = document.getElementById('password')?.value;
      handleLogin(userLogin, userPassWord);
    }
  };

  const getUiLogin = async () => {
    setLoadings(true)
    try {
      const data = await MASTER_SETTING.GET_UI_LOGIN();
      if (data) {
        setHtmlString(data?.data?.loginHtml);
      }
      setLoadings(false)
    } catch (e) {
      console.log(e);
      setLoadings(false)
    }
  };

  useEffect(() => {
    getUiLogin();
  }, []);


  useEffect(() => {
    if (username.trim() && password.trim()) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [username, password]);

  const handleKeyPressLogin = (e) => {
    if (e.keyCode === 13 || e.which === 13) {
      isButtonDisabled || handleLoginCross();
    }
  };

  const handleLoginCross = () => {
    if (username && password) {
      login({ username, password, history });
    } else {
      setIsLogged(true);
    }
  }

  useEffect(() => {
    if (localStorage?.getItem('IS_LOGIN')) {
      history.push('/select-role');
    }
  }, []);

  return (
    <div>
      {!userMeData && <Redirect to="/login" />}
      <Loading loading={loading || loadings} />
      <Toast />
      {!_.isEmpty(htmlString) ? (
        <div dangerouslySetInnerHTML={{ __html: htmlString }} />
      ) : (
        <div id="logincross_body">
          <div id="logincross_background_img">
            <div className="cross-inputarea">
              <div className="pcmenu">
                <table className="logincross_table">
                  <tbody>
                    <tr>
                      <td className="logincross_td_2">
                        <TextField
                          error={error}
                          id="username"
                          type="username"
                          placeholder="ユーザーID"
                          className="logincross_text mb-2"
                          onChange={(e) => setUsername(e.target.value)}
                          onKeyPress={(e) => handleKeyPressLogin(e)}
                        />
                        <input type="hidden" name="stella_serial" value="salesdemo" />
                      </td>
                    </tr>

                    <tr>
                      <td className="logincross_td_2">
                        <TextField
                          error={error}
                          id="password"
                          type="password"
                          placeholder="パスワード"
                          className="logincross_text"
                          onChange={(e) => setPassword(e.target.value)}
                          onKeyPress={(e) => handleKeyPressLogin(e)}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="logincross_td_3">
                        <Button
                          className="logincrossBtn_btn"
                          variant="contained"
                          size="large"
                          color="secondary"
                          onClick={() => handleLoginCross()}
                        // disabled={isButtonDisabled}
                        />
                      </td>
                    </tr>
                    <tr>
                      {isLogged && <p className="notify-login">ユーザIDとパスワードの組み合わせが間違っています。</p>}
                    </tr>
                    <tr>
                      <td className="logincross_td_4"></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="spmenu">
                <table className="logincross_table">
                  <tbody>
                    <tr>
                      <td className="logincross_td_2">
                        <TextField
                          error={error}
                          id="username"
                          type="username"
                          placeholder="ユーザID"
                          className="logincross_text "
                          onChange={(e) => setUsername(e.target.value)}
                          onKeyPress={(e) => handleKeyPressLogin(e)}
                        />
                        <input type="hidden" name="stella_serial" value="salesdemo" />
                      </td>
                    </tr>
                    <tr>
                      <td className="logincross_td_2">
                        <TextField
                          error={error}
                          type="password"
                          className="logincross_text"
                          placeholder="パスワード"
                          onChange={(e) => setPassword(e.target.value)}
                          onKeyPress={(e) => handleKeyPressLogin(e)}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td rowSpan="2">
                        <Button
                          type="submit"
                          value=""
                          variant="contained"
                          size="large"
                          color="secondary"
                          onClick={() => handleLoginCross()}
                          disabled={isButtonDisabled}
                          className="logincrossBtn_btn"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="logincross_td_4" colSpan="2"></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const mapActionToProps = {
  login,
};

export default compose(translate('translations'), withRouter, connect(null, mapActionToProps))(LoginCross);
