import React, { useState, useEffect, useRef } from 'react';
import 'assets/scss/page/ChatPage/style.scss';
import { Tooltip } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { DATETIME_JP_FORMAT } from 'helpers/constants';
import moment from 'moment';
import { getListMessageInRoom, updateListMessageInRoom, getListUserInRoom, updateListUserInRoom, removeUserInRoom } from 'actions/chat';
import { socket } from '../../../helpers/callApi';
import { CHAT } from 'constants/endpoints';
import ModalAddUserInRoom from '../../../components/ModalChatRoom/ModalAddUserInRoom';
import { pushToast } from 'components/Toast';
import Loading from 'components/Loading/Loading';
import _ from 'lodash';
import UserDefault from '../../../assets/img/user-default.png';

const FormChatRoom = ({
  roomActive,
  reloadListRoom = () => {},
  messagesEndRef,
  scrollToBottom}) => {
  const dispatch = useDispatch();
  const [textMessage, setTextMessage] = useState('');
  const [openModalAddUser, setOpenModalAddUser] = useState(false);
  const { listMessage, listUser } = useSelector(state => state.chat);
  const userId = useSelector(state => state.authentication?.userMe?.data?.userId);
  const { loading } = useSelector(store => store.utils);

  useEffect(() => {
    scrollToBottom();
    if (_.filter(listMessage, _.size)?.length) {
      const payload = {
        userId,
        roomId: roomActive.roomId
      }
      socket.emit('last-read', payload);
    }

  }, [listMessage]);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown, false);

    return () => {
      document.removeEventListener('keydown', handleKeyDown, false);
    };
  }, [textMessage]);

  useEffect(() => {
    if (!_.isEmpty(roomActive)) {
      dispatch(getListMessageInRoom({ roomId: roomActive.roomId }));
      dispatch(getListUserInRoom({ roomId: roomActive.roomId }));
      listenListMessage();
      scrollToBottom();
    }
  }, [roomActive]);

  const listenListMessage = () => {
    socket.on('send-message-room-success', data => {
      setTextMessage('');
      dispatch(updateListMessageInRoom(data));
      scrollToBottom();
    });

    socket.on('leave-room-success', data => {
      dispatch(getListUserInRoom({ roomId: roomActive.roomId }));
    });

    socket.on('leave-user-room', data => {
      reloadListRoom();
    });
    // cho nguoi add
    socket.on('add-user-room', data => {
      dispatch(getListUserInRoom({ roomId: roomActive.roomId }));
    });

    // for diff user
    socket.on('join-room-success', data => {
      dispatch(getListUserInRoom({ roomId: roomActive.roomId }));
    });

    // cho nguoi dc add
    socket.on(`room${userId}`, data => {
      reloadListRoom();
    });

    // người kick
    // socket.on(`join-or-leave`, data => {
    //   if(data?.statusRoom === 2) {
    //     dispatch(updateListUserInRoom(data));
    //   } else {
    //     dispatch(removeUserInRoom(data));
    //   }
    // });

    // trừ kick & bị kick
    socket.on(`join-or-leave-room-success`, data => {
      if(data?.statusRoom === 2) {
        dispatch(updateListUserInRoom(data));
      } else {
        dispatch(removeUserInRoom(data));
      }
    });
  };

  const handleSendMessage = () => {
    if (textMessage) {
      const payload = {
        message: textMessage,
        roomId: roomActive.roomId,
        userId
      };
      socket.emit('chat-room', payload, err => {
        pushToast('error', err?.errorMessage);
      });
    }
  };

  const handleKeyDown = e => {
    const myElement = document.getElementById('send-message');
    if(myElement === document.activeElement){
      if (!!textMessage.trim() && e.key === 'Enter') {
        e.preventDefault();
        handleSendMessage();
      }
    }
  };

  const handleChangeTextMessage = e => {
    setTextMessage(e.target.value);
  };

  const toggleModalAddUser = () => {
    setOpenModalAddUser(!openModalAddUser);
  };

  const handleOpenModalAddUser = () => {
    setOpenModalAddUser(true);
  };

  const handleLeaveRoom = () => {
    const payload = {
      userId,
      roomId: roomActive.roomId
    };
    socket.emit('leave-room', payload, err => {
      pushToast('error', err?.errorMessage);
    });
  };

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  const totalUserActive = listUser?.filter(elm => +elm.statusRoom === 2)?.length;
  return !_.isEmpty(roomActive) ? (
    <div className="col-8 chat__form--pdRow">
      <Loading loading={loading} />
      <div className="chat__form--groundb p-2">
        <div className="d-flex justify-content-between align-item-center mx-3 p-1">
          <p className="chat__form--fz20" title={roomActive.name}>{roomActive.name}</p>
          <div>
            <button className="btn btn-style" onClick={handleLeaveRoom}>
              退会
            </button>
            <i className="fa fa-user chat__form--icon  chat__form--mgTop">{totalUserActive || 0}</i>
            <i
              className="ml-2 fa fa-user-plus chat__form--icon  chat__form--mgTop"
              onClick={handleOpenModalAddUser}
            ></i>
          </div>
        </div>
      </div>

      <div className="wrapper-chat">
        {/* <div className="chat-date">
          <div className="start line"></div>
          <span>20:25 03/05/2021</span>
          <div className="flx">
            <div className="end line"></div>
          </div>
        </div> */}

        <div className="message" id="message">
          {listMessage?.map((item, index) => {
            const infoUser = listUser.find(elm => elm.userId === item.creatorId);
            const nameUser = !!infoUser?.firstName ? `${infoUser?.companyName} ${infoUser?.firstName} ${infoUser?.lastName}` : infoUser?.userName;

            return item.creatorId === userId ? (
              <div key={index} className="form-message right-message">
                <div className="message-body-wrapper">
                  <div className="d-flex right-message">
                    <span className="text-date">{moment(item?.createdAt).format(DATETIME_JP_FORMAT)}</span>
                  </div>
                  <div className="content-right">
                    {item.message}
                  </div>
                </div>
              </div>
            ) : (
              <div key={index} className="form-message">
                <div className="thumb">
                  <div className="avatar">
                    <img src={UserDefault} alt="tuyentc" className="avatar-image" />
                  </div>
                </div>

                <div className="message-body-wrapper">
                  <div className="d-flex">
                    <span className="user">{nameUser}</span>{' '}
                    <span className="text-date">{moment(item?.createdAt).format(DATETIME_JP_FORMAT)}</span>
                  </div>
                  <div className="">
                    {item.message}
                  </div>
                </div>
              </div>
            );
          })}
          <div style={{ float: 'left', clear: 'both' }} ref={messagesEndRef}></div>

          {/* <div className="attachment">
            <div className="color-primary-font-color attachment-block">
              <div className="attachment-block-border background-info-font-color"></div>
              <div className="attachment-author">
                <img src="https://chatwork.bap.jp/avatar/tuyentc" />
                tuyentc
                <span className="time-link">
                  <a href="" rel="noopener noreferrer">
                    25 tháng 9 năm 2020 11:54
                  </a>
                </span>
              </div>
              <div className="attachment-flex">
                <div className="attachment-flex-column-grow attachment-text">di2Bw^H0</div>
              </div>
            </div>
          </div> */}
        </div>
      </div>

      <div className="chat__form--bdR">
        <div className="input-chat">
          <textarea
            onChange={handleChangeTextMessage}
            value={textMessage}
            type="text"
            id="send-message"
            placeholder="Type a message"
            className="chat__form--text"
          />
        </div>

        <div className="form-send">
          {
            !!textMessage.trim() && <>
              <i className="fa fa-paper-plane-o chat__form--icon" onClick={handleSendMessage} id="send" title="send"></i>
            </>
          }
        </div>
      </div>

      {openModalAddUser && (
        <ModalAddUserInRoom modal={openModalAddUser} toggle={toggleModalAddUser} roomActive={roomActive} userId={userId} />
      )}
    </div>
  ) : (
    ''
  );
};

export default FormChatRoom;
