import React from 'react';

const data = {
  header: ['更新者', '更新日', '更新区分', '備考'],
  body: [
    {
      更新者: '管理01',
      更新日: '2020/04/15 19:47:13',
      更新区分: '更新',
      備考: '【partner_name】株式会社ネオマルス→Array【partner_name_kana】カブシキガイシャネオマルス→Array'
    }
  ]
};

const renderItem = (item, index) => {
  return (
    <tr key={index}>
      {data.header.map((element, key) => {
        return (
          <>
            <td style={{ fontSize: 12 }} key={key}>
              {item[element]}
            </td>
          </>
        );
      })}
    </tr>
  );
};

const TableUpdate = () => {
  return (
    <div id="Table_Update" className="mt-2">
      <table className={`listTbl `}>
        <thead>
          <tr>
            <th colSpan="4" style={{ fontSize: 16, textAlign: 'left', paddingLeft: 25 }}>
              更新履歴
            </th>
          </tr>
          <tr>
            {data.header.map((item, index) => (
              <th scope="col" key={index} style={{ fontSize: 16 }}>
                {item}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>{data.body.map((listData, index) => renderItem(listData, index))}</tbody>
      </table>
    </div>
  );
};

export default TableUpdate;
