import React, { useEffect } from 'react';
import { useState } from 'react';
import { DATE_JP_FORMAT, time } from '../../../helpers/constants';
import DateTimePicker from '../../DateTimePicker';
import moment from 'moment';
import { Collapse } from 'reactstrap';
import './style.scss';
import { useHistory } from 'react-router';
import _ from 'lodash';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ja from 'date-fns/locale/ja';
import { formatInputSelectDate } from 'helpers/helpers';
registerLocale('ja', ja);

const DetailVisitDate = ({ disabled, dataVisitDate, onChangeVisitDate }) => {
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(true);
  const [checkedButton, setCheckedButton] = useState('');
  const [dateTo, setDateTo] = useState()

  useEffect(() => {
    setDateTo(moment(`${formatInputSelectDate(dataVisitDate?.visitDateTo)}`, `${DATE_JP_FORMAT}`).isValid() ? dataVisitDate?.visitDateTo?.slice(-16 , -6) || '' : '')
  },[dataVisitDate])

  const handleVisitDateTo = (date) => {
    setDateTo(date ? moment(new Date(date)).format(DATE_JP_FORMAT) : '');
    onChangeVisitDate({
      visitDateTo: date
        ? `${moment(new Date(date)).format(DATE_JP_FORMAT)} ${formatInputSelectTime(dataVisitDate?.visitDateTo) || '00:00'}`
        : ''
    });
  };

  const handleVisitPreferredDateTo = (date, item) => {
    const newDate = date ? moment(new Date(date)).format(DATE_JP_FORMAT) : '';
    const { preferredDate } = dataVisitDate;
    onChangeVisitDate({
      preferredDate: {
        ...preferredDate,
        [item]: {
          ...preferredDate[item],
          visitDateTo: newDate ? `${newDate} ${preferredDate[item]?.visitDateTo.slice(11, 16) || '00:00'}` : ''
        }
      }
    });
  };

  const onClickShow = () => {
    setIsOpen(!isOpen);
  };

  const onChangeDate = (date, item) => {
    const visitDate = date !== null ? moment(date).format(DATE_JP_FORMAT) : '';
    const { preferredDate, visitDateTo, visitDateFrom } = dataVisitDate;

    if (item === 'visitDate') {
      onChangeVisitDate({
        visitDate,
        visitDateTo: visitDate ? `${visitDate} ${formatInputSelectTime(dataVisitDate?.visitDateTo) || '00:00'}` : '',
        visitDateFrom: visitDate ? visitDateFrom || '00:00' : '',
      });
    } else {
      onChangeVisitDate({
        preferredDate: {
          ...preferredDate,
          [item]: {
            ...preferredDate[item],
            visitDate,
            visitDateFrom: visitDate ? preferredDate[item]?.visitDateFrom || '00:00' : '',
            visitDateTo: visitDate && preferredDate[item]?.visitDateTo ? `${visitDate} ${formatInputSelectTime(preferredDate[item]?.visitDateTo) || '00:00'}` : visitDate ? `${visitDate} ${'00:00'}` : ''
          }
        }
      })
    }
  };

  const handleSelectTime = (e, item) => {
    if(item === 'visitDate') {
      onChangeVisitDate({ [e.target.name]: `${dateTo} ${e.target.value }` })
    } else {
      const { preferredDate } = dataVisitDate
      onChangeVisitDate({
        preferredDate: {
          ...preferredDate,
          [item]: {
            ...preferredDate[item],
            [e.target.name]: e.target.name === 'visitDateTo' && e.target.value ? `${moment(new Date(preferredDate[item]?.visitDateTo)).format(DATE_JP_FORMAT)} ${e.target.value}` : e.target.value
          }
        }
      })
    }
  };

  const handleSelectTimeFrom = (e, item) => {
    if(item === 'visitDate') {
      onChangeVisitDate({ [e.target.name]: e.target.value })
    } else {
      const { preferredDate } = dataVisitDate
      onChangeVisitDate({
        preferredDate: {
          ...preferredDate,
          [item]: {
            ...preferredDate[item],
            [e.target.name]: e.target.value
          }
        }
      })
    }
  };

  const formatInputSelectTime = (dateTime) => {
    if (!dateTime) return '';
    return dateTime.slice(-5);
  };

  const handleRadio = (e) => {
    let value = e.target.value;
    setCheckedButton(value);
    let preferredDate = dataVisitDate?.preferredDate?.[value];
    onChangeVisitDate({
      visitDate: preferredDate?.visitDate,
      visitDateFrom: preferredDate?.visitDateFrom || '00:00',
      visitDateTo: !_.isEmpty(preferredDate?.visitDateTo) ? preferredDate?.visitDateTo : `${preferredDate?.visitDate} 00:00`,
    });
  };

  return (
    <div className="detail-erp-table-wrapper mt-3 visit-date-component">
      <div className="title-absolute" onClick={onClickShow}>
        訪問日時
        {isOpen ? <i className="fas fa-arrow-circle-up ml-2"></i> : <i className="fas fa-arrow-circle-down ml-2"></i>}
      </div>
      <Collapse isOpen={isOpen}>
        <div className="detail__condition">
          <table className="detail__form">
            <thead>
              <tr>
                <th>訪問日時</th>
                <th>訪問日</th>
                <th>希望訪問日時①</th>
                <th>希望訪問日時②</th>
                <th>希望訪問日時③</th>
                <th>希望訪問日時④</th>
                <th>希望訪問日時⑤</th>
              </tr>
            </thead>
            <tbody>
              {history.location.pathname === '/detail-receive-confirm' && (
                <tr>
                  <td className="detail__form--input text-center" style={{ background: '#0e5a8d', color: 'white' }}>希望日選択</td>
                  <td className='detail__form--input'></td>
                  {['visitDate1', 'visitDate2', 'visitDate3', 'visitDate4', 'visitDate5'].map((item, index) => {
                    return (
                      <td className="text-center detail__form--input " key={index} >
                        <input
                          type="radio"
                          className='cursor-pointer'
                          value={item}
                          disabled={_.isEmpty(dataVisitDate?.preferredDate?.[item]?.visitDate)}
                          checked={checkedButton === `${item}`}
                          onChange={handleRadio}
                        />
                      </td>
                    );
                  })}
                </tr>
              )}
              <tr>
                <td className="detail__form--input text-center" style={{ background: '#0e5a8d', color: 'white' }}>
                  指定訪問日時
                </td>
                {['visitDate', 'visitDate1', 'visitDate2', 'visitDate3', 'visitDate4', 'visitDate5'].map(
                  (item, index) => {
                    return (
                      <td key={index} className="detail__form--input">
                        {item === 'visitDate' ? (
                          <DateTimePicker
                            date={dataVisitDate?.visitDate ? new Date(dataVisitDate?.visitDate) : null}
                            onChangeDate={(e) => onChangeDate(e, item)}
                            disabled={disabled}
                            id={index}
                          />
                        ) : (
                          <DateTimePicker
                            date={
                              dataVisitDate?.preferredDate?.[item]?.visitDate
                                ? new Date(dataVisitDate?.preferredDate?.[item]?.visitDate)
                                : null
                            }
                            disabled={disabled}
                            onChangeDate={(e) => onChangeDate(e, item)}
                            id={index}
                          />
                        )}
                      </td>
                    );
                  }
                )}
              </tr>
              <tr>
                <td className="detail__form--input text-center" style={{ background: '#0e5a8d', color: 'white' }}>
                  開始予定
                </td>
                {['visitDate', 'visitDate1', 'visitDate2', 'visitDate3', 'visitDate4', 'visitDate5'].map(
                  (item, index) => {
                    return (
                      <td key={index} className="detail__form--input">
                        {item === 'visitDate' ? (
                          <select
                            name="visitDateFrom"
                            className="w-100"
                            disabled={disabled || !dataVisitDate?.visitDate}
                            value={dataVisitDate?.visitDateFrom}
                            onChange={(e) => handleSelectTimeFrom(e, item)}
                          >
                            {time.map((item, index) => (
                              <option value={item} key={index}>
                                {item}
                              </option>
                            ))}
                          </select>
                        ) : (
                          <select
                            name="visitDateFrom"
                            disabled={disabled || !dataVisitDate?.preferredDate?.[item]?.visitDate}
                            className="w-100"
                            value={dataVisitDate?.preferredDate?.[item]?.visitDateFrom}
                            onChange={(e) => handleSelectTimeFrom(e, item)}
                          >
                            {time.map((item, index) => (
                              <option value={item} key={index}>
                                {item}
                              </option>
                            ))}
                          </select>
                        )}
                      </td>
                    );
                  }
                )}
              </tr>
              <tr>
                <td
                  className="detail__form--input text-center"
                  style={{ width: '10%', background: '#0e5a8d', color: 'white' }}
                >
                  終了予定
                </td>
                {['visitDate', 'visitDate1', 'visitDate2', 'visitDate3', 'visitDate4', 'visitDate5'].map(
                  (item, index) => {
                    return (
                      <td key={index} className="detail__form--input">
                        {item === 'visitDate' ? (
                          <div className='d-flex'>
                            <DatePicker
                            disabled={disabled}
                            selected={dateTo ? new Date(dateTo) : null}
                            onChange={(date) => handleVisitDateTo(date)}
                            locale="ja"
                            dateFormat="yyyy/MM/dd"
                            dateFormatCalendar="yyyy年 MM月"
                            className='w-100'
                            />
                            <select
                              className="w-100"
                              name="visitDateTo"
                              onChange={(e) => handleSelectTime(e, item)}
                              value={formatInputSelectTime(dataVisitDate?.visitDateTo)}
                              disabled={disabled || !dataVisitDate?.visitDate || _.isEmpty(dateTo)}
                              style={{ borderLeft : "2px solid rgb(14, 90, 141)"}}
                            >
                              {time.map((item, index) => (
                                <option value={item} key={index}>
                                  {item}
                                </option>
                              ))}
                            </select>
                          </div>
                        ) : (
                          <div className='d-flex'>
                            <DatePicker
                              disabled={disabled || !dataVisitDate?.preferredDate?.[item]?.visitDate}
                              selected={
                                dataVisitDate?.preferredDate?.[item]?.visitDateTo ? (moment(`${formatInputSelectDate(dataVisitDate?.preferredDate?.[item]?.visitDateTo)}`, `${DATE_JP_FORMAT}`).isValid()
                                  ? new Date(dataVisitDate?.preferredDate?.[item]?.visitDateTo)
                                  : `${dataVisitDate?.preferredDate?.[item]?.visitDate ? new Date(`${dataVisitDate?.preferredDate?.[item]?.visitDate} ${dataVisitDate?.preferredDate?.[item]?.visitDateTo}`) : ''}`) : ''}
                              onChange={(date) => handleVisitPreferredDateTo(date, item)}
                              locale="ja"
                              dateFormat="yyyy/MM/dd"
                              dateFormatCalendar="yyyy年 MM月"
                              className='w-100'
                            />
                            <select
                              className="w-100"
                              name="visitDateTo"
                              disabled={disabled || !dataVisitDate?.preferredDate?.[item]?.visitDate || !dataVisitDate?.preferredDate?.[item]?.visitDateTo}
                              onChange={(e) => handleSelectTime(e, item)}
                              value={formatInputSelectTime(dataVisitDate?.preferredDate?.[item]?.visitDateTo)}
                              style={{ borderLeft : "2px solid rgb(14, 90, 141)"}}
                            >
                              {time.map((item, index) => (
                                <option value={item} key={index}>
                                  {item}
                                </option>
                              ))}
                            </select>
                          </div>
                        )}
                      </td>
                    );
                  }
                )}
              </tr>

            </tbody>
          </table>
        </div>
      </Collapse>
    </div>
  );
};

export default DetailVisitDate;
