import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import '../../../../assets/scss/page/CsvMaster/index.scss';
import { CONTRACT_ENDPOINTS } from '../../../../constants/endpoints';
import Loading from 'components/Loading/Loading';
import _ from 'lodash';
import TableModalServiceContract from './TableServiceContract';

const mockData = {
  providerId: '',
  provider: 'service',
  monitorId: '',
  projectId: '',
  productId: '',
};

const ModalService = (props) => {
  const { toggle = () => {}, modal = false, recruitmentContractId, monitorId, projectId, productId, onSubmit, dataTree={} } = props;
  const [listService, setListService] = useState([]);
  const [dataService, setDataService] = useState(mockData);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let objectTmp = dataService;
    if (monitorId) {
      objectTmp = { ...objectTmp, monitorId };
    }
    if (recruitmentContractId) {
      objectTmp = { ...objectTmp, recruitmentContractId };
    }
    if (projectId) {
      objectTmp = { ...objectTmp, projectId };
    }
    if (productId) {
      objectTmp = { ...objectTmp, productId };
    }
    setDataService(objectTmp);
    getListService({ projectId, productId });
  }, []);

  const getListService = async ({ projectId, productId }) => {
    try {
      setLoading(true);
      const data = await CONTRACT_ENDPOINTS.GET_LIST_SERVICE({ projectId, productId });
      if (data) {
        setListService(
          _.filter(data?.data, function (elm) {
            return !Object.keys(
              !_.isEmpty(dataTree?.[monitorId]?.nonProjects?.[productId])
                ? dataTree[monitorId].nonProjects[productId].services
                : dataTree[monitorId].projects[projectId].products[productId].services || {}
            ).includes(elm.serviceId);
          })
        );
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const handleClickBtn = ({ serviceId, state }) => {
    const newList = listService?.map((item) => {
      if (item.serviceId === serviceId) {
        return { ...item, state: !state };
      }
      return item;
    });
    setListService(newList);
    setDataService({ ...dataService, listService: newList });
  };

  const addService = () => {
    onServiceSubmit(dataService);
  };

  const onServiceSubmit = async (body) => {
    onSubmit(body, 'service');
  };

  return (
    <Modal className="form-modal-contract" isOpen={modal} toggle={() => toggle('service')}>
      <Loading loading={loading} />
      <ModalHeader toggle={() => toggle('service')}>Service新規登録</ModalHeader>
      <ModalBody>
        <div className="block-content pb-1">
          <div className="item-content">
            <label>プロジェクト名</label>
            <input type="text" value={dataTree && !_.isEmpty(dataTree?.[monitorId]?.nonProjects?.[productId]) ? '' : dataTree[monitorId]?.projects[projectId].projectName } disabled />
          </div>
          <div className="item-content">
            <label>工事名</label>
            <input type="text" value={dataTree && !_.isEmpty(dataTree?.[monitorId]?.nonProjects?.[productId]) ?  dataTree?.[monitorId]?.nonProjects?.[productId]?.productName :dataTree[monitorId].projects[projectId].products[productId].productName } disabled />
          </div>
          <div className="table-modal-service">
            <TableModalServiceContract handleClickBtn={handleClickBtn} dataTableService={listService} />
          </div>
        </div>
        <button className="btn-update" onClick={() => addService()}>
          登録
        </button>
      </ModalBody>
    </Modal>
  );
};

export default ModalService;
