export const AREAS_PERMITTED = [
  {
    "groupCode": "011002",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "札幌市",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ｻｯﾎﾟﾛｼ"
  },
  {
    "groupCode": "012025",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "函館市",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ﾊｺﾀﾞﾃｼ"
  },
  {
    "groupCode": "012033",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "小樽市",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ｵﾀﾙｼ"
  },
  {
    "groupCode": "012041",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "旭川市",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ｱｻﾋｶﾜｼ"
  },
  {
    "groupCode": "012050",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "室蘭市",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ﾑﾛﾗﾝｼ"
  },
  {
    "groupCode": "012068",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "釧路市",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ｸｼﾛｼ"
  },
  {
    "groupCode": "012076",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "帯広市",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ｵﾋﾞﾋﾛｼ"
  },
  {
    "groupCode": "012084",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "北見市",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ｷﾀﾐｼ"
  },
  {
    "groupCode": "012092",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "夕張市",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ﾕｳﾊﾞﾘｼ"
  },
  {
    "groupCode": "012106",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "岩見沢市",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ｲﾜﾐｻﾞﾜｼ"
  },
  {
    "groupCode": "012114",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "網走市",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ｱﾊﾞｼﾘｼ"
  },
  {
    "groupCode": "012122",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "留萌市",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ﾙﾓｲｼ"
  },
  {
    "groupCode": "012131",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "苫小牧市",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ﾄﾏｺﾏｲｼ"
  },
  {
    "groupCode": "012149",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "稚内市",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ﾜｯｶﾅｲｼ"
  },
  {
    "groupCode": "012157",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "美唄市",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ﾋﾞﾊﾞｲｼ"
  },
  {
    "groupCode": "012165",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "芦別市",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ｱｼﾍﾞﾂｼ"
  },
  {
    "groupCode": "012173",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "江別市",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ｴﾍﾞﾂｼ"
  },
  {
    "groupCode": "012181",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "赤平市",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ｱｶﾋﾞﾗｼ"
  },
  {
    "groupCode": "012190",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "紋別市",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ﾓﾝﾍﾞﾂｼ"
  },
  {
    "groupCode": "012203",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "士別市",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ｼﾍﾞﾂｼ"
  },
  {
    "groupCode": "012211",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "名寄市",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ﾅﾖﾛｼ"
  },
  {
    "groupCode": "012220",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "三笠市",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ﾐｶｻｼ"
  },
  {
    "groupCode": "012238",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "根室市",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ﾈﾑﾛｼ"
  },
  {
    "groupCode": "012246",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "千歳市",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ﾁﾄｾｼ"
  },
  {
    "groupCode": "012254",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "滝川市",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ﾀｷｶﾜｼ"
  },
  {
    "groupCode": "012262",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "砂川市",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ｽﾅｶﾞﾜｼ"
  },
  {
    "groupCode": "012271",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "歌志内市",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ｳﾀｼﾅｲｼ"
  },
  {
    "groupCode": "012289",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "深川市",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ﾌｶｶﾞﾜｼ"
  },
  {
    "groupCode": "012297",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "富良野市",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ﾌﾗﾉｼ"
  },
  {
    "groupCode": "012301",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "登別市",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ﾉﾎﾞﾘﾍﾞﾂｼ"
  },
  {
    "groupCode": "012319",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "恵庭市",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ｴﾆﾜｼ"
  },
  {
    "groupCode": "012335",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "伊達市",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ﾀﾞﾃｼ"
  },
  {
    "groupCode": "012343",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "北広島市",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ｷﾀﾋﾛｼﾏｼ"
  },
  {
    "groupCode": "012351",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "石狩市",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ｲｼｶﾘｼ"
  },
  {
    "groupCode": "012360",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "北斗市",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ﾎｸﾄｼ"
  },
  {
    "groupCode": "013030",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "当別町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ﾄｳﾍﾞﾂﾁｮｳ"
  },
  {
    "groupCode": "013048",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "新篠津村",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ｼﾝｼﾉﾂﾑﾗ"
  },
  {
    "groupCode": "013315",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "松前町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ﾏﾂﾏｴﾁｮｳ"
  },
  {
    "groupCode": "013323",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "福島町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ﾌｸｼﾏﾁｮｳ"
  },
  {
    "groupCode": "013331",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "知内町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ｼﾘｳﾁﾁｮｳ"
  },
  {
    "groupCode": "013340",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "木古内町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ｷｺﾅｲﾁｮｳ"
  },
  {
    "groupCode": "013374",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "七飯町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ﾅﾅｴﾁｮｳ"
  },
  {
    "groupCode": "013439",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "鹿部町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ｼｶﾍﾞﾁｮｳ"
  },
  {
    "groupCode": "013455",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "森町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ﾓﾘﾏﾁ"
  },
  {
    "groupCode": "013463",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "八雲町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ﾔｸﾓﾁｮｳ"
  },
  {
    "groupCode": "013471",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "長万部町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ｵｼｬﾏﾝﾍﾞﾁｮｳ"
  },
  {
    "groupCode": "013617",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "江差町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ｴｻｼﾁｮｳ"
  },
  {
    "groupCode": "013625",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "上ノ国町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ｶﾐﾉｸﾆﾁｮｳ"
  },
  {
    "groupCode": "013633",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "厚沢部町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ｱｯｻﾌﾞﾁｮｳ"
  },
  {
    "groupCode": "013641",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "乙部町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ｵﾄﾍﾞﾁｮｳ"
  },
  {
    "groupCode": "013676",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "奥尻町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ｵｸｼﾘﾁｮｳ"
  },
  {
    "groupCode": "013706",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "今金町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ｲﾏｶﾈﾁｮｳ"
  },
  {
    "groupCode": "013714",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "せたな町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ｾﾀﾅﾁｮｳ"
  },
  {
    "groupCode": "013919",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "島牧村",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ｼﾏﾏｷﾑﾗ"
  },
  {
    "groupCode": "013927",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "寿都町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ｽｯﾂﾁｮｳ"
  },
  {
    "groupCode": "013935",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "黒松内町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ｸﾛﾏﾂﾅｲﾁｮｳ"
  },
  {
    "groupCode": "013943",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "蘭越町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ﾗﾝｺｼﾁｮｳ"
  },
  {
    "groupCode": "013951",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "ニセコ町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ﾆｾｺﾁｮｳ"
  },
  {
    "groupCode": "013960",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "真狩村",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ﾏｯｶﾘﾑﾗ"
  },
  {
    "groupCode": "013978",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "留寿都村",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ﾙｽﾂﾑﾗ"
  },
  {
    "groupCode": "013986",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "喜茂別町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ｷﾓﾍﾞﾂﾁｮｳ"
  },
  {
    "groupCode": "013994",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "京極町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ｷｮｳｺﾞｸﾁｮｳ"
  },
  {
    "groupCode": "014001",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "倶知安町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ｸｯﾁｬﾝﾁｮｳ"
  },
  {
    "groupCode": "014010",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "共和町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ｷｮｳﾜﾁｮｳ"
  },
  {
    "groupCode": "014028",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "岩内町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ｲﾜﾅｲﾁｮｳ"
  },
  {
    "groupCode": "014036",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "泊村",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ﾄﾏﾘﾑﾗ"
  },
  {
    "groupCode": "014044",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "神恵内村",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ｶﾓｴﾅｲﾑﾗ"
  },
  {
    "groupCode": "014052",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "積丹町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ｼｬｺﾀﾝﾁｮｳ"
  },
  {
    "groupCode": "014061",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "古平町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ﾌﾙﾋﾞﾗﾁｮｳ"
  },
  {
    "groupCode": "014079",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "仁木町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ﾆｷﾁｮｳ"
  },
  {
    "groupCode": "014087",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "余市町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ﾖｲﾁﾁｮｳ"
  },
  {
    "groupCode": "014095",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "赤井川村",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ｱｶｲｶﾞﾜﾑﾗ"
  },
  {
    "groupCode": "014231",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "南幌町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ﾅﾝﾎﾟﾛﾁｮｳ"
  },
  {
    "groupCode": "014249",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "奈井江町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ﾅｲｴﾁｮｳ"
  },
  {
    "groupCode": "014257",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "上砂川町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ｶﾐｽﾅｶﾞﾜﾁｮｳ"
  },
  {
    "groupCode": "014273",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "由仁町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ﾕﾆﾁｮｳ"
  },
  {
    "groupCode": "014281",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "長沼町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ﾅｶﾞﾇﾏﾁｮｳ"
  },
  {
    "groupCode": "014290",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "栗山町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ｸﾘﾔﾏﾁｮｳ"
  },
  {
    "groupCode": "014303",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "月形町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ﾂｷｶﾞﾀﾁｮｳ"
  },
  {
    "groupCode": "014311",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "浦臼町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ｳﾗｳｽﾁｮｳ"
  },
  {
    "groupCode": "014320",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "新十津川町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ｼﾝﾄﾂｶﾜﾁｮｳ"
  },
  {
    "groupCode": "014338",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "妹背牛町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ﾓｾｳｼﾁｮｳ"
  },
  {
    "groupCode": "014346",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "秩父別町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ﾁｯﾌﾟﾍﾞﾂﾁｮｳ"
  },
  {
    "groupCode": "014362",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "雨竜町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ｳﾘｭｳﾁｮｳ"
  },
  {
    "groupCode": "014371",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "北竜町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ﾎｸﾘｭｳﾁｮｳ"
  },
  {
    "groupCode": "014389",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "沼田町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ﾇﾏﾀﾁｮｳ"
  },
  {
    "groupCode": "014524",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "鷹栖町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ﾀｶｽﾁｮｳ"
  },
  {
    "groupCode": "014532",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "東神楽町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ﾋｶﾞｼｶｸﾞﾗﾁｮｳ"
  },
  {
    "groupCode": "014541",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "当麻町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ﾄｳﾏﾁｮｳ"
  },
  {
    "groupCode": "014559",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "比布町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ﾋﾟｯﾌﾟﾁｮｳ"
  },
  {
    "groupCode": "014567",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "愛別町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ｱｲﾍﾞﾂﾁｮｳ"
  },
  {
    "groupCode": "014575",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "上川町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ｶﾐｶﾜﾁｮｳ"
  },
  {
    "groupCode": "014583",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "東川町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ﾋｶﾞｼｶﾜﾁｮｳ"
  },
  {
    "groupCode": "014591",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "美瑛町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ﾋﾞｴｲﾁｮｳ"
  },
  {
    "groupCode": "014605",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "上富良野町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ｶﾐﾌﾗﾉﾁｮｳ"
  },
  {
    "groupCode": "014613",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "中富良野町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ﾅｶﾌﾗﾉﾁｮｳ"
  },
  {
    "groupCode": "014621",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "南富良野町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ﾐﾅﾐﾌﾗﾉﾁｮｳ"
  },
  {
    "groupCode": "014630",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "占冠村",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ｼﾑｶｯﾌﾟﾑﾗ"
  },
  {
    "groupCode": "014648",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "和寒町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ﾜｯｻﾑﾁｮｳ"
  },
  {
    "groupCode": "014656",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "剣淵町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ｹﾝﾌﾞﾁﾁｮｳ"
  },
  {
    "groupCode": "014681",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "下川町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ｼﾓｶﾜﾁｮｳ"
  },
  {
    "groupCode": "014699",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "美深町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ﾋﾞﾌｶﾁｮｳ"
  },
  {
    "groupCode": "014702",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "音威子府村",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ｵﾄｲﾈｯﾌﾟﾑﾗ"
  },
  {
    "groupCode": "014711",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "中川町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ﾅｶｶﾞﾜﾁｮｳ"
  },
  {
    "groupCode": "014729",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "幌加内町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ﾎﾛｶﾅｲﾁｮｳ"
  },
  {
    "groupCode": "014818",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "増毛町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ﾏｼｹﾁｮｳ"
  },
  {
    "groupCode": "014826",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "小平町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ｵﾋﾞﾗﾁｮｳ"
  },
  {
    "groupCode": "014834",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "苫前町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ﾄﾏﾏｴﾁｮｳ"
  },
  {
    "groupCode": "014842",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "羽幌町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ﾊﾎﾞﾛﾁｮｳ"
  },
  {
    "groupCode": "014851",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "初山別村",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ｼｮｻﾝﾍﾞﾂﾑﾗ"
  },
  {
    "groupCode": "014869",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "遠別町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ｴﾝﾍﾞﾂﾁｮｳ"
  },
  {
    "groupCode": "014877",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "天塩町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ﾃｼｵﾁｮｳ"
  },
  {
    "groupCode": "015113",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "猿払村",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ｻﾙﾌﾂﾑﾗ"
  },
  {
    "groupCode": "015121",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "浜頓別町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ﾊﾏﾄﾝﾍﾞﾂﾁｮｳ"
  },
  {
    "groupCode": "015130",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "中頓別町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ﾅｶﾄﾝﾍﾞﾂﾁｮｳ"
  },
  {
    "groupCode": "015148",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "枝幸町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ｴｻｼﾁｮｳ"
  },
  {
    "groupCode": "015164",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "豊富町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ﾄﾖﾄﾐﾁｮｳ"
  },
  {
    "groupCode": "015172",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "礼文町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ﾚﾌﾞﾝﾁｮｳ"
  },
  {
    "groupCode": "015181",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "利尻町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ﾘｼﾘﾁｮｳ"
  },
  {
    "groupCode": "015199",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "利尻富士町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ﾘｼﾘﾌｼﾞﾁｮｳ"
  },
  {
    "groupCode": "015202",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "幌延町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ﾎﾛﾉﾍﾞﾁｮｳ"
  },
  {
    "groupCode": "015431",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "美幌町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ﾋﾞﾎﾛﾁｮｳ"
  },
  {
    "groupCode": "015440",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "津別町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ﾂﾍﾞﾂﾁｮｳ"
  },
  {
    "groupCode": "015458",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "斜里町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ｼｬﾘﾁｮｳ"
  },
  {
    "groupCode": "015466",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "清里町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ｷﾖｻﾄﾁｮｳ"
  },
  {
    "groupCode": "015474",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "小清水町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ｺｼﾐｽﾞﾁｮｳ"
  },
  {
    "groupCode": "015491",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "訓子府町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ｸﾝﾈｯﾌﾟﾁｮｳ"
  },
  {
    "groupCode": "015504",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "置戸町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ｵｹﾄﾁｮｳ"
  },
  {
    "groupCode": "015521",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "佐呂間町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ｻﾛﾏﾁｮｳ"
  },
  {
    "groupCode": "015555",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "遠軽町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ｴﾝｶﾞﾙﾁｮｳ"
  },
  {
    "groupCode": "015598",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "湧別町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ﾕｳﾍﾞﾂﾁｮｳ"
  },
  {
    "groupCode": "015601",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "滝上町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ﾀｷﾉｳｴﾁｮｳ"
  },
  {
    "groupCode": "015610",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "興部町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ｵｺｯﾍﾟﾁｮｳ"
  },
  {
    "groupCode": "015628",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "西興部村",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ﾆｼｵｺｯﾍﾟﾑﾗ"
  },
  {
    "groupCode": "015636",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "雄武町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ｵｳﾑﾁｮｳ"
  },
  {
    "groupCode": "015644",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "大空町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ｵｵｿﾞﾗﾁｮｳ"
  },
  {
    "groupCode": "015717",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "豊浦町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ﾄﾖｳﾗﾁｮｳ"
  },
  {
    "groupCode": "015750",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "壮瞥町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ｿｳﾍﾞﾂﾁｮｳ"
  },
  {
    "groupCode": "015784",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "白老町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ｼﾗｵｲﾁｮｳ"
  },
  {
    "groupCode": "015814",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "厚真町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ｱﾂﾏﾁｮｳ"
  },
  {
    "groupCode": "015849",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "洞爺湖町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ﾄｳﾔｺﾁｮｳ"
  },
  {
    "groupCode": "015857",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "安平町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ｱﾋﾞﾗﾁｮｳ"
  },
  {
    "groupCode": "015865",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "むかわ町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ﾑｶﾜﾁｮｳ"
  },
  {
    "groupCode": "016012",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "日高町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ﾋﾀﾞｶﾁｮｳ"
  },
  {
    "groupCode": "016021",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "平取町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ﾋﾞﾗﾄﾘﾁｮｳ"
  },
  {
    "groupCode": "016047",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "新冠町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ﾆｲｶｯﾌﾟﾁｮｳ"
  },
  {
    "groupCode": "016071",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "浦河町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ｳﾗｶﾜﾁｮｳ"
  },
  {
    "groupCode": "016080",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "様似町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ｻﾏﾆﾁｮｳ"
  },
  {
    "groupCode": "016098",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "えりも町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ｴﾘﾓﾁｮｳ"
  },
  {
    "groupCode": "016101",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "新ひだか町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ｼﾝﾋﾀﾞｶﾁｮｳ"
  },
  {
    "groupCode": "016314",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "音更町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ｵﾄﾌｹﾁｮｳ"
  },
  {
    "groupCode": "016322",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "士幌町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ｼﾎﾛﾁｮｳ"
  },
  {
    "groupCode": "016331",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "上士幌町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ｶﾐｼﾎﾛﾁｮｳ"
  },
  {
    "groupCode": "016349",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "鹿追町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ｼｶｵｲﾁｮｳ"
  },
  {
    "groupCode": "016357",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "新得町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ｼﾝﾄｸﾁｮｳ"
  },
  {
    "groupCode": "016365",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "清水町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ｼﾐｽﾞﾁｮｳ"
  },
  {
    "groupCode": "016373",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "芽室町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ﾒﾑﾛﾁｮｳ"
  },
  {
    "groupCode": "016381",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "中札内村",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ﾅｶｻﾂﾅｲﾑﾗ"
  },
  {
    "groupCode": "016390",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "更別村",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ｻﾗﾍﾞﾂﾑﾗ"
  },
  {
    "groupCode": "016411",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "大樹町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ﾀｲｷﾁｮｳ"
  },
  {
    "groupCode": "016420",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "広尾町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ﾋﾛｵﾁｮｳ"
  },
  {
    "groupCode": "016438",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "幕別町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ﾏｸﾍﾞﾂﾁｮｳ"
  },
  {
    "groupCode": "016446",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "池田町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ｲｹﾀﾞﾁｮｳ"
  },
  {
    "groupCode": "016454",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "豊頃町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ﾄﾖｺﾛﾁｮｳ"
  },
  {
    "groupCode": "016462",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "本別町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ﾎﾝﾍﾞﾂﾁｮｳ"
  },
  {
    "groupCode": "016471",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "足寄町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ｱｼｮﾛﾁｮｳ"
  },
  {
    "groupCode": "016489",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "陸別町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ﾘｸﾍﾞﾂﾁｮｳ"
  },
  {
    "groupCode": "016497",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "浦幌町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ｳﾗﾎﾛﾁｮｳ"
  },
  {
    "groupCode": "016616",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "釧路町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ｸｼﾛﾁｮｳ"
  },
  {
    "groupCode": "016624",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "厚岸町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ｱｯｹｼﾁｮｳ"
  },
  {
    "groupCode": "016632",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "浜中町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ﾊﾏﾅｶﾁｮｳ"
  },
  {
    "groupCode": "016641",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "標茶町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ｼﾍﾞﾁｬﾁｮｳ"
  },
  {
    "groupCode": "016659",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "弟子屈町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ﾃｼｶｶﾞﾁｮｳ"
  },
  {
    "groupCode": "016675",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "鶴居村",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ﾂﾙｲﾑﾗ"
  },
  {
    "groupCode": "016683",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "白糠町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ｼﾗﾇｶﾁｮｳ"
  },
  {
    "groupCode": "016918",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "別海町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ﾍﾞﾂｶｲﾁｮｳ"
  },
  {
    "groupCode": "016926",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "中標津町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ﾅｶｼﾍﾞﾂﾁｮｳ"
  },
  {
    "groupCode": "016934",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "標津町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ｼﾍﾞﾂﾁｮｳ"
  },
  {
    "groupCode": "016942",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "羅臼町",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ﾗｳｽﾁｮｳ"
  },
  {
    "groupCode": "016951",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "色丹村",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ｼｺﾀﾝﾑﾗ"
  },
  {
    "groupCode": "016969",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "泊村",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ﾄﾏﾘﾑﾗ"
  },
  {
    "groupCode": "016977",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "留夜別村",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ﾙﾔﾍﾞﾂﾑﾗ"
  },
  {
    "groupCode": "016985",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "留別村",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ﾙﾍﾞﾂﾑﾗ"
  },
  {
    "groupCode": "016993",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "紗那村",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ｼｬﾅﾑﾗ"
  },
  {
    "groupCode": "017001",
    "prefectureNameKanji": "北海道",
    "cityNameKanji": "蘂取村",
    "prefectureNameKatakana": "ﾎｯｶｲﾄﾞｳ",
    "cityNameKatakana": "ｼﾍﾞﾄﾛﾑﾗ"
  },
  {
    "groupCode": "022012",
    "prefectureNameKanji": "青森県",
    "cityNameKanji": "青森市",
    "prefectureNameKatakana": "ｱｵﾓﾘｹﾝ",
    "cityNameKatakana": "ｱｵﾓﾘｼ"
  },
  {
    "groupCode": "022021",
    "prefectureNameKanji": "青森県",
    "cityNameKanji": "弘前市",
    "prefectureNameKatakana": "ｱｵﾓﾘｹﾝ",
    "cityNameKatakana": "ﾋﾛｻｷｼ"
  },
  {
    "groupCode": "022039",
    "prefectureNameKanji": "青森県",
    "cityNameKanji": "八戸市",
    "prefectureNameKatakana": "ｱｵﾓﾘｹﾝ",
    "cityNameKatakana": "ﾊﾁﾉﾍｼ"
  },
  {
    "groupCode": "022047",
    "prefectureNameKanji": "青森県",
    "cityNameKanji": "黒石市",
    "prefectureNameKatakana": "ｱｵﾓﾘｹﾝ",
    "cityNameKatakana": "ｸﾛｲｼｼ"
  },
  {
    "groupCode": "022055",
    "prefectureNameKanji": "青森県",
    "cityNameKanji": "五所川原市",
    "prefectureNameKatakana": "ｱｵﾓﾘｹﾝ",
    "cityNameKatakana": "ｺﾞｼｮｶﾞﾜﾗｼ"
  },
  {
    "groupCode": "022063",
    "prefectureNameKanji": "青森県",
    "cityNameKanji": "十和田市",
    "prefectureNameKatakana": "ｱｵﾓﾘｹﾝ",
    "cityNameKatakana": "ﾄﾜﾀﾞｼ"
  },
  {
    "groupCode": "022071",
    "prefectureNameKanji": "青森県",
    "cityNameKanji": "三沢市",
    "prefectureNameKatakana": "ｱｵﾓﾘｹﾝ",
    "cityNameKatakana": "ﾐｻﾜｼ"
  },
  {
    "groupCode": "022080",
    "prefectureNameKanji": "青森県",
    "cityNameKanji": "むつ市",
    "prefectureNameKatakana": "ｱｵﾓﾘｹﾝ",
    "cityNameKatakana": "ﾑﾂｼ"
  },
  {
    "groupCode": "022098",
    "prefectureNameKanji": "青森県",
    "cityNameKanji": "つがる市",
    "prefectureNameKatakana": "ｱｵﾓﾘｹﾝ",
    "cityNameKatakana": "ﾂｶﾞﾙｼ"
  },
  {
    "groupCode": "022101",
    "prefectureNameKanji": "青森県",
    "cityNameKanji": "平川市",
    "prefectureNameKatakana": "ｱｵﾓﾘｹﾝ",
    "cityNameKatakana": "ﾋﾗｶﾜｼ"
  },
  {
    "groupCode": "023019",
    "prefectureNameKanji": "青森県",
    "cityNameKanji": "平内町",
    "prefectureNameKatakana": "ｱｵﾓﾘｹﾝ",
    "cityNameKatakana": "ﾋﾗﾅｲﾏﾁ"
  },
  {
    "groupCode": "023035",
    "prefectureNameKanji": "青森県",
    "cityNameKanji": "今別町",
    "prefectureNameKatakana": "ｱｵﾓﾘｹﾝ",
    "cityNameKatakana": "ｲﾏﾍﾞﾂﾏﾁ"
  },
  {
    "groupCode": "023043",
    "prefectureNameKanji": "青森県",
    "cityNameKanji": "蓬田村",
    "prefectureNameKatakana": "ｱｵﾓﾘｹﾝ",
    "cityNameKatakana": "ﾖﾓｷﾞﾀﾑﾗ"
  },
  {
    "groupCode": "023078",
    "prefectureNameKanji": "青森県",
    "cityNameKanji": "外ヶ浜町",
    "prefectureNameKatakana": "ｱｵﾓﾘｹﾝ",
    "cityNameKatakana": "ｿﾄｶﾞﾊﾏﾏﾁ"
  },
  {
    "groupCode": "023213",
    "prefectureNameKanji": "青森県",
    "cityNameKanji": "鰺ヶ沢町",
    "prefectureNameKatakana": "ｱｵﾓﾘｹﾝ",
    "cityNameKatakana": "ｱｼﾞｶﾞｻﾜﾏﾁ"
  },
  {
    "groupCode": "023230",
    "prefectureNameKanji": "青森県",
    "cityNameKanji": "深浦町",
    "prefectureNameKatakana": "ｱｵﾓﾘｹﾝ",
    "cityNameKatakana": "ﾌｶｳﾗﾏﾁ"
  },
  {
    "groupCode": "023434",
    "prefectureNameKanji": "青森県",
    "cityNameKanji": "西目屋村",
    "prefectureNameKatakana": "ｱｵﾓﾘｹﾝ",
    "cityNameKatakana": "ﾆｼﾒﾔﾑﾗ"
  },
  {
    "groupCode": "023612",
    "prefectureNameKanji": "青森県",
    "cityNameKanji": "藤崎町",
    "prefectureNameKatakana": "ｱｵﾓﾘｹﾝ",
    "cityNameKatakana": "ﾌｼﾞｻｷﾏﾁ"
  },
  {
    "groupCode": "023621",
    "prefectureNameKanji": "青森県",
    "cityNameKanji": "大鰐町",
    "prefectureNameKatakana": "ｱｵﾓﾘｹﾝ",
    "cityNameKatakana": "ｵｵﾜﾆﾏﾁ"
  },
  {
    "groupCode": "023671",
    "prefectureNameKanji": "青森県",
    "cityNameKanji": "田舎館村",
    "prefectureNameKatakana": "ｱｵﾓﾘｹﾝ",
    "cityNameKatakana": "ｲﾅｶﾀﾞﾃﾑﾗ"
  },
  {
    "groupCode": "023817",
    "prefectureNameKanji": "青森県",
    "cityNameKanji": "板柳町",
    "prefectureNameKatakana": "ｱｵﾓﾘｹﾝ",
    "cityNameKatakana": "ｲﾀﾔﾅｷﾞﾏﾁ"
  },
  {
    "groupCode": "023841",
    "prefectureNameKanji": "青森県",
    "cityNameKanji": "鶴田町",
    "prefectureNameKatakana": "ｱｵﾓﾘｹﾝ",
    "cityNameKatakana": "ﾂﾙﾀﾏﾁ"
  },
  {
    "groupCode": "023876",
    "prefectureNameKanji": "青森県",
    "cityNameKanji": "中泊町",
    "prefectureNameKatakana": "ｱｵﾓﾘｹﾝ",
    "cityNameKatakana": "ﾅｶﾄﾞﾏﾘﾏﾁ"
  },
  {
    "groupCode": "024015",
    "prefectureNameKanji": "青森県",
    "cityNameKanji": "野辺地町",
    "prefectureNameKatakana": "ｱｵﾓﾘｹﾝ",
    "cityNameKatakana": "ﾉﾍｼﾞﾏﾁ"
  },
  {
    "groupCode": "024023",
    "prefectureNameKanji": "青森県",
    "cityNameKanji": "七戸町",
    "prefectureNameKatakana": "ｱｵﾓﾘｹﾝ",
    "cityNameKatakana": "ｼﾁﾉﾍﾏﾁ"
  },
  {
    "groupCode": "024058",
    "prefectureNameKanji": "青森県",
    "cityNameKanji": "六戸町",
    "prefectureNameKatakana": "ｱｵﾓﾘｹﾝ",
    "cityNameKatakana": "ﾛｸﾉﾍﾏﾁ"
  },
  {
    "groupCode": "024066",
    "prefectureNameKanji": "青森県",
    "cityNameKanji": "横浜町",
    "prefectureNameKatakana": "ｱｵﾓﾘｹﾝ",
    "cityNameKatakana": "ﾖｺﾊﾏﾏﾁ"
  },
  {
    "groupCode": "024082",
    "prefectureNameKanji": "青森県",
    "cityNameKanji": "東北町",
    "prefectureNameKatakana": "ｱｵﾓﾘｹﾝ",
    "cityNameKatakana": "ﾄｳﾎｸﾏﾁ"
  },
  {
    "groupCode": "024112",
    "prefectureNameKanji": "青森県",
    "cityNameKanji": "六ヶ所村",
    "prefectureNameKatakana": "ｱｵﾓﾘｹﾝ",
    "cityNameKatakana": "ﾛｯｶｼｮﾑﾗ"
  },
  {
    "groupCode": "024121",
    "prefectureNameKanji": "青森県",
    "cityNameKanji": "おいらせ町",
    "prefectureNameKatakana": "ｱｵﾓﾘｹﾝ",
    "cityNameKatakana": "ｵｲﾗｾﾁｮｳ"
  },
  {
    "groupCode": "024236",
    "prefectureNameKanji": "青森県",
    "cityNameKanji": "大間町",
    "prefectureNameKatakana": "ｱｵﾓﾘｹﾝ",
    "cityNameKatakana": "ｵｵﾏﾏﾁ"
  },
  {
    "groupCode": "024244",
    "prefectureNameKanji": "青森県",
    "cityNameKanji": "東通村",
    "prefectureNameKatakana": "ｱｵﾓﾘｹﾝ",
    "cityNameKatakana": "ﾋｶﾞｼﾄﾞｵﾘﾑﾗ"
  },
  {
    "groupCode": "024252",
    "prefectureNameKanji": "青森県",
    "cityNameKanji": "風間浦村",
    "prefectureNameKatakana": "ｱｵﾓﾘｹﾝ",
    "cityNameKatakana": "ｶｻﾞﾏｳﾗﾑﾗ"
  },
  {
    "groupCode": "024261",
    "prefectureNameKanji": "青森県",
    "cityNameKanji": "佐井村",
    "prefectureNameKatakana": "ｱｵﾓﾘｹﾝ",
    "cityNameKatakana": "ｻｲﾑﾗ"
  },
  {
    "groupCode": "024414",
    "prefectureNameKanji": "青森県",
    "cityNameKanji": "三戸町",
    "prefectureNameKatakana": "ｱｵﾓﾘｹﾝ",
    "cityNameKatakana": "ｻﾝﾉﾍﾏﾁ"
  },
  {
    "groupCode": "024422",
    "prefectureNameKanji": "青森県",
    "cityNameKanji": "五戸町",
    "prefectureNameKatakana": "ｱｵﾓﾘｹﾝ",
    "cityNameKatakana": "ｺﾞﾉﾍﾏﾁ"
  },
  {
    "groupCode": "024431",
    "prefectureNameKanji": "青森県",
    "cityNameKanji": "田子町",
    "prefectureNameKatakana": "ｱｵﾓﾘｹﾝ",
    "cityNameKatakana": "ﾀｯｺﾏﾁ"
  },
  {
    "groupCode": "024457",
    "prefectureNameKanji": "青森県",
    "cityNameKanji": "南部町",
    "prefectureNameKatakana": "ｱｵﾓﾘｹﾝ",
    "cityNameKatakana": "ﾅﾝﾌﾞﾁｮｳ"
  },
  {
    "groupCode": "024465",
    "prefectureNameKanji": "青森県",
    "cityNameKanji": "階上町",
    "prefectureNameKatakana": "ｱｵﾓﾘｹﾝ",
    "cityNameKatakana": "ﾊｼｶﾐﾁｮｳ"
  },
  {
    "groupCode": "024503",
    "prefectureNameKanji": "青森県",
    "cityNameKanji": "新郷村",
    "prefectureNameKatakana": "ｱｵﾓﾘｹﾝ",
    "cityNameKatakana": "ｼﾝｺﾞｳﾑﾗ"
  },
  {
    "groupCode": "032018",
    "prefectureNameKanji": "岩手県",
    "cityNameKanji": "盛岡市",
    "prefectureNameKatakana": "ｲﾜﾃｹﾝ",
    "cityNameKatakana": "ﾓﾘｵｶｼ"
  },
  {
    "groupCode": "032026",
    "prefectureNameKanji": "岩手県",
    "cityNameKanji": "宮古市",
    "prefectureNameKatakana": "ｲﾜﾃｹﾝ",
    "cityNameKatakana": "ﾐﾔｺｼ"
  },
  {
    "groupCode": "032034",
    "prefectureNameKanji": "岩手県",
    "cityNameKanji": "大船渡市",
    "prefectureNameKatakana": "ｲﾜﾃｹﾝ",
    "cityNameKatakana": "ｵｵﾌﾅﾄｼ"
  },
  {
    "groupCode": "032051",
    "prefectureNameKanji": "岩手県",
    "cityNameKanji": "花巻市",
    "prefectureNameKatakana": "ｲﾜﾃｹﾝ",
    "cityNameKatakana": "ﾊﾅﾏｷｼ"
  },
  {
    "groupCode": "032069",
    "prefectureNameKanji": "岩手県",
    "cityNameKanji": "北上市",
    "prefectureNameKatakana": "ｲﾜﾃｹﾝ",
    "cityNameKatakana": "ｷﾀｶﾐｼ"
  },
  {
    "groupCode": "032077",
    "prefectureNameKanji": "岩手県",
    "cityNameKanji": "久慈市",
    "prefectureNameKatakana": "ｲﾜﾃｹﾝ",
    "cityNameKatakana": "ｸｼﾞｼ"
  },
  {
    "groupCode": "032085",
    "prefectureNameKanji": "岩手県",
    "cityNameKanji": "遠野市",
    "prefectureNameKatakana": "ｲﾜﾃｹﾝ",
    "cityNameKatakana": "ﾄｵﾉｼ"
  },
  {
    "groupCode": "032093",
    "prefectureNameKanji": "岩手県",
    "cityNameKanji": "一関市",
    "prefectureNameKatakana": "ｲﾜﾃｹﾝ",
    "cityNameKatakana": "ｲﾁﾉｾｷｼ"
  },
  {
    "groupCode": "032107",
    "prefectureNameKanji": "岩手県",
    "cityNameKanji": "陸前高田市",
    "prefectureNameKatakana": "ｲﾜﾃｹﾝ",
    "cityNameKatakana": "ﾘｸｾﾞﾝﾀｶﾀｼ"
  },
  {
    "groupCode": "032115",
    "prefectureNameKanji": "岩手県",
    "cityNameKanji": "釜石市",
    "prefectureNameKatakana": "ｲﾜﾃｹﾝ",
    "cityNameKatakana": "ｶﾏｲｼｼ"
  },
  {
    "groupCode": "032131",
    "prefectureNameKanji": "岩手県",
    "cityNameKanji": "二戸市",
    "prefectureNameKatakana": "ｲﾜﾃｹﾝ",
    "cityNameKatakana": "ﾆﾉﾍｼ"
  },
  {
    "groupCode": "032140",
    "prefectureNameKanji": "岩手県",
    "cityNameKanji": "八幡平市",
    "prefectureNameKatakana": "ｲﾜﾃｹﾝ",
    "cityNameKatakana": "ﾊﾁﾏﾝﾀｲｼ"
  },
  {
    "groupCode": "032158",
    "prefectureNameKanji": "岩手県",
    "cityNameKanji": "奥州市",
    "prefectureNameKatakana": "ｲﾜﾃｹﾝ",
    "cityNameKatakana": "ｵｳｼｭｳｼ"
  },
  {
    "groupCode": "032166",
    "prefectureNameKanji": "岩手県",
    "cityNameKanji": "滝沢市",
    "prefectureNameKatakana": "ｲﾜﾃｹﾝ",
    "cityNameKatakana": "ﾀｷｻﾞﾜｼ"
  },
  {
    "groupCode": "033014",
    "prefectureNameKanji": "岩手県",
    "cityNameKanji": "雫石町",
    "prefectureNameKatakana": "ｲﾜﾃｹﾝ",
    "cityNameKatakana": "ｼｽﾞｸｲｼﾁｮｳ"
  },
  {
    "groupCode": "033022",
    "prefectureNameKanji": "岩手県",
    "cityNameKanji": "葛巻町",
    "prefectureNameKatakana": "ｲﾜﾃｹﾝ",
    "cityNameKatakana": "ｸｽﾞﾏｷﾏﾁ"
  },
  {
    "groupCode": "033031",
    "prefectureNameKanji": "岩手県",
    "cityNameKanji": "岩手町",
    "prefectureNameKatakana": "ｲﾜﾃｹﾝ",
    "cityNameKatakana": "ｲﾜﾃﾏﾁ"
  },
  {
    "groupCode": "033219",
    "prefectureNameKanji": "岩手県",
    "cityNameKanji": "紫波町",
    "prefectureNameKatakana": "ｲﾜﾃｹﾝ",
    "cityNameKatakana": "ｼﾜﾁｮｳ"
  },
  {
    "groupCode": "033227",
    "prefectureNameKanji": "岩手県",
    "cityNameKanji": "矢巾町",
    "prefectureNameKatakana": "ｲﾜﾃｹﾝ",
    "cityNameKatakana": "ﾔﾊﾊﾞﾁｮｳ"
  },
  {
    "groupCode": "033669",
    "prefectureNameKanji": "岩手県",
    "cityNameKanji": "西和賀町",
    "prefectureNameKatakana": "ｲﾜﾃｹﾝ",
    "cityNameKatakana": "ﾆｼﾜｶﾞﾏﾁ"
  },
  {
    "groupCode": "033812",
    "prefectureNameKanji": "岩手県",
    "cityNameKanji": "金ケ崎町",
    "prefectureNameKatakana": "ｲﾜﾃｹﾝ",
    "cityNameKatakana": "ｶﾈｶﾞｻｷﾁｮｳ"
  },
  {
    "groupCode": "034029",
    "prefectureNameKanji": "岩手県",
    "cityNameKanji": "平泉町",
    "prefectureNameKatakana": "ｲﾜﾃｹﾝ",
    "cityNameKatakana": "ﾋﾗｲｽﾞﾐﾁｮｳ"
  },
  {
    "groupCode": "034410",
    "prefectureNameKanji": "岩手県",
    "cityNameKanji": "住田町",
    "prefectureNameKatakana": "ｲﾜﾃｹﾝ",
    "cityNameKatakana": "ｽﾐﾀﾁｮｳ"
  },
  {
    "groupCode": "034614",
    "prefectureNameKanji": "岩手県",
    "cityNameKanji": "大槌町",
    "prefectureNameKatakana": "ｲﾜﾃｹﾝ",
    "cityNameKatakana": "ｵｵﾂﾁﾁｮｳ"
  },
  {
    "groupCode": "034827",
    "prefectureNameKanji": "岩手県",
    "cityNameKanji": "山田町",
    "prefectureNameKatakana": "ｲﾜﾃｹﾝ",
    "cityNameKatakana": "ﾔﾏﾀﾞﾏﾁ"
  },
  {
    "groupCode": "034835",
    "prefectureNameKanji": "岩手県",
    "cityNameKanji": "岩泉町",
    "prefectureNameKatakana": "ｲﾜﾃｹﾝ",
    "cityNameKatakana": "ｲﾜｲｽﾞﾐﾁｮｳ"
  },
  {
    "groupCode": "034843",
    "prefectureNameKanji": "岩手県",
    "cityNameKanji": "田野畑村",
    "prefectureNameKatakana": "ｲﾜﾃｹﾝ",
    "cityNameKatakana": "ﾀﾉﾊﾀﾑﾗ"
  },
  {
    "groupCode": "034851",
    "prefectureNameKanji": "岩手県",
    "cityNameKanji": "普代村",
    "prefectureNameKatakana": "ｲﾜﾃｹﾝ",
    "cityNameKatakana": "ﾌﾀﾞｲﾑﾗ"
  },
  {
    "groupCode": "035017",
    "prefectureNameKanji": "岩手県",
    "cityNameKanji": "軽米町",
    "prefectureNameKatakana": "ｲﾜﾃｹﾝ",
    "cityNameKatakana": "ｶﾙﾏｲﾏﾁ"
  },
  {
    "groupCode": "035033",
    "prefectureNameKanji": "岩手県",
    "cityNameKanji": "野田村",
    "prefectureNameKatakana": "ｲﾜﾃｹﾝ",
    "cityNameKatakana": "ﾉﾀﾞﾑﾗ"
  },
  {
    "groupCode": "035068",
    "prefectureNameKanji": "岩手県",
    "cityNameKanji": "九戸村",
    "prefectureNameKatakana": "ｲﾜﾃｹﾝ",
    "cityNameKatakana": "ｸﾉﾍﾑﾗ"
  },
  {
    "groupCode": "035076",
    "prefectureNameKanji": "岩手県",
    "cityNameKanji": "洋野町",
    "prefectureNameKatakana": "ｲﾜﾃｹﾝ",
    "cityNameKatakana": "ﾋﾛﾉﾁｮｳ"
  },
  {
    "groupCode": "035246",
    "prefectureNameKanji": "岩手県",
    "cityNameKanji": "一戸町",
    "prefectureNameKatakana": "ｲﾜﾃｹﾝ",
    "cityNameKatakana": "ｲﾁﾉﾍﾏﾁ"
  },
  {
    "groupCode": "041009",
    "prefectureNameKanji": "宮城県",
    "cityNameKanji": "仙台市",
    "prefectureNameKatakana": "ﾐﾔｷﾞｹﾝ",
    "cityNameKatakana": "ｾﾝﾀﾞｲｼ"
  },
  {
    "groupCode": "042021",
    "prefectureNameKanji": "宮城県",
    "cityNameKanji": "石巻市",
    "prefectureNameKatakana": "ﾐﾔｷﾞｹﾝ",
    "cityNameKatakana": "ｲｼﾉﾏｷｼ"
  },
  {
    "groupCode": "042030",
    "prefectureNameKanji": "宮城県",
    "cityNameKanji": "塩竈市",
    "prefectureNameKatakana": "ﾐﾔｷﾞｹﾝ",
    "cityNameKatakana": "ｼｵｶﾞﾏｼ"
  },
  {
    "groupCode": "042056",
    "prefectureNameKanji": "宮城県",
    "cityNameKanji": "気仙沼市",
    "prefectureNameKatakana": "ﾐﾔｷﾞｹﾝ",
    "cityNameKatakana": "ｹｾﾝﾇﾏｼ"
  },
  {
    "groupCode": "042064",
    "prefectureNameKanji": "宮城県",
    "cityNameKanji": "白石市",
    "prefectureNameKatakana": "ﾐﾔｷﾞｹﾝ",
    "cityNameKatakana": "ｼﾛｲｼｼ"
  },
  {
    "groupCode": "042072",
    "prefectureNameKanji": "宮城県",
    "cityNameKanji": "名取市",
    "prefectureNameKatakana": "ﾐﾔｷﾞｹﾝ",
    "cityNameKatakana": "ﾅﾄﾘｼ"
  },
  {
    "groupCode": "042081",
    "prefectureNameKanji": "宮城県",
    "cityNameKanji": "角田市",
    "prefectureNameKatakana": "ﾐﾔｷﾞｹﾝ",
    "cityNameKatakana": "ｶｸﾀﾞｼ"
  },
  {
    "groupCode": "042099",
    "prefectureNameKanji": "宮城県",
    "cityNameKanji": "多賀城市",
    "prefectureNameKatakana": "ﾐﾔｷﾞｹﾝ",
    "cityNameKatakana": "ﾀｶﾞｼﾞｮｳｼ"
  },
  {
    "groupCode": "042111",
    "prefectureNameKanji": "宮城県",
    "cityNameKanji": "岩沼市",
    "prefectureNameKatakana": "ﾐﾔｷﾞｹﾝ",
    "cityNameKatakana": "ｲﾜﾇﾏｼ"
  },
  {
    "groupCode": "042129",
    "prefectureNameKanji": "宮城県",
    "cityNameKanji": "登米市",
    "prefectureNameKatakana": "ﾐﾔｷﾞｹﾝ",
    "cityNameKatakana": "ﾄﾒｼ"
  },
  {
    "groupCode": "042137",
    "prefectureNameKanji": "宮城県",
    "cityNameKanji": "栗原市",
    "prefectureNameKatakana": "ﾐﾔｷﾞｹﾝ",
    "cityNameKatakana": "ｸﾘﾊﾗｼ"
  },
  {
    "groupCode": "042145",
    "prefectureNameKanji": "宮城県",
    "cityNameKanji": "東松島市",
    "prefectureNameKatakana": "ﾐﾔｷﾞｹﾝ",
    "cityNameKatakana": "ﾋｶﾞｼﾏﾂｼﾏｼ"
  },
  {
    "groupCode": "042153",
    "prefectureNameKanji": "宮城県",
    "cityNameKanji": "大崎市",
    "prefectureNameKatakana": "ﾐﾔｷﾞｹﾝ",
    "cityNameKatakana": "ｵｵｻｷｼ"
  },
  {
    "groupCode": "042161",
    "prefectureNameKanji": "宮城県",
    "cityNameKanji": "富谷市",
    "prefectureNameKatakana": "ﾐﾔｷﾞｹﾝ",
    "cityNameKatakana": "ﾄﾐﾔｼ"
  },
  {
    "groupCode": "043010",
    "prefectureNameKanji": "宮城県",
    "cityNameKanji": "蔵王町",
    "prefectureNameKatakana": "ﾐﾔｷﾞｹﾝ",
    "cityNameKatakana": "ｻﾞｵｳﾏﾁ"
  },
  {
    "groupCode": "043028",
    "prefectureNameKanji": "宮城県",
    "cityNameKanji": "七ヶ宿町",
    "prefectureNameKatakana": "ﾐﾔｷﾞｹﾝ",
    "cityNameKatakana": "ｼﾁｶｼｭｸﾏﾁ"
  },
  {
    "groupCode": "043214",
    "prefectureNameKanji": "宮城県",
    "cityNameKanji": "大河原町",
    "prefectureNameKatakana": "ﾐﾔｷﾞｹﾝ",
    "cityNameKatakana": "ｵｵｶﾞﾜﾗﾏﾁ"
  },
  {
    "groupCode": "043222",
    "prefectureNameKanji": "宮城県",
    "cityNameKanji": "村田町",
    "prefectureNameKatakana": "ﾐﾔｷﾞｹﾝ",
    "cityNameKatakana": "ﾑﾗﾀﾏﾁ"
  },
  {
    "groupCode": "043231",
    "prefectureNameKanji": "宮城県",
    "cityNameKanji": "柴田町",
    "prefectureNameKatakana": "ﾐﾔｷﾞｹﾝ",
    "cityNameKatakana": "ｼﾊﾞﾀﾏﾁ"
  },
  {
    "groupCode": "043249",
    "prefectureNameKanji": "宮城県",
    "cityNameKanji": "川崎町",
    "prefectureNameKatakana": "ﾐﾔｷﾞｹﾝ",
    "cityNameKatakana": "ｶﾜｻｷﾏﾁ"
  },
  {
    "groupCode": "043419",
    "prefectureNameKanji": "宮城県",
    "cityNameKanji": "丸森町",
    "prefectureNameKatakana": "ﾐﾔｷﾞｹﾝ",
    "cityNameKatakana": "ﾏﾙﾓﾘﾏﾁ"
  },
  {
    "groupCode": "043613",
    "prefectureNameKanji": "宮城県",
    "cityNameKanji": "亘理町",
    "prefectureNameKatakana": "ﾐﾔｷﾞｹﾝ",
    "cityNameKatakana": "ﾜﾀﾘﾁｮｳ"
  },
  {
    "groupCode": "043621",
    "prefectureNameKanji": "宮城県",
    "cityNameKanji": "山元町",
    "prefectureNameKatakana": "ﾐﾔｷﾞｹﾝ",
    "cityNameKatakana": "ﾔﾏﾓﾄﾁｮｳ"
  },
  {
    "groupCode": "044016",
    "prefectureNameKanji": "宮城県",
    "cityNameKanji": "松島町",
    "prefectureNameKatakana": "ﾐﾔｷﾞｹﾝ",
    "cityNameKatakana": "ﾏﾂｼﾏﾏﾁ"
  },
  {
    "groupCode": "044041",
    "prefectureNameKanji": "宮城県",
    "cityNameKanji": "七ヶ浜町",
    "prefectureNameKatakana": "ﾐﾔｷﾞｹﾝ",
    "cityNameKatakana": "ｼﾁｶﾞﾊﾏﾏﾁ"
  },
  {
    "groupCode": "044067",
    "prefectureNameKanji": "宮城県",
    "cityNameKanji": "利府町",
    "prefectureNameKatakana": "ﾐﾔｷﾞｹﾝ",
    "cityNameKatakana": "ﾘﾌﾁｮｳ"
  },
  {
    "groupCode": "044211",
    "prefectureNameKanji": "宮城県",
    "cityNameKanji": "大和町",
    "prefectureNameKatakana": "ﾐﾔｷﾞｹﾝ",
    "cityNameKatakana": "ﾀｲﾜﾁｮｳ"
  },
  {
    "groupCode": "044229",
    "prefectureNameKanji": "宮城県",
    "cityNameKanji": "大郷町",
    "prefectureNameKatakana": "ﾐﾔｷﾞｹﾝ",
    "cityNameKatakana": "ｵｵｻﾄﾁｮｳ"
  },
  {
    "groupCode": "044245",
    "prefectureNameKanji": "宮城県",
    "cityNameKanji": "大衡村",
    "prefectureNameKatakana": "ﾐﾔｷﾞｹﾝ",
    "cityNameKatakana": "ｵｵﾋﾗﾑﾗ"
  },
  {
    "groupCode": "044440",
    "prefectureNameKanji": "宮城県",
    "cityNameKanji": "色麻町",
    "prefectureNameKatakana": "ﾐﾔｷﾞｹﾝ",
    "cityNameKatakana": "ｼｶﾏﾁｮｳ"
  },
  {
    "groupCode": "044458",
    "prefectureNameKanji": "宮城県",
    "cityNameKanji": "加美町",
    "prefectureNameKatakana": "ﾐﾔｷﾞｹﾝ",
    "cityNameKatakana": "ｶﾐﾏﾁ"
  },
  {
    "groupCode": "045012",
    "prefectureNameKanji": "宮城県",
    "cityNameKanji": "涌谷町",
    "prefectureNameKatakana": "ﾐﾔｷﾞｹﾝ",
    "cityNameKatakana": "ﾜｸﾔﾁｮｳ"
  },
  {
    "groupCode": "045055",
    "prefectureNameKanji": "宮城県",
    "cityNameKanji": "美里町",
    "prefectureNameKatakana": "ﾐﾔｷﾞｹﾝ",
    "cityNameKatakana": "ﾐｻﾄﾏﾁ"
  },
  {
    "groupCode": "045811",
    "prefectureNameKanji": "宮城県",
    "cityNameKanji": "女川町",
    "prefectureNameKatakana": "ﾐﾔｷﾞｹﾝ",
    "cityNameKatakana": "ｵﾅｶﾞﾜﾁｮｳ"
  },
  {
    "groupCode": "046060",
    "prefectureNameKanji": "宮城県",
    "cityNameKanji": "南三陸町",
    "prefectureNameKatakana": "ﾐﾔｷﾞｹﾝ",
    "cityNameKatakana": "ﾐﾅﾐｻﾝﾘｸﾁｮｳ"
  },
  {
    "groupCode": "052019",
    "prefectureNameKanji": "秋田県",
    "cityNameKanji": "秋田市",
    "prefectureNameKatakana": "ｱｷﾀｹﾝ",
    "cityNameKatakana": "ｱｷﾀｼ"
  },
  {
    "groupCode": "052027",
    "prefectureNameKanji": "秋田県",
    "cityNameKanji": "能代市",
    "prefectureNameKatakana": "ｱｷﾀｹﾝ",
    "cityNameKatakana": "ﾉｼﾛｼ"
  },
  {
    "groupCode": "052035",
    "prefectureNameKanji": "秋田県",
    "cityNameKanji": "横手市",
    "prefectureNameKatakana": "ｱｷﾀｹﾝ",
    "cityNameKatakana": "ﾖｺﾃｼ"
  },
  {
    "groupCode": "052043",
    "prefectureNameKanji": "秋田県",
    "cityNameKanji": "大館市",
    "prefectureNameKatakana": "ｱｷﾀｹﾝ",
    "cityNameKatakana": "ｵｵﾀﾞﾃｼ"
  },
  {
    "groupCode": "052060",
    "prefectureNameKanji": "秋田県",
    "cityNameKanji": "男鹿市",
    "prefectureNameKatakana": "ｱｷﾀｹﾝ",
    "cityNameKatakana": "ｵｶﾞｼ"
  },
  {
    "groupCode": "052078",
    "prefectureNameKanji": "秋田県",
    "cityNameKanji": "湯沢市",
    "prefectureNameKatakana": "ｱｷﾀｹﾝ",
    "cityNameKatakana": "ﾕｻﾞﾜｼ"
  },
  {
    "groupCode": "052094",
    "prefectureNameKanji": "秋田県",
    "cityNameKanji": "鹿角市",
    "prefectureNameKatakana": "ｱｷﾀｹﾝ",
    "cityNameKatakana": "ｶﾂﾞﾉｼ"
  },
  {
    "groupCode": "052108",
    "prefectureNameKanji": "秋田県",
    "cityNameKanji": "由利本荘市",
    "prefectureNameKatakana": "ｱｷﾀｹﾝ",
    "cityNameKatakana": "ﾕﾘﾎﾝｼﾞｮｳｼ"
  },
  {
    "groupCode": "052116",
    "prefectureNameKanji": "秋田県",
    "cityNameKanji": "潟上市",
    "prefectureNameKatakana": "ｱｷﾀｹﾝ",
    "cityNameKatakana": "ｶﾀｶﾞﾐｼ"
  },
  {
    "groupCode": "052124",
    "prefectureNameKanji": "秋田県",
    "cityNameKanji": "大仙市",
    "prefectureNameKatakana": "ｱｷﾀｹﾝ",
    "cityNameKatakana": "ﾀﾞｲｾﾝｼ"
  },
  {
    "groupCode": "052132",
    "prefectureNameKanji": "秋田県",
    "cityNameKanji": "北秋田市",
    "prefectureNameKatakana": "ｱｷﾀｹﾝ",
    "cityNameKatakana": "ｷﾀｱｷﾀｼ"
  },
  {
    "groupCode": "052141",
    "prefectureNameKanji": "秋田県",
    "cityNameKanji": "にかほ市",
    "prefectureNameKatakana": "ｱｷﾀｹﾝ",
    "cityNameKatakana": "ﾆｶﾎｼ"
  },
  {
    "groupCode": "052159",
    "prefectureNameKanji": "秋田県",
    "cityNameKanji": "仙北市",
    "prefectureNameKatakana": "ｱｷﾀｹﾝ",
    "cityNameKatakana": "ｾﾝﾎﾞｸｼ"
  },
  {
    "groupCode": "053031",
    "prefectureNameKanji": "秋田県",
    "cityNameKanji": "小坂町",
    "prefectureNameKatakana": "ｱｷﾀｹﾝ",
    "cityNameKatakana": "ｺｻｶﾏﾁ"
  },
  {
    "groupCode": "053279",
    "prefectureNameKanji": "秋田県",
    "cityNameKanji": "上小阿仁村",
    "prefectureNameKatakana": "ｱｷﾀｹﾝ",
    "cityNameKatakana": "ｶﾐｺｱﾆﾑﾗ"
  },
  {
    "groupCode": "053465",
    "prefectureNameKanji": "秋田県",
    "cityNameKanji": "藤里町",
    "prefectureNameKatakana": "ｱｷﾀｹﾝ",
    "cityNameKatakana": "ﾌｼﾞｻﾄﾏﾁ"
  },
  {
    "groupCode": "053481",
    "prefectureNameKanji": "秋田県",
    "cityNameKanji": "三種町",
    "prefectureNameKatakana": "ｱｷﾀｹﾝ",
    "cityNameKatakana": "ﾐﾀﾈﾁｮｳ"
  },
  {
    "groupCode": "053490",
    "prefectureNameKanji": "秋田県",
    "cityNameKanji": "八峰町",
    "prefectureNameKatakana": "ｱｷﾀｹﾝ",
    "cityNameKatakana": "ﾊｯﾎﾟｳﾁｮｳ"
  },
  {
    "groupCode": "053619",
    "prefectureNameKanji": "秋田県",
    "cityNameKanji": "五城目町",
    "prefectureNameKatakana": "ｱｷﾀｹﾝ",
    "cityNameKatakana": "ｺﾞｼﾞｮｳﾒﾏﾁ"
  },
  {
    "groupCode": "053635",
    "prefectureNameKanji": "秋田県",
    "cityNameKanji": "八郎潟町",
    "prefectureNameKatakana": "ｱｷﾀｹﾝ",
    "cityNameKatakana": "ﾊﾁﾛｳｶﾞﾀﾏﾁ"
  },
  {
    "groupCode": "053660",
    "prefectureNameKanji": "秋田県",
    "cityNameKanji": "井川町",
    "prefectureNameKatakana": "ｱｷﾀｹﾝ",
    "cityNameKatakana": "ｲｶﾜﾏﾁ"
  },
  {
    "groupCode": "053686",
    "prefectureNameKanji": "秋田県",
    "cityNameKanji": "大潟村",
    "prefectureNameKatakana": "ｱｷﾀｹﾝ",
    "cityNameKatakana": "ｵｵｶﾞﾀﾑﾗ"
  },
  {
    "groupCode": "054348",
    "prefectureNameKanji": "秋田県",
    "cityNameKanji": "美郷町",
    "prefectureNameKatakana": "ｱｷﾀｹﾝ",
    "cityNameKatakana": "ﾐｻﾄﾁｮｳ"
  },
  {
    "groupCode": "054631",
    "prefectureNameKanji": "秋田県",
    "cityNameKanji": "羽後町",
    "prefectureNameKatakana": "ｱｷﾀｹﾝ",
    "cityNameKatakana": "ｳｺﾞﾏﾁ"
  },
  {
    "groupCode": "054640",
    "prefectureNameKanji": "秋田県",
    "cityNameKanji": "東成瀬村",
    "prefectureNameKatakana": "ｱｷﾀｹﾝ",
    "cityNameKatakana": "ﾋｶﾞｼﾅﾙｾﾑﾗ"
  },
  {
    "groupCode": "062014",
    "prefectureNameKanji": "山形県",
    "cityNameKanji": "山形市",
    "prefectureNameKatakana": "ﾔﾏｶﾞﾀｹﾝ",
    "cityNameKatakana": "ﾔﾏｶﾞﾀｼ"
  },
  {
    "groupCode": "062022",
    "prefectureNameKanji": "山形県",
    "cityNameKanji": "米沢市",
    "prefectureNameKatakana": "ﾔﾏｶﾞﾀｹﾝ",
    "cityNameKatakana": "ﾖﾈｻﾞﾜｼ"
  },
  {
    "groupCode": "062031",
    "prefectureNameKanji": "山形県",
    "cityNameKanji": "鶴岡市",
    "prefectureNameKatakana": "ﾔﾏｶﾞﾀｹﾝ",
    "cityNameKatakana": "ﾂﾙｵｶｼ"
  },
  {
    "groupCode": "062049",
    "prefectureNameKanji": "山形県",
    "cityNameKanji": "酒田市",
    "prefectureNameKatakana": "ﾔﾏｶﾞﾀｹﾝ",
    "cityNameKatakana": "ｻｶﾀｼ"
  },
  {
    "groupCode": "062057",
    "prefectureNameKanji": "山形県",
    "cityNameKanji": "新庄市",
    "prefectureNameKatakana": "ﾔﾏｶﾞﾀｹﾝ",
    "cityNameKatakana": "ｼﾝｼﾞｮｳｼ"
  },
  {
    "groupCode": "062065",
    "prefectureNameKanji": "山形県",
    "cityNameKanji": "寒河江市",
    "prefectureNameKatakana": "ﾔﾏｶﾞﾀｹﾝ",
    "cityNameKatakana": "ｻｶﾞｴｼ"
  },
  {
    "groupCode": "062073",
    "prefectureNameKanji": "山形県",
    "cityNameKanji": "上山市",
    "prefectureNameKatakana": "ﾔﾏｶﾞﾀｹﾝ",
    "cityNameKatakana": "ｶﾐﾉﾔﾏｼ"
  },
  {
    "groupCode": "062081",
    "prefectureNameKanji": "山形県",
    "cityNameKanji": "村山市",
    "prefectureNameKatakana": "ﾔﾏｶﾞﾀｹﾝ",
    "cityNameKatakana": "ﾑﾗﾔﾏｼ"
  },
  {
    "groupCode": "062090",
    "prefectureNameKanji": "山形県",
    "cityNameKanji": "長井市",
    "prefectureNameKatakana": "ﾔﾏｶﾞﾀｹﾝ",
    "cityNameKatakana": "ﾅｶﾞｲｼ"
  },
  {
    "groupCode": "062103",
    "prefectureNameKanji": "山形県",
    "cityNameKanji": "天童市",
    "prefectureNameKatakana": "ﾔﾏｶﾞﾀｹﾝ",
    "cityNameKatakana": "ﾃﾝﾄﾞｳｼ"
  },
  {
    "groupCode": "062111",
    "prefectureNameKanji": "山形県",
    "cityNameKanji": "東根市",
    "prefectureNameKatakana": "ﾔﾏｶﾞﾀｹﾝ",
    "cityNameKatakana": "ﾋｶﾞｼﾈｼ"
  },
  {
    "groupCode": "062120",
    "prefectureNameKanji": "山形県",
    "cityNameKanji": "尾花沢市",
    "prefectureNameKatakana": "ﾔﾏｶﾞﾀｹﾝ",
    "cityNameKatakana": "ｵﾊﾞﾅｻﾞﾜｼ"
  },
  {
    "groupCode": "062138",
    "prefectureNameKanji": "山形県",
    "cityNameKanji": "南陽市",
    "prefectureNameKatakana": "ﾔﾏｶﾞﾀｹﾝ",
    "cityNameKatakana": "ﾅﾝﾖｳｼ"
  },
  {
    "groupCode": "063011",
    "prefectureNameKanji": "山形県",
    "cityNameKanji": "山辺町",
    "prefectureNameKatakana": "ﾔﾏｶﾞﾀｹﾝ",
    "cityNameKatakana": "ﾔﾏﾉﾍﾞﾏﾁ"
  },
  {
    "groupCode": "063029",
    "prefectureNameKanji": "山形県",
    "cityNameKanji": "中山町",
    "prefectureNameKatakana": "ﾔﾏｶﾞﾀｹﾝ",
    "cityNameKatakana": "ﾅｶﾔﾏﾏﾁ"
  },
  {
    "groupCode": "063215",
    "prefectureNameKanji": "山形県",
    "cityNameKanji": "河北町",
    "prefectureNameKatakana": "ﾔﾏｶﾞﾀｹﾝ",
    "cityNameKatakana": "ｶﾎｸﾁｮｳ"
  },
  {
    "groupCode": "063223",
    "prefectureNameKanji": "山形県",
    "cityNameKanji": "西川町",
    "prefectureNameKatakana": "ﾔﾏｶﾞﾀｹﾝ",
    "cityNameKatakana": "ﾆｼｶﾜﾏﾁ"
  },
  {
    "groupCode": "063231",
    "prefectureNameKanji": "山形県",
    "cityNameKanji": "朝日町",
    "prefectureNameKatakana": "ﾔﾏｶﾞﾀｹﾝ",
    "cityNameKatakana": "ｱｻﾋﾏﾁ"
  },
  {
    "groupCode": "063240",
    "prefectureNameKanji": "山形県",
    "cityNameKanji": "大江町",
    "prefectureNameKatakana": "ﾔﾏｶﾞﾀｹﾝ",
    "cityNameKatakana": "ｵｵｴﾏﾁ"
  },
  {
    "groupCode": "063410",
    "prefectureNameKanji": "山形県",
    "cityNameKanji": "大石田町",
    "prefectureNameKatakana": "ﾔﾏｶﾞﾀｹﾝ",
    "cityNameKatakana": "ｵｵｲｼﾀﾞﾏﾁ"
  },
  {
    "groupCode": "063614",
    "prefectureNameKanji": "山形県",
    "cityNameKanji": "金山町",
    "prefectureNameKatakana": "ﾔﾏｶﾞﾀｹﾝ",
    "cityNameKatakana": "ｶﾈﾔﾏﾏﾁ"
  },
  {
    "groupCode": "063622",
    "prefectureNameKanji": "山形県",
    "cityNameKanji": "最上町",
    "prefectureNameKatakana": "ﾔﾏｶﾞﾀｹﾝ",
    "cityNameKatakana": "ﾓｶﾞﾐﾏﾁ"
  },
  {
    "groupCode": "063631",
    "prefectureNameKanji": "山形県",
    "cityNameKanji": "舟形町",
    "prefectureNameKatakana": "ﾔﾏｶﾞﾀｹﾝ",
    "cityNameKatakana": "ﾌﾅｶﾞﾀﾏﾁ"
  },
  {
    "groupCode": "063649",
    "prefectureNameKanji": "山形県",
    "cityNameKanji": "真室川町",
    "prefectureNameKatakana": "ﾔﾏｶﾞﾀｹﾝ",
    "cityNameKatakana": "ﾏﾑﾛｶﾞﾜﾏﾁ"
  },
  {
    "groupCode": "063657",
    "prefectureNameKanji": "山形県",
    "cityNameKanji": "大蔵村",
    "prefectureNameKatakana": "ﾔﾏｶﾞﾀｹﾝ",
    "cityNameKatakana": "ｵｵｸﾗﾑﾗ"
  },
  {
    "groupCode": "063665",
    "prefectureNameKanji": "山形県",
    "cityNameKanji": "鮭川村",
    "prefectureNameKatakana": "ﾔﾏｶﾞﾀｹﾝ",
    "cityNameKatakana": "ｻｹｶﾞﾜﾑﾗ"
  },
  {
    "groupCode": "063673",
    "prefectureNameKanji": "山形県",
    "cityNameKanji": "戸沢村",
    "prefectureNameKatakana": "ﾔﾏｶﾞﾀｹﾝ",
    "cityNameKatakana": "ﾄｻﾞﾜﾑﾗ"
  },
  {
    "groupCode": "063819",
    "prefectureNameKanji": "山形県",
    "cityNameKanji": "高畠町",
    "prefectureNameKatakana": "ﾔﾏｶﾞﾀｹﾝ",
    "cityNameKatakana": "ﾀｶﾊﾀﾏﾁ"
  },
  {
    "groupCode": "063827",
    "prefectureNameKanji": "山形県",
    "cityNameKanji": "川西町",
    "prefectureNameKatakana": "ﾔﾏｶﾞﾀｹﾝ",
    "cityNameKatakana": "ｶﾜﾆｼﾏﾁ"
  },
  {
    "groupCode": "064017",
    "prefectureNameKanji": "山形県",
    "cityNameKanji": "小国町",
    "prefectureNameKatakana": "ﾔﾏｶﾞﾀｹﾝ",
    "cityNameKatakana": "ｵｸﾞﾆﾏﾁ"
  },
  {
    "groupCode": "064025",
    "prefectureNameKanji": "山形県",
    "cityNameKanji": "白鷹町",
    "prefectureNameKatakana": "ﾔﾏｶﾞﾀｹﾝ",
    "cityNameKatakana": "ｼﾗﾀｶﾏﾁ"
  },
  {
    "groupCode": "064033",
    "prefectureNameKanji": "山形県",
    "cityNameKanji": "飯豊町",
    "prefectureNameKatakana": "ﾔﾏｶﾞﾀｹﾝ",
    "cityNameKatakana": "ｲｲﾃﾞﾏﾁ"
  },
  {
    "groupCode": "064262",
    "prefectureNameKanji": "山形県",
    "cityNameKanji": "三川町",
    "prefectureNameKatakana": "ﾔﾏｶﾞﾀｹﾝ",
    "cityNameKatakana": "ﾐｶﾜﾏﾁ"
  },
  {
    "groupCode": "064289",
    "prefectureNameKanji": "山形県",
    "cityNameKanji": "庄内町",
    "prefectureNameKatakana": "ﾔﾏｶﾞﾀｹﾝ",
    "cityNameKatakana": "ｼﾖｳﾅｲﾏﾁ"
  },
  {
    "groupCode": "064611",
    "prefectureNameKanji": "山形県",
    "cityNameKanji": "遊佐町",
    "prefectureNameKatakana": "ﾔﾏｶﾞﾀｹﾝ",
    "cityNameKatakana": "ﾕｻﾞﾏﾁ"
  },
  {
    "groupCode": "072010",
    "prefectureNameKanji": "福島県",
    "cityNameKanji": "福島市",
    "prefectureNameKatakana": "ﾌｸｼﾏｹﾝ",
    "cityNameKatakana": "ﾌｸｼﾏｼ"
  },
  {
    "groupCode": "072028",
    "prefectureNameKanji": "福島県",
    "cityNameKanji": "会津若松市",
    "prefectureNameKatakana": "ﾌｸｼﾏｹﾝ",
    "cityNameKatakana": "ｱｲﾂﾞﾜｶﾏﾂｼ"
  },
  {
    "groupCode": "072036",
    "prefectureNameKanji": "福島県",
    "cityNameKanji": "郡山市",
    "prefectureNameKatakana": "ﾌｸｼﾏｹﾝ",
    "cityNameKatakana": "ｺｵﾘﾔﾏｼ"
  },
  {
    "groupCode": "072044",
    "prefectureNameKanji": "福島県",
    "cityNameKanji": "いわき市",
    "prefectureNameKatakana": "ﾌｸｼﾏｹﾝ",
    "cityNameKatakana": "ｲﾜｷｼ"
  },
  {
    "groupCode": "072052",
    "prefectureNameKanji": "福島県",
    "cityNameKanji": "白河市",
    "prefectureNameKatakana": "ﾌｸｼﾏｹﾝ",
    "cityNameKatakana": "ｼﾗｶﾜｼ"
  },
  {
    "groupCode": "072079",
    "prefectureNameKanji": "福島県",
    "cityNameKanji": "須賀川市",
    "prefectureNameKatakana": "ﾌｸｼﾏｹﾝ",
    "cityNameKatakana": "ｽｶｶﾞﾜｼ"
  },
  {
    "groupCode": "072087",
    "prefectureNameKanji": "福島県",
    "cityNameKanji": "喜多方市",
    "prefectureNameKatakana": "ﾌｸｼﾏｹﾝ",
    "cityNameKatakana": "ｷﾀｶﾀｼ"
  },
  {
    "groupCode": "072095",
    "prefectureNameKanji": "福島県",
    "cityNameKanji": "相馬市",
    "prefectureNameKatakana": "ﾌｸｼﾏｹﾝ",
    "cityNameKatakana": "ｿｳﾏｼ"
  },
  {
    "groupCode": "072109",
    "prefectureNameKanji": "福島県",
    "cityNameKanji": "二本松市",
    "prefectureNameKatakana": "ﾌｸｼﾏｹﾝ",
    "cityNameKatakana": "ﾆﾎﾝﾏﾂｼ"
  },
  {
    "groupCode": "072117",
    "prefectureNameKanji": "福島県",
    "cityNameKanji": "田村市",
    "prefectureNameKatakana": "ﾌｸｼﾏｹﾝ",
    "cityNameKatakana": "ﾀﾑﾗｼ"
  },
  {
    "groupCode": "072125",
    "prefectureNameKanji": "福島県",
    "cityNameKanji": "南相馬市",
    "prefectureNameKatakana": "ﾌｸｼﾏｹﾝ",
    "cityNameKatakana": "ﾐﾅﾐｿｳﾏｼ"
  },
  {
    "groupCode": "072133",
    "prefectureNameKanji": "福島県",
    "cityNameKanji": "伊達市",
    "prefectureNameKatakana": "ﾌｸｼﾏｹﾝ",
    "cityNameKatakana": "ﾀﾞﾃｼ"
  },
  {
    "groupCode": "072141",
    "prefectureNameKanji": "福島県",
    "cityNameKanji": "本宮市",
    "prefectureNameKatakana": "ﾌｸｼﾏｹﾝ",
    "cityNameKatakana": "ﾓﾄﾐﾔｼ"
  },
  {
    "groupCode": "073016",
    "prefectureNameKanji": "福島県",
    "cityNameKanji": "桑折町",
    "prefectureNameKatakana": "ﾌｸｼﾏｹﾝ",
    "cityNameKatakana": "ｺｵﾘﾏﾁ"
  },
  {
    "groupCode": "073032",
    "prefectureNameKanji": "福島県",
    "cityNameKanji": "国見町",
    "prefectureNameKatakana": "ﾌｸｼﾏｹﾝ",
    "cityNameKatakana": "ｸﾆﾐﾏﾁ"
  },
  {
    "groupCode": "073083",
    "prefectureNameKanji": "福島県",
    "cityNameKanji": "川俣町",
    "prefectureNameKatakana": "ﾌｸｼﾏｹﾝ",
    "cityNameKatakana": "ｶﾜﾏﾀﾏﾁ"
  },
  {
    "groupCode": "073229",
    "prefectureNameKanji": "福島県",
    "cityNameKanji": "大玉村",
    "prefectureNameKatakana": "ﾌｸｼﾏｹﾝ",
    "cityNameKatakana": "ｵｵﾀﾏﾑﾗ"
  },
  {
    "groupCode": "073423",
    "prefectureNameKanji": "福島県",
    "cityNameKanji": "鏡石町",
    "prefectureNameKatakana": "ﾌｸｼﾏｹﾝ",
    "cityNameKatakana": "ｶｶﾞﾐｲｼﾏﾁ"
  },
  {
    "groupCode": "073440",
    "prefectureNameKanji": "福島県",
    "cityNameKanji": "天栄村",
    "prefectureNameKatakana": "ﾌｸｼﾏｹﾝ",
    "cityNameKatakana": "ﾃﾝｴｲﾑﾗ"
  },
  {
    "groupCode": "073628",
    "prefectureNameKanji": "福島県",
    "cityNameKanji": "下郷町",
    "prefectureNameKatakana": "ﾌｸｼﾏｹﾝ",
    "cityNameKatakana": "ｼﾓｺﾞｳﾏﾁ"
  },
  {
    "groupCode": "073644",
    "prefectureNameKanji": "福島県",
    "cityNameKanji": "檜枝岐村",
    "prefectureNameKatakana": "ﾌｸｼﾏｹﾝ",
    "cityNameKatakana": "ﾋﾉｴﾏﾀﾑﾗ"
  },
  {
    "groupCode": "073679",
    "prefectureNameKanji": "福島県",
    "cityNameKanji": "只見町",
    "prefectureNameKatakana": "ﾌｸｼﾏｹﾝ",
    "cityNameKatakana": "ﾀﾀﾞﾐﾏﾁ"
  },
  {
    "groupCode": "073687",
    "prefectureNameKanji": "福島県",
    "cityNameKanji": "南会津町",
    "prefectureNameKatakana": "ﾌｸｼﾏｹﾝ",
    "cityNameKatakana": "ﾐﾅﾐｱｲﾂﾞﾏﾁ"
  },
  {
    "groupCode": "074021",
    "prefectureNameKanji": "福島県",
    "cityNameKanji": "北塩原村",
    "prefectureNameKatakana": "ﾌｸｼﾏｹﾝ",
    "cityNameKatakana": "ｷﾀｼｵﾊﾞﾗﾑﾗ"
  },
  {
    "groupCode": "074055",
    "prefectureNameKanji": "福島県",
    "cityNameKanji": "西会津町",
    "prefectureNameKatakana": "ﾌｸｼﾏｹﾝ",
    "cityNameKatakana": "ﾆｼｱｲﾂﾞﾏﾁ"
  },
  {
    "groupCode": "074071",
    "prefectureNameKanji": "福島県",
    "cityNameKanji": "磐梯町",
    "prefectureNameKatakana": "ﾌｸｼﾏｹﾝ",
    "cityNameKatakana": "ﾊﾞﾝﾀﾞｲﾏﾁ"
  },
  {
    "groupCode": "074080",
    "prefectureNameKanji": "福島県",
    "cityNameKanji": "猪苗代町",
    "prefectureNameKatakana": "ﾌｸｼﾏｹﾝ",
    "cityNameKatakana": "ｲﾅﾜｼﾛﾏﾁ"
  },
  {
    "groupCode": "074217",
    "prefectureNameKanji": "福島県",
    "cityNameKanji": "会津坂下町",
    "prefectureNameKatakana": "ﾌｸｼﾏｹﾝ",
    "cityNameKatakana": "ｱｲﾂﾞﾊﾞﾝｹﾞﾏﾁ"
  },
  {
    "groupCode": "074225",
    "prefectureNameKanji": "福島県",
    "cityNameKanji": "湯川村",
    "prefectureNameKatakana": "ﾌｸｼﾏｹﾝ",
    "cityNameKatakana": "ﾕｶﾞﾜﾑﾗ"
  },
  {
    "groupCode": "074233",
    "prefectureNameKanji": "福島県",
    "cityNameKanji": "柳津町",
    "prefectureNameKatakana": "ﾌｸｼﾏｹﾝ",
    "cityNameKatakana": "ﾔﾅｲﾂﾞﾏﾁ"
  },
  {
    "groupCode": "074446",
    "prefectureNameKanji": "福島県",
    "cityNameKanji": "三島町",
    "prefectureNameKatakana": "ﾌｸｼﾏｹﾝ",
    "cityNameKatakana": "ﾐｼﾏﾏﾁ"
  },
  {
    "groupCode": "074454",
    "prefectureNameKanji": "福島県",
    "cityNameKanji": "金山町",
    "prefectureNameKatakana": "ﾌｸｼﾏｹﾝ",
    "cityNameKatakana": "ｶﾈﾔﾏﾏﾁ"
  },
  {
    "groupCode": "074462",
    "prefectureNameKanji": "福島県",
    "cityNameKanji": "昭和村",
    "prefectureNameKatakana": "ﾌｸｼﾏｹﾝ",
    "cityNameKatakana": "ｼｮｳﾜﾑﾗ"
  },
  {
    "groupCode": "074471",
    "prefectureNameKanji": "福島県",
    "cityNameKanji": "会津美里町",
    "prefectureNameKatakana": "ﾌｸｼﾏｹﾝ",
    "cityNameKatakana": "ｱｲﾂﾞﾐｻﾄﾏﾁ"
  },
  {
    "groupCode": "074616",
    "prefectureNameKanji": "福島県",
    "cityNameKanji": "西郷村",
    "prefectureNameKatakana": "ﾌｸｼﾏｹﾝ",
    "cityNameKatakana": "ﾆｼｺﾞｳﾑﾗ"
  },
  {
    "groupCode": "074641",
    "prefectureNameKanji": "福島県",
    "cityNameKanji": "泉崎村",
    "prefectureNameKatakana": "ﾌｸｼﾏｹﾝ",
    "cityNameKatakana": "ｲｽﾞﾐｻﾞｷﾑﾗ"
  },
  {
    "groupCode": "074659",
    "prefectureNameKanji": "福島県",
    "cityNameKanji": "中島村",
    "prefectureNameKatakana": "ﾌｸｼﾏｹﾝ",
    "cityNameKatakana": "ﾅｶｼﾞﾏﾑﾗ"
  },
  {
    "groupCode": "074667",
    "prefectureNameKanji": "福島県",
    "cityNameKanji": "矢吹町",
    "prefectureNameKatakana": "ﾌｸｼﾏｹﾝ",
    "cityNameKatakana": "ﾔﾌﾞｷﾏﾁ"
  },
  {
    "groupCode": "074811",
    "prefectureNameKanji": "福島県",
    "cityNameKanji": "棚倉町",
    "prefectureNameKatakana": "ﾌｸｼﾏｹﾝ",
    "cityNameKatakana": "ﾀﾅｸﾞﾗﾏﾁ"
  },
  {
    "groupCode": "074829",
    "prefectureNameKanji": "福島県",
    "cityNameKanji": "矢祭町",
    "prefectureNameKatakana": "ﾌｸｼﾏｹﾝ",
    "cityNameKatakana": "ﾔﾏﾂﾘﾏﾁ"
  },
  {
    "groupCode": "074837",
    "prefectureNameKanji": "福島県",
    "cityNameKanji": "塙町",
    "prefectureNameKatakana": "ﾌｸｼﾏｹﾝ",
    "cityNameKatakana": "ﾊﾅﾜﾏﾁ"
  },
  {
    "groupCode": "074845",
    "prefectureNameKanji": "福島県",
    "cityNameKanji": "鮫川村",
    "prefectureNameKatakana": "ﾌｸｼﾏｹﾝ",
    "cityNameKatakana": "ｻﾒｶﾞﾜﾑﾗ"
  },
  {
    "groupCode": "075019",
    "prefectureNameKanji": "福島県",
    "cityNameKanji": "石川町",
    "prefectureNameKatakana": "ﾌｸｼﾏｹﾝ",
    "cityNameKatakana": "ｲｼｶﾜﾏﾁ"
  },
  {
    "groupCode": "075027",
    "prefectureNameKanji": "福島県",
    "cityNameKanji": "玉川村",
    "prefectureNameKatakana": "ﾌｸｼﾏｹﾝ",
    "cityNameKatakana": "ﾀﾏｶﾜﾑﾗ"
  },
  {
    "groupCode": "075035",
    "prefectureNameKanji": "福島県",
    "cityNameKanji": "平田村",
    "prefectureNameKatakana": "ﾌｸｼﾏｹﾝ",
    "cityNameKatakana": "ﾋﾗﾀﾑﾗ"
  },
  {
    "groupCode": "075043",
    "prefectureNameKanji": "福島県",
    "cityNameKanji": "浅川町",
    "prefectureNameKatakana": "ﾌｸｼﾏｹﾝ",
    "cityNameKatakana": "ｱｻｶﾜﾏﾁ"
  },
  {
    "groupCode": "075051",
    "prefectureNameKanji": "福島県",
    "cityNameKanji": "古殿町",
    "prefectureNameKatakana": "ﾌｸｼﾏｹﾝ",
    "cityNameKatakana": "ﾌﾙﾄﾞﾉﾏﾁ"
  },
  {
    "groupCode": "075213",
    "prefectureNameKanji": "福島県",
    "cityNameKanji": "三春町",
    "prefectureNameKatakana": "ﾌｸｼﾏｹﾝ",
    "cityNameKatakana": "ﾐﾊﾙﾏﾁ"
  },
  {
    "groupCode": "075221",
    "prefectureNameKanji": "福島県",
    "cityNameKanji": "小野町",
    "prefectureNameKatakana": "ﾌｸｼﾏｹﾝ",
    "cityNameKatakana": "ｵﾉﾏﾁ"
  },
  {
    "groupCode": "075418",
    "prefectureNameKanji": "福島県",
    "cityNameKanji": "広野町",
    "prefectureNameKatakana": "ﾌｸｼﾏｹﾝ",
    "cityNameKatakana": "ﾋﾛﾉﾏﾁ"
  },
  {
    "groupCode": "075426",
    "prefectureNameKanji": "福島県",
    "cityNameKanji": "楢葉町",
    "prefectureNameKatakana": "ﾌｸｼﾏｹﾝ",
    "cityNameKatakana": "ﾅﾗﾊﾏﾁ"
  },
  {
    "groupCode": "075434",
    "prefectureNameKanji": "福島県",
    "cityNameKanji": "富岡町",
    "prefectureNameKatakana": "ﾌｸｼﾏｹﾝ",
    "cityNameKatakana": "ﾄﾐｵｶﾏﾁ"
  },
  {
    "groupCode": "075442",
    "prefectureNameKanji": "福島県",
    "cityNameKanji": "川内村",
    "prefectureNameKatakana": "ﾌｸｼﾏｹﾝ",
    "cityNameKatakana": "ｶﾜｳﾁﾑﾗ"
  },
  {
    "groupCode": "075451",
    "prefectureNameKanji": "福島県",
    "cityNameKanji": "大熊町",
    "prefectureNameKatakana": "ﾌｸｼﾏｹﾝ",
    "cityNameKatakana": "ｵｵｸﾏﾏﾁ"
  },
  {
    "groupCode": "075469",
    "prefectureNameKanji": "福島県",
    "cityNameKanji": "双葉町",
    "prefectureNameKatakana": "ﾌｸｼﾏｹﾝ",
    "cityNameKatakana": "ﾌﾀﾊﾞﾏﾁ"
  },
  {
    "groupCode": "075477",
    "prefectureNameKanji": "福島県",
    "cityNameKanji": "浪江町",
    "prefectureNameKatakana": "ﾌｸｼﾏｹﾝ",
    "cityNameKatakana": "ﾅﾐｴﾏﾁ"
  },
  {
    "groupCode": "075485",
    "prefectureNameKanji": "福島県",
    "cityNameKanji": "葛尾村",
    "prefectureNameKatakana": "ﾌｸｼﾏｹﾝ",
    "cityNameKatakana": "ｶﾂﾗｵﾑﾗ"
  },
  {
    "groupCode": "075612",
    "prefectureNameKanji": "福島県",
    "cityNameKanji": "新地町",
    "prefectureNameKatakana": "ﾌｸｼﾏｹﾝ",
    "cityNameKatakana": "ｼﾝﾁﾏﾁ"
  },
  {
    "groupCode": "075647",
    "prefectureNameKanji": "福島県",
    "cityNameKanji": "飯舘村",
    "prefectureNameKatakana": "ﾌｸｼﾏｹﾝ",
    "cityNameKatakana": "ｲｲﾀﾃﾑﾗ"
  },
  {
    "groupCode": "082015",
    "prefectureNameKanji": "茨城県",
    "cityNameKanji": "水戸市",
    "prefectureNameKatakana": "ｲﾊﾞﾗｷｹﾝ",
    "cityNameKatakana": "ﾐﾄｼ"
  },
  {
    "groupCode": "082023",
    "prefectureNameKanji": "茨城県",
    "cityNameKanji": "日立市",
    "prefectureNameKatakana": "ｲﾊﾞﾗｷｹﾝ",
    "cityNameKatakana": "ﾋﾀﾁｼ"
  },
  {
    "groupCode": "082031",
    "prefectureNameKanji": "茨城県",
    "cityNameKanji": "土浦市",
    "prefectureNameKatakana": "ｲﾊﾞﾗｷｹﾝ",
    "cityNameKatakana": "ﾂﾁｳﾗｼ"
  },
  {
    "groupCode": "082040",
    "prefectureNameKanji": "茨城県",
    "cityNameKanji": "古河市",
    "prefectureNameKatakana": "ｲﾊﾞﾗｷｹﾝ",
    "cityNameKatakana": "ｺｶﾞｼ"
  },
  {
    "groupCode": "082058",
    "prefectureNameKanji": "茨城県",
    "cityNameKanji": "石岡市",
    "prefectureNameKatakana": "ｲﾊﾞﾗｷｹﾝ",
    "cityNameKatakana": "ｲｼｵｶｼ"
  },
  {
    "groupCode": "082074",
    "prefectureNameKanji": "茨城県",
    "cityNameKanji": "結城市",
    "prefectureNameKatakana": "ｲﾊﾞﾗｷｹﾝ",
    "cityNameKatakana": "ﾕｳｷｼ"
  },
  {
    "groupCode": "082082",
    "prefectureNameKanji": "茨城県",
    "cityNameKanji": "龍ケ崎市",
    "prefectureNameKatakana": "ｲﾊﾞﾗｷｹﾝ",
    "cityNameKatakana": "ﾘｭｳｶﾞｻｷｼ"
  },
  {
    "groupCode": "082104",
    "prefectureNameKanji": "茨城県",
    "cityNameKanji": "下妻市",
    "prefectureNameKatakana": "ｲﾊﾞﾗｷｹﾝ",
    "cityNameKatakana": "ｼﾓﾂﾏｼ"
  },
  {
    "groupCode": "082112",
    "prefectureNameKanji": "茨城県",
    "cityNameKanji": "常総市",
    "prefectureNameKatakana": "ｲﾊﾞﾗｷｹﾝ",
    "cityNameKatakana": "ｼﾞｮｳｿｳｼ"
  },
  {
    "groupCode": "082121",
    "prefectureNameKanji": "茨城県",
    "cityNameKanji": "常陸太田市",
    "prefectureNameKatakana": "ｲﾊﾞﾗｷｹﾝ",
    "cityNameKatakana": "ﾋﾀﾁｵｵﾀｼ"
  },
  {
    "groupCode": "082147",
    "prefectureNameKanji": "茨城県",
    "cityNameKanji": "高萩市",
    "prefectureNameKatakana": "ｲﾊﾞﾗｷｹﾝ",
    "cityNameKatakana": "ﾀｶﾊｷﾞｼ"
  },
  {
    "groupCode": "082155",
    "prefectureNameKanji": "茨城県",
    "cityNameKanji": "北茨城市",
    "prefectureNameKatakana": "ｲﾊﾞﾗｷｹﾝ",
    "cityNameKatakana": "ｷﾀｲﾊﾞﾗｷｼ"
  },
  {
    "groupCode": "082163",
    "prefectureNameKanji": "茨城県",
    "cityNameKanji": "笠間市",
    "prefectureNameKatakana": "ｲﾊﾞﾗｷｹﾝ",
    "cityNameKatakana": "ｶｻﾏｼ"
  },
  {
    "groupCode": "082171",
    "prefectureNameKanji": "茨城県",
    "cityNameKanji": "取手市",
    "prefectureNameKatakana": "ｲﾊﾞﾗｷｹﾝ",
    "cityNameKatakana": "ﾄﾘﾃﾞｼ"
  },
  {
    "groupCode": "082198",
    "prefectureNameKanji": "茨城県",
    "cityNameKanji": "牛久市",
    "prefectureNameKatakana": "ｲﾊﾞﾗｷｹﾝ",
    "cityNameKatakana": "ｳｼｸｼ"
  },
  {
    "groupCode": "082201",
    "prefectureNameKanji": "茨城県",
    "cityNameKanji": "つくば市",
    "prefectureNameKatakana": "ｲﾊﾞﾗｷｹﾝ",
    "cityNameKatakana": "ﾂｸﾊﾞｼ"
  },
  {
    "groupCode": "082210",
    "prefectureNameKanji": "茨城県",
    "cityNameKanji": "ひたちなか市",
    "prefectureNameKatakana": "ｲﾊﾞﾗｷｹﾝ",
    "cityNameKatakana": "ﾋﾀﾁﾅｶｼ"
  },
  {
    "groupCode": "082228",
    "prefectureNameKanji": "茨城県",
    "cityNameKanji": "鹿嶋市",
    "prefectureNameKatakana": "ｲﾊﾞﾗｷｹﾝ",
    "cityNameKatakana": "ｶｼﾏｼ"
  },
  {
    "groupCode": "082236",
    "prefectureNameKanji": "茨城県",
    "cityNameKanji": "潮来市",
    "prefectureNameKatakana": "ｲﾊﾞﾗｷｹﾝ",
    "cityNameKatakana": "ｲﾀｺｼ"
  },
  {
    "groupCode": "082244",
    "prefectureNameKanji": "茨城県",
    "cityNameKanji": "守谷市",
    "prefectureNameKatakana": "ｲﾊﾞﾗｷｹﾝ",
    "cityNameKatakana": "ﾓﾘﾔｼ"
  },
  {
    "groupCode": "082252",
    "prefectureNameKanji": "茨城県",
    "cityNameKanji": "常陸大宮市",
    "prefectureNameKatakana": "ｲﾊﾞﾗｷｹﾝ",
    "cityNameKatakana": "ﾋﾀﾁｵｵﾐﾔｼ"
  },
  {
    "groupCode": "082261",
    "prefectureNameKanji": "茨城県",
    "cityNameKanji": "那珂市",
    "prefectureNameKatakana": "ｲﾊﾞﾗｷｹﾝ",
    "cityNameKatakana": "ﾅｶｼ"
  },
  {
    "groupCode": "082279",
    "prefectureNameKanji": "茨城県",
    "cityNameKanji": "筑西市",
    "prefectureNameKatakana": "ｲﾊﾞﾗｷｹﾝ",
    "cityNameKatakana": "ﾁｸｾｲｼ"
  },
  {
    "groupCode": "082287",
    "prefectureNameKanji": "茨城県",
    "cityNameKanji": "坂東市",
    "prefectureNameKatakana": "ｲﾊﾞﾗｷｹﾝ",
    "cityNameKatakana": "ﾊﾞﾝﾄﾞｳｼ"
  },
  {
    "groupCode": "082295",
    "prefectureNameKanji": "茨城県",
    "cityNameKanji": "稲敷市",
    "prefectureNameKatakana": "ｲﾊﾞﾗｷｹﾝ",
    "cityNameKatakana": "ｲﾅｼｷｼ"
  },
  {
    "groupCode": "082309",
    "prefectureNameKanji": "茨城県",
    "cityNameKanji": "かすみがうら市",
    "prefectureNameKatakana": "ｲﾊﾞﾗｷｹﾝ",
    "cityNameKatakana": "ｶｽﾐｶﾞｳﾗｼ"
  },
  {
    "groupCode": "082317",
    "prefectureNameKanji": "茨城県",
    "cityNameKanji": "桜川市",
    "prefectureNameKatakana": "ｲﾊﾞﾗｷｹﾝ",
    "cityNameKatakana": "ｻｸﾗｶﾞﾜｼ"
  },
  {
    "groupCode": "082325",
    "prefectureNameKanji": "茨城県",
    "cityNameKanji": "神栖市",
    "prefectureNameKatakana": "ｲﾊﾞﾗｷｹﾝ",
    "cityNameKatakana": "ｶﾐｽｼ"
  },
  {
    "groupCode": "082333",
    "prefectureNameKanji": "茨城県",
    "cityNameKanji": "行方市",
    "prefectureNameKatakana": "ｲﾊﾞﾗｷｹﾝ",
    "cityNameKatakana": "ﾅﾒｶﾞﾀｼ"
  },
  {
    "groupCode": "082341",
    "prefectureNameKanji": "茨城県",
    "cityNameKanji": "鉾田市",
    "prefectureNameKatakana": "ｲﾊﾞﾗｷｹﾝ",
    "cityNameKatakana": "ﾎｺﾀｼ"
  },
  {
    "groupCode": "082350",
    "prefectureNameKanji": "茨城県",
    "cityNameKanji": "つくばみらい市",
    "prefectureNameKatakana": "ｲﾊﾞﾗｷｹﾝ",
    "cityNameKatakana": "ﾂｸﾊﾞﾐﾗｲｼ"
  },
  {
    "groupCode": "082368",
    "prefectureNameKanji": "茨城県",
    "cityNameKanji": "小美玉市",
    "prefectureNameKatakana": "ｲﾊﾞﾗｷｹﾝ",
    "cityNameKatakana": "ｵﾐﾀﾏｼ"
  },
  {
    "groupCode": "083020",
    "prefectureNameKanji": "茨城県",
    "cityNameKanji": "茨城町",
    "prefectureNameKatakana": "ｲﾊﾞﾗｷｹﾝ",
    "cityNameKatakana": "ｲﾊﾞﾗｷﾏﾁ"
  },
  {
    "groupCode": "083097",
    "prefectureNameKanji": "茨城県",
    "cityNameKanji": "大洗町",
    "prefectureNameKatakana": "ｲﾊﾞﾗｷｹﾝ",
    "cityNameKatakana": "ｵｵｱﾗｲﾏﾁ"
  },
  {
    "groupCode": "083101",
    "prefectureNameKanji": "茨城県",
    "cityNameKanji": "城里町",
    "prefectureNameKatakana": "ｲﾊﾞﾗｷｹﾝ",
    "cityNameKatakana": "ｼﾛｻﾄﾏﾁ"
  },
  {
    "groupCode": "083411",
    "prefectureNameKanji": "茨城県",
    "cityNameKanji": "東海村",
    "prefectureNameKatakana": "ｲﾊﾞﾗｷｹﾝ",
    "cityNameKatakana": "ﾄｳｶｲﾑﾗ"
  },
  {
    "groupCode": "083640",
    "prefectureNameKanji": "茨城県",
    "cityNameKanji": "大子町",
    "prefectureNameKatakana": "ｲﾊﾞﾗｷｹﾝ",
    "cityNameKatakana": "ﾀﾞｲｺﾞﾏﾁ"
  },
  {
    "groupCode": "084425",
    "prefectureNameKanji": "茨城県",
    "cityNameKanji": "美浦村",
    "prefectureNameKatakana": "ｲﾊﾞﾗｷｹﾝ",
    "cityNameKatakana": "ﾐﾎﾑﾗ"
  },
  {
    "groupCode": "084433",
    "prefectureNameKanji": "茨城県",
    "cityNameKanji": "阿見町",
    "prefectureNameKatakana": "ｲﾊﾞﾗｷｹﾝ",
    "cityNameKatakana": "ｱﾐﾏﾁ"
  },
  {
    "groupCode": "084476",
    "prefectureNameKanji": "茨城県",
    "cityNameKanji": "河内町",
    "prefectureNameKatakana": "ｲﾊﾞﾗｷｹﾝ",
    "cityNameKatakana": "ｶﾜﾁﾏﾁ"
  },
  {
    "groupCode": "085219",
    "prefectureNameKanji": "茨城県",
    "cityNameKanji": "八千代町",
    "prefectureNameKatakana": "ｲﾊﾞﾗｷｹﾝ",
    "cityNameKatakana": "ﾔﾁﾖﾏﾁ"
  },
  {
    "groupCode": "085421",
    "prefectureNameKanji": "茨城県",
    "cityNameKanji": "五霞町",
    "prefectureNameKatakana": "ｲﾊﾞﾗｷｹﾝ",
    "cityNameKatakana": "ｺﾞｶﾏﾁ"
  },
  {
    "groupCode": "085464",
    "prefectureNameKanji": "茨城県",
    "cityNameKanji": "境町",
    "prefectureNameKatakana": "ｲﾊﾞﾗｷｹﾝ",
    "cityNameKatakana": "ｻｶｲﾏﾁ"
  },
  {
    "groupCode": "085642",
    "prefectureNameKanji": "茨城県",
    "cityNameKanji": "利根町",
    "prefectureNameKatakana": "ｲﾊﾞﾗｷｹﾝ",
    "cityNameKatakana": "ﾄﾈﾏﾁ"
  },
  {
    "groupCode": "092011",
    "prefectureNameKanji": "栃木県",
    "cityNameKanji": "宇都宮市",
    "prefectureNameKatakana": "ﾄﾁｷﾞｹﾝ",
    "cityNameKatakana": "ｳﾂﾉﾐﾔｼ"
  },
  {
    "groupCode": "092029",
    "prefectureNameKanji": "栃木県",
    "cityNameKanji": "足利市",
    "prefectureNameKatakana": "ﾄﾁｷﾞｹﾝ",
    "cityNameKatakana": "ｱｼｶｶﾞｼ"
  },
  {
    "groupCode": "092037",
    "prefectureNameKanji": "栃木県",
    "cityNameKanji": "栃木市",
    "prefectureNameKatakana": "ﾄﾁｷﾞｹﾝ",
    "cityNameKatakana": "ﾄﾁｷﾞｼ"
  },
  {
    "groupCode": "092045",
    "prefectureNameKanji": "栃木県",
    "cityNameKanji": "佐野市",
    "prefectureNameKatakana": "ﾄﾁｷﾞｹﾝ",
    "cityNameKatakana": "ｻﾉｼ"
  },
  {
    "groupCode": "092053",
    "prefectureNameKanji": "栃木県",
    "cityNameKanji": "鹿沼市",
    "prefectureNameKatakana": "ﾄﾁｷﾞｹﾝ",
    "cityNameKatakana": "ｶﾇﾏｼ"
  },
  {
    "groupCode": "092061",
    "prefectureNameKanji": "栃木県",
    "cityNameKanji": "日光市",
    "prefectureNameKatakana": "ﾄﾁｷﾞｹﾝ",
    "cityNameKatakana": "ﾆｯｺｳｼ"
  },
  {
    "groupCode": "092088",
    "prefectureNameKanji": "栃木県",
    "cityNameKanji": "小山市",
    "prefectureNameKatakana": "ﾄﾁｷﾞｹﾝ",
    "cityNameKatakana": "ｵﾔﾏｼ"
  },
  {
    "groupCode": "092096",
    "prefectureNameKanji": "栃木県",
    "cityNameKanji": "真岡市",
    "prefectureNameKatakana": "ﾄﾁｷﾞｹﾝ",
    "cityNameKatakana": "ﾓｵｶｼ"
  },
  {
    "groupCode": "092100",
    "prefectureNameKanji": "栃木県",
    "cityNameKanji": "大田原市",
    "prefectureNameKatakana": "ﾄﾁｷﾞｹﾝ",
    "cityNameKatakana": "ｵｵﾀﾜﾗｼ"
  },
  {
    "groupCode": "092118",
    "prefectureNameKanji": "栃木県",
    "cityNameKanji": "矢板市",
    "prefectureNameKatakana": "ﾄﾁｷﾞｹﾝ",
    "cityNameKatakana": "ﾔｲﾀｼ"
  },
  {
    "groupCode": "092134",
    "prefectureNameKanji": "栃木県",
    "cityNameKanji": "那須塩原市",
    "prefectureNameKatakana": "ﾄﾁｷﾞｹﾝ",
    "cityNameKatakana": "ﾅｽｼｵﾊﾞﾗｼ"
  },
  {
    "groupCode": "092142",
    "prefectureNameKanji": "栃木県",
    "cityNameKanji": "さくら市",
    "prefectureNameKatakana": "ﾄﾁｷﾞｹﾝ",
    "cityNameKatakana": "ｻｸﾗｼ"
  },
  {
    "groupCode": "092151",
    "prefectureNameKanji": "栃木県",
    "cityNameKanji": "那須烏山市",
    "prefectureNameKatakana": "ﾄﾁｷﾞｹﾝ",
    "cityNameKatakana": "ﾅｽｶﾗｽﾔﾏｼ"
  },
  {
    "groupCode": "092169",
    "prefectureNameKanji": "栃木県",
    "cityNameKanji": "下野市",
    "prefectureNameKatakana": "ﾄﾁｷﾞｹﾝ",
    "cityNameKatakana": "ｼﾓﾂｹｼ"
  },
  {
    "groupCode": "093017",
    "prefectureNameKanji": "栃木県",
    "cityNameKanji": "上三川町",
    "prefectureNameKatakana": "ﾄﾁｷﾞｹﾝ",
    "cityNameKatakana": "ｶﾐﾉｶﾜﾏﾁ"
  },
  {
    "groupCode": "093424",
    "prefectureNameKanji": "栃木県",
    "cityNameKanji": "益子町",
    "prefectureNameKatakana": "ﾄﾁｷﾞｹﾝ",
    "cityNameKatakana": "ﾏｼｺﾏﾁ"
  },
  {
    "groupCode": "093432",
    "prefectureNameKanji": "栃木県",
    "cityNameKanji": "茂木町",
    "prefectureNameKatakana": "ﾄﾁｷﾞｹﾝ",
    "cityNameKatakana": "ﾓﾃｷﾞﾏﾁ"
  },
  {
    "groupCode": "093441",
    "prefectureNameKanji": "栃木県",
    "cityNameKanji": "市貝町",
    "prefectureNameKatakana": "ﾄﾁｷﾞｹﾝ",
    "cityNameKatakana": "ｲﾁｶｲﾏﾁ"
  },
  {
    "groupCode": "093459",
    "prefectureNameKanji": "栃木県",
    "cityNameKanji": "芳賀町",
    "prefectureNameKatakana": "ﾄﾁｷﾞｹﾝ",
    "cityNameKatakana": "ﾊｶﾞﾏﾁ"
  },
  {
    "groupCode": "093611",
    "prefectureNameKanji": "栃木県",
    "cityNameKanji": "壬生町",
    "prefectureNameKatakana": "ﾄﾁｷﾞｹﾝ",
    "cityNameKatakana": "ﾐﾌﾞﾏﾁ"
  },
  {
    "groupCode": "093645",
    "prefectureNameKanji": "栃木県",
    "cityNameKanji": "野木町",
    "prefectureNameKatakana": "ﾄﾁｷﾞｹﾝ",
    "cityNameKatakana": "ﾉｷﾞﾏﾁ"
  },
  {
    "groupCode": "093840",
    "prefectureNameKanji": "栃木県",
    "cityNameKanji": "塩谷町",
    "prefectureNameKatakana": "ﾄﾁｷﾞｹﾝ",
    "cityNameKatakana": "ｼｵﾔﾏﾁ"
  },
  {
    "groupCode": "093866",
    "prefectureNameKanji": "栃木県",
    "cityNameKanji": "高根沢町",
    "prefectureNameKatakana": "ﾄﾁｷﾞｹﾝ",
    "cityNameKatakana": "ﾀｶﾈｻﾞﾜﾏﾁ"
  },
  {
    "groupCode": "094072",
    "prefectureNameKanji": "栃木県",
    "cityNameKanji": "那須町",
    "prefectureNameKatakana": "ﾄﾁｷﾞｹﾝ",
    "cityNameKatakana": "ﾅｽﾏﾁ"
  },
  {
    "groupCode": "094111",
    "prefectureNameKanji": "栃木県",
    "cityNameKanji": "那珂川町",
    "prefectureNameKatakana": "ﾄﾁｷﾞｹﾝ",
    "cityNameKatakana": "ﾅｶｶﾞﾜﾏﾁ"
  },
  {
    "groupCode": "102016",
    "prefectureNameKanji": "群馬県",
    "cityNameKanji": "前橋市",
    "prefectureNameKatakana": "ｸﾞﾝﾏｹﾝ",
    "cityNameKatakana": "ﾏｴﾊﾞｼｼ"
  },
  {
    "groupCode": "102024",
    "prefectureNameKanji": "群馬県",
    "cityNameKanji": "高崎市",
    "prefectureNameKatakana": "ｸﾞﾝﾏｹﾝ",
    "cityNameKatakana": "ﾀｶｻｷｼ"
  },
  {
    "groupCode": "102032",
    "prefectureNameKanji": "群馬県",
    "cityNameKanji": "桐生市",
    "prefectureNameKatakana": "ｸﾞﾝﾏｹﾝ",
    "cityNameKatakana": "ｷﾘｭｳｼ"
  },
  {
    "groupCode": "102041",
    "prefectureNameKanji": "群馬県",
    "cityNameKanji": "伊勢崎市",
    "prefectureNameKatakana": "ｸﾞﾝﾏｹﾝ",
    "cityNameKatakana": "ｲｾｻｷｼ"
  },
  {
    "groupCode": "102059",
    "prefectureNameKanji": "群馬県",
    "cityNameKanji": "太田市",
    "prefectureNameKatakana": "ｸﾞﾝﾏｹﾝ",
    "cityNameKatakana": "ｵｵﾀｼ"
  },
  {
    "groupCode": "102067",
    "prefectureNameKanji": "群馬県",
    "cityNameKanji": "沼田市",
    "prefectureNameKatakana": "ｸﾞﾝﾏｹﾝ",
    "cityNameKatakana": "ﾇﾏﾀｼ"
  },
  {
    "groupCode": "102075",
    "prefectureNameKanji": "群馬県",
    "cityNameKanji": "館林市",
    "prefectureNameKatakana": "ｸﾞﾝﾏｹﾝ",
    "cityNameKatakana": "ﾀﾃﾊﾞﾔｼｼ"
  },
  {
    "groupCode": "102083",
    "prefectureNameKanji": "群馬県",
    "cityNameKanji": "渋川市",
    "prefectureNameKatakana": "ｸﾞﾝﾏｹﾝ",
    "cityNameKatakana": "ｼﾌﾞｶﾜｼ"
  },
  {
    "groupCode": "102091",
    "prefectureNameKanji": "群馬県",
    "cityNameKanji": "藤岡市",
    "prefectureNameKatakana": "ｸﾞﾝﾏｹﾝ",
    "cityNameKatakana": "ﾌｼﾞｵｶｼ"
  },
  {
    "groupCode": "102105",
    "prefectureNameKanji": "群馬県",
    "cityNameKanji": "富岡市",
    "prefectureNameKatakana": "ｸﾞﾝﾏｹﾝ",
    "cityNameKatakana": "ﾄﾐｵｶｼ"
  },
  {
    "groupCode": "102113",
    "prefectureNameKanji": "群馬県",
    "cityNameKanji": "安中市",
    "prefectureNameKatakana": "ｸﾞﾝﾏｹﾝ",
    "cityNameKatakana": "ｱﾝﾅｶｼ"
  },
  {
    "groupCode": "102121",
    "prefectureNameKanji": "群馬県",
    "cityNameKanji": "みどり市",
    "prefectureNameKatakana": "ｸﾞﾝﾏｹﾝ",
    "cityNameKatakana": "ﾐﾄﾞﾘｼ"
  },
  {
    "groupCode": "103446",
    "prefectureNameKanji": "群馬県",
    "cityNameKanji": "榛東村",
    "prefectureNameKatakana": "ｸﾞﾝﾏｹﾝ",
    "cityNameKatakana": "ｼﾝﾄｳﾑﾗ"
  },
  {
    "groupCode": "103454",
    "prefectureNameKanji": "群馬県",
    "cityNameKanji": "吉岡町",
    "prefectureNameKatakana": "ｸﾞﾝﾏｹﾝ",
    "cityNameKatakana": "ﾖｼｵｶﾏﾁ"
  },
  {
    "groupCode": "103667",
    "prefectureNameKanji": "群馬県",
    "cityNameKanji": "上野村",
    "prefectureNameKatakana": "ｸﾞﾝﾏｹﾝ",
    "cityNameKatakana": "ｳｴﾉﾑﾗ"
  },
  {
    "groupCode": "103675",
    "prefectureNameKanji": "群馬県",
    "cityNameKanji": "神流町",
    "prefectureNameKatakana": "ｸﾞﾝﾏｹﾝ",
    "cityNameKatakana": "ｶﾝﾅﾏﾁ"
  },
  {
    "groupCode": "103829",
    "prefectureNameKanji": "群馬県",
    "cityNameKanji": "下仁田町",
    "prefectureNameKatakana": "ｸﾞﾝﾏｹﾝ",
    "cityNameKatakana": "ｼﾓﾆﾀﾏﾁ"
  },
  {
    "groupCode": "103837",
    "prefectureNameKanji": "群馬県",
    "cityNameKanji": "南牧村",
    "prefectureNameKatakana": "ｸﾞﾝﾏｹﾝ",
    "cityNameKatakana": "ﾅﾝﾓｸﾑﾗ"
  },
  {
    "groupCode": "103845",
    "prefectureNameKanji": "群馬県",
    "cityNameKanji": "甘楽町",
    "prefectureNameKatakana": "ｸﾞﾝﾏｹﾝ",
    "cityNameKatakana": "ｶﾝﾗﾏﾁ"
  },
  {
    "groupCode": "104213",
    "prefectureNameKanji": "群馬県",
    "cityNameKanji": "中之条町",
    "prefectureNameKatakana": "ｸﾞﾝﾏｹﾝ",
    "cityNameKatakana": "ﾅｶﾉｼﾞﾖｳﾏﾁ"
  },
  {
    "groupCode": "104248",
    "prefectureNameKanji": "群馬県",
    "cityNameKanji": "長野原町",
    "prefectureNameKatakana": "ｸﾞﾝﾏｹﾝ",
    "cityNameKatakana": "ﾅｶﾞﾉﾊﾗﾏﾁ"
  },
  {
    "groupCode": "104256",
    "prefectureNameKanji": "群馬県",
    "cityNameKanji": "嬬恋村",
    "prefectureNameKatakana": "ｸﾞﾝﾏｹﾝ",
    "cityNameKatakana": "ﾂﾏｺﾞｲﾑﾗ"
  },
  {
    "groupCode": "104264",
    "prefectureNameKanji": "群馬県",
    "cityNameKanji": "草津町",
    "prefectureNameKatakana": "ｸﾞﾝﾏｹﾝ",
    "cityNameKatakana": "ｸｻﾂﾏﾁ"
  },
  {
    "groupCode": "104281",
    "prefectureNameKanji": "群馬県",
    "cityNameKanji": "高山村",
    "prefectureNameKatakana": "ｸﾞﾝﾏｹﾝ",
    "cityNameKatakana": "ﾀｶﾔﾏﾑﾗ"
  },
  {
    "groupCode": "104299",
    "prefectureNameKanji": "群馬県",
    "cityNameKanji": "東吾妻町",
    "prefectureNameKatakana": "ｸﾞﾝﾏｹﾝ",
    "cityNameKatakana": "ﾋｶﾞｼｱｶﾞﾂﾏﾏﾁ"
  },
  {
    "groupCode": "104434",
    "prefectureNameKanji": "群馬県",
    "cityNameKanji": "片品村",
    "prefectureNameKatakana": "ｸﾞﾝﾏｹﾝ",
    "cityNameKatakana": "ｶﾀｼﾅﾑﾗ"
  },
  {
    "groupCode": "104442",
    "prefectureNameKanji": "群馬県",
    "cityNameKanji": "川場村",
    "prefectureNameKatakana": "ｸﾞﾝﾏｹﾝ",
    "cityNameKatakana": "ｶﾜﾊﾞﾑﾗ"
  },
  {
    "groupCode": "104485",
    "prefectureNameKanji": "群馬県",
    "cityNameKanji": "昭和村",
    "prefectureNameKatakana": "ｸﾞﾝﾏｹﾝ",
    "cityNameKatakana": "ｼｮｳﾜﾑﾗ"
  },
  {
    "groupCode": "104493",
    "prefectureNameKanji": "群馬県",
    "cityNameKanji": "みなかみ町",
    "prefectureNameKatakana": "ｸﾞﾝﾏｹﾝ",
    "cityNameKatakana": "ﾐﾅｶﾐﾏﾁ"
  },
  {
    "groupCode": "104647",
    "prefectureNameKanji": "群馬県",
    "cityNameKanji": "玉村町",
    "prefectureNameKatakana": "ｸﾞﾝﾏｹﾝ",
    "cityNameKatakana": "ﾀﾏﾑﾗﾏﾁ"
  },
  {
    "groupCode": "105210",
    "prefectureNameKanji": "群馬県",
    "cityNameKanji": "板倉町",
    "prefectureNameKatakana": "ｸﾞﾝﾏｹﾝ",
    "cityNameKatakana": "ｲﾀｸﾗﾏﾁ"
  },
  {
    "groupCode": "105228",
    "prefectureNameKanji": "群馬県",
    "cityNameKanji": "明和町",
    "prefectureNameKatakana": "ｸﾞﾝﾏｹﾝ",
    "cityNameKatakana": "ﾒｲﾜﾏﾁ"
  },
  {
    "groupCode": "105236",
    "prefectureNameKanji": "群馬県",
    "cityNameKanji": "千代田町",
    "prefectureNameKatakana": "ｸﾞﾝﾏｹﾝ",
    "cityNameKatakana": "ﾁﾖﾀﾞﾏﾁ"
  },
  {
    "groupCode": "105244",
    "prefectureNameKanji": "群馬県",
    "cityNameKanji": "大泉町",
    "prefectureNameKatakana": "ｸﾞﾝﾏｹﾝ",
    "cityNameKatakana": "ｵｵｲｽﾞﾐﾏﾁ"
  },
  {
    "groupCode": "105252",
    "prefectureNameKanji": "群馬県",
    "cityNameKanji": "邑楽町",
    "prefectureNameKatakana": "ｸﾞﾝﾏｹﾝ",
    "cityNameKatakana": "ｵｳﾗﾏﾁ"
  },
  {
    "groupCode": "111007",
    "prefectureNameKanji": "埼玉県",
    "cityNameKanji": "さいたま市",
    "prefectureNameKatakana": "ｻｲﾀﾏｹﾝ",
    "cityNameKatakana": "ｻｲﾀﾏｼ"
  },
  {
    "groupCode": "112011",
    "prefectureNameKanji": "埼玉県",
    "cityNameKanji": "川越市",
    "prefectureNameKatakana": "ｻｲﾀﾏｹﾝ",
    "cityNameKatakana": "ｶﾜｺﾞｴｼ"
  },
  {
    "groupCode": "112020",
    "prefectureNameKanji": "埼玉県",
    "cityNameKanji": "熊谷市",
    "prefectureNameKatakana": "ｻｲﾀﾏｹﾝ",
    "cityNameKatakana": "ｸﾏｶﾞﾔｼ"
  },
  {
    "groupCode": "112038",
    "prefectureNameKanji": "埼玉県",
    "cityNameKanji": "川口市",
    "prefectureNameKatakana": "ｻｲﾀﾏｹﾝ",
    "cityNameKatakana": "ｶﾜｸﾞﾁｼ"
  },
  {
    "groupCode": "112062",
    "prefectureNameKanji": "埼玉県",
    "cityNameKanji": "行田市",
    "prefectureNameKatakana": "ｻｲﾀﾏｹﾝ",
    "cityNameKatakana": "ｷﾞﾖｳﾀﾞｼ"
  },
  {
    "groupCode": "112071",
    "prefectureNameKanji": "埼玉県",
    "cityNameKanji": "秩父市",
    "prefectureNameKatakana": "ｻｲﾀﾏｹﾝ",
    "cityNameKatakana": "ﾁﾁﾌﾞｼ"
  },
  {
    "groupCode": "112089",
    "prefectureNameKanji": "埼玉県",
    "cityNameKanji": "所沢市",
    "prefectureNameKatakana": "ｻｲﾀﾏｹﾝ",
    "cityNameKatakana": "ﾄｺﾛｻﾞﾜｼ"
  },
  {
    "groupCode": "112097",
    "prefectureNameKanji": "埼玉県",
    "cityNameKanji": "飯能市",
    "prefectureNameKatakana": "ｻｲﾀﾏｹﾝ",
    "cityNameKatakana": "ﾊﾝﾉｳｼ"
  },
  {
    "groupCode": "112101",
    "prefectureNameKanji": "埼玉県",
    "cityNameKanji": "加須市",
    "prefectureNameKatakana": "ｻｲﾀﾏｹﾝ",
    "cityNameKatakana": "ｶｿﾞｼ"
  },
  {
    "groupCode": "112119",
    "prefectureNameKanji": "埼玉県",
    "cityNameKanji": "本庄市",
    "prefectureNameKatakana": "ｻｲﾀﾏｹﾝ",
    "cityNameKatakana": "ﾎﾝｼﾞﾖｳｼ"
  },
  {
    "groupCode": "112127",
    "prefectureNameKanji": "埼玉県",
    "cityNameKanji": "東松山市",
    "prefectureNameKatakana": "ｻｲﾀﾏｹﾝ",
    "cityNameKatakana": "ﾋｶﾞｼﾏﾂﾔﾏｼ"
  },
  {
    "groupCode": "112143",
    "prefectureNameKanji": "埼玉県",
    "cityNameKanji": "春日部市",
    "prefectureNameKatakana": "ｻｲﾀﾏｹﾝ",
    "cityNameKatakana": "ｶｽｶﾍﾞｼ"
  },
  {
    "groupCode": "112151",
    "prefectureNameKanji": "埼玉県",
    "cityNameKanji": "狭山市",
    "prefectureNameKatakana": "ｻｲﾀﾏｹﾝ",
    "cityNameKatakana": "ｻﾔﾏｼ"
  },
  {
    "groupCode": "112160",
    "prefectureNameKanji": "埼玉県",
    "cityNameKanji": "羽生市",
    "prefectureNameKatakana": "ｻｲﾀﾏｹﾝ",
    "cityNameKatakana": "ﾊﾆﾕｳｼ"
  },
  {
    "groupCode": "112178",
    "prefectureNameKanji": "埼玉県",
    "cityNameKanji": "鴻巣市",
    "prefectureNameKatakana": "ｻｲﾀﾏｹﾝ",
    "cityNameKatakana": "ｺｳﾉｽｼ"
  },
  {
    "groupCode": "112186",
    "prefectureNameKanji": "埼玉県",
    "cityNameKanji": "深谷市",
    "prefectureNameKatakana": "ｻｲﾀﾏｹﾝ",
    "cityNameKatakana": "ﾌｶﾔｼ"
  },
  {
    "groupCode": "112194",
    "prefectureNameKanji": "埼玉県",
    "cityNameKanji": "上尾市",
    "prefectureNameKatakana": "ｻｲﾀﾏｹﾝ",
    "cityNameKatakana": "ｱｹﾞｵｼ"
  },
  {
    "groupCode": "112216",
    "prefectureNameKanji": "埼玉県",
    "cityNameKanji": "草加市",
    "prefectureNameKatakana": "ｻｲﾀﾏｹﾝ",
    "cityNameKatakana": "ｿｳｶｼ"
  },
  {
    "groupCode": "112224",
    "prefectureNameKanji": "埼玉県",
    "cityNameKanji": "越谷市",
    "prefectureNameKatakana": "ｻｲﾀﾏｹﾝ",
    "cityNameKatakana": "ｺｼｶﾞﾔｼ"
  },
  {
    "groupCode": "112232",
    "prefectureNameKanji": "埼玉県",
    "cityNameKanji": "蕨市",
    "prefectureNameKatakana": "ｻｲﾀﾏｹﾝ",
    "cityNameKatakana": "ﾜﾗﾋﾞｼ"
  },
  {
    "groupCode": "112241",
    "prefectureNameKanji": "埼玉県",
    "cityNameKanji": "戸田市",
    "prefectureNameKatakana": "ｻｲﾀﾏｹﾝ",
    "cityNameKatakana": "ﾄﾀﾞｼ"
  },
  {
    "groupCode": "112259",
    "prefectureNameKanji": "埼玉県",
    "cityNameKanji": "入間市",
    "prefectureNameKatakana": "ｻｲﾀﾏｹﾝ",
    "cityNameKatakana": "ｲﾙﾏｼ"
  },
  {
    "groupCode": "112275",
    "prefectureNameKanji": "埼玉県",
    "cityNameKanji": "朝霞市",
    "prefectureNameKatakana": "ｻｲﾀﾏｹﾝ",
    "cityNameKatakana": "ｱｻｶｼ"
  },
  {
    "groupCode": "112283",
    "prefectureNameKanji": "埼玉県",
    "cityNameKanji": "志木市",
    "prefectureNameKatakana": "ｻｲﾀﾏｹﾝ",
    "cityNameKatakana": "ｼｷｼ"
  },
  {
    "groupCode": "112291",
    "prefectureNameKanji": "埼玉県",
    "cityNameKanji": "和光市",
    "prefectureNameKatakana": "ｻｲﾀﾏｹﾝ",
    "cityNameKatakana": "ﾜｺｳｼ"
  },
  {
    "groupCode": "112305",
    "prefectureNameKanji": "埼玉県",
    "cityNameKanji": "新座市",
    "prefectureNameKatakana": "ｻｲﾀﾏｹﾝ",
    "cityNameKatakana": "ﾆｲｻﾞｼ"
  },
  {
    "groupCode": "112313",
    "prefectureNameKanji": "埼玉県",
    "cityNameKanji": "桶川市",
    "prefectureNameKatakana": "ｻｲﾀﾏｹﾝ",
    "cityNameKatakana": "ｵｹｶﾞﾜｼ"
  },
  {
    "groupCode": "112321",
    "prefectureNameKanji": "埼玉県",
    "cityNameKanji": "久喜市",
    "prefectureNameKatakana": "ｻｲﾀﾏｹﾝ",
    "cityNameKatakana": "ｸｷｼ"
  },
  {
    "groupCode": "112330",
    "prefectureNameKanji": "埼玉県",
    "cityNameKanji": "北本市",
    "prefectureNameKatakana": "ｻｲﾀﾏｹﾝ",
    "cityNameKatakana": "ｷﾀﾓﾄｼ"
  },
  {
    "groupCode": "112348",
    "prefectureNameKanji": "埼玉県",
    "cityNameKanji": "八潮市",
    "prefectureNameKatakana": "ｻｲﾀﾏｹﾝ",
    "cityNameKatakana": "ﾔｼｵｼ"
  },
  {
    "groupCode": "112356",
    "prefectureNameKanji": "埼玉県",
    "cityNameKanji": "富士見市",
    "prefectureNameKatakana": "ｻｲﾀﾏｹﾝ",
    "cityNameKatakana": "ﾌｼﾞﾐｼ"
  },
  {
    "groupCode": "112372",
    "prefectureNameKanji": "埼玉県",
    "cityNameKanji": "三郷市",
    "prefectureNameKatakana": "ｻｲﾀﾏｹﾝ",
    "cityNameKatakana": "ﾐｻﾄｼ"
  },
  {
    "groupCode": "112381",
    "prefectureNameKanji": "埼玉県",
    "cityNameKanji": "蓮田市",
    "prefectureNameKatakana": "ｻｲﾀﾏｹﾝ",
    "cityNameKatakana": "ﾊｽﾀﾞｼ"
  },
  {
    "groupCode": "112399",
    "prefectureNameKanji": "埼玉県",
    "cityNameKanji": "坂戸市",
    "prefectureNameKatakana": "ｻｲﾀﾏｹﾝ",
    "cityNameKatakana": "ｻｶﾄﾞｼ"
  },
  {
    "groupCode": "112402",
    "prefectureNameKanji": "埼玉県",
    "cityNameKanji": "幸手市",
    "prefectureNameKatakana": "ｻｲﾀﾏｹﾝ",
    "cityNameKatakana": "ｻｯﾃｼ"
  },
  {
    "groupCode": "112411",
    "prefectureNameKanji": "埼玉県",
    "cityNameKanji": "鶴ヶ島市",
    "prefectureNameKatakana": "ｻｲﾀﾏｹﾝ",
    "cityNameKatakana": "ﾂﾙｶﾞｼﾏｼ"
  },
  {
    "groupCode": "112429",
    "prefectureNameKanji": "埼玉県",
    "cityNameKanji": "日高市",
    "prefectureNameKatakana": "ｻｲﾀﾏｹﾝ",
    "cityNameKatakana": "ﾋﾀﾞｶｼ"
  },
  {
    "groupCode": "112437",
    "prefectureNameKanji": "埼玉県",
    "cityNameKanji": "吉川市",
    "prefectureNameKatakana": "ｻｲﾀﾏｹﾝ",
    "cityNameKatakana": "ﾖｼｶﾜｼ"
  },
  {
    "groupCode": "112453",
    "prefectureNameKanji": "埼玉県",
    "cityNameKanji": "ふじみ野市",
    "prefectureNameKatakana": "ｻｲﾀﾏｹﾝ",
    "cityNameKatakana": "ﾌｼﾞﾐﾉｼ"
  },
  {
    "groupCode": "112461",
    "prefectureNameKanji": "埼玉県",
    "cityNameKanji": "白岡市",
    "prefectureNameKatakana": "ｻｲﾀﾏｹﾝ",
    "cityNameKatakana": "ｼﾗｵｶｼ"
  },
  {
    "groupCode": "113018",
    "prefectureNameKanji": "埼玉県",
    "cityNameKanji": "伊奈町",
    "prefectureNameKatakana": "ｻｲﾀﾏｹﾝ",
    "cityNameKatakana": "ｲﾅﾏﾁ"
  },
  {
    "groupCode": "113247",
    "prefectureNameKanji": "埼玉県",
    "cityNameKanji": "三芳町",
    "prefectureNameKatakana": "ｻｲﾀﾏｹﾝ",
    "cityNameKatakana": "ﾐﾖｼﾏﾁ"
  },
  {
    "groupCode": "113263",
    "prefectureNameKanji": "埼玉県",
    "cityNameKanji": "毛呂山町",
    "prefectureNameKatakana": "ｻｲﾀﾏｹﾝ",
    "cityNameKatakana": "ﾓﾛﾔﾏﾏﾁ"
  },
  {
    "groupCode": "113271",
    "prefectureNameKanji": "埼玉県",
    "cityNameKanji": "越生町",
    "prefectureNameKatakana": "ｻｲﾀﾏｹﾝ",
    "cityNameKatakana": "ｵｺﾞｾﾏﾁ"
  },
  {
    "groupCode": "113417",
    "prefectureNameKanji": "埼玉県",
    "cityNameKanji": "滑川町",
    "prefectureNameKatakana": "ｻｲﾀﾏｹﾝ",
    "cityNameKatakana": "ﾅﾒｶﾞﾜﾏﾁ"
  },
  {
    "groupCode": "113425",
    "prefectureNameKanji": "埼玉県",
    "cityNameKanji": "嵐山町",
    "prefectureNameKatakana": "ｻｲﾀﾏｹﾝ",
    "cityNameKatakana": "ﾗﾝｻﾞﾝﾏﾁ"
  },
  {
    "groupCode": "113433",
    "prefectureNameKanji": "埼玉県",
    "cityNameKanji": "小川町",
    "prefectureNameKatakana": "ｻｲﾀﾏｹﾝ",
    "cityNameKatakana": "ｵｶﾞﾜﾏﾁ"
  },
  {
    "groupCode": "113468",
    "prefectureNameKanji": "埼玉県",
    "cityNameKanji": "川島町",
    "prefectureNameKatakana": "ｻｲﾀﾏｹﾝ",
    "cityNameKatakana": "ｶﾜｼﾞﾏﾏﾁ"
  },
  {
    "groupCode": "113476",
    "prefectureNameKanji": "埼玉県",
    "cityNameKanji": "吉見町",
    "prefectureNameKatakana": "ｻｲﾀﾏｹﾝ",
    "cityNameKatakana": "ﾖｼﾐﾏﾁ"
  },
  {
    "groupCode": "113484",
    "prefectureNameKanji": "埼玉県",
    "cityNameKanji": "鳩山町",
    "prefectureNameKatakana": "ｻｲﾀﾏｹﾝ",
    "cityNameKatakana": "ﾊﾄﾔﾏﾏﾁ"
  },
  {
    "groupCode": "113492",
    "prefectureNameKanji": "埼玉県",
    "cityNameKanji": "ときがわ町",
    "prefectureNameKatakana": "ｻｲﾀﾏｹﾝ",
    "cityNameKatakana": "ﾄｷｶﾞﾜﾏﾁ"
  },
  {
    "groupCode": "113611",
    "prefectureNameKanji": "埼玉県",
    "cityNameKanji": "横瀬町",
    "prefectureNameKatakana": "ｻｲﾀﾏｹﾝ",
    "cityNameKatakana": "ﾖｺｾﾞﾏﾁ"
  },
  {
    "groupCode": "113620",
    "prefectureNameKanji": "埼玉県",
    "cityNameKanji": "皆野町",
    "prefectureNameKatakana": "ｻｲﾀﾏｹﾝ",
    "cityNameKatakana": "ﾐﾅﾉﾏﾁ"
  },
  {
    "groupCode": "113638",
    "prefectureNameKanji": "埼玉県",
    "cityNameKanji": "長瀞町",
    "prefectureNameKatakana": "ｻｲﾀﾏｹﾝ",
    "cityNameKatakana": "ﾅｶﾞﾄﾛﾏﾁ"
  },
  {
    "groupCode": "113654",
    "prefectureNameKanji": "埼玉県",
    "cityNameKanji": "小鹿野町",
    "prefectureNameKatakana": "ｻｲﾀﾏｹﾝ",
    "cityNameKatakana": "ｵｶﾞﾉﾏﾁ"
  },
  {
    "groupCode": "113697",
    "prefectureNameKanji": "埼玉県",
    "cityNameKanji": "東秩父村",
    "prefectureNameKatakana": "ｻｲﾀﾏｹﾝ",
    "cityNameKatakana": "ﾋｶﾞｼﾁﾁﾌﾞﾑﾗ"
  },
  {
    "groupCode": "113816",
    "prefectureNameKanji": "埼玉県",
    "cityNameKanji": "美里町",
    "prefectureNameKatakana": "ｻｲﾀﾏｹﾝ",
    "cityNameKatakana": "ﾐｻﾄﾏﾁ"
  },
  {
    "groupCode": "113832",
    "prefectureNameKanji": "埼玉県",
    "cityNameKanji": "神川町",
    "prefectureNameKatakana": "ｻｲﾀﾏｹﾝ",
    "cityNameKatakana": "ｶﾐｶﾜﾏﾁ"
  },
  {
    "groupCode": "113859",
    "prefectureNameKanji": "埼玉県",
    "cityNameKanji": "上里町",
    "prefectureNameKatakana": "ｻｲﾀﾏｹﾝ",
    "cityNameKatakana": "ｶﾐｻﾄﾏﾁ"
  },
  {
    "groupCode": "114081",
    "prefectureNameKanji": "埼玉県",
    "cityNameKanji": "寄居町",
    "prefectureNameKatakana": "ｻｲﾀﾏｹﾝ",
    "cityNameKatakana": "ﾖﾘｲﾏﾁ"
  },
  {
    "groupCode": "114421",
    "prefectureNameKanji": "埼玉県",
    "cityNameKanji": "宮代町",
    "prefectureNameKatakana": "ｻｲﾀﾏｹﾝ",
    "cityNameKatakana": "ﾐﾔｼﾛﾏﾁ"
  },
  {
    "groupCode": "114642",
    "prefectureNameKanji": "埼玉県",
    "cityNameKanji": "杉戸町",
    "prefectureNameKatakana": "ｻｲﾀﾏｹﾝ",
    "cityNameKatakana": "ｽｷﾞﾄﾏﾁ"
  },
  {
    "groupCode": "114651",
    "prefectureNameKanji": "埼玉県",
    "cityNameKanji": "松伏町",
    "prefectureNameKatakana": "ｻｲﾀﾏｹﾝ",
    "cityNameKatakana": "ﾏﾂﾌﾞｼﾏﾁ"
  },
  {
    "groupCode": "121002",
    "prefectureNameKanji": "千葉県",
    "cityNameKanji": "千葉市",
    "prefectureNameKatakana": "ﾁﾊﾞｹﾝ",
    "cityNameKatakana": "ﾁﾊﾞｼ"
  },
  {
    "groupCode": "122025",
    "prefectureNameKanji": "千葉県",
    "cityNameKanji": "銚子市",
    "prefectureNameKatakana": "ﾁﾊﾞｹﾝ",
    "cityNameKatakana": "ﾁｮｳｼｼ"
  },
  {
    "groupCode": "122033",
    "prefectureNameKanji": "千葉県",
    "cityNameKanji": "市川市",
    "prefectureNameKatakana": "ﾁﾊﾞｹﾝ",
    "cityNameKatakana": "ｲﾁｶﾜｼ"
  },
  {
    "groupCode": "122041",
    "prefectureNameKanji": "千葉県",
    "cityNameKanji": "船橋市",
    "prefectureNameKatakana": "ﾁﾊﾞｹﾝ",
    "cityNameKatakana": "ﾌﾅﾊﾞｼｼ"
  },
  {
    "groupCode": "122050",
    "prefectureNameKanji": "千葉県",
    "cityNameKanji": "館山市",
    "prefectureNameKatakana": "ﾁﾊﾞｹﾝ",
    "cityNameKatakana": "ﾀﾃﾔﾏｼ"
  },
  {
    "groupCode": "122068",
    "prefectureNameKanji": "千葉県",
    "cityNameKanji": "木更津市",
    "prefectureNameKatakana": "ﾁﾊﾞｹﾝ",
    "cityNameKatakana": "ｷｻﾗﾂﾞｼ"
  },
  {
    "groupCode": "122076",
    "prefectureNameKanji": "千葉県",
    "cityNameKanji": "松戸市",
    "prefectureNameKatakana": "ﾁﾊﾞｹﾝ",
    "cityNameKatakana": "ﾏﾂﾄﾞｼ"
  },
  {
    "groupCode": "122084",
    "prefectureNameKanji": "千葉県",
    "cityNameKanji": "野田市",
    "prefectureNameKatakana": "ﾁﾊﾞｹﾝ",
    "cityNameKatakana": "ﾉﾀﾞｼ"
  },
  {
    "groupCode": "122106",
    "prefectureNameKanji": "千葉県",
    "cityNameKanji": "茂原市",
    "prefectureNameKatakana": "ﾁﾊﾞｹﾝ",
    "cityNameKatakana": "ﾓﾊﾞﾗｼ"
  },
  {
    "groupCode": "122114",
    "prefectureNameKanji": "千葉県",
    "cityNameKanji": "成田市",
    "prefectureNameKatakana": "ﾁﾊﾞｹﾝ",
    "cityNameKatakana": "ﾅﾘﾀｼ"
  },
  {
    "groupCode": "122122",
    "prefectureNameKanji": "千葉県",
    "cityNameKanji": "佐倉市",
    "prefectureNameKatakana": "ﾁﾊﾞｹﾝ",
    "cityNameKatakana": "ｻｸﾗｼ"
  },
  {
    "groupCode": "122131",
    "prefectureNameKanji": "千葉県",
    "cityNameKanji": "東金市",
    "prefectureNameKatakana": "ﾁﾊﾞｹﾝ",
    "cityNameKatakana": "ﾄｳｶﾞﾈｼ"
  },
  {
    "groupCode": "122157",
    "prefectureNameKanji": "千葉県",
    "cityNameKanji": "旭市",
    "prefectureNameKatakana": "ﾁﾊﾞｹﾝ",
    "cityNameKatakana": "ｱｻﾋｼ"
  },
  {
    "groupCode": "122165",
    "prefectureNameKanji": "千葉県",
    "cityNameKanji": "習志野市",
    "prefectureNameKatakana": "ﾁﾊﾞｹﾝ",
    "cityNameKatakana": "ﾅﾗｼﾉｼ"
  },
  {
    "groupCode": "122173",
    "prefectureNameKanji": "千葉県",
    "cityNameKanji": "柏市",
    "prefectureNameKatakana": "ﾁﾊﾞｹﾝ",
    "cityNameKatakana": "ｶｼﾜｼ"
  },
  {
    "groupCode": "122181",
    "prefectureNameKanji": "千葉県",
    "cityNameKanji": "勝浦市",
    "prefectureNameKatakana": "ﾁﾊﾞｹﾝ",
    "cityNameKatakana": "ｶﾂｳﾗｼ"
  },
  {
    "groupCode": "122190",
    "prefectureNameKanji": "千葉県",
    "cityNameKanji": "市原市",
    "prefectureNameKatakana": "ﾁﾊﾞｹﾝ",
    "cityNameKatakana": "ｲﾁﾊﾗｼ"
  },
  {
    "groupCode": "122203",
    "prefectureNameKanji": "千葉県",
    "cityNameKanji": "流山市",
    "prefectureNameKatakana": "ﾁﾊﾞｹﾝ",
    "cityNameKatakana": "ﾅｶﾞﾚﾔﾏｼ"
  },
  {
    "groupCode": "122211",
    "prefectureNameKanji": "千葉県",
    "cityNameKanji": "八千代市",
    "prefectureNameKatakana": "ﾁﾊﾞｹﾝ",
    "cityNameKatakana": "ﾔﾁﾖｼ"
  },
  {
    "groupCode": "122220",
    "prefectureNameKanji": "千葉県",
    "cityNameKanji": "我孫子市",
    "prefectureNameKatakana": "ﾁﾊﾞｹﾝ",
    "cityNameKatakana": "ｱﾋﾞｺｼ"
  },
  {
    "groupCode": "122238",
    "prefectureNameKanji": "千葉県",
    "cityNameKanji": "鴨川市",
    "prefectureNameKatakana": "ﾁﾊﾞｹﾝ",
    "cityNameKatakana": "ｶﾓｶﾞﾜｼ"
  },
  {
    "groupCode": "122246",
    "prefectureNameKanji": "千葉県",
    "cityNameKanji": "鎌ケ谷市",
    "prefectureNameKatakana": "ﾁﾊﾞｹﾝ",
    "cityNameKatakana": "ｶﾏｶﾞﾔｼ"
  },
  {
    "groupCode": "122254",
    "prefectureNameKanji": "千葉県",
    "cityNameKanji": "君津市",
    "prefectureNameKatakana": "ﾁﾊﾞｹﾝ",
    "cityNameKatakana": "ｷﾐﾂｼ"
  },
  {
    "groupCode": "122262",
    "prefectureNameKanji": "千葉県",
    "cityNameKanji": "富津市",
    "prefectureNameKatakana": "ﾁﾊﾞｹﾝ",
    "cityNameKatakana": "ﾌｯﾂｼ"
  },
  {
    "groupCode": "122271",
    "prefectureNameKanji": "千葉県",
    "cityNameKanji": "浦安市",
    "prefectureNameKatakana": "ﾁﾊﾞｹﾝ",
    "cityNameKatakana": "ｳﾗﾔｽｼ"
  },
  {
    "groupCode": "122289",
    "prefectureNameKanji": "千葉県",
    "cityNameKanji": "四街道市",
    "prefectureNameKatakana": "ﾁﾊﾞｹﾝ",
    "cityNameKatakana": "ﾖﾂｶｲﾄﾞｳｼ"
  },
  {
    "groupCode": "122297",
    "prefectureNameKanji": "千葉県",
    "cityNameKanji": "袖ケ浦市",
    "prefectureNameKatakana": "ﾁﾊﾞｹﾝ",
    "cityNameKatakana": "ｿﾃﾞｶﾞｳﾗｼ"
  },
  {
    "groupCode": "122301",
    "prefectureNameKanji": "千葉県",
    "cityNameKanji": "八街市",
    "prefectureNameKatakana": "ﾁﾊﾞｹﾝ",
    "cityNameKatakana": "ﾔﾁﾏﾀｼ"
  },
  {
    "groupCode": "122319",
    "prefectureNameKanji": "千葉県",
    "cityNameKanji": "印西市",
    "prefectureNameKatakana": "ﾁﾊﾞｹﾝ",
    "cityNameKatakana": "ｲﾝｻﾞｲｼ"
  },
  {
    "groupCode": "122327",
    "prefectureNameKanji": "千葉県",
    "cityNameKanji": "白井市",
    "prefectureNameKatakana": "ﾁﾊﾞｹﾝ",
    "cityNameKatakana": "ｼﾛｲｼ"
  },
  {
    "groupCode": "122335",
    "prefectureNameKanji": "千葉県",
    "cityNameKanji": "富里市",
    "prefectureNameKatakana": "ﾁﾊﾞｹﾝ",
    "cityNameKatakana": "ﾄﾐｻﾄｼ"
  },
  {
    "groupCode": "122343",
    "prefectureNameKanji": "千葉県",
    "cityNameKanji": "南房総市",
    "prefectureNameKatakana": "ﾁﾊﾞｹﾝ",
    "cityNameKatakana": "ﾐﾅﾐﾎﾞｳｿｳｼ"
  },
  {
    "groupCode": "122351",
    "prefectureNameKanji": "千葉県",
    "cityNameKanji": "匝瑳市",
    "prefectureNameKatakana": "ﾁﾊﾞｹﾝ",
    "cityNameKatakana": "ｿｳｻｼ"
  },
  {
    "groupCode": "122360",
    "prefectureNameKanji": "千葉県",
    "cityNameKanji": "香取市",
    "prefectureNameKatakana": "ﾁﾊﾞｹﾝ",
    "cityNameKatakana": "ｶﾄﾘｼ"
  },
  {
    "groupCode": "122378",
    "prefectureNameKanji": "千葉県",
    "cityNameKanji": "山武市",
    "prefectureNameKatakana": "ﾁﾊﾞｹﾝ",
    "cityNameKatakana": "ｻﾝﾑｼ"
  },
  {
    "groupCode": "122386",
    "prefectureNameKanji": "千葉県",
    "cityNameKanji": "いすみ市",
    "prefectureNameKatakana": "ﾁﾊﾞｹﾝ",
    "cityNameKatakana": "ｲｽﾐｼ"
  },
  {
    "groupCode": "122394",
    "prefectureNameKanji": "千葉県",
    "cityNameKanji": "大網白里市",
    "prefectureNameKatakana": "ﾁﾊﾞｹﾝ",
    "cityNameKatakana": "ｵｵｱﾐｼﾗｻﾄｼ"
  },
  {
    "groupCode": "123226",
    "prefectureNameKanji": "千葉県",
    "cityNameKanji": "酒々井町",
    "prefectureNameKatakana": "ﾁﾊﾞｹﾝ",
    "cityNameKatakana": "ｼｽｲﾏﾁ"
  },
  {
    "groupCode": "123293",
    "prefectureNameKanji": "千葉県",
    "cityNameKanji": "栄町",
    "prefectureNameKatakana": "ﾁﾊﾞｹﾝ",
    "cityNameKatakana": "ｻｶｴﾏﾁ"
  },
  {
    "groupCode": "123421",
    "prefectureNameKanji": "千葉県",
    "cityNameKanji": "神崎町",
    "prefectureNameKatakana": "ﾁﾊﾞｹﾝ",
    "cityNameKatakana": "ｺｳｻﾞｷﾏﾁ"
  },
  {
    "groupCode": "123471",
    "prefectureNameKanji": "千葉県",
    "cityNameKanji": "多古町",
    "prefectureNameKatakana": "ﾁﾊﾞｹﾝ",
    "cityNameKatakana": "ﾀｺﾏﾁ"
  },
  {
    "groupCode": "123498",
    "prefectureNameKanji": "千葉県",
    "cityNameKanji": "東庄町",
    "prefectureNameKatakana": "ﾁﾊﾞｹﾝ",
    "cityNameKatakana": "ﾄｳﾉｼｮｳﾏﾁ"
  },
  {
    "groupCode": "124036",
    "prefectureNameKanji": "千葉県",
    "cityNameKanji": "九十九里町",
    "prefectureNameKatakana": "ﾁﾊﾞｹﾝ",
    "cityNameKatakana": "ｸｼﾞﾕｳｸﾘﾏﾁ"
  },
  {
    "groupCode": "124095",
    "prefectureNameKanji": "千葉県",
    "cityNameKanji": "芝山町",
    "prefectureNameKatakana": "ﾁﾊﾞｹﾝ",
    "cityNameKatakana": "ｼﾊﾞﾔﾏﾏﾁ"
  },
  {
    "groupCode": "124109",
    "prefectureNameKanji": "千葉県",
    "cityNameKanji": "横芝光町",
    "prefectureNameKatakana": "ﾁﾊﾞｹﾝ",
    "cityNameKatakana": "ﾖｺｼﾊﾞﾋｶﾘﾏﾁ"
  },
  {
    "groupCode": "124214",
    "prefectureNameKanji": "千葉県",
    "cityNameKanji": "一宮町",
    "prefectureNameKatakana": "ﾁﾊﾞｹﾝ",
    "cityNameKatakana": "ｲﾁﾉﾐﾔﾏﾁ"
  },
  {
    "groupCode": "124222",
    "prefectureNameKanji": "千葉県",
    "cityNameKanji": "睦沢町",
    "prefectureNameKatakana": "ﾁﾊﾞｹﾝ",
    "cityNameKatakana": "ﾑﾂｻﾞﾜﾏﾁ"
  },
  {
    "groupCode": "124231",
    "prefectureNameKanji": "千葉県",
    "cityNameKanji": "長生村",
    "prefectureNameKatakana": "ﾁﾊﾞｹﾝ",
    "cityNameKatakana": "ﾁｮｳｾｲﾑﾗ"
  },
  {
    "groupCode": "124249",
    "prefectureNameKanji": "千葉県",
    "cityNameKanji": "白子町",
    "prefectureNameKatakana": "ﾁﾊﾞｹﾝ",
    "cityNameKatakana": "ｼﾗｺﾏﾁ"
  },
  {
    "groupCode": "124265",
    "prefectureNameKanji": "千葉県",
    "cityNameKanji": "長柄町",
    "prefectureNameKatakana": "ﾁﾊﾞｹﾝ",
    "cityNameKatakana": "ﾅｶﾞﾗﾏﾁ"
  },
  {
    "groupCode": "124273",
    "prefectureNameKanji": "千葉県",
    "cityNameKanji": "長南町",
    "prefectureNameKatakana": "ﾁﾊﾞｹﾝ",
    "cityNameKatakana": "ﾁｮｳﾅﾝﾏﾁ"
  },
  {
    "groupCode": "124419",
    "prefectureNameKanji": "千葉県",
    "cityNameKanji": "大多喜町",
    "prefectureNameKatakana": "ﾁﾊﾞｹﾝ",
    "cityNameKatakana": "ｵｵﾀｷﾏﾁ"
  },
  {
    "groupCode": "124435",
    "prefectureNameKanji": "千葉県",
    "cityNameKanji": "御宿町",
    "prefectureNameKatakana": "ﾁﾊﾞｹﾝ",
    "cityNameKatakana": "ｵﾝｼﾞﾕｸﾏﾁ"
  },
  {
    "groupCode": "124630",
    "prefectureNameKanji": "千葉県",
    "cityNameKanji": "鋸南町",
    "prefectureNameKatakana": "ﾁﾊﾞｹﾝ",
    "cityNameKatakana": "ｷﾖﾅﾝﾏﾁ"
  },
  {
    "groupCode": "131016",
    "prefectureNameKanji": "東京都",
    "cityNameKanji": "千代田区",
    "prefectureNameKatakana": "ﾄｳｷｮｳﾄ",
    "cityNameKatakana": "ﾁﾖﾀﾞｸ"
  },
  {
    "groupCode": "131024",
    "prefectureNameKanji": "東京都",
    "cityNameKanji": "中央区",
    "prefectureNameKatakana": "ﾄｳｷｮｳﾄ",
    "cityNameKatakana": "ﾁｭｳｵｳｸ"
  },
  {
    "groupCode": "131032",
    "prefectureNameKanji": "東京都",
    "cityNameKanji": "港区",
    "prefectureNameKatakana": "ﾄｳｷｮｳﾄ",
    "cityNameKatakana": "ﾐﾅﾄｸ"
  },
  {
    "groupCode": "131041",
    "prefectureNameKanji": "東京都",
    "cityNameKanji": "新宿区",
    "prefectureNameKatakana": "ﾄｳｷｮｳﾄ",
    "cityNameKatakana": "ｼﾝｼﾞｭｸｸ"
  },
  {
    "groupCode": "131059",
    "prefectureNameKanji": "東京都",
    "cityNameKanji": "文京区",
    "prefectureNameKatakana": "ﾄｳｷｮｳﾄ",
    "cityNameKatakana": "ﾌﾞﾝｷｮｳｸ"
  },
  {
    "groupCode": "131067",
    "prefectureNameKanji": "東京都",
    "cityNameKanji": "台東区",
    "prefectureNameKatakana": "ﾄｳｷｮｳﾄ",
    "cityNameKatakana": "ﾀｲﾄｳｸ"
  },
  {
    "groupCode": "131075",
    "prefectureNameKanji": "東京都",
    "cityNameKanji": "墨田区",
    "prefectureNameKatakana": "ﾄｳｷｮｳﾄ",
    "cityNameKatakana": "ｽﾐﾀﾞｸ"
  },
  {
    "groupCode": "131083",
    "prefectureNameKanji": "東京都",
    "cityNameKanji": "江東区",
    "prefectureNameKatakana": "ﾄｳｷｮｳﾄ",
    "cityNameKatakana": "ｺｳﾄｳｸ"
  },
  {
    "groupCode": "131091",
    "prefectureNameKanji": "東京都",
    "cityNameKanji": "品川区",
    "prefectureNameKatakana": "ﾄｳｷｮｳﾄ",
    "cityNameKatakana": "ｼﾅｶﾞﾜｸ"
  },
  {
    "groupCode": "131105",
    "prefectureNameKanji": "東京都",
    "cityNameKanji": "目黒区",
    "prefectureNameKatakana": "ﾄｳｷｮｳﾄ",
    "cityNameKatakana": "ﾒｸﾞﾛｸ"
  },
  {
    "groupCode": "131113",
    "prefectureNameKanji": "東京都",
    "cityNameKanji": "大田区",
    "prefectureNameKatakana": "ﾄｳｷｮｳﾄ",
    "cityNameKatakana": "ｵｵﾀｸ"
  },
  {
    "groupCode": "131121",
    "prefectureNameKanji": "東京都",
    "cityNameKanji": "世田谷区",
    "prefectureNameKatakana": "ﾄｳｷｮｳﾄ",
    "cityNameKatakana": "ｾﾀｶﾞﾔｸ"
  },
  {
    "groupCode": "131130",
    "prefectureNameKanji": "東京都",
    "cityNameKanji": "渋谷区",
    "prefectureNameKatakana": "ﾄｳｷｮｳﾄ",
    "cityNameKatakana": "ｼﾌﾞﾔｸ"
  },
  {
    "groupCode": "131148",
    "prefectureNameKanji": "東京都",
    "cityNameKanji": "中野区",
    "prefectureNameKatakana": "ﾄｳｷｮｳﾄ",
    "cityNameKatakana": "ﾅｶﾉｸ"
  },
  {
    "groupCode": "131156",
    "prefectureNameKanji": "東京都",
    "cityNameKanji": "杉並区",
    "prefectureNameKatakana": "ﾄｳｷｮｳﾄ",
    "cityNameKatakana": "ｽｷﾞﾅﾐｸ"
  },
  {
    "groupCode": "131164",
    "prefectureNameKanji": "東京都",
    "cityNameKanji": "豊島区",
    "prefectureNameKatakana": "ﾄｳｷｮｳﾄ",
    "cityNameKatakana": "ﾄｼﾏｸ"
  },
  {
    "groupCode": "131172",
    "prefectureNameKanji": "東京都",
    "cityNameKanji": "北区",
    "prefectureNameKatakana": "ﾄｳｷｮｳﾄ",
    "cityNameKatakana": "ｷﾀｸ"
  },
  {
    "groupCode": "131181",
    "prefectureNameKanji": "東京都",
    "cityNameKanji": "荒川区",
    "prefectureNameKatakana": "ﾄｳｷｮｳﾄ",
    "cityNameKatakana": "ｱﾗｶﾜｸ"
  },
  {
    "groupCode": "131199",
    "prefectureNameKanji": "東京都",
    "cityNameKanji": "板橋区",
    "prefectureNameKatakana": "ﾄｳｷｮｳﾄ",
    "cityNameKatakana": "ｲﾀﾊﾞｼｸ"
  },
  {
    "groupCode": "131202",
    "prefectureNameKanji": "東京都",
    "cityNameKanji": "練馬区",
    "prefectureNameKatakana": "ﾄｳｷｮｳﾄ",
    "cityNameKatakana": "ﾈﾘﾏｸ"
  },
  {
    "groupCode": "131211",
    "prefectureNameKanji": "東京都",
    "cityNameKanji": "足立区",
    "prefectureNameKatakana": "ﾄｳｷｮｳﾄ",
    "cityNameKatakana": "ｱﾀﾞﾁｸ"
  },
  {
    "groupCode": "131229",
    "prefectureNameKanji": "東京都",
    "cityNameKanji": "葛飾区",
    "prefectureNameKatakana": "ﾄｳｷｮｳﾄ",
    "cityNameKatakana": "ｶﾂｼｶｸ"
  },
  {
    "groupCode": "131237",
    "prefectureNameKanji": "東京都",
    "cityNameKanji": "江戸川区",
    "prefectureNameKatakana": "ﾄｳｷｮｳﾄ",
    "cityNameKatakana": "ｴﾄﾞｶﾞﾜｸ"
  },
  {
    "groupCode": "132012",
    "prefectureNameKanji": "東京都",
    "cityNameKanji": "八王子市",
    "prefectureNameKatakana": "ﾄｳｷｮｳﾄ",
    "cityNameKatakana": "ﾊﾁｵｳｼﾞｼ"
  },
  {
    "groupCode": "132021",
    "prefectureNameKanji": "東京都",
    "cityNameKanji": "立川市",
    "prefectureNameKatakana": "ﾄｳｷｮｳﾄ",
    "cityNameKatakana": "ﾀﾁｶﾜｼ"
  },
  {
    "groupCode": "132039",
    "prefectureNameKanji": "東京都",
    "cityNameKanji": "武蔵野市",
    "prefectureNameKatakana": "ﾄｳｷｮｳﾄ",
    "cityNameKatakana": "ﾑｻｼﾉｼ"
  },
  {
    "groupCode": "132047",
    "prefectureNameKanji": "東京都",
    "cityNameKanji": "三鷹市",
    "prefectureNameKatakana": "ﾄｳｷｮｳﾄ",
    "cityNameKatakana": "ﾐﾀｶｼ"
  },
  {
    "groupCode": "132055",
    "prefectureNameKanji": "東京都",
    "cityNameKanji": "青梅市",
    "prefectureNameKatakana": "ﾄｳｷｮｳﾄ",
    "cityNameKatakana": "ｵｳﾒｼ"
  },
  {
    "groupCode": "132063",
    "prefectureNameKanji": "東京都",
    "cityNameKanji": "府中市",
    "prefectureNameKatakana": "ﾄｳｷｮｳﾄ",
    "cityNameKatakana": "ﾌﾁｭｳｼ"
  },
  {
    "groupCode": "132071",
    "prefectureNameKanji": "東京都",
    "cityNameKanji": "昭島市",
    "prefectureNameKatakana": "ﾄｳｷｮｳﾄ",
    "cityNameKatakana": "ｱｷｼﾏｼ"
  },
  {
    "groupCode": "132080",
    "prefectureNameKanji": "東京都",
    "cityNameKanji": "調布市",
    "prefectureNameKatakana": "ﾄｳｷｮｳﾄ",
    "cityNameKatakana": "ﾁｮｳﾌｼ"
  },
  {
    "groupCode": "132098",
    "prefectureNameKanji": "東京都",
    "cityNameKanji": "町田市",
    "prefectureNameKatakana": "ﾄｳｷｮｳﾄ",
    "cityNameKatakana": "ﾏﾁﾀﾞｼ"
  },
  {
    "groupCode": "132101",
    "prefectureNameKanji": "東京都",
    "cityNameKanji": "小金井市",
    "prefectureNameKatakana": "ﾄｳｷｮｳﾄ",
    "cityNameKatakana": "ｺｶﾞﾈｲｼ"
  },
  {
    "groupCode": "132110",
    "prefectureNameKanji": "東京都",
    "cityNameKanji": "小平市",
    "prefectureNameKatakana": "ﾄｳｷｮｳﾄ",
    "cityNameKatakana": "ｺﾀﾞｲﾗｼ"
  },
  {
    "groupCode": "132128",
    "prefectureNameKanji": "東京都",
    "cityNameKanji": "日野市",
    "prefectureNameKatakana": "ﾄｳｷｮｳﾄ",
    "cityNameKatakana": "ﾋﾉｼ"
  },
  {
    "groupCode": "132136",
    "prefectureNameKanji": "東京都",
    "cityNameKanji": "東村山市",
    "prefectureNameKatakana": "ﾄｳｷｮｳﾄ",
    "cityNameKatakana": "ﾋｶﾞｼﾑﾗﾔﾏｼ"
  },
  {
    "groupCode": "132144",
    "prefectureNameKanji": "東京都",
    "cityNameKanji": "国分寺市",
    "prefectureNameKatakana": "ﾄｳｷｮｳﾄ",
    "cityNameKatakana": "ｺｸﾌﾞﾝｼﾞｼ"
  },
  {
    "groupCode": "132152",
    "prefectureNameKanji": "東京都",
    "cityNameKanji": "国立市",
    "prefectureNameKatakana": "ﾄｳｷｮｳﾄ",
    "cityNameKatakana": "ｸﾆﾀﾁｼ"
  },
  {
    "groupCode": "132187",
    "prefectureNameKanji": "東京都",
    "cityNameKanji": "福生市",
    "prefectureNameKatakana": "ﾄｳｷｮｳﾄ",
    "cityNameKatakana": "ﾌｯｻｼ"
  },
  {
    "groupCode": "132195",
    "prefectureNameKanji": "東京都",
    "cityNameKanji": "狛江市",
    "prefectureNameKatakana": "ﾄｳｷｮｳﾄ",
    "cityNameKatakana": "ｺﾏｴｼ"
  },
  {
    "groupCode": "132209",
    "prefectureNameKanji": "東京都",
    "cityNameKanji": "東大和市",
    "prefectureNameKatakana": "ﾄｳｷｮｳﾄ",
    "cityNameKatakana": "ﾋｶﾞｼﾔﾏﾄｼ"
  },
  {
    "groupCode": "132217",
    "prefectureNameKanji": "東京都",
    "cityNameKanji": "清瀬市",
    "prefectureNameKatakana": "ﾄｳｷｮｳﾄ",
    "cityNameKatakana": "ｷﾖｾｼ"
  },
  {
    "groupCode": "132225",
    "prefectureNameKanji": "東京都",
    "cityNameKanji": "東久留米市",
    "prefectureNameKatakana": "ﾄｳｷｮｳﾄ",
    "cityNameKatakana": "ﾋｶﾞｼｸﾙﾒｼ"
  },
  {
    "groupCode": "132233",
    "prefectureNameKanji": "東京都",
    "cityNameKanji": "武蔵村山市",
    "prefectureNameKatakana": "ﾄｳｷｮｳﾄ",
    "cityNameKatakana": "ﾑｻｼﾑﾗﾔﾏｼ"
  },
  {
    "groupCode": "132241",
    "prefectureNameKanji": "東京都",
    "cityNameKanji": "多摩市",
    "prefectureNameKatakana": "ﾄｳｷｮｳﾄ",
    "cityNameKatakana": "ﾀﾏｼ"
  },
  {
    "groupCode": "132250",
    "prefectureNameKanji": "東京都",
    "cityNameKanji": "稲城市",
    "prefectureNameKatakana": "ﾄｳｷｮｳﾄ",
    "cityNameKatakana": "ｲﾅｷﾞｼ"
  },
  {
    "groupCode": "132276",
    "prefectureNameKanji": "東京都",
    "cityNameKanji": "羽村市",
    "prefectureNameKatakana": "ﾄｳｷｮｳﾄ",
    "cityNameKatakana": "ﾊﾑﾗｼ"
  },
  {
    "groupCode": "132284",
    "prefectureNameKanji": "東京都",
    "cityNameKanji": "あきる野市",
    "prefectureNameKatakana": "ﾄｳｷｮｳﾄ",
    "cityNameKatakana": "ｱｷﾙﾉｼ"
  },
  {
    "groupCode": "132292",
    "prefectureNameKanji": "東京都",
    "cityNameKanji": "西東京市",
    "prefectureNameKatakana": "ﾄｳｷｮｳﾄ",
    "cityNameKatakana": "ﾆｼﾄｳｷｮｳｼ"
  },
  {
    "groupCode": "133035",
    "prefectureNameKanji": "東京都",
    "cityNameKanji": "瑞穂町",
    "prefectureNameKatakana": "ﾄｳｷｮｳﾄ",
    "cityNameKatakana": "ﾐｽﾞﾎﾏﾁ"
  },
  {
    "groupCode": "133051",
    "prefectureNameKanji": "東京都",
    "cityNameKanji": "日の出町",
    "prefectureNameKatakana": "ﾄｳｷｮｳﾄ",
    "cityNameKatakana": "ﾋﾉﾃﾞﾏﾁ"
  },
  {
    "groupCode": "133078",
    "prefectureNameKanji": "東京都",
    "cityNameKanji": "檜原村",
    "prefectureNameKatakana": "ﾄｳｷｮｳﾄ",
    "cityNameKatakana": "ﾋﾉﾊﾗﾑﾗ"
  },
  {
    "groupCode": "133086",
    "prefectureNameKanji": "東京都",
    "cityNameKanji": "奥多摩町",
    "prefectureNameKatakana": "ﾄｳｷｮｳﾄ",
    "cityNameKatakana": "ｵｸﾀﾏﾏﾁ"
  },
  {
    "groupCode": "133612",
    "prefectureNameKanji": "東京都",
    "cityNameKanji": "大島町",
    "prefectureNameKatakana": "ﾄｳｷｮｳﾄ",
    "cityNameKatakana": "ｵｵｼﾏﾏﾁ"
  },
  {
    "groupCode": "133621",
    "prefectureNameKanji": "東京都",
    "cityNameKanji": "利島村",
    "prefectureNameKatakana": "ﾄｳｷｮｳﾄ",
    "cityNameKatakana": "ﾄｼﾏﾑﾗ"
  },
  {
    "groupCode": "133639",
    "prefectureNameKanji": "東京都",
    "cityNameKanji": "新島村",
    "prefectureNameKatakana": "ﾄｳｷｮｳﾄ",
    "cityNameKatakana": "ﾆｲｼﾞﾏﾑﾗ"
  },
  {
    "groupCode": "133647",
    "prefectureNameKanji": "東京都",
    "cityNameKanji": "神津島村",
    "prefectureNameKatakana": "ﾄｳｷｮｳﾄ",
    "cityNameKatakana": "ｺｳﾂﾞｼﾏﾑﾗ"
  },
  {
    "groupCode": "133817",
    "prefectureNameKanji": "東京都",
    "cityNameKanji": "三宅村",
    "prefectureNameKatakana": "ﾄｳｷｮｳﾄ",
    "cityNameKatakana": "ﾐﾔｹﾑﾗ"
  },
  {
    "groupCode": "133825",
    "prefectureNameKanji": "東京都",
    "cityNameKanji": "御蔵島村",
    "prefectureNameKatakana": "ﾄｳｷｮｳﾄ",
    "cityNameKatakana": "ﾐｸﾗｼﾞﾏﾑﾗ"
  },
  {
    "groupCode": "134015",
    "prefectureNameKanji": "東京都",
    "cityNameKanji": "八丈町",
    "prefectureNameKatakana": "ﾄｳｷｮｳﾄ",
    "cityNameKatakana": "ﾊﾁｼﾞｮｳﾏﾁ"
  },
  {
    "groupCode": "134023",
    "prefectureNameKanji": "東京都",
    "cityNameKanji": "青ヶ島村",
    "prefectureNameKatakana": "ﾄｳｷｮｳﾄ",
    "cityNameKatakana": "ｱｵｶﾞｼﾏﾑﾗ"
  },
  {
    "groupCode": "134210",
    "prefectureNameKanji": "東京都",
    "cityNameKanji": "小笠原村",
    "prefectureNameKatakana": "ﾄｳｷｮｳﾄ",
    "cityNameKatakana": "ｵｶﾞｻﾜﾗﾑﾗ"
  },
  {
    "groupCode": "141003",
    "prefectureNameKanji": "神奈川県",
    "cityNameKanji": "横浜市",
    "prefectureNameKatakana": "ｶﾅｶﾞﾜｹﾝ",
    "cityNameKatakana": "ﾖｺﾊﾏｼ"
  },
  {
    "groupCode": "141305",
    "prefectureNameKanji": "神奈川県",
    "cityNameKanji": "川崎市",
    "prefectureNameKatakana": "ｶﾅｶﾞﾜｹﾝ",
    "cityNameKatakana": "ｶﾜｻｷｼ"
  },
  {
    "groupCode": "141500",
    "prefectureNameKanji": "神奈川県",
    "cityNameKanji": "相模原市",
    "prefectureNameKatakana": "ｶﾅｶﾞﾜｹﾝ",
    "cityNameKatakana": "ｻｶﾞﾐﾊﾗｼ"
  },
  {
    "groupCode": "142018",
    "prefectureNameKanji": "神奈川県",
    "cityNameKanji": "横須賀市",
    "prefectureNameKatakana": "ｶﾅｶﾞﾜｹﾝ",
    "cityNameKatakana": "ﾖｺｽｶｼ"
  },
  {
    "groupCode": "142034",
    "prefectureNameKanji": "神奈川県",
    "cityNameKanji": "平塚市",
    "prefectureNameKatakana": "ｶﾅｶﾞﾜｹﾝ",
    "cityNameKatakana": "ﾋﾗﾂｶｼ"
  },
  {
    "groupCode": "142042",
    "prefectureNameKanji": "神奈川県",
    "cityNameKanji": "鎌倉市",
    "prefectureNameKatakana": "ｶﾅｶﾞﾜｹﾝ",
    "cityNameKatakana": "ｶﾏｸﾗｼ"
  },
  {
    "groupCode": "142051",
    "prefectureNameKanji": "神奈川県",
    "cityNameKanji": "藤沢市",
    "prefectureNameKatakana": "ｶﾅｶﾞﾜｹﾝ",
    "cityNameKatakana": "ﾌｼﾞｻﾜｼ"
  },
  {
    "groupCode": "142069",
    "prefectureNameKanji": "神奈川県",
    "cityNameKanji": "小田原市",
    "prefectureNameKatakana": "ｶﾅｶﾞﾜｹﾝ",
    "cityNameKatakana": "ｵﾀﾞﾜﾗｼ"
  },
  {
    "groupCode": "142077",
    "prefectureNameKanji": "神奈川県",
    "cityNameKanji": "茅ヶ崎市",
    "prefectureNameKatakana": "ｶﾅｶﾞﾜｹﾝ",
    "cityNameKatakana": "ﾁｶﾞｻｷｼ"
  },
  {
    "groupCode": "142085",
    "prefectureNameKanji": "神奈川県",
    "cityNameKanji": "逗子市",
    "prefectureNameKatakana": "ｶﾅｶﾞﾜｹﾝ",
    "cityNameKatakana": "ｽﾞｼｼ"
  },
  {
    "groupCode": "142107",
    "prefectureNameKanji": "神奈川県",
    "cityNameKanji": "三浦市",
    "prefectureNameKatakana": "ｶﾅｶﾞﾜｹﾝ",
    "cityNameKatakana": "ﾐｳﾗｼ"
  },
  {
    "groupCode": "142115",
    "prefectureNameKanji": "神奈川県",
    "cityNameKanji": "秦野市",
    "prefectureNameKatakana": "ｶﾅｶﾞﾜｹﾝ",
    "cityNameKatakana": "ﾊﾀﾞﾉｼ"
  },
  {
    "groupCode": "142123",
    "prefectureNameKanji": "神奈川県",
    "cityNameKanji": "厚木市",
    "prefectureNameKatakana": "ｶﾅｶﾞﾜｹﾝ",
    "cityNameKatakana": "ｱﾂｷﾞｼ"
  },
  {
    "groupCode": "142131",
    "prefectureNameKanji": "神奈川県",
    "cityNameKanji": "大和市",
    "prefectureNameKatakana": "ｶﾅｶﾞﾜｹﾝ",
    "cityNameKatakana": "ﾔﾏﾄｼ"
  },
  {
    "groupCode": "142140",
    "prefectureNameKanji": "神奈川県",
    "cityNameKanji": "伊勢原市",
    "prefectureNameKatakana": "ｶﾅｶﾞﾜｹﾝ",
    "cityNameKatakana": "ｲｾﾊﾗｼ"
  },
  {
    "groupCode": "142158",
    "prefectureNameKanji": "神奈川県",
    "cityNameKanji": "海老名市",
    "prefectureNameKatakana": "ｶﾅｶﾞﾜｹﾝ",
    "cityNameKatakana": "ｴﾋﾞﾅｼ"
  },
  {
    "groupCode": "142166",
    "prefectureNameKanji": "神奈川県",
    "cityNameKanji": "座間市",
    "prefectureNameKatakana": "ｶﾅｶﾞﾜｹﾝ",
    "cityNameKatakana": "ｻﾞﾏｼ"
  },
  {
    "groupCode": "142174",
    "prefectureNameKanji": "神奈川県",
    "cityNameKanji": "南足柄市",
    "prefectureNameKatakana": "ｶﾅｶﾞﾜｹﾝ",
    "cityNameKatakana": "ﾐﾅﾐｱｼｶﾞﾗｼ"
  },
  {
    "groupCode": "142182",
    "prefectureNameKanji": "神奈川県",
    "cityNameKanji": "綾瀬市",
    "prefectureNameKatakana": "ｶﾅｶﾞﾜｹﾝ",
    "cityNameKatakana": "ｱﾔｾｼ"
  },
  {
    "groupCode": "143014",
    "prefectureNameKanji": "神奈川県",
    "cityNameKanji": "葉山町",
    "prefectureNameKatakana": "ｶﾅｶﾞﾜｹﾝ",
    "cityNameKatakana": "ﾊﾔﾏﾏﾁ"
  },
  {
    "groupCode": "143219",
    "prefectureNameKanji": "神奈川県",
    "cityNameKanji": "寒川町",
    "prefectureNameKatakana": "ｶﾅｶﾞﾜｹﾝ",
    "cityNameKatakana": "ｻﾑｶﾜﾏﾁ"
  },
  {
    "groupCode": "143413",
    "prefectureNameKanji": "神奈川県",
    "cityNameKanji": "大磯町",
    "prefectureNameKatakana": "ｶﾅｶﾞﾜｹﾝ",
    "cityNameKatakana": "ｵｵｲｿﾏﾁ"
  },
  {
    "groupCode": "143421",
    "prefectureNameKanji": "神奈川県",
    "cityNameKanji": "二宮町",
    "prefectureNameKatakana": "ｶﾅｶﾞﾜｹﾝ",
    "cityNameKatakana": "ﾆﾉﾐﾔﾏﾁ"
  },
  {
    "groupCode": "143618",
    "prefectureNameKanji": "神奈川県",
    "cityNameKanji": "中井町",
    "prefectureNameKatakana": "ｶﾅｶﾞﾜｹﾝ",
    "cityNameKatakana": "ﾅｶｲﾏﾁ"
  },
  {
    "groupCode": "143626",
    "prefectureNameKanji": "神奈川県",
    "cityNameKanji": "大井町",
    "prefectureNameKatakana": "ｶﾅｶﾞﾜｹﾝ",
    "cityNameKatakana": "ｵｵｲﾏﾁ"
  },
  {
    "groupCode": "143634",
    "prefectureNameKanji": "神奈川県",
    "cityNameKanji": "松田町",
    "prefectureNameKatakana": "ｶﾅｶﾞﾜｹﾝ",
    "cityNameKatakana": "ﾏﾂﾀﾞﾏﾁ"
  },
  {
    "groupCode": "143642",
    "prefectureNameKanji": "神奈川県",
    "cityNameKanji": "山北町",
    "prefectureNameKatakana": "ｶﾅｶﾞﾜｹﾝ",
    "cityNameKatakana": "ﾔﾏｷﾀﾏﾁ"
  },
  {
    "groupCode": "143669",
    "prefectureNameKanji": "神奈川県",
    "cityNameKanji": "開成町",
    "prefectureNameKatakana": "ｶﾅｶﾞﾜｹﾝ",
    "cityNameKatakana": "ｶｲｾｲﾏﾁ"
  },
  {
    "groupCode": "143821",
    "prefectureNameKanji": "神奈川県",
    "cityNameKanji": "箱根町",
    "prefectureNameKatakana": "ｶﾅｶﾞﾜｹﾝ",
    "cityNameKatakana": "ﾊｺﾈﾏﾁ"
  },
  {
    "groupCode": "143839",
    "prefectureNameKanji": "神奈川県",
    "cityNameKanji": "真鶴町",
    "prefectureNameKatakana": "ｶﾅｶﾞﾜｹﾝ",
    "cityNameKatakana": "ﾏﾅﾂﾙﾏﾁ"
  },
  {
    "groupCode": "143847",
    "prefectureNameKanji": "神奈川県",
    "cityNameKanji": "湯河原町",
    "prefectureNameKatakana": "ｶﾅｶﾞﾜｹﾝ",
    "cityNameKatakana": "ﾕｶﾞﾜﾗﾏﾁ"
  },
  {
    "groupCode": "144011",
    "prefectureNameKanji": "神奈川県",
    "cityNameKanji": "愛川町",
    "prefectureNameKatakana": "ｶﾅｶﾞﾜｹﾝ",
    "cityNameKatakana": "ｱｲｶﾜﾏﾁ"
  },
  {
    "groupCode": "144029",
    "prefectureNameKanji": "神奈川県",
    "cityNameKanji": "清川村",
    "prefectureNameKatakana": "ｶﾅｶﾞﾜｹﾝ",
    "cityNameKatakana": "ｷﾖｶﾜﾑﾗ"
  },
  {
    "groupCode": "151009",
    "prefectureNameKanji": "新潟県",
    "cityNameKanji": "新潟市",
    "prefectureNameKatakana": "ﾆｲｶﾞﾀｹﾝ",
    "cityNameKatakana": "ﾆｲｶﾞﾀｼ"
  },
  {
    "groupCode": "152021",
    "prefectureNameKanji": "新潟県",
    "cityNameKanji": "長岡市",
    "prefectureNameKatakana": "ﾆｲｶﾞﾀｹﾝ",
    "cityNameKatakana": "ﾅｶﾞｵｶｼ"
  },
  {
    "groupCode": "152048",
    "prefectureNameKanji": "新潟県",
    "cityNameKanji": "三条市",
    "prefectureNameKatakana": "ﾆｲｶﾞﾀｹﾝ",
    "cityNameKatakana": "ｻﾝｼﾞｮｳｼ"
  },
  {
    "groupCode": "152056",
    "prefectureNameKanji": "新潟県",
    "cityNameKanji": "柏崎市",
    "prefectureNameKatakana": "ﾆｲｶﾞﾀｹﾝ",
    "cityNameKatakana": "ｶｼﾜｻﾞｷｼ"
  },
  {
    "groupCode": "152064",
    "prefectureNameKanji": "新潟県",
    "cityNameKanji": "新発田市",
    "prefectureNameKatakana": "ﾆｲｶﾞﾀｹﾝ",
    "cityNameKatakana": "ｼﾊﾞﾀｼ"
  },
  {
    "groupCode": "152081",
    "prefectureNameKanji": "新潟県",
    "cityNameKanji": "小千谷市",
    "prefectureNameKatakana": "ﾆｲｶﾞﾀｹﾝ",
    "cityNameKatakana": "ｵﾁﾞﾔｼ"
  },
  {
    "groupCode": "152099",
    "prefectureNameKanji": "新潟県",
    "cityNameKanji": "加茂市",
    "prefectureNameKatakana": "ﾆｲｶﾞﾀｹﾝ",
    "cityNameKatakana": "ｶﾓｼ"
  },
  {
    "groupCode": "152102",
    "prefectureNameKanji": "新潟県",
    "cityNameKanji": "十日町市",
    "prefectureNameKatakana": "ﾆｲｶﾞﾀｹﾝ",
    "cityNameKatakana": "ﾄｵｶﾏﾁｼ"
  },
  {
    "groupCode": "152111",
    "prefectureNameKanji": "新潟県",
    "cityNameKanji": "見附市",
    "prefectureNameKatakana": "ﾆｲｶﾞﾀｹﾝ",
    "cityNameKatakana": "ﾐﾂｹｼ"
  },
  {
    "groupCode": "152129",
    "prefectureNameKanji": "新潟県",
    "cityNameKanji": "村上市",
    "prefectureNameKatakana": "ﾆｲｶﾞﾀｹﾝ",
    "cityNameKatakana": "ﾑﾗｶﾐｼ"
  },
  {
    "groupCode": "152137",
    "prefectureNameKanji": "新潟県",
    "cityNameKanji": "燕市",
    "prefectureNameKatakana": "ﾆｲｶﾞﾀｹﾝ",
    "cityNameKatakana": "ﾂﾊﾞﾒｼ"
  },
  {
    "groupCode": "152161",
    "prefectureNameKanji": "新潟県",
    "cityNameKanji": "糸魚川市",
    "prefectureNameKatakana": "ﾆｲｶﾞﾀｹﾝ",
    "cityNameKatakana": "ｲﾄｲｶﾞﾜｼ"
  },
  {
    "groupCode": "152170",
    "prefectureNameKanji": "新潟県",
    "cityNameKanji": "妙高市",
    "prefectureNameKatakana": "ﾆｲｶﾞﾀｹﾝ",
    "cityNameKatakana": "ﾐｮｳｺｳｼ"
  },
  {
    "groupCode": "152188",
    "prefectureNameKanji": "新潟県",
    "cityNameKanji": "五泉市",
    "prefectureNameKatakana": "ﾆｲｶﾞﾀｹﾝ",
    "cityNameKatakana": "ｺﾞｾﾝｼ"
  },
  {
    "groupCode": "152226",
    "prefectureNameKanji": "新潟県",
    "cityNameKanji": "上越市",
    "prefectureNameKatakana": "ﾆｲｶﾞﾀｹﾝ",
    "cityNameKatakana": "ｼﾞｮｳｴﾂｼ"
  },
  {
    "groupCode": "152234",
    "prefectureNameKanji": "新潟県",
    "cityNameKanji": "阿賀野市",
    "prefectureNameKatakana": "ﾆｲｶﾞﾀｹﾝ",
    "cityNameKatakana": "ｱｶﾞﾉｼ"
  },
  {
    "groupCode": "152242",
    "prefectureNameKanji": "新潟県",
    "cityNameKanji": "佐渡市",
    "prefectureNameKatakana": "ﾆｲｶﾞﾀｹﾝ",
    "cityNameKatakana": "ｻﾄﾞｼ"
  },
  {
    "groupCode": "152251",
    "prefectureNameKanji": "新潟県",
    "cityNameKanji": "魚沼市",
    "prefectureNameKatakana": "ﾆｲｶﾞﾀｹﾝ",
    "cityNameKatakana": "ｳｵﾇﾏｼ"
  },
  {
    "groupCode": "152269",
    "prefectureNameKanji": "新潟県",
    "cityNameKanji": "南魚沼市",
    "prefectureNameKatakana": "ﾆｲｶﾞﾀｹﾝ",
    "cityNameKatakana": "ﾐﾅﾐｳｵﾇﾏｼ"
  },
  {
    "groupCode": "152277",
    "prefectureNameKanji": "新潟県",
    "cityNameKanji": "胎内市",
    "prefectureNameKatakana": "ﾆｲｶﾞﾀｹﾝ",
    "cityNameKatakana": "ﾀｲﾅｲｼ"
  },
  {
    "groupCode": "153079",
    "prefectureNameKanji": "新潟県",
    "cityNameKanji": "聖籠町",
    "prefectureNameKatakana": "ﾆｲｶﾞﾀｹﾝ",
    "cityNameKatakana": "ｾｲﾛｳﾏﾁ"
  },
  {
    "groupCode": "153427",
    "prefectureNameKanji": "新潟県",
    "cityNameKanji": "弥彦村",
    "prefectureNameKatakana": "ﾆｲｶﾞﾀｹﾝ",
    "cityNameKatakana": "ﾔﾋｺﾑﾗ"
  },
  {
    "groupCode": "153613",
    "prefectureNameKanji": "新潟県",
    "cityNameKanji": "田上町",
    "prefectureNameKatakana": "ﾆｲｶﾞﾀｹﾝ",
    "cityNameKatakana": "ﾀｶﾞﾐﾏﾁ"
  },
  {
    "groupCode": "153851",
    "prefectureNameKanji": "新潟県",
    "cityNameKanji": "阿賀町",
    "prefectureNameKatakana": "ﾆｲｶﾞﾀｹﾝ",
    "cityNameKatakana": "ｱｶﾞﾏﾁ"
  },
  {
    "groupCode": "154059",
    "prefectureNameKanji": "新潟県",
    "cityNameKanji": "出雲崎町",
    "prefectureNameKatakana": "ﾆｲｶﾞﾀｹﾝ",
    "cityNameKatakana": "ｲｽﾞﾓｻﾞｷﾏﾁ"
  },
  {
    "groupCode": "154610",
    "prefectureNameKanji": "新潟県",
    "cityNameKanji": "湯沢町",
    "prefectureNameKatakana": "ﾆｲｶﾞﾀｹﾝ",
    "cityNameKatakana": "ﾕｻﾞﾜﾏﾁ"
  },
  {
    "groupCode": "154822",
    "prefectureNameKanji": "新潟県",
    "cityNameKanji": "津南町",
    "prefectureNameKatakana": "ﾆｲｶﾞﾀｹﾝ",
    "cityNameKatakana": "ﾂﾅﾝﾏﾁ"
  },
  {
    "groupCode": "155047",
    "prefectureNameKanji": "新潟県",
    "cityNameKanji": "刈羽村",
    "prefectureNameKatakana": "ﾆｲｶﾞﾀｹﾝ",
    "cityNameKatakana": "ｶﾘﾜﾑﾗ"
  },
  {
    "groupCode": "155811",
    "prefectureNameKanji": "新潟県",
    "cityNameKanji": "関川村",
    "prefectureNameKatakana": "ﾆｲｶﾞﾀｹﾝ",
    "cityNameKatakana": "ｾｷｶﾜﾑﾗ"
  },
  {
    "groupCode": "155861",
    "prefectureNameKanji": "新潟県",
    "cityNameKanji": "粟島浦村",
    "prefectureNameKatakana": "ﾆｲｶﾞﾀｹﾝ",
    "cityNameKatakana": "ｱﾜｼﾏｳﾗﾑﾗ"
  },
  {
    "groupCode": "162019",
    "prefectureNameKanji": "富山県",
    "cityNameKanji": "富山市",
    "prefectureNameKatakana": "ﾄﾔﾏｹﾝ",
    "cityNameKatakana": "ﾄﾔﾏｼ"
  },
  {
    "groupCode": "162027",
    "prefectureNameKanji": "富山県",
    "cityNameKanji": "高岡市",
    "prefectureNameKatakana": "ﾄﾔﾏｹﾝ",
    "cityNameKatakana": "ﾀｶｵｶｼ"
  },
  {
    "groupCode": "162043",
    "prefectureNameKanji": "富山県",
    "cityNameKanji": "魚津市",
    "prefectureNameKatakana": "ﾄﾔﾏｹﾝ",
    "cityNameKatakana": "ｳｵﾂﾞｼ"
  },
  {
    "groupCode": "162051",
    "prefectureNameKanji": "富山県",
    "cityNameKanji": "氷見市",
    "prefectureNameKatakana": "ﾄﾔﾏｹﾝ",
    "cityNameKatakana": "ﾋﾐｼ"
  },
  {
    "groupCode": "162060",
    "prefectureNameKanji": "富山県",
    "cityNameKanji": "滑川市",
    "prefectureNameKatakana": "ﾄﾔﾏｹﾝ",
    "cityNameKatakana": "ﾅﾒﾘｶﾜｼ"
  },
  {
    "groupCode": "162078",
    "prefectureNameKanji": "富山県",
    "cityNameKanji": "黒部市",
    "prefectureNameKatakana": "ﾄﾔﾏｹﾝ",
    "cityNameKatakana": "ｸﾛﾍﾞｼ"
  },
  {
    "groupCode": "162086",
    "prefectureNameKanji": "富山県",
    "cityNameKanji": "砺波市",
    "prefectureNameKatakana": "ﾄﾔﾏｹﾝ",
    "cityNameKatakana": "ﾄﾅﾐｼ"
  },
  {
    "groupCode": "162094",
    "prefectureNameKanji": "富山県",
    "cityNameKanji": "小矢部市",
    "prefectureNameKatakana": "ﾄﾔﾏｹﾝ",
    "cityNameKatakana": "ｵﾔﾍﾞｼ"
  },
  {
    "groupCode": "162108",
    "prefectureNameKanji": "富山県",
    "cityNameKanji": "南砺市",
    "prefectureNameKatakana": "ﾄﾔﾏｹﾝ",
    "cityNameKatakana": "ﾅﾝﾄｼ"
  },
  {
    "groupCode": "162116",
    "prefectureNameKanji": "富山県",
    "cityNameKanji": "射水市",
    "prefectureNameKatakana": "ﾄﾔﾏｹﾝ",
    "cityNameKatakana": "ｲﾐｽﾞｼ"
  },
  {
    "groupCode": "163210",
    "prefectureNameKanji": "富山県",
    "cityNameKanji": "舟橋村",
    "prefectureNameKatakana": "ﾄﾔﾏｹﾝ",
    "cityNameKatakana": "ﾌﾅﾊｼﾑﾗ"
  },
  {
    "groupCode": "163228",
    "prefectureNameKanji": "富山県",
    "cityNameKanji": "上市町",
    "prefectureNameKatakana": "ﾄﾔﾏｹﾝ",
    "cityNameKatakana": "ｶﾐｲﾁﾏﾁ"
  },
  {
    "groupCode": "163236",
    "prefectureNameKanji": "富山県",
    "cityNameKanji": "立山町",
    "prefectureNameKatakana": "ﾄﾔﾏｹﾝ",
    "cityNameKatakana": "ﾀﾃﾔﾏﾏﾁ"
  },
  {
    "groupCode": "163422",
    "prefectureNameKanji": "富山県",
    "cityNameKanji": "入善町",
    "prefectureNameKatakana": "ﾄﾔﾏｹﾝ",
    "cityNameKatakana": "ﾆｭｳｾﾞﾝﾏﾁ"
  },
  {
    "groupCode": "163431",
    "prefectureNameKanji": "富山県",
    "cityNameKanji": "朝日町",
    "prefectureNameKatakana": "ﾄﾔﾏｹﾝ",
    "cityNameKatakana": "ｱｻﾋﾏﾁ"
  },
  {
    "groupCode": "172014",
    "prefectureNameKanji": "石川県",
    "cityNameKanji": "金沢市",
    "prefectureNameKatakana": "ｲｼｶﾜｹﾝ",
    "cityNameKatakana": "ｶﾅｻﾞﾜｼ"
  },
  {
    "groupCode": "172022",
    "prefectureNameKanji": "石川県",
    "cityNameKanji": "七尾市",
    "prefectureNameKatakana": "ｲｼｶﾜｹﾝ",
    "cityNameKatakana": "ﾅﾅｵｼ"
  },
  {
    "groupCode": "172031",
    "prefectureNameKanji": "石川県",
    "cityNameKanji": "小松市",
    "prefectureNameKatakana": "ｲｼｶﾜｹﾝ",
    "cityNameKatakana": "ｺﾏﾂｼ"
  },
  {
    "groupCode": "172049",
    "prefectureNameKanji": "石川県",
    "cityNameKanji": "輪島市",
    "prefectureNameKatakana": "ｲｼｶﾜｹﾝ",
    "cityNameKatakana": "ﾜｼﾞﾏｼ"
  },
  {
    "groupCode": "172057",
    "prefectureNameKanji": "石川県",
    "cityNameKanji": "珠洲市",
    "prefectureNameKatakana": "ｲｼｶﾜｹﾝ",
    "cityNameKatakana": "ｽｽﾞｼ"
  },
  {
    "groupCode": "172065",
    "prefectureNameKanji": "石川県",
    "cityNameKanji": "加賀市",
    "prefectureNameKatakana": "ｲｼｶﾜｹﾝ",
    "cityNameKatakana": "ｶｶﾞｼ"
  },
  {
    "groupCode": "172073",
    "prefectureNameKanji": "石川県",
    "cityNameKanji": "羽咋市",
    "prefectureNameKatakana": "ｲｼｶﾜｹﾝ",
    "cityNameKatakana": "ﾊｸｲｼ"
  },
  {
    "groupCode": "172090",
    "prefectureNameKanji": "石川県",
    "cityNameKanji": "かほく市",
    "prefectureNameKatakana": "ｲｼｶﾜｹﾝ",
    "cityNameKatakana": "ｶﾎｸｼ"
  },
  {
    "groupCode": "172103",
    "prefectureNameKanji": "石川県",
    "cityNameKanji": "白山市",
    "prefectureNameKatakana": "ｲｼｶﾜｹﾝ",
    "cityNameKatakana": "ﾊｸｻﾝｼ"
  },
  {
    "groupCode": "172111",
    "prefectureNameKanji": "石川県",
    "cityNameKanji": "能美市",
    "prefectureNameKatakana": "ｲｼｶﾜｹﾝ",
    "cityNameKatakana": "ﾉﾐｼ"
  },
  {
    "groupCode": "172120",
    "prefectureNameKanji": "石川県",
    "cityNameKanji": "野々市市",
    "prefectureNameKatakana": "ｲｼｶﾜｹﾝ",
    "cityNameKatakana": "ﾉﾉｲﾁｼ"
  },
  {
    "groupCode": "173240",
    "prefectureNameKanji": "石川県",
    "cityNameKanji": "川北町",
    "prefectureNameKatakana": "ｲｼｶﾜｹﾝ",
    "cityNameKatakana": "ｶﾜｷﾀﾏﾁ"
  },
  {
    "groupCode": "173614",
    "prefectureNameKanji": "石川県",
    "cityNameKanji": "津幡町",
    "prefectureNameKatakana": "ｲｼｶﾜｹﾝ",
    "cityNameKatakana": "ﾂﾊﾞﾀﾏﾁ"
  },
  {
    "groupCode": "173657",
    "prefectureNameKanji": "石川県",
    "cityNameKanji": "内灘町",
    "prefectureNameKatakana": "ｲｼｶﾜｹﾝ",
    "cityNameKatakana": "ｳﾁﾅﾀﾞﾏﾁ"
  },
  {
    "groupCode": "173843",
    "prefectureNameKanji": "石川県",
    "cityNameKanji": "志賀町",
    "prefectureNameKatakana": "ｲｼｶﾜｹﾝ",
    "cityNameKatakana": "ｼｶﾏﾁ"
  },
  {
    "groupCode": "173860",
    "prefectureNameKanji": "石川県",
    "cityNameKanji": "宝達志水町",
    "prefectureNameKatakana": "ｲｼｶﾜｹﾝ",
    "cityNameKatakana": "ﾎｳﾀﾞﾂｼﾐｽﾞﾁｮｳ"
  },
  {
    "groupCode": "174076",
    "prefectureNameKanji": "石川県",
    "cityNameKanji": "中能登町",
    "prefectureNameKatakana": "ｲｼｶﾜｹﾝ",
    "cityNameKatakana": "ﾅｶﾉﾄﾏﾁ"
  },
  {
    "groupCode": "174611",
    "prefectureNameKanji": "石川県",
    "cityNameKanji": "穴水町",
    "prefectureNameKatakana": "ｲｼｶﾜｹﾝ",
    "cityNameKatakana": "ｱﾅﾐｽﾞﾏﾁ"
  },
  {
    "groupCode": "174637",
    "prefectureNameKanji": "石川県",
    "cityNameKanji": "能登町",
    "prefectureNameKatakana": "ｲｼｶﾜｹﾝ",
    "cityNameKatakana": "ﾉﾄﾁｮｳ"
  },
  {
    "groupCode": "182010",
    "prefectureNameKanji": "福井県",
    "cityNameKanji": "福井市",
    "prefectureNameKatakana": "ﾌｸｲｹﾝ",
    "cityNameKatakana": "ﾌｸｲｼ"
  },
  {
    "groupCode": "182028",
    "prefectureNameKanji": "福井県",
    "cityNameKanji": "敦賀市",
    "prefectureNameKatakana": "ﾌｸｲｹﾝ",
    "cityNameKatakana": "ﾂﾙｶﾞｼ"
  },
  {
    "groupCode": "182044",
    "prefectureNameKanji": "福井県",
    "cityNameKanji": "小浜市",
    "prefectureNameKatakana": "ﾌｸｲｹﾝ",
    "cityNameKatakana": "ｵﾊﾞﾏｼ"
  },
  {
    "groupCode": "182052",
    "prefectureNameKanji": "福井県",
    "cityNameKanji": "大野市",
    "prefectureNameKatakana": "ﾌｸｲｹﾝ",
    "cityNameKatakana": "ｵｵﾉｼ"
  },
  {
    "groupCode": "182061",
    "prefectureNameKanji": "福井県",
    "cityNameKanji": "勝山市",
    "prefectureNameKatakana": "ﾌｸｲｹﾝ",
    "cityNameKatakana": "ｶﾂﾔﾏｼ"
  },
  {
    "groupCode": "182079",
    "prefectureNameKanji": "福井県",
    "cityNameKanji": "鯖江市",
    "prefectureNameKatakana": "ﾌｸｲｹﾝ",
    "cityNameKatakana": "ｻﾊﾞｴｼ"
  },
  {
    "groupCode": "182087",
    "prefectureNameKanji": "福井県",
    "cityNameKanji": "あわら市",
    "prefectureNameKatakana": "ﾌｸｲｹﾝ",
    "cityNameKatakana": "ｱﾜﾗｼ"
  },
  {
    "groupCode": "182095",
    "prefectureNameKanji": "福井県",
    "cityNameKanji": "越前市",
    "prefectureNameKatakana": "ﾌｸｲｹﾝ",
    "cityNameKatakana": "ｴﾁｾﾞﾝｼ"
  },
  {
    "groupCode": "182109",
    "prefectureNameKanji": "福井県",
    "cityNameKanji": "坂井市",
    "prefectureNameKatakana": "ﾌｸｲｹﾝ",
    "cityNameKatakana": "ｻｶｲｼ"
  },
  {
    "groupCode": "183229",
    "prefectureNameKanji": "福井県",
    "cityNameKanji": "永平寺町",
    "prefectureNameKatakana": "ﾌｸｲｹﾝ",
    "cityNameKatakana": "ｴｲﾍｲｼﾞﾁｮｳ"
  },
  {
    "groupCode": "183822",
    "prefectureNameKanji": "福井県",
    "cityNameKanji": "池田町",
    "prefectureNameKatakana": "ﾌｸｲｹﾝ",
    "cityNameKatakana": "ｲｹﾀﾞﾁｮｳ"
  },
  {
    "groupCode": "184047",
    "prefectureNameKanji": "福井県",
    "cityNameKanji": "南越前町",
    "prefectureNameKatakana": "ﾌｸｲｹﾝ",
    "cityNameKatakana": "ﾐﾅﾐｴﾁｾﾞﾝﾁｮｳ"
  },
  {
    "groupCode": "184233",
    "prefectureNameKanji": "福井県",
    "cityNameKanji": "越前町",
    "prefectureNameKatakana": "ﾌｸｲｹﾝ",
    "cityNameKatakana": "ｴﾁｾﾞﾝﾁｮｳ"
  },
  {
    "groupCode": "184420",
    "prefectureNameKanji": "福井県",
    "cityNameKanji": "美浜町",
    "prefectureNameKatakana": "ﾌｸｲｹﾝ",
    "cityNameKatakana": "ﾐﾊﾏﾁｮｳ"
  },
  {
    "groupCode": "184811",
    "prefectureNameKanji": "福井県",
    "cityNameKanji": "高浜町",
    "prefectureNameKatakana": "ﾌｸｲｹﾝ",
    "cityNameKatakana": "ﾀｶﾊﾏﾁｮｳ"
  },
  {
    "groupCode": "184837",
    "prefectureNameKanji": "福井県",
    "cityNameKanji": "おおい町",
    "prefectureNameKatakana": "ﾌｸｲｹﾝ",
    "cityNameKatakana": "ｵｵｲﾁｮｳ"
  },
  {
    "groupCode": "185019",
    "prefectureNameKanji": "福井県",
    "cityNameKanji": "若狭町",
    "prefectureNameKatakana": "ﾌｸｲｹﾝ",
    "cityNameKatakana": "ﾜｶｻﾁｮｳ"
  },
  {
    "groupCode": "192015",
    "prefectureNameKanji": "山梨県",
    "cityNameKanji": "甲府市",
    "prefectureNameKatakana": "ﾔﾏﾅｼｹﾝ",
    "cityNameKatakana": "ｺｳﾌｼ"
  },
  {
    "groupCode": "192023",
    "prefectureNameKanji": "山梨県",
    "cityNameKanji": "富士吉田市",
    "prefectureNameKatakana": "ﾔﾏﾅｼｹﾝ",
    "cityNameKatakana": "ﾌｼﾞﾖｼﾀﾞｼ"
  },
  {
    "groupCode": "192040",
    "prefectureNameKanji": "山梨県",
    "cityNameKanji": "都留市",
    "prefectureNameKatakana": "ﾔﾏﾅｼｹﾝ",
    "cityNameKatakana": "ﾂﾙｼ"
  },
  {
    "groupCode": "192058",
    "prefectureNameKanji": "山梨県",
    "cityNameKanji": "山梨市",
    "prefectureNameKatakana": "ﾔﾏﾅｼｹﾝ",
    "cityNameKatakana": "ﾔﾏﾅｼｼ"
  },
  {
    "groupCode": "192066",
    "prefectureNameKanji": "山梨県",
    "cityNameKanji": "大月市",
    "prefectureNameKatakana": "ﾔﾏﾅｼｹﾝ",
    "cityNameKatakana": "ｵｵﾂｷｼ"
  },
  {
    "groupCode": "192074",
    "prefectureNameKanji": "山梨県",
    "cityNameKanji": "韮崎市",
    "prefectureNameKatakana": "ﾔﾏﾅｼｹﾝ",
    "cityNameKatakana": "ﾆﾗｻｷｼ"
  },
  {
    "groupCode": "192082",
    "prefectureNameKanji": "山梨県",
    "cityNameKanji": "南アルプス市",
    "prefectureNameKatakana": "ﾔﾏﾅｼｹﾝ",
    "cityNameKatakana": "ﾐﾅﾐｱﾙﾌﾟｽｼ"
  },
  {
    "groupCode": "192091",
    "prefectureNameKanji": "山梨県",
    "cityNameKanji": "北杜市",
    "prefectureNameKatakana": "ﾔﾏﾅｼｹﾝ",
    "cityNameKatakana": "ﾎｸﾄｼ"
  },
  {
    "groupCode": "192104",
    "prefectureNameKanji": "山梨県",
    "cityNameKanji": "甲斐市",
    "prefectureNameKatakana": "ﾔﾏﾅｼｹﾝ",
    "cityNameKatakana": "ｶｲｼ"
  },
  {
    "groupCode": "192112",
    "prefectureNameKanji": "山梨県",
    "cityNameKanji": "笛吹市",
    "prefectureNameKatakana": "ﾔﾏﾅｼｹﾝ",
    "cityNameKatakana": "ﾌｴﾌｷｼ"
  },
  {
    "groupCode": "192121",
    "prefectureNameKanji": "山梨県",
    "cityNameKanji": "上野原市",
    "prefectureNameKatakana": "ﾔﾏﾅｼｹﾝ",
    "cityNameKatakana": "ｳｴﾉﾊﾗｼ"
  },
  {
    "groupCode": "192139",
    "prefectureNameKanji": "山梨県",
    "cityNameKanji": "甲州市",
    "prefectureNameKatakana": "ﾔﾏﾅｼｹﾝ",
    "cityNameKatakana": "ｺｳｼｭｳｼ"
  },
  {
    "groupCode": "192147",
    "prefectureNameKanji": "山梨県",
    "cityNameKanji": "中央市",
    "prefectureNameKatakana": "ﾔﾏﾅｼｹﾝ",
    "cityNameKatakana": "ﾁｭｳｵｳｼ"
  },
  {
    "groupCode": "193461",
    "prefectureNameKanji": "山梨県",
    "cityNameKanji": "市川三郷町",
    "prefectureNameKatakana": "ﾔﾏﾅｼｹﾝ",
    "cityNameKatakana": "ｲﾁｶﾜﾐｻﾄﾁｮｳ"
  },
  {
    "groupCode": "193640",
    "prefectureNameKanji": "山梨県",
    "cityNameKanji": "早川町",
    "prefectureNameKatakana": "ﾔﾏﾅｼｹﾝ",
    "cityNameKatakana": "ﾊﾔｶﾜﾁｮｳ"
  },
  {
    "groupCode": "193658",
    "prefectureNameKanji": "山梨県",
    "cityNameKanji": "身延町",
    "prefectureNameKatakana": "ﾔﾏﾅｼｹﾝ",
    "cityNameKatakana": "ﾐﾉﾌﾞﾁｮｳ"
  },
  {
    "groupCode": "193666",
    "prefectureNameKanji": "山梨県",
    "cityNameKanji": "南部町",
    "prefectureNameKatakana": "ﾔﾏﾅｼｹﾝ",
    "cityNameKatakana": "ﾅﾝﾌﾞﾁｮｳ"
  },
  {
    "groupCode": "193682",
    "prefectureNameKanji": "山梨県",
    "cityNameKanji": "富士川町",
    "prefectureNameKatakana": "ﾔﾏﾅｼｹﾝ",
    "cityNameKatakana": "ﾌｼﾞｶﾜﾁｮｳ"
  },
  {
    "groupCode": "193844",
    "prefectureNameKanji": "山梨県",
    "cityNameKanji": "昭和町",
    "prefectureNameKatakana": "ﾔﾏﾅｼｹﾝ",
    "cityNameKatakana": "ｼｮｳﾜﾁｮｳ"
  },
  {
    "groupCode": "194221",
    "prefectureNameKanji": "山梨県",
    "cityNameKanji": "道志村",
    "prefectureNameKatakana": "ﾔﾏﾅｼｹﾝ",
    "cityNameKatakana": "ﾄﾞｳｼﾑﾗ"
  },
  {
    "groupCode": "194239",
    "prefectureNameKanji": "山梨県",
    "cityNameKanji": "西桂町",
    "prefectureNameKatakana": "ﾔﾏﾅｼｹﾝ",
    "cityNameKatakana": "ﾆｼｶﾂﾗﾁｮｳ"
  },
  {
    "groupCode": "194247",
    "prefectureNameKanji": "山梨県",
    "cityNameKanji": "忍野村",
    "prefectureNameKatakana": "ﾔﾏﾅｼｹﾝ",
    "cityNameKatakana": "ｵｼﾉﾑﾗ"
  },
  {
    "groupCode": "194255",
    "prefectureNameKanji": "山梨県",
    "cityNameKanji": "山中湖村",
    "prefectureNameKatakana": "ﾔﾏﾅｼｹﾝ",
    "cityNameKatakana": "ﾔﾏﾅｶｺﾑﾗ"
  },
  {
    "groupCode": "194298",
    "prefectureNameKanji": "山梨県",
    "cityNameKanji": "鳴沢村",
    "prefectureNameKatakana": "ﾔﾏﾅｼｹﾝ",
    "cityNameKatakana": "ﾅﾙｻﾜﾑﾗ"
  },
  {
    "groupCode": "194301",
    "prefectureNameKanji": "山梨県",
    "cityNameKanji": "富士河口湖町",
    "prefectureNameKatakana": "ﾔﾏﾅｼｹﾝ",
    "cityNameKatakana": "ﾌｼﾞｶﾜｸﾞﾁｺﾏﾁ"
  },
  {
    "groupCode": "194425",
    "prefectureNameKanji": "山梨県",
    "cityNameKanji": "小菅村",
    "prefectureNameKatakana": "ﾔﾏﾅｼｹﾝ",
    "cityNameKatakana": "ｺｽｹﾞﾑﾗ"
  },
  {
    "groupCode": "194433",
    "prefectureNameKanji": "山梨県",
    "cityNameKanji": "丹波山村",
    "prefectureNameKatakana": "ﾔﾏﾅｼｹﾝ",
    "cityNameKatakana": "ﾀﾊﾞﾔﾏﾑﾗ"
  },
  {
    "groupCode": "202011",
    "prefectureNameKanji": "長野県",
    "cityNameKanji": "長野市",
    "prefectureNameKatakana": "ﾅｶﾞﾉｹﾝ",
    "cityNameKatakana": "ﾅｶﾞﾉｼ"
  },
  {
    "groupCode": "202029",
    "prefectureNameKanji": "長野県",
    "cityNameKanji": "松本市",
    "prefectureNameKatakana": "ﾅｶﾞﾉｹﾝ",
    "cityNameKatakana": "ﾏﾂﾓﾄｼ"
  },
  {
    "groupCode": "202037",
    "prefectureNameKanji": "長野県",
    "cityNameKanji": "上田市",
    "prefectureNameKatakana": "ﾅｶﾞﾉｹﾝ",
    "cityNameKatakana": "ｳｴﾀﾞｼ"
  },
  {
    "groupCode": "202045",
    "prefectureNameKanji": "長野県",
    "cityNameKanji": "岡谷市",
    "prefectureNameKatakana": "ﾅｶﾞﾉｹﾝ",
    "cityNameKatakana": "ｵｶﾔｼ"
  },
  {
    "groupCode": "202053",
    "prefectureNameKanji": "長野県",
    "cityNameKanji": "飯田市",
    "prefectureNameKatakana": "ﾅｶﾞﾉｹﾝ",
    "cityNameKatakana": "ｲｲﾀﾞｼ"
  },
  {
    "groupCode": "202061",
    "prefectureNameKanji": "長野県",
    "cityNameKanji": "諏訪市",
    "prefectureNameKatakana": "ﾅｶﾞﾉｹﾝ",
    "cityNameKatakana": "ｽﾜｼ"
  },
  {
    "groupCode": "202070",
    "prefectureNameKanji": "長野県",
    "cityNameKanji": "須坂市",
    "prefectureNameKatakana": "ﾅｶﾞﾉｹﾝ",
    "cityNameKatakana": "ｽｻﾞｶｼ"
  },
  {
    "groupCode": "202088",
    "prefectureNameKanji": "長野県",
    "cityNameKanji": "小諸市",
    "prefectureNameKatakana": "ﾅｶﾞﾉｹﾝ",
    "cityNameKatakana": "ｺﾓﾛｼ"
  },
  {
    "groupCode": "202096",
    "prefectureNameKanji": "長野県",
    "cityNameKanji": "伊那市",
    "prefectureNameKatakana": "ﾅｶﾞﾉｹﾝ",
    "cityNameKatakana": "ｲﾅｼ"
  },
  {
    "groupCode": "202100",
    "prefectureNameKanji": "長野県",
    "cityNameKanji": "駒ヶ根市",
    "prefectureNameKatakana": "ﾅｶﾞﾉｹﾝ",
    "cityNameKatakana": "ｺﾏｶﾞﾈｼ"
  },
  {
    "groupCode": "202118",
    "prefectureNameKanji": "長野県",
    "cityNameKanji": "中野市",
    "prefectureNameKatakana": "ﾅｶﾞﾉｹﾝ",
    "cityNameKatakana": "ﾅｶﾉｼ"
  },
  {
    "groupCode": "202126",
    "prefectureNameKanji": "長野県",
    "cityNameKanji": "大町市",
    "prefectureNameKatakana": "ﾅｶﾞﾉｹﾝ",
    "cityNameKatakana": "ｵｵﾏﾁｼ"
  },
  {
    "groupCode": "202134",
    "prefectureNameKanji": "長野県",
    "cityNameKanji": "飯山市",
    "prefectureNameKatakana": "ﾅｶﾞﾉｹﾝ",
    "cityNameKatakana": "ｲｲﾔﾏｼ"
  },
  {
    "groupCode": "202142",
    "prefectureNameKanji": "長野県",
    "cityNameKanji": "茅野市",
    "prefectureNameKatakana": "ﾅｶﾞﾉｹﾝ",
    "cityNameKatakana": "ﾁﾉｼ"
  },
  {
    "groupCode": "202151",
    "prefectureNameKanji": "長野県",
    "cityNameKanji": "塩尻市",
    "prefectureNameKatakana": "ﾅｶﾞﾉｹﾝ",
    "cityNameKatakana": "ｼｵｼﾞﾘｼ"
  },
  {
    "groupCode": "202177",
    "prefectureNameKanji": "長野県",
    "cityNameKanji": "佐久市",
    "prefectureNameKatakana": "ﾅｶﾞﾉｹﾝ",
    "cityNameKatakana": "ｻｸｼ"
  },
  {
    "groupCode": "202185",
    "prefectureNameKanji": "長野県",
    "cityNameKanji": "千曲市",
    "prefectureNameKatakana": "ﾅｶﾞﾉｹﾝ",
    "cityNameKatakana": "ﾁｸﾏｼ"
  },
  {
    "groupCode": "202193",
    "prefectureNameKanji": "長野県",
    "cityNameKanji": "東御市",
    "prefectureNameKatakana": "ﾅｶﾞﾉｹﾝ",
    "cityNameKatakana": "ﾄｳﾐｼ"
  },
  {
    "groupCode": "202207",
    "prefectureNameKanji": "長野県",
    "cityNameKanji": "安曇野市",
    "prefectureNameKatakana": "ﾅｶﾞﾉｹﾝ",
    "cityNameKatakana": "ｱﾂﾞﾐﾉｼ"
  },
  {
    "groupCode": "203033",
    "prefectureNameKanji": "長野県",
    "cityNameKanji": "小海町",
    "prefectureNameKatakana": "ﾅｶﾞﾉｹﾝ",
    "cityNameKatakana": "ｺｳﾐﾏﾁ"
  },
  {
    "groupCode": "203041",
    "prefectureNameKanji": "長野県",
    "cityNameKanji": "川上村",
    "prefectureNameKatakana": "ﾅｶﾞﾉｹﾝ",
    "cityNameKatakana": "ｶﾜｶﾐﾑﾗ"
  },
  {
    "groupCode": "203050",
    "prefectureNameKanji": "長野県",
    "cityNameKanji": "南牧村",
    "prefectureNameKatakana": "ﾅｶﾞﾉｹﾝ",
    "cityNameKatakana": "ﾐﾅﾐﾏｷﾑﾗ"
  },
  {
    "groupCode": "203068",
    "prefectureNameKanji": "長野県",
    "cityNameKanji": "南相木村",
    "prefectureNameKatakana": "ﾅｶﾞﾉｹﾝ",
    "cityNameKatakana": "ﾐﾅﾐｱｲｷﾑﾗ"
  },
  {
    "groupCode": "203076",
    "prefectureNameKanji": "長野県",
    "cityNameKanji": "北相木村",
    "prefectureNameKatakana": "ﾅｶﾞﾉｹﾝ",
    "cityNameKatakana": "ｷﾀｱｲｷﾑﾗ"
  },
  {
    "groupCode": "203092",
    "prefectureNameKanji": "長野県",
    "cityNameKanji": "佐久穂町",
    "prefectureNameKatakana": "ﾅｶﾞﾉｹﾝ",
    "cityNameKatakana": "ｻｸﾎﾏﾁ"
  },
  {
    "groupCode": "203211",
    "prefectureNameKanji": "長野県",
    "cityNameKanji": "軽井沢町",
    "prefectureNameKatakana": "ﾅｶﾞﾉｹﾝ",
    "cityNameKatakana": "ｶﾙｲｻﾞﾜﾏﾁ"
  },
  {
    "groupCode": "203238",
    "prefectureNameKanji": "長野県",
    "cityNameKanji": "御代田町",
    "prefectureNameKatakana": "ﾅｶﾞﾉｹﾝ",
    "cityNameKatakana": "ﾐﾖﾀﾏﾁ"
  },
  {
    "groupCode": "203246",
    "prefectureNameKanji": "長野県",
    "cityNameKanji": "立科町",
    "prefectureNameKatakana": "ﾅｶﾞﾉｹﾝ",
    "cityNameKatakana": "ﾀﾃｼﾅﾏﾁ"
  },
  {
    "groupCode": "203491",
    "prefectureNameKanji": "長野県",
    "cityNameKanji": "青木村",
    "prefectureNameKatakana": "ﾅｶﾞﾉｹﾝ",
    "cityNameKatakana": "ｱｵｷﾑﾗ"
  },
  {
    "groupCode": "203505",
    "prefectureNameKanji": "長野県",
    "cityNameKanji": "長和町",
    "prefectureNameKatakana": "ﾅｶﾞﾉｹﾝ",
    "cityNameKatakana": "ﾅｶﾞﾜﾏﾁ"
  },
  {
    "groupCode": "203611",
    "prefectureNameKanji": "長野県",
    "cityNameKanji": "下諏訪町",
    "prefectureNameKatakana": "ﾅｶﾞﾉｹﾝ",
    "cityNameKatakana": "ｼﾓｽﾜﾏﾁ"
  },
  {
    "groupCode": "203629",
    "prefectureNameKanji": "長野県",
    "cityNameKanji": "富士見町",
    "prefectureNameKatakana": "ﾅｶﾞﾉｹﾝ",
    "cityNameKatakana": "ﾌｼﾞﾐﾏﾁ"
  },
  {
    "groupCode": "203637",
    "prefectureNameKanji": "長野県",
    "cityNameKanji": "原村",
    "prefectureNameKatakana": "ﾅｶﾞﾉｹﾝ",
    "cityNameKatakana": "ﾊﾗﾑﾗ"
  },
  {
    "groupCode": "203823",
    "prefectureNameKanji": "長野県",
    "cityNameKanji": "辰野町",
    "prefectureNameKatakana": "ﾅｶﾞﾉｹﾝ",
    "cityNameKatakana": "ﾀﾂﾉﾏﾁ"
  },
  {
    "groupCode": "203831",
    "prefectureNameKanji": "長野県",
    "cityNameKanji": "箕輪町",
    "prefectureNameKatakana": "ﾅｶﾞﾉｹﾝ",
    "cityNameKatakana": "ﾐﾉﾜﾏﾁ"
  },
  {
    "groupCode": "203840",
    "prefectureNameKanji": "長野県",
    "cityNameKanji": "飯島町",
    "prefectureNameKatakana": "ﾅｶﾞﾉｹﾝ",
    "cityNameKatakana": "ｲｲｼﾞﾏﾏﾁ"
  },
  {
    "groupCode": "203858",
    "prefectureNameKanji": "長野県",
    "cityNameKanji": "南箕輪村",
    "prefectureNameKatakana": "ﾅｶﾞﾉｹﾝ",
    "cityNameKatakana": "ﾐﾅﾐﾐﾉﾜﾑﾗ"
  },
  {
    "groupCode": "203866",
    "prefectureNameKanji": "長野県",
    "cityNameKanji": "中川村",
    "prefectureNameKatakana": "ﾅｶﾞﾉｹﾝ",
    "cityNameKatakana": "ﾅｶｶﾞﾜﾑﾗ"
  },
  {
    "groupCode": "203882",
    "prefectureNameKanji": "長野県",
    "cityNameKanji": "宮田村",
    "prefectureNameKatakana": "ﾅｶﾞﾉｹﾝ",
    "cityNameKatakana": "ﾐﾔﾀﾞﾑﾗ"
  },
  {
    "groupCode": "204021",
    "prefectureNameKanji": "長野県",
    "cityNameKanji": "松川町",
    "prefectureNameKatakana": "ﾅｶﾞﾉｹﾝ",
    "cityNameKatakana": "ﾏﾂｶﾜﾏﾁ"
  },
  {
    "groupCode": "204030",
    "prefectureNameKanji": "長野県",
    "cityNameKanji": "高森町",
    "prefectureNameKatakana": "ﾅｶﾞﾉｹﾝ",
    "cityNameKatakana": "ﾀｶﾓﾘﾏﾁ"
  },
  {
    "groupCode": "204048",
    "prefectureNameKanji": "長野県",
    "cityNameKanji": "阿南町",
    "prefectureNameKatakana": "ﾅｶﾞﾉｹﾝ",
    "cityNameKatakana": "ｱﾅﾝﾁｮｳ"
  },
  {
    "groupCode": "204072",
    "prefectureNameKanji": "長野県",
    "cityNameKanji": "阿智村",
    "prefectureNameKatakana": "ﾅｶﾞﾉｹﾝ",
    "cityNameKatakana": "ｱﾁﾑﾗ"
  },
  {
    "groupCode": "204099",
    "prefectureNameKanji": "長野県",
    "cityNameKanji": "平谷村",
    "prefectureNameKatakana": "ﾅｶﾞﾉｹﾝ",
    "cityNameKatakana": "ﾋﾗﾔﾑﾗ"
  },
  {
    "groupCode": "204102",
    "prefectureNameKanji": "長野県",
    "cityNameKanji": "根羽村",
    "prefectureNameKatakana": "ﾅｶﾞﾉｹﾝ",
    "cityNameKatakana": "ﾈﾊﾞﾑﾗ"
  },
  {
    "groupCode": "204111",
    "prefectureNameKanji": "長野県",
    "cityNameKanji": "下條村",
    "prefectureNameKatakana": "ﾅｶﾞﾉｹﾝ",
    "cityNameKatakana": "ｼﾓｼﾞｮｳﾑﾗ"
  },
  {
    "groupCode": "204129",
    "prefectureNameKanji": "長野県",
    "cityNameKanji": "売木村",
    "prefectureNameKatakana": "ﾅｶﾞﾉｹﾝ",
    "cityNameKatakana": "ｳﾙｷﾞﾑﾗ"
  },
  {
    "groupCode": "204137",
    "prefectureNameKanji": "長野県",
    "cityNameKanji": "天龍村",
    "prefectureNameKatakana": "ﾅｶﾞﾉｹﾝ",
    "cityNameKatakana": "ﾃﾝﾘｭｳﾑﾗ"
  },
  {
    "groupCode": "204145",
    "prefectureNameKanji": "長野県",
    "cityNameKanji": "泰阜村",
    "prefectureNameKatakana": "ﾅｶﾞﾉｹﾝ",
    "cityNameKatakana": "ﾔｽｵｶﾑﾗ"
  },
  {
    "groupCode": "204153",
    "prefectureNameKanji": "長野県",
    "cityNameKanji": "喬木村",
    "prefectureNameKatakana": "ﾅｶﾞﾉｹﾝ",
    "cityNameKatakana": "ﾀｶｷﾞﾑﾗ"
  },
  {
    "groupCode": "204161",
    "prefectureNameKanji": "長野県",
    "cityNameKanji": "豊丘村",
    "prefectureNameKatakana": "ﾅｶﾞﾉｹﾝ",
    "cityNameKatakana": "ﾄﾖｵｶﾑﾗ"
  },
  {
    "groupCode": "204170",
    "prefectureNameKanji": "長野県",
    "cityNameKanji": "大鹿村",
    "prefectureNameKatakana": "ﾅｶﾞﾉｹﾝ",
    "cityNameKatakana": "ｵｵｼｶﾑﾗ"
  },
  {
    "groupCode": "204226",
    "prefectureNameKanji": "長野県",
    "cityNameKanji": "上松町",
    "prefectureNameKatakana": "ﾅｶﾞﾉｹﾝ",
    "cityNameKatakana": "ｱｹﾞﾏﾂﾏﾁ"
  },
  {
    "groupCode": "204234",
    "prefectureNameKanji": "長野県",
    "cityNameKanji": "南木曽町",
    "prefectureNameKatakana": "ﾅｶﾞﾉｹﾝ",
    "cityNameKatakana": "ﾅｷﾞｿﾏﾁ"
  },
  {
    "groupCode": "204251",
    "prefectureNameKanji": "長野県",
    "cityNameKanji": "木祖村",
    "prefectureNameKatakana": "ﾅｶﾞﾉｹﾝ",
    "cityNameKatakana": "ｷｿﾑﾗ"
  },
  {
    "groupCode": "204293",
    "prefectureNameKanji": "長野県",
    "cityNameKanji": "王滝村",
    "prefectureNameKatakana": "ﾅｶﾞﾉｹﾝ",
    "cityNameKatakana": "ｵｳﾀｷﾑﾗ"
  },
  {
    "groupCode": "204307",
    "prefectureNameKanji": "長野県",
    "cityNameKanji": "大桑村",
    "prefectureNameKatakana": "ﾅｶﾞﾉｹﾝ",
    "cityNameKatakana": "ｵｵｸﾜﾑﾗ"
  },
  {
    "groupCode": "204323",
    "prefectureNameKanji": "長野県",
    "cityNameKanji": "木曽町",
    "prefectureNameKatakana": "ﾅｶﾞﾉｹﾝ",
    "cityNameKatakana": "ｷｿﾏﾁ"
  },
  {
    "groupCode": "204463",
    "prefectureNameKanji": "長野県",
    "cityNameKanji": "麻績村",
    "prefectureNameKatakana": "ﾅｶﾞﾉｹﾝ",
    "cityNameKatakana": "ｵﾐﾑﾗ"
  },
  {
    "groupCode": "204480",
    "prefectureNameKanji": "長野県",
    "cityNameKanji": "生坂村",
    "prefectureNameKatakana": "ﾅｶﾞﾉｹﾝ",
    "cityNameKatakana": "ｲｸｻｶﾑﾗ"
  },
  {
    "groupCode": "204501",
    "prefectureNameKanji": "長野県",
    "cityNameKanji": "山形村",
    "prefectureNameKatakana": "ﾅｶﾞﾉｹﾝ",
    "cityNameKatakana": "ﾔﾏｶﾞﾀﾑﾗ"
  },
  {
    "groupCode": "204510",
    "prefectureNameKanji": "長野県",
    "cityNameKanji": "朝日村",
    "prefectureNameKatakana": "ﾅｶﾞﾉｹﾝ",
    "cityNameKatakana": "ｱｻﾋﾑﾗ"
  },
  {
    "groupCode": "204528",
    "prefectureNameKanji": "長野県",
    "cityNameKanji": "筑北村",
    "prefectureNameKatakana": "ﾅｶﾞﾉｹﾝ",
    "cityNameKatakana": "ﾁｸﾎｸﾑﾗ"
  },
  {
    "groupCode": "204811",
    "prefectureNameKanji": "長野県",
    "cityNameKanji": "池田町",
    "prefectureNameKatakana": "ﾅｶﾞﾉｹﾝ",
    "cityNameKatakana": "ｲｹﾀﾞﾏﾁ"
  },
  {
    "groupCode": "204820",
    "prefectureNameKanji": "長野県",
    "cityNameKanji": "松川村",
    "prefectureNameKatakana": "ﾅｶﾞﾉｹﾝ",
    "cityNameKatakana": "ﾏﾂｶﾜﾑﾗ"
  },
  {
    "groupCode": "204854",
    "prefectureNameKanji": "長野県",
    "cityNameKanji": "白馬村",
    "prefectureNameKatakana": "ﾅｶﾞﾉｹﾝ",
    "cityNameKatakana": "ﾊｸﾊﾞﾑﾗ"
  },
  {
    "groupCode": "204862",
    "prefectureNameKanji": "長野県",
    "cityNameKanji": "小谷村",
    "prefectureNameKatakana": "ﾅｶﾞﾉｹﾝ",
    "cityNameKatakana": "ｵﾀﾘﾑﾗ"
  },
  {
    "groupCode": "205214",
    "prefectureNameKanji": "長野県",
    "cityNameKanji": "坂城町",
    "prefectureNameKatakana": "ﾅｶﾞﾉｹﾝ",
    "cityNameKatakana": "ｻｶｷﾏﾁ"
  },
  {
    "groupCode": "205419",
    "prefectureNameKanji": "長野県",
    "cityNameKanji": "小布施町",
    "prefectureNameKatakana": "ﾅｶﾞﾉｹﾝ",
    "cityNameKatakana": "ｵﾌﾞｾﾏﾁ"
  },
  {
    "groupCode": "205435",
    "prefectureNameKanji": "長野県",
    "cityNameKanji": "高山村",
    "prefectureNameKatakana": "ﾅｶﾞﾉｹﾝ",
    "cityNameKatakana": "ﾀｶﾔﾏﾑﾗ"
  },
  {
    "groupCode": "205613",
    "prefectureNameKanji": "長野県",
    "cityNameKanji": "山ノ内町",
    "prefectureNameKatakana": "ﾅｶﾞﾉｹﾝ",
    "cityNameKatakana": "ﾔﾏﾉｳﾁﾏﾁ"
  },
  {
    "groupCode": "205621",
    "prefectureNameKanji": "長野県",
    "cityNameKanji": "木島平村",
    "prefectureNameKatakana": "ﾅｶﾞﾉｹﾝ",
    "cityNameKatakana": "ｷｼﾞﾏﾀﾞｲﾗﾑﾗ"
  },
  {
    "groupCode": "205630",
    "prefectureNameKanji": "長野県",
    "cityNameKanji": "野沢温泉村",
    "prefectureNameKatakana": "ﾅｶﾞﾉｹﾝ",
    "cityNameKatakana": "ﾉｻﾞﾜｵﾝｾﾝﾑﾗ"
  },
  {
    "groupCode": "205834",
    "prefectureNameKanji": "長野県",
    "cityNameKanji": "信濃町",
    "prefectureNameKatakana": "ﾅｶﾞﾉｹﾝ",
    "cityNameKatakana": "ｼﾅﾉﾏﾁ"
  },
  {
    "groupCode": "205885",
    "prefectureNameKanji": "長野県",
    "cityNameKanji": "小川村",
    "prefectureNameKatakana": "ﾅｶﾞﾉｹﾝ",
    "cityNameKatakana": "ｵｶﾞﾜﾑﾗ"
  },
  {
    "groupCode": "205907",
    "prefectureNameKanji": "長野県",
    "cityNameKanji": "飯綱町",
    "prefectureNameKatakana": "ﾅｶﾞﾉｹﾝ",
    "cityNameKatakana": "ｲｲﾂﾞﾅﾏﾁ"
  },
  {
    "groupCode": "206024",
    "prefectureNameKanji": "長野県",
    "cityNameKanji": "栄村",
    "prefectureNameKatakana": "ﾅｶﾞﾉｹﾝ",
    "cityNameKatakana": "ｻｶｴﾑﾗ"
  },
  {
    "groupCode": "212016",
    "prefectureNameKanji": "岐阜県",
    "cityNameKanji": "岐阜市",
    "prefectureNameKatakana": "ｷﾞﾌｹﾝ",
    "cityNameKatakana": "ｷﾞﾌｼ"
  },
  {
    "groupCode": "212024",
    "prefectureNameKanji": "岐阜県",
    "cityNameKanji": "大垣市",
    "prefectureNameKatakana": "ｷﾞﾌｹﾝ",
    "cityNameKatakana": "ｵｵｶﾞｷｼ"
  },
  {
    "groupCode": "212032",
    "prefectureNameKanji": "岐阜県",
    "cityNameKanji": "高山市",
    "prefectureNameKatakana": "ｷﾞﾌｹﾝ",
    "cityNameKatakana": "ﾀｶﾔﾏｼ"
  },
  {
    "groupCode": "212041",
    "prefectureNameKanji": "岐阜県",
    "cityNameKanji": "多治見市",
    "prefectureNameKatakana": "ｷﾞﾌｹﾝ",
    "cityNameKatakana": "ﾀｼﾞﾐｼ"
  },
  {
    "groupCode": "212059",
    "prefectureNameKanji": "岐阜県",
    "cityNameKanji": "関市",
    "prefectureNameKatakana": "ｷﾞﾌｹﾝ",
    "cityNameKatakana": "ｾｷｼ"
  },
  {
    "groupCode": "212067",
    "prefectureNameKanji": "岐阜県",
    "cityNameKanji": "中津川市",
    "prefectureNameKatakana": "ｷﾞﾌｹﾝ",
    "cityNameKatakana": "ﾅｶﾂｶﾞﾜｼ"
  },
  {
    "groupCode": "212075",
    "prefectureNameKanji": "岐阜県",
    "cityNameKanji": "美濃市",
    "prefectureNameKatakana": "ｷﾞﾌｹﾝ",
    "cityNameKatakana": "ﾐﾉｼ"
  },
  {
    "groupCode": "212083",
    "prefectureNameKanji": "岐阜県",
    "cityNameKanji": "瑞浪市",
    "prefectureNameKatakana": "ｷﾞﾌｹﾝ",
    "cityNameKatakana": "ﾐｽﾞﾅﾐｼ"
  },
  {
    "groupCode": "212091",
    "prefectureNameKanji": "岐阜県",
    "cityNameKanji": "羽島市",
    "prefectureNameKatakana": "ｷﾞﾌｹﾝ",
    "cityNameKatakana": "ﾊｼﾏｼ"
  },
  {
    "groupCode": "212105",
    "prefectureNameKanji": "岐阜県",
    "cityNameKanji": "恵那市",
    "prefectureNameKatakana": "ｷﾞﾌｹﾝ",
    "cityNameKatakana": "ｴﾅｼ"
  },
  {
    "groupCode": "212113",
    "prefectureNameKanji": "岐阜県",
    "cityNameKanji": "美濃加茂市",
    "prefectureNameKatakana": "ｷﾞﾌｹﾝ",
    "cityNameKatakana": "ﾐﾉｶﾓｼ"
  },
  {
    "groupCode": "212121",
    "prefectureNameKanji": "岐阜県",
    "cityNameKanji": "土岐市",
    "prefectureNameKatakana": "ｷﾞﾌｹﾝ",
    "cityNameKatakana": "ﾄｷｼ"
  },
  {
    "groupCode": "212130",
    "prefectureNameKanji": "岐阜県",
    "cityNameKanji": "各務原市",
    "prefectureNameKatakana": "ｷﾞﾌｹﾝ",
    "cityNameKatakana": "ｶｶﾐｶﾞﾊﾗｼ"
  },
  {
    "groupCode": "212148",
    "prefectureNameKanji": "岐阜県",
    "cityNameKanji": "可児市",
    "prefectureNameKatakana": "ｷﾞﾌｹﾝ",
    "cityNameKatakana": "ｶﾆｼ"
  },
  {
    "groupCode": "212156",
    "prefectureNameKanji": "岐阜県",
    "cityNameKanji": "山県市",
    "prefectureNameKatakana": "ｷﾞﾌｹﾝ",
    "cityNameKatakana": "ﾔﾏｶﾞﾀｼ"
  },
  {
    "groupCode": "212164",
    "prefectureNameKanji": "岐阜県",
    "cityNameKanji": "瑞穂市",
    "prefectureNameKatakana": "ｷﾞﾌｹﾝ",
    "cityNameKatakana": "ﾐｽﾞﾎｼ"
  },
  {
    "groupCode": "212172",
    "prefectureNameKanji": "岐阜県",
    "cityNameKanji": "飛騨市",
    "prefectureNameKatakana": "ｷﾞﾌｹﾝ",
    "cityNameKatakana": "ﾋﾀﾞｼ"
  },
  {
    "groupCode": "212181",
    "prefectureNameKanji": "岐阜県",
    "cityNameKanji": "本巣市",
    "prefectureNameKatakana": "ｷﾞﾌｹﾝ",
    "cityNameKatakana": "ﾓﾄｽｼ"
  },
  {
    "groupCode": "212199",
    "prefectureNameKanji": "岐阜県",
    "cityNameKanji": "郡上市",
    "prefectureNameKatakana": "ｷﾞﾌｹﾝ",
    "cityNameKatakana": "ｸﾞｼﾞｮｳｼ"
  },
  {
    "groupCode": "212202",
    "prefectureNameKanji": "岐阜県",
    "cityNameKanji": "下呂市",
    "prefectureNameKatakana": "ｷﾞﾌｹﾝ",
    "cityNameKatakana": "ｹﾞﾛｼ"
  },
  {
    "groupCode": "212211",
    "prefectureNameKanji": "岐阜県",
    "cityNameKanji": "海津市",
    "prefectureNameKatakana": "ｷﾞﾌｹﾝ",
    "cityNameKatakana": "ｶｲﾂﾞｼ"
  },
  {
    "groupCode": "213021",
    "prefectureNameKanji": "岐阜県",
    "cityNameKanji": "岐南町",
    "prefectureNameKatakana": "ｷﾞﾌｹﾝ",
    "cityNameKatakana": "ｷﾞﾅﾝﾁｮｳ"
  },
  {
    "groupCode": "213039",
    "prefectureNameKanji": "岐阜県",
    "cityNameKanji": "笠松町",
    "prefectureNameKatakana": "ｷﾞﾌｹﾝ",
    "cityNameKatakana": "ｶｻﾏﾂﾁｮｳ"
  },
  {
    "groupCode": "213411",
    "prefectureNameKanji": "岐阜県",
    "cityNameKanji": "養老町",
    "prefectureNameKatakana": "ｷﾞﾌｹﾝ",
    "cityNameKatakana": "ﾖｳﾛｳﾁｮｳ"
  },
  {
    "groupCode": "213616",
    "prefectureNameKanji": "岐阜県",
    "cityNameKanji": "垂井町",
    "prefectureNameKatakana": "ｷﾞﾌｹﾝ",
    "cityNameKatakana": "ﾀﾙｲﾁｮｳ"
  },
  {
    "groupCode": "213624",
    "prefectureNameKanji": "岐阜県",
    "cityNameKanji": "関ケ原町",
    "prefectureNameKatakana": "ｷﾞﾌｹﾝ",
    "cityNameKatakana": "ｾｷｶﾞﾊﾗﾁｮｳ"
  },
  {
    "groupCode": "213811",
    "prefectureNameKanji": "岐阜県",
    "cityNameKanji": "神戸町",
    "prefectureNameKatakana": "ｷﾞﾌｹﾝ",
    "cityNameKatakana": "ｺﾞｳﾄﾞﾁｮｳ"
  },
  {
    "groupCode": "213829",
    "prefectureNameKanji": "岐阜県",
    "cityNameKanji": "輪之内町",
    "prefectureNameKatakana": "ｷﾞﾌｹﾝ",
    "cityNameKatakana": "ﾜﾉｳﾁﾁｮｳ"
  },
  {
    "groupCode": "213837",
    "prefectureNameKanji": "岐阜県",
    "cityNameKanji": "安八町",
    "prefectureNameKatakana": "ｷﾞﾌｹﾝ",
    "cityNameKatakana": "ｱﾝﾊﾟﾁﾁｮｳ"
  },
  {
    "groupCode": "214019",
    "prefectureNameKanji": "岐阜県",
    "cityNameKanji": "揖斐川町",
    "prefectureNameKatakana": "ｷﾞﾌｹﾝ",
    "cityNameKatakana": "ｲﾋﾞｶﾞﾜﾁｮｳ"
  },
  {
    "groupCode": "214035",
    "prefectureNameKanji": "岐阜県",
    "cityNameKanji": "大野町",
    "prefectureNameKatakana": "ｷﾞﾌｹﾝ",
    "cityNameKatakana": "ｵｵﾉﾁｮｳ"
  },
  {
    "groupCode": "214043",
    "prefectureNameKanji": "岐阜県",
    "cityNameKanji": "池田町",
    "prefectureNameKatakana": "ｷﾞﾌｹﾝ",
    "cityNameKatakana": "ｲｹﾀﾞﾁｮｳ"
  },
  {
    "groupCode": "214213",
    "prefectureNameKanji": "岐阜県",
    "cityNameKanji": "北方町",
    "prefectureNameKatakana": "ｷﾞﾌｹﾝ",
    "cityNameKatakana": "ｷﾀｶﾞﾀﾁｮｳ"
  },
  {
    "groupCode": "215015",
    "prefectureNameKanji": "岐阜県",
    "cityNameKanji": "坂祝町",
    "prefectureNameKatakana": "ｷﾞﾌｹﾝ",
    "cityNameKatakana": "ｻｶﾎｷﾞﾁｮｳ"
  },
  {
    "groupCode": "215023",
    "prefectureNameKanji": "岐阜県",
    "cityNameKanji": "富加町",
    "prefectureNameKatakana": "ｷﾞﾌｹﾝ",
    "cityNameKatakana": "ﾄﾐｶﾁｮｳ"
  },
  {
    "groupCode": "215031",
    "prefectureNameKanji": "岐阜県",
    "cityNameKanji": "川辺町",
    "prefectureNameKatakana": "ｷﾞﾌｹﾝ",
    "cityNameKatakana": "ｶﾜﾍﾞﾁｮｳ"
  },
  {
    "groupCode": "215040",
    "prefectureNameKanji": "岐阜県",
    "cityNameKanji": "七宗町",
    "prefectureNameKatakana": "ｷﾞﾌｹﾝ",
    "cityNameKatakana": "ﾋﾁｿｳﾁｮｳ"
  },
  {
    "groupCode": "215058",
    "prefectureNameKanji": "岐阜県",
    "cityNameKanji": "八百津町",
    "prefectureNameKatakana": "ｷﾞﾌｹﾝ",
    "cityNameKatakana": "ﾔｵﾂﾁｮｳ"
  },
  {
    "groupCode": "215066",
    "prefectureNameKanji": "岐阜県",
    "cityNameKanji": "白川町",
    "prefectureNameKatakana": "ｷﾞﾌｹﾝ",
    "cityNameKatakana": "ｼﾗｶﾜﾁｮｳ"
  },
  {
    "groupCode": "215074",
    "prefectureNameKanji": "岐阜県",
    "cityNameKanji": "東白川村",
    "prefectureNameKatakana": "ｷﾞﾌｹﾝ",
    "cityNameKatakana": "ﾋｶﾞｼｼﾗｶﾜﾑﾗ"
  },
  {
    "groupCode": "215210",
    "prefectureNameKanji": "岐阜県",
    "cityNameKanji": "御嵩町",
    "prefectureNameKatakana": "ｷﾞﾌｹﾝ",
    "cityNameKatakana": "ﾐﾀｹﾁｮｳ"
  },
  {
    "groupCode": "216046",
    "prefectureNameKanji": "岐阜県",
    "cityNameKanji": "白川村",
    "prefectureNameKatakana": "ｷﾞﾌｹﾝ",
    "cityNameKatakana": "ｼﾗｶﾜﾑﾗ"
  },
  {
    "groupCode": "221007",
    "prefectureNameKanji": "静岡県",
    "cityNameKanji": "静岡市",
    "prefectureNameKatakana": "ｼｽﾞｵｶｹﾝ",
    "cityNameKatakana": "ｼｽﾞｵｶｼ"
  },
  {
    "groupCode": "221309",
    "prefectureNameKanji": "静岡県",
    "cityNameKanji": "浜松市",
    "prefectureNameKatakana": "ｼｽﾞｵｶｹﾝ",
    "cityNameKatakana": "ﾊﾏﾏﾂｼ"
  },
  {
    "groupCode": "222038",
    "prefectureNameKanji": "静岡県",
    "cityNameKanji": "沼津市",
    "prefectureNameKatakana": "ｼｽﾞｵｶｹﾝ",
    "cityNameKatakana": "ﾇﾏﾂﾞｼ"
  },
  {
    "groupCode": "222054",
    "prefectureNameKanji": "静岡県",
    "cityNameKanji": "熱海市",
    "prefectureNameKatakana": "ｼｽﾞｵｶｹﾝ",
    "cityNameKatakana": "ｱﾀﾐｼ"
  },
  {
    "groupCode": "222062",
    "prefectureNameKanji": "静岡県",
    "cityNameKanji": "三島市",
    "prefectureNameKatakana": "ｼｽﾞｵｶｹﾝ",
    "cityNameKatakana": "ﾐｼﾏｼ"
  },
  {
    "groupCode": "222071",
    "prefectureNameKanji": "静岡県",
    "cityNameKanji": "富士宮市",
    "prefectureNameKatakana": "ｼｽﾞｵｶｹﾝ",
    "cityNameKatakana": "ﾌｼﾞﾉﾐﾔｼ"
  },
  {
    "groupCode": "222089",
    "prefectureNameKanji": "静岡県",
    "cityNameKanji": "伊東市",
    "prefectureNameKatakana": "ｼｽﾞｵｶｹﾝ",
    "cityNameKatakana": "ｲﾄｳｼ"
  },
  {
    "groupCode": "222097",
    "prefectureNameKanji": "静岡県",
    "cityNameKanji": "島田市",
    "prefectureNameKatakana": "ｼｽﾞｵｶｹﾝ",
    "cityNameKatakana": "ｼﾏﾀﾞｼ"
  },
  {
    "groupCode": "222101",
    "prefectureNameKanji": "静岡県",
    "cityNameKanji": "富士市",
    "prefectureNameKatakana": "ｼｽﾞｵｶｹﾝ",
    "cityNameKatakana": "ﾌｼﾞｼ"
  },
  {
    "groupCode": "222119",
    "prefectureNameKanji": "静岡県",
    "cityNameKanji": "磐田市",
    "prefectureNameKatakana": "ｼｽﾞｵｶｹﾝ",
    "cityNameKatakana": "ｲﾜﾀｼ"
  },
  {
    "groupCode": "222127",
    "prefectureNameKanji": "静岡県",
    "cityNameKanji": "焼津市",
    "prefectureNameKatakana": "ｼｽﾞｵｶｹﾝ",
    "cityNameKatakana": "ﾔｲﾂﾞｼ"
  },
  {
    "groupCode": "222135",
    "prefectureNameKanji": "静岡県",
    "cityNameKanji": "掛川市",
    "prefectureNameKatakana": "ｼｽﾞｵｶｹﾝ",
    "cityNameKatakana": "ｶｹｶﾞﾜｼ"
  },
  {
    "groupCode": "222143",
    "prefectureNameKanji": "静岡県",
    "cityNameKanji": "藤枝市",
    "prefectureNameKatakana": "ｼｽﾞｵｶｹﾝ",
    "cityNameKatakana": "ﾌｼﾞｴﾀﾞｼ"
  },
  {
    "groupCode": "222151",
    "prefectureNameKanji": "静岡県",
    "cityNameKanji": "御殿場市",
    "prefectureNameKatakana": "ｼｽﾞｵｶｹﾝ",
    "cityNameKatakana": "ｺﾞﾃﾝﾊﾞｼ"
  },
  {
    "groupCode": "222160",
    "prefectureNameKanji": "静岡県",
    "cityNameKanji": "袋井市",
    "prefectureNameKatakana": "ｼｽﾞｵｶｹﾝ",
    "cityNameKatakana": "ﾌｸﾛｲｼ"
  },
  {
    "groupCode": "222194",
    "prefectureNameKanji": "静岡県",
    "cityNameKanji": "下田市",
    "prefectureNameKatakana": "ｼｽﾞｵｶｹﾝ",
    "cityNameKatakana": "ｼﾓﾀﾞｼ"
  },
  {
    "groupCode": "222208",
    "prefectureNameKanji": "静岡県",
    "cityNameKanji": "裾野市",
    "prefectureNameKatakana": "ｼｽﾞｵｶｹﾝ",
    "cityNameKatakana": "ｽｿﾉｼ"
  },
  {
    "groupCode": "222216",
    "prefectureNameKanji": "静岡県",
    "cityNameKanji": "湖西市",
    "prefectureNameKatakana": "ｼｽﾞｵｶｹﾝ",
    "cityNameKatakana": "ｺｻｲｼ"
  },
  {
    "groupCode": "222224",
    "prefectureNameKanji": "静岡県",
    "cityNameKanji": "伊豆市",
    "prefectureNameKatakana": "ｼｽﾞｵｶｹﾝ",
    "cityNameKatakana": "ｲｽﾞｼ"
  },
  {
    "groupCode": "222232",
    "prefectureNameKanji": "静岡県",
    "cityNameKanji": "御前崎市",
    "prefectureNameKatakana": "ｼｽﾞｵｶｹﾝ",
    "cityNameKatakana": "ｵﾏｴｻﾞｷｼ"
  },
  {
    "groupCode": "222241",
    "prefectureNameKanji": "静岡県",
    "cityNameKanji": "菊川市",
    "prefectureNameKatakana": "ｼｽﾞｵｶｹﾝ",
    "cityNameKatakana": "ｷｸｶﾞﾜｼ"
  },
  {
    "groupCode": "222259",
    "prefectureNameKanji": "静岡県",
    "cityNameKanji": "伊豆の国市",
    "prefectureNameKatakana": "ｼｽﾞｵｶｹﾝ",
    "cityNameKatakana": "ｲｽﾞﾉｸﾆｼ"
  },
  {
    "groupCode": "222267",
    "prefectureNameKanji": "静岡県",
    "cityNameKanji": "牧之原市",
    "prefectureNameKatakana": "ｼｽﾞｵｶｹﾝ",
    "cityNameKatakana": "ﾏｷﾉﾊﾗｼ"
  },
  {
    "groupCode": "223018",
    "prefectureNameKanji": "静岡県",
    "cityNameKanji": "東伊豆町",
    "prefectureNameKatakana": "ｼｽﾞｵｶｹﾝ",
    "cityNameKatakana": "ﾋｶﾞｼｲｽﾞﾁｮｳ"
  },
  {
    "groupCode": "223026",
    "prefectureNameKanji": "静岡県",
    "cityNameKanji": "河津町",
    "prefectureNameKatakana": "ｼｽﾞｵｶｹﾝ",
    "cityNameKatakana": "ｶﾜﾂﾞﾁｮｳ"
  },
  {
    "groupCode": "223042",
    "prefectureNameKanji": "静岡県",
    "cityNameKanji": "南伊豆町",
    "prefectureNameKatakana": "ｼｽﾞｵｶｹﾝ",
    "cityNameKatakana": "ﾐﾅﾐｲｽﾞﾁｮｳ"
  },
  {
    "groupCode": "223051",
    "prefectureNameKanji": "静岡県",
    "cityNameKanji": "松崎町",
    "prefectureNameKatakana": "ｼｽﾞｵｶｹﾝ",
    "cityNameKatakana": "ﾏﾂｻﾞｷﾁｮｳ"
  },
  {
    "groupCode": "223069",
    "prefectureNameKanji": "静岡県",
    "cityNameKanji": "西伊豆町",
    "prefectureNameKatakana": "ｼｽﾞｵｶｹﾝ",
    "cityNameKatakana": "ﾆｼｲｽﾞﾁｮｳ"
  },
  {
    "groupCode": "223255",
    "prefectureNameKanji": "静岡県",
    "cityNameKanji": "函南町",
    "prefectureNameKatakana": "ｼｽﾞｵｶｹﾝ",
    "cityNameKatakana": "ｶﾝﾅﾐﾁｮｳ"
  },
  {
    "groupCode": "223417",
    "prefectureNameKanji": "静岡県",
    "cityNameKanji": "清水町",
    "prefectureNameKatakana": "ｼｽﾞｵｶｹﾝ",
    "cityNameKatakana": "ｼﾐｽﾞﾁｮｳ"
  },
  {
    "groupCode": "223425",
    "prefectureNameKanji": "静岡県",
    "cityNameKanji": "長泉町",
    "prefectureNameKatakana": "ｼｽﾞｵｶｹﾝ",
    "cityNameKatakana": "ﾅｶﾞｲｽﾞﾐﾁｮｳ"
  },
  {
    "groupCode": "223441",
    "prefectureNameKanji": "静岡県",
    "cityNameKanji": "小山町",
    "prefectureNameKatakana": "ｼｽﾞｵｶｹﾝ",
    "cityNameKatakana": "ｵﾔﾏﾁｮｳ"
  },
  {
    "groupCode": "224243",
    "prefectureNameKanji": "静岡県",
    "cityNameKanji": "吉田町",
    "prefectureNameKatakana": "ｼｽﾞｵｶｹﾝ",
    "cityNameKatakana": "ﾖｼﾀﾞﾁｮｳ"
  },
  {
    "groupCode": "224294",
    "prefectureNameKanji": "静岡県",
    "cityNameKanji": "川根本町",
    "prefectureNameKatakana": "ｼｽﾞｵｶｹﾝ",
    "cityNameKatakana": "ｶﾜﾈﾎﾝﾁｮｳ"
  },
  {
    "groupCode": "224618",
    "prefectureNameKanji": "静岡県",
    "cityNameKanji": "森町",
    "prefectureNameKatakana": "ｼｽﾞｵｶｹﾝ",
    "cityNameKatakana": "ﾓﾘﾏﾁ"
  },
  {
    "groupCode": "231002",
    "prefectureNameKanji": "愛知県",
    "cityNameKanji": "名古屋市",
    "prefectureNameKatakana": "ｱｲﾁｹﾝ",
    "cityNameKatakana": "ﾅｺﾞﾔｼ"
  },
  {
    "groupCode": "232017",
    "prefectureNameKanji": "愛知県",
    "cityNameKanji": "豊橋市",
    "prefectureNameKatakana": "ｱｲﾁｹﾝ",
    "cityNameKatakana": "ﾄﾖﾊｼｼ"
  },
  {
    "groupCode": "232025",
    "prefectureNameKanji": "愛知県",
    "cityNameKanji": "岡崎市",
    "prefectureNameKatakana": "ｱｲﾁｹﾝ",
    "cityNameKatakana": "ｵｶｻﾞｷｼ"
  },
  {
    "groupCode": "232033",
    "prefectureNameKanji": "愛知県",
    "cityNameKanji": "一宮市",
    "prefectureNameKatakana": "ｱｲﾁｹﾝ",
    "cityNameKatakana": "ｲﾁﾉﾐﾔｼ"
  },
  {
    "groupCode": "232041",
    "prefectureNameKanji": "愛知県",
    "cityNameKanji": "瀬戸市",
    "prefectureNameKatakana": "ｱｲﾁｹﾝ",
    "cityNameKatakana": "ｾﾄｼ"
  },
  {
    "groupCode": "232050",
    "prefectureNameKanji": "愛知県",
    "cityNameKanji": "半田市",
    "prefectureNameKatakana": "ｱｲﾁｹﾝ",
    "cityNameKatakana": "ﾊﾝﾀﾞｼ"
  },
  {
    "groupCode": "232068",
    "prefectureNameKanji": "愛知県",
    "cityNameKanji": "春日井市",
    "prefectureNameKatakana": "ｱｲﾁｹﾝ",
    "cityNameKatakana": "ｶｽｶﾞｲｼ"
  },
  {
    "groupCode": "232076",
    "prefectureNameKanji": "愛知県",
    "cityNameKanji": "豊川市",
    "prefectureNameKatakana": "ｱｲﾁｹﾝ",
    "cityNameKatakana": "ﾄﾖｶﾜｼ"
  },
  {
    "groupCode": "232084",
    "prefectureNameKanji": "愛知県",
    "cityNameKanji": "津島市",
    "prefectureNameKatakana": "ｱｲﾁｹﾝ",
    "cityNameKatakana": "ﾂｼﾏｼ"
  },
  {
    "groupCode": "232092",
    "prefectureNameKanji": "愛知県",
    "cityNameKanji": "碧南市",
    "prefectureNameKatakana": "ｱｲﾁｹﾝ",
    "cityNameKatakana": "ﾍｷﾅﾝｼ"
  },
  {
    "groupCode": "232106",
    "prefectureNameKanji": "愛知県",
    "cityNameKanji": "刈谷市",
    "prefectureNameKatakana": "ｱｲﾁｹﾝ",
    "cityNameKatakana": "ｶﾘﾔｼ"
  },
  {
    "groupCode": "232114",
    "prefectureNameKanji": "愛知県",
    "cityNameKanji": "豊田市",
    "prefectureNameKatakana": "ｱｲﾁｹﾝ",
    "cityNameKatakana": "ﾄﾖﾀｼ"
  },
  {
    "groupCode": "232122",
    "prefectureNameKanji": "愛知県",
    "cityNameKanji": "安城市",
    "prefectureNameKatakana": "ｱｲﾁｹﾝ",
    "cityNameKatakana": "ｱﾝｼﾞｮｳｼ"
  },
  {
    "groupCode": "232131",
    "prefectureNameKanji": "愛知県",
    "cityNameKanji": "西尾市",
    "prefectureNameKatakana": "ｱｲﾁｹﾝ",
    "cityNameKatakana": "ﾆｼｵｼ"
  },
  {
    "groupCode": "232149",
    "prefectureNameKanji": "愛知県",
    "cityNameKanji": "蒲郡市",
    "prefectureNameKatakana": "ｱｲﾁｹﾝ",
    "cityNameKatakana": "ｶﾞﾏｺﾞｵﾘｼ"
  },
  {
    "groupCode": "232157",
    "prefectureNameKanji": "愛知県",
    "cityNameKanji": "犬山市",
    "prefectureNameKatakana": "ｱｲﾁｹﾝ",
    "cityNameKatakana": "ｲﾇﾔﾏｼ"
  },
  {
    "groupCode": "232165",
    "prefectureNameKanji": "愛知県",
    "cityNameKanji": "常滑市",
    "prefectureNameKatakana": "ｱｲﾁｹﾝ",
    "cityNameKatakana": "ﾄｺﾅﾒｼ"
  },
  {
    "groupCode": "232173",
    "prefectureNameKanji": "愛知県",
    "cityNameKanji": "江南市",
    "prefectureNameKatakana": "ｱｲﾁｹﾝ",
    "cityNameKatakana": "ｺｳﾅﾝｼ"
  },
  {
    "groupCode": "232190",
    "prefectureNameKanji": "愛知県",
    "cityNameKanji": "小牧市",
    "prefectureNameKatakana": "ｱｲﾁｹﾝ",
    "cityNameKatakana": "ｺﾏｷｼ"
  },
  {
    "groupCode": "232203",
    "prefectureNameKanji": "愛知県",
    "cityNameKanji": "稲沢市",
    "prefectureNameKatakana": "ｱｲﾁｹﾝ",
    "cityNameKatakana": "ｲﾅｻﾞﾜｼ"
  },
  {
    "groupCode": "232211",
    "prefectureNameKanji": "愛知県",
    "cityNameKanji": "新城市",
    "prefectureNameKatakana": "ｱｲﾁｹﾝ",
    "cityNameKatakana": "ｼﾝｼﾛｼ"
  },
  {
    "groupCode": "232220",
    "prefectureNameKanji": "愛知県",
    "cityNameKanji": "東海市",
    "prefectureNameKatakana": "ｱｲﾁｹﾝ",
    "cityNameKatakana": "ﾄｳｶｲｼ"
  },
  {
    "groupCode": "232238",
    "prefectureNameKanji": "愛知県",
    "cityNameKanji": "大府市",
    "prefectureNameKatakana": "ｱｲﾁｹﾝ",
    "cityNameKatakana": "ｵｵﾌﾞｼ"
  },
  {
    "groupCode": "232246",
    "prefectureNameKanji": "愛知県",
    "cityNameKanji": "知多市",
    "prefectureNameKatakana": "ｱｲﾁｹﾝ",
    "cityNameKatakana": "ﾁﾀｼ"
  },
  {
    "groupCode": "232254",
    "prefectureNameKanji": "愛知県",
    "cityNameKanji": "知立市",
    "prefectureNameKatakana": "ｱｲﾁｹﾝ",
    "cityNameKatakana": "ﾁﾘｭｳｼ"
  },
  {
    "groupCode": "232262",
    "prefectureNameKanji": "愛知県",
    "cityNameKanji": "尾張旭市",
    "prefectureNameKatakana": "ｱｲﾁｹﾝ",
    "cityNameKatakana": "ｵﾜﾘｱｻﾋｼ"
  },
  {
    "groupCode": "232271",
    "prefectureNameKanji": "愛知県",
    "cityNameKanji": "高浜市",
    "prefectureNameKatakana": "ｱｲﾁｹﾝ",
    "cityNameKatakana": "ﾀｶﾊﾏｼ"
  },
  {
    "groupCode": "232289",
    "prefectureNameKanji": "愛知県",
    "cityNameKanji": "岩倉市",
    "prefectureNameKatakana": "ｱｲﾁｹﾝ",
    "cityNameKatakana": "ｲﾜｸﾗｼ"
  },
  {
    "groupCode": "232297",
    "prefectureNameKanji": "愛知県",
    "cityNameKanji": "豊明市",
    "prefectureNameKatakana": "ｱｲﾁｹﾝ",
    "cityNameKatakana": "ﾄﾖｱｹｼ"
  },
  {
    "groupCode": "232301",
    "prefectureNameKanji": "愛知県",
    "cityNameKanji": "日進市",
    "prefectureNameKatakana": "ｱｲﾁｹﾝ",
    "cityNameKatakana": "ﾆｯｼﾝｼ"
  },
  {
    "groupCode": "232319",
    "prefectureNameKanji": "愛知県",
    "cityNameKanji": "田原市",
    "prefectureNameKatakana": "ｱｲﾁｹﾝ",
    "cityNameKatakana": "ﾀﾊﾗｼ"
  },
  {
    "groupCode": "232327",
    "prefectureNameKanji": "愛知県",
    "cityNameKanji": "愛西市",
    "prefectureNameKatakana": "ｱｲﾁｹﾝ",
    "cityNameKatakana": "ｱｲｻｲｼ"
  },
  {
    "groupCode": "232335",
    "prefectureNameKanji": "愛知県",
    "cityNameKanji": "清須市",
    "prefectureNameKatakana": "ｱｲﾁｹﾝ",
    "cityNameKatakana": "ｷﾖｽｼ"
  },
  {
    "groupCode": "232343",
    "prefectureNameKanji": "愛知県",
    "cityNameKanji": "北名古屋市",
    "prefectureNameKatakana": "ｱｲﾁｹﾝ",
    "cityNameKatakana": "ｷﾀﾅｺﾞﾔｼ"
  },
  {
    "groupCode": "232351",
    "prefectureNameKanji": "愛知県",
    "cityNameKanji": "弥富市",
    "prefectureNameKatakana": "ｱｲﾁｹﾝ",
    "cityNameKatakana": "ﾔﾄﾐｼ"
  },
  {
    "groupCode": "232360",
    "prefectureNameKanji": "愛知県",
    "cityNameKanji": "みよし市",
    "prefectureNameKatakana": "ｱｲﾁｹﾝ",
    "cityNameKatakana": "ﾐﾖｼｼ"
  },
  {
    "groupCode": "232378",
    "prefectureNameKanji": "愛知県",
    "cityNameKanji": "あま市",
    "prefectureNameKatakana": "ｱｲﾁｹﾝ",
    "cityNameKatakana": "ｱﾏｼ"
  },
  {
    "groupCode": "232386",
    "prefectureNameKanji": "愛知県",
    "cityNameKanji": "長久手市",
    "prefectureNameKatakana": "ｱｲﾁｹﾝ",
    "cityNameKatakana": "ﾅｶﾞｸﾃｼ"
  },
  {
    "groupCode": "233021",
    "prefectureNameKanji": "愛知県",
    "cityNameKanji": "東郷町",
    "prefectureNameKatakana": "ｱｲﾁｹﾝ",
    "cityNameKatakana": "ﾄｳｺﾞｳﾁｮｳ"
  },
  {
    "groupCode": "233421",
    "prefectureNameKanji": "愛知県",
    "cityNameKanji": "豊山町",
    "prefectureNameKatakana": "ｱｲﾁｹﾝ",
    "cityNameKatakana": "ﾄﾖﾔﾏﾁｮｳ"
  },
  {
    "groupCode": "233617",
    "prefectureNameKanji": "愛知県",
    "cityNameKanji": "大口町",
    "prefectureNameKatakana": "ｱｲﾁｹﾝ",
    "cityNameKatakana": "ｵｵｸﾞﾁﾁｮｳ"
  },
  {
    "groupCode": "233625",
    "prefectureNameKanji": "愛知県",
    "cityNameKanji": "扶桑町",
    "prefectureNameKatakana": "ｱｲﾁｹﾝ",
    "cityNameKatakana": "ﾌｿｳﾁｮｳ"
  },
  {
    "groupCode": "234249",
    "prefectureNameKanji": "愛知県",
    "cityNameKanji": "大治町",
    "prefectureNameKatakana": "ｱｲﾁｹﾝ",
    "cityNameKatakana": "ｵｵﾊﾙﾁｮｳ"
  },
  {
    "groupCode": "234257",
    "prefectureNameKanji": "愛知県",
    "cityNameKanji": "蟹江町",
    "prefectureNameKatakana": "ｱｲﾁｹﾝ",
    "cityNameKatakana": "ｶﾆｴﾁｮｳ"
  },
  {
    "groupCode": "234273",
    "prefectureNameKanji": "愛知県",
    "cityNameKanji": "飛島村",
    "prefectureNameKatakana": "ｱｲﾁｹﾝ",
    "cityNameKatakana": "ﾄﾋﾞｼﾏﾑﾗ"
  },
  {
    "groupCode": "234419",
    "prefectureNameKanji": "愛知県",
    "cityNameKanji": "阿久比町",
    "prefectureNameKatakana": "ｱｲﾁｹﾝ",
    "cityNameKatakana": "ｱｸﾞｲﾁｮｳ"
  },
  {
    "groupCode": "234427",
    "prefectureNameKanji": "愛知県",
    "cityNameKanji": "東浦町",
    "prefectureNameKatakana": "ｱｲﾁｹﾝ",
    "cityNameKatakana": "ﾋｶﾞｼｳﾗﾁｮｳ"
  },
  {
    "groupCode": "234451",
    "prefectureNameKanji": "愛知県",
    "cityNameKanji": "南知多町",
    "prefectureNameKatakana": "ｱｲﾁｹﾝ",
    "cityNameKatakana": "ﾐﾅﾐﾁﾀﾁｮｳ"
  },
  {
    "groupCode": "234460",
    "prefectureNameKanji": "愛知県",
    "cityNameKanji": "美浜町",
    "prefectureNameKatakana": "ｱｲﾁｹﾝ",
    "cityNameKatakana": "ﾐﾊﾏﾁｮｳ"
  },
  {
    "groupCode": "234478",
    "prefectureNameKanji": "愛知県",
    "cityNameKanji": "武豊町",
    "prefectureNameKatakana": "ｱｲﾁｹﾝ",
    "cityNameKatakana": "ﾀｹﾄﾖﾁｮｳ"
  },
  {
    "groupCode": "235016",
    "prefectureNameKanji": "愛知県",
    "cityNameKanji": "幸田町",
    "prefectureNameKatakana": "ｱｲﾁｹﾝ",
    "cityNameKatakana": "ｺｳﾀﾁｮｳ"
  },
  {
    "groupCode": "235610",
    "prefectureNameKanji": "愛知県",
    "cityNameKanji": "設楽町",
    "prefectureNameKatakana": "ｱｲﾁｹﾝ",
    "cityNameKatakana": "ｼﾀﾗﾁｮｳ"
  },
  {
    "groupCode": "235628",
    "prefectureNameKanji": "愛知県",
    "cityNameKanji": "東栄町",
    "prefectureNameKatakana": "ｱｲﾁｹﾝ",
    "cityNameKatakana": "ﾄｳｴｲﾁｮｳ"
  },
  {
    "groupCode": "235636",
    "prefectureNameKanji": "愛知県",
    "cityNameKanji": "豊根村",
    "prefectureNameKatakana": "ｱｲﾁｹﾝ",
    "cityNameKatakana": "ﾄﾖﾈﾑﾗ"
  },
  {
    "groupCode": "242012",
    "prefectureNameKanji": "三重県",
    "cityNameKanji": "津市",
    "prefectureNameKatakana": "ﾐｴｹﾝ",
    "cityNameKatakana": "ﾂｼ"
  },
  {
    "groupCode": "242021",
    "prefectureNameKanji": "三重県",
    "cityNameKanji": "四日市市",
    "prefectureNameKatakana": "ﾐｴｹﾝ",
    "cityNameKatakana": "ﾖｯｶｲﾁｼ"
  },
  {
    "groupCode": "242039",
    "prefectureNameKanji": "三重県",
    "cityNameKanji": "伊勢市",
    "prefectureNameKatakana": "ﾐｴｹﾝ",
    "cityNameKatakana": "ｲｾｼ"
  },
  {
    "groupCode": "242047",
    "prefectureNameKanji": "三重県",
    "cityNameKanji": "松阪市",
    "prefectureNameKatakana": "ﾐｴｹﾝ",
    "cityNameKatakana": "ﾏﾂｻｶｼ"
  },
  {
    "groupCode": "242055",
    "prefectureNameKanji": "三重県",
    "cityNameKanji": "桑名市",
    "prefectureNameKatakana": "ﾐｴｹﾝ",
    "cityNameKatakana": "ｸﾜﾅｼ"
  },
  {
    "groupCode": "242071",
    "prefectureNameKanji": "三重県",
    "cityNameKanji": "鈴鹿市",
    "prefectureNameKatakana": "ﾐｴｹﾝ",
    "cityNameKatakana": "ｽｽﾞｶｼ"
  },
  {
    "groupCode": "242080",
    "prefectureNameKanji": "三重県",
    "cityNameKanji": "名張市",
    "prefectureNameKatakana": "ﾐｴｹﾝ",
    "cityNameKatakana": "ﾅﾊﾞﾘｼ"
  },
  {
    "groupCode": "242098",
    "prefectureNameKanji": "三重県",
    "cityNameKanji": "尾鷲市",
    "prefectureNameKatakana": "ﾐｴｹﾝ",
    "cityNameKatakana": "ｵﾜｾｼ"
  },
  {
    "groupCode": "242101",
    "prefectureNameKanji": "三重県",
    "cityNameKanji": "亀山市",
    "prefectureNameKatakana": "ﾐｴｹﾝ",
    "cityNameKatakana": "ｶﾒﾔﾏｼ"
  },
  {
    "groupCode": "242110",
    "prefectureNameKanji": "三重県",
    "cityNameKanji": "鳥羽市",
    "prefectureNameKatakana": "ﾐｴｹﾝ",
    "cityNameKatakana": "ﾄﾊﾞｼ"
  },
  {
    "groupCode": "242128",
    "prefectureNameKanji": "三重県",
    "cityNameKanji": "熊野市",
    "prefectureNameKatakana": "ﾐｴｹﾝ",
    "cityNameKatakana": "ｸﾏﾉｼ"
  },
  {
    "groupCode": "242144",
    "prefectureNameKanji": "三重県",
    "cityNameKanji": "いなべ市",
    "prefectureNameKatakana": "ﾐｴｹﾝ",
    "cityNameKatakana": "ｲﾅﾍﾞｼ"
  },
  {
    "groupCode": "242152",
    "prefectureNameKanji": "三重県",
    "cityNameKanji": "志摩市",
    "prefectureNameKatakana": "ﾐｴｹﾝ",
    "cityNameKatakana": "ｼﾏｼ"
  },
  {
    "groupCode": "242161",
    "prefectureNameKanji": "三重県",
    "cityNameKanji": "伊賀市",
    "prefectureNameKatakana": "ﾐｴｹﾝ",
    "cityNameKatakana": "ｲｶﾞｼ"
  },
  {
    "groupCode": "243035",
    "prefectureNameKanji": "三重県",
    "cityNameKanji": "木曽岬町",
    "prefectureNameKatakana": "ﾐｴｹﾝ",
    "cityNameKatakana": "ｷｿｻｷﾁｮｳ"
  },
  {
    "groupCode": "243248",
    "prefectureNameKanji": "三重県",
    "cityNameKanji": "東員町",
    "prefectureNameKatakana": "ﾐｴｹﾝ",
    "cityNameKatakana": "ﾄｳｲﾝﾁｮｳ"
  },
  {
    "groupCode": "243418",
    "prefectureNameKanji": "三重県",
    "cityNameKanji": "菰野町",
    "prefectureNameKatakana": "ﾐｴｹﾝ",
    "cityNameKatakana": "ｺﾓﾉﾁｮｳ"
  },
  {
    "groupCode": "243434",
    "prefectureNameKanji": "三重県",
    "cityNameKanji": "朝日町",
    "prefectureNameKatakana": "ﾐｴｹﾝ",
    "cityNameKatakana": "ｱｻﾋﾁｮｳ"
  },
  {
    "groupCode": "243442",
    "prefectureNameKanji": "三重県",
    "cityNameKanji": "川越町",
    "prefectureNameKatakana": "ﾐｴｹﾝ",
    "cityNameKatakana": "ｶﾜｺﾞｴﾁｮｳ"
  },
  {
    "groupCode": "244414",
    "prefectureNameKanji": "三重県",
    "cityNameKanji": "多気町",
    "prefectureNameKatakana": "ﾐｴｹﾝ",
    "cityNameKatakana": "ﾀｷﾁｮｳ"
  },
  {
    "groupCode": "244422",
    "prefectureNameKanji": "三重県",
    "cityNameKanji": "明和町",
    "prefectureNameKatakana": "ﾐｴｹﾝ",
    "cityNameKatakana": "ﾒｲﾜﾁｮｳ"
  },
  {
    "groupCode": "244431",
    "prefectureNameKanji": "三重県",
    "cityNameKanji": "大台町",
    "prefectureNameKatakana": "ﾐｴｹﾝ",
    "cityNameKatakana": "ｵｵﾀﾞｲﾁｮｳ"
  },
  {
    "groupCode": "244619",
    "prefectureNameKanji": "三重県",
    "cityNameKanji": "玉城町",
    "prefectureNameKatakana": "ﾐｴｹﾝ",
    "cityNameKatakana": "ﾀﾏｷﾁｮｳ"
  },
  {
    "groupCode": "244708",
    "prefectureNameKanji": "三重県",
    "cityNameKanji": "度会町",
    "prefectureNameKatakana": "ﾐｴｹﾝ",
    "cityNameKatakana": "ﾜﾀﾗｲﾁｮｳ"
  },
  {
    "groupCode": "244716",
    "prefectureNameKanji": "三重県",
    "cityNameKanji": "大紀町",
    "prefectureNameKatakana": "ﾐｴｹﾝ",
    "cityNameKatakana": "ﾀｲｷﾁｮｳ"
  },
  {
    "groupCode": "244724",
    "prefectureNameKanji": "三重県",
    "cityNameKanji": "南伊勢町",
    "prefectureNameKatakana": "ﾐｴｹﾝ",
    "cityNameKatakana": "ﾐﾅﾐｲｾﾁｮｳ"
  },
  {
    "groupCode": "245437",
    "prefectureNameKanji": "三重県",
    "cityNameKanji": "紀北町",
    "prefectureNameKatakana": "ﾐｴｹﾝ",
    "cityNameKatakana": "ｷﾎｸﾁｮｳ"
  },
  {
    "groupCode": "245615",
    "prefectureNameKanji": "三重県",
    "cityNameKanji": "御浜町",
    "prefectureNameKatakana": "ﾐｴｹﾝ",
    "cityNameKatakana": "ﾐﾊﾏﾁｮｳ"
  },
  {
    "groupCode": "245623",
    "prefectureNameKanji": "三重県",
    "cityNameKanji": "紀宝町",
    "prefectureNameKatakana": "ﾐｴｹﾝ",
    "cityNameKatakana": "ｷﾎｳﾁｮｳ"
  },
  {
    "groupCode": "252018",
    "prefectureNameKanji": "滋賀県",
    "cityNameKanji": "大津市",
    "prefectureNameKatakana": "ｼｶﾞｹﾝ",
    "cityNameKatakana": "ｵｵﾂｼ"
  },
  {
    "groupCode": "252026",
    "prefectureNameKanji": "滋賀県",
    "cityNameKanji": "彦根市",
    "prefectureNameKatakana": "ｼｶﾞｹﾝ",
    "cityNameKatakana": "ﾋｺﾈｼ"
  },
  {
    "groupCode": "252034",
    "prefectureNameKanji": "滋賀県",
    "cityNameKanji": "長浜市",
    "prefectureNameKatakana": "ｼｶﾞｹﾝ",
    "cityNameKatakana": "ﾅｶﾞﾊﾏｼ"
  },
  {
    "groupCode": "252042",
    "prefectureNameKanji": "滋賀県",
    "cityNameKanji": "近江八幡市",
    "prefectureNameKatakana": "ｼｶﾞｹﾝ",
    "cityNameKatakana": "ｵｳﾐﾊﾁﾏﾝｼ"
  },
  {
    "groupCode": "252069",
    "prefectureNameKanji": "滋賀県",
    "cityNameKanji": "草津市",
    "prefectureNameKatakana": "ｼｶﾞｹﾝ",
    "cityNameKatakana": "ｸｻﾂｼ"
  },
  {
    "groupCode": "252077",
    "prefectureNameKanji": "滋賀県",
    "cityNameKanji": "守山市",
    "prefectureNameKatakana": "ｼｶﾞｹﾝ",
    "cityNameKatakana": "ﾓﾘﾔﾏｼ"
  },
  {
    "groupCode": "252085",
    "prefectureNameKanji": "滋賀県",
    "cityNameKanji": "栗東市",
    "prefectureNameKatakana": "ｼｶﾞｹﾝ",
    "cityNameKatakana": "ﾘｯﾄｳｼ"
  },
  {
    "groupCode": "252093",
    "prefectureNameKanji": "滋賀県",
    "cityNameKanji": "甲賀市",
    "prefectureNameKatakana": "ｼｶﾞｹﾝ",
    "cityNameKatakana": "ｺｳｶｼ"
  },
  {
    "groupCode": "252107",
    "prefectureNameKanji": "滋賀県",
    "cityNameKanji": "野洲市",
    "prefectureNameKatakana": "ｼｶﾞｹﾝ",
    "cityNameKatakana": "ﾔｽｼ"
  },
  {
    "groupCode": "252115",
    "prefectureNameKanji": "滋賀県",
    "cityNameKanji": "湖南市",
    "prefectureNameKatakana": "ｼｶﾞｹﾝ",
    "cityNameKatakana": "ｺﾅﾝｼ"
  },
  {
    "groupCode": "252123",
    "prefectureNameKanji": "滋賀県",
    "cityNameKanji": "高島市",
    "prefectureNameKatakana": "ｼｶﾞｹﾝ",
    "cityNameKatakana": "ﾀｶｼﾏｼ"
  },
  {
    "groupCode": "252131",
    "prefectureNameKanji": "滋賀県",
    "cityNameKanji": "東近江市",
    "prefectureNameKatakana": "ｼｶﾞｹﾝ",
    "cityNameKatakana": "ﾋｶﾞｼｵｳﾐｼ"
  },
  {
    "groupCode": "252140",
    "prefectureNameKanji": "滋賀県",
    "cityNameKanji": "米原市",
    "prefectureNameKatakana": "ｼｶﾞｹﾝ",
    "cityNameKatakana": "ﾏｲﾊﾞﾗｼ"
  },
  {
    "groupCode": "253839",
    "prefectureNameKanji": "滋賀県",
    "cityNameKanji": "日野町",
    "prefectureNameKatakana": "ｼｶﾞｹﾝ",
    "cityNameKatakana": "ﾋﾉﾁｮｳ"
  },
  {
    "groupCode": "253847",
    "prefectureNameKanji": "滋賀県",
    "cityNameKanji": "竜王町",
    "prefectureNameKatakana": "ｼｶﾞｹﾝ",
    "cityNameKatakana": "ﾘﾕｳｵｳﾁｮｳ"
  },
  {
    "groupCode": "254258",
    "prefectureNameKanji": "滋賀県",
    "cityNameKanji": "愛荘町",
    "prefectureNameKatakana": "ｼｶﾞｹﾝ",
    "cityNameKatakana": "ｱｲｼｮｳﾁｮｳ"
  },
  {
    "groupCode": "254410",
    "prefectureNameKanji": "滋賀県",
    "cityNameKanji": "豊郷町",
    "prefectureNameKatakana": "ｼｶﾞｹﾝ",
    "cityNameKatakana": "ﾄﾖｻﾄﾁｮｳ"
  },
  {
    "groupCode": "254428",
    "prefectureNameKanji": "滋賀県",
    "cityNameKanji": "甲良町",
    "prefectureNameKatakana": "ｼｶﾞｹﾝ",
    "cityNameKatakana": "ｺｳﾗﾁｮｳ"
  },
  {
    "groupCode": "254436",
    "prefectureNameKanji": "滋賀県",
    "cityNameKanji": "多賀町",
    "prefectureNameKatakana": "ｼｶﾞｹﾝ",
    "cityNameKatakana": "ﾀｶﾞﾁｮｳ"
  },
  {
    "groupCode": "261009",
    "prefectureNameKanji": "京都府",
    "cityNameKanji": "京都市",
    "prefectureNameKatakana": "ｷｮｳﾄﾌ",
    "cityNameKatakana": "ｷｮｳﾄｼ"
  },
  {
    "groupCode": "262013",
    "prefectureNameKanji": "京都府",
    "cityNameKanji": "福知山市",
    "prefectureNameKatakana": "ｷｮｳﾄﾌ",
    "cityNameKatakana": "ﾌｸﾁﾔﾏｼ"
  },
  {
    "groupCode": "262021",
    "prefectureNameKanji": "京都府",
    "cityNameKanji": "舞鶴市",
    "prefectureNameKatakana": "ｷｮｳﾄﾌ",
    "cityNameKatakana": "ﾏｲﾂﾞﾙｼ"
  },
  {
    "groupCode": "262030",
    "prefectureNameKanji": "京都府",
    "cityNameKanji": "綾部市",
    "prefectureNameKatakana": "ｷｮｳﾄﾌ",
    "cityNameKatakana": "ｱﾔﾍﾞｼ"
  },
  {
    "groupCode": "262048",
    "prefectureNameKanji": "京都府",
    "cityNameKanji": "宇治市",
    "prefectureNameKatakana": "ｷｮｳﾄﾌ",
    "cityNameKatakana": "ｳｼﾞｼ"
  },
  {
    "groupCode": "262056",
    "prefectureNameKanji": "京都府",
    "cityNameKanji": "宮津市",
    "prefectureNameKatakana": "ｷｮｳﾄﾌ",
    "cityNameKatakana": "ﾐﾔﾂﾞｼ"
  },
  {
    "groupCode": "262064",
    "prefectureNameKanji": "京都府",
    "cityNameKanji": "亀岡市",
    "prefectureNameKatakana": "ｷｮｳﾄﾌ",
    "cityNameKatakana": "ｶﾒｵｶｼ"
  },
  {
    "groupCode": "262072",
    "prefectureNameKanji": "京都府",
    "cityNameKanji": "城陽市",
    "prefectureNameKatakana": "ｷｮｳﾄﾌ",
    "cityNameKatakana": "ｼﾞｮｳﾖｳｼ"
  },
  {
    "groupCode": "262081",
    "prefectureNameKanji": "京都府",
    "cityNameKanji": "向日市",
    "prefectureNameKatakana": "ｷｮｳﾄﾌ",
    "cityNameKatakana": "ﾑｺｳｼ"
  },
  {
    "groupCode": "262099",
    "prefectureNameKanji": "京都府",
    "cityNameKanji": "長岡京市",
    "prefectureNameKatakana": "ｷｮｳﾄﾌ",
    "cityNameKatakana": "ﾅｶﾞｵｶｷｮｳｼ"
  },
  {
    "groupCode": "262102",
    "prefectureNameKanji": "京都府",
    "cityNameKanji": "八幡市",
    "prefectureNameKatakana": "ｷｮｳﾄﾌ",
    "cityNameKatakana": "ﾔﾜﾀｼ"
  },
  {
    "groupCode": "262111",
    "prefectureNameKanji": "京都府",
    "cityNameKanji": "京田辺市",
    "prefectureNameKatakana": "ｷｮｳﾄﾌ",
    "cityNameKatakana": "ｷｮｳﾀﾅﾍﾞｼ"
  },
  {
    "groupCode": "262129",
    "prefectureNameKanji": "京都府",
    "cityNameKanji": "京丹後市",
    "prefectureNameKatakana": "ｷｮｳﾄﾌ",
    "cityNameKatakana": "ｷｮｳﾀﾝｺﾞｼ"
  },
  {
    "groupCode": "262137",
    "prefectureNameKanji": "京都府",
    "cityNameKanji": "南丹市",
    "prefectureNameKatakana": "ｷｮｳﾄﾌ",
    "cityNameKatakana": "ﾅﾝﾀﾝｼ"
  },
  {
    "groupCode": "262145",
    "prefectureNameKanji": "京都府",
    "cityNameKanji": "木津川市",
    "prefectureNameKatakana": "ｷｮｳﾄﾌ",
    "cityNameKatakana": "ｷﾂﾞｶﾞﾜｼ"
  },
  {
    "groupCode": "263036",
    "prefectureNameKanji": "京都府",
    "cityNameKanji": "大山崎町",
    "prefectureNameKatakana": "ｷｮｳﾄﾌ",
    "cityNameKatakana": "ｵｵﾔﾏｻﾞｷﾁｮｳ"
  },
  {
    "groupCode": "263222",
    "prefectureNameKanji": "京都府",
    "cityNameKanji": "久御山町",
    "prefectureNameKatakana": "ｷｮｳﾄﾌ",
    "cityNameKatakana": "ｸﾐﾔﾏﾁｮｳ"
  },
  {
    "groupCode": "263435",
    "prefectureNameKanji": "京都府",
    "cityNameKanji": "井手町",
    "prefectureNameKatakana": "ｷｮｳﾄﾌ",
    "cityNameKatakana": "ｲﾃﾞﾁｮｳ"
  },
  {
    "groupCode": "263443",
    "prefectureNameKanji": "京都府",
    "cityNameKanji": "宇治田原町",
    "prefectureNameKatakana": "ｷｮｳﾄﾌ",
    "cityNameKatakana": "ｳｼﾞﾀﾜﾗﾁｮｳ"
  },
  {
    "groupCode": "263648",
    "prefectureNameKanji": "京都府",
    "cityNameKanji": "笠置町",
    "prefectureNameKatakana": "ｷｮｳﾄﾌ",
    "cityNameKatakana": "ｶｻｷﾞﾁｮｳ"
  },
  {
    "groupCode": "263656",
    "prefectureNameKanji": "京都府",
    "cityNameKanji": "和束町",
    "prefectureNameKatakana": "ｷｮｳﾄﾌ",
    "cityNameKatakana": "ﾜﾂﾞｶﾁｮｳ"
  },
  {
    "groupCode": "263664",
    "prefectureNameKanji": "京都府",
    "cityNameKanji": "精華町",
    "prefectureNameKatakana": "ｷｮｳﾄﾌ",
    "cityNameKatakana": "ｾｲｶﾁｮｳ"
  },
  {
    "groupCode": "263672",
    "prefectureNameKanji": "京都府",
    "cityNameKanji": "南山城村",
    "prefectureNameKatakana": "ｷｮｳﾄﾌ",
    "cityNameKatakana": "ﾐﾅﾐﾔﾏｼﾛﾑﾗ"
  },
  {
    "groupCode": "264075",
    "prefectureNameKanji": "京都府",
    "cityNameKanji": "京丹波町",
    "prefectureNameKatakana": "ｷｮｳﾄﾌ",
    "cityNameKatakana": "ｷｮｳﾀﾝﾊﾞﾁｮｳ"
  },
  {
    "groupCode": "264636",
    "prefectureNameKanji": "京都府",
    "cityNameKanji": "伊根町",
    "prefectureNameKatakana": "ｷｮｳﾄﾌ",
    "cityNameKatakana": "ｲﾈﾁｮｳ"
  },
  {
    "groupCode": "264652",
    "prefectureNameKanji": "京都府",
    "cityNameKanji": "与謝野町",
    "prefectureNameKatakana": "ｷｮｳﾄﾌ",
    "cityNameKatakana": "ﾖｻﾉﾁｮｳ"
  },
  {
    "groupCode": "271004",
    "prefectureNameKanji": "大阪府",
    "cityNameKanji": "大阪市",
    "prefectureNameKatakana": "ｵｵｻｶﾌ",
    "cityNameKatakana": "ｵｵｻｶｼ"
  },
  {
    "groupCode": "271403",
    "prefectureNameKanji": "大阪府",
    "cityNameKanji": "堺市",
    "prefectureNameKatakana": "ｵｵｻｶﾌ",
    "cityNameKatakana": "ｻｶｲｼ"
  },
  {
    "groupCode": "272027",
    "prefectureNameKanji": "大阪府",
    "cityNameKanji": "岸和田市",
    "prefectureNameKatakana": "ｵｵｻｶﾌ",
    "cityNameKatakana": "ｷｼﾜﾀﾞｼ"
  },
  {
    "groupCode": "272035",
    "prefectureNameKanji": "大阪府",
    "cityNameKanji": "豊中市",
    "prefectureNameKatakana": "ｵｵｻｶﾌ",
    "cityNameKatakana": "ﾄﾖﾅｶｼ"
  },
  {
    "groupCode": "272043",
    "prefectureNameKanji": "大阪府",
    "cityNameKanji": "池田市",
    "prefectureNameKatakana": "ｵｵｻｶﾌ",
    "cityNameKatakana": "ｲｹﾀﾞｼ"
  },
  {
    "groupCode": "272051",
    "prefectureNameKanji": "大阪府",
    "cityNameKanji": "吹田市",
    "prefectureNameKatakana": "ｵｵｻｶﾌ",
    "cityNameKatakana": "ｽｲﾀｼ"
  },
  {
    "groupCode": "272060",
    "prefectureNameKanji": "大阪府",
    "cityNameKanji": "泉大津市",
    "prefectureNameKatakana": "ｵｵｻｶﾌ",
    "cityNameKatakana": "ｲｽﾞﾐｵｵﾂｼ"
  },
  {
    "groupCode": "272078",
    "prefectureNameKanji": "大阪府",
    "cityNameKanji": "高槻市",
    "prefectureNameKatakana": "ｵｵｻｶﾌ",
    "cityNameKatakana": "ﾀｶﾂｷｼ"
  },
  {
    "groupCode": "272086",
    "prefectureNameKanji": "大阪府",
    "cityNameKanji": "貝塚市",
    "prefectureNameKatakana": "ｵｵｻｶﾌ",
    "cityNameKatakana": "ｶｲﾂﾞｶｼ"
  },
  {
    "groupCode": "272094",
    "prefectureNameKanji": "大阪府",
    "cityNameKanji": "守口市",
    "prefectureNameKatakana": "ｵｵｻｶﾌ",
    "cityNameKatakana": "ﾓﾘｸﾞﾁｼ"
  },
  {
    "groupCode": "272108",
    "prefectureNameKanji": "大阪府",
    "cityNameKanji": "枚方市",
    "prefectureNameKatakana": "ｵｵｻｶﾌ",
    "cityNameKatakana": "ﾋﾗｶﾀｼ"
  },
  {
    "groupCode": "272116",
    "prefectureNameKanji": "大阪府",
    "cityNameKanji": "茨木市",
    "prefectureNameKatakana": "ｵｵｻｶﾌ",
    "cityNameKatakana": "ｲﾊﾞﾗｷｼ"
  },
  {
    "groupCode": "272124",
    "prefectureNameKanji": "大阪府",
    "cityNameKanji": "八尾市",
    "prefectureNameKatakana": "ｵｵｻｶﾌ",
    "cityNameKatakana": "ﾔｵｼ"
  },
  {
    "groupCode": "272132",
    "prefectureNameKanji": "大阪府",
    "cityNameKanji": "泉佐野市",
    "prefectureNameKatakana": "ｵｵｻｶﾌ",
    "cityNameKatakana": "ｲｽﾞﾐｻﾉｼ"
  },
  {
    "groupCode": "272141",
    "prefectureNameKanji": "大阪府",
    "cityNameKanji": "富田林市",
    "prefectureNameKatakana": "ｵｵｻｶﾌ",
    "cityNameKatakana": "ﾄﾝﾀﾞﾊﾞﾔｼｼ"
  },
  {
    "groupCode": "272159",
    "prefectureNameKanji": "大阪府",
    "cityNameKanji": "寝屋川市",
    "prefectureNameKatakana": "ｵｵｻｶﾌ",
    "cityNameKatakana": "ﾈﾔｶﾞﾜｼ"
  },
  {
    "groupCode": "272167",
    "prefectureNameKanji": "大阪府",
    "cityNameKanji": "河内長野市",
    "prefectureNameKatakana": "ｵｵｻｶﾌ",
    "cityNameKatakana": "ｶﾜﾁﾅｶﾞﾉｼ"
  },
  {
    "groupCode": "272175",
    "prefectureNameKanji": "大阪府",
    "cityNameKanji": "松原市",
    "prefectureNameKatakana": "ｵｵｻｶﾌ",
    "cityNameKatakana": "ﾏﾂﾊﾞﾗｼ"
  },
  {
    "groupCode": "272183",
    "prefectureNameKanji": "大阪府",
    "cityNameKanji": "大東市",
    "prefectureNameKatakana": "ｵｵｻｶﾌ",
    "cityNameKatakana": "ﾀﾞｲﾄｳｼ"
  },
  {
    "groupCode": "272191",
    "prefectureNameKanji": "大阪府",
    "cityNameKanji": "和泉市",
    "prefectureNameKatakana": "ｵｵｻｶﾌ",
    "cityNameKatakana": "ｲｽﾞﾐｼ"
  },
  {
    "groupCode": "272205",
    "prefectureNameKanji": "大阪府",
    "cityNameKanji": "箕面市",
    "prefectureNameKatakana": "ｵｵｻｶﾌ",
    "cityNameKatakana": "ﾐﾉｵｼ"
  },
  {
    "groupCode": "272213",
    "prefectureNameKanji": "大阪府",
    "cityNameKanji": "柏原市",
    "prefectureNameKatakana": "ｵｵｻｶﾌ",
    "cityNameKatakana": "ｶｼﾜﾗｼ"
  },
  {
    "groupCode": "272221",
    "prefectureNameKanji": "大阪府",
    "cityNameKanji": "羽曳野市",
    "prefectureNameKatakana": "ｵｵｻｶﾌ",
    "cityNameKatakana": "ﾊﾋﾞｷﾉｼ"
  },
  {
    "groupCode": "272230",
    "prefectureNameKanji": "大阪府",
    "cityNameKanji": "門真市",
    "prefectureNameKatakana": "ｵｵｻｶﾌ",
    "cityNameKatakana": "ｶﾄﾞﾏｼ"
  },
  {
    "groupCode": "272248",
    "prefectureNameKanji": "大阪府",
    "cityNameKanji": "摂津市",
    "prefectureNameKatakana": "ｵｵｻｶﾌ",
    "cityNameKatakana": "ｾｯﾂｼ"
  },
  {
    "groupCode": "272256",
    "prefectureNameKanji": "大阪府",
    "cityNameKanji": "高石市",
    "prefectureNameKatakana": "ｵｵｻｶﾌ",
    "cityNameKatakana": "ﾀｶｲｼｼ"
  },
  {
    "groupCode": "272264",
    "prefectureNameKanji": "大阪府",
    "cityNameKanji": "藤井寺市",
    "prefectureNameKatakana": "ｵｵｻｶﾌ",
    "cityNameKatakana": "ﾌｼﾞｲﾃﾞﾗｼ"
  },
  {
    "groupCode": "272272",
    "prefectureNameKanji": "大阪府",
    "cityNameKanji": "東大阪市",
    "prefectureNameKatakana": "ｵｵｻｶﾌ",
    "cityNameKatakana": "ﾋｶﾞｼｵｵｻｶｼ"
  },
  {
    "groupCode": "272281",
    "prefectureNameKanji": "大阪府",
    "cityNameKanji": "泉南市",
    "prefectureNameKatakana": "ｵｵｻｶﾌ",
    "cityNameKatakana": "ｾﾝﾅﾝｼ"
  },
  {
    "groupCode": "272299",
    "prefectureNameKanji": "大阪府",
    "cityNameKanji": "四條畷市",
    "prefectureNameKatakana": "ｵｵｻｶﾌ",
    "cityNameKatakana": "ｼｼﾞﾖｳﾅﾜﾃｼ"
  },
  {
    "groupCode": "272302",
    "prefectureNameKanji": "大阪府",
    "cityNameKanji": "交野市",
    "prefectureNameKatakana": "ｵｵｻｶﾌ",
    "cityNameKatakana": "ｶﾀﾉｼ"
  },
  {
    "groupCode": "272311",
    "prefectureNameKanji": "大阪府",
    "cityNameKanji": "大阪狭山市",
    "prefectureNameKatakana": "ｵｵｻｶﾌ",
    "cityNameKatakana": "ｵｵｻｶｻﾔﾏｼ"
  },
  {
    "groupCode": "272329",
    "prefectureNameKanji": "大阪府",
    "cityNameKanji": "阪南市",
    "prefectureNameKatakana": "ｵｵｻｶﾌ",
    "cityNameKatakana": "ﾊﾝﾅﾝｼ"
  },
  {
    "groupCode": "273015",
    "prefectureNameKanji": "大阪府",
    "cityNameKanji": "島本町",
    "prefectureNameKatakana": "ｵｵｻｶﾌ",
    "cityNameKatakana": "ｼﾏﾓﾄﾁｮｳ"
  },
  {
    "groupCode": "273210",
    "prefectureNameKanji": "大阪府",
    "cityNameKanji": "豊能町",
    "prefectureNameKatakana": "ｵｵｻｶﾌ",
    "cityNameKatakana": "ﾄﾖﾉﾁｮｳ"
  },
  {
    "groupCode": "273228",
    "prefectureNameKanji": "大阪府",
    "cityNameKanji": "能勢町",
    "prefectureNameKatakana": "ｵｵｻｶﾌ",
    "cityNameKatakana": "ﾉｾﾁｮｳ"
  },
  {
    "groupCode": "273414",
    "prefectureNameKanji": "大阪府",
    "cityNameKanji": "忠岡町",
    "prefectureNameKatakana": "ｵｵｻｶﾌ",
    "cityNameKatakana": "ﾀﾀﾞｵｶﾁｮｳ"
  },
  {
    "groupCode": "273619",
    "prefectureNameKanji": "大阪府",
    "cityNameKanji": "熊取町",
    "prefectureNameKatakana": "ｵｵｻｶﾌ",
    "cityNameKatakana": "ｸﾏﾄﾘﾁｮｳ"
  },
  {
    "groupCode": "273627",
    "prefectureNameKanji": "大阪府",
    "cityNameKanji": "田尻町",
    "prefectureNameKatakana": "ｵｵｻｶﾌ",
    "cityNameKatakana": "ﾀｼﾞﾘﾁｮｳ"
  },
  {
    "groupCode": "273660",
    "prefectureNameKanji": "大阪府",
    "cityNameKanji": "岬町",
    "prefectureNameKatakana": "ｵｵｻｶﾌ",
    "cityNameKatakana": "ﾐｻｷﾁｮｳ"
  },
  {
    "groupCode": "273813",
    "prefectureNameKanji": "大阪府",
    "cityNameKanji": "太子町",
    "prefectureNameKatakana": "ｵｵｻｶﾌ",
    "cityNameKatakana": "ﾀｲｼﾁｮｳ"
  },
  {
    "groupCode": "273821",
    "prefectureNameKanji": "大阪府",
    "cityNameKanji": "河南町",
    "prefectureNameKatakana": "ｵｵｻｶﾌ",
    "cityNameKatakana": "ｶﾅﾝﾁｮｳ"
  },
  {
    "groupCode": "273830",
    "prefectureNameKanji": "大阪府",
    "cityNameKanji": "千早赤阪村",
    "prefectureNameKatakana": "ｵｵｻｶﾌ",
    "cityNameKatakana": "ﾁﾊﾔｱｶｻｶﾑﾗ"
  },
  {
    "groupCode": "281000",
    "prefectureNameKanji": "兵庫県",
    "cityNameKanji": "神戸市",
    "prefectureNameKatakana": "ﾋｮｳｺﾞｹﾝ",
    "cityNameKatakana": "ｺｳﾍﾞｼ"
  },
  {
    "groupCode": "282014",
    "prefectureNameKanji": "兵庫県",
    "cityNameKanji": "姫路市",
    "prefectureNameKatakana": "ﾋｮｳｺﾞｹﾝ",
    "cityNameKatakana": "ﾋﾒｼﾞｼ"
  },
  {
    "groupCode": "282022",
    "prefectureNameKanji": "兵庫県",
    "cityNameKanji": "尼崎市",
    "prefectureNameKatakana": "ﾋｮｳｺﾞｹﾝ",
    "cityNameKatakana": "ｱﾏｶﾞｻｷｼ"
  },
  {
    "groupCode": "282031",
    "prefectureNameKanji": "兵庫県",
    "cityNameKanji": "明石市",
    "prefectureNameKatakana": "ﾋｮｳｺﾞｹﾝ",
    "cityNameKatakana": "ｱｶｼｼ"
  },
  {
    "groupCode": "282049",
    "prefectureNameKanji": "兵庫県",
    "cityNameKanji": "西宮市",
    "prefectureNameKatakana": "ﾋｮｳｺﾞｹﾝ",
    "cityNameKatakana": "ﾆｼﾉﾐﾔｼ"
  },
  {
    "groupCode": "282057",
    "prefectureNameKanji": "兵庫県",
    "cityNameKanji": "洲本市",
    "prefectureNameKatakana": "ﾋｮｳｺﾞｹﾝ",
    "cityNameKatakana": "ｽﾓﾄｼ"
  },
  {
    "groupCode": "282065",
    "prefectureNameKanji": "兵庫県",
    "cityNameKanji": "芦屋市",
    "prefectureNameKatakana": "ﾋｮｳｺﾞｹﾝ",
    "cityNameKatakana": "ｱｼﾔｼ"
  },
  {
    "groupCode": "282073",
    "prefectureNameKanji": "兵庫県",
    "cityNameKanji": "伊丹市",
    "prefectureNameKatakana": "ﾋｮｳｺﾞｹﾝ",
    "cityNameKatakana": "ｲﾀﾐｼ"
  },
  {
    "groupCode": "282081",
    "prefectureNameKanji": "兵庫県",
    "cityNameKanji": "相生市",
    "prefectureNameKatakana": "ﾋｮｳｺﾞｹﾝ",
    "cityNameKatakana": "ｱｲｵｲｼ"
  },
  {
    "groupCode": "282090",
    "prefectureNameKanji": "兵庫県",
    "cityNameKanji": "豊岡市",
    "prefectureNameKatakana": "ﾋｮｳｺﾞｹﾝ",
    "cityNameKatakana": "ﾄﾖｵｶｼ"
  },
  {
    "groupCode": "282103",
    "prefectureNameKanji": "兵庫県",
    "cityNameKanji": "加古川市",
    "prefectureNameKatakana": "ﾋｮｳｺﾞｹﾝ",
    "cityNameKatakana": "ｶｺｶﾞﾜｼ"
  },
  {
    "groupCode": "282120",
    "prefectureNameKanji": "兵庫県",
    "cityNameKanji": "赤穂市",
    "prefectureNameKatakana": "ﾋｮｳｺﾞｹﾝ",
    "cityNameKatakana": "ｱｺｳｼ"
  },
  {
    "groupCode": "282138",
    "prefectureNameKanji": "兵庫県",
    "cityNameKanji": "西脇市",
    "prefectureNameKatakana": "ﾋｮｳｺﾞｹﾝ",
    "cityNameKatakana": "ﾆｼﾜｷｼ"
  },
  {
    "groupCode": "282146",
    "prefectureNameKanji": "兵庫県",
    "cityNameKanji": "宝塚市",
    "prefectureNameKatakana": "ﾋｮｳｺﾞｹﾝ",
    "cityNameKatakana": "ﾀｶﾗﾂﾞｶｼ"
  },
  {
    "groupCode": "282154",
    "prefectureNameKanji": "兵庫県",
    "cityNameKanji": "三木市",
    "prefectureNameKatakana": "ﾋｮｳｺﾞｹﾝ",
    "cityNameKatakana": "ﾐｷｼ"
  },
  {
    "groupCode": "282162",
    "prefectureNameKanji": "兵庫県",
    "cityNameKanji": "高砂市",
    "prefectureNameKatakana": "ﾋｮｳｺﾞｹﾝ",
    "cityNameKatakana": "ﾀｶｻｺﾞｼ"
  },
  {
    "groupCode": "282171",
    "prefectureNameKanji": "兵庫県",
    "cityNameKanji": "川西市",
    "prefectureNameKatakana": "ﾋｮｳｺﾞｹﾝ",
    "cityNameKatakana": "ｶﾜﾆｼｼ"
  },
  {
    "groupCode": "282189",
    "prefectureNameKanji": "兵庫県",
    "cityNameKanji": "小野市",
    "prefectureNameKatakana": "ﾋｮｳｺﾞｹﾝ",
    "cityNameKatakana": "ｵﾉｼ"
  },
  {
    "groupCode": "282197",
    "prefectureNameKanji": "兵庫県",
    "cityNameKanji": "三田市",
    "prefectureNameKatakana": "ﾋｮｳｺﾞｹﾝ",
    "cityNameKatakana": "ｻﾝﾀﾞｼ"
  },
  {
    "groupCode": "282201",
    "prefectureNameKanji": "兵庫県",
    "cityNameKanji": "加西市",
    "prefectureNameKatakana": "ﾋｮｳｺﾞｹﾝ",
    "cityNameKatakana": "ｶｻｲｼ"
  },
  {
    "groupCode": "282219",
    "prefectureNameKanji": "兵庫県",
    "cityNameKanji": "丹波篠山市",
    "prefectureNameKatakana": "ﾋｮｳｺﾞｹﾝ",
    "cityNameKatakana": "ﾀﾝﾊﾞｻｻﾔﾏｼ"
  },
  {
    "groupCode": "282227",
    "prefectureNameKanji": "兵庫県",
    "cityNameKanji": "養父市",
    "prefectureNameKatakana": "ﾋｮｳｺﾞｹﾝ",
    "cityNameKatakana": "ﾔﾌﾞｼ"
  },
  {
    "groupCode": "282235",
    "prefectureNameKanji": "兵庫県",
    "cityNameKanji": "丹波市",
    "prefectureNameKatakana": "ﾋｮｳｺﾞｹﾝ",
    "cityNameKatakana": "ﾀﾝﾊﾞｼ"
  },
  {
    "groupCode": "282243",
    "prefectureNameKanji": "兵庫県",
    "cityNameKanji": "南あわじ市",
    "prefectureNameKatakana": "ﾋｮｳｺﾞｹﾝ",
    "cityNameKatakana": "ﾐﾅﾐｱﾜｼﾞｼ"
  },
  {
    "groupCode": "282251",
    "prefectureNameKanji": "兵庫県",
    "cityNameKanji": "朝来市",
    "prefectureNameKatakana": "ﾋｮｳｺﾞｹﾝ",
    "cityNameKatakana": "ｱｻｺﾞｼ"
  },
  {
    "groupCode": "282260",
    "prefectureNameKanji": "兵庫県",
    "cityNameKanji": "淡路市",
    "prefectureNameKatakana": "ﾋｮｳｺﾞｹﾝ",
    "cityNameKatakana": "ｱﾜｼﾞｼ"
  },
  {
    "groupCode": "282278",
    "prefectureNameKanji": "兵庫県",
    "cityNameKanji": "宍粟市",
    "prefectureNameKatakana": "ﾋｮｳｺﾞｹﾝ",
    "cityNameKatakana": "ｼｿｳｼ"
  },
  {
    "groupCode": "282286",
    "prefectureNameKanji": "兵庫県",
    "cityNameKanji": "加東市",
    "prefectureNameKatakana": "ﾋｮｳｺﾞｹﾝ",
    "cityNameKatakana": "ｶﾄｳｼ"
  },
  {
    "groupCode": "282294",
    "prefectureNameKanji": "兵庫県",
    "cityNameKanji": "たつの市",
    "prefectureNameKatakana": "ﾋｮｳｺﾞｹﾝ",
    "cityNameKatakana": "ﾀﾂﾉｼ"
  },
  {
    "groupCode": "283011",
    "prefectureNameKanji": "兵庫県",
    "cityNameKanji": "猪名川町",
    "prefectureNameKatakana": "ﾋｮｳｺﾞｹﾝ",
    "cityNameKatakana": "ｲﾅｶﾞﾜﾁｮｳ"
  },
  {
    "groupCode": "283657",
    "prefectureNameKanji": "兵庫県",
    "cityNameKanji": "多可町",
    "prefectureNameKatakana": "ﾋｮｳｺﾞｹﾝ",
    "cityNameKatakana": "ﾀｶﾁｮｳ"
  },
  {
    "groupCode": "283819",
    "prefectureNameKanji": "兵庫県",
    "cityNameKanji": "稲美町",
    "prefectureNameKatakana": "ﾋｮｳｺﾞｹﾝ",
    "cityNameKatakana": "ｲﾅﾐﾁｮｳ"
  },
  {
    "groupCode": "283827",
    "prefectureNameKanji": "兵庫県",
    "cityNameKanji": "播磨町",
    "prefectureNameKatakana": "ﾋｮｳｺﾞｹﾝ",
    "cityNameKatakana": "ﾊﾘﾏﾁｮｳ"
  },
  {
    "groupCode": "284424",
    "prefectureNameKanji": "兵庫県",
    "cityNameKanji": "市川町",
    "prefectureNameKatakana": "ﾋｮｳｺﾞｹﾝ",
    "cityNameKatakana": "ｲﾁｶﾜﾁｮｳ"
  },
  {
    "groupCode": "284432",
    "prefectureNameKanji": "兵庫県",
    "cityNameKanji": "福崎町",
    "prefectureNameKatakana": "ﾋｮｳｺﾞｹﾝ",
    "cityNameKatakana": "ﾌｸｻｷﾁｮｳ"
  },
  {
    "groupCode": "284467",
    "prefectureNameKanji": "兵庫県",
    "cityNameKanji": "神河町",
    "prefectureNameKatakana": "ﾋｮｳｺﾞｹﾝ",
    "cityNameKatakana": "ｶﾐｶﾜﾁｮｳ"
  },
  {
    "groupCode": "284645",
    "prefectureNameKanji": "兵庫県",
    "cityNameKanji": "太子町",
    "prefectureNameKatakana": "ﾋｮｳｺﾞｹﾝ",
    "cityNameKatakana": "ﾀｲｼﾁｮｳ"
  },
  {
    "groupCode": "284815",
    "prefectureNameKanji": "兵庫県",
    "cityNameKanji": "上郡町",
    "prefectureNameKatakana": "ﾋｮｳｺﾞｹﾝ",
    "cityNameKatakana": "ｶﾐｺﾞｵﾘﾁｮｳ"
  },
  {
    "groupCode": "285013",
    "prefectureNameKanji": "兵庫県",
    "cityNameKanji": "佐用町",
    "prefectureNameKatakana": "ﾋｮｳｺﾞｹﾝ",
    "cityNameKatakana": "ｻﾖｳﾁｮｳ"
  },
  {
    "groupCode": "285854",
    "prefectureNameKanji": "兵庫県",
    "cityNameKanji": "香美町",
    "prefectureNameKatakana": "ﾋｮｳｺﾞｹﾝ",
    "cityNameKatakana": "ｶﾐﾁｮｳ"
  },
  {
    "groupCode": "285862",
    "prefectureNameKanji": "兵庫県",
    "cityNameKanji": "新温泉町",
    "prefectureNameKatakana": "ﾋｮｳｺﾞｹﾝ",
    "cityNameKatakana": "ｼﾝｵﾝｾﾝﾁｮｳ"
  },
  {
    "groupCode": "292010",
    "prefectureNameKanji": "奈良県",
    "cityNameKanji": "奈良市",
    "prefectureNameKatakana": "ﾅﾗｹﾝ",
    "cityNameKatakana": "ﾅﾗｼ"
  },
  {
    "groupCode": "292028",
    "prefectureNameKanji": "奈良県",
    "cityNameKanji": "大和高田市",
    "prefectureNameKatakana": "ﾅﾗｹﾝ",
    "cityNameKatakana": "ﾔﾏﾄﾀｶﾀﾞｼ"
  },
  {
    "groupCode": "292036",
    "prefectureNameKanji": "奈良県",
    "cityNameKanji": "大和郡山市",
    "prefectureNameKatakana": "ﾅﾗｹﾝ",
    "cityNameKatakana": "ﾔﾏﾄｺｵﾘﾔﾏｼ"
  },
  {
    "groupCode": "292044",
    "prefectureNameKanji": "奈良県",
    "cityNameKanji": "天理市",
    "prefectureNameKatakana": "ﾅﾗｹﾝ",
    "cityNameKatakana": "ﾃﾝﾘｼ"
  },
  {
    "groupCode": "292052",
    "prefectureNameKanji": "奈良県",
    "cityNameKanji": "橿原市",
    "prefectureNameKatakana": "ﾅﾗｹﾝ",
    "cityNameKatakana": "ｶｼﾊﾗｼ"
  },
  {
    "groupCode": "292061",
    "prefectureNameKanji": "奈良県",
    "cityNameKanji": "桜井市",
    "prefectureNameKatakana": "ﾅﾗｹﾝ",
    "cityNameKatakana": "ｻｸﾗｲｼ"
  },
  {
    "groupCode": "292079",
    "prefectureNameKanji": "奈良県",
    "cityNameKanji": "五條市",
    "prefectureNameKatakana": "ﾅﾗｹﾝ",
    "cityNameKatakana": "ｺﾞｼﾞｮｳｼ"
  },
  {
    "groupCode": "292087",
    "prefectureNameKanji": "奈良県",
    "cityNameKanji": "御所市",
    "prefectureNameKatakana": "ﾅﾗｹﾝ",
    "cityNameKatakana": "ｺﾞｾｼ"
  },
  {
    "groupCode": "292095",
    "prefectureNameKanji": "奈良県",
    "cityNameKanji": "生駒市",
    "prefectureNameKatakana": "ﾅﾗｹﾝ",
    "cityNameKatakana": "ｲｺﾏｼ"
  },
  {
    "groupCode": "292109",
    "prefectureNameKanji": "奈良県",
    "cityNameKanji": "香芝市",
    "prefectureNameKatakana": "ﾅﾗｹﾝ",
    "cityNameKatakana": "ｶｼﾊﾞｼ"
  },
  {
    "groupCode": "292117",
    "prefectureNameKanji": "奈良県",
    "cityNameKanji": "葛城市",
    "prefectureNameKatakana": "ﾅﾗｹﾝ",
    "cityNameKatakana": "ｶﾂﾗｷﾞｼ"
  },
  {
    "groupCode": "292125",
    "prefectureNameKanji": "奈良県",
    "cityNameKanji": "宇陀市",
    "prefectureNameKatakana": "ﾅﾗｹﾝ",
    "cityNameKatakana": "ｳﾀﾞｼ"
  },
  {
    "groupCode": "293229",
    "prefectureNameKanji": "奈良県",
    "cityNameKanji": "山添村",
    "prefectureNameKatakana": "ﾅﾗｹﾝ",
    "cityNameKatakana": "ﾔﾏｿﾞｴﾑﾗ"
  },
  {
    "groupCode": "293423",
    "prefectureNameKanji": "奈良県",
    "cityNameKanji": "平群町",
    "prefectureNameKatakana": "ﾅﾗｹﾝ",
    "cityNameKatakana": "ﾍｸﾞﾘﾁｮｳ"
  },
  {
    "groupCode": "293431",
    "prefectureNameKanji": "奈良県",
    "cityNameKanji": "三郷町",
    "prefectureNameKatakana": "ﾅﾗｹﾝ",
    "cityNameKatakana": "ｻﾝｺﾞｳﾁｮｳ"
  },
  {
    "groupCode": "293440",
    "prefectureNameKanji": "奈良県",
    "cityNameKanji": "斑鳩町",
    "prefectureNameKatakana": "ﾅﾗｹﾝ",
    "cityNameKatakana": "ｲｶﾙｶﾞﾁｮｳ"
  },
  {
    "groupCode": "293458",
    "prefectureNameKanji": "奈良県",
    "cityNameKanji": "安堵町",
    "prefectureNameKatakana": "ﾅﾗｹﾝ",
    "cityNameKatakana": "ｱﾝﾄﾞﾁｮｳ"
  },
  {
    "groupCode": "293610",
    "prefectureNameKanji": "奈良県",
    "cityNameKanji": "川西町",
    "prefectureNameKatakana": "ﾅﾗｹﾝ",
    "cityNameKatakana": "ｶﾜﾆｼﾁｮｳ"
  },
  {
    "groupCode": "293628",
    "prefectureNameKanji": "奈良県",
    "cityNameKanji": "三宅町",
    "prefectureNameKatakana": "ﾅﾗｹﾝ",
    "cityNameKatakana": "ﾐﾔｹﾁｮｳ"
  },
  {
    "groupCode": "293636",
    "prefectureNameKanji": "奈良県",
    "cityNameKanji": "田原本町",
    "prefectureNameKatakana": "ﾅﾗｹﾝ",
    "cityNameKatakana": "ﾀﾜﾗﾓﾄﾁｮｳ"
  },
  {
    "groupCode": "293857",
    "prefectureNameKanji": "奈良県",
    "cityNameKanji": "曽爾村",
    "prefectureNameKatakana": "ﾅﾗｹﾝ",
    "cityNameKatakana": "ｿﾆﾑﾗ"
  },
  {
    "groupCode": "293865",
    "prefectureNameKanji": "奈良県",
    "cityNameKanji": "御杖村",
    "prefectureNameKatakana": "ﾅﾗｹﾝ",
    "cityNameKatakana": "ﾐﾂｴﾑﾗ"
  },
  {
    "groupCode": "294012",
    "prefectureNameKanji": "奈良県",
    "cityNameKanji": "高取町",
    "prefectureNameKatakana": "ﾅﾗｹﾝ",
    "cityNameKatakana": "ﾀｶﾄﾘﾁｮｳ"
  },
  {
    "groupCode": "294021",
    "prefectureNameKanji": "奈良県",
    "cityNameKanji": "明日香村",
    "prefectureNameKatakana": "ﾅﾗｹﾝ",
    "cityNameKatakana": "ｱｽｶﾑﾗ"
  },
  {
    "groupCode": "294241",
    "prefectureNameKanji": "奈良県",
    "cityNameKanji": "上牧町",
    "prefectureNameKatakana": "ﾅﾗｹﾝ",
    "cityNameKatakana": "ｶﾝﾏｷﾁｮｳ"
  },
  {
    "groupCode": "294250",
    "prefectureNameKanji": "奈良県",
    "cityNameKanji": "王寺町",
    "prefectureNameKatakana": "ﾅﾗｹﾝ",
    "cityNameKatakana": "ｵｳｼﾞﾁｮｳ"
  },
  {
    "groupCode": "294268",
    "prefectureNameKanji": "奈良県",
    "cityNameKanji": "広陵町",
    "prefectureNameKatakana": "ﾅﾗｹﾝ",
    "cityNameKatakana": "ｺｳﾘﾖｳﾁｮｳ"
  },
  {
    "groupCode": "294276",
    "prefectureNameKanji": "奈良県",
    "cityNameKanji": "河合町",
    "prefectureNameKatakana": "ﾅﾗｹﾝ",
    "cityNameKatakana": "ｶﾜｲﾁｮｳ"
  },
  {
    "groupCode": "294411",
    "prefectureNameKanji": "奈良県",
    "cityNameKanji": "吉野町",
    "prefectureNameKatakana": "ﾅﾗｹﾝ",
    "cityNameKatakana": "ﾖｼﾉﾁｮｳ"
  },
  {
    "groupCode": "294420",
    "prefectureNameKanji": "奈良県",
    "cityNameKanji": "大淀町",
    "prefectureNameKatakana": "ﾅﾗｹﾝ",
    "cityNameKatakana": "ｵｵﾖﾄﾞﾁｮｳ"
  },
  {
    "groupCode": "294438",
    "prefectureNameKanji": "奈良県",
    "cityNameKanji": "下市町",
    "prefectureNameKatakana": "ﾅﾗｹﾝ",
    "cityNameKatakana": "ｼﾓｲﾁﾁｮｳ"
  },
  {
    "groupCode": "294446",
    "prefectureNameKanji": "奈良県",
    "cityNameKanji": "黒滝村",
    "prefectureNameKatakana": "ﾅﾗｹﾝ",
    "cityNameKatakana": "ｸﾛﾀｷﾑﾗ"
  },
  {
    "groupCode": "294462",
    "prefectureNameKanji": "奈良県",
    "cityNameKanji": "天川村",
    "prefectureNameKatakana": "ﾅﾗｹﾝ",
    "cityNameKatakana": "ﾃﾝｶﾜﾑﾗ"
  },
  {
    "groupCode": "294471",
    "prefectureNameKanji": "奈良県",
    "cityNameKanji": "野迫川村",
    "prefectureNameKatakana": "ﾅﾗｹﾝ",
    "cityNameKatakana": "ﾉｾｶﾞﾜﾑﾗ"
  },
  {
    "groupCode": "294497",
    "prefectureNameKanji": "奈良県",
    "cityNameKanji": "十津川村",
    "prefectureNameKatakana": "ﾅﾗｹﾝ",
    "cityNameKatakana": "ﾄﾂｶﾜﾑﾗ"
  },
  {
    "groupCode": "294501",
    "prefectureNameKanji": "奈良県",
    "cityNameKanji": "下北山村",
    "prefectureNameKatakana": "ﾅﾗｹﾝ",
    "cityNameKatakana": "ｼﾓｷﾀﾔﾏﾑﾗ"
  },
  {
    "groupCode": "294519",
    "prefectureNameKanji": "奈良県",
    "cityNameKanji": "上北山村",
    "prefectureNameKatakana": "ﾅﾗｹﾝ",
    "cityNameKatakana": "ｶﾐｷﾀﾔﾏﾑﾗ"
  },
  {
    "groupCode": "294527",
    "prefectureNameKanji": "奈良県",
    "cityNameKanji": "川上村",
    "prefectureNameKatakana": "ﾅﾗｹﾝ",
    "cityNameKatakana": "ｶﾜｶﾐﾑﾗ"
  },
  {
    "groupCode": "294535",
    "prefectureNameKanji": "奈良県",
    "cityNameKanji": "東吉野村",
    "prefectureNameKatakana": "ﾅﾗｹﾝ",
    "cityNameKatakana": "ﾋｶﾞｼﾖｼﾉﾑﾗ"
  },
  {
    "groupCode": "302015",
    "prefectureNameKanji": "和歌山県",
    "cityNameKanji": "和歌山市",
    "prefectureNameKatakana": "ﾜｶﾔﾏｹﾝ",
    "cityNameKatakana": "ﾜｶﾔﾏｼ"
  },
  {
    "groupCode": "302023",
    "prefectureNameKanji": "和歌山県",
    "cityNameKanji": "海南市",
    "prefectureNameKatakana": "ﾜｶﾔﾏｹﾝ",
    "cityNameKatakana": "ｶｲﾅﾝｼ"
  },
  {
    "groupCode": "302031",
    "prefectureNameKanji": "和歌山県",
    "cityNameKanji": "橋本市",
    "prefectureNameKatakana": "ﾜｶﾔﾏｹﾝ",
    "cityNameKatakana": "ﾊｼﾓﾄｼ"
  },
  {
    "groupCode": "302040",
    "prefectureNameKanji": "和歌山県",
    "cityNameKanji": "有田市",
    "prefectureNameKatakana": "ﾜｶﾔﾏｹﾝ",
    "cityNameKatakana": "ｱﾘﾀﾞｼ"
  },
  {
    "groupCode": "302058",
    "prefectureNameKanji": "和歌山県",
    "cityNameKanji": "御坊市",
    "prefectureNameKatakana": "ﾜｶﾔﾏｹﾝ",
    "cityNameKatakana": "ｺﾞﾎﾞｳｼ"
  },
  {
    "groupCode": "302066",
    "prefectureNameKanji": "和歌山県",
    "cityNameKanji": "田辺市",
    "prefectureNameKatakana": "ﾜｶﾔﾏｹﾝ",
    "cityNameKatakana": "ﾀﾅﾍﾞｼ"
  },
  {
    "groupCode": "302074",
    "prefectureNameKanji": "和歌山県",
    "cityNameKanji": "新宮市",
    "prefectureNameKatakana": "ﾜｶﾔﾏｹﾝ",
    "cityNameKatakana": "ｼﾝｸﾞｳｼ"
  },
  {
    "groupCode": "302082",
    "prefectureNameKanji": "和歌山県",
    "cityNameKanji": "紀の川市",
    "prefectureNameKatakana": "ﾜｶﾔﾏｹﾝ",
    "cityNameKatakana": "ｷﾉｶﾜｼ"
  },
  {
    "groupCode": "302091",
    "prefectureNameKanji": "和歌山県",
    "cityNameKanji": "岩出市",
    "prefectureNameKatakana": "ﾜｶﾔﾏｹﾝ",
    "cityNameKatakana": "ｲﾜﾃﾞｼ"
  },
  {
    "groupCode": "303046",
    "prefectureNameKanji": "和歌山県",
    "cityNameKanji": "紀美野町",
    "prefectureNameKatakana": "ﾜｶﾔﾏｹﾝ",
    "cityNameKatakana": "ｷﾐﾉﾁｮｳ"
  },
  {
    "groupCode": "303411",
    "prefectureNameKanji": "和歌山県",
    "cityNameKanji": "かつらぎ町",
    "prefectureNameKatakana": "ﾜｶﾔﾏｹﾝ",
    "cityNameKatakana": "ｶﾂﾗｷﾞﾁｮｳ"
  },
  {
    "groupCode": "303437",
    "prefectureNameKanji": "和歌山県",
    "cityNameKanji": "九度山町",
    "prefectureNameKatakana": "ﾜｶﾔﾏｹﾝ",
    "cityNameKatakana": "ｸﾄﾞﾔﾏﾁｮｳ"
  },
  {
    "groupCode": "303445",
    "prefectureNameKanji": "和歌山県",
    "cityNameKanji": "高野町",
    "prefectureNameKatakana": "ﾜｶﾔﾏｹﾝ",
    "cityNameKatakana": "ｺｳﾔﾁｮｳ"
  },
  {
    "groupCode": "303615",
    "prefectureNameKanji": "和歌山県",
    "cityNameKanji": "湯浅町",
    "prefectureNameKatakana": "ﾜｶﾔﾏｹﾝ",
    "cityNameKatakana": "ﾕｱｻﾁｮｳ"
  },
  {
    "groupCode": "303623",
    "prefectureNameKanji": "和歌山県",
    "cityNameKanji": "広川町",
    "prefectureNameKatakana": "ﾜｶﾔﾏｹﾝ",
    "cityNameKatakana": "ﾋﾛｶﾞﾜﾁｮｳ"
  },
  {
    "groupCode": "303666",
    "prefectureNameKanji": "和歌山県",
    "cityNameKanji": "有田川町",
    "prefectureNameKatakana": "ﾜｶﾔﾏｹﾝ",
    "cityNameKatakana": "ｱﾘﾀﾞｶﾞﾜﾁｮｳ"
  },
  {
    "groupCode": "303810",
    "prefectureNameKanji": "和歌山県",
    "cityNameKanji": "美浜町",
    "prefectureNameKatakana": "ﾜｶﾔﾏｹﾝ",
    "cityNameKatakana": "ﾐﾊﾏﾁｮｳ"
  },
  {
    "groupCode": "303828",
    "prefectureNameKanji": "和歌山県",
    "cityNameKanji": "日高町",
    "prefectureNameKatakana": "ﾜｶﾔﾏｹﾝ",
    "cityNameKatakana": "ﾋﾀﾞｶﾁｮｳ"
  },
  {
    "groupCode": "303836",
    "prefectureNameKanji": "和歌山県",
    "cityNameKanji": "由良町",
    "prefectureNameKatakana": "ﾜｶﾔﾏｹﾝ",
    "cityNameKatakana": "ﾕﾗﾁｮｳ"
  },
  {
    "groupCode": "303909",
    "prefectureNameKanji": "和歌山県",
    "cityNameKanji": "印南町",
    "prefectureNameKatakana": "ﾜｶﾔﾏｹﾝ",
    "cityNameKatakana": "ｲﾅﾐﾁｮｳ"
  },
  {
    "groupCode": "303917",
    "prefectureNameKanji": "和歌山県",
    "cityNameKanji": "みなべ町",
    "prefectureNameKatakana": "ﾜｶﾔﾏｹﾝ",
    "cityNameKatakana": "ﾐﾅﾍﾞﾁｮｳ"
  },
  {
    "groupCode": "303925",
    "prefectureNameKanji": "和歌山県",
    "cityNameKanji": "日高川町",
    "prefectureNameKatakana": "ﾜｶﾔﾏｹﾝ",
    "cityNameKatakana": "ﾋﾀﾞｶｶﾞﾜﾁｮｳ"
  },
  {
    "groupCode": "304018",
    "prefectureNameKanji": "和歌山県",
    "cityNameKanji": "白浜町",
    "prefectureNameKatakana": "ﾜｶﾔﾏｹﾝ",
    "cityNameKatakana": "ｼﾗﾊﾏﾁｮｳ"
  },
  {
    "groupCode": "304042",
    "prefectureNameKanji": "和歌山県",
    "cityNameKanji": "上富田町",
    "prefectureNameKatakana": "ﾜｶﾔﾏｹﾝ",
    "cityNameKatakana": "ｶﾐﾄﾝﾀﾞﾁｮｳ"
  },
  {
    "groupCode": "304069",
    "prefectureNameKanji": "和歌山県",
    "cityNameKanji": "すさみ町",
    "prefectureNameKatakana": "ﾜｶﾔﾏｹﾝ",
    "cityNameKatakana": "ｽｻﾐﾁｮｳ"
  },
  {
    "groupCode": "304212",
    "prefectureNameKanji": "和歌山県",
    "cityNameKanji": "那智勝浦町",
    "prefectureNameKatakana": "ﾜｶﾔﾏｹﾝ",
    "cityNameKatakana": "ﾅﾁｶﾂｳﾗﾁｮｳ"
  },
  {
    "groupCode": "304221",
    "prefectureNameKanji": "和歌山県",
    "cityNameKanji": "太地町",
    "prefectureNameKatakana": "ﾜｶﾔﾏｹﾝ",
    "cityNameKatakana": "ﾀｲｼﾞﾁｮｳ"
  },
  {
    "groupCode": "304247",
    "prefectureNameKanji": "和歌山県",
    "cityNameKanji": "古座川町",
    "prefectureNameKatakana": "ﾜｶﾔﾏｹﾝ",
    "cityNameKatakana": "ｺｻﾞｶﾞﾜﾁｮｳ"
  },
  {
    "groupCode": "304271",
    "prefectureNameKanji": "和歌山県",
    "cityNameKanji": "北山村",
    "prefectureNameKatakana": "ﾜｶﾔﾏｹﾝ",
    "cityNameKatakana": "ｷﾀﾔﾏﾑﾗ"
  },
  {
    "groupCode": "304280",
    "prefectureNameKanji": "和歌山県",
    "cityNameKanji": "串本町",
    "prefectureNameKatakana": "ﾜｶﾔﾏｹﾝ",
    "cityNameKatakana": "ｸｼﾓﾄﾁｮｳ"
  },
  {
    "groupCode": "312011",
    "prefectureNameKanji": "鳥取県",
    "cityNameKanji": "鳥取市",
    "prefectureNameKatakana": "ﾄｯﾄﾘｹﾝ",
    "cityNameKatakana": "ﾄｯﾄﾘｼ"
  },
  {
    "groupCode": "312029",
    "prefectureNameKanji": "鳥取県",
    "cityNameKanji": "米子市",
    "prefectureNameKatakana": "ﾄｯﾄﾘｹﾝ",
    "cityNameKatakana": "ﾖﾅｺﾞｼ"
  },
  {
    "groupCode": "312037",
    "prefectureNameKanji": "鳥取県",
    "cityNameKanji": "倉吉市",
    "prefectureNameKatakana": "ﾄｯﾄﾘｹﾝ",
    "cityNameKatakana": "ｸﾗﾖｼｼ"
  },
  {
    "groupCode": "312045",
    "prefectureNameKanji": "鳥取県",
    "cityNameKanji": "境港市",
    "prefectureNameKatakana": "ﾄｯﾄﾘｹﾝ",
    "cityNameKatakana": "ｻｶｲﾐﾅﾄｼ"
  },
  {
    "groupCode": "313025",
    "prefectureNameKanji": "鳥取県",
    "cityNameKanji": "岩美町",
    "prefectureNameKatakana": "ﾄｯﾄﾘｹﾝ",
    "cityNameKatakana": "ｲﾜﾐﾁｮｳ"
  },
  {
    "groupCode": "313254",
    "prefectureNameKanji": "鳥取県",
    "cityNameKanji": "若桜町",
    "prefectureNameKatakana": "ﾄｯﾄﾘｹﾝ",
    "cityNameKatakana": "ﾜｶｻﾁｮｳ"
  },
  {
    "groupCode": "313289",
    "prefectureNameKanji": "鳥取県",
    "cityNameKanji": "智頭町",
    "prefectureNameKatakana": "ﾄｯﾄﾘｹﾝ",
    "cityNameKatakana": "ﾁﾂﾞﾁｮｳ"
  },
  {
    "groupCode": "313297",
    "prefectureNameKanji": "鳥取県",
    "cityNameKanji": "八頭町",
    "prefectureNameKatakana": "ﾄｯﾄﾘｹﾝ",
    "cityNameKatakana": "ﾔｽﾞﾁｮｳ"
  },
  {
    "groupCode": "313645",
    "prefectureNameKanji": "鳥取県",
    "cityNameKanji": "三朝町",
    "prefectureNameKatakana": "ﾄｯﾄﾘｹﾝ",
    "cityNameKatakana": "ﾐｻｻﾁｮｳ"
  },
  {
    "groupCode": "313700",
    "prefectureNameKanji": "鳥取県",
    "cityNameKanji": "湯梨浜町",
    "prefectureNameKatakana": "ﾄｯﾄﾘｹﾝ",
    "cityNameKatakana": "ﾕﾘﾊﾏﾁｮｳ"
  },
  {
    "groupCode": "313718",
    "prefectureNameKanji": "鳥取県",
    "cityNameKanji": "琴浦町",
    "prefectureNameKatakana": "ﾄｯﾄﾘｹﾝ",
    "cityNameKatakana": "ｺﾄｳﾗﾁｮｳ"
  },
  {
    "groupCode": "313726",
    "prefectureNameKanji": "鳥取県",
    "cityNameKanji": "北栄町",
    "prefectureNameKatakana": "ﾄｯﾄﾘｹﾝ",
    "cityNameKatakana": "ﾎｸｴｲﾁｮｳ"
  },
  {
    "groupCode": "313840",
    "prefectureNameKanji": "鳥取県",
    "cityNameKanji": "日吉津村",
    "prefectureNameKatakana": "ﾄｯﾄﾘｹﾝ",
    "cityNameKatakana": "ﾋｴﾂﾞｿﾝ"
  },
  {
    "groupCode": "313866",
    "prefectureNameKanji": "鳥取県",
    "cityNameKanji": "大山町",
    "prefectureNameKatakana": "ﾄｯﾄﾘｹﾝ",
    "cityNameKatakana": "ﾀﾞｲｾﾝﾁｮｳ"
  },
  {
    "groupCode": "313891",
    "prefectureNameKanji": "鳥取県",
    "cityNameKanji": "南部町",
    "prefectureNameKatakana": "ﾄｯﾄﾘｹﾝ",
    "cityNameKatakana": "ﾅﾝﾌﾞﾁｮｳ"
  },
  {
    "groupCode": "313904",
    "prefectureNameKanji": "鳥取県",
    "cityNameKanji": "伯耆町",
    "prefectureNameKatakana": "ﾄｯﾄﾘｹﾝ",
    "cityNameKatakana": "ﾎｳｷﾁｮｳ"
  },
  {
    "groupCode": "314013",
    "prefectureNameKanji": "鳥取県",
    "cityNameKanji": "日南町",
    "prefectureNameKatakana": "ﾄｯﾄﾘｹﾝ",
    "cityNameKatakana": "ﾆﾁﾅﾝﾁｮｳ"
  },
  {
    "groupCode": "314021",
    "prefectureNameKanji": "鳥取県",
    "cityNameKanji": "日野町",
    "prefectureNameKatakana": "ﾄｯﾄﾘｹﾝ",
    "cityNameKatakana": "ﾋﾉﾁｮｳ"
  },
  {
    "groupCode": "314030",
    "prefectureNameKanji": "鳥取県",
    "cityNameKanji": "江府町",
    "prefectureNameKatakana": "ﾄｯﾄﾘｹﾝ",
    "cityNameKatakana": "ｺｳﾌﾁｮｳ"
  },
  {
    "groupCode": "322016",
    "prefectureNameKanji": "島根県",
    "cityNameKanji": "松江市",
    "prefectureNameKatakana": "ｼﾏﾈｹﾝ",
    "cityNameKatakana": "ﾏﾂｴｼ"
  },
  {
    "groupCode": "322024",
    "prefectureNameKanji": "島根県",
    "cityNameKanji": "浜田市",
    "prefectureNameKatakana": "ｼﾏﾈｹﾝ",
    "cityNameKatakana": "ﾊﾏﾀﾞｼ"
  },
  {
    "groupCode": "322032",
    "prefectureNameKanji": "島根県",
    "cityNameKanji": "出雲市",
    "prefectureNameKatakana": "ｼﾏﾈｹﾝ",
    "cityNameKatakana": "ｲｽﾞﾓｼ"
  },
  {
    "groupCode": "322041",
    "prefectureNameKanji": "島根県",
    "cityNameKanji": "益田市",
    "prefectureNameKatakana": "ｼﾏﾈｹﾝ",
    "cityNameKatakana": "ﾏｽﾀﾞｼ"
  },
  {
    "groupCode": "322059",
    "prefectureNameKanji": "島根県",
    "cityNameKanji": "大田市",
    "prefectureNameKatakana": "ｼﾏﾈｹﾝ",
    "cityNameKatakana": "ｵｵﾀﾞｼ"
  },
  {
    "groupCode": "322067",
    "prefectureNameKanji": "島根県",
    "cityNameKanji": "安来市",
    "prefectureNameKatakana": "ｼﾏﾈｹﾝ",
    "cityNameKatakana": "ﾔｽｷﾞｼ"
  },
  {
    "groupCode": "322075",
    "prefectureNameKanji": "島根県",
    "cityNameKanji": "江津市",
    "prefectureNameKatakana": "ｼﾏﾈｹﾝ",
    "cityNameKatakana": "ｺﾞｳﾂｼ"
  },
  {
    "groupCode": "322091",
    "prefectureNameKanji": "島根県",
    "cityNameKanji": "雲南市",
    "prefectureNameKatakana": "ｼﾏﾈｹﾝ",
    "cityNameKatakana": "ｳﾝﾅﾝｼ"
  },
  {
    "groupCode": "323438",
    "prefectureNameKanji": "島根県",
    "cityNameKanji": "奥出雲町",
    "prefectureNameKatakana": "ｼﾏﾈｹﾝ",
    "cityNameKatakana": "ｵｸｲｽﾞﾓﾁｮｳ"
  },
  {
    "groupCode": "323861",
    "prefectureNameKanji": "島根県",
    "cityNameKanji": "飯南町",
    "prefectureNameKatakana": "ｼﾏﾈｹﾝ",
    "cityNameKatakana": "ｲｲﾅﾝﾁｮｳ"
  },
  {
    "groupCode": "324418",
    "prefectureNameKanji": "島根県",
    "cityNameKanji": "川本町",
    "prefectureNameKatakana": "ｼﾏﾈｹﾝ",
    "cityNameKatakana": "ｶﾜﾓﾄﾏﾁ"
  },
  {
    "groupCode": "324485",
    "prefectureNameKanji": "島根県",
    "cityNameKanji": "美郷町",
    "prefectureNameKatakana": "ｼﾏﾈｹﾝ",
    "cityNameKatakana": "ﾐｻﾄﾁｮｳ"
  },
  {
    "groupCode": "324493",
    "prefectureNameKanji": "島根県",
    "cityNameKanji": "邑南町",
    "prefectureNameKatakana": "ｼﾏﾈｹﾝ",
    "cityNameKatakana": "ｵｵﾅﾝﾁｮｳ"
  },
  {
    "groupCode": "325015",
    "prefectureNameKanji": "島根県",
    "cityNameKanji": "津和野町",
    "prefectureNameKatakana": "ｼﾏﾈｹﾝ",
    "cityNameKatakana": "ﾂﾜﾉﾁｮｳ"
  },
  {
    "groupCode": "325058",
    "prefectureNameKanji": "島根県",
    "cityNameKanji": "吉賀町",
    "prefectureNameKatakana": "ｼﾏﾈｹﾝ",
    "cityNameKatakana": "ﾖｼｶﾁｮｳ"
  },
  {
    "groupCode": "325252",
    "prefectureNameKanji": "島根県",
    "cityNameKanji": "海士町",
    "prefectureNameKatakana": "ｼﾏﾈｹﾝ",
    "cityNameKatakana": "ｱﾏﾁｮｳ"
  },
  {
    "groupCode": "325261",
    "prefectureNameKanji": "島根県",
    "cityNameKanji": "西ノ島町",
    "prefectureNameKatakana": "ｼﾏﾈｹﾝ",
    "cityNameKatakana": "ﾆｼﾉｼﾏﾁｮｳ"
  },
  {
    "groupCode": "325279",
    "prefectureNameKanji": "島根県",
    "cityNameKanji": "知夫村",
    "prefectureNameKatakana": "ｼﾏﾈｹﾝ",
    "cityNameKatakana": "ﾁﾌﾞﾑﾗ"
  },
  {
    "groupCode": "325287",
    "prefectureNameKanji": "島根県",
    "cityNameKanji": "隠岐の島町",
    "prefectureNameKatakana": "ｼﾏﾈｹﾝ",
    "cityNameKatakana": "ｵｷﾉｼﾏﾁｮｳ"
  },
  {
    "groupCode": "331007",
    "prefectureNameKanji": "岡山県",
    "cityNameKanji": "岡山市",
    "prefectureNameKatakana": "ｵｶﾔﾏｹﾝ",
    "cityNameKatakana": "ｵｶﾔﾏｼ"
  },
  {
    "groupCode": "332020",
    "prefectureNameKanji": "岡山県",
    "cityNameKanji": "倉敷市",
    "prefectureNameKatakana": "ｵｶﾔﾏｹﾝ",
    "cityNameKatakana": "ｸﾗｼｷｼ"
  },
  {
    "groupCode": "332038",
    "prefectureNameKanji": "岡山県",
    "cityNameKanji": "津山市",
    "prefectureNameKatakana": "ｵｶﾔﾏｹﾝ",
    "cityNameKatakana": "ﾂﾔﾏｼ"
  },
  {
    "groupCode": "332046",
    "prefectureNameKanji": "岡山県",
    "cityNameKanji": "玉野市",
    "prefectureNameKatakana": "ｵｶﾔﾏｹﾝ",
    "cityNameKatakana": "ﾀﾏﾉｼ"
  },
  {
    "groupCode": "332054",
    "prefectureNameKanji": "岡山県",
    "cityNameKanji": "笠岡市",
    "prefectureNameKatakana": "ｵｶﾔﾏｹﾝ",
    "cityNameKatakana": "ｶｻｵｶｼ"
  },
  {
    "groupCode": "332071",
    "prefectureNameKanji": "岡山県",
    "cityNameKanji": "井原市",
    "prefectureNameKatakana": "ｵｶﾔﾏｹﾝ",
    "cityNameKatakana": "ｲﾊﾞﾗｼ"
  },
  {
    "groupCode": "332089",
    "prefectureNameKanji": "岡山県",
    "cityNameKanji": "総社市",
    "prefectureNameKatakana": "ｵｶﾔﾏｹﾝ",
    "cityNameKatakana": "ｿｳｼﾞﾔｼ"
  },
  {
    "groupCode": "332097",
    "prefectureNameKanji": "岡山県",
    "cityNameKanji": "高梁市",
    "prefectureNameKatakana": "ｵｶﾔﾏｹﾝ",
    "cityNameKatakana": "ﾀｶﾊｼｼ"
  },
  {
    "groupCode": "332101",
    "prefectureNameKanji": "岡山県",
    "cityNameKanji": "新見市",
    "prefectureNameKatakana": "ｵｶﾔﾏｹﾝ",
    "cityNameKatakana": "ﾆｲﾐｼ"
  },
  {
    "groupCode": "332119",
    "prefectureNameKanji": "岡山県",
    "cityNameKanji": "備前市",
    "prefectureNameKatakana": "ｵｶﾔﾏｹﾝ",
    "cityNameKatakana": "ﾋﾞｾﾞﾝｼ"
  },
  {
    "groupCode": "332127",
    "prefectureNameKanji": "岡山県",
    "cityNameKanji": "瀬戸内市",
    "prefectureNameKatakana": "ｵｶﾔﾏｹﾝ",
    "cityNameKatakana": "ｾﾄｳﾁｼ"
  },
  {
    "groupCode": "332135",
    "prefectureNameKanji": "岡山県",
    "cityNameKanji": "赤磐市",
    "prefectureNameKatakana": "ｵｶﾔﾏｹﾝ",
    "cityNameKatakana": "ｱｶｲﾜｼ"
  },
  {
    "groupCode": "332143",
    "prefectureNameKanji": "岡山県",
    "cityNameKanji": "真庭市",
    "prefectureNameKatakana": "ｵｶﾔﾏｹﾝ",
    "cityNameKatakana": "ﾏﾆﾜｼ"
  },
  {
    "groupCode": "332151",
    "prefectureNameKanji": "岡山県",
    "cityNameKanji": "美作市",
    "prefectureNameKatakana": "ｵｶﾔﾏｹﾝ",
    "cityNameKatakana": "ﾐﾏｻｶｼ"
  },
  {
    "groupCode": "332160",
    "prefectureNameKanji": "岡山県",
    "cityNameKanji": "浅口市",
    "prefectureNameKatakana": "ｵｶﾔﾏｹﾝ",
    "cityNameKatakana": "ｱｻｸﾁｼ"
  },
  {
    "groupCode": "333468",
    "prefectureNameKanji": "岡山県",
    "cityNameKanji": "和気町",
    "prefectureNameKatakana": "ｵｶﾔﾏｹﾝ",
    "cityNameKatakana": "ﾜｹﾁｮｳ"
  },
  {
    "groupCode": "334235",
    "prefectureNameKanji": "岡山県",
    "cityNameKanji": "早島町",
    "prefectureNameKatakana": "ｵｶﾔﾏｹﾝ",
    "cityNameKatakana": "ﾊﾔｼﾏﾁｮｳ"
  },
  {
    "groupCode": "334456",
    "prefectureNameKanji": "岡山県",
    "cityNameKanji": "里庄町",
    "prefectureNameKatakana": "ｵｶﾔﾏｹﾝ",
    "cityNameKatakana": "ｻﾄｼｮｳﾁｮｳ"
  },
  {
    "groupCode": "334618",
    "prefectureNameKanji": "岡山県",
    "cityNameKanji": "矢掛町",
    "prefectureNameKatakana": "ｵｶﾔﾏｹﾝ",
    "cityNameKatakana": "ﾔｶｹﾞﾁｮｳ"
  },
  {
    "groupCode": "335860",
    "prefectureNameKanji": "岡山県",
    "cityNameKanji": "新庄村",
    "prefectureNameKatakana": "ｵｶﾔﾏｹﾝ",
    "cityNameKatakana": "ｼﾝｼﾞﾖｳｿﾝ"
  },
  {
    "groupCode": "336068",
    "prefectureNameKanji": "岡山県",
    "cityNameKanji": "鏡野町",
    "prefectureNameKatakana": "ｵｶﾔﾏｹﾝ",
    "cityNameKatakana": "ｶｶﾞﾐﾉﾁｮｳ"
  },
  {
    "groupCode": "336220",
    "prefectureNameKanji": "岡山県",
    "cityNameKanji": "勝央町",
    "prefectureNameKatakana": "ｵｶﾔﾏｹﾝ",
    "cityNameKatakana": "ｼｮｳｵｳﾁｮｳ"
  },
  {
    "groupCode": "336238",
    "prefectureNameKanji": "岡山県",
    "cityNameKanji": "奈義町",
    "prefectureNameKatakana": "ｵｶﾔﾏｹﾝ",
    "cityNameKatakana": "ﾅｷﾞﾁｮｳ"
  },
  {
    "groupCode": "336432",
    "prefectureNameKanji": "岡山県",
    "cityNameKanji": "西粟倉村",
    "prefectureNameKatakana": "ｵｶﾔﾏｹﾝ",
    "cityNameKatakana": "ﾆｼｱﾜｸﾗｿﾝ"
  },
  {
    "groupCode": "336637",
    "prefectureNameKanji": "岡山県",
    "cityNameKanji": "久米南町",
    "prefectureNameKatakana": "ｵｶﾔﾏｹﾝ",
    "cityNameKatakana": "ｸﾒﾅﾝﾁｮｳ"
  },
  {
    "groupCode": "336661",
    "prefectureNameKanji": "岡山県",
    "cityNameKanji": "美咲町",
    "prefectureNameKatakana": "ｵｶﾔﾏｹﾝ",
    "cityNameKatakana": "ﾐｻｷﾁｮｳ"
  },
  {
    "groupCode": "336815",
    "prefectureNameKanji": "岡山県",
    "cityNameKanji": "吉備中央町",
    "prefectureNameKatakana": "ｵｶﾔﾏｹﾝ",
    "cityNameKatakana": "ｷﾋﾞﾁｭｳｵｳﾁｮｳ"
  },
  {
    "groupCode": "341002",
    "prefectureNameKanji": "広島県",
    "cityNameKanji": "広島市",
    "prefectureNameKatakana": "ﾋﾛｼﾏｹﾝ",
    "cityNameKatakana": "ﾋﾛｼﾏｼ"
  },
  {
    "groupCode": "342025",
    "prefectureNameKanji": "広島県",
    "cityNameKanji": "呉市",
    "prefectureNameKatakana": "ﾋﾛｼﾏｹﾝ",
    "cityNameKatakana": "ｸﾚｼ"
  },
  {
    "groupCode": "342033",
    "prefectureNameKanji": "広島県",
    "cityNameKanji": "竹原市",
    "prefectureNameKatakana": "ﾋﾛｼﾏｹﾝ",
    "cityNameKatakana": "ﾀｹﾊﾗｼ"
  },
  {
    "groupCode": "342041",
    "prefectureNameKanji": "広島県",
    "cityNameKanji": "三原市",
    "prefectureNameKatakana": "ﾋﾛｼﾏｹﾝ",
    "cityNameKatakana": "ﾐﾊﾗｼ"
  },
  {
    "groupCode": "342050",
    "prefectureNameKanji": "広島県",
    "cityNameKanji": "尾道市",
    "prefectureNameKatakana": "ﾋﾛｼﾏｹﾝ",
    "cityNameKatakana": "ｵﾉﾐﾁｼ"
  },
  {
    "groupCode": "342076",
    "prefectureNameKanji": "広島県",
    "cityNameKanji": "福山市",
    "prefectureNameKatakana": "ﾋﾛｼﾏｹﾝ",
    "cityNameKatakana": "ﾌｸﾔﾏｼ"
  },
  {
    "groupCode": "342084",
    "prefectureNameKanji": "広島県",
    "cityNameKanji": "府中市",
    "prefectureNameKatakana": "ﾋﾛｼﾏｹﾝ",
    "cityNameKatakana": "ﾌﾁｭｳｼ"
  },
  {
    "groupCode": "342092",
    "prefectureNameKanji": "広島県",
    "cityNameKanji": "三次市",
    "prefectureNameKatakana": "ﾋﾛｼﾏｹﾝ",
    "cityNameKatakana": "ﾐﾖｼｼ"
  },
  {
    "groupCode": "342106",
    "prefectureNameKanji": "広島県",
    "cityNameKanji": "庄原市",
    "prefectureNameKatakana": "ﾋﾛｼﾏｹﾝ",
    "cityNameKatakana": "ｼｮｳﾊﾞﾗｼ"
  },
  {
    "groupCode": "342114",
    "prefectureNameKanji": "広島県",
    "cityNameKanji": "大竹市",
    "prefectureNameKatakana": "ﾋﾛｼﾏｹﾝ",
    "cityNameKatakana": "ｵｵﾀｹｼ"
  },
  {
    "groupCode": "342122",
    "prefectureNameKanji": "広島県",
    "cityNameKanji": "東広島市",
    "prefectureNameKatakana": "ﾋﾛｼﾏｹﾝ",
    "cityNameKatakana": "ﾋｶﾞｼﾋﾛｼﾏｼ"
  },
  {
    "groupCode": "342131",
    "prefectureNameKanji": "広島県",
    "cityNameKanji": "廿日市市",
    "prefectureNameKatakana": "ﾋﾛｼﾏｹﾝ",
    "cityNameKatakana": "ﾊﾂｶｲﾁｼ"
  },
  {
    "groupCode": "342149",
    "prefectureNameKanji": "広島県",
    "cityNameKanji": "安芸高田市",
    "prefectureNameKatakana": "ﾋﾛｼﾏｹﾝ",
    "cityNameKatakana": "ｱｷﾀｶﾀｼ"
  },
  {
    "groupCode": "342157",
    "prefectureNameKanji": "広島県",
    "cityNameKanji": "江田島市",
    "prefectureNameKatakana": "ﾋﾛｼﾏｹﾝ",
    "cityNameKatakana": "ｴﾀｼﾞﾏｼ"
  },
  {
    "groupCode": "343021",
    "prefectureNameKanji": "広島県",
    "cityNameKanji": "府中町",
    "prefectureNameKatakana": "ﾋﾛｼﾏｹﾝ",
    "cityNameKatakana": "ﾌﾁｭｳﾁｮｳ"
  },
  {
    "groupCode": "343048",
    "prefectureNameKanji": "広島県",
    "cityNameKanji": "海田町",
    "prefectureNameKatakana": "ﾋﾛｼﾏｹﾝ",
    "cityNameKatakana": "ｶｲﾀﾁｮｳ"
  },
  {
    "groupCode": "343072",
    "prefectureNameKanji": "広島県",
    "cityNameKanji": "熊野町",
    "prefectureNameKatakana": "ﾋﾛｼﾏｹﾝ",
    "cityNameKatakana": "ｸﾏﾉﾁｮｳ"
  },
  {
    "groupCode": "343099",
    "prefectureNameKanji": "広島県",
    "cityNameKanji": "坂町",
    "prefectureNameKatakana": "ﾋﾛｼﾏｹﾝ",
    "cityNameKatakana": "ｻｶﾁｮｳ"
  },
  {
    "groupCode": "343684",
    "prefectureNameKanji": "広島県",
    "cityNameKanji": "安芸太田町",
    "prefectureNameKatakana": "ﾋﾛｼﾏｹﾝ",
    "cityNameKatakana": "ｱｷｵｵﾀﾁｮｳ"
  },
  {
    "groupCode": "343692",
    "prefectureNameKanji": "広島県",
    "cityNameKanji": "北広島町",
    "prefectureNameKatakana": "ﾋﾛｼﾏｹﾝ",
    "cityNameKatakana": "ｷﾀﾋﾛｼﾏﾁｮｳ"
  },
  {
    "groupCode": "344311",
    "prefectureNameKanji": "広島県",
    "cityNameKanji": "大崎上島町",
    "prefectureNameKatakana": "ﾋﾛｼﾏｹﾝ",
    "cityNameKatakana": "ｵｵｻｷｶﾐｼﾞﾏﾁｮｳ"
  },
  {
    "groupCode": "344621",
    "prefectureNameKanji": "広島県",
    "cityNameKanji": "世羅町",
    "prefectureNameKatakana": "ﾋﾛｼﾏｹﾝ",
    "cityNameKatakana": "ｾﾗﾁｮｳ"
  },
  {
    "groupCode": "345458",
    "prefectureNameKanji": "広島県",
    "cityNameKanji": "神石高原町",
    "prefectureNameKatakana": "ﾋﾛｼﾏｹﾝ",
    "cityNameKatakana": "ｼﾞﾝｾｷｺｳｹﾞﾝﾁｮｳ"
  },
  {
    "groupCode": "352012",
    "prefectureNameKanji": "山口県",
    "cityNameKanji": "下関市",
    "prefectureNameKatakana": "ﾔﾏｸﾞﾁｹﾝ",
    "cityNameKatakana": "ｼﾓﾉｾｷｼ"
  },
  {
    "groupCode": "352021",
    "prefectureNameKanji": "山口県",
    "cityNameKanji": "宇部市",
    "prefectureNameKatakana": "ﾔﾏｸﾞﾁｹﾝ",
    "cityNameKatakana": "ｳﾍﾞｼ"
  },
  {
    "groupCode": "352039",
    "prefectureNameKanji": "山口県",
    "cityNameKanji": "山口市",
    "prefectureNameKatakana": "ﾔﾏｸﾞﾁｹﾝ",
    "cityNameKatakana": "ﾔﾏｸﾞﾁｼ"
  },
  {
    "groupCode": "352047",
    "prefectureNameKanji": "山口県",
    "cityNameKanji": "萩市",
    "prefectureNameKatakana": "ﾔﾏｸﾞﾁｹﾝ",
    "cityNameKatakana": "ﾊｷﾞｼ"
  },
  {
    "groupCode": "352063",
    "prefectureNameKanji": "山口県",
    "cityNameKanji": "防府市",
    "prefectureNameKatakana": "ﾔﾏｸﾞﾁｹﾝ",
    "cityNameKatakana": "ﾎｳﾌｼ"
  },
  {
    "groupCode": "352071",
    "prefectureNameKanji": "山口県",
    "cityNameKanji": "下松市",
    "prefectureNameKatakana": "ﾔﾏｸﾞﾁｹﾝ",
    "cityNameKatakana": "ｸﾀﾞﾏﾂｼ"
  },
  {
    "groupCode": "352080",
    "prefectureNameKanji": "山口県",
    "cityNameKanji": "岩国市",
    "prefectureNameKatakana": "ﾔﾏｸﾞﾁｹﾝ",
    "cityNameKatakana": "ｲﾜｸﾆｼ"
  },
  {
    "groupCode": "352101",
    "prefectureNameKanji": "山口県",
    "cityNameKanji": "光市",
    "prefectureNameKatakana": "ﾔﾏｸﾞﾁｹﾝ",
    "cityNameKatakana": "ﾋｶﾘｼ"
  },
  {
    "groupCode": "352110",
    "prefectureNameKanji": "山口県",
    "cityNameKanji": "長門市",
    "prefectureNameKatakana": "ﾔﾏｸﾞﾁｹﾝ",
    "cityNameKatakana": "ﾅｶﾞﾄｼ"
  },
  {
    "groupCode": "352128",
    "prefectureNameKanji": "山口県",
    "cityNameKanji": "柳井市",
    "prefectureNameKatakana": "ﾔﾏｸﾞﾁｹﾝ",
    "cityNameKatakana": "ﾔﾅｲｼ"
  },
  {
    "groupCode": "352136",
    "prefectureNameKanji": "山口県",
    "cityNameKanji": "美祢市",
    "prefectureNameKatakana": "ﾔﾏｸﾞﾁｹﾝ",
    "cityNameKatakana": "ﾐﾈｼ"
  },
  {
    "groupCode": "352152",
    "prefectureNameKanji": "山口県",
    "cityNameKanji": "周南市",
    "prefectureNameKatakana": "ﾔﾏｸﾞﾁｹﾝ",
    "cityNameKatakana": "ｼｭｳﾅﾝｼ"
  },
  {
    "groupCode": "352161",
    "prefectureNameKanji": "山口県",
    "cityNameKanji": "山陽小野田市",
    "prefectureNameKatakana": "ﾔﾏｸﾞﾁｹﾝ",
    "cityNameKatakana": "ｻﾝﾖｳｵﾉﾀﾞｼ"
  },
  {
    "groupCode": "353051",
    "prefectureNameKanji": "山口県",
    "cityNameKanji": "周防大島町",
    "prefectureNameKatakana": "ﾔﾏｸﾞﾁｹﾝ",
    "cityNameKatakana": "ｽｵｳｵｵｼﾏﾁｮｳ"
  },
  {
    "groupCode": "353213",
    "prefectureNameKanji": "山口県",
    "cityNameKanji": "和木町",
    "prefectureNameKatakana": "ﾔﾏｸﾞﾁｹﾝ",
    "cityNameKatakana": "ﾜｷﾁｮｳ"
  },
  {
    "groupCode": "353418",
    "prefectureNameKanji": "山口県",
    "cityNameKanji": "上関町",
    "prefectureNameKatakana": "ﾔﾏｸﾞﾁｹﾝ",
    "cityNameKatakana": "ｶﾐﾉｾｷﾁｮｳ"
  },
  {
    "groupCode": "353434",
    "prefectureNameKanji": "山口県",
    "cityNameKanji": "田布施町",
    "prefectureNameKatakana": "ﾔﾏｸﾞﾁｹﾝ",
    "cityNameKatakana": "ﾀﾌﾞｾﾁｮｳ"
  },
  {
    "groupCode": "353442",
    "prefectureNameKanji": "山口県",
    "cityNameKanji": "平生町",
    "prefectureNameKatakana": "ﾔﾏｸﾞﾁｹﾝ",
    "cityNameKatakana": "ﾋﾗｵﾁｮｳ"
  },
  {
    "groupCode": "355020",
    "prefectureNameKanji": "山口県",
    "cityNameKanji": "阿武町",
    "prefectureNameKatakana": "ﾔﾏｸﾞﾁｹﾝ",
    "cityNameKatakana": "ｱﾌﾞﾁｮｳ"
  },
  {
    "groupCode": "362018",
    "prefectureNameKanji": "徳島県",
    "cityNameKanji": "徳島市",
    "prefectureNameKatakana": "ﾄｸｼﾏｹﾝ",
    "cityNameKatakana": "ﾄｸｼﾏｼ"
  },
  {
    "groupCode": "362026",
    "prefectureNameKanji": "徳島県",
    "cityNameKanji": "鳴門市",
    "prefectureNameKatakana": "ﾄｸｼﾏｹﾝ",
    "cityNameKatakana": "ﾅﾙﾄｼ"
  },
  {
    "groupCode": "362034",
    "prefectureNameKanji": "徳島県",
    "cityNameKanji": "小松島市",
    "prefectureNameKatakana": "ﾄｸｼﾏｹﾝ",
    "cityNameKatakana": "ｺﾏﾂｼﾏｼ"
  },
  {
    "groupCode": "362042",
    "prefectureNameKanji": "徳島県",
    "cityNameKanji": "阿南市",
    "prefectureNameKatakana": "ﾄｸｼﾏｹﾝ",
    "cityNameKatakana": "ｱﾅﾝｼ"
  },
  {
    "groupCode": "362051",
    "prefectureNameKanji": "徳島県",
    "cityNameKanji": "吉野川市",
    "prefectureNameKatakana": "ﾄｸｼﾏｹﾝ",
    "cityNameKatakana": "ﾖｼﾉｶﾞﾜｼ"
  },
  {
    "groupCode": "362069",
    "prefectureNameKanji": "徳島県",
    "cityNameKanji": "阿波市",
    "prefectureNameKatakana": "ﾄｸｼﾏｹﾝ",
    "cityNameKatakana": "ｱﾜｼ"
  },
  {
    "groupCode": "362077",
    "prefectureNameKanji": "徳島県",
    "cityNameKanji": "美馬市",
    "prefectureNameKatakana": "ﾄｸｼﾏｹﾝ",
    "cityNameKatakana": "ﾐﾏｼ"
  },
  {
    "groupCode": "362085",
    "prefectureNameKanji": "徳島県",
    "cityNameKanji": "三好市",
    "prefectureNameKatakana": "ﾄｸｼﾏｹﾝ",
    "cityNameKatakana": "ﾐﾖｼｼ"
  },
  {
    "groupCode": "363014",
    "prefectureNameKanji": "徳島県",
    "cityNameKanji": "勝浦町",
    "prefectureNameKatakana": "ﾄｸｼﾏｹﾝ",
    "cityNameKatakana": "ｶﾂｳﾗﾁｮｳ"
  },
  {
    "groupCode": "363022",
    "prefectureNameKanji": "徳島県",
    "cityNameKanji": "上勝町",
    "prefectureNameKatakana": "ﾄｸｼﾏｹﾝ",
    "cityNameKatakana": "ｶﾐｶﾂﾁｮｳ"
  },
  {
    "groupCode": "363219",
    "prefectureNameKanji": "徳島県",
    "cityNameKanji": "佐那河内村",
    "prefectureNameKatakana": "ﾄｸｼﾏｹﾝ",
    "cityNameKatakana": "ｻﾅｺﾞｳﾁｿﾝ"
  },
  {
    "groupCode": "363413",
    "prefectureNameKanji": "徳島県",
    "cityNameKanji": "石井町",
    "prefectureNameKatakana": "ﾄｸｼﾏｹﾝ",
    "cityNameKatakana": "ｲｼｲﾁｮｳ"
  },
  {
    "groupCode": "363421",
    "prefectureNameKanji": "徳島県",
    "cityNameKanji": "神山町",
    "prefectureNameKatakana": "ﾄｸｼﾏｹﾝ",
    "cityNameKatakana": "ｶﾐﾔﾏﾁｮｳ"
  },
  {
    "groupCode": "363685",
    "prefectureNameKanji": "徳島県",
    "cityNameKanji": "那賀町",
    "prefectureNameKatakana": "ﾄｸｼﾏｹﾝ",
    "cityNameKatakana": "ﾅｶﾁｮｳ"
  },
  {
    "groupCode": "363839",
    "prefectureNameKanji": "徳島県",
    "cityNameKanji": "牟岐町",
    "prefectureNameKatakana": "ﾄｸｼﾏｹﾝ",
    "cityNameKatakana": "ﾑｷﾞﾁｮｳ"
  },
  {
    "groupCode": "363871",
    "prefectureNameKanji": "徳島県",
    "cityNameKanji": "美波町",
    "prefectureNameKatakana": "ﾄｸｼﾏｹﾝ",
    "cityNameKatakana": "ﾐﾅﾐﾁｮｳ"
  },
  {
    "groupCode": "363880",
    "prefectureNameKanji": "徳島県",
    "cityNameKanji": "海陽町",
    "prefectureNameKatakana": "ﾄｸｼﾏｹﾝ",
    "cityNameKatakana": "ｶｲﾖｳﾁｮｳ"
  },
  {
    "groupCode": "364011",
    "prefectureNameKanji": "徳島県",
    "cityNameKanji": "松茂町",
    "prefectureNameKatakana": "ﾄｸｼﾏｹﾝ",
    "cityNameKatakana": "ﾏﾂｼｹﾞﾁｮｳ"
  },
  {
    "groupCode": "364029",
    "prefectureNameKanji": "徳島県",
    "cityNameKanji": "北島町",
    "prefectureNameKatakana": "ﾄｸｼﾏｹﾝ",
    "cityNameKatakana": "ｷﾀｼﾞﾏﾁｮｳ"
  },
  {
    "groupCode": "364037",
    "prefectureNameKanji": "徳島県",
    "cityNameKanji": "藍住町",
    "prefectureNameKatakana": "ﾄｸｼﾏｹﾝ",
    "cityNameKatakana": "ｱｲｽﾞﾐﾁｮｳ"
  },
  {
    "groupCode": "364045",
    "prefectureNameKanji": "徳島県",
    "cityNameKanji": "板野町",
    "prefectureNameKatakana": "ﾄｸｼﾏｹﾝ",
    "cityNameKatakana": "ｲﾀﾉﾁｮｳ"
  },
  {
    "groupCode": "364053",
    "prefectureNameKanji": "徳島県",
    "cityNameKanji": "上板町",
    "prefectureNameKatakana": "ﾄｸｼﾏｹﾝ",
    "cityNameKatakana": "ｶﾐｲﾀﾁｮｳ"
  },
  {
    "groupCode": "364681",
    "prefectureNameKanji": "徳島県",
    "cityNameKanji": "つるぎ町",
    "prefectureNameKatakana": "ﾄｸｼﾏｹﾝ",
    "cityNameKatakana": "ﾂﾙｷﾞﾁｮｳ"
  },
  {
    "groupCode": "364894",
    "prefectureNameKanji": "徳島県",
    "cityNameKanji": "東みよし町",
    "prefectureNameKatakana": "ﾄｸｼﾏｹﾝ",
    "cityNameKatakana": "ﾋｶﾞｼﾐﾖｼﾁｮｳ"
  },
  {
    "groupCode": "372013",
    "prefectureNameKanji": "香川県",
    "cityNameKanji": "高松市",
    "prefectureNameKatakana": "ｶｶﾞﾜｹﾝ",
    "cityNameKatakana": "ﾀｶﾏﾂｼ"
  },
  {
    "groupCode": "372021",
    "prefectureNameKanji": "香川県",
    "cityNameKanji": "丸亀市",
    "prefectureNameKatakana": "ｶｶﾞﾜｹﾝ",
    "cityNameKatakana": "ﾏﾙｶﾞﾒｼ"
  },
  {
    "groupCode": "372030",
    "prefectureNameKanji": "香川県",
    "cityNameKanji": "坂出市",
    "prefectureNameKatakana": "ｶｶﾞﾜｹﾝ",
    "cityNameKatakana": "ｻｶｲﾃﾞｼ"
  },
  {
    "groupCode": "372048",
    "prefectureNameKanji": "香川県",
    "cityNameKanji": "善通寺市",
    "prefectureNameKatakana": "ｶｶﾞﾜｹﾝ",
    "cityNameKatakana": "ｾﾞﾝﾂｳｼﾞｼ"
  },
  {
    "groupCode": "372056",
    "prefectureNameKanji": "香川県",
    "cityNameKanji": "観音寺市",
    "prefectureNameKatakana": "ｶｶﾞﾜｹﾝ",
    "cityNameKatakana": "ｶﾝｵﾝｼﾞｼ"
  },
  {
    "groupCode": "372064",
    "prefectureNameKanji": "香川県",
    "cityNameKanji": "さぬき市",
    "prefectureNameKatakana": "ｶｶﾞﾜｹﾝ",
    "cityNameKatakana": "ｻﾇｷｼ"
  },
  {
    "groupCode": "372072",
    "prefectureNameKanji": "香川県",
    "cityNameKanji": "東かがわ市",
    "prefectureNameKatakana": "ｶｶﾞﾜｹﾝ",
    "cityNameKatakana": "ﾋｶﾞｼｶｶﾞﾜｼ"
  },
  {
    "groupCode": "372081",
    "prefectureNameKanji": "香川県",
    "cityNameKanji": "三豊市",
    "prefectureNameKatakana": "ｶｶﾞﾜｹﾝ",
    "cityNameKatakana": "ﾐﾄﾖｼ"
  },
  {
    "groupCode": "373222",
    "prefectureNameKanji": "香川県",
    "cityNameKanji": "土庄町",
    "prefectureNameKatakana": "ｶｶﾞﾜｹﾝ",
    "cityNameKatakana": "ﾄﾉｼｮｳﾁｮｳ"
  },
  {
    "groupCode": "373249",
    "prefectureNameKanji": "香川県",
    "cityNameKanji": "小豆島町",
    "prefectureNameKatakana": "ｶｶﾞﾜｹﾝ",
    "cityNameKatakana": "ｼｮｳﾄﾞｼﾏﾁｮｳ"
  },
  {
    "groupCode": "373419",
    "prefectureNameKanji": "香川県",
    "cityNameKanji": "三木町",
    "prefectureNameKatakana": "ｶｶﾞﾜｹﾝ",
    "cityNameKatakana": "ﾐｷﾁｮｳ"
  },
  {
    "groupCode": "373648",
    "prefectureNameKanji": "香川県",
    "cityNameKanji": "直島町",
    "prefectureNameKatakana": "ｶｶﾞﾜｹﾝ",
    "cityNameKatakana": "ﾅｵｼﾏﾁｮｳ"
  },
  {
    "groupCode": "373869",
    "prefectureNameKanji": "香川県",
    "cityNameKanji": "宇多津町",
    "prefectureNameKatakana": "ｶｶﾞﾜｹﾝ",
    "cityNameKatakana": "ｳﾀﾂﾞﾁｮｳ"
  },
  {
    "groupCode": "373877",
    "prefectureNameKanji": "香川県",
    "cityNameKanji": "綾川町",
    "prefectureNameKatakana": "ｶｶﾞﾜｹﾝ",
    "cityNameKatakana": "ｱﾔｶﾞﾜﾁｮｳ"
  },
  {
    "groupCode": "374032",
    "prefectureNameKanji": "香川県",
    "cityNameKanji": "琴平町",
    "prefectureNameKatakana": "ｶｶﾞﾜｹﾝ",
    "cityNameKatakana": "ｺﾄﾋﾗﾁｮｳ"
  },
  {
    "groupCode": "374041",
    "prefectureNameKanji": "香川県",
    "cityNameKanji": "多度津町",
    "prefectureNameKatakana": "ｶｶﾞﾜｹﾝ",
    "cityNameKatakana": "ﾀﾄﾞﾂﾁｮｳ"
  },
  {
    "groupCode": "374067",
    "prefectureNameKanji": "香川県",
    "cityNameKanji": "まんのう町",
    "prefectureNameKatakana": "ｶｶﾞﾜｹﾝ",
    "cityNameKatakana": "ﾏﾝﾉｳﾁｮｳ"
  },
  {
    "groupCode": "382019",
    "prefectureNameKanji": "愛媛県",
    "cityNameKanji": "松山市",
    "prefectureNameKatakana": "ｴﾋﾒｹﾝ",
    "cityNameKatakana": "ﾏﾂﾔﾏｼ"
  },
  {
    "groupCode": "382027",
    "prefectureNameKanji": "愛媛県",
    "cityNameKanji": "今治市",
    "prefectureNameKatakana": "ｴﾋﾒｹﾝ",
    "cityNameKatakana": "ｲﾏﾊﾞﾘｼ"
  },
  {
    "groupCode": "382035",
    "prefectureNameKanji": "愛媛県",
    "cityNameKanji": "宇和島市",
    "prefectureNameKatakana": "ｴﾋﾒｹﾝ",
    "cityNameKatakana": "ｳﾜｼﾞﾏｼ"
  },
  {
    "groupCode": "382043",
    "prefectureNameKanji": "愛媛県",
    "cityNameKanji": "八幡浜市",
    "prefectureNameKatakana": "ｴﾋﾒｹﾝ",
    "cityNameKatakana": "ﾔﾜﾀﾊﾏｼ"
  },
  {
    "groupCode": "382051",
    "prefectureNameKanji": "愛媛県",
    "cityNameKanji": "新居浜市",
    "prefectureNameKatakana": "ｴﾋﾒｹﾝ",
    "cityNameKatakana": "ﾆｲﾊﾏｼ"
  },
  {
    "groupCode": "382060",
    "prefectureNameKanji": "愛媛県",
    "cityNameKanji": "西条市",
    "prefectureNameKatakana": "ｴﾋﾒｹﾝ",
    "cityNameKatakana": "ｻｲｼﾞｮｳｼ"
  },
  {
    "groupCode": "382078",
    "prefectureNameKanji": "愛媛県",
    "cityNameKanji": "大洲市",
    "prefectureNameKatakana": "ｴﾋﾒｹﾝ",
    "cityNameKatakana": "ｵｵｽﾞｼ"
  },
  {
    "groupCode": "382108",
    "prefectureNameKanji": "愛媛県",
    "cityNameKanji": "伊予市",
    "prefectureNameKatakana": "ｴﾋﾒｹﾝ",
    "cityNameKatakana": "ｲﾖｼ"
  },
  {
    "groupCode": "382132",
    "prefectureNameKanji": "愛媛県",
    "cityNameKanji": "四国中央市",
    "prefectureNameKatakana": "ｴﾋﾒｹﾝ",
    "cityNameKatakana": "ｼｺｸﾁｭｳｵｳｼ"
  },
  {
    "groupCode": "382141",
    "prefectureNameKanji": "愛媛県",
    "cityNameKanji": "西予市",
    "prefectureNameKatakana": "ｴﾋﾒｹﾝ",
    "cityNameKatakana": "ｾｲﾖｼ"
  },
  {
    "groupCode": "382159",
    "prefectureNameKanji": "愛媛県",
    "cityNameKanji": "東温市",
    "prefectureNameKatakana": "ｴﾋﾒｹﾝ",
    "cityNameKatakana": "ﾄｳｵﾝｼ"
  },
  {
    "groupCode": "383562",
    "prefectureNameKanji": "愛媛県",
    "cityNameKanji": "上島町",
    "prefectureNameKatakana": "ｴﾋﾒｹﾝ",
    "cityNameKatakana": "ｶﾐｼﾞﾏﾁｮｳ"
  },
  {
    "groupCode": "383864",
    "prefectureNameKanji": "愛媛県",
    "cityNameKanji": "久万高原町",
    "prefectureNameKatakana": "ｴﾋﾒｹﾝ",
    "cityNameKatakana": "ｸﾏｺｳｹﾞﾝﾁｮｳ"
  },
  {
    "groupCode": "384011",
    "prefectureNameKanji": "愛媛県",
    "cityNameKanji": "松前町",
    "prefectureNameKatakana": "ｴﾋﾒｹﾝ",
    "cityNameKatakana": "ﾏｻｷﾁｮｳ"
  },
  {
    "groupCode": "384020",
    "prefectureNameKanji": "愛媛県",
    "cityNameKanji": "砥部町",
    "prefectureNameKatakana": "ｴﾋﾒｹﾝ",
    "cityNameKatakana": "ﾄﾍﾞﾁｮｳ"
  },
  {
    "groupCode": "384224",
    "prefectureNameKanji": "愛媛県",
    "cityNameKanji": "内子町",
    "prefectureNameKatakana": "ｴﾋﾒｹﾝ",
    "cityNameKatakana": "ｳﾁｺﾁｮｳ"
  },
  {
    "groupCode": "384429",
    "prefectureNameKanji": "愛媛県",
    "cityNameKanji": "伊方町",
    "prefectureNameKatakana": "ｴﾋﾒｹﾝ",
    "cityNameKatakana": "ｲｶﾀﾁｮｳ"
  },
  {
    "groupCode": "384844",
    "prefectureNameKanji": "愛媛県",
    "cityNameKanji": "松野町",
    "prefectureNameKatakana": "ｴﾋﾒｹﾝ",
    "cityNameKatakana": "ﾏﾂﾉﾁｮｳ"
  },
  {
    "groupCode": "384887",
    "prefectureNameKanji": "愛媛県",
    "cityNameKanji": "鬼北町",
    "prefectureNameKatakana": "ｴﾋﾒｹﾝ",
    "cityNameKatakana": "ｷﾎｸﾁｮｳ"
  },
  {
    "groupCode": "385069",
    "prefectureNameKanji": "愛媛県",
    "cityNameKanji": "愛南町",
    "prefectureNameKatakana": "ｴﾋﾒｹﾝ",
    "cityNameKatakana": "ｱｲﾅﾝﾁｮｳ"
  },
  {
    "groupCode": "392014",
    "prefectureNameKanji": "高知県",
    "cityNameKanji": "高知市",
    "prefectureNameKatakana": "ｺｳﾁｹﾝ",
    "cityNameKatakana": "ｺｳﾁｼ"
  },
  {
    "groupCode": "392022",
    "prefectureNameKanji": "高知県",
    "cityNameKanji": "室戸市",
    "prefectureNameKatakana": "ｺｳﾁｹﾝ",
    "cityNameKatakana": "ﾑﾛﾄｼ"
  },
  {
    "groupCode": "392031",
    "prefectureNameKanji": "高知県",
    "cityNameKanji": "安芸市",
    "prefectureNameKatakana": "ｺｳﾁｹﾝ",
    "cityNameKatakana": "ｱｷｼ"
  },
  {
    "groupCode": "392049",
    "prefectureNameKanji": "高知県",
    "cityNameKanji": "南国市",
    "prefectureNameKatakana": "ｺｳﾁｹﾝ",
    "cityNameKatakana": "ﾅﾝｺｸｼ"
  },
  {
    "groupCode": "392057",
    "prefectureNameKanji": "高知県",
    "cityNameKanji": "土佐市",
    "prefectureNameKatakana": "ｺｳﾁｹﾝ",
    "cityNameKatakana": "ﾄｻｼ"
  },
  {
    "groupCode": "392065",
    "prefectureNameKanji": "高知県",
    "cityNameKanji": "須崎市",
    "prefectureNameKatakana": "ｺｳﾁｹﾝ",
    "cityNameKatakana": "ｽｻｷｼ"
  },
  {
    "groupCode": "392081",
    "prefectureNameKanji": "高知県",
    "cityNameKanji": "宿毛市",
    "prefectureNameKatakana": "ｺｳﾁｹﾝ",
    "cityNameKatakana": "ｽｸﾓｼ"
  },
  {
    "groupCode": "392090",
    "prefectureNameKanji": "高知県",
    "cityNameKanji": "土佐清水市",
    "prefectureNameKatakana": "ｺｳﾁｹﾝ",
    "cityNameKatakana": "ﾄｻｼﾐｽﾞｼ"
  },
  {
    "groupCode": "392103",
    "prefectureNameKanji": "高知県",
    "cityNameKanji": "四万十市",
    "prefectureNameKatakana": "ｺｳﾁｹﾝ",
    "cityNameKatakana": "ｼﾏﾝﾄｼ"
  },
  {
    "groupCode": "392111",
    "prefectureNameKanji": "高知県",
    "cityNameKanji": "香南市",
    "prefectureNameKatakana": "ｺｳﾁｹﾝ",
    "cityNameKatakana": "ｺｳﾅﾝｼ"
  },
  {
    "groupCode": "392120",
    "prefectureNameKanji": "高知県",
    "cityNameKanji": "香美市",
    "prefectureNameKatakana": "ｺｳﾁｹﾝ",
    "cityNameKatakana": "ｶﾐｼ"
  },
  {
    "groupCode": "393011",
    "prefectureNameKanji": "高知県",
    "cityNameKanji": "東洋町",
    "prefectureNameKatakana": "ｺｳﾁｹﾝ",
    "cityNameKatakana": "ﾄｳﾖｳﾁｮｳ"
  },
  {
    "groupCode": "393029",
    "prefectureNameKanji": "高知県",
    "cityNameKanji": "奈半利町",
    "prefectureNameKatakana": "ｺｳﾁｹﾝ",
    "cityNameKatakana": "ﾅﾊﾘﾁｮｳ"
  },
  {
    "groupCode": "393037",
    "prefectureNameKanji": "高知県",
    "cityNameKanji": "田野町",
    "prefectureNameKatakana": "ｺｳﾁｹﾝ",
    "cityNameKatakana": "ﾀﾉﾁｮｳ"
  },
  {
    "groupCode": "393045",
    "prefectureNameKanji": "高知県",
    "cityNameKanji": "安田町",
    "prefectureNameKatakana": "ｺｳﾁｹﾝ",
    "cityNameKatakana": "ﾔｽﾀﾞﾁｮｳ"
  },
  {
    "groupCode": "393053",
    "prefectureNameKanji": "高知県",
    "cityNameKanji": "北川村",
    "prefectureNameKatakana": "ｺｳﾁｹﾝ",
    "cityNameKatakana": "ｷﾀｶﾞﾜﾑﾗ"
  },
  {
    "groupCode": "393061",
    "prefectureNameKanji": "高知県",
    "cityNameKanji": "馬路村",
    "prefectureNameKatakana": "ｺｳﾁｹﾝ",
    "cityNameKatakana": "ｳﾏｼﾞﾑﾗ"
  },
  {
    "groupCode": "393070",
    "prefectureNameKanji": "高知県",
    "cityNameKanji": "芸西村",
    "prefectureNameKatakana": "ｺｳﾁｹﾝ",
    "cityNameKatakana": "ｹﾞｲｾｲﾑﾗ"
  },
  {
    "groupCode": "393410",
    "prefectureNameKanji": "高知県",
    "cityNameKanji": "本山町",
    "prefectureNameKatakana": "ｺｳﾁｹﾝ",
    "cityNameKatakana": "ﾓﾄﾔﾏﾁｮｳ"
  },
  {
    "groupCode": "393444",
    "prefectureNameKanji": "高知県",
    "cityNameKanji": "大豊町",
    "prefectureNameKatakana": "ｺｳﾁｹﾝ",
    "cityNameKatakana": "ｵｵﾄﾖﾁｮｳ"
  },
  {
    "groupCode": "393631",
    "prefectureNameKanji": "高知県",
    "cityNameKanji": "土佐町",
    "prefectureNameKatakana": "ｺｳﾁｹﾝ",
    "cityNameKatakana": "ﾄｻﾁｮｳ"
  },
  {
    "groupCode": "393649",
    "prefectureNameKanji": "高知県",
    "cityNameKanji": "大川村",
    "prefectureNameKatakana": "ｺｳﾁｹﾝ",
    "cityNameKatakana": "ｵｵｶﾜﾑﾗ"
  },
  {
    "groupCode": "393860",
    "prefectureNameKanji": "高知県",
    "cityNameKanji": "いの町",
    "prefectureNameKatakana": "ｺｳﾁｹﾝ",
    "cityNameKatakana": "ｲﾉﾁｮｳ"
  },
  {
    "groupCode": "393878",
    "prefectureNameKanji": "高知県",
    "cityNameKanji": "仁淀川町",
    "prefectureNameKatakana": "ｺｳﾁｹﾝ",
    "cityNameKatakana": "ﾆﾖﾄﾞｶﾞﾜﾁｮｳ"
  },
  {
    "groupCode": "394017",
    "prefectureNameKanji": "高知県",
    "cityNameKanji": "中土佐町",
    "prefectureNameKatakana": "ｺｳﾁｹﾝ",
    "cityNameKatakana": "ﾅｶﾄｻﾁｮｳ"
  },
  {
    "groupCode": "394025",
    "prefectureNameKanji": "高知県",
    "cityNameKanji": "佐川町",
    "prefectureNameKatakana": "ｺｳﾁｹﾝ",
    "cityNameKatakana": "ｻｶﾜﾁｮｳ"
  },
  {
    "groupCode": "394033",
    "prefectureNameKanji": "高知県",
    "cityNameKanji": "越知町",
    "prefectureNameKatakana": "ｺｳﾁｹﾝ",
    "cityNameKatakana": "ｵﾁﾁｮｳ"
  },
  {
    "groupCode": "394050",
    "prefectureNameKanji": "高知県",
    "cityNameKanji": "梼原町",
    "prefectureNameKatakana": "ｺｳﾁｹﾝ",
    "cityNameKatakana": "ﾕｽﾊﾗﾁｮｳ"
  },
  {
    "groupCode": "394106",
    "prefectureNameKanji": "高知県",
    "cityNameKanji": "日高村",
    "prefectureNameKatakana": "ｺｳﾁｹﾝ",
    "cityNameKatakana": "ﾋﾀﾞｶﾑﾗ"
  },
  {
    "groupCode": "394114",
    "prefectureNameKanji": "高知県",
    "cityNameKanji": "津野町",
    "prefectureNameKatakana": "ｺｳﾁｹﾝ",
    "cityNameKatakana": "ﾂﾉﾁｮｳ"
  },
  {
    "groupCode": "394122",
    "prefectureNameKanji": "高知県",
    "cityNameKanji": "四万十町",
    "prefectureNameKatakana": "ｺｳﾁｹﾝ",
    "cityNameKatakana": "ｼﾏﾝﾄﾁｮｳ"
  },
  {
    "groupCode": "394246",
    "prefectureNameKanji": "高知県",
    "cityNameKanji": "大月町",
    "prefectureNameKatakana": "ｺｳﾁｹﾝ",
    "cityNameKatakana": "ｵｵﾂｷﾁｮｳ"
  },
  {
    "groupCode": "394271",
    "prefectureNameKanji": "高知県",
    "cityNameKanji": "三原村",
    "prefectureNameKatakana": "ｺｳﾁｹﾝ",
    "cityNameKatakana": "ﾐﾊﾗﾑﾗ"
  },
  {
    "groupCode": "394289",
    "prefectureNameKanji": "高知県",
    "cityNameKanji": "黒潮町",
    "prefectureNameKatakana": "ｺｳﾁｹﾝ",
    "cityNameKatakana": "ｸﾛｼｵﾁｮｳ"
  },
  {
    "groupCode": "401005",
    "prefectureNameKanji": "福岡県",
    "cityNameKanji": "北九州市",
    "prefectureNameKatakana": "ﾌｸｵｶｹﾝ",
    "cityNameKatakana": "ｷﾀｷｭｳｼｭｳｼ"
  },
  {
    "groupCode": "401307",
    "prefectureNameKanji": "福岡県",
    "cityNameKanji": "福岡市",
    "prefectureNameKatakana": "ﾌｸｵｶｹﾝ",
    "cityNameKatakana": "ﾌｸｵｶｼ"
  },
  {
    "groupCode": "402028",
    "prefectureNameKanji": "福岡県",
    "cityNameKanji": "大牟田市",
    "prefectureNameKatakana": "ﾌｸｵｶｹﾝ",
    "cityNameKatakana": "ｵｵﾑﾀｼ"
  },
  {
    "groupCode": "402036",
    "prefectureNameKanji": "福岡県",
    "cityNameKanji": "久留米市",
    "prefectureNameKatakana": "ﾌｸｵｶｹﾝ",
    "cityNameKatakana": "ｸﾙﾒｼ"
  },
  {
    "groupCode": "402044",
    "prefectureNameKanji": "福岡県",
    "cityNameKanji": "直方市",
    "prefectureNameKatakana": "ﾌｸｵｶｹﾝ",
    "cityNameKatakana": "ﾉｵｶﾞﾀｼ"
  },
  {
    "groupCode": "402052",
    "prefectureNameKanji": "福岡県",
    "cityNameKanji": "飯塚市",
    "prefectureNameKatakana": "ﾌｸｵｶｹﾝ",
    "cityNameKatakana": "ｲｲﾂﾞｶｼ"
  },
  {
    "groupCode": "402061",
    "prefectureNameKanji": "福岡県",
    "cityNameKanji": "田川市",
    "prefectureNameKatakana": "ﾌｸｵｶｹﾝ",
    "cityNameKatakana": "ﾀｶﾞﾜｼ"
  },
  {
    "groupCode": "402079",
    "prefectureNameKanji": "福岡県",
    "cityNameKanji": "柳川市",
    "prefectureNameKatakana": "ﾌｸｵｶｹﾝ",
    "cityNameKatakana": "ﾔﾅｶﾞﾜｼ"
  },
  {
    "groupCode": "402109",
    "prefectureNameKanji": "福岡県",
    "cityNameKanji": "八女市",
    "prefectureNameKatakana": "ﾌｸｵｶｹﾝ",
    "cityNameKatakana": "ﾔﾒｼ"
  },
  {
    "groupCode": "402117",
    "prefectureNameKanji": "福岡県",
    "cityNameKanji": "筑後市",
    "prefectureNameKatakana": "ﾌｸｵｶｹﾝ",
    "cityNameKatakana": "ﾁｸｺﾞｼ"
  },
  {
    "groupCode": "402125",
    "prefectureNameKanji": "福岡県",
    "cityNameKanji": "大川市",
    "prefectureNameKatakana": "ﾌｸｵｶｹﾝ",
    "cityNameKatakana": "ｵｵｶﾜｼ"
  },
  {
    "groupCode": "402133",
    "prefectureNameKanji": "福岡県",
    "cityNameKanji": "行橋市",
    "prefectureNameKatakana": "ﾌｸｵｶｹﾝ",
    "cityNameKatakana": "ﾕｸﾊｼｼ"
  },
  {
    "groupCode": "402141",
    "prefectureNameKanji": "福岡県",
    "cityNameKanji": "豊前市",
    "prefectureNameKatakana": "ﾌｸｵｶｹﾝ",
    "cityNameKatakana": "ﾌﾞｾﾞﾝｼ"
  },
  {
    "groupCode": "402150",
    "prefectureNameKanji": "福岡県",
    "cityNameKanji": "中間市",
    "prefectureNameKatakana": "ﾌｸｵｶｹﾝ",
    "cityNameKatakana": "ﾅｶﾏｼ"
  },
  {
    "groupCode": "402168",
    "prefectureNameKanji": "福岡県",
    "cityNameKanji": "小郡市",
    "prefectureNameKatakana": "ﾌｸｵｶｹﾝ",
    "cityNameKatakana": "ｵｺﾞｵﾘｼ"
  },
  {
    "groupCode": "402176",
    "prefectureNameKanji": "福岡県",
    "cityNameKanji": "筑紫野市",
    "prefectureNameKatakana": "ﾌｸｵｶｹﾝ",
    "cityNameKatakana": "ﾁｸｼﾉｼ"
  },
  {
    "groupCode": "402184",
    "prefectureNameKanji": "福岡県",
    "cityNameKanji": "春日市",
    "prefectureNameKatakana": "ﾌｸｵｶｹﾝ",
    "cityNameKatakana": "ｶｽｶﾞｼ"
  },
  {
    "groupCode": "402192",
    "prefectureNameKanji": "福岡県",
    "cityNameKanji": "大野城市",
    "prefectureNameKatakana": "ﾌｸｵｶｹﾝ",
    "cityNameKatakana": "ｵｵﾉｼﾞｮｳｼ"
  },
  {
    "groupCode": "402206",
    "prefectureNameKanji": "福岡県",
    "cityNameKanji": "宗像市",
    "prefectureNameKatakana": "ﾌｸｵｶｹﾝ",
    "cityNameKatakana": "ﾑﾅｶﾀｼ"
  },
  {
    "groupCode": "402214",
    "prefectureNameKanji": "福岡県",
    "cityNameKanji": "太宰府市",
    "prefectureNameKatakana": "ﾌｸｵｶｹﾝ",
    "cityNameKatakana": "ﾀﾞｻﾞｲﾌｼ"
  },
  {
    "groupCode": "402231",
    "prefectureNameKanji": "福岡県",
    "cityNameKanji": "古賀市",
    "prefectureNameKatakana": "ﾌｸｵｶｹﾝ",
    "cityNameKatakana": "ｺｶﾞｼ"
  },
  {
    "groupCode": "402249",
    "prefectureNameKanji": "福岡県",
    "cityNameKanji": "福津市",
    "prefectureNameKatakana": "ﾌｸｵｶｹﾝ",
    "cityNameKatakana": "ﾌｸﾂｼ"
  },
  {
    "groupCode": "402257",
    "prefectureNameKanji": "福岡県",
    "cityNameKanji": "うきは市",
    "prefectureNameKatakana": "ﾌｸｵｶｹﾝ",
    "cityNameKatakana": "ｳｷﾊｼ"
  },
  {
    "groupCode": "402265",
    "prefectureNameKanji": "福岡県",
    "cityNameKanji": "宮若市",
    "prefectureNameKatakana": "ﾌｸｵｶｹﾝ",
    "cityNameKatakana": "ﾐﾔﾜｶｼ"
  },
  {
    "groupCode": "402273",
    "prefectureNameKanji": "福岡県",
    "cityNameKanji": "嘉麻市",
    "prefectureNameKatakana": "ﾌｸｵｶｹﾝ",
    "cityNameKatakana": "ｶﾏｼ"
  },
  {
    "groupCode": "402281",
    "prefectureNameKanji": "福岡県",
    "cityNameKanji": "朝倉市",
    "prefectureNameKatakana": "ﾌｸｵｶｹﾝ",
    "cityNameKatakana": "ｱｻｸﾗｼ"
  },
  {
    "groupCode": "402290",
    "prefectureNameKanji": "福岡県",
    "cityNameKanji": "みやま市",
    "prefectureNameKatakana": "ﾌｸｵｶｹﾝ",
    "cityNameKatakana": "ﾐﾔﾏｼ"
  },
  {
    "groupCode": "402303",
    "prefectureNameKanji": "福岡県",
    "cityNameKanji": "糸島市",
    "prefectureNameKatakana": "ﾌｸｵｶｹﾝ",
    "cityNameKatakana": "ｲﾄｼﾏｼ"
  },
  {
    "groupCode": "402311",
    "prefectureNameKanji": "福岡県",
    "cityNameKanji": "那珂川市",
    "prefectureNameKatakana": "ﾌｸｵｶｹﾝ",
    "cityNameKatakana": "ﾅｶｶﾞﾜｼ"
  },
  {
    "groupCode": "403415",
    "prefectureNameKanji": "福岡県",
    "cityNameKanji": "宇美町",
    "prefectureNameKatakana": "ﾌｸｵｶｹﾝ",
    "cityNameKatakana": "ｳﾐﾏﾁ"
  },
  {
    "groupCode": "403423",
    "prefectureNameKanji": "福岡県",
    "cityNameKanji": "篠栗町",
    "prefectureNameKatakana": "ﾌｸｵｶｹﾝ",
    "cityNameKatakana": "ｻｻｸﾞﾘﾏﾁ"
  },
  {
    "groupCode": "403431",
    "prefectureNameKanji": "福岡県",
    "cityNameKanji": "志免町",
    "prefectureNameKatakana": "ﾌｸｵｶｹﾝ",
    "cityNameKatakana": "ｼﾒﾏﾁ"
  },
  {
    "groupCode": "403440",
    "prefectureNameKanji": "福岡県",
    "cityNameKanji": "須恵町",
    "prefectureNameKatakana": "ﾌｸｵｶｹﾝ",
    "cityNameKatakana": "ｽｴﾏﾁ"
  },
  {
    "groupCode": "403458",
    "prefectureNameKanji": "福岡県",
    "cityNameKanji": "新宮町",
    "prefectureNameKatakana": "ﾌｸｵｶｹﾝ",
    "cityNameKatakana": "ｼﾝｸﾞｳﾏﾁ"
  },
  {
    "groupCode": "403482",
    "prefectureNameKanji": "福岡県",
    "cityNameKanji": "久山町",
    "prefectureNameKatakana": "ﾌｸｵｶｹﾝ",
    "cityNameKatakana": "ﾋｻﾔﾏﾏﾁ"
  },
  {
    "groupCode": "403491",
    "prefectureNameKanji": "福岡県",
    "cityNameKanji": "粕屋町",
    "prefectureNameKatakana": "ﾌｸｵｶｹﾝ",
    "cityNameKatakana": "ｶｽﾔﾏﾁ"
  },
  {
    "groupCode": "403814",
    "prefectureNameKanji": "福岡県",
    "cityNameKanji": "芦屋町",
    "prefectureNameKatakana": "ﾌｸｵｶｹﾝ",
    "cityNameKatakana": "ｱｼﾔﾏﾁ"
  },
  {
    "groupCode": "403822",
    "prefectureNameKanji": "福岡県",
    "cityNameKanji": "水巻町",
    "prefectureNameKatakana": "ﾌｸｵｶｹﾝ",
    "cityNameKatakana": "ﾐｽﾞﾏｷﾏﾁ"
  },
  {
    "groupCode": "403831",
    "prefectureNameKanji": "福岡県",
    "cityNameKanji": "岡垣町",
    "prefectureNameKatakana": "ﾌｸｵｶｹﾝ",
    "cityNameKatakana": "ｵｶｶﾞｷﾏﾁ"
  },
  {
    "groupCode": "403849",
    "prefectureNameKanji": "福岡県",
    "cityNameKanji": "遠賀町",
    "prefectureNameKatakana": "ﾌｸｵｶｹﾝ",
    "cityNameKatakana": "ｵﾝｶﾞﾁｮｳ"
  },
  {
    "groupCode": "404012",
    "prefectureNameKanji": "福岡県",
    "cityNameKanji": "小竹町",
    "prefectureNameKatakana": "ﾌｸｵｶｹﾝ",
    "cityNameKatakana": "ｺﾀｹﾏﾁ"
  },
  {
    "groupCode": "404021",
    "prefectureNameKanji": "福岡県",
    "cityNameKanji": "鞍手町",
    "prefectureNameKatakana": "ﾌｸｵｶｹﾝ",
    "cityNameKatakana": "ｸﾗﾃﾏﾁ"
  },
  {
    "groupCode": "404217",
    "prefectureNameKanji": "福岡県",
    "cityNameKanji": "桂川町",
    "prefectureNameKatakana": "ﾌｸｵｶｹﾝ",
    "cityNameKatakana": "ｹｲｾﾝﾏﾁ"
  },
  {
    "groupCode": "404471",
    "prefectureNameKanji": "福岡県",
    "cityNameKanji": "筑前町",
    "prefectureNameKatakana": "ﾌｸｵｶｹﾝ",
    "cityNameKatakana": "ﾁｸｾﾞﾝﾏﾁ"
  },
  {
    "groupCode": "404489",
    "prefectureNameKanji": "福岡県",
    "cityNameKanji": "東峰村",
    "prefectureNameKatakana": "ﾌｸｵｶｹﾝ",
    "cityNameKatakana": "ﾄｳﾎｳﾑﾗ"
  },
  {
    "groupCode": "405035",
    "prefectureNameKanji": "福岡県",
    "cityNameKanji": "大刀洗町",
    "prefectureNameKatakana": "ﾌｸｵｶｹﾝ",
    "cityNameKatakana": "ﾀﾁｱﾗｲﾏﾁ"
  },
  {
    "groupCode": "405221",
    "prefectureNameKanji": "福岡県",
    "cityNameKanji": "大木町",
    "prefectureNameKatakana": "ﾌｸｵｶｹﾝ",
    "cityNameKatakana": "ｵｵｷﾏﾁ"
  },
  {
    "groupCode": "405442",
    "prefectureNameKanji": "福岡県",
    "cityNameKanji": "広川町",
    "prefectureNameKatakana": "ﾌｸｵｶｹﾝ",
    "cityNameKatakana": "ﾋﾛｶﾜﾏﾁ"
  },
  {
    "groupCode": "406015",
    "prefectureNameKanji": "福岡県",
    "cityNameKanji": "香春町",
    "prefectureNameKatakana": "ﾌｸｵｶｹﾝ",
    "cityNameKatakana": "ｶﾜﾗﾏﾁ"
  },
  {
    "groupCode": "406023",
    "prefectureNameKanji": "福岡県",
    "cityNameKanji": "添田町",
    "prefectureNameKatakana": "ﾌｸｵｶｹﾝ",
    "cityNameKatakana": "ｿｴﾀﾞﾏﾁ"
  },
  {
    "groupCode": "406040",
    "prefectureNameKanji": "福岡県",
    "cityNameKanji": "糸田町",
    "prefectureNameKatakana": "ﾌｸｵｶｹﾝ",
    "cityNameKatakana": "ｲﾄﾀﾞﾏﾁ"
  },
  {
    "groupCode": "406058",
    "prefectureNameKanji": "福岡県",
    "cityNameKanji": "川崎町",
    "prefectureNameKatakana": "ﾌｸｵｶｹﾝ",
    "cityNameKatakana": "ｶﾜｻｷﾏﾁ"
  },
  {
    "groupCode": "406082",
    "prefectureNameKanji": "福岡県",
    "cityNameKanji": "大任町",
    "prefectureNameKatakana": "ﾌｸｵｶｹﾝ",
    "cityNameKatakana": "ｵｵﾄｳﾏﾁ"
  },
  {
    "groupCode": "406091",
    "prefectureNameKanji": "福岡県",
    "cityNameKanji": "赤村",
    "prefectureNameKatakana": "ﾌｸｵｶｹﾝ",
    "cityNameKatakana": "ｱｶﾑﾗ"
  },
  {
    "groupCode": "406104",
    "prefectureNameKanji": "福岡県",
    "cityNameKanji": "福智町",
    "prefectureNameKatakana": "ﾌｸｵｶｹﾝ",
    "cityNameKatakana": "ﾌｸﾁﾏﾁ"
  },
  {
    "groupCode": "406210",
    "prefectureNameKanji": "福岡県",
    "cityNameKanji": "苅田町",
    "prefectureNameKatakana": "ﾌｸｵｶｹﾝ",
    "cityNameKatakana": "ｶﾝﾀﾞﾏﾁ"
  },
  {
    "groupCode": "406252",
    "prefectureNameKanji": "福岡県",
    "cityNameKanji": "みやこ町",
    "prefectureNameKatakana": "ﾌｸｵｶｹﾝ",
    "cityNameKatakana": "ﾐﾔｺﾏﾁ"
  },
  {
    "groupCode": "406422",
    "prefectureNameKanji": "福岡県",
    "cityNameKanji": "吉富町",
    "prefectureNameKatakana": "ﾌｸｵｶｹﾝ",
    "cityNameKatakana": "ﾖｼﾄﾐﾏﾁ"
  },
  {
    "groupCode": "406465",
    "prefectureNameKanji": "福岡県",
    "cityNameKanji": "上毛町",
    "prefectureNameKatakana": "ﾌｸｵｶｹﾝ",
    "cityNameKatakana": "ｺｳｹﾞﾏﾁ"
  },
  {
    "groupCode": "406473",
    "prefectureNameKanji": "福岡県",
    "cityNameKanji": "築上町",
    "prefectureNameKatakana": "ﾌｸｵｶｹﾝ",
    "cityNameKatakana": "ﾁｸｼﾞｮｳﾏﾁ"
  },
  {
    "groupCode": "412015",
    "prefectureNameKanji": "佐賀県",
    "cityNameKanji": "佐賀市",
    "prefectureNameKatakana": "ｻｶﾞｹﾝ",
    "cityNameKatakana": "ｻｶﾞｼ"
  },
  {
    "groupCode": "412023",
    "prefectureNameKanji": "佐賀県",
    "cityNameKanji": "唐津市",
    "prefectureNameKatakana": "ｻｶﾞｹﾝ",
    "cityNameKatakana": "ｶﾗﾂｼ"
  },
  {
    "groupCode": "412031",
    "prefectureNameKanji": "佐賀県",
    "cityNameKanji": "鳥栖市",
    "prefectureNameKatakana": "ｻｶﾞｹﾝ",
    "cityNameKatakana": "ﾄｽｼ"
  },
  {
    "groupCode": "412040",
    "prefectureNameKanji": "佐賀県",
    "cityNameKanji": "多久市",
    "prefectureNameKatakana": "ｻｶﾞｹﾝ",
    "cityNameKatakana": "ﾀｸｼ"
  },
  {
    "groupCode": "412058",
    "prefectureNameKanji": "佐賀県",
    "cityNameKanji": "伊万里市",
    "prefectureNameKatakana": "ｻｶﾞｹﾝ",
    "cityNameKatakana": "ｲﾏﾘｼ"
  },
  {
    "groupCode": "412066",
    "prefectureNameKanji": "佐賀県",
    "cityNameKanji": "武雄市",
    "prefectureNameKatakana": "ｻｶﾞｹﾝ",
    "cityNameKatakana": "ﾀｹｵｼ"
  },
  {
    "groupCode": "412074",
    "prefectureNameKanji": "佐賀県",
    "cityNameKanji": "鹿島市",
    "prefectureNameKatakana": "ｻｶﾞｹﾝ",
    "cityNameKatakana": "ｶｼﾏｼ"
  },
  {
    "groupCode": "412082",
    "prefectureNameKanji": "佐賀県",
    "cityNameKanji": "小城市",
    "prefectureNameKatakana": "ｻｶﾞｹﾝ",
    "cityNameKatakana": "ｵｷﾞｼ"
  },
  {
    "groupCode": "412091",
    "prefectureNameKanji": "佐賀県",
    "cityNameKanji": "嬉野市",
    "prefectureNameKatakana": "ｻｶﾞｹﾝ",
    "cityNameKatakana": "ｳﾚｼﾉｼ"
  },
  {
    "groupCode": "412104",
    "prefectureNameKanji": "佐賀県",
    "cityNameKanji": "神埼市",
    "prefectureNameKatakana": "ｻｶﾞｹﾝ",
    "cityNameKatakana": "ｶﾝｻﾞｷｼ"
  },
  {
    "groupCode": "413275",
    "prefectureNameKanji": "佐賀県",
    "cityNameKanji": "吉野ヶ里町",
    "prefectureNameKatakana": "ｻｶﾞｹﾝ",
    "cityNameKatakana": "ﾖｼﾉｶﾞﾘﾁｮｳ"
  },
  {
    "groupCode": "413411",
    "prefectureNameKanji": "佐賀県",
    "cityNameKanji": "基山町",
    "prefectureNameKatakana": "ｻｶﾞｹﾝ",
    "cityNameKatakana": "ｷﾔﾏﾁｮｳ"
  },
  {
    "groupCode": "413453",
    "prefectureNameKanji": "佐賀県",
    "cityNameKanji": "上峰町",
    "prefectureNameKatakana": "ｻｶﾞｹﾝ",
    "cityNameKatakana": "ｶﾐﾐﾈﾁｮｳ"
  },
  {
    "groupCode": "413461",
    "prefectureNameKanji": "佐賀県",
    "cityNameKanji": "みやき町",
    "prefectureNameKatakana": "ｻｶﾞｹﾝ",
    "cityNameKatakana": "ﾐﾔｷﾁｮｳ"
  },
  {
    "groupCode": "413879",
    "prefectureNameKanji": "佐賀県",
    "cityNameKanji": "玄海町",
    "prefectureNameKatakana": "ｻｶﾞｹﾝ",
    "cityNameKatakana": "ｹﾞﾝｶｲﾁｮｳ"
  },
  {
    "groupCode": "414018",
    "prefectureNameKanji": "佐賀県",
    "cityNameKanji": "有田町",
    "prefectureNameKatakana": "ｻｶﾞｹﾝ",
    "cityNameKatakana": "ｱﾘﾀﾁｮｳ"
  },
  {
    "groupCode": "414239",
    "prefectureNameKanji": "佐賀県",
    "cityNameKanji": "大町町",
    "prefectureNameKatakana": "ｻｶﾞｹﾝ",
    "cityNameKatakana": "ｵｵﾏﾁﾁｮｳ"
  },
  {
    "groupCode": "414247",
    "prefectureNameKanji": "佐賀県",
    "cityNameKanji": "江北町",
    "prefectureNameKatakana": "ｻｶﾞｹﾝ",
    "cityNameKatakana": "ｺｳﾎｸﾏﾁ"
  },
  {
    "groupCode": "414255",
    "prefectureNameKanji": "佐賀県",
    "cityNameKanji": "白石町",
    "prefectureNameKatakana": "ｻｶﾞｹﾝ",
    "cityNameKatakana": "ｼﾛｲｼﾁｮｳ"
  },
  {
    "groupCode": "414417",
    "prefectureNameKanji": "佐賀県",
    "cityNameKanji": "太良町",
    "prefectureNameKatakana": "ｻｶﾞｹﾝ",
    "cityNameKatakana": "ﾀﾗﾁｮｳ"
  },
  {
    "groupCode": "422011",
    "prefectureNameKanji": "長崎県",
    "cityNameKanji": "長崎市",
    "prefectureNameKatakana": "ﾅｶﾞｻｷｹﾝ",
    "cityNameKatakana": "ﾅｶﾞｻｷｼ"
  },
  {
    "groupCode": "422029",
    "prefectureNameKanji": "長崎県",
    "cityNameKanji": "佐世保市",
    "prefectureNameKatakana": "ﾅｶﾞｻｷｹﾝ",
    "cityNameKatakana": "ｻｾﾎﾞｼ"
  },
  {
    "groupCode": "422037",
    "prefectureNameKanji": "長崎県",
    "cityNameKanji": "島原市",
    "prefectureNameKatakana": "ﾅｶﾞｻｷｹﾝ",
    "cityNameKatakana": "ｼﾏﾊﾞﾗｼ"
  },
  {
    "groupCode": "422045",
    "prefectureNameKanji": "長崎県",
    "cityNameKanji": "諫早市",
    "prefectureNameKatakana": "ﾅｶﾞｻｷｹﾝ",
    "cityNameKatakana": "ｲｻﾊﾔｼ"
  },
  {
    "groupCode": "422053",
    "prefectureNameKanji": "長崎県",
    "cityNameKanji": "大村市",
    "prefectureNameKatakana": "ﾅｶﾞｻｷｹﾝ",
    "cityNameKatakana": "ｵｵﾑﾗｼ"
  },
  {
    "groupCode": "422070",
    "prefectureNameKanji": "長崎県",
    "cityNameKanji": "平戸市",
    "prefectureNameKatakana": "ﾅｶﾞｻｷｹﾝ",
    "cityNameKatakana": "ﾋﾗﾄﾞｼ"
  },
  {
    "groupCode": "422088",
    "prefectureNameKanji": "長崎県",
    "cityNameKanji": "松浦市",
    "prefectureNameKatakana": "ﾅｶﾞｻｷｹﾝ",
    "cityNameKatakana": "ﾏﾂｳﾗｼ"
  },
  {
    "groupCode": "422096",
    "prefectureNameKanji": "長崎県",
    "cityNameKanji": "対馬市",
    "prefectureNameKatakana": "ﾅｶﾞｻｷｹﾝ",
    "cityNameKatakana": "ﾂｼﾏｼ"
  },
  {
    "groupCode": "422100",
    "prefectureNameKanji": "長崎県",
    "cityNameKanji": "壱岐市",
    "prefectureNameKatakana": "ﾅｶﾞｻｷｹﾝ",
    "cityNameKatakana": "ｲｷｼ"
  },
  {
    "groupCode": "422118",
    "prefectureNameKanji": "長崎県",
    "cityNameKanji": "五島市",
    "prefectureNameKatakana": "ﾅｶﾞｻｷｹﾝ",
    "cityNameKatakana": "ｺﾞﾄｳｼ"
  },
  {
    "groupCode": "422126",
    "prefectureNameKanji": "長崎県",
    "cityNameKanji": "西海市",
    "prefectureNameKatakana": "ﾅｶﾞｻｷｹﾝ",
    "cityNameKatakana": "ｻｲｶｲｼ"
  },
  {
    "groupCode": "422134",
    "prefectureNameKanji": "長崎県",
    "cityNameKanji": "雲仙市",
    "prefectureNameKatakana": "ﾅｶﾞｻｷｹﾝ",
    "cityNameKatakana": "ｳﾝｾﾞﾝｼ"
  },
  {
    "groupCode": "422142",
    "prefectureNameKanji": "長崎県",
    "cityNameKanji": "南島原市",
    "prefectureNameKatakana": "ﾅｶﾞｻｷｹﾝ",
    "cityNameKatakana": "ﾐﾅﾐｼﾏﾊﾞﾗｼ"
  },
  {
    "groupCode": "423076",
    "prefectureNameKanji": "長崎県",
    "cityNameKanji": "長与町",
    "prefectureNameKatakana": "ﾅｶﾞｻｷｹﾝ",
    "cityNameKatakana": "ﾅｶﾞﾖﾁｮｳ"
  },
  {
    "groupCode": "423084",
    "prefectureNameKanji": "長崎県",
    "cityNameKanji": "時津町",
    "prefectureNameKatakana": "ﾅｶﾞｻｷｹﾝ",
    "cityNameKatakana": "ﾄｷﾞﾂﾁｮｳ"
  },
  {
    "groupCode": "423211",
    "prefectureNameKanji": "長崎県",
    "cityNameKanji": "東彼杵町",
    "prefectureNameKatakana": "ﾅｶﾞｻｷｹﾝ",
    "cityNameKatakana": "ﾋｶﾞｼｿﾉｷﾞﾁｮｳ"
  },
  {
    "groupCode": "423220",
    "prefectureNameKanji": "長崎県",
    "cityNameKanji": "川棚町",
    "prefectureNameKatakana": "ﾅｶﾞｻｷｹﾝ",
    "cityNameKatakana": "ｶﾜﾀﾅﾁｮｳ"
  },
  {
    "groupCode": "423238",
    "prefectureNameKanji": "長崎県",
    "cityNameKanji": "波佐見町",
    "prefectureNameKatakana": "ﾅｶﾞｻｷｹﾝ",
    "cityNameKatakana": "ﾊｻﾐﾁｮｳ"
  },
  {
    "groupCode": "423831",
    "prefectureNameKanji": "長崎県",
    "cityNameKanji": "小値賀町",
    "prefectureNameKatakana": "ﾅｶﾞｻｷｹﾝ",
    "cityNameKatakana": "ｵﾁﾞｶﾁｮｳ"
  },
  {
    "groupCode": "423912",
    "prefectureNameKanji": "長崎県",
    "cityNameKanji": "佐々町",
    "prefectureNameKatakana": "ﾅｶﾞｻｷｹﾝ",
    "cityNameKatakana": "ｻｻﾞﾁｮｳ"
  },
  {
    "groupCode": "424111",
    "prefectureNameKanji": "長崎県",
    "cityNameKanji": "新上五島町",
    "prefectureNameKatakana": "ﾅｶﾞｻｷｹﾝ",
    "cityNameKatakana": "ｼﾝｶﾐｺﾞﾄｳﾁｮｳ"
  },
  {
    "groupCode": "431001",
    "prefectureNameKanji": "熊本県",
    "cityNameKanji": "熊本市",
    "prefectureNameKatakana": "ｸﾏﾓﾄｹﾝ",
    "cityNameKatakana": "ｸﾏﾓﾄｼ"
  },
  {
    "groupCode": "432024",
    "prefectureNameKanji": "熊本県",
    "cityNameKanji": "八代市",
    "prefectureNameKatakana": "ｸﾏﾓﾄｹﾝ",
    "cityNameKatakana": "ﾔﾂｼﾛｼ"
  },
  {
    "groupCode": "432032",
    "prefectureNameKanji": "熊本県",
    "cityNameKanji": "人吉市",
    "prefectureNameKatakana": "ｸﾏﾓﾄｹﾝ",
    "cityNameKatakana": "ﾋﾄﾖｼｼ"
  },
  {
    "groupCode": "432041",
    "prefectureNameKanji": "熊本県",
    "cityNameKanji": "荒尾市",
    "prefectureNameKatakana": "ｸﾏﾓﾄｹﾝ",
    "cityNameKatakana": "ｱﾗｵｼ"
  },
  {
    "groupCode": "432059",
    "prefectureNameKanji": "熊本県",
    "cityNameKanji": "水俣市",
    "prefectureNameKatakana": "ｸﾏﾓﾄｹﾝ",
    "cityNameKatakana": "ﾐﾅﾏﾀｼ"
  },
  {
    "groupCode": "432067",
    "prefectureNameKanji": "熊本県",
    "cityNameKanji": "玉名市",
    "prefectureNameKatakana": "ｸﾏﾓﾄｹﾝ",
    "cityNameKatakana": "ﾀﾏﾅｼ"
  },
  {
    "groupCode": "432083",
    "prefectureNameKanji": "熊本県",
    "cityNameKanji": "山鹿市",
    "prefectureNameKatakana": "ｸﾏﾓﾄｹﾝ",
    "cityNameKatakana": "ﾔﾏｶﾞｼ"
  },
  {
    "groupCode": "432105",
    "prefectureNameKanji": "熊本県",
    "cityNameKanji": "菊池市",
    "prefectureNameKatakana": "ｸﾏﾓﾄｹﾝ",
    "cityNameKatakana": "ｷｸﾁｼ"
  },
  {
    "groupCode": "432113",
    "prefectureNameKanji": "熊本県",
    "cityNameKanji": "宇土市",
    "prefectureNameKatakana": "ｸﾏﾓﾄｹﾝ",
    "cityNameKatakana": "ｳﾄｼ"
  },
  {
    "groupCode": "432121",
    "prefectureNameKanji": "熊本県",
    "cityNameKanji": "上天草市",
    "prefectureNameKatakana": "ｸﾏﾓﾄｹﾝ",
    "cityNameKatakana": "ｶﾐｱﾏｸｻｼ"
  },
  {
    "groupCode": "432130",
    "prefectureNameKanji": "熊本県",
    "cityNameKanji": "宇城市",
    "prefectureNameKatakana": "ｸﾏﾓﾄｹﾝ",
    "cityNameKatakana": "ｳｷｼ"
  },
  {
    "groupCode": "432148",
    "prefectureNameKanji": "熊本県",
    "cityNameKanji": "阿蘇市",
    "prefectureNameKatakana": "ｸﾏﾓﾄｹﾝ",
    "cityNameKatakana": "ｱｿｼ"
  },
  {
    "groupCode": "432156",
    "prefectureNameKanji": "熊本県",
    "cityNameKanji": "天草市",
    "prefectureNameKatakana": "ｸﾏﾓﾄｹﾝ",
    "cityNameKatakana": "ｱﾏｸｻｼ"
  },
  {
    "groupCode": "432164",
    "prefectureNameKanji": "熊本県",
    "cityNameKanji": "合志市",
    "prefectureNameKatakana": "ｸﾏﾓﾄｹﾝ",
    "cityNameKatakana": "ｺｳｼｼ"
  },
  {
    "groupCode": "433489",
    "prefectureNameKanji": "熊本県",
    "cityNameKanji": "美里町",
    "prefectureNameKatakana": "ｸﾏﾓﾄｹﾝ",
    "cityNameKatakana": "ﾐｻﾄﾏﾁ"
  },
  {
    "groupCode": "433641",
    "prefectureNameKanji": "熊本県",
    "cityNameKanji": "玉東町",
    "prefectureNameKatakana": "ｸﾏﾓﾄｹﾝ",
    "cityNameKatakana": "ｷﾞｮｸﾄｳﾏﾁ"
  },
  {
    "groupCode": "433675",
    "prefectureNameKanji": "熊本県",
    "cityNameKanji": "南関町",
    "prefectureNameKatakana": "ｸﾏﾓﾄｹﾝ",
    "cityNameKatakana": "ﾅﾝｶﾝﾏﾁ"
  },
  {
    "groupCode": "433683",
    "prefectureNameKanji": "熊本県",
    "cityNameKanji": "長洲町",
    "prefectureNameKatakana": "ｸﾏﾓﾄｹﾝ",
    "cityNameKatakana": "ﾅｶﾞｽﾏﾁ"
  },
  {
    "groupCode": "433691",
    "prefectureNameKanji": "熊本県",
    "cityNameKanji": "和水町",
    "prefectureNameKatakana": "ｸﾏﾓﾄｹﾝ",
    "cityNameKatakana": "ﾅｺﾞﾐﾏﾁ"
  },
  {
    "groupCode": "434035",
    "prefectureNameKanji": "熊本県",
    "cityNameKanji": "大津町",
    "prefectureNameKatakana": "ｸﾏﾓﾄｹﾝ",
    "cityNameKatakana": "ｵｵﾂﾞﾏﾁ"
  },
  {
    "groupCode": "434043",
    "prefectureNameKanji": "熊本県",
    "cityNameKanji": "菊陽町",
    "prefectureNameKatakana": "ｸﾏﾓﾄｹﾝ",
    "cityNameKatakana": "ｷｸﾖｳﾏﾁ"
  },
  {
    "groupCode": "434230",
    "prefectureNameKanji": "熊本県",
    "cityNameKanji": "南小国町",
    "prefectureNameKatakana": "ｸﾏﾓﾄｹﾝ",
    "cityNameKatakana": "ﾐﾅﾐｵｸﾞﾆﾏﾁ"
  },
  {
    "groupCode": "434248",
    "prefectureNameKanji": "熊本県",
    "cityNameKanji": "小国町",
    "prefectureNameKatakana": "ｸﾏﾓﾄｹﾝ",
    "cityNameKatakana": "ｵｸﾞﾆﾏﾁ"
  },
  {
    "groupCode": "434256",
    "prefectureNameKanji": "熊本県",
    "cityNameKanji": "産山村",
    "prefectureNameKatakana": "ｸﾏﾓﾄｹﾝ",
    "cityNameKatakana": "ｳﾌﾞﾔﾏﾑﾗ"
  },
  {
    "groupCode": "434281",
    "prefectureNameKanji": "熊本県",
    "cityNameKanji": "高森町",
    "prefectureNameKatakana": "ｸﾏﾓﾄｹﾝ",
    "cityNameKatakana": "ﾀｶﾓﾘﾏﾁ"
  },
  {
    "groupCode": "434329",
    "prefectureNameKanji": "熊本県",
    "cityNameKanji": "西原村",
    "prefectureNameKatakana": "ｸﾏﾓﾄｹﾝ",
    "cityNameKatakana": "ﾆｼﾊﾗﾑﾗ"
  },
  {
    "groupCode": "434337",
    "prefectureNameKanji": "熊本県",
    "cityNameKanji": "南阿蘇村",
    "prefectureNameKatakana": "ｸﾏﾓﾄｹﾝ",
    "cityNameKatakana": "ﾐﾅﾐｱｿﾑﾗ"
  },
  {
    "groupCode": "434418",
    "prefectureNameKanji": "熊本県",
    "cityNameKanji": "御船町",
    "prefectureNameKatakana": "ｸﾏﾓﾄｹﾝ",
    "cityNameKatakana": "ﾐﾌﾈﾏﾁ"
  },
  {
    "groupCode": "434426",
    "prefectureNameKanji": "熊本県",
    "cityNameKanji": "嘉島町",
    "prefectureNameKatakana": "ｸﾏﾓﾄｹﾝ",
    "cityNameKatakana": "ｶｼﾏﾏﾁ"
  },
  {
    "groupCode": "434434",
    "prefectureNameKanji": "熊本県",
    "cityNameKanji": "益城町",
    "prefectureNameKatakana": "ｸﾏﾓﾄｹﾝ",
    "cityNameKatakana": "ﾏｼｷﾏﾁ"
  },
  {
    "groupCode": "434442",
    "prefectureNameKanji": "熊本県",
    "cityNameKanji": "甲佐町",
    "prefectureNameKatakana": "ｸﾏﾓﾄｹﾝ",
    "cityNameKatakana": "ｺｳｻﾏﾁ"
  },
  {
    "groupCode": "434477",
    "prefectureNameKanji": "熊本県",
    "cityNameKanji": "山都町",
    "prefectureNameKatakana": "ｸﾏﾓﾄｹﾝ",
    "cityNameKatakana": "ﾔﾏﾄﾁｮｳ"
  },
  {
    "groupCode": "434680",
    "prefectureNameKanji": "熊本県",
    "cityNameKanji": "氷川町",
    "prefectureNameKatakana": "ｸﾏﾓﾄｹﾝ",
    "cityNameKatakana": "ﾋｶﾜﾁｮｳ"
  },
  {
    "groupCode": "434825",
    "prefectureNameKanji": "熊本県",
    "cityNameKanji": "芦北町",
    "prefectureNameKatakana": "ｸﾏﾓﾄｹﾝ",
    "cityNameKatakana": "ｱｼｷﾀﾏﾁ"
  },
  {
    "groupCode": "434841",
    "prefectureNameKanji": "熊本県",
    "cityNameKanji": "津奈木町",
    "prefectureNameKatakana": "ｸﾏﾓﾄｹﾝ",
    "cityNameKatakana": "ﾂﾅｷﾞﾏﾁ"
  },
  {
    "groupCode": "435015",
    "prefectureNameKanji": "熊本県",
    "cityNameKanji": "錦町",
    "prefectureNameKatakana": "ｸﾏﾓﾄｹﾝ",
    "cityNameKatakana": "ﾆｼｷﾏﾁ"
  },
  {
    "groupCode": "435058",
    "prefectureNameKanji": "熊本県",
    "cityNameKanji": "多良木町",
    "prefectureNameKatakana": "ｸﾏﾓﾄｹﾝ",
    "cityNameKatakana": "ﾀﾗｷﾞﾏﾁ"
  },
  {
    "groupCode": "435066",
    "prefectureNameKanji": "熊本県",
    "cityNameKanji": "湯前町",
    "prefectureNameKatakana": "ｸﾏﾓﾄｹﾝ",
    "cityNameKatakana": "ﾕﾉﾏｴﾏﾁ"
  },
  {
    "groupCode": "435074",
    "prefectureNameKanji": "熊本県",
    "cityNameKanji": "水上村",
    "prefectureNameKatakana": "ｸﾏﾓﾄｹﾝ",
    "cityNameKatakana": "ﾐｽﾞｶﾐﾑﾗ"
  },
  {
    "groupCode": "435104",
    "prefectureNameKanji": "熊本県",
    "cityNameKanji": "相良村",
    "prefectureNameKatakana": "ｸﾏﾓﾄｹﾝ",
    "cityNameKatakana": "ｻｶﾞﾗﾑﾗ"
  },
  {
    "groupCode": "435112",
    "prefectureNameKanji": "熊本県",
    "cityNameKanji": "五木村",
    "prefectureNameKatakana": "ｸﾏﾓﾄｹﾝ",
    "cityNameKatakana": "ｲﾂｷﾑﾗ"
  },
  {
    "groupCode": "435121",
    "prefectureNameKanji": "熊本県",
    "cityNameKanji": "山江村",
    "prefectureNameKatakana": "ｸﾏﾓﾄｹﾝ",
    "cityNameKatakana": "ﾔﾏｴﾑﾗ"
  },
  {
    "groupCode": "435139",
    "prefectureNameKanji": "熊本県",
    "cityNameKanji": "球磨村",
    "prefectureNameKatakana": "ｸﾏﾓﾄｹﾝ",
    "cityNameKatakana": "ｸﾏﾑﾗ"
  },
  {
    "groupCode": "435147",
    "prefectureNameKanji": "熊本県",
    "cityNameKanji": "あさぎり町",
    "prefectureNameKatakana": "ｸﾏﾓﾄｹﾝ",
    "cityNameKatakana": "ｱｻｷﾞﾘﾁｮｳ"
  },
  {
    "groupCode": "435317",
    "prefectureNameKanji": "熊本県",
    "cityNameKanji": "苓北町",
    "prefectureNameKatakana": "ｸﾏﾓﾄｹﾝ",
    "cityNameKatakana": "ﾚｲﾎｸﾏﾁ"
  },
  {
    "groupCode": "442011",
    "prefectureNameKanji": "大分県",
    "cityNameKanji": "大分市",
    "prefectureNameKatakana": "ｵｵｲﾀｹﾝ",
    "cityNameKatakana": "ｵｵｲﾀｼ"
  },
  {
    "groupCode": "442020",
    "prefectureNameKanji": "大分県",
    "cityNameKanji": "別府市",
    "prefectureNameKatakana": "ｵｵｲﾀｹﾝ",
    "cityNameKatakana": "ﾍﾞｯﾌﾟｼ"
  },
  {
    "groupCode": "442038",
    "prefectureNameKanji": "大分県",
    "cityNameKanji": "中津市",
    "prefectureNameKatakana": "ｵｵｲﾀｹﾝ",
    "cityNameKatakana": "ﾅｶﾂｼ"
  },
  {
    "groupCode": "442046",
    "prefectureNameKanji": "大分県",
    "cityNameKanji": "日田市",
    "prefectureNameKatakana": "ｵｵｲﾀｹﾝ",
    "cityNameKatakana": "ﾋﾀｼ"
  },
  {
    "groupCode": "442054",
    "prefectureNameKanji": "大分県",
    "cityNameKanji": "佐伯市",
    "prefectureNameKatakana": "ｵｵｲﾀｹﾝ",
    "cityNameKatakana": "ｻｲｷｼ"
  },
  {
    "groupCode": "442062",
    "prefectureNameKanji": "大分県",
    "cityNameKanji": "臼杵市",
    "prefectureNameKatakana": "ｵｵｲﾀｹﾝ",
    "cityNameKatakana": "ｳｽｷｼ"
  },
  {
    "groupCode": "442071",
    "prefectureNameKanji": "大分県",
    "cityNameKanji": "津久見市",
    "prefectureNameKatakana": "ｵｵｲﾀｹﾝ",
    "cityNameKatakana": "ﾂｸﾐｼ"
  },
  {
    "groupCode": "442089",
    "prefectureNameKanji": "大分県",
    "cityNameKanji": "竹田市",
    "prefectureNameKatakana": "ｵｵｲﾀｹﾝ",
    "cityNameKatakana": "ﾀｹﾀｼ"
  },
  {
    "groupCode": "442097",
    "prefectureNameKanji": "大分県",
    "cityNameKanji": "豊後高田市",
    "prefectureNameKatakana": "ｵｵｲﾀｹﾝ",
    "cityNameKatakana": "ﾌﾞﾝｺﾞﾀｶﾀﾞｼ"
  },
  {
    "groupCode": "442101",
    "prefectureNameKanji": "大分県",
    "cityNameKanji": "杵築市",
    "prefectureNameKatakana": "ｵｵｲﾀｹﾝ",
    "cityNameKatakana": "ｷﾂｷｼ"
  },
  {
    "groupCode": "442119",
    "prefectureNameKanji": "大分県",
    "cityNameKanji": "宇佐市",
    "prefectureNameKatakana": "ｵｵｲﾀｹﾝ",
    "cityNameKatakana": "ｳｻｼ"
  },
  {
    "groupCode": "442127",
    "prefectureNameKanji": "大分県",
    "cityNameKanji": "豊後大野市",
    "prefectureNameKatakana": "ｵｵｲﾀｹﾝ",
    "cityNameKatakana": "ﾌﾞﾝｺﾞｵｵﾉｼ"
  },
  {
    "groupCode": "442135",
    "prefectureNameKanji": "大分県",
    "cityNameKanji": "由布市",
    "prefectureNameKatakana": "ｵｵｲﾀｹﾝ",
    "cityNameKatakana": "ﾕﾌｼ"
  },
  {
    "groupCode": "442143",
    "prefectureNameKanji": "大分県",
    "cityNameKanji": "国東市",
    "prefectureNameKatakana": "ｵｵｲﾀｹﾝ",
    "cityNameKatakana": "ｸﾆｻｷｼ"
  },
  {
    "groupCode": "443221",
    "prefectureNameKanji": "大分県",
    "cityNameKanji": "姫島村",
    "prefectureNameKatakana": "ｵｵｲﾀｹﾝ",
    "cityNameKatakana": "ﾋﾒｼﾏﾑﾗ"
  },
  {
    "groupCode": "443417",
    "prefectureNameKanji": "大分県",
    "cityNameKanji": "日出町",
    "prefectureNameKatakana": "ｵｵｲﾀｹﾝ",
    "cityNameKatakana": "ﾋｼﾞﾏﾁ"
  },
  {
    "groupCode": "444618",
    "prefectureNameKanji": "大分県",
    "cityNameKanji": "九重町",
    "prefectureNameKatakana": "ｵｵｲﾀｹﾝ",
    "cityNameKatakana": "ｺｺﾉｴﾏﾁ"
  },
  {
    "groupCode": "444626",
    "prefectureNameKanji": "大分県",
    "cityNameKanji": "玖珠町",
    "prefectureNameKatakana": "ｵｵｲﾀｹﾝ",
    "cityNameKatakana": "ｸｽﾏﾁ"
  },
  {
    "groupCode": "452017",
    "prefectureNameKanji": "宮崎県",
    "cityNameKanji": "宮崎市",
    "prefectureNameKatakana": "ﾐﾔｻﾞｷｹﾝ",
    "cityNameKatakana": "ﾐﾔｻﾞｷｼ"
  },
  {
    "groupCode": "452025",
    "prefectureNameKanji": "宮崎県",
    "cityNameKanji": "都城市",
    "prefectureNameKatakana": "ﾐﾔｻﾞｷｹﾝ",
    "cityNameKatakana": "ﾐﾔｺﾉｼﾞｮｳｼ"
  },
  {
    "groupCode": "452033",
    "prefectureNameKanji": "宮崎県",
    "cityNameKanji": "延岡市",
    "prefectureNameKatakana": "ﾐﾔｻﾞｷｹﾝ",
    "cityNameKatakana": "ﾉﾍﾞｵｶｼ"
  },
  {
    "groupCode": "452041",
    "prefectureNameKanji": "宮崎県",
    "cityNameKanji": "日南市",
    "prefectureNameKatakana": "ﾐﾔｻﾞｷｹﾝ",
    "cityNameKatakana": "ﾆﾁﾅﾝｼ"
  },
  {
    "groupCode": "452050",
    "prefectureNameKanji": "宮崎県",
    "cityNameKanji": "小林市",
    "prefectureNameKatakana": "ﾐﾔｻﾞｷｹﾝ",
    "cityNameKatakana": "ｺﾊﾞﾔｼｼ"
  },
  {
    "groupCode": "452068",
    "prefectureNameKanji": "宮崎県",
    "cityNameKanji": "日向市",
    "prefectureNameKatakana": "ﾐﾔｻﾞｷｹﾝ",
    "cityNameKatakana": "ﾋｭｳｶﾞｼ"
  },
  {
    "groupCode": "452076",
    "prefectureNameKanji": "宮崎県",
    "cityNameKanji": "串間市",
    "prefectureNameKatakana": "ﾐﾔｻﾞｷｹﾝ",
    "cityNameKatakana": "ｸｼﾏｼ"
  },
  {
    "groupCode": "452084",
    "prefectureNameKanji": "宮崎県",
    "cityNameKanji": "西都市",
    "prefectureNameKatakana": "ﾐﾔｻﾞｷｹﾝ",
    "cityNameKatakana": "ｻｲﾄｼ"
  },
  {
    "groupCode": "452092",
    "prefectureNameKanji": "宮崎県",
    "cityNameKanji": "えびの市",
    "prefectureNameKatakana": "ﾐﾔｻﾞｷｹﾝ",
    "cityNameKatakana": "ｴﾋﾞﾉｼ"
  },
  {
    "groupCode": "453412",
    "prefectureNameKanji": "宮崎県",
    "cityNameKanji": "三股町",
    "prefectureNameKatakana": "ﾐﾔｻﾞｷｹﾝ",
    "cityNameKatakana": "ﾐﾏﾀﾁｮｳ"
  },
  {
    "groupCode": "453617",
    "prefectureNameKanji": "宮崎県",
    "cityNameKanji": "高原町",
    "prefectureNameKatakana": "ﾐﾔｻﾞｷｹﾝ",
    "cityNameKatakana": "ﾀｶﾊﾙﾁｮｳ"
  },
  {
    "groupCode": "453820",
    "prefectureNameKanji": "宮崎県",
    "cityNameKanji": "国富町",
    "prefectureNameKatakana": "ﾐﾔｻﾞｷｹﾝ",
    "cityNameKatakana": "ｸﾆﾄﾐﾁｮｳ"
  },
  {
    "groupCode": "453838",
    "prefectureNameKanji": "宮崎県",
    "cityNameKanji": "綾町",
    "prefectureNameKatakana": "ﾐﾔｻﾞｷｹﾝ",
    "cityNameKatakana": "ｱﾔﾁｮｳ"
  },
  {
    "groupCode": "454010",
    "prefectureNameKanji": "宮崎県",
    "cityNameKanji": "高鍋町",
    "prefectureNameKatakana": "ﾐﾔｻﾞｷｹﾝ",
    "cityNameKatakana": "ﾀｶﾅﾍﾞﾁｮｳ"
  },
  {
    "groupCode": "454028",
    "prefectureNameKanji": "宮崎県",
    "cityNameKanji": "新富町",
    "prefectureNameKatakana": "ﾐﾔｻﾞｷｹﾝ",
    "cityNameKatakana": "ｼﾝﾄﾐﾁｮｳ"
  },
  {
    "groupCode": "454036",
    "prefectureNameKanji": "宮崎県",
    "cityNameKanji": "西米良村",
    "prefectureNameKatakana": "ﾐﾔｻﾞｷｹﾝ",
    "cityNameKatakana": "ﾆｼﾒﾗｿﾝ"
  },
  {
    "groupCode": "454044",
    "prefectureNameKanji": "宮崎県",
    "cityNameKanji": "木城町",
    "prefectureNameKatakana": "ﾐﾔｻﾞｷｹﾝ",
    "cityNameKatakana": "ｷｼﾞｮｳﾁｮｳ"
  },
  {
    "groupCode": "454052",
    "prefectureNameKanji": "宮崎県",
    "cityNameKanji": "川南町",
    "prefectureNameKatakana": "ﾐﾔｻﾞｷｹﾝ",
    "cityNameKatakana": "ｶﾜﾐﾅﾐﾁｮｳ"
  },
  {
    "groupCode": "454061",
    "prefectureNameKanji": "宮崎県",
    "cityNameKanji": "都農町",
    "prefectureNameKatakana": "ﾐﾔｻﾞｷｹﾝ",
    "cityNameKatakana": "ﾂﾉﾁｮｳ"
  },
  {
    "groupCode": "454214",
    "prefectureNameKanji": "宮崎県",
    "cityNameKanji": "門川町",
    "prefectureNameKatakana": "ﾐﾔｻﾞｷｹﾝ",
    "cityNameKatakana": "ｶﾄﾞｶﾞﾜﾁｮｳ"
  },
  {
    "groupCode": "454290",
    "prefectureNameKanji": "宮崎県",
    "cityNameKanji": "諸塚村",
    "prefectureNameKatakana": "ﾐﾔｻﾞｷｹﾝ",
    "cityNameKatakana": "ﾓﾛﾂｶｿﾝ"
  },
  {
    "groupCode": "454303",
    "prefectureNameKanji": "宮崎県",
    "cityNameKanji": "椎葉村",
    "prefectureNameKatakana": "ﾐﾔｻﾞｷｹﾝ",
    "cityNameKatakana": "ｼｲﾊﾞｿﾝ"
  },
  {
    "groupCode": "454311",
    "prefectureNameKanji": "宮崎県",
    "cityNameKanji": "美郷町",
    "prefectureNameKatakana": "ﾐﾔｻﾞｷｹﾝ",
    "cityNameKatakana": "ﾐｻﾄﾁｮｳ"
  },
  {
    "groupCode": "454419",
    "prefectureNameKanji": "宮崎県",
    "cityNameKanji": "高千穂町",
    "prefectureNameKatakana": "ﾐﾔｻﾞｷｹﾝ",
    "cityNameKatakana": "ﾀｶﾁﾎﾁｮｳ"
  },
  {
    "groupCode": "454427",
    "prefectureNameKanji": "宮崎県",
    "cityNameKanji": "日之影町",
    "prefectureNameKatakana": "ﾐﾔｻﾞｷｹﾝ",
    "cityNameKatakana": "ﾋﾉｶｹﾞﾁｮｳ"
  },
  {
    "groupCode": "454435",
    "prefectureNameKanji": "宮崎県",
    "cityNameKanji": "五ヶ瀬町",
    "prefectureNameKatakana": "ﾐﾔｻﾞｷｹﾝ",
    "cityNameKatakana": "ｺﾞｶｾﾁｮｳ"
  },
  {
    "groupCode": "462012",
    "prefectureNameKanji": "鹿児島県",
    "cityNameKanji": "鹿児島市",
    "prefectureNameKatakana": "ｶｺﾞｼﾏｹﾝ",
    "cityNameKatakana": "ｶｺﾞｼﾏｼ"
  },
  {
    "groupCode": "462039",
    "prefectureNameKanji": "鹿児島県",
    "cityNameKanji": "鹿屋市",
    "prefectureNameKatakana": "ｶｺﾞｼﾏｹﾝ",
    "cityNameKatakana": "ｶﾉﾔｼ"
  },
  {
    "groupCode": "462047",
    "prefectureNameKanji": "鹿児島県",
    "cityNameKanji": "枕崎市",
    "prefectureNameKatakana": "ｶｺﾞｼﾏｹﾝ",
    "cityNameKatakana": "ﾏｸﾗｻﾞｷｼ"
  },
  {
    "groupCode": "462063",
    "prefectureNameKanji": "鹿児島県",
    "cityNameKanji": "阿久根市",
    "prefectureNameKatakana": "ｶｺﾞｼﾏｹﾝ",
    "cityNameKatakana": "ｱｸﾈｼ"
  },
  {
    "groupCode": "462080",
    "prefectureNameKanji": "鹿児島県",
    "cityNameKanji": "出水市",
    "prefectureNameKatakana": "ｶｺﾞｼﾏｹﾝ",
    "cityNameKatakana": "ｲｽﾞﾐｼ"
  },
  {
    "groupCode": "462101",
    "prefectureNameKanji": "鹿児島県",
    "cityNameKanji": "指宿市",
    "prefectureNameKatakana": "ｶｺﾞｼﾏｹﾝ",
    "cityNameKatakana": "ｲﾌﾞｽｷｼ"
  },
  {
    "groupCode": "462136",
    "prefectureNameKanji": "鹿児島県",
    "cityNameKanji": "西之表市",
    "prefectureNameKatakana": "ｶｺﾞｼﾏｹﾝ",
    "cityNameKatakana": "ﾆｼﾉｵﾓﾃｼ"
  },
  {
    "groupCode": "462144",
    "prefectureNameKanji": "鹿児島県",
    "cityNameKanji": "垂水市",
    "prefectureNameKatakana": "ｶｺﾞｼﾏｹﾝ",
    "cityNameKatakana": "ﾀﾙﾐｽﾞｼ"
  },
  {
    "groupCode": "462152",
    "prefectureNameKanji": "鹿児島県",
    "cityNameKanji": "薩摩川内市",
    "prefectureNameKatakana": "ｶｺﾞｼﾏｹﾝ",
    "cityNameKatakana": "ｻﾂﾏｾﾝﾀﾞｲｼ"
  },
  {
    "groupCode": "462161",
    "prefectureNameKanji": "鹿児島県",
    "cityNameKanji": "日置市",
    "prefectureNameKatakana": "ｶｺﾞｼﾏｹﾝ",
    "cityNameKatakana": "ﾋｵｷｼ"
  },
  {
    "groupCode": "462179",
    "prefectureNameKanji": "鹿児島県",
    "cityNameKanji": "曽於市",
    "prefectureNameKatakana": "ｶｺﾞｼﾏｹﾝ",
    "cityNameKatakana": "ｿｵｼ"
  },
  {
    "groupCode": "462187",
    "prefectureNameKanji": "鹿児島県",
    "cityNameKanji": "霧島市",
    "prefectureNameKatakana": "ｶｺﾞｼﾏｹﾝ",
    "cityNameKatakana": "ｷﾘｼﾏｼ"
  },
  {
    "groupCode": "462195",
    "prefectureNameKanji": "鹿児島県",
    "cityNameKanji": "いちき串木野市",
    "prefectureNameKatakana": "ｶｺﾞｼﾏｹﾝ",
    "cityNameKatakana": "ｲﾁｷｸｼｷﾉｼ"
  },
  {
    "groupCode": "462209",
    "prefectureNameKanji": "鹿児島県",
    "cityNameKanji": "南さつま市",
    "prefectureNameKatakana": "ｶｺﾞｼﾏｹﾝ",
    "cityNameKatakana": "ﾐﾅﾐｻﾂﾏｼ"
  },
  {
    "groupCode": "462217",
    "prefectureNameKanji": "鹿児島県",
    "cityNameKanji": "志布志市",
    "prefectureNameKatakana": "ｶｺﾞｼﾏｹﾝ",
    "cityNameKatakana": "ｼﾌﾞｼｼ"
  },
  {
    "groupCode": "462225",
    "prefectureNameKanji": "鹿児島県",
    "cityNameKanji": "奄美市",
    "prefectureNameKatakana": "ｶｺﾞｼﾏｹﾝ",
    "cityNameKatakana": "ｱﾏﾐｼ"
  },
  {
    "groupCode": "462233",
    "prefectureNameKanji": "鹿児島県",
    "cityNameKanji": "南九州市",
    "prefectureNameKatakana": "ｶｺﾞｼﾏｹﾝ",
    "cityNameKatakana": "ﾐﾅﾐｷｭｳｼｭｳｼ"
  },
  {
    "groupCode": "462241",
    "prefectureNameKanji": "鹿児島県",
    "cityNameKanji": "伊佐市",
    "prefectureNameKatakana": "ｶｺﾞｼﾏｹﾝ",
    "cityNameKatakana": "ｲｻｼ"
  },
  {
    "groupCode": "462250",
    "prefectureNameKanji": "鹿児島県",
    "cityNameKanji": "姶良市",
    "prefectureNameKatakana": "ｶｺﾞｼﾏｹﾝ",
    "cityNameKatakana": "ｱｲﾗｼ"
  },
  {
    "groupCode": "463035",
    "prefectureNameKanji": "鹿児島県",
    "cityNameKanji": "三島村",
    "prefectureNameKatakana": "ｶｺﾞｼﾏｹﾝ",
    "cityNameKatakana": "ﾐｼﾏﾑﾗ"
  },
  {
    "groupCode": "463043",
    "prefectureNameKanji": "鹿児島県",
    "cityNameKanji": "十島村",
    "prefectureNameKatakana": "ｶｺﾞｼﾏｹﾝ",
    "cityNameKatakana": "ﾄｼﾏﾑﾗ"
  },
  {
    "groupCode": "463922",
    "prefectureNameKanji": "鹿児島県",
    "cityNameKanji": "さつま町",
    "prefectureNameKatakana": "ｶｺﾞｼﾏｹﾝ",
    "cityNameKatakana": "ｻﾂﾏﾁｮｳ"
  },
  {
    "groupCode": "464040",
    "prefectureNameKanji": "鹿児島県",
    "cityNameKanji": "長島町",
    "prefectureNameKatakana": "ｶｺﾞｼﾏｹﾝ",
    "cityNameKatakana": "ﾅｶﾞｼﾏﾁｮｳ"
  },
  {
    "groupCode": "464520",
    "prefectureNameKanji": "鹿児島県",
    "cityNameKanji": "湧水町",
    "prefectureNameKatakana": "ｶｺﾞｼﾏｹﾝ",
    "cityNameKatakana": "ﾕｳｽｲﾁｮｳ"
  },
  {
    "groupCode": "464686",
    "prefectureNameKanji": "鹿児島県",
    "cityNameKanji": "大崎町",
    "prefectureNameKatakana": "ｶｺﾞｼﾏｹﾝ",
    "cityNameKatakana": "ｵｵｻｷﾁｮｳ"
  },
  {
    "groupCode": "464821",
    "prefectureNameKanji": "鹿児島県",
    "cityNameKanji": "東串良町",
    "prefectureNameKatakana": "ｶｺﾞｼﾏｹﾝ",
    "cityNameKatakana": "ﾋｶﾞｼｸｼﾗﾁｮｳ"
  },
  {
    "groupCode": "464902",
    "prefectureNameKanji": "鹿児島県",
    "cityNameKanji": "錦江町",
    "prefectureNameKatakana": "ｶｺﾞｼﾏｹﾝ",
    "cityNameKatakana": "ｷﾝｺｳﾁｮｳ"
  },
  {
    "groupCode": "464911",
    "prefectureNameKanji": "鹿児島県",
    "cityNameKanji": "南大隅町",
    "prefectureNameKatakana": "ｶｺﾞｼﾏｹﾝ",
    "cityNameKatakana": "ﾐﾅﾐｵｵｽﾐﾁｮｳ"
  },
  {
    "groupCode": "464929",
    "prefectureNameKanji": "鹿児島県",
    "cityNameKanji": "肝付町",
    "prefectureNameKatakana": "ｶｺﾞｼﾏｹﾝ",
    "cityNameKatakana": "ｷﾓﾂｷﾁｮｳ"
  },
  {
    "groupCode": "465011",
    "prefectureNameKanji": "鹿児島県",
    "cityNameKanji": "中種子町",
    "prefectureNameKatakana": "ｶｺﾞｼﾏｹﾝ",
    "cityNameKatakana": "ﾅｶﾀﾈﾁｮｳ"
  },
  {
    "groupCode": "465020",
    "prefectureNameKanji": "鹿児島県",
    "cityNameKanji": "南種子町",
    "prefectureNameKatakana": "ｶｺﾞｼﾏｹﾝ",
    "cityNameKatakana": "ﾐﾅﾐﾀﾈﾁｮｳ"
  },
  {
    "groupCode": "465054",
    "prefectureNameKanji": "鹿児島県",
    "cityNameKanji": "屋久島町",
    "prefectureNameKatakana": "ｶｺﾞｼﾏｹﾝ",
    "cityNameKatakana": "ﾔｸｼﾏﾁｮｳ"
  },
  {
    "groupCode": "465232",
    "prefectureNameKanji": "鹿児島県",
    "cityNameKanji": "大和村",
    "prefectureNameKatakana": "ｶｺﾞｼﾏｹﾝ",
    "cityNameKatakana": "ﾔﾏﾄｿﾝ"
  },
  {
    "groupCode": "465241",
    "prefectureNameKanji": "鹿児島県",
    "cityNameKanji": "宇検村",
    "prefectureNameKatakana": "ｶｺﾞｼﾏｹﾝ",
    "cityNameKatakana": "ｳｹﾝｿﾝ"
  },
  {
    "groupCode": "465259",
    "prefectureNameKanji": "鹿児島県",
    "cityNameKanji": "瀬戸内町",
    "prefectureNameKatakana": "ｶｺﾞｼﾏｹﾝ",
    "cityNameKatakana": "ｾﾄｳﾁﾁｮｳ"
  },
  {
    "groupCode": "465275",
    "prefectureNameKanji": "鹿児島県",
    "cityNameKanji": "龍郷町",
    "prefectureNameKatakana": "ｶｺﾞｼﾏｹﾝ",
    "cityNameKatakana": "ﾀﾂｺﾞｳﾁｮｳ"
  },
  {
    "groupCode": "465291",
    "prefectureNameKanji": "鹿児島県",
    "cityNameKanji": "喜界町",
    "prefectureNameKatakana": "ｶｺﾞｼﾏｹﾝ",
    "cityNameKatakana": "ｷｶｲﾁｮｳ"
  },
  {
    "groupCode": "465305",
    "prefectureNameKanji": "鹿児島県",
    "cityNameKanji": "徳之島町",
    "prefectureNameKatakana": "ｶｺﾞｼﾏｹﾝ",
    "cityNameKatakana": "ﾄｸﾉｼﾏﾁｮｳ"
  },
  {
    "groupCode": "465313",
    "prefectureNameKanji": "鹿児島県",
    "cityNameKanji": "天城町",
    "prefectureNameKatakana": "ｶｺﾞｼﾏｹﾝ",
    "cityNameKatakana": "ｱﾏｷﾞﾁｮｳ"
  },
  {
    "groupCode": "465321",
    "prefectureNameKanji": "鹿児島県",
    "cityNameKanji": "伊仙町",
    "prefectureNameKatakana": "ｶｺﾞｼﾏｹﾝ",
    "cityNameKatakana": "ｲｾﾝﾁｮｳ"
  },
  {
    "groupCode": "465330",
    "prefectureNameKanji": "鹿児島県",
    "cityNameKanji": "和泊町",
    "prefectureNameKatakana": "ｶｺﾞｼﾏｹﾝ",
    "cityNameKatakana": "ﾜﾄﾞﾏﾘﾁｮｳ"
  },
  {
    "groupCode": "465348",
    "prefectureNameKanji": "鹿児島県",
    "cityNameKanji": "知名町",
    "prefectureNameKatakana": "ｶｺﾞｼﾏｹﾝ",
    "cityNameKatakana": "ﾁﾅﾁｮｳ"
  },
  {
    "groupCode": "465356",
    "prefectureNameKanji": "鹿児島県",
    "cityNameKanji": "与論町",
    "prefectureNameKatakana": "ｶｺﾞｼﾏｹﾝ",
    "cityNameKatakana": "ﾖﾛﾝﾁｮｳ"
  },
  {
    "groupCode": "472018",
    "prefectureNameKanji": "沖縄県",
    "cityNameKanji": "那覇市",
    "prefectureNameKatakana": "ｵｷﾅﾜｹﾝ",
    "cityNameKatakana": "ﾅﾊｼ"
  },
  {
    "groupCode": "472051",
    "prefectureNameKanji": "沖縄県",
    "cityNameKanji": "宜野湾市",
    "prefectureNameKatakana": "ｵｷﾅﾜｹﾝ",
    "cityNameKatakana": "ｷﾞﾉﾜﾝｼ"
  },
  {
    "groupCode": "472077",
    "prefectureNameKanji": "沖縄県",
    "cityNameKanji": "石垣市",
    "prefectureNameKatakana": "ｵｷﾅﾜｹﾝ",
    "cityNameKatakana": "ｲｼｶﾞｷｼ"
  },
  {
    "groupCode": "472085",
    "prefectureNameKanji": "沖縄県",
    "cityNameKanji": "浦添市",
    "prefectureNameKatakana": "ｵｷﾅﾜｹﾝ",
    "cityNameKatakana": "ｳﾗｿｴｼ"
  },
  {
    "groupCode": "472093",
    "prefectureNameKanji": "沖縄県",
    "cityNameKanji": "名護市",
    "prefectureNameKatakana": "ｵｷﾅﾜｹﾝ",
    "cityNameKatakana": "ﾅｺﾞｼ"
  },
  {
    "groupCode": "472107",
    "prefectureNameKanji": "沖縄県",
    "cityNameKanji": "糸満市",
    "prefectureNameKatakana": "ｵｷﾅﾜｹﾝ",
    "cityNameKatakana": "ｲﾄﾏﾝｼ"
  },
  {
    "groupCode": "472115",
    "prefectureNameKanji": "沖縄県",
    "cityNameKanji": "沖縄市",
    "prefectureNameKatakana": "ｵｷﾅﾜｹﾝ",
    "cityNameKatakana": "ｵｷﾅﾜｼ"
  },
  {
    "groupCode": "472123",
    "prefectureNameKanji": "沖縄県",
    "cityNameKanji": "豊見城市",
    "prefectureNameKatakana": "ｵｷﾅﾜｹﾝ",
    "cityNameKatakana": "ﾄﾐｸﾞｽｸｼ"
  },
  {
    "groupCode": "472131",
    "prefectureNameKanji": "沖縄県",
    "cityNameKanji": "うるま市",
    "prefectureNameKatakana": "ｵｷﾅﾜｹﾝ",
    "cityNameKatakana": "ｳﾙﾏｼ"
  },
  {
    "groupCode": "472140",
    "prefectureNameKanji": "沖縄県",
    "cityNameKanji": "宮古島市",
    "prefectureNameKatakana": "ｵｷﾅﾜｹﾝ",
    "cityNameKatakana": "ﾐﾔｺｼﾞﾏｼ"
  },
  {
    "groupCode": "472158",
    "prefectureNameKanji": "沖縄県",
    "cityNameKanji": "南城市",
    "prefectureNameKatakana": "ｵｷﾅﾜｹﾝ",
    "cityNameKatakana": "ﾅﾝｼﾞｮｳｼ"
  },
  {
    "groupCode": "473014",
    "prefectureNameKanji": "沖縄県",
    "cityNameKanji": "国頭村",
    "prefectureNameKatakana": "ｵｷﾅﾜｹﾝ",
    "cityNameKatakana": "ｸﾆｶﾞﾐｿﾝ"
  },
  {
    "groupCode": "473022",
    "prefectureNameKanji": "沖縄県",
    "cityNameKanji": "大宜味村",
    "prefectureNameKatakana": "ｵｷﾅﾜｹﾝ",
    "cityNameKatakana": "ｵｵｷﾞﾐｿﾝ"
  },
  {
    "groupCode": "473031",
    "prefectureNameKanji": "沖縄県",
    "cityNameKanji": "東村",
    "prefectureNameKatakana": "ｵｷﾅﾜｹﾝ",
    "cityNameKatakana": "ﾋｶﾞｼｿﾝ"
  },
  {
    "groupCode": "473065",
    "prefectureNameKanji": "沖縄県",
    "cityNameKanji": "今帰仁村",
    "prefectureNameKatakana": "ｵｷﾅﾜｹﾝ",
    "cityNameKatakana": "ﾅｷｼﾞﾝｿﾝ"
  },
  {
    "groupCode": "473081",
    "prefectureNameKanji": "沖縄県",
    "cityNameKanji": "本部町",
    "prefectureNameKatakana": "ｵｷﾅﾜｹﾝ",
    "cityNameKatakana": "ﾓﾄﾌﾞﾁｮｳ"
  },
  {
    "groupCode": "473111",
    "prefectureNameKanji": "沖縄県",
    "cityNameKanji": "恩納村",
    "prefectureNameKatakana": "ｵｷﾅﾜｹﾝ",
    "cityNameKatakana": "ｵﾝﾅｿﾝ"
  },
  {
    "groupCode": "473138",
    "prefectureNameKanji": "沖縄県",
    "cityNameKanji": "宜野座村",
    "prefectureNameKatakana": "ｵｷﾅﾜｹﾝ",
    "cityNameKatakana": "ｷﾞﾉｻﾞｿﾝ"
  },
  {
    "groupCode": "473146",
    "prefectureNameKanji": "沖縄県",
    "cityNameKanji": "金武町",
    "prefectureNameKatakana": "ｵｷﾅﾜｹﾝ",
    "cityNameKatakana": "ｷﾝﾁｮｳ"
  },
  {
    "groupCode": "473154",
    "prefectureNameKanji": "沖縄県",
    "cityNameKanji": "伊江村",
    "prefectureNameKatakana": "ｵｷﾅﾜｹﾝ",
    "cityNameKatakana": "ｲｴｿﾝ"
  },
  {
    "groupCode": "473243",
    "prefectureNameKanji": "沖縄県",
    "cityNameKanji": "読谷村",
    "prefectureNameKatakana": "ｵｷﾅﾜｹﾝ",
    "cityNameKatakana": "ﾖﾐﾀﾝｿﾝ"
  },
  {
    "groupCode": "473251",
    "prefectureNameKanji": "沖縄県",
    "cityNameKanji": "嘉手納町",
    "prefectureNameKatakana": "ｵｷﾅﾜｹﾝ",
    "cityNameKatakana": "ｶﾃﾞﾅﾁｮｳ"
  },
  {
    "groupCode": "473260",
    "prefectureNameKanji": "沖縄県",
    "cityNameKanji": "北谷町",
    "prefectureNameKatakana": "ｵｷﾅﾜｹﾝ",
    "cityNameKatakana": "ﾁﾔﾀﾝﾁｮｳ"
  },
  {
    "groupCode": "473278",
    "prefectureNameKanji": "沖縄県",
    "cityNameKanji": "北中城村",
    "prefectureNameKatakana": "ｵｷﾅﾜｹﾝ",
    "cityNameKatakana": "ｷﾀﾅｶｸﾞｽｸｿﾝ"
  },
  {
    "groupCode": "473286",
    "prefectureNameKanji": "沖縄県",
    "cityNameKanji": "中城村",
    "prefectureNameKatakana": "ｵｷﾅﾜｹﾝ",
    "cityNameKatakana": "ﾅｶｸﾞｽｸｿﾝ"
  },
  {
    "groupCode": "473294",
    "prefectureNameKanji": "沖縄県",
    "cityNameKanji": "西原町",
    "prefectureNameKatakana": "ｵｷﾅﾜｹﾝ",
    "cityNameKatakana": "ﾆｼﾊﾗﾁｮｳ"
  },
  {
    "groupCode": "473481",
    "prefectureNameKanji": "沖縄県",
    "cityNameKanji": "与那原町",
    "prefectureNameKatakana": "ｵｷﾅﾜｹﾝ",
    "cityNameKatakana": "ﾖﾅﾊﾞﾙﾁｮｳ"
  },
  {
    "groupCode": "473502",
    "prefectureNameKanji": "沖縄県",
    "cityNameKanji": "南風原町",
    "prefectureNameKatakana": "ｵｷﾅﾜｹﾝ",
    "cityNameKatakana": "ﾊｴﾊﾞﾙﾁｮｳ"
  },
  {
    "groupCode": "473537",
    "prefectureNameKanji": "沖縄県",
    "cityNameKanji": "渡嘉敷村",
    "prefectureNameKatakana": "ｵｷﾅﾜｹﾝ",
    "cityNameKatakana": "ﾄｶｼｷｿﾝ"
  },
  {
    "groupCode": "473545",
    "prefectureNameKanji": "沖縄県",
    "cityNameKanji": "座間味村",
    "prefectureNameKatakana": "ｵｷﾅﾜｹﾝ",
    "cityNameKatakana": "ｻﾞﾏﾐｿﾝ"
  },
  {
    "groupCode": "473553",
    "prefectureNameKanji": "沖縄県",
    "cityNameKanji": "粟国村",
    "prefectureNameKatakana": "ｵｷﾅﾜｹﾝ",
    "cityNameKatakana": "ｱｸﾞﾆｿﾝ"
  },
  {
    "groupCode": "473561",
    "prefectureNameKanji": "沖縄県",
    "cityNameKanji": "渡名喜村",
    "prefectureNameKatakana": "ｵｷﾅﾜｹﾝ",
    "cityNameKatakana": "ﾄﾅｷｿﾝ"
  },
  {
    "groupCode": "473570",
    "prefectureNameKanji": "沖縄県",
    "cityNameKanji": "南大東村",
    "prefectureNameKatakana": "ｵｷﾅﾜｹﾝ",
    "cityNameKatakana": "ﾐﾅﾐﾀﾞｲﾄｳｿﾝ"
  },
  {
    "groupCode": "473588",
    "prefectureNameKanji": "沖縄県",
    "cityNameKanji": "北大東村",
    "prefectureNameKatakana": "ｵｷﾅﾜｹﾝ",
    "cityNameKatakana": "ｷﾀﾀﾞｲﾄｳｿﾝ"
  },
  {
    "groupCode": "473596",
    "prefectureNameKanji": "沖縄県",
    "cityNameKanji": "伊平屋村",
    "prefectureNameKatakana": "ｵｷﾅﾜｹﾝ",
    "cityNameKatakana": "ｲﾍﾔｿﾝ"
  },
  {
    "groupCode": "473600",
    "prefectureNameKanji": "沖縄県",
    "cityNameKanji": "伊是名村",
    "prefectureNameKatakana": "ｵｷﾅﾜｹﾝ",
    "cityNameKatakana": "ｲｾﾞﾅｿﾝ"
  },
  {
    "groupCode": "473618",
    "prefectureNameKanji": "沖縄県",
    "cityNameKanji": "久米島町",
    "prefectureNameKatakana": "ｵｷﾅﾜｹﾝ",
    "cityNameKatakana": "ｸﾒｼﾞﾏﾁｮｳ"
  },
  {
    "groupCode": "473626",
    "prefectureNameKanji": "沖縄県",
    "cityNameKanji": "八重瀬町",
    "prefectureNameKatakana": "ｵｷﾅﾜｹﾝ",
    "cityNameKatakana": "ﾔｴｾﾁｮｳ"
  },
  {
    "groupCode": "473758",
    "prefectureNameKanji": "沖縄県",
    "cityNameKanji": "多良間村",
    "prefectureNameKatakana": "ｵｷﾅﾜｹﾝ",
    "cityNameKatakana": "ﾀﾗﾏｿﾝ"
  },
  {
    "groupCode": "473812",
    "prefectureNameKanji": "沖縄県",
    "cityNameKanji": "竹富町",
    "prefectureNameKatakana": "ｵｷﾅﾜｹﾝ",
    "cityNameKatakana": "ﾀｹﾄﾐﾁｮｳ"
  },
  {
    "groupCode": "473821",
    "prefectureNameKanji": "沖縄県",
    "cityNameKanji": "与那国町",
    "prefectureNameKatakana": "ｵｷﾅﾜｹﾝ",
    "cityNameKatakana": "ﾖﾅｸﾞﾆﾁｮｳ"
  }
]