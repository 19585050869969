import React, { useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import 'assets/scss/page/AnkenMasterNew/ModalCreateService.scss';
import TableModalResults from './TableModalResults';

const ModalResultSettings = (props) => {
  const { toggle = () => { }, modal = false, serviceResult = null, productResult = null, nameTitle } = props;
  const [statusGetList, setStatusGetList] = useState(false);

  return (
    <div>
      <Modal className="modal-dialog-custom" isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>作業結果設定</ModalHeader>
        <ModalBody>
          <div className="form-body-service">
            <TableModalResults
              statusGetList={statusGetList}
              setStatusGetList={() =>
                setStatusGetList(false)
              }
              serviceResult={serviceResult}
              productResult={productResult}
              nameTitle={nameTitle}
            />
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ModalResultSettings;
