import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import ModalDetailSettingCsvStella from './ModalDetailSettingCsvStella';

const ModalSettingOrderDetail = ({ idProduct, modalNext = false, toggle, routeList, nameScreen, arrRole, idProvide, onBack = () => {}, detailId, nameTitle }) => {
  const Back = () => {
    toggle();
    onBack(arrRole, nameScreen);
  };

  return (
    <div>
      <Modal className="modal-dialog-custom modal-add-modal-csv" isOpen={modalNext} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          <button onClick={Back} className="button__back">
          戻る
          </button>
        </ModalHeader>
        <ModalBody>
          <ModalDetailSettingCsvStella routeList={routeList} nameScreen={nameScreen} arrRole={arrRole} idProvide={idProvide} idProduct={idProduct} detailId={detailId} nameTitle={nameTitle} />
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ModalSettingOrderDetail;
