import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import './style.scss';

import { Helmet } from 'react-helmet';
import Modify1 from './Modify1';
import Modify2 from './Modify2';
import Modify3 from './Modify3';
import { useHistory } from 'react-router-dom';
import Modify4 from './Modify4';
import HeaderMasterSetting from '../../../components/HeaderMasterSetting';

const renderButtonStep = ({ key = 0, title = '', active = false, modify = 0, onClickActive = () => {} }) => {
  return (
    <div
      className={`d-flex mr-2 ${active ? 'wrapper-button-step-active' : 'wrapper-button-step'}`}
      // onClick={() => onClickActive(modify)}
      key={key}
    >
      <div className="button-step d-flex justify-content-center align-items-center">{title}</div>
      <div className="arrow-right">
        <div></div>
      </div>
    </div>
  );
};

const dataStep = [
  {
    label: 'クライアント選択',
    modify: '1',
    active: true,
  },
  {
    label: '案件名登録',
    modify: '2',
    active: false,
  },
  {
    label: '案件情報設定',
    modify: '3',
    active: false,
  },
  {
    label: 'アカウント閲覧設定',
    modify: '4',
    active: false,
  },
];

const ModifyAnken = () => {
  const [stepActive, setStepActive] = useState(null);
  const history = useHistory();
  const modify = (process.browser && new URL(window.location.href).searchParams.get('modify')) || null;

  useEffect(() => {
    if (modify) {
      setStepActive(modify);
    }
  }, [modify]);

  const onClickActive = (modify) => {
    setStepActive(modify);
    history.push(`/modify_anken?modify=${modify}`);
  };

  const renderModify = (modify) => {
    switch (modify) {
      case '1':
        return <Modify1 />;
      case '2':
        return <Modify2 />;
      case '3':
        return <Modify3 />;
      case '4':
        return <Modify4 />;
    }
  };

  return (
    <div className="wrapper-modify-anken-master">
      <HeaderMasterSetting />
      <div className="wrapper-main ">
        <div className="wrapper-modify-anken h-100">
          <Helmet>
            <title>案件マスタ</title>
          </Helmet>
          <div className="d-flex p-3">
            {dataStep.map((item, index) =>
              item.modify === stepActive
                ? renderButtonStep({
                    title: item.label,
                    active: true,
                    modify: item.modify,
                    onClickActive: onClickActive,
                    key: index,
                  })
                : renderButtonStep({
                    title: item.label,
                    active: false,
                    modify: item.modify,
                    onClickActive: onClickActive,
                    key: index,
                  })
            )}
          </div>
          {stepActive && renderModify(stepActive)}
        </div>
      </div>
    </div>
  );
};

export default compose(withRouter)(ModifyAnken);
