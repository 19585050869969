import React, { useEffect, useState } from 'react';
import Header from 'components/Header';
import Footer from 'components/Footer';
import { ORDER } from 'constants/endpoints';
import { getRole } from 'helpers/helpers';
import { SCREEN_NAME } from 'helpers/constants';
import { pushToast } from 'components/Toast';
import 'assets/scss/page/DetailCancelInfo/style.scss';
import DetailData from 'components/DetailCenter/DetailData';
import DetailDataList from 'components/DetailCenter/DetailDataList';
import DetailOrder from 'components/DetailCenter/DetailOrder';
import DetailListAllData from 'components/DetailCenter/DetailListAllData';
import { Link, useHistory } from 'react-router-dom';
import DetailTreeDocumentSetting from 'components/DetailTreeDocumentSetting';
import { useSelector } from 'react-redux';
import PopupConfirmCustom from 'components/popupConfirmCustom';
import DetailResult from '../../../components/DetailOrderErp/DetailResult';
import DetailTimeWorker from '../../../components/DetailOrderErp/DetailTimeWorker';

const mockData = {
  screenName: SCREEN_NAME.CANCEL_INFO,
  recycle: true,
  resultCancelInfo: '',
  reasonForCancellation: '',
  statusCancelInfo: false,
};

const DetailCancelInfo = () => {
  const [role, setRole] = React.useState(null);
  const [idRole, setIdRole] = React.useState(null);
  const [dataOrder, setDataOrder] = useState(mockData);
  const [data, setData] = useState([]);
  let organizationId = process.browser && new URL(window.location.href).searchParams.get('organizationId') || '';
  let orderId = process.browser && new URL(window.location.href).searchParams.get('orderId');
  let name = (process.browser && new URL(window.location.href).searchParams.get('p')) || 'cancel_info';
  const [p, setP] = React.useState(name);
  const colorScreen = useSelector((store) => store.utils.colorMe);
  const history = useHistory();
  const [showPopupConfirm, setShowPopupConfirm] = useState(false);
  const [statusCancel, setStatusCancel] = useState([]);
  const [listDetailScreen, setListDetailScreen] = useState([]);
  const [dataDetailTimeWorker, setDataDetailTimeWorker] = useState({})

  useEffect(() => {
    getListRoleChild();
  }, []);

  const getListRoleChild = async () => {
    const newRole = localStorage.getItem('role');
    try {
      const data = await ORDER.GET_LIST_DETAIL_SETTING({
        arrRole: newRole,
        nameScreen: 'cancel_info',
        orderId,
        organizationId
      });
      if (data) {
        setListDetailScreen(data?.data?.detailScreen);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    setP(name);
  }, [name]);

  const getOrderDetail = async ({ orderId = '' }) => {
    try {
      const data = await ORDER.GET_DETAIL_CANCEL_INFO({ orderId, screenName: 'cancel_info', organizationId });
      if (data) {
        setData(data?.data);
        getStatusCancel({
          serviceId: data?.data?.serviceId,
          productId: data?.data?.productId,
          orderId: data?.data?.orderId,
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (data) {
      setDataOrder({
        ...dataOrder,
        recycle: data.recycle,
        resultCancelInfo: data.resultCancelInfo,
        reasonForCancellation: data.reasonForCancellation,
        settingCancel: data.settingCancel,
      });
      setDataDetailTimeWorker(data)
    }
  }, [data]);

  useEffect(() => {
    const newRole = localStorage.getItem('role');
    setIdRole(newRole);
    if (newRole) {
      setRole(getRole({ idRole: newRole }));
    }

    if (orderId) {
      getOrderDetail({
        orderId: orderId,
      });
    }
  }, [orderId]);

  const handleChangeText = (e) => {
    let name = e.target.name;
    let newData = { ...dataOrder };
    newData = { ...newData, [name]: e.target.value };
    setDataOrder(newData);
  };

  const handleChangeSelect = (e) => {
    const value = e.target.value;
    let newData = { ...dataOrder };
    const settingCancel = statusCancel.filter((i) => i.id === value);
    newData = {
      ...newData,
      settingCancel: value,
      settingCancelResult: settingCancel?.[0],
      resultCancelInfo: settingCancel?.[0]?.pullDownDisplay || "",
    };
    setDataOrder(newData);
  };

  const updateOrderInfo = async ({ body = {}, id_order = '' }) => {
    try {
      const data = await ORDER.UPDATE_CANCEL_INFO({ body: body, id_order: id_order, organizationId });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
      } else {
        pushToast('success', 'Update order success');
        if (body?.recycle) {
          return history.push(`/recreate/detail_recreate/${id_order}`);
        } else {
          return history.push(`/list?p=cancel_info`);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getStatusCancel = async ({ serviceId, productId, orderId, type = 'cancelSetting' }) => {
    try {
      const data = await ORDER.GET_STATUS_ORDER({ serviceId, productId, orderId, type, organizationId });
      if (data) {
        setStatusCancel(data?.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onUpdateOrder = (data) => {
    const settingCancel = statusCancel.find((i) => i.id === dataOrder?.settingCancel)
    updateOrderInfo({ body: { ...dataOrder, ...data, updateTitle: `保存 キャンセル結果：${settingCancel?.pullDownDisplay || ''}`, companyName: localStorage.getItem('companyName')}, id_order: orderId });
  };

  return (
    <div className="wrapper-detail-cancel-info">
      <div className="main">
        <Header p={p} idRole={idRole} color={colorScreen?.code} colorText={colorScreen?.colorText} />
        <div className="body-wrapper">
          <div className="logout_btn">
            <Link to={`list?p=${p}`}>
              <span className="mypage_ab_custom">戻る</span>
            </Link>
          </div>
          {listDetailScreen?.map((item, index) => {
            if (item.status) {
              switch (item.name) {
                case 'clientCompany':
                  return <DetailOrder data={data} p={p} key={index} />;
                case 'applicationCompany':
                  return <DetailData data={data} p={p} link={false} key={index} />;
                case 'visitDateWorker':
                  return <DetailTimeWorker screenName={SCREEN_NAME.CANCEL_INFO} data={dataDetailTimeWorker} key={index} />;
                case 'cancelList':
                  return (
                    <div key={index}>
                      <div className="detail-cancel-table-wrappers">
                        <div className="title-absolute">キャンセル一覧</div>
                        <div className="detail__cancel">
                          <table className="table__form">
                            <tbody>
                              <tr>
                                <th className="table__form--color table__form--th">キャンセル結果</th>
                                <td className="table__form--color--td table__form--td" style={{ width: '20%' }}>
                                  <select
                                    name="settingCancel"
                                    onChange={handleChangeSelect}
                                    value={dataOrder?.settingCancel}
                                    style={{ display: 'block' }}
                                    className='w-100'
                                  >
                                    <option value=""></option>
                                    {statusCancel?.map((item, index) => {
                                      return (
                                        <option value={item.id} key={index}>
                                          {item.pullDownDisplay}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </td>
                                <th className="table__form--color table__form--th" rowSpan="2">
                                  結果備考
                                </th>
                                <td className="table__form--color--td table__form--td" rowSpan="2">
                                  <textarea
                                    value={dataOrder?.reasonForCancellation}
                                    onChange={handleChangeText}
                                    name="reasonForCancellation"
                                    cols="70"
                                    rows="5"
                                    className="w-100 table__form--td--input"
                                  >
                                    {dataOrder?.reasonForCancellation}
                                  </textarea>
                                </td>
                              </tr>
                              <tr>
                                <th className="table__form--color table__form--th">キャンセル日時</th>
                                <td className="table__form--color--td" style={{ width: '20%' }}>
                                  {data?.canceledAt}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <DetailResult orderId={orderId} />
                      <div className="group-action-update-order mb-2">
                        <span className="btn-update-order__wrap">
                          <button className="btn-update-order" onClick={() => setShowPopupConfirm(!showPopupConfirm)}>
                          キャンセル実行
                          </button>
                          <PopupConfirmCustom
                            headerTitle="確認"
                            rederBody={() => {
                              return (
                                <>
                                  <div>キャンセルを受け付けました。</div>
                                  <div>この情報を複製して物件情報を作成しますか？</div>
                                </>
                              );
                            }}
                            okText="はい"
                            onConfirm={() => {
                              onUpdateOrder({ recycle: true });
                              setShowPopupConfirm(false);
                            }}
                            cancelText="いいえ"
                            onCancel={() => {
                              onUpdateOrder({ recycle: false });
                              setShowPopupConfirm(false);
                            }}
                            showPopup={showPopupConfirm}
                            onToggle={() => setShowPopupConfirm(!showPopupConfirm)}
                          />
                        </span>
                      </div>
                    </div>
                  );
                case 'detailInfo':
                  return <DetailListAllData data={data} screenName={SCREEN_NAME.CANCEL_INFO} key={index} />;
                case 'document':
                  return  <DetailTreeDocumentSetting orderId={orderId} order={data} key={index} />;
                case 'history':
                  return <DetailDataList datePick={false} screenName={SCREEN_NAME.CANCEL_INFO} orderId={orderId} key={index} />;
                default:
                  return '';
              }
            }
          })}
        </div>
        <Footer color={colorScreen?.code} colorText={colorScreen?.colorText} />
      </div>
    </div>
  );
};

export default DetailCancelInfo;
