import React from 'react';
import { HEADER_MENU, SCREEN_NAME } from '../../../helpers/constants';
import { ORDER, ORDER_CLIENT } from '../../../constants/endpoints';
import { useEffect } from 'react';
import { getRole } from '../../../helpers/helpers';
import Header from '../../../components/Header';
import './style.scss';
import { useState } from 'react';
import { pushToast } from '../../../components/Toast';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import DetailData from '../../../components/DetailCenter/DetailData';
import DetailDataList from '../../../components/DetailCenter/DetailDataList';
import DetailOrder from '../../../components/DetailCenter/DetailOrder';
import DetailListAllData from '../../../components/DetailCenter/DetailListAllData';
import DetailTreeDocumentSetting from 'components/DetailTreeDocumentSetting';
import Loading from 'components/Loading/Loading';
import _ from 'lodash';
import DetailReportErp from '../../../components/DetailOrderErp/DetailReportErp';
import Footer from "../../../components/Footer/index.jsx"

const SportAcceptList = () => {
  let name = (process.browser && new URL(window.location.href).searchParams.get('p')) || 'preparation_info';
  let orderId = process.browser && new URL(window.location.href).searchParams.get('orderId');
  let organizationId = process.browser && new URL(window.location.href).searchParams.get('organizationId') || '';
  const [p, setP] = React.useState(name || HEADER_MENU.RECEIVE_ALL);
  const [role, setRole] = React.useState(null);
  const [idRole, setIdRole] = React.useState(null);
  const [data, setData] = React.useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedName, setSelectedName] = useState('');
  const colorScreen = useSelector((store) => store.utils.colorMe);
  const [loading, setLoading] = useState(false);
  const [listDetailScreen, setListDetailScreen] = useState([]);
  const [documentSetting, setDocumentSetting] = useState([]);

  useEffect(() => {
    getListRoleChild();
  }, []);

  const getListRoleChild = async () => {
    setLoading(true);
    const newRole = localStorage.getItem('role');
    try {
      const data = await ORDER.GET_LIST_DETAIL_SETTING({
        arrRole: newRole,
        nameScreen: 'preparation_info',
        orderId,
        organizationId
      });
      if (data) {
        setListDetailScreen(data?.data?.detailScreen);
        // setListDetailScreen(data ?.data ?.detailScreen.concat(
        //   {name: "documentSetting", sortNum: 99, status: true}
        // ));
        const newData = _.groupBy(data?.data?.documentSettingPreparationInfo, 'typeChildId');
        setDocumentSetting(newData);
      }
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  useEffect(() => {
    setP(name);
  }, [name]);

  const getOrderDetail = async ({ orderId = '' }) => {
    setLoading(true);
    try {
      const data = await ORDER.GET_DETAIL_CANCEL_INFO({ orderId, screenName: "preparation_info", organizationId });
      if (data) {
        setData(data?.data);
      };
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  useEffect(() => {
    const newRole = localStorage.getItem('role');
    setIdRole(newRole);
    if (newRole) {
      setRole(getRole({ idRole: newRole }));
    }
    if (orderId) {
      getOrderDetail({ orderId });
    }
  }, [orderId]);

  const updateFileOrder = async ({ body = '', order_id = '', screenName: screenName }) => {
    setLoading(true);
    try {
      const data = await ORDER_CLIENT.UPDATE_FILE_ORDER_DESCRIBE({
        body: body,
        order_id: order_id,
        screenName: screenName,
      });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else if (data.message.errorMessage) {
          pushToast('error', data?.message?.errorMessage);
        } else {
          pushToast('error', data?.message);
        }
      } else {
        pushToast('success', 'Upload file order describe');
        getOrderDetail({ orderId });
      }
      setLoading(false);
    } catch (errorMessage) {
      console.log('errorMessage: ', errorMessage);
      setLoading(false);
    }
  };

  const onChangeHandler = (e) => {
    setSelectedFile(e?.target?.files[0]);
    setSelectedName(e?.target?.files[0]?.name);
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const onClickHandler = async () => {
    const data = {
      file: selectedFile ? await toBase64(selectedFile) : '',
      fileName: selectedFile ? selectedFile.name : '',
      screenName: '依頼一覧',
    };
    {
      !_.isEmpty(data.fileName) && updateFileOrder({ body: data, order_id: orderId });
    }
  };

  return (
    <div className="wrapper-sport-accept-list">
      <Loading loading={loading} />
      <div className="main">
        <Header idRole={idRole} color={colorScreen?.code} colorText={colorScreen?.colorText} p="preparation_info" />
        <div className="body-wrapper">
          <div className="logout_btn">
            <Link to={`list?p=${p}`}>
              <span className="mypage_ab_custom">戻る</span>
            </Link>
          </div>
          {listDetailScreen?.map((item) => {
            if (item.status) {
              switch (item.name) {
                case 'clientCompany':
                  return <DetailOrder data={data} p={p} />;
                case 'applicationCompany':
                  return <DetailData data={data} p={p} link={false} />;
                case 'preparationInfo':
                  return (
                    <>
                      <div className="detail-sport-list-wrapper">
                        <div className="title-absolute">依頼一覧</div>
                        {/* <div className="wrapper_main_list"> */}
                        <table className="conditTab">
                          <tbody>
                            {' '}
                            <tr>
                              <th width="150">依頼担当者</th>
                              <td>{data?.requestingPerson}</td>
                            </tr>
                            <tr>
                              <th>依頼日</th>
                              <td>{data?.requestedDate}</td>
                            </tr>
                            <tr>
                              <th>訪問日</th>
                              <td>{data?.visitDate}</td>
                            </tr>
                            <tr>
                              <th>管理コード</th>
                              <td>{data?.managementCode}</td>
                            </tr>
                            <tr>
                              <th>物件名</th>
                              <td>{data?.installationSiteName}</td>
                            </tr>
                            <tr>
                              <th>都道府県</th>
                              <td>{data?.prefecturesVisited}</td>
                            </tr>
                            <tr>
                              <th>訪問先拠点住所</th>
                              <td>{data?.visitedBaseAddress}</td>
                            </tr>
                            <tr>
                              <th>訪問先建物名</th>
                              <td>{data?.visitedBuildingName}</td>
                            </tr>
                            {/*<tr>*/}
                            {/*  <th>資料添付</th>*/}
                            {/*  <td>*/}
                            {/*    <label className="button-file-input" htmlFor="upload-csv" />*/}
                            {/*    <input*/}
                            {/*      type="file"*/}
                            {/*      name="file"*/}
                            {/*      id="upload-csv"*/}
                            {/*      className="d-none"*/}
                            {/*      onChange={onChangeHandler}*/}
                            {/*    />*/}
                            {/*    <input*/}
                            {/*      id="dummy_file1"*/}
                            {/*      type="text"*/}
                            {/*      value={selectedName === '' ? data?.describeFiles?.[0].name : selectedName}*/}
                            {/*      disabled="disabled"*/}
                            {/*    />*/}
                            {/*    <br />*/}
                            {/*  </td>*/}
                            {/*</tr>*/}
                            {/*<tr>*/}
                            {/*  <th>資料添付ダウンロード</th>*/}
                            {/*  <td>*/}
                            {/*    {data?.describeFiles?.[0].name}*/}
                            {/*    <a*/}
                            {/*      type="button"*/}
                            {/*      className="button-dowload-form"*/}
                            {/*      onClick={() => window.open(`${data?.describeFiles?.[0].url}`)}*/}
                            {/*    />*/}
                            {/*  </td>*/}
                            {/*</tr>*/}
                          </tbody>
                        </table>
                      </div>
                      {/* </div> */}
                    </>
                  );
                case 'fileUpload':
                  return (
                    Object.keys(documentSetting).map((item, i) => {
                      const nameForm = documentSetting?.[item]?.[0]?.nameTypeChild;
                      return (
                        <DetailReportErp
                          p={p}
                          data={data} organizationId={organizationId}
                          orderId={orderId}
                          partnerId=''
                          key={i} documentSetting={documentSetting?.[item]}
                          nameForm={nameForm}
                          type={'reportInformation'}
                          screenName={'依頼一覧'}
                          />
                      );
                    })
                  );
                case 'detailInfo':
                  return <DetailListAllData screenName={SCREEN_NAME.SPORT_ACCEPT} data={data} />;
                // case 'documentSetting':
                //   return <DetailTreeDocumentSetting orderId={orderId}/>;
                case 'document':
                  return <DetailTreeDocumentSetting orderId={orderId} order={data} />;
                case 'history':
                  return (
                    <DetailDataList
                      role={role}
                      orderId={orderId}
                      datePick={false}
                      screenName={SCREEN_NAME.SPORT_ACCEPT}
                    />
                  );
                default:
                  return '';
              }
            }
          })}
        </div>
      <Footer color={colorScreen?.code} colorText={colorScreen?.colorText} />
      </div>
    </div>
  );
};
export default SportAcceptList;
