import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { pushToast } from '../../../components/Toast';
import { ORDER } from '../../../constants/endpoints';
import { SCREEN_NAME } from '../../../helpers/constants';
import DetailWorker from '../DetailWorkerList/DetailWorker';
import 'assets/scss/page/CancelDetailWorker/style.scss';
import { useSelector } from 'react-redux'

const mockData = {
  resultCancelWorker: '',
  resultJudgment: '',
  reasonCancelWorker: '',
  screenName : SCREEN_NAME.TODAY
};

const CancelDetailWorker = () => {
  const orderId = (process.browser && new URL(window.location.href).searchParams.get('orderId')) || null;
  const organizationId = (process.browser && new URL(window.location.href).searchParams.get('organizationId')) || '';
  const [data, setData] = useState([]);
  const [dataOrder, setDataOrder] = useState(mockData);
  const history = useHistory();
  const userMeData = useSelector((store) => store.authentication.userMe);

  const [nameResultCancelWorker, setNameResultCancelWorker] = useState('選択してください。');

  useEffect(() => {
    if (data) {
      setDataOrder({
        ...dataOrder,
        resultCancelWorker: data?.resultCancelWorker,
        resultJudgment: data?.resultJudgment,
        reasonCancelWorker: data?.reasonCancelWorker,
        staffId: data?.staffId,
      });
    }
  }, [data]);

  const isFirstWorker = () => {
    return dataOrder?.staffId?.indexOf(userMeData?.data?.loginId) === 0;
  }

  const getOrderDetail = async ({ orderId = '' }) => {
    try {
      const data = await ORDER.GET_DETAIL_CANCEL_INFO({ orderId: orderId , organizationId });
      if (data) {
        setData(data?.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (orderId) {
      getOrderDetail({
        orderId: orderId,
      });
    }
  }, [orderId]);

  const updateOrderPartner = async ({ body = {}, id_order = '' }) => {
    try {
      delete body.staffId
      const data = await ORDER.UPDATE_CANCEL_ORDER_BY_WORKRER({ body: body, id_order: id_order, organizationId });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
      } else {
        pushToast('success', 'Update order success');
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onClickUpDate = () => {
    updateOrderPartner({ id_order: orderId, body: dataOrder });
  };

  const onChangeTime = (e) => {
    let name = e.target.name;
    setDataOrder({ ...dataOrder, [name]: e.target.value });
  };
  const [selectName, setSelectName] = useState();

  const handleSelectValue = (e) => {
    let value = e.target.value;
    let name = e.target.name;
    setSelectName(value);
    setNameResultCancelWorker(value);
    setDataOrder({ ...dataOrder, [name]: value, resultJudgment: '当日CAN' });
  };

  const onReturn = () => {
    history.goBack();
  };

  return (
    <div className="cancel-detail-worker-wrapper">
      <div className="wrapper-work-list pl-0 pr-0" style={{ minHeight: 0 }}>
        <div className="block-link row mr-0 ml-0 ">
          <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4 block-link-child text-left">
            <a onClick={onReturn} className="item">
              <span>戻る</span>
            </a>
          </div>
          <div className="block-link-child col-sm-6 col-md-4 col-lg-4 col-xl-4">
            <span style={{ color: '#fff' }}>キャンセル連絡</span>
          </div>
          <div className="block-feature col-sm-6 col-md-4 col-lg-4 col-xl-4"></div>
        </div>
      </div>
      <DetailWorker data={data} />
      <div>
        <label for="id01">キャンセル理由：</label>
        <div className="ui-select px-2">
          <div id="id01-button" className="ui-btn ui-icon-carat-d ui-btn-icon-right ui-corner-all ui-shadow">
            <span>{dataOrder?.resultCancelWorker ? dataOrder?.resultCancelWorker : nameResultCancelWorker}</span>
            <select
              disabled={!isFirstWorker()}
              name="resultCancelWorker"
              onChange={handleSelectValue}
              value={dataOrder?.resultCancelWorker}
            >
              <option value="選択してください。">選択してください。</option>
              <option value="日程変更">日程変更</option>
              <option value="申込み取下げ">申込み取下げ</option>
              <option value="不在">不在</option>
            </select>
          </div>
        </div>

        <label for="site_result_str">結果判定：</label>
        <div className="px-2">
          <div className="ui-input-text ui-body-inherit ui-corner-all ui-shadow-inset">
            <input
              className="form-control"
              disabled={!isFirstWorker()}
              type="text"
              name=""
              value={dataOrder?.resultJudgment !== '' ? dataOrder?.resultJudgment : selectName}
            />
          </div>
        </div>

        <label for="site_result_etc">結果コメント：</label>
        <div className="px-2">
          <textarea
            name="reasonCancelWorker"
            onChange={onChangeTime}
            disabled={!isFirstWorker()}
            id="site_result_etc"
            cols="40"
            value={dataOrder?.reasonCancelWorker}
            colbasics="10"
            className="ui-input-text ui-shadow-inset ui-body-inherit ui-corner-all ui-textinput-autogrow form-control rounded-0"
            style={{ height: 51 }}
          >
            {dataOrder?.reasonCancelWorker}
          </textarea>
        </div>

        <div align="center">
          { isFirstWorker() && <input
            type="submit"
            className="ui-btn ui-btn-inline ui-corner-all "
            style={{ backgroundColor: '#DF0101' }}
            id="btn_report"
            name="btn_report"
            value="保 存"
            onClick={() => onClickUpDate()}
          />}
        </div>
      </div>
    </div>
  );
};

export default CancelDetailWorker;
