import React, { useState } from 'react';
import 'assets/scss/page/BranchMaster/SearchBranchMaster.scss';
import { Helmet } from 'react-helmet';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import ButtonBranch from './ButtonBranch';

const dataSearchMock = {
    page: 1,
    limit: 10,
    branchName: '',
    branchNameKana: ''
};

const SearchBranchMaster = ({
    fontSize, getListBranchMaster = () => {
    }
}) => {
    const [dataSearch, setDataSearch] = useState(dataSearchMock);

    const handleChangeSearch = (e) => {
        const typeName = e.target.name;
        let newData = { ...dataSearch, [typeName]: e.target.value };
        setDataSearch(newData);
    };

    const onSubmit = () => {
        getListBranchMaster(dataSearch);
    };

    return (
        <div className="wrapper-search-branch-master">
            <Helmet>
                <title>拠点登録</title>
            </Helmet>

            <div>
                <ButtonBranch subTitle="新規登録" color="green" icon="icon-pencil" width={244} font={fontSize} />
            </div>

            <div className="form-search">
                <div className="form-search__label">
                  拠点名
                </div>
                <input
                    type="text"
                    className="form-search__input"
                    name="branchName"
                    value={dataSearch?.branchName}
                    onChange={handleChangeSearch}
                />
                <button className="btn form-search__submit" onClick={() => onSubmit()}>
                    <i className="fa fa-search" />
                    検索
                </button>
            </div>
        </div>
    );
};

export default compose(withRouter)(SearchBranchMaster);
