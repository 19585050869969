import React, { useEffect, useState } from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { getListOrder } from '../../../actions/order';
import Footer from '../../../components/Footer';
import HeaderMasterSetting from '../../../components/HeaderMasterSetting';
import PaginationCustom from '../../../components/PaginationCustom';
import SearchMenu from '../../../components/SearchMenu';
import TableListMaintenance from '../../../components/TableListMaintenance';
import { COLOR_ENDPOINT, ORDER } from '../../../constants/endpoints';
import { HEADER_MENU } from '../../../helpers/constants';
import { getRole } from '../../../helpers/helpers';
import './style.scss';
import Loading from '../../../components/Loading/Loading';

const INIT_AFFILIATE_PAGINATION = {
  page: 1,
  limit: 10,
};

const Index = (props) => {
  const { t } = props;
  let name = (process.browser && new URL(window.location.href).searchParams.get('p')) || 'receive_all';
  const [p, setP] = React.useState(name || HEADER_MENU.RECEIVE_ALL);
  const [activePage, setActivePage] = React.useState(1);
  const [role, setRole] = React.useState(null);
  const [idRole, setIdRole] = React.useState(null);
  const [color, setColor] = React.useState('#1c2a53');
  const [roleDetail, setRoleDetail] = useState('');
  const [ListExampleMonitor, setListExampleMonitor] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (idRole) {
      setRoleDetail(getRole({ idRole: idRole }));
    }
  }, [idRole]);
  const getColor = async ({ role = '' }) => {
    try {
      const data = await COLOR_ENDPOINT.GET_COLOR({ role: idRole });
      if (data && data?.code === 0) {
        setColor(data?.data?.code);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (idRole) {
      getColor({ role: idRole });
    }
  }, [idRole]);

  useEffect(() => {
    const newRole = localStorage.getItem('role');
    setIdRole(newRole);
    if (newRole) {
      setRole(getRole({ idRole: newRole }));
    }
  }, []);

  useEffect(() => {
    setP(name);
  }, [name]);

  useEffect(() => {
    getListExampleMonitor({ page: activePage, limit: 10 });
  }, [activePage]);

  const getListExampleMonitor = async ({
    page = INIT_AFFILIATE_PAGINATION.page,
    limit = INIT_AFFILIATE_PAGINATION.limit,
  }) => {
    setLoading(true);
    try {
      const data = await ORDER.GET_LIST_ORDER_EXAMPLE({
        page: page,
        limit: limit,
      });
      setListExampleMonitor(data);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };
  return (
    <>
      <div className="main">
        <Loading loading={loading} />
        <HeaderMasterSetting />
        <SearchMenu role={'EXAMPLE'} color={color} />
        <TableListMaintenance data={ListExampleMonitor && ListExampleMonitor.data} t={t} />
        {ListExampleMonitor && ListExampleMonitor.data && ListExampleMonitor.data.count > 10 && (
          <PaginationCustom
            activePage={activePage}
            itemsCountPerPage={10}
            totalItemsCount={ListExampleMonitor && ListExampleMonitor.data && ListExampleMonitor.data.count}
            pageRangeDisplayed={5}
            handlePageChange={handlePageChange}
          />
        )}
        <Footer color={color} />
      </div>
    </>
  );
};

const mapStateToProps = (store) => {
  return {
    listOrder: store.order.listOrder,
    loading: store.utils.loading,
  };
};

const mapActionToProps = {
  getListOrder,
};

export default compose(translate('translations'), withRouter, connect(mapStateToProps, mapActionToProps))(Index);
