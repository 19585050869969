import React, { useState } from 'react';
import './style.scss';

const data = {
  header: ['リンク種類', 'リンク名称', 'URL', '表示・非表示', 'アイコン'],
  body: [
    {
      リンク種類: '通常',
      リンク名称: '前日確認',
      URL: 'list.php?p=previsit',
      表示: '表示',
      非表示: false,
    },
    {
      リンク種類: '通常',
      リンク名称: '集計サマリ',
      URL: 'monitor_cand.php',
      表示: '表示',
      非表示: false,
    },
  ],
};

const renderItem = (item, index, fontSize) => {
  return (
    <tr key={index}>
      {data.header.map((element, key) => {
        return element === 'アイコン' ? (
          <td>
            <div className="d-flex align-items-center justify-content-between flex-column flex-xl-row">
              <input
                type="file"
                className="d-none"
                id="choose-file"
                accept=".jpg,.jpeg,.pdf,.xls,.xlsx,.ppt,.pptx,.doc,.docx,.txt,.png"
              />
              <label htmlFor="choose-file" className="type-file mb-0" />
              <input
                className="dummy_file"
                value=""
                type="text"
                placeholder="ファイルを選んで下さい..."
                disabled="disabled"
              />
            </div>
          </td>
        ) : element === 'リンク名称' ? (
          <td>
            <div className="d-flex align-items-center form-input">
              <input value={item[element]} className={`form-control input-link-name ${fontSize}`} />
              <span className="ml-2">最大5文字</span>
            </div>
          </td>
        ) : element === 'URL' ? (
          <td>
            <div className="form-input">
              <input value={item[element]} className={`form-control ${fontSize}`} />
            </div>
          </td>
        ) : element === '表示・非表示' ? (
          <td>
            <div className="d-flex align-items-center">
              <input type="radio" name={`show_link[${index}]`} value="show" id={`LinkMasterId1On${index}`} />
              <label className="mb-0 ml-1" htmlFor={`LinkMasterId1On${index}`}>
                表示
              </label>
              <input type="radio" name={`show_link[${index}]`} id={`LinkMasterId1Off${index}`} className="ml-1" />
              <label className="mb-0 ml-1" htmlFor={`LinkMasterId1Off${index}`}>
                非表示
              </label>
              <input type="checkbox" name="delete[1]" id="delete1" value="1" className="ml-1" />
              <label className="mb-0 ml-1" htmlFor="delete1">
                削除
              </label>
            </div>
          </td>
        ) : (
          <td key={key}>{item[element]}</td>
        );
      })}
    </tr>
  );
};

const TablePageMaster = ({ fontSize = 'small' }) => {
  const [listData, SetListData] = useState(data.body);

  const onClickAddItem = () => {
    let newListData = [...listData];
    let newItem = {
      リンク種類: '通常',
      リンク名称: '',
      URL: '',
      表示: '表示',
      非表示: false,
    };
    newListData.push(newItem);
    SetListData(newListData);
  };

  return (
    <>
      <div className="table-page-master-wrapper table-responsive mt-3">
        <table className={`table mb-0 ${fontSize}`}>
          <thead>
            <tr>
              {data.header.map((item, index) => (
                <th scope="col" key={index}>
                  {item}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>{listData.map((listItem, index) => renderItem(listItem, index, fontSize))}</tbody>
        </table>
      </div>
      <div className="group-button d-flex align-items-center justify-content-center mt-3 flex-column">
        <button className={`btn button-add ${fontSize}`} onClick={() => onClickAddItem()}>
          <i className="fa fa-plus" />
          追加
        </button>
        <button className={`btn button-submit mt-3 ${fontSize}`}>追加</button>
      </div>
    </>
  );
};

export default TablePageMaster;
