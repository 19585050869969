import React, { useState } from 'react';
import HeaderMasterSetting from 'components/HeaderMasterSetting';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { ORDER } from '../../../constants/endpoints';
import PaginationCustom from 'components/PaginationCustom';
import { compose } from 'redux';
import { translate } from 'react-i18next';
import Loading from 'components/Loading/Loading';
import 'assets/scss/page/ApprovalRequest/style.scss';
import { Link } from 'react-router-dom';
import { getNoOrder } from '../../../helpers/helpers';
import Footer from "../../../components/Footer/index.jsx"

const ApprovalRequest = ({ t }) => {
  const colorScreen = useSelector((store) => store.utils.colorMe);
  const [idRole, setIdRole] = useState(null);

  const [dataInfo, setDataInfo] = useState({});
  const [activePage, setActivePage] = React.useState(1);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const newRole = localStorage.getItem('role');
    setIdRole(newRole);
  }, []);

  const colorStatus = (status) => {
    switch (status) {
      case 'red':
        return { background: '#ff0000', color: 'white', width: 'unset', animation: 'status-pulse-red 1s infinite' };
      case 'yellow':
        return { background: '#ffff00', color: 'black', width: 'unset', animation: 'status-pulse-yellow 1s infinite' };
      default:
        return { border: 'none' };
    }
  };

  const renderTable = (item, element) => {
    if (element === 'status') {
      return item[element] === true ? '確認済' : item[element] === false ? '未確認' : '';
    } else {
      return item[element];
    }
  };

  const renderLinkDetal = (item) => {
    switch (item.type) {
      case 'オーダー承認':
        return (
          <td>
            <Link
              className="table-confirm--btn_edit"
              to={`/describe-receive-info?orderId=${item?.orderId}&back=confirm&organizationId=${item?.organizationId}`}
            >
              確認
            </Link>
          </td>
        );
      case '事前確認の承認':
        return (
          <td>
            <Link
              className="table-confirm--btn_edit"
              to={`/detail-before-confirm-erp?orderId=${item?.orderId}&back=confirm&organizationId=${item?.organizationId}`}
            >
              確認
            </Link>
          </td>
        );
      case '納品承認':
        return (
          <td>
            <Link
              className="table-confirm--btn_edit"
              to={`/detail-report-upload-info?orderId=${item?.orderId}&back=confirm&organizationId=${item?.organizationId}`}
            >
              確認
            </Link>
          </td>
        );
      default:
        return '';
    }
  };

  const renderItem = (item, index) => {
    return (
      <tr key={index}>
        <td>{getNoOrder({ index, limit: 10, activePage: activePage })}</td>
        {dataInfo?.header?.map((element, key) => {
          return <td key={key}>{renderTable(item, element)}</td>;
        })}
        {['オーダー承認', '納品承認', '事前確認の承認'].includes(item?.type) ? (
          <td className="table--nowrap">
            {item?.statusAlert && (
              <div className="status_process mb-1" style={colorStatus('red')}>
                {item?.statusAlert}
              </div>
            )}
            {!item?.status && (
              <div div className="status_process" style={colorStatus('yellow')}>
                {/* {item?.type == '納品承認' ? `納品承認待ち` : `承認待ち`} */}
                承認待ち
              </div>
            )}
          </td>
        ) : (
          <td></td>
        )}

        {renderLinkDetal(item)}
      </tr>
    );
  };

  const getListOrder = async ({ page, limit }) => {
    setLoading(true);
    try {
      const data = await ORDER.GET_LIST_ORDER_CONFIRM_FUNCTION({
        page: page,
        limit: limit,
      });
      setDataInfo(data?.data);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (activePage) {
      getListOrder({ page: activePage, limit: 10 });
    }
  }, [activePage]);

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  return (
    <div className="main">
      <HeaderMasterSetting idRole={idRole} color={colorScreen?.code} colorText={colorScreen?.colorText} />
      <Loading loading={loading} />
      <div className="table-confirm">
        <div className="table-confirm--wrapper table-responsive mb-2">
          承認待ち：{dataInfo?.count}件
          <table className="w-100">
            <thead>
              <tr>
                <th scope="col">No</th>
                {dataInfo?.header?.map((item, index) => (
                  <th scope="col" key={index}>
                    {t(`table_my_page.${item}`)}
                  </th>
                ))}
                <th scope="col">状態</th>
                <th scope="col" className='w-6'>操作</th>
              </tr>
            </thead>
            <tbody>{dataInfo?.body?.map((listData, index) => renderItem(listData, index))}</tbody>
          </table>
        </div>
        {dataInfo?.count > 10 && (
          <PaginationCustom
            activePage={activePage}
            itemsCountPerPage={10}
            totalItemsCount={dataInfo && dataInfo.count}
            pageRangeDisplayed={5}
            handlePageChange={handlePageChange}
          />
        )}
      </div>
      <Footer color={colorScreen?.code} colorText={colorScreen?.colorText} />
    </div>
  );
};

export default compose(translate('translations'))(ApprovalRequest);
