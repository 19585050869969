import React, { useState, useEffect } from 'react';
import { EMAIL_SETTING } from 'constants/endpoints';
import { pushToast } from 'components/Toast';
import { compose } from 'redux';
import { translate } from 'react-i18next';
import { SCREEN_NAME } from '../../../../helpers/constants';
import ModalEmailUpdate from '../ModalEmailUpdate/index.jsx';
import _ from 'lodash';
import Loading from 'components/Loading/Loading';
import '../../../../assets/scss/page/MasterSettings/ModalMailSetting.scss';
import { getDataHistoryMasterSetting } from 'actions/authentication';
import { useDispatch } from 'react-redux';

const ModalMailSetting = (props) => {
  const {
    idProvider,
    idService,
    dataMailFetch,
    t,
    idProvide,
    productIdTree,
    filter,
    isHidden,
    showModalEmailUpdate,
    hideModalEmailUpdate,
    idRole,
    nameProduct,
  } = props;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [listMailSettings, setListMailSettings] = useState(dataMailFetch);
  const [numOfUpdate, setNumOfUpdate] = useState('');

  const getListMailSettings = async () => {
    try {
      setLoading(true);
      const data = await EMAIL_SETTING.GET_LIST_EMAIL_SETTINGS({
        idProvide,
        productId: productIdTree,
        serviceId: idService,
      });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
        setLoading(false);
      } else {
        setListMailSettings(data?.data?.map((mail) => _.pick(mail, filter)) || []);
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const handleUpdateMail = async () => {
    const body = {
      mailSettings: listMailSettings,
      screenName: SCREEN_NAME.MY_CORP,
      productId: productIdTree,
      serviceId: idService,
      updateTitle : `設定を保存　メール送信設定　${nameProduct ? nameProduct : ''}`
    };
    try {
      setLoading(true);
      const data = await EMAIL_SETTING.CREATE_EMAIL_SETTING({ body, id: idProvider, idProvide });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
        setLoading(false);
      } else {
        getListMailSettings();
        dispatch(getDataHistoryMasterSetting({ logId : '', screenName: SCREEN_NAME.MY_CORP, idProvide }));
        pushToast('success', t(`modal_setting_order_email.update_success`));
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const closeModalEmailUpdate = () => {
    setNumOfUpdate('');
    hideModalEmailUpdate();
  };

  const switchToModalEmailUpdate = (localNum) => {
    setNumOfUpdate(localNum);
    showModalEmailUpdate();
  };

  return (
    <>
      {numOfUpdate >= 1 && numOfUpdate <= 30 && (
        <ModalEmailUpdate
          className={isHidden ? `` : `hidden-item`}
          numOfUpdate={numOfUpdate}
          listMailSettings={listMailSettings}
          closeModal={closeModalEmailUpdate}
          productId={productIdTree}
          idService={idService}
          idProvider={idProvider}
          idProvide={idProvide}
          t={t}
          filter={filter}
          isHidden={isHidden}
          showModalEmailUpdate={showModalEmailUpdate}
          hideModalEmailUpdate={hideModalEmailUpdate}
          listMailSettings={listMailSettings}
          getListMailSettings={getListMailSettings}
          idRole={idRole}
        />
      )}
      <Loading loading={loading} />
      <div className={!isHidden ? `form-body form--height-unset mail-setting` : `hidden-item`}>
        {idService !== '' && (
          <>
            <div className="item-content flex-row row">
              <p className="text-capitalize col-2">1.&nbsp;承認</p>
              <div className="d-flex justify-content-between col-10">
                <div className="d-flex justify-content-between">
                  <div className="check-item check-item--pl30 mr-2">
                    <input
                      name={listMailSettings[0]?.localNum}
                      type="radio"
                      checked={listMailSettings[0]?.state == 1}
                      onClick={(e) => {
                        let newMailList = [...listMailSettings];
                        let data = { ...newMailList[0], state: e.target.checked ? 1 : 0 };
                        newMailList[0] = data;
                        setListMailSettings(newMailList);
                      }}
                    />
                    <label>利用</label>
                  </div>
                  <div className="checkbox-group">
                    <input
                      type="checkbox"
                      checked={listMailSettings[0]?.active == 1}
                      onClick={(e) => {
                        let newMailList = [...listMailSettings];
                        let data = { ...newMailList[0], active: e.target.checked ? 1 : 0 };
                        newMailList[0] = data;
                        setListMailSettings(newMailList);
                      }}
                    />
                    <label>アラート</label>
                    <button onClick={() => switchToModalEmailUpdate(1)} className="btn-email-update">
                      詳細
                    </button>
                  </div>
                </div>
                <div className="check-item check-item--pl30">
                  <input
                    type="radio"
                    name={listMailSettings[0]?.localNum}
                    checked={listMailSettings[0]?.state == 0}
                    onClick={(e) => {
                      let newMailList = [...listMailSettings];
                      let data = { ...newMailList[0], state: e.target.checked ? 0 : 1 };
                      newMailList[0] = data;
                      setListMailSettings(newMailList);
                    }}
                  />
                  <label>未使用</label>
                </div>
              </div>
            </div>

            <div className="item-content flex-row row">
              <p className="text-capitalize col-2">2.&nbsp;アポイント</p>
              <div className="d-flex justify-content-between col-10">
                <div className="d-flex justify-content-between">
                  <div className="check-item check-item--pl30 mr-2">
                    <input
                      type="radio"
                      name={listMailSettings[1]?.localNum}
                      checked={listMailSettings[1]?.state == 1}
                      onClick={(e) => {
                        let newMailList = [...listMailSettings];
                        let data = { ...newMailList[1], state: e.target.checked ? 1 : 0 };
                        newMailList[1] = data;
                        setListMailSettings(newMailList);
                      }}
                    />
                    <label>利用</label>
                  </div>
                  <div className="checkbox-group">
                    <input
                      type="checkbox"
                      checked={listMailSettings[1]?.active == 1}
                      onClick={(e) => {
                        let newMailList = [...listMailSettings];
                        let data = { ...newMailList[1], active: e.target.checked ? 1 : 0 };
                        newMailList[1] = data;
                        setListMailSettings(newMailList);
                      }}
                    />
                    <label>アラート</label>
                    <button onClick={() => switchToModalEmailUpdate(2)} className="btn-email-update">
                      詳細
                    </button>
                  </div>
                </div>
                <div className="check-item check-item--pl30">
                  <input
                    type="radio"
                    name={listMailSettings[1]?.localNum}
                    checked={listMailSettings[1]?.state == 0}
                    onClick={(e) => {
                      let newMailList = [...listMailSettings];
                      let data = { ...newMailList[1], state: e.target.checked ? 0 : 1 };
                      newMailList[1] = data;
                      setListMailSettings(newMailList);
                    }}
                  />
                  <label>未使用</label>
                </div>
              </div>
            </div>

            <div className="item-content flex-row row">
              <p className="text-capitalize col-2">3.&nbsp;稼働調整</p>
              <div className="d-flex justify-content-between col-10">
                <div className="d-flex justify-content-between">
                  <div className="check-item check-item--pl30 mr-2">
                    <input
                      type="radio"
                      name={listMailSettings[2]?.localNum}
                      checked={
                        listMailSettings[2]?.state == 1 ||
                        listMailSettings[3]?.state == 1 ||
                        listMailSettings[4]?.state == 1 ||
                        listMailSettings[5]?.state == 1
                      }
                      onClick={(e) => {
                        let newMailList = [...listMailSettings];
                        let data2 = { ...newMailList[2], state: e.target.checked ? 1 : 0 };
                        let data3 = { ...newMailList[3], state: e.target.checked ? 1 : 0 };
                        let data4 = { ...newMailList[4], state: e.target.checked ? 1 : 0 };
                        let data5 = { ...newMailList[5], state: e.target.checked ? 1 : 0 };
                        newMailList[2] = data2;
                        newMailList[3] = data3;
                        newMailList[4] = data4;
                        newMailList[5] = data5;
                        setListMailSettings(newMailList);
                      }}
                    />
                    <label>利用</label>
                  </div>
                  <div className="checkbox-group">
                    <input
                      type="checkbox"
                      checked={listMailSettings[2]?.active == 1}
                      onClick={(e) => {
                        let newMailList = [...listMailSettings];
                        let data = { ...newMailList[2], active: e.target.checked ? 1 : 0 };
                        newMailList[2] = data;
                        setListMailSettings(newMailList);
                      }}
                    />
                    <label>打診</label>
                    <button onClick={() => switchToModalEmailUpdate(3)} className="btn-email-update">
                      詳細
                    </button>
                  </div>
                  <div className="checkbox-group">
                    <input
                      type="checkbox"
                      checked={listMailSettings[3]?.active == 1}
                      onClick={(e) => {
                        let newMailList = [...listMailSettings];
                        let data = { ...newMailList[3], active: e.target.checked ? 1 : 0 };
                        newMailList[3] = data;
                        setListMailSettings(newMailList);
                      }}
                    />
                    <label>確保</label>
                    <button onClick={() => switchToModalEmailUpdate(4)} className="btn-email-update">
                      詳細
                    </button>
                  </div>
                  <div className="checkbox-group">
                    <input
                      type="checkbox"
                      checked={listMailSettings[4]?.active == 1}
                      onClick={(e) => {
                        let newMailList = [...listMailSettings];
                        let data = { ...newMailList[4], active: e.target.checked ? 1 : 0 };
                        newMailList[4] = data;
                        setListMailSettings(newMailList);
                      }}
                    />
                    <label>見送</label>
                    <button onClick={() => switchToModalEmailUpdate(5)} className="btn-email-update">
                      詳細
                    </button>
                  </div>
                  <div className="checkbox-group hidden-item">
                    <input
                      type="checkbox"
                      checked={listMailSettings[5]?.active == 1}
                      onClick={(e) => {
                        let newMailList = [...listMailSettings];
                        let data = { ...newMailList[5], active: e.target.checked ? 1 : 0 };
                        newMailList[5] = data;
                        setListMailSettings(newMailList);
                      }}
                    />
                    <label>アラート</label>
                    <button onClick={() => switchToModalEmailUpdate(6)} className="btn-email-update">
                      詳細
                    </button>
                  </div>
                </div>
                <div className="check-item check-item--pl30">
                  <input
                    type="radio"
                    name={listMailSettings[2]?.localNum}
                    checked={
                      listMailSettings[2]?.state == 0 &&
                      listMailSettings[3]?.state == 0 &&
                      listMailSettings[4]?.state == 0 &&
                      listMailSettings[5]?.state == 0
                    }
                    onClick={(e) => {
                      let newMailList = [...listMailSettings];
                      let data2 = { ...newMailList[2], state: e.target.checked ? 0 : 1 };
                      let data3 = { ...newMailList[3], state: e.target.checked ? 0 : 1 };
                      let data4 = { ...newMailList[4], state: e.target.checked ? 0 : 1 };
                      let data5 = { ...newMailList[5], state: e.target.checked ? 0 : 1 };
                      newMailList[2] = data2;
                      newMailList[3] = data3;
                      newMailList[4] = data4;
                      newMailList[5] = data5;
                      setListMailSettings(newMailList);
                    }}
                  />
                  <label>未使用</label>
                </div>
              </div>
            </div>

            <div className="item-content flex-row row">
              <p className="text-capitalize col-2">4.&nbsp;作業員</p>
              <div className="d-flex justify-content-between col-10">
                <div className="d-flex justify-content-between">
                  <div className="check-item check-item--pl30 mr-2">
                    <input
                      type="radio"
                      name={listMailSettings[6]?.localNum}
                      checked={listMailSettings[6]?.state == 1 || listMailSettings[7]?.state == 1}
                      onClick={(e) => {
                        let newMailList = [...listMailSettings];
                        let data6 = { ...newMailList[6], state: e.target.checked ? 1 : 0 };
                        let data7 = { ...newMailList[7], state: e.target.checked ? 1 : 0 };
                        newMailList[6] = data6;
                        newMailList[7] = data7;
                        setListMailSettings(newMailList);
                      }}
                    />
                    <label>利用</label>
                  </div>
                  <div className="checkbox-group">
                    <input
                      type="checkbox"
                      checked={listMailSettings[6]?.active == 1}
                      onClick={(e) => {
                        let newMailList = [...listMailSettings];
                        let data = { ...newMailList[6], active: e.target.checked ? 1 : 0 };
                        newMailList[6] = data;
                        setListMailSettings(newMailList);
                      }}
                    />
                    <label>確保依頼</label>
                    <button onClick={() => switchToModalEmailUpdate(7)} className="btn-email-update">
                      詳細
                    </button>
                  </div>
                  <div className="checkbox-group">
                    <input
                      type="checkbox"
                      checked={listMailSettings[7]?.active == 1}
                      onClick={(e) => {
                        let newMailList = [...listMailSettings];
                        let data = { ...newMailList[7], active: e.target.checked ? 1 : 0 };
                        newMailList[7] = data;
                        setListMailSettings(newMailList);
                      }}
                    />
                    <label>アラート</label>
                    <button onClick={() => switchToModalEmailUpdate(8)} className="btn-email-update">
                      詳細
                    </button>
                  </div>
                </div>
                <div className="check-item check-item--pl30">
                  <input
                    type="radio"
                    name={listMailSettings[6]?.localNum}
                    checked={listMailSettings[6]?.state == 0 && listMailSettings[7]?.state == 0}
                    onClick={(e) => {
                      let newMailList = [...listMailSettings];
                      let data6 = { ...newMailList[6], state: e.target.checked ? 0 : 1 };
                      let data7 = { ...newMailList[7], state: e.target.checked ? 0 : 1 };
                      newMailList[6] = data6;
                      newMailList[7] = data7;
                      setListMailSettings(newMailList);
                    }}
                  />
                  <label>未使用</label>
                </div>
              </div>
            </div>

            <div className="item-content flex-row row">
              <p className="text-capitalize col-2">5.&nbsp;事前確認</p>
              <div className="d-flex justify-content-between col-10">
                <div className="d-flex justify-content-between">
                  <div className="check-item check-item--pl30 mr-2">
                    <input
                      type="radio"
                      name={listMailSettings[8]?.localNum}
                      checked={
                        listMailSettings[8]?.state == 1 &&
                        listMailSettings[9]?.state == 1 &&
                        listMailSettings[10]?.state == 1
                      }
                      onClick={(e) => {
                        let newMailList = [...listMailSettings];
                        let data8 = { ...newMailList[8], state: e.target.checked ? 1 : 0 };
                        let data9 = { ...newMailList[9], state: e.target.checked ? 1 : 0 };
                        let data10 = { ...newMailList[10], state: e.target.checked ? 1 : 0 };
                        newMailList[8] = data8;
                        newMailList[9] = data9;
                        newMailList[10] = data10;
                        setListMailSettings(newMailList);
                      }}
                    />
                    <label>利用</label>
                  </div>
                  <div className="checkbox-group">
                    <input
                      type="checkbox"
                      checked={listMailSettings[8]?.active == 1}
                      onClick={(e) => {
                        let newMailList = [...listMailSettings];
                        let data = { ...newMailList[8], active: e.target.checked ? 1 : 0 };
                        newMailList[8] = data;
                        setListMailSettings(newMailList);
                      }}
                    />
                    <label>対応依頼</label>
                    <button onClick={() => switchToModalEmailUpdate(9)} className="btn-email-update">
                      詳細
                    </button>
                  </div>
                  <div className="checkbox-group">
                    <input
                      type="checkbox"
                      checked={listMailSettings[9]?.active == 1}
                      onClick={(e) => {
                        let newMailList = [...listMailSettings];
                        let data = { ...newMailList[9], active: e.target.checked ? 1 : 0 };
                        newMailList[9] = data;
                        setListMailSettings(newMailList);
                      }}
                    />
                    <label>申請期限切れ</label>
                    <button onClick={() => switchToModalEmailUpdate(10)} className="btn-email-update">
                      詳細
                    </button>
                  </div>
                  <div className="checkbox-group">
                    <input
                      type="checkbox"
                      checked={listMailSettings[10]?.active == 1}
                      onClick={(e) => {
                        let newMailList = [...listMailSettings];
                        let data = { ...newMailList[10], active: e.target.checked ? 1 : 0 };
                        newMailList[10] = data;
                        setListMailSettings(newMailList);
                      }}
                    />
                    <label>事前確認期限切れ</label>
                    <button onClick={() => switchToModalEmailUpdate(11)} className="btn-email-update">
                      詳細
                    </button>
                  </div>
                </div>
                <div className="check-item check-item--pl30">
                  <input
                    type="radio"
                    name={listMailSettings[8]?.localNum}
                    checked={
                      listMailSettings[8]?.state == 0 ||
                      listMailSettings[9]?.state == 0 ||
                      listMailSettings[10]?.state == 0
                    }
                    onClick={(e) => {
                      let newMailList = [...listMailSettings];
                      let data8 = { ...newMailList[8], state: e.target.checked ? 0 : 1 };
                      let data9 = { ...newMailList[9], state: e.target.checked ? 0 : 1 };
                      let data10 = { ...newMailList[10], state: e.target.checked ? 0 : 1 };
                      newMailList[8] = data8;
                      newMailList[9] = data9;
                      newMailList[10] = data10;
                      setListMailSettings(newMailList);
                    }}
                  />
                  <label>未使用</label>
                </div>
              </div>
            </div>

            <div className="d-flex flex-column">
              <div className="item-content flex-row row mb-0">
                <p className="text-capitalize col-2">6.&nbsp;当日稼働</p>
                <div className="d-flex justify-content-between col-10">
                  <div className="d-flex justify-content-between">
                    <div className="check-item check-item--pl30 mr-2">
                      <input
                        type="radio"
                        name={listMailSettings[11]?.localNum}
                        checked={
                          listMailSettings[11]?.state == 1 &&
                          listMailSettings[12]?.state == 1 &&
                          listMailSettings[13]?.state == 1 &&
                          listMailSettings[14]?.state == 1
                        }
                        onClick={(e) => {
                          let newMailList = [...listMailSettings];
                          let data11 = { ...newMailList[11], state: e.target.checked ? 1 : 0 };
                          let data12 = { ...newMailList[12], state: e.target.checked ? 1 : 0 };
                          let data13 = { ...newMailList[13], state: e.target.checked ? 1 : 0 };
                          let data14 = { ...newMailList[14], state: e.target.checked ? 1 : 0 };
                          newMailList[11] = data11;
                          newMailList[12] = data12;
                          newMailList[13] = data13;
                          newMailList[14] = data14;
                          setListMailSettings(newMailList);
                        }}
                      />
                      <label>利用</label>
                    </div>
                  </div>
                  <div className="check-item check-item--pl30">
                    <input
                      type="radio"
                      name={listMailSettings[11]?.localNum}
                      checked={
                        listMailSettings[11]?.state == 0 ||
                        listMailSettings[12]?.state == 0 ||
                        listMailSettings[13]?.state == 0 ||
                        listMailSettings[14]?.state == 0
                      }
                      onClick={(e) => {
                        let newMailList = [...listMailSettings];
                        let data11 = { ...newMailList[11], state: e.target.checked ? 0 : 1 };
                        let data12 = { ...newMailList[12], state: e.target.checked ? 0 : 1 };
                        let data13 = { ...newMailList[13], state: e.target.checked ? 0 : 1 };
                        let data14 = { ...newMailList[14], state: e.target.checked ? 0 : 1 };
                        newMailList[11] = data11;
                        newMailList[12] = data12;
                        newMailList[13] = data13;
                        newMailList[14] = data14;
                        setListMailSettings(newMailList);
                      }}
                    />
                    <label>未使用</label>
                  </div>
                </div>
              </div>
              <div className="align-self-center">
                <div className="d-flex flex-row">
                  <div className="checkbox-group">
                    <input
                      type="checkbox"
                      checked={listMailSettings[13]?.active == 1}
                      onClick={(e) => {
                        let newMailList = [...listMailSettings];
                        let data = { ...newMailList[13], active: e.target.checked ? 1 : 0 };
                        newMailList[13] = data;
                        setListMailSettings(newMailList);
                      }}
                    />
                    <label>未完了</label>
                    <button onClick={() => switchToModalEmailUpdate(14)} className="btn-email-update">
                      詳細
                    </button>
                  </div>
                  <div className="checkbox-group ml-1">
                    <input
                      type="checkbox"
                      checked={listMailSettings[13]?.toERPFlg == 1}
                      onClick={(e) => {
                        let newMailList = [...listMailSettings];
                        let data = { ...newMailList[13], toERPFlg: e.target.checked ? 1 : 0 };
                        newMailList[13] = data;
                        setListMailSettings(newMailList);
                      }}
                    />
                    <label>{[5, 6, 7, '5', '6', '7'].includes(idRole) ? 'ManagementGroup' : 'ERP'}</label>
                  </div>
                  <div className="checkbox-group ml-1">
                    <input
                      type="checkbox"
                      checked={listMailSettings[13]?.toManagementFlg == 1}
                      onClick={(e) => {
                        let newMailList = [...listMailSettings];
                        let data = { ...newMailList[13], toManagementFlg: e.target.checked ? 1 : 0 };
                        newMailList[13] = data;
                        setListMailSettings(newMailList);
                      }}
                    />
                    <label>Partner</label>
                  </div>
                  <div className="checkbox-group ml-1">
                    <input
                      type="checkbox"
                      checked={listMailSettings[13]?.toWorkerFlg == 1}
                      onClick={(e) => {
                        let newMailList = [...listMailSettings];
                        let data = { ...newMailList[13], toWorkerFlg: e.target.checked ? 1 : 0 };
                        newMailList[13] = data;
                        setListMailSettings(newMailList);
                      }}
                    />
                    <label>Worker</label>
                  </div>
                  <div className="checkbox-group ml-1">
                    <input
                      type="checkbox"
                      checked={listMailSettings[13]?.toMonitorFlg == 1}
                      onClick={(e) => {
                        let newMailList = [...listMailSettings];
                        let data = { ...newMailList[13], toMonitorFlg: e.target.checked ? 1 : 0 };
                        newMailList[13] = data;
                        setListMailSettings(newMailList);
                      }}
                    />
                    <label>Client</label>
                  </div>
                </div>
                <div className="d-flex flex-row">
                  <div className="checkbox-group">
                    <input
                      type="checkbox"
                      checked={listMailSettings[14]?.active == 1}
                      onClick={(e) => {
                        let newMailList = [...listMailSettings];
                        let data = { ...newMailList[14], active: e.target.checked ? 1 : 0 };
                        newMailList[14] = data;
                        setListMailSettings(newMailList);
                      }}
                    />
                    <label>当日CAN</label>
                    <button onClick={() => switchToModalEmailUpdate(15)} className="btn-email-update">
                      詳細
                    </button>
                  </div>
                  <div className="checkbox-group ml-1">
                    <input
                      type="checkbox"
                      checked={listMailSettings[14]?.toERPFlg == 1}
                      onClick={(e) => {
                        let newMailList = [...listMailSettings];
                        let data = { ...newMailList[14], toERPFlg: e.target.checked ? 1 : 0 };
                        newMailList[14] = data;
                        setListMailSettings(newMailList);
                      }}
                    />
                    <label>{[5, 6, 7, '5', '6', '7'].includes(idRole) ? 'ManagementGroup' : 'ERP'}</label>
                  </div>
                  <div className="checkbox-group ml-1">
                    <input
                      type="checkbox"
                      checked={listMailSettings[14]?.toManagementFlg == 1}
                      onClick={(e) => {
                        let newMailList = [...listMailSettings];
                        let data = { ...newMailList[14], toManagementFlg: e.target.checked ? 1 : 0 };
                        newMailList[14] = data;
                        setListMailSettings(newMailList);
                      }}
                    />
                    <label>Partner</label>
                  </div>
                  <div className="checkbox-group ml-1">
                    <input
                      type="checkbox"
                      checked={listMailSettings[14]?.toWorkerFlg == 1}
                      onClick={(e) => {
                        let newMailList = [...listMailSettings];
                        let data = { ...newMailList[14], toWorkerFlg: e.target.checked ? 1 : 0 };
                        newMailList[14] = data;
                        setListMailSettings(newMailList);
                      }}
                    />
                    <label>Worker</label>
                  </div>
                  <div className="checkbox-group ml-1">
                    <input
                      type="checkbox"
                      checked={listMailSettings[14]?.toMonitorFlg == 1}
                      onClick={(e) => {
                        let newMailList = [...listMailSettings];
                        let data = { ...newMailList[14], toMonitorFlg: e.target.checked ? 1 : 0 };
                        newMailList[14] = data;
                        setListMailSettings(newMailList);
                      }}
                    />
                    <label>Client</label>
                  </div>
                </div>
              </div>
            </div>

            <div className="item-content flex-row row">
              <p className="text-capitalize col-2">7.&nbsp;報告書回収</p>
              <div className="d-flex justify-content-between col-10">
                <div className="d-flex justify-content-between">
                  <div className="check-item check-item--pl30 mr-2">
                    <input
                      type="radio"
                      name={listMailSettings[15]?.localNum}
                      checked={
                        listMailSettings[15]?.state == 1 &&
                        listMailSettings[16]?.state == 1 &&
                        listMailSettings[17]?.state == 1 &&
                        listMailSettings[18]?.state == 1
                      }
                      onClick={(e) => {
                        let newMailList = [...listMailSettings];
                        let data15 = { ...newMailList[15], state: e.target.checked ? 1 : 0 };
                        let data16 = { ...newMailList[16], state: e.target.checked ? 1 : 0 };
                        let data17 = { ...newMailList[17], state: e.target.checked ? 1 : 0 };
                        let data18 = { ...newMailList[18], state: e.target.checked ? 1 : 0 };
                        newMailList[15] = data15;
                        newMailList[16] = data16;
                        newMailList[17] = data17;
                        newMailList[18] = data18;
                        setListMailSettings(newMailList);
                      }}
                    />
                    <label>利用</label>
                  </div>
                  <div className="checkbox-group">
                    <input
                      type="checkbox"
                      checked={listMailSettings[15]?.active == 1}
                      onClick={(e) => {
                        let newMailList = [...listMailSettings];
                        let data = { ...newMailList[15], active: e.target.checked ? 1 : 0 };
                        newMailList[15] = data;
                        setListMailSettings(newMailList);
                      }}
                    />
                    <label>提出</label>
                    <button onClick={() => switchToModalEmailUpdate(16)} className="btn-email-update">
                      詳細
                    </button>
                  </div>
                  <div className="checkbox-group">
                    <input
                      type="checkbox"
                      checked={listMailSettings[16]?.active == 1}
                      onClick={(e) => {
                        let newMailList = [...listMailSettings];
                        let data = { ...newMailList[16], active: e.target.checked ? 1 : 0 };
                        newMailList[16] = data;
                        setListMailSettings(newMailList);
                      }}
                    />
                    <label>差戻</label>
                    <button onClick={() => switchToModalEmailUpdate(17)} className="btn-email-update">
                      詳細
                    </button>
                  </div>
                  <div className="checkbox-group">
                    <input
                      type="checkbox"
                      checked={listMailSettings[17]?.active == 1}
                      onClick={(e) => {
                        let newMailList = [...listMailSettings];
                        let data = { ...newMailList[17], active: e.target.checked ? 1 : 0 };
                        newMailList[17] = data;
                        setListMailSettings(newMailList);
                      }}
                    />
                    <label>再提出</label>
                    <button onClick={() => switchToModalEmailUpdate(18)} className="btn-email-update">
                      詳細
                    </button>
                  </div>
                  <div className="checkbox-group">
                    <input
                      type="checkbox"
                      checked={listMailSettings[18]?.active == 1}
                      onClick={(e) => {
                        let newMailList = [...listMailSettings];
                        let data = { ...newMailList[18], active: e.target.checked ? 1 : 0 };
                        newMailList[18] = data;
                        setListMailSettings(newMailList);
                      }}
                    />
                    <label>アラート</label>
                    <button onClick={() => switchToModalEmailUpdate(19)} className="btn-email-update">
                      詳細
                    </button>
                  </div>
                </div>
                <div className="check-item check-item--pl30">
                  <input
                    type="radio"
                    name={listMailSettings[15]?.localNum}
                    checked={
                      listMailSettings[15]?.state == 0 ||
                      listMailSettings[16]?.state == 0 ||
                      listMailSettings[17]?.state == 0 ||
                      listMailSettings[18]?.state == 0
                    }
                    onClick={(e) => {
                      let newMailList = [...listMailSettings];
                      let data15 = { ...newMailList[15], state: e.target.checked ? 0 : 1 };
                      let data16 = { ...newMailList[16], state: e.target.checked ? 0 : 1 };
                      let data17 = { ...newMailList[17], state: e.target.checked ? 0 : 1 };
                      let data18 = { ...newMailList[18], state: e.target.checked ? 0 : 1 };
                      newMailList[15] = data15;
                      newMailList[16] = data16;
                      newMailList[17] = data17;
                      newMailList[18] = data18;
                      setListMailSettings(newMailList);
                    }}
                  />
                  <label>未使用</label>
                </div>
              </div>
            </div>

            <div className="item-content flex-row row">
              <p className="text-capitalize col-2">8.&nbsp;報告書納品</p>
              <div className="d-flex justify-content-between col-10">
                <div className="d-flex justify-content-between">
                  <div className="check-item check-item--pl30 mr-2">
                    <input
                      type="radio"
                      name={listMailSettings[19]?.localNum}
                      checked={
                        listMailSettings[19]?.state == 1 &&
                        listMailSettings[20]?.state == 1 &&
                        listMailSettings[21]?.state == 1 &&
                        listMailSettings[22]?.state == 1
                      }
                      onClick={(e) => {
                        let newMailList = [...listMailSettings];
                        let data19 = { ...newMailList[19], state: e.target.checked ? 1 : 0 };
                        let data20 = { ...newMailList[20], state: e.target.checked ? 1 : 0 };
                        let data21 = { ...newMailList[21], state: e.target.checked ? 1 : 0 };
                        let data22 = { ...newMailList[22], state: e.target.checked ? 1 : 0 };
                        newMailList[19] = data19;
                        newMailList[20] = data20;
                        newMailList[21] = data21;
                        newMailList[22] = data22;
                        setListMailSettings(newMailList);
                      }}
                    />
                    <label>利用</label>
                  </div>
                  <div className="checkbox-group">
                    <input
                      type="checkbox"
                      checked={listMailSettings[19]?.active == 1}
                      onClick={(e) => {
                        let newMailList = [...listMailSettings];
                        let data = { ...newMailList[19], active: e.target.checked ? 1 : 0 };
                        newMailList[19] = data;
                        setListMailSettings(newMailList);
                      }}
                    />
                    <label>納品</label>
                    <button onClick={() => switchToModalEmailUpdate(20)} className="btn-email-update">
                      詳細
                    </button>
                  </div>
                  <div className="checkbox-group">
                    <input
                      type="checkbox"
                      checked={listMailSettings[20]?.active == 1}
                      onClick={(e) => {
                        let newMailList = [...listMailSettings];
                        let data = { ...newMailList[20], active: e.target.checked ? 1 : 0 };
                        newMailList[20] = data;
                        setListMailSettings(newMailList);
                      }}
                    />
                    <label>差戻</label>
                    <button onClick={() => switchToModalEmailUpdate(21)} className="btn-email-update">
                      詳細
                    </button>
                  </div>
                  <div className="checkbox-group">
                    <input
                      type="checkbox"
                      checked={listMailSettings[21]?.active == 1}
                      onClick={(e) => {
                        let newMailList = [...listMailSettings];
                        let data = { ...newMailList[21], active: e.target.checked ? 1 : 0 };
                        newMailList[21] = data;
                        setListMailSettings(newMailList);
                      }}
                    />
                    <label>再納品</label>
                    <button onClick={() => switchToModalEmailUpdate(22)} className="btn-email-update">
                      詳細
                    </button>
                  </div>
                  <div className="checkbox-group">
                    <input
                      type="checkbox"
                      checked={listMailSettings[22]?.active == 1}
                      onClick={(e) => {
                        let newMailList = [...listMailSettings];
                        let data = { ...newMailList[22], active: e.target.checked ? 1 : 0 };
                        newMailList[22] = data;
                        setListMailSettings(newMailList);
                      }}
                    />
                    <label>アラート</label>
                    <button onClick={() => switchToModalEmailUpdate(23)} className="btn-email-update">
                      詳細
                    </button>
                  </div>
                </div>
                <div className="check-item check-item--pl30">
                  <input
                    type="radio"
                    name={listMailSettings[19]?.localNum}
                    checked={
                      listMailSettings[19]?.state == 0 ||
                      listMailSettings[20]?.state == 0 ||
                      listMailSettings[21]?.state == 0 ||
                      listMailSettings[22]?.state == 0
                    }
                    onClick={(e) => {
                      let newMailList = [...listMailSettings];
                      let data19 = { ...newMailList[19], state: e.target.checked ? 0 : 1 };
                      let data20 = { ...newMailList[20], state: e.target.checked ? 0 : 1 };
                      let data21 = { ...newMailList[21], state: e.target.checked ? 0 : 1 };
                      let data22 = { ...newMailList[22], state: e.target.checked ? 0 : 1 };
                      newMailList[19] = data19;
                      newMailList[20] = data20;
                      newMailList[21] = data21;
                      newMailList[22] = data22;
                      setListMailSettings(newMailList);
                    }}
                  />
                  <label>未使用</label>
                </div>
              </div>
            </div>

            <div className="d-flex flex-column">
              <div className="item-content flex-row row mb-0">
                <p className="text-capitalize col-2">9.&nbsp;キャンセル</p>
                <div className="d-flex justify-content-between col-10">
                  <div className="d-flex justify-content-between">
                    <div className="check-item check-item--pl30 mr-2">
                      <input
                        type="radio"
                        name={listMailSettings[23]?.localNum}
                        checked={listMailSettings[23]?.state == 1 && listMailSettings[24]?.state == 1}
                        onClick={(e) => {
                          let newMailList = [...listMailSettings];
                          let data23 = { ...newMailList[23], state: e.target.checked ? 1 : 0 };
                          let data24 = { ...newMailList[24], state: e.target.checked ? 1 : 0 };
                          newMailList[23] = data23;
                          newMailList[24] = data24;
                          setListMailSettings(newMailList);
                        }}
                      />
                      <label>利用</label>
                    </div>
                  </div>
                  <div className="check-item check-item--pl30">
                    <input
                      type="radio"
                      name={listMailSettings[23]?.localNum}
                      checked={listMailSettings[23]?.state == 0 || listMailSettings[24]?.state == 0}
                      onClick={(e) => {
                        let newMailList = [...listMailSettings];
                        let data23 = { ...newMailList[23], state: e.target.checked ? 0 : 1 };
                        let data24 = { ...newMailList[24], state: e.target.checked ? 0 : 1 };
                        newMailList[23] = data23;
                        newMailList[24] = data24;
                        setListMailSettings(newMailList);
                      }}
                    />
                    <label>未使用</label>
                  </div>
                </div>
              </div>
              <div className="align-self-center">
                <div className="d-flex flex-row">
                  <div className="checkbox-group">
                    <input
                      type="checkbox"
                      checked={listMailSettings[23]?.active == 1}
                      onClick={(e) => {
                        let newMailList = [...listMailSettings];
                        let data = { ...newMailList[23], active: e.target.checked ? 1 : 0 };
                        newMailList[23] = data;
                        setListMailSettings(newMailList);
                      }}
                    />
                    <label>事前CAN</label>
                    <button onClick={() => switchToModalEmailUpdate(24)} className="btn-email-update">
                      詳細
                    </button>
                  </div>
                  <div className="checkbox-group ml-1">
                    <input
                      type="checkbox"
                      checked={listMailSettings[23]?.toERPFlg == 1}
                      onClick={(e) => {
                        let newMailList = [...listMailSettings];
                        let data = { ...newMailList[23], toERPFlg: e.target.checked ? 1 : 0 };
                        newMailList[23] = data;
                        setListMailSettings(newMailList);
                      }}
                    />
                    <label>{[5, 6, 7, '5', '6', '7'].includes(idRole) ? 'ManagementGroup' : 'ERP'}</label>
                  </div>
                  <div className="checkbox-group ml-1">
                    <input
                      type="checkbox"
                      checked={listMailSettings[23]?.toManagementFlg == 1}
                      onClick={(e) => {
                        let newMailList = [...listMailSettings];
                        let data = { ...newMailList[23], toManagementFlg: e.target.checked ? 1 : 0 };
                        newMailList[23] = data;
                        setListMailSettings(newMailList);
                      }}
                    />
                    <label>Partner</label>
                  </div>
                  <div className="checkbox-group ml-1">
                    <input
                      type="checkbox"
                      checked={listMailSettings[23]?.toWorkerFlg == 1}
                      onClick={(e) => {
                        let newMailList = [...listMailSettings];
                        let data = { ...newMailList[23], toWorkerFlg: e.target.checked ? 1 : 0 };
                        newMailList[23] = data;
                        setListMailSettings(newMailList);
                      }}
                    />
                    <label>Worker</label>
                  </div>
                  <div className="checkbox-group ml-1">
                    <input
                      type="checkbox"
                      checked={listMailSettings[23]?.toMonitorFlg == 1}
                      onClick={(e) => {
                        let newMailList = [...listMailSettings];
                        let data = { ...newMailList[23], toMonitorFlg: e.target.checked ? 1 : 0 };
                        newMailList[23] = data;
                        setListMailSettings(newMailList);
                      }}
                    />
                    <label>Client</label>
                  </div>
                </div>
                <div className="d-flex flex-row">
                  <div className="checkbox-group">
                    <input
                      type="checkbox"
                      checked={listMailSettings[24]?.active == 1}
                      onClick={(e) => {
                        let newMailList = [...listMailSettings];
                        let data = { ...newMailList[24], active: e.target.checked ? 1 : 0 };
                        newMailList[24] = data;
                        setListMailSettings(newMailList);
                      }}
                    />
                    <label>前日CAN</label>
                    <button onClick={() => switchToModalEmailUpdate(25)} className="btn-email-update">
                      詳細
                    </button>
                  </div>
                  <div className="checkbox-group ml-1">
                    <input
                      type="checkbox"
                      checked={listMailSettings[24]?.toERPFlg == 1}
                      onClick={(e) => {
                        let newMailList = [...listMailSettings];
                        let data = { ...newMailList[24], toERPFlg: e.target.checked ? 1 : 0 };
                        newMailList[24] = data;
                        setListMailSettings(newMailList);
                      }}
                    />
                    <label>{[5, 6, 7, '5', '6', '7'].includes(idRole) ? 'ManagementGroup' : 'ERP'}</label>
                  </div>
                  <div className="checkbox-group ml-1">
                    <input
                      type="checkbox"
                      checked={listMailSettings[24]?.toManagementFlg == 1}
                      onClick={(e) => {
                        let newMailList = [...listMailSettings];
                        let data = { ...newMailList[24], toManagementFlg: e.target.checked ? 1 : 0 };
                        newMailList[24] = data;
                        setListMailSettings(newMailList);
                      }}
                    />
                    <label>Partner</label>
                  </div>
                  <div className="checkbox-group ml-1">
                    <input
                      type="checkbox"
                      checked={listMailSettings[24]?.toWorkerFlg == 1}
                      onClick={(e) => {
                        let newMailList = [...listMailSettings];
                        let data = { ...newMailList[24], toWorkerFlg: e.target.checked ? 1 : 0 };
                        newMailList[24] = data;
                        setListMailSettings(newMailList);
                      }}
                    />
                    <label>Worker</label>
                  </div>
                  <div className="checkbox-group ml-1">
                    <input
                      type="checkbox"
                      checked={listMailSettings[24]?.toMonitorFlg == 1}
                      onClick={(e) => {
                        let newMailList = [...listMailSettings];
                        let data = { ...newMailList[24], toMonitorFlg: e.target.checked ? 1 : 0 };
                        newMailList[24] = data;
                        setListMailSettings(newMailList);
                      }}
                    />
                    <label>Client</label>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {idService === '' && (
          <div className="d-flex flex-column mb-1">
            <div className="item-content flex-row row mb-0">
              <p className="text-capitalize col-2">1.&nbsp;契約管理</p>
              <div className="d-flex justify-content-between col-10">
                <div className="d-flex justify-content-between">
                  <div className="check-item check-item--pl30 mr-2">
                    <input
                      type="radio"
                      name={listMailSettings[25]?.localNum}
                      checked={
                        listMailSettings[25]?.state == 1 &&
                        listMailSettings[26]?.state == 1 &&
                        listMailSettings[27]?.state == 1 &&
                        listMailSettings[28]?.state == 1 &&
                        listMailSettings[29]?.state == 1
                      }
                      onClick={(e) => {
                        let newMailList = [...listMailSettings];
                        let data25 = { ...newMailList[25], state: e.target.checked ? 1 : 0 };
                        let data26 = { ...newMailList[26], state: e.target.checked ? 1 : 0 };
                        let data27 = { ...newMailList[27], state: e.target.checked ? 1 : 0 };
                        let data28 = { ...newMailList[28], state: e.target.checked ? 1 : 0 };
                        let data29 = { ...newMailList[29], state: e.target.checked ? 1 : 0 };
                        newMailList[25] = data25;
                        newMailList[26] = data26;
                        newMailList[27] = data27;
                        newMailList[28] = data28;
                        newMailList[29] = data29;
                        setListMailSettings(newMailList);
                      }}
                    />
                    <label>利用</label>
                  </div>
                </div>
                <div className="check-item check-item--pl30">
                  <input
                    type="radio"
                    name={listMailSettings[25]?.localNum}
                    checked={
                      listMailSettings[25]?.state == 0 ||
                      listMailSettings[26]?.state == 0 ||
                      listMailSettings[27]?.state == 0 ||
                      listMailSettings[28]?.state == 0 ||
                      listMailSettings[29]?.state == 0
                    }
                    onClick={(e) => {
                      let newMailList = [...listMailSettings];
                      let data25 = { ...newMailList[25], state: e.target.checked ? 0 : 1 };
                      let data26 = { ...newMailList[26], state: e.target.checked ? 0 : 1 };
                      let data27 = { ...newMailList[27], state: e.target.checked ? 0 : 1 };
                      let data28 = { ...newMailList[28], state: e.target.checked ? 0 : 1 };
                      let data29 = { ...newMailList[29], state: e.target.checked ? 0 : 1 };
                      newMailList[25] = data25;
                      newMailList[26] = data26;
                      newMailList[27] = data27;
                      newMailList[28] = data28;
                      newMailList[29] = data29;
                      setListMailSettings(newMailList);
                    }}
                  />
                  <label>未使用</label>
                </div>
              </div>
            </div>
            <div className="align-self-center">
              <div className="d-flex flex-row">
                <div className="d-flex">
                  <div className="d-flex flex-column">
                    <p className="m-0">{[5, 6, 7, '5', '6', '7'].includes(idRole) ? 'ManagementGroup' : 'ERP'}</p>
                    <p className="m-0">Partner</p>
                  </div>
                  <div className="d-flex flex-column">
                    <div className="d-flex mb-1 ml-5 tenth-row">
                      <div className="checkbox-group">
                        <input
                          type="checkbox"
                          checked={listMailSettings[26]?.active == 1}
                          onClick={(e) => {
                            let newMailList = [...listMailSettings];
                            let data = { ...newMailList[26], active: e.target.checked ? 1 : 0 };
                            newMailList[26] = data;
                            setListMailSettings(newMailList);
                          }}
                        />
                        <label>差戻</label>
                        <button onClick={() => switchToModalEmailUpdate(27)} className="btn-email-update">
                          詳細
                        </button>
                      </div>
                      <div className="checkbox-group">
                        <input
                          type="checkbox"
                          checked={listMailSettings[27]?.active == 1}
                          onClick={(e) => {
                            let newMailList = [...listMailSettings];
                            let data = { ...newMailList[27], active: e.target.checked ? 1 : 0 };
                            newMailList[27] = data;
                            setListMailSettings(newMailList);
                          }}
                        />
                        <label>完了</label>
                        <button onClick={() => switchToModalEmailUpdate(28)} className="btn-email-update">
                          詳細
                        </button>
                      </div>
                    </div>
                    <div className="d-flex ml-5 tenth-row">
                      <div className="checkbox-group">
                        <input
                          type="checkbox"
                          checked={listMailSettings[28]?.active == 1}
                          onClick={(e) => {
                            let newMailList = [...listMailSettings];
                            let data = { ...newMailList[28], active: e.target.checked ? 1 : 0 };
                            newMailList[28] = data;
                            setListMailSettings(newMailList);
                          }}
                        />
                        <label>提出</label>
                        <button onClick={() => switchToModalEmailUpdate(29)} className="btn-email-update">
                          詳細
                        </button>
                      </div>
                      <div className="checkbox-group">
                        <input
                          type="checkbox"
                          checked={listMailSettings[29]?.active == 1}
                          onClick={(e) => {
                            let newMailList = [...listMailSettings];
                            let data = { ...newMailList[29], active: e.target.checked ? 1 : 0 };
                            newMailList[29] = data;
                            setListMailSettings(newMailList);
                          }}
                        />
                        <label>再提出</label>
                        <button onClick={() => switchToModalEmailUpdate(30)} className="btn-email-update">
                          詳細
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <button className="btn-email-submit mt-3" onClick={handleUpdateMail}>
          設定を保存
        </button>
      </div>
    </>
  );
};

export default compose(translate('translations'))(ModalMailSetting);
