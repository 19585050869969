import React, { useState, useEffect } from 'react';
import './style.scss';
import { ROLE, EXTRA_DATA_STATUS } from '../../helpers/constants';

const ConfirmStatus = ({ disable, role, getDataBody, orderDataDetail, orderId }) => {
  const [statusRequiredConfirm, setStatusRequiredConfirm] = useState('');
  const [statusMonitoring, setStatusMonitoring] = useState('');

  useEffect(() => {
    setStatusRequiredConfirm(orderDataDetail?.statusRequiredConfirm || '');
    setStatusMonitoring(orderDataDetail?.statusMonitoring || '');
  }, [orderDataDetail, orderId]);

  const options = ([ROLE.CENTER, ROLE.PARTNERGROUP].includes(role)) ?
  [
    {
      label: '対応待ち',
      value: 'waitingForResponse',
    },
    {
      label: '対応中',
      value: 'underAdmissionApplication',
    },
    {
      label: '対応完了',
      value: 'correspondenceCompleted',
    },
    {
      label: 'キャンセル',
      value: 'cancel',
    },

    {
      label: '対応保留',
      value: 'withdrawal',
    },
  ]:
  [
    {
      label: '対応待ち',
      value: 'waitingForResponse',
    },
    {
      label: '対応中',
      value: 'underAdmissionApplication',
    },
    {
      label: '対応完了',
      value: 'correspondenceCompleted',
    },
    {
      label: 'キャンセル依頼',
      value: 'cancelRequest',
    },
    {
      label: '対応保留',
      value: 'withdrawal',
    },
  ];
  const getStatusMonitoring = (e) => {
    setStatusMonitoring(e.target.value);
    getDataBody({ statusMonitoring: e.target.value });
  };

  const setStatusRequiredOfConfirm = (e) => {
    const value = e.target?.value;
    setStatusRequiredConfirm(value);
    getDataBody({ statusRequiredConfirm: value });
  };

  return (
    <div className="wrapper-comfirm-status">
      <div className="detail-cancel-table-wrapper detail-cancel-table-wrapper-two">
        <div className="title-absolute">前日確認ステータス</div>
        <div className="block-item">
          <div className="block-left">
            <select value={statusMonitoring} onChange={getStatusMonitoring}>
              {options.map((option, index) => (
                <option value={option.value} key={index}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
          {
            //role parner
          }
          {/* extraData?.unused */}
          {orderDataDetail?.extra === EXTRA_DATA_STATUS.UNUSED &&
            (role === ROLE.PARTNER || role === ROLE.PARTNERGROUP || role === ROLE.CENTER || role === ROLE.CLIENT) && (
              <div className="block-right">
                <span className="title">承認依頼</span>
                <span>
                  <label>必要</label>
                  <input
                    type="radio"
                    value="on"
                    checked={statusRequiredConfirm === 'on'}
                    onChange={setStatusRequiredOfConfirm}
                    name="statusRequiredConfirm"
                    disabled={disable}
                  />
                </span>
                <label>不要</label>
                <span>
                  <input
                    type="radio"
                    value="off"
                    checked={statusRequiredConfirm === 'off'}
                    onChange={setStatusRequiredOfConfirm}
                    name="statusRequiredConfirm"
                    disabled={disable}
                  />
                </span>
              </div>
            )}
          {/* extraData?.option */}
          {orderDataDetail?.extra === EXTRA_DATA_STATUS.OPTION &&
            (role === ROLE.PARTNER || role === ROLE.PARTNERGROUP || role === ROLE.CENTER || role === ROLE.CLIENT) && (
              <div className="block-right">
                <span className="title">承認依頼</span>
                <span>
                  <label>必要</label>
                  <input
                    type="radio"
                    value="on"
                    checked={statusRequiredConfirm === 'on'}
                    onChange={setStatusRequiredOfConfirm}
                    name="statusRequiredConfirm"
                    disabled={disable}
                  />
                </span>
                <label>不要</label>
                <span>
                  <input
                    type="radio"
                    value="off"
                    checked={statusRequiredConfirm === 'off'}
                    onChange={setStatusRequiredOfConfirm}
                    name="statusRequiredConfirm"
                    disabled={disable}
                  />
                </span>
                <label>確認中</label>
                <span>
                  <input
                    type="radio"
                    value="option"
                    checked={statusRequiredConfirm === 'option'}
                    onChange={setStatusRequiredOfConfirm}
                    name="statusRequiredConfirm"
                    disabled={disable}
                  />
                </span>
                {/* extraData?.option */}
                {orderDataDetail?.extra === EXTRA_DATA_STATUS.OPTION &&
                  (role === ROLE.CENTER || role === ROLE.PARTNERGROUP) && <button className="btn-item">確定</button>}
              </div>
            )}
        </div>
      </div>
    </div>
  );
};

export default ConfirmStatus;
