import React, { useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { pushToast } from '../../../../../components/Toast';
import { DEVICE_ENDPOINT } from '../../../../../constants/endpoints';
import { SCREEN_NAME } from '../../../../../helpers/constants';

const ModalAddFieldCsv = (props) => {
  const { toggle = () => {}, modal = false, id, loadListDevice = () => {} } = props;
  const [inputList, setInputList] = useState(['']);

  const onClickSubmit = () => {
    createFieldCsv({ fields: { fields: inputList , screenName : SCREEN_NAME.ITEM_MANAGEMENT }, id: id });
  };

  const createFieldCsv = async ({ fields = {}, id = '' }) => {
    try {
      const data = await DEVICE_ENDPOINT.ADD_FIELD_CSV({ fields: fields, id: id });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
      } else {
        pushToast('success', 'Create data success');
        toggle(toggle);
        setInputList(['']);
        loadListDevice();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleInputChange = (e, index) => {
    const { value } = e.target;
    const list = [...inputList];
    list[index] = value;
    setInputList(list);
  };

  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  const handleAddClick = () => {
    setInputList([...inputList, '']);
  };

  return (
    <Modal className="form-add-goods-master" isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle}></ModalHeader>
      <ModalBody>
        {inputList.map((item, i) => {
          return (
            <div className="form-group row ml-0" key={i}>
              <label className="col-sm-3 col-form-label">管理フィールド追加</label>
              <div className="col-sm-5">
                <input
                  type="text"
                  className="form-control"
                  name=""
                  onChange={(e) => handleInputChange(e, i)}
                  value={item}
                />
              </div>
              <div className="col-sm-4">
                {inputList.length !== 1 && (
                  <button className="btn-add" onClick={() => handleRemoveClick(i)}>
                    REMOVE
                  </button>
                )}
                {inputList.length - 1 === i && (
                  <button className="ml-1 btn-add" onClick={handleAddClick}>
                    ADD
                  </button>
                )}
              </div>
            </div>
          );
        })}
      </ModalBody>
      <ModalFooter>
        <Button className="btn btn-success" onClick={onClickSubmit}>
          更新
        </Button>
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalAddFieldCsv;
