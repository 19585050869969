import React from 'react';
import { Link } from 'react-router-dom';

import './style.scss';

const BreadcrumbMaintenance = ({ breadcrumb = [], fontSize = 'small', color = '' }) => {
  return (
    <div className="breadcrumb-wrapper">
      <div className="py-4 d-flex font-weight-bold w-100 align-items-center">
        <h3 className="breadcrumb-icon">
          <i className={`icon-gauge ${fontSize} ${color}`} />
        </h3>
        <Link to="/master_all">
          <h3 className={`breadcrumb-text ${fontSize} ${color}`}>マスターメンテ</h3>
        </Link>
        {breadcrumb &&
          breadcrumb.length > 0 &&
          breadcrumb.map((item, index) => (
            <Link to={item.link} key={index}>
              <h3 className={`breadcrumb-text ${fontSize}`}>
                <span> </span>
                {item.name}
              </h3>
            </Link>
          ))}
      </div>
    </div>
  );
};

export default BreadcrumbMaintenance;
