import React, { useEffect, useState } from 'react';
import { ORGANIZATION_ENDPOINT } from '../../../constants/endpoints';
import { pushToast } from '../../../components/Toast';
import HeaderMasterSetting from '../../../components/HeaderMasterSetting';
import { useSelector } from 'react-redux';
import { translate } from 'react-i18next';
import { compose } from 'redux';
import { getRoleOrganization, validateRequried } from '../../../helpers/helpers';
import LoadingNew from '../../../components/LoadingNew';
import { ROLE, SCREEN_NAME, PARAMS_REQUIRED_ORGANIZATION, PARAMS_PERMITTED_ORRGANIZATION } from '../../../helpers/constants';
import MemoHistory from '../../../components/MemoHistory';
import AutocompleteAddress from 'components/AutocompleteAddress';
import AreaSelection from 'components/AreaSelection';
import { AREAS_PERMITTED } from 'helpers/areas';
import _ from 'lodash';
import { Link, withRouter } from 'react-router-dom';
import { getDataHistoryMasterSetting } from 'actions/authentication';
import { useDispatch } from 'react-redux';
import FooterMaintenance from "components/FooterMaintenance"

const mockDataClient = {
  screenName: SCREEN_NAME.ORGANIZATION,
  screenNamePermission: SCREEN_NAME.ORGANIZATION,
  areas: {},
  isAllAnkenJoin: false
};

const EditModifyOrganization = ({ t }) => {
  const dispatch = useDispatch();
  const colorScreen = useSelector((store) => store.utils.colorMe);
  const [dataOrganization, setDataOrganization] = useState(mockDataClient);
  const [origin, setOrigin] = useState(null);
  const [postalCode1, setPostalCode1] = useState('');
  const [postalCode2, setPostalCode2] = useState('');
  const [data, setData] = useState([]);
  const [roleOrganization, setRoleOrganization] = useState('');
  const [loading, setLoading] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  let id = (process.browser && new URL(window.location.href).searchParams.get('sid')) || null;
  const newRole = localStorage.getItem('role');
  const [prefectureAreas, setPrefectureAreas] = useState({});
  const prefectureNameKanji = _.groupBy(AREAS_PERMITTED, 'prefectureNameKanji');

  useEffect(() => {
    if (newRole) {
      setRoleOrganization(getRoleOrganization({ idRole: newRole }));
    }
  }, []);

  useEffect(() => {
    if (dataOrganization) {
      setPostalCode1(dataOrganization?.postalCode?.split('-')[0] || '');
      setPostalCode2(dataOrganization?.postalCode?.split('-')[1] || '');
    }
  }, [dataOrganization]);

  const getModify = async ({ id = '' }) => {
    setLoading(true);
    try {
      if (roleOrganization === ROLE.NEOMARS) {
        const data = await ORGANIZATION_ENDPOINT.GET_DETAIL_ORGANIZATION({ id });
        if (data) {
          setData(data?.data);
          const dataHandler = _.flatten(Object.values(data?.data?.areas || {})).map(e => {
            return !_.isEmpty(data?.data?.prefectureAreas[e]) ? {[e]: data?.data?.prefectureAreas[e]} : {[e]: _.compact(_.map(prefectureNameKanji[e] || [], 'cityNameKanji'))}
          });
          const newPrefectureAreas = _.isEmpty(dataHandler) ? {} : Object.assign(...dataHandler);
          setPrefectureAreas(newPrefectureAreas || {});
        }
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  useEffect(() => {
    if (data) {
      const newData = {...dataOrganization, ...data};
      setDataOrganization({...newData, areas: newData?.areas || {}});
      setOrigin(newData?.abbreviation);
    }
  }, [data]);

  useEffect(() => {
    if (id && roleOrganization) {
      getModify({ id });
    }
  }, [id, roleOrganization]);

  useEffect(() => {
    if(dataOrganization) {
      validateRequried({data: dataOrganization, arrField: PARAMS_REQUIRED_ORGANIZATION.filter(e => e !== 'code'), onDisabled});
    }
  }, [dataOrganization]);

  const onDisabled = ({status}) => {
    setIsDisable(status);
  };

  const handleChangeText = (e) => {
    let name = e.target.name;
    if(name === 'abbreviation' && !!origin) return false

    let newData = { ...dataOrganization };
    newData = { ...newData, [name]: e.target.value };
    setDataOrganization(newData);
  };

  const onChangePostalCode = (e) => {
    if (e.target.name === 'postalCode1') {
      setPostalCode1(e.target.value);
      let newData = { ...dataOrganization };
      newData = { ...newData, postalCode: e.target.value + '-' + postalCode2 };
      setDataOrganization(newData);
    } else if (e.target.name === 'postalCode2') {
      setPostalCode2(e.target.value);
      let newData = { ...dataOrganization };
      newData = { ...newData, postalCode: postalCode1 + '-' + e.target.value };
      setDataOrganization(newData);
    }
  };

  const updateOrganization = async ({ body, id = '' }) => {
    setLoading(true);
    try {
      const data = await (ORGANIZATION_ENDPOINT.UPDATE_ORGANIZATION({ body, id }));
      if (data && data.code !== 0) {
        pushToast('error', data?.message?.extra || data?.message?.errorMessage);
      } else {
        pushToast('success', 'Update info success');
        dispatch(getDataHistoryMasterSetting({ logId: '', screenName: SCREEN_NAME.ORGANIZATION }));
        getModify({ id });
      }
      setLoading(false);
    } catch (errorMessage) {
      console.log('errorMessage: ', errorMessage);
      setLoading(false);
    }
  };

  const onClickSubmit = () => {
    if (roleOrganization === ROLE.NEOMARS && id && dataOrganization) {
      const body = {
        ...dataOrganization, prefectureAreas, officialName: dataOrganization?.companyName, organizationName: dataOrganization?.companyName, updateTitle: `更新 ${dataOrganization.typeRole === 'management-group' ? 'ManagementGroup_編集' : 'ERP_編集'} ${dataOrganization?.officialNameKana || ''}`
      };
      updateOrganization({ body: _.pick(body, PARAMS_PERMITTED_ORRGANIZATION), id });
    }
  };

  const onChangePrefectureAreas = (data) => {
    setPrefectureAreas(data);
  }

  const onChangeAreas = (areas) => {
    const newData = { ...dataOrganization, areas };
    setDataOrganization(newData);
    const values = _.flatten(Object.values(newData?.areas || {}));
    const dataHandler = values.map(e => {
      return Object.keys(prefectureAreas || {}).includes(e) ? {[e]: prefectureAreas[e]} : {[e]: _.compact(_.map(prefectureNameKanji[e] || [], 'cityNameKanji'))}
    })
    const newPrefectureAreas = _.isEmpty(dataHandler) ? {} : Object.assign(...dataHandler);
    setPrefectureAreas(newPrefectureAreas);
  }

  return (
    <div className="wrapper-modify-organization-master">
      <HeaderMasterSetting checkName={dataOrganization.typeRole === 'management-group' ? '/edit_managementGroup_organizations' : '/edit_erp_organizations'} color={colorScreen?.code} colorText={colorScreen?.colorText} />
      <LoadingNew loading={loading} />
      {/* <Header setFontSizeAll={setFontSizeAll}/> */}
      <div className="wrapper-main ">
        <div className="body-modify-organization">
          <div className="modifly__organization">
            <table className="table__modifly">
              <tr>
                <th colSpan="4" className="table__modifly--color text-left pl-1">
                  {dataOrganization?.typeRole === 'management-group' ? 'ManagementGroup' : 'ERP'}
                  <button className="btn modifly__organization--button modifly__organization--submit ml-5">基本情報</button>
                  <Link to="/contract" className="btn modifly__organization--button modifly__organization--submit">契約情報</Link>
                </th>
              </tr>
              <tr>
                <th className="table__modifly--color modifly__organization--pd-unset">CloudID</th>
                <td className="modifly__organization--pd-unset">
                  <input
                    type="text"
                    className="w-100"
                    disabled={roleOrganization === ROLE.PARTNERGROUP}
                    value={id}
                    id="code"
                    name="code"
                  />
                </td>
                <th className="table__modifly--color modifly__organization--pd-unset">募集案件参加</th>
                <td className="modifly__organization--pd-unset">
                  <input
                    type="checkbox"
                    className="w-10"
                    checked={dataOrganization?.isAllAnkenJoin}
                    onClick={() => setDataOrganization({ ...dataOrganization , isAllAnkenJoin: !dataOrganization?.isAllAnkenJoin })}
                  />
                </td>
              </tr>

              {/* <tr>
                <th className="table__modifly--color modifly__organization--pd-unset">組織名</th>
                <td colSpan="3" className="modifly__organization--pd-unset">
                  <input
                    type="text"
                    className="w-100 table__modifly--input"
                    name="organizationName"
                    id="organizationName"
                    value={dataOrganization?.organizationName}
                    onChange={handleChangeText}
                  />
                </td>
              </tr> */}
              <tr>
                <th className="table__modifly--color modifly__organization--pd-unset">会社名</th>
                <td className="modifly__organization--pd-unset">
                  <input
                    type="text"
                    className="w-100 table__modifly--input"
                    name="companyName"
                    id="companyName"
                    value={dataOrganization?.companyName}
                    onChange={handleChangeText}
                  />
                </td>
                <th className="table__modifly--color modifly__organization--pd-unset">電話番号</th>
                <td className="modifly__organization--pd-unset">
                  <input
                    type="text"
                    className="w-100 table__modifly--input"
                    name="phoneNumber"
                    id="phoneNumber"
                    value={dataOrganization?.phoneNumber}
                    onChange={handleChangeText}
                  />
                </td>
              </tr>
              <tr>
                <th className="table__modifly--color modifly__organization--pd-unset">フリガナ</th>
                <td className="modifly__organization--pd-unset" colSpan="3">
                  <input
                    type="text"
                    className="w-100 table__modifly--input"
                    name="officialNameKana"
                    id="officialNameKana"
                    value={dataOrganization?.officialNameKana}
                    onChange={handleChangeText}
                  />
                </td>
              </tr>
              <tr>
                <th className="table__modifly--color modifly__organization--pd-unset">システム表示名称</th>
                <td className="modifly__organization--pd-unset">
                  <input
                    type="text"
                    className="w-100 table__modifly--input"
                    name="abbreviationName"
                    id="abbreviationName"
                    value={dataOrganization?.abbreviationName}
                    onChange={handleChangeText}
                  />
                </td>
                <th className="table__modifly--color modifly__organization--pd-unset">システム表示略称（英2～4文字）</th>
                <td className="modifly__organization--pd-unset">
                  <input
                    type="text"
                    className="w-100 table__modifly--input"
                    name="abbreviation"
                    id="abbreviation"
                    maxLength="4"
                    value={dataOrganization?.abbreviation}
                    disabled={!!origin}
                    onChange={handleChangeText}
                  />
                </td>
              </tr>
              <tr>
                <th className="table__modifly--color modifly__organization--pd-unset">代表者</th>
                <td className="modifly__organization--pd-unset">
                  <input
                    type="text"
                    className="w-100 table__modifly--input"
                    name="name"
                    id="name"
                    value={dataOrganization?.name}
                    onChange={handleChangeText}
                  />
                </td>
                <th className="table__modifly--color modifly__organization--pd-unset">フリガナ</th>
                <td className="modifly__organization--pd-unset">
                  <input
                    type="text"
                    className="w-100 table__modifly--input"
                    name="nameKana"
                    id="nameKana"
                    value={dataOrganization?.nameKana}
                    onChange={handleChangeText}
                  />
                </td>
              </tr>
              <tr>
                <th className="table__modifly--color modifly__organization--pd-unset">郵便番号</th>
                <td className="modifly__organization--pd-unset" style={{ width: '26%' }}>
                  <div className="d-flex w-100">
                    <input
                      className="table__modifly--input w-50 mr-1"
                      type="number"
                      name="postalCode1"
                      maxLength={4}
                      value={postalCode1}
                      onChange={onChangePostalCode}
                    />
                    <input
                      className="table__modifly--input w-50"
                      type="number"
                      name="postalCode2"
                      maxLength="4"
                      value={postalCode2}
                      onChange={onChangePostalCode}
                    />
                  </div>
                </td>
                <th className="table__modifly--color modifly__organization--pd-unset">都道府県</th>
                <td className="modifly__organization--pd-unset">
                  <select
                    className="w-100 table__modifly--input"
                    name="prefecture"
                    value={dataOrganization?.prefecture}
                    onChange={handleChangeText}
                  >
                    <option value=""></option>
                    <option value="北海道">北海道</option>
                    <option value="青森県">青森県</option>
                    <option value="岩手県">岩手県</option>
                    <option value="宮城県">宮城県</option>
                    <option value="秋田県">秋田県</option>
                    <option value="山形県">山形県</option>
                    <option value="福島県">福島県</option>
                    <option value="東京都">東京都</option>
                    <option value="神奈川県">神奈川県</option>
                    <option value="埼玉県">埼玉県</option>
                    <option value="千葉県">千葉県</option>
                    <option value="茨城県">茨城県</option>
                    <option value="栃木県">栃木県</option>
                    <option value="群馬県">群馬県</option>
                    <option value="山梨県">山梨県</option>
                    <option value="新潟県">新潟県</option>
                    <option value="長野県">長野県</option>
                    <option value="石川県">石川県</option>
                    <option value="富山県">富山県</option>
                    <option value="福井県">福井県</option>
                    <option value="愛知県">愛知県</option>
                    <option value="岐阜県">岐阜県</option>
                    <option value="静岡県">静岡県</option>
                    <option value="三重県">三重県</option>
                    <option value="大阪府">大阪府</option>
                    <option value="兵庫県">兵庫県</option>
                    <option value="京都府">京都府</option>
                    <option value="滋賀県">滋賀県</option>
                    <option value="奈良県">奈良県</option>
                    <option value="和歌山県">和歌山県</option>
                    <option value="鳥取県">鳥取県</option>
                    <option value="島根県">島根県</option>
                    <option value="岡山県">岡山県</option>
                    <option value="広島県">広島県</option>
                    <option value="山口県">山口県</option>
                    <option value="徳島県">徳島県</option>
                    <option value="香川県">香川県</option>
                    <option value="愛媛県">愛媛県</option>
                    <option value="高知県">高知県</option>
                    <option value="福岡県">福岡県</option>
                    <option value="佐賀県">佐賀県</option>
                    <option value="長崎県">長崎県</option>
                    <option value="熊本県">熊本県</option>
                    <option value="大分県">大分県</option>
                    <option value="宮崎県">宮崎県</option>
                    <option value="鹿児島県">鹿児島県</option>
                    <option value="沖縄県">沖縄県</option>
                  </select>
                </td>
              </tr>
              <tr>
                <th className="table__modifly--color modifly__organization--pd-unset">住所</th>
                <td className="modifly__organization--pd-unset" colSpan="3">
                  <AutocompleteAddress
                    address={dataOrganization?.address}
                    onChange={(address) => {
                      setDataOrganization({ ...dataOrganization, address });
                    }}
                    name="address"
                    onSelectAddress={({ address, latitude, longitude }) => {
                      setDataOrganization({ ...dataOrganization, address, latitude, longitude });
                    }}
                  />
                </td>
              </tr>
              <tr>
                <th className="table__modifly--color modifly__organization--pd-unset">フリガナ</th>
                <td className="modifly__organization--pd-unset" colSpan="3">
                  <input
                    type="text"
                    className="w-100 table__modifly--input"
                    name="addressKana"
                    value={dataOrganization?.addressKana}
                    onChange={handleChangeText}
                  />
                </td>
              </tr>
                <tr>
                  <th className="table__modifly--color modifly__organization--pd-unset">緯度</th>
                  <td className="modifly__organization--pd-unset">
                    <input
                      type="text"
                      className="w-100 table__modifly--input"
                      value={dataOrganization?.latitude}
                      name="latitude"
                      id="latitude"
                    />
                  </td>
                  <th className="table__modifly--color modifly__organization--pd-unset">経度</th>
                  <td className="modifly__organization--pd-unset">
                    <input
                      type="text"
                      className="w-100 table__modifly--input"
                      value={dataOrganization?.longitude}
                      name="longitude"
                      id="longitude"
                    />
                  </td>
                </tr>
                <tr>
                  <th className="table__modifly--color modifly__organization--pd-unset">対応エリア設定</th>
                  <td className="table__modifly--background modifly__organization--pd-unset" colSpan="3">
                    <AreaSelection
                      activeArea={{ ...dataOrganization?.areas }}
                      prefectureAreas={prefectureAreas}
                      onChangePrefectureAreas={onChangePrefectureAreas}
                      onChange={(areas) => { onChangeAreas(areas) }}
                    />
                  </td>
                </tr>
            </table>
            <div className="text-center">
              <button
                className="btn modifly__organization--button modifly__organization--submit--update"
                onClick={onClickSubmit}
                disabled={isDisable}
              >
                更新
              </button>
            </div>
          </div>
          <div className="pt-2">
            <MemoHistory colors={colorScreen?.code} colorText={colorScreen?.colorText} logId={id} screenName={SCREEN_NAME.ORGANIZATION}/>
          </div>
          <FooterMaintenance />
        </div>
      </div>
    </div>
  );
};

export default compose(translate('translations'))(EditModifyOrganization);
