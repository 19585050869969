import React, { useEffect, useState } from 'react';
import { pushToast } from '../../../../../components/Toast';
import { ORDER } from '../../../../../constants/endpoints';
import { getRole } from '../../../../../helpers/helpers';
import Loading from 'components/Loading/Loading';
import 'assets/scss/page/AnkenMasterNew/TableAddRole.scss';
import { getDataHistoryMasterSetting } from 'actions/authentication';
import { useDispatch } from 'react-redux';
import { SCREEN_NAME } from '../../../../../helpers/constants';

const TableAddRole = ({
  ModalAddRoleNextChild = () => {},
  routeList,
  namePermission,
  ModalDetailSetting = () => {},
  idProvide,
  statusBeforeConfirm,
}) => {
  const dispatch = useDispatch();
  const [ListHeaderRole, setListHeaderRoleData] = useState({});
  const [menuId, setMenuId] = useState('');
  const [loading, setLoading] = useState(false);
  const checkRole = localStorage.getItem('role');

  useEffect(() => {
    getListHeaderRole();
  }, []);

  useEffect(() => {
    getListHeaderRole();
  }, [idProvide]);

  const getListHeaderRole = async () => {
    try {
      setLoading(true);
      const data = await ORDER.GET_LIST_HEADER_FOLLOW_ROLE({
        route: routeList,
        idProvide: idProvide,
      });
      setListHeaderRoleData(data?.data);
      setMenuId(data?.data?.menuId);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const handleOnChange = (e, elm) => {
    const { value } = e.target;
    var isTrueSet = value === 'true';
    const ListHeaderRoleDataTmp =
      ListHeaderRole &&
      ListHeaderRole.data &&
      ListHeaderRole.data?.map((item) => {
        if (item.name === elm.name) {
          return { ...item, active: isTrueSet };
        }
        return item;
      });

    setListHeaderRoleData({ data: ListHeaderRoleDataTmp });
  };

  const handleUpdateListRoleChild = async (dataUpdate) => {
    try {
      setLoading(true);
      const data = await ORDER.UPDATE_LIST_HEADER_FOLLOW_ROLE(dataUpdate, namePermission, menuId, idProvide);
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
      } else {
        pushToast('success', 'Update success');
        dispatch(getDataHistoryMasterSetting({ logId: '', screenName: SCREEN_NAME.MY_CORP }));
      }
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const onUpdateListRoleChild = () => {
    handleUpdateListRoleChild({...ListHeaderRole , updateTitle : '保存 メニュー表示設定'});
  };

  const renderTableInfo = (ListHeaderRole = []) => {
    return (
      <table className="table table-bordered">
        <thead>
          <tr>
            <th colSpan="3">利用画面設定</th>
          </tr>
        </thead>
        <tbody>
          {ListHeaderRole?.data
            ?.filter(
              (item) =>
                item.name !== 'equipment-management' && item.name !== (statusBeforeConfirm === false && `tomorrow_info`)
            )
            .map((item, index) => {
              const arrRole = getRole({ idRole: item.roles[0] })?.toLowerCase();
              return (
               item.name === 'orderGroup' ? '' : (
                  <tr key={index} className={routeList === '11' && item.name}>
                    <td>{item.label}</td>
                    <td>
                      <span className="input-check">
                        <input
                          type="radio"
                          name={`active-${index}`}
                          value={true}
                          checked={item.active}
                          onChange={(e) => handleOnChange(e, item)}
                        />
                        <label>表示</label>
                      </span>
                      <span className="input-check">
                        <input
                          type="radio"
                          name={`active-${index}`}
                          value={false}
                          checked={!item.active}
                          onChange={(e) => handleOnChange(e, item)}
                        />
                        <label>非表示</label>
                      </span>
                    </td>
                    <td>
                      <div className="form-btn-modal">
                        <button className="btn-customize-modal" onClick={() => ModalAddRoleNextChild(arrRole, item.name)}>
                        表示項目設定
                        </button>
                        <button className="btn-customize-modal" onClick={() => ModalDetailSetting(arrRole, item.name)}>
                        詳細設定
                        </button>
                      </div>
                    </td>
                  </tr>
                )
              );
            })}
        </tbody>
      </table>
    );
  };

  return (
    <div className="table-info-company ">
      <Loading loading={loading} />
      {renderTableInfo(ListHeaderRole)}
      <button onClick={onUpdateListRoleChild} className="btn-submit">
        保存
      </button>
    </div>
  );
};

export default TableAddRole;
