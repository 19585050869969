import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import '../../style.scss';

const ButtonChecked = ({ status, onChange = () => { }, state = null , index, color}) => {
    const [active, setActive] = useState(status);

    const onClickButon = () => {
        onChange({ status: !active , index, state });
        setActive(!active);
    };

    return (
        <div className="item-button">
            <div className="btn-group btn-group-toggle" data-toggle="buttons">
                <button style={{ backgroundColor: `${status ? color : '#f8f9fa'}`}} onClick={() => onClickButon()}>
                    表示
                </button>
                <button style={{ backgroundColor: `${!status ? color : '#f8f9fa'}`}} onClick={() => onClickButon()}>
                    非表示
                </button>
            </div>
        </div>
    );
};

export default compose(withRouter)(ButtonChecked);
