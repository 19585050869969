import React, { useState, useEffect } from 'react';
import { STAFF_ENDPOINT } from '../../../constants/endpoints';
import { pushToast } from '../../../components/Toast';
import { SCREEN_NAME } from '../../../helpers/constants';
import HeaderMasterSetting from 'components/HeaderMasterSetting';
import { useSelector } from 'react-redux';
import Loading from 'components/Loading/Loading';

const DeleteStaff = ({ submit }) => {
    const [staffData, setStaffData] = useState('');
    const [data, setData] = useState([]);
    const colorScreen = useSelector((store) => store.utils.colorMe);
    let id = (process.browser && new URL(window.location.href).searchParams.get('sid')) || null;
    const [loading, setLoading] = useState(false);

    const updateStaffMaster = async ({ body, id = '' }) => {
        try {
            const data = await STAFF_ENDPOINT.DELETE_STAFF({ body: body, id: id });
            if (data && data.code !== 0) {
                if (data && data.message && data.message.extra) {
                    pushToast('error', data?.message?.extra);
                } else {
                    pushToast('error', data?.message?.errorMessage);
                }
            } else {
                pushToast('success', 'delete success');
                window.location.href = (`/staff_master`);
            }
        } catch (errorMessage) {
            console.log('errorMessage: ', errorMessage);
        }
    };


    const Create = () => {
        if (staffData && id) {
            updateStaffMaster({ body: staffData, id: id });
        }
    };

    const getStaff = async ({ id }) => {
        setLoading(true);
        try {
            const data = await STAFF_ENDPOINT.GET_DETAIL({ id: id });
            if (data) {
                setData(data.data);
                let newData = { ...data.data , screenName : SCREEN_NAME.STAFF_MASTER};
                setStaffData(newData);
                setLoading(false);
            }
        } catch (e) {
            console.log(e);
            setLoading(false);
        }
    };

    useEffect(() => {
        if (id) {
            getStaff({ id: id });
        }
    }, [id]);

    return (
        <div className="main-branch-master">
        <Loading loading={loading} />
        <HeaderMasterSetting checkName={'/staff_master'} color={colorScreen?.code} colorText={colorScreen?.colorText} />
            <div className="body-wrapper mb-1">
                    <div align="center" className="search">
                        <div>
                            <table width="500" border="0" align="center" cellPadding="1" cellSpacing="1" id="result"
                                className="discribeTable">
                                <colgroup>
                                    <col width="200" />
                                    <col width="150" />
                                    <col width="150" />

                                </colgroup>
                                <tbody>
                                    <tr>
                                        <th scope="row">パートナー名</th>
                                        <td colSpan="2">
                                            {staffData?.branchName}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">作業員名</th>
                                        <td colSpan="2">
                                            {staffData?.name}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">作業員連絡先</th>
                                        <td colSpan="2">
                                            {staffData?.contact}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">作成日</th>
                                        <td colSpan="2">
                                            {staffData?.createdAt}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div align="center" className="margin_top10 mt-2">
                                <button className={`cancel_submit submitbutton btn btnS ${submit ? 'btnSuccess' : ''}`}
                                    onClick={() => Create()}
                                >消す
                                    </button>

                            </div>
                        </div>
                    </div>
            </div>
        </div>
    )
};

export default DeleteStaff;
