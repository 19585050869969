import React, { useState, useEffect } from 'react';
import './style.scss';
import { Collapse } from 'reactstrap';
import { ANKEN_MASTER_NEW } from 'constants/endpoints';
import _ from 'lodash';

const DetailTreeDocumentSetting = ({ orderId, title = 'ドキュメント情報', order, type='reportInformation' }) => {
  let organizationId = (process.browser && new URL(window.location.href).searchParams.get('organizationId')) || '';
  const [isOpen, setIsOpen] = useState(false);
  const [listTree, setListTree] = useState({});
  const [showButtonUpload, setShowButtonUpload] = useState(false);
  const [idDocument, setIdDocumment] = useState();
  const [data, setData] = useState([]);

  const onClickShow = () => {
    setIsOpen(!isOpen);
  };

  const handleData = ({ isType = '', elm, typeDocument }) => {
    const newData = [];
    (listTree?.orders || []).map(e => {
      if(typeDocument === 'serviceId' && e?.[typeDocument] !== elm?.[typeDocument]) return;

      const document = (isType && e?.[type+elm?.timestamp]) || (isType === false && elm?.documentTitle) || (isType === '' && e?.[elm?.timestamp]);
      newData.push({visitDate: e?.visitDate, document, settingCompletionResult: e?.settingCompletionResult, isType});
    });
    setData(newData);
  };

  const onClickShowButton = ({ elm, typeDocument }) => {
    if(elm?.uploadFromTo?.includes(1)){
      handleData({ isType: false, elm, typeDocument });
    } else if(elm?.uploadFromTo?.includes(2)){
      handleData({ isType: true, elm, typeDocument });
    } else if(elm?.uploadFromTo?.includes(6)){
      handleData({ isType: true, elm, typeDocument });
    } else {
      handleData({ elm, typeDocument });
    }
    if (elm?.documentSettingId === idDocument) {
      setShowButtonUpload(false);
      setIdDocumment('');
    } else {
      setIdDocumment(elm?.documentSettingId);
      setShowButtonUpload(true);
    }
  };

  const getTreeDocumentSetting = async ({ orderId = '' }) => {
    try {
      const data = await ANKEN_MASTER_NEW.GET_TREE_DOCUMENT_DETAIL_ORDER({ orderId, organizationId });
      if (data) {
        setListTree(data?.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getTreeDocumentSetting({ orderId });
  }, []);

  const itemTree = (array) => {
    return (
      <ul>
        {array &&
          array.map((item, index) => {
            return (
              <li key={index}>
                <p>
                  {item.serviceName}
                  {item?.materialType?.map((elm, i) => {
                    return (
                      <button
                        style={{
                          backgroundColor: colorBtn(elm),
                          border:
                            elm.materialTypeName === '共通資料' && !elm?.documentTitle?.url && '#0e5a8d 2px dotted',
                        }}
                        onClick={() =>
                          onClickShowButton({ elm, typeDocument: 'serviceId' })
                        }
                        key={i}
                      >
                        {elm?.numberOfMaterials}
                      </button>
                    );
                  })}
                </p>
              </li>
            );
          })}
      </ul>
    );
  };

  const colorBtn = (item) => {
    switch (item.materialTypeName) {
      case '共通資料':
        return '#fec001';
      case '展開資料':
        return '#002060';
      case '作成資料':
        return '#0eb0f0';
      case '回収資料':
        return '#0fb050';
      default:
        return '#387ca9';
    }
  };

  return (
    <div className="tree-wrapper">
      <div className="form-wrapper">
        <div className="title-absolute cursor-pointer" onClick={onClickShow}>
          {title}{' '}
          {isOpen ? <i className="fas fa-arrow-circle-up ml-2"></i> : <i className="fas fa-arrow-circle-down ml-2"></i>}
        </div>
        <Collapse isOpen={isOpen}>
          <div className="form-tree">
            <div className="form-tree-group">
              <div className="d-flex flex-direction-row">
                <div className="title-project">{listTree?.projectName}</div>
                {listTree?.materialType?.map((item, index) => {
                  return (
                    <button
                      style={{
                        backgroundColor: colorBtn(item),
                        border:
                          item.materialTypeName === '共通資料' && !item?.documentTitle?.url && '#0e5a8d 2px dotted',
                      }}
                      onClick={() =>
                        onClickShowButton({ elm: item, typeDocument: 'projectId' })
                      }
                      key={index}
                    >
                      {item?.numberOfMaterials}
                    </button>
                  );
                })}
              </div>
              {listTree?.products?.length > 0 && (
                <ul>
                  {listTree &&
                    listTree?.products.map((item, index) => {
                      return (
                        <li key={index}>
                          <p>
                            {item.productName}
                            {item?.materialType?.map((elm, i) => {
                              return (
                                <button
                                  style={{
                                    backgroundColor: colorBtn(elm),
                                    border:
                                      elm.materialTypeName === '共通資料' &&
                                      !elm?.documentTitle?.url &&
                                      '#0e5a8d 2px dotted',
                                  }}
                                  onClick={() =>
                                    onClickShowButton({ elm, typeDocument: 'productId' })
                                  }
                                  key={i}
                                >
                                  {elm?.numberOfMaterials}
                                </button>
                              );
                            })}
                          </p>

                          {item?.services?.length > 0 ? itemTree(item?.services) : ''}
                        </li>
                      );
                    })}
                </ul>
              )}
              {showButtonUpload && (
                <div className="text-center mb-2">
                  {
                    data?.map((e, index) => {
                      if(index !== 0 && e.isType === false) return;

                      return(
                        <div className="row table--pdb10" key={index}>
                          <div className="col-3 text-right">{e.isType === false ? '' : e?.visitDate || ''}</div>
                          <div  className="col-2">{e.isType === false ? '' : e?.settingCompletionResult?.pullDownDisplay || ''}</div>
                          <input className="input-modal col-3" type="text" disabled value={e?.document?.name || ''} />
                          <button
                            className="btn-modal col-3"
                            disabled={!e?.document?.key}
                            onClick={() => window.open(`${e?.document?.key || ''}`)}
                          >
                            ダウンロード
                          </button>
                        </div>
                      )
                    })
                  }

                </div>
              )}
            </div>
          </div>
        </Collapse>
      </div>
    </div>
  );
};

export default DetailTreeDocumentSetting;
