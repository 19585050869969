import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { DATE_JP_FORMAT, DATE_JP_RENDER, SCREEN_NAME } from '../../../../../helpers/constants';
import 'assets/scss/page/contract/Credit.scss';

const Credit = ({
  recruitmentContractId,
  dataDetailCredit,
  onHandleSubmit,
  stepPermission,
  submitUpload,
  recruitmentEstimateId,
  dataUploadFile,
  removeFileStep,
}) => {
  const [selectedName, setSelectedName] = useState('');
  const [selectedFile, setSelectedFile] = useState([]);
  const [dataCredit, setDataCredit] = useState({});
  const [listFileCredit, setListFileCredit] = useState([]);
  const optionData = ['未対応', '対応中', '対応完了'];
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (dataDetailCredit) {
      setDataCredit({
        comment: dataDetailCredit?.comment || dataCredit?.comment || '',
        creditInformation: dataDetailCredit?.creditInformation || dataCredit?.creditInformation || '',
        state: dataDetailCredit?.state || dataCredit?.state || '',
        endDay: dataDetailCredit?.endDay || dataCredit?.endDay || '',
      });
      setListFileCredit(dataDetailCredit?.files);
    }
  }, [dataDetailCredit]);

  useEffect(() => {
    if (dataUploadFile?.credit) {
      setListFileCredit(dataUploadFile?.credit?.files);
      setSelectedFile([]);
    }
  }, [dataUploadFile]);

  const onChangeHandler = async (e) => {
    const tmpFile = e?.target?.files;
    const base64Promises = [];
    Object.keys(tmpFile).map((item, index) => {
      base64Promises.push(toBase64(tmpFile[index]));
    });
    const base64s = await Promise.all(base64Promises);
    const tmpFileResult = Object.keys(tmpFile).map((item, index) => {
      return {
        fileName: tmpFile[index].name,
        buffer: base64s[index],
      };
    });
    setSelectedFile(tmpFileResult);
    setSelectedName(tmpFileResult.map((item) => item.fileName));
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handleUploadFile = () => {
    const body = {
      files: selectedFile,
      step: 0,
      screenName: SCREEN_NAME.CONTRACT_MANAGEMENT,
      contractEstimateId: recruitmentEstimateId,
    };
    submitUpload(body);
    setSelectedName('');
    document.getElementById('upload-csv-credit').value = '';
  };

  const handleDeleteFile = (key) => {
    const body = {
      screenName: SCREEN_NAME.CONTRACT_MANAGEMENT,
      step: 0,
      key,
    };
    removeFileStep(body);
  };

  const onChangeTextCredit = (e) => {
    const { value, name } = e.target;
    setDataCredit({ ...dataCredit, [name]: value });
  };

  const onChangeDateCredit = (date, text) => {
    if (date) {
      let newData = { ...dataCredit, [text]: moment(new Date(date)).format(DATE_JP_FORMAT) };
      setDataCredit(newData);
    } else {
      let newData = { ...dataCredit, [text]: '' };
      setDataCredit(newData);
    }
  };

  const onCreateAndUpdate = (textSubmit, status, updateTitle) => {
    onHandleSubmit(
      status === 'statusDone'
        ? { ...dataCredit, screenName: SCREEN_NAME.CONTRACT_MANAGEMENT, isConfirm: true }
        : { ...dataCredit, screenName: SCREEN_NAME.CONTRACT_MANAGEMENT },
      textSubmit,
      updateTitle
    );
  };

  const onChangeOptionEstimates = (e) => {
    const { value, name } = e.target;
    setDataCredit({ ...dataCredit, [name]: value });
  };

  return (
    <div className="detail-registration-contract">
      <div className="detail-basic-table-wrapper">
        <div className="title-absolute">与信</div>
        <div className="row">
          <div className="col-3 text-center">
            <label className="label-contract">与信情報</label>
          </div>
          <div className="col-9">
            <input
              disabled={disabled}
              className={`${disabled ? 'input-contract-disabled' : ''} input-contract mr-2`}
              name="creditInformation"
              value={dataCredit?.creditInformation}
              onChange={onChangeTextCredit}
              type="text"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-3 text-center">
            <label className="label-contract">ファイル</label>
          </div>
          <div className="col-9">
            <div className="row">
              <div className="col-3">
                <label
                  htmlFor="upload-csv-credit"
                  className={`${disabled ? 'button-disable-contract' : ''} button-contract`}
                >
                  ファイル選択
                </label>
                <input
                  type="file"
                  name="file"
                  id="upload-csv-credit"
                  disabled={disabled}
                  multiple
                  className="d-none input-contract"
                  onChange={onChangeHandler}
                />
              </div>
              <div className="col-6">
                <input
                  type="text"
                  className={`${disabled ? 'input-contract-disabled' : ''} input-contract`}
                  placeholder={selectedName}
                  disabled
                />
              </div>
              <div className="col-3">
                {!disabled ? (
                  <label className="button-contract" onClick={() => handleUploadFile()}>
                    アップロード
                  </label>
                ) : (
                  <label className={`${disabled ? 'button-disable-contract' : ''} button-contract`}>アップロード</label>
                )}
              </div>
            </div>
          </div>
        </div>
        {listFileCredit?.map((item, index) => {
          return (
            <div key={index} className="row">
              <div className="col-3"></div>
              <div
                className={`${disabled ? 'disabled-text' : ''} col-4 text-left cursor-pointer`}
                onClick={() => window.open(`${item?.url}`)}
              >
                {item.name}
              </div>
              <div className={`${disabled ? 'disabled-text' : ''} col-4`} style={{ textAlign: 'end' }}>
                {item.timeUpload}
              </div>
              <div
                className={`${disabled ? 'disabled-text' : ''} col-1 cursor-pointer`}
                onClick={() => handleDeleteFile(item?.key)}
              >
                [X]
              </div>
            </div>
          );
        })}

        <div className="row">
          <div className="col-3 text-center">
            <label className="label-contract">コメント欄</label>
          </div>
          <div className="col-9">
            <input
              disabled={disabled}
              className={`${disabled ? 'input-contract-disabled' : ''} input-contract`}
              type="text"
              name="comment"
              value={dataCredit?.comment}
              onChange={onChangeTextCredit}
            />
          </div>
          <div className="col-3 text-center">
            <label className="label-contract">状態</label>
          </div>
          <div className="col-3">
            <select
              name="state"
              disabled={disabled}
              className="text-input select--result select--w100"
              onChange={onChangeOptionEstimates}
              value={dataCredit?.state}
            >
              <option defaultValue=""></option>
              {optionData?.map((it, i) => {
                return (
                  <option value={it} key={i}>
                    {it}
                  </option>
                );
              })}
            </select>
            {/* <input
              type="text"
              className="input-contract"
              name="state"
              value={dataCredit?.state}
              onChange={onChangeTextCredit}
            ></input> */}
          </div>
          <div className="col-3">
            <label className="label-contract">完了日</label>
          </div>
          <div className="col-3">
            <DatePicker
              disabled={disabled}
              selected={!_.isEmpty(dataCredit?.endDay) ? new Date(dataCredit?.endDay) : ''}
              onChange={(date) => onChangeDateCredit(date, 'endDay')}
              locale="ja"
              dateFormat={DATE_JP_RENDER}
              dateFormatCalendar="yyyy年 MM月"
              className={`${disabled ? 'input-contract-disabled' : ''} input-contract`}
            />
          </div>
        </div>
        <div className="justify-content-between align-items-center d-flex mb-2">
          <div>
            <button
              disabled={disabled}
              className={`${disabled ? 'button-disable-contract' : ''} button-submit-contract`}
              onClick={() => {
                let updateTitle = `完了 与信 ${dataCredit?.state}`
                onCreateAndUpdate('credit', 'statusDone', updateTitle)
              }}
            >
              完了
            </button>
          </div>
          <div>
            <button
              disabled={disabled}
              className={`${disabled ? 'button-disable-contract' : ''} button-submit-contract`}
              onClick={() => {
                let updateTitle = `保存 与信 ${dataCredit?.state}`
                onCreateAndUpdate('credit', '', updateTitle)
              }}
            >
              保存
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Credit;
