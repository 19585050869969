import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import BasicInfo from '../BasicInfo';
import './style.scss';
import { PRODUCT_ENDPOINT, PROJECT_ENDPOINT } from '../../../../constants/endpoints';
import { pushToast } from '../../../../components/Toast';
import Select from 'react-select';


const Modify2 = (props) => {
  const { history } = props;
  const [name, setName] = useState('');
  const [allProduct, setAllProduct] = useState([]);
  const [optionProduct, setOptionProduct] = useState([]);
  const [idProject, setIdProject] = useState(null);
  const [submit, setSubmit] = useState(true);
  const [productInfo, setProductInfo] = useState({});
  const productId = (process.browser && new URL(window.location.href).searchParams.get('productId')) || null;

  const getProductInfo = async ({ productId = '' }) => {
    try {
      const data = await PRODUCT_ENDPOINT.GET_PRODUCT_INFO({ productId: productId });
      if (data) {
        setProductInfo(data?.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (productId) {
      getProductInfo({ productId: productId });
    }
  }, [productId]);

  const handleChangeName = (e) => {
    setName(e.target.value);
  };

  useEffect(() => {
    if (idProject?.length && name?.length) {
      setSubmit(false);
    } else {
      setSubmit(true);
    }

  }, [idProject, name]);


  const getAllProduct = async () => {
    try {
      const data = await PROJECT_ENDPOINT.GET_ALL_PROJECT({ page: 0 });
      if (data && data.data && data.data.body) {
        setAllProduct(data?.data?.body);
      }
    } catch (errorMessage) {
      console.log('errorMessage: ', errorMessage);
    }
  };

  useEffect(() => {
    let newOption = [];
    if (allProduct && allProduct.length > 0) {
      allProduct.map((item) => {
          let newItem = { value: item.projectCode, label: item.projectName };
          newOption.push(newItem);
        }
      );
      setOptionProduct(newOption);
    }

  }, [allProduct]);

  useEffect(() => {
    getAllProduct();
  }, []);

  const handleChangeProduct = (e) => {
    setIdProject(e.value);
  };

  const createStep2 = async (body) => {
    try {
      const data = await PRODUCT_ENDPOINT.CREATE_PRODUCT_STEP_TWO(body);

      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
      } else {
        history.push(`/modify_anken?modify=3&productId=${productId}`);
        pushToast('success', 'Create step 2 success');
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onCLickSubmit = () => {
    if (productId && name && idProject) {
      createStep2({ productId: productId, name: name, idProject: idProject });
    }
  };

  return (
    <div className="wrapper-modify-2">
      <div className="d-flex">
        <div className="col-2 wrapper-column" style={{ fontSize: '16px' }}>
          新規案件名
        </div>
        <div className="col-10 table-right">
          <input className="form-control col-6" onChange={handleChangeName} value={name}/>
        </div>
      </div>
      <div className="d-flex">
        <div className="col-2 wrapper-column" style={{ fontSize: '16px' }}>
          PROJECT NAME
        </div>
        <div className="col-10 table-right">
          <div className='col-6 pl-0'>
            <Select options={optionProduct} onChange={handleChangeProduct}/>
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-center mt-4 mb-3 wrapper-button-next">
        <button className="btn button-next" onClick={() => onCLickSubmit()} disabled={submit}>次へ</button>
      </div>
      <BasicInfo productInfo={productInfo}/>
    </div>
  );
};

export default compose(withRouter)(Modify2);
