import React, { useState, useEffect } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { ROLE, NAME_MASTER_SETTING } from '../../../../helpers/constants';
import { STAFF_ENDPOINT, MASTER_SETTING, COMMON_ENDPOINT } from '../../../../constants/endpoints';
import PaginationCustom from '../../../../components/PaginationCustom';
import Loading from '../../../../components/Loading/Loading';
import CollaseTableAdress from '../CollaseTableAdress';
import _ from 'lodash';

const TableListAdress = ({ role = ROLE.PARTNER, color, roleDetail, t, p, idRole, active, colorText }) => {
  const [activePagess, setActivePagess] = useState(1);
  const [activeStaff, setActiveStaff] = useState(1);
  const [product, setProduct] = useState('');
  const [dataListDate, setDataListDate] = useState({});
  const [loading, setLoading] = useState(false);
  const [listPartner, setListPartner] = useState([]);
  const [partnerId, setPartnerId] = useState([]);
  const [listArea, setListArea] = useState([]);
  const [hideshow, setHideshow] = useState('');

  const getStaffGroupByPartner = async ({
    page = page,
    limit = limit,
    pagePartner = pagePartner,
    limitPartner = limitPartner,
    partnerId = '',
  }) => {
    setLoading(true);
    try {
      const data = await STAFF_ENDPOINT.GET_STAFF_GROUP_BY_PARTNER({
        page: page,
        limit: limit,
        pagePartner: pagePartner,
        limitPartner: limitPartner,
        partnerId: partnerId,
      });
      if (data) {
        setListPartner(data?.data?.partners);
        setProduct(data?.data?.count || 0);
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  useEffect(() => {
    getStaffGroupByPartner({
      page: activeStaff,
      limit: 10,
      pagePartner: activePagess,
      limitPartner: 10,
      partnerId: partnerId,
    });
    getListAreaParams();
  }, [activePagess, activeStaff]);

  useEffect(() => {
    setActivePagess(1);
  }, [p]);

  const handlePageChangess = (pageNumber) => {
    setActivePagess(pageNumber);
  };

  const getListAreaParams = async () => {
    try {
      const data = await COMMON_ENDPOINT.GET_LIST_AREA();
      if (data) {
        if (!_.isEmpty(data?.data)) {
          console.log(1, data?.data);
          const dataAreas = data?.data;

          setListArea({
            hokkaidoAndTohoku: dataAreas?.hokkaidoAndTohoku || [],
            kanto: dataAreas?.kanto || [],
            koshinetsuAndHokuriku: dataAreas?.koshinetsuAndHokuriku || [],
            tokai: dataAreas?.tokai || [],
            kansai: dataAreas?.kansai || [],
            china: dataAreas?.china || [],
            shikoku: dataAreas?.shikoku || [],
            kyushuAndOkinawa: dataAreas?.kyushuAndOkinawa || [],
          });
        } else {
          setListArea([]);
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  const handleHide = (id) => {
    if (hideshow === id) {
      setHideshow('');
    } else {
      setHideshow(id);
    }
  };

  const handlePageEstimate = (pageNumber, id) => {
    setActiveStaff(pageNumber);
    setPartnerId(id);
  };

  return (
    <div className="table-menu-wrapper">
      <Loading loading={loading} />
      {listPartner &&
        listPartner.map((item, index) => {
          return (
            <CollaseTableAdress
              active={active}
              role={role}
              key={index}
              item={item}
              dataListDate={dataListDate}
              color={color}
              handleHide={handleHide}
              hideshow={hideshow}
              colorText={colorText}
              roleDetail={roleDetail}
              idRole={idRole}
              activeStaff={activeStaff}
              handlePageEstimate={handlePageEstimate}
              t={t}
              p={p}
              listArea={listArea}
            />
          );
        })}
      {product > 10 && (
        <PaginationCustom
          activePage={activePagess}
          itemsCountPerPage={10}
          totalItemsCount={product}
          pageRangeDisplayed={5}
          handlePageChange={handlePageChangess}
        />
      )}
    </div>
  );
};

export default TableListAdress;
