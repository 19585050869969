import React, { useState, useEffect } from 'react';
import { Collapse } from 'reactstrap';
import 'react-datepicker/dist/react-datepicker.css';
import '../../../../assets/scss/page/center/Users/style.scss';
import { ROLE, NEOMARS_USERS, ERP_USERS, MANAGERGROUP_USERS, MANAGER_USERS } from '../../../../helpers/constants';
import User from '../User';
import { USER_ENDPOINT } from '../../../../constants/endpoints';
import PaginationCustom from '../../../../components/PaginationCustom';
import { Link } from 'react-router-dom';
import _ from 'lodash';

const UserList = ({colors ,colorText, item = [], index, role, isShow, handleCollap, changeLoading}) => {
  const [classActive, setClassActive] = useState('ERP');
  const [roleActive, setRoleActive] = useState('ERP');
  const [roleList, setRoleList] = useState([]);
  const [dataUsers, setDataUsers] = useState(item);
  const toggle = () => handleCollap(item.organizationId);
  const [activePage, setActivePage] = useState(1);
  const [search, setSearch] = useState('');

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    getListUsersByOrganization({page: pageNumber, nameRole: classActive});
  };

  const dataList = (role) => {
    switch(role) {
      case ROLE.NEOMARS:
        return NEOMARS_USERS;
      case ROLE.CENTER:
        return ERP_USERS;
      case ROLE.PARTNERGROUP:
        return MANAGERGROUP_USERS;
      case ROLE.PARTNER:
        return MANAGER_USERS;
      default:
        return [];
    }
  }

  const getListUsersByOrganization = async ({
      page = 1, limit = 10, pageOrganization = 1,
      limitOrganization = 10, organizationId = dataUsers?.organizationId, nameRole = 'erp', name = search
    }) => {
    try {
      changeLoading(true);
      const data = await USER_ENDPOINT.GET_LIST_USER_ORGANIZATION({
        page,
        limit,pageOrganization,
        limitOrganization,
        organizationId, nameRole, name
      });
      if (data) {
        changeLoading(false);
        setDataUsers(data?.data?.organizations[0]);
        return true;
      }
    } catch (e) {
      changeLoading(false);
      console.log(e);
    }
  };

  useEffect(() => {
    const data = dataList(role)
    setRoleList( dataList(role) );
    setClassActive( data[data.length - 1]?.api );
    setRoleActive( data[data.length - 1]?.name );
  }, [role]);

  useEffect(() => {
    setDataUsers( item );
  }, [item]);

  const getDataUser = (i) => {
    getListUsersByOrganization({nameRole: i.api});
    setClassActive(i.api);
    setRoleActive(i.name);
    setActivePage(1);
  }

  const changeSearch = (e) => {
    let value = e.target.value;
    setSearch(value);
  }

  const handleSearch = async () => {
    let searchSuccess = await getListUsersByOrganization({nameRole: classActive, name: search});

    if (searchSuccess) {
      setActivePage(1)
    }
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSearch()
    }
  }

  return (
    <div className="setting-master-menu-wrapper">
      <div className="wrapper-btn-colappse">
        <div onClick={toggle} className="mb-1 searchbar d-flex justify-content-between align-items-center" style = {{background :`${colors}`}}>
          <div className="ml-2" style={{ color: `${colorText}` }}>{dataUsers?.companyName}</div>
          <div className="mr-2" style={{ color: `${colorText}` }}>
            {isShow === item.organizationId ? <i className="icon-up-open icon-searchbar" /> : <i className="icon-down-open icon-searchbar" />}
          </div>
        </div>
      </div>
      <div>
        <Collapse isOpen={isShow === item.organizationId}>
          <div className="form__content">
            <div className="form__flex form__flex--end">
            {
              roleList.map ((i, index) => {
                return <button key={index} className={`button__btn-load button__btn-load--modify ${classActive === i.api && 'button__btn-load--active'}`} style={{backgroundColor: `${i.color}`}} onClick={() =>{getDataUser(i)}}>{i.name}</button>
              })
            }
            </div>
            <div className="form__flex form__flex--mgt-10">
              <div className="form-search">
                <div className="form__group-input form__group-input--border-none">
                  <div className="title">検索条件</div>
                  <input type="text" onChange = {(e) => changeSearch(e)} onKeyDown={(e) => handleKeyDown(e)} />
                </div>
                <button className="btn-tree" onClick = {handleSearch}>検索</button>
              </div>
              {
                roleList.map((i, index) => {
                  return <Link key={index} to={`/users/register-user?organizationId=${item.organizationId}&role=${i.name}`} className="button__btn-load button__btn-load--modify" style={{backgroundColor: `${i.color}`}}>新規作成</Link>
                })
              }
            </div>
            <User listUser={dataUsers?.users} index={index} colors={colors} activePage={activePage} organizationId={dataUsers?.organizationId} roleActive={roleActive} />
            {!_.isEmpty(dataUsers?.users) && dataUsers?.count > 10 && (
              <PaginationCustom
                  activePage={activePage}
                  itemsCountPerPage={10}
                  totalItemsCount={dataUsers && dataUsers?.count}
                  pageRangeDisplayed={5}
                  handlePageChange={handlePageChange}
              />
            )}
          </div>
        </Collapse>
      </div>
    </div>
  );
};

export default UserList;
