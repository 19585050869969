import React, { Fragment, useEffect, useState } from 'react';
import 'assets/scss/page/AppointInfoDetail/style.scss';
import Header from 'components/Header';
import Footer from 'components/Footer';
import { ORDER } from 'constants/endpoints';
import FormAppoint from './FormAppoint';
import { SCREEN_NAME, HEADER_MENU, ROLE, PERMISSTED_PARAMS,DATE_JP_FORMAT } from 'helpers/constants';
import { getRoleNew } from 'helpers/helpers';
import DetailData from 'components/DetailCenter/DetailData';
import DetailDataList from 'components/DetailCenter/DetailDataList';
import DetailOrder from 'components/DetailCenter/DetailOrder';
import DetailListAllData from 'components/DetailCenter/DetailListAllData';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import DetailTreeDocumentSetting from 'components/DetailTreeDocumentSetting';
import DetailTimeWorker from 'components/DetailOrderErp/DetailTimeWorker';
import DetailVisitDate from 'components/DetailOrderErp/DetailVisitDate';
import DetailSent from 'components/DetailOrderErp/DetailSent';
import ButtonDetail from 'components/ButtonDetail';
import { pushToast } from 'components/Toast';
import { getRole, formatInputSelectDate } from 'helpers/helpers';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';


const AppointInfoDetail = () => {
  let orderId = process.browser && new URL(window.location.href).searchParams.get('orderId');
  let name = (process.browser && new URL(window.location.href).searchParams.get('p')) || 'appoint_info';
  let organizationId = (process.browser && new URL(window.location.href).searchParams.get('organizationId')) || '';
  const colorScreen = useSelector((store) => store.utils.colorMe);
  const idRole = localStorage.getItem('role');
  const role = getRoleNew({ idRole });
  const [dataOrder, setDataOrder] = useState({});
  const [update, setUpdate] = useState(false);
  const [p, setP] = React.useState(name || HEADER_MENU.RECEIVE_ALL);
  const [listDetailScreen, setListDetailScreen] = useState([]);
  const [dataDetailTimeWorker, setDataDetailTimeWorker] = useState({});
  const [masterSend, setMasterSend] = useState({ erpMattersToBeSent: '', managementMattersToBeSent: '', callLog: '' });
  const history = useHistory();
  const nameRole = getRole({ idRole });

  useEffect(() => {
    getListRoleChild();
  }, []);

  const getListRoleChild = async () => {
    try {
      const data = await ORDER.GET_LIST_DETAIL_SETTING({
        arrRole: idRole,
        nameScreen: 'appoint_info',
        orderId,
        organizationId,
      });
      if (data) {
        setListDetailScreen(_.sortBy(data?.data?.detailScreen?.concat({ name: 'button', sortNum: 5.5, status: true }), 'sortNum'));
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    setP(name);
  }, [name]);

  const onUpdate = () => {
    setUpdate(!update);
  };

  const updateOrder = (detailData) => {
    setDataOrder({ ...dataOrder, ...detailData });
  };

  const getOrderDetail = async ({ orderId = '' }) => {
    try {
      const data = await ORDER.GET_ORDER_DETAIL_APPOINT_INFO({ orderId, screenName: 'appoint_info' , organizationId });
      if (data) {
        setDataOrder({
          ...data,
          visitDateTo: data?.visitDateTo ? (moment(`${formatInputSelectDate(data?.visitDateTo)}`, `${DATE_JP_FORMAT}`).isValid()
            ? data?.visitDateTo
            : `${data?.visitDate ? `${data?.visitDate} ${data?.visitDateTo}` : ''}`) : '',
          visitDateToTemp: data?.visitDateTo ? (moment(`${formatInputSelectDate(data?.visitDateTo)}`, `${DATE_JP_FORMAT}`).isValid()
            ? data?.visitDateTo
            : `${data?.visitDate ? `${data?.visitDate} ${data?.visitDateTo}` : ''}`) : '',
          visitDateTmp: data?.visitDate,
          visitDateToTmp: data?.visitDateTo,
          visitDateFromTmp: data?.visitDateFrom,
          callStatus: data?.callStatus || '未完了',
        });
        setMasterSend({
          erpMattersToBeSent: data?.erpMattersToBeSent || '', managementMattersToBeSent: data?.managementMattersToBeSent || '', callLog: data?.callLog || ''
        })
        setDataDetailTimeWorker(data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getDataOrderForDetailTimeWorker = async () => {
    try {
      const data = await ORDER.GET_ORDER_DETAIL_APPOINT_INFO({ orderId });
      if (data) {
        setDataDetailTimeWorker(data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (orderId) {
      getOrderDetail({
        orderId: orderId,
      });
    }
  }, [orderId]);

  const handleVisitDate = ({ dataVisitDate }) => {
    if ('visitDate' in dataVisitDate) {
      setDataOrder({ ...dataOrder, ...{visitDateTemp: dataVisitDate?.visitDate}})
    } else if ('visitDateFrom' in dataVisitDate) {
      setDataOrder({ ...dataOrder, ...{visitDateFromTemp: dataVisitDate?.visitDateFrom}});
    } else if ('visitDateTo' in dataVisitDate) {
      setDataOrder({ ...dataOrder, ...{visitDateToTemp: dataVisitDate?.visitDateTo}});
    } else {
      setDataOrder({ ...dataOrder, ...dataVisitDate });
    }
  };

  const onChangeTextMasterSend = (e) => {
    let name = e.target.name;
    setMasterSend({ ...masterSend, [name]: e.target.value });
  };

  const onClickSubmit = () => {
    const newData = _.pick(dataOrder?.callStatus === "完了" ? {
      ...dataOrder, visitDate: dataOrder?.visitDate || dataOrder?.visitDateTemp,
      visitDateFrom: dataOrder?.visitDateFrom || dataOrder?.visitDateFromTemp || '00:00', visitDateTo: dataOrder?.visitDateTo || dataOrder?.visitDateToTemp || '00:00'
    } : (
      _.omit(dataOrder, ['visitDate', 'visitDateFrom', 'visitDateTo', 'visitDateTemp', 'visitDateFromTemp', 'visitDateToTemp'])
    ), PERMISSTED_PARAMS);
    const updateTitle = `架電ステータス：${newData?.callStatus}`;
    updateAppointInfo({ body: { ...newData, ...masterSend, screenName: SCREEN_NAME.APPOINT_INFO, companyName: localStorage.getItem('companyName'), updateTitle }, id_order: orderId });
  };


  const updateAppointInfo = async ({ body, id_order }) => {
    try {
      const data = await ORDER.UPDATE_APPOINT_INFO({ body, id_order, organizationId });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
      } else {
        pushToast('success', 'Update success');
        onUpdate();
        return history.push('/list?p=appoint_info');
      }
    } catch (errorMessage) {
      console.log('errorMessage: ', errorMessage);
    }
  };

  return (
    <div className="wrapper-appoint-info-detail">
      <div className="main">
        <Header p={p} idRole={idRole} color={colorScreen?.code} colorText={colorScreen?.colorText} />
        <div className="body-wrapper">
          <div className="logout_btn">
            <Link to={`list?p=${p}`}>
              <span className="mypage_ab_custom">戻る</span>
            </Link>
          </div>

          {listDetailScreen?.map((item, index) => {
            if (item.status && [ROLE.CENTER, ROLE.PARTNERGROUP].includes(role)) {
              switch (item.name) {
                case 'clientCompany':
                  return <DetailOrder p={p} data={dataOrder} key={index} />;
                case 'applicationCompany':
                  return <DetailData p={p} data={dataOrder} link={false} key={index} />;
                case 'visitDateWorker':
                  return (
                    <DetailVisitDate
                      dataVisitDate={{visitDate: dataOrder?.visitDateTmp, visitDateFrom: dataOrder?.visitDateFromTmp, visitDateTo: dataOrder?.visitDateToTmp, preferredDate: dataOrder?.preferredDate}}
                      onChangeVisitDate={dataVisitDate => handleVisitDate({ dataVisitDate })}
                      key={index}
                      disabled={true}
                    />
                  )
                case 'appointmentInfo':
                  return (
                    <FormAppoint
                      orderId={orderId}
                      onUpdate={onUpdate}
                      dataOrder={dataOrder}
                      name={item.name}
                      updateOrder={updateOrder}
                      key={index}
                      onChangeTextMasterSend={onChangeTextMasterSend}
                      masterSend={masterSend}
                    />
                  );
                case 'mattersSent':
                  return <DetailSent
                    data={masterSend}
                    onChangeText={onChangeTextMasterSend}
                    isDisabledManagement={!(nameRole === ROLE.PARTNER)}
                    isDisabledErp={(nameRole === ROLE.PARTNER)}
                    key={index}
                    role={role}
                  />
                case 'button':
                  return (
                    <div className="row-btn-submit" key={index}>
                      <ButtonDetail text="保存" onUpdateOrder={onClickSubmit} />
                    </div>
                  )
                case 'detailInfo':
                  return <DetailListAllData data={dataOrder} screenName={SCREEN_NAME.APPOINT_INFO} key={index} />;
                case 'document':
                  return <DetailTreeDocumentSetting orderId={orderId} order={dataOrder} />;
                case 'history':
                  return (
                    <DetailDataList
                      datePick={false}
                      screenName={SCREEN_NAME.APPOINT_INFO}
                      orderId={orderId}
                      key={index}
                    />
                  );
                default:
                  return '';
              }
            } else if (item.status) {
              switch (item.name) {
                case 'clientCompany':
                  return <DetailOrder p={p} data={dataOrder} key={index} />;
                case 'applicationCompany':
                  return <DetailData p={p} data={dataOrder} link={false} key={index} />;
                case 'visitDateWorker':
                  return (
                    <DetailVisitDate
                      dataVisitDate={{visitDate: dataOrder?.visitDateTmp, visitDateFrom: dataOrder?.visitDateFromTmp, visitDateTo: dataOrder?.visitDateToTmp, preferredDate: dataOrder?.preferredDate}}
                      onChangeVisitDate={dataVisitDate => handleVisitDate({ dataVisitDate })}
                      key={index}
                      disabled = {true}
                    />
                  )
                case 'mattersSent':
                  return <DetailSent
                    data={masterSend}
                    onChangeText={onChangeTextMasterSend}
                    isDisabledManagement={!(nameRole === ROLE.PARTNER)}
                    isDisabledErp={(nameRole === ROLE.PARTNER)}
                    key={index}
                  />
                case 'appointInfo':
                  return (
                    <FormAppoint
                      orderId={orderId}
                      onUpdate={onUpdate}
                      dataOrder={dataOrder}
                      name={item.name}
                      updateOrder={updateOrder}
                      key={index}
                      onChangeTextMasterSend={onChangeTextMasterSend}
                      masterSend={masterSend}
                    />
                  );
                case 'button':
                  return (
                    <div className="row-btn-submit" key={index}>
                      <ButtonDetail text="保存" onUpdateOrder={onClickSubmit} />
                    </div>
                  )
                case 'detailInfo':
                  return <DetailListAllData data={dataOrder} screenName={SCREEN_NAME.APPOINT_INFO} key={index} />;
                case 'document':
                  return <DetailTreeDocumentSetting orderId={orderId} order={dataOrder} />;
                case 'history':
                  return (
                    <DetailDataList
                      datePick={false}
                      screenName={SCREEN_NAME.APPOINT_INFO}
                      orderId={orderId}
                      key={index}
                    />
                  );
                default:
                  return '';
              }
            }
          })}
        </div>
        <Footer color={colorScreen?.code} colorText={colorScreen?.colorText} />
      </div>
    </div>
  );
};

export default AppointInfoDetail;
