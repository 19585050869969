import { all } from 'redux-saga/effects';
import { getListUser } from './user';
import { getListOrder } from './order';
import { getRoleMe, logIn, logOut, getUserMe,getDataHistory } from './authentication';
import { handleChat } from './chat';

export default function* rootSaga() {
  yield all(
    [
      getListUser(),
      getListOrder(),
      logIn(),
      logOut(),
      getUserMe(),
      getRoleMe(),
      handleChat(),
      getDataHistory(),
    ]
  );
}
