import React, { useEffect, useState } from 'react';
import { Collapse } from 'reactstrap';
import _ from 'lodash';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import PaginationCustom from '../../../../components/PaginationCustom';
import { PRODUCT_ENDPOINT } from '../../../../constants/endpoints';
import { pushToast } from '../../../../components/Toast';
import { SCREEN_DISPATCH_SETTING } from '../../../../helpers/constants';
import { getDataHistoryMasterSetting } from 'actions/authentication';
import { useDispatch } from 'react-redux';

const CollapItemWorker = ({ colors, item, handleHide, hideshow, colorText, changeLoading }) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [dataContracts] = useState(item);
  const [activePage, setActivePage] = useState(1);
  const [listItem, setListItem] = useState([]);
  const [listSearch, setListSearch] = useState([]);
  const [search, setSearch] = useState('');

  useEffect(() => {
    setListItem(item?.staffs);
    if (search !== '') {
      let data =
        listItem &&
        listItem.filter((ele) => {
          return ele?.partnerName.toLowerCase().indexOf(search.toLowerCase()) !== -1;
        });
      _.isEmpty(data) && _.isEmpty(search) ? setListSearch(item?.staffs) : setListSearch(data)
    } else {
      setListSearch(item?.staffs)
    }
  }, [item, search]);

  const toggle = () => {
    setIsOpen(!isOpen);
    handleHide(item.productId);
  };
  const handleChangePageContract = (pageNumber) => {
    setActivePage(pageNumber);
    // getListContractEst({pageContract: pageNumber, contractId: dataContracts.recruitmentContractId});
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const items1 = reorder(listSearch, result.source.index, result.destination.index);
    setListSearch(items1);
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const updateStaffSchema = async ({ body, productId = '' }) => {
    try {
      changeLoading(true);
      const data = await PRODUCT_ENDPOINT.UPDATE_STAFF_SCHEMA({ body: body, productId: productId });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
        changeLoading(false);
      } else {
        pushToast('success', 'Update info success');
        dispatch(getDataHistoryMasterSetting({ logId : '', screenName: SCREEN_DISPATCH_SETTING.workerPriority.label }));
        changeLoading(false);
      }
    } catch (errorMessage) {
      console.log('errorMessage: ', errorMessage);
      changeLoading(false);
    }
  };

  const updateStaff = () => {
    let dataUpdate =
      listSearch &&
      _.compact(listSearch)?.map((ele, index) => {
        return {
          loginId: ele?.loginId,
          sortNum: index + 1,
        };
      });
    updateStaffSchema({ body: { staffSchema: dataUpdate , screenName : SCREEN_DISPATCH_SETTING.workerPriority.label, updateTitle:`保存 並べ替え設定 ${item.name}` }, productId: item?.productId });
  };

  return (
    <div className="setting-master-menu-wrapper">
      <div className="wrapper-btn-colappse">
        <div
          onClick={toggle}
          className="mb-1 searchbar d-flex justify-content-between align-items-center"
          style={{ background: `${colors}` }}
        >
          <div className="ml-2" style={{ color: `${colorText}` }}>
            {item.name}
          </div>
          <div className="mr-2" style={{ color: `${colorText}` }}>
            {hideshow === item?.productId ? (
              <i className="icon-up-open icon-searchbar" />
            ) : (
                <i className="icon-down-open icon-searchbar" />
              )}
          </div>
        </div>
      </div>
      <div>
        <Collapse isOpen={hideshow === item?.productId}>
          <div className="table-list-contract">
            <div className="block-search text-left">
              <label>検索条件</label>
              <input type="search" placeholder="search..." onChange={(e) => setSearch(e.target.value)} />
            </div>
            <div className="wrapper-master-setting wrapper-master-setting-custom-contract">
              <table className="form__item" style={{ borderCollapse: 'unset', width: '100%' }}>
                <thead>
                  <tr>
                    <th style={{ width: '10%' }}>ID</th>
                    <th style={{ width: '15%' }}>Management</th>
                    <th style={{ width: '10%' }}>作業員</th>
                    <th style={{ width: '25%' }}>都道府県</th>
                    <th style={{ width: '40%' }}>起点住所</th>
                  </tr>
                </thead>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="droppable">
                    {(provided) => (
                      <tbody {...provided.droppableProps} ref={provided.innerRef}>
                        {listSearch && listSearch.map((ele, index) => {
                          if (!_.isEmpty(ele)) {
                            ele.area = ele?.prefectures || ele?.area || ele?.prefecturesTemp;
                            return (
                              <Draggable key={`${index + 1}`} draggableId={`${index + 1}`} index={index}>
                                {(provided) => (
                                  <tr
                                    key={index}
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <td className="form__item--input" style={{ width: '10%' }}>{ele?.loginId}</td>
                                    <td className="form__item--input" style={{ width: '20%' }}>{ele?.partnerName}</td>
                                    <td className="form__item--input" style={{ width: '10%' }}>{ele?.surName} {ele?.name || ''}</td>
                                    <td className="form__item--input" style={{ width: '20%' }}>{ele?.area || ''}</td>
                                    <td className="form__item--input" style={{ width: '40%' }}>{ele?.address || ''}</td>
                                  </tr>
                                )}
                              </Draggable>
                            );
                          }
                        })}
                      </tbody>
                    )}
                  </Droppable>
                </DragDropContext>
              </table>
            </div>

            <div className="list-button-contact text-right">
              <button className="item mr-0" style={{ width: 300 }} onClick={updateStaff}>
                保存
              </button>
            </div>
            {!_.isEmpty(dataContracts?.estimates?.data) && dataContracts?.count > 10 && (
              <PaginationCustom
                activePage={activePage}
                itemsCountPerPage={10}
                totalItemsCount={dataContracts?.count}
                pageRangeDisplayed={5}
                handlePageChange={handleChangePageContract}
              />
            )}
          </div>
        </Collapse>
      </div>
    </div>
  );
};

export default CollapItemWorker;
