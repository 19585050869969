import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import 'react-datepicker/dist/react-datepicker.css';
import './style.scss';
import { ROLE, HEADER_MENU } from '../../helpers/constants';
import PaginationCustom from '../PaginationCustom';
import CollapseTable from './CollapseTable';
import ShukeiTableClient from '../TableHeader/ShukeiTableClient';
import ShukeiTableMaster from '../TableHeader/ShukeiTableMaster';
import { withRouter, useHistory } from 'react-router-dom';
import { compose } from 'redux';
import { translate } from 'react-i18next';
import Loading from 'components/Loading/Loading';
import { MASTER_SETTING, ORDER, ORDER_PARTNER, ORDER_CLIENT, STAFF_ENDPOINT } from 'constants/endpoints';
import SearchMenu from '../SearchMenu';
import _ from 'lodash';

const INIT_AFFILIATE_PAGINATION = {
  page: 1,
  limit: 10,
};

const TableListV2 = ({ role = ROLE.PARTNER, color, roleDetail, t, p, idRole, statusShowAll, colorText, detailCheck = {}, activeMenu='' }) => {
  let nameP = (process.browser && new URL(window.location.href).searchParams.get('p')) || '';
  const history = useHistory();
  const paramSearch = useSelector((store) => store.utils.paramSearch);
  const [listProduct, setListProduct] = useState([]);
  const [product, setProduct] = useState('');
  const [data, setData] = useState('');
  const [activePageTable, setActivePageTable] = useState(1);
  const [productId, setProductId] = useState('');
  const [loading, setLoading] = useState(false);
  const [activePageSearch, setActivePageSearch] = useState(1);
  const [partnerId, setPartnerId] = useState('');
  const [partnerBranch, setpartnerBranch] = useState([]);
  const [staffBranch, setstaffBranch] = useState([]);
  const [sortData, setSortData] = useState({});
  const [summary, setSummary] = useState(false);
  const [hideshow, setHideshow] = useState('');

  const getOrderDetail = async ({ screenRole = '', screenName = '' }) => {
    try {
      const data = await MASTER_SETTING.GET_ALL_SUMMARY({ screenRole, screenName });
      if (data) {
        let newData = data?.data[0];
        newData = { ...newData };
        delete newData.screenName;
        delete newData.screenRole;
        delete newData.summaryProductSettingId;
        setData(Object.values(newData));
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    const screenName = localStorage.getItem('nameMenu') || 'receive_all';
    const screenRole = localStorage.getItem('role');
    setProductId('');
    setActivePageTable(1);
    setActivePageSearch(1);
    setListProduct([]);

    if (screenName) {
      getOrderDetail({ screenName: p, screenRole });
    }
  }, [nameP]);

  useEffect(() => {
    setActivePageTable(1);
    setActivePageSearch(1);
    setProductId('');
    setListProduct([]);
  }, [statusShowAll]);

  const responApi = (productId, data , checkCount = false) => {
    if(p !== 'summary') {
      if(data) {
        if (productId !== '') {
          const tmp1 = listProduct;
          const tmp2 = tmp1.map((item) => {
            if (item.productId === data?.data?.products[0]?.productId) {
              return { ...item, body: data?.data?.products[0]?.body, countSummary: data?.data?.products[0]?.countSummary };
            }
            return item;
          });
          setListProduct(tmp2);
        } else {
          setListProduct(data?.data?.products || []);
          setPartnerId(data?.data?.partnerId);
        }
        const count = !data?.data?.count ? 0 : checkCount ? product : data?.data?.count
        setProduct(count);
      }
    } else {
      if (productId !== '') {
        const tmp1 = listProduct;
        const dataPagination = _.filter(data?.data?.products , {'productId' : productId })
        const tmp2 = tmp1.map((item) => {
          if (item.productId === dataPagination[0]?.productId) {
            return { ...item, body: dataPagination[0]?.body, countSummary: dataPagination[0]?.countSummary };
          }
          return item;
        });
        setListProduct(tmp2);
      } else {
        setListProduct(data?.data?.products || []);
        setPartnerId(data?.data?.partnerId);
      }
      setProduct(data?.data?.count);
    }
  };

  const formApi = async ({
    page = INIT_AFFILIATE_PAGINATION.page,
    limit = INIT_AFFILIATE_PAGINATION.limit,
    pageProduct = INIT_AFFILIATE_PAGINATION.page,
    limitProduct = INIT_AFFILIATE_PAGINATION.limit,
    productId = '',
    API,
    sortBy,
    sortProductId,
    sortWith,
    totalScreen = '',
    caseName = '',
    managementCode = '',
    serviceName = '',
    requestedDateTo = '',
    requestedDateFrom = '',
    operationAdjustmentDateTo = '',
    operationAdjustmentDateFrom = '',
    applicantCompanyName = '',
    resultsPreparation = '',
    visitDateTo = '',
    visitDateFrom = '',
    partnerName = '',
    visitedBaseName = '',
    reportReceiptDate = '',
    reportDeliveryDate = '',
    contactName = '',
    installationSiteName = '',
    timeAddPartnerTo = '',
    timeAddPartnerFrom = '',
    operationAdjustmentInfo = '',
    reportReceiptDateFrom = '',
    reportReceiptDateTo = '',
    timestampReportUploadInfoFrom = '',
    timestampReportUploadInfoTo = '',
    checkVisitDate = '',
    checkTimeAddPartner = '',
    checkReportReceiptDate = '',
    isDefault = false,
    checkCount = false,
  }) => {
    setLoading(true);
    try {
      const data = !isDefault ? await API({
        page,
        limit,
        pageProduct: statusShowAll ? 0 : pageProduct,
        limitProduct,
        sortBy,
        sortProductId,
        sortWith,
        caseName: paramSearch.caseName || caseName,
        managementCode: paramSearch.managementCode || managementCode,
        serviceName: paramSearch.serviceName || serviceName,
        requestedDateTo: paramSearch.requestedDateTo || requestedDateTo,
        requestedDateFrom: paramSearch.requestedDateFrom || requestedDateFrom,
        operationAdjustmentDateTo: paramSearch.operationAdjustmentDateTo || operationAdjustmentDateTo,
        operationAdjustmentDateFrom: paramSearch.operationAdjustmentDateFrom || operationAdjustmentDateFrom,
        applicantCompanyName: paramSearch.applicantCompanyName || applicantCompanyName,
        resultsPreparation: paramSearch.resultsPreparation || resultsPreparation,
        visitDateTo: paramSearch.visitDateTo || visitDateTo,
        visitDateFrom: paramSearch.visitDateFrom || visitDateFrom,
        partnerName: paramSearch.partnerName || partnerName,
        visitedBaseName: paramSearch.visitedBaseName || visitedBaseName,
        prefecturesVisited: paramSearch.prefecturesVisited,
        reportReceiptDate: paramSearch.reportReceiptDate || reportReceiptDate,
        reportDeliveryDate: paramSearch.reportDeliveryDate || reportDeliveryDate,
        installationSiteName: paramSearch.installationSiteName || installationSiteName,
        timeAddPartnerFrom: paramSearch.timeAddPartnerFrom || timeAddPartnerFrom,
        timeAddPartnerTo: paramSearch.timeAddPartnerTo || timeAddPartnerTo,
        reportReceiptDateFrom: paramSearch.reportReceiptDateFrom || reportReceiptDateFrom,
        reportReceiptDateTo: paramSearch.reportReceiptDateTo || reportReceiptDateTo,
        timestampReportUploadInfoFrom: paramSearch.timestampReportUploadInfoFrom || timestampReportUploadInfoFrom,
        timestampReportUploadInfoTo: paramSearch.timestampReportUploadInfoTo || timestampReportUploadInfoTo,
        operationAdjustmentInfo: paramSearch.operationAdjustmentInfo || operationAdjustmentInfo,
        contactName: paramSearch.contactName || contactName,
        checkVisitDate: paramSearch.checkVisitDate || checkVisitDate,
        checkTimeAddPartner: paramSearch.checkTimeAddPartner || checkTimeAddPartner,
        checkReportReceiptDate: paramSearch.checkReportReceiptDate || checkReportReceiptDate,
        productId,
        totalScreen,
      }) : {};
      if(data) {
        responApi(productId, data , checkCount);
        setLoading(false)
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const getListRecevieAll = ({ dataSearch }) => {
    dataSearch = { ...dataSearch, ...sortData }
    setSummary('')
    const API = ORDER.GET_LIST_RECEIVE_ALL;
    formApi({ ...dataSearch, API });
  };

  const getListOrderDescribe = ({ dataSearch }) => {
    dataSearch = { ...dataSearch, ...sortData }
    const API = ORDER.GET_LIST_ORDER_DESCRIBE;
    formApi({ ...dataSearch, API });
  };

  const getListSummary = ({ dataSearch , totalScreen = '' }) => {
    dataSearch = { ...dataSearch, ...sortData, totalScreen: totalScreen || '' };
    const API = ORDER.GET_LIST_STATISTIC;
    setSummary(totalScreen);
    history.push('list?p=summary')
    formApi({ ...dataSearch, API });
  };

  const getListOrderTomorrow = ({ dataSearch }) => {
    dataSearch = { ...dataSearch, ...sortData }
    const API = ORDER.GET_LIST_ORDER_TOMORROW;
    formApi({ ...dataSearch, API });
  };

  const getOrderPartnerTomorrow = ({ dataSearch }) => {
    dataSearch = { ...dataSearch, ...sortData }
    const API = ORDER_PARTNER.GET_ORDER_PARTNER_TOMORROW;
    formApi({ ...dataSearch, API });
  };

  const getOrderClientTomorrow = ({ dataSearch }) => {
    dataSearch = { ...dataSearch, ...sortData }
    const API = ORDER_CLIENT.GET_ORDER_CLIENT_TOMORROW;
    formApi({ ...dataSearch, API });
  };

  const getListOrderAppointInfo = ({ dataSearch }) => {
    dataSearch = { ...dataSearch, ...sortData }
    const API = ORDER.GET_LIST_ORDER_APPOINT_INFO;
    formApi({ ...dataSearch, API });
  };

  const getListOrderConditionInfo = ({ dataSearch }) => {
    dataSearch = { ...dataSearch, ...sortData }
    const API = ORDER.GET_LIST_ORDER_CONDITION_INFO;
    formApi({ ...dataSearch, API });
  };

  const getListSiteInfoOrder = ({ dataSearch }) => {
    dataSearch = { ...dataSearch, ...sortData }
    const API = ORDER.GET_LIST_SITE_INFO_ORDER;
    formApi({ ...dataSearch, API });
  };

  const getListCancelInfo = ({ dataSearch }) => {
    dataSearch = { ...dataSearch, ...sortData };
    const API = ORDER.GET_LIST_SEARCH_CANCEL_INFO;
    formApi({ ...dataSearch, API });
  };

  const getListCancelMenu = ({ dataSearch, isDefault = false }) => {
    dataSearch = { ...dataSearch, ...sortData };
    const API = ORDER.GET_LIST_SEARCH_CANCEL_MENU;
    formApi({ ...dataSearch, API, isDefault });
  };

  const getListPartnerCancelInfo = ({ dataSearch }) => {
    dataSearch = { ...dataSearch, ...sortData };
    const API = ORDER_PARTNER.GET_LIST_PARTNER_CANCEL_INFO;
    formApi({ ...dataSearch, API });
  }

  const getListCancelMenuClient = ({ dataSearch , isDefault = false }) => {
    dataSearch = { ...dataSearch, ...sortData };
    const API = ORDER_CLIENT.GET_LIST_CANCEL_MENU;
    formApi({ ...dataSearch, API , isDefault });
  }

  const getListCancelInfoClient = ({ dataSearch , isDefault = false }) => {
    dataSearch = { ...dataSearch, ...sortData };
    const API = ORDER_CLIENT.GET_LIST_CANCEL_INFO;
    formApi({ ...dataSearch, API , isDefault });
  }

  const getListReportUploadInfo = ({ dataSearch }) => {
    dataSearch = { ...dataSearch, ...sortData }
    const API = ORDER.GET_LIST_REPORT_UPLOAD_INFO;
    formApi({ ...dataSearch, API });
  };

  const getListPreparationInfo = ({ dataSearch }) => {
    dataSearch = { ...dataSearch, ...sortData }
    const API = ORDER.GET_LIST_PREPARATION_INFO;
    formApi({ ...dataSearch, API });
  };

  const getListDeliveryInfo = ({ dataSearch }) => {
    dataSearch = { ...dataSearch, ...sortData }
    const API = ORDER.GET_LIST_DELIVERY_INFO;
    formApi({ ...dataSearch, API });
  };

  const getListReceiveConfirm = ({ dataSearch }) => {
    dataSearch = { ...dataSearch, ...sortData }
    const API = ORDER_PARTNER.GET_LIST_RECEIVE_CONFIRM;
    formApi({ ...dataSearch, API });
  };

  const getReportList = ({ dataSearch }) => {
    const API = ORDER_PARTNER.GET_REPOST_LIST;
    formApi({ ...dataSearch, API });
  };

  const getListOrderPartnerHonkado = ({ dataSearch }) => {
    dataSearch = { ...dataSearch, ...sortData }
    const API = ORDER_PARTNER.GET_LIST_ORDER_PARTNER_HONKADO;
    formApi({ ...dataSearch, API });
  };

  const getListOrderPartnerAppointInfo = ({ dataSearch }) => {
    dataSearch = { ...dataSearch, ...sortData }
    const API = ORDER_PARTNER.GET_LIST_ORDER_PARTNER_APPOINT_INFO;
    formApi({ ...dataSearch, API });
  };

  const getReportCompledted = ({ dataSearch }) => {
    const API = ORDER_PARTNER.GET_REPORT_COMPLETED;
    formApi({ ...dataSearch, API });
  };

  const getPartnerUnoperationList = ({ dataSearch }) => {
    dataSearch = { ...dataSearch, ...sortData }
    const API = ORDER_PARTNER.GET_PARTNER_UNOPERATION_LIST;
    formApi({ ...dataSearch, API });
  };

  const getListOrderPartnerReceive = ({ dataSearch }) => {
    dataSearch = { ...dataSearch, ...sortData }
    const API = ORDER_PARTNER.GET_LIST_ORDER_PARTNER_RECEIVE;
    formApi({ ...dataSearch, API });
  };

  const getListHonkadoClient = ({ dataSearch }) => {
    dataSearch = { ...dataSearch, ...sortData }
    const API = ORDER_CLIENT.GET_LIST_HONKADO;
    formApi({ ...dataSearch, API });
  };

  const getListDeliveryClient = ({ dataSearch }) => {
    dataSearch = { ...dataSearch, ...sortData }
    const API = ORDER_CLIENT.GET_LIST_DELIVERY;
    formApi({ ...dataSearch, API });
  };

  const getListCompleteList = ({ dataSearch }) => {
    dataSearch = { ...dataSearch, ...sortData }
    const API = ORDER_CLIENT.GET_LIST_COMPLETE_LIST;
    formApi({ ...dataSearch, API });
  };

  const getListJyucyu = ({ dataSearch }) => {
    dataSearch = { ...dataSearch, ...sortData }
    const API = ORDER_CLIENT.GET_LIST_JYUCYU;
    formApi({ ...dataSearch, API });
  };

  const getListSportAccept = ({ dataSearch }) => {
    dataSearch = { ...dataSearch, ...sortData }
    const API = ORDER_CLIENT.GET_LIST_SPORT_ACCEPT;
    formApi({ ...dataSearch, API });
  };

  const getBranchPartner = async () => {
    try {
      const data = await ORDER_PARTNER.GET_LIST_BRANCH_PARTNER({});
      if (data) {
        setpartnerBranch(data?.data?.body || [])
      }
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    setSortData({})
  }, [p])

  const getStaffBranch = async () => {
    try {
      const data = await STAFF_ENDPOINT.GET_ALL_STAFF({ page: 0 });
      if (data) {
        setstaffBranch(data?.data?.result || [])
      }
    } catch (e) {
      console.log(e);
    }
  };

  const sortOrderBy = (sortBody) => {
    setSortData(sortBody);
  }
  useEffect(() => {
    const dataSearch = {
      page: 1,
      limit: 10,
      pageProduct: 1,
      limitProduct: 10,
      productId,
    };
    setHideshow('')
    setActivePageSearch(1);
    if (p && idRole && (roleDetail === ROLE.CENTER || roleDetail === ROLE.PARTNERGROUP)) {
      if (p === HEADER_MENU.RECEIVE_INFO) {
        getListOrderDescribe({ dataSearch });
      } else if (p === HEADER_MENU.RECEIVE_ALL) {
        getListRecevieAll({ dataSearch });
      } else if (p === HEADER_MENU.APPOINT_INFO) {
        getListOrderAppointInfo({ dataSearch });
      } else if (p === HEADER_MENU.CONDITION_INFO) {
        getListOrderConditionInfo({ dataSearch });
      } else if (p === HEADER_MENU.SITE_INFO) {
        getListSiteInfoOrder({ dataSearch });
      } else if (p === HEADER_MENU.REPORT_UPLOAD_INFO) {
        getListReportUploadInfo({ dataSearch });
      } else if (p === HEADER_MENU.PREPARATION_INFO) {
        getListPreparationInfo({ dataSearch });
      } else if (p === HEADER_MENU.DELIVERED_INFO) {
        getListDeliveryInfo({ dataSearch });
      } else if (p === HEADER_MENU.TOMORROW_INFO) {
        getListOrderTomorrow({ dataSearch });
      } else if (p === HEADER_MENU.PREPARATION_INFO) {
        getListOrderPartnerReceive({ dataSearch });
      } else if (p === HEADER_MENU.TOMORROW_INFO) {
        getOrderPartnerTomorrow({ dataSearch });
      } else if (p === HEADER_MENU.CANCEL_INFO) {
        getListCancelInfo({ dataSearch });
      } else if (p === HEADER_MENU.CANCEL_MENU) {
        getListCancelMenu({ dataSearch, isDefault: _.isEmpty(_.compact(Object.values(_.omit(paramSearch, ['limit', 'page', 'searchCurrentPath', 'prefecturesVisited'])))) });
      }  else if (p === 'summary') {
        !summary && history.push('/list?p=receive_all')
      }
    } else if (p && idRole && roleDetail === ROLE.PARTNER) {
      if (p === HEADER_MENU.RECEIVE_ALL) {
        getListReceiveConfirm({ dataSearch });
      } else if (p === HEADER_MENU.REPORT_UPLOAD_INFO) {
        getReportList({ dataSearch });
      } else if (p === HEADER_MENU.SITE_INFO) {
        getListOrderPartnerHonkado({ dataSearch });
      } else if (p === HEADER_MENU.APPOINT_INFO) {
        getListOrderPartnerAppointInfo({ dataSearch });
      } else if (p === HEADER_MENU.DELIVERED_INFO) {
        getReportCompledted({ dataSearch });
      } else if (p === HEADER_MENU.UNOPERATION_LIST) {
        getPartnerUnoperationList({ dataSearch });
      } else if (p === HEADER_MENU.CANCEL_MENU) {
        getListPartnerCancelInfo({ dataSearch });
      } else if (p === HEADER_MENU.TOMORROW_INFO) {
        getOrderPartnerTomorrow({ dataSearch });
      } else if (p === HEADER_MENU.PREPARATION_INFO) {
        getBranchPartner();
        getStaffBranch();
        getListOrderPartnerReceive({ dataSearch });
      }
    } else if (p && idRole && roleDetail === ROLE.CLIENT) {
      if (p === HEADER_MENU.RECEIVE_ALL) {
        getListJyucyu({ dataSearch });
      } else if (p === HEADER_MENU.SITE_INFO) {
        getListHonkadoClient({ dataSearch });
      } else if (p === HEADER_MENU.DELIVERED_INFO) {
        getListDeliveryClient({ dataSearch });
      } else if (p === HEADER_MENU.REPORT_UPLOAD_INFO) {
        getListCompleteList({ dataSearch });
      } else if (p === HEADER_MENU.PREPARATION_INFO) {
        getListSportAccept({ dataSearch });
      } else if (p === HEADER_MENU.TOMORROW_INFO) {
        getOrderClientTomorrow({ dataSearch });
      } else if (p === HEADER_MENU.CANCEL_MENU) {
        getListCancelMenuClient({ ...dataSearch , isDefault: _.isEmpty(_.compact(Object.values(_.omit(paramSearch, ['limit', 'page', 'searchCurrentPath'])))) });
      } else if (p === HEADER_MENU.CANCEL_INFO) {
        getListCancelInfoClient({ dataSearch });
      }  else if (p === 'summary') {
        !summary && history.push('/list?p=receive_all')
      }
    };
  }, [p, paramSearch, roleDetail, statusShowAll]);

  useEffect(() => {
    setActivePageSearch(1);
  },[summary])

  // Do not merge useEffect

  useEffect(() => {
    if(!_.isEmpty(sortData)) {
      const dataSearch = {
        page: activePageTable,
        limit: 10,
        pageProduct: activePageSearch,
        limitProduct: 10,
        productId,
      };
      if (p && idRole && (roleDetail === ROLE.CENTER || roleDetail === ROLE.PARTNERGROUP)) {

        if (p === HEADER_MENU.RECEIVE_INFO) {
          getListOrderDescribe({ dataSearch });
        } else if (p === HEADER_MENU.RECEIVE_ALL) {
          getListRecevieAll({ dataSearch });
        } else if (p === HEADER_MENU.APPOINT_INFO) {
          getListOrderAppointInfo({ dataSearch });
        } else if (p === HEADER_MENU.CONDITION_INFO) {
          getListOrderConditionInfo({ dataSearch });
        } else if (p === HEADER_MENU.SITE_INFO) {
          getListSiteInfoOrder({ dataSearch });
        } else if (p === HEADER_MENU.REPORT_UPLOAD_INFO) {
          getListReportUploadInfo({ dataSearch });
        } else if (p === HEADER_MENU.PREPARATION_INFO) {
          getListPreparationInfo({ dataSearch });
        } else if (p === HEADER_MENU.DELIVERED_INFO) {
          getListDeliveryInfo({ dataSearch });
        } else if (p === HEADER_MENU.TOMORROW_INFO) {
          getListOrderTomorrow({ dataSearch });
        } else if (p === HEADER_MENU.PREPARATION_INFO) {
          getListOrderPartnerReceive({ dataSearch });
        } else if (p === HEADER_MENU.TOMORROW_INFO) {
          getOrderPartnerTomorrow({ dataSearch });
        } else if (p === HEADER_MENU.CANCEL_INFO) {
          getListCancelInfo({ dataSearch });
        } else if (p === HEADER_MENU.CANCEL_MENU) {
          getListCancelMenu({ dataSearch });
        }  else if (p === 'summary') {
          getListSummary({ dataSearch , totalScreen : summary });
        }
      } else if (p && idRole && roleDetail === ROLE.PARTNER) {
        if (p === HEADER_MENU.RECEIVE_ALL) {
          getListReceiveConfirm({ dataSearch });
        } else if (p === HEADER_MENU.REPORT_UPLOAD_INFO) {
          getReportList({ dataSearch });
        } else if (p === HEADER_MENU.SITE_INFO) {
          getListOrderPartnerHonkado({ dataSearch });
        } else if (p === HEADER_MENU.APPOINT_INFO) {
          getListOrderPartnerAppointInfo({ dataSearch });
        } else if (p === HEADER_MENU.DELIVERED_INFO) {
          getReportCompledted({ dataSearch });
        } else if (p === HEADER_MENU.UNOPERATION_LIST) {
          getPartnerUnoperationList({ dataSearch });
        } else if (p === HEADER_MENU.CANCEL_MENU) {
          getListPartnerCancelInfo({ dataSearch });
        } else if (p === HEADER_MENU.TOMORROW_INFO) {
          getOrderPartnerTomorrow({ dataSearch });
        } else if (p === HEADER_MENU.PREPARATION_INFO) {
          getBranchPartner();
          getStaffBranch();
          getListOrderPartnerReceive({ dataSearch });
        }
      } else if (p && idRole && roleDetail === ROLE.CLIENT) {
        if (p === HEADER_MENU.RECEIVE_ALL) {
          getListJyucyu({ dataSearch });
        } else if (p === HEADER_MENU.SITE_INFO) {
          getListHonkadoClient({ dataSearch });
        } else if (p === HEADER_MENU.DELIVERED_INFO) {
          getListDeliveryClient({ dataSearch });
        } else if (p === HEADER_MENU.REPORT_UPLOAD_INFO) {
          getListCompleteList({ dataSearch });
        } else if (p === HEADER_MENU.PREPARATION_INFO) {
          getListSportAccept({ dataSearch });
        } else if (p === HEADER_MENU.TOMORROW_INFO) {
          getOrderClientTomorrow({ dataSearch });
        }  else if (p === 'summary') {
          getListSummary({ dataSearch , totalScreen : summary });
        }
      };
    }
  }, [sortData]);

  useEffect(() => {
    const dataSearch = {
      page: activePageTable,
      limit: 10,
      pageProduct: activePageSearch,
      limitProduct: 10,
      productId,
    };
    if (p && idRole && (roleDetail === ROLE.CENTER || roleDetail === ROLE.PARTNERGROUP)) {
      if (p === HEADER_MENU.RECEIVE_INFO) {
        getListOrderDescribe({ dataSearch });
      } else if (p === HEADER_MENU.RECEIVE_ALL) {
        getListRecevieAll({ dataSearch });
      } else if (p === HEADER_MENU.APPOINT_INFO) {
        getListOrderAppointInfo({ dataSearch });
      } else if (p === HEADER_MENU.CONDITION_INFO) {
        getListOrderConditionInfo({ dataSearch });
      } else if (p === HEADER_MENU.SITE_INFO) {
        getListSiteInfoOrder({ dataSearch });
      } else if (p === HEADER_MENU.REPORT_UPLOAD_INFO) {
        getListReportUploadInfo({ dataSearch });
      } else if (p === HEADER_MENU.PREPARATION_INFO) {
        getListPreparationInfo({ dataSearch });
      } else if (p === HEADER_MENU.DELIVERED_INFO) {
        getListDeliveryInfo({ dataSearch });
      } else if (p === HEADER_MENU.TOMORROW_INFO) {
        getListOrderTomorrow({ dataSearch });
      } else if (p === HEADER_MENU.PREPARATION_INFO) {
        getListOrderPartnerReceive({ dataSearch });
      } else if (p === HEADER_MENU.TOMORROW_INFO) {
        getOrderPartnerTomorrow({ dataSearch });
      } else if (p === HEADER_MENU.CANCEL_INFO) {
        getListCancelInfo({ dataSearch });
      } else if (p === HEADER_MENU.CANCEL_MENU) {
        getListCancelMenu({ dataSearch });
      } else if (p === 'summary') {
        getListSummary({ dataSearch , totalScreen : summary });
      }
    } else if (p && idRole && roleDetail === ROLE.PARTNER) {
      if (p === HEADER_MENU.RECEIVE_ALL) {
        getListReceiveConfirm({ dataSearch });
      } else if (p === HEADER_MENU.REPORT_UPLOAD_INFO) {
        getReportList({ dataSearch });
      } else if (p === HEADER_MENU.SITE_INFO) {
        getListOrderPartnerHonkado({ dataSearch });
      } else if (p === HEADER_MENU.APPOINT_INFO) {
        getListOrderPartnerAppointInfo({ dataSearch });
      } else if (p === HEADER_MENU.DELIVERED_INFO) {
        getReportCompledted({ dataSearch });
      } else if (p === HEADER_MENU.UNOPERATION_LIST) {
        getPartnerUnoperationList({ dataSearch });
      } else if (p === HEADER_MENU.CANCEL_MENU) {
        getListPartnerCancelInfo({ dataSearch });
      } else if (p === HEADER_MENU.TOMORROW_INFO) {
        getOrderPartnerTomorrow({ dataSearch });
      } else if (p === HEADER_MENU.PREPARATION_INFO) {
        getBranchPartner();
        getStaffBranch();
        getListOrderPartnerReceive({ dataSearch });
      }
    } else if (p && idRole && roleDetail === ROLE.CLIENT) {
      if (p === HEADER_MENU.RECEIVE_ALL) {
        getListJyucyu({ dataSearch });
      } else if (p === HEADER_MENU.SITE_INFO) {
        getListHonkadoClient({ dataSearch });
      } else if (p === HEADER_MENU.DELIVERED_INFO) {
        getListDeliveryClient({ dataSearch });
      } else if (p === HEADER_MENU.REPORT_UPLOAD_INFO) {
        getListCompleteList({ dataSearch });
      } else if (p === HEADER_MENU.PREPARATION_INFO) {
        getListSportAccept({ dataSearch });
      } else if (p === HEADER_MENU.TOMORROW_INFO) {
        getOrderClientTomorrow({ dataSearch });
      }  else if (p === 'summary') {
        getListSummary({ dataSearch , totalScreen : summary, });
      }
    };
  }, [activePageTable, activePageSearch]);

  useEffect(() => {
    const dataSearch = {
      page: activePageTable,
      limit: 10,
      pageProduct: activePageSearch,
      limitProduct: 10,
      productId,
    };
    if (p && idRole && (roleDetail === ROLE.CENTER || roleDetail === ROLE.PARTNERGROUP)) {
      if (p === HEADER_MENU.CANCEL_MENU) {
        getListCancelMenu({ ...dataSearch });
      } else if (p === HEADER_MENU.CANCEL_INFO) {
        getListCancelInfo({ ...dataSearch });
      } else {
        setListProduct([]);
      }
    } else if (p && idRole && roleDetail === ROLE.PARTNER) {
      if (p === HEADER_MENU.CANCEL_MENU) {
        getListPartnerCancelInfo({ ...dataSearch, ...sortData });
      } else {
        setListProduct([]);
      }
    } else if (p && idRole && roleDetail === ROLE.CLIENT) {
      if (p === HEADER_MENU.CANCEL_MENU) {
        getListCancelMenuClient({ ...dataSearch });
      } else if (p === HEADER_MENU.CANCEL_INFO) {
        getListCancelInfoClient({ ...dataSearch });
      } else {
        setListProduct([]);
      }
    }
  }, [activePageTable, activePageSearch]);

  const renderShukeiTable = (role) => {
    switch (role) {
      case ROLE.CLIENT:
        return <ShukeiTableClient p={p} getListStatistic={getListSummary} />;
      case ROLE.CENTER:
      case ROLE.PARTNERGROUP:
        return <ShukeiTableMaster p={p} getListStatistic={getListSummary} />;
      default:
        return <></>;
    }
  };

  const handleHide = (id) => {
    if (hideshow === id) {
      setHideshow('');
    } else {
      setHideshow(id);
    }
  };

  const handlePageChangeSearch = (pageNumber) => {
    setActivePageSearch(pageNumber);
  };

  const getListPagination = (p) => {
    if (p && (roleDetail === ROLE.CENTER || roleDetail === ROLE.PARTNERGROUP) && summary && p === HEADER_MENU.RECEIVE_ALL) {
      return getListSummary;
    } else if (p && (roleDetail === ROLE.CENTER || roleDetail === ROLE.PARTNERGROUP)) {
      switch (p) {
        case HEADER_MENU.RECEIVE_INFO:
          return getListOrderDescribe;
        case HEADER_MENU.CONDITION_INFO:
          return getListOrderConditionInfo;
        case HEADER_MENU.APPOINT_INFO:
          return getListOrderAppointInfo;
        case HEADER_MENU.SITE_INFO:
          return getListSiteInfoOrder;
        case HEADER_MENU.CANCEL_INFO:
          return getListCancelInfo;
        case HEADER_MENU.CANCEL_MENU:
          return getListCancelMenu;
        case HEADER_MENU.REPORT_UPLOAD_INFO:
          return getListReportUploadInfo;
        case HEADER_MENU.TOMORROW_INFO:
          return getListOrderTomorrow;
        case HEADER_MENU.PREPARATION_INFO:
          return getListPreparationInfo;
        case HEADER_MENU.DELIVERED_INFO:
          return getListDeliveryInfo;
        case HEADER_MENU.RECEIVE_ALL:
          return getListRecevieAll
        case 'summary':
            return getListSummary
        default:
          return '';
      }
    } else if (p && roleDetail === ROLE.PARTNER) {
      switch (p) {
        case HEADER_MENU.RECEIVE_ALL:
          return getListReceiveConfirm;
        case HEADER_MENU.REPORT_UPLOAD_INFO:
          return getReportList;
        case HEADER_MENU.SITE_INFO:
          return getListOrderPartnerHonkado;
        case HEADER_MENU.APPOINT_INFO:
          return getListOrderPartnerAppointInfo;
        case HEADER_MENU.DELIVERED_INFO:
          return getReportCompledted;
        case HEADER_MENU.CANCEL_MENU:
          return getListPartnerCancelInfo;
        case HEADER_MENU.UNOPERATION_LIST:
          return getPartnerUnoperationList;
        case HEADER_MENU.PREPARATION_INFO:
          return getListOrderPartnerReceive;
        case HEADER_MENU.TOMORROW_INFO:
          return getOrderPartnerTomorrow;
      }
    } else if (p && roleDetail === ROLE.CLIENT) {
      switch (p) {
        case HEADER_MENU.RECEIVE_ALL:
          return getListJyucyu;
        case HEADER_MENU.PREPARATION_INFO:
          return getListSportAccept;
        case HEADER_MENU.CANCEL_MENU:
          return getListCancelMenuClient;
        case HEADER_MENU.CANCEL_INFO:
          return getListCancelInfoClient;
        case HEADER_MENU.DELIVERED_INFO:
          return getListDeliveryClient;
        case HEADER_MENU.SITE_INFO:
          return getListHonkadoClient;
        case HEADER_MENU.REPORT_UPLOAD_INFO:
          return getListCompleteList;
        case HEADER_MENU.TOMORROW_INFO:
          return getOrderClientTomorrow;
        case 'summary':
          return getListSummary;
      }
    }
  };

  return (
    <div className="body-wrapper">
      <SearchMenu nameRoute={nameP} role={role} color={color} colorText={colorText}  />
      <div className="table-menu-wrapper">
        <Loading loading={loading} />
        {(p === 'receive_all' || p === 'summary') && renderShukeiTable(role)}
        {/* {p === 'receive_all' &&
          (roleDetail === ROLE.CENTER || roleDetail === ROLE.PARTNERGROUP) &&
          listProductMain &&
          listProductMain.map((item, index) => {
            return (
              <span key={index} className="master_link_area mr-2">
                <Link to={`/summary-receive-all?idScreenSetting=${item.screenSettingId}`}>
                  <p className="mb-0 link_button master_link">
                    <i className="fa icon-link-ext icon-custom-error" aria-hidden="true" style={{ fontSize: '55px' }} />{' '}
                    {item.nameScreenSetting}
                  </p>
                </Link>
              </span>
            );
          })}
        {p === 'receive_all' && renderShukeiTable(role)}
        <div>
          {p === 'receive_info' && (roleDetail === ROLE.CENTER || roleDetail === ROLE.PARTNERGROUP) && (
            <div className="master_link_area">
              <Link to="/read_import_csv">
                <p className="mb-0 link_button master_link">
                  <i className="fa icon-upload-cloud" aria-hidden="true" style={{ fontSize: '55px' }} /> 新規依頼投入
                </p>
              </Link>
            </div>
          )}
        </div>
        <div>
          {p === 'preparation_info' && (roleDetail === ROLE.CENTER || (roleDetail === ROLE.PARTNER || roleDetail === ROLE.PARTNERGROUP || roleDetail === ROLE.CLIENT)) && (
            <div className="master_link_area">
              <Link to="/list?p=tomorrow_info">
                <p className="mb-0 link_button master_link">
                  <i className="fa icon-link-ext" aria-hidden="true" style={{ fontSize: '55px' }} /> 事前確認
                </p>
              </Link>
            </div>
          )}
        </div>
        <div>
          {p === 'condition_info' && (roleDetail === ROLE.CENTER || (roleDetail === ROLE.PARTNER || roleDetail === ROLE.PARTNERGROUP || roleDetail === ROLE.CLIENT)) && (
            <div className="master_link_area">
              <Link to="/dispatch-all">
                <p className="mb-0 link_button master_link">
                  <i className="fa icon-link-ext" aria-hidden="true" style={{ fontSize: '60px' }} />ディスパッチ
                </p>
              </Link>
            </div>
          )}
        </div> */}
        {listProduct &&
          listProduct.map((item, index) => {
            return (
              <CollapseTable
                getListProduct={getListPagination(p)}
                sortOrderBy={sortOrderBy}
                data={data}
                role={role}
                key={index}
                item={item}
                color={color}
                colorText={colorText}
                handleHide={handleHide}
                hideshow={hideshow}
                roleDetail={roleDetail}
                idRole={idRole}
                p={p}
                t={t}
                activePageTable={activePageTable}
                summary={summary}
                partnerId={partnerId}
                partnerBranch={partnerBranch}
                staffBranch={staffBranch}
                detailCheck={detailCheck}
                getListPreparationInfo={
                  (roleDetail === ROLE.CENTER || roleDetail === ROLE.PARTNERGROUP)
                    ? getListPreparationInfo
                    : roleDetail === ROLE.PARTNER
                    ? getListOrderPartnerReceive
                    : ''
                }
                activePageSearch={activePageSearch}
                activeMenu={activeMenu}
                paramSearch={paramSearch}
              />
            );
          })}
        {product > 10 && (
          <PaginationCustom
            activePage={activePageSearch}
            itemsCountPerPage={10}
            totalItemsCount={product}
            pageRangeDisplayed={5}
            handlePageChange={handlePageChangeSearch}
          />
        )}
      </div>
    </div>
  );
};

export default compose(translate('translations'), withRouter)(TableListV2);
