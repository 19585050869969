import React, { useEffect, useState } from 'react';
import FooterMaintenance from 'components/FooterMaintenance';
import UserList from './UserList';
import ManagerUsers from './ManagerUsers';
import HeaderMasterSetting from 'components/HeaderMasterSetting';
import { useSelector } from 'react-redux';
import { USER_ENDPOINT, MASTER_SETTING } from 'constants/endpoints';
import { getRoleNew } from 'helpers/helpers';
import PaginationCustom from 'components/PaginationCustom';
import { ROLE, NEOMARS_USERS,
  ERP_USERS, MANAGERGROUP_USERS,
  MANAGER_USERS, MONITORING_USERS,
  DEFAULT_ROLES } from 'helpers/constants';
import Loading from 'components/Loading/Loading';
import _ from 'lodash';
import MemoHistory from '../../../components/MemoHistory';
import { SCREEN_NAME } from '../../../helpers/constants';
import 'assets/scss/page/center/Users/edit.scss'

const Users = () => {
  const colorScreen = useSelector((store) => store.utils.colorMe);
  const [idRole, setIdRole] = useState('');
  const [role, setRole] = useState('');
  const [listUser, setListUser] = useState([]);
  const [dataTables, setDataTables] = useState([]);
  const [activePage, setActivePage] = React.useState(1);
  const [roleList, setRoleList] = useState([]);
  const [isShow, setIsShow] = useState('');
  const [loading, setLoading] = useState(false);
  const [moduleManager, setModuleManager] = useState({});

  const getModuleManager = async ({colorId}) => {
    setLoading(true);
    try {
      const data = await MASTER_SETTING.GET_MODULE_MANAGER({colorId});
      if (data) {
        setLoading(false);
        let newData = data?.data;
        newData.management = newData.partner;
        newData.monitoring = newData.client;
        newData.Partner = newData.partner;
        newData.Client = newData.client;
        setModuleManager(newData);
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  useEffect(() => {
    if(colorScreen.colorId) {
      getModuleManager({ colorId: colorScreen.colorId })
    }
  },[colorScreen?.colorId])

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    role === 'NEOMARS' ? getListUsers({pageOrganization: pageNumber}) : getDataTables({page: pageNumber})
  };

  const getDataTables = async ({
      page = 1, limit = 10, pageUser = 1, limitUser = 10, type = '', permissionId = ''
    }) => {
    try {
      setLoading(true);
      const data = await USER_ENDPOINT.GET_LIST_USER_PARTNER({
        page, limit, pageUser, limitUser, type, permissionId
      });
      if (data) {
        setLoading(false);
        setDataTables(data?.data || []);
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const getListUsers = async ({
      page = 1, limit = 10, pageOrganization = 1,
      limitOrganization = 10, organizationId = '', nameRole = 'erp'
    }) => {
    try {
      setLoading(true);
      const data = await USER_ENDPOINT.GET_LIST_USER_ORGANIZATION({
        page,
        limit,pageOrganization,
        limitOrganization,
        organizationId, nameRole
      });
      if (data) {
        setLoading(false);
        setListUser(data?.data || []);
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const handleCollap = (key) => {
    if (isShow === key) {
      setIsShow('');
    } else {
      setIsShow(key);
    }
  }

  useEffect(() => {
    if(!_.isEmpty(roleList)){
      role === 'NEOMARS' ? getListUsers({nameRole: roleList[roleList.length - 1]?.api}) : getDataTables({page: 1})
    }
  }, [roleList])

  const dataList = (role) => {
    switch(role) {
      case ROLE.NEOMARS:
        return NEOMARS_USERS;
      case ROLE.CENTER:
        return ERP_USERS;
      case ROLE.PARTNERGROUP:
        return MANAGERGROUP_USERS;
      case ROLE.PARTNER:
        return MANAGER_USERS;
      case ROLE.CLIENT:
        return MONITORING_USERS;
      default:
        return [];
    }
  }

  useEffect(() => {
    setRoleList(dataList(role));
  }, [role]);

  useEffect(() => {
    const newRole = localStorage.getItem('role');
    setIdRole(newRole);
  }, []);

  const changeLoading = (isLoading = false) => {
    setLoading(isLoading);
  }

  useEffect(() => {
    if (idRole) {
      setRole(getRoleNew({ idRole: idRole }));
    }
  }, [idRole]);
  return (
    <div className="wrapper-master-setting">
      <Loading loading={loading} />
      <HeaderMasterSetting color={colorScreen?.code} colorText={colorScreen?.colorText} />
      {
        <>
          <div className="body pb-4">
            <div className="wrapper-main">
              <div className="wrapper-result">
                <div className="result-title">
                </div>
              </div>
              {
                role === 'NEOMARS' ? (
                  listUser?.organizations?.map((item, index) => {
                    return <UserList key={index} colors={colorScreen?.code} colorText={colorScreen?.colorText} item={item} index={index} role={role} isShow={isShow} handleCollap={handleCollap} changeLoading={changeLoading} />
                  })
                ) : (
                  _.map(_.pick(dataTables, DEFAULT_ROLES), (val, key) => {
                    if(moduleManager?.[key] || key === 'ERP' || key === 'ManagementGroup') {
                      return <ManagerUsers colors={colorScreen?.code}
                      colorText={colorScreen?.colorText} val={val} k={key}
                      role={role} isShow={isShow} handleCollap={handleCollap}
                      changeLoading={changeLoading} count={dataTables[`${key}Count`] || 0} />
                    }
                  })
                )
              }
              <div className="d-flex justify-content-center align-items-center group-button"></div>
            </div>
            {
              !_.isEmpty(listUser) && listUser?.count > 10 && (
                <PaginationCustom
                activePage={activePage}
                itemsCountPerPage={10}
                totalItemsCount={listUser && listUser?.count}
                pageRangeDisplayed={5}
                handlePageChange={handlePageChange} />
              )
            }
            <MemoHistory
              colors={colorScreen?.code}
              colorText={colorScreen?.colorText}
              screenName={SCREEN_NAME.USER_MASTER}
            />
          </div>
        </>
      }
      <FooterMaintenance />
    </div>
  );
};

export default Users;
