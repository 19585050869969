import React, { useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { translate } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import '../../../../assets/scss/page/contract/index.scss';
import Footer from '../../../../components/Footer';
import HeaderMasterSetting from '../../../../components/HeaderMasterSetting';
import { CONTRACT_ENDPOINTS } from '../../../../constants/endpoints';
import { useEffect } from 'react';
import { set } from 'date-fns/esm';
import { pushToast } from '../../../../components/Toast';
import LoadingNew from '../../../../components/LoadingNew';
import MemoHistory from '../../../../components/MemoHistory';
import { SCREEN_NAME } from '../../../../helpers/constants';

const mockData = {
  projectId: '',
  productId: '',
  serviceId: '',
  title: '',
  taxDivision: '',
  form: '',
  claim: '',
  payment: '',
  explanation: '',
  effectiveness: true,
  screenName : SCREEN_NAME.CONTRACT_MANAGEMENT
};

const MasterTarifConfiguration = (props) => {
  const colorScreen = useSelector((store) => store.utils.colorMe);
  let tariffId = process.browser && new URL(window.location.href).searchParams.get('tariffId');
  const history = useHistory();
  const [dataCreate, setDataCreate] = useState(mockData);
  const [listItem, setListItem] = useState([]);
  const [listProject, setListProject] = useState([]);
  const [listProduct, setListProduct] = useState([]);
  const [listService, setListService] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dataError, setDataError] = useState();

  const onReturn = () => {
    history.goBack();
  };

  const getAllProduct = async ({ projectId = '', productId = '' }) => {
    setLoading(true);
    try {
      const data = await CONTRACT_ENDPOINTS.GET_PROVIDER_CONTRACT({
        projectId,
        productId,
      });
      if (projectId === '' && productId === '') {
        if (data) {
          setListProject(data?.data?.providers);
          setLoading(false);
        }
      }
      if (projectId) {
        if (data) {
          setListProduct(data?.data?.providers);
          setLoading(false);
        }
      }
      if (productId) {
        if (data) {
          setListService(data?.data?.providers);
          setLoading(false);
        }
      }
    } catch (e) {
      setLoading(true);
      console.log(e);
    }
  };

  const getListTariffSetting = async ({ tariffId = '' }) => {
    try {
      const data = await CONTRACT_ENDPOINTS.GET_TARIFF_SETTING_LIST({
        tariffId: tariffId,
      });
      if (data) {
        setListItem(data?.data?.tariffSetting);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getAllProduct({});
  }, []);

  useEffect(() => {
    getListTariffSetting({ tariffId: tariffId });
  }, [tariffId]);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const items1 = reorder(listItem, result.source.index, result.destination.index);
    setListItem(items1);
  };

  const handleChangText = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    let newData = { ...dataCreate };
    if (name === 'projectId') {
      newData = { ...newData, [name]: value, tariffId: tariffId };
      getAllProduct({ projectId: value });
      setListService([]);
    }
    if (name === 'productId') {
      newData = { ...newData, [name]: value };
      getAllProduct({ productId: value });
    } else {
      newData = { ...newData, [name]: value };
    }
    setDataCreate(newData);
  };

  const handleChangeItemCheckBox = (sortNum) => {
    const newData = listItem?.map((item) => {
      if (item.sortNum === sortNum) {
        return { ...item, effectiveness: !item.effectiveness };
      } else {
        return item;
      }
    });
    setListItem(newData);
  };

  const handleChangeItem = (e, sortNum) => {
    const valueField = e.target.value;
    const name = e.target.name;
    const newData = listItem?.map((item) => {
      if (item.sortNum === sortNum) {
        return { ...item, [name]: valueField };
      } else {
        return item;
      }
    });
    setListItem(newData);
  };

  const createTariffSetting = async (body) => {
    try {
      const data = await CONTRACT_ENDPOINTS.CREATE_TARIFF_SETTING(body);
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
      } else {
        pushToast('success', 'Create user success');
        getListTariffSetting({ tariffId: tariffId });
        setDataCreate(mockData);
      }
    } catch (errorMessage) {
      console.log('errorMessage: ', errorMessage);
    }
  };

  const deleteTariffSetting = async ({ tariffSettingId = '' }) => {
    try {
      const data = await CONTRACT_ENDPOINTS.DELETE_TARIFF_SETTING({
        tariffSettingId: tariffSettingId,
      });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
      } else {
        pushToast('success', 'Delete user success');
        getListTariffSetting({ tariffId: tariffId });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const updateTariffSetting = async ({ body = [], tariffId = '' }) => {
    try {
      const data = await CONTRACT_ENDPOINTS.UPDATE_TARIFF_SETTING({ body: body, tariffId: tariffId });
      setDataError(data?.data);
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
      } else {
        pushToast('success', 'Update tariff setting success');
        getListTariffSetting({ tariffId: tariffId });
      }
    } catch (errorMessage) {
      console.log('errorMessage: ', errorMessage);
    }
  };

  const submitUpdate = () => {
    const newData =
      listItem &&
      listItem.map((item, index) => {
        return {
          claim: item.claim,
          effectiveness: item.effectiveness,
          explanation: item.explanation,
          form: item.form,
          payment: item.payment,
          taxDivision: item.taxDivision,
          title: item.title,
          tariffSettingId: item.tariffSettingId,
          sortNum: index + 1,
        };
      });
    updateTariffSetting({ body: { tariffSettings: newData , screenName :  SCREEN_NAME.CONTRACT_MANAGEMENT }, tariffId: tariffId });
  };

  const submitDelete = (tariffSettingId) => {
    deleteTariffSetting({ tariffSettingId: tariffSettingId });
  };

  const submitCreate = () => {
    createTariffSetting(dataCreate);
  };

  return (
    <>
      <div className="main">
        <LoadingNew loading={loading} />
        <HeaderMasterSetting color={colorScreen?.code} colorText={colorScreen?.colorText} checkName="/contract" />
        <div className="block-contract">
          <div className="list-table">
            <button className="btn-back" onClick={onReturn}>
              戻る
            </button>
            <div className="table-list-contract">
              <div className="title-recruitment">タリフマスタ設定</div>
              <div className="add-list-contract">
                <div className="row mr-0 ml-0">
                  <div className="col-4">
                    <span className="title-add-list">Project</span>
                    <select
                      className="select-add-contract"
                      onChange={handleChangText}
                      name="projectId"
                      value={dataCreate?.projectId}
                    >
                      <option value="">---</option>
                      {listProject &&
                        listProject.map((item, index) => {
                          return (
                            <option key={index} value={item?.projectCode}>
                              {item?.projectName}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                  <div className="col-4">
                    <span className="title-add-list">Product</span>
                    <select
                      className="select-add-contract"
                      onChange={handleChangText}
                      name="productId"
                      value={dataCreate.productId}
                    >
                      <option value="">---</option>
                      {listProduct &&
                        listProduct.map((item) => {
                          return <option value={item?.productId}>{item?.name}</option>;
                        })}{' '}
                    </select>
                  </div>
                  <div className="col-4">
                    <span className="title-add-list">Service</span>
                    <select
                      className="select-add-contract"
                      onChange={handleChangText}
                      name="serviceId"
                      value={dataCreate.serviceId}
                    >
                      <option value="">---</option>
                      {listService &&
                        listService.map((item) => {
                          return <option value={item?.serviceId}>{item?.name}</option>;
                        })}
                    </select>
                  </div>
                  <div className="col-2">
                    <span className="title-add-list">項目名</span>
                    <input
                      type="text"
                      className="input-add-contract"
                      onChange={handleChangText}
                      name="title"
                      value={dataCreate?.title}
                    />
                  </div>
                  <div className="col-2">
                    <span className="title-add-list">税区分</span>
                    <select
                      className="select-add-contract"
                      onChange={handleChangText}
                      name="taxDivision"
                      value={dataCreate.taxDivision}
                    >
                      <option value="">---</option>
                      <option value="外税">外税</option>
                    </select>
                  </div>
                  <div className="col-2">
                    <span className="title-add-list">形式</span>
                    <select
                      className="select-add-contract"
                      onChange={handleChangText}
                      name="form"
                      value={dataCreate.form}
                    >
                      <option value="">---</option>
                      <option value="選択">選択</option>
                    </select>
                  </div>
                  <div className="col-2">
                    <span className="title-add-list">請求 </span>
                    <input
                      type="number"
                      className="input-add-contract"
                      onChange={handleChangText}
                      name="claim"
                      value={dataCreate.claim}
                    />
                  </div>
                  <div className="col-2">
                    <span className="title-add-list">支払</span>
                    <input
                      type="number"
                      className="input-add-contract"
                      onChange={handleChangText}
                      name="payment"
                      value={dataCreate.payment}
                    />
                  </div>

                  <div className="col-2">
                    <span className="title-add-list">説明</span>
                    <input
                      type="text"
                      className="input-add-contract"
                      onChange={handleChangText}
                      name="explanation"
                      value={dataCreate.explanation}
                    />
                  </div>
                </div>
                <div className="list-button-contact text-right">
                  <button className="item mt-1" onClick={() => submitCreate()}>
                    追加
                  </button>
                </div>
              </div>
              <div className="wrapper-master-setting wrapper-master-setting-custom-contract">
                <table className="form-worker">
                  {listItem?.length > 0 && (
                    <thead>
                      <tr>
                        <th style={{ width: '50px' }}></th>
                        <th className="text-center">Proj</th>
                        <th className="text-center">Prod</th>
                        <th className="text-center">Ser</th>
                        <th className="text-center">項目名</th>
                        <th className="text-center th-select">税区分</th>
                        <th className="text-center th-select">形式</th>
                        <th className="text-center">請求</th>
                        <th className="text-center">支払</th>
                        <th className="text-center">説明</th>
                        <th className="text-center th-checkbox">表示</th>
                      </tr>
                    </thead>
                  )}
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                      {(provided, snapshot) => (
                        <tbody {...provided.droppableProps} ref={provided.innerRef}>
                          {listItem &&
                            listItem.map((item, index) => {
                              let valueError =
                                dataError && dataError.find((i) => i.tariffSettingId === item?.tariffSettingId);
                              return (
                                <Draggable key={`${index + 1}`} draggableId={`${index + 1}`} index={index}>
                                  {(provided, snapshot) => (
                                    <tr
                                      className="item-worker"
                                      key={index}
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <td>
                                        <div className="icon" onClick={() => submitDelete(item?.tariffSettingId)}>
                                          <i className="fas fa-times"></i>
                                        </div>
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          className={`${
                                            valueError && valueError.requestId ? 'text-input-error' : 'text-input'
                                          } version text-left`}
                                          name="projectName"
                                          value={item?.projectName}
                                          disabled
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          className={`${
                                            valueError && valueError.requestId ? 'text-input-error' : 'text-input'
                                          } version text-left`}
                                          name="productName"
                                          disabled
                                          value={item?.productName}
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          className={`${
                                            valueError && valueError.requestId ? 'text-input-error' : 'text-input'
                                          } version text-left`}
                                          name="serviceName"
                                          disabled
                                          value={item?.serviceName}
                                        />{' '}
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          className={`${
                                            valueError && valueError.requestId ? 'text-input-error' : 'text-input'
                                          } version text-left`}
                                          name="title"
                                          value={item?.title}
                                          onChange={(e) => handleChangeItem(e, item.sortNum)}
                                        />{' '}
                                      </td>
                                      <td className="td-select">
                                        <select
                                          className={`${
                                            valueError && valueError.requestId ? 'select-custom-error' : 'select-custom'
                                          } `}
                                          name="taxDivision"
                                          value={item?.taxDivision}
                                          onChange={(e) => handleChangeItem(e, item.sortNum)}
                                        >
                                          <option value="">---</option>
                                          <option value="外税">外税</option>
                                        </select>
                                      </td>
                                      <td className="td-select">
                                        <select
                                          className={`${
                                            valueError && valueError.requestId ? 'select-custom-error' : 'select-custom'
                                          } `}
                                          name="form"
                                          value={item?.form}
                                          onChange={(e) => handleChangeItem(e, item.sortNum)}
                                        >
                                          {' '}
                                          <option value="">---</option>
                                          <option value="選択">選択</option>
                                        </select>
                                      </td>
                                      <td>
                                        <input
                                          type="number"
                                          className={`${
                                            valueError && valueError.requestId ? 'text-input-error' : 'text-input'
                                          } version text-left`}
                                          name="claim"
                                          value={item?.claim}
                                          onChange={(e) => handleChangeItem(e, item.sortNum)}
                                        />{' '}
                                      </td>
                                      <td>
                                        <input
                                          type="number"
                                          className={`${
                                            valueError && valueError.requestId ? 'text-input-error' : 'text-input'
                                          } version text-left`}
                                          name="payment"
                                          value={item?.payment}
                                          onChange={(e) => handleChangeItem(e, item.sortNum)}
                                        />{' '}
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          className={`${
                                            valueError && valueError.requestId ? 'text-input-error' : 'text-input'
                                          } version text-left`}
                                          name="explanation"
                                          value={item?.explanation}
                                          onChange={(e) => handleChangeItem(e, item.sortNum)}
                                        />{' '}
                                      </td>
                                      <td className="align-middle">
                                        <label className="container-checkbox">
                                          <input
                                            type="checkbox"
                                            name="effectiveness"
                                            checked={item?.effectiveness}
                                            onChange={() => handleChangeItemCheckBox(item.sortNum)}
                                          />
                                          <span className="checkmark"></span>
                                        </label>
                                      </td>
                                    </tr>
                                  )}
                                </Draggable>
                              );
                            })}
                        </tbody>
                      )}
                    </Droppable>
                  </DragDropContext>
                </table>
                <div className="list-button-contact text-right">
                  <button className="item mr-0" onClick={() => submitUpdate()}>
                    設定を保存
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <MemoHistory colors={colorScreen?.code} colorText={colorScreen?.colorText} screenName={SCREEN_NAME.CONTRACT_MANAGEMENT}/>
        <Footer color={colorScreen?.code} colorText={colorScreen?.colorText} />
      </div>
    </>
  );
};

export default compose(translate('translations'), withRouter)(MasterTarifConfiguration);
