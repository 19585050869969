import React, { useState, useEffect } from 'react';
import './style.scss';
import { ORDER } from 'constants/endpoints';
import { pushToast } from 'components/Toast';
import { DATETIME_JP_FORMAT, SCREEN_NAME } from 'helpers/constants';
import { withRouter } from 'react-router-dom';
import Loading from 'components/Loading/Loading';
import PopupRemarks from 'components/popupRemarks';
import RejectMemo from 'components/RejectMemo';
import _ from 'lodash';
import moment from 'moment';

const defaulfUpload = {
  'erpFileReportFirst': '',
  'erpFileReportSecond': ''
};

const listParam = ['comment', 'status', 'name', 'key', 'timestamp', 'url', 'numberOfMaterials'];

const DetailTableUploadReportUploadInfo = ({
   data,
   idRole,
   onReload,
   title = '報告書',
   back = '',
   history,
   documentSetting = [],
   onChangeData = () => {}
 }) => {
  let orderId = process.browser && new URL(window.location.href).searchParams.get('orderId');
  let organizationId = process.browser && new URL(window.location.href).searchParams.get('organizationId') || '';
  let partnerId = process.browser && new URL(window.location.href).searchParams.get('partnerId');
  let name = (process.browser && new URL(window.location.href).searchParams.get('p')) || 'report_upload_info';
  const [dataOrder, setDataOrder] = useState(data);
  const [fileName, setFileName] = useState(defaulfUpload);
  const [contentFile, setContentFile] = useState(defaulfUpload);
  const [loading, setLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [showPopupConfirm, setShowPopupConfirm] = useState(false);

  useEffect(() => {
    setDataOrder(data);
  }, [data]);

  useEffect(() => {
    onChangeData(_.pick({ ...dataOrder, screenName: SCREEN_NAME.REPORT_UPLOAD_INFO }, PICK_REPORT_UPLOAD_INFO));
  }, [dataOrder]);

  const handleChangeComment = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setDataOrder({
      ...dataOrder,
      [name]: {
        ...dataOrder[name],
        comment: value
      }
    });
  };

  useEffect(() => {
    let checked = true;
    let value = documentSetting?.map((ele) => {
      if (dataOrder[ele?.timestamp] && dataOrder[ele?.timestamp]?.status === false && !ele?.uploadFile) {
        checked = false;
      }
    });
    setIsDisabled(checked);
  }, [dataOrder]);

  const handleChangeStatus = ({ nameType, value, numberOfMaterials }) => {
    const dataHandle = {
      [nameType]: {
        ...dataOrder[nameType],
        status: value,
        timestamp: moment(new Date()).format(DATETIME_JP_FORMAT),
        numberOfMaterials: numberOfMaterials,
      }
    }
    setDataOrder({
      ...dataOrder,
      ...dataHandle,
    });
  };

  const handleUpdate = () => {
    const body = _.pick({
      ...dataOrder,
      screenName: SCREEN_NAME.REPORT_UPLOAD_INFO,
      statusReportUploadInfo: false,
      updateTitle: !(dataOrder?.reportDeliveredSettings)?'納品-確認済':'納品',
      companyName: localStorage.getItem('companyName'),
      nameApplication: '報告書',
    }, [...PICK_REPORT_UPLOAD_INFO, 'statusReportUploadInfo']);
    handleUpdateReport({ body, orderId });
  };

  const onRequestConfirm = () => {
    const body = _.pick({
      ...dataOrder,
      screenName: SCREEN_NAME.REPORT_UPLOAD_INFO,
      updateTitle: '納品処理',
      nameApplication: '報告書',
      companyName: localStorage.getItem('companyName'),
    }, [...PICK_REPORT_UPLOAD_INFO]);
    handleRequestConfirm({ body, orderId });
  }

  const handleUpdateReport = async ({ body, orderId }) => {
    try {
      setLoading(true);
      const data = await ORDER.UPDATE_REPORT_UPLOAD_INFO({ body, orderId, organizationId });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
        setLoading(false);
      } else {
        pushToast('success', 'Update Report Success');
        setLoading(false);
        onReload();
        if(['2', 2, '3', 3, '5', 5, '6', 6].includes(idRole)){
          return history.push('/confirm-page');
        }else{
          return history.push(`/list?p=report_upload_info`);
        }
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const handleRequestConfirm = async ({ body, orderId }) => {
    try {
      setLoading(true);
      const data = await ORDER.REQUEST_CONFIRM_REPORT_UPLOAD({ body, orderId, organizationId });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
        setLoading(false);
      } else {
        pushToast('success', 'Request Confirm Report Success');
        setLoading(false);
        onReload();
        if(['2', 2, '3', 3, '5', 5, '6', 6].includes(idRole)){
          return history.push('/confirm-page');
        }else{
          return history.push(`/list?p=report_upload_info`);
        }
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const handleDataPick = () => {
    let pick = documentSetting.map(e => {
      if (dataOrder[e.timestamp]) {
        dataOrder[e.timestamp] = _.pick(dataOrder[e.timestamp], listParam);
      }
      return e.timestamp;
    });
    pick = [
      ...pick,
      'screenName',
      'updateTitle',
      'companyName',
      'nameApplication',
    ];
    return pick;
  };
  const PICK_REPORT_UPLOAD_INFO = handleDataPick();

  const handleReject = () => {
    const body = _.pick({ ...dataOrder, screenName: SCREEN_NAME.REPORT_UPLOAD_INFO, updateTitle: '差戻', companyName: localStorage.getItem('companyName'), nameApplication: '報告書' }, PICK_REPORT_UPLOAD_INFO);
    handleRejectOrder({ body, orderId });
  };

  const onRejectRequest = () => {
    rejectRequestConfirm({ orderId, screenName: SCREEN_NAME.REPORT_UPLOAD_INFO, updateTitle: '差戻', companyName: localStorage.getItem('companyName'), nameApplication: '報告書', reportUploadInfoMemo: dataOrder?.reportUploadInfoMemo });
  }

  const rejectRequestConfirm = async ({ orderId = '', screenName = '', reportUploadInfoMemo = '', companyName = '', nameApplication = '', updateTitle = '' }) => {
    try {
      setLoading(true);
      const data = await ORDER.REJECT_REQUEST_REPORT_UPLOAD_INFO({ orderId, body: { screenName, reportUploadInfoMemo, companyName, updateTitle }, organizationId });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
        setLoading(false);
      } else {
        pushToast('success', 'Reject request order success');
        history.push(back === 'confirm' ? '/confirm-page' : '/list?p=report_upload_info');
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const handleTextArea = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    const newData = {...dataOrder, [name]: value};
    setDataOrder(newData);
  };

  const handleRejectOrder = async ({ body = {}, orderId = '' }) => {
    try {
      setLoading(true);
      const data = await ORDER.UPDATE_DEFECTIVE_RETURN({ body, id_order: orderId, organizationId });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
        setLoading(false);
      } else {
        pushToast('success', 'Update order success');
        setLoading(false);
        if(['2', 2, '3', 3, '5', 5, '6', 6].includes(idRole)){
          return history.push('/confirm-page');
        }else{
          return history.push(`/list?p=report_upload_info`);
        }

      }
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const handleUpload = async ({ nameType, numberOfMaterials }) => {
    const body = {
      [nameType]: {
        file: contentFile?.[nameType],
        fileName: fileName?.[nameType],
        numberOfMaterials
      },
      screenName: SCREEN_NAME.REPORT_UPLOAD_INFO,
      updateTitle : 'アップロード',
      nameApplication: '報告書',
      companyName: localStorage.getItem('companyName'),
    };
    handleUploadFile({ body, orderId });
  };

  const handleUploadFile = async ({ body, orderId }) => {
    try {
      const data = await ORDER.UPLOAD_FILES_REPORT({ body, orderId, organizationId });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
      } else {
        pushToast('success', 'Upload success');
        onReload();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const onChangeHandler = async ({ e, nameType }) => {
    const files = e?.target?.files[0];
    const name = e?.target?.files[0]?.name;
    setFileName({
      ...fileName,
      [nameType]: name
    });

    setContentFile({
      ...contentFile,
      [nameType]: await toBase64(files)
    });
  };

  const handleLinkWorker = () => {
    history.push(
      `/report-order?orderId=${orderId}&organizationId=${organizationId}&partnerId=${partnerId}&menuName=workOnTheDay`
    );
  };

  const handleAction = () => {
    setShowPopupConfirm(!showPopupConfirm);
  };

  return (
    <div className='upload-wrapper'>
      <Loading loading={loading} />
      <div className='form-wrapper'>
        <div className='title-absolute cursor-pointer'>
          {title}
        </div>
        <div className='table-upload'>

          <div className='row-table'>
            <div className='header-row'>
              <div className='item-header full'>
                <p>Worker報告書</p>
              </div>
            </div>
            <div className='content-row'>
              <button
                onClick={() => handleLinkWorker()}
                className='btn-modal'>Worker確認
              </button>

            </div>
          </div>

          {documentSetting?.map((elm, index) => {
            return (
              <div key={index} className='row-table'>
                <div className='header-row'>
                  <div className='group-header-full'>
                    <div className='item-header'>
                      <p>{elm.numberOfMaterials}</p>
                    </div>
                  </div>
                </div>
                {elm.uploadFile ? (
                  <>
                    <div className='group-content-row'>
                      <div className='content-row'>
                        <label htmlFor={elm.timestamp} className='btn-modal'>
                          ファイル選択
                        </label>
                        <input
                          type='file'
                          name='file'
                          id={[elm.timestamp]}
                          className='d-none input-contract'
                          onChange={(e) => onChangeHandler({ e, nameType: elm.timestamp })}
                          disabled={(dataOrder?.reportUploadApproval && dataOrder?.statusReportUploadRequest) || ['2', 2, '3', 3, '5', 5, '6', 6].includes(idRole)}
                        />

                        <input
                          type='text' disabled
                          value={fileName[elm.timestamp] || dataOrder[elm.timestamp]?.name}
                          className={`input-modal ${elm?.isRequired && !(fileName[elm.timestamp] || dataOrder[elm.timestamp]?.name) && 'content__input--required'}`} />
                      </div>
                    </div>
                    <div className='group-content-row'>
                      <div className='content-row'>
                        <button
                          className='btn-modal'
                          id={elm.timestamp}
                          disabled={(dataOrder?.reportUploadApproval && dataOrder?.statusReportUploadRequest) || ['2', 2, '3', 3, '5', 5, '6', 6].includes(idRole)}
                          onClick={() => handleUpload({
                            nameType: elm.timestamp,
                            numberOfMaterials: elm.numberOfMaterials
                          })}
                        >アップロード
                        </button>
                        {elm.uploadFromAndTo ? (<button
                          className='btn-modal'
                          disabled={!dataOrder ?.[elm.timestamp]?.url}
                          onClick={() => window.open(`${dataOrder ?.[elm.timestamp]?.url}`)}
                        >ダウンロード</button>) : (<div className='empty-div'></div>)}
                      </div>
                    </div>
                    <div className='group-content-row'>
                      <div className='content-row'>
                        {
                          (_.isEmpty(dataOrder ?.[elm.timestamp]) || !dataOrder ?.[elm.timestamp]?.status) ?
                            <input
                              type='text'
                              onChange={handleChangeComment}
                              name={elm.timestamp}
                              value={dataOrder ?.[elm.timestamp]?.comment}
                              disabled
                              className='input-modal' /> : <div className='empty-div'></div>
                        }
                        <p className='text-time'>
                          {dataOrder[elm.timestamp]?.timestamp}
                        </p>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className='group-content-row'>
                      <div className='content-row'>
                        <button className='download-btn'>
                          <p>
                            {dataOrder?.[elm.timestamp]?.name}
                          </p>
                        </button>
                      </div>
                    </div>
                    <div className='group-content-row'>
                      <div className='content-row'>
                        <button
                          className='btn-modal'
                          disabled={!dataOrder ?.[elm.timestamp]?.url}
                          onClick={() => window.open(`${dataOrder ?.[elm.timestamp]?.url}`)}
                        >ダウンロード
                        </button>
                        <button
                          onClick={() => handleChangeStatus({ nameType: elm.timestamp, value: true, numberOfMaterials: elm.numberOfMaterials })}
                          className={`btn-modal ${(dataOrder ?.[elm.timestamp]?.status || !_.has(dataOrder ?.[elm.timestamp], 'status')) ? '' : 'isCheck'}`}
                        >OK
                        </button>
                        <button
                          onClick={() => handleChangeStatus({ nameType: [elm.timestamp], value: false, numberOfMaterials: elm.numberOfMaterials })}
                          className={`btn-modal ${(!dataOrder?.[elm.timestamp]?.status || !_.has(dataOrder ?.[elm.timestamp], 'status')) ? '' : 'isCheck'}`}
                        >NG
                        </button>
                      </div>
                    </div>
                    <div className='group-content-row'>
                      <div className='content-row'>
                        {
                          (_.isEmpty(dataOrder ?.[elm.timestamp]) || !dataOrder ?.[elm.timestamp]?.status) ?
                            <input
                              type='text'
                              onChange={handleChangeComment}
                              name={elm.timestamp}
                              value={dataOrder ?.[elm.timestamp]?.comment}
                              className='input-modal' /> : <div className='empty-div'></div>
                        }

                        <p className='text-time'>
                          {dataOrder ?.[elm.timestamp]?.timestamp}
                        </p>
                      </div>
                    </div>
                  </>
                )}
              </div>);
          })}

          {
            !['2', 2, '3', 3, '5', 5, '6', 6].includes(idRole) && dataOrder?.reportUploadApproval && (
              <div className='row-table'>
                <div className='header-row'>
                  <div className='group-header-full'>
                    <div className='item-header'>
                      <p>差戻コメント</p>
                    </div>
                  </div>
                </div>
                <div className='group-content-row group-content-row__textarea'>
                  <textarea
                    name="reportUploadInfoMemo"
                    value={dataOrder?.reportUploadInfoMemo}
                    rows='5'
                    cols='50'
                    disabled
                    className='group-content-row__textarea--modify'
                  ></textarea>
                </div>
              </div>
            )
          }
        </div>
        {back !== 'confirm' &&
          <div className='group-btn-update'>
            <button className={`btn-modal ${(!isDisabled) ? 'isCheck' : ''}`}
                    disabled={!isDisabled || (dataOrder?.reportUploadApproval && dataOrder?.statusReportUploadRequest) || ['2', 2, '3', 3, '5', 5, '6', 6].includes(idRole)}
                    onClick={dataOrder?.reportUploadApproval ? onRequestConfirm : handleUpdate}
            >
              {!((
                (dataOrder?.reportUploadApproval && dataOrder?.statusReportUploadRequest) ||
                ['2', 2, '3', 3, '5', 5, '6', 6].includes(idRole)
              ) && dataOrder?.reportUploadApproval)
                ? (dataOrder?.reportUploadApproval ? '納品処理':'納品')
                : (dataOrder?.reportUploadApproval && dataOrder?.statusReportUploadRequest) ||
                  ['2', 2, '3', 3, '5', 5, '6', 6].includes(idRole)
                ? '承認待ち'
                : '納品'}          </button>
            <button className={`btn-modal ${isDisabled ? 'isCheck' : ''}`}
                    disabled={isDisabled || (dataOrder?.reportUploadApproval && dataOrder?.statusReportUploadRequest) || ['2', 2, '3', 3, '5', 5, '6', 6].includes(idRole)}
                    onClick={handleReject}>差戻
            </button>
          </div>
        }
        {
          back === 'confirm' && dataOrder?.reportUploadApproval && (
            <div className='group-btn-update'>
              <button className={`btn-modal`}
                      disabled={!dataOrder?.statusReportUploadRequest}
                      onClick={handleUpdate}
              >承認
              </button>
              <button className={`btn-modal`}
                      disabled={!dataOrder?.statusReportUploadRequest}
                      onClick={handleAction}>差戻
              </button>
            </div>
          )
        }
      </div>
      <PopupRemarks
        headerTitle="差戻コメント"
        rederBody={() => {
          return (
            <textarea name='reportUploadInfoMemo' className="popup-confirm-custom__body--textarea" onChange={handleTextArea}>{dataOrder?.reportUploadInfoMemo}</textarea>
          );
        }}
        okText="登録"
        onConfirm={onRejectRequest}
        cancelText="キャンセル"
        onCancel={() => {
          setShowPopupConfirm(false);
        }}
        showPopup={showPopupConfirm}
        onToggle={() => setShowPopupConfirm(!showPopupConfirm)}
      />
    </div>
  );
};

export default withRouter(DetailTableUploadReportUploadInfo);
