import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import Header from 'components/Header';
import { translate } from 'react-i18next';
import './style.scss';
import Footer from 'components/Footer';
import { HEADER_MENU } from 'helpers/constants';
import { getListOrder } from 'actions/order';
import { connect, useSelector, useDispatch } from 'react-redux';
import { getRole, getRoleColor } from 'helpers/helpers';
import { ROLE } from 'helpers/constants';
import TableListV2 from 'components/TableListV2';
import { pushToast } from 'components/Toast';
import ReadAndImportCsv from '../ReadAndImportCsv';
import { MASTER_SETTING } from 'constants/endpoints';
import { getColorMe, getLogo } from 'actions/utils';

const Index = (props) => {
  const { location, t } = props;
  const colorScreen = useSelector((store) => store.utils.colorMe);
  let name = (process.browser && new URL(window.location.href).searchParams.get('p')) || 'receive_all';
  const [p, setP] = React.useState(name || HEADER_MENU.RECEIVE_ALL);
  const [role, setRole] = React.useState(null);
  const [idRole, setIdRole] = React.useState(null);
  const [roleDetail, setRoleDetail] = useState('');
  const [statusShowAll, setStatusShowAll] = useState(false);
  const [detailCheck, setDetailCheck] = useState();
  const [activeMenu, setActiveMenu] = useState('全情報');
  const dispatch = useDispatch();
  const dispatchAddToCart = (message) => dispatch(getColorMe(message));
  const dispatchAddLogoToCart = (message) => dispatch(getLogo(message));

  const showAll = (status, active = '') => {
    setStatusShowAll(status);
    setActiveMenu(active);
  };

  useEffect(() => {
    if (idRole) {
      setRoleDetail(getRole({ idRole }));
      const idProvide = localStorage.getItem('idProvide');
      if (idProvide) getColorInfo({ role: idRole, idProvide });
    }
  }, [idRole]);

  useEffect(() => {
    setP(name);
  }, [name]);

  useEffect(() => {
    const newRole = localStorage.getItem('role');
    setIdRole(newRole);
    location.isPermission && pushToast('error', 'Permission Denied');
    if (newRole) {
      setRole(getRole({ idRole: newRole }));
    }
    getListOptionSetting();
  }, []);

  const getListOptionSetting = async () => {
    const idProvider = localStorage.getItem('idProvide')
    try {
      const data = await MASTER_SETTING.GET_LIST_OPTION_SETTING(idProvider);
      if (data?.data) {
        setDetailCheck(data?.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getColorInfo = async ({ role, idProvide = '' }) => {
    try {
      const data = await MASTER_SETTING.GET_LIST_COLOR_BY_ORGANIZATION(1, idProvide);
      if (data) {
        const color = data?.data?.[getRoleColor({ idRole: role })];
        const colorMaster = {
          code: color?.codeColor,
          colorText: color?.colorText,
          colorId: data?.data?.colorId,
        }
        dispatchAddLogoToCart(data?.data);
        dispatchAddToCart(colorMaster);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <div className="main-list">
        <Header
          p={p === 'summary' ? 'receive_all' : p}
          idRole={idRole}
          color={colorScreen?.code}
          colorText={colorScreen?.colorText}
          showAll={showAll}
          detailCheck={detailCheck}
          roleDetail={roleDetail}/>
        {p === 'receive_info' && roleDetail === ROLE.CLIENT ? (
          <ReadAndImportCsv HideHeader={false} />
        ) : (
          <>
            <TableListV2
              idRole={idRole}
              p={p}
              t={t}
              role={role}
              color={colorScreen?.code}
              roleDetail={roleDetail}
              statusShowAll={statusShowAll}
              colorText={colorScreen?.colorText}
              detailCheck={detailCheck}
              activeMenu={activeMenu}
            />
          </>
        )}
        <Footer color={colorScreen?.code} colorText={colorScreen?.colorText} />
      </div>
      {/*</BaseCheckAuthen>*/}
    </>
  );
};

const mapStateToProps = (store) => {
  return {
    listOrder: store.order.listOrder,
    loading: store.utils.loading,
  };
};

const mapActionToProps = {
  getListOrder,
};

export default compose(translate('translations'), withRouter, connect(mapStateToProps, mapActionToProps))(Index);
