import React, { useState, useEffect } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { translate } from 'react-i18next';
import { Collapse } from 'reactstrap';
import { compose } from 'redux';
import '../../../../assets/scss/page/CsvMaster/index.scss';
import PaginationCustom from '../../../../components/PaginationCustom';
import _ from 'lodash';

const CollapItem = ({ colors, item, handleHide, hideshow, colorText, t, dataHeader }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [dataAccouting, setDataAccouting] = useState(item);
  const [activePage, setActivePage] = useState(1);

  const toggle = () => {
    setIsOpen(!isOpen);
    handleHide(dataAccouting.recruitmentContractId);
  };

  useEffect(() => {
    setDataAccouting(item);
  }, [item]);

  const handleChangePageAccount = (pageNumber) => {
    setActivePage(pageNumber);
  };

  return (
    <div className="setting-master-menu-wrapper">
      <div className="wrapper-btn-colappse">
        <div
          onClick={toggle}
          className="mb-1 searchbar d-flex justify-content-between align-items-center"
          style={{ background: `${colors}` }}
        >
          <div className="ml-2" style={{ color: `${colorText}` }}>
            {`${dataAccouting?.tariffName}（${dataAccouting?.recruitmentContract?.title}）`}
          </div>
          <div className="mr-2" style={{ color: `${colorText}` }}>
            {isOpen ? <i className="icon-up-open icon-searchbar" /> : <i className="icon-down-open icon-searchbar" />}
          </div>
        </div>
      </div>
      <div>
        <Collapse isOpen={hideshow === dataAccouting?.recruitmentContractId}>
          <div className="table-list-contract">
            <div className="title-table">
              <span>請求：\1,500,000　請求率53％　支払：\950,000　支払率：95％　請求予測：…　支払予測…　</span>
            </div>
            <table className="table table-bordered">
              <tbody>
                <tr>
                  {dataHeader &&
                    dataHeader?.map((item, index) => (
                      <td key={index}>
                        {item === 'earnings (OP)' ? (
                          <>{t('accounting_header.sales')}</>
                        ) : item === 'payment (OP)' ? (
                          <> {t('accounting_header.Paymentop')}</>
                        ) : (
                          <>{t(`accounting_header.${item}`)}</>
                        )}
                      </td>
                    ))}
                </tr>
                {dataAccouting?.accounts?.map((itemIn, index) => {
                  return (
                    <tr key={index}>
                      <td>{itemIn.acceptanceMonth}</td>
                      <td>{itemIn.companyName}</td>
                      <td>{itemIn.earnings}</td>
                      <td>{itemIn.earningsOP}</td>
                      <td>{itemIn.orderNumberAcceptance}</td>
                      <td>{itemIn.paymentMonth}</td>
                      <td>{itemIn.payment}</td>
                      <td>{itemIn.paymentOP}</td>
                      <td>{itemIn.orderNumberPayment}</td>
                      <td>{itemIn.state}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {!_.isEmpty(dataAccouting?.accounts) && dataAccouting?.count > 10 && (
              <PaginationCustom
                activePage={activePage}
                itemsCountPerPage={10}
                totalItemsCount={dataAccouting?.count}
                pageRangeDisplayed={5}
                handlePageChange={handleChangePageAccount}
              />
            )}
          </div>
        </Collapse>
      </div>
    </div>
  );
};

export default compose(translate('translations'))(CollapItem);
