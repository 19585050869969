import React, { useEffect, useState } from 'react';
import { ORGANIZATION_ENDPOINT } from '../../../../constants/endpoints';
import { pushToast } from '../../../../components/Toast';

const mockData = {
  code: '',
  nameKana: '',
  name: '',
  officialName: '',
  officialNameKana: '',
  postalCode: '',
  prefecture: '',
  address: '',
  addressKana: ''
};

const TableInformation = (props) => {
  const { history } = props;
  const [dataOrganization, setDataOrganization] = useState(mockData);
  const [submit, setSubmit] = useState(false);
  const [postalCode1, setPostalCode1] = useState('');
  const [postalCode2, setPostalCode2] = useState('');

  const getCode = async () => {
    try {
      const data = await ORGANIZATION_ENDPOINT.GET_CODE();
      if (data) {
        let newData = { ...dataOrganization, code: data?.data?.code };
        setDataOrganization(newData);
      }
    } catch (e) {
      console.log(e);
    }
  };


  const createOrganization = async (body) => {
    try {
      const data = await ORGANIZATION_ENDPOINT.CREATE_ORAGINIZATION(body);

      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
      } else {
        pushToast('success', 'Create Organization success');
        history.push('/ListMaintenance');
      }

    } catch (e) {
      console.log(e);
    }
  };

  const handleChangeText = (e) => {
    let type = e.target.name;
    let newDataClient = { ...dataOrganization, [type]: e.target.value };
    setDataOrganization(newDataClient);
  };

  const onClickGetCode = () => {
    getCode();
  };

  const onClickSubmit = () => {
    createOrganization(dataOrganization);
  };

  const onChangePostalCode = (e) => {
    const type = e.target.name;
    if (type === 'postalCode1') {
      setPostalCode1(e.target.value);
    } else {
      setPostalCode2(e.target.value);
    }
  };

  useEffect(() => {
    let newDataClient = { ...dataOrganization, postalCode: postalCode1 + '-' + postalCode2 };
    setDataOrganization(newDataClient);
  }, [postalCode1, postalCode2]);

  useEffect(() => {
    if (
      dataOrganization.code.length > 0 &&
      dataOrganization.name.length > 0 &&
      dataOrganization.nameKana.length > 0
    // &&
    // dataOrganization.officialName.length > 0 &&
    // dataOrganization.officialNameKana.length > 0 &&
    // dataOrganization.address.length > 0 &&
    // dataOrganization.addressKana.length > 0 &&
    // postalCode1.length > 0 &&
    // postalCode2.length > 0
    ) {
      setSubmit(true);
    } else {
      setSubmit(false);
    }
  }, [dataOrganization]);

  return (
    <div align="center">
      <div>
        <span>クライアント登録</span>
      </div>

      <table width="100%" className="formTbl mb20">
        <colgroup>
          <col width="20%"/>
          <col width="80%"/>
        </colgroup>
        <tbody>
        <tr>
          <th colSpan="2">基本情報（入力必須）</th>
        </tr>
        <tr>
          <th>
            クライアントコード
            <br/>
            （半角英数5文字）
          </th>
          <td>
            <input
              type="text"
              value={dataOrganization?.code}
              className={`min ajaxkey check ${dataOrganization?.code === '' ? 'input-err' : 'check_ok'}`}
              readOnly="readonly"
            />
            <button className='ml-2 btn button-get-code' onClick={() => onClickGetCode()}>新規コードを取得</button>
          </td>
        </tr>
        <tr>
          <th>クライアント名</th>
          <td style={{ whiteSpace: 'nowrap' }}>
            <select name="client_name0" className="client_name_val">
              <option value="、">法人格</option>
              <option value="株式会社、カブシキガイシャ、前">前(株)</option>
              <option value="株式会社、カブシキガイシャ、後" selected="selected">
                後(株)
              </option>
              <option value="有限会社、ユウゲンガイシャ、前">前(有)</option>
              <option value="有限会社、ユウゲンガイシャ、後">後(有)</option>
            </select>
            <input
              type="text"
              className={`lengM client_name_val check ${dataOrganization?.name === '' ? 'input-err' : 'check_ok'}`}
              name='name'
              value={dataOrganization?.name}
              onChange={handleChangeText}
            />
            <span className="kanaspan">株式会社</span>
          </td>
        </tr>
        <tr>
          <th>クライアント名カナ</th>
          <td style={{ whiteSpace: 'nowrap' }}>
            <input
              type="text"
              className={`lengM client_name_val toZenKata check ${dataOrganization?.name === '' ? 'input-err' : 'check_ok'}`}
              value={dataOrganization?.nameKana}
              name='nameKana'
              onChange={handleChangeText}
            />
            <span className="kanaspan">カブシキガイシャ</span>

            <input type="hidden" name="client_name_kana" value="ソフトバンクカブシキガイシャ"/>
          </td>
        </tr>
        <tr>
          <th colSpan="2">請求情報</th>
        </tr>
        <tr>
          <th>代表者名</th>
          <td>
            <input type="text" name="client_representative" className="lengS" value={dataOrganization?.officialName}
                   onChange={handleChangeText} name='officialName'/>
          </td>
        </tr>
        <tr>
          <th>フリガナ</th>
          <td>
            <input type="text" name="client_representative_kana" className="lengS"
                   value={dataOrganization?.officialNameKana} onChange={handleChangeText} name='officialNameKana'/>
          </td>
        </tr>
        <tr>
          <th>郵便番号</th>
          <td>
            <input type="text" name="postalCode1" value={postalCode1} onChange={onChangePostalCode} maxLength="4"
                   size="3"/> -{' '}
            <input type="text" name="postalCode2" value={postalCode2} onChange={onChangePostalCode} maxLength="4"
                   size="4"/>
          </td>
        </tr>
        <tr>
          <th>都道府県</th>
          <td>
            <select name='prefecture' onChange={handleChangeText}>
              <option value="" selected="selected"></option>
              <option value="北海道">北海道</option>
              <option value="青森県">青森県</option>
              <option value="岩手県">岩手県</option>
              <option value="宮城県">宮城県</option>
              <option value="秋田県">秋田県</option>
              <option value="山形県">山形県</option>
              <option value="福島県">福島県</option>
              <option value="東京都">東京都</option>
              <option value="神奈川県">神奈川県</option>
              <option value="埼玉県">埼玉県</option>
              <option value="千葉県">千葉県</option>
              <option value="茨城県">茨城県</option>
              <option value="栃木県">栃木県</option>
              <option value="群馬県">群馬県</option>
              <option value="山梨県">山梨県</option>
              <option value="新潟県">新潟県</option>
              <option value="長野県">長野県</option>
              <option value="石川県">石川県</option>
              <option value="富山県">富山県</option>
              <option value="福井県">福井県</option>
              <option value="愛知県">愛知県</option>
              <option value="岐阜県">岐阜県</option>
              <option value="静岡県">静岡県</option>
              <option value="三重県">三重県</option>
              <option value="大阪府">大阪府</option>
              <option value="兵庫県">兵庫県</option>
              <option value="京都府">京都府</option>
              <option value="滋賀県">滋賀県</option>
              <option value="奈良県">奈良県</option>
              <option value="和歌山県">和歌山県</option>
              <option value="鳥取県">鳥取県</option>
              <option value="島根県">島根県</option>
              <option value="岡山県">岡山県</option>
              <option value="広島県">広島県</option>
              <option value="山口県">山口県</option>
              <option value="徳島県">徳島県</option>
              <option value="香川県">香川県</option>
              <option value="愛媛県">愛媛県</option>
              <option value="高知県">高知県</option>
              <option value="福岡県">福岡県</option>
              <option value="佐賀県">佐賀県</option>
              <option value="長崎県">長崎県</option>
              <option value="熊本県">熊本県</option>
              <option value="大分県">大分県</option>
              <option value="宮崎県">宮崎県</option>
              <option value="鹿児島県">鹿児島県</option>
              <option value="沖縄県">沖縄県</option>
            </select>
          </td>
        </tr>
        <tr>
          <th>住所</th>
          <td>
            <input type="text" className="lengM" value={dataOrganization?.address} onChange={handleChangeText}
                   name='address'/>
          </td>
        </tr>
        <tr>
          <th>フリガナ</th>
          <td>
            <input
              type="text"
              className="lengM"
              value={dataOrganization?.addressKana} onChange={handleChangeText}
              name='addressKana'
            />
          </td>
        </tr>
        </tbody>
      </table>
      <div align="center" className="submit_div mt-2">
        <button className={`cancel_submit submitbutton btn btnS ${submit ? 'btnSuccess' : ''}`}
                onClick={() => onClickSubmit()}
                disabled={!submit}>更新
        </button>
      </div>
    </div>
  );
};

export default TableInformation;
