import React, { useEffect, useState } from 'react';
import { translate } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useHistory, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import '../../../../assets/scss/page/contract/index.scss';
import Footer from '../../../../components/Footer';
import HeaderMasterSetting from '../../../../components/HeaderMasterSetting';
import Loading from '../../../../components/Loading/Loading';
import PaginationCustom from '../../../../components/PaginationCustom';
import { CONTRACT_ENDPOINTS } from '../../../../constants/endpoints';
import CollapItem from './CollapItem';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import { DATETIME_EXPORT_JP_FORMAT } from 'helpers/constants';

const mockupData = [
  { id: 1, name: '丸丸商事（端末設置）' },
  { id: 2, name: 'AAA社（LAN工事）' },
];

const CompanyContract = (props) => {
  const history = useHistory();
  const colorScreen = useSelector((store) => store.utils.colorMe);
  const [isOpen, setIsOpen] = useState(false);
  const [idRole, setIdRole] = useState(null);
  const toggle = () => setIsOpen(!isOpen);
  const [hideshow, setHideshow] = useState('');
  const [dataListContract, setDataListContract] = useState({});
  const [activePage, setActivePage] = useState(1);
  const [activePageContract, setActivePageContract] = useState(1);
  const [loading, setLoading] = useState(false);
  const [defaultCsv, setDefaultCsv] = useState('');
  const companyName= localStorage.getItem('companyName');

  const handleHide = (id) => {
    if (hideshow === id) {
      setHideshow('');
    } else {
      setHideshow(id);
    }
  };

  useEffect(() => {
    setIdRole(localStorage.getItem('role'));
    getListContract({ page: activePage, limit: 10, pageMonitor: activePageContract, limitMonitor: 10 });
  }, []);

  const getListContract = async ({ page, limit, pageMonitor, limitMonitor , sortRecruitment }) => {
    setLoading(true);
    try {
      const data = await CONTRACT_ENDPOINTS.GET_LIST_ESTIMATE_DONE({
        page,
        limit,
        pageMonitor,
        limitMonitor,
        sortRecruitment
      });
      if (data?.data) {
        setDataListContract(data?.data);
      }
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const getListContractDowload = async () => {
    setLoading(true);
    try {
      const data = await CONTRACT_ENDPOINTS.GET_CSV_CONTRACT_ESTIMATE();
      if (data?.data) {
        setDefaultCsv(data?.data);
        document.getElementById(`download-csv`).click();
      }
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const handleChangePage = (pageNumber) => {
    setActivePage(pageNumber);
    getListContract({ page: pageNumber, limit: 10, pageMonitor: 1, limitMonitor: 10 });
  };

  const onDownLoadCSV = (e) => {
    getListContractDowload();
    e.stopPropagation();
  };

  const onDownload = (e) => {
    e.stopPropagation();
  };

  return (
    <>
      <div className="main">
        <HeaderMasterSetting color={colorScreen?.code} colorText={colorScreen?.colorText} checkName="/contract" />
        <Loading loading={loading} />
        <div className="block-contract">
          <div className="list-table">
            <Link to="/contract" className="btn-back">
              戻る
            </Link>
            <div className="table-list-contract">
              <div className="title-recruitment">契約会社一覧</div>
              <div className="list-button-contact d-flex justify-content-end">
                <div className="ml-2" style={{ color: `${colorScreen?.colorText}` }}>
                  <span className="item" onClick={onDownLoadCSV}>
                    CSV出力{' '}
                  </span>
                  <CSVLink className="text-decoration-none" data={defaultCsv} filename={`契約会社一覧_${moment().format(DATETIME_EXPORT_JP_FORMAT)}.csv`}>
                    <span onClick={onDownload} id={`download-csv`}></span>
                  </CSVLink>
                </div>
              </div>
              <div className="block-search">
                <label>検索条件</label>
                <input type="text" />
                <Link to="#" className="item ml-2">
                  検索
                </Link>
              </div>
              <div className="list-table">
                {dataListContract?.data?.map((item, index) => {
                  return (
                    <CollapItem
                      getListContract={getListContract}
                      item={item}
                      key={index}
                      colors={colorScreen?.code}
                      colorText={colorScreen?.colorText}
                      handleHide={handleHide}
                      hideshow={hideshow}
                      activePageContract={activePageContract}
                      roleId={idRole}
                      activePage={activePage}
                    />
                  );
                })}
                {dataListContract?.count > 10 && (
                  <PaginationCustom
                    activePage={activePage}
                    itemsCountPerPage={10}
                    totalItemsCount={dataListContract?.count}
                    pageRangeDisplayed={5}
                    handlePageChange={handleChangePage}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <Footer color={colorScreen?.code} colorText={colorScreen?.colorText} />
      </div>
    </>
  );
};

export default compose(translate('translations'), withRouter)(CompanyContract);
