import { Collapse } from 'reactstrap';
import React, { useState, useEffect } from 'react';
import { getParamSearch } from '../../../actions/utils';
import { connect, useSelector } from 'react-redux';
import { compose } from 'redux';
import { withLocalize } from 'react-localize-redux';
import moment from 'moment';
import DataTimeSearch from '../../DataTimeSearch';
import { DATE_JP_FORMAT } from '../../../helpers/constants';

const dataSearchMock = {
  page: 1,
  limit: 10,
  caseName: '',
  managementCode: '',
  serviceName: '',
  applicantCompanyName: '',
  requestedDateTo: '',
  requestedDateFrom: '',
  visitDateTo: '',
  visitDateFrom: '',
  visitedBaseName: '',
  searchCurrentPath: '',
  contactName : '',
};

const CollapseClient = ({ isOpen, color, getParamSearch, nameRoute, colorText , dataService , checkSearch }) => {
  const [hideshow2, setHideshow2] = useState(false);
  const toggleHide = () => setHideshow2(!hideshow2);
  const [dataSearch, setDataSearch] = useState(dataSearchMock);
  const paramSearchBack = useSelector((store) => store.utils.paramSearch);
  const isEdit = paramSearchBack['searchCurrentPath'] == nameRoute && Object.entries(paramSearchBack).filter(([k,v])=>!!v).length > 3;
  isOpen = checkSearch ? isOpen : isEdit ? true : isOpen;

  const handleChangeSearch = (e) => {
    const typeName = e.target.name;
    let newData = { ...dataSearch, [typeName]: e.target.value };
    setDataSearch(newData);
  };

  useEffect(() => {
     if (isEdit) {
      setDataSearch(paramSearchBack);
      getParamSearch(paramSearchBack);
    } else {
      Array.from(document.querySelectorAll('input')).forEach((input) => (input.value = ''));
      setDataSearch(dataSearchMock);
      getParamSearch(dataSearchMock);
    }
  }, [nameRoute]);

  const handleReset = () => {
    Array.from(document.querySelectorAll('input')).forEach((input) => (input.value = ''));
    setDataSearch(dataSearchMock);
  };

  const onChangeDeadline = (date, name) => {
    setDataSearch({ ...dataSearch, [name]: date === null ? '' : moment(new Date(date)).format(DATE_JP_FORMAT) });
  };

  const onSubmit = () => {
    if (nameRoute == 'cancel_menu') {
      getParamSearch({ ...dataSearch, searchCurrentPath: nameRoute, isDefault: true });
    } else {
      getParamSearch({...dataSearch, searchCurrentPath : nameRoute});
    }
  };

  return (
    <>
      <Collapse isOpen={isOpen}>
        <table style={{ width: '100%' }} className="table-search">
          <tbody>
            <tr>
              <th style={{ backgroundColor: `${color}`, color: `${colorText}` }}>工事名</th>
              <td>
                <input type="text" name="caseName" value={dataSearch?.caseName} onChange={handleChangeSearch} />
              </td>
              <th style={{ backgroundColor: `${color}`, color: `${colorText}` }}>申込会社名</th>
              <td>
                <input
                  type="text"
                  name="applicantCompanyName"
                  value={dataSearch?.applicantCompanyName}
                  onChange={handleChangeSearch}
                />
              </td>
            </tr>
            <tr>
              <th style={{ backgroundColor: `${color}`, color: `${colorText}` }}>管理コード</th>
              <td>
                <input
                  type="text"
                  name="managementCode"
                  value={dataSearch?.managementCode}
                  onChange={handleChangeSearch}
                />
              </td>

              <th style={{ backgroundColor: `${color}`, color: `${colorText}` }}>工程名</th>
              <td>
                <select name="serviceName" value={dataSearch?.serviceName} onChange={handleChangeSearch} id="serviceName">
                  <option value="">--</option>
                  {dataService && dataService?.map((item) =>
                    <option value={item?.name}>{item?.name}</option>
                   )}
                </select>
              </td>
            </tr>
            <tr>
              <th style={{ backgroundColor: `${color}`, color: `${colorText}` }}>担当者名</th>
              <td>
                <input
                  type="text"
                  name="contactName"
                  value={dataSearch?.contactName}
                  onChange={handleChangeSearch}
                />
              </td>
              <th style={{ backgroundColor: `${color}`, color: `${colorText}` }}>物件名</th>
              <td>
                <input
                  type="text"
                  name="installationSiteName"
                  value={dataSearch?.installationSiteName}
                  onChange={handleChangeSearch}
                />
              </td>
            </tr>
            <tr>
              <th style={{ backgroundColor: `${color}`, color: `${colorText}` }}>依頼日</th>
              <td>
                <div className="d-flex align-items-center">
                  <div className="date-time-picker-wrapper">
                    <DataTimeSearch
                      onChangeDate={onChangeDeadline}
                      name="requestedDateFrom"
                      value={dataSearch?.requestedDateFrom}
                    />
                  </div>
                  ～
                  <div className="date-time-picker-wrapper">
                    <DataTimeSearch
                      onChangeDate={onChangeDeadline}
                      name="requestedDateTo"
                      value={dataSearch?.requestedDateTo}
                    />
                  </div>
                </div>
              </td>
              <th style={{ backgroundColor: `${color}`, color: `${colorText}` }}>指定訪問日時</th>
              <td>
                <div className="d-flex align-items-center">
                  <div className="date-time-picker-wrapper">
                    <DataTimeSearch
                      onChangeDate={onChangeDeadline}
                      name="visitDateFrom"
                      value={dataSearch?.visitDateFrom}
                    />
                  </div>{' '}
                  ～
                  <div className="date-time-picker-wrapper">
                    <DataTimeSearch
                      onChangeDate={onChangeDeadline}
                      name="visitDateTo"
                      value={dataSearch?.visitDateTo}
                    />
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div className="SearchButton" align="center">
          <button className="btn btnS btnSubmit mr-2" onClick={() => onSubmit()}>
            <i className="icon-search" />検 索
          </button>
          <button type="button" className="btn btnS btnReset" onClick={handleReset}>
            <i className="icon-arrows-cw" /> 一括解除
          </button>
        </div>
      </Collapse>
    </>
  );
};

const mapActionToProps = {
  getParamSearch,
};

export default compose(withLocalize, connect(null, mapActionToProps))(CollapseClient);
