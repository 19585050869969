import React from 'react';
import './style.scss';

const data = {
  header: [
    'パートナーID',
    '略称',
    'パートナー名',
    'パートナー名カナ',
    '代表者名',
    'フリガナ',
    '作成日',
    '更新日',
    '操作'
  ],
  body: [
    {
      パートナーID: '35',
      略称: 'NMC',
      パートナー名: '株式会社NEOMARS',
      パートナー名カナ: 'カブシキガイシャネオマルス',
      代表者名: '',
      フリガナ: '',
      作成日: '2020/04/10',
      更新日: '2020/04/15',
      操作: 'ユーザー情報'
    },
    {
      パートナーID: '35',
      略称: 'NMC',
      パートナー名: '株式会社NEOMARS',
      パートナー名カナ: 'カブシキガイシャネオマルス',
      代表者名: '',
      フリガナ: '',
      作成日: '2020/04/10',
      更新日: '2020/04/15',
      操作: 'ユーザー情報'
    }
  ]
};
const renderItem = (item, index, fontSize) => {
  return (
    <tr key={index}>
      <td className="no-wrapper-partner">
        <div className={`d-flex justify-content-center align-items-center font-weight-bolder no-style ${fontSize}`}>
          {index + 1}
        </div>
      </td>
      {data.header.map((element, key) => {
        return item[element] === 'ユーザー情報' ? (
          <td>
            <button className="user_btn" onClick={()=> window.location.href = (`/modify_partner?sid=${item.パートナーID}`)}>
              <i className="pl-1 fa fa-wrench" />
              <span className="pl-1">閲覧</span>
            </button>
            <button className="user_btn" onClick={() => window.location.href = ("users")}>
              <i className="fa fa-user" />
              <span className="pl-1">ユーザー情報</span>
            </button>
            <button className="user_btn">
              <i className="fa fa-male" />
              <span className="pl-1">ワーカー情報</span>
            </button>
          </td>
        ) : (
          <td key={key}>{item[element]}</td>
        );
      })}
    </tr>
  );
};

const TablePartner = ({ fontSize }) => {
  return (
    <div id="Result-partner">
      <div className="text-banner">
        <h3 className={`${fontSize} font-weight-bolder`}>検索結果：2件</h3>
      </div>
      <table className={`listTbl ${fontSize}`}>
        <thead>
          <tr>
            <th scope="col">No .</th>
            {data.header.map((item, index) => (
              <th scope="col" key={index}>
                {item}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>{data.body.map((listData, index) => renderItem(listData, index))}</tbody>
      </table>
    </div>
  );
};

export default TablePartner;
