import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import './style.scss';
import { compose } from 'redux';
import { translate } from 'react-i18next';
import ModalConfirmListItemSetting from '../ModalConfirmListSetting'

const ModalDetailSettingOrder = (props) => {
  const { toggle = () => { }, modal = false, productId, serviceId, pluginSettingId, nameSetting, color, t, orderId } = props;
  return (
    <div>
      <Modal className="modal-dialog-custom modal-dialog-custom--resize" isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>{t(`modal_setting_order.title_confirm_setting`)}</ModalHeader>
        <ModalBody>
          <ModalConfirmListItemSetting productId={productId} serviceId={serviceId}  pluginSettingId={pluginSettingId} nameSetting={nameSetting} colors={color} orderId ={orderId}/>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default compose(translate('translations'))(ModalDetailSettingOrder);
