import _ from 'lodash';
import React, { useState } from 'react';
import { CSVLink } from 'react-csv';
import { Collapse } from 'reactstrap';
import { PRODUCT_ENDPOINT } from '../../../constants/endpoints';
import './style.scss';
import TableListDetail from './TableListDetail';
import Loading from 'components/Loading/Loading';
import moment from 'moment';
import { DATETIME_EXPORT_JP_FORMAT, DATE_JP_FORMAT } from 'helpers/constants';
import { formatInputSelectDate } from 'helpers/helpers';
import { pushToast } from '../../Toast';

const CollapseTable = (props) => {
  let {
    getListProduct,
    sortOrderBy,
    item,
    t,
    index,
    color,
    role,
    roleDetail,
    idRole,
    handleHide,
    hideshow,
    data = [],
    p,
    activePageTable,
    partnerId,
    partnerBranch,
    staffBranch,
    colorText,
    detailCheck,
    getListPreparationInfo,
    activePageSearch,
    summary,
    activeMenu,
    paramSearch
  } = props;
  // let name = (process.browser && new URL(window.location.href).searchParams.get('p')) || 'receive_all';
  let screenName = (process.browser && new URL(window.location.href).searchParams.get('p')) || 'receive_all';
  const [idProduct, setIdProduct] = useState();
  const [defaultCsv, setDefaultCsv] = useState([]);
  const [loading, setLoading] = useState(false);
  const toggleHide = (e, id) => {
    handleHide(id);
    setIdProduct(id);
    e.stopPropagation();
  };
  // const userMeData = useSelector((store) => store.authentication.userMe, shallowEqual);
  // useEffect(() => {
  //   if (userMeData && userMeData.data && userMeData.data.userRole) {
  //     let newRole = getRole({ idRole: userMeData.data.userRole[0] });
  //     setRole(newRole);
  //   }
  // }, [userMeData]);

  const onDownLoadCSV = (e) => {
    getCSVDownLoad({ screenName: screenName, productId: item.productId, organizationId: item?.organizationId || '' });
    e.stopPropagation();
  };

  const getCSVDownLoad = async ({
    screenName: screenName,
    productId: productId,
    caseName = '',
    managementCode = '',
    serviceName = '',
    requestedDateTo = '',
    requestedDateFrom = '',
    operationAdjustmentDateTo = '',
    operationAdjustmentDateFrom = '',
    applicantCompanyName = '',
    resultsPreparation = '',
    visitDateTo = '',
    visitDateFrom = '',
    partnerName = '',
    visitedBaseName = '',
    reportReceiptDate = '',
    reportDeliveryDate = '',
    contactName = '',
    installationSiteName = '',
    timeAddPartnerTo = '',
    timeAddPartnerFrom = '',
    operationAdjustmentInfo = '',
    reportReceiptDateFrom = '',
    reportReceiptDateTo = '',
    timestampReportUploadInfoFrom = '',
    timestampReportUploadInfoTo = '',
    checkVisitDate = '',
    checkTimeAddPartner = '',
    checkReportReceiptDate = '',
    totalScreen = '',
    organizationId,
    }) => {
    try {
      setLoading(true);
      const data = await PRODUCT_ENDPOINT.DOWNLOAD_CSV_PRODUCT_SETTING({
        screenName: screenName,
        productId: productId,
        caseName: paramSearch.caseName || caseName,
        managementCode: paramSearch.managementCode || managementCode,
        serviceName: paramSearch.serviceName || serviceName,
        requestedDateTo: paramSearch.requestedDateTo || requestedDateTo,
        requestedDateFrom: paramSearch.requestedDateFrom || requestedDateFrom,
        operationAdjustmentDateTo: paramSearch.operationAdjustmentDateTo || operationAdjustmentDateTo,
        operationAdjustmentDateFrom: paramSearch.operationAdjustmentDateFrom || operationAdjustmentDateFrom,
        applicantCompanyName: paramSearch.applicantCompanyName || applicantCompanyName,
        resultsPreparation: paramSearch.resultsPreparation || resultsPreparation,
        visitDateTo: paramSearch.visitDateTo || visitDateTo,
        visitDateFrom: paramSearch.visitDateFrom || visitDateFrom,
        partnerName: paramSearch.partnerName || partnerName,
        visitedBaseName: paramSearch.visitedBaseName || visitedBaseName,
        prefecturesVisited: paramSearch.prefecturesVisited,
        reportReceiptDate: paramSearch.reportReceiptDate || reportReceiptDate,
        reportDeliveryDate: paramSearch.reportDeliveryDate || reportDeliveryDate,
        installationSiteName: paramSearch.installationSiteName || installationSiteName,
        timeAddPartnerFrom: paramSearch.timeAddPartnerFrom || timeAddPartnerFrom,
        timeAddPartnerTo: paramSearch.timeAddPartnerTo || timeAddPartnerTo,
        reportReceiptDateFrom: paramSearch.reportReceiptDateFrom || reportReceiptDateFrom,
        reportReceiptDateTo: paramSearch.reportReceiptDateTo || reportReceiptDateTo,
        timestampReportUploadInfoFrom: paramSearch.timestampReportUploadInfoFrom || timestampReportUploadInfoFrom,
        timestampReportUploadInfoTo: paramSearch.timestampReportUploadInfoTo || timestampReportUploadInfoTo,
        operationAdjustmentInfo: paramSearch.operationAdjustmentInfo || operationAdjustmentInfo,
        contactName: paramSearch.contactName || contactName,
        checkVisitDate: paramSearch.checkVisitDate || checkVisitDate,
        checkTimeAddPartner: paramSearch.checkTimeAddPartner || checkTimeAddPartner,
        checkReportReceiptDate: paramSearch.checkReportReceiptDate || checkReportReceiptDate,
        totalScreen : summary || '',
        organizationId
      });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
      } else {
        let formatData = data?.data?.map((item) => {
          return {
            ...item,
            //訪問時間To: !_.isEmpty(item?.訪問時間To)
            //  ? moment(`${formatInputSelectDate(item?.訪問時間To)}`, `${DATE_JP_FORMAT}`).isValid()
            //    ? item?.訪問時間To
            //    : `${item?.訪問日 || ''} ${item?.訪問時間To || ''}`
            //  : '',
          };
        });
        setDefaultCsv(formatData);
        document.getElementById(`download-csv-${productId}`).click();
      }
      setLoading(false);
    } catch (errorMessage) {
      console.log('errorMessage: ', errorMessage);
      setLoading(false);
    }
  };

  const onDownload = (e) => {
    e.stopPropagation();
  };

  return (
    <>
      <Loading loading={loading} />
      <div className="wrapper-btn-colappse" onClick={(e) => toggleHide(e, item.productId)}>
        <div
          data-id={index}
          className="mb-1 searchbar d-flex justify-content-between align-items-center "
          style={{ background: `${color}` }}
        >
          <div className="ml-2" style={{ color: `${colorText}` }}>
            {item.caseName}
            {item?.exportSetting?.isDisplay && (
              <span className="btn-export-csv" onClick={onDownLoadCSV}>
                {t('menu_list.button_export_csv')}
              </span>
            )}
            <CSVLink className="text-decoration-none" data={defaultCsv} filename={`${activeMenu}_${moment().format(DATETIME_EXPORT_JP_FORMAT)}.csv`}>
              <span onClick={onDownload} id={`download-csv-${item.productId}`}></span>
            </CSVLink>
          </div>
          <div className="mr-2 d-flex" style={{ color: `${colorText}` }}>
            <div style={{ fontSize: 11 }} className="mt-2 d-flex">
              {
                screenName === 'tomorrow_info' ? (
                  <div className="mx-2 d-flex">
                    事前確認
                    <p className="font-weight-boid px-1" style={{ color: 'red' }}>
                      {item?.count || 0}
                    </p>{' '}
                    件
                  </div>
                ) : (
                  _.map(item?.countSummary, (val, key) => {
                    return (
                      <div className="mx-2 d-flex" key={key}>
                        {t(`project_title.${key}`)}
                        <p className="font-weight-boid px-1" style={{ color: 'red' }}>
                          {val}
                        </p>{' '}
                        件
                      </div>
                    );
                  })
                )
              }
            </div>
            {hideshow === item.productId ? (
              <i className="icon-up-open icon-searchbar" />
            ) : (
              <i className="icon-down-open icon-searchbar" />
            )}
          </div>
        </div>
      </div>
      <Collapse isOpen={hideshow === item.productId ? true : false}>
        <TableListDetail
          getListProduct={getListProduct}
          sortOrderBy={sortOrderBy}
          casaName={item.casaName}
          idProduct={idProduct}
          p={p}
          t={t}
          color={color}
          roleDetail={roleDetail}
          idRole={idRole}
          role={role}
          item={item}
          colorText={colorText}
          activePageTable={activePageTable}
          partnerBranch={partnerBranch}
          staffBranch={staffBranch}
          partnerId={partnerId}
          detailCheck={detailCheck}
          getListPreparationInfo={getListPreparationInfo}
          activePageSearch={activePageSearch}
          summary={summary}
          paramSearch={paramSearch}
        />
      </Collapse>
    </>
  );
};

export default CollapseTable;
