import React, { useState } from 'react';
import { Collapse } from 'reactstrap';
import './../../../assets/scss/page/DetailErp/style.scss';

const DetailManagemment = ({ data }) => {
  const [isOpen, setIsOpen] = useState(true);

  const onClickShow = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <div className="detail-erp-table-wrapper">
        <div className="title-absolute" onClick={onClickShow}>
          協力企業情報
          {isOpen ? <i className="fas fa-arrow-circle-up ml-2"></i> : <i className="fas fa-arrow-circle-down ml-2"></i>}
        </div>
        <Collapse isOpen={isOpen}>
          <div className="detail__managerment">
            <table className="detail__table">
              <thead>
                <tr>
                  <th>協力企業名</th>
                  <th>協力企業ID</th>
                  <th>作業員①</th>
                  <th>作業員ID①</th>
                  <th>作業員②</th>
                  <th>作業員ID②</th>
                  <th>作業員③</th>
                  <th>作業員ID③</th>
                  <th>作業員④</th>
                  <th>作業員ID④</th>
                  <th>作業員⑤</th>
                  <th>作業員ID⑤</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="detail__table--form detail__table--color text-center">{data?.partnerName}</td>
                  <td className="detail__table--form detail__table--color text-center">{data?.partnerId}</td>
                  <td className="detail__table--form detail__table--color text-center">{data?.workerName?.[0]}</td>
                  <td className="detail__table--form detail__table--color text-center">{data?.staffId?.[0]}</td>
                  <td className="detail__table--form detail__table--color text-center">{data?.workerName?.[1]}</td>
                  <td className="detail__table--form detail__table--color text-center">{data?.staffId?.[1]}</td>
                  <td className="detail__table--form detail__table--color text-center">{data?.workerName?.[2]}</td>
                  <td className="detail__table--form detail__table--color text-center">{data?.staffId?.[2]}</td>
                  <td className="detail__table--form detail__table--color text-center">{data?.workerName?.[3]}</td>
                  <td className="detail__table--form detail__table--color text-center">{data?.staffId?.[3]}</td>
                  <td className="detail__table--form detail__table--color text-center">{data?.workerName?.[4]}</td>
                  <td className="detail__table--form detail__table--color text-center">{data?.staffId?.[4]}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </Collapse>
      </div>
    </div>
  );
};

export default DetailManagemment;
