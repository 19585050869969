import React from 'react';
import _ from 'lodash';
import './style.scss';
const TableCondit = ({ dataOrder }) => {
  return (
    <div className="list">
      <table className="conditTab">
        <tbody>
          <tr>
            <th width="150">管理コード</th>
            <td>{dataOrder?.controlCode}</td>
            <th width="150">都道府県</th>
            <td />
            {/* <td>{dataOrder?.prefecturesVisited}</td> */}
          </tr>
          <tr>
            <th width="150">工程</th>
            <td>{dataOrder?.engineering}</td>
            <th width="150" rowSpan="2">
              訪問先拠点住所
            </th>
            <td rowSpan="2">{dataOrder?.visitedBaseAddress}</td>
          </tr>
          <tr>
            <th width="150">申込会社名</th>
            <td>{dataOrder?.applicantCompanyName}</td>
          </tr>
          <tr>
            <th width="150">物件名</th>
            <td>{dataOrder?.installationSiteName}</td>
            <th width="150">訪問先建物名</th>
            <td>{dataOrder?.visitedBuildingName}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default TableCondit;
