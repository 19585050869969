import React from 'react';
import './style.scss'

const ButtonDetail = ({ text = '保存', onUpdateOrder , value='center' , size = '0', className='', disabled = false  }) => {
  return (
    <div className={`button-detail-btn pb-1 text-${value}`} style={{paddingTop: `${size}em`}}>
      <button disabled={ disabled } className={`btn-item ${className}`} onClick={() => onUpdateOrder(text)}>
        {text}
      </button>
    </div>
  );
};

export default ButtonDetail;
