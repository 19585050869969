import React from 'react';
import './style.scss';

const Footer = ({ color , colorText}) => {
  return (
    <div className="footer-wrapper d-flex align-items-center justify-content-center" style={{ background: `${color}` }}>
      <div className="text-center">
        <p className="p-0 m-0" style={{ color: `${colorText}` }}>
          Copyright <i className="icon-copyright" />
          NEOMARS CO.,LTD.All Rights Reserved.
        </p>
        <a
          href="https://dev-salesdemo.stella-cloud.com/stellacloud/terms_of_service.pdf"
          style={{ color: `${colorText}` }}
          target="_brank"
        >
        </a>
      </div>
    </div>
  );
};

export default Footer;
