import React, { useEffect, useState } from 'react';
import { translate } from 'react-i18next';
import { compose } from 'redux';
import DateTimePicker from 'components/DateTimePicker';
import Header from 'components/Header';
import { COLOR_ENDPOINT, ORDER } from 'constants/endpoints';
import { getRole } from 'helpers/helpers';
import 'assets/scss/page/center/DetailTomorrowCenter/style.scss';
import DetailData from 'components/DetailData';
import DetailDataList from 'components/DetailDataList';
import ConfirmStatus from 'components/ConfirmStatus';
import SupportStatus from 'components/SupportStatus';
import { pushToast } from 'components/Toast';
import moment from 'moment';
import { SCREEN_NAME, DATE_JP_FORMAT } from 'helpers/constants';

const mockData = {
  files: '',
};
const dataStatus = {
  statusRequiredDeadline: true,
  statusRequiredConfirm: true,
  statusRequiredWorker: true,
};

const DetailListOrder = () => {
  const [role, setRole] = React.useState(null);
  const [color, setColor] = React.useState('#1c2a53');
  const [idRole, setIdRole] = React.useState(null);
  const [selectedFile, setSelectedFile] = useState([]);
  const [selectedName, setSelectedName] = useState('');
  const [removeData, setRemoveData] = useState(mockData);
  const [deadlineDate, setDeadlineDate] = useState('');
  const [completionDate, setCompletionDate] = useState('');
  const [requiredDeadline, setRequiredDeadline] = useState(false);
  const [body, setBody] = useState({});
  const [status, setStatus] = useState(dataStatus);
  const [orderDataDetail, setOrderDataDetail] = useState();
  let orderId = process.browser && new URL(window.location.href).searchParams.get('orderId');
  let organizationId = process.browser && new URL(window.location.href).searchParams.get('organizationId') || '';

  const [datas, setDatas] = useState('');
  const [extraData, setExtraData] = useState('');
  const [listFiles, setlistFiles] = useState('');

  const getColor = async () => {
    try {
      const data = await COLOR_ENDPOINT.GET_COLOR({ role: idRole });
      if (data && data?.code === 0) {
        setColor(data?.data?.code);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (idRole) {
      getColor({ role: idRole });
    }
  }, [idRole]);

  useEffect(() => {
    const newRole = localStorage.getItem('role');
    setIdRole(newRole);
    if (newRole) {
      setRole(getRole({ idRole: newRole }));
    }
  });
  useEffect(() => {
    getDetailOrder({ orderId });
  }, [orderId]);

  const getOrderDetail = async ({ orderId = '' }) => {
    try {
      const data = await ORDER.GET_STATUS_BEFORE_VISIT({ orderId });
      if (data) {
        setlistFiles(data?.data?.receiveAllFiles);
        setExtraData(data?.data?.workerAllocation?.extraData?.option1);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getOrderDetailByOrderId = async ({ orderId = '' }) => {
    try {
      const data = await ORDER.GET_ORDER_DETAIL({ orderId, getAll: true });
      if (data) {
        setOrderDataDetail(data?.data);
      }
    } catch (e) {
      console.log(e);
    }
  };
  const getDetailOrder = async ({ orderId = '' }) => {
    try {
      const data = await ORDER.GET_ORDER_DETAIL({ orderId });
      if (data) {
        setlistFiles(data?.data?.receiveAllFiles);
        setExtraData(data?.data?.workerAllocation?.extraData?.option1);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getOrderDetailss = async ({ orderId = '' }) => {
    try {
      const data = await ORDER.GET_DETAIL_CANCEL_INFO({ orderId , organizationId });
      if (data) {
        setDatas(data?.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getOrderDetail({ orderId });
    getOrderDetailss({ orderId });
    getOrderDetailByOrderId({ orderId });
  }, [orderId]);

  useEffect(() => {
    setRequiredDeadline(orderDataDetail?.statusRequiredDeadline);
    setDeadlineDate(orderDataDetail?.admissionApplicationDeadline);
    setCompletionDate(orderDataDetail?.admissionApplicationCompletionDate);
  }, [orderDataDetail, orderId]);

  const uploadCsv = async ({ body = '', order_id = '' }) => {
    try {
      const data = await ORDER.UPLOAD_FILIE_RECEVICE_ALL({ body, order_id });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else if (data.message.errorMessage) {
          pushToast('error', data?.message?.errorMessage);
        } else {
          pushToast('error', data?.message);
        }
      } else {
        pushToast('success', 'Upload success');
        getOrderDetail({ orderId });
        setSelectedName('');
      }
    } catch (errorMessage) {
      console.log('errorMessage: ', errorMessage);
    }
  };

  const onChangeHandler = async (e) => {
    const tmpFile = e?.target?.files;
    const tmpFileResult = Object.keys(tmpFile).map((item, index) => {
      return tmpFile[index].name;
    });
    const base64Promises = [];
    const dataNew = [];
    Object.keys(tmpFile).map((item, index) => {
      base64Promises.push(toBase64(tmpFile[index]));
    });

    const base64 = await Promise.all(base64Promises);

    Object.keys(tmpFile).map((item, index) => {
      dataNew.push({
        file: base64[index],
        fileName: tmpFile[index].name,
      });
    });

    setSelectedFile(dataNew);
    setSelectedName(tmpFileResult);
  };

  const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

  const onClickHandler = () => {
    const data = {
      files: selectedFile,
      screenName: SCREEN_NAME.TOMORROW_INFO,
    };
    uploadCsv({ body: data, order_id: orderId });
  };

  const removeFile = async ({ body, id = '' }) => {
    try {
      const data = await ORDER.REMOVE_FILE_ADMISSION({ body, id });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
      } else {
        pushToast('success', 'delete success');
        setlistFiles(data?.data?.Attributes?.receiveAllFiles);
        unCheckedAll();
      }
    } catch (errorMessage) {
      console.log('errorMessage: ', errorMessage);
    }
  };

  const handleRemove = () => {
    if (orderId) {
      removeFile({ body: removeData, id: orderId });
    }
  };

  const unCheckedAll = () => {
    document.querySelectorAll('input[type="checkbox"]').forEach((item) => {
      item.checked = false;
    });
  };
  const onChangeDeadline = (date) => {
    setDeadlineDate(moment(new Date(date)).format(DATE_JP_FORMAT));
    setBody({ ...body, admissionApplicationDeadline: moment(new Date(date)).format(DATE_JP_FORMAT) });
  };
  const onChangeompletionDate = (date) => {
    setCompletionDate(moment(new Date(date)).format(DATE_JP_FORMAT));
    setBody({ ...body, admissionApplicationCompletionDate: moment(new Date(date)).format(DATE_JP_FORMAT) });
  };

  const handleChangeCheck = (e) => {
    let target = e.target;
    let value = target.value;
    let newData = { ...removeData, files: [...removeData.files, { key: value }] };
    setRemoveData(newData);
  };
  const setStatusRequiredOfDeadline = (e) => {
    let value = e.target?.value;
    if (value === 'true' || value === true) {
      value = true;
    } else {
      value = false;
    }
    setRequiredDeadline(value);
    setStatus({ ...status, statusRequiredDeadline: value });
  };

  const getDataBody = (value) => {
    setBody({ ...body, ...value });
  };
  const getStatusBody = (value) => {
    setStatus({ ...status, ...value });
  };

  return (
    <div className="wrapper-detail-cancel-info-custom">
      <div className="main">
        <Header idRole={idRole} color={color} p='preparation_info' classCustom={'header-wrapper--mgbt-30'} />
        <div className="body-wrapper">
          <DetailData data={datas} p={'tomorrow_info'} link={false} title={'現状情報'} />
          <ConfirmStatus getDataBody={getDataBody} getStatusBody={getStatusBody} orderDataDetail={orderDataDetail && orderDataDetail} orderId={orderId}/>
          <SupportStatus getDataBody={getDataBody} getStatusBody={getStatusBody} orderDataDetail={orderDataDetail} orderId={orderId} color={color}/>
          <div className="detail-cancel-table-wrapper detail-cancel-table-wrapper-three detail-cancel-table-wrapper-four">
            <div className="title-absolute">入館申請期限</div>
            <div className="block-item">
              <div className="block-left mb-1">
                <label>前日確認期限</label>
                <div className="block-date">
                  <DateTimePicker
                    onChangeDate={onChangeDeadline}
                    date={
                      deadlineDate?.length > 0
                        ? new Date(deadlineDate && deadlineDate)
                        : null
                    }
                  />
                </div>
              </div>
            </div>
            <div className="block-item">
              <div className="block-left mb-1">
                <label>前日確認完了日</label>
                <div className="block-date">
                  <DateTimePicker
                    onChangeDate={onChangeompletionDate}
                    date={
                      completionDate?.length > 0
                        ? new Date(completionDate && completionDate)
                        : null
                    }
                  />
                </div>
              </div>
            </div>
            <div className="block-item">
              <div className="block-left mb-1">
                <label className="cursor-pointer title-file title-file-custom" htmlFor="upload-csv">
                  ファイルを指定
                </label>
                <input type="file" name="file" id="upload-csv" multiple className="d-none" onChange={onChangeHandler} />
                <input
                  className="dummy_file input-file-custom"
                  type="text"
                  placeholder={selectedName}
                  disabled="disabled"
                />
              </div>
              <div className="block-right">
                <button className="button-upload" onClick={() => onClickHandler()}>
                  UPLOAD
                </button>
              </div>
            </div>
            <div className="block-item">
              <div className="block-left d-flex mb-1">
                <label className="text-center">UPLOAD FILE</label>
                <div>
                  {listFiles &&
                    listFiles.map((item, index) => {
                      return (
                        <div className="mb-1" key={index}>
                          <input type="checkbox" value={item.key} onChange={handleChangeCheck} />
                          {item.name}
                        </div>
                      );
                    })}
                </div>
              </div>
              <div className="block-right">
                <button className="button-upload" onClick={() => handleRemove()}>
                  DELETE
                </button>
                <div className="mt-2">
                  <span className="title">承認依頼</span>
                  <span>
                    <label>必要</label>
                    <input
                      type="radio"
                      value="true"
                      checked={requiredDeadline === true}
                      onChange={setStatusRequiredOfDeadline}
                      name="statusRequiredOfDeadline"
                    />
                  </span>
                  <label>不要</label>
                  <span>
                    <input
                      type="radio"
                      value="false"
                      checked={requiredDeadline === false}
                      onChange={setStatusRequiredOfDeadline}
                      name="statusRequiredOfDeadline"
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <DetailDataList
            extraData={extraData}
            role={role}
            orderId={orderId}
            datePick={true}
            title_remark="承認依頼"
            body={body}
            status={status}
            getDataBody={getDataBody}
            orderDataDetail={orderDataDetail}
            screenName={SCREEN_NAME.TOMORROW_INFO}
          />
        </div>
      </div>
    </div>
  );
};
export default compose(translate('translations'))(DetailListOrder);
