import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import './style.scss';
import { CLIENT_ENDPOINT, PRODUCT_ENDPOINT } from '../../../../constants/endpoints';
import { pushToast } from '../../../../components/Toast';

const mockDataClient = {
  clientCode: '',
  firstNameKana: '',
  firstName: '',
  billName: '',
  billNameKana: '',
  postalCode: '',
  prefecture: '',
  address: '',
  addressKana: '',
};

const listPrefecture = [
  '北海道',
  '岩手県',
  '秋田県',
  '福島県',
  '神奈川県',
  '千葉県',
  '栃木県',
  '山梨県',
  '長野県',
  '富山県',
  '愛知県',
  '静岡県',
  '大阪府',
  '京都府',
  '奈良県',
  '鳥取県',
  '鳥取県',
  '岡山県',
  '山口県',
  '香川県',
  '高知県',
  '佐賀県',
  '熊本県',
  '宮崎県',
  '沖縄県'
];

const Modify1 = (props) => {
  const { history } = props;
  const [dataClient, setDataClient] = useState(mockDataClient);
  const [submit, setSubmit] = useState(false);
  const [postalCode1, setPostalCode1] = useState('');
  const [postalCode2, setPostalCode2] = useState('');
  const [allClient, setAllClient] = useState([]);
  const [client, setClient] = useState('new');
  const [disable, setDisable] = useState(false);

  useEffect(() => {
    if (client === 'new') {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [client]);

  useEffect(() => {
    getAllClient();
  }, []);

  const getAllClient = async () => {
    try {
      const data = await CLIENT_ENDPOINT.GET_ALL_CLIENT();
      if (data) {
        setAllClient(data?.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getNewClientID = async () => {
    try {
      const data = await CLIENT_ENDPOINT.GET_NEW_CLIENT_ID();
      if (data) {
        let newDataClient = { ...dataClient, clientCode: data?.data?.code };
        setDataClient(newDataClient);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    let newDataClient = { ...dataClient, postalCode: postalCode1 + '-' + postalCode2 };
    setDataClient(newDataClient);
  }, [postalCode1, postalCode2]);

  const handleChangeClient = (e) => {
    setClient(e.target.value);
  };

  const createProduct = async (body) => {
    try {
      const data = await PRODUCT_ENDPOINT.CREATE_PRODUCT_STEP_ONE(body);

      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
      } else {
        if (data?.data?.productId) {
          history.push(`/modify_anken?modify=2&productId=${data?.data?.productId}`);
        }
        pushToast('success', 'Create step 1 success');
      }

    } catch (e) {
      console.log(e);
    }
  };

  const onClickGetClientId = () => {
    getNewClientID();
  };

  const handleChangeText = (e) => {
    let type = e.target.name;
    switch (type) {
      case 'firstName': {
        let newDataClient = { ...dataClient, firstName: e.target.value };
        setDataClient(newDataClient);
        break;
      }
      case 'firstNameKana': {
        let newDataClient = { ...dataClient, firstNameKana: e.target.value };
        setDataClient(newDataClient);
        break;
      }
      case 'billName': {
        let newDataClient = { ...dataClient, billName: e.target.value };
        setDataClient(newDataClient);
        break;
      }
      case 'billNameKana': {
        let newDataClient = { ...dataClient, billNameKana: e.target.value };
        setDataClient(newDataClient);
        break;
      }
      case 'address': {
        let newDataClient = { ...dataClient, address: e.target.value };
        setDataClient(newDataClient);
        break;
      }
      case 'addressKana': {
        let newDataClient = { ...dataClient, addressKana: e.target.value };
        setDataClient(newDataClient);
        break;
      }
      case 'postalCode1': {
        setPostalCode1(e.target.value);
        break;
      }
      case 'postalCode2': {
        setPostalCode2(e.target.value);
        break;
      }
    }
  };

  const handleChangSelect = (e) => {
    let newDataClient = { ...dataClient, prefecture: e.target.value };
    setDataClient(newDataClient);
  };

  const onClickSubmit = () => {
    if (client === 'new') {
      createProduct(dataClient);
    } else {
      createProduct({ clientId: client });
    }
  };

  useEffect(() => {
    if (client === 'new') {
      if (
        dataClient.clientCode.length > 0 &&
        dataClient.firstNameKana.length > 0 &&
        dataClient.firstName.length > 0 &&
        dataClient.billName.length > 0 &&
        dataClient.billNameKana.length > 0 &&
        dataClient.prefecture.length > 0 &&
        dataClient.address.length > 0 &&
        dataClient.addressKana.length > 0 &&
        postalCode1.length > 0 &&
        postalCode2.length > 0

      ) {
        setSubmit(true);
      } else {
        setSubmit(false);
      }
    } else {
      setSubmit(true);
    }
  }, [dataClient, client]);

  return (
    <div className="wrapper-modify-1">
      <div className="wrapper-column">
        <p className="mb-0" style={{ fontSize: '16px' }}>
          発注企業選択
        </p>
      </div>
      <div className="wrapper-sub-title">
        <select className="form-control select-existing-client" onChange={handleChangeClient}>
          <option value='new'>---</option>
          {
            allClient.map((item, index) =>
              <option value={item?.userId} key={index}>{item?.firstName}</option>
            )
          }
        </select>
      </div>
      <div className="mt-3">
        <div className="wrapper-column">
          <p className="mb-0" style={{ fontSize: '16px' }}>
            新規クライアント登録
          </p>
        </div>
        <div className="wrapper-column">
          <p className="mb-0">基本情報（入力必須）</p>
        </div>
        <div className="d-flex">
          <div className="col-2 wrapper-column">
            クライアントコード
            <br/>
            （半角英数5文字）
          </div>
          <div className="col-10 table-right d-flex">
            <input
              className={`form-control col-2 ${dataClient.clientCode === '' && client === 'new' ? 'input-err' : ''}`}
              disabled={true}
              value={dataClient.clientCode}/>
            <button className='ml-2 btn btn-light' disabled={disable} onClick={() => onClickGetClientId()}>新規コードを取得
            </button>
          </div>
        </div>
        <div className="d-flex">
          <div className="col-2 wrapper-column">クライアント名</div>
          <div className="col-10 table-right">
            <select className="form-control select-existing-client col-1" style={{ fontSize: '12px' }}>
              <option value="、">法人格</option>
              <option value="株式会社、カブシキガイシャ、前">前(株)</option>
              <option value="株式会社、カブシキガイシャ、後">後(株)</option>
              <option value="有限会社、ユウゲンガイシャ、前">前(有)</option>
              <option value="有限会社、ユウゲンガイシャ、後">後(有)</option>
            </select>
            <div className="d-flex align-items-center mt-2">
              <input
                className={`form-control col-6 ${dataClient.firstName === '' && client === 'new' ? 'input-err' : ''}`}
                name='firstName'
                value={dataClient.firstName}
                disabled={disable}
                onChange={handleChangeText}/>
              <label className="ml-2 mb-0">株式会社</label>
            </div>
          </div>
        </div>
        <div className="d-flex">
          <div className="col-2 wrapper-column">クライアント名</div>
          <div className="col-10 table-right align-items-center d-flex">
            <input
              className={`form-control col-6 ${dataClient.firstNameKana === '' && client === 'new' ? 'input-err' : ''}`}
              name='firstNameKana' value={dataClient.firstNameKana}
              disabled={disable}
              onChange={handleChangeText}/>
            <label className="ml-2 mb-0">カブシキガイシャ</label>
          </div>
        </div>
        <div className="wrapper-column">
          <p className="mb-0">請求情報</p>
        </div>
        <div className="d-flex">
          <div className="col-2 wrapper-column">クライアント名</div>
          <div className="col-10 table-right align-items-center d-flex">
            <input className={`form-control col-3 ${dataClient.billName === '' && client === 'new' ? 'input-err' : ''}`}
                   value={dataClient.billName} onChange={handleChangeText}
                   disabled={disable}
                   name='billName'/>
          </div>
        </div>
        <div className="d-flex">
          <div className="col-2 wrapper-column">フリガナ</div>
          <div className="col-10 table-right align-items-center d-flex">
            <input
              className={`form-control col-3 ${dataClient.billNameKana === '' && client === 'new' ? 'input-err' : ''}`}
              value={dataClient.billNameKana} onChange={handleChangeText}
              disabled={disable}
              name='billNameKana'/>
          </div>
        </div>
        <div className="d-flex">
          <div className="col-2 wrapper-column">郵便番号</div>
          <div className="col-10 table-right align-items-center d-flex">
            <input className={`form-control col-1 ${postalCode1 === '' && client === 'new' ? 'input-err' : ''}`}
                   value={postalCode1} name='postalCode1' onChange={handleChangeText} disabled={disable}
                   maxLength={4}
            />-
            <input className={`form-control col-1 ${postalCode2 === '' && client === 'new' ? 'input-err' : ''}`}
                   value={postalCode2} name='postalCode2' onChange={handleChangeText} disabled={disable}
                   maxLength={4}
            />
          </div>
        </div>
        <div className="d-flex">
          <div className="col-2 wrapper-column">都道府県</div>
          <div className="col-10 table-right align-items-center d-flex">
            <select
              className={`form-control select-existing-client ${dataClient.prefecture === '' && client === 'new' ? 'input-err' : ''}`}
              style={{ fontSize: '12px', width: '100px' }}
              disabled={disable}
              onChange={handleChangSelect}>
              <option value={null}></option>
              {
                listPrefecture.map((item, index) => <option key={index} value={item}>{item}</option>
                )}
            </select>
          </div>
        </div>
        <div className="d-flex">
          <div className="col-2 wrapper-column">住所</div>
          <div className="col-10 table-right align-items-center d-flex">
            <input className={`form-control col-6 ${dataClient.address === '' && client === 'new' ? 'input-err' : ''}`}
                   value={dataClient.address} name='address'
                   disabled={disable}
                   onChange={handleChangeText}/>
          </div>
        </div>
        <div className="d-flex">
          <div className="col-2 wrapper-column">フリガナ</div>
          <div className="col-10 table-right align-items-center d-flex">
            <input
              className={`form-control col-6 ${dataClient.addressKana === '' && client === 'new' ? 'input-err' : ''}`}
              onChange={handleChangeText} name='addressKana'
              disabled={disable}
              value={dataClient.addressKana}/>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-center mt-4 mb-3 wrapper-button-next">
          <button className="btn button-next" onClick={() => onClickSubmit()} disabled={!submit}>次へ</button>
        </div>
        {/*<BasicInfo/>*/}
      </div>
    </div>
  );
};

export default compose(withRouter)(Modify1);
