import React from 'react';
import 'assets/scss/page/AppointInfoDetail/FormAppoint.scss';
import { time, ROLE } from '../../../../helpers/constants';
import DateTimePicker from '../../../../components/DateTimePicker';
import _ from 'lodash';

const callStatus_data = [
  '未完了',
  '対応中',
  '完了',
  'キャンセル',
  'キャンセル依頼',
  '対応保留'
];

const DetailUpdate = ({ dateTo, data, handleChangeValue, onChangeDate, nameStaff, nameRole, handleChangeVisitDate, masterSend, onChangeCallLog, onChangeVisitDateTo }) => {

  const onChangeText = (e) => {
    handleChangeValue(e);
  };

  const onChangeVisitDate = ({key, keyTemp, e}) => {
    handleChangeVisitDate({key, keyTemp, e});
  };

  const formatInputSelectTime = (dateTime) => {
    if (!dateTime) return '';
    return dateTime.slice(-5);
  };

  return (
    <div className="detail__update">
      <table className="detail__table">
        <tbody>
          <tr>
            <th className="detail__table--th">架電結果</th>
            <td className="detail__table--color">
            <table className="detail__table">
              <tbody>
                <tr>
                  <th className="detail__table--th th-child">架電状況</th>
                  <td>
                    <select
                      name="callStatus" onChange={onChangeText}
                      value={data ?.callStatus}
                      className="select-form">
                      {
                        callStatus_data.map((elm, i) => {
                          const isDisable = elm === 'キャンセル' && nameRole === ROLE.PARTNER || elm === 'キャンセル依頼' && nameRole !== ROLE.PARTNER;
                          return (
                            <option value={elm} disabled={isDisable} key={i}>{elm}</option>
                          )
                        })
                      }
                    </select>
                  </td>
                  </tr>

                  <tr>
                    <th className="detail__table--th th-child">{data ?.callStatus === '対応中' ? '次回架電日' : '訪問日時'}</th>
                    {
                      data ?.callStatus === '完了' &&
                      <td className="tr-child">
                          <DateTimePicker
                            onChangeDate={onChangeDate}
                            date={data?.visitDateTemp?.length > 0 || data ?.visitDate ?.length > 0 ? new Date(data ?.visitDateTemp || data ?.visitDate) : ''}
                          />

                        <select
                          className="select-form w-20"
                          name="visitDateFrom"
                          onChange={e => onChangeVisitDate({key: 'visitDateFrom', keyTemp: 'visitDateFromTemp', e})}
                          value={data?.visitDateFromTemp || data ?.visitDateFrom}>
                          {
                            time.map((elm, i) => (
                              <option value={elm} key={i}>{elm}</option>
                            ))
                          }
                        </select>
                        <DateTimePicker
                          onChangeDate={onChangeVisitDateTo}
                          date = {!_.isEmpty(dateTo) ?  new Date(dateTo) : ''}
                        />
                        <select
                          className="select-form w-20"
                          name="visitDateTo"
                          disabled={_.isEmpty(dateTo)}
                          onChange={e => onChangeVisitDate({key: 'visitDateTo', keyTemp: 'visitDateToTemp', e})}
                          value={formatInputSelectTime(data?.visitDateToTemp || data ?.visitDateTo)}>
                          {
                            time.map((elm, i) => (
                              <option value={elm} key={i}>{elm}</option>
                            ))
                          }
                        </select>
                      </td>
                    }

                    {
                      data ?.callStatus === '対応中' &&
                        <td className="tr-child">
                          <DateTimePicker
                            onChangeDate={onChangeDate}
                            date={data ?.newCallDate ?.length > 0 ? new Date(data ?.newCallDate) : ''}
                          />


                          <select
                            className="select-form"
                            name="newCallDateFrom"
                            onChange={onChangeText}
                            value={data ?.newCallDateFrom}>
                            {
                              time.map((elm, i) => (
                                <option value={elm} key={i}>{elm}</option>
                              ))
                            }
                          </select>
                          <select
                            className="select-form"
                            name="newCallDateTo"
                            onChange={onChangeText}
                            value={data ?.newCallDateTo}>
                            {
                              time.map((elm, i) => (
                                <option value={elm} key={i}>{elm}</option>
                              ))
                            }
                          </select>
                        </td>
                    }

                  </tr>

                  <tr>
                  <th className="detail__table--th th-child">作業員</th>
                    <td  className="tr-child">
                      <input className="input-child"
                        name="contactPerson" value={nameStaff}
                        disabled
                      />
                  </td>
                </tr>

              </tbody>
            </table>
            </td>
            <th className="detail__table--th">架電メモ</th>
            <td >
              <textarea name="callLog" rows="4" className="detail__table--color w-100 detail__table--color-text"
                value={masterSend?.callLog} onChange={onChangeCallLog}>
              </textarea>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default DetailUpdate;
