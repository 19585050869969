import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import BasicInfo from '../BasicInfo';
import './style.scss';
import TableInformation from './TableInformation';
import OrderInformation from './OrderInformation';
import SiteInformation from './SiteInformation';
import CollectionInformation from './CollectionInformaiton';
import WorkerInformation from './WorkerInformation';
import { PRODUCT_ENDPOINT } from '../../../../constants/endpoints';

const Modify3 = (props) => {
  const { history } = props;
  const [informationActive, setInformationActive] = useState('order-information');
  const [productInfo, setProductInfo] = useState({});
  const productId = (process.browser && new URL(window.location.href).searchParams.get('productId')) || null;

  const getProductInfo = async ({ productId = '' }) => {
    try {
      const data = await PRODUCT_ENDPOINT.GET_PRODUCT_INFO({ productId: productId });
      if (data) {
        setProductInfo(data?.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (productId) {
      getProductInfo({ productId: productId });
    }
  }, [productId]);

  const onClickActive = (active) => {
    setInformationActive(active);
  };

  const renderInformationTable = (active) => {
    switch (active) {
      case 'order-information':
        return <OrderInformation history={history}/>;
      case 'site-information':
        return <SiteInformation/>;
      case 'worker-information':
        return <WorkerInformation/>;
      case 'collection-information':
        return <CollectionInformation/>;
      default:
        return <></>;
    }
  };

  return (
    <div className='wrapper-modify-3'>
      <BasicInfo productInfo={productInfo}/>
      <TableInformation
        active={informationActive}
        onClickActive={onClickActive}
      />
      {
        renderInformationTable(informationActive)
      }
    </div>
  )
    ;
};

export default compose(withRouter)(Modify3);
