import React from 'react';
import './style.scss';

const renderItem = (item, index, header, headerChild) => {
  const header_main = header && header.concat(headerChild);
  return (
    <tr key={index}>
      <td>{index + 1}</td>
      {header_main.map((element, key) => {
        return (
          <td key={key} className={element ? `class-${element}` : ''}>
            {item[element]}
          </td>
        );
      })}
    </tr>
  );
};

const TableListMaintenance = ({ data = [], t }) => {
  var { count, body, header, headerChild } = data;
  const arr_tmp = [];
  header && header.forEach((element, index) => (arr_tmp[index] = []));
  // var header_child_tmp = arr_tmp.concat(header_child);
  // var header_tmp = header && header.concat(['', '', '', '', '', '']);
  const headerJa = header ?.map(item => t(`table_order_example.${item}`));
  const header_main = header && headerJa.concat(headerChild);

  return (
    <div className="table-list-maintenace-wrapper table-list-wrapper-custom">
      <div>
        <div align="left" className="search_result_num">
          検索結果：{count}件
        </div>
      </div>
      <div className="table-responsive">
      <table className="table table-striped ">
        <thead>
          {/* <tr>
            <th scope="col"></th>
            {header_child_tmp &&
              header_child_tmp.map((item, index) => (
                <th scope="col" key={index} className={item != '' ? 'th-child ' : ''}>
                  {item!="" ? t(`table_order_example.${item}`) : ''}
                </th>
              ))}
          </tr> */}
          <tr>
            <th scope="col">No</th>
            {header_main &&
              header_main.map((item, index) => (
                <th scope="col" key={index}>
                  {item !== "" ? item : ''}
                  <span>
                    <a>▲</a>
                    <a>▼</a>
                  </span>
                </th>
              ))}
          </tr>
        </thead>
        <tbody>{body && body.map((listData, index) => renderItem(listData, index, header, headerChild))}</tbody>
      </table>
      </div>
    </div>
  );
};

export default TableListMaintenance;
