import React from 'react';
import './style.scss';
import ButtonClient from '../ButtonClientMaster';
import {Helmet} from "react-helmet";
import BreadcrumbMaintenance from "../BreadMaintenance";

const SearchPartner = ({fontSize}) => {
  const breadcrumb = [{ name: 'PARTNER MASTER', link: '/partner_master' }];
    return (
    <div className="wrapper-search-partner">
      {/*<h3 className={`title-name ${fontSize}`} onClick={() => (window.location.href = '/center/master_all')}>*/}
      {/*  <a href="#">*/}
      {/*    <i className="fa icon-gauge" aria-hidden="true" />*/}
      {/*    マスターメンテ*/}
      {/*  </a>{' '}*/}
      {/*  &gt; PARTNER MASTER*/}
      {/*</h3>*/}
      <Helmet>
        <title>パートナーマスタ</title>
      </Helmet>
      <BreadcrumbMaintenance breadcrumb={breadcrumb} fontSize={fontSize} color="green" />
      <div className="clearfix ">
        <div className="float-right">
          <ButtonClient subTitle="新規パートナー登録" color="green" icon="icon-pencil" width={300} font={fontSize} />
        </div>
      </div>
      <h3 className={`title-name ${fontSize}`}>検索条件</h3>

      <div className=" row  " style={{ margin: 0 }}>
        <div className="col-xl-6 col-12  d-flex bg-white mb-1 table-list " style={{ padding: 0 }}>
          <div className="tab_condit_input_item  " style={{ width: '30%' }}>
            <p className={`${fontSize}`}> パートナー名</p>
          </div>
          <div className="py-2 w-75 tab_condit ">
            <input type="text" className="tab_condit_input ml-2" name="client_name_s" />
          </div>
        </div>
        <div className="col-xl-6 col-12  d-flex bg-white mb-1 table-list" style={{ padding: 0 }}>
          <div className="tab_condit_input_item  " style={{ width: '30%' }}>
            <p className={`${fontSize}`}> パートナー名カナ</p>
          </div>
          <div className="py-2 w-75 tab_condit">
            <input type="text" className="tab_condit_input ml-2" name="client_name_s" />
          </div>
        </div>
      </div>
      <div className="pt-4" align="center">
        <ButtonClient subTitle="検索" color="green" icon={''} width={100} font={fontSize} />
      </div>
    </div>
  );
};

export default SearchPartner;
