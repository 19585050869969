import React from 'react';
import 'assets/scss/components/AutocompleteAddress/style.scss';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

const AutocompleteAddress = ({
  address,
  onChange = () => {},
  onSelectAddress = () => {},
  name=''
}) => {

  const renderFuncAutoComplete = ({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
    return (
      <>
        <input name={name} id={name} {...getInputProps()}/>
        <div className="selection-adress-suggestion">
          {loading && <div>Loading...</div>}
          {suggestions.map((suggestion, index) => {
            return (
              <div
                {...getSuggestionItemProps(suggestion)}
                className="suggestion-item"
                key={index}
              >
                <span>{suggestion.description}</span>
              </div>
            );
          })}
        </div>
      </>
    )
  }

  const handleSelect = async (address) => {
    await geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        onSelectAddress({
          address,
          latitude: latLng['lat'],
          longitude: latLng['lng']
        })
      })
      .catch((error) => console.error('Error', error));
  };

  return (
    <PlacesAutocomplete
      value={address || ''}
      onChange={onChange}
      onSelect={handleSelect}
    >
      {renderFuncAutoComplete}
    </PlacesAutocomplete>
  )
}

export default AutocompleteAddress
