import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { translate } from 'react-i18next';
import Footer from 'components/Footer';
import { HEADER_MENU } from 'helpers/constants';
import { getListOrder } from 'actions/order';
import { connect } from 'react-redux';
// import PaginationCustom from 'components/PaginationCustom';
import { COLOR_ENDPOINT, MASTER_SETTING } from 'constants/endpoints';
// ORDER, ORDER_PARTNER, ORDER_CLIENT
import { getRole } from 'helpers/helpers';
// import { ROLE, ROLE_ENUM } from 'helpers/constants';
import TableListV2 from 'components/TableListV2';
// import TableListStaff from 'components/TableListStaff';
import HeaderMasterSetting from 'components/HeaderMasterSetting';
import 'assets/scss/page/CenterMasterList/style.scss';

const CenterMasterList = (props) => {
  const { t } = props;
  let name = (process.browser && new URL(window.location.href).searchParams.get('p')) || 'receive_all';
  const [p, setP] = React.useState(name || HEADER_MENU.RECEIVE_ALL);
  const [role, setRole] = React.useState(null);
  const [idRole, setIdRole] = React.useState(null);
  const [color, setColor] = React.useState('#1c2a53');
  const [roleDetail, setRoleDetail] = useState('');
  const [detailCheck, setDetailCheck] = useState();

  useEffect(() => {
    if (idRole) {
      setRoleDetail(getRole({ idRole: idRole }));
    }
  }, [idRole]);

  const getColor = async () => {
    try {
      const data = await COLOR_ENDPOINT.GET_COLOR({ role: idRole });
      if (data && data?.code === 0) {
        setColor(data?.data?.code);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getListOptionSetting = async () => {
    try {
      const data = await MASTER_SETTING.GET_LIST_OPTION_SETTING(localStorage.getItem('idProvide'));
      if (data?.data) {
        setDetailCheck(data?.data?.confirmedTheDayBefore);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (idRole) {
      getColor();
    }
  }, [idRole]);

  useEffect(() => {
    setP(name);
  }, [name]);

  useEffect(() => {
    const newRole = localStorage.getItem('role');
    setIdRole(newRole);
    if (newRole) {
      setRole(getRole({ idRole: newRole }));
    }
    getListOptionSetting()
  }, []);
  return (
    <>
      {/*<BaseCheckAuthen>*/}
      <div className="main">
        <HeaderMasterSetting p={p} idRole={idRole} color={color} />
            <div className="body-wrapper">
              {/* {roleDetail === 'CENTER' && ( */}
                 <TableListV2
                 idRole={idRole}
                 p={p}
                 t={t} role={role}
                 color={color}
                 detailCheck={detailCheck}
                 roleDetail={roleDetail} />

            </div>

        <Footer color={color} />
      </div>
      {/*</BaseCheckAuthen>*/}
    </>

  );
};

const mapStateToProps = (store) => {
  return {
    listOrder: store.order.listOrder,
    loading: store.utils.loading
  };
};

const mapActionToProps = {
  getListOrder
};

export default compose(translate('translations'), withRouter, connect(mapStateToProps, mapActionToProps))(CenterMasterList);
