import GoogleMapReact from 'google-map-react';
import React, { useEffect, useState } from 'react';
import Geocode from 'react-geocode';
import { translate } from 'react-i18next';
import { connect, useSelector } from 'react-redux';
import { Link, useHistory, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { logOut, getRoleMe } from '../../../actions/authentication';
import { ORDER_PARTNER, STAFF_ENDPOINT } from '../../../constants/endpoints';
import { DAY_WEEK } from 'helpers/constants';
import Loading from 'components/Loading/Loading';
import FooterMaintenance from 'components/FooterMaintenance';
import './style.scss';
import { getConfig } from "../../../env"
Geocode.setApiKey(getConfig('REACT_APP_GOOGLE_API_KEY'));
Geocode.setLanguage('ja');
Geocode.setRegion('JP');
Geocode.enableDebug();

const getMapOptions = () => {
  return {
    disableDefaultUI: false,
    mapTypeControl: true,
    streetViewControl: false,
    styles: [{ featureType: 'poi', elementType: 'labels', stylers: [{ visibility: 'on' }] }],
  };
};

const MapWorker = ({ logOut, getRoleMe }) => {
  const userMeData = useSelector((store) => store.authentication.userMe);
  const history = useHistory();
  const [dateTime, setDateTime] = useState(new Date());
  const dataTimeNew = dateTime.toLocaleDateString('ja-JP');
  const [listAddress, setListAddress] = useState([]);
  const workName = (process.browser && new URL(window.location.href).searchParams.get('workName')) || null;
  const [staff, setStaff] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (workName === 'pre') {
      getListByStaff({ day: 'yesterday' });
    } else if (workName === 'today') {
      getListByStaff({ day: 'today' });
    } else if (workName === 'nextday') {
      getListByStaff({ day: 'tomorrow' });
    } else {
      getListByStaff({ day: '' });
    };

    if(userMeData?.data?.staffId) {
      getDetailStaff({ id: userMeData?.data?.staffId });
    };
  }, []);

  const AnyReactComponent = ({ text, id }) => (
    <div className="marker-item">
      <p>
        <span onClick={() => detailOrder(id)}>{text}</span>{' '}
        <i onClick={() => deleteItemAddress(id)} className="fas fa-times"></i>
      </p>
    </div>
  );

  const deleteItemAddress = (id) => {
    const arrTmp = listAddress.filter((item) => {
      return item.orderId !== id;
    });
    setListAddress(arrTmp);
  };

  const detailOrder = (idOrder) => {
    history.push(`/detail-worker-list?orderId=${idOrder}&workName=${workName}`);
  };

  const getListByStaff = async ({ page = '0', limit = '10', day = '' }) => {
    try {
      const data = await ORDER_PARTNER.GET_LIST_BY_STAFF({
        page: page,
        limit: limit,
        day: day,
      });
      if (data && data.data && data.data.body) {
        const result = await Promise.all(
          data?.data?.body.map(async (item) => {
            let address =
              item?.prefecturesVisited + item?.visitedBaseAddress + item?.visitedBuildingName || 'Tokyo, Japan';
            const itemTmp = await Geocode.fromAddress(address);
            const { lng, lat } = itemTmp?.results[0]?.geometry?.location;
            return { lng, lat, prefecturesVisited: item.prefecturesVisited, orderId: item.orderId };
          })
        );
        setListAddress(result);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getDetailStaff = async ({ id = '' }) => {
    try {
      setLoading(true);
      const data = await STAFF_ENDPOINT.GET_DETAIL({ id });
      if(data) {
        setLoading(false);
        setStaff(data?.data || {});
      }else {
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const onClickLogOut = () => {
    logOut();
  };

  return (
    <div className="wrapper-work-list h-100 wrapper-work-list--wrap">
      <Loading loading={loading} />
      <div className="block-link row mr-0 ml-0 wrapper-work-list--wrap-child wrapper-work-list--wrap-100">
        <div className="col-sm-0 col-md-4 col-lg-4 col-xl-4"></div>
        <div className="block-link-child col-sm-6 col-md-4 col-lg-4 col-xl-4">
          <Link to={`/workerList?&workName=today`} className="item">
            <span>一覧</span>
          </Link>{' '}
          <Link to="/worker-map" className="item">
            <span>マップ</span>
          </Link>
        </div>
        <div className="block-feature col-sm-6 col-md-4 col-lg-4 col-xl-4 pr-0">
          <Link to="/select-role" className="mypage_a" onClick={() => getRoleMe()}>
            権限切替
          </Link>
          <div className="ml-2 logout_btn" onClick={() => onClickLogOut()}>
            <a href="#" className="logout_a">
              ログアウト
            </a>
          </div>
        </div>
      </div>
      <div className="wrapper-work-list--pdt-100" style={{ width: '100%' }}>
        <div style={{ fontSize: 30, textAlign: 'center' }} id="strdate">
          {dataTimeNew}({DAY_WEEK[new Date().getDay()]})
        </div>
        <div style={{ fontSize: 20, textAlign: 'center' }} className="my-3" id="str2">
          姓名： {staff.surName} {staff?.name}
        </div>
      </div>
      <div className="wrapper-maps">
        <div style={{ height: '100vh', width: '100%' }}>
          {listAddress ? (
            <GoogleMapReact
              bootstrapURLKeys={{ key: getConfig('REACT_APP_GOOGLE_API_KEY') }}
              defaultCenter={{
                lat: listAddress[0]?.lat || 33.2393075,
                lng: listAddress[0]?.lng || 131.6053725,
              }}
              defaultZoom={16}
              options={getMapOptions}
            >
              {listAddress?.map((item, index) => {
                return (
                  <AnyReactComponent
                    key={index}
                    lat={item?.lat}
                    lng={item?.lng}
                    text={item?.prefecturesVisited || 'Tokyo, Japan'}
                    id={item?.orderId}
                  />
                );
              })}
            </GoogleMapReact>
          ) : (
            ''
          )}
        </div>
      </div>
      <FooterMaintenance/>
    </div>
  );
};
const mapActionToProps = {
  logOut, getRoleMe
};

export default compose(translate('translations'), withRouter, connect(null, mapActionToProps))(MapWorker);
