import React, { useEffect, useState, Fragment } from 'react';
import { TimeDispatch } from 'helpers/constants';
import { ORDER, DISPATCH } from 'constants/endpoints';
import { pushToast } from 'components/Toast';
import ModalConfirm from 'components/ModalConfirm';
import _ from 'lodash';

const TableDispatch = ({
  dataWorkingSchedule,
  organizationId,
  visitDate, orderId,
  onReload,
  pushDescription,
  colors,
  taskActive}) => {
  let elmnt = document.getElementsByName("table-dispatch");
  const newLElm = elmnt.length > 0 && [...elmnt] ?.map(item => item ?.offsetWidth);
  const newWidth = _.compact(newLElm)[0];
  const widthElement = newWidth / (10 * 4);

  const [modalConfirm, setModalConfirm] = useState(false);
  const [bodyRemove, setBodyRemove] = useState({});
  const [orderActive, setOrderActive] = useState(orderId);
  const [valueWidthFormTask, setValueWidthFormTask] = useState();

  const toggle = (body) => {
    setModalConfirm(!modalConfirm);
    setBodyRemove(body)
  };

  useEffect(() => {
    if (widthElement > 0) {
      setValueWidthFormTask(widthElement);
    }
  },[widthElement])

  useEffect(() => {
      getDetalOrder({ orderId: orderActive});
  }, []);

  const handleAddTask = ({ time, staffId, productId }) => {
    const body = {
      visitDateFrom: time,
      orderId, staffId, organizationId,
      productId,
    }
    onAddTask({body});
  };

  const onAddTask = async ({ body = '' }) => {
    try {
      const data = await DISPATCH.ADD_TASK({body});
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
      } else {
        pushToast('success', 'Add task success');
        onReload();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const renderContent = (itemTable, index, productId) => {
    return (
      <div className="form-item-select-time" key={index} name={`table-dispatch`}>
        {
          TimeDispatch.map((item, i) => {
            const itemFormat = ('0' + item).slice(-2)

            return (
              <Fragment key={i}>
                <div className="item-select-time"
                  id={`${item}:00`}
                  onClick={() => handleAddTask({productId, time: `${itemFormat}:00`, staffId:itemTable.staffId })}
                ><i className="fas fa-plus"></i></div>

                <div className="item-select-time"
                  id={`${item}:15`}
                  onClick={() => handleAddTask({productId, time: `${itemFormat}:15`, staffId:itemTable.staffId })}
                ><i className="fas fa-plus"></i></div>

                <div className="item-select-time"
                  id={`${item}:30`}
                  onClick={() => handleAddTask({productId, time: `${itemFormat}:30`, staffId:itemTable.staffId })}
                ><i className="fas fa-plus"></i></div>

                <div className="item-select-time end"
                  id={`${item}:45`}
                  onClick={() => handleAddTask({productId, time: `${itemFormat}:45`, staffId:itemTable.staffId })}
                ><i className="fas fa-plus"></i></div>
              </Fragment>
            )
          })
        }
      </div>
    );
  };

  const onRemoveTask = async ({ body = {} }) => {
    try {
      const data = await DISPATCH.REMOVE_TASK({body});
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
      } else {
        pushToast('success', 'Delete task success');
        setModalConfirm(false);
        onReload();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleConfirm = () => {
    onRemoveTask({body: bodyRemove})
  };

  const handleRemoveTask = ({ orderId, staffId }) => {
    const body = {
      orderId, staffId, organizationId, visitDate
    }
    toggle(body);
  };

  const getDetailTaskJob = ({orderId, taskId, workTime}) => {
    getDetalOrder({ orderId, taskId, workTime });
    setOrderActive(orderId);
  };

  const getDetalOrder = async ({ orderId, taskId, workTime }) => {
    try {
      const data = await ORDER.GET_ORDER_DETAIL_APPOINT_INFO({ orderId, organizationId });
      if (data) {
        pushDescription({data : data && data, taskId, workTime});
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onAddLead = async ({ body = {} }) => {
    try {
      const data = await DISPATCH.ADD_LEAD({body});
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
      } else {
        pushToast('success', 'Add Lead success');
        onReload();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleChangeLead = ({orderId, staffId}) => {
    const body = { orderId, staffId, visitDate };
    onAddLead({body});
  };

  const renderTaskJob = (itemTable, item, index) => {
    const hourStart = item?.visitTime?.split(':')[0];
    const minuteStart = item ?.visitTime ?.split(':')[1];

    const hourEnd = item?.endTime?.split(':')[0];
    const minuteEnd = item ?.endTime ?.split(':')[1];

    const valueWidth = ((((hourEnd - hourStart) * 4) + ((minuteEnd - minuteStart) / 15)) * valueWidthFormTask);
    const valueLeft = ((((hourStart - 6) * 4) + ((minuteStart) / 15)) * valueWidthFormTask);

    const valueWidthMovingTime = (item?.movingTime * valueWidthFormTask) / 15;
    const valueWidthWorkTime = (((item ?.workTime.split(':')[0] * 4) + (item ?.workTime.split(':')[1] / 15)) * valueWidthFormTask);

    const textTime = () => {
      const order = dataWorkingSchedule?.body?.find(i => i?.orderId === item?.orderId);
      return order ? `${order ?.visitDateFrom ?.substring(0, 5)} ~ ${order ?.visitDateTo ?.slice(-5)}` : `${item ?.visitTime ?.substring(0, 5)} ~ ${item ?.endTime ?.substring(0, 5)}`;
    }

    return (
      <div
        className={taskActive?.taskId === item.id ? 'active form-task-job' : 'form-task-job'}
        id={item.id}
        key={index}
        style={{ width: valueWidth, left: valueLeft }}
        onClick={() => getDetailTaskJob({ orderId: item?.orderId, taskId: item?.id, workTime:item?.workTime})}
      >
        <div
          className={orderId === item.orderId ? 'content-task-job' : 'content-task-job diff'}
          style={{ backgroundColor: (item?.labelSetting || colors) }}
        >
          {
            orderId === item.orderId &&
            <div className="form-checkbox">
              <input
                type="checkbox"
                id={item.id}
                checked={item.isLead}
                disabled={item.isLead}
                onChange={() => {
                  handleChangeLead({
                    orderId: item?.orderId,
                    staffId: item?.staffId
                  })
                }}
              />
            </div>
          }

          <div
            className="form-come"
            style={{ width: valueWidthMovingTime }}>
              <i className="fas fa-car"></i>
            </div>
          <div className="form-work"
            style={{ width: valueWidthWorkTime }}
          >
            <p title={textTime()}>{textTime()}</p>
            <p title={item?.managementCode || ''}>{item?.managementCode || ''}</p>
          </div>

          <div
            className="form-go"
            style={{ width: valueWidthMovingTime }}
          ><i className="fas fa-car"></i></div>
          <div className="form-close"
            onClick={() => handleRemoveTask({
              orderId: item?.orderId,
              staffId: item?.staffId
            })}
          ><i className="fas fa-times"></i></div>
        </div>
      </div>
    )

  };

  const renderHeader = () => {
    return TimeDispatch.map((item, index) => {
      return (
        <Fragment key={index}>
          <div className="item-time-header" >
            <span className="circle-time">{item}</span></div>
          <div className="item-time-header"></div>
          <div className="item-time-header"></div>
          <div className="item-time-header"></div>
        </Fragment>
      )
    })
  };

  return (
    <div className="wrapper-table-dispatch">
      <div className="form-table">
        <div className="form-name-item">
          <div className="padding-header"></div>
          <div className="form-label-item">
              {
              _.sortBy(dataWorkingSchedule ?.staffs, 'area') ?.map((itemTable, index) =>
                <div className='name-item-table row' key={index} >
                  <div title={`${itemTable?.area || ''} ${itemTable.branchAbbreviation || itemTable.abbreviationName || ''}`} className="col-12">{`${itemTable?.area || ''} ${itemTable.branchAbbreviation || itemTable.abbreviationName || ''}`}</div>
                  <div title={`${itemTable.surName} ${itemTable.name}`} className="col-12">{`${itemTable.surName} ${itemTable.name}`}</div>
                </div>)
              }
          </div>
        </div>

        <div className="form-content-table">
          <div className="header-table">
            {renderHeader()}
          </div>
          {
          dataWorkingSchedule?.staffs?.length > 0 ?
          <div className="content-table">
            {
              _.sortBy(dataWorkingSchedule?.staffs, 'area')?.map((itemTable, index) => {
                return (
                  <div className="row-form" key={index}>
                    {
                      itemTable?.workingSchedule?.map((item, index) => renderTaskJob(itemTable, item, index))
                    }
                    {renderContent(itemTable, index, dataWorkingSchedule?.productId)}
                  </div>
                )
              })
            }
          </div> : <div className="form-no-data">No Data</div>
        }
        </div>
      </div>
      <ModalConfirm toggle={toggle} modal={modalConfirm} handleConfirm={handleConfirm}/>
    </div>
  );
};

export default TableDispatch;
