import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { Helmet } from 'react-helmet';
import BreadcrumbMaintenance from '../../../components/BreadMaintenance';
import FooterMaintenance from '../../../components/FooterMaintenance';

import './style.scss';
import TablePageMaster from './TablePageMaster';
import TableSelectMaster from './TableSelect';
import HeaderMasterSetting from '../../../components/HeaderMasterSetting';

const dataAllSelect = [
  { label: '訪問先拠点住所', value: '訪問先拠点住所', type: 'basic-select' },
  { label: '申し送り事項', value: '申し送り事項', type: 'basic-select' },
  {
    label: 'パートナー伝達用申し送り事項',
    value: 'パートナー伝達用申し送り事項',
    type: 'basic-select',
  },
  { label: '担当者名', value: '担当者名', type: 'basic-select' },
  { label: '稼働調整日', value: '稼働調整日', type: 'basic-select' },
];

const dataBasicSelect = [
  { label: '1', value: '1', type: 'basic-select' },
  { label: '2', value: '2', type: 'basic-select' },
  { label: '3', value: '3', type: 'basic-select' },
  { label: '4', value: '4', type: 'basic-select' },
  { label: '5', value: '5', type: 'basic-select' },
];

const dataPremium = [
  { label: 'a', value: 'a', type: 'premium-select' },
  { label: 'b', value: 'b', type: 'premium-select' },
  { label: 'c', value: 'c', type: 'premium-select' },
  { label: 'd', value: 'd', type: 'premium-select' },
  { label: 'e', value: 'e', type: 'premium-select' },
];

const PageMaster = () => {
  const breadcrumb = [
    { name: 'CENTER MASTER', link: '/center_master_all' },
    { name: '表示画面設定', link: '/page_master' },
  ];
  const fontSize ='small';
  const [dataBasic, setDataBasic] = useState(dataBasicSelect);
  const [dataAll, setDataAll] = useState(dataAllSelect);
  const [basicSelect, setBasicSelect] = useState(null);
  const [allSelect, setAllSelect] = useState(null);

  const handleChangeBasic = (event) => {
    setBasicSelect(event.target.selectedIndex);
    setAllSelect(null);
  };

  const handleChangeAll = (event) => {
    setAllSelect(event.target.selectedIndex);
    setBasicSelect(null);
  };

  const onClickInsert = (index) => {
    if (index != null) {
      let newdata = [...dataAll];
      let itemValue = { ...dataBasic[index] };
      if (itemValue) {
        newdata.push(itemValue);
      }
      setDataAll(newdata);
      let newDataBasic = [...dataBasic];
      newDataBasic.splice(index, 1);
      setDataBasic(newDataBasic);
      setBasicSelect(null);
      setAllSelect(null);
    }
  };

  const onClickDel = (index) => {
    if (index !== null) {
      let newBasicData = [...dataBasic];
      let itemValue = { ...dataAll[index] };
      newBasicData.push(itemValue);
      setDataBasic(newBasicData);
      let newAllData = [...dataAll];
      newAllData.splice(index, 1);
      setDataAll(newAllData);
      setAllSelect(null);
      setBasicSelect(null);
    }
  };

  function array_move(arr, old_index, new_index) {
    while (old_index < 0) {
      old_index += arr.length;
    }
    while (new_index < 0) {
      new_index += arr.length;
    }
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    setDataAll(arr);
    return arr; // for testing purposes
  }

  const onClickUp = () => {
    if (allSelect > 0) {
      array_move(dataAll, allSelect, allSelect - 1);
      setAllSelect(allSelect - 1);
    }
  };

  const onClickDown = () => {
    if (allSelect < dataAll.length - 1) {
      array_move(dataAll, allSelect, allSelect + 1);
      setAllSelect(allSelect + 1);
    }
  };

  const onClickUpFirst = () => {
    if (allSelect !== 0) {
      array_move(dataAll, allSelect, 0);
      setAllSelect(0);
    }
  };

  const onClickDownLast = () => {
    if (allSelect !== dataAll.length) {
      array_move(dataAll, allSelect, dataAll.length - 1);
      setAllSelect(dataAll.length - 1);
    }
  };

  return (
    <div className="wrapper-page-master">
      <Helmet>
        <title>表示画面設定</title>
      </Helmet>
      <HeaderMasterSetting/>
      <div className="body pb-4">
        <div className="wrapper-main">
          <BreadcrumbMaintenance breadcrumb={breadcrumb} fontSize={fontSize} />
          <div className="d-flex flex-wrap group-button mb-1">
            <button className={`btn button-search active mb-1 ${fontSize}`}>全情報</button>
            <button className={`btn button-search mb-1 ${fontSize}`}>全情報</button>
            <button className={`btn button-search mb-1 ${fontSize}`}>全情報</button>
            <button className={`btn button-search mb-1 ${fontSize}`}>全情報</button>
          </div>
          <div className="d-flex flex-wrap group-button">
            <button className={`btn button-search active mb-1 ${fontSize}`}>全情報</button>
            <button className={`btn button-search mb-1 ${fontSize}`}>全情報</button>
            <button className={`btn button-search mb-1 ${fontSize}`}>全情報</button>
            <button className={`btn button-search mb-1 ${fontSize}`}>全情報</button>
            <button className={`btn button-search mb-1 ${fontSize}`}>全情報</button>
            <button className={`btn button-search mb-1 ${fontSize}`}>全情報</button>
            <button className={`btn button-search mb-1 ${fontSize}`}>全情報</button>
            <button className={`btn button-search mb-1 ${fontSize}`}>全情報</button>
            <button className={`btn button-search mb-1 ${fontSize}`}>全情報</button>
            <button className={`btn button-search mb-1 ${fontSize}`}>全情報</button>
            <button className={`btn button-search mb-1 ${fontSize}`}>全情報</button>
          </div>
          <div className="wrapper-result mt-3 text-center">
            <h2 className={`font-weight-bolder ${fontSize}`}>
              <i className="fa fas fa-external-link-alt" />
              リンク表示設定
            </h2>
          </div>
          <TablePageMaster fontSize={fontSize} />
          <div className="wrapper-result mt-4 text-center">
            <h2 className={`font-weight-bolder ${fontSize}`}>
              <i className="far fa-list-alt" />
              表示項目設定
            </h2>
          </div>
          <div className="d-flex pb-4" style={{ height: '500px' }}>
            <div className="col-5 d-flex flex-column wraper-text">
              <div className="h-50">
                <label className={`m-0 p-0 ${fontSize}`}>基本フィールド</label>
                <TableSelectMaster
                  tableTop="tableTop"
                  data={dataBasic}
                  fontSize={fontSize}
                  selected={basicSelect}
                  handleChange={handleChangeBasic}
                />
              </div>
              <div className="h-50">
                <label className={`m-0 p-0 ${fontSize}`}>追加フィールド</label>
                <TableSelectMaster data={dataPremium} fontSize={fontSize} />
              </div>
            </div>
            <div className="col-1 d-flex flex-column align-items-center justify-content-center group-button">
              <button className={`btn button-insert ${fontSize}`} onClick={() => onClickInsert(basicSelect)}>
                追加→
              </button>
              <button className={`btn button-insert ${fontSize}`} onClick={() => onClickDel(allSelect)}>
                ←削除
              </button>
            </div>
            <div className="col-5 wraper-text">
              <label className={`m-0 p-0 ${fontSize}`}>表示フィールド</label>
              <TableSelectMaster
                data={dataAll}
                fontSize={fontSize}
                handleChange={handleChangeAll}
                selected={allSelect}
              />
            </div>
            <div className="col-1 d-flex flex-column align-items-center justify-content-center">
              <input type="button" className="topbtn my-1" onClick={() => onClickUpFirst()} />
              <input type="button" className="upbtn my-1" onClick={() => onClickUp()} />
              <input type="button" className="downbtn my-1" onClick={() => onClickDown()} />
              <input type="button" className="bottombtn my-1" onClick={() => onClickDownLast()} />
            </div>
          </div>
          <div className="group-button d-flex justify-content-center">
            <button className={`btn button-submit mt-3 ${fontSize}`}>追加</button>
          </div>
        </div>
      </div>
      <FooterMaintenance />
    </div>
  );
};

export default compose(withRouter)(PageMaster);
