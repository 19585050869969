import React from 'react';
import TableProcess from '../TableProcess';

const data = [
  { id: 3, label: '開通試験', essential: true },
  { id: 2, label: '宅内工事', essential: false },
  { id: 6, label: '現場調査', essential: true },
];

const CollectionInformation = () => {
  return (
    <div className="report-colletion-wrapper mt-3">
      <div className="wrapper-column" style={{ fontSize: '14px' }}>
        報告書回収情報
      </div>
      <div className="d-flex h-100">
        <div className="wrapper-column col-2">報告書添付必須</div>
        <div className="wrapper-table-right">
          <TableProcess data={data} />
        </div>
      </div>
    </div>
  );
};

export default CollectionInformation;
