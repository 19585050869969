import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import HeaderMasterSetting from 'components/HeaderMasterSetting';
import { ANKEN_MASTER_NEW } from '../../../constants/endpoints';
import Loading from 'components/Loading/Loading';
import ModalFix from './ModalFix';
import ModalDeletion from './ModalDeletion';
import { translate } from 'react-i18next';

const RecordCorrection = ({ t }) => {
  const colorScreen = useSelector((store) => store.utils.colorMe);
  const [listTreeNone, setListTreeNone] = useState();
  const [loading, setLoading] = useState(false);
  const [modalFix, setModalFix] = useState(false);
  const [modalDeletion, setModalDeletion] = useState(false);
  const [productId, setProductId] = useState('');
  const [serviceId, setServiceId] = useState('');

  const getTree = async () => {
    try {
      setLoading(true);
      const data = await ANKEN_MASTER_NEW.GET_TREE_BY_PROJECT();
      if (data) {
        setListTreeNone(data?.data?.clients);
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  useEffect(() => {
    getTree();
  }, []);

  const toggleOpenFix = ({ item, productId }) => {
    setServiceId(item?.serviceId)
    setProductId(productId)
    setModalFix(!modalFix);
  };

  const toggleOpenDeletion = ({ item, productId }) => {
    setServiceId(item?.serviceId)
    setProductId(productId)
    setModalDeletion(!modalDeletion);
  };

  const renderTreeProject = (client, i) => {
    return (
      <div className="form-tree" key={i}>
        <div className="d-flex flex-direction-row">
          <p className="title-project">{client?.name}</p>
        </div>
        {
          client?.projects?.length > 0 &&
          <ul>
            {client && client?.projects.map((item, index) => {
              return (
                <li key={index}>
                  <p>
                    {item.projectName}
                  </p>
                  {item?.product?.length > 0 ? itemTree(item?.product) : ''}
                </li>
              );
            })}
          </ul>
        }
      </div>
    )
  };

  const itemTree = (array) => {
    return (
      <ul>
        {array &&
          array.map((item, index) => {
            return (
              <li key={index}>
                <p>
                  {item.name}
                </p>
                {item?.service?.length > 0 ? itemTrees(item?.service, item.productId) : ''}
              </li>
            );
          })}
      </ul>
    );
  };

  const itemTrees = (array, productId) => {
    return (
      <ul>
        {array &&
          array.map((item, index) => {
            return (
              <li key={index}>
                <p>
                  {item.name}
                  <button onClick={() => toggleOpenFix({ item, productId })}>修正</button>
                  <button onClick={() => toggleOpenDeletion({ item, productId })}>レコード削除</button>
                </p>
              </li>
            );
          })}
      </ul>
    );
  };

  return (
    <div className='wrapper-master-setting'>
      <Loading loading={loading} />
      <HeaderMasterSetting checkName="/record-correction" color={colorScreen?.code} colorText={colorScreen?.colorText} />
      <div className="body pb-4">
        <div className="wrapper-main">
          <div className="wrapper-result">
            <div className='setting-master-menu-wrapper'>
              <div className="content-view p-5">
                {listTreeNone && listTreeNone?.map((item, key) => renderTreeProject(item, key))}
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center align-items-center group-button"></div>
        </div>
      </div>
      {modalFix ? <ModalFix modal={modalFix} toggle={toggleOpenFix} productId={productId} serviceId={serviceId} t={t}/> : ''}
      {modalDeletion ? <ModalDeletion modal={modalDeletion} toggle={toggleOpenDeletion} productId={productId} serviceId={serviceId} /> : ''}
    </div>
  )
};

export default translate('translations')(RecordCorrection);