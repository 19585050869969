import FooterMaintenance from 'components/FooterMaintenance';
import HeaderMasterSetting from 'components/HeaderMasterSetting';
import ModalConfirm from 'components/ModalConfirm';
import { pushToast } from 'components/Toast';
import { WORKER_ITEM_SETTINGS } from 'constants/endpoints';
import { genId } from 'helpers/helpers';
import React, { useEffect, useState, Fragment } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import './style.scss';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import MemoHistory from '../../../components/MemoHistory';
import { SCREEN_NAME } from 'helpers/constants';
import { useHistory } from 'react-router-dom';
import Loading from 'components/Loading/Loading';
import { translate } from 'react-i18next';
import { notUpdateCSV } from '../../../helpers/constants';
import { getDataHistoryMasterSetting } from 'actions/authentication';

const WorkerItemSettings = (props) => {
  const { t } = props;
  const dispatch = useDispatch();
  let versionServiceId = new URL(window.location.href).searchParams.get('versionServiceId') || '';
  let menuSettingId = new URL(window.location.href).searchParams.get('menuSettingId') || '';
  let serviceProductId = new URL(window.location.href).searchParams.get('serviceProductId');
  const productId = `P${serviceProductId.split('P')?.[1]}`;
  let menuName = new URL(window.location.href).searchParams.get('menuName');
  let serviceName = decodeURIComponent(new URL(window.location.href).searchParams.get('serviceName')) || '';
  let productName = decodeURIComponent(new URL(window.location.href).searchParams.get('productName')) || '';
  let version = decodeURIComponent(new URL(window.location.href).searchParams.get('version')) || '';
  const colorScreen = useSelector((store) => store.utils.colorMe);
  const [idRole, setIdRole] = useState('');
  const [listItem, setListItem] = useState([]);
  const [listOptionsType, setListOptionsType] = useState([]);
  const [itemDelete, setItemDelete] = useState({});
  const [modalConfirm, setModalConfirm] = useState(false);
  const [listCsvExport,setListCsvExport] = useState({})
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [optionListItem,setOptionListItem] = useState([]);
  const dataUpdateType = ['callLog','visitDateFrom','visitDateTo']

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onReturn = () => {
    history.push(`/configuration-worker/worker-menu-setting?sortKey=${versionServiceId}&serviceProductId=${serviceProductId}&productName=${productName}&serviceName=${serviceName}&version=${version}`);
  }

  const toggle = (item) => {
    setModalConfirm(!modalConfirm);
    setItemDelete(item);
  };

  useEffect(()=> {
    if(listItem) {
      const array = []
      let data =  listItem?.map((item , index) => {
        if(_.includes(['フィールド反映', 'フィールド参照'], item?.itemType[0]?.name)) {
          let ArrayValue =  item?.itemType[0]?.value?.map((elm, i) => {
            if(_.isUndefined(elm?.num)) {
              return array?.push(`${index > 8 ? '' : '0'}${index+1}_${i+1}`)
            } else {
              return  array?.push(elm?.num)
            }
          })
        } else {
          if(!_.includes(['自動判定'], item?.itemType[0]?.name)) {
            return array?.push(`${index > 8 ? '' : '0'}${index + 1}_1`)
          }
        }
      })
      setOptionListItem(_.compact(array))
    }
  },[listItem])

  const handleDeleteWorkerItemSettings = async () => {
    try {
      const data = await WORKER_ITEM_SETTINGS.DELETE_WORKER_ITEM_SETTINGS({
        menuSettingId,
        menuItemSettingId: itemDelete.menuItemSettingId,
        versionServiceId,
        serviceProductId,
        menuName,
      });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data ?.message ?.extra);
        } else {
          pushToast('error', data ?.message ?.errorMessage);
        }
      } else {
        pushToast('success', 'delete success');
        getListWorkerItemSettings({ menuSettingId });
        toggle();
      }
    } catch (errorMessage) {
      console.log('errorMessage: ', errorMessage);
    }
  };

  const handleConfirm = () => {
    handleDeleteWorkerItemSettings();
  };

  const changeListSelect = (e, menuItemSettingId) => {
    const value = e.target.value;
    let newList = listItem?.map((item) => {
      const objValue =
        value === '自動判定'
          ? { name: value, value: [{ sortNum: 1 }, { sortNum: 2 }] }
          : value === 'リンクボタン'
          ? { name: value, value: [{ sortNum: 1 }] }
          : { name: value };
      if (item.menuItemSettingId === menuItemSettingId) {
        return { ...item, itemType: [objValue] };
      } else {
        return item;
      }
    });
    setListItem(newList);
  };

  const deleteListValueCheck = ({menuItemSettingId, index}) => {
    const newList = listItem?.map((item) => {
      if (item.menuItemSettingId === menuItemSettingId) {
        let newItemType = item ?.itemType[0].value.filter((item, i) => index !== i);
        return {
          ...item,
          itemType: [{
            ...item ?.itemType[0], value: newItemType
          }]
        }
      } else {
        return item;
      }
    });
    setListItem(newList);
  };

  const getCsvExport = async ({ productId = '' }) => {
    setLoading(true);
    try {
      const data = await WORKER_ITEM_SETTINGS.GET_CSV_EXPORT({
        productId,
      });
      if (data) {
        setListCsvExport(data)
      }
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const getListWorkerItemSettings = async ({ menuSettingId = '' }) => {
    setLoading(true);
    try {
      const data = await WORKER_ITEM_SETTINGS.GET_LIST_WORKER_ITEM_SETTINGS({
        menuSettingId,
        versionServiceId,
        serviceProductId,
        menuName
      });
      if (data) {
        setListItem(data?.data?.items);
        setListOptionsType(data?.data?.itemType);
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  useEffect(() => {
    const newRole = localStorage.getItem('role');
    setIdRole(newRole);
    getListWorkerItemSettings({ menuSettingId });
    getCsvExport({productId});
  }, []);


  const deleteListItem = (item) => {
    if (!item.hasOwnProperty('menuSettingId')) {
      setListItem(listItem.filter(elm => elm.menuItemSettingId !== item.menuItemSettingId));
    } else {
      toggle(item);
    }
  };

  const addListItem = (index) => {
    const newList = [...listItem,
      {
        menuItemSettingId: genId(),
        required: false,
        itemName: '',
        content: '',
        itemType: [],
        titleName: '',
        type: ''
       }];
    setListItem(newList);
  };

  const handleChange = (e, menuItemSettingId) => {
    const valueField = e.target.value;
    const nameField = e.target.name;
    const newData = listItem?.map((item) => {
      if (item.menuItemSettingId === menuItemSettingId) {
        return { ...item, [nameField]: valueField };
      } else {
        return item;
      }
    });
    setListItem(newData);
  };

  const handleChangeCheckBox = (menuItemSettingId) => {
    const newData = listItem?.map((item) => {
      if (item.menuItemSettingId === menuItemSettingId) {
        return { ...item, required: !item.required };
      } else {
        return item;
      }
    });
    setListItem(newData);
  };

  const editWorkerItemSettings = async ({ body }) => {
    setLoading(true);
    try {
      const data = await WORKER_ITEM_SETTINGS.EDIT_WORKER_ITEM_SETTINGS({ body });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
        setLoading(false);
      } else {
        pushToast('success', 'Edit version service success');
        getListWorkerItemSettings({ menuSettingId });
        dispatch(getDataHistoryMasterSetting({ logId: '', screenName: SCREEN_NAME.WORKER_SETTINGS }));
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const handleEditWorkerItemSettings = () => {
    const newListItem = listItem ?.map((item, index) => {
      let newItemtype = [{
        name: item ?.itemType[0] ?.name
      }];

      if (item?.itemType[0]?.name === 'フィールド参照') {
        newItemtype[0].value = item?.itemType[0]?.value?.map((elm, i) => {
          return {
            name: elm.name,
            sortNum: i + 1,
            defaultValue: elm?.defaultValue,
            nameInStella: elm?.nameInStella,
            codeInStella: elm?.codeInStella,
            isUsed: elm?.isUsed,
            type: elm?.type,
            distinguish: elm?.distinguish,
            outputProject: elm?.outputProject,
            num: `${index > 8 ? '' : '0'}${index+1}_${i+1}`,
          };
        });
      }

      if(item?.itemType[0]?.name === 'フィールド反映') {
        newItemtype[0].value = item?.itemType[0]?.value?.map((elm, i) => {
          return {
            name: elm.name,
            sortNum: i + 1,
            defaultValue: elm?.defaultValue,
            nameInStella: elm?.nameInStella,
            codeInStella: elm?.codeInStella,
            isUsed: elm?.isUsed,
            type: elm?.type,
            distinguish: elm?.distinguish,
            outputProject: elm?.outputProject,
            num: `${index > 8 ? '' : '0'}${index+1}_${i+1}`,
            typeUpdate : elm?.typeUpdate,
          };
        });
      }

      if ( _.includes(['ラジオボタン', 'チェックボックス', '自動判定'], item?.itemType[0]?.name)) {
        newItemtype[0].value = item ?.itemType[0] ?.value ?.map((elm, i) => {
          return {
            name: elm.name,
            sortNum: i + 1,
          }
        })
      };

      if(item?.itemType[0]?.name === "リンクボタン") {
        newItemtype[0].value = item ?.itemType[0] ?.value ?.map((elm, i) => {
          return {
            name: elm.name || '',
            sortNum: i + 1,
            link : elm?.link || '',
          }
        })
      }

      if (!item.hasOwnProperty('menuSettingId')) {
        if(_.includes(['フィールド反映', 'フィールド参照'], item?.itemType[0]?.name)) {
          return {
            content: item.content,
            itemName: item.itemName,
            sortNum: index + 1,
            itemType: newItemtype,
            titleName: item.titleName,
            type: item.type,
            required: item.required,
          };
        } else {
          return {
            content: item.content,
            itemName: item.itemName,
            sortNum: index + 1,
            itemType: newItemtype,
            titleName: item.titleName,
            type: item.type,
            required: item.required,
            num : `${index > 8 ? '' : '0'}${index+1}_1`
          };
        }
      } else {
        if(_.includes(['フィールド反映', 'フィールド参照'], item?.itemType[0]?.name)) {
          return {
            titleName: item.titleName,
            type: item.type,
            itemName: item.itemName,
            content: item.content,
            required: item.required,
            sortNum: index + 1,
            itemType: newItemtype,
            menuItemSettingId: item.menuItemSettingId,
          };
        } else {
          return {
            titleName: item.titleName,
            type: item.type,
            itemName: item.itemName,
            content: item.content,
            required: item.required,
            sortNum: index + 1,
            itemType: newItemtype,
            menuItemSettingId: item.menuItemSettingId,
            num : `${index > 8 ? '' : '0'}${index+1}_1`
          };
        }
      }
    });

    const body = {
      menuItems: newListItem,
      versionServiceId,
      menuSettingId,
      serviceProductId,
      screenName: SCREEN_NAME.WORKER_SETTINGS,
      menuName,
      updateTitle: `保存 ${productName} ${serviceName}`
    };

    const statusDisable = body ?.menuItems ?.map((item) => {
      if (_.includes(['フィールド反映', 'フィールド参照', '自動判定','ラジオボタン','チェックボックス'], item?.itemType[0]?.name)) {
        if (_.isEmpty(item?.itemType[0].value)) {
          return false;
        } else {
          return true
        }
      } else {
        return true
      }
    });

    if (statusDisable.includes(false)) {
      pushToast('error', 'Please add Button Field');

    } else {
      editWorkerItemSettings({ body });
    }

  };

  const addListValueCheck = ({menuItemSettingId}) => {
    const newList = listItem.map(item => {
      const objValue = {sortNum: item ?.itemType[0] ?.value?.length > 0 ? item ?.itemType[0] ?.value?.length + 1 : 1 };
      if (menuItemSettingId === item.menuItemSettingId) {
        if (item ?.itemType[0] ?.value) {
          return {
            ...item,
            itemType: [{
              ...item ?.itemType[0], value: [...item ?.itemType[0] ?.value, objValue]
            }]
          }
        } else {
          return {
            ...item,
            itemType: [{
              ...item ?.itemType[0], value: [objValue]
            }]
          }
        }

      } else {
        return item
      };
    });
    setListItem(newList);
  };

  const addListValueCheckSelect = ({ menuItemSettingId }) => {
    const newList = listItem.map((item) => {
      const objValue = { sortNum: item?.itemType[0]?.value?.length > 0 ? item?.itemType[0]?.value?.length + 1 : 1 };
      if (menuItemSettingId === item.menuItemSettingId) {
        if (item?.itemType[0]?.value) {
          return {
            ...item,
            itemType: [
              {
                ...item?.itemType[0],
                value: [...item?.itemType[0]?.value, objValue],
              },
            ],
          };
        } else {
          return {
            ...item,
            itemType: [
              {
                ...item?.itemType[0],
                value: [objValue],
              },
            ],
          };
        }
      } else {
        return item;
      }
    });
    setListItem(newList);
  };

  const handleChangeValueCheck = ({ index, menuItemSettingId, e }) => {
    const valueInput = e.target.value;
    const newList = listItem.map((item) => {
      if (menuItemSettingId === item.menuItemSettingId) {
        const newListItem = item ?.itemType[0] ?.value ?.map((elm,i) => {
          if (index === i) {
            return { ...elm, name: valueInput }
          }
          return elm
        });
        return {
          ...item,
          itemType: [{
            ...item ?.itemType[0], value: newListItem
          }]}
      } else {
        return item
      };
    });
    setListItem(newList);
  };

  const handleChangeValueSelect = ({ index, menuItemSettingId, e }) => {
    const valueInput = e.target.value;
    const nameSelect = e.target.name;
    const newList = listItem.map((item) => {
      if (menuItemSettingId === item.menuItemSettingId) {
        const newListItem = item ?.itemType[0] ?.value ?.map((elm,i) => {

          if (index === i) {
            if(valueInput === 'インポートCSV') {
              return {...elm , 'distinguish' : valueInput , 'outputProject' : 'Primary' }
            } else {
              return { ...elm, [nameSelect]: valueInput }
            }
          }
          return elm
        });
        return {
          ...item,
          itemType: [{
            ...item ?.itemType[0], value: newListItem
          }]}
      } else {
        return item
      };
    });
    setListItem(newList);
  };

  const handleOjectSelect = ({ index, menuItemSettingId, e }) => {
    const newList = listItem.map((item) => {
      if (menuItemSettingId === item.menuItemSettingId) {
        const newListItem = item?.itemType[0]?.value?.map((elm, i) => {
          let dataOutputProjects = _.filter(listCsvExport?.data, { distinguish: elm?.distinguish });
          let dataName = _.filter(dataOutputProjects?.[0]?.outputProjects, { outputProject: elm?.outputProject });
          let dataUpdateSelect = _.filter(dataName?.[0]?.names, { name: e.target.value });
          let ojectUpdate = {
            name: dataUpdateSelect?.[0]?.name,
            defaultValue: dataUpdateSelect?.[0]?.defaultValue,
            nameInStella: dataUpdateSelect?.[0]?.nameInStella,
            codeInStella: dataUpdateSelect?.[0]?.codeInStella,
            isUsed: dataUpdateSelect?.[0]?.isUsed,
            type: dataUpdateSelect?.[0]?.type,
          };
          if (index === i) {
            return { ...elm, ...ojectUpdate };
          }
          return elm;
        });
        return {
          ...item,
          itemType: [
            {
              ...item?.itemType[0],
              value: newListItem,
            },
          ],
        };
      } else {
        return item;
      }
    });
    setListItem(newList);
  };

  const handleOjectSelectUpdate = ({ index, menuItemSettingId, e  , dataNoneOutputProjects}) => {
    let  optionData = dataNoneOutputProjects?.filter((ele => ele.name === e.target.value))?.[0]
    const newList = listItem.map((item) => {
      if (menuItemSettingId === item.menuItemSettingId) {
        const newListItem = item?.itemType[0]?.value?.map((elm, i) => {
          let dataOutputProjects = _.filter(listCsvExport?.data, { distinguish: elm?.distinguish });
          let dataName = _.filter(dataOutputProjects?.[0]?.outputProjects, { outputProject: optionData?.outputProjects });
          let dataUpdateSelect = _.filter(dataName?.[0]?.names, { name: e.target.value });
          let ojectUpdate = {
            outputProject: optionData?.outputProjects,
            name: dataUpdateSelect?.[0]?.name,
            defaultValue: dataUpdateSelect?.[0]?.defaultValue,
            nameInStella: dataUpdateSelect?.[0]?.nameInStella,
            codeInStella: dataUpdateSelect?.[0]?.codeInStella,
            isUsed: dataUpdateSelect?.[0]?.isUsed,
            type: dataUpdateSelect?.[0]?.type,
          };
          if (index === i) {
            return { ...elm, ...ojectUpdate };
          }
          return elm;
        });
        return {
          ...item,
          itemType: [
            {
              ...item?.itemType[0],
              value: newListItem,
            },
          ],
        };
      } else {
        return item;
      }
    });
    setListItem(newList);
  };

  const renderAddInput = (item) => {
    return (
      <Fragment>
        {item?.itemType[0].value?.map((elm, index) => (
          <Draggable key={`${index + 1}`} draggableId={`${index + 1}`} index={index}>
            {(provided, snapshot) => (
              <div
                key={index}
                className="form-selected"
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
              >
                <div
                  className="icon"
                  style={{ borderColor: `${colorScreen?.code}`, color: `${colorScreen?.code}` }}
                  onClick={() => deleteListValueCheck({ menuItemSettingId: item.menuItemSettingId, index })}
                >
                  <i className="fas fa-times"></i>
                </div>
                {item?.itemType[0].name === '自動判定' ? (
                  <>
                    <select
                      className="item-selected"
                      style={{ borderColor: `${colorScreen?.code}`, color: `${colorScreen?.code}` }}
                      value={elm?.name}
                      onChange={(e) => handleChangeValueCheck({ index, menuItemSettingId: item.menuItemSettingId, e })}
                    >
                      <option value=''>...</option>
                      {optionListItem?.map((ele) => (
                        <option value={ele}>{ele}</option>
                      ))}
                    </select>
                  </>
                ) : (
                  <input
                    className="item-selected"
                    style={{ borderColor: `${colorScreen?.code}`, color: `${colorScreen?.code}` }}
                    value={elm?.name}
                    onChange={(e) => handleChangeValueCheck({ index, menuItemSettingId: item.menuItemSettingId, e })}
                  />
                )}
              </div>
            )}
          </Draggable>
        ))}
        {item?.itemType[0].name !== '自動判定' && (
          <div
            className="icon-add"
            style={{ borderColor: `${colorScreen?.code}`, color: `${colorScreen?.code}` }}
            onClick={() => addListValueCheck({ menuItemSettingId: item.menuItemSettingId })}
          >
            <i className="fas fa-plus"></i>
          </div>
        )}
      </Fragment>
    );
  };

  const renderShowValue = (item, indexSelect) => {
    return (
      <Fragment>
        {item?.itemType[0].value?.map((elm, index) => {
          let dataOutputProjects = _.filter(listCsvExport?.data, { distinguish: elm?.distinguish });
          let dataName = _.filter(dataOutputProjects?.[0]?.outputProjects, { outputProject: elm?.outputProject });
          return (
            <tr className="item-worker">
              <td></td>
              <td></td>
              <td>
                {item?.itemType[0]?.name !== 'リンクボタン' ? (
                  <div className="d-flex">
                    <div className="pr-3" style={{ marginLeft: 'auto' }}>
                      <div
                        className="icon"
                        style={{ borderColor: `${colorScreen?.code}`, color: `${colorScreen?.code}`, width: 90 }}
                      >
                        {`${indexSelect > 8 ? '' : '0'}${indexSelect + 1}_${index + 1}`}
                      </div>
                    </div>
                    <div
                      className="icon"
                      style={{ borderColor: `${colorScreen?.code}`, color: `${colorScreen?.code}` }}
                      onClick={() => deleteListValueCheck({ menuItemSettingId: item.menuItemSettingId, index })}
                    >
                      <i className="fas fa-times"></i>
                    </div>
                  </div>
                ) : (
                  <div className="float-right">
                    <div
                      className="icon"
                      style={{ borderColor: `${colorScreen?.code}`, color: `${colorScreen?.code}`, width: 150 }}
                    >
                      ボタン名
                    </div>
                  </div>
                )}
              </td>
              <td>
                {item?.itemType[0]?.name !== 'リンクボタン' ? (
                  <select
                    className="text-input w-100"
                    style={{ borderColor: `${colorScreen?.code}`, color: `${colorScreen?.code}` }}
                    value={elm?.distinguish}
                    name="distinguish"
                    onChange={(e) => handleChangeValueSelect({ index, menuItemSettingId: item.menuItemSettingId, e })}
                  >
                    <option value=""></option>
                    {listCsvExport?.data?.map((ele) => (
                      <option value={ele?.distinguish}>{ele?.distinguish}</option>
                    ))}
                  </select>
                ) : (
                  <input
                    className="text-input w-100"
                    style={{ borderColor: `${colorScreen?.code}`, color: `${colorScreen?.code}` }}
                    onChange={(e) => handleChangeValueSelect({ index, menuItemSettingId: item.menuItemSettingId, e })}
                    value={elm?.name}
                    name="name"
                  />
                )}
              </td>
              <td>
                {item?.itemType[0]?.name !== 'リンクボタン' ? (
                  elm?.distinguish !== 'インポートCSV' ? (
                    <select
                      className="text-input w-100"
                      style={{ borderColor: `${colorScreen?.code}`, color: `${colorScreen?.code}` }}
                      value={elm?.outputProject}
                      name="outputProject"
                      disabled={_.isEmpty(elm?.distinguish)}
                      onChange={(e) => handleChangeValueSelect({ index, menuItemSettingId: item.menuItemSettingId, e })}
                    >
                      <option value=""></option>
                      {dataOutputProjects?.[0]?.outputProjects
                        ?.filter((elm) => elm.outputProject !== '精算情報')
                        .map((ele , k) => {
                          return (
                            <option key={k} value={ele?.outputProject}>{ele?.outputProject}</option>
                          )
                        })}
                    </select>
                  ) : (
                    <select
                      className="text-input w-100"
                      style={{ borderColor: `${colorScreen?.code}`, color: `${colorScreen?.code}` }}
                      value={elm?.name}
                      disabled={_.isEmpty(elm?.outputProject)}
                      name="name"
                      onChange={(e) => handleOjectSelect({ index, menuItemSettingId: item.menuItemSettingId, e })}
                    >
                      <option value=""></option>
                        {(item?.itemType[0].name === 'フィールド反映'
                          ? dataName?.[0]?.names?.filter((ele) => !notUpdateCSV.includes(ele?.nameInStella))
                          : dataName?.[0]?.names
                        )?.map((ele) => (
                          <option value={ele?.name}>{ele?.name}</option>
                        ))}
                    </select>
                  )
                ) : (
                  <div className="text-center">
                    <div
                      className="icon w-100"
                      style={{ borderColor: `${colorScreen?.code}`, color: `${colorScreen?.code}` }}
                    >
                      URL
                    </div>
                  </div>
                )}
              </td>
              <td>
                {item?.itemType[0]?.name !== 'リンクボタン' ? (
                  elm?.distinguish !== 'インポートCSV' ? (
                    <select
                      className="text-input w-100"
                      style={{ borderColor: `${colorScreen?.code}`, color: `${colorScreen?.code}` }}
                      value={elm?.name}
                      disabled={_.isEmpty(elm?.outputProject)}
                      name="name"
                      onChange={(e) => handleOjectSelect({ index, menuItemSettingId: item.menuItemSettingId, e })}
                    >
                      <option value=""></option>
                        {(item?.itemType[0].name === 'フィールド反映'
                          ? dataName?.[0]?.names?.filter((ele) => dataUpdateType.includes(ele?.nameInStella))
                          : dataName?.[0]?.names
                        )?.map((ele) => (
                          <option value={ele?.name}>{ele?.name}</option>
                        ))}
                    </select>
                  ) : item?.itemType[0].name === 'フィールド反映' ? (
                    <select
                      className="text-input w-100"
                      style={{ borderColor: `${colorScreen?.code}`, color: `${colorScreen?.code}` }}
                      onChange={(e) => handleChangeValueSelect({ index, menuItemSettingId: item.menuItemSettingId, e })}
                      value={elm?.typeUpdate}
                      name="typeUpdate"
                    >
                      <option value=""></option>
                      {['string', 'date', 'time', 'timestamp','number']?.map((item) => (
                        <option value={item}>{t(`import_primary.${item?.toUpperCase()}`)}</option>
                      ))}
                    </select>
                  ) : ''
                ) : (
                  <input
                    className="text-input w-100"
                    style={{ borderColor: `${colorScreen?.code}`, color: `${colorScreen?.code}` }}
                    onChange={(e) => handleChangeValueSelect({ index, menuItemSettingId: item.menuItemSettingId, e })}
                    value={elm?.link}
                    name="link"
                  />
                )}
              </td>
              <td></td>
            </tr>
          );
        })}
        {item?.itemType[0]?.name !== 'リンクボタン' && (
          <tr className="item-worker">
            <td></td>
            <td></td>
            <td className="d-flex">
              <div
                className="icon"
                style={{ borderColor: `${colorScreen?.code}`, color: `${colorScreen?.code}`, marginLeft: 'auto' }}
                onClick={() => addListValueCheckSelect({ menuItemSettingId: item.menuItemSettingId })}
              >
                <i className="fas fa-plus"></i>
              </div>
            </td>
          </tr>
        )}
      </Fragment>
    );
  };

  const renderShowValueUpdate = (item, indexSelect) => {
    return (
      <Fragment>
        {item?.itemType[0].value?.map((elm, index) => {
          let dataOutputProjects = _.filter(listCsvExport?.data, { distinguish: elm?.distinguish });
          let dataNoneOutputProjects = [];
          let dataHandle = dataOutputProjects?.[0]?.outputProjects?.map((ele) => {
            let dataCheck = ele?.names?.map((item) => {
              dataNoneOutputProjects = [...dataNoneOutputProjects, { ...item, outputProjects: ele?.outputProject }];
            });
          });
          let dataName = _.filter(dataOutputProjects?.[0]?.outputProjects, { outputProject: elm?.outputProject });
          return (
            <tr className="item-worker">
              <td></td>
              <td></td>
              <td>
                <div className="d-flex">
                  <div className="pr-3" style={{ marginLeft: 'auto' }}>
                    <div
                      className="icon"
                      style={{ borderColor: `${colorScreen?.code}`, color: `${colorScreen?.code}`, width: 90 }}
                    >
                      {`${indexSelect > 8 ? '' : '0'}${indexSelect + 1}_${index + 1}`}
                    </div>
                  </div>
                  <div
                    className="icon"
                    style={{ borderColor: `${colorScreen?.code}`, color: `${colorScreen?.code}` }}
                    onClick={() => deleteListValueCheck({ menuItemSettingId: item.menuItemSettingId, index })}
                  >
                    <i className="fas fa-times"></i>
                  </div>
                </div>
              </td>
              <td>
                <select
                  className="text-input w-100"
                  style={{ borderColor: `${colorScreen?.code}`, color: `${colorScreen?.code}` }}
                  value={elm?.distinguish}
                  name="distinguish"
                  onChange={(e) => handleChangeValueSelect({ index, menuItemSettingId: item.menuItemSettingId, e })}
                >
                  <option value=""></option>
                  {listCsvExport?.data?.map((ele) => (
                    <option value={ele?.distinguish}>{ele?.distinguish}</option>
                  ))}
                </select>
              </td>
              <td>
                {elm?.distinguish !== 'インポートCSV' ? (
                  <select
                    className="text-input w-100"
                    style={{ borderColor: `${colorScreen?.code}`, color: `${colorScreen?.code}` }}
                    value={elm?.name}
                    disabled={_.isEmpty(elm?.distinguish)}
                    name="name"
                    onChange={(e) => handleOjectSelectUpdate({ index, menuItemSettingId: item.menuItemSettingId, e , dataNoneOutputProjects : dataNoneOutputProjects })}
                  >
                    <option value=""></option>
                    {(elm?.distinguish === 'stellaCloud'
                      ? dataNoneOutputProjects?.filter((ele) => dataUpdateType.includes(ele?.nameInStella))
                      : []
                    )?.map((ele) => (
                      <option value={ele?.name}>{ele?.name}</option>
                      )
                    )}
                  </select>
                ) : (
                  <select
                    className="text-input w-100"
                    style={{ borderColor: `${colorScreen?.code}`, color: `${colorScreen?.code}` }}
                    value={elm?.name}
                    disabled={_.isEmpty(elm?.outputProject)}
                    name="name"
                    onChange={(e) => handleOjectSelect({ index, menuItemSettingId: item.menuItemSettingId, e })}
                  >
                    <option value=""></option>
                    {(item?.itemType[0].name === 'フィールド反映'
                      ? dataName?.[0]?.names?.filter((ele) => !notUpdateCSV.includes(ele?.nameInStella))
                      : dataName?.[0]?.names
                    )?.map((ele) => (
                      <option   value={ele?.name}>{ele?.name}</option>
                    ))}
                  </select>
                )}
              </td>
              <td>
                <select
                  className="text-input w-100"
                  style={{ borderColor: `${colorScreen?.code}`, color: `${colorScreen?.code}` }}
                  onChange={(e) => handleChangeValueSelect({ index, menuItemSettingId: item.menuItemSettingId, e })}
                  value={elm?.typeUpdate}
                  name="typeUpdate"
                >
                  <option value=""></option>
                  {(elm?.distinguish === 'インポートCSV'
                    ? ['string', 'date', 'time', 'timestamp', 'number', 'barcode']
                    : ['string', 'date', 'time', 'timestamp', 'number']
                  )?.map((item) => (
                    <option value={item}>{t(`import_primary.${item?.toUpperCase()}`)}</option>
                  ))}
                </select>
              </td>
              <td></td>
            </tr>
          );
        })}
        <tr className="item-worker">
          <td></td>
          <td></td>
          <td className="d-flex">
            <div
              className="icon"
              style={{ borderColor: `${colorScreen?.code}`, color: `${colorScreen?.code}`, marginLeft: 'auto' }}
              onClick={() => addListValueCheckSelect({ menuItemSettingId: item.menuItemSettingId })}
            >
              <i className="fas fa-plus"></i>
            </div>
          </td>
        </tr>
      </Fragment>
    );
  };

  const showListVersion = () => {
    return (
      listItem &&
      listItem.map((item, index) => {
        return (
          <Fragment>
            <Draggable key={`${index + 1}`} draggableId={`${index + 1}`} index={index}>
            {(provided, snapshot) => (
              <tr
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                className="item-worker"
                key={index}
              >
                <td>
                  <div className="d-flex">
                    <div
                      className="icon"
                      style={{ borderColor: `${colorScreen?.code}`, color: `${colorScreen?.code}` }}
                      onClick={() => deleteListItem(item)}
                    >
                      <i className="fas fa-times"></i>
                    </div>
                    {!_.includes(['フィールド反映' , 'フィールド参照'] ,item ?.itemType[0] ?.name) && (
                      <div className="pl-1" style={{ marginLeft: 'auto' }}>
                      <div
                        className="icon"
                        style={{ borderColor: `${colorScreen?.code}`, color: `${colorScreen?.code}`, width: 60 }}
                      >
                        {`${index > 8 ? '' : '0'}${index + 1}_1`}
                      </div>
                    </div>
                    )}
                  </div>
                </td>
                <td>
                  <input
                    type="text"
                    className="text-input"
                    name="titleName"
                    value={item.titleName}
                    style={{ borderColor: `${colorScreen?.code}`, color: `${colorScreen?.code}` }}
                    onChange={(e) => handleChange(e, item.menuItemSettingId)}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="text-input"
                    name="type"
                    value={item.type}
                    style={{ borderColor: `${colorScreen?.code}`, color: `${colorScreen?.code}` }}
                    onChange={(e) => handleChange(e, item.menuItemSettingId)}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="text-input w-100"
                    name="content"
                    value={item.content}
                    style={{ borderColor: `${colorScreen?.code}`, color: `${colorScreen?.code}` }}
                  onChange={(e) => handleChange(e, item.menuItemSettingId)}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="text-input w-100"
                    name="itemName"
                    value={item.itemName}
                    style={{ borderColor: `${colorScreen?.code}`, color: `${colorScreen?.code}` }}
                    onChange={(e) => handleChange(e, item.menuItemSettingId)}
                  />
                </td>
                <td>
                  <DragDropContext onDragEnd={(e) => onDragEnd2(item, e)}>
                    <Droppable droppableId="droppable">
                      {(provided, snapshot) => (
                        <div className="list-select" {...provided.droppableProps} ref={provided.innerRef}>
                          <select
                            name="itemType"
                            className="text-input select"
                            id="itemType"
                            style={{ borderColor: `${colorScreen?.code}`, color: `${colorScreen?.code}` }}
                            value={item?.itemType[0]?.name}
                            onChange={(e) => changeListSelect(e, item.menuItemSettingId, index)}
                          >
                            <option value="--"></option>
                            {listOptionsType?.map((item, index) => (
                              <option value={item} key={index}>
                                {item}
                              </option>
                            ))}
                          </select>
                          {
                            _.includes(['ラジオボタン' , 'チェックボックス' , '自動判定'], item ?.itemType[0] ?.name) ?
                              renderAddInput(item) : ''
                          }
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </td>
                <td>
                  <label className="container-checkbox">
                    <input
                      type="checkbox"
                      name="required"
                      checked={item.required}
                      onChange={() => handleChangeCheckBox(item.menuItemSettingId)}
                    />
                    <span className="checkmark"></span>
                  </label>
                </td>
              </tr>
            )}
          </Draggable>
          {_.includes(['フィールド参照' , 'リンクボタン'] ,item ?.itemType[0] ?.name) ? renderShowValue(item , index)  : ''}
          {_.includes(['フィールド反映'] , item ?.itemType[0] ?.name) ? renderShowValueUpdate(item , index) : ''}
          </Fragment>
        );
      })
    );
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const items1 = reorder(listItem, result.source.index, result.destination.index);
    setListItem(items1);
  };

  const onDragEnd2 = (item, result) => {
    if (!result.destination) {
      return;
    }
    const arrTmp = listItem.map((itemList) => {
      if (itemList.menuItemSettingId === item.menuItemSettingId) {
        const items1 = reorder(itemList.itemType[0].value, result.source.index, result.destination.index);
        return { ...itemList, itemType:[{...itemList.itemType[0], value: items1}]};
      } else {
        return itemList;
      }
    });
    setListItem(arrTmp);
  };

  return (
    <div className="wrapper-master-setting">
      <Loading loading={loading} />
      <HeaderMasterSetting checkName='/configuration-worker' color={colorScreen?.code} colorText={colorScreen?.colorText}/>
      <div className="body pb-1">
        <button className="mypage_ab_custom" onClick={onReturn}>戻る</button>
        <div className="wrapper-main">
          <div className="wrapper-result">
            <div className="result-title text-left mb-4 mt-2">{`作業手順設定＞${productName || ''}＞${serviceName || ''}＞${version || ''}`}</div>
            <table className="form-worker">
              {listItem?.length > 0 && (
                <tr>
                  <th className="text-center" scope="col"></th>
                  <th className="text-center" scope="col">作業名</th>
                  <th className="text-center" scope="col">手順</th>
                  <th className="text-center" scope="col">詳細</th>
                  <th className="text-center" scope="col">指示内容</th>
                  <th className="text-center" scope="col">入力形式</th>
                  <th className="text-center" scope="col">必須</th>
                </tr>
              )}
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <tbody {...provided.droppableProps} ref={provided.innerRef}>
                      {showListVersion()}
                      <tr className="item-worker">
                        <td>
                          <div
                            className="icon"
                            style={{ borderColor: `${colorScreen?.code}`, color: `${colorScreen?.code}` }}
                            onClick={() => addListItem(listItem?.length)}
                          >
                            <i className="fas fa-plus"></i>
                          </div>
                        </td>
                      </tr>
                      {provided.placeholder}
                    </tbody>
                  )}
                </Droppable>
              </DragDropContext>
            </table>
          </div>
          <div className="d-flex justify-content-end align-items-center group-button">
            <button
              style={{ backgroundColor: `${colorScreen ?.code}` }}
              onClick={() => handleEditWorkerItemSettings()}>
            保存
            </button>
          </div>
        </div>
      </div>
      <MemoHistory colors={colorScreen?.code} colorText={colorScreen?.colorText} screenName={SCREEN_NAME.WORKER_SETTINGS} />
      <FooterMaintenance />
      <ModalConfirm toggle={toggle} modal={modalConfirm} handleConfirm={handleConfirm} />
    </div>
  );
};

export default translate('translations')(WorkerItemSettings);
