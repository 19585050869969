import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader } from 'reactstrap';
import './style.scss';
import SignaturePad from 'react-signature-canvas';

const ModalSignature = (props) => {
  const { toggle = () => { }, modal = false, pushTrimData  } = props;
  const [sigPad, setSigPad] = useState({});

  const clear = () => {
    setSigPad(sigPad.clear());
  };

  const trim = () => {
    // if (!sigPad.isEmpty()) {
    //   pushTrimData(sigPad.getTrimmedCanvas().toDataURL('image/png'));
    // }
    pushTrimData(sigPad.getTrimmedCanvas().toDataURL('image/png'));

    // setTrimmedDataURL(sigPad.getTrimmedCanvas().toDataURL('image/png'));
  };

  return (
    <div>
      <Modal className="modal-signature" isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}></ModalHeader>
        <div className="form-signature">
          <SignaturePad
            ref={(ref) => { setSigPad(ref) }} />
          <div className="button-block-submit">
          <button className="button-clear" onClick={clear}>クリア</button>
          <button className="button-trim" onClick={trim}>確定</button>
          </div>
        </div>

      </Modal>
    </div>
  );
};

export default ModalSignature;
