import React from 'react';
import '../../assets/scss/components/ServicesButton/style.scss'

const ServicesButton = (services) => {
  return (
      services?.services.split(',').map((item) => {
          return (
            <button className='px-2 m-1 d-inline-block btn-services'>{item}</button>
          )
      }
    )
  )
};

export default ServicesButton;