import React, { useState, useEffect } from 'react';
import { Collapse } from 'reactstrap';
import 'react-datepicker/dist/react-datepicker.css';
import './style.scss';

//component
import InfoCompany from './InfoCompany';
import ModuleManage from './ModuleManage'


const CollaMasterSetting = ({ colors, idProvide , colorText, dataColorOrg, moduleManager, idRole, statusBeforeConfirm }) => {
  const [isOpenInfoCompany, setIsOpenInfoCompany] = useState(false);
  const [isOpenModuleManagement, setIsOpenModuleManagement] = useState(false);
  const toggleInfoCompany = () => setIsOpenInfoCompany(!isOpenInfoCompany);
  const toggleModuleManagement = () => setIsOpenModuleManagement(!isOpenModuleManagement);

  return (
    <div className="setting-master-menu-wrapper">
      <div className="wrapper-btn-colappse">
        <div onClick={toggleInfoCompany} className="mb-1 searchbar d-flex justify-content-between align-items-center" style={{background :`${colors}` }}>
          <div className="ml-2" style={{ color: `${colorText}` }}>企業情報</div>
          <div className="mr-2" style={{ color: `${colorText}` }}>
            {isOpenInfoCompany ? <i className="icon-up-open icon-searchbar" /> : <i className="icon-down-open icon-searchbar" />}
          </div>
        </div>
      </div>
      <div>
        <Collapse isOpen={isOpenInfoCompany}>
          <InfoCompany colors={colors} idProvide={idProvide} dataColorOrg={dataColorOrg} />
        </Collapse>
      </div>

      <div className="wrapper-btn-colappse">
        <div onClick={toggleModuleManagement} className="mb-1 searchbar d-flex justify-content-between align-items-center" style={{background :`${colors}` }}>
          <div className="ml-2" style={{ color: `${colorText}` }}>メニュー表示設定</div>
          <div className="mr-2" style={{ color: `${colorText}` }}>
            {isOpenModuleManagement ? <i className="icon-up-open icon-searchbar" /> : <i className="icon-down-open icon-searchbar" />}
          </div>
        </div>
      </div>
      <div>
        <Collapse isOpen={isOpenModuleManagement}>
          <ModuleManage
            idProvide={idProvide} colorId={dataColorOrg ?.colorId}
            moduleManager={moduleManager} idRole={idRole} statusBeforeConfirm={statusBeforeConfirm}/>
        </Collapse>
      </div>
      {/* {role && role === ROLE.CENTER && <CollapseCenter getList={getList} isOpen={isOpen} color={color}/>} */}
    </div>
  );
};

export default CollaMasterSetting;
