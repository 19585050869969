import React, { useState, useEffect } from 'react';
import { DATE_JP_FORMAT, DATE_MONTH_YEAR, SCREEN_NAME } from 'helpers/constants';
import TableSchedules from '../TableSchedules';
import _ from 'lodash';
import { MASTER_SETTING } from 'constants/endpoints';
import moment from 'moment';
import { pushToast } from 'components/Toast';
import ScheduledOperation from '../../ScheduledOperation';
import { useDispatch } from 'react-redux';
import { getDataHistoryMasterSetting } from 'actions/authentication';

const Schedules = ({colors ,colorText, changeLoading}) => {
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(new Date());
  const [workingSchedules, setWorkingSchedules] = useState({ workingStartTime: '00:00', workingEndTime: '00:00', breakStartTime: '00:00', breakEndTime: '00:00' });
  const [isSchedule, setIsSchedule] = useState(false);
  const [isCreate, setIsCreate] = useState(false);
  const permissionId = localStorage.getItem('permissionId');

  useEffect(() => {
    if(isSchedule && startDate) {
      getListWorkingSchedule({ date: moment(startDate).format(DATE_MONTH_YEAR), partnerId: workingSchedules.partnerId });
    }
  }, [startDate, workingSchedules.partnerId]);

  useEffect(() => {
    if(isSchedule && startDate) {
      getPartnerOrganizations();
    }else if(!isSchedule) {
      setWorkingSchedules({ workingStartTime: '00:00', workingEndTime: '00:00', breakStartTime: '00:00', breakEndTime: '00:00' });
      setStartDate(new Date());
    }
  }, [isSchedule])

  const getDaysInMonth = (month, year) => {
    let date = new Date(year, month, 1);
    let days = [];
    while (date.getMonth() === month) {
      let dateCheck = new Date(moment(date));
      let currentDay = dateCheck.getDay();
      let type = [0, 6].includes(currentDay) ? 2 : 1;
      days.push({ date: moment(dateCheck).format(DATE_JP_FORMAT), type, breakEndTime: '', breakStartTime: '', workingEndTime: '', workingStartTime: '', remarks: '', status: false });
      date.setDate(date.getDate() + 1);
    }
    return days;
  };

  const getListWorkingSchedule = async ({ date, partnerId }) => {
    try {
      changeLoading(true);
      const data = await MASTER_SETTING.GET_WORKING_SCHEDULE({ date, partnerId});
      if (data) {
        changeLoading(false);
        const newData = {...data?.data};
        setIsCreate(!_.isEmpty(data?.data) && !data?.data?.calendarMasterId);
        setWorkingSchedules({
          ...workingSchedules, ...newData, dateChange: new Date(),
          calendar: _.isEmpty(newData?.calendar) ? getDaysInMonth(new Date(startDate).getMonth(), new Date(startDate).getFullYear()) : newData?.calendar,
          ...{ workingStartTime: '00:00', workingEndTime: '00:00', breakStartTime: '00:00', breakEndTime: '00:00' }
        });
      }
      changeLoading(false);
    } catch (e) {
      changeLoading(false);
      console.log(e);
    }
  };

  const getPartnerOrganizations = async () => {
    try {
      changeLoading(true);
      const data = await MASTER_SETTING.GET_PARTNER_ORGANIZATION();
      if (data) {
        const newData = data?.data?.filter(e => _.isEmpty(permissionId) || e.partnerId === permissionId);
        setWorkingSchedules({partners: newData || [], partnerId: newData?.[0]?.partnerId});
      }
    } catch (e) {
      console.log(e);
      changeLoading(false);
    }
  };

  const updateWorkingSchedule = async ({ body }) => {
    try {
      changeLoading(true);
      const data = await MASTER_SETTING.CREATE_UPDATE_WORKING_SCHEDULE({ body });
      if (data && data.code !== 0) {
        pushToast('error', data?.message?.extra || data?.message?.errorMessage);
      } else {
        pushToast('success', 'Create schedule success');
        if(isSchedule && startDate) {
          getListWorkingSchedule({ date: moment(startDate).format(DATE_MONTH_YEAR), partnerId: workingSchedules.partnerId });
        }
        dispatch(getDataHistoryMasterSetting({ logId: '', screenName: SCREEN_NAME.WORKING_SCHEDULE, idProvide: '' }));
      }
      changeLoading(false);
    } catch (e) {
      changeLoading(false);
      console.log(e);
    }
  };

  const handleChange = (date) => {
    setStartDate(date);
  };

  const handleChangeCalendar = ({data}) => {
    const newData = {...workingSchedules, ...data};
    setWorkingSchedules(newData);
  }

  const onUpdate = () => {
    const body = {
      calendar: workingSchedules.calendar, partnerId: workingSchedules?.partnerId || workingSchedules?.partners?.[0]?.partnerId,
      date: moment(startDate).format(DATE_MONTH_YEAR), screenName: SCREEN_NAME.WORKING_SCHEDULE, workingStartTime: workingSchedules?.workingStartTime,
      workingEndTime: workingSchedules?.workingEndTime, breakStartTime: workingSchedules?.breakStartTime, breakEndTime: workingSchedules?.breakEndTime,
      updateTitle: "送信 カレンダー作成"
    };
    updateWorkingSchedule({ body })
  }

  return (
    <div className="setting-master-menu-wrapper wrapper-calendar-master">
      <div className="wrapper-btn-colappse">
        <div className="mb-1 searchbar d-flex justify-content-between align-items-center" style = {{background :`${colors}`}}>
          <div className="ml-2" style={{ color: `${colorText}` }}>カレンダー作成</div>
          <div className="mr-2" style={{ color: `${colorText}` }}>
          </div>
        </div>
      </div>
      <div>
        <div className="form__content">
          <div className="form__flex form__flex--mgt-10">
            <div className="form-search form__flex--start">
              <button className={`btn-tree ${isSchedule ? 'button--unactive' : 'button--active'}`} onClick={() => setIsSchedule(false)}>稼働予定</button>
              <button className={`btn-tree ${!isSchedule ? 'button--unactive' : 'button--active'}`} onClick={() => setIsSchedule(true)}>カレンダー作成</button>
            </div>
          </div>
          {!isSchedule && <ScheduledOperation />}
          {
          isSchedule && (
            <>
              <TableSchedules workingSchedules={workingSchedules} isCreate={isCreate} startDate={startDate} isSchedule={isSchedule} handleChangeCalendar={handleChangeCalendar} handleChange={handleChange} onUpdate={onUpdate} />
              <div className="text-right">
                <button className="button-submit-calendar" onClick={onUpdate}>送信</button>
              </div>
            </>
          )
          }
        </div>
      </div>
    </div>
  );
};

export default Schedules;
