import ja from 'date-fns/locale/ja';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Collapse } from 'reactstrap';
import BasicInformation from 'components/BasicInformation';
import HeaderMasterSetting from 'components/HeaderMasterSetting';
import RequestDestination from 'components/RequestDestination';
import FooterMaintenance from "components/FooterMaintenance";
import { pushToast } from 'components/Toast';
import { CONTRACT_ENDPOINTS } from 'constants/endpoints';
import { DATE_JP_RENDER, DATE_JP_FORMAT } from 'helpers/constants';
import ModalMonitor from '../ModalMonitor';
import ModalProduct from '../ModalProduct';
import ModalProject from '../ModalProject';
import ModalService from '../ModalService';
import 'assets/scss/page/contract/RecruitmentRegistration.scss';
import MemoHistory from '../../../../components/MemoHistory';
import { SCREEN_NAME } from '../../../../helpers/constants';
import Loading from 'components/Loading/Loading';
import { useDispatch } from 'react-redux';
import { getDataHistoryMasterSetting } from 'actions/authentication';
import ParticipatingCompany from '../../../../components/ParticipatingCompany';

registerLocale('ja', ja);

const RecruitmentRegistration = ({ }) => {
  const colorScreen = useSelector((store) => store.utils.colorMe);
  const history = useHistory();
  const [isOpenMonitor, setIsOpenMonitor] = useState(false);
  const [isOpenProject, setIsOpenProject] = useState(false);
  const [isOpenProduct, setIsOpenProduct] = useState(false);
  const [isOpenService, setIsOpenService] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const [check, setCheck] = useState(false);
  const [dataDetail, setDataDetail] = useState({});
  const [recruitmentContractId, setRecruitmentContractId] = useState(
    new URL(window.location.href).searchParams.get('id')
  );
  let productId = process.browser && new URL(window.location.href).searchParams.get('productId');
  const organizationIdLogin = localStorage.getItem('idProvide');
  let organizationId = process.browser && new URL(window.location.href).searchParams.get('organizationId') || '';
  let onBack = (process.browser && new URL(window.location.href).searchParams.get('onBack')) || '';
  const organizationIdProduct = (process.browser && new URL(window.location.href).searchParams.get('organizationIdProduct'));

  const [monitorIdTmp, setMonitorIdTmp] = useState('');
  const [projectIdTmp, setProjectIdTmp] = useState('');
  const [productIdTmp, setProductIdTmp] = useState('');
  const [dataDetailNotTree, setDataDetailNotTree] = useState({});
  const [treeProductContract, setTreeProductContract] = useState({});
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedName, setSelectedName] = useState('');
  const [listFile, setListFile] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [updateStep, setUpdateStep] = useState(0)
  const dispatch = useDispatch()

  const getUpdateName = (dataTree) => {
    let monitor = Object.keys(dataTree);
    if (dataTree?.[monitor]?.projects) {
      let projectId = Object.keys(dataTree?.[monitor]?.projects)
      let projectName = dataTree?.[monitor]?.projects?.[projectId]?.projectName;
      let productId = Object.keys(dataTree?.[monitor]?.projects?.[projectId]?.products)
      let productName = dataTree?.[monitor]?.projects?.[projectId]?.products?.[productId].productName;
      return `${productName}-${projectName}`
    }
    if (dataTree?.[monitor]?.nonProjects) {
      let nonProjectId = Object.keys(dataTree?.[monitor]?.nonProjects)
      let productName = dataTree?.[monitor]?.nonProjects?.[nonProjectId]?.productName;
      return `${productName}`
    }
  }

  const onReturn = () => {
    if (onBack === 'back') {
      history.push(`/contract/list-recruitment`);
    } if (organizationIdProduct) {
      history.push(`/contract/application-site`);
    } else {
      history.push(`/contract`);
    }
  };

  const getTreeProductId = async ({ productId = '' }) => {
    try {
      const data = await CONTRACT_ENDPOINTS.GET_TREE_PRODUCT_ID({
        productId: productId,
        organizationId
      });
      if (data?.data) {
        setTreeProductContract(data?.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (productId) {
      getTreeProductId({ productId });
    }
  }, [productId]);

  const onClickShow = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (recruitmentContractId) {
      getDetailRecruitment({ recruitmentContractId: recruitmentContractId });
    }
    const organizationProduct = organizationIdProduct || organizationId;
    setCheck(organizationProduct !== organizationIdLogin);
  }, [recruitmentContractId]);

  const getDetailRecruitment = async ({ recruitmentContractId = '' }) => {
    setLoading(true);
    try {
      const data = await CONTRACT_ENDPOINTS.GET_DETAIL_RECRUITMENT({
        recruitmentContractId,
        organizationId
      });
      if (data?.data) {
        setListFile(data?.data?.recruitmentContractFile);
        setDataDetail(data?.data);
        const arrTmp = [
          'areas',
          'title',
          'businessContent',
          'endTimeBusiness',
          'endTimeRecruitment',
          'money',
          'necessaryResults',
          'partners',
          'recruitmentContractId',
          'recruitmentType',
          'scope',
          'startTimeBusiness',
          'startTimeRecruitment',
          'tree',
          'productIdChild',
          'projectIdChild',
          'productId',
        ];
        let newData = { ...dataDetailNotTree };
        for (const key in data?.data) {
          if (arrTmp.includes(key)) {
            newData[key] = data?.data[key];
          }
        }
        setDataDetailNotTree(newData);
      }
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const onChangeDate = (date, text) => {
    if (date) {
      let newData = { ...dataDetailNotTree, [text]: moment(new Date(date)).format(DATE_JP_FORMAT) };
      setDataDetailNotTree(newData);
    } else {
      let newData = { ...dataDetailNotTree, [text]: '' };
      setDataDetailNotTree(newData);
    }
  };

  const onChangeTextAndNumber = (e) => {
    const { value, name } = e.target;
    setDataDetailNotTree({ ...dataDetailNotTree, [name]: value });
  };

  const onHandleRecruitmentType = (name, value) => {
    setDataDetailNotTree({ ...dataDetailNotTree, [name]: value });
  };

  const onHandleUpdateAndCreate = (updateTitle) => {
    onUpdateAndCreate({
      body: !_.isEmpty(recruitmentContractId)
        ? {
          ...dataDetailNotTree,
          productId,
          recruitmentId: recruitmentContractId,
          screenName: SCREEN_NAME.CONTRACT_MANAGEMENT,
          tree: treeProductContract,
          updateTitle,
          organizationId,
        }
        : {
          ...dataDetailNotTree,
          productId,
          screenName: SCREEN_NAME.CONTRACT_MANAGEMENT,
          tree: treeProductContract,
          updateTitle,
          organizationId,
        },
    });
  };

  const onUpdateAndCreate = async ({ body = '' }) => {
    setLoading(true);
    try {
      const data = await CONTRACT_ENDPOINTS.CREATE_RECRUITMENT_CONTRACT({ body });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
      } else {
        pushToast('success', 'Registration recruitment success.');
        setSelectedFile(null);
        setSelectedName('');
        document.getElementById('upload-csv').value = '';
        if (data?.data?.recruitmentId) {
          history.push(`/contract/recruitment?id=${data?.data?.recruitmentId}&productId=${productId}&organizationId=${organizationId}`);
          getDetailRecruitment({ recruitmentContractId: data?.data?.recruitmentId });
          setRecruitmentContractId(data?.data?.recruitmentId);
          setUpdateStep(updateStep + 1)
        } else {
          getDetailRecruitment({ recruitmentContractId: recruitmentContractId });
          setUpdateStep(updateStep + 1)
        }
        dispatch(getDataHistoryMasterSetting({ logId: '', screenName: SCREEN_NAME.CONTRACT_MANAGEMENT }));
      }
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const onHandleChangePartner = (e, partners) => {
    const target = e.target;
    const { name, value } = target;
    let arrTmp = dataDetailNotTree?.partners || [];
    if (target.checked) {
      arrTmp.push(value);
    } else {
      arrTmp.splice(arrTmp.indexOf(value), 1);
    }
    let newData = {
      ...dataDetailNotTree,
      [name]: arrTmp,
    };
    setDataDetailNotTree(newData);
  };

  const onHandleChangePartnerAll = (e, partner) => {
    const target = e.target;
    const { name } = target;
    const elm = partner?.map((ele) => {
      return ele?.partnerId;
    });
    const dataPartner = dataDetailNotTree?.partners || []
    if (target.checked) {
      let add = elm.filter(ele => !dataPartner?.includes(ele))
      let newData = { ...dataDetailNotTree, [name]: [...dataPartner, ...add] };
      setDataDetailNotTree(newData);
      setIsChecked(true)
    } else {
      const check = dataDetailNotTree?.partners?.filter(ele => !elm?.includes(ele))
      let newData = { ...dataDetailNotTree, [name]: check };
      setDataDetailNotTree(newData);
      setIsChecked(false)
    }
  };

  const onhandleProjectAndProduct = (name, value) => {
    setDataDetailNotTree({ ...dataDetailNotTree, [name]: value });
  };

  const onHandleChangeArea = (e, keyListArea, dataAreas = {}) => {
    const target = e.target;
    const { value } = target;
    let arrTmp = dataDetailNotTree?.areas?.[keyListArea] || [];
    if (target.checked) {
      arrTmp.push(value);
      if (!_.isEmpty(dataAreas)) {
        arrTmp = dataAreas?.[keyListArea]?.filter(e => { return arrTmp.includes(e) });
      }
    } else {
      arrTmp.splice(arrTmp.indexOf(value), 1);
    }

    let newData = { ...dataDetailNotTree, areas: { ...dataDetailNotTree.areas, [keyListArea]: arrTmp } };
    setDataDetailNotTree(newData);
  };

  const onChangeHandler = (e) => {
    setSelectedFile(e?.target?.files[0]);
    setSelectedName(e?.target?.files[0]?.name);
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const onClickHandler = async () => {
    const body = {
      file: selectedFile ? await toBase64(selectedFile) : '',
      fileName: selectedName ? selectedName : '',
      screenName: SCREEN_NAME.CONTRACT_MANAGEMENT,
      productId,
    };
    const bodyUpload = {
      file: selectedFile ? await toBase64(selectedFile) : '',
      fileName: selectedName ? selectedName : '',
      recruitmentId: recruitmentContractId,
      screenName: SCREEN_NAME.CONTRACT_MANAGEMENT,
      productId,
    };
    !_.isEmpty(recruitmentContractId) ? uploadCsv({ body: bodyUpload }) : uploadCsv({ body });
  };

  const uploadCsv = async ({ body }) => {
    try {
      const data = await CONTRACT_ENDPOINTS.UPLOAD_FILE_CONTRACT({ body, organizationId });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else if (data.message.errorMessage) {
          pushToast('error', data?.message?.errorMessage);
        } else {
          pushToast('error', data?.message);
        }
      } else {
        pushToast('success', 'Upload success');
        setSelectedFile(null);
        setSelectedName('');
        document.getElementById('upload-csv').value = '';
        if (data?.data?.recruitmentId) {
          history.push(`/contract/recruitment?id=${data?.data?.recruitmentId}&productId=${productId}`);
          getDetailRecruitment({ recruitmentContractId: data?.data?.recruitmentId });
          setRecruitmentContractId(data?.data?.recruitmentId);
        } else {
          getDetailRecruitment({ recruitmentContractId: recruitmentContractId });
        }
      }
    } catch (errorMessage) {
      console.log('errorMessage: ', errorMessage);
    }
  };

  const removeFileContractRecruitment = async ({ body, recruitmentContractId }) => {
    try {
      const data = await CONTRACT_ENDPOINTS.REMOVE_FILE({ body, recruitmentContractId: recruitmentContractId });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else if (data.message.errorMessage) {
          pushToast('error', data?.message?.errorMessage);
        } else {
          pushToast('error', data?.message);
        }
      } else {
        pushToast('success', 'Upload success');
        getDetailRecruitment({ recruitmentContractId: recruitmentContractId });
      }
    } catch (errorMessage) {
      console.log('errorMessage: ', errorMessage);
    }
  };

  const handleDeleteFile = (key) => {
    const body = {
      key,
      organizationId: localStorage.getItem('idProvide'),
      screenName: SCREEN_NAME.CONTRACT_MANAGEMENT,
    };
    !check && removeFileContractRecruitment({ body, recruitmentContractId });
  };

  return (
    <div className="wrapper-recruitment-registration-detail">
      <div className="main">
        <HeaderMasterSetting color={colorScreen?.code} colorText={colorScreen?.colorText} checkName="/contract" />
        <Loading loading={loading} />
        <div className="body-wrapper ">
          <div className="block-contract">
            <div className="list-table">
              <button className="btn-back" onClick={onReturn}>
                戻る
              </button>
              <div className="table-list-contract">
                <div className="title-recruitment mb-3">募集画面</div>
                <BasicInformation dataTree={treeProductContract} title="基本情報" />
                <div className="detail-registration-contract">
                  <div className="detail-basic-table-wrapper">
                    <div className="title-absolute" onClick={onClickShow}>
                      募集内容{' '}
                      {isOpen ? (
                        <i className="fas fa-arrow-circle-up ml-2 cursor-pointer"></i>
                      ) : (
                        <i className="fas fa-arrow-circle-down ml-2 cursor-pointer"></i>
                      )}
                    </div>
                    <Collapse isOpen={isOpen}>
                      <div className="row">
                        <div className="col-3 text-center">
                          <label className="label-contract">題名</label>
                        </div>
                        <div className="col-9">
                          <input
                            type="text"
                            className={`${check ? 'button-disable-contract' : ''} input-contract`}
                            name="title"
                            onChange={onChangeTextAndNumber}
                            value={dataDetailNotTree?.title}
                            disabled={check}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-3 text-center">
                          <label className="label-contract">募集期間</label>
                        </div>
                        <div className="col-9">
                          <div className="row">
                            <div className="block-date ml-3">
                              <DatePicker
                                selected={
                                  !_.isEmpty(dataDetailNotTree?.startTimeRecruitment)
                                    ? new Date(dataDetailNotTree?.startTimeRecruitment)
                                    : ''
                                }
                                onChange={(date) => onChangeDate(date, 'startTimeRecruitment')}
                                locale="ja"
                                className={`${check ? 'button-disable-contract' : ''} input-contract`}
                                dateFormat={DATE_JP_RENDER}
                                dateFormatCalendar="yyyy年 MM月"
                                disabled={check}
                              />
                            </div>
                            <div className="block-date ml-3">
                              <DatePicker
                                selected={
                                  !_.isEmpty(dataDetailNotTree?.endTimeRecruitment)
                                    ? new Date(dataDetailNotTree?.endTimeRecruitment)
                                    : ''
                                }
                                onChange={(date) => onChangeDate(date, 'endTimeRecruitment')}
                                locale="ja"
                                className={`${check ? 'button-disable-contract' : ''} input-contract`}
                                dateFormat={DATE_JP_RENDER}
                                dateFormatCalendar="yyyy年 MM月"
                                disabled={check}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-3 text-center">
                          <label className="label-contract">業務期間</label>
                        </div>
                        <div className="col-9">
                          <div className="row">
                            <div className="block-date ml-3">
                              <DatePicker
                                selected={
                                  !_.isEmpty(dataDetailNotTree?.startTimeBusiness)
                                    ? new Date(dataDetailNotTree?.startTimeBusiness)
                                    : ''
                                }
                                onChange={(date) => onChangeDate(date, 'startTimeBusiness')}
                                locale="ja"
                                className={`${check ? 'button-disable-contract' : ''} input-contract`}
                                dateFormat={DATE_JP_RENDER}
                                dateFormatCalendar="yyyy年 MM月"
                                disabled={check}
                              />
                            </div>
                            <div className="block-date ml-3">
                              <DatePicker
                                selected={
                                  !_.isEmpty(dataDetailNotTree?.endTimeBusiness)
                                    ? new Date(dataDetailNotTree?.endTimeBusiness)
                                    : ''
                                }
                                onChange={(date) => onChangeDate(date, 'endTimeBusiness')}
                                locale="ja"
                                className={`${check ? 'button-disable-contract' : ''} input-contract`}
                                dateFormat={DATE_JP_RENDER}
                                dateFormatCalendar="yyyy年 MM月"
                                disabled={check}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-3 text-center">
                          <label className="label-contract">業務内容</label>
                        </div>
                        <div className="col-9">
                          <input
                            type="text"
                            name="businessContent"
                            className={`${check ? 'button-disable-contract' : ''} input-contract`}
                            onChange={onChangeTextAndNumber}
                            value={dataDetailNotTree?.businessContent}
                            disabled={check}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-3 text-center">
                          <label className="label-contract">必要成果物</label>
                        </div>
                        <div className="col-9">
                          <input
                            type="text"
                            name="necessaryResults"
                            className={`${check ? 'button-disable-contract' : ''} input-contract`}
                            onChange={onChangeTextAndNumber}
                            value={dataDetailNotTree?.necessaryResults}
                            disabled={check}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-3 text-center">
                          <label className="label-contract">規模</label>
                        </div>
                        <div className="col-9">
                          <input
                            type="text"
                            name="scope"
                            className={`${check ? 'button-disable-contract' : ''} input-contract`}
                            onChange={onChangeTextAndNumber}
                            value={dataDetailNotTree?.scope}
                            disabled={check}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-3 text-center">
                          <label className="label-contract">金額</label>
                        </div>
                        <div className="col-9">
                          <input
                            type="text"
                            name="money"
                            className={`${check ? 'button-disable-contract' : ''} input-contract`}
                            onChange={onChangeTextAndNumber}
                            value={dataDetailNotTree?.money}
                            disabled={check}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-3 text-center">
                          <label className="label-contract">ファイル</label>
                        </div>
                        <div className="col-9">
                          <div className="row">
                            <div className="col-3">
                              {check ? (
                                <label className="button-contract button-contract-disabled">
                                  ファイル選択
                                </label>
                              ) : (
                                <label className="button-contract cursor-pointer" htmlFor="upload-csv">
                                  ファイル選択
                                </label>
                              )}
                            </div>
                            <div className="col-6">
                              <input
                                type="file"
                                name="file"
                                id="upload-csv"
                                multiple=""
                                className="d-none"
                                onChange={onChangeHandler}
                              />
                              <input
                                className="dummy_file input-file-custom"
                                type="text"
                                className={`${check ? 'button-disable-contract' : ''} input-contract`}
                                placeholder={selectedName}
                                disabled
                              />
                            </div>
                            <div className="col-3">
                              {check ? (
                                <label className="button-contract button-contract-disabled">
                                  アップロード
                                </label>
                              ) : (
                                <label className="button-contract cursor-pointer" onClick={onClickHandler}>
                                  アップロード
                                </label>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      {listFile?.map((item, index) => {
                        return (
                          <div key={index} className="row">
                            <div className="col-3"></div>
                            {check ? (
                              <div className={`${check ? 'input-contract-disabled' : ''} col-4 text-left cursor-pointer`}>
                                {item.name}
                              </div>
                            ) : (
                              <div onClick={() => window.open(`${item?.url}`)} className={`col-4 text-left cursor-pointer`}>
                                {item.name}
                              </div>
                            )}
                            <div className={`col-3 ${check ? 'input-contract-disabled' : ''}`} style={{ textAlign: 'end' }}>
                              {item.timeUpload}
                            </div>
                            <div className={`col-1 cursor-pointer ${check ? 'input-contract-disabled' : ''}`} onClick={() => handleDeleteFile(item?.key)}>
                              [X]
                            </div>
                          </div>
                        );
                      })}
                    </Collapse>
                  </div>
                </div>
                <RequestDestination
                  title="依頼先指定"
                  onHandleRecruitmentType={onHandleRecruitmentType}
                  dataDetailNotTree={dataDetailNotTree}
                  onHandleChangePartner={onHandleChangePartner}
                  onHandleChangePartnerAll={onHandleChangePartnerAll}
                  onHandleChangeArea={onHandleChangeArea}
                  onhandleProjectAndProduct={onhandleProjectAndProduct}
                  organizationId={organizationId}
                  recruitmentContractId={recruitmentContractId}
                  isChecked={isChecked}
                  updateStep={updateStep}
                  check={check}
                />
                {check && (
                  <ParticipatingCompany recruitmentContractId={recruitmentContractId} isPageContract={false} organizationId={organizationId}/>
                )}
                <div className="block-right text-center mb-3">
                  <button
                    className={`${check ? 'button-upload-disable' : ''} button-upload`}
                    disabled={check}
                    onClick={() => onHandleUpdateAndCreate(
                      `${recruitmentContractId
                        ? `更新 募集画面 ${getUpdateName(treeProductContract)}`
                        : `募集登録 募集画面 ${getUpdateName(treeProductContract)}`
                      }`
                    )}>
                    {recruitmentContractId ? '更新' : '募集登録'}
                  </button>
                </div>
              </div>
            </div>
            <MemoHistory
              colors={colorScreen?.code}
              colorText={colorScreen?.colorText}
              logId={recruitmentContractId}
              screenName={SCREEN_NAME.CONTRACT_MANAGEMENT}
            />
          </div>
          {isOpenMonitor && (
            <ModalMonitor
              modal={isOpenMonitor}
              recruitmentContractId={recruitmentContractId}
              dataTree={dataDetailNotTree?.tree}
            />
          )}
          {isOpenProject && (
            <ModalProject
              modal={isOpenProject}
              recruitmentContractId={recruitmentContractId}
              monitorId={monitorIdTmp}
              dataTree={dataDetailNotTree?.tree}
            />
          )}
          {isOpenProduct && (
            <ModalProduct
              modal={isOpenProduct}
              recruitmentContractId={recruitmentContractId}
              monitorId={monitorIdTmp}
              projectId={projectIdTmp}
              dataTree={dataDetailNotTree?.tree}
            />
          )}
          {isOpenService && (
            <ModalService
              modal={isOpenService}
              recruitmentContractId={recruitmentContractId}
              monitorId={monitorIdTmp}
              projectId={projectIdTmp}
              productId={productIdTmp}
              dataTree={dataDetailNotTree?.tree}
            />
          )}
        </div>
        <FooterMaintenance />
      </div>
    </div>
  );
};

export default RecruitmentRegistration;
