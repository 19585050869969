import React, { useEffect, useState } from 'react';
import HeaderMasterSetting from 'components/HeaderMasterSetting';
import Loading from 'components/Loading/Loading';
import { useSelector } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import { CONTRACT_ENDPOINTS } from 'constants/endpoints';
import { Collapse } from 'reactstrap';
import { DATE_JP_FORMAT, ROLE } from 'helpers/constants';
import { getRole } from 'helpers/helpers';
import { Link } from 'react-router-dom';
import PaginationCustom from 'components/PaginationCustom';
import { translate } from 'react-i18next';

const ApplicationSite = ({ t }) => {
  const colorScreen = useSelector((store) => store.utils.colorMe);
  const permissionIdGroup = localStorage.getItem('permissionIdGroup') || '';
  const [loading, setLoading] = useState(false);
  const [dataListContract, setDataListContract] = useState({});
  const [activePage, setActivePage] = useState(1);
  const [isOpen, setIsOpen] = useState(true);
  const checkRole = getRole({ idRole: localStorage.getItem('role') });
  const idProvider = localStorage.getItem('idProvide');

  useEffect(() => {
    getListContract({ page: activePage, limit: 10 });
  }, []);

  const getListContract = async ({ page, limit }) => {
    setLoading(true);
    try {
      const data = await CONTRACT_ENDPOINTS.GET_LIST_CONTRACT_ESTIMATE_PARTNER({
        page,
        limit,
        permissionIdGroup,
      });

      if (data?.data) {
        setDataListContract(data?.data);
      }
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const handleChangePage = (pageNumber) => {
    setActivePage(pageNumber);
    getListContract({ page: pageNumber, limit: 10, permissionIdGroup });
  };

  const handleMessage = ({ contractEstimate }) => {
    let message = [];
    ['contractStatus', 'estimate', 'nda'].map(e => {
      if (contractEstimate?.[e]) {
        if (contractEstimate?.[e]?.addMessagePartner && !message.includes(contractEstimate?.[e]?.addMessagePartner)) {
          message.push(contractEstimate?.[e]?.addMessagePartner);
        }

        if (contractEstimate?.[e]?.addMessageERP && !message.includes(contractEstimate?.[e]?.addMessageERP)) {
          message.push(contractEstimate?.[e]?.addMessageERP);
        }
      }
    });
    return checkMessage({ message: message })
  };

  const checkMessage = ({ message }) => {
    let text = ''
    switch(message.length) {
      case 2: {
        text = '返却中・確認待ち';
        break;
      }

      case 1: {
        text = message[0] === '（返却中）' ? '返却中' : '確認待ち';
        break;
      }

      default:
        text = '';
    }

    return text;
  }

  return (
    <div className="main">
      <Loading loading={loading} />
      <HeaderMasterSetting color={colorScreen?.code} colorText={colorScreen?.colorText} checkName="/contract" />
      <div className="block-contract">
        <div className="list-table">
          <Link to="/contract" className="btn-back">
            戻る
          </Link>
          <div className="table-list-contract">
            <Collapse isOpen={isOpen}>
              <table className="table table-bordered">
                <tbody>
                  <tr>
                    <th>募集会社</th>
                    <th>募集種別</th>
                    <th>エリア</th>
                    <th>募集期間</th>
                    <th>業務期間</th>
                    <th>規模</th>
                    <th>契約状態</th>
                    <th>状況</th>
                    <th className="w-15">操作</th>
                  </tr>
                  {dataListContract?.result?.map((itemIn, index) => {
                    const message = handleMessage({ contractEstimate: itemIn });
                    return (
                      <tr key={index}>
                        <td>{itemIn?.companyNameERP}</td>
                        <td className="w-8">{itemIn.recruitmentType}</td>
                        <td className="text-left w-16">
                          {Object.keys(itemIn?.areas || {}).map((key) => {
                            return <> {t(`areas.${key}`)},</>;
                          })}
                        </td>
                        <td>
                          {itemIn?.startTimeRecruitment
                            ? `${moment(new Date(itemIn?.startTimeRecruitment)).format(DATE_JP_FORMAT)} - `
                            : ''}
                          {itemIn?.endTimeRecruitment
                            ? moment(new Date(itemIn?.endTimeRecruitment)).format(DATE_JP_FORMAT)
                            : ''}
                        </td>
                        <td>
                          {itemIn?.startTimeBusiness
                            ? `${moment(new Date(itemIn?.startTimeBusiness)).format(DATE_JP_FORMAT)} - `
                            : ''}
                          {itemIn?.endTimeBusiness
                            ? moment(new Date(itemIn?.endTimeBusiness)).format(DATE_JP_FORMAT)
                            : ''}
                        </td>
                        <td>{itemIn?.scope}</td>
                        <td className="w-8">{itemIn?.contractStatus?.state}</td>
                        <td>
                          <Link
                            to={`/contract/recruitment?productId=${itemIn?.productId}&id=${itemIn.recruitmentId}&organizationId=${itemIn?.organizationId}&organizationIdProduct=${itemIn?.organizationIdProduct}`}
                            className="btn-edit mr-0"
                          >
                            {+itemIn?.statusConfirmManagement === 1
                              ? '募集中'
                              : +itemIn?.statusConfirmManagement === 2
                              ? '見送り'
                              : '未確定'}
                          </Link>
                        </td>
                        <td>
                          {+itemIn?.statusConfirmManagement === 1 ? (
                            <Link
                              to={`/contract/registration?recruitmentContractId=${itemIn.recruitmentId}&recruitmentEstimateId=${itemIn.contractEstimateId}&organizationId=${itemIn.organizationId}&onBack=application`}
                              className="btn-edit mr-0"
                              disabled
                            >
                              編集
                            </Link>
                          ) : (
                            <Link className="button-update-application ">編集</Link>
                          )}
                          {
                            (message === '確認待ち') ? ( <button className="btn-edit-blue btn-edit mr-0">{message}</button>) : (message && message !== '確認待ち') ? (
                              <button className="btn-edit-red btn-edit mr-0">{message}</button>
                            ) : ('')
                          }
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              {dataListContract?.count > 10 && (
                <PaginationCustom
                  activePage={activePage}
                  itemsCountPerPage={10}
                  totalItemsCount={dataListContract?.count}
                  pageRangeDisplayed={5}
                  handlePageChange={handleChangePage}
                />
              )}
            </Collapse>
          </div>
        </div>
      </div>
    </div>
  );
};
export default translate('translations')(ApplicationSite);
