import DateTimePicker from '../../DateTimePicker';
import { Collapse } from 'reactstrap';
import React, { useState, useEffect } from 'react';
import { getParamSearch } from '../../../actions/utils';
import { connect, useSelector } from 'react-redux';
import { compose } from 'redux';
import { withLocalize } from 'react-localize-redux';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import DataTimeSearch from '../../DataTimeSearch';
import { NAME_MASTER_SETTING, DATE_JP_FORMAT, AREAS, STATUS_CONFIRM_PARTNER } from '../../../helpers/constants';
import { MASTER_SETTING } from '../../../constants/endpoints';
import { translate } from 'react-i18next';
import _ from 'lodash'

const dataSearchMock = {
  page: 1,
  limit: 10,
  caseName: '',
  managementCode: '',
  serviceName: '',
  requestedDateTo: '',
  requestedDateFrom: '',
  operationAdjustmentDateTo: '',
  operationAdjustmentDateFrom: '',
  applicantCompanyName: '',
  resultsPreparation: '',
  visitDateTo: '',
  visitDateFrom: '',
  visitedBaseName: '',
  partnerName: '',
  prefecturesVisited: '',
  reportReceiptDate: '',
  reportDeliveryDate: '',
  searchCurrentPath: '',
  operationAdjustmentInfo: '',
};

const CollapsePartner = ({ isOpen, color, getList = () => { }, getParamSearch, nameRoute, colorText, dataService, t, checkSearch }) => {
  const [hideshow2, setHideshow2] = useState(false);
  const toggleHide = () => setHideshow2(!hideshow2);
  const [dataSearch, setDataSearch] = useState(dataSearchMock);
  const [listAddress, setListAddress] = useState({});
  const [prefecturesVisiteds, setPrefecturesVisiteds] = useState([]);
  const paramSearchBack = useSelector((store) => store.utils.paramSearch);
  const isEdit = paramSearchBack['searchCurrentPath'] == nameRoute && Object.entries(paramSearchBack).filter(([k, v]) => !!v).length > 3;
  isOpen = checkSearch ? isOpen : isEdit ? true : isOpen;

  useEffect(() => {
    getListAddress({ name: NAME_MASTER_SETTING.ADDRESS_JAPAN });
  }, []);

  const getListAddress = async ({ name = '' }) => {
    try {
      const data = await MASTER_SETTING.GET_LIST_ADDRESS({ name: name });
      if (data) {
        setListAddress(data?.data?.values || {});
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleChangeSearch = (e , check = false) => {
    const typeName = e.target.name;
    let newData = { ...dataSearch, [typeName]: check ? e.target.checked : e.target.value };
    setDataSearch(newData);
  };

  useEffect(() => {
    if (isEdit) {
      setDataSearch(paramSearchBack);
      getParamSearch(paramSearchBack);
      setPrefecturesVisiteds(JSON.parse(decodeURIComponent(paramSearchBack?.prefecturesVisited)));
    } else {
      Array.from(document.querySelectorAll('input')).forEach((input) => (input.value = ''));
      document.querySelectorAll('input[type=checkbox]').forEach( el => el.checked = '' );
      setDataSearch(dataSearchMock);
      getParamSearch(dataSearchMock);
    }
  }, [nameRoute]);

  const handleReset = () => {
    Array.from(document.querySelectorAll('input')).forEach((input) => (input.value = ''));
    document.querySelectorAll('input[type=checkbox]').forEach( el => el.checked = '' );
    setDataSearch(dataSearchMock);
    setPrefecturesVisiteds([]);
  };

  const onChangeDeadline = (date, name) => {
    setDataSearch({ ...dataSearch, [name]: date === null ? '' : moment(new Date(date)).format(DATE_JP_FORMAT) });
  };

  const onSubmit = () => {
    const newData = {...dataSearch , prefecturesVisited : encodeURIComponent(JSON.stringify(prefecturesVisiteds))}
    if (nameRoute == 'cancel_menu') {
      getParamSearch({ ...newData, searchCurrentPath: nameRoute, isDefault: true });
    } else {
      getParamSearch({ ...newData, searchCurrentPath: nameRoute });
    }
  };

  const handleActiveArea = (e) => {
    let target = e.target;
    let value = target.value;
    let arrTmp = prefecturesVisiteds || [];
    if (target.checked) {
      arrTmp.push(value);
    } else {
      arrTmp.splice(arrTmp.indexOf(value), 1);
    }
    setPrefecturesVisiteds(prefecturesVisiteds);
  };
  return (
    <>
      <Collapse isOpen={isOpen}>
        <table style={{ width: '100%' }} className="table-search">
          <tbody>
            <tr>
              <th style={{ backgroundColor: `${color}`, color: `${colorText}` }}>工事名</th>
              <td>
                <input type="text" name="caseName" value={dataSearch?.caseName} onChange={handleChangeSearch} />
              </td>

              <th style={{ backgroundColor: `${color}`, color: `${colorText}` }}>工程名</th>
              <td>
                <select name="serviceName" value={dataSearch?.serviceName} onChange={handleChangeSearch} id="serviceName">
                  <option value="">--</option>
                  {dataService && dataService?.map((item, index) =>
                    <option key={index} value={item?.name}>{item?.name}</option>
                   )}
                </select>
              </td>
            </tr>
            <tr>
              <th style={{ backgroundColor: `${color}`, color: `${colorText}` }}>管理コード</th>
              <td>
                <input
                  type="text"
                  name="managementCode"
                  value={dataSearch?.managementCode}
                  onChange={handleChangeSearch}
                />
              </td>

              <th style={{ backgroundColor: `${color}`, color: `${colorText}` }}>作成日</th>
              <td>
                <div className="d-flex align-items-center">
                  <div className="date-time-picker-wrapper">
                    <DataTimeSearch
                      onChangeDate={onChangeDeadline}
                      name="requestedDateFrom"
                      value={dataSearch?.requestedDateFrom}
                    />
                  </div>
                  ～
                  <div className="date-time-picker-wrapper">
                    <DataTimeSearch
                      onChangeDate={onChangeDeadline}
                      name="requestedDateTo"
                      value={dataSearch?.requestedDateTo}
                    />
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <th style={{ backgroundColor: `${color}`, color: `${colorText}` }}>物件名</th>
              <td>
                <input
                  type="text"
                  name="installationSiteName"
                  value={dataSearch?.installationSiteName}
                  onChange={handleChangeSearch}
                />
              </td>
              <th style={{ backgroundColor: `${color}`, color: `${colorText}` }}>協力企業名</th>
              <td>
                <input type="text" name="partnerName" value={dataSearch?.partnerName} onChange={handleChangeSearch} />
              </td>
            </tr>
            <tr>
              <th style={{ backgroundColor: `${color}`, color: `${colorText}` }}>稼働打診日</th>
              <td>
                <div className="d-flex align-items-center">
                  <div className="date-time-picker-wrapper">
                    <DataTimeSearch
                      onChangeDate={onChangeDeadline}
                      name="timeAddPartnerFrom"
                      value={dataSearch?.timeAddPartnerFrom}
                    />
                  </div>
                  ～
                  <div className="date-time-picker-wrapper">
                    <DataTimeSearch
                      onChangeDate={onChangeDeadline}
                      name="timeAddPartnerTo"
                      value={dataSearch?.timeAddPartnerTo}
                    />
                  </div>
                  空白
                  <input type="checkbox" checked={dataSearch?.checkTimeAddPartner} name='checkTimeAddPartner' onChange={(e) => handleChangeSearch(e , true)}/>
                </div>
              </td>
              <th style={{ backgroundColor: `${color}`, color: `${colorText}` }}>稼働調整状況</th>
              <td>
                <select name="operationAdjustmentInfo" onChange={handleChangeSearch} value={dataSearch?.operationAdjustmentInfo}>
                  <option value="">---</option>
                  <option value={STATUS_CONFIRM_PARTNER.emptyEL}>{STATUS_CONFIRM_PARTNER.empty}</option>
                  <option value={STATUS_CONFIRM_PARTNER.waitingEng}>{STATUS_CONFIRM_PARTNER.waitingJP}</option>
                  <option value={STATUS_CONFIRM_PARTNER.confirmedEng}>{STATUS_CONFIRM_PARTNER.secure}</option>
                </select>
              </td>
            </tr>
            <tr>
              <th style={{ backgroundColor: `${color}`, color: `${colorText}` }}>申込会社名</th>
              <td>
                <input
                  type="text"
                  name="applicantCompanyName"
                  value={dataSearch?.applicantCompanyName}
                  onChange={handleChangeSearch}
                />
              </td>
              <th style={{ backgroundColor: `${color}`, color: `${colorText}` }}>指定訪問日時</th>
              <td>
                <div className="d-flex align-items-center">
                  <div className="date-time-picker-wrapper">
                    <DataTimeSearch
                      onChangeDate={onChangeDeadline}
                      name="visitDateFrom"
                      value={dataSearch?.visitDateFrom}
                    />
                  </div>{' '}
                  ～
                  <div className="date-time-picker-wrapper">
                    <DataTimeSearch
                      onChangeDate={onChangeDeadline}
                      name="visitDateTo"
                      value={dataSearch?.visitDateTo}
                    />
                  </div>
                  空白
                  <input type="checkbox" checked={dataSearch?.checkVisitDate} onChange={(e) => handleChangeSearch(e, true)} name='checkVisitDate' />
                </div>
              </td>
            </tr>
            <tr>
              <th style={{ backgroundColor: `${color}`, color: `${colorText}` }}>都道府県</th>
              <td>
                <div className="btn-hideshow" onClick={toggleHide}>
                  ▼ 都道府県を選択 ▼
                </div>
                <div className={hideshow2 ? 'hideshow2' : 'd-none'}>
                  {!_.isEmpty(listAddress) && AREAS.map((item, index) => {
                    return (
                      <div key={index}>
                        <p className="title-address" style={{ marginBottom: 0, fontSize: 11 }}>
                          {t(`areas.${item}`)}
                        </p>
                        <div className="block-check">
                          {listAddress[item].map((itemChild, indexChild) => {
                            return (
                              <span key={indexChild} className="item-check">
                                <input
                                  style={{ position: 'absolute', marginTop: 4, marginLeft: -2 }}
                                  className=""
                                  value={itemChild}
                                  type="checkbox"
                                  defaultChecked={prefecturesVisiteds?.includes(itemChild)}
                                  checked={prefecturesVisiteds?.[`${item}`]?.includes(itemChild)}
                                  onChange={(e) => handleActiveArea(e)}
                                />
                                <label style={{ fontSize: 11 , marginLeft: 11 }}>
                                  {itemChild}
                                </label>
                              </span>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </td>
              <th style={{ backgroundColor: `${color}`, color: `${colorText}` }}>作業結果</th>
              <td>
                <select name="resultsPreparation" onChange={handleChangeSearch} value={dataSearch?.resultsPreparation}>
                  <option defaultValue=""></option>
                  <option value="完了">完了</option>
                  <option value="未完了">未完了</option>
                  <option value="当日CAN">当日CAN</option>
                </select>
              </td>
            </tr>
            <tr>
              <th style={{ backgroundColor: `${color}`, color: `${colorText}` }}>報告書受領日</th>
              <td>
                <div className="d-flex align-items-center">
                  <DataTimeSearch
                    onChangeDate={onChangeDeadline}
                    name="reportReceiptDateFrom"
                    value={dataSearch?.reportReceiptDateFrom}/>
                  ～
                  <DataTimeSearch
                   onChangeDate={onChangeDeadline}
                   name="reportReceiptDateTo"
                   value={dataSearch?.reportReceiptDateTo}/>
                  空白
                  <input type="checkbox" checked={dataSearch?.checkReportReceiptDate} name='checkReportReceiptDate' onChange={(e) => handleChangeSearch(e , true)} />
                </div>
              </td>
              <th style={{ backgroundColor: `${color}`, color: `${colorText}` }}>稼働調整日</th>
              <td>
                <div className="d-flex align-items-center">
                  <div className="date-time-picker-wrapper">
                    <DataTimeSearch
                      onChangeDate={onChangeDeadline}
                      name="timeAddPartnerFrom"
                      value={dataSearch?.timeAddPartnerFrom}
                    />
                  </div>
                  ～
                  <div className="date-time-picker-wrapper">
                    <DataTimeSearch
                      onChangeDate={onChangeDeadline}
                      name="timeAddPartnerTo"
                      value={dataSearch?.timeAddPartnerTo}
                    />
                  </div>
                  空白
                  <input type="checkbox" checked={dataSearch?.checkTimeAddPartner} name='checkTimeAddPartner' onChange={(e) => handleChangeSearch(e , true)}/>
                </div>
              </td>
            </tr>

          </tbody>
        </table>
        <div className="SearchButton" align="center">
          <button className="btn btnS btnSubmit mr-2" onClick={() => onSubmit()}>
            <i className="icon-search" />検 索
          </button>
          <button type="button" className="btn btnS btnReset" onClick={handleReset}>
            <i className="icon-arrows-cw" /> 一括解除
          </button>
        </div>
      </Collapse>
    </>
  );
};

const mapActionToProps = {
  getParamSearch,
};

export default compose(translate('translations'),withLocalize, connect(null, mapActionToProps))(CollapsePartner);
