import React, { useState, useEffect } from "react";
import { MASTER_SETTING } from "constants/endpoints";
import Loading from "components/Loading/Loading";
import { SCREEN_NAME } from 'helpers/constants';
import { pushToast } from 'components/Toast';
import { getDataHistoryMasterSetting } from 'actions/authentication';
import { useDispatch } from 'react-redux';
import { SCREEN_DISPATCH_SETTING } from "../../../../helpers/constants";

const SettingPriorityStaff = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [dataOptionSetting, setDataOptionSetting] = useState({});
  const [mockupBody, setMockupBody] = useState({ screenName: SCREEN_DISPATCH_SETTING.priorityPattern.label });

  const getListOptionSetting = async (idProvide) => {
    setLoading(true);
    try {
      const data = await MASTER_SETTING.GET_LIST_OPTION_SETTING(idProvide);
      if (data) {
        setDataOptionSetting(data ?.data);
      }
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  useEffect(() => {
    getListOptionSetting(localStorage.getItem('idProvide'));
  }, []);

  const handleOnClick = (e, status) => {
    let name = e.target.name;
    setDataOptionSetting({ ...dataOptionSetting, [name]: status });
    setMockupBody({ ...mockupBody, [name]: status });
  };

  const onSubmit = () => {
    CreateListOption({ body: {...mockupBody, updateTitle: `保存 優先パターン` }, idProvide: localStorage.getItem('idProvide') });
  };

  const CreateListOption = async ({ body, idProvide }) => {
    try {
      const data = await MASTER_SETTING.CREATE_LIST_OPTION_SETTING({ body, idProvide });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
      } else {
        pushToast('success', 'Update success');
        dispatch(getDataHistoryMasterSetting({ logId : '', screenName: SCREEN_DISPATCH_SETTING.priorityPattern.label, idProvide }));
      }
    } catch (errorMessage) {
      console.log('errorMessage: ', errorMessage);
    }
  };

  return (
    <div className="table-menu-wrapper">
      <Loading loading={loading} />
      <div className="content-view">
        <div className="row custom-row">
        <div className="col-6 text-center">
            <input
              type="radio"
              name="priorityDispatch"
              checked={dataOptionSetting?.priorityDispatch}
              defaultChecked={true}
              onClick={(e) =>
                handleOnClick(e, true)
              }
            />
            <label className="content-view--label-unset">ワーカー並べ替え</label>
          </div>
          <div className="col-6 text-center">
            <input
              type="radio"
              name="priorityDispatch"
              checked={!dataOptionSetting?.priorityDispatch}
              onClick={(e) =>
                handleOnClick(e, false)
              }
            />
            <label className="content-view--label-unset">ワーカースキル</label>
          </div>
        </div>
        <div className="text-center">
          <button className="btn btn-submit" onClick={onSubmit}>
            保存
          </button>
        </div>
      </div>
    </div>
  );
};

export default SettingPriorityStaff;
