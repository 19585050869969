import React, { Fragment } from 'react';
import { HEADER_MENU, SCREEN_NAME } from 'helpers/constants';
import { COLOR_ENDPOINT, ORDER, STAFF_ENDPOINT, ORDER_PARTNER } from 'constants/endpoints';
import { useEffect } from 'react';
import { getRole } from 'helpers/helpers';
import Header from 'components/Header';
import Footer from 'components/Footer';
import 'assets/scss/page/DetailReportCompledted/style.scss';
import { useState } from 'react';
import { pushToast } from 'components/Toast';
import DetailData from 'components/DetailCenter/DetailData';
import DetailDataList from 'components/DetailCenter/DetailDataList';
import DetailOrder from 'components/DetailCenter/DetailOrder';
import DetailListAllData from 'components/DetailCenter/DetailListAllData';
import DetailTreeDocumentSetting from 'components/DetailTreeDocumentSetting';
import DetailSent from '../../../components/DetailOrderErp/DetailSent';
import DetailTimeWorker from '../../../components/DetailOrderErp/DetailTimeWorker';
import DetailResult from '../../../components/DetailOrderErp/DetailResult';
import DetailSite from '../../../components/DetailOrderErp/DetailSite';
import DetailReport from '../../../components/DetailOrderErp/DetailReport';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Loading from 'components/Loading/Loading';
import _ from 'lodash';

const DetailReportCompledted = () => {
  let name = (process.browser && new URL(window.location.href).searchParams.get('p')) || 'delivered_info';
  let orderId = process.browser && new URL(window.location.href).searchParams.get('orderId');
  let organizationId = process.browser && new URL(window.location.href).searchParams.get('organizationId') || '';
  let partnerId = process.browser && new URL(window.location.href).searchParams.get('partnerId');
  const [p, setP] = React.useState(name || HEADER_MENU.RECEIVE_ALL);
  const [role, setRole] = React.useState(null);
  const [idRole, setIdRole] = React.useState(null);
  const [data, setData] = React.useState('');
  const [dataOrder, setDataOrder] = useState('');
  const [listDetailScreen, setListDetailScreen] = useState([]);
  const colorScreen = useSelector((store) => store.utils.colorMe);
  const [statusResult, setStatusResult] = useState([]);
  const [documentSetting, setDocumentSetting] = useState([]);
  const [dataDetailTimeWorker, setDataDetailTimeWorker] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getListRoleChild();
  }, []);

  const getListRoleChild = async () => {
    setLoading(true);
    const newRole = localStorage.getItem('role');
    try {
      const data = await ORDER.GET_LIST_DETAIL_SETTING({
        arrRole: newRole,
        nameScreen: 'delivered_info',
        orderId,
        organizationId,
      });
      if (data) {
        setDocumentSetting(data?.data?.documentSettingUploadFile);
        setListDetailScreen(data?.data?.detailScreen);
      }
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  useEffect(() => {
    setP(name);
  }, [name]);

  const getOrderDetail = async ({ orderId = '' }) => {
    try {
      setLoading(true);
      const data = await ORDER.GET_DETAIL_CANCEL_INFO({ orderId, organizationId: organizationId || '', screenName: 'delivered_info' });
      if (data) {
        setData(data?.data);
        setDataDetailTimeWorker(data?.data);
        let newData = data?.data;
        newData = { ...newData, screenName: SCREEN_NAME.HONKADO_LIST };
        setDataOrder(newData);
        getStatusResult({
          serviceId: data?.data?.serviceId,
          productId: data?.data?.productId,
          orderId: data?.data?.orderId,
        });
      }
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const getStatusResult = async ({ serviceId, productId, orderId, type = 'completionResultSetting' }) => {
    try {
      setLoading(true);
      const data = await ORDER.GET_STATUS_ORDER({ serviceId, productId, orderId, type, organizationId });
      if (data) {
        setStatusResult(data?.data);
      }
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  useEffect(() => {
    const newRole = localStorage.getItem('role');
    setIdRole(newRole);
    if (newRole) {
      setRole(getRole({ idRole: newRole }));
    }
    if (orderId) {
      getOrderDetail({ orderId });
    }
  }, [orderId]);

  return (
    <div className="wrapper-detail-delivered-info">
      <Loading loading={loading} />
      <div className="main">
        <Header idRole={idRole} color={colorScreen?.code} colorText={colorScreen?.colorText} p={p} />
        <div className="body-wrapper">
          <div className="logout_btn">
            <Link to={`list?p=${p}`}>
              <span className="mypage_ab_custom">戻る</span>
            </Link>
          </div>
          {listDetailScreen &&
            listDetailScreen.map((item, index) => {
              if (item?.status) {
                switch (item.name) {
                  case 'clientCompany':
                    return <DetailOrder p={p} data={data} key={index} />;
                  case 'applicationCompany':
                    return <DetailData p={p} data={data} link={false} key={index} />;
                  case 'mattersSent':
                    return (
                      <div key={index} >
                        <DetailSent data={data} isDisabledManagement={true} isDisabledErp={true} />
                      </div>
                    );
                  case 'visitDateWorker':
                    return <DetailTimeWorker screenName={SCREEN_NAME.APPOINT_INFO} data={dataDetailTimeWorker} key={index} />;
                  case 'result':
                    return <DetailSite data={data} key={index} />;
                  case 'reportSubmissionInformation':
                    return (
                      <DetailReport
                        key={index}
                        data={data}
                        organizationId={organizationId}
                        orderId={orderId}
                        partnerId={partnerId}
                        documentSetting={documentSetting}
                      />
                    );
                  case 'acceptanceInformation1':
                    return <DetailResult orderId={orderId} key={index} />;
                  case 'detailInfo':
                    return <DetailListAllData data={data}  screenName={SCREEN_NAME.APPOINT_INFO} key={index} />;

                  case 'document':
                    return (
                      <Fragment key={index}>
                        <DetailTreeDocumentSetting orderId={orderId} order={data} />
                      </Fragment>
                    );
                  case 'history':
                    return (
                      <DetailDataList
                        key={index}
                        datePick={false}
                        screenName={SCREEN_NAME.APPOINT_INFO}
                        orderId={orderId}
                        organizationId={organizationId}
                      />
                    );
                  default:
                    return '';
                }
              }
            })}
        </div>
        <Footer color={colorScreen?.code} colorText={colorScreen?.colorText} />
      </div>
    </div>
  );
};

export default DetailReportCompledted;
