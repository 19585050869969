import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { ANKEN_MASTER_NEW } from 'constants/endpoints';
import { pushToast } from 'components/Toast';
import 'assets/scss/page/AnkenMasterNew/TableModal.scss';

import { translate } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { SCREEN_NAME } from '../../../../../helpers/constants';

const TableCreateService = ({statusGetList, setStatusGetList, createSuccess}) => {
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const [dataTableService, setDataTableService] = useState([]);
  const [valueSearch, setValueSearch] = useState('');

  const changeValueSearch = (e) => {
    setValueSearch(e.target.value)
  };

  useEffect(() => {
    getServiceByOrganization({ valueSearch: '' });
  }, []);

  useEffect(() => {
    if (statusGetList) {
      getServiceByOrganization({ valueSearch: '' });
    }
  }, [statusGetList]);

  const handleSearch = () => {
    getServiceByOrganization({valueSearch});
  }

  const getServiceByOrganization = async ({ valueSearch = '' }) => {
    try {
      valueSearch = encodeURIComponent(valueSearch);
      const data = await ANKEN_MASTER_NEW.GET_ALL_SERVICE_BY_ORGANIZATION({name: valueSearch});
      if (data) {
        setDataTableService(data ?.data);
        setStatusGetList(false)
      }
    } catch (e) {
      console.log(e);
    }
  }

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const items = reorder(dataTableService, result.source.index, result.destination.index);
    setDataTableService(items);
  };


  const updateService = () => {
    const newList = dataTableService?.map((item, index) => {
      return {
        serviceId: item.serviceId,
        sortNum: index + 1,
        status: item.status
      }
    });

    const body = {
      services: newList ,
      screenName : SCREEN_NAME.CASE_MASTA,
      updateTitle : '更新 工程管理'
    };

    handleUpdateService({ body })
  };

  const handleUpdateService = async ({body}) => {
    try {
      const data = await ANKEN_MASTER_NEW.UPDATE_SERVICE({ body });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
      } else {
        pushToast('success', ' Update Service success');
        getServiceByOrganization({ valueSearch: ''})
        createSuccess();
      }
    } catch (e) {
      console.log(e);
    }
  };



  const onChangeStatus = (value, service) => {
    const newList = dataTableService.map((item) => {
      if (service.serviceId === item.serviceId) {
          return {...item, status: !item.status}
      }
      return item;
    })
    setDataTableService(newList);
};

  const renderItem = (item, index) => {
    return (
      <Draggable key={`${index + 1}`} draggableId={`${index + 1}`} index={index}>
        {(provided, snapshot) => (
          <tr
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            key={index}
          >
            <td>{index+1}</td>
            <td>{item.name}</td>
            <td>
              <span className="input-check">
                <input
                  type="radio"
                  checked={item.status}
                  onChange={() => onChangeStatus(item.status, item)}
                />
                <label>表示</label>
              </span>
              <span className="input-check">
                <input
                  type="radio"
                  onChange={() => onChangeStatus(item.value, item)}
                  checked={!item.status} />
                <label>非表示</label>
              </span>
            </td>
          </tr>
        )}
      </Draggable>
    );
  };

  const renderTableInfo = (data) => {
    return (
      <table className="table table-bordered table-anken-master">
        <thead>
          <tr>
            <th>No</th>
            <th>工程名</th>
            <th>利用状況</th>
          </tr>
        </thead>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <tbody
                {...provided.droppableProps} ref={provided.innerRef}
              className="scroll-table">
              {dataTableService?.map((item, index) => {
                return renderItem(item, index);
              })}
            {provided.placeholder}
            </tbody>
            )}
          </Droppable>
        </DragDropContext>

      </table>
    );
  };

  return (
    <div className="table-modal-service">
      <div className="form-search">
        <div className="group-input">
          <div className="title">検索条件</div>
          <input
            type="text"
            value={valueSearch}
            onChange={changeValueSearch}/>
        </div>
        <button
          className="btn-tree"
          onClick={()=> handleSearch()}
        >検索</button>
      </div>
      <div className="table-info-company ">
        {renderTableInfo()}
        <div className="form-btn-setting">
          <button
            onClick={()=> updateService()}
            >更新</button>
        </div>
      </div>
    </div>
  );
};

export default compose(translate('translations'), withRouter)(TableCreateService);
