import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

const ButtonFalse = () => {
  return (
    <div className="group-button-wrapper">
      <div className="btn-group btn-group-toggle" data-toggle="buttons">
        <label className={`btn process-button btn-primary`}>必要</label>
      </div>
    </div>
  );
};

export default compose(withRouter)(ButtonFalse);
