import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { renderToStaticMarkup } from 'react-dom/server';
import { withLocalize } from 'react-localize-redux';
import List from './page/List';
import enTranslation from '../i18n/translation/en.js';
import viTranslation from '../i18n/translation/vi.js';
import Login from './page/Login';
import LoginCross from './page/LoginCross';
import VerifyUser from './page/VerifyUser';
import VerifyUserCross from './page/VerifyUserCross';
import ForgotPassword from './page/ForgotPassword';
import ForgotPasswordCross from './page/ForgotPasswordCross';
import AnkenMaster from './page/AnkenMaster';
import Users from './page/Users';
import PartnerMaster from './page/PartnerMaster';
import ProcessMaster from './page/ProcessMaster';
import Monitor from './page/Monitor';
import PageMaster from './page/PageMaster';
import OutputList from './page/OutputConfList';
import FieldNameChange from './page/FieldNameChange';
import ReceiveAllEdit from './page/ReceiveAllEdit';
import Describe from './page/Describe';
import ModifyStaff from './page/ModifyStaff';
import MondifyClient from './page/MondifyClient';
import ModifyPartner from './page/modifyPartner';
import ModifyAnken from './page/ModifyAnken';
import PartnerStaffFiledMaster from './page/PartnerStaffFiledMaster';
import StaffMaster from './page/StaffMaster';
import { getUserMe, getRoleMe } from '../actions/authentication';
import { compose } from 'redux';
import { connect, shallowEqual, useSelector } from 'react-redux';
import OutputConf from './page/OutputConf';
import Toast from '../components/Toast';
import MyPage from './page/MyPage';
// import CreateUser from './page/CreateUser';
import RegisterUsers from './page/Users/RegisterUsers';
import EditUsers from './page/Users/EditUsers';
import ImportCsv from './page/ImportCsv';
import ReadAndImportCsv from './page/ReadAndImportCsv';

import SelectRole from './page/SelectRole';
import Organizations from './page/Organizations';
import ModifyOrganization from './page/ModifyOrganization';
import DescribeReceiveInfo from './page/DescribeReceiveInfo';
import AppointInfoDetail from './page/AppointInfoDetail';
import EditModifyOrganization from './page/EditModifyOrganization';
import EditStaffMaster from './page/EditStaffMaster';
import CreateStaffMaster from './page/CreateStaffMaster';
import ModifyBranchStaff from './page/ModifyBranchStaff';
import ModifyBranch from './page/ModifyBranch';
import DeleteStaff from './page/DeleteStaff';
import DetailCancelMenu from './page/DetailCancelMenu';
import DetailCancelInfo from './page/DetailCancelInfo';
import DetailConditionInfo from './page/DetailConditionInfo';
import DetailSiteInfo from './page/DetailSiteInfo';
import DetailPreparationInfo from './page/DetaiPreparationInfo';
import DetailPreparationInfoPartner from './page/DetailPreparationInfoPartner';
import DetailReportUploadInfo from './page/DetailReportUploadInfo';
import DetailDeliveredInfo from './page/DetailDeliveredInfo';
import WorkerEnter from './page/WorkerEnter';
import DetailReceiveConfirm from './page/DetailReceiveConfirm';
import DetailReportList from './page/DetailReportList';
import DetailHonkadoList from './page/DetailHonkadoList';
import DetailReportCompledted from './page/DetailReportCompledted';
import ListWorker from './page/ListWorker';
import MapWorker from './page/MapWorker';
import DetailCancelMenuClient from './page/DetailCancelMenuClient';
import DetailSportAcceptList from './page/DetailSportacceptList';
import DetailDeliveryClient from './page/DetailDeliveryClient';
import SportAcceptList from './page/SportAcceptList';
import DetailWorkerList from './page/DetailWorkerList';
import CancelDetailWorker from './page/CancelDetailWorker';
import DetailListOrder from './page/DetailListOrder';
import DetailTomorrowClient from './page/Client/DetailTomorrowClient';
import DetailBeforeConfirmErp from './page/Erp/DetailBeforeConfirmErp';
import DetailBeforeConfirmPartner from './page/Partner/DetailBeforeConfirmPartner';
import DetailBeforeConfirmClient from './page/Client/DetailBeforeConfirmClient';
import ExampleMonitor from './page/ExampleMonitor';
import MasterSetting from './page/MasterSetting';
import DispatchSetting from './page/DispatchSetting';
import SelectTableOrganization from './page/SelectTableOrganization';
import CenterMasterList from './page/CenterMasterList';
import BranchMaster from './page/BranchMaster';
import BranchStaffMaster from './page/BranchStaffMaster';
import EditModifyBranch from './page/EditBranchMaster';
import EquipmentManagement from './page/EquipmentManagement';
import VersionService from './page/VersionService';
import WorkerItemSettings from './page/WorkerItemSettings';
import ConfigWorker from './page/ConfigWorker';
import AggregateMaintenance from './page/AggregateMaintenance';
import WorkerMenuSetting from './page/WorkerMenuSetting';
import PrimaryCsv from './page/PrimaryCsv';
import SummaryReceiveAll from './page/SummaryReceiveAll';
import DetailTomorrowCenter from './page/centers/DetailTomorrowCenter';
import AnkenMasterNew from './page/AnkenMasterNew';
import ImportCsvPri from './page/ImportCsvPrimary';
import SecondarCsv from './page/SecondarCsv';
import ReportOrder from './page/ReportOrder';
import { PERMISSION, DISABLE_MENU_LIST } from '../helpers/constants';
import {
  isPermissionMyCorp,
  isPermissionOrganization,
  isPermissionAnkenMaster,
  isPermissionSettingOrder,
  isPermissionSettingWorker,
  isPermissionUserMaster,
  isPermissionDispatchSetting,
  isPermissionContract,
  isPermissionCsvMaster,
  isPermissionCreateOrganization,
  isPermissionPaymentDate,
} from '../helpers/permissionAuth';
import CsvMaster from './page/CsvMaster';
import PaymentDate from './page/PaymentDate';
import Contract from './page/Contract';
import Accounting from './page/Accounting';
import ListAcceptance from './page/Accounting/ListAcceptance';
import ListPayment from './page/Accounting/ListPayment';
import ListCompletion from './page/Accounting/ListCompletion';
import RecruitmentRegistration from './page/Contract/RecruitmentRegistration';
import ListRecruitment from './page/Contract/ListRecruitment';
import ListContractFilter from './page/Contract/ListContractFilter';
import RegistrationContract from './page/Contract/RegistrationContract';
import QuotationContract from './page/Contract/QuotationContract';
import CompanyContract from './page/Contract/CompanyContract';
import MasterTarif from './page/Contract/MasterTarif';
import MasterTarifEdit from './page/Contract/MasterTarifEdit';
import MasterTarifConfiguration from './page/Contract/MasterTarifConfiguration';
import Dispatch from './page/Dispatch';
import _ from 'lodash';
import DispatchAll from './page/DispatchAll';
import DispatchMap from './page/DispatchMap';
import UpdatePaymentDate from './page/PaymentDate/UpdatePaymentDate';
import BillingMaster from './page/Contract/BillingMaster';
import PaymentMaster from './page/Contract/PaymentMaster';
import Recreate from './page/Recreate';
import RecreateOrder from './page/Recreate/RecreateOrder';
import createOrganization from './page/CreateOrganization';
import EditOrganization from './page/EditOrganization';
import CalenderMaster from './page/CalendarMaster';
import workingSchedule from './page/workingSchedule';
import StaffWorkingSchedule from './page/StaffWorkingSchedule';
import StaffRequest from './page/workingSchedule/StaffRequest';
import ChatPage from './page/ChatPage';
import RecordCorrection from './page/RecordCorrection';
import OrderGroup from './page/OrderGruop';
import AddContractRecord from './page/Contract/AddContractRecord';
import ApplicationSite from './page/Contract/ApplicationSite';
import ApprovalRequest from './page/ApprovalRequest';

const App = ({ ...props }) => {
  const isDrone = window.location.host.split('neo.stella-cloud.com').includes('drone.stella-cloud.com');
  const { getUserMe, getRoleMe } = props;
  const roleMeData = useSelector((store) => store.authentication.roleMe, shallowEqual);
  const [moduleManager, setModuleManager] = useState({});
  useEffect(() => {
    props.initialize({
      languages: [{ code: 'en' }, { code: 'vi' }],
      options: { renderToStaticMarkup },
    });
    props.addTranslationForLanguage(enTranslation, 'en');
    props.addTranslationForLanguage(viTranslation, 'vi');
  }, []);

  useEffect(() => {
    if (!['/confirm', '/login'].includes(window.location.pathname)) {
      getUserMe();
      getRoleMe();
    }
  }, [getUserMe, getRoleMe]);

  useEffect(() => {
    const dataRole = (roleMeData?.data?.moduleManagement || []).map((item) => {
      let role_center = (!item.center && DISABLE_MENU_LIST.ROLE_ERP) || [];
      let role_partner = (!item.partner && DISABLE_MENU_LIST.ROLE_PARTNER) || [];
      let role_monitoring = (!item.client && DISABLE_MENU_LIST.ROLE_MONITORING) || [];
      const arr_tmp = _.union(role_center, role_monitoring, role_partner);
      return {
        organizationId: item.organizationId,
        data: arr_tmp,
      };
    });
    setModuleManager(_.mapValues(_.keyBy(dataRole, 'organizationId'), 'data'));
  }, [roleMeData]);

  const PrivateRoute = ({ condition, redirect, ...props }) => {
    condition = condition();

    if (condition || roleMeData?.data) {
      return <Route {...props} />;
    }
    return <Redirect to={redirect} />;
  };

  const redirectUrl = () => {
    switch (true) {
      case PERMISSION.PERMISSION_MAINTAIN_MYCORP.includes(localStorage.getItem('role')):
        return '/master-setting';
      case PERMISSION.PERMISSION_MAINTAIN_STAFF.includes(localStorage.getItem('role')):
        return '/organizations';
      case PERMISSION.PERMISSION_OPERATION_PARTNER.includes(localStorage.getItem('role')):
        return '/staff_master';
      case PERMISSION.MONITORING_GROUP_OPERATION_MANAGER.includes(localStorage.getItem('role')):
        return '/users';
      case PERMISSION.PERMISSION_PARTNER_CONTRACT.includes(localStorage.getItem('role')):
        return '/contract';
      case !_.isEmpty(moduleManager) &&
        moduleManager[localStorage.getItem('idProvide')].includes(localStorage.getItem('role')):
        return '/select-role';
      default:
        return '/list';
    }
  };

  const checkPermission = (path, currentComponent, isPer = true) => {
    switch (true) {
      case PERMISSION.PERMISSION_PATH_MAINTAIN.includes(path) &&
        PERMISSION.PERMISSION_MAINTAIN.includes(localStorage.getItem('role')):
        return isPer ? currentComponent : <Redirect to={redirectUrl()} />;
      case PERMISSION.PERMISSION_PATH_MAINTAIN.includes(path) &&
        !PERMISSION.PERMISSION_MAINTAIN.includes(localStorage.getItem('role')):
        return <Redirect to={redirectUrl()} />;
      case !PERMISSION.PERMISSION_PATH_MAINTAIN.includes(path) &&
        PERMISSION.PERMISSION_MAINTAIN.includes(localStorage.getItem('role')):
        return <Redirect to={redirectUrl()} />;
      case PERMISSION.PERMISSION_PATH_LIST.includes(path) &&
        !_.isEmpty(moduleManager) &&
        moduleManager[localStorage.getItem('idProvide')].includes(localStorage.getItem('role')):
        return <Redirect to={redirectUrl()} />;
      default:
        return currentComponent;
    }
  };

  const renderAuth = () => {
    return (
      <div className="content-wrapper">
        <BrowserRouter>
          {roleMeData && (
            <Switch>
              <Route path="/list" render={() => checkPermission('list', <List />)} />
              <Route path="/recreate" exact component={Recreate} />
              <Route exact path="/recreate/detail_recreate/:id" component={RecreateOrder} />
              <Route
                path="/users"
                exact={true}
                render={() => checkPermission('users', <Users />, isPermissionUserMaster())}
              />
              <Route
                path="/users/register-user"
                render={() => checkPermission('register-user', <RegisterUsers />, isPermissionUserMaster())}
              />
              <Route
                path="/users/edit-user"
                render={() => checkPermission('edit-user', <EditUsers />, isPermissionUserMaster())}
              />
              <Route path="/anken_master" component={AnkenMaster} />
              <Route path="/Partner_master" component={PartnerMaster} />
              <Route path="/process_master" component={ProcessMaster} />
              <Route path="/csv_output_conf_list" component={OutputList} />
              <Route path="/field_name_change" component={FieldNameChange} />
              <Route path="/monitor" component={Monitor} />
              <Route path="/calendar_master" component={CalenderMaster} />
              <Route
                path="/master-setting"
                render={() => checkPermission('master-setting', <MasterSetting />, isPermissionMyCorp())}
              />
              <Route path="/page_master" component={PageMaster} />
              <Route path="/receive_all_edit" component={ReceiveAllEdit} />
              <Route path="/describe" component={Describe} />
              <Route path="/modify_staff" component={ModifyStaff} exact={false} />
              <Route path="/modify_client" component={MondifyClient} />
              <Route path="/modify_partner" component={ModifyPartner} />
              <Route path="/csv_output_conf" component={OutputConf} />
              <Route path="/modify_anken" component={ModifyAnken} />
              <Route path="/ajax_partnerstaff_filedmaster" component={PartnerStaffFiledMaster} />
              <Route path="/my_page" component={MyPage} />
              <Route path="/chat-page" component={ChatPage} />
              <Route path="/staff_master" exact={true} component={StaffMaster} />
              <Route path="/import_csv" component={ImportCsv} />
              <Route path="/read_import_csv" component={ReadAndImportCsv} />
              <Route exact path="/select-role" component={SelectRole} />
              <Route
                exact
                path="/organizations"
                render={() => checkPermission('organizations', <Organizations />, isPermissionOrganization())}
              />
              <Route
                path="/organizations/modify_organization"
                render={() =>
                  checkPermission(
                    'organizations/modify_organization',
                    <ModifyOrganization />,
                    isPermissionCreateOrganization()
                  )
                }
              />
              <Route exact path="/describe-receive-info" component={DescribeReceiveInfo} />
              <Route exact path="/organizations/edit_modify_organization" component={EditModifyOrganization} />
              <Route path="/organizations/create-organization" component={createOrganization} />
              <Route path="/organizations/edit-organization" component={EditOrganization} />
              <Route exact path="/staff_master/edit_staff_master" component={EditStaffMaster} />
              <Route exact path="/staff_master/create_staff_master" component={CreateStaffMaster} />
              <Route exact path="/branch-master/modify-branch-staff" component={ModifyBranchStaff} />
              <Route exact path="/branch-master/modify-branch" component={ModifyBranch} />
              <Route path="/appoint-info-detail" component={AppointInfoDetail} />
              <Route path="/delete_staff" component={DeleteStaff} />
              <Route path="/detail-cancel-menu" component={DetailCancelMenu} />
              <Route path="/detail-cancel-info" component={DetailCancelInfo} />
              <Route path="/detail-condition-info" component={DetailConditionInfo} />
              <Route path="/detail-site-info" component={DetailSiteInfo} />
              <Route path="/detail-preparation-info" component={DetailPreparationInfo} />
              <Route path="/detail-preparation-info-partner" component={DetailPreparationInfoPartner} />
              <Route path="/detail-report-upload-info" component={DetailReportUploadInfo} />
              <Route path="/detail-delivered-info" component={DetailDeliveredInfo} />
              <Route path="/WorkEnter" component={WorkerEnter} />
              <Route path="/detail-receive-confirm" component={DetailReceiveConfirm} />
              <Route path="/detail-report-list" component={DetailReportList} />
              <Route path="/detail-honkado-list" component={DetailHonkadoList} />
              <Route path="/workerList" component={ListWorker} />
              <Route path="/worker-map" component={MapWorker} />
              {/* <Route path="/record-correction" component={RecordCorrection} /> */}
              <Route
                path="/csv-master"
                render={() => checkPermission('csv-master', <CsvMaster />, isPermissionCsvMaster())}
              />
              <Route path="/detail-report-compledted" component={DetailReportCompledted} />
              <Route path="/cancel-menu-detail" component={DetailCancelMenuClient} />
              <Route path="/detail-sport-accept" component={DetailSportAcceptList} />
              <Route path="/detail-delivery-client" component={DetailDeliveryClient} />
              <Route path="/sport-accept-list" component={SportAcceptList} />
              <Route path="/detail-worker-list" component={DetailWorkerList} />
              <Route path="/cancel-detail-worker" component={CancelDetailWorker} />
              <Route path="/detail-list-order" component={DetailListOrder} />
              <Route path="/detail-tomorrow-client" component={DetailTomorrowClient} />
              <Route path="/detail-before-confirm-erp" component={DetailBeforeConfirmErp} />
              <Route path="/detail-before-confirm-partner" component={DetailBeforeConfirmPartner} />
              <Route path="/detail-before-confirm-client" component={DetailBeforeConfirmClient} />
              <Route path="/detail-tomorrow-center" component={DetailTomorrowCenter} />
              <Route path="/example-monitor" component={ExampleMonitor} />
              <Route
                path="/dispatch-settings"
                render={() => checkPermission('dispatch-settings', <DispatchSetting />, isPermissionDispatchSetting())}
              />
              <Route path="/center_staff_master/select-table-organization" component={SelectTableOrganization} />
              <Route path="/center-master-list" component={CenterMasterList} />
              <Route path="/branch-master" exact={true} component={BranchMaster} />
              <Route path="/branch-master/branch-staff-master" component={BranchStaffMaster} />
              <Route path="/branch-master/edit-modify-branch" component={EditModifyBranch} />
              {/* <Route path="/equipment-management" component={EquipmentManagement} /> */}
              <Route
                path="/equipment-management"
                render={() => checkPermission('equipment-management', <EquipmentManagement />)}
              />
              <Route path="/configuration-worker/version-service" component={VersionService} />
              <Route
                path="/configuration-worker"
                exact={true}
                render={() => checkPermission('configuration-worker', <ConfigWorker />, isPermissionSettingWorker())}
              />
              <Route path="/aggregate" component={AggregateMaintenance} />
              <Route
                path="/ImportCsv-pri-sco"
                exact={true}
                render={() => checkPermission('ImportCsv-pri-sco', <ImportCsvPri />, isPermissionSettingOrder())}
              />
              <Route path="/configuration-worker/worker-menu-setting" component={WorkerMenuSetting} />
              <Route path="/configuration-worker/worker-item-settings" component={WorkerItemSettings} />
              <Route path="/ImportCsv-pri-sco/primary-csv" component={PrimaryCsv} />
              <Route path="/ImportCsv-pri-sco/secondar-csv" component={SecondarCsv} />
              <Route path="/summary-receive-all" component={SummaryReceiveAll} />
              <Route
                path="/anken-master"
                render={() => checkPermission('anken-master', <AnkenMasterNew />, isPermissionAnkenMaster())}
              />
              <Route path="/report-order" component={ReportOrder} />

              {/* Contract */}
              <Route path="/contract/recruitment" component={RecruitmentRegistration} />
              <Route
                path="/contract"
                exact
                render={() => checkPermission('contract', <Contract />, isPermissionContract())}
              />
              <Route path="/contract/filters" component={ListContractFilter} />
              <Route path="/contract/list-recruitment" component={ListRecruitment} />
              <Route path="/contract/contract-record" component={AddContractRecord} />
              <Route path="/contract/application-site" component={ApplicationSite} />
              <Route path="/contract/registration" component={RegistrationContract} />
              <Route path="/contract/list-quotation" component={QuotationContract} />
              <Route path="/contract/list-contract-company" component={CompanyContract} />
              <Route path="/contract/master-tarif" component={MasterTarif} />
              <Route path="/contract/master-tarif-edit" component={MasterTarifEdit} />
              <Route path="/contract/master-tarif-configuration" component={MasterTarifConfiguration} />
              <Route path="/contract/billing-master" component={BillingMaster} />
              <Route path="/contract/payment-master" component={PaymentMaster} />
              <Route path="/orderGroup" component={OrderGroup} />

              {/* Dispatch */}
              <Route path="/dispatch" component={Dispatch} />
              <Route path="/dispatch-all" component={DispatchAll} />
              <Route path="/dispatch-map" component={DispatchMap} />

              {/* Payment Date Maintain */}
              <Route
                path="/payment-date"
                exact
                render={() => checkPermission('payment-date', <PaymentDate />, isPermissionPaymentDate())}
              />
              <Route path="/payment-date/update/:id" component={UpdatePaymentDate} />
              {/* Accounting */}
              <Route path="/accounting" component={Accounting} exact />
              <Route path="/accounting/list-acceptance" component={ListAcceptance} />
              <Route path="/accounting/list-payment" component={ListPayment} />
              <Route path="/accounting/list-completion" component={ListCompletion} />
              <Route path="/staff-working-schedule" component={StaffWorkingSchedule} />
              <Route path="/confirm-page" component={ApprovalRequest} />
              <Route path="/working-schedule" exact component={workingSchedule} />
              <Route path="/working-schedule/staff-request" component={StaffRequest} />
            </Switch>
          )}
        </BrowserRouter>
        <Toast />
      </div>
    );
  };

  const cookie = () => {
    let accessToken = document.cookie.match(new RegExp('(^| )' + 'STELLA.ACCESS_TOKEN' + '=([^;]+)'))?.[2] || '';
    return accessToken;
  };

  useEffect(() => {
    if (cookie() === '') {
      localStorage.removeItem('IS_LOGIN');
    }
  }, [cookie()]);

  return (
    <div className="content-wrapper">
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={isDrone ? LoginCross : Login} />
          <Route path="/login" component={isDrone ? LoginCross : Login} />
          <Route path="/confirm" component={isDrone ? VerifyUserCross : VerifyUser} />
          <Route path="/forgot-password" component={isDrone ? ForgotPasswordCross : ForgotPassword} />
          <PrivateRoute
            condition={() => localStorage.getItem('IS_LOGIN')}
            redirect="/"
            path="/"
            component={renderAuth}
          />
        </Switch>
      </BrowserRouter>
      {localStorage?.getItem('IS_LOGIN') === null && <Toast />}
    </div>
  );
};

const mapActionToProps = {
  getUserMe,
  getRoleMe,
};

export default compose(withLocalize, connect(null, mapActionToProps))(App);
