import { ACTIONS } from '../actions/chat';
import _ from 'lodash';

export const utilsState = {
  loading: false,
  listRoom: [],
  listMessage: [],
  listUser: [],
  listChatUserByUser: [],
  listMessageUserByUser:[]
};

export default (state = utilsState, action) => {
  switch (action.type) {

    case ACTIONS.SAVE_LIST_ROOM:
      return {
        ...state,
        listRoom: action.listRoom || []
      };
    case ACTIONS.UPDATE_LIST_ROOM:
      return {
        ...state,
        listRoom: [...state.listRoom, action.payload]
      };


    case ACTIONS.SAVE_LIST_MESSAGE_IN_ROOM:
      return {
        ...state,
        listMessage: action.listMessage || []
      };
    case ACTIONS.UPDATE_LIST_MESSAGE_IN_ROOM:
      const newList = [...state.listMessage, action.payload];
      return {
        ...state,
        listMessage: _.uniqBy(newList, 'timestamp')
      };


    case ACTIONS.SAVE_LIST_USER_IN_ROOM:
      return {
        ...state,
        listUser: action.listUser || []
      };

    case ACTIONS.UPDATE_LIST_USER_IN_ROOM:
      const newListUser = [...state.listUser, action.payload];
      return {
        ...state,
        listUser: _.uniqBy(newListUser, 'userId')
      };

    case ACTIONS.UPDATE_MULTIPLE_USER:
      const newMultUser = [...state.listUser, ...action.payload];
      return {
        ...state,
        listUser: _.reverse(_.uniqBy(_.reverse(newMultUser), 'userId'))
      };

    case ACTIONS.REMOVE_USER_IN_ROOM:
      const newData = state.listUser.filter(e => e?.userId !== action?.payload?.userId);
      return {
        ...state,
        listUser: _.uniqBy(newData, 'userId')
      };


    case ACTIONS.SAVE_LIST_CHAT_USER_BY_USER:
      return {
        ...state,
        listChatUserByUser: action.listChatUserByUser || []
      };

    case ACTIONS.SAVE_LIST_MESSAGE_IN_USER_BY_USER:
      return {
        ...state,
        listMessageUserByUser: action.listMessageUserByUser || []
      };
    case ACTIONS.UPDATE_LIST_MESSAGE_IN_USER_BY_USER:
      const newListMessage = [...state.listMessageUserByUser, action.payload];
      return {
        ...state,
        listMessageUserByUser: _.uniqBy(newListMessage, 'timestamp')
      };

    default:
      return state;
  }
};
