import DateTimePicker from '../../DateTimePicker';
import { Collapse } from 'reactstrap';
import React, { useState } from 'react';
import _ from 'lodash';

const CollapseCenter = ({ isOpen, color, dataOrder }) => {
  const [hideshow2, setHideshow2] = useState(false);
  const toggleHide = () => setHideshow2(!hideshow2);

  return (
    <>
      <Collapse isOpen={isOpen}>
        <table style={{ width: '100%' }} className="table-search">
          <colgroup>
            <col className="col__table" />
            <col className="col__value" />
            <col className="col__table" />
            <col className="col__value" />
          </colgroup>
          <tbody>
            <tr>
              <th className="table--w15">案件名</th>
              <td className="table--w85" colSpan="3">{dataOrder?.caseName}</td>
            </tr>
            <tr>
              <th className="table--w15">パートナー名</th>
              <td className="table--w85" colSpan="3">{dataOrder?.abbreviationName}</td>
            </tr>
            <tr>
              <th className="table--w15">稼働調整日</th>
              <td className="table--w30">{dataOrder?.timeAddPartner}</td>
              <th className="table--w15">工程</th>
              <td className="table--w30">{dataOrder?.serviceName}</td>
            </tr>
            <tr>
              <th className="table--w15">
                申送り事項
                <br />
                パートナー伝達用
              </th>
              <td className="table--w85" colSpan="3">{dataOrder?.managementMattersToBeSent}</td>
            </tr>
            <tr>
              <th className="table--w15">管理コード</th>
              <td className="table--w85" colSpan="3">{dataOrder?.managementCode}</td>
            </tr>
            <tr>
              <th className="table--w15">訪問開始日時</th>
              <td className="table--w30">{`${!_.isEmpty(dataOrder?.visitDate) ? dataOrder?.visitDate : ''} ${
                !_.isEmpty(dataOrder?.visitDateFrom) ? dataOrder?.visitDateFrom : ''
              }`}</td>
              <th className="table--w15">訪問終了日時</th>
              <td className="table--w30">
                {`${!_.isEmpty(dataOrder?.visitDate) ? dataOrder?.visitDate : ''} ${
                  !_.isEmpty(dataOrder?.visitDateTo) ? dataOrder?.visitDateTo : ''
                }`}
              </td>
            </tr>
            <tr>
              <th className="table--w15">物件名</th>
              <td className="table--w85" colSpan="3">{dataOrder?.installationSiteName}</td>
            </tr>
            <tr>
              <th className="table--w15">訪問先ご担当者</th>
              <td className="table--w30">{dataOrder?.contactPersonInCharge}</td>
              <th className="table--w15">訪問先ご担当者連絡先</th>
              <td className="table--w30">{dataOrder?.contactInformation}</td>
            </tr>
            <tr>
              <th className="table--w15" rowSpan="2">訪問先住所</th>
              <td className="table--w85" rowSpan="2" colSpan="3">
                {dataOrder?.visitedBaseAddress}
              </td>
            </tr>
            <tr></tr>
            <tr>
              <th className="table--w15">訪問先建物名</th>
              <td className="table--w30">{dataOrder?.visitedBuildingName}</td>
              <th className="table--w15">作業予定箇所</th>
              <td className="table--w30">{dataOrder?.scheduledInstallationLocation}</td>
            </tr>
          </tbody>
        </table>
      </Collapse>
    </>
  );
};

export default CollapseCenter;
