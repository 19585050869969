import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { PRODUCT_ENDPOINT, COLOR_ENDPOINT } from '../../../../../constants/endpoints';
import './style.scss';
import ButtonTrueFalse from './ButtonTrueFalse';
import ButtonFalse from './ButtonFalse';
import { pushToast } from '../../../../../components/Toast';



const TableProcess = ({ productId }) => {

  const mockData = {
    productId: productId,
    createNewOrder: true,
    orderExecutionApproval: true,
    appointment: true,
    operationAdjustment: true,
    workerAllocation: true,
    confirmedTheDayBefore: true,
    confirmed: true,
    reportCollection: true,
    reportDelivery: true,
    acceptanceConfirmaton: true,
  }

  const [data, setData] = useState(mockData);

  const onChangeCreateNewOrder = ({ status = true }) => {
    let newData = { ...data };
    newData = { ...newData, createNewOrder: status }
    setData(newData)
  };
  const onChangeOrderExecutionApproval = ({ status = false }) => {
    let newData = { ...data };
    newData = { ...newData, orderExecutionApproval: status }
    setData(newData)
  };
  const onChangeAppointment = ({ status = false }) => {
    let newData = { ...data };
    newData = { ...newData, appointment: status }
    setData(newData)
  };
  const onChangeOperationAdjustment = ({ status = false }) => {
    let newData = { ...data };
    newData = { ...newData, operationAdjustment: status }
    setData(newData)
  };
  const onChangeWorkerAllocation = ({ status = false }) => {
    let newData = { ...data };
    newData = { ...newData, workerAllocation: status }
    setData(newData)
  };
  const onChangeConfirmedTheDayBefore = ({ status = false }) => {
    let newData = { ...data };
    newData = { ...newData, confirmedTheDayBefore: status }
    setData(newData)
  };
  const onChangeConfirmed = ({ status = true }) => {
    let newData = { ...data };
    newData = { ...newData, confirmed: status }
    setData(newData)
  };
  const onChangeReportCollection = ({ status = false }) => {
    let newData = { ...data };
    newData = { ...newData, reportCollection: status }
    setData(newData)
  };
  const onChangeReportDelivery = ({ status = true }) => {
    let newData = { ...data };
    newData = { ...newData, reportDelivery: status }
    setData(newData)
  };
  const onChangeAcceptanceConfirmaton = ({ status = false }) => {
    let newData = { ...data };
    newData = { ...newData, acceptanceConfirmaton: status }
    setData(newData)
  };

  const createProductSetting = async (body) => {
    try {
      const data = await PRODUCT_ENDPOINT.CREATE_PRODUCT_SETTING(body);
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
      } else {
        pushToast('success', 'Create product success');
      }
    } catch (errorMessage) {
      console.log('errorMessage: ', errorMessage);
    }
  };

  const onClickSubmit = () => {
    createProductSetting(data)
  };

  return (
    <div className="wrapper-table-process">
      <table>
        <tbody>
          <tr>
            <th>
              <div className="p-2 d-flex table-process-col">
                <div className="wrapper-id">1</div>
                新規Order作成
                  </div>
            </th>
            <td>
              <div className="p-2 d-flex table-process-right col-6">
                <ButtonFalse onChange={onChangeCreateNewOrder}/>
              </div>
            </td>
          </tr>
          <tr>
            <th>
              <div className="p-2 d-flex table-process-col">
                <div className="wrapper-id">2</div>
                Order実行承認
                  </div>
            </th>
            <td>
              <div className="p-2 d-flex table-process-right col-6">
                <ButtonTrueFalse status={data?.orderExecutionApproval} onChange={onChangeOrderExecutionApproval} />
              </div>
            </td>
          </tr>
          <tr>
            <th>
              <div className="p-2 d-flex table-process-col">
                <div className="wrapper-id">3</div>
                アポイント
                  </div>
            </th>
            <td>
              <div className="p-2 d-flex table-process-right col-6">
                <ButtonTrueFalse status={data?.appointment} onChange={onChangeAppointment} />
              </div>
            </td>
          </tr>
          <tr>
            <th>
              <div className="p-2 d-flex table-process-col">
                <div className="wrapper-id">4</div>
                稼動調整
                  </div>
            </th>
            <td>
              <div className="p-2 d-flex table-process-right col-6">
                <ButtonTrueFalse status={data?.operationAdjustment} onChange={onChangeOperationAdjustment} />
              </div>
            </td>
          </tr>
          <tr>
            <th>
              <div className="p-2 d-flex table-process-col">
                <div className="wrapper-id">5</div>
                作業員割当
                  </div>
            </th>
            <td>
              <div className="p-2 d-flex table-process-right col-6">
                <ButtonTrueFalse status={data?.workerAllocation} onChange={onChangeWorkerAllocation} />
              </div>
            </td>
          </tr>
          <tr>
            <th>
              <div className="p-2 d-flex table-process-col">
                <div className="wrapper-id">6</div>
                前日確認
                  </div>
            </th>
            <td>
              <div className="p-2 d-flex table-process-right col-6">
                <ButtonTrueFalse status={data?.onChangeConfirmedTheDayBefore} onChange={onChangeConfirmedTheDayBefore} />
              </div>
            </td>
          </tr>
          <tr>
            <th>
              <div className="p-2 d-flex table-process-col">
                <div className="wrapper-id">7</div>
                完了確認
                  </div>
            </th>
            <td>
              <div className="p-2 d-flex table-process-right col-6">
                <ButtonFalse onChange={onChangeConfirmed}/>
              </div>
            </td>
          </tr>
          <tr>
            <th>
              <div className="p-2 d-flex table-process-col">
                <div className="wrapper-id">8</div>
                報告書回収
                  </div>
            </th>
            <td>
              <div className="p-2 d-flex table-process-right col-6">
                <ButtonTrueFalse status={data?.reportCollection} onChange={onChangeReportCollection} />
              </div>
            </td>
          </tr>
          <tr>
            <th>
              <div className="p-2 d-flex table-process-col">
                <div className="wrapper-id">9</div>
                報告書納品
                  </div>
            </th>
            <td>
              <div className="p-2 d-flex table-process-right col-6">
                <ButtonFalse onChange={onChangeReportDelivery}/>
              </div>
            </td>
          </tr>
          <tr>
            <th>
              <div className="p-2 d-flex table-process-col">
                <div className="wrapper-id">10</div>
                検収確認
                  </div>
            </th>
            <td>
              <div className="p-2 d-flex table-process-right col-6">
                <ButtonTrueFalse status={data?.acceptanceConfirmaton} onChange={onChangeAcceptanceConfirmaton} />
              </div>
            </td>
          </tr>


        </tbody>
      </table>
      <div className="d-flex justify-content-center align-items-center">
        <button className="btn btn-danger button-anken-submit mt-3" onClick={() => onClickSubmit()}>登録</button>
      </div>
    </div>
  );
};

export default compose(withRouter)(TableProcess);
