import React, { useState, useEffect } from 'react';
import AutocompleteAddress from 'components/AutocompleteAddress';
import { USER_ENDPOINT } from '../../../../constants/endpoints';
import { pushToast } from '../../../../components/Toast';
import HeaderMasterSetting from '../../../../components/HeaderMasterSetting';
import { useSelector } from 'react-redux';
import { PARAMS_REQUIRED_USER, SCREEN_NAME, DEFAULT_ADDRESS, DATE_JP_FORMAT, ROLE_ENUM_SELECT, ROLE_TYPE_ORGANIZATION, NAME_ROLE, PERMITTED_PARAM_USER_MONITORING_CREATE } from '../../../../helpers/constants';
import Loading from 'components/Loading/Loading';
import DateTimePicker from 'components/DateTimePicker';
import moment from 'moment';
import Select from 'react-select';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { generatePassword, validateRequried} from 'helpers/helpers';
import AreaSelection from 'components/AreaSelection';
import 'assets/scss/page/center/Users/create-update-user.scss'
import { AREAS_PERMITTED } from 'helpers/areas';
import QualificationList from '../../../../components/QualificationList';
import FooterMaintenance from "components/FooterMaintenance";

const getQualifiedMock = {
  grade: "",
  licenseNumber: "",
  qualifications: "",
  validityPeriodFrom: "",
  validityPeriodTo: ""
}

const mockData = {
  firstNameKana: '',
  lastNameKana: '',
  firstName: '',
  lastName: '',
  email: '',
  loginId: '',
  movingSpeed: null,
  distanceRange: null,
  address: '',
  areas: {},
  prefectures: '',
  passwordExpirationDate: '',
  phoneNumber: '',
  addressKana: '',
  latitude: null,
  longitude: null,
  postalCode: '',
  loginPassword: '',
  roles: [],
  getQualified: [{ ...getQualifiedMock }, { ...getQualifiedMock }, {...getQualifiedMock}],
  screenName : SCREEN_NAME.USER_MASTER
};

const RegisterUsers = () => {
  const colorScreen = useSelector((store) => store.utils.colorMe);
  const [userData, setUserData] = useState(mockData);
  const [isDisable, setIsDisable] = useState(true);
  const [loading, setLoading] = useState(false);
  let organizationId = (process.browser && new URL(window.location.href).searchParams.get('organizationId')) || localStorage.getItem('idProvide');
  let permissionId = (process.browser && (new URL(window.location.href).searchParams.get('clientId') || new URL(window.location.href).searchParams.get('partnerId')));
  let partnerId = (process.browser && new URL(window.location.href).searchParams.get('partnerId'));
  let role = (process.browser && new URL(window.location.href).searchParams.get('role')) || null;
  let typeRole = (process.browser && new URL(window.location.href).searchParams.get('typeRole')) || '';
  const [options, setOptions] = useState({primary: [], secondary: []});
  const [defaultValues, setDefaultValues] = useState([]);
  const [prefectureAreas, setPrefectureAreas] = useState({});
  const [rolePrimary , setRolePrimary] = useState()
  const [code, setCode] = useState(null);
  const history = useHistory();
  const prefectureNameKanji = _.groupBy(AREAS_PERMITTED, 'prefectureNameKanji');
  const idRole = localStorage.getItem('role');
  const [listBranchMaster, setListBranchMaster] = useState([]);

  const handleOptions = ({typeRole}) => {
    if(idRole === "1") return handleNeo({typeRole});

    switch(typeRole){
      case "2":
        return _.filter(ROLE_ENUM_SELECT, function(num) { return ["2", "3", "4", "8", "9", "10", "11", "12", "13", "14"].includes(num.value) });
      case "3":
        return _.filter(ROLE_ENUM_SELECT, function(num) { return ["3", "4", "9", "10", "12", "13", "14"].includes(num.value) });
      case "4":
        return _.filter(ROLE_ENUM_SELECT, function(num) { return ["4", "10", "13", "14"].includes(num.value) });
      case "5":
        return _.filter(ROLE_ENUM_SELECT, function(num) { return ["5", "6", "7", "8", "9", "10", "11", "12", "13", "14"].includes(num.value) });
      case "6":
        return _.filter(ROLE_ENUM_SELECT, function(num) { return ["6", "7", "9", "10", "12", "13", "14"].includes(num.value) });
      case "7":
        return _.filter(ROLE_ENUM_SELECT, function(num) { return ["7", "10", "13", "14"].includes(num.value) });
      case "8":
        return _.filter(ROLE_ENUM_SELECT, function(num) { return ["8", "9", "10", "14"].includes(num.value) });
      case "9":
        return _.filter(ROLE_ENUM_SELECT, function(num) { return ["9", "10", "14"].includes(num.value) });
      case "10":
        return _.filter(ROLE_ENUM_SELECT, function(num) { return ["10", "14"].includes(num.value) });
      case "11":
        return _.filter(ROLE_ENUM_SELECT, function(num) { return ["11", "12", "13"].includes(num.value) });
      case "12":
        return _.filter(ROLE_ENUM_SELECT, function(num) { return ["12", "13"].includes(num.value) });
      case "13":
        return _.filter(ROLE_ENUM_SELECT, function(num) { return ["13"].includes(num.value) });
      case "14":
        return _.filter(ROLE_ENUM_SELECT, function(num) { return ["14"].includes(num.value) });
      default:
        return []
    }
  }

  const handleNeo = ({typeRole}) => {
    switch (typeRole) {
      case "2":
        return _.filter(ROLE_ENUM_SELECT, function(num) { return ["2", "3", "4"].includes(num.value) });
      case "3":
        return _.filter(ROLE_ENUM_SELECT, function(num) { return ["3", "4"].includes(num.value) });
      case "4":
        return _.filter(ROLE_ENUM_SELECT, function(num) { return ["4"].includes(num.value) });
      case "5":
        return _.filter(ROLE_ENUM_SELECT, function(num) { return ["5", "6", "7", "14"].includes(num.value) });
      case "6":
        return _.filter(ROLE_ENUM_SELECT, function(num) { return ["6", "7", "14"].includes(num.value) });
      case "7":
        return _.filter(ROLE_ENUM_SELECT, function(num) { return ["7", "14"].includes(num.value) });
    }
  }

  const handlePrimaryOptions = () => {
    switch(role){
      case "ERP":
        getCode({ typeUser: 'erp', typeId: organizationId });
        return handleErp();
      case "ManagementGroup":
        getCode({ typeUser: 'partnerGroup', typeId: organizationId });
        return handleManagementGroup();
      case "Partner":
        getCode({ typeUser: 'partner', typeId: permissionId });
        return handleManagement();
      case "Client":
        getCode({ typeUser: 'client', typeId: permissionId });
        return handleMonitoring();
      case "Worker":
        return _.filter(ROLE_ENUM_SELECT, function(num) { return ["14"].includes(num.value) });
      default:
        return []
    }
  };

  const handleErp = () => {
    switch(idRole){
      case "1":
      case "2":
        return _.filter(ROLE_ENUM_SELECT, function(num) {return ["2", "3", "4"].includes(num.value) });
      case "3":
        return _.filter(ROLE_ENUM_SELECT, function(num) { return ["3", "4"].includes(num.value) });
      case "4":
        return _.filter(ROLE_ENUM_SELECT, function(num) { return ["4"].includes(num.value) });
      default:
        return []
    }
  };

  const handleManagementGroup = () => {
    switch(idRole){
      case "1":
      case "5":
        return _.filter(ROLE_ENUM_SELECT, function(num) {return ["5", "6", "7"].includes(num.value) });
      case "6":
        return _.filter(ROLE_ENUM_SELECT, function(num) { return ["6", "7"].includes(num.value) });
      case "7":
        return _.filter(ROLE_ENUM_SELECT, function(num) { return ["7"].includes(num.value) });
      default:
        return []
    }
  };

  const handleManagement = () => {
    switch(idRole){
      case "2":
        return _.filter(ROLE_ENUM_SELECT, function(num) {return ["8", "9", "10", "14"].includes(num.value) });
      case "3":
        return _.filter(ROLE_ENUM_SELECT, function(num) { return ["9", "10", "14"].includes(num.value) });
      case "4":
        return _.filter(ROLE_ENUM_SELECT, function(num) { return ["10", "14"].includes(num.value) });
      case "5":
        return _.filter(ROLE_ENUM_SELECT, function(num) {return ["8", "9", "10", "14"].includes(num.value) });
      case "6":
        return _.filter(ROLE_ENUM_SELECT, function(num) { return ["9", "10", "14"].includes(num.value) });
      case "7":
        return _.filter(ROLE_ENUM_SELECT, function(num) { return ["10", "14"].includes(num.value) });
      case "8":
        return _.filter(ROLE_ENUM_SELECT, function(num) {return ["8", "9", "10", "14"].includes(num.value) });
      case "9":
        return _.filter(ROLE_ENUM_SELECT, function(num) { return ["9", "10", "14"].includes(num.value) });
      case "10":
        return _.filter(ROLE_ENUM_SELECT, function(num) { return ["10", "14"].includes(num.value) });
      default:
        return []
    }
  };

  const handleMonitoring = () => {
    switch(idRole){
      case "2":
        return _.filter(ROLE_ENUM_SELECT, function(num) {return ["11", "12", "13"].includes(num.value) });
      case "3":
        return _.filter(ROLE_ENUM_SELECT, function(num) { return ["12", "13"].includes(num.value) });
      case "4":
        return _.filter(ROLE_ENUM_SELECT, function(num) { return ["13"].includes(num.value) });
      case "5":
        return _.filter(ROLE_ENUM_SELECT, function(num) {return ["11", "12", "13"].includes(num.value) });
      case "6":
        return _.filter(ROLE_ENUM_SELECT, function(num) { return ["12", "13"].includes(num.value) });
      case "7":
        return _.filter(ROLE_ENUM_SELECT, function(num) { return ["13"].includes(num.value) });
      case "11":
        return _.filter(ROLE_ENUM_SELECT, function(num) {return ["11", "12", "13"].includes(num.value) });
      case "12":
        return _.filter(ROLE_ENUM_SELECT, function(num) { return ["12", "13"].includes(num.value) });
      case "13":
        return _.filter(ROLE_ENUM_SELECT, function(num) { return ["13"].includes(num.value) });
      default:
        return []
    }
  };

  const handleRoleApi = () => {
    return ROLE_TYPE_ORGANIZATION.find((i) => i.name === role)?.permission;
  }

  useEffect(() => {
    const key = handleRoleApi();
    const permission = _.isEmpty(key) ? {} : { [key]: permissionId }
    setOptions({primary: handlePrimaryOptions(), secondary: []});
    setUserData({...userData, organizationId, ...permission });
    if(partnerId) {
      getListBranchMaster({partnerId});
    }
  }, []);

  useEffect(() => {
    if(userData.primaryRole) {
      setOptions({...options, secondary: handleOptions({typeRole: userData?.primaryRole?.idRole})});
    }
  }, [userData.primaryRole]);

  useEffect(() => {
    if (userData) {
      validateRequried({
        data: userData,
        arrField:
          userData?.primaryRole?.idRole === '14'
            ? [...PARAMS_REQUIRED_USER, ...['movingSpeed', 'distanceRange']]
            : PARAMS_REQUIRED_USER,
        onDisabled,
        arrFieldRemove: userData?.primaryRole?.idRole === '14' ? [] : ['movingSpeed', 'distanceRange'],
      });
    }
  }, [userData]);

  useEffect(() => {
    if(code) {
      setUserData({...userData, loginId: code});
    };
  }, [code])

  const onDisabled = ({status}) => {
    setIsDisable(status);
  };

  const getCode = async ({ typeUser, typeId }) => {
    try {
      setLoading(true);
      const data = await USER_ENDPOINT.GET_CODE({ typeUser, typeId });
      if (data) {
        setLoading(false);
        setCode(data?.data?.code);
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const getListBranchMaster = async ({ partnerId }) => {
    try {
      setLoading(true);
      const data = await USER_ENDPOINT.GET_BRANCH_MASTER({ partnerId });
      if (data) {
        setLoading(false);
        setListBranchMaster(data?.data);
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const userRegister = async (body) => {
    try {
      setLoading(true);
      body.postalCode = `${!_.isEmpty(body.postNumberFirst) ? body.postNumberFirst : ''}-${
        !_.isEmpty(body.postNumberLast) ? body.postNumberLast : ''
      }`;
      if(_.isEmpty(body?.primaryRole?.idRole)) {
        delete body.primaryRole;
      }
      delete body.postNumberFirst;
      delete body.postNumberLast;
      const data = await USER_ENDPOINT.USER_CREATE(body);
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
      } else {
        pushToast('success', 'Create user success');
        history.push(`edit-user?sid=${data?.data?.idUser}&organizationId=${organizationId}&role=${role}&permissionId=${permissionId}`);
      }
      setLoading(false);
    } catch (errorMessage) {
      console.log('errorMessage: ', errorMessage);
      setLoading(false);
    }
  };

  const titleName = () => {
    switch (role) {
      case NAME_ROLE.monitoring:
        return '発注企業利用者新規作成';
      case NAME_ROLE.rep:
        return '契約企業利用者新規作成';
      case NAME_ROLE.managementGroup:
        return 'ManagementGroup_User_新規作成';
      default:
        return '協力企業利用者新規作成';
    };
  }

  const onCreate = () => {
    if (userData) {
      const passwordExpirationDate = moment(new Date()).add(1, 'months').format(DATE_JP_FORMAT);
      if (role === NAME_ROLE.monitoring) {
        const body = _.pick(userData,  PERMITTED_PARAM_USER_MONITORING_CREATE)
        userRegister({ ...body, prefectureAreas, primaryKeyType: role, updateTitle: `更新 ${titleName()} ${userData?.loginId?.trim()}`, passwordExpirationDate });
      } else {
        userRegister({ ...userData, prefectureAreas, primaryKeyType: role, updateTitle: `更新 ${titleName()} ${userData?.loginId?.trim()}`, passwordExpirationDate });
      }
    }
  };

  const onChangeText = (e) => {
    const name = e.target.name;
    if(name === 'loginId' && !!code) return false;
    const value = e.target.value;
    const newData = {...userData, [name]: value};
    setUserData(newData);
  };

  const onChangeDate = (date) => {
    setUserData({ ...userData, passwordExpirationDate: date === null ? '' : moment(new Date(date)).format(DATE_JP_FORMAT) });
  };

  const onClickMakePass = () => {
    let newPass = generatePassword();
    let newUserData = { ...userData };
    newUserData = { ...newUserData, loginPassword: newPass };
    setUserData(newUserData);
  };

  const handleChange = (e) => {
    setDefaultValues(e || []);
    const roles = e?.map(i => i['value']) || [];
    let newUserData = { ...userData };
    newUserData = { ...newUserData, roles: roles };
    setUserData(newUserData);
  };

  const onChangePrefectureAreas = (data) => {
    setPrefectureAreas(data);
  }

  const onChangeAreas = (areas) => {
    const newData = { ...userData, areas };
    setUserData(newData);
    const values = _.flatten(Object.values(newData?.areas || {}));
    const dataHandler = values.map(e => {
      return Object.keys(prefectureAreas || {}).includes(e) ? {[e]: prefectureAreas[e]} : {[e]: _.compact(_.map(prefectureNameKanji[e] || [], 'cityNameKanji'))}
    })
    const newPrefectureAreas = _.isEmpty(dataHandler) ? {} : Object.assign(...dataHandler);
    setPrefectureAreas(newPrefectureAreas);
  }

  const handleRolePrimary = (e) => {
    const roles = e?.value || '' ;
    setRolePrimary(e);
    setDefaultValues([e]);
    let newUserData = { ...userData };
    newUserData = { ...newUserData, roles: [roles],  primaryRole: { idOrganization :  userData?.organizationId, idRole: !_.isEmpty(roles) ? roles : '' } };
    setUserData(newUserData);
  };

  const handleChangeQualificationText = ({ e, item, index, name, value }) => {
    const fieldName = e && e.target.name || name
    item[fieldName] = e && e.target.value || value
    const getQualified = userData ?.getQualified;
    getQualified[index] = item
    setUserData({ ...userData, getQualified })
  }

  const handleRemote  = (index) => {
    const getQualified = [...userData?.getQualified];
    getQualified.splice(index, 1);
    setUserData({ ...userData, getQualified });
  }
  return (
    <div className="main-branch-master user-master-form-create-update-screen">
      <Loading loading={loading} />
      <HeaderMasterSetting color={colorScreen?.code} colorText={colorScreen?.colorText} nameRole={role}/>
      <div className="body-wrapper mb-1">
        <div align="center" style={{ border: `3px solid ${colorScreen?.code}`}} className="table__user">
          <div className="grid--pd15" >
            <table className="user-master-table">
            <tbody>
              <tr>
                <th>利用者ID</th>
                <td>
                  <input name="loginId" className={`${_.isEmpty(userData?.loginId?.trim()) ? 'input-err' : ''}`} value={userData.loginId} onChange={onChangeText} disabled={!!code} />
                </td>
                {/* <th>パスワード有効期限日</th>
                <td>
                  <DateTimePicker
                    onChangeDate={onChangeDate}
                    name="passwordExpirationDate"
                    id="passwordExpirationDate"
                    isRequired={true}
                    date={userData?.passwordExpirationDate ? new Date(userData?.passwordExpirationDate) : null }
                  />
                </td> */}
              </tr>
              {
                partnerId && (
                  <tr>
                    <th>拠点名</th>
                    <td>
                      <select
                        name="branchId"
                        onChange={onChangeText}
                        value={userData?.branchId}
                        id="branchId"
                      >
                        <option value="" selected>----</option>
                        {
                          listBranchMaster ?.map((item, index) => {
                            return (
                              <option value={item.branchId} key={index}>{item.branchName}</option>
                            )
                          })
                        }
                      </select>
                    </td>
                  </tr>
                )
              }
              <tr>
                <th>ログインパスワード</th>
                <td className="inline">
                  <input name="loginPassword" id="loginPassword" className={`input-w50`} value={userData?.loginPassword} onChange={onChangeText} />
                  <button className="btn table__user--btn-pw btn-generate-password" onClick={() => onClickMakePass()}>pw自動生成</button>
                </td>
                <td colSpan="2" />
              </tr>

              <tr className="group-name-input">
                <th>利用者名</th>
                <td>
                  <div className="inline">
                    <label>姓</label>
                    <input id="firstName" name="firstName" value={userData?.firstName} onChange={onChangeText} />
                  </div>
                </td>
                <td>
                  <div className="inline">
                    <label>名</label>
                    <input id="lastName" name="lastName" value={userData?.lastName} onChange={onChangeText} />
                  </div>
                </td>
              </tr>

              <tr className="group-name-input">
                <th>フリガナ</th>
                <td>
                  <div className="inline">
                    <label>セイ</label>
                    <input id="firstNameKana" name="firstNameKana" value={userData?.firstNameKana} onChange={onChangeText} />
                  </div>
                </td>
                <td>
                  <div className="inline">
                    <label>メイ</label>
                    <input id="lastNameKana" name="lastNameKana" value={userData?.lastNameKana} onChange={onChangeText} />
                  </div>
                </td>
              </tr>

              <tr>
                <th>携帯電話番号</th>
                <td>
                  <input name="phoneNumber" value={userData?.phoneNumber} onChange={onChangeText} />
                </td>
                <td colSpan="2"></td>
              </tr>

              <tr>
                <th>メールアドレス</th>
                <td colSpan="3">
                  <div className="group-email-input">
                    <input id="email" name="email" value={userData?.email} onChange={onChangeText} />
                  </div>
                </td>
              </tr>
              <tr>
                <th>権限選択</th>
                <td colSpan="3">
                  <Select
                    value={rolePrimary}
                    name="primaryRole"
                    id="primaryRole"
                    options={options?.primary}
                    className="basic-multi-select mb-1"
                    classNamePrefix="select"
                    onChange={(e) => handleRolePrimary(e)}
                    />
                  <Select
                    value={defaultValues}
                    isMulti
                    name="roles"
                    id="roles"
                    options={options?.secondary}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(e) => handleChange(e)}
                  />
                </td>
              </tr>
                { (typeRole === 'monitor' || role !== NAME_ROLE.monitoring) && (
                  <>
                    <tr>
                      <th>郵便番号</th>
                      <td className="inline">
                        <input
                          value={userData.postNumberFirst}
                          onChange={onChangeText}
                          name="postNumberFirst"
                        />
                        <input
                          value={userData.postNumberLast}
                          onChange={onChangeText}
                          name="postNumberLast"
                        />
                      </td>
                      <th>都道府県</th>
                      <td>
                        <select
                          name="prefectures"
                          value={userData.prefectures}
                          onChange={onChangeText}
                        >
                          {['', ...DEFAULT_ADDRESS].map((item, index) => {
                            return (
                              <option value={item} key={index}>
                                {item}
                              </option>
                            );
                          })}
                        </select>
                      </td>
                    </tr>

                    <tr>
                      <th>住所</th>
                      <td colSpan="3">
                        <AutocompleteAddress
                          name="address"
                          address={userData?.address}
                          onChange={(address) => {
                            setUserData({ ...userData, address });
                          }}
                          onSelectAddress={({ address, latitude, longitude }) => {
                            setUserData({ ...userData, address, latitude, longitude });
                          }}
                        />
                      </td>
                    </tr>

                    <tr>
                      <th>フリガナ</th>
                      <td colSpan="3">
                        <input name="addressKana" value={userData?.addressKana} onChange={onChangeText} />
                      </td>
                    </tr>
                  </>
                )}
                {role !== NAME_ROLE.monitoring && (
                  <>
                    <tr>
                      <th>緯度</th>
                      <td>
                        <input value={userData?.latitude} onChange={onChangeText} name="latitude" id="latitude" />
                      </td>
                      <th>経度</th>
                      <td>
                        <input value={userData?.longitude} onChange={onChangeText} name="longitude" id="longitude"/>
                      </td>
                    </tr>

                    <tr>
                      <th>移動速度</th>
                      <td className="inline">
                        <label>1時間</label>
                        <input type="number" id='movingSpeed'  name="movingSpeed" value={userData?.movingSpeed} onChange={onChangeText} />
                        <label>Km</label>
                      </td>
                      <th>移動距離範囲</th>
                      <td className="inline">
                        <input
                          type="number"
                          id='distanceRange'
                          name="distanceRange"
                          value={userData?.distanceRange}
                          onChange={onChangeText}
                        />
                        <label>Km</label>
                      </td>
                    </tr>
                  </>
                )}
                {role !== NAME_ROLE.monitoring && (
                  <tr>
                    <th>対応エリア設定</th>
                    <td colSpan="3">
                      <AreaSelection
                        activeArea={{ ...userData?.areas }}
                        prefectureAreas={prefectureAreas}
                        onChangePrefectureAreas={onChangePrefectureAreas}
                        onChange={(areas) => { onChangeAreas(areas) }}
                      />
                    </td>
                  </tr>
                )}
            </tbody>
            </table>

            <div className="group-action-user">
              <button
                className="btn btn-submit-user"
                onClick={onCreate}
                disabled={isDisable}
              >
                更新
              </button>
            </div>

          </div>
        </div>
      </div>
      <FooterMaintenance />
    </div>
  );
};

export default RegisterUsers;
