import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import 'assets/scss/page/AnkenMasterNew/ModalAddServiceInProduct.scss';

import { pushToast } from 'components/Toast';
import TableModalService from './TableModalService';
import { ANKEN_MASTER_NEW } from 'constants/endpoints';
import _ from 'lodash';
import { SCREEN_NAME } from '../../../../helpers/constants';

const ModalAddServiceInProduct = (props) => {
  const { toggle = () => { }, modal = false, infoProductModal, createSuccess, nameTitle } = props;
  const [valueSearch, setValueSearch] = useState('');
  const [dataTableService, setDataTableService] = useState([]);
  const changeValueSearch = (e) => {
    setValueSearch(e.target.value)
  };

  useEffect(() => {
    getServiceByOrganization({ valueSearch: '' });
  }, []);

  const getServiceByOrganization = async ({ valueSearch = '' }) => {
    try {
      const data = await ANKEN_MASTER_NEW.GET_SERVICE_BY_ORGANIZATION({ name: valueSearch });
      if (data) {
        const newData = data?.data?.map(item => {
          if (infoProductModal?.serviceSchema?.includes(item?.serviceId)) {
            return {
              ...item, state: true
            }
          } else {
            return {
              ...item, state: false
            }
          }
        });
        // const dataLast = infoProductModal?.service?.map((item) => {
        //   const newItem = {...item, state: true};
        //   newData.push(newItem);
        //   return newData;
        // });
        // setDataTableService(_.orderBy(_.uniqBy(_.flatten(newData), 'serviceId'), 'sortNum'));
        setDataTableService(_.orderBy(_.uniqBy(newData, 'serviceId'), 'sortNum'));
      }

    } catch (e) {
      console.log(e);
    }
  };

  const handleSearch = () => {
    getServiceByOrganization({valueSearch});
  }

  const handleClickBtn = ({ serviceId, state }) => {
    const newList = dataTableService?.map((item) => {
      if (item.serviceId === serviceId) {
        return { ...item, state: !state }
      }
      return item
    });
    setDataTableService(newList)
  };

  const addListService = () => {
    const newList = dataTableService?.filter((item) => item.state === true)?.map((item) => {
      return (item.serviceId);
    });

    const body = {
      serviceIds: newList,
      screenName : SCREEN_NAME.CASE_MASTA,
      updateTitle : `登録 WORKER REPORT ${nameTitle ? nameTitle : ''}`
    }
    handleAddListService({body,productId: infoProductModal.productId })

  };

  const handleAddListService = async ({body, productId}) => {
    try {
      const data = await ANKEN_MASTER_NEW.ADD_SERVICE_IN_PRODUCT({ body, productId });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
      } else {
        pushToast('success', 'Create Product success');
        createSuccess();
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div>
      <Modal className="modal-dialog-custom" isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>工程設定</ModalHeader>
        <ModalBody>
          <div className="form-body-add-service">
            <div className="form-top-modal">
              <div className="form-left">
                <div className="group-input mb-1">
                  <div className="title">プロジェクト名</div>
                  <input
                    type="text"
                    value={infoProductModal.projectName}
                    disabled
                  />
                </div>

                <div className="group-input mb-1">
                  <div className="title">工事名</div>
                  <input
                    type="text"
                    value={infoProductModal.productName}
                    disabled
                  />
                </div>
              </div>

              <div>
                <button
                  onClick={() => addListService()}
                >登録</button>
              </div>
            </div>

          <div className="form-get-code">
            <div className="group-input-search">
              <div className="title">検索条件</div>
              <input
                type="text"
                value={valueSearch}
                onChange={changeValueSearch}
              />
            </div>
            <button
            onClick={()=> handleSearch()}
            >検索</button>

            </div>
            <div className="table-modal-service">
              <TableModalService
                handleClickBtn={handleClickBtn}
                dataTableService={dataTableService}
              />
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ModalAddServiceInProduct;
