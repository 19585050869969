import React, { useState, useEffect } from 'react';
import { TabContent, TabPane } from 'reactstrap';
import TableDiv1 from './TableDiv1';
import TableDiv2 from './TableDiv2';
import { ORDER } from 'constants/endpoints';
import './style.scss';

const TabsTable = ({ dataOrder }) => {
  let organizationId = process.browser && new URL(window.location.href).searchParams.get('organizationId') || '';
  const [activeTab, setActiveTab] = useState('1');
  const [detailReceiveAll, setDetailReceiveAll] = useState('');

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const getDetailReceiveAll = async (orderId) => {
    try {
      const res = await ORDER.GET_DETAIL_RECEIVE_ALL({ orderId , organizationId })
      setDetailReceiveAll({ ...res?.data , timePartnerConfirm : dataOrder?.timePartnerConfirm })
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (dataOrder) getDetailReceiveAll(dataOrder.orderId);
  }, [dataOrder])

  return (
    <div className="tabs-custom">
      <div id="tabs" className="ui-tabs ui-widget ui-widget-content ui-corner-all">
        <div className="" style={{ height: 50 }}>
          <ul className="ui-tabs-nav ui-helper-reset ui-helper-clearfix ui-widget-header ui-corner-all d-flex">
            <li
              className={`ui-state-default ui-corner-top ${ activeTab === '1' && 'active-tab'}`}
              onClick={() => {
                toggle('1');
              }}
            >
              CSV
            </li>
            <li
              className={`ui-state-default ui-corner-top ui-tabs-selected ui-state-active ${ activeTab === '2' && 'active-tab'}`}
              onClick={() => {
                toggle('2');
              }}
            >
              更新
            </li>
          </ul>
        </div>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <TableDiv1 detailReceiveAll={detailReceiveAll} />
          </TabPane>
          <TabPane tabId="2">
            <TableDiv2 detailReceiveAll={detailReceiveAll} />
          </TabPane>
        </TabContent>
      </div>
    </div>
  );
};

export default TabsTable;
