import React, { useState } from 'react';
import RegistrationRadio from '../RegistrationRadio';
import TableProcess from '../TableProcess';
import TableHeaderProcess from '../TableHeaderProcees';
import { PRODUCT_ENDPOINT } from '../../../../../constants/endpoints';
import { pushToast } from '../../../../../components/Toast';

const OrderInformation = (props) => {
  const { history } = props;
  const [selectRegistration, setSelectRegistration] = useState('standard_csv');
  const [dataProcess, setdataProcess] = useState([]);
  const [appointment, setAppointment] = useState(null);
  const productId = (process.browser && new URL(window.location.href).searchParams.get('productId')) || null;

  // const onChangeSchema = (schema) => {
  //   setProductSchema(schema);
  // };

  const onChangeProcess = (process) => {
    setdataProcess(process);
  };

  const onSelect = (item) => {
    setSelectRegistration(item);
  };

  const handleChangeAppointment = (e) => {
    setAppointment(e.target.value);
  };

  const createStep3 = async (body) => {
    try {
      const data = await PRODUCT_ENDPOINT.CREATE_PRODUCT_STEP_THREE(body);
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
      } else {
        history.push(`/modify_anken?modify=4&productId=${productId}`);
        pushToast('success', 'Create step 3 success');
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onClickSubmit = () => {
    if (productId && appointment) {
      createStep3({
        productId: productId,
        // productSchema: productSchema,
        serviceSchema: dataProcess,
        appointmentCategory: appointment
      });
    } else if (!appointment) {
      createStep3({
        productId: productId,
        // productSchema: productSchema,
        serviceSchema: dataProcess
      });
    }

  };

  return (
    <>
      <RegistrationRadio onSelect={onSelect} selectOption={selectRegistration}/>
      {/* <div className="wrapper-column" style={{ fontSize: '14px' }}>
        受注情報
      </div>
      <div className="d-flex h-100">
        <div className="wrapper-column col-2">インポート順</div>
        <div className="wrapper-table">
          {selectRegistration === 'standard_csv' ? (
            <CsvTableStandard onChangeSchema={onChangeSchema}/>
          ) : selectRegistration === 'individual_csv' ? (
            <CsvTableIndividual/>
          ) : selectRegistration === 'manual_input' ? (
            <CsvTableStandard/>
          ) : (
            <></>
          )}
        </div>
      </div> */}
      <div className="d-flex h-100">
        <div className="wrapper-column col-2">工程管理</div>
        <div className="wrapper-table-right">
          <div className="link-process-master">
            <a href="/process_master" target="_blank">
              全案件工程管理
            </a>
          </div>
          <div className="wrapper-process-table">
            <TableProcess onChangeProcess={onChangeProcess}/>
          </div>
        </div>
      </div>
      <div className="d-flex h-100">
        <div className="wrapper-column col-2">施工ワークフロー</div>
        <div className="wrapper-table-right">
          <div className="link-process-master">
            {/* <a href="/process_master" target="_blank">
              全案件工程管理
            </a> */}
          </div>
          <div className="wrapper-process-table">
            <TableHeaderProcess productId = {productId}/>
          </div>
        </div>
      </div>
      <div className="d-flex h-100">
        <div className="wrapper-column col-2">工程管理</div>
        <div className="wrapper-table-right">
          <div onChange={handleChangeAppointment}>
            <div className="form-check form-check-inline">
              <input className="form-check-input" type="radio" name="processRadio" id="centerButton" value="center"/>
              <label className="form-check-label" htmlFor="centerButton">
                センター
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input className="form-check-input" type="radio" name="processRadio" id="partnerButton" value="partner"/>
              <label className="form-check-label" htmlFor="partnerButton">
                パートナー
              </label>
            </div>
          </div>
          <div className="d-flex justify-content-center align-items-center">
            <button className="btn button-anken-submit" onClick={() => onClickSubmit()}>登録</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderInformation;
