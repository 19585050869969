import React, { useEffect, useState } from 'react';
import 'assets/scss/page/Login/style.scss';
import Button from 'reactstrap/es/Button';
import TextField from '@material-ui/core/TextField';
import { login } from '../../../actions/authentication';
import { compose } from 'redux';
import { translate } from 'react-i18next';
import { Redirect, withRouter } from 'react-router-dom';
import { connect, shallowEqual, useSelector } from 'react-redux';
import Loading from '../../../components/Loading/Loading';
import Toast from 'components/Toast';
import _ from 'lodash';
import { MASTER_SETTING } from '../../../constants/endpoints';

const Login = (props) => {
  const { login, history } = props;
  const loading = useSelector((store) => store.utils.loading);
  const userMeData = useSelector((store) => store.authentication.userMe, shallowEqual);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [error, setError] = useState(false);
  const [isLogged, setIsLogged] = useState(false);
  const [htmlString, setHtmlString] = useState('');
  const [loadings,setLoadings] = useState(false);
  const [errHtml,setErrHtml] = useState(true);

  useEffect(() => {
    window.myfunc = handleSubmit;
    return () => (window.myfunc = null);
  }, []);

  const handleSubmit = () => {
    const userLogin = document.getElementById('username')?.value;
    const userPassWord = document.getElementById('password')?.value;
    handleLogin(userLogin, userPassWord);
  };

  useEffect(() => {
    try {
      document.addEventListener('keydown', handleKeyPress, false);
      return () => {
        try {
          document.removeEventListener('keydown', handleKeyPress, false);
          document.getElementById("confirmPassword").style.display = "none";
          document.getElementById("labelConfirm").style.display = "none";
        } catch (e) {
          setErrHtml(false);
        }
      }
    } catch (e) {
      setErrHtml(false);
    }
  }, [htmlString]);

  const handleLogin = (userLogin, userPassWord) => {
    if (userLogin && userPassWord) {
      login({ username: userLogin, password: userPassWord, history });
    } else {
      setIsLogged(true);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      const userLogin = document.getElementById('username')?.value;
      const userPassWord = document.getElementById('password')?.value;
      handleLogin(userLogin, userPassWord);
    }
  };

  const getUiLogin = async () => {
    setLoadings(true);
    try {
      const data = await MASTER_SETTING.GET_UI_LOGIN();
      if (data) {
        setHtmlString(data?.data?.loginHtml);
      }
      setLoadings(false);
    } catch (e) {
      setLoadings(false);
      console.log(e);
    }
  };

  useEffect(() => {
    getUiLogin();
  }, []);

  useEffect(() => {
    if (username.trim() && password.trim()) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [username, password]);

  const handleLoginS = () => {
    if (username && password) {
      login({ username, password, history });
    }
  };

  useEffect(() => {
    if (localStorage?.getItem('IS_LOGIN')) {
      history.push('/select-role');
    }
  }, []);

  return (
    <div>
      {!userMeData && <Redirect to="/login" />}
      <Loading loading={loading || loadings} />
      <Toast />
      {!_.isEmpty(htmlString) && errHtml ? (
        <div dangerouslySetInnerHTML={{ __html: htmlString }} />
      ) : (
        <div id="login_body">
          <div id="login_background_img">
            <div className="inputarea">
              <div className="pcmenu">
                <table className="login_table">
                  <tbody>
                    <tr>
                      <td className="login_td_1">ユーザーID:</td>
                    </tr>
                    <tr>
                      <td className="login_td_2">
                        <TextField
                          error={error}
                          id="username"
                          type="username"
                          placeholder="ユーザーID"
                          className="login_text"
                          onChange={(e) => setUsername(e.target.value)}
                          onKeyPress={(e) => handleKeyPress(e)}
                        />
                        <input type="hidden" name="stella_serial" value="salesdemo" />
                      </td>
                    </tr>
                    <tr>
                      <td className="login_td_1">パスワード:</td>
                    </tr>
                    <tr>
                      <td className="login_td_2">
                        <TextField
                          error={error}
                          id="password"
                          type="password"
                          placeholder="パスワード"
                          className="login_text"
                          onChange={(e) => setPassword(e.target.value)}
                          onKeyPress={(e) => handleKeyPress(e)}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="login_td_1" id="confirmPassword">パスワード:</td>
                    </tr>
                    <tr>
                      <td className="login_td_2">
                        <TextField
                          id="labelConfirm"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="login_td_3">
                        <Button
                          className="loginBtn_btn"
                          variant="contained"
                          size="large"
                          color="secondary"
                          onClick={() => handleLoginS()}
                          disabled={isButtonDisabled}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="login_td_4"></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="spmenu">
                <table className="login_table">
                  <tbody>
                    <tr>
                      <td className="login_td_2">
                        <TextField
                          error={error}
                          id="username"
                          type="username"
                          placeholder="ユーザーID"
                          className="login_text "
                          onChange={(e) => setUsername(e.target.value)}
                          onKeyPress={(e) => handleKeyPress(e)}
                        />
                        <input type="hidden" name="stella_serial" value="salesdemo" />
                      </td>
                      <td rowSpan="2">
                        <Button
                          type="submit"
                          value=""
                          variant="contained"
                          size="large"
                          color="secondary"
                          onClick={() => handleLoginS()}
                          disabled={isButtonDisabled}
                          className="loginBtn_btn"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="login_td_2">
                        <TextField
                          error={error}
                          type="password"
                          className="login_text"
                          placeholder="パスワード"
                          onChange={(e) => setPassword(e.target.value)}
                          onKeyPress={(e) => handleKeyPress(e)}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="login_td_4" colSpan="2"></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const mapActionToProps = {
  login,
};

export default compose(translate('translations'), withRouter, connect(null, mapActionToProps))(Login);
