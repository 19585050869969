export const ACTIONS = {
  USER_LOGIN: 'USER_LOGIN',
  USER_LOG_OUT: 'USER_LOG_OUT',
  SAVE_USER_ME: 'SAVE_USER_ME',
  GET_USER_ME: 'GET_USER_ME',
  SAVE_ROLE_ME: 'SAVE_ROLE_ME',
  GET_ROLE_ME: 'GET_ROLE_ME',
  GET_DATA_HISTORY: 'GET_DATA_HISTORY',
  SAVE_DATA_HISTORY: 'SAVE_DATA_HISTORY',
  GET_DATA_HISTORY_MASTER_SETTING: 'GET_DATA_HISTORY_MASTER_SETTING',
  SAVE_DATA_HISTORY_MASTER_SETTING: 'SAVE_DATA_HISTORY_MASTER_SETTING',
};

export const login = (payload) => {
  return { type: ACTIONS.USER_LOGIN, payload };
};

export const logOut = () => {
  return { type: ACTIONS.USER_LOG_OUT };
};

export const getUserMe = () => {
  return { type: ACTIONS.GET_USER_ME };
};

export const getRoleMe = () => {
  return { type: ACTIONS.GET_ROLE_ME };
};

export const getDataHistory = (payload) => {
  return { type: ACTIONS.GET_DATA_HISTORY, payload };
};

export const getDataHistoryMasterSetting = (payload) => {
  return { type: ACTIONS.GET_DATA_HISTORY_MASTER_SETTING, payload };
};
