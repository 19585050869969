import React, {useState} from "react";
import TableSelectUser from "../../ModifyStaff/TableSelectUser";

const TableInformationPartner = ({data_left , data_right}) => {
    const [itemLeftSelect, setItemLeftSelect] = useState('');
    const [itemRightSelect, setItemRightSelect] = useState('');

    const [dataLeft, setDataLeft] = useState(data_left);
    const [dataRight, setDataRight] = useState(data_right);

    const handleChangeLeft = event => {
        setItemLeftSelect(event.target.value);
    };

    const handleChangeRight = event => {
        setItemRightSelect(event.target.value);
    };

    const onClickInsert = item => {
        if (itemLeftSelect && itemLeftSelect !== '') {
            let newDataRight = [...dataRight];
            newDataRight.push(item);
            setDataRight(newDataRight);

            let newDataLeft = [...dataLeft];
            newDataLeft = newDataLeft.filter(itemleft => itemleft !== item);
            setDataLeft(newDataLeft);
            setItemLeftSelect('');
        }
    };

    const onClickDel = item => {
        if (itemRightSelect && itemRightSelect !== '') {
            let newDataLeft = [...dataLeft];
            newDataLeft.push(item);
            setDataLeft(newDataLeft);

            let newDataRight = [...dataRight];

            newDataRight = newDataRight.filter(itemRight => itemRight !== item);
            setDataRight(newDataRight);
            setItemRightSelect('');
        }
    };
    return (
        <div align="center" className="search">
            <table width="100%" className="formTbl mb20">
                <colgroup>
                    <col width="15%" />
                    <col width="75%" />
                </colgroup>
                <tbody>
                <tr>
                    <th colSpan="2">基本情報（入力必須）</th>
                </tr>
                <tr>
                    <th>パートナー名</th>
                    <td style={{ whiteSpace: 'nowrap' }}>
                        <select name="partner_name0" id="partner_name0">
                            <option value="、">法人格</option>
                            <option value="株式会社、カブシキガイシャ、前" selected="selected">
                                前(株)
                            </option>
                            <option value="株式会社、カブシキガイシャ、後">後(株)</option>
                            <option value="有限会社、ユウゲンガイシャ、前">前(有)</option>
                            <option value="有限会社、ユウゲンガイシャ、後">後(有)</option>
                        </select>
                        <span className="kanaspan">株式会社</span>
                        <input type="hidden" name="partner_name[]" id="partner_name" className="kanaspan" value="株式会社" />
                        <input type="text" value="NEOMARS" className="ajaxkey mid" name="partner_name[]" id="partner_name_v" />
                    </td>
                </tr>
                <tr>
                    <th>パートナー名カナ</th>
                    <td style={{ whiteSpace: 'nowrap' }}>
                        <span className="kanaspan">カブシキガイシャ</span>
                        <input
                            type="hidden"
                            name="partner_name_kana[]"
                            id="partner_name_kana"
                            className="kanaspan"
                            value="カブシキガイシャ"
                        />
                        <input
                            type="text"
                            value="ネオマルス"
                            id="partner_name_kana_v"
                            name="partner_name_kana[]"
                            className="ajaxkey mid toZenKata"
                        />
                    </td>
                </tr>
                <tr>
                    <th>パートナー略称（英数3文字）</th>
                    <td>
                        <input type="text" name="partner_id2" className="ajaxkey min" value="NMC" />
                    </td>
                </tr>
                <tr>
                    <th>パートナーシステム利用フラグ</th>
                    <td>
                        <input type="checkbox" value="1" name="active_flag" id="active_flag1" checked="checked" />
                        <label htmlFor="active_flag1">利用する</label>
                    </td>
                </tr>
                <tr>
                    <th colSpan="2">支払情報</th>
                </tr>
                <tr>
                    <th>代表者名</th>
                    <td>
                        <input type="text" name="representative" value="" className="middle" />
                    </td>
                </tr>
                <tr>
                    <th>フリガナ</th>
                    <td>
                        <input type="text" name="representative_kana" value="" className="toZenKata middle" />
                    </td>
                </tr>
                <tr>
                    <th>郵便番号</th>
                    <td>
                        <input type="text" id="zip1" name="partner_zip1" value="870" className="min" /> -{' '}
                        <input type="text" id="zip2" name="partner_zip2" value="0034" className="min" />
                    </td>
                </tr>
                <tr>
                    <th>都道府県</th>
                    <td>
                        <select name="partner_pref" id="pref" className="">
                            <option value="大分県">大分県</option>
                            <option value=""></option>
                            <option value="北海道">北海道</option>
                            <option value="青森県">青森県</option>
                            <option value="岩手県">岩手県</option>
                            <option value="宮城県">宮城県</option>
                            <option value="秋田県">秋田県</option>
                            <option value="山形県">山形県</option>
                            <option value="福島県">福島県</option>
                            <option value="東京都">東京都</option>
                            <option value="神奈川県">神奈川県</option>
                            <option value="埼玉県">埼玉県</option>
                            <option value="千葉県">千葉県</option>
                            <option value="茨城県">茨城県</option>
                            <option value="栃木県">栃木県</option>
                            <option value="群馬県">群馬県</option>
                            <option value="山梨県">山梨県</option>
                            <option value="新潟県">新潟県</option>
                            <option value="長野県">長野県</option>
                            <option value="石川県">石川県</option>
                            <option value="富山県">富山県</option>
                            <option value="福井県">福井県</option>
                            <option value="愛知県">愛知県</option>
                            <option value="岐阜県">岐阜県</option>
                            <option value="静岡県">静岡県</option>
                            <option value="三重県">三重県</option>
                            <option value="大阪府">大阪府</option>
                            <option value="兵庫県">兵庫県</option>
                            <option value="京都府">京都府</option>
                            <option value="滋賀県">滋賀県</option>
                            <option value="奈良県">奈良県</option>
                            <option value="和歌山県">和歌山県</option>
                            <option value="鳥取県">鳥取県</option>
                            <option value="島根県">島根県</option>
                            <option value="岡山県">岡山県</option>
                            <option value="広島県">広島県</option>
                            <option value="山口県">山口県</option>
                            <option value="徳島県">徳島県</option>
                            <option value="香川県">香川県</option>
                            <option value="愛媛県">愛媛県</option>
                            <option value="高知県">高知県</option>
                            <option value="福岡県">福岡県</option>
                            <option value="佐賀県">佐賀県</option>
                            <option value="長崎県">長崎県</option>
                            <option value="熊本県">熊本県</option>
                            <option value="大分県">大分県</option>
                            <option value="宮崎県">宮崎県</option>
                            <option value="鹿児島県">鹿児島県</option>
                            <option value="沖縄県">沖縄県</option>
                        </select>
                    </td>
                </tr>
                <tr>
                    <th>住所</th>
                    <td>
                        <input type="text" name="partner_address1" value="大分市都町2-1-10 ウォーカービル8F" id="address2" />
                    </td>
                </tr>
                <tr>
                    <th>フリガナ</th>
                    <td>
                        <input
                            type="text"
                            name="partner_address1_kana"
                            value="オオイタシミヤコマチ2-1-10"
                            id="partner_address1_kana"
                            className="toZenKata"
                        />
                    </td>
                </tr>
                <tr>
                    <th>銀行名</th>
                    <td>
                        <input type="text" name="financial_institution" value="" id="financial_institution" />
                    </td>
                </tr>
                <tr>
                    <th>銀行支店</th>
                    <td>
                        <input type="text" name="financial_branch" value="" id="financial_branch" />
                    </td>
                </tr>
                <tr>
                    <th>口座種別・番号</th>
                    <td>
                        <input type="radio" name="financial_account_type" value="1" id="financial_account_type1" />
                        <label htmlFor="financial_account_type1">普通</label>
                        <input type="radio" name="financial_account_type" value="2" id="financial_account_type2" />
                        <label htmlFor="financial_account_type2">当座</label>
                        <input type="text" name="financial_account_code" value="" id="financial_account_code" className="min" />
                    </td>
                </tr>
                <tr>
                    <th>口座名義</th>
                    <td>
                        <input
                            type="text"
                            name="financial_account_hoder"
                            value=""
                            id="financial_account_hoder"
                            className="toZenKata"
                        />
                    </td>
                </tr>
                <tr>
                    <th colSpan="2">稼働可能案件</th>
                </tr>
                <tr>
                    <th>閲覧許可案件</th>
                    <td>
                        <div className="table-right col-10 p-2 d-flex">
                            <div className="wrapper-list-select d-flex w-100">
                                <div className="list-of-cases col-5">
                                    <label>案件一覧</label>
                                    <TableSelectUser data={dataLeft} handleChange={handleChangeLeft} />
                                </div>
                                <div className="col-1 d-flex justify-content-center align-items-center flex-column">
                                    <i
                                        className="fa fa-arrow-circle-right cursor-pointer my-1 btn-add"
                                        onClick={() => onClickInsert(itemLeftSelect)}
                                    />
                                    <i
                                        className="fa fa-arrow-circle-left cursor-pointer my-1 btn-add"
                                        onClick={() => onClickDel(itemRightSelect)}
                                    />
                                </div>
                                <div className="list-of-cases col-5">
                                    <label>閲覧案件</label>
                                    <TableSelectUser data={dataRight} handleChange={handleChangeRight} />
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
            <div align="center" className="pt-4 pb-4">
                <input type="submit" value="更新" className="submitbutton btn btnS btnSuccess" id="insertbtn"/>
            </div>
        </div>
    )
};

export default TableInformationPartner
