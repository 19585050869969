import React from 'react';
import { Link } from 'react-router-dom';
import './style.scss';

const DetailData = ({ data, p = 'receive_info', title='依頼会社' }) => {
  return (
    <div className="detail-data-wrapper">
      <div className="detail-cancel-table-wrapper">
        <div className="title-absolute">{title}</div>
        <table className="conditTab">
          <tbody>
            <tr>
              <th>申込ご担当者連絡先</th>
              <td>{data?.contactPersonInCharge}</td>
            </tr>
            <tr>
              <th>物件名</th>
              <td>{data?.installationSiteName}</td>
              <th>訪問先建物名</th>
              <td>{data?.visitedBuildingName}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DetailData;
