import React from 'react';
import './style.scss';

const FooterMaintenance = () => {
  return (
    <div className="footer-wrapper-main d-flex align-items-center justify-content-center">
      <div className="text-center">
        <p className="p-0 m-0">
          Copyright <i className="icon-copyright" />
          NEOMARS CO.,LTD.All Rights Reserved.
        </p>
      </div>
    </div>
  );
};

export default FooterMaintenance;
