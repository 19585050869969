import React, { useState } from 'react';
import './style.scss';

const data = {
  header: ['基本名', '表示名'],
  body: [
    {
      基本名: '依頼日',
      表示名: ''
    },
    {
      基本名: '管理コード',
      表示名: ''
    }
  ]
};

const renderItem = (item, index, fontSize) => {
  return (
    <tr key={index}>
      <td className="no-wrapper">
        <div className={`d-flex justify-content-center number align-items-center font-weight-bolder ${fontSize}`}>
          {index + 1}
        </div>
      </td>
      {data.header.map((element, key) => {
        return element === '表示名' ? (
          <td style={{ padding: 9 }} key={key}>
            <div className="input-text">
              <input
                className=" p-2 input-text-item"
                type="text"
                value=""
                id={`inlineRadio1${index}`}
                name={`group${index}`}
              />
            </div>
          </td>
        ) : (
          <td style={{ width: '47% ' }} key={key}>
            {item[element]}
          </td>
        );
      })}
    </tr>
  );
};

const TableFieldNameChange = ({ fontSize }) => {
  const [add, setAdd] = useState(data.body);
  const addNew = () => {
    setAdd(preState => [...preState, { 基本名: "追加フィールド", 表示名: "" }]);
  };
  return (
    <div className="table-field-name table-responsive">
      <table className={`table table-striped mb-0 ${fontSize}`}>
        <thead>
          <tr>
            <th scope="col" style={{ width: '30px' }}>
              No
            </th>
            {data.header.map((item, index) => (
              <th scope="col" key={index}>
                {item}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>{add.map((listData, index) => renderItem(listData, index, fontSize))}</tbody>
      </table>
      <div className="d-flex justify-content-center align-items-center group-button ">
        <button onClick={addNew} className={`btn button-submit button-item mr-2 px-4 ${fontSize}`}>
          追加
        </button>
      </div>
      <div className="d-flex justify-content-center align-items-center group-button">
        <button className={`btn button-submit mr-2 px-4 ${fontSize} `}>登録</button>
      </div>
    </div>
  );
};

export default TableFieldNameChange;
