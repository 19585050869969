import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { USER_ENDPOINT } from '../../../../constants/endpoints';
import { pushToast } from '../../../../components/Toast';
import { translate } from 'react-i18next';

const dataChangePass = {
  oldPass: '',
  newPass: '',
  confirmPassword: '',
};

const ModalChangePass = (props) => {
  const { toggle = () => {}, modal = false, t } = props;

  const [data, setData] = useState(dataChangePass);
  const [submit, setSubmit] = useState(true);

  const handleChangeText = (e) => {
    const name = e.target.name;
    let newData = { ...data, [name]: e.target.value };
    setData(newData);
  };

  const onClickSubmit = () => {
    changePass({ oldPassword: data?.oldPass, newPassword: data?.newPass, confirmPassword: data?.confirmPassword });
  };

  const changePass = async ({ oldPassword = '', newPassword = '', confirmPassword = '' }) => {
    try {
      const data = await USER_ENDPOINT.CHANGE_PASS({ oldPassword, newPassword, confirmPassword });
      if (data && data.code !== 0) {
        pushToast('error', data?.message?.errorMessage);
      } else {
        pushToast('success', 'Change pass success');
        setData(dataChangePass)
        toggle();
      }
    } catch (errorMessage) {
      console.log('errorMessage: ', errorMessage);
    }
  };

  useEffect(() => {
    if (
      data?.oldPass?.length > 0 &&
      data?.newPass?.length > 0 &&
      data?.confirmPassword?.length > 0
    ) {
      setSubmit(false);
    } else setSubmit(true);
  }, [data]);

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>パスワード変更</ModalHeader>
        <ModalBody>
          <table className="table--w100">
            <tbody>
              <tr>
                <td>
                  <label>旧パスワード</label>
                </td>
                <td className="pl-3 table--pdb10">
                  <input className="form-control form--border-bl" name="oldPass" onChange={handleChangeText} type="passWord" />
                </td>
              </tr>
              <tr>
                <td>
                  <label>新パスワード</label>
                </td>
                <td className="pl-3 table--pdb10">
                  <input className="form-control form--border-bl" name="newPass" onChange={handleChangeText} type="passWord" />
                </td>
              </tr>
              <tr>
                <td>
                  <label>パスワード確認</label>
                </td>
                <td className="pl-3 table--pdb10">
                  <input className="form-control form--border-bl" name="confirmPassword" onChange={handleChangeText} type="passWord" />
                </td>
              </tr>
            </tbody>
          </table>
          <div className="d-flex justify-content-center align-items-center mt-2">
            <button className="btn btn-primary" onClick={() => onClickSubmit()} disabled={submit}>
              保存
            </button>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default translate('translations')(ModalChangePass);
