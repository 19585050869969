import React, { useEffect, useState } from 'react';
import { translate } from 'react-i18next';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import Header from 'components/Header';
import Footer from 'components/Footer';
import { ORDER } from 'constants/endpoints';
import { getRole } from 'helpers/helpers';
import 'assets/scss/page/DetailListOrder/style.scss';
import DetailData from 'components/DetailCenter/DetailData';
import DetailDataList from 'components/DetailCenter/DetailDataList';
import DetailOrder from 'components/DetailCenter/DetailOrder';
import DetailTreeDocumentSetting from 'components/DetailTreeDocumentSetting';
import DetailListAllData from 'components/DetailCenter/DetailListAllData';
import { SCREEN_NAME } from 'helpers/constants';
import Loading from 'components/Loading/Loading';
import TabsTable from '../ReceiveAllEdit/TabsTable';
import { useSelector } from 'react-redux';
import HeaderMasterSetting from 'components/HeaderMasterSetting';

const DetailListOrder = () => {
  const [role, setRole] = React.useState(null);
  const [idRole, setIdRole] = React.useState(null);
  let orderId = process.browser && new URL(window.location.href).searchParams.get('orderId');
  let organizationId = process.browser && new URL(window.location.href).searchParams.get('organizationId');
  let back = process.browser && new URL(window.location.href).searchParams.get('back') || '';
  const colorScreen = useSelector((store) => store.utils.colorMe);
  const [datas, setDatas] = useState('');
  const [extraData, setExtraData] = useState('');
  const [loading, setLoading] = useState(false);
  const [listDetailScreen, setListDetailScreen] = useState([]);

  useEffect(() => {
    getListRoleChild();
  }, []);

  const getListRoleChild = async () => {
    const newRole = localStorage.getItem('role');
    try {
      const data = await ORDER.GET_LIST_DETAIL_SETTING({
        arrRole: newRole,
        nameScreen: 'receive_all',
        orderId,
        organizationId
      });
      if (data) {
        setListDetailScreen(data?.data?.detailScreen);
        // setListDetailScreen(data?.data?.detailScreen?.concat({ name: 'documentSetting', sortNum: 99, status: true }));
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    const newRole = localStorage.getItem('role');
    setIdRole(newRole);
    if (newRole) {
      setRole(getRole({ idRole: newRole }));
    }
  });

  const getOrderDetail = async ({ orderId = '' }) => {
    try {
      setLoading(true);
      const data = await ORDER.GET_STATUS_BEFORE_VISIT({ orderId });
      if (data) {
        setExtraData(data?.data?.workerAllocation?.extraData?.option1);
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const getOrderDetailss = async ({ orderId = '' }) => {
    try {
      setLoading(true);
      const data = await ORDER.GET_DETAIL_CANCEL_INFO({ orderId, screenName: 'receive_all', organizationId });
      if (data) {
        setDatas(data?.data);
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  useEffect(() => {
    getOrderDetail({ orderId });
    getOrderDetailss({ orderId });
  }, [orderId]);

  return (
    <div className="wrapper-detail-cancel-info-custom">
      <Loading loading={loading} />
      <div className="main">
        {back === 'confirm' ? <HeaderMasterSetting idRole={idRole} color={colorScreen?.code} colorText={colorScreen?.colorText} /> : <Header idRole={idRole} color={colorScreen?.code} p="receive_all" colorText={colorScreen?.colorText} />}
        <div className="body-wrapper">
          <div className="logout_btn">
          <Link to={`${back === 'confirm' ? '/confirm-page' : back === 'mypage' ? '/my_page' : `list?p=receive_all`}`}>
              <span className="mypage_ab_custom">戻る</span>
            </Link>
          </div>
          {listDetailScreen?.map((item, index) => {
            if (item.status) {
              switch (item.name) {
                case 'clientCompany':
                  return <DetailOrder data={datas} p={'tomorrow_info'} key={index} />;
                case 'applicationCompany':
                  return <DetailData data={datas} p={'tomorrow_info'} link={false} key={index} />;
                case 'allInfo':
                  return (
                    <div className="detail-cancel-table-wrapper" key={index}>
                      <div className="title-absolute">全情報</div>
                      <div className="wrapper_main_list wrapper--unset-height">
                        <div className="list">
                          <TabsTable dataOrder={datas} />
                        </div>
                      </div>
                    </div>
                  );
                case 'detailInfo':
                  return <DetailListAllData data={datas} screenName={SCREEN_NAME.RECEIVE_ALL} key={index} />;
                case 'document':
                  return (
                    <DetailTreeDocumentSetting orderId={orderId} order={datas} key={index} />
                  );
                case 'history':
                  return (
                    <DetailDataList
                      extraData={extraData}
                      role={role}
                      orderId={orderId}
                      datePick={false}
                      screenName={SCREEN_NAME.RECEIVE_ALL}
                      key={index}
                    />
                  );
                default:
                  return '';
              }
            }
          })}
        </div>
        <Footer color={colorScreen?.code} colorText={colorScreen?.colorText} />
      </div>
    </div>
  );
};
export default compose(translate('translations'))(DetailListOrder);
