import React, { useEffect, useState } from 'react';
import FooterMaintenance from 'components/FooterMaintenance';
import DatePicker from 'react-datepicker';
import TableDispatch from './TableDispatch';
import moment from 'moment';
// import { pushToast } from 'components/Toast';
import { DISPATCH } from 'constants/endpoints';
import Loading from 'components/Loading/Loading';
import Header from 'components/Header';
import { DATE_JP_FORMAT } from 'helpers/constants';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import 'assets/scss/page/Dispatch/style.scss';
import { useSelector } from 'react-redux';
import MemoHistory from '../../../components/MemoHistory';

const Dispatch = () => {
  const organizationId = localStorage.getItem('idProvide');
  const colorScreen = useSelector((store) => store.utils.colorMe);
  let orderId = (process.browser && new URL(window.location.href).searchParams.get('orderId')) || '';
  let visitDate = (process.browser && new URL(window.location.href).searchParams.get('visitDate')) || '';

  const [idRole, setIdRole] = useState('');
  const [dateSelect, setDateSelect] = useState((visitDate === 'undefined' || _.isEmpty(visitDate)) ? moment(new Date()).format(DATE_JP_FORMAT) : visitDate);
  const [dataWorkingSchedule, setDataWorkingSchedule] = useState([]);
  const [loading, setLoading] = useState(false);
  const [taskDescription, setTaskDescription] = useState({});
  const [taskActive, setTaskActive] = useState({});
  const history = useHistory();

  useEffect(() => {
    setDateSelect((visitDate === 'undefined' || _.isEmpty(visitDate)) ? moment(new Date()).format(DATE_JP_FORMAT) : visitDate);
    const newRole = localStorage.getItem('role');
    setIdRole(newRole);
  }, []);


  useEffect(() => {
    handleGetListWorkerSchedule({
      visitDate: dateSelect,
      organizationId,
      orderId
    })
  }, [dateSelect]);

  const handleGetListWorkerSchedule = async ({ visitDate, organizationId, orderId }) => {
    setLoading(true);
    try {
      const data = await DISPATCH.GET_WORKING_SCHEDULE({ visitDate, organizationId, orderId });
      if (data) {
        setDataWorkingSchedule(data ?.data?.products);
        setLoading(false);
      }
    } catch (errorMessage) {
      console.log('errorMessage: ', errorMessage);
      setLoading(false);
    }
  };

  const changeDate = (date) => {
    setDateSelect(moment(new Date(date)).format(DATE_JP_FORMAT));
  };

  const handleReload = () => {
    handleGetListWorkerSchedule({
      visitDate: dateSelect,
      organizationId,
      orderId
    })
  };

  const pushDescription = ({ data, taskId, workTime }) => {
    setTaskDescription(data);
    setTaskActive({taskId, workTime})
  };

  const handleLinkBack = () => {
    history.push(
      {
        pathname: '/dispatch-all',
        state: {visitDate}
      }
    )
  };

  const address = `${taskDescription?.prefecturesVisited || ''} - ${taskDescription?.visitedBaseAddress || ''} - ${taskDescription?.visitedBuildingName || ''}`

  return (
    <div className="wrapper-dispatch">
      <Loading loading={loading} />
      <Header p= 'condition_info'  idRole={idRole} color={colorScreen?.code} colorText={colorScreen?.colorText} />
      <div className="body pb-4">
        <div className="wrapper-main-dispatch">
          <div className="logout_btn">
            <span className="mypage_ab_custom" onClick={handleLinkBack}>戻る</span>
          </div>
          <div className='form-select-date'>

            <div className="form-input-and-reload">
              <DatePicker
                style={{ backgroundColor: `${colorScreen.code}` }}
                onChange={(date) => changeDate(date)}
                selected={new Date(dateSelect)}
                locale="ja"
                dateFormat="yyyy/MM/dd"
                dateFormatCalendar="yyyy年 MM月"
                disabled
              />
              <div className="button-reload"
                onClick={() => handleReload()}
              >
                <i className="fas fa-sync-alt"></i>
              </div>
            </div>
          </div>

          {
            !_.isEmpty(taskDescription) ?
            <div className="form-description">
              <div className="row-description">
                <div className="item-description flex-fill">
                  <div className="label-form">案件名</div>
                  <div className="content-form">{taskDescription?.projectTitle}</div>
                </div>

                <div className="item-description flex-fill">
                  <div className="label-form">管理 IＤ</div>
                  <div className="content-form">{taskDescription?.managementCode}</div>
                </div>

                <div className="item-description flex-fill">
                  <div className="label-form">物件名</div>
                  <div className="content-form">{taskDescription?.installationSiteName}</div>
                </div>

                <div className="item-description flex-fill">
                  <div className="label-form">訪問時間</div>
                  <div className="content-form">{`${taskDescription?.visitDateFrom || ''} ~ ${taskDescription?.visitDateTo || ''}`}</div>
                </div>

                <div className="item-description flex-fill">
                  <div className="label-form">所要時間</div>
                    <div className="content-form">{taskDescription?.workTimeProductService}</div>
                </div>
              </div>


              <div className="row-description">
                <div className="item-description flex-fill">
                  <div className="label-form">場所</div>
                  <div className="content-form">{address}</div>
                </div>

                <div className="item-description flex-fill">
                  <div className="label-form">マンション名</div>
                  <div className="content-form">{taskDescription?.visitedBuildingName}</div>
                </div>

                <div className="item-description flex-fill">
                  <div className="label-form">施工区分</div>
                  <div className="content-form">{taskDescription?.constructionClassification}</div>
                </div>

                <div className="item-description flex-fill">
                  <div className="label-form">作業内容</div>
                  <div className="content-form">{_.compact(Object.values(taskDescription?.options || [])).toString()}</div>
                </div>
              </div>
            <div className="row-description">
              <div className="item-description flex-fill">
                <div className="label-form">申し送り事項</div>
                <div className="content-form">{taskDescription?.notificationItems}</div>
              </div>
            </div>
          </div> : <div className="form-description"></div>
          }

          <TableDispatch
            dataWorkingSchedule={dataWorkingSchedule?.[0]}
            organizationId={organizationId}
            visitDate={dateSelect}
            orderId={orderId}
            onReload={handleReload}
            pushDescription={pushDescription}
            taskActive={taskActive}
          />
        </div>
      </div>
      <MemoHistory colors={colorScreen?.code} colorText={colorScreen?.colorText} visitDate={visitDate}/>
      <FooterMaintenance />
    </div>
  );
};

export default Dispatch;
