import React from 'react';
import { compose } from 'redux';
import ButtonMasterSetting from '../ButtonMasterSetting';
import { translate } from 'react-i18next';
import { PERMISSION } from '../../../helpers/constants';

const MonitoringMenu = ({ idRole, t , colorText }) => {
  return (
    <>
      {
        idRole && PERMISSION.MONITORING_GROUP_CONTRACT_MANAGER.includes(idRole) && (
          <>
            <div className="page_icon">
              <div className="page_name page_name--nowrap" style = {{ color : `${colorText}` }}>{t('menu_partner.my_corp')}</div>
              <ButtonMasterSetting
                link="/master-setting"
                title="Master Setting"
                subTitle="集計表"
                color="dark-blue"
                icon="icon-list-alt"
              />
            </div>
            <div className="page_icon">
              <div className="page_name page_name--nowrap" style = {{ color : `${colorText}` }}>{t('menu_partner.user_master')}</div>
              <ButtonMasterSetting
                title="USER MASTER"
                subTitle="ユーザー"
                color="dark-blue"
                icon="icon-user"
                link="/users"
              />
            </div>
            <div className="page_icon" style = {{display : 'none'}}>
              <div className="page_name page_name--nowrap" style = {{ color : `${colorText}` }}>{t('menu_partner.accounting')}</div>
              <ButtonMasterSetting
                link="/accounting"
                title="accounting"
                subTitle="accounting"
                color="dark-blue"
                icon="icon-list-alt"
              />
            </div>
            <div className="page_icon">
              <div className="page_name page_name--nowrap" style = {{ color : `${colorText}` }}>{t('menu_partner.contract')}</div>
              <ButtonMasterSetting
                link="/contract"
                title="契約管理"
                subTitle="契約管理"
                color="dark-blue"
                icon="icon-list-alt"
              />
            </div>
            <div className="page_icon">
              <div className="page_name page_name--nowrap" style = {{ color : `${colorText}` }}>{t('menu_partner.csv_master')}</div>
              <ButtonMasterSetting
                title="USER MASTER"
                subTitle="ディスパッチ"
                color="dark-blue"
                icon="icon-file-excel"
                link="/csv-master"
              />
            </div>
          </>
        )
      }
      {
        idRole && PERMISSION.MONITORING_GROUP_OPERATION_MANAGER.includes(idRole) && (
          <div className="page_icon">
            <div className="page_name page_name--nowrap" style = {{ color : `${colorText}` }}>{t('menu_partner.user_master')}</div>
            <ButtonMasterSetting
              title="USER MASTER"
              subTitle="ユーザー"
              color="dark-blue"
              icon="icon-user"
              link="/users"
            />
          </div>
        )
      }
    </>
  );
};

export default compose(translate('translations'))(MonitoringMenu);
