import React from 'react';
import './style.scss'
const data = {
  header: ['更新者', '更新日', '更新区分', '備考'],
  body: [
    {
      更新者: 'SYSTEM',
      更新日: '2020/04/13 11:03:02',
      更新区分: '新規作成',
      備考: '【client_pref】→東京都【client_address1】→新宿【client_address1_kana】→シンジュク【client_zip】-→000-0000'
    },
    {
      更新者: 'SYSTEM',
      更新日: '2020/04/13 11:03:02',
      更新区分: '新規作成',
      備考: '【client_pref】→東京都【client_address1】→新宿【client_address1_kana】→シンジュク【client_zip】-→000-0000'
    }
  ]
};

const renderItem = (item, index) => {
  return (
    <tr key={index}>
      {data.header.map((element, key) => {
        return (
          <>
            <td style={{ fontSize: 12 }} key={key}>
              {item[element]}
            </td>
          </>
        );
      })}
    </tr>
  );
};

const TableUpdate = () => {
  return (
    <div id="Table_Update" className="mt-2">
      <div className="text-banner text-center">
        <span className="font-weight-bolder " >更新履歴</span>
      </div>
      <table className={`listTbl `}>
        <thead>
          <tr>
            {data.header.map((item, index) => (
              <th scope="col" key={index} style={{ fontSize: 12 }}>
                {item}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>{data.body.map((listData, index) => renderItem(listData, index))}</tbody>
      </table>
    </div>
  );
};

export default TableUpdate;
