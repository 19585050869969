import React, { useEffect, useState } from 'react';
import Footer from 'components/Footer';
import Header from 'components/Header';
import { useSelector } from 'react-redux';
import { ORDER } from 'constants/endpoints';
import ProductList from './ProductList';
import PaginationCustom from 'components/PaginationCustom';
import { ROLE } from 'helpers/constants';
import Loading from 'components/Loading/Loading';
import SearchMenu from 'components/SearchMenu';
import _ from 'lodash';

const Recreate = (props) => {
  const colorScreen = useSelector((store) => store.utils.colorMe);
  const search = useSelector((store) => store.utils.paramSearch);
  const [idRole, setIdRole] = useState('');
  const [listProduct, setListProduct] = useState([]);
  const [activePage, setActivePage] = React.useState(1);
  const [isShow, setIsShow] = useState('');
  const [loading, setLoading] = useState(false);
  const [isShowAll, setIsShowAll] = useState(false);
  const [count, setCount] = useState(0);

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    getListReNew({ pageProduct: pageNumber });
  };

  const showAll = (status) => {
    setIsShowAll(status);
  };

  useEffect(() => {
    getListReNew({});
  }, [isShowAll])

  const paginationDefault = {
    page: 1, limit: 10
  }

  const getListReNew = async ({
    page = paginationDefault.page,
    limit = paginationDefault.limit,
    pageProduct = paginationDefault.page,
    limitProduct = paginationDefault.limit,
    productId = '',
    productName = '',
    caseName = '',
    managementCode = '',
    serviceName = '',
    requestedDateTo = '',
    requestedDateFrom = '',
    operationAdjustmentDateTo = '',
    operationAdjustmentDateFrom = '',
    applicantCompanyName = '',
    resultsPreparation = '',
    visitDateTo = '',
    visitDateFrom = '',
    visitedBaseName = '',
    partnerName = '',
  }) => {
    setLoading(true);
    try {
      const data = await ORDER.GET_LIST_RENEW({
        page: page,
        limit: limit,
        pageProduct: isShowAll ? 0 : pageProduct,
        limitProduct: limitProduct,
        productName: productName,
        caseName: caseName,
        managementCode: managementCode,
        serviceName: serviceName,
        productId: productId,
        requestedDateTo: requestedDateTo,
        requestedDateFrom: requestedDateFrom,
        operationAdjustmentDateTo: operationAdjustmentDateTo,
        operationAdjustmentDateFrom: operationAdjustmentDateFrom,
        applicantCompanyName: applicantCompanyName,
        resultsPreparation: resultsPreparation,
        visitDateTo: visitDateTo,
        visitDateFrom: visitDateFrom,
        visitedBaseName: visitedBaseName,
        partnerName: partnerName,
      });
      if(data) {
        setLoading(false);
        setListProduct(data?.data?.products);
        setCount(data?.data?.count || 0);
      }
    } catch (e) {
      setLoading(true);
      console.log(e);
    }
  };

  const handleCollap = (key) => {
    if (isShow === key) {
      setIsShow('');
    } else {
      setIsShow(key);
    }
  }

  useEffect(() => {
    getListReNew({});
    if(localStorage.getItem('role')) {
      setIdRole(localStorage.getItem('role'));
    }
  }, []);

  useEffect(() => {
    getListReNew(search);
  }, [search]);

  return (
    <div className="wrapper-master-setting">
      <Loading loading={loading} />
      <Header p={"receive_info"} idRole={idRole} color={colorScreen?.code} colorText={colorScreen?.colorText} showAll={showAll} />
      {
        <>
          <div className="body pb-4">
            <SearchMenu role={ROLE.PARTNERGROUP} color={colorScreen?.code} colorText={colorScreen?.colorText} />
            <div className="wrapper-main">
              <div className="wrapper-result">
                <div className="result-title">
                </div>
              </div>
              {
                listProduct?.map((item, index) => {
                  return <ProductList isShowAll={isShowAll} key={index} isShow={isShow} colors={colorScreen?.code} colorText={colorScreen?.colorText} listProduct={listProduct} item={item} handleCollap={handleCollap} />
                })
              }
              <div className="d-flex justify-content-center align-items-center group-button"></div>
            </div>
            {
              !_.isEmpty(listProduct) && count > paginationDefault.limit && (
                <PaginationCustom
                activePage={activePage}
                itemsCountPerPage={paginationDefault.limit}
                totalItemsCount={count}
                pageRangeDisplayed={5}
                handlePageChange={handlePageChange} />
              )
            }
          </div>
        </>
      }
      <Footer color={colorScreen?.code} colorText={colorScreen?.colorText} />
    </div>
  );
};

export default Recreate;
