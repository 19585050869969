import React, { useState } from 'react';
import { Collapse } from 'reactstrap';
import 'react-datepicker/dist/react-datepicker.css';
import 'assets/scss/page/center/Users/style.scss';
import { USER_ENDPOINT } from 'constants/endpoints';
import PaginationCustom from 'components/PaginationCustom';
import DetailUsers from '../DetailUsers';
import Loading from 'components/Loading/Loading';

const ManagerUsers = ({colors, colorText, isShow, handleCollap, val, k, count = 0}) => {
  const [dataUsers, setDataUsers] = useState(val);
  const toggle = () => handleCollap(k);
  const [activePage, setActivePage] = useState(1);
  const [isShowChild, setIsShowChild] = useState('');
  const [loading, setLoading] = useState(false);

  const handleCollapChild = (key) => {
    if (isShowChild === key) {
      setIsShowChild('');
    } else {
      setIsShowChild(key);
    }
  }

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    getDataTables({page: pageNumber, type: k});
  };

  const getDataTables = async ({
      page = 1, limit = 10, pageUser = 1, limitUser = 10, type = '', permissionId = ''
    }) => {
    try {
      setLoading(true);
      const data = await USER_ENDPOINT.GET_LIST_USER_PARTNER({
        page, limit, pageUser, limitUser, type, permissionId
      });
      if (data) {
        setLoading(false);
        setDataUsers(data?.data?.[k] || [])
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  return (
    <div className="setting-master-menu-wrapper">
      <Loading loading={loading} />
      <div className="wrapper-btn-colappse">
        <div onClick={toggle} className="mb-1 searchbar d-flex justify-content-between align-items-center" style = {{background :`${colors}`}}>
          <div className="ml-2" style={{ color: `${colorText}` }}>{k === 'ERP' ? 'ERP：契約企業' : k === 'Partner' ? 'Partner：協力企業' : k === 'Client' ? 'Client：発注企業' : k}</div>
          <div className="mr-2" style={{ color: `${colorText}` }}>
            {isShow === k ? <i className="icon-up-open icon-searchbar" /> : <i className="icon-down-open icon-searchbar" />}
          </div>
        </div>
      </div>
      <Collapse isOpen={isShow === k}>
        {
          dataUsers.map((item, index) => {
            return (
              <DetailUsers listUser={item} index={index} colors={colors} key={index} prePage={activePage}
                colorText={colorText} k={k} handleCollapChild={handleCollapChild} isShowChild={isShowChild} />
            )
          })
        }
        {
          count > 10 && (
            <PaginationCustom
              activePage={activePage}
              itemsCountPerPage={10}
              totalItemsCount={count}
              pageRangeDisplayed={5}
              handlePageChange={handlePageChange} />
          )
        }
      </Collapse>
    </div>
  );
};

export default ManagerUsers;
