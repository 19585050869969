import React, { useState, useEffect } from 'react';
import './style.scss';
import { BRANCH_MASTER_ENDPOINT } from '../../../constants/endpoints';
import { pushToast } from '../../../components/Toast';
import TableUpdateStaff from './TableUpdateStaff';
import { useSelector } from 'react-redux';
import HeaderMasterSetting from '../../../components/HeaderMasterSetting';
import { generatePassword } from 'helpers/helpers';
import FooterMaintenance from "components/FooterMaintenance"

const ModifyBranchStaff = (props) => {
  const [staffData, setStaffData] = useState({});
  const [checkBtn, setCheckBtn] = useState(true);
  const [checkForm, setCheckForm] = useState(false);
  const [idStaff, setIdStaff] = useState(null);
  const [phoneNumber1, setPhoneNumber1] = useState('');
  const [phoneNumber2, setPhoneNumber2] = useState('');
  const [phoneNumber3, setPhoneNumber3] = useState('');
  const [idBranch, setIdBranch] = useState(null);
  const [dataLogStaff, setDataLogStaff] = useState([]);
  const colorScreen = useSelector((store) => store.utils.colorMe);
  useEffect(() => {
    let staffId = (process.browser && new URL(window.location.href).searchParams.get('sid')) || null;
    let branchId = (process.browser && new URL(window.location.href).searchParams.get('branchId')) || null;
    setIdStaff(staffId);
    setIdBranch(branchId);
    if (staffId) {
      getDetailStaff(staffId);
      getListLogUpdate(staffId);
    }
  }, []);

  const getListLogUpdate = async (staffId) => {
    try {
      const data = await BRANCH_MASTER_ENDPOINT.GET_LIST_LOG_UPDATE_STAFF({ staffId });
      if (data) {
        setDataLogStaff(data?.data);
      }
    } catch (errorMessage) {
      console.log('errorMessage: ', errorMessage);
    }
  };

  const getDetailStaff = async (staffId) => {
    try {
      const data = await BRANCH_MASTER_ENDPOINT.GET_DETAIL_BRANCH_STAFF({ staffId });
      if (data && data?.code === 0) {
        setStaffData(data?.data);
        setPhoneNumber1(data?.data.userContact.split('-')[0] || '');
        setPhoneNumber2(data?.data.userContact.split('-')[1] || '');
        setPhoneNumber3(data?.data.userContact.split('-')[2] || '');
        const senseStatus = data?.data?.suspensionStatus;
        if (senseStatus && staffId) {
          setCheckForm(true);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    const keyRequired = [
      'loginId',
      'loginPassword',
      'userFirstNameKana',
      'userLastNameKana',
      'userFirstName',
      'userLastName',
      'userEmail',
    ];
    for (let i = 0; i < keyRequired.length; i++) {
      if (!Object.keys(staffData).includes(keyRequired[i])) {
        setCheckBtn(true);
        break;
      } else {
        setCheckBtn(false);
      }
    }
  }, [staffData]);

  const onClickMakePass = () => {
    let newPass = generatePassword();
    let newStaffData = { ...staffData };
    newStaffData = { ...newStaffData, loginPassword: newPass };
    setStaffData(newStaffData);
  };

  const handleChangeText = (e) => {
    let name = e.target.name;
    let newStaffData = { ...staffData };
    if (e.target.value) {
      newStaffData = { ...newStaffData, [name]: e.target.value };
    } else {
      delete newStaffData[name];
    }
    setStaffData(newStaffData);
  };

  const createStaff = async (body) => {
    try {
      const data = await BRANCH_MASTER_ENDPOINT.CREATE_STAFF_BRANCH_MASTER(body);
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
      } else {
        pushToast('success', 'Create Staff success');
        props.history.push(`branch-staff-master?branchId=${idBranch}`);
      }
    } catch (errorMessage) {
      console.log('errorMessage: ', errorMessage);
    }
  };

  const updateStaff = async (body) => {
    try {
      const data = await BRANCH_MASTER_ENDPOINT.UPDATE_BRANCH_STAFF({ body, id: idStaff });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
      } else {
        pushToast('success', 'Update Staff success');
        getDetailStaff(idStaff);
        getListLogUpdate(idStaff);
      }
    } catch (errorMessage) {
      console.log('errorMessage: ', errorMessage);
    }
  };

  const handleSubmit = () => {
    if (idStaff) {
      const data = {
        branchId: staffData.branchId,
        loginId: staffData.loginId,
        loginPassword: staffData.loginPassword,
        userFirstNameKana: staffData.userFirstNameKana,
        userLastNameKana: staffData.userLastNameKana,
        userFirstName: staffData.userFirstName,
        userLastName: staffData.userLastName,
        userContact: staffData.userContact,
        userEmail: staffData.userEmail,
        suspensionStatus: staffData.suspensionStatus,
      };
      updateStaff(data);
    } else {
      const data = {
        ...staffData,
        branchId: idBranch,
      };
      createStaff(data);
    }
  };

  const handleChangeContact = (e) => {
    if (e.target.name === 'phoneNumber1') {
      setPhoneNumber1(e.target.value);
      let newStaffData = { ...staffData };
      newStaffData = { ...newStaffData, userContact: e.target.value + '-' + phoneNumber2 + '-' + phoneNumber3 };
      setStaffData(newStaffData);
    } else if (e.target.name === 'phoneNumber2') {
      setPhoneNumber2(e.target.value);
      let newStaffData = { ...staffData };
      newStaffData = { ...newStaffData, userContact: phoneNumber1 + '-' + e.target.value + '-' + phoneNumber3 };
      setStaffData(newStaffData);
    } else if (e.target.name === 'phoneNumber3') {
      setPhoneNumber3(e.target.value);
      let newStaffData = { ...staffData };
      newStaffData = { ...newStaffData, userContact: phoneNumber1 + '-' + phoneNumber2 + '-' + e.target.value };
      setStaffData(newStaffData);
    }
  };

  const checkSense = () => {
    const result = window.confirm('終了処理してもよろしいですか？');
    if (result) {
      let newStaffData = { ...staffData };
      newStaffData = { ...newStaffData, suspensionStatus: true };
      setStaffData(newStaffData);
      const data = {
        branchId: staffData.branchId,
        loginId: staffData.loginId,
        loginPassword: staffData.loginPassword,
        userFirstNameKana: staffData.userFirstNameKana,
        userLastNameKana: staffData.userLastNameKana,
        userFirstName: staffData.userFirstName,
        userLastName: staffData.userLastName,
        userContact: staffData.userContact,
        userEmail: staffData.userEmail,
        suspensionStatus: true,
      };
      updateStaff(data);
    }
  };

  return (
    <div className="main-modify-branch-staff-master">
      <HeaderMasterSetting checkName={'/branch-master'} color={colorScreen?.code} colorText={colorScreen?.colorText} />
      <div className="body-wrapper mb-1">
        <div align="center" className="search mb-3">
          <div align="center" className="title">
            <p>ユーザー詳細</p>
          </div>
          <div>
            <table
              border="0"
              align="center"
              cellPadding="1"
              cellSpacing="1"
              id="result"
              className="table-modify-branch-staff"
            >
              <colgroup>
                <col width="30%" />
                <col width="35%" />
                <col width="35%" />
              </colgroup>
              <tbody>
                <tr>
                  <th scope="row">拠点名 </th>
                  <td colSpan="2">ＢＡＰ東京</td>
                </tr>

                <tr>
                  <th>利用者ID</th>
                  <td colSpan="2">
                    <input
                      type="text"
                      className={`validate required full long ${
                        staffData.hasOwnProperty('loginId') ? 'check-ok' : 'check'
                      }`}
                      name="loginId"
                      onChange={handleChangeText}
                      value={staffData.loginId}
                      disabled={checkForm}
                    />
                  </td>
                </tr>

                <tr>
                  <th>ログインパスワード</th>
                  <td colSpan="2">
                    <input
                      type="text"
                      className={`validate required full long ${
                        staffData.hasOwnProperty('loginPassword') ? 'check-ok' : 'check'
                      }`}
                      name="loginPassword"
                      value={staffData.loginPassword}
                      onChange={handleChangeText}
                      disabled={checkForm}
                    />

                    <input
                      type="button"
                      className="mailbtn ml-2"
                      value="pw自動生成"
                      disabled={checkForm}
                      onClick={() => onClickMakePass()}
                    />
                  </td>
                </tr>

                <tr>
                  <th>フリガナ</th>
                  <td>
                    {' '}
                    セイ：
                    <input
                      type="text"
                      className={`validate required full short ${
                        staffData.hasOwnProperty('userLastNameKana') ? 'check-ok' : 'check'
                      }`}
                      name="userLastNameKana"
                      onChange={handleChangeText}
                      value={staffData.userLastNameKana}
                      disabled={checkForm}
                    />
                  </td>

                  <td>
                    {' '}
                    メイ：
                    <input
                      type="text"
                      className={`validate required full short ${
                        staffData.hasOwnProperty('userFirstNameKana') ? 'check-ok' : 'check'
                      }`}
                      name="userFirstNameKana"
                      onChange={handleChangeText}
                      value={staffData.userFirstNameKana}
                      disabled={checkForm}
                    />
                  </td>
                </tr>

                <tr>
                  <th>利用者名</th>
                  <td>
                    {' '}
                    姓：
                    <input
                      type="text"
                      className={`validate required full short ${
                        staffData.hasOwnProperty('userLastName') ? 'check-ok' : 'check'
                      }`}
                      name="userLastName"
                      onChange={handleChangeText}
                      value={staffData.userLastName}
                      disabled={checkForm}
                    />
                  </td>
                  <td>
                    {' '}
                    名：
                    <input
                      type="text"
                      className={`validate required full short ${
                        staffData.hasOwnProperty('userFirstName') ? 'check-ok' : 'check'
                      }`}
                      name="userFirstName"
                      onChange={handleChangeText}
                      value={staffData.userFirstName}
                      disabled={checkForm}
                    />
                  </td>
                </tr>

                <tr>
                  <th>ユーザー連絡先</th>
                  <td colSpan="2">
                    <input
                      type="text"
                      className="validate required full input-small"
                      name="phoneNumber1"
                      onChange={handleChangeContact}
                      value={phoneNumber1}
                      disabled={checkForm}
                    />
                    -
                    <input
                      type="text"
                      className="validate required full input-small"
                      name="phoneNumber2"
                      onChange={handleChangeContact}
                      value={phoneNumber2}
                      disabled={checkForm}
                    />
                    -
                    <input
                      type="text"
                      className="validate required full input-small"
                      name="phoneNumber3"
                      onChange={handleChangeContact}
                      value={phoneNumber3}
                      disabled={checkForm}
                    />
                  </td>
                </tr>

                <tr>
                  <th>ユーザーemail</th>
                  <td colSpan="2">
                    <input
                      type="text"
                      className={`validate required full long-top ${
                        staffData.hasOwnProperty('userEmail') ? 'check-ok' : 'check'
                      }`}
                      name="userEmail"
                      onChange={handleChangeText}
                      value={staffData.userEmail}
                      disabled={checkForm}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div align="center" className="margin_top10 mb-4">
            <button
              disabled={checkForm ? checkForm : checkBtn}
              className={`cancel_submit submitbutton btn-branch btnSuccess`}
              onClick={handleSubmit}
            >
              更新
            </button>
          </div>
          {idStaff && (
            <div align="center" className="margin_top10 mb-1">
              <button
                className={`cancel_submit submitbutton btn-branch btnSuccess`}
                onClick={checkSense}
                disabled={checkForm}
              >
                ユーザー利用停止
              </button>
            </div>
          )}

          <TableUpdateStaff dataLogStaff={dataLogStaff} />
        </div>
        <FooterMaintenance/>
      </div>
    </div>
  );
};

export default ModifyBranchStaff;
