import React from 'react';
import 'assets/scss/page/BranchStaffMaster/TableStaffBranchMaster.scss';
import { getNoOrder } from '../../../../helpers/helpers';
import {Link} from 'react-router-dom';
const data = {
  header: [
    '利用者ID',
    '利用者名',
    'フリガナ',
    'ユーザー連絡先',
    'ユーザーemail',
    '作成日',
    '更新日',
    'システム利用状況',
    '終了日'
  ]
};

const renderItem = (item, index, fontSize, db, activePage) => {
  return (
    <tr key={index}>
      <td className="no-wrapper">
        <div className="d-flex justify-content-center align-items-center font-weight-bolder no-style green">   {getNoOrder({ index: index, limit: 10, activePage: activePage })}</div>
      </td>
      <td>{item?.loginId}</td>
      <td>{item?.userFirstName + ' ' + item?.userLastName}</td>
      <td>{item?.userFirstNameKana + ' ' + item?.userLastNameKana}</td>
      <td>{item?.userContact}</td>
      <td>{item?.userEmail}</td>
      <td>{item?.createdAt}</td>
      <td>{item?.updatedAt}</td>
      <td>{item?.suspensionStatus ? '': '利用中'}</td>
      <td>{item?.suspensionStatus ? item?.suspensionDate: ''}</td>
      {/*{data.header.map((element, key) => {*/}
      {/*  return <td key={key}>{item[element]}</td>;*/}
      {/*})}*/}
      <td>
        <Link to={`/branch-master/modify-branch-staff?sid=${item?.staffBranchId}`}>
          <a className="btn-edit cursor-pointer"
          target='_blank'>
            更新
          </a>
        </Link>

      </td>
    </tr>
  );
};

const TableStaffBranchMaster = ({ fontSize = 'small', db = 'center', listUser = [], activePage = 1 }) => {
  return (
    <div className="table-staff-branch-wrapper table-responsive">
      <table className={`table table-striped ${fontSize}`}>
        <thead>
        <tr>
          <th scope="col">No</th>
          {data.header.map((item, index) => (
            <th scope="col" key={index}>
              {item}
              <br/>
              <div className="wrapper-sort">
                <a  className="green">
                  ▲
                </a>
                {/*</Link>*/}
                <a className="green">
                  ▼
                </a>
              </div>
            </th>
          ))}
          <th scope="col">操作</th>
        </tr>
        </thead>
        <tbody>{listUser.map((listData, index) => renderItem(listData, index, fontSize, db, activePage))}</tbody>
      </table>
    </div>
  );
};

export default TableStaffBranchMaster;
