import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import '../../../../../assets/scss/page/CsvMaster/index.scss';

const ModalPaymentMaster = (props) => {
  const { toggle, modal = false } = props;

  return (
    <Modal className="form-modal-contract" isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle} cssModule={{ 'modal-title': 'w-100 text-center mb-0' }}>
        支払項目
      </ModalHeader>
      <ModalBody>
        <div className="block-content pb-0">
          <div className="item-content">
            <p className="title-money">50,000</p>
            <input type="text" className="input-money" />
          </div>
        </div>
        <div className="text-center">
          <button className="btn-update float-none">更新</button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalPaymentMaster;
