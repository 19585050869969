import React from 'react';
import { useHistory } from 'react-router-dom';
import './style.scss';

const TableWorker = ({ data = [], t, workName }) => {
  const history = useHistory();

  const renderItem = (item, index, data, workName) => {
    return (
      <tr key={index} onClick={() => detailPage(item.orderId, workName, item.organizationId)}>
        {data.header
          .filter((ele) => ele !== 'applicantCompanyName')
          .map((element, key) => {
            return (
              <td key={key}>
                {element === 'status'
                  ? checkStatus(item['statusOrderWorker'])
                  : element === 'prefecturesVisited'
                  ? item?.installationSiteName
                  : item[element]}
              </td>
            );
          })}
      </tr>
    );
  };

  const checkStatus = (status) => {
    if (status === 'cancelWorker') {
      return t('list_check_status_order.cancelWorker');
    } else if (status === 'NotYetWrittenReport') {
      return t('list_check_status_order.NotYetWrittenReport');
    } else if (status === 'wroteReport') {
      return t('list_check_status_order.wroteReport');
    } else if (status === 'sendReport') {
      return t('list_check_status_order.sendReport');
    } else {
      return '';
    }
  };

  const detailPage = (orderId, workName, organizationId = '') => {
    history.push(`/detail-worker-list?orderId=${orderId}&workName=${workName}&organizationId=${organizationId}`);
  };

  return (
    <div className="table-list-worker-wrapper table-responsive">
      <table className="table table-stripe">
        <thead>
          <tr style={{ backgroundColor: '#e65529' }}>
            {data?.header
              ?.filter((ele) => ele !== 'applicantCompanyName')
              ?.map((item, index) => (
                <th scope="col" key={index}>
                  {t(`table_order_list_worker.${item}`)}
                </th>
              ))}
          </tr>
        </thead>
        <tbody>{data?.body?.map((listData, index) => renderItem(listData, index, data, workName))}</tbody>
      </table>
    </div>
  );
};

export default TableWorker;
