import React, { useState, useEffect } from 'react';
import { Collapse } from 'reactstrap';
import 'react-datepicker/dist/react-datepicker.css';
import '../../../../assets/scss/page/center/Users/style.scss';
import { ORGANIZATION_ENDPOINT, USER_ENDPOINT } from '../../../../constants/endpoints';
import PaginationCustom from '../../../../components/PaginationCustom';
import Organization from '../Organization';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { ROLE } from '../../../../helpers/constants';

const OrganizationList = ({colors ,colorText, item = {}, listOrganizations, handleCollap, isShow, changeLoading ,isVerifyRole ,data ,roleOrganization
}) => {
  const [dataOrganizations, setDataOrganizations] = useState([]);
  const toggle = () => handleCollap(item.key);
  const [activePage, setActivePage] = React.useState(1);
  const [search, setSearch] = useState('');

  const [pagination, setPagination] = useState({
    countClient: data?.countClient || 0,
    countPartner: data?.countPartner || 0,
    count: !_.isEmpty(dataOrganizations) && dataOrganizations?.[0]?.count || 0
  })

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    {
      (roleOrganization === ROLE.NEOMARS )
        ? getOrganizations({ page: pageNumber })
        : getPartnerAndClient({ page: pageNumber, type: item?.key === 'monitoring' ? 'client' : 'partner' });
    }
  };

  useEffect(() => {
    if(item?.key === 'monitoring') {
      setDataOrganizations(data?.clients)
    } if(item?.key === 'management') {
      setDataOrganizations(data?.partners)
    }
  },[data])

  useEffect(() => {
    if(isVerifyRole && roleOrganization === ROLE.NEOMARS) {
      !_.isEmpty(item) && !_.isEmpty(listOrganizations) && setDataOrganizations(_.compact(listOrganizations.map(organ => {
        return (item.key in organ) ? organ : null
      })));
    }
  }, [listOrganizations])

  const getOrganizations = async ({
    page = 1, limit = 10, typeRole = item.key, name = ''
  }) => {
    try {
      changeLoading(true);
      const data = await ORGANIZATION_ENDPOINT.GET_ORAGINIZATIONS({ page, limit, typeRole, name: search });
      if (data) {
        changeLoading(false);
        setDataOrganizations(data?.data);
        return true;
      }
    } catch (e) {
      changeLoading(false);
      console.log(e);
    }
  };

  const getPartnerAndClient = async ({ type = '', page = 1, limit = 10 , name = '' }) => {
    changeLoading(true);
    try {
      const data = await USER_ENDPOINT.GET_PARTNER_AND_CLIENT({ type: type, page: page, limit: limit , name : search });
      if (data) {
        if(item?.key === 'management') {
          setDataOrganizations(data?.data?.partners);
          setPagination({
            ...pagination,
            countPartner: data?.data?.countPartner
          })
        } else {
          setDataOrganizations(data?.data?.clients);
          setPagination({
            ...pagination,
            countClient: data?.data?.countClient
          })
        }
        changeLoading(false);
        return true;
      }
    } catch (errorMessage) {
      changeLoading(false);
      console.log('errorMessage: ', errorMessage);
    }
  };

  const changeSearch = (e) => {
    let value = e.target.value;
    setSearch(value);
  }

  const handleSearchData = async () => {
    let searchSuccess = false;

    if (roleOrganization === ROLE.NEOMARS) {
      searchSuccess = await getOrganizations({name: search})
    } else {
      searchSuccess = await getPartnerAndClient({ name: search, type: item?.key === 'monitoring' ? 'client' : 'partner' });
    }

    if (searchSuccess) {
      setActivePage(1)
    }
  }

  const searchData = () => {
    handleSearchData()
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSearchData()
    }
  }

  const dataList = () => {
    if( isVerifyRole && roleOrganization === ROLE.PARTNERGROUP ) {
      return !_.isEmpty(dataOrganizations) && dataOrganizations || []
    } if (isVerifyRole && (roleOrganization === ROLE.NEOMARS )) {
      return !_.isEmpty(item) && !_.isEmpty(dataOrganizations[0]) && dataOrganizations[0][item.key] || []
    }
  }

  useEffect(() => {
    setPagination({
      ...pagination,
      countClient: data?.countClient || 0,
      countPartner: data?.countPartner || 0,
    })
  }, [data])

  return (
    <div className="setting-master-menu-wrapper">
      <div className="wrapper-btn-colappse">
        <div onClick={toggle} className="mb-1 searchbar d-flex justify-content-between align-items-center" style = {{background :`${colors}`}}>
          <div className="ml-2" style={{ color: `${colorText}` }}>{item.name}</div>
          <div className="mr-2" style={{ color: `${colorText}` }}>
            {isShow === item.key ? <i className="icon-up-open icon-searchbar" /> : <i className="icon-down-open icon-searchbar" />}
          </div>
        </div>
      </div>
      <div>
        <Collapse isOpen={isShow === item.key}>
          <div className="form__content">
            <div className="form__flex form__flex--end">
            </div>
              <div className="form__flex form__flex--mgt-10">
              <div className="form-search">
                <div className="form__group-input form__group-input--border-none">
                  <div className="title">検索条件</div>
                  <input type="text" onChange = {(e) => changeSearch(e)} onKeyDown={(e) => handleKeyDown(e)} />
                </div>
                <button className="btn-tree" onClick = {searchData}>検索</button>
              </div>
              {
                isVerifyRole && (roleOrganization === ROLE.NEOMARS ) && (
                  <Link to={`/organizations/modify_organization?typeRole=${item.key}`} className="button__btn-load button__btn-load--modify">
                    <div className="">新規企業登録</div>
                  </Link>)
              }
              {
                isVerifyRole && roleOrganization === ROLE.PARTNERGROUP && (
                  <Link to={`/organizations/create-organization?typeRole=${item.key}`} className="button__btn-load button__btn-load--modify">
                    <div className="">新規企業登録</div>
                  </Link>)
              }
            </div>
            <Organization dataOrganizations={dataList()} dataNew={data?.organization} colors={colors} activePage={activePage} isVerifyRole={isVerifyRole} checkKey={item?.key} roleOrganization={roleOrganization}/>
            {!_.isEmpty(dataOrganizations) && dataOrganizations?.[0]?.count > 10 && (
              <PaginationCustom
                activePage={activePage}
                itemsCountPerPage={10}
                totalItemsCount={dataOrganizations && dataOrganizations?.[0]?.count}
                pageRangeDisplayed={5}
                handlePageChange={handlePageChange}
              />
            )}
            { pagination?.countPartner > 10 && item.key === 'management' && roleOrganization === ROLE.PARTNERGROUP && (
              <PaginationCustom
                activePage={activePage}
                itemsCountPerPage={10}
                totalItemsCount={pagination?.countPartner}
                pageRangeDisplayed={5}
                handlePageChange={handlePageChange}
              />
            )}
            { pagination?.countClient > 10 && item.key === 'monitoring' && roleOrganization === ROLE.PARTNERGROUP && (
              <PaginationCustom
                activePage={activePage}
                itemsCountPerPage={10}
                totalItemsCount={pagination?.countClient}
                pageRangeDisplayed={5}
                handlePageChange={handlePageChange}
              />
            )}
          </div>
        </Collapse>
      </div>
    </div>
  );
};

export default OrganizationList;
