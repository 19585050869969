import React from 'react';
import { getNoOrder } from 'helpers/helpers';
import { DATE_JP_FORMAT } from 'helpers/constants';
import { Link } from 'react-router-dom';
import moment from 'moment';

const data = {
  header: [
    '利用者ID',
    '利用者名',
    'フリガナ',
    '連絡先',
    'email',
    '作成日',
    '更新日',
    '利用状況',
    '利用停止日',
    '操作'
  ],
};

const User = ({ listUser = [], colors, activePage, organizationId = '', roleActive = 'ERP' }) => {
  const renderItem = (item, index) => {
    return (
      <tr key={index}>
        <td className="no-wrapper">
          <div>{getNoOrder({ index, limit: 10, activePage })}</div>
        </td>
        <td>{item?.loginId}</td>
        <td>{item?.firstName + ' ' + item?.lastName}</td>
        <td>{item?.firstNameKana + ' ' + item?.lastNameKana}</td>
        <td>{item?.phoneNumber}</td>
        <td>{item?.email}</td>
        <td>{item?.createdAt ? moment(new Date(item?.createdAt)).format(DATE_JP_FORMAT) : ''}</td>
        <td>{item?.updatedAt ? moment(new Date(Array.isArray(item?.updatedAt) ? item?.updatedAt?.[0] : item?.updatedAt)).format(DATE_JP_FORMAT) : ''}</td>
        <td>{item?.userSub && item?.usageStatus !== 3 ? (item?.usageStatus === 1 ? '利用停止' : '利用中') : ''}</td>
        <td>{item?.usageStatus === 1 ? item?.statusUpdatedAt : ''}</td>
        <td className='button--center button--wrap'>
          <Link to={`/users/edit-user?sid=${item?.userId}&organizationId=${organizationId}&role=${roleActive}`}>
            <div className="button__edit">更新</div>
          </Link>
        </td>
      </tr>
    );
  };

  return (
    <div className="table-list-wrapper table-responsive">
      <table className="table table-striped">
        <thead>
          <tr style={{ backgroundColor: `${colors}` }}>
            <th scope="col">No</th>
            {data.header.map((item, index) => (
              <th scope="col" key={index}>
                {item}
                <br />
              </th>
            ))}
          </tr>
        </thead>
        <tbody>{listUser.map((listData, index) => renderItem(listData, index, activePage))}</tbody>
      </table>
    </div>
  );
};

export default User;
