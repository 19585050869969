import React, { useEffect, useState } from 'react';
import { Collapse } from 'reactstrap';
import { ROLE } from '../../../helpers/constants';

const DetailSent = ({ data, onChangeText = () => { }, isDisabledErp = false, isDisabledManagement = false, role = '' }) => {
  const [isOpen, setIsOpen] = useState(true);
  const onClickShow = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <div className="detail-erp-table-wrapper mt-1">
        <div className="title-absolute" onClick={onClickShow}>
          申し送り事項
          {isOpen ? <i className="fas fa-arrow-circle-up ml-2"></i> : <i className="fas fa-arrow-circle-down ml-2"></i>}
        </div>
        <Collapse isOpen={isOpen}>
          <div className="detail__managerment">
            <table className="detail__table">
              <tbody>
                <tr>
                  <th className="detail__table--th th-matters-to-be-sent">
                    {role ===  ROLE.PARTNERGROUP ? 'ManagementGroup' : '契約企業'}
                    <br />
                    申し送り事項
                  </th>
                  <td>
                    <textarea name="erpMattersToBeSent" cols="50" rows="5" disabled={isDisabledErp} className="detail__table--textarea"
                      value={data?.erpMattersToBeSent} onChange={onChangeText}>
                    </textarea>
                  </td>
                  <th className="detail__table--th">
                    協力企業
                    <br /> 申し送り事項
                  </th>
                  <td>
                    <textarea name="managementMattersToBeSent" cols="50" rows="5" disabled={isDisabledManagement} className="detail__table--textarea"
                      value={data?.managementMattersToBeSent} onChange={onChangeText}>
                    </textarea>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Collapse>
      </div>
    </div>
  );
};

export default DetailSent;
