export const EXPORT_SETTING_DEFAULT = [
  {
    distinguish: 'stellaCloud',
    outputProject: '-',
    name: 'システムID',
    defaultValue: '-',
    sortNum: 1,
    nameInStella: 'orderId',
    codeInStella: '',
    isUsed: false,
    type: 'string',
  },
  {
    distinguish: 'stellaCloud',
    outputProject: '基本情報',
    name: '管理コード',
    defaultValue: '',
    sortNum: 2,
    nameInStella: 'managementCode',
    codeInStella: '',
    isUsed: false,
    type: 'string',
  },
  {
    distinguish: 'stellaCloud',
    outputProject: '基本情報',
    name: 'Service',
    defaultValue: '',
    sortNum: 3,
    nameInStella: 'serviceName',
    codeInStella: '',
    isUsed: false,
    type: 'string',
  },
  {
    distinguish: 'stellaCloud',
    outputProject: '基本情報',
    name: '物件名',
    defaultValue: '',
    sortNum: 4,
    nameInStella: 'installationSiteName',
    codeInStella: '',
    isUsed: false,
    type: 'string',
  },
  {
    distinguish: 'stellaCloud',
    outputProject: '基本情報',
    name: 'Monitoring',
    defaultValue: '',
    sortNum: 5,
    nameInStella: 'clientName',
    codeInStella: '',
    isUsed: false,
    type: 'string',
  },
  {
    distinguish: 'stellaCloud',
    outputProject: '基本情報',
    name: 'Project',
    defaultValue: '',
    sortNum: 6,
    nameInStella: 'projectName',
    codeInStella: '',
    isUsed: false,
    type: 'string',
  },
  {
    distinguish: 'stellaCloud',
    outputProject: '基本情報',
    name: 'Product',
    defaultValue: '',
    sortNum: 7,
    nameInStella: 'caseName',
    codeInStella: '',
    isUsed: false,
    type: 'string',
  },
  {
    distinguish: 'stellaCloud',
    outputProject: '基本情報',
    name: '申込者住所',
    defaultValue: '',
    sortNum: 8,
    nameInStella: 'applicantAddress',
    codeInStella: '',
    isUsed: false,
    type: 'string',
  },
  {
    distinguish: 'stellaCloud',
    outputProject: '基本情報',
    name: '訪問先拠点住所',
    defaultValue: '',
    sortNum: 9,
    nameInStella: 'visitedBaseAddress',
    codeInStella: '',
    isUsed: false,
    type: 'string',
  },
  {
    distinguish: 'stellaCloud',
    outputProject: '基本情報',
    name: '立会者住所',
    defaultValue: '',
    sortNum: 10,
    nameInStella: 'witnessAddress',
    codeInStella: '',
    isUsed: false,
    type: 'string',
  },
  {
    distinguish: 'stellaCloud',
    outputProject: 'アポイント情報',
    name: '架電ステータス',
    defaultValue: '',
    sortNum: 16,
    nameInStella: 'callStatus',
    codeInStella: '',
    isUsed: false,
    type: 'string',
  },
  {
    distinguish: 'stellaCloud',
    outputProject: 'アポイント情報',
    name: '架電メモ',
    defaultValue: '',
    sortNum: 17,
    nameInStella: 'callLog',
    codeInStella: '',
    isUsed: true,
    type: 'string',
  },
  {
    distinguish: 'stellaCloud',
    outputProject: 'アポイント情報',
    name: '訪問日',
    defaultValue: '',
    sortNum: 18,
    nameInStella: 'visitDate',
    codeInStella: '',
    isUsed: false,
    type: 'date',
  },
  {
    distinguish: 'stellaCloud',
    outputProject: 'アポイント情報',
    name: '訪問日_From',
    defaultValue: '',
    sortNum: 19,
    nameInStella: 'visitDayFrom',
    codeInStella: '',
    isUsed: false,
    type: 'date',
  },
  {
    distinguish: 'stellaCloud',
    outputProject: 'アポイント情報',
    name: '訪問日_To',
    defaultValue: '',
    sortNum: 20,
    nameInStella: 'visitDayTo',
    codeInStella: '',
    isUsed: false,
    type: 'date',
  },
  {
    distinguish: 'stellaCloud',
    outputProject: '稼働調整情報',
    name: '稼働調整状況',
    defaultValue: '',
    sortNum: 23,
    nameInStella: 'adjustmentStatus',
    codeInStella: '',
    isUsed: false,
    type: 'string',
  },
  {
    distinguish: 'stellaCloud',
    outputProject: '稼働待ち情報',
    name: 'Management',
    defaultValue: '',
    sortNum: 27,
    nameInStella: 'partnerName',
    codeInStella: '',
    isUsed: false,
    type: 'string',
  },
  {
    distinguish: 'stellaCloud',
    outputProject: '稼働待ち情報',
    name: '作業員確定日',
    defaultValue: '',
    sortNum: 28,
    nameInStella: 'timeAddStaff',
    codeInStella: '',
    isUsed: false,
    type: 'datetime',
  },
  {
    distinguish: 'stellaCloud',
    outputProject: '事前確認',
    name: '事前確認ステータス',
    defaultValue: '',
    sortNum: 30,
    nameInStella: 'statusMonitoring',
    codeInStella: '',
    isUsed: false,
    type: 'string',
  },
  {
    distinguish: 'stellaCloud',
    outputProject: '事前確認',
    name: '事前確認完了日',
    defaultValue: '',
    sortNum: 31,
    nameInStella: 'timeBeforeConfirmFinish',
    codeInStella: '',
    isUsed: false,
    type: 'datetime',
  },
  {
    distinguish: 'stellaCloud',
    outputProject: '事前確認',
    name: '入館申請完了日',
    defaultValue: '',
    sortNum: 32,
    nameInStella: 'timestampRequestConfirm',
    codeInStella: '',
    isUsed: false,
    type: 'datetime',
  },
  {
    distinguish: 'stellaCloud',
    outputProject: '事前確認',
    name: '道路使用許可申請完了日',
    defaultValue: '',
    sortNum: 33,
    nameInStella: 'roadsUePermitApplicationCompletionDate',
    codeInStella: '',
    isUsed: false,
    type: 'datetime',
  },
  {
    distinguish: 'stellaCloud',
    outputProject: '事前確認',
    name: '共架申請完了日',
    defaultValue: '',
    sortNum: 34,
    nameInStella: 'coFrameApplicationOver',
    codeInStella: '',
    isUsed: false,
    type: 'datetime',
  },
  {
    distinguish: 'stellaCloud',
    outputProject: '事前確認',
    name: 'GC局入局申請完了日',
    defaultValue: '',
    sortNum: 35,
    nameInStella: 'GCFinishedDay',
    codeInStella: '',
    isUsed: false,
    type: 'datetime',
  },
  {
    distinguish: 'stellaCloud',
    outputProject: '事前確認',
    name: '鍵借用申請完了日',
    defaultValue: '',
    sortNum: 36,
    nameInStella: 'keyLoanApplicationDate',
    codeInStella: '',
    isUsed: false,
    type: 'datetime',
  },
  {
    distinguish: 'stellaCloud',
    outputProject: '事前確認',
    name: '作業員対応完了日',
    defaultValue: '',
    sortNum: 37,
    nameInStella: 'timeWorkerFinishBeforeConfirm',
    codeInStella: '',
    isUsed: false,
    type: 'datetime',
  },
  {
    distinguish: 'stellaCloud',
    outputProject: '当日現場状況',
    name: '当日作業完了日',
    defaultValue: '',
    sortNum: 42,
    nameInStella: 'timestampSiteInfo',
    codeInStella: '',
    isUsed: false,
    type: 'datetime',
  },
  {
    distinguish: 'stellaCloud',
    outputProject: '当日現場状況',
    name: '結果',
    defaultValue: '',
    sortNum: 43,
    nameInStella: 'resultsPreparation',
    codeInStella: '',
    isUsed: false,
    type: 'string',
  },
  {
    distinguish: 'stellaCloud',
    outputProject: '当日現場状況',
    name: '入館',
    defaultValue: '',
    sortNum: 44,
    nameInStella: 'startTime',
    codeInStella: '',
    isUsed: false,
    type: 'time',
  },
  {
    distinguish: 'stellaCloud',
    outputProject: '当日現場状況',
    name: '退館',
    defaultValue: '',
    sortNum: 45,
    nameInStella: 'endTime',
    codeInStella: '',
    isUsed: false,
    type: 'time',
  },
  {
    distinguish: 'stellaCloud',
    outputProject: '当日現場状況',
    name: '結果備考',
    defaultValue: '',
    sortNum: 46,
    nameInStella: 'resultCancel',
    codeInStella: '',
    isUsed: true,
    type: 'string',
  },
  {
    distinguish: 'stellaCloud',
    outputProject: '報告書情報',
    name: '報告書ステータス',
    defaultValue: '',
    sortNum: 47,
    nameInStella: 'statusReportUploadInfo',
    codeInStella: '',
    isUsed: false,
    type: 'string',
  },
  {
    distinguish: 'stellaCloud',
    outputProject: '報告書情報',
    name: '報告書送信日',
    defaultValue: '',
    sortNum: 48,
    nameInStella: 'timeWorkerReport',
    codeInStella: '',
    isUsed: false,
    type: 'datetime',
  },
  {
    distinguish: 'stellaCloud',
    outputProject: '報告書情報',
    name: '報告書提出日',
    defaultValue: '',
    sortNum: 49,
    nameInStella: 'timePartnerReport',
    codeInStella: '',
    isUsed: false,
    type: 'datetime',
  },
  {
    distinguish: 'stellaCloud',
    outputProject: '報告書情報',
    name: '報告書確認日',
    defaultValue: '',
    sortNum: 50,
    nameInStella: 'rejectOrConfirmDate',
    codeInStella: '',
    isUsed: false,
    type: 'datetime',
  },
  {
    distinguish: 'stellaCloud',
    outputProject: '報告書情報',
    name: '報告書納品日',
    defaultValue: '',
    sortNum: 51,
    nameInStella: 'timestampReportUploadInfo',
    codeInStella: '',
    isUsed: false,
    type: 'datetime',
  },
  {
    distinguish: 'stellaCloud',
    outputProject: '報告書情報',
    name: '報告書納品承認依頼日',
    defaultValue: '',
    sortNum: 52,
    nameInStella: 'timestampReportUploadInfoRequest',
    codeInStella: '',
    isUsed: false,
    type: 'datetime',
  },
  {
    distinguish: 'stellaCloud',
    outputProject: '報告書情報',
    name: '報告書納品承認日',
    defaultValue: '',
    sortNum: 53,
    nameInStella: 'timestampReportUploadInfoConfirm',
    codeInStella: '',
    isUsed: false,
    type: 'datetime',
  },
  {
    distinguish: 'stellaCloud',
    outputProject: '報告書情報',
    name: '不備返却日',
    defaultValue: '',
    sortNum: 54,
    nameInStella: 'reportReturnDate',
    codeInStella: '',
    isUsed: false,
    type: 'datetime',
  },
  {
    distinguish: 'stellaCloud',
    outputProject: '報告書情報',
    name: '不備返却コメント',
    defaultValue: '',
    sortNum: 55,
    nameInStella: 'defectiveReturnContents',
    codeInStella: '',
    isUsed: true,
    type: 'string',
  },
  {
    distinguish: 'stellaCloud',
    outputProject: '報告書情報',
    name: 'Monitoring_不備返却日',
    defaultValue: '',
    sortNum: 56,
    nameInStella: 'monitoringDefectiveReturnDate',
    codeInStella: '',
    isUsed: false,
    type: 'datetime',
  },
  {
    distinguish: 'stellaCloud',
    outputProject: '報告書情報',
    name: 'Monitoring_不備返却コメント',
    defaultValue: '',
    sortNum: 57,
    nameInStella: 'monitoringDefectiveReturnComment',
    codeInStella: '',
    isUsed: true,
    type: 'string',
  },
  {
    distinguish: 'stellaCloud',
    outputProject: '精算情報',
    name: '精算ステータス',
    defaultValue: '',
    sortNum: 58,
    nameInStella: 'paymentStatus',
    codeInStella: '',
    isUsed: false,
    type: 'string',
  },
  {
    distinguish: 'stellaCloud',
    outputProject: '精算情報',
    name: 'Management_検収依頼日',
    defaultValue: '',
    sortNum: 59,
    nameInStella: 'managementAcceptanceRequest',
    codeInStella: '',
    isUsed: false,
    type: 'datetime',
  },
  {
    distinguish: 'stellaCloud',
    outputProject: '精算情報',
    name: 'ERP_検収確認日',
    defaultValue: '',
    sortNum: 60,
    nameInStella: 'ERPAcceptanceConfirmDate',
    codeInStella: '',
    isUsed: false,
    type: 'datetime',
  },
  {
    distinguish: 'stellaCloud',
    outputProject: '精算情報',
    name: 'ERP_差戻日',
    defaultValue: '',
    sortNum: 61,
    nameInStella: 'ERPAcceptanceRejectDate',
    codeInStella: '',
    isUsed: false,
    type: 'datetime',
  },
  {
    distinguish: 'stellaCloud',
    outputProject: '精算情報',
    name: 'Monitoring_検収確認日',
    defaultValue: '',
    sortNum: 62,
    nameInStella: 'mornitorAcceptanceConfirmDate',
    codeInStella: '',
    isUsed: false,
    type: 'datetime',
  },
  {
    distinguish: 'stellaCloud',
    outputProject: '精算情報',
    name: 'Monitoring_差戻日',
    defaultValue: '',
    sortNum: 63,
    nameInStella: 'mornitorAcceptanceRejectDate',
    codeInStella: '',
    isUsed: false,
    type: 'datetime',
  },
  {
    distinguish: 'stellaCloud',
    outputProject: '精算情報',
    name: '検収処理承認依頼日',
    defaultValue: '',
    sortNum: 64,
    nameInStella: 'acceptanceProcessRequestDate',
    codeInStella: '',
    isUsed: false,
    type: 'datetime',
  },
  {
    distinguish: 'stellaCloud',
    outputProject: '精算情報',
    name: '検収処理承認日',
    defaultValue: '',
    sortNum: 65,
    nameInStella: 'acceptanceProcessConfirmDate',
    codeInStella: '',
    isUsed: false,
    type: 'datetime',
  },
  {
    distinguish: 'stellaCloud',
    outputProject: 'キャンセル',
    name: 'キャンセル結果',
    defaultValue: '',
    sortNum: 66,
    nameInStella: 'resultCancelInfo',
    codeInStella: '',
    isUsed: false,
    type: 'string',
  },
  {
    distinguish: 'stellaCloud',
    outputProject: 'キャンセル',
    name: 'キャンセル依頼日',
    defaultValue: '',
    sortNum: 67,
    nameInStella: 'timestampCancelMenu',
    codeInStella: '',
    isUsed: false,
    type: 'datetime',
  },
  {
    distinguish: 'stellaCloud',
    outputProject: 'キャンセル',
    name: 'キャンセル完了日',
    defaultValue: '',
    sortNum: 68,
    nameInStella: 'canceledAt',
    codeInStella: '',
    isUsed: false,
    type: 'datetime',
  },
  {
    distinguish: 'stellaCloud',
    outputProject: 'キャンセル',
    name: 'キャンセル結果備考',
    defaultValue: '',
    sortNum: 69,
    nameInStella: 'reasonForCancellation',
    codeInStella: '',
    isUsed: true,
    type: 'string',
  },
  {
    distinguish: 'stellaCloud',
    outputProject: '申し送り事項',
    name: '協力企業申し送り事項',
    defaultValue: '',
    sortNum: 70,
    nameInStella: 'notificationItems',
    codeInStella: '',
    isUsed: true,
    type: 'string',
  },
  {
    distinguish: 'stellaCloud',
    outputProject: '申し送り事項',
    name: '契約企業申し送り事項',
    defaultValue: '',
    sortNum: 71,
    nameInStella: 'erpMattersToBeSent',
    codeInStella: '',
    isUsed: true,
    type: 'string',
  },
  {
    distinguish: 'stellaCloud',
    outputProject: '申し送り事項',
    name: '協力企業申し送り事項',
    defaultValue: '',
    sortNum: 72,
    nameInStella: 'managementMattersToBeSent',
    codeInStella: '',
    isUsed: true,
    type: 'string',
  },
  {
    distinguish: 'stellaCloud',
    outputProject: 'オプション関連',
    name: '案件名',
    defaultValue: '',
    sortNum: 73,
    nameInStella: 'projectTitle',
    codeInStella: '',
    isUsed: false,
    type: 'string',
  },
  {
    distinguish: 'stellaCloud',
    outputProject: 'オプション関連',
    name: '施工区分',
    defaultValue: '',
    sortNum: 74,
    nameInStella: 'constructionClassification',
    codeInStella: '',
    isUsed: false,
    type: 'string',
  },
  {
    distinguish: 'Worker',
    outputProject: '事前確認',
    name: '訪問先確認完了日時',
    defaultValue: '',
    sortNum: 75,
    nameInStella: 'timestampBeforeConfirmDest',
    codeInStella: '',
    isUsed: false,
    type: 'datetime',
  },
  {
    distinguish: 'Worker',
    outputProject: '事前確認',
    name: '車両確認完了日時',
    defaultValue: '',
    sortNum: 76,
    nameInStella: 'timestampBeforeConfirmCar',
    codeInStella: '',
    isUsed: false,
    type: 'datetime',
  },
  {
    distinguish: 'Worker',
    outputProject: '事前確認',
    name: '持参品確認完了日時',
    defaultValue: '',
    sortNum: 77,
    nameInStella: 'timestampBeforeConfirmLuggage',
    codeInStella: '',
    isUsed: false,
    type: 'datetime',
  },
  {
    distinguish: 'Worker',
    outputProject: '事前確認',
    name: '部材確認完了日時',
    defaultValue: '',
    sortNum: 78,
    nameInStella: 'timestampBeforeConfirmMaterial',
    codeInStella: '',
    isUsed: false,
    type: 'datetime',
  },
  {
    distinguish: 'Worker',
    outputProject: '事前確認',
    name: '当日使用品確認完了日時',
    defaultValue: '',
    sortNum: 79,
    nameInStella: 'timestampBeforeConfirmItem',
    codeInStella: '',
    isUsed: false,
    type: 'datetime',
  },
  {
    distinguish: 'Worker',
    outputProject: '事前確認',
    name: '当日撤去品確認完了日時',
    defaultValue: '',
    sortNum: 80,
    nameInStella: 'timestampBeforeConfirmWasteItem',
    codeInStella: '',
    isUsed: false,
    type: 'datetime',
  },
  {
    distinguish: 'Worker',
    outputProject: '事前確認',
    name: '立会者確認完了日時',
    defaultValue: '',
    sortNum: 81,
    nameInStella: 'timestampBeforeConfirmWitness',
    codeInStella: '',
    isUsed: false,
    type: 'datetime',
  },
  {
    distinguish: 'Worker',
    outputProject: '事前確認',
    name: '鍵借用確認完了日時',
    defaultValue: '',
    sortNum: 82,
    nameInStella: 'keyLoanApplicationDate',
    codeInStella: '',
    isUsed: false,
    type: 'datetime',
  },
  {
    distinguish: 'stellaCloud',
    outputProject: '受注情報',
    name: '受注処理承認日',
    defaultValue: '',
    sortNum: 14,
    nameInStella: 'timestampReceiveInfo',
    codeInStella: '',
    isUsed: false,
    type: 'datetime',
  },
  {
    distinguish: 'stellaCloud',
    outputProject: 'アポイント情報',
    name: '訪問日確定日時',
    defaultValue: '',
    sortNum: 15,
    nameInStella: 'timestampAppointInfo',
    codeInStella: '',
    isUsed: false,
    type: 'datetime',
  },
  {
    distinguish: 'stellaCloud',
    outputProject: 'アポイント情報',
    name: '訪問日時_From',
    defaultValue: '',
    sortNum: 21,
    nameInStella: 'visitDateFrom',
    codeInStella: '',
    isUsed: true,
    type: 'time',
  },
  {
    distinguish: 'stellaCloud',
    outputProject: 'アポイント情報',
    name: '訪問日時_To',
    defaultValue: '',
    sortNum: 22,
    nameInStella: 'visitDateTo',
    codeInStella: '',
    isUsed: true,
    type: 'time',
  },
  {
    distinguish: 'stellaCloud',
    outputProject: '稼働調整情報',
    name: '稼働打診日時',
    defaultValue: '',
    sortNum: 24,
    nameInStella: 'timeAddPartner',
    codeInStella: '',
    isUsed: false,
    type: 'datetime',
  },
  {
    distinguish: 'stellaCloud',
    outputProject: '稼働調整情報',
    name: '稼働回答日時',
    defaultValue: '',
    sortNum: 25,
    nameInStella: 'timePartnerConfirm',
    codeInStella: '',
    isUsed: false,
    type: 'datetime',
  },
  {
    distinguish: 'stellaCloud',
    outputProject: '稼働調整情報',
    name: '稼働確保日時',
    defaultValue: '',
    sortNum: 26,
    nameInStella: 'timestampConditionInfo',
    codeInStella: '',
    isUsed: false,
    type: 'datetime',
  },
  {
    distinguish: 'stellaCloud',
    outputProject: '稼働待ち情報',
    name: '作業員名_1',
    defaultValue: '',
    sortNum: 29,
    nameInStella: 'workerName',
    codeInStella: '',
    isUsed: false,
    type: 'string',
  },
  {
    distinguish: 'stellaCloud',
    outputProject: '事前確認',
    name: '事前確認送信日',
    defaultValue: '',
    sortNum: 38,
    nameInStella: 'timestampBeforeConfirmWorkerRequest',
    codeInStella: '',
    isUsed: false,
    type: 'datetime',
  },
  {
    distinguish: 'stellaCloud',
    outputProject: '事前確認',
    name: '事前確認_承認メモ',
    defaultValue: '',
    sortNum: 39,
    nameInStella: 'beforeConfirmMemo',
    codeInStella: '',
    isUsed: false,
    type: 'string',
  },
  {
    distinguish: 'stellaCloud',
    outputProject: '事前確認',
    name: '事前確認承認依頼日',
    defaultValue: '',
    sortNum: 40,
    nameInStella: 'beforeConfirmRequestDate',
    codeInStella: '',
    isUsed: false,
    type: 'date',
  },
  {
    distinguish: 'stellaCloud',
    outputProject: '事前確認',
    name: '事前確認承認日',
    defaultValue: '',
    sortNum: 41,
    nameInStella: 'timestampStatusBeforeConfirm',
    codeInStella: '',
    isUsed: false,
    type: 'datetime',
  },
  {
    distinguish: 'stellaCloud',
    outputProject: '受注情報',
    name: '受注日時',
    defaultValue: '',
    sortNum: 11,
    nameInStella: 'requestedDate',
    codeInStella: '',
    isUsed: false,
    type: 'datetime',
  },
  {
    distinguish: 'stellaCloud',
    outputProject: '受注情報',
    name: '受注処理日時',
    defaultValue: '',
    sortNum: 12,
    nameInStella: 'timestampReceiveInfoRequest',
    codeInStella: '',
    isUsed: false,
    type: 'datetime',
  },
  {
    distinguish: 'stellaCloud',
    outputProject: '受注情報',
    name: '受注処理承認依頼日',
    defaultValue: '',
    sortNum: 13,
    nameInStella: 'receiveInfoRequestDate',
    codeInStella: '',
    isUsed: false,
    type: 'date',
  },
];
