import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import './style.scss';

const TableCenterUser = ({ data = [], role = 'center', handle }) => {

  const handleChangeView = (e) => {
    handle({ idUser: e.target.value, checked: e.target.checked, role: role });
  };

  return (
    <div className="wrapper-table-center-user table-responsive">
      <table>
        <tbody>
        {data?.map((item, index) => (
          <tr key={index}>
            <td>{item?.firstName + ' ' + item?.lastName}</td>
            <td>
              <div className="form-check">
                <input className="form-check-input" type="checkbox" value={item?.userId} id={item?.userId + index}
                       checked={item?.viewable} onChange={handleChangeView}/>
                <label className="form-check-label" htmlFor={item?.userId + index}>
                  閲覧可
                </label>
              </div>
            </td>
          </tr>
        ))}
        </tbody>
      </table>
    </div>
  );
};

export default compose(withRouter)(TableCenterUser);
