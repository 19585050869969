import React from 'react';
import Pagination from 'react-js-pagination';
import './style.scss';

const PaginationCustom = (props) => {
  const { itemsCountPerPage, totalItemsCount = 10, pageRangeDisplayed, activePage, handlePageChange } = props;
  return (
    <>
      <div className="pagination-wrapper d-flex justify-content-center align-items-center">
        <Pagination
          activePage={activePage}
          itemsCountPerPage={itemsCountPerPage}
          totalItemsCount={parseInt(totalItemsCount)}
          pageRangeDisplayed={pageRangeDisplayed}
          onChange={handlePageChange}
          itemClass="page-item"
          linkClass="page-link"
        />
      </div>
    </>
  );
};

export default PaginationCustom;
