import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import TableAddRoleNext from './TableAddRoleNext';

const ModalAddRoleNext = ({ arrRole, modalNext = false, toggle, routeList, nameRole, nameScreen, idProvide = '', idProduct , namePermission, onBack = () => {}, nameTitle }) => {
  const Back = () => {
    toggle();
    onBack({ valueRole : routeList, productId: idProduct })
  };

  return (
    <div>
      <Modal className="modal-dialog-custom" isOpen={modalNext} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          <button onClick={Back} className="button__back">
          戻る
          </button>
        </ModalHeader>
        <ModalBody>
          <TableAddRoleNext routeList={routeList} nameScreen={nameScreen} namePermission={namePermission} idProvide={idProvide} idProduct={idProduct} nameTitle={nameTitle} />
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ModalAddRoleNext;
