import ja from 'date-fns/locale/ja'; // the locale you want
import React, { useEffect, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { pushToast } from '../../../../../components/Toast';
import { DEVICE_ENDPOINT, EXPORT_GOODS_ENDPOINT, PRODUCT_ENDPOINT } from '../../../../../constants/endpoints';
import { SCREEN_NAME, STATUS_GOODS } from '../../../../../helpers/constants';
registerLocale('ja', ja);

const mockData = {
  productId: '',
  managementCode: '',
  orderPlace: '',
  orderDate: '',
  expectedDate: '',
  unit: '',
  quantity: '',
  receivedQuantity: '',
  status: '',
  producer: '',
  screenName : SCREEN_NAME.ITEM_MANAGEMENT
};

const ModalUpdateExportGoods = (props) => {
  const { toggle = () => {}, modal = false, exportGoodsInfoId, loadListStaff = () => {} } = props;
  const [dataGoods, setDataGoods] = useState(mockData);
  const [listProduct, setListProduct] = useState();
  const [data, setData] = useState();
  const [listDevice, setListDevice] = useState([]);
  const [deviveSelected, setDeviveSelected] = useState('');

  const getAllProductByUser = async () => {
    try {
      const data = await PRODUCT_ENDPOINT.GET_LIST_OPTION_PRODUCT_BY_USER();
      if (data) {
        setListProduct(data?.data?.body);
      }
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    if (data) {
      setDeviveSelected(data.deviceId);
      setDataGoods({
        ...dataGoods,
        productId: data.productId,
        managementCode: data.managementCode,
        deviceId: data.deviceId,
        orderPlace: data.orderPlace,
        orderDate: data.orderDate ? new Date(data.orderDate) : '',
        expectedDate: data.expectedDate ? new Date(data.expectedDate) : '',
        receiptDate: data.receiptDate ? new Date(data.receiptDate) : '',
        unit: data.unit,
        quantity: data.quantity,
        receivedQuantity: data.receivedQuantity,
        status: data.status,
        producer: data.producer,
      });
    }
  }, [data]);

  const getDetail = async ({ id = '' }) => {
    try {
      const data = await EXPORT_GOODS_ENDPOINT.GET_DETAIL({ id: id });
      if (data) {
        setData(data.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (exportGoodsInfoId) {
      getDetail({ id: exportGoodsInfoId });
    }
  }, [exportGoodsInfoId]);

  useEffect(() => {
    // getAllProductByUser();
    getAllDeviceByUser();
  }, []);

  useEffect(() => {
    if (deviveSelected) {
      const infoDevice = listDevice?.filter(item => item.deviceId === deviveSelected)?.[0];
      const listProduct = infoDevice?.productSchema;
      setListProduct(listProduct)
    }
  }, [deviveSelected, listDevice]);

  const getAllDeviceByUser = async () => {
    try {
      const data = await DEVICE_ENDPOINT.GET_LIST_DEVICE_INFO({ page: 0 });
      if (data) {
        setListDevice(data?.data?.body);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const UpdateImportGoodsMaster = async ({ body, id }) => {
    try {
      const data = await EXPORT_GOODS_ENDPOINT.UPDATE_EXPORT_GOODS({ body: body, id: id });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
      } else {
        pushToast('success', 'update data success');
        toggle(toggle);
        loadListStaff();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleChangeText = (e) => {
    let type = e.target.name;
    let newData = { ...dataGoods, [type]: e.target.value };
    setDataGoods(newData);
    if (type === 'deviceId') {
      setDeviveSelected(e.target.value);
    }
  };

  const onClickSubmit = () => {
    UpdateImportGoodsMaster({ body: dataGoods, id: exportGoodsInfoId });
  };

  const handleChangeDate = async (date, name) => {
    let newData = await { ...dataGoods, [name]: date };
    setDataGoods(newData);
  };

  return (
    <Modal className="form-add-goods-master" isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle}>UPDATE</ModalHeader>
      <ModalBody>
        <form>
          <div className="form-group row ml-0">
            <label className="col-sm-5 col-form-label">品名</label>
            <div className="col-sm-7">
              <select
                className="form-control"
                name="deviceId"
                onChange={handleChangeText}
                value={dataGoods ?.deviceId}>
                <option disabled value=''>---</option>

                {listDevice &&
                  listDevice.map((item, index) => {
                    return (
                      <option key={index} value={item.deviceId}>
                        {item.nameDevice}
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>

          <div className="form-group row ml-0">
            <label className="col-sm-5 col-form-label">案件名</label>
            <div className="col-sm-7">
              <select
                className="form-control"
                name="productId"
                onChange={handleChangeText}
                value={dataGoods ?.productId}
                disabled={!dataGoods.deviceId}
              >
                <option disabled value=''>---</option>
                {listProduct &&
                  listProduct.map((item, index) => {
                    return (
                      <option key={index} value={item.productId}>
                        {item.productName}
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>

          <div className="form-group row ml-0">
            <label className="col-sm-5 col-form-label">管理コード</label>
            <div className="col-sm-7">
              <input
                type="text"
                className="form-control"
                name="managementCode"
                onChange={handleChangeText}
                value={dataGoods?.managementCode}
              />
            </div>
          </div>

          <div className="form-group row ml-0">
            <label className="col-sm-5 col-form-label">発注元</label>
            <div className="col-sm-7">
              <input
                type="text"
                className="form-control"
                name="orderPlace"
                onChange={handleChangeText}
                value={dataGoods?.orderPlace}
              />
            </div>
          </div>

          <div className="form-group row ml-0">
            <label className="col-sm-5 col-form-label">発注日</label>
            <div className="col-sm-7">
              <DatePicker
                selected={dataGoods?.orderDate}
                onChange={(date) => handleChangeDate(date, 'orderDate')}
                locale="ja"
                dateFormat="yyyy/MM/dd"
                dateFormatCalendar="yyyy年 MM月"
                className="form-control"
              />
            </div>
          </div>

          <div className="form-group row ml-0">
            <label className="col-sm-5 col-form-label">入庫予定日</label>
            <div className="col-sm-7">
              <DatePicker
                selected={dataGoods?.expectedDate}
                onChange={(date) => handleChangeDate(date, 'expectedDate')}
                locale="ja"
                name="expectedDate"
                dateFormat="yyyy/MM/dd"
                dateFormatCalendar="yyyy年 MM月"
                className="form-control"
              />
            </div>
          </div>

          <div className="form-group row ml-0">
            <label className="col-sm-5 col-form-label">受領日</label>
            <div className="col-sm-7">
              <DatePicker
                selected={dataGoods?.receiptDate}
                onChange={(date) => handleChangeDate(date, 'receiptDate')}
                locale="ja"
                dateFormat="yyyy/MM/dd"
                dateFormatCalendar="yyyy年 MM月"
                className="form-control"
              />
            </div>
          </div>

          <div className="form-group row ml-0">
            <label className="col-sm-5 col-form-label">メーカー</label>
            <div className="col-sm-7">
              <input
                type="text"
                className="form-control"
                name="producer"
                onChange={handleChangeText}
                value={dataGoods?.producer}
              />
            </div>
          </div>

          <div className="form-group row ml-0">
            <label className="col-sm-5 col-form-label">入庫数量</label>
            <div className="col-sm-7">
              <input
                type="text"
                className="form-control"
                onChange={handleChangeText}
                name="quantity"
                placeholder=""
                value={dataGoods?.quantity}
              />
            </div>
          </div>

          <div className="form-group row ml-0">
            <label className="col-sm-5 col-form-label">入荷数量</label>
            <div className="col-sm-7">
              <input
                type="text"
                className="form-control"
                onChange={handleChangeText}
                name="receivedQuantity"
                placeholder=''
                value={dataGoods?.receivedQuantity}
              />
            </div>
          </div>

          <div className="form-group row ml-0">
            <label className="col-sm-5 col-form-label">状態</label>
            <div className="col-sm-7">
              <select className="form-control" name="status" onChange={handleChangeText} value={dataGoods?.status}>
                {
                  STATUS_GOODS.map((item, index) => {
                    return (
                      <option key={index} value={item}>{item}</option>
                    )
                  })
                }
              </select>
            </div>
          </div>

          <div className="form-group row ml-0">
            <label className="col-sm-5 col-form-label">単位</label>
            <div className="col-sm-7">
              <select className="form-control" name="unit" onChange={handleChangeText} value={dataGoods?.unit}>
                <option value="">----</option>
                <option value="package">package</option>
                <option value="kg">kg</option>
                <option value="box">box</option>
              </select>
            </div>
          </div>
        </form>
      </ModalBody>
      <ModalFooter>
        <Button className="btn btn-success" onClick={onClickSubmit}>
          更新
        </Button>
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalUpdateExportGoods;
