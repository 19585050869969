import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { translate } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import '../../../assets/scss/page/contract/index.scss';
import Footer from '../../../components/Footer';
import HeaderMasterSetting from '../../../components/HeaderMasterSetting';
import Loading from '../../../components/Loading/Loading';
import PaginationCustom from '../../../components/PaginationCustom';
import { CONTRACT_ENDPOINTS } from '../../../constants/endpoints';
import CollapItem from './CollapItem';
import { ROLE, DATE_JP_FORMAT } from '../../../helpers/constants';
import { getRole } from '../../../helpers/helpers';
import { Collapse } from 'reactstrap';
import moment from 'moment';
import MemoHistory from '../../../components/MemoHistory/index';
import { SCREEN_NAME } from '../../../helpers/constants';
import FooterMaintenance from "components/FooterMaintenance"

const Contract = (props) => {
  const colorScreen = useSelector((store) => store.utils.colorMe);
  const [isOpen, setIsOpen] = useState(true);
  const [idRole, setIdRole] = useState(null);
  const toggle = () => setIsOpen(!isOpen);
  const [hideshow, setHideshow] = useState('');
  const [dataListContract, setDataListContract] = useState({});
  const [activePage, setActivePage] = useState(1);
  const [activePageContract, setActivePageContract] = useState(1);
  const [loading, setLoading] = useState(false);
  const checkRole = getRole({ idRole: localStorage.getItem('role') });
  const idProvider = localStorage.getItem('idProvide');

  const handleHide = (id) => {
    if (hideshow === id) {
      setHideshow('');
    } else {
      setHideshow(id);
    }
  };

  useEffect(() => {
    setIdRole(localStorage.getItem('role'));
    getListContract({ page: activePage, limit: 10, pageContract: activePageContract, limitContract: 10 });
  }, []);

  const getListContract = async ({ page, limit, pageContract, limitContract, sortProductId = '' }) => {
    setLoading(true);
    try {
      const data = await (checkRole === ROLE.PARTNER
        ? CONTRACT_ENDPOINTS.GET_LIST_CONTRACT_ESTIMATE_PARTNER({ page, limit })
        : CONTRACT_ENDPOINTS.GET_LIST_CONTRACT_RECRUITMENT({
            page,
            limit,
            pageContract,
            limitContract,
            sortProductId: sortProductId,
          }));
      if (data?.data) {
        setDataListContract(data?.data);
      }
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const handleChangePage = (pageNumber) => {
    setActivePage(pageNumber);
    getListContract(
      checkRole === ROLE.PARTNER
        ? { page: pageNumber, limit: 10 }
        : { page: pageNumber, limit: 10, pageContract: activePageContract, limitContract: 10 }
    );
  };

  const handleMessage = ({ contractEstimate }) => {
    let message = [];
    ['contractStatus', 'estimate', 'nda'].map(e => {
      if (contractEstimate?.[e]) {
        if (contractEstimate?.[e]?.addMessagePartner && !message.includes(contractEstimate?.[e]?.addMessagePartner)) {
          message.push(contractEstimate?.[e]?.addMessagePartner);
        }

        if (contractEstimate?.[e]?.addMessageERP && !message.includes(contractEstimate?.[e]?.addMessageERP)) {
          message.push(contractEstimate?.[e]?.addMessageERP);
        }
      }
    });
    return checkMessage({ message: message })
  };

  const checkMessage = ({ message }) => {
    let text = ''
    switch(message.length) {
      case 2: {
        text = '返却中・確認待ち';
        break;
      }

      case 1: {
        text = message[0] === '（返却中）' ? '返却中' : '確認待ち';
        break;
      }

      default:
        text = '';
    }

    return text;
  }

  return (
    <>
      <div className="main">
        <Loading loading={loading} />
        <HeaderMasterSetting color={colorScreen?.code} colorText={colorScreen?.colorText} />
        <div className="block-contract">
          {/* <div className="search-menu-wrapper search-menu-wrapper-contract">
            <div className="wrapper-btn-colappse">
              <div
                onClick={toggle}
                className="mb-1 searchbar searchbar-contract d-flex justify-content-between align-items-center"
              >
                <div></div>
                <div>契約管理</div>
                <div className="mr-2">
                  {isOpen ? (
                    <i className="icon-up-open icon-searchbar" />
                  ) : (
                    <i className="icon-down-open icon-searchbar" />
                  )}
                </div>
              </div>
            </div>
          </div> */}
          {checkRole === ROLE.PARTNER && (
            <div className="block-contract">
              <div className="list-table">
                <div className="table-list-contract">
                  <Collapse isOpen={isOpen}>
                    <table className="table table-bordered">
                      <tbody>
                        <tr>
                          <td>契約種別1</td>
                          <td>Product</td>
                          <td>Project</td>
                          <td>Service</td>
                          <td>契約期間 From</td>
                          <td>契約期間 To</td>
                          <td>状態</td>
                          <td>操作</td>
                        </tr>
                        {dataListContract?.result?.map((itemIn, index) => {
                          const message = handleMessage({ contractEstimate: itemIn });
                          return (
                            <tr key={index}>
                              <td>{itemIn?.contractStatus?.contractType}</td>
                              <td>{itemIn?.productName}</td>
                              <td>{itemIn.projectName}</td>
                              <td className="w-30">
                                {!_.isEmpty(itemIn?.services)
                                  ? itemIn?.services.map((ele) => {
                                      return <button className="px-2 m-1 d-inline-block btn-services">{ele}</button>;
                                    })
                                  : ''}
                              </td>
                              <td>{itemIn?.contractStatus?.contractPeriodStart ? moment(new Date(itemIn?.contractStatus?.contractPeriodStart)).format(DATE_JP_FORMAT) : ''}</td>
                              <td>{itemIn?.contractStatus?.contractPeriodEnd ? moment(new Date(itemIn?.contractStatus?.contractPeriodEnd)).format(DATE_JP_FORMAT) : ''}</td>
                              <td>{itemIn?.contractStatus?.state}</td>
                              <td className={`justify-content-center ${message ? 'td-check-table' : ''}`}>
                                <Link
                                  to={`/contract/registration?recruitmentContractId=${itemIn.recruitmentId}&recruitmentEstimateId=${itemIn.contractEstimateId}&organizationId=${itemIn.organizationId}&onBack=`}
                                  className="btn-edit"
                                >
                                  編集
                                </Link>
                                {
                                  (message === '確認待ち') ? ( <button className="btn-edit-blue btn-edit">{message}</button>) : (message && message !== '確認待ち') ? (
                                    <button className="btn-edit-red btn-edit">{message}</button>
                                  ) : ('')
                                }
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    {dataListContract?.count > 10 && (
                      <PaginationCustom
                        activePage={activePage}
                        itemsCountPerPage={10}
                        totalItemsCount={dataListContract?.count}
                        pageRangeDisplayed={5}
                        handlePageChange={handleChangePage}
                      />
                    )}
                  </Collapse>
                </div>
              </div>
            </div>
          )}
          {checkRole !== ROLE.PARTNER && (
            <div className="list-button-contact">
              <Link to="/contract/list-recruitment" className="item">
                募集一覧
              </Link>
              {!_.isEmpty(idRole) && idRole !== '1' && (
                <>
                  <Link to="/contract/list-contract-company" className="item">
                    契約会社一覧
                  </Link>
                  <Link to="/contract/application-site" className='item'>
                  応募サイト
                  </Link>
                </>
              )}
            </div>
          )}
          {checkRole !== ROLE.PARTNER && (
            <div className="list-table">
              {dataListContract?.data?.map((item, index) => {
                return (
                  <CollapItem
                    item={item}
                    key={index}
                    colors={colorScreen?.code}
                    colorText={colorScreen?.colorText}
                    handleHide={handleHide}
                    hideshow={hideshow}
                    activePageContract={activePageContract}
                    roleId={idRole}
                    getListContract={getListContract}
                    activePages={activePage}
                  />
                );
              })}
              {dataListContract?.count > 10 && (
                <PaginationCustom
                  activePage={activePage}
                  itemsCountPerPage={10}
                  totalItemsCount={dataListContract?.count}
                  pageRangeDisplayed={5}
                  handlePageChange={handleChangePage}
                />
              )}
            </div>
          )}
        </div>
        <div className="table-history-contract">
          <MemoHistory colors={colorScreen?.code} colorText={colorScreen?.colorText} screenName={SCREEN_NAME.CONTRACT_MANAGEMENT} />
        </div>
        <FooterMaintenance />
      </div>
    </>
  );
};

export default compose(translate('translations'), withRouter)(Contract);
