import React, { useEffect, useState } from 'react';
import Header from 'components/Header';
import Footer from 'components/Footer';
import { ORDER } from 'constants/endpoints';
import { SCREEN_NAME } from 'helpers/constants';
import { getRole } from 'helpers/helpers';
import 'assets/scss/page/DetailReportUploadInfo/style.scss';
import DetailData from 'components/DetailCenter/DetailData';
import DetailDataList from 'components/DetailCenter/DetailDataList';
import DetailOrder from 'components/DetailCenter/DetailOrder';
import DetailListAllData from 'components/DetailCenter/DetailListAllData';
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import DetailTreeDocumentSetting from 'components/DetailTreeDocumentSetting';
import DetailTimeWorker from 'components/DetailOrderErp/DetailTimeWorker';
import DetailTableUploadReportUploadInfo from 'components/TableUploadReportUploadInfoERP';
import DetailSite from 'components/DetailOrderErp/DetailSite';
import Loading from 'components/Loading/Loading';
import DetailSent from '../../../components/DetailOrderErp/DetailSent';
import DetailResult from '../../../components/DetailOrderErp/DetailResult';
import { pushToast } from 'components/Toast';
import HeaderMasterSetting from 'components/HeaderMasterSetting';
import _ from 'lodash';
import { getDataHistory } from 'actions/authentication';
import { useDispatch } from 'react-redux';
import { getRoleNew } from '../../../helpers/helpers';

const mockData = {
  defectiveReturnContents: '',
  screenName: SCREEN_NAME.REPORT_UPLOAD_INFO
};

const mockDataReport = {
  reportConfirmedAt: '',
  statusReportUploadInfo: false,
  screenName: SCREEN_NAME.REPORT_UPLOAD_INFO
};

const DetailReportUploadInfo = () => {
  const dispatch = useDispatch();
  const [nameRole, setNameRole] = React.useState('');
  const [idRole, setIdRole] = React.useState(null);
  const [dataOrder, setDataOrder] = useState(mockData);
  const [dataDetailReport, setDataDetailReport] = useState(mockDataReport);
  const [data, setData] = useState([]);
  let orderId = process.browser && new URL(window.location.href).searchParams.get('orderId');
  let organizationId = process.browser && new URL(window.location.href).searchParams.get('organizationId') || '';
  let name = (process.browser && new URL(window.location.href).searchParams.get('p')) || 'report_upload_info';
  let back = process.browser && new URL(window.location.href).searchParams.get('back') || '';
  const [p, setP] = React.useState(name);
  const colorScreen = useSelector((store) => store.utils.colorMe);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [dataTmp, setDataTmp] = useState([]);
  const [listDetailScreen, setListDetailScreen] = useState([]);
  const [documentSetting, setDocumentSetting] = useState([]);
  const [dataDetailTimeWorker, setDataDetailTimeWorker] = useState({});
  const listParam = ['comment', 'status', 'name', 'key', 'timestamp', 'url'];

  useEffect(() => {
    getListRoleChild();
  }, []);

  const handleDataPick = () => {
    let pick = documentSetting.map(e => {
      if (dataOrder[e.timestamp]) {
        dataOrder[e.timestamp] = _.pick(dataOrder[e.timestamp], listParam);
      }
      return e.timestamp;
    });
    pick = [
      ...pick,
      'screenName',
      'updateTitle',
      'companyName'
    ];
    return pick;
  };

  const getListRoleChild = async () => {
    setLoading(true);
    const newRole = localStorage.getItem('role');
    try {
      const data = await ORDER.GET_LIST_DETAIL_SETTING({
        arrRole: newRole,
        nameScreen: 'report_upload_info',
        orderId,
        organizationId
      });
      if (data) {
        setListDetailScreen(data?.data?.detailScreen.concat(
          { name: 'rejectMemo', sortNum: 6, status: true }, { name: 'button', sortNum: 6, status: true }
        ));
        setDocumentSetting(data?.data?.documentSettingUploadFile);
      }
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  useEffect(() => {
    setP(name);
  }, [name]);

  const getOrderDetail = async ({ orderId = '' }) => {
    setLoading(true);
    try {
      const data = await ORDER.GET_DETAIL_CANCEL_INFO({
        orderId,
        organizationId: organizationId || '',
        screenName: 'report_upload_info'
      });
      if (data) {
        setData(data?.data);
        setDataDetailTimeWorker(data?.data);
      }
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (data) {
      setDataOrder({
        ...dataOrder,
        defectiveReturnContents: data.defectiveReturnContents
      });

      setDataDetailReport({
        ...dataDetailReport,
        reportConfirmedAt: data.reportConfirmedAt
      });
    }
  }, [data]);

  useEffect(() => {
    const newRole = localStorage.getItem('role');
    setIdRole(newRole);
    if (newRole) {
      setNameRole(getRoleNew({ idRole: newRole }));
    }

    if (orderId) {
      getOrderDetail({ orderId });
    }
  }, [orderId]);

  const onReload = () => {
    getOrderDetail({ orderId });
    dispatch(getDataHistory({ orderId, screenName: SCREEN_NAME.REPORT_UPLOAD_INFO, organizationId }));
  };

  const handleSave = () => {
    saveReportOrder({ body: { ...dataTmp, updateTitle: '保存', companyName: localStorage.getItem('companyName'), nameApplication: '報告書' }, orderId });
  };

  const onChangeData = (tmp) => {
    setDataTmp(tmp);
  };

  const saveReportOrder = async ({ body, orderId }) => {
    try {
      setLoading(true);
      const data = await ORDER.UPDATE_REPORT_ORDER_TEMP({ body, orderId, organizationId });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
        setLoading(false);
      } else {
        pushToast('success', 'Update Report success');
        setLoading(false);
        onReload();
        return history.push(`/list?p=report_upload_info`);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  return (
    <div className='wrapper-detail-report-upload-info'>
      <Loading loading={loading} />
      <div className='main'>
        {['2', 2, '3', 3, '5', 5, '6', 6].includes(idRole) ? <HeaderMasterSetting idRole={idRole} color={colorScreen?.code} colorText={colorScreen?.colorText} /> : <Header p={p} idRole={idRole} color={colorScreen?.code} colorText={colorScreen?.colorText} /> }
        <div className="body-wrapper">
          <div className="logout_btn">
            <Link to={`${back === 'confirm' ? '/confirm-page' : back === 'mypage' ? '/my_page': `list?p=${p}`}`}>
              <span className="mypage_ab_custom">戻る</span>
            </Link>
          </div>
          {_.sortBy(listDetailScreen, 'sortNum')?.map((item, index) => {
            if (item.status) {
              switch (item.name) {
                case 'clientCompany':
                  return <DetailOrder data={data} p={p} key={index} />;
                case 'applicationCompany':
                  return <DetailData data={data} p={p} link={false} key={index} />;
                case 'mattersSent':
                  return <DetailSent data={data} isDisabledManagement={true} isDisabledErp={true} key={index} role={nameRole}/>;
                case 'visitDateWorker':
                  return <DetailTimeWorker screenName={SCREEN_NAME.REPORT_UPLOAD_INFO} data={dataDetailTimeWorker} key={index} />;
                case 'result':
                  return <DetailSite data={data} key={index} />;
                case 'acceptanceInformation1':
                  return <DetailResult orderId={orderId} key={index} screenName={SCREEN_NAME.REPORT_UPLOAD_INFO} />;
                case 'report':
                  return <DetailTableUploadReportUploadInfo data={data} onReload={onReload} key={index} idRole={idRole} back={back}
                                                            documentSetting={documentSetting}
                                                            onChangeData={onChangeData} />;
                case 'button':
                  return (
                    <div className='upload-wrapper button--center-plus'>
                      <button className='btn-modal' onClick={handleSave}>保存</button>
                    </div>
                  );
                case 'detailInfo':
                  return <DetailListAllData data={data} screenName={SCREEN_NAME.REPORT_UPLOAD_INFO} key={index} />;
                case 'document':
                  return <DetailTreeDocumentSetting orderId={orderId} order={data} index={index} />;
                case 'history':
                  return <DetailDataList datePick={false} screenName={SCREEN_NAME.REPORT_UPLOAD_INFO} orderId={orderId}
                                         key={index} />;
                default:
                  return '';
              }
            }
          })}
        </div>
        <Footer color={colorScreen?.code} colorText={colorScreen?.colorText} />
      </div>
    </div>
  );
};

export default DetailReportUploadInfo;
