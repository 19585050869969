import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import GroupButtonProcess from '../GroupButtonProcess';
import { PRODUCT_ENDPOINT } from '../../../../../constants/endpoints';
import './style.scss';

const TableProcess = ({ onChangeProcess }) => {
  const [allService, setAllService] = useState([]);
  const [chooseService, setChooseService] = useState([]);

  useEffect(() => {
    let newChooseService = [];
    if (allService.length > 0) {
      allService.map((item) => {
        if (item?.status) {
          newChooseService.push(item?.serviceId);
        }
      });
      setChooseService(newChooseService);
    }
  }, [allService]);

  useEffect(() => {
    onChangeProcess(chooseService);
  }, [chooseService]);


  const handleSelectService = ({ idService = '', status = false }) => {
    let newChooseService = [...chooseService];
    if (status) {
      newChooseService.push(idService);
      setChooseService(newChooseService);
    } else {
      newChooseService = newChooseService.filter(item => item !== idService);
      setChooseService(newChooseService);
    }
  };

  const getAllService = async () => {
    try {
      const data = await PRODUCT_ENDPOINT.GET_ALL_SERVICE();
      setAllService(data?.data?.body);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getAllService();
  }, []);

  return (
    <div className="wrapper-table-process">
      <table>
        <tbody>
        {allService &&
        allService.map((item, index) => (
          <tr key={index}>
            <th>
              <div className="p-2 d-flex table-process-col">
                <div className="wrapper-id">{index + 1}</div>
                {item?.name}
              </div>
            </th>
            <td>
              <div className="p-2 d-flex table-process-right col-6">
                <GroupButtonProcess essential={item?.status} value={item?.serviceId} onChange={handleSelectService}/>
              </div>
            </td>
          </tr>
        ))}
        </tbody>
      </table>
      <div className="d-flex justify-content-center align-items-center">
        {/*<button className="btn btn-danger button-anken-submit mt-3">登録</button>*/}
      </div>
    </div>
  );
};

export default compose(withRouter)(TableProcess);
