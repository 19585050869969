import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import '../../assets/scss/page/DetailConditionInfo/style.scss';
import _ from 'lodash';
import { getRole } from '../../helpers/helpers';
import { ROLE } from '../../helpers/constants';
import moment from 'moment';
import '../../assets/scss/components/ModalSelect/style.scss';
import Loading from 'components/Loading/Loading';
import { PREPARATION_INFO } from '../../constants/endpoints';
import { pushToast } from '../Toast/index.jsx';

const ModalSelect = (props) => {
  const {
    toggle = () => {},
    modal = false,
    staffId,
    orderId,
    updateConfirm,
    partnerName,
    statusConditionInfo = true,
    visitDate,
    organizationId = ''
  } = props;
  const [dataChecked, setDataChecked] = useState(Array.isArray(staffId) ? [...staffId] : []);
  const [dataUpdate, setDataUpdate] = useState();
  const [loading, setLoading] = useState(false);
  const [leader, setLeader] = useState(Array.isArray(staffId) ? staffId[0] : '');
  const [listDetailStaff, setListDetailStaff] = useState([]);
  const roleName = getRole({ idRole: localStorage.getItem('role') });
  const disabledUpdate =
    roleName === ROLE.PARTNER &&
    statusConditionInfo === false &&
    Array.isArray(staffId) &&
    staffId.length > 0 &&
    moment(visitDate) <= moment();

  useEffect(() => {
    setDataUpdate(dataChecked);
  }, [dataChecked]);

  const submitStaff = async () => {
    await updateConfirm(dataChecked, leader);
    getStaffsByOrderPreparation({ orderId });
  };

  useEffect(() => {
    getStaffsByOrderPreparation({ orderId });
  }, []);

  const handleChangeCheckItem = (e) => {
    let arrTmp = dataChecked || [];
    if (e.target.checked) {
      arrTmp.push(e.target.value);
      if (arrTmp.length == 1) setLeader(e.target.value);
    } else {
      arrTmp.splice(arrTmp.indexOf(e.target.value), 1);
      if (leader == e.target.value) setLeader('');
    }
    setDataChecked(arrTmp);
    const newData = { ...dataUpdate, data: arrTmp };
    setDataUpdate(newData);
  };

  const handleChangeLeader = (e) => {
    setLeader(e.target.value);
  };

  const getStaffsByOrderPreparation = async ({ orderId }) => {
    try {
      setLoading(true);
      const data = await PREPARATION_INFO.GET_STAFF_BY_ORDER_PREPARATION({ orderId, organizationId });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
        setLoading(false);
      } else {
        setListDetailStaff(data?.data);
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const renderListDetailStaff = (item, index) => {
    return (
      <tr key={index}>
        <td>
          <input
            type="radio"
            value={item?.loginId}
            checked={leader == item?.loginId && dataChecked.includes(item.loginId)}
            disabled={!dataChecked.includes(item.loginId) || disabledUpdate}
            onChange={(e) => handleChangeLeader(e)}
          />
        </td>
        <td>
          <input
            type="checkbox"
            value={item.loginId}
            onChange={(e) => handleChangeCheckItem(e)}
            checked={dataChecked.includes(item.loginId)}
            disabled={disabledUpdate}
          />
        </td>
        <td>{!_.isEmpty(item?.branchName) ? item?.branchName : item?.abbreviationName}</td>
        <td>
          {item?.surName} {item?.name}
        </td>
        <td>{item?.contact}</td>
        <td>{item?.calendar}</td>
        <td>{item?.distance}</td>
        <td className={!item?.areaInOut && 'text-danger'}>
        {item?.areaInOut ? `エリア内` : `エリア外`}</td>
      </tr>
    );
  };

  return (
    <Modal className="form-modal-contract modal-detail" isOpen={modal} toggle={() => toggle('project')}>
      <ModalHeader toggle={() => toggle('project')}>作業員選択</ModalHeader>
      <ModalBody>
        <Loading loading={loading} />
        <div className="modal-select-scrollbar">
          <table className="text-center table">
            <thead>
              <tr className="text-black">
                <th>主</th>
                <th>副</th>
                <th>会社名</th>
                <th>氏名</th>
                <th>連絡先</th>
                <th>スケジュール</th>
                <th>距離</th>
                <th>対応エリア</th>
              </tr>
            </thead>
            <tbody>
              {listDetailStaff && listDetailStaff?.map((item, index) => renderListDetailStaff(item, index))}
            </tbody>
          </table>
        </div>
        <button className="btn-update" onClick={() => submitStaff()} disabled={disabledUpdate}>
          登録
        </button>
      </ModalBody>
    </Modal>
  );
};

export default ModalSelect;
