import React, { useEffect, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ja from 'date-fns/locale/ja'; // the locale you want
registerLocale('ja', ja);

const DateTimePicker = ({ disabled = false, date = null, onChangeDate = () => {}, name="", id="", isRequired = false }) => {
  const [startDate, setStartDate] = useState(date);

  useEffect(() => {
    setStartDate(date);
  }, [date]);

  const handleChange = (date) => {
    onChangeDate(date);
    setStartDate(date);
  };

  const keepRequried = () => {
    isRequired && !startDate && document.getElementById(id).classList.add('isRequired');
  };

  return (
    <div className="date-time-picker-wrapper">
      <DatePicker
        selected={startDate}
        onChange={(date) => handleChange(date)}
        disabled={disabled}
        locale="ja"
        dateFormat="yyyy/MM/dd"
        dateFormatCalendar="yyyy年 MM月"
        name={name}
        onCalendarClose={keepRequried}
        id={id}
      />
    </div>
  );
};

export default DateTimePicker;
