import React from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import './style.scss';

const DetailData = ({ data, p = 'receive_info', link = true, title= '現状情報' }) => {
  return (
    <div className="detail-data-wrapper">
      {link && <div className="logout_btn">
        <Link to={`list?p=${p}`}>
          <span className="mypage_ab">戻る</span>
        </Link>
      </div>}
      <div className="detail-cancel-table-wrapper">
        <div className="title-absolute">{title}</div>
        <table className="conditTabFix">
          <tbody>
            <tr>
              <th width="150">案件名</th>
              <td>{data?.caseName}</td>
              <th width="150">管理コード</th>
              <td>{data?.managementCode}</td>
            </tr>
            <tr>
              <th>工程</th>
              <td>{data?.serviceName}</td>

              <th>都道府県</th>
              <td>{data?.prefecturesVisited}</td>
            </tr>
            <tr>
              <th>申込会社名</th>
              <td>{data?.applicantCompanyName}</td>
              <th>訪問先拠点住所</th>
              <td>{ _.compact([1, 2, 3, 4, 5].map(e => { return data?.[`visitedBaseAddress${e}`] })).join('')}</td>
            </tr>
            <tr>
              <th>物件名</th>
              <td>{data?.installationSiteName}</td>
              <th>訪問先建物名</th>
              <td>{data?.visitedBuildingName}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DetailData;
