import React, { useEffect, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ja from 'date-fns/locale/ja'; // the locale you want
registerLocale('ja', ja);

const DataTimeSearch = ({ date = null, onChangeDate = () => {} , value ='', name = ''}) => {
  const [startDate, setStartDate] = useState(date);

  useEffect(() => {
    if (date) {
      setStartDate(date);
    }
  }, [date]);

  useEffect(() => {
      setStartDate(value);
  }, [value]);

  const handleChange = (date) => {
    onChangeDate(date ,name );
    setStartDate(date);
  };
  return (
    <div className="date-time-picker-wrapper">
      <DatePicker
        selected={startDate ? new Date(startDate): ''}
        onChange={(date) => handleChange(date)}
        locale="ja"
        dateFormat="yyyy/MM/dd"
        dateFormatCalendar="yyyy年 MM月"
      />
    </div>
  );
};

export default DataTimeSearch;
