import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { Helmet } from 'react-helmet';
// import HeaderMaintenance from 'components/HeaderMaintenance';
// import BreadcrumbMaintenance from 'components/BreadMaintenance';
import FooterMaintenance from 'components/FooterMaintenance';
import TableListAnken from './TableListAnken';

import 'assets/scss/page/AnkenMaster/style.scss';
import { PRODUCT_ENDPOINT } from 'constants/endpoints';
import PaginationCustom from 'components/PaginationCustom';
import HeaderMasterSetting from 'components/HeaderMasterSetting';
import { Link } from 'react-router-dom';

const INIT_PAGINATION = {
  page: 1,
  limit: 10,
};
const mockDataSearch = {
  page: '1',
  limit: '10',
  name: '',
  customerName: '',
};

const AnkenMaster = () => {
  const breadcrumb = [
    { name: 'CENTER MASTER', link: '/center_master_all' },
    { name: '案件マスタ', link: '/anken_master' },
  ];
  const [fontSize, setFontSizeAll] = useState('small');
  const [allProduct, setAllProduct] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [dataSearch, setDataSearch] = useState(mockDataSearch);

  const getAllProduct = async ({
    page = INIT_PAGINATION.page,
    limit = INIT_PAGINATION.limit,
    name = '',
    customerName = '',
  }) => {
    try {
      const data = await PRODUCT_ENDPOINT.GET_ALL_PRODUCT({
        page: page,
        limit: limit,
        name: name,
        customerName: customerName,
      });
      if (data && data.data) {
        setAllProduct(data.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  useEffect(() => {
    getAllProduct({ page: activePage });
  }, [activePage]);

  const handleChangeSearch = (e) => {
    const typeName = e.target.name;
    let newData = { ...dataSearch, [typeName]: e.target.value };
    setDataSearch(newData);
  };

  const onSubmit = () => {
    getAllProduct(dataSearch);
  };

  return (
    <div className="wrapper-anken-master">
      <Helmet>
        <title>案件マスタ</title>
      </Helmet>
      {/* <HeaderMaintenance setFontSizeAll={setFontSizeAll} /> */}
      <HeaderMasterSetting />
      <div className="body pb-4">
        <div className="wrapper-main">
          {/* <BreadcrumbMaintenance breadcrumb={breadcrumb} fontSize={fontSize} /> */}
          <div className="flex-row-reverse group-button text-right">
            <Link to="/modify_anken?modify=1">
              <button className={`btn button-submit ${fontSize}`}>
                <i className="fas fa-pencil-alt" />
                新規プロダクト登録
              </button>
            </Link>
          </div>
          <div className="title-label">
            <h3 className={`text-center mt-2 font-weight-bold ${fontSize}`}>検索条件</h3>
          </div>
          <div className="row search-wrapper">
            <div className="col-12 col-md-6 d-flex mb-1 pr-md-0">
              <div className="col-4 h-100 p-2 label-input">
                <label className={`${fontSize}`}>案件名</label>
              </div>
              <div className="h-100 col-8 p-2 input-wrapper">
                <input
                  value={dataSearch?.customerName}
                  onChange={handleChangeSearch}
                  name="customerName"
                  className={`form-control ${fontSize}`}
                />
              </div>
            </div>
            <div className="col-12 col-md-6 d-flex mb-1 px-md-0 pl-xl-0">
              <div className="col-4 h-100 p-2 label-input">
                <label className={`${fontSize}`}>クライアント名 </label>
              </div>
              <div className="h-100 col-8 p-2 input-wrapper">
                <input
                  value={dataSearch?.name}
                  onChange={handleChangeSearch}
                  name="name"
                  className={`form-control ${fontSize}`}
                />
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center align-items-center group-button">
            <button className={`btn button-edit mr-2 px-4 ${fontSize}`} onClick={() => onSubmit()}>
              検索
            </button>
          </div>
          <div className="wrapper-result">
            <div className="result-title">
              <h3 className={`font-weight-bolder ${fontSize}`}>検索結果：29件</h3>
            </div>
            <TableListAnken fontSize={fontSize} allProduct={allProduct?.body} activePage={activePage} />
            <div className="pt-3">
              {allProduct && allProduct.body && allProduct?.count && (
                <PaginationCustom
                  activePage={activePage}
                  itemsCountPerPage={10}
                  totalItemsCount={allProduct && allProduct?.count}
                  pageRangeDisplayed={5}
                  handlePageChange={handlePageChange}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <FooterMaintenance />
    </div>
  );
};

export default compose(withRouter)(AnkenMaster);
