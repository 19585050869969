import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import HeaderMasterSetting from '../../../components/HeaderMasterSetting';
import AutocompleteAddress from 'components/AutocompleteAddress';
import { pushToast } from '../../../components/Toast';
import { BRANCH_MASTER_ENDPOINT, ORGANIZATION_ENDPOINT } from '../../../constants/endpoints';
import { SCREEN_NAME, DEFAULT_ADDRESS, PARAMS_REQUIRED_BRANCH } from '../../../helpers/constants';
import { validateRequried } from '../../../helpers/helpers';
import 'assets/scss/page/BranchMaster/UpdateBranchMasterScreen.scss';
import BorderGroupForm from 'components/BorderGroupForm';
import { withNamespaces } from 'react-i18next';
import AreaSelection from 'components/AreaSelection';
import { AREAS_PERMITTED } from 'helpers/areas';
import _ from 'lodash';
import FooterMaintenance from "components/FooterMaintenance"

const mockData = {
  branchName: '',
  branchNameKana: '',
  branchAbbreviation: '',
  representativeName: '',
  representativeNameKana: '',
  postNumberFirst: '',
  postNumberLast: '',
  prefecture: '',
  residence: '',
  domicileKana: '',
  screenName : SCREEN_NAME.BRANCH_MASTER,
  areas: {}
};

const EditModifyBranch = () => {
  let branchId = (process.browser && new URL(window.location.href).searchParams.get('branchId')) || null;
  const [dataBranch, setDataBranch] = useState(mockData);
  const [data, setData] = useState('');
  const [organization, setOrganization] = useState({});
  const colorScreen = useSelector((store) => store.utils.colorMe);
  const [prefectureAreas, setPrefectureAreas] = useState({});
  const prefectureNameKanji = _.groupBy(AREAS_PERMITTED, 'prefectureNameKanji');

  const getDetailBranchMaster = async ({ branchId = '' }) => {
    try {
      const data = await BRANCH_MASTER_ENDPOINT.GET_DETAIL_BRANCH({ branchId: branchId });
      if (data) {
        setData(data.data);
        const dataHandler = _.flatten(Object.values(data?.data?.areas || {})).map(e => {
          return !_.isEmpty(data?.data?.prefectureAreas[e]) ? {[e]: data?.data?.prefectureAreas[e]} : {[e]: _.compact(_.map(prefectureNameKanji[e] || [], 'cityNameKanji'))}
        });
        const newPrefectureAreas = _.isEmpty(dataHandler) ? {} : Object.assign(...dataHandler);
        setPrefectureAreas(newPrefectureAreas || {});
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (branchId) {
      getDetailBranchMaster({ branchId: branchId });
      getOrganization();
    }
  }, [branchId]);

  useEffect(() => {
    if(dataBranch) {
      validateRequried({data: dataBranch, arrField: PARAMS_REQUIRED_BRANCH});
    }
  }, [dataBranch]);

  useEffect(() => {
    if (data) {
      setDataBranch({
        ...dataBranch,
        branchName: data.branchName,
        branchNameKana: data.branchNameKana,
        branchAbbreviation: data.branchAbbreviation,
        representativeName: data.representativeName,
        representativeNameKana: data.representativeNameKana,
        postNumberFirst: data.postNumberFirst,
        postNumberLast: data.postNumberLast,
        prefecture: data.prefecture,
        residence: data.residence,
        domicileKana: data.domicileKana,
        areas: data.areas || {},
        latitude: data.latitude,
        longitude: data.longitude,
        phoneNumber: data.phoneNumber,
        branchAbbreviationJP: data.branchAbbreviationJP
      });
    }
  }, [data]);

  const getOrganization = async () => {
    try {
      const data = await ORGANIZATION_ENDPOINT.GET_ORAGINIZATION_LOGIN();
      if (data) {
        setOrganization(data?.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const updateBranchMaster = async ({ body, branchId }) => {
    try {
      const data = await BRANCH_MASTER_ENDPOINT.UPDATE_BRANCH_MASTER({ body: body, branchId: branchId });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
      } else {
        pushToast('success', 'Update branch success');
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleChangeText = (e) => {
    let type = e.target.name;
    let newData = { ...dataBranch, [type]: e.target.value };
    setDataBranch(newData);
  };

  const onClickSubmit = () => {
    if (dataBranch && branchId) {
      const body = {...dataBranch, prefectureAreas, updateTitle: `登録 拠点登録 ${dataBranch?.branchName || ''}`};
      updateBranchMaster({ body, branchId: branchId });
    }
  };

  const onChangePrefectureAreas = (data) => {
    setPrefectureAreas(data);
  }

  const onChangeAreas = (areas) => {
    const newData = { ...dataBranch, areas };
    setDataBranch(newData);
    const values = _.flatten(Object.values(newData?.areas || {}));
    const dataHandler = values.map(e => {
      return Object.keys(prefectureAreas || {}).includes(e) ? {[e]: prefectureAreas[e]} : {[e]: _.compact(_.map(prefectureNameKanji[e] || [], 'cityNameKanji'))}
    })
    const newPrefectureAreas = _.isEmpty(dataHandler) ? {} : Object.assign(...dataHandler);
    setPrefectureAreas(newPrefectureAreas);
  }

  return (
    <div className="main-modify-branch-master edit-branch-master-screen">
      <HeaderMasterSetting checkName={'/branch-master'} color={colorScreen?.code} colorText={colorScreen?.colorText} />
      <BorderGroupForm
        color={colorScreen?.code}
      >
      <div className="body-wrapper mb-1">
        <div align="center" className="search">
          <div>
            <table className="edit-branch-master-screen__table">
            <tbody>
              <tr>
                <th className="header-title" colSpan="4">拠点情報</th>
              </tr>
              <tr>
                <th>拠点名</th>
                <td>
                  <input
                    onChange={handleChangeText}
                    value={dataBranch.branchName}
                    name="branchName"
                    id="branchName"
                  />
                </td>
                <th>電話番号</th>
                <td>
                  <input
                    onChange={handleChangeText}
                    value={dataBranch.phoneNumber}
                    name="phoneNumber"
                    type="number"
                  />
                </td>
              </tr>
              <tr>
                <th>フリガナ</th>
                <td colSpan="3">
                  <input
                    onChange={handleChangeText}
                    value={dataBranch.branchNameKana}
                    name="branchNameKana"
                    id="branchNameKana"
                  />
                </td>
              </tr>
              <tr>
                <th>拠点略称名</th>
                <td>
                  <input
                    onChange={handleChangeText}
                    value={dataBranch.branchAbbreviationJP}
                    name="branchAbbreviationJP"
                    id="branchAbbreviationJP"
                  />
                </td>
                <th>システム表示略称（英字2～4文字）</th>
                <td>
                  <input
                    value={dataBranch.branchAbbreviation}
                    onChange={handleChangeText}
                    name="branchAbbreviation"
                    id="branchAbbreviation"
                  />
                </td>
              </tr>
              <tr>
                <th>代表者</th>
                <td>
                  <input
                    onChange={handleChangeText}
                    value={dataBranch.representativeName}
                    name="representativeName"
                  />
                </td>
                <th>フリガナ</th>
                <td>
                  <input
                    onChange={handleChangeText}
                    value={dataBranch.representativeNameKana}
                    name="representativeNameKana"
                  />
                </td>
              </tr>
              <tr>
                <th>郵便番号</th>
                <td className="inline">
                  <input
                    value={dataBranch.postNumberFirst}
                    onChange={handleChangeText}
                    name="postNumberFirst"
                  />
                  <input
                    value={dataBranch.postNumberLast}
                    onChange={handleChangeText}
                    name="postNumberLast"
                  />
                </td>
                <th>都道府県</th>
                <td>
                  <select
                    name="prefecture"
                    value={dataBranch.prefecture}
                    onChange={handleChangeText}
                  >
                    {['', ...DEFAULT_ADDRESS].map((item, index) => {
                      return (
                        <option value={item} key={index}>
                          {item}
                        </option>
                      );
                    })}
                  </select>
                </td>
              </tr>
              <tr>
                <th>住所</th>
                <td colSpan="3">
                  <AutocompleteAddress
                    name="address"
                    address={dataBranch?.residence}
                    onChange={(address) => { setDataBranch({ ...dataBranch, residence: address }) }}
                    onSelectAddress={({ address, latitude, longitude }) => { setDataBranch({ ...dataBranch, residence: address, latitude, longitude }) }}
                  />
                </td>
              </tr>
              <tr>
                <th>フリガナ</th>
                <td colSpan="3">
                  <input
                    onChange={handleChangeText}
                    value={dataBranch.domicileKana}
                    name="domicileKana"
                  />
                </td>
              </tr>
              <tr>
                <th>緯度</th>
                <td>
                  <input
                    value={dataBranch?.latitude}
                    onChange={handleChangeText}
                    name="latitude"
                    id="latitude"
                  />
                </td>
                <th>経度</th>
                <td>
                  <input
                    value={dataBranch?.longitude}
                    onChange={handleChangeText}
                    name="longitude"
                    id="longitude"
                  />
                </td>
              </tr>
              <tr>
                <th>対応エリア設定</th>
                <td colSpan="3">
                  <AreaSelection
                    activeArea={{ ...dataBranch?.areas }}
                    prefectureAreas={prefectureAreas}
                    onChangePrefectureAreas={onChangePrefectureAreas}
                    onChange={(areas) => { onChangeAreas(areas) }}
                  />
                </td>
              </tr>
            </tbody>
            </table>
            <button
              className="btn btn-edit-branch-master"
              onClick={onClickSubmit}
            >
              保存
            </button>
          </div>
        </div>
      </div>
      </BorderGroupForm>
      <FooterMaintenance/>
    </div>
  );
};

export default withNamespaces('translations')(EditModifyBranch);
