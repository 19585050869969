import React, { useEffect, useState } from 'react';
import { translate } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, withRouter, useHistory } from 'react-router-dom';
import { compose } from 'redux';
import Footer from '../../../../components/Footer';
import HeaderMasterSetting from '../../../../components/HeaderMasterSetting';
import LoadingNew from '../../../../components/LoadingNew';
import MemoHistory from '../../../../components/MemoHistory';
import { pushToast } from '../../../../components/Toast';
import { CONTRACT_ENDPOINTS } from '../../../../constants/endpoints';
import { SCREEN_NAME } from '../../../../helpers/constants';

const MasterTarifEdit = (props) => {
  let tariffId = process.browser && new URL(window.location.href).searchParams.get('tariffId');
  const colorScreen = useSelector((store) => store.utils.colorMe);
  const history = useHistory();
  const [listItem, setListItem] = useState([]);
  const [loading, setLoading] = useState(false);

  const onReturn = () => {
    history.goBack();
  };

  const handleChangeItem = (e, index) => {
    const valueField = e.target.value;
    const name = e.target.name;
    const newData = listItem?.map((ele, eleI) => {
      if (index === eleI) {
        return { ...ele, [name]: valueField };
      } else {
        return ele;
      }
    });
    setListItem(newData);
  };

  const getListSettingEdit = async ({ tariffId = '' }) => {
    setLoading(true);
    try {
      const data = await CONTRACT_ENDPOINTS.GET_TARIFF_SETTING_EDIT({
        tariffId: tariffId,
      });
      if (data) {
        setListItem(data?.data?.tariffSetting);
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const updateTariffSettingClassfication = async ({ body = [], tariffId = '' }) => {
    try {
      const data = await CONTRACT_ENDPOINTS.UPDATE_TARIFF_SETTING_CLASSIFICATION({ body: body, tariffId: tariffId });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
      } else {
        pushToast('success', 'Update tariff setting classfication success');
        getListSettingEdit({ tariffId: tariffId });
      }
    } catch (errorMessage) {
      console.log('errorMessage: ', errorMessage);
    }
  };

  const submitUpdate = () => {
    let newData =
      listItem &&
      listItem.map((item) => {
        return {
          taxDivision: item?.taxDivision,
          form: item?.form || '',
          tariffSettingId: item?.tariffSettingId,
        };
      });
    updateTariffSettingClassfication({ body: { tariffSettings: newData , screenName : SCREEN_NAME.CONTRACT_MANAGEMENT }, tariffId: tariffId });
  };

  useEffect(() => {
    getListSettingEdit({ tariffId: tariffId });
  }, [tariffId]);

  return (
    <>
      <div className="main">
        <LoadingNew loading={loading} />
        <HeaderMasterSetting color={colorScreen?.code} colorText={colorScreen?.colorText} checkName="/contract" />
        <div className="block-contract">
          <div className="list-table">
            <Link to={'/contract/master-tarif'}>
              <button className="btn-back">戻る</button>
            </Link>
            <div className="table-list-contract">
              <div className="title-recruitment">タリフマスタ</div>
              <div className="list-button-contact">
                <Link to={`/contract/billing-master?tariffId=${tariffId}`}>
                  <button className="item">請求マスタ</button>
                </Link>
                <Link to={`/contract/payment-master?tariffId=${tariffId}`}>
                  <button className="item">支払マスタ</button>
                </Link>
              </div>
              <table className="table table-bordered">
                <tbody>
                  <tr>
                    <td>Monitoring</td>
                    <td>Project</td>
                    <td>Product</td>
                    <td>Service</td>
                    <td>契約状態</td>
                    <td>操作</td>
                    <td>操作</td>
                  </tr>
                  {listItem?.map((itemIn, index) => {
                    return (
                      <tr key={index}>
                        <td>{itemIn.recruitmentEstimateId}</td>
                        <td>{itemIn?.projectName}</td>
                        <td>{itemIn?.productName}</td>
                        <td>{itemIn?.serviceName}</td>
                        <td>{itemIn?.title}</td>
                        <td>
                          <select
                            className="select-contract"
                            value={itemIn?.taxDivision}
                            name="taxDivision"
                            onChange={(e) => handleChangeItem(e, index)}
                          >
                            <option value="">---</option>
                            <option value="外税">外税</option>
                          </select>
                        </td>
                        <td>
                          <select
                            className="select-contract"
                            value={itemIn?.form}
                            name="form"
                            onChange={(e) => handleChangeItem(e, index)}
                          >
                            <option value="">---</option>
                            <option value="選択">選択</option>
                          </select>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <div className="list-button-contact text-right">
                <button className="item  mr-0" onClick={() => submitUpdate()}>
                  設定を保存
                </button>
              </div>
            </div>
          </div>
        </div>
        <MemoHistory colors={colorScreen?.code} colorText={colorScreen?.colorText} screenName={SCREEN_NAME.CONTRACT_MANAGEMENT}/>
        <Footer color={colorScreen?.code} colorText={colorScreen?.colorText} />
      </div>
    </>
  );
};

export default compose(translate('translations'), withRouter)(MasterTarifEdit);
