import { call, takeLatest, put } from 'redux-saga/effects';
import { ACTIONS } from '../actions/chat';
import { showLoading, hideLoading } from '../actions/utils';
import { callApi } from '../helpers/callApi';
// import { pushToast } from '../components/Toast';

export function* handleChat() {
  yield takeLatest(ACTIONS.GET_LIST_ROOM, function* ({ nameRoom }) {
    yield put(showLoading());
    try {
      const data = yield call(callApi, {}, { endpoint: `chat-manager/room/list-room?name=${nameRoom || ''}`, method: 'GET' });
      if (data) {
        yield put({ type: ACTIONS.SAVE_LIST_ROOM, listRoom: data.data });
      }
      yield put(hideLoading());
    } catch (error) {
      yield put(hideLoading());
      console.log('error: ', error);
    }
  })

  yield takeLatest(ACTIONS.GET_LIST_MESSAGE_IN_ROOM, function* (payload) {
    yield put(showLoading());
    try {
      const data = yield call(callApi, {}, { endpoint: `chat-manager/chat/list-chat-room?roomId=${payload.payload.roomId}`, method: 'GET' });
      if (data) {
        yield put({ type: ACTIONS.SAVE_LIST_MESSAGE_IN_ROOM, listMessage: data.data });
      }
      yield put(hideLoading());
    } catch (error) {
      yield put(hideLoading());
      console.log('error: ', error);
    }
  })

  yield takeLatest(ACTIONS.GET_LIST_USER_IN_ROOM, function* (payload) {
    yield put(showLoading());

    try {
      const data = yield call(callApi, {}, { endpoint: `chat-manager/room/list-user-room?roomId=${payload.payload.roomId}`, method: 'GET' });
      if (data) {
        yield put({ type: ACTIONS.SAVE_LIST_USER_IN_ROOM, listUser: data.data });
      }
      yield put(hideLoading());
    } catch (error) {
      yield put(hideLoading());
      console.log('error: ', error);
    }
  })

  yield takeLatest(ACTIONS.GET_LIST_CHAT_USER_BY_USER, function* (payload) {
    yield put(showLoading());

    try {
      const data = yield call(callApi, {}, { endpoint: `chat-manager/room/list-user-chat?page=0&limit=10&name=`, method: 'GET' });
      if (data) {
        yield put({ type: ACTIONS.SAVE_LIST_CHAT_USER_BY_USER, listChatUserByUser: data.data.users });
      }
      yield put(hideLoading());
    } catch (error) {
      yield put(hideLoading());
      console.log('error: ', error);
    }
  })

  yield takeLatest(ACTIONS.GET_LIST_MESSAGE_IN_USER_BY_USER, function* (payload) {
    yield put(showLoading());
    try {
      const data = yield call(callApi, {}, { endpoint: `chat-manager/chat/list-message?userId=${payload.payload.userId}`, method: 'GET' });
      if (data) {
        yield put({ type: ACTIONS.SAVE_LIST_MESSAGE_IN_USER_BY_USER, listMessageUserByUser: data.data });
      }
      yield put(hideLoading());
    } catch (error) {
      yield put(hideLoading());
      console.log('error: ', error);
    }
  })
}

