import React, { useState, useEffect } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { ROLE } from '../../../../helpers/constants';
import { PRODUCT_ENDPOINT } from '../../../../constants/endpoints';
import PaginationCustom from '../../../../components/PaginationCustom';
import CollapseTableSetting from '../CollapseTableSetting';
import Loading from '../../../../components/Loading/Loading';
import _ from 'lodash';

const INIT_AFFILIATE_PAGINATION = {
    page: 1,
    limit: 10
};
const TableListProduct = ({ role = ROLE.PARTNER, color, roleDetail, t, p, idRole ,colorText }) => {
    const [activePagess, setActivePagess] = useState(1);
    const [listProduct, setListProduct] = useState('');
    const [product, setProduct] = useState(null);
    const [loading, setLoading] = useState(false);

    const getAllProductByUser = async ({ page = INIT_AFFILIATE_PAGINATION.page,
        limit = INIT_AFFILIATE_PAGINATION.limit, }) => {
        setLoading(true);
        try {
            const data = await PRODUCT_ENDPOINT.GET_ALL_PRODUCT_BY_USER({ page: page, limit: limit });
            if (data) {
                setListProduct(data?.data?.body);
                setProduct(data?.data?.count)
                setLoading(false);
            }
        } catch (e) {
            console.log(e);
            setLoading(false);
        }
    };

    useEffect(() => {
        getAllProductByUser({ page: activePagess, limit: 10 })
    }, [activePagess])

    useEffect(() => {
        setActivePagess(1);
    }, [p]);

    const handlePageChangess = (pageNumber) => {
        setActivePagess(pageNumber);
    };

    const [hideshow, setHideshow] = useState('');
    const handleHide = (id) => {
        if (hideshow === id) {
            setHideshow('');
        } else {
            setHideshow(id);
        }
    }

    return (
        <div className="table-menu-wrapper">
            <Loading loading={loading} />
            {listProduct && listProduct.map((item, index) => {
                return (
                    <CollapseTableSetting
                        role={role}
                        key={index}
                        item={item}
                        color={color}
                        colorText={colorText}
                        handleHide={handleHide}
                        hideshow={hideshow}
                        roleDetail={roleDetail} idRole={idRole} t={t} p={p}
                    />
                )
            })}
            {!_.isEmpty(listProduct) && product > 10 && (
                <PaginationCustom
                    activePage={activePagess}
                    itemsCountPerPage={10}
                    totalItemsCount={product}
                    pageRangeDisplayed={5}
                    handlePageChange={handlePageChangess}
                />
            )}
        </div>
    );
};


export default TableListProduct;
