import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';

const DetailReport = ({ data, orderId, organizationId, partnerId, documentSetting = [] }) => {
  const {statusReportUploadInfo } = data;
  const [dataDownload, setDataDownload] = useState([])

  useEffect(() => {
    if(statusReportUploadInfo) {
      setDataDownload(documentSetting)
    } else {
      const nameFileReportUploadInfo = data?.nameFileReportUploadInfo?.filter(e => e.isReportUploadInfo) || [];

      setDataDownload(nameFileReportUploadInfo)
    }
  },[statusReportUploadInfo])

  return (
    <div className="detail-delivered-table-wrapper">
      <div className="title-absolute">報告書提出情報</div>
      <div className="detail__delivered">
        <table className="table__delivered">
          <tbody>
            <tr>
              <th className="table__delivered--font table__delivered--size" colSpan="2" style={{ width: '16%' }}>
                Worker
              </th>
              <td style={{ width: '22%' }}>
                <Link to={`/report-order?orderId=${orderId}&organizationId=${organizationId}&partnerId=${partnerId}&menuName=workOnTheDay`}>
                  <button className="table__delivered--submit">Worker確認</button>
                </Link>
              </td>
              <th className="table__delivered--th" style={{ width: '13%' }}></th>
              <td></td>
            </tr>
            {
              dataDownload?.length > 0 ?
              dataDownload.map((elm, index) => {
                  return (
                    <>
                      <tr key={index}>
                        <th className="table__delivered--font table__delivered--size" colSpan="2" style={{ width: '16%' }}>
                          {elm?.numberOfMaterials}
                        </th>
                        <td><a href={`${data?.[elm.timestamp]?.url}`} target="_blank">{data?.[elm.timestamp]?.name}</a></td>
                        <th className="table__delivered--th" style={{ width: '30%' }}>
                          <button
                            className="table__delivered--submit"
                            disabled={_.isEmpty(data?.[elm.timestamp]?.name)}
                            onClick={() => window.open(`${data?.[elm.timestamp]?.url}`)}
                          > ダウンロード
                            </button>
                        </th>
                        <td>

                          {/* {
                            (_.isEmpty(data?.[elm.timestamp]) || !data?.[elm.timestamp]?.status) ?
                              <input
                                disabled
                                className="w-75 table__delivered--input"
                                value={data?.[elm.timestamp]?.comment}
                              ></input> : <div className="empty-div"></div>
                          } */}
                          {/* <label>{data?.[elm.timestamp]?.timestamp}</label> */}
                        </td>
                      </tr>
                    </>
                  )
                }) : ''
            }
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DetailReport;
