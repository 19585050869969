import React, { useEffect } from 'react';
import { useState } from 'react';
import '../../assets/scss/page/MemoHistoryTable/style.scss';
import { ORDER } from '../../constants/endpoints';
import { time } from '../../helpers/constants';
import { pushToast } from '../Toast';
import _ from 'lodash';
import { translate } from 'react-i18next';

const MemoTableHistory = ({ orderId, screenName, t }) => {
  const [dataComment, setDataComment] = useState({});
  const [inputShow, setInputShow] = useState('');
  const [dataLogOrder, setDataLogOrder] = useState({});

  const onChangeText = (e) => {
    setDataComment({ ...dataComment, [e.target.name]: e.target.value });
    if (e.target.name === 'contactPerson') {
      setDataComment({ ['contactPerson']: e.target.value });
    }
    if (e.target.name === 'category') {
      setDataComment({ ['contactPerson']: dataComment.contactPerson, ['category']: e.target.value });
    }
  };

  const handleRadio = (e, value) => {
    setDataComment({ ...dataComment, [e.target.name]: value });
    setInputShow(value);
  };

  const getAllLog = async ({ page = 0, limit = 10, orderId = '', screenName = '' }) => {
    try {
      const data = await ORDER.GET_ALL_LOG({ page: page, limit: limit, orderId: orderId, screenName: screenName });
      if (data && data?.code === 0) {
        setDataLogOrder(data?.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (orderId && screenName) {
      getAllLog({ screenName, orderId });
    }
  }, [orderId, screenName]);

  const createLog = async (body) => {
    try {
      const data = await ORDER.CREATE_LOG(body);
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
      } else {
        pushToast('success', 'Create log success');
        setDataComment({
          contactPerson: '作業員',
        });
        getAllLog({ page: 0, orderId: orderId, screenName: screenName });
      }
    } catch (errorMessage) {
      console.log('errorMessage: ', errorMessage);
    }
  };

  const onSubmit = () => {
    const data = {
      ...dataComment,
      orderId: orderId,
      screenName: screenName,
    };
    createLog(data);
  };

  return (
    <div>
      <div className="body__loghistory">
        <table className="table__history">
          <colgroup>
            <col style={{ width: '20%' }}></col>
            <col style={{ width: '20%' }}></col>
            <col style={{ width: '60%' }}></col>
          </colgroup>
          <tbody>
            <tr>
              <th className="table__history--color">連絡者</th>
              <th className="table__history--color">カテゴリ</th>
              <th className="table__history--color">コメント</th>
            </tr>
            <tr>
              <td className="table__history--color--td table__history--form--td p-0">
                {['作業員', 'パートナー', 'クライアント', '訪問先担当者'].map((item, index) => {
                  return (
                    <div key={index}
                      className="float-left w-100 "
                      style={{
                        background:
                          item === '作業員'
                            ? '#E9D26D'
                            : item === 'パートナー'
                            ? '#8CD6A1'
                            : item === 'クライアント'
                            ? '#89BEF3'
                            : '#FFCC99',
                        height: 37.5,
                      }}
                    >
                      <input
                        className="float-left table__history--input--radio ml-2"
                        id={`${item}1`}
                        name="contactPerson"
                        type="radio"
                        value={item}
                        onChange={onChangeText}
                        checked={dataComment?.contactPerson === item}
                      />
                      <label
                        style={{
                          background:
                            item === '作業員'
                              ? '#E9D26D'
                              : item === 'パートナー'
                              ? '#8CD6A1'
                              : item === 'クライアント'
                              ? '#89BEF3'
                              : '#FFCC99',
                          marginTop: 7,
                        }}
                        className="float-left ml-1 table__history--click"
                        htmlFor={`${item}1`}
                      >
                        {item}
                      </label>
                    </div>
                  );
                })}
              </td>
              <td
                className="table__history--color--td table__history--form--td"
                style={{
                  background:
                    dataComment?.contactPerson === '作業員'
                      ? '#E9D26D'
                      : dataComment?.contactPerson === 'パートナー'
                      ? '#8CD6A1'
                      : dataComment?.contactPerson === 'クライアント'
                      ? '#89BEF3'
                      : dataComment?.contactPerson === '訪問先担当者'
                      ? '#FFCC99'
                      : '',
                }}
              >
                {(dataComment?.contactPerson === '作業員'
                  ? ['入館催促', '入館受付', '作業時間超過確認', '作業内容確認', '退館受付']
                  : dataComment?.contactPerson === 'パートナー'
                  ? ['作業員変更', '作業員情報催促', '稼働調整催促', '報告書催促']
                  : dataComment?.contactPerson === 'クライアント'
                  ? ['登録情報追加・変更']
                  : ['アポイント', '当日変更連絡', 'その他']
                ).map((items, index) => {
                  return (
                    <div className="float-left w-100 ml-2" key={index} >
                      <input
                        id={`${items}1`}
                        name="category"
                        className="float-left table__history--radio"
                        type="radio"
                        value={items}
                        onChange={onChangeText}
                        checked={dataComment?.category === items}
                      />
                      <label className="table__history--click float-left ml-1" htmlFor={`${items}1`}>
                        {items}
                      </label>
                    </div>
                  );
                })}
              </td>
              <td className="table__history--color--td table__history--form--td">
                {(dataComment?.category === '入館催促' || dataComment?.category === '作業時間超過確認') && (
                  <div className="w-100">
                    <div className="d-flex ml-3">
                      <input
                        type="radio"
                        name="comment"
                        className="float-left table__history--radio "
                        onChange={(e) => handleRadio(e, '連絡OK予定時刻')}
                        checked={inputShow === '連絡OK予定時刻'}
                        id="連絡OK予定時刻"
                      />
                      <label className="ml-1" htmlFor="連絡OK予定時刻">
                        連絡OK 予定時刻
                      </label>
                      <select
                        className="ml-1 w-25"
                        name="timeComment"
                        onChange={(e) => onChangeText(e)}
                        value={dataComment?.timeComment}
                      >
                        {time.map((item, index) => (
                          <option value={item} key={index}>
                            {item}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="float-left ml-3">
                      <input
                        type="radio"
                        name="comment"
                        id="連絡NG・エスカ有り"
                        className="float-left table__history--radio"
                        onChange={(e) => handleRadio(e, '連絡NG・エスカ有り')}
                        checked={inputShow === '連絡NG・エスカ有り'}
                      />
                      <label className="ml-1" htmlFor={`連絡NG・エスカ有り`}>
                        連絡NG・エスカ有り
                      </label>
                    </div>
                  </div>
                )}
                <div className="w-100 ">
                  <textarea
                    style={{ width: '95%' }}
                    name="comment"
                    onChange={onChangeText}
                    value={dataComment?.comment}
                  ></textarea>
                </div>
                <button className="float-right table__history--button mr-3" onClick={() => onSubmit()}>
                  登録
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <table className="table__history">
          <colgroup>
            <col style={{ width: '10%' }}></col>
            <col style={{ width: '10%' }}></col>
            <col style={{ width: '10%' }}></col>
            <col style={{ width: '10%' }}></col>
            <col style={{ width: '10%' }}></col>
            <col style={{ width: '10%' }}></col>
            <col style={{ width: '40%' }}></col>
          </colgroup>
          <tbody>
            <tr>
              <th className="table__history--color">更新時間</th>
              <th className="table__history--color">連絡者</th>
              <th className="table__history--color">カテゴリ</th>
              <th className="table__history--color">画面名</th>
              <th className="table__history--color">モジュール</th>
              <th className="table__history--color">項目名</th>
              <th className="table__history--color">更新内容</th>
            </tr>
            {dataLogOrder?.body?.map((item, index) => {
              let newComment = {};

              try {
                newComment = JSON.parse(item?.comment.slice(5));
              } catch(e) {
                console.log(e)
              }

              newComment = Object.keys(newComment).reduce((prev, curr, index) => {
                return {
                  ...prev,
                  [t(`id.${curr}`)]: !_.isObject(newComment[curr])
                    ? newComment[curr]
                    : newComment[curr].length > 0
                    ? newComment[curr].map((itemss) => {
                        return _.mapKeys(itemss, function (value, key) {
                          return t(`id.${key}`);
                        });
                      })
                    : _.map(newComment[curr], (valueMap, keys) => {
                        return {
                          [t(`id.${keys}`)]: !_.isObject(valueMap)
                            ? valueMap
                            : _.mapKeys(valueMap, function (va, ke) {
                                return t(`id.${ke}`);
                              }),
                        };
                      }),
                  //  _.mapKeys(newComment[curr], function (value, key) {
                  //     return t(`log_history.${key}`);
                  //   })
                };
              }, {});
              return (
                <tr key={index}>
                  <td
                    className="table__history--color--td"
                    style={{
                      background:
                        item?.contactPerson === '作業員'
                          ? '#E9D26D'
                          : item?.contactPerson === 'パートナー'
                          ? '#8CD6A1'
                          : item?.contactPerson === 'クライアント'
                          ? '#89BEF3'
                          : item?.contactPerson === '訪問先担当者'
                          ? '#FFCC99'
                          : '',
                    }}
                  >
                    {item?.currentTime}
                  </td>
                  <td
                    className="table__history--color--td"
                    style={{
                      background:
                        item?.contactPerson === '作業員'
                          ? '#E9D26D'
                          : item?.contactPerson === 'パートナー'
                          ? '#8CD6A1'
                          : item?.contactPerson === 'クライアント'
                          ? '#89BEF3'
                          : item?.contactPerson === '訪問先担当者'
                          ? '#FFCC99'
                          : '',
                    }}
                  >
                    {item?.contactPerson}
                  </td>
                  <td
                    className="table__history--color--td"
                    style={{
                      background:
                        item?.contactPerson === '作業員'
                          ? '#E9D26D'
                          : item?.contactPerson === 'パートナー'
                          ? '#8CD6A1'
                          : item?.contactPerson === 'クライアント'
                          ? '#89BEF3'
                          : item?.contactPerson === '訪問先担当者'
                          ? '#FFCC99'
                          : '',
                    }}
                  >
                    {item?.category}
                  </td>
                  <td
                    className="table__history--color--td"
                    style={{
                      background:
                        item?.contactPerson === '作業員'
                          ? '#E9D26D'
                          : item?.contactPerson === 'パートナー'
                          ? '#8CD6A1'
                          : item?.contactPerson === 'クライアント'
                          ? '#89BEF3'
                          : item?.contactPerson === '訪問先担当者'
                          ? '#FFCC99'
                          : '',
                    }}
                  >
                    {item?.screenName}
                  </td>
                  <td
                    className="table__history--color--td"
                    style={{
                      background:
                        item?.contactPerson === '作業員'
                          ? '#E9D26D'
                          : item?.contactPerson === 'パートナー'
                          ? '#8CD6A1'
                          : item?.contactPerson === 'クライアント'
                          ? '#89BEF3'
                          : item?.contactPerson === '訪問先担当者'
                          ? '#FFCC99'
                          : '',
                    }}
                  >
                    {item?.productIdManagementCode}
                  </td>
                  <td
                    className="table__history--color--td"
                    style={{
                      background:
                        item?.contactPerson === '作業員'
                          ? '#E9D26D'
                          : item?.contactPerson === 'パートナー'
                          ? '#8CD6A1'
                          : item?.contactPerson === 'クライアント'
                          ? '#89BEF3'
                          : item?.contactPerson === '訪問先担当者'
                          ? '#FFCC99'
                          : '',
                    }}
                  >
                    {item?.writer}
                  </td>
                  <td
                    className="table__history--color--td "
                    style={{
                      background:
                        item?.contactPerson === '作業員'
                          ? '#E9D26D'
                          : item?.contactPerson === 'パートナー'
                          ? '#8CD6A1'
                          : item?.contactPerson === 'クライアント'
                          ? '#89BEF3'
                          : item?.contactPerson === '訪問先担当者'
                          ? '#FFCC99'
                          : '',
                      textAlign: 'left',
                    }}
                  >
                    {_.map(newComment, (v, k) => {
                      return (
                        <div key={k}>
                          - {JSON.stringify(k)} :{' '}
                          {!_.isObject(v)
                            ? JSON.stringify(v)
                            : v.length > 0
                            ? v.map((ele) => {
                                return Object.keys(ele).map((ke, e) => {
                                  return (
                                    <div className="pl-2" key={e}>
                                      - {JSON.stringify(ke)} : {JSON.stringify(ele[ke])}
                                    </div>
                                  );
                                });
                              })
                            : Object.keys(v).map((ki, m) => {
                                return (
                                  <div className="pl-2" key={m}>
                                    - {JSON.stringify(ki)} : {JSON.stringify(v[ki])}
                                  </div>
                                );
                              })}
                        </div>
                      );
                    })}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default translate('translations')(MemoTableHistory);
