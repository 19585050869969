import React, { useEffect, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './style.scss';
import ja from 'date-fns/locale/ja'; // the locale you want
import moment from 'moment';
import _ from 'lodash';
registerLocale('ja', ja);

const DateTimePicker = ({ listCalendarMaster = [], statusCalendarSettings, disabled = false, date = null, onChangeDate = () => {}, month, highlightLists = [], changeMonth = () => {} }) => {
  const [startDate, setStartDate] = useState(date);
  const [highlightList, setHighlightList] = useState([]);

  useEffect(() => {
    setStartDate(date);
  }, [date]);

  useEffect(() => {
    if(!_.isEmpty(listCalendarMaster)) {
      setHighlightList(statusCalendarSettings ? highlightLists : [{ 'react-datepicker__day--disabled': getListNewDate(listCalendarMaster) }])
    }
  }, [highlightLists]);

  const getListNewDate = list => {
    return list?.map(item => {
      return new Date(item.date);
    });
  };

  const handleChange = date => {
    onChangeDate(date);
    setStartDate(date);
  };

  return (
    <div className="">
      <DatePicker
        selected={startDate}
        onChange={date => handleChange(date)}
        disabled={disabled}
        locale="ja"
        monthsShown={month}
        dateFormat="yyyy/MM/dd"
        dateFormatCalendar="yyyy年 MM月"
        highlightDates={highlightList}
        onMonthChange={changeMonth}
        inline
      />
    </div>
  );
};

export default DateTimePicker;
