import React, { useEffect, useState } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import './style.scss';
import { OPTION_IMG_REPORT } from 'helpers/constants';

import ListInformation from 'components/ListInformation';
import { ORDER_PARTNER, ORDER } from 'constants/endpoints';
import ReportAttachment from './ReportTable';
import { pushToast } from 'components/Toast';
import { useSelector } from 'react-redux';
import Loading from 'components/Loading/Loading';
import _ from 'lodash';
import FooterMaintenance from "components/FooterMaintenance"

const WorkEnter = ({ location }) => {
  const workName = (process.browser && new URL(window.location.href).searchParams.get('workName')) || null;
  const orderId = (process.browser && new URL(window.location.href).searchParams.get('orderId')) || null;
  const organizationId = (process.browser && new URL(window.location.href).searchParams.get('organizationId')) || null;
  let menuSettingId = (process.browser && new URL(window.location.href).searchParams.get('menuSettingId')) || null;
  const menuName = (process.browser && new URL(window.location.href).searchParams.get('menuName')) || null;
  const onBack = (process.browser && new URL(window.location.href).searchParams.get('onBack')) || null;
  let partnerId = (process.browser && new URL(window.location.href).searchParams.get('partnerId')) || null;
  const backDetail = (process.browser && new URL(window.location.href).searchParams.get('backDetail')) || null;
  const beforeConfirmRole = (process.browser && new URL(window.location.href).searchParams.get('beforeConfirmRole')) || 'erp';
  const userMeData = useSelector((store) => store.authentication.userMe);

  const [listWorkerMenu, setListWorkerMenu] = useState([]);
  const [dataOrder, setDataOrder] = useState('');
  const [dataActive, setDataActive] = useState({});
  const history = useHistory();
  const [menuSettingIdTmp, setMenuSettingIdTmp] = useState(null);
  const [loading, setLoading] = useState(false);
  const [onTemporary, setOnTemporary] = useState(false);
  const [isChangeStep, setIsChangeStep] = useState(false);

  useEffect(() => {
    if (orderId) {
      getOrderDetail({ orderId });
    }
  }, [orderId]);

  useEffect(() => {
    if (menuSettingId) {
      setMenuSettingIdTmp(menuSettingId);
    }
  }, [menuSettingId]);

  const isFirstWorker = () => {
    return localStorage.getItem('role') === '14' && dataOrder?.staffId?.indexOf(userMeData?.data?.loginId) === 0 || localStorage.getItem('role') !== '14';
  };

  const getOrderDetail = async ({ orderId = '' }) => {
    try {
      const data = await ORDER.GET_DETAIL_CANCEL_INFO({ orderId, organizationId });
      if (data) {
        setDataOrder(data?.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getWorkerMenu({ orderId });
  }, []);

  const getWorkerMenu = async ({ orderId = '' }) => {
    setLoading(true);
    try {
      const data = await ORDER_PARTNER.GET_WORKER_MENU({ orderId, organizationId: organizationId || '', menuName });
      if (!_.isEmpty(data ?.data)) {
        setListWorkerMenu(data?.data);
        if (_.isEmpty(dataActive)) {
          if (!menuSettingId) {
            setDataActive({...data?.data?.[0] , menuItemsSettings : handleActiveData(data?.data?.[0])});
          } else {
            const newDataActive = data ?.data ?.find(item => item.menuSettingId === menuSettingId);
            setDataActive({...newDataActive , menuItemsSettings : handleActiveData(newDataActive)});
          }
        }
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const getDisaleBtnStep = ({id}) => {
    if (id === 0) {
      return false;
    } else {
      const worker = listWorkerMenu[id - 1]
      if (worker.isConfirm) {
        return false;
      } else {
        return true
      }
    }
  }

  const renderButtonStep = ({
    key = 0,
    title = '',
    active = false,
    onClickActive = () => {},
    dataActive,
    isFinished = false,
    isFinishedPre = false,
  }) => {
    return (
      <div className="col-xl-2 col-12 px-0">
        <div
          className={`menu-btn
            ${getDisaleBtnStep({id: key}) && 'disabledBtn'}
            ${active ? ' wrapper-button-step-active' : 'wrapper-button-step'}`
          }
          onClick={() => onClickActive(dataActive)}
          key={key}
        >
          <div
            className={`button-step
            ${isFinished ? 'isFinished' : isFinishedPre ? 'step-doing' : key === 0 ? 'step-doing' : ''}`}
          >
            {title}
          </div>
        </div>
      </div>
    );
  };

  const handleActiveData = (dataActive) => {
    let contentUpdate =
      dataActive?.menuItemsSettings?.map((item) => {
        if (item?.itemType?.[0]?.name === 'フィールド反映') {
          return item?.itemType?.[0]?.content;
        }
      }) || [];
    let contents = {};
    let checkData = _.compact(contentUpdate)?.map((e) => {
      const key = Object.keys(e);
      let check = key?.map((elm) => {
        contents[elm] = contents[elm] ? contents[elm] : e[elm];
      });
    });
    let dataUpdate = dataActive?.menuItemsSettings?.map((item) => {
      let dataVisitDate = item?.itemType?.map((ele) => {
        if (ele?.name === 'フィールド反映') {
          return {
            ...ele,
            content: contents,
          };
        } else return ele;
      });
      return { ...item, itemType: dataVisitDate };
    });
    return dataUpdate;
  };

  const onClickActive = (dataActive) => {
    setDataActive({ ...dataActive, menuItemsSettings: handleActiveData(dataActive) });
    setIsChangeStep(true);
    const newUrl = location.search.split('&menuSettingId')[0];
    history.push({
      search: `${newUrl}&menuSettingId=${dataActive.menuSettingId}`,
    });
  };

  const changeComment = ({name, menuItemSettingId, value}) => {
    const newData = dataActive?.menuItemsSettings?.map((item) => {
      if (item.menuItemSettingId === menuItemSettingId) {
        const newMenuItem = item?.itemType?.map((elm) => {
          if (elm.name === name) {
            return { ...elm, content: {...elm.content, comment: value }};
          } else {
            return elm;
          }
        });
        return { ...item, itemType: newMenuItem };
      } else {
        return item;
      }
    });
    setDataActive({ ...dataActive, menuItemsSettings: newData });
  };

  const handleCheckImg = ({ name, menuItemSettingId, value }) => {
    const newData = dataActive?.menuItemsSettings?.map((item) => {
      if (item.menuItemSettingId === menuItemSettingId) {
        const newMenuItem = item?.itemType?.map((elm) => {
          if (elm.name === name) {
            if (elm ?.content ?.option ?.length > 0) {
              if (value === OPTION_IMG_REPORT.REMOVE) {
                if (elm ?.content ?.option.includes(OPTION_IMG_REPORT.REMOVE)) {
                  const newOption = elm ?.content ?.option ?.filter(e => e !== OPTION_IMG_REPORT.REMOVE);
                  if (elm?.name === 'バーコード識別') {
                    return {
                      ...elm,
                      content: {
                        option: newOption,
                      },
                    };
                  } else {
                    return { ...elm, content: { option: newOption, content: '' } };
                  }
                } else {
                  if (elm?.name === 'バーコード識別') {
                    return {
                      ...elm,
                      content: {
                        option: [...elm?.content?.option, value],
                      },
                    };
                  } else {
                    return { ...elm, content: { option: [...elm?.content?.option, value], content: '' } };
                  }
                }
              } else {
                if (elm ?.content ?.option.includes(OPTION_IMG_REPORT.NOT_ALLOWED)) {
                  const newOption = elm ?.content ?.option ?.filter(e => e !== OPTION_IMG_REPORT.NOT_ALLOWED);
                  return { ...elm, content: { ...elm?.content, option: newOption } };
                } else {
                  return { ...elm, content: { ...elm?.content, option: [...elm ?.content ?.option, value] } };
                }
              }
            } else {
              if (value === OPTION_IMG_REPORT.REMOVE) {
                if (elm?.name === 'バーコード識別') {
                  return {
                    ...elm,
                    content: {
                      option: [value],
                    },
                  };
                } else {
                  return { ...elm, content: { content: '', option: [value] } };
                }
              } else {
                return { ...elm, content: { ...elm?.content, option: [value] } };
              }
            }
          } else {
            return elm;
          }
        });
        return { ...item, itemType: newMenuItem };

      } else {
        return item;
      }
    });
    setDataActive({ ...dataActive, menuItemsSettings: newData });
  };

  const changeContent = (name, menuItemSettingId, value) => {
    const newData = dataActive?.menuItemsSettings?.map((item) => {
      if (item.menuItemSettingId === menuItemSettingId) {
        const newMenuItem = item?.itemType?.map((elm) => {
          if (elm.name === name) {
            return { ...elm, content: value };
          } else {
            return elm;
          }
        });
        return { ...item, itemType: newMenuItem };
      } else {
        return item;
      }
    });
    setDataActive({ ...dataActive, menuItemsSettings: newData });
  };

  const pushContent = ({ newData }) => {
    setDataActive({ ...dataActive, menuItemsSettings: newData });
  };

  const changeContentVisitDate = (name, menuItemSettingId, value) => {
    const newData = dataActive?.menuItemsSettings?.map((item) => {
      if(item?.itemType?.[0]?.name === name) {
        const newMenuItem = item?.itemType?.map((elm) => {
          if (elm.name === name) {
            return { ...elm, content: value };
          } else {
            return elm;
          }
        });
        return { ...item, itemType: newMenuItem };
      }
      else {
        return item;
      }
    });
    setDataActive({ ...dataActive, menuItemsSettings: newData });
  };

  const pushContentVisitDate = ({ newData }) => {
    setDataActive({ ...dataActive, menuItemsSettings: newData });
  };

  const changeFile = async (name, menuItemSettingId, e) => {
    const file = e?.target?.files[0];
    const fileName = e?.target?.files[0]?.name;
    const value = {
      url: await toBase64(file),
      fileNameNew: fileName,
    };

    const newData = dataActive?.menuItemsSettings?.map((item) => {
      if (item.menuItemSettingId === menuItemSettingId) {
        const newMenuItem = item?.itemType?.map((elm) => {
          if (elm.name === name) {
            return { ...elm, content: { ...elm.content, ...value } };
          } else {
            return elm;
          }
        });
        return { ...item, itemType: newMenuItem };
      } else {
        return item;
      }
    });
    setDataActive({ ...dataActive, menuItemsSettings: newData });
  };

  const changeImage = async (name, menuItemSettingId, e) => {
    const file = e?.target?.files[0];
    const fileName = e?.target?.files[0]?.name;
    const value = {
      content: await toBase64(file),
      fileName,
    };

    await changeContent(name, menuItemSettingId, value);
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const changeBarcode = async (name, menuItemSettingId, e) => {
    const fileName = e?.target?.files[0]?.name;
    const files = e?.target?.files[0];
    const body = {
      fileName,
      content: files ? await toBase64(files) : '',
    };
    getBarcode({ name, menuItemSettingId, body });
  };

  const getBarcode = async ({ name, menuItemSettingId, body }) => {
    try {
      const data = await ORDER_PARTNER.GET_BARCODE({ body });
      if (!_.isEmpty(data?.data)) {
        const value = {...data?.data , barCodeCustom : data?.data?.barCode}
        changeContent(name, menuItemSettingId, value);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleOnReportOrderSuccess = (data) => {
    const currentDataActive = data?.Attributes?.workerReport[menuName]?.data?.find(item => {
      return item.menuSettingId === dataActive?.menuSettingId
    })

    if (!currentDataActive.isConfirm) return;

    let dataActiveIndex = listWorkerMenu.findIndex(item => item.menuSettingId === dataActive?.menuSettingId)
    if (dataActiveIndex < 0) return;

    dataActiveIndex += 1

    const newDataActive = listWorkerMenu[dataActiveIndex]

    if (!newDataActive) {
      const search = location.search;
      onReturn();
    }

    onClickActive(newDataActive);
  }

  const onReturn = () => {
    if (onBack === 'detail-report-list') {
      history.push({
        pathname: '/detail-report-list',
        search: `?orderId=${orderId}&organizationId=${organizationId}&partnerId=${partnerId}`,
      });
    }
    if (partnerId && onBack === 'detail-delivery-client') {
      history.push({
        pathname: '/detail-delivery-client',
        search: `?orderId=${orderId}&organizationId=${organizationId}&partnerId=${partnerId}`,
      });
    }
    if (localStorage.getItem('nameMenu') === 'site_info' && onBack === 'site_info') {
      history.push(`/list?p=site_info`);
    }
    if (localStorage.getItem('nameMenu') === 'preparation_info' && onBack === 'tomorrow_info') {
      history.push(`/list?p=tomorrow_info`);
    }
    if (localStorage.getItem('nameMenu') === 'report_upload_info' && onBack === 'report_upload_info') {
      history.push(`/list?p=report_upload_info`);
    }
    if(backDetail === 'backDetail') {
      if(beforeConfirmRole === 'erp') {
        history.push(`/detail-before-confirm-erp?orderId=${orderId}&organizationId=${organizationId}&isActive=1`)
      } else if (beforeConfirmRole === 'management') {
        history.push(`/detail-before-confirm-partner?orderId=${orderId}&organizationId=${organizationId}&isActive=1`)
      } else {
        history.push(`/detail-before-confirm-client?orderId=${orderId}&organizationId=${organizationId}&isActive=1`)
      }
    }
    if (partnerId && localStorage.getItem('nameMenu') === 'delivered_info' && onBack !== 'detail-delivery-client') {
      history.push({
        pathname: '/detail-delivered-info',
        search: `?orderId=${orderId}&organizationId=${organizationId}&partnerId=${partnerId}`,
      });
    } else if (partnerId && localStorage.getItem('nameMenu') === 'report_upload_info') {
      history.push({
        pathname: '/detail-report-upload-info',
        search: `?orderId=${orderId}&organizationId=${organizationId}&partnerId=${partnerId}`,
      });
    } else if (workName && localStorage.getItem('role') === '14') {
      history.push({
        pathname: '/detail-worker-list',
        search: `?orderId=${orderId}&workName=${workName}&organizationId=${organizationId}`,
      });
    }
  };

  const getDetailAgain = () => {
    getWorkerMenu({ orderId });
  };

  const onSaveTemporary = () => {
    setOnTemporary(true)
  };

  const renderWorkName = () => {
    return (
      <ReportAttachment
        alloweHandleReport={isFirstWorker()}
        dataActive={dataActive}
        pushContent={pushContent}
        pushFile={changeFile}
        pushBarcode={changeBarcode}
        pushImage={changeImage}
        getDetailAgain={getDetailAgain}
        onReportOrderSuccess={handleOnReportOrderSuccess}
        onTemporary={onTemporary}
        setTemporary={() => setOnTemporary(false)}
        handleCheckImg={handleCheckImg}
        changeComment={changeComment}
        changeContentVisitDate={pushContentVisitDate}
        dataOrder={dataOrder}
        isChangeStep={isChangeStep}
        setIsChangeStep={setIsChangeStep}
      />
    );
  }

  return (
    <div className="wrapper-report-order h-100">
      <Loading loading={loading} />
      <div className="wrapper-work-list pl-0 pr-0 wrapper-work-list--wrap" style={{ minHeight: 0 }}>
        <div className="block-link mr-0 ml-0 wrapper-work-list--wrap-child wrapper-work-list--wrap-100">
          <div className="block-link-child text-left">
            <a onClick={() => onReturn()} className="item">
              <span>戻る</span>
            </a>
          </div>

          <div className="block-link-child text-center">
            <span style={{ color: '#fff' }}>{dataActive?.name}</span>
          </div>

          <div className="block-link-child">
            <button onClick={() => onSaveTemporary()}
              className="temporary text-center"
              disabled={!dataActive?.menuSettingId || dataActive?.isConfirm}
            >
              <span>一時保存</span>
            </button>
          </div>
        </div>
      </div>
      <div className="main-report-order wrapper-work-list--mgt-100">
        <div className="d-flex menu_wrap row">
          {listWorkerMenu?.map((item, index) =>
            renderButtonStep({
              title: item.name,
              active: (menuSettingIdTmp && menuSettingIdTmp === item.menuSettingId )? true : (!menuSettingIdTmp && index === 0),
              menuSettingId: item.menuSettingId,
              onClickActive: onClickActive,
              dataActive: item,
              key: index,
              isFinished: item.isConfirm,
              isFinishedPre: listWorkerMenu?.[index - 1]?.isConfirm
            })
          )}
        </div>
        <ListInformation dataOrder={dataOrder} />
        {renderWorkName()}
      </div>
      <FooterMaintenance />
    </div>
  );
};

export default compose(withRouter)(WorkEnter);
