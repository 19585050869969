export const List_STEP = [
  { // step 1
    headerTitle: '受注情報',
    rows: [
      [{ label: '作成日', key: 'requestedDate' }, { label: '承認日', key: 'timestampReceiveInfo' }],
    ]
  }, { // step 2
    headerTitle: 'アポイント情報',
    rows: [
      [{ label: '架電ステータス', key: 'callStatus' }, { label: '架電メモ', key: 'callLog' }],
      [
        { label: '訪問日確定日', key: 'timestampAppointInfo' },
        { label: '訪問日', key: 'visitDate' },
        { label: '開始予定', key: 'visitDateFrom' },
        { label: '終了予定', key: 'visitDateTo' },
      ],
    ]
  }, { // step 3
    headerTitle: '稼働調整',
    rows: [
      [
        { label: '調整状況', key: 'adjustmentStatus' },
        { label: '稼働打診日', key: 'timeAddPartner' },
        { label: '稼働回答日', key: 'timePartnerConfirm' },
        { label: '稼働確保日', key: 'timestampConditionInfo' },
      ],
    ]
  }, { // step 4
    headerTitle: '稼働待ち情報',
    rows: [
      [
        { label: 'Management', key: 'abbreviationName' },
        { label: '作業員', key: 'totalStaff' },
        { label: '作業員確定日', key: 'timeAddStaff' },
      ],
    ]
  }, { //step 5
    headerTitle: '事前確認',
    rows: [
      [
        { label: '事前確認ステータス', key: 'statusMonitoring' },
        { label: '事前確認完了日', key: 'timeBeforeConfirmFinish' },
        { label: '入館申請完了日', key: 'timestampRequestConfirm' },
        { label: '道路使用許可申請日', key: 'roadsUePermitApplicationCompletionDate' },
      ], [
        { label: '共架申請完了日', value: '', key: 'coFrameApplicationOver' },
        { label: 'GC局入局申請日', value: '', key: 'GCFinishedDay' },
        { label: '鍵借用申請完了日', value: '', key: 'keyLoanApplicationDate' },
        { label: '作業員対応完了日', value: '', key: 'timeWorkerFinishBeforeConfirm' },
      ],
    ]
  }, { //step 6
    headerTitle: '当日現場情報',
    rows: [
      [
        { label: '結果', key: 'resultsPreparation' },
        { label: '入館', key: 'startTime' },
        { label: '退館', key: 'endTime' },
        { label: '結果備考', key: 'resultCancel' },
      ]
    ]
  }, { //step 7
    headerTitle: '報告書情報',
    rows: [
      [
        { label: '報告書ステータス', key: '' },
        { label: '報告書送信日', key: 'timeWorkerReport' },
        { label: '報告書提出日', key: 'timePartnerReport' },
        { label: '報告書確認日', key: 'rejectOrConfirmDate' },
      ],
      [ { label: '報告書納品日', key: 'timestampReportUploadInfo' } ],
      [
        { label: '不備返却日', key: 'timestampRejectReportUpload' },
        { label: '不備返却コメント', key: 'defectiveReturnContents' },
        { label: 'Monitoring_不備返却日', value: '', key: 'monitoringDefectiveReturnDate' },
        { label: 'Monitoring_不備返却コメント', value: '', key: 'monitoringDefectiveReturnComment' },
      ],
    ]
  },
  /*
  { //step 8
    headerTitle: '報告書',
    rows: [
      [
        { label: '検収ステータス', value: 'WAITTING_CONFIRM', key: 'key' },
        { label: 'Manegement_検収依頼日', value: 'WAITTING_CONFIRM', key: 'key' },
        { label: 'ERP_検収承認日', value: 'WAITTING_CONFIRM', key: 'key' },
        { label: 'Monitoring_検収承認日', value: 'WAITTING_CONFIRM', key: 'key' },
      ]
    ]
  },
*/
  { // step 9
    headerTitle: 'キャンセル',
    rows: [
      [
        { label: 'キャンセル結果', key: 'resultCancelInfo' },
        { label: 'キャンセル依頼日', key: 'timestampCancelMenu' },
        { label: 'キャンセル完了日', key: 'canceledAt' },
        { label: '結果備考', key: 'reasonForCancellation' },
      ],
    ]
  },
]
