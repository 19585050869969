import React, { useEffect, useState } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { Helmet } from 'react-helmet';
import { WORKER_MENU } from '../../../helpers/constants';
import './style.scss';
import ListInformation from '../../../components/ListInformation';
import { WORKER_ENDPOINT, ORDER } from '../../../constants/endpoints';
import FooterMaintenance from 'components/FooterMaintenance';
import EnterPage from './EnterPage';
import ReportCheck from './Report004Check';
import ReportAttachment from './Report003';
import LeavePage from './LeavePage';
import { pushToast } from '../../../components/Toast';

const renderButtonStep = ({
    key = 0, title = '', active = false, workName = 0, onClickActive = () => {},
}) => {
    return (
        <div className="menu_wrap">
            <div
                className={`d-flex   ${active ? ' wrapper-button-step-active' : 'wrapper-button-step'}`}
                onClick={() => onClickActive(workName)}
                key={key}
            >
                <div className="button-step d-flex justify-content-center align-items-center">{title}</div>
                <div className="arrow-right">
                    <div></div>
                </div>
            </div>
        </div>
    );
};

const dataStep = [
    {
        index: 0,
        name: WORKER_MENU.ENTER_PAGE,
        workName: 'enter_page',
        label: '入館',
        active: true,
        fullName: '入館',
    },
    {
        index: 1,
        name: WORKER_MENU.REPORT004_CHECK,
        workName: WORKER_MENU.REPORT004_CHECK,
        fullName: '報告書入力',
        label: '4.報告書入力',
        active: false,
    },
    {
        index: 2,
        name: WORKER_MENU.REPORT003,
        workName: WORKER_MENU.REPORT003,
        fullName: '報告書添付',
        label: '4.報告書入力',
        active: false,
    },
    {
        index: 3,
        workName: WORKER_MENU.LEAVE_PAGE,
        name: WORKER_MENU.LEAVE_PAGE,
        fullName: '結果・退館',
        label: '結果・退館',
        active: false,
    }
]

const WorkEnter = () => {
    const [stepActive, setStepActive] = useState(null);
    const [activeMenu, setActiveMenu] = React.useState(0);
    const history = useHistory();
    const workName = (process.browser && new URL(window.location.href).searchParams.get('workName')) || null;
    const orderId = (process.browser && new URL(window.location.href).searchParams.get('orderId')) || null;
    const organizationId = (process.browser && new URL(window.location.href).searchParams.get('organizationId')) || null;

    useEffect(() => {
        if (workName) {
            setStepActive(workName);
        }
    }, [workName]);

    useEffect(() => {
        if (dataStep && workName) {
            const result = dataStep.filter((data) => data.name === workName);
            setActiveMenu(result[0].index);
        }
    }, [workName]);

    const onClickActive = (workName) => {
        setStepActive(workName);
        history.push(`/workenter?&workName=${workName}&orderId=${orderId}`);
    };
    const [dataOrder, setDataOrder] = useState('')

    const getOrderDetail = async ({ orderId = '' }) => {
        try {
            const data = await ORDER.GET_DETAIL_CANCEL_INFO({ orderId, organizationId });
            if (data) {
                let newData = data?.data;
                newData = { ...newData }
                setDataOrder(newData)
            }
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        if (orderId) {
            getOrderDetail({
                orderId,
            });
        }

    }, [orderId]);

    const mockData = {
        orderId,
        admissionWorker: '',
    }
    const [dataWorker, setDataWorker] = useState(mockData)
    const [workerId, setWorkerId] = useState('');
    const createWorker = async (body) => {
        try {
            const data = await WORKER_ENDPOINT.CREATE_WORKER(body);
            if (data) {
                setWorkerId(data?.data?.workerId);
            }
            if (data && data.code !== 0) {
                if (data && data.message && data.message.extra) {
                    pushToast('error', data?.message?.extra);
                } else {
                    pushToast('error', data?.message?.errorMessage);
                }
            } else {
                pushToast('success', 'Create worker success');
                // window.location.href = (`/workenter?&workName=enter_page&orderId=${data?.workerId}`);
            }
        } catch (errorMessage) {
            console.log('errorMessage: ', errorMessage);
        }
    };

    const onClickSubmit = () => {
        createWorker(dataWorker)
    };

    const HandleHour1 = () => {
        let d = new Date();
        let hour = d.getHours();
        let minutes = (d.getMinutes() < 10 ? '0' : '') + d.getMinutes();
        let newData = { ...dataWorker }
        newData = { ...newData, admissionWorker: hour + ':' + minutes }
        setDataWorker(newData);
    }

    const renderWorkName = (workName) => {
        switch (workName) {
            case 'enter_page':
                return <EnterPage HandleHour1={HandleHour1} dataWorker={dataWorker} onClickSubmit={onClickSubmit} />;
            case 'report004_check':
                return <ReportCheck workerId={workerId} />;
            case 'report003':
                return <ReportAttachment workerId={workerId} />;
            case 'leave_page':
                return <LeavePage workerId={workerId} />;
        }
    };
    return (
        <div className="wrapper-work-name h-100">
            <Helmet>
                <title>{(dataStep && dataStep[activeMenu]?.label) || ''}</title>
            </Helmet>
            <div>
            </div>
            <div style={{ width: '100%', backgroundColor: 'black', color: 'white' }} >
                <p className="font-weight-boid " style={{ fontSize: 30, textAlign: 'center' }}> {(dataStep && dataStep[activeMenu]?.label) || ''}</p>
            </div>
            <div className="d-flex p-3">
                {dataStep.map((item, index) =>
                    item.workName === stepActive
                        ? renderButtonStep({
                            title: item.fullName,
                            active: true,
                            workName: item.workName,
                            onClickActive,
                            key: index,
                        })
                        : renderButtonStep({
                            title: item.fullName,
                            active: false,
                            workName: item.workName,
                            onClickActive,
                            key: index,
                        })
                )}
            </div>
            <ListInformation dataOrder={dataOrder} />
            {stepActive && renderWorkName(stepActive)}
        <FooterMaintenance/>
        </div>
    );
};

export default compose(withRouter)(WorkEnter);