import React, { useState } from 'react';
import 'assets/scss/page/AnkenMasterNew/style.scss';

import FooterMaintenance from '../../../components/FooterMaintenance';
import CollaMasterViewTree from './CollaMasterViewTree';
import HeaderMasterSetting from '../../../components/HeaderMasterSetting';
import { useSelector } from 'react-redux';
import MemoHistory from '../../../components/MemoHistory';
import { SCREEN_NAME } from '../../../helpers/constants';

const MasterSetting = (props) => {
  const colorScreen = useSelector((store) => store.utils.colorMe);
  const [statusBeforeConfirm, setStatusBeforeConfirm] = useState(true);

  const getListOption = ({ option }) => {
    setStatusBeforeConfirm(!!option ?.confirmedTheDayBefore)
  };

  return (
    <div className="wrapper-master-setting">
      <HeaderMasterSetting color={colorScreen?.code} colorText={colorScreen?.colorText} getListOption={getListOption}/>
      <div className="body pb-4">
        <div className="wrapper-main">
          <div className="wrapper-result">
            <CollaMasterViewTree colors={colorScreen ?.code} colorText={colorScreen ?.colorText} statusBeforeConfirm={statusBeforeConfirm}/>
            <MemoHistory colors={colorScreen?.code} colorText={colorScreen?.colorText} screenName={SCREEN_NAME.CASE_MASTA} />
          </div>
          <div className="d-flex justify-content-center align-items-center group-button"></div>
        </div>
      </div>
      <FooterMaintenance />
    </div>
  );
};

export default MasterSetting;
