import React, { useState } from 'react';
import './style.scss';

const data = [
  {
    更新者: '管理01',
    更新日: '2020/06/10 13:24:42',
    更新区分: '仮パスワード発行',
  },
  {
    更新者: '管理01',
    更新日: '2020/06/10 13:24:42',
    更新区分: '仮パスワード発行',
  },
  {
    更新者: '管理01',
    更新日: '2020/06/10 13:24:42',
    更新区分: '仮パスワード発行',
  },
  {
    更新者: '管理01',
    更新日: '2020/06/10 13:24:42',
    更新区分: '仮パスワード発行',
  },
  {
    更新者: '管理01',
    更新日: '2020/06/10 13:24:42',
    更新区分: '仮パスワード発行',
  },
  {
    更新者: '管理01',
    更新日: '2020/06/10 13:24:42',
    更新区分: '仮パスワード発行',
  },
];

const renderItem = (item, index) => {
  return (
    <tr key={index}>
      <td>{item.更新者}</td>
      <td>{item.更新日}</td>
      <td>{item.更新区分}</td>
    </tr>
  );
};

const TableUpdateHistory = ({ type = '' }) => {
  const [listData] = useState(data);

  return (
    <>
      <div className="wrapper-table-update-history table-responsive">
        <table className="table mb-0">
          <thead>
            <tr>
              <th scope="col">更新者</th>
              <th scope="col">更新日</th>
              <th scope="col">更新区分</th>
            </tr>
          </thead>
          {type !== 'new' && <tbody>{listData && listData.map((item, index) => renderItem(item, index))}</tbody>}
        </table>
      </div>
    </>
  );
};

export default TableUpdateHistory;
