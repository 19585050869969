import React, { useState } from 'react';
import './style.scss'
import { WORKER_ENDPOINT } from '../../../../constants/endpoints';
import { pushToast } from '../../../../components/Toast';

const EnterPage = ({ HandleHour1, onClickSubmit ,dataWorker }) => {
    return (
        <div className="detail-enter-page">
            <div align="center">
                <p style={{ fontSize: 50 }}>入館</p>
            </div>
            <table className="basictab" width="100%">
                <colgroup><col width="5%" />
                    <col width="15%" />
                    <col width="45%" />
                    <col width="25%" />
                </colgroup><tbody><tr>
                    <th colSpan="4" className="font16">入館</th>
                </tr>
                    <tr className="pcmenu">
                        <th>No</th>
                        <th>区分</th>
                        <th>内容1</th>
                        <th>操作</th>
                    </tr>
                    <tr>
                        <td className="rightalign">1</td>
                        <td>入館</td>
                        <td>
                            <div className="anken003_wrap">
                                <div className="text-center">
                                    <table id="responsive02" className="noborder_tab" style={{ fontSize: 20 }}>
                                        <tbody><tr>
                                            <td>
                                                <div className="ui-btn ui-input-btn ui-corner-all ui-shadow ui-state-disabled">入館
                                                <input type="button" name="btn_enter" value="入館" onClick={() => HandleHour1()} />
                                                </div>
                                            </td>
                                            <td style={{ verticalAlign: "middle" }}><p id="responsive01" style={{ fontSize: 20 }}>{dataWorker?.admissionWorker}</p>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </td>
                        <td></td>
                    </tr>
                </tbody></table>
            <div align="center">
                <input type="button" className="submitbutton" data-role="none" value="保存" onClick={() => onClickSubmit()} />
            </div>
        </div>
    )
};


export default EnterPage;
