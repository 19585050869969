import React from 'react';
import './style.scss';
import TableWorkerRight from '../TableWorker/TableWorkerRight';

const data = {
  open: ['前日確認', '入館前確認', '入館', '写真撮影', '報告書入力', '報告書添付', '退館前確認', '結果・退館'],
  construction: ['前日確認', '入館前確認', '入館', '写真撮影', '報告書入力', '報告書添付', '退館前確認', '結果・退館'],
};

const TableWorkerAll = () => {
  return (
    <div className="wrapper-table-worker-all">
      <table>
        <tbody>
          {data.open &&
            data.open.map((item, index) =>
              index === 0 ? (
                <tr className="first_row">
                  <th rowSpan="8">0</th>
                  <th rowSpan="8">全工程</th>
                  <td>
                    <TableWorkerRight value={item} id={`opem${item}`} />
                  </td>
                </tr>
              ) : (
                <tr>
                  <td>
                    <TableWorkerRight value={item} id={`opem${item}`} />
                  </td>
                </tr>
              )
            )}
        </tbody>
      </table>
    </div>
  );
};

export default TableWorkerAll;
