import React, { useState } from 'react';
import Header from '../../../components/HeaderMaintenance';

import './style.scss';
import SearchPartner from '../../../components/SearchPartnerMaster';
import TablePartner from '../../../components/TablePartnerMaster';
import FooterMaintenance from '../../../components/FooterMaintenance';

const Index = () => {
  const [fontSize, setFontSizeAll] = useState('small');
  return (
    <div className="main_list_partner">
      <Header setFontSizeAll={setFontSizeAll} />
      <div className="body_list wrapper-list ">
        <SearchPartner fontSize={fontSize} />
        <TablePartner fontSize={fontSize} />
      </div>
      <FooterMaintenance />
    </div>
  );
};

export default Index;
