import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { translate } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import '../../../../assets/scss/page/contract/index.scss';
import Footer from '../../../../components/Footer';
import HeaderMasterSetting from '../../../../components/HeaderMasterSetting';
import Loading from '../../../../components/Loading/Loading';
import PaginationCustom from '../../../../components/PaginationCustom';
import { CONTRACT_ENDPOINTS } from '../../../../constants/endpoints';
import { ROLE, DATE_JP_FORMAT } from '../../../../helpers/constants';
import { Collapse } from 'reactstrap';
import moment from 'moment';

const ListContractFilter = (props) => {
  const colorScreen = useSelector((store) => store.utils.colorMe);
  const [isOpen, setIsOpen] = useState(true);
  const [dataListContract, setDataListContract] = useState({});
  const [activePage, setActivePage] = useState(1);
  const [loading, setLoading] = useState(false);
  const partnerId = (process.browser && new URL(window.location.href).searchParams.get('partnerId'));
  const clientId = (process.browser && new URL(window.location.href).searchParams.get('clientId'));
  const nameRole = (process.browser && new URL(window.location.href).searchParams.get('nameRole'));

  useEffect(() => {
    getListContract({ partnerId, clientId, page: activePage, limit: 10 });
  }, []);

  const getListContract = async ({ partnerId, clientId, page = 1, limit = 10 }) => {
    setLoading(true);
    try {
      const data = await CONTRACT_ENDPOINTS.GET_CONTRACT_MANAGEMENTS({ partnerId, clientId, page, limit });
      if (data?.data) {
        setDataListContract(data?.data);
      } else {
        setDataListContract({});
      }
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const handleChangePage = (pageNumber) => {
    setActivePage(pageNumber);
    getListContract({ partnerId, clientId, page: pageNumber, limit: 10 });
  };

  return (
    <>
      <div className="main">
        <Loading loading={loading} />
        <HeaderMasterSetting color={colorScreen?.code} colorText={colorScreen?.colorText} />
        <div className="block-contract">
          <div className="block-contract">
            <div className="list-table">
              <Link className="btn-back" to={`/organizations/edit-organization?sid=${nameRole === 'management' ? partnerId : clientId}&role=${nameRole}`}>
                戻る
              </Link>
              <div className="table-list-contract">
                <Collapse isOpen={isOpen}>
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <td>契約種別</td>
                        <td>Product</td>
                        <td>Project</td>
                        <td>Service</td>
                        <td>契約期間 From</td>
                        <td>契約期間 To</td>
                        <td>状態</td>
                        <td>操作</td>
                      </tr>
                    </thead>
                    <tbody>
                      {dataListContract?.result?.map((itemIn, index) => {
                        return (
                          <tr key={index}>
                            <td>{itemIn?.contractStatus?.contractType}</td>
                            <td>{itemIn?.productName}</td>
                            <td>{itemIn.projectName}</td>
                            <td className="w-30">
                              {!_.isEmpty(itemIn?.services)
                                ? itemIn?.services.map((ele) => {
                                    return <button className="px-2 m-1 d-inline-block btn-services">{ele}</button>;
                                  })
                                : ''}
                            </td>
                            <td>{itemIn?.contractStatus?.contractPeriodStart ? moment(new Date(itemIn?.contractStatus?.contractPeriodStart)).format(DATE_JP_FORMAT) : ''}</td>
                            <td>{itemIn?.contractStatus?.contractPeriodEnd ? moment(new Date(itemIn?.contractStatus?.contractPeriodEnd)).format(DATE_JP_FORMAT) : ''}</td>
                            <td>{itemIn?.contractStatus?.state}</td>
                            <td className="justify-content-center">
                              <Link
                                to={`/contract/registration?recruitmentContractId=${itemIn.recruitmentId}&recruitmentEstimateId=${itemIn.contractEstimateId}&organizationId=${itemIn.organizationId}&onBack=`}
                                className="btn-edit"
                              >
                                編集
                              </Link>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  {dataListContract?.count > 10 && (
                    <PaginationCustom
                      activePage={activePage}
                      itemsCountPerPage={10}
                      totalItemsCount={dataListContract?.count}
                      pageRangeDisplayed={5}
                      handlePageChange={handleChangePage}
                    />
                  )}
                </Collapse>
              </div>
            </div>
          </div>
        </div>
        <Footer color={colorScreen?.code} colorText={colorScreen?.colorText} />
      </div>
    </>
  );
};

export default compose(translate('translations'), withRouter)(ListContractFilter);
