import React, { useState, useEffect } from 'react';
import './style.scss';
import { ORDER } from 'constants/endpoints';
import { pushToast } from 'components/Toast';
import { DATETIME_JP_FORMAT } from 'helpers/constants';
import { withRouter } from 'react-router-dom';
import Loading from 'components/Loading/Loading';

import _ from 'lodash';
import moment from 'moment';
const defaulfUpload = {
  'erpFileReportFirst': '',
  'erpFileReportSecond': '',
};

const listParam = ['comment', 'status', 'name', 'key', 'timestamp', 'url', 'numberOfMaterials'];

const TableUploadReportUploadInfoMonitoring = ({ data, onReload, title = '報告書', history, documentSetting = [], onChangeData = () => {}, screenName = '' }) => {
  let orderId = process.browser && new URL(window.location.href).searchParams.get('orderId');
  let organizationId = process.browser && new URL(window.location.href).searchParams.get('organizationId') || '';
  let partnerId = process.browser && new URL(window.location.href).searchParams.get('partnerId');
  let name = (process.browser && new URL(window.location.href).searchParams.get('p')) || 'delivered_info';
  const [dataOrder, setDataOrder] = useState(data);
  const [fileName, setFileName] = useState(defaulfUpload);
  const [contentFile, setContentFile] = useState(defaulfUpload);
  const [loading, setLoading] = useState(false);
  const [isDisabled , setIsDisabled] = useState(true);
  useEffect(() => {
    setDataOrder(data)
  }, [data]);

  useEffect(() => {
    onChangeData(_.pick({...dataOrder,  screenName: screenName }, PICK_REPORT_UPLOAD_INFO))
  }, [dataOrder]);

  const handleChangeComment = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setDataOrder({
      ...dataOrder,
      [name]: {
        ...dataOrder[name],
        comment: value
      }
    });
  };

  useEffect(() => {
    let checked = true;
    let value = documentSetting?.map((ele) => {
      if (dataOrder[ele?.timestamp] && dataOrder[ele?.timestamp]?.status === false && !ele?.uploadFile) {
        checked = false;
      }
    });
    setIsDisabled(checked);
  }, [dataOrder]);

  const handleChangeStatus = ({ nameType, value }) => {
    setDataOrder({
      ...dataOrder,
      [nameType]: {
        ...dataOrder[nameType],
        status: value,
        timestamp: moment(new Date()).format(DATETIME_JP_FORMAT)
      }
    });
  };

  const handleUpdate = () => {
    const body = _.pick({...dataOrder, updateTitle: '確認済', companyName: localStorage.getItem('companyName'), screenName: screenName}, PICK_REPORT_UPLOAD_INFO);
    handleUpdateReport({body, orderId})
  };

  const handleUpdateReport = async ({ body, orderId }) => {
    try {
      setLoading(true);
      const data = await ORDER.MONITORING_CONFIRM_REPORT({ body, orderId });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
        setLoading(false);
      } else {
        pushToast('success', 'Update Report success');
        setLoading(false);
        onReload();
        return history.push(`/list?p=delivered_info`);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };
  const handleDataPick = () => {
    let pick = documentSetting.map(e => {
      if (dataOrder[e.timestamp]) {
        dataOrder[e.timestamp] = _.pick(dataOrder[e.timestamp], listParam);
      }
      return e.timestamp;
    });
    pick = [
      ...pick,
      'screenName',
      'updateTitle',
      'companyName',
      'nameApplication',
    ];
    return pick;
  }
  const PICK_REPORT_UPLOAD_INFO = handleDataPick();

  const handleReject = () => {
    const body = _.pick({ ...dataOrder,  screenName: screenName, updateTitle: '差戻', companyName: localStorage.getItem('companyName'), nameApplication: '報告書' }, PICK_REPORT_UPLOAD_INFO);
    handleRejectOrder({body, orderId})
  };

  const handleRejectOrder = async ({ body = {}, orderId = '' }) => {
    try {
      setLoading(true);
      const data = await ORDER.MONITORING_REJECT_REPORT({ body, orderId });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
        setLoading(false);
      } else {
        pushToast('success', 'Update order success');
        setLoading(false);
        return history.push(`/list?p=delivered_info`);
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const handleUpload = async({ nameType, numberOfMaterials }) => {
    const body = {
      [nameType]: {
        file: contentFile?.[nameType],
        fileName: fileName?.[nameType],
      } ,
      screenName: screenName,
      updateTitle : 'アップロード',
      companyName: localStorage.getItem('companyName'),
      nameApplication: '報告書',
      numberOfMaterials,
    };
    handleUploadFile({body, orderId})
  };

  const handleUploadFile = async ({ body, orderId }) => {
    try {
      const data = await ORDER.UPLOAD_FILES_REPORT({ body, orderId, organizationId });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
      } else {
        pushToast('success', 'Upload success');
        onReload();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

  const onChangeHandler = async ({e, nameType}) => {
    const files = e ?.target ?.files[0];
    const name = e ?.target ?.files[0] ?.name;
    setFileName({
      ...fileName,
      [nameType]: name
    })

    setContentFile({
      ...contentFile,
      [nameType]: await toBase64(files)
    })
  };

  const handleLinkWorker = () => {
    history.push(
      `/report-order?orderId=${orderId}&organizationId=${organizationId}&partnerId=${partnerId}&menuName=workOnTheDay&onBack=detail-delivery-client`
    )
  };

  return (
    <div className="upload-wrapper">
      <Loading loading={loading} />
      <div className="form-wrapper">
        <div className="title-absolute cursor-pointer">
          {title}
        </div>
        <div className="table-upload">

          <div className="row-table">
            <div className="header-row">
              <div className="item-header full">
                <p>Worker報告書</p>
              </div>
            </div>
            <div className="content-row">
              <button
                onClick={()=> handleLinkWorker()}
                className="btn-modal">Worker確認</button>

            </div>
          </div>

            {documentSetting?.map((elm ,index) => {
              return (
                  <div key={index} className='row-table'>
                    <div className='header-row'>
                      <div className='group-header-full'>
                        <div className='item-header'>
                          <p>{elm.numberOfMaterials}</p>
                        </div>
                      </div>
                    </div>
                    { elm.uploadFile ? (
                      <>
                        <div className="group-content-row">
                          <div className="content-row">
                            <label htmlFor={elm.timestamp} className="btn-modal">
                              ファイル選択
                            </label>
                            <input
                              type="file"
                              name="file"
                              id={[elm.timestamp]}
                              className="d-none input-contract"
                              onChange={(e) => onChangeHandler({e, nameType: elm.timestamp})}
                            />

                            <input
                              type="text" disabled
                              value={fileName[elm.timestamp] || dataOrder[elm.timestamp]?.name}
                              className={`input-modal ${elm?.isRequired && !(fileName[elm.timestamp] || dataOrder[elm.timestamp]?.name) && "content__input--required"}`} />
                          </div>
                        </div>
                        <div className="group-content-row">
                          <div className="content-row">
                            <button
                              className="btn-modal"
                              id={elm.timestamp}
                              onClick={() => handleUpload({
                                nameType: elm.timestamp, numberOfMaterials: elm.numberOfMaterials
                              })}
                            >アップロード</button>
                            {elm.uploadFromAndTo ? ( <button
                              className="btn-modal"
                              disabled={!dataOrder ?.[elm.timestamp] ?.url}
                              onClick={() => window.open(`${dataOrder ?.[elm.timestamp] ?.url}`)}
                            >ダウンロード</button>) : (<div className="empty-div"></div>)}
                          </div>
                        </div>
                        <div className="group-content-row">
                          <div className="content-row">
                            {
                              (_.isEmpty(dataOrder ?.[elm.timestamp]) || !dataOrder ?.[elm.timestamp] ?.status) ?
                                <input
                                  type="text"
                                  onChange={handleChangeComment}
                                  name= {elm.timestamp}
                                  value={dataOrder ?.[elm.timestamp] ?.comment}
                                  disabled
                                  className="input-modal" /> : <div className="empty-div"></div>
                            }
                            <p className="text-time">
                              {dataOrder[elm.timestamp] ?.timestamp}
                            </p>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="group-content-row">
                          <div className="content-row">
                            <button className="download-btn">
                              <p>
                                {dataOrder?.[elm.timestamp]?.name}
                              </p>
                            </button>
                          </div>
                        </div>
                        <div className="group-content-row">
                          <div className="content-row">
                            <button
                              className="btn-modal"
                              disabled={!dataOrder ?.[elm.timestamp] ?.url}
                              onClick={() => window.open(`${dataOrder ?.[elm.timestamp] ?.url}`)}
                            >ダウンロード</button>
                            <button
                              onClick={() => handleChangeStatus({ nameType: elm.timestamp, value: true })}
                              className={`btn-modal ${(dataOrder ?.[elm.timestamp] ?.status || !_.has(dataOrder ?.[elm.timestamp], "status")) ? '' : 'isCheck'}`}
                            >OK</button>
                            <button
                              onClick={() => handleChangeStatus({ nameType: [elm.timestamp], value: false })}
                              className={`btn-modal ${(!dataOrder?.[elm.timestamp]?.status || !_.has(dataOrder ?.[elm.timestamp], "status")) ? '' : 'isCheck'}`}
                            >NG</button>
                          </div>
                        </div>
                        <div className="group-content-row">
                          <div className="content-row">
                            {
                              (_.isEmpty(dataOrder ?.[elm.timestamp]) || !dataOrder ?.[elm.timestamp] ?.status) ?
                                <input
                                  type="text"
                                  onChange={handleChangeComment}
                                  name= {elm.timestamp}
                                  value={dataOrder ?.[elm.timestamp] ?.comment}
                                  className="input-modal" /> : <div className="empty-div"></div>
                            }

                            <p className="text-time">
                              {dataOrder ?.[elm.timestamp] ?.timestamp}
                            </p>
                          </div>
                        </div>
                      </>
                    ) }
                  </div>)
            })}

        </div>
        <div className="group-btn-update">
          <button className={`btn-modal ${(!isDisabled ) ? 'isCheck' : ''}`}
            disabled = {!isDisabled }
            onClick={handleUpdate}
          >確認済</button>
          <button className={`btn-modal ${isDisabled ? 'isCheck' : '' }`}
          disabled = {isDisabled }
          onClick={handleReject}>差戻</button>
        </div>
      </div>
    </div>
  );
};

export default withRouter(TableUploadReportUploadInfoMonitoring);
