/*eslint-disable-next-line*/
// export const getTokenFromLocalStorage = () => localStorage.getItem('USER_ACCESS_TOKEN');

import { ROLE } from './constants';
import moment from 'moment';
import _ from 'lodash';

export const getRole = ({ idRole = 1 }) => {
  switch (idRole) {
    case '1':
    case '2':
    case '3':
    case '4':
      return ROLE.CENTER;
    case '5':
    case '6':
    case '7':
      return ROLE.PARTNERGROUP;
    case '8':
    case '9':
    case '10':
      return ROLE.PARTNER;
    case '11':
    case '12':
    case '13':
      return ROLE.CLIENT;
    case '14':
      return ROLE.WORKER
    default:
      return null
  }
};

export const getRoleNew = ({ idRole = 1 }) => {
  switch (idRole) {
    case '1':
      return ROLE.NEOMARS;
    case '2':
    case '3':
    case '4':
      return ROLE.CENTER;
    case '5':
    case '6':
    case '7':
      return ROLE.PARTNERGROUP;
    case '8':
    case '9':
    case '10':
      return ROLE.PARTNER;
    case '11':
    case '12':
    case '13':
      return ROLE.CLIENT;
    case '14':
      return ROLE.WORKER
    default:
      return null
  }
};

export const getRoleOrganization = ({ idRole = 1 }) => {
  switch (idRole) {
    case '1':
      return ROLE.NEOMARS;
    case '2':
    case '3':
    case '5':
    case '6':
      return ROLE.PARTNERGROUP;
    default:
      return null
  }
};

export const getRoleColor = ({ idRole = 1 }) => {
  switch (idRole) {
    case '1':
    case '2':
    case '3':
    case '4':
    case '5':
    case '6':
    case '7':
      return 'center';
    case '8':
    case '9':
    case '10':
      return 'partner';
    case '11':
    case '12':
    case '13':
      return 'client';
    default:
      return 'worker';
  }
};

export const getDate = (value) => {
  let date = new Date(moment(value));
  let currentDay = date.getDay()
  let dayName = ''

  switch (currentDay) {
    case 0:
      dayName = '日'
      break
    case 1:
      dayName = '月'
      break
    case 2:
      dayName = '火'
      break
    case 3:
      dayName = '水'
      break
    case 4:
      dayName = '木'
      break
    case 5:
      dayName = '金'
      break
    case 6:
      dayName = '土'
      break
    default:
      return ''
  }
  return dayName;
};

export const formatInputSelectTime = (dateTime) => {
  if (!dateTime) return '';
  return dateTime.slice(-5);
};

export const formatInputSelectDate = (dateTime) => {
  if (!dateTime) return '';
  return dateTime.slice(-16, -6);
};

export const getNoOrder = ({ index, limit, activePage }) => {
  return (parseInt(activePage) === 1) ? (index + 1) : (activePage * limit - limit + index + 1);
};

export const genId = () => Math.random().toString(36).substr(2, 9)

export const convertFileToBase64 = (file) =>
new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = (error) => reject(error);
});

export const generatePassword = () => {
  let chars = ['abcdefghijklmnopqrstuvwxyz', 'ABCDEFGHIJKLMNOPQRSTUVWXYZ', '0123456789', '!@#$%^&*'];
  let randPwd = [3, 2, 2, 1]
    .map(function (len, i) {
      return Array(len)
        .fill(chars[i])
        .map(function (x) {
          return x[Math.floor(Math.random() * x.length)];
        })
        .join('');
    })
    .concat()
    .join('')
    .split('')
    .sort(function () {
      return 0.5 - Math.random();
    })
    .join('');
  return randPwd;
};

export const combineTime = ({ end, visitTime, start }) => {
  const hourEnd = +end?.split(':')[0];
  const minuteEnd = +end ?.split(':')[1];
  const hourVisitTime = +visitTime?.split(':')[0];
  const minuteVisitTime = +visitTime ?.split(':')[1];
  const hourStart = +start?.split(':')[0];
  const minuteStart = +start ?.split(':')[1];
  return `${(hourEnd - hourStart) + hourVisitTime}:${Math.abs(minuteEnd - minuteStart) + minuteVisitTime}:00`
};

export const getTimeWorking = ({ end, start }) => {
  const hourEnd = +end?.split(':')[0];
  const minuteEnd = +end ?.split(':')[1];

  const hourStart = +start?.split(':')[0];
  const minuteStart = +start ?.split(':')[1];
  return `00:${(((hourEnd - hourStart) * 60 ) + (minuteEnd - minuteStart))}:00`
};

const isEmptyVal = (value) => {
  return  value === undefined || value === null ||
          (typeof value === "object" && Object.keys(value).length === 0) ||
          (typeof value === "string" && value.trim().length === 0)
}

export const validateRequried = ({data, arrField = [], onDisabled = () => {} , arrFieldRemove = []}) => {
  const fieldBlank = arrField.map((field) => {
    const elm = document.getElementById(field);
    if(isEmptyVal(elm)) return null;
    if(isEmptyVal(data?.[field])){
      elm.classList.add('isRequired');
      return field;
    }else {
      elm.classList.remove('isRequired');
      return null;
    }
  });

  arrFieldRemove.map((field) => {
    const elm = document.getElementById(field);
    if(isEmptyVal(elm)) return null;
    elm.classList.remove('isRequired');
    return null;
  });

  _.isEmpty(_.compact(fieldBlank)) ? onDisabled({status: false}) : onDisabled({status: true});
};

export const addDays = (date, days) => {
  const time = new Date(date);
  time.setDate(time.getDate() + days);
  return time;
};

export const subDays = (date, days) => {
  const time = new Date(date);
  time.setDate(time.getDate() - days);
  return time;
};

export const filterBlankItem = (item) => {
  const arrayValueItem = Object.values(item)
  return arrayValueItem.every((val, i, arr) => val === '' || undefined)
};

