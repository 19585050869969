import { getConfig } from '../env';
import axios from 'axios';
import socketIOClient from "socket.io-client";

const cookie = () => {
  let accessToken = document.cookie.match(new RegExp('(^| )' + 'STELLA.ACCESS_TOKEN' + '=([^;]+)'))?.[2] || '';
  let refreshToken = document.cookie.match(new RegExp('(^| )' + 'STELLA.REFRESH_TOKEN' + '=([^;]+)'))?.[2] || '';
  return `STELLA.ACCESS_TOKEN=${accessToken};STELLA.REFRESH_TOKEN=${refreshToken}`
}


const logoutRedirect = () => {
  document.cookie = `STELLA.ACCESS_TOKEN=`;
  document.cookie = `STELLA.REFRESH_TOKEN=`;
  window.location.href = '/login';
  localStorage.removeItem('IS_LOGIN');
}

export const socket = socketIOClient(getConfig('SOCKET_KEY'));

export const callApi = (data, options) => {
  const { endpoint, method, idProvide = '' , permissionIdGroup } = options;
  const provider = localStorage.getItem('provide');
  const idRole = localStorage.getItem('role');
  const idProvider = idProvide || localStorage.getItem('idProvide');
  const permissionId =  localStorage.getItem('permissionId');

  const config = {
    // withCredentials: true,
    // credentials: 'include',
    method: method || 'POST',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      'Authorization': cookie(),
    },
    data:
      method === 'POST' || method === 'PUT'
        ? JSON.stringify({
          ...data
        })
        : undefined,
    url: `${getConfig('API_SERVER')}/${endpoint}`

  };
  if (provider) {
    let newAdd = { ...config?.headers }
    config.headers = {
      ...newAdd,
      'provider': provider
    }
  }
  if (idRole) {
    let newAdd = { ...config?.headers }
    config.headers = {
      ...newAdd,
      'idRole': idRole
    }
  }

  if (idProvider) {
    let newAdd = { ...config?.headers }
    config.headers = {
      ...newAdd,
      'idProvider': idProvider
    }
  }

  if (permissionId) {
    let newAdd = { ...config?.headers }
    config.headers = {
      ...newAdd,
      'permissionId': permissionId
    }
  }
  if (permissionIdGroup) {
    let newAdd = { ...config?.headers }
    config.headers = {
      ...newAdd,
      'permissionId': permissionIdGroup
    }
  }
  /*eslint-disable-next-line*/
  return axios({ ...config })
    .then((response) => {
      if(response?.data?.message?.errorCode === 252) {
        logoutRedirect();
      }else {
        return response.data;
      }
    })
    .catch((err) => {
      return { errorMessage: err };
    });
};

export const uploadFile = (data, options) => {
  const { endpoint, method, idProvide = '' } = options;
  const provider = localStorage.getItem('provide');
  const idRole = localStorage.getItem('role');
  const idProvider = idProvide || localStorage.getItem('idProvide')
  const permissionId =  localStorage.getItem('permissionId');

  const config = {
    // withCredentials: true,
    // credentials: 'include',
    method: method || 'POST',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': cookie(),
    },
    data: data,
    url: `${getConfig('API_SERVER')}/${endpoint}`
  };
  if (provider) {
    let newAdd = { ...config?.headers }
    config.headers = {
      ...newAdd,
      'provider': provider
    }
  }
  if (idRole) {
    let newAdd = { ...config?.headers }
    config.headers = {
      ...newAdd,
      'idRole': idRole
    }
  }

  if (idProvider) {
    let newAdd = { ...config?.headers }
    config.headers = {
      ...newAdd,
      'idProvider': idProvider
    }
  }

  if (permissionId) {
    let newAdd = { ...config?.headers }
    config.headers = {
      ...newAdd,
      'permissionId': permissionId
    }
  }

  /*eslint-disable-next-line*/
  return axios({ ...config })
    .then((response) => {
      if(response?.data?.message?.errorCode === 252) {
        logoutRedirect();
      }else {
        return response.data;
      }
    })
    .catch((err) => {
      return { errorMessage: err };
    });
};

export const downloadFile = (data, options) => {
  const { endpoint, method, idProvide = '', fileName = '' } = options;
  const provider = localStorage.getItem('provide');
  const idRole = localStorage.getItem('role');
  const idProvider = idProvide || localStorage.getItem('idProvide')
  const permissionId =  localStorage.getItem('permissionId');

  const config = {
    method: method || 'POST',
    responseType: 'blob',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      'Authorization': cookie(),
    },
    data: data,
    url: `${getConfig('API_SERVER')}/${endpoint}`
  };
  if (provider) {
    let newAdd = { ...config?.headers }
    config.headers = {
      ...newAdd,
      'provider': provider
    }
  }
  if (idRole) {
    let newAdd = { ...config?.headers }
    config.headers = {
      ...newAdd,
      'idRole': idRole
    }
  }

  if (idProvider) {
    let newAdd = { ...config?.headers }
    config.headers = {
      ...newAdd,
      'idProvider': idProvider
    }
  }

  if (permissionId) {
    let newAdd = { ...config?.headers }
    config.headers = {
      ...newAdd,
      'permissionId': permissionId
    }
  }

  /*eslint-disable-next-line*/
  return axios({ ...config })
    .then((response) => {
      if(response?.data?.message?.errorCode === 252) {
        logoutRedirect();
      }else {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
      }
    })
    .catch((err) => {
      return { errorMessage: err };
    });
};
