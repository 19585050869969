import React from 'react';
import './style.scss';
import TableModiflyOrganization from './TableModifyOrganization';
import HeaderMasterSetting from '../../../components/HeaderMasterSetting';
import { useSelector } from 'react-redux';

const ModifyOrganization = () => {
  const colorScreen = useSelector((store) => store.utils.colorMe);
  let typeRole = (process.browser && new URL(window.location.href).searchParams.get('typeRole')) || null;

  return (
    <>
      <div className="wrapper-modify-organization-master">
        <HeaderMasterSetting checkName={typeRole === 'management-group' ? '/managementGroup_organizations' : '/erp_organizations'} color={colorScreen?.code} colorText={colorScreen?.colorText} />
        <div className="wrapper-main ">
          <div className="body-modify-organization">
            <TableModiflyOrganization typeRole={typeRole} />
          </div>
        </div>
        {/* <MemoHistory colors={colorScreen?.code} colorText={colorScreen?.colorText} /> */}
      </div>
    </>
  );
};

export default ModifyOrganization;
