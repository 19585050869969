import React, { useState, useEffect } from 'react';
import { Collapse } from 'reactstrap';
import TableDetailListStaff from '../TableDetailListStaff';
import PaginationCustom from '../../../../components/PaginationCustom';
import _ from 'lodash';

const CollaseTableAdress = (props) => {
  let {
    item,
    t,
    index,
    p,
    color,
    role,
    roleDetail,
    idRole,
    handleHide,
    hideshow,
    activeStaff,
    colorText,
    handlePageEstimate,
    listArea
  } = props;
  const [hideCollap, setHideCollap] = useState('');

  const toggleHide = (id) => {
    handleHide(id);
  };

  const handleChangePageEstimate = (pageNumber) => {
    handlePageEstimate(pageNumber, item.id);
  };

  const handleCollap = (id) => {
    if (hideCollap === id) {
      setHideCollap('');
    } else {
      setHideCollap(id);
    }
  };

  return (
    <>
      <div className="wrapper-btn-colappse" onClick={() => toggleHide(item?.partnerId)}>
        <div
          data-id={index}
          className="mb-1 searchbar d-flex justify-content-between align-items-center "
          style={{ background: `${color}` }}
        >
          <div className="ml-2" style={{ color: `${colorText}` }}>
            {item.officialName}
          </div>
          <div className="mr-2 d-flex" style={{ color: `${colorText}` }}>
            {hideshow === item.partnerId ? (
              <i className="icon-up-open icon-searchbar" />
            ) : (
              <i className="icon-down-open icon-searchbar" />
            )}
          </div>
        </div>
      </div>
      <Collapse isOpen={hideshow === item.partnerId} className="collap--style">
        {item?.staffs.map((ele, index) => {
          return (
            <TableDetailListStaff
              key={index}
              item={ele}
              p={p}
              t={t}
              color={color}
              colorText={colorText}
              roleDetail={roleDetail}
              idRole={idRole}
              role={role}
              hideCollap={hideCollap}
              handleCollap={handleCollap}
              listArea={listArea}
            />
          );
        })}
        {!_.isEmpty(item?.staffs) && item?.count > 10 && (
          <PaginationCustom
            activePage={activeStaff}
            itemsCountPerPage={10}
            totalItemsCount={item?.count}
            pageRangeDisplayed={5}
            handlePageChange={handleChangePageEstimate}
          />
        )}

      </Collapse>
    </>
  );
};

export default CollaseTableAdress;
