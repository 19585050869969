import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import TableAddRole from './TableAddRole';

const ModalAddRole = (props) => {
  const {
    toggle = () => { }, modal = false, ModalAddRoleNext = () => { }, statusBeforeConfirm,
    routeList, namePermission, ModalDetailSetting = () => { }, idProvide } = props;

  return (
    <div>
      <Modal className="modal-dialog-custom" isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}></ModalHeader>
        <ModalBody>
          <TableAddRole
            routeList={routeList} ModalAddRoleNextChild={ModalAddRoleNext} statusBeforeConfirm={statusBeforeConfirm}
            namePermission={namePermission} ModalDetailSetting={ModalDetailSetting} idProvide={idProvide} />
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ModalAddRole;
