import React, { useEffect, useRef, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { translate } from 'react-i18next';
import { compose } from 'redux';
import GoogleMap from 'google-map-react';
import Geocode from 'react-geocode';
import 'assets/scss/page/DispatchMap/style.scss';
import { pushToast } from 'components/Toast';
import _ from 'lodash';
import { DISPATCH } from 'constants/endpoints';
import TableListOrder from './TableListOrder';
import { getConfig } from '../../../env'
import { SCREEN_NAME } from '../../../helpers/constants';

const MapDispatch = ({ history, t }) => {
  let visitDate = (process.browser && new URL(window.location.href).searchParams.get('visitDate')) || '';

  const [lat, setLat] = useState('');
  const [lng, setLng] = useState('');
  const [latTokyo, setLatTokyo] = useState('');
  const [lngTokyo, setLngTokyo] = useState('');
  const [listArea, setListArea] = useState([]);
  const [areaSelected, setAreaSelected] = useState('');
  const [staffSelected, setStaffSelected] = useState({});
  const [addressMoved, setAddressMoved] = useState('');
  const [listStaff, setListStaff] = useState([]);
  const [listOrder, setListOrder] = useState([]);
  const [isRefreshingMap, setIsRefreshingMap] = useState(false);
  const [radiusArea, setRadiusArea] = useState(10);
  const tableListOrderRef = useRef()

  const refreshMap = async () => { setIsRefreshingMap(true); setTimeout(() => { setIsRefreshingMap(false); }, 10); };

  useEffect(() => {
    getLocation();
    getAllArea({ visitDate });
  }, []);

  useEffect(() => {
    if (areaSelected === 'all') {
      setLat(latTokyo);
      setLng(lngTokyo);
    } else {
      if (listOrder?.length > 0) {
        const fistLat = listOrder[0].latitude ? listOrder[0].latitude : '';
        const fistLng = listOrder[0].longitude ? listOrder[0].longitude : '';
        if (fistLat && fistLng) {
          setLat(fistLat);
          setLng(fistLng);
        }
      }
    }
  }, [listOrder]);

  useEffect(() => {
    if (areaSelected) {
      getDataByVisiDateAndArea({ areaSelected, visitDate });
    }
    if (!_.isEmpty(staffSelected)) {
      setStaffSelected({});
      setAddressMoved('');
    }
  }, [areaSelected]);

  useEffect(() => {
    if (!_.isEmpty(staffSelected)) {
      refreshMap();
    }
  }, [staffSelected])

  const getAllArea = async ({ visitDate }) => {
    try {
      const data = await DISPATCH.GET_ALL_AREA({ visitDate });
      if (data) {
        setListArea(data?.data);
      }
    } catch (errorMessage) {
      console.log('errorMessage: ', errorMessage);
    }
  };

  const getLocation = async () => {
    let address = 'Tokyo, Japan';
    const itemTmp = await Geocode.fromAddress(address);
    if (itemTmp) {
      const { lng, lat } = itemTmp?.results[0]?.geometry?.location;
      setLatTokyo(lat);
      setLngTokyo(lng);
      setLat(lat);
      setLng(lng);
    }
  };

  const getAddressLatLog = async ({ lat, lng }) => {
    const itemTmp = await Geocode.fromLatLng(lat, lng);
    if (itemTmp) {
      const address = itemTmp?.results[0]?.formatted_address;
      setAddressMoved(address);
    }
  };

  const getMapOptions = () => {
    return {
      disableDefaultUI: false,
      mapTypeControl: true,
      streetViewControl: false,
      styles: [{ featureType: 'poi', elementType: 'labels', stylers: [{ visibility: 'on' }] }],
    };
  };

  const getDataByVisiDateAndArea = async ({ areaSelected, visitDate }) => {
    try {
      const data = await DISPATCH.GET_DATA_BY_VISITDATE_AND_AREA({ area: areaSelected, visitDate });
      if (data) {
        setListStaff(data?.data?.staffs);
        setListOrder(data?.data?.orders);
        refreshMap();
      }
    } catch (errorMessage) {
      console.log('errorMessage: ', errorMessage);
    }
  };

  const handleChangeArea = (e) => {
    const value = e.target.value;
    setAreaSelected(value);
    tableListOrderRef.current.callGetListOrderCondtionInfor({
      prefecturesVisited: value && value !== 'all' ? [value] : '',
      staffLoginId: '',
    })
  };

  const handleChangeStaff = (e) => {
    const value = e.target.value;
    const staffChoose = listStaff?.filter(item => item.staffId === value)[0] || {};
    setStaffSelected(staffChoose);
    setAddressMoved(staffChoose.addressMoved);
    setLat(staffChoose?.dispatchShift?.latitude ? staffChoose?.dispatchShift?.latitude : Number(staffChoose?.latitude));
    setLng(staffChoose?.dispatchShift?.longitude ? staffChoose?.dispatchShift?.longitude : Number(staffChoose?.longitude));
    setRadiusArea(staffChoose?.distanceRange ? parseFloat(staffChoose?.distanceRange) : 10);
    tableListOrderRef.current.callGetListOrderCondtionInfor({
      prefecturesVisited: areaSelected && areaSelected !== 'all' ? [areaSelected] : '',
      staffLoginId: staffChoose?.loginId || '',
    })

    if (!value && areaSelected) {
      return getDataByVisiDateAndArea({ areaSelected, visitDate });
    }
  };

  const handleSaveStaff = () => {
    const body = {
      latitude: lat,
      longitude: lng,
      addressMoved: addressMoved || staffSelected?.address,
      visitDate,
      screenName: SCREEN_NAME.DISPATCH_SETTINGS_ORDER,
      updateTitle: '保存'
    };
    handleUpdatePositionStaff({ body, staffId: staffSelected.staffId })
  };

  const handleUpdatePositionStaff = async ({ body, staffId }) => {
    try {
      const data = await DISPATCH.UPDATE_POSITION_STAFF({ body, staffId });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
      } else {
        pushToast('success', 'Update Position Staff success');
        // getDataByVisiDateAndArea({ areaSelected, visitDate });
        const newStaff = listStaff.map(i => {
          if (i.staffId === staffId) {
            return { ...i, dispatchShift: { ...i.dispatchShift, addressMoved: body?.addressMoved, latitude: body.latitude, longitude: body.longitude } }
          } else { return i }
        });
        setListStaff(newStaff);
        setLat(body.latitude);
        setLng(body.longitude);
        refreshMap();
        tableListOrderRef.current.callGetListOrderCondtionInfor({
          prefecturesVisited: areaSelected && areaSelected !== 'all' ? [areaSelected] : '',
          staffLoginId: staffSelected.loginId || '',
        })
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleApiLoaded = ({ map, maps }) => {
    let staff;
    let order;

    //render red circle 10km
    let redCircle = new maps.Circle({
      strokeColor: '#FF0000',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#FF0000',
      fillOpacity: 0.3,
      map,
      center: !_.isEmpty(staffSelected) && { lat, lng },
      radius: radiusArea * 1000,
    });

    //render staff
    listStaff?.length && listStaff.map(i => {
      staff = new maps.Marker({
        animation: i.staffId === staffSelected.staffId && maps.Animation.DROP,
        position: new maps.LatLng(
          i?.dispatchShift?.latitude ? i?.dispatchShift?.latitude : i.latitude,
          i?.dispatchShift?.longitude ? i?.dispatchShift?.longitude : i.longitude
        ),
        map,
        draggable: (staffSelected.staffId === i.staffId),
        zIndex: (staffSelected.staffId === i.staffId) ? 100 : 1,
      })

      maps.event.addListener(staff, 'dragend', function(evt) {
        setLat(evt.latLng.lat());
        setLng(evt.latLng.lng());
        getAddressLatLog({ lat: evt.latLng.lat(), lng: evt.latLng.lng() })
      });
      const content = (i?.surName || '') + (i?.name || '');
      let infoStaff = new maps.InfoWindow()

      maps.event.addListener(staff, 'click', (function(marker, content, infoStaff) {
        return function() {
          infoStaff.setContent(content);
          infoStaff.open(map, marker);
        };
      })(staff, content, infoStaff));
      {
        (staffSelected.staffId === i.staffId) &&
          redCircle.bindTo('center', staff, 'position');
      }
    });

    //render order
    listOrder?.length && listOrder.map(i => {
      if (!i.isDispatch) {
        order = new maps.Marker({
          icon: {
            path: maps.SymbolPath.CIRCLE,
            fillColor: 'yellow',
            fillOpacity: 1,
            scale: 7,
            strokeColor: 'yellow',
            strokeWeight: 4,
          },
          map,
          position: new maps.LatLng(
            i.latitude,
            i.longitude
          ),
        });
        const content = i.managementCode;
        let infoOrder = new maps.InfoWindow()
        maps.event.addListener(order, 'click', (function(marker, content, infoOrder) {
          return function() {
            infoOrder.setContent(content);
            infoOrder.open(map, marker);
          };
        })(order, content, infoOrder));
      } else if (i.staffId.includes(staffSelected.loginId)) {
        order = new maps.Marker({
          icon: {
            path: maps.SymbolPath.CIRCLE,
            fillColor: 'blue',
            fillOpacity: 1,
            scale: 12,
            strokeColor: 'blue',
            strokeWeight: 4,
          },
          map,
          position: new maps.LatLng(
            i.latitude,
            i.longitude
          ),
        });
        const content = i.managementCode;
        let infoOrder = new maps.InfoWindow()
        maps.event.addListener(order, 'click', (function(marker, content, infoOrder) {
          return function() {
            infoOrder.setContent(content);
            infoOrder.open(map, marker);
          };
        })(order, content, infoOrder));
      }
    })
  };

  const handleLinkBack = () => {
    history.push(
      {
        pathname: '/dispatch-all',
        state: { visitDate },
      }
    )
  };

	return (
		<div className="form-map-dispatch">
			<div className="line-date" style={{ minHeight: 0 }}>
				{visitDate}
			</div>
			<div className="form-link-back">
				<div className="form-back-dispatch">
          <i className="fas fa-tachometer-alt"></i>
          <span className="btn-back" onClick={handleLinkBack}>ディスパッチ</span>
				</div>
				<div className="arrow">{'>'}</div>
				<div>MAP</div>
			</div>

			<div className="form-map">
				<div className='content-map'>
        {areaSelected && !isRefreshingMap && (
            <GoogleMap
              bootstrapURLKeys={{
                key: getConfig('REACT_APP_GOOGLE_API_KEY'),
                language: "ja", region: "JP"
              }}
              defaultCenter={{ lat, lng }}
              defaultZoom={11}
              options={getMapOptions}
              onGoogleApiLoaded={handleApiLoaded}
            >
            </GoogleMap>
          )}
				</div>
        <div className="form-setting">
          <div className="form-setting-select">
            <div className="content-select">
              <p className="title">都道府県</p>
              <select onChange={handleChangeArea} value={areaSelected}>
                <option value=''> -- 地域の選択 -- </option>
                <option value="all">すべて</option>
                {
                  listArea?.map((item, index) => {
                    return (
                      <option value={item} key={index}>{item}</option>
                    )
                  })
                }
              </select>
            </div>

            <div className="content-select">
              <p className="title">作業員氏名</p>
              {
                areaSelected &&
                <select onChange={handleChangeStaff} value={staffSelected?.staffId || ''}>
                  <option value=''> -- スタッフの選択 -- </option>
                  {
                    listStaff?.map((item, index) => {
                      return (
                        <option value={item.staffId} key={index}>{item.surName} {item.name}</option>
                      )
                    })
                  }
                </select>
              }
            </div>

            <div className="content-select">
              <p className="title">起点住所</p>
              <label>{addressMoved || staffSelected?.address}</label>
            </div>

            <div className="content-select">
              <p className="title">選択中のエリア中心緯度経度</p>
              <div className="input-lat-long">
                <p>緯度</p>
                <input disabled value={!_.isEmpty(staffSelected) ? lat : ''}/>
              </div>

              <div className="input-lat-long">
                <p>経度</p>
                <input disabled value={!_.isEmpty(staffSelected) ? lng : ''}/>
              </div>
            </div>

            <button
              className="btn-save"
              onClick={ () => handleSaveStaff() }
              disabled={_.isEmpty(staffSelected)}
            >保存</button>

          </div>
          <div className="form-time">
            <p className="title">スケジュール</p>
            <div className="form-flg">
              <div className="content-flg left">
                {
                  _.chunk(staffSelected?.dispatchShift?.freeFlags, 4).slice(0, 15).map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="item-flg"
                      >
                        {`${index + 6}:00`}
                        <div className={item.includes(0) ? 'color-div busy' : 'color-div free'}></div>
                      </div>
                    )
                  })
                }
              </div>
              <div className="content-flg right">
                {
                  _.chunk(staffSelected?.dispatchShift?.freeFlags, 4).slice(15, 29).map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="item-flg"
                      >
                        {`${index + 21}:00`}
                        <div className={item.includes(0) ? 'color-div busy' : 'color-div free'}></div>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="form-note">選択中のエリアに含まれる拠点</div>
      <TableListOrder
        visitDate={visitDate}
        ref={tableListOrderRef}
        translate={t}
      />
		</div>
	);
};

export default compose(translate('translations'), withRouter)(MapDispatch);
