import React, { useEffect, useState } from 'react';

const RegistrationRadio = ({ selectOption = 'standard_csv', onSelect = () => {} }) => {
  // const [selectOption, SetSelectOption] = useState(option);
  const handleOptionChange = (option) => {
    // SetSelectOption(option);
    onSelect(option);
  };
  return (
    <div className="registration-radio-wrapper">
      <div className="wrapper-column mt-3" style={{ fontSize: '14px' }}>
        新規登録種別
      </div>
      <div className="table-right d-flex align-items-center" style={{ fontSize: '14px' }}>
        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="radio"
            name="inlineRadioOptions"
            id="inlineRadio1"
            value="standard_csv"
            checked={selectOption === 'standard_csv'}
            onChange={() => handleOptionChange('standard_csv')}
          />
          <label className="form-check-label" htmlFor="inlineRadio1">
            標準csv
          </label>
        </div>
        {/*<div className="form-check form-check-inline mx-3">*/}
        {/*  <input*/}
        {/*    className="form-check-input"*/}
        {/*    type="radio"*/}
        {/*    name="inlineRadioOptions"*/}
        {/*    id="inlineRadio2"*/}
        {/*    value="individual_csv"*/}
        {/*    checked={selectOption === 'individual_csv'}*/}
        {/*    onChange={() => handleOptionChange('individual_csv')}*/}
        {/*  />*/}
        {/*  <label className="form-check-label" htmlFor="inlineRadio2">*/}
        {/*    個別csv*/}
        {/*  </label>*/}
        {/*</div>*/}
        {/*<div className="form-check form-check-inline">*/}
        {/*  <input*/}
        {/*    className="form-check-input"*/}
        {/*    type="radio"*/}
        {/*    name="inlineRadioOptions"*/}
        {/*    id="inlineRadio3"*/}
        {/*    value="manual_input"*/}
        {/*    checked={selectOption === 'manual_input'}*/}
        {/*    onChange={() => handleOptionChange('manual_input')}*/}
        {/*  />*/}
        {/*  <label className="form-check-label" htmlFor="inlineRadio3">*/}
        {/*    手入力*/}
        {/*  </label>*/}
        {/*</div>*/}
      </div>
    </div>
  );
};

export default RegistrationRadio;
