import React, { useEffect, useState } from 'react';
import FooterMaintenance from '../../../components/FooterMaintenance';
import OrganizationList from './OrganizationList';
import HeaderMasterSetting from '../../../components/HeaderMasterSetting';
import { useSelector } from 'react-redux';
import { ORGANIZATION_ENDPOINT,
  PERMISSION_ROLE, USER_ENDPOINT,
  MASTER_SETTING } from '../../../constants/endpoints';
import { ROLE, ROLE_ERP_ORGANIZATION, ROLE_ORGANIZATION, SCREEN_NAME } from '../../../helpers/constants';
import Loading from 'components/Loading/Loading';
import { getRoleOrganization } from '../../../helpers/helpers';
import MemoHistory from '../../../components/MemoHistory';

const Organizations = () => {
  const colorScreen = useSelector((store) => store.utils.colorMe);
  const [listOrganizations, setListOrganization] = useState([]);
  const [isShow, setIsShow] = useState('');
  const [loading, setLoading] = useState(false);
  const [listDataOrganization, setListDataOrganization] = useState();
  const [roleOrganization, setRoleOrganiztion] = useState();
  const [moduleManager, setModuleManager] = useState({});

  const getModuleManager = async ({colorId}) => {
    setLoading(true);
    try {
      const data = await MASTER_SETTING.GET_MODULE_MANAGER({colorId});
      if (data) {
        setLoading(false);
        setModuleManager(data?.data);
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  useEffect(() => {
    getModuleManager({ colorId: colorScreen.colorId })
  },[colorScreen])

  const getListOrganization = async ({ page = 1, limit = 10 }) => {
    try {
      setLoading(true);
      const data = await ORGANIZATION_ENDPOINT.GET_ORAGINIZATIONS({ page, limit });
      if (data) {
        setListOrganization(data?.data);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const changeLoading = (isLoading = false) => {
    setLoading(isLoading);
  };

  const handleCollap = (key) => {
    if (isShow === key) {
      setIsShow('');
    } else {
      setIsShow(key);
    }
  };

  const isVerifyRole = async () => {
    try {
      const data = await PERMISSION_ROLE.CHECK_ROLE({ roleId: localStorage.getItem('role') });
      if (data && data?.code === 0) {
        console.log(data.data);
        return data.data;
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getPartnerAndClient = async ({ type = '', page = 1, limit = 10 , name = '' }) => {
    setLoading(true);
    try {
      const data = await USER_ENDPOINT.GET_PARTNER_AND_CLIENT({ type: type, page: page, limit: limit , name : name });
      if (data) {
        setListDataOrganization(data?.data);
        setLoading(false);
      }
    } catch (errorMessage) {
      setLoading(false);
      console.log('errorMessage: ', errorMessage);
    }
  };

  useEffect(() => {
    const newRole = localStorage.getItem('role');
    if (newRole) {
      setRoleOrganiztion(getRoleOrganization({ idRole: newRole }));
    }
  }, []);

  useEffect(() => {
    if (isVerifyRole && roleOrganization === ROLE.NEOMARS) {
      getListOrganization({});
    }
    if (isVerifyRole && roleOrganization === ROLE.PARTNERGROUP) {
      getPartnerAndClient({});
    }
  }, [roleOrganization]);

  return (
    <div className="wrapper-master-setting">
      <Loading loading={loading} />
      <HeaderMasterSetting color={colorScreen?.code} colorText={colorScreen?.colorText} />
      {
        <>
          <div className="body pb-4">
            <div className="wrapper-main">
              {isVerifyRole && roleOrganization === ROLE.PARTNERGROUP && (
                <div align="center" className="search">
                  <table width="100%" className="formTbl mb20 ">
                    <colgroup>
                      <col width="15%" />
                      <col width="75%" />
                    </colgroup>
                    <tbody>
                      <tr className="pb-1">
                        <th style={{ backgroundColor: `${colorScreen?.code}`, color: `${colorScreen?.colorText}` }}>
                        CloudID
                        </th>
                        <td style={{ whiteSpace: 'nowrap', backgroundColor: 'white' }}>
                          <input
                            type="text"
                            className="form-control"
                            readOnly="readonly"
                            value={listDataOrganization?.organization?.organizationId || ''}
                            disabled
                          />
                        </td>
                      </tr>
                      <tr className="pb-1">
                        <th style={{ backgroundColor: `${colorScreen?.code}`, color: `${colorScreen?.colorText}` }}>
                        企業名
                        </th>
                        <td style={{ whiteSpace: 'nowrap', backgroundColor: 'white' }}>
                          <input
                            type="text"
                            className="form-control"
                            name="officialName"
                            disabled
                            value={listDataOrganization?.organization?.officialName || ''}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th style={{ backgroundColor: `${colorScreen?.code}`, color: `${colorScreen?.colorText}` }}>
                        フリガナ
                        </th>
                        <td style={{ whiteSpace: 'nowrap', backgroundColor: 'white' }}>
                          <input
                            type="text"
                            name="officialNameKana"
                            className="form-control"
                            value={listDataOrganization?.organization?.officialNameKana || ''}
                            disabled
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}
              <div className="wrapper-result">{/* <div className="result-title">
                </div> */}</div>
              {isVerifyRole &&
                roleOrganization === ROLE.NEOMARS &&
                ROLE_ERP_ORGANIZATION.map((item, index) => {
                  return (
                    <OrganizationList
                      key={index}
                      colors={colorScreen?.code}
                      colorText={colorScreen?.colorText}
                      item={item}
                      listOrganizations={listOrganizations}
                      isShow={isShow}
                      handleCollap={handleCollap}
                      changeLoading={changeLoading}
                      isVerifyRole={isVerifyRole}
                      roleOrganization={roleOrganization}
                    />
                  );
                })}
              {isVerifyRole &&
                roleOrganization === ROLE.PARTNERGROUP &&
                ROLE_ORGANIZATION.map((item, index) => {
                  if(moduleManager?.[item.role]) {
                    return (
                    <OrganizationList
                      key={index}
                      colors={colorScreen?.code}
                      colorText={colorScreen?.colorText}
                      item={item}
                      isShow={isShow}
                      handleCollap={handleCollap}
                      changeLoading={changeLoading}
                      isVerifyRole={isVerifyRole}
                      data={listDataOrganization}
                      roleOrganization={roleOrganization}
                      // getPartnerAndClient={getPartnerAndClient}
                    />
                  );
                  }
                })}
              <div className="d-flex justify-content-center align-items-center group-button"></div>
            </div>
            <MemoHistory colors={colorScreen?.code} colorText={colorScreen?.colorText}   screenName={SCREEN_NAME.ORGANIZATION}/>
          </div>
        </>
      }
      <FooterMaintenance />
    </div>
  );
};

export default Organizations;
