import React, { Fragment, useEffect, useState } from 'react';
import 'assets/scss/page/DescribeReceiveInfo/style.scss';
import Header from 'components/Header';
import Footer from 'components/Footer';
import { ORDER, MASTER_SETTING } from 'constants/endpoints';
import DateTimePicker from 'components/DateTimePicker';
import { pushToast } from 'components/Toast';
import { SCREEN_NAME, HEADER_MENU, DATE_JP_FORMAT } from 'helpers/constants';
import { useHistory, Link } from 'react-router-dom';
import DetailData from 'components/DetailCenter/DetailData';
import DetailDataList from 'components/DetailCenter/DetailDataList';
import DetailOrder from 'components/DetailCenter/DetailOrder';
import DetailListAllData from 'components/DetailCenter/DetailListAllData';
import DetailVisitDate from 'components/DetailOrderErp/DetailVisitDate';
import ButtonDetail from 'components/ButtonDetail';
import DetailReportErp from '../../../components/DetailOrderErp/DetailReportErp';
import MattersToBeSent from 'components/MattersToBeSent';
import DetailManagemment from 'components/DetailOrderErp/DetailManagemment';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { translate } from 'react-i18next';
import _ from 'lodash';
import { Collapse } from 'reactstrap';
import HeaderMasterSetting from 'components/HeaderMasterSetting';
import DetailTreeDocumentSetting from 'components/DetailTreeDocumentSetting';
import { formatInputSelectDate } from 'helpers/helpers';
import PopupRemarks from 'components/popupRemarks';
import RejectMemo from 'components/RejectMemo';
import { getDataHistory } from 'actions/authentication';
import { useDispatch } from 'react-redux';

const mockData = {
  screenName: SCREEN_NAME.RECEIVE_INFO,
  applicantCompanyName: '',
  personInChargeOfApplication: '',
  contactPersonForApplication: '',
  workerResponseRequired: true,
  appointmentCategory: '',
  notificationItems: '',
  visitedSiteName: '',
  contactPersonInCharge: '',
  contactInformation: '',
  visitedPostalCode: '',
  prefecturesVisited: '',
  visitedBaseAddress: '',
  visitedBuildingName: '',
  scheduledInstallationLocation: '',
  managementMattersToBeSent: '',
  preferredDate: { visiteDate1: {}, visiteDate2: {}, visiteDate3: {}, visiteDate4: {}, visiteDate5: {} }
};

const DescribeReceiveInfo = () => {
  const dispatch = useDispatch();
  const [dataOrder, setDataOrder] = useState(mockData);
  let orderId = process.browser && new URL(window.location.href).searchParams.get('orderId');
  let organizationId = process.browser && new URL(window.location.href).searchParams.get('organizationId') || '';
  let partnerId = process.browser && new URL(window.location.href).searchParams.get('partnerId');
  let back = process.browser && new URL(window.location.href).searchParams.get('back') || '';
  const [idRole, setIdRole] = React.useState(null);
  let name = (process.browser && new URL(window.location.href).searchParams.get('p')) || 'receive_info';
  const [p, setP] = React.useState(name || HEADER_MENU.RECEIVE_ALL);
  const [listDetailScreen, setListDetailScreen] = useState([]);
  const colorScreen = useSelector((store) => store.utils.colorMe);
  const [data, setData] = useState('');
  const [isOpenSettingAppointment, setIsOpenSettingAppointment] = useState(true);
  const [dataCheck, setDetailCheck] = useState({});
  const [documentSetting, setDocumentSetting] = useState([]);
  const history = useHistory();
  const [showPopupConfirm, setShowPopupConfirm] = useState(false);
  const statusBeforeConfirm = dataCheck.confirmedTheDayBefore;

  useEffect(() => {
    getListRoleChild();
    getListOptionSetting();
  }, []);

  const getListRoleChild = async () => {
    const newRole = localStorage.getItem('role');
    try {
      const data = await ORDER.GET_LIST_DETAIL_SETTING({
        arrRole: newRole,
        nameScreen: p,
        orderId,
        organizationId
      });
      if (data) {
        setListDetailScreen(data?.data?.detailScreen.concat(
          { name: 'rejectMemo', sortNum: 7, status: true }, { name: 'button', sortNum: 7, status: true }
        ));
        const newData = _.groupBy(data?.data?.documentSettingReceiveAll, 'typeChildId');
        setDocumentSetting(newData);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getListOptionSetting = async () => {
    const idProvider = localStorage.getItem('idProvide');
    try {
      const data = await MASTER_SETTING.GET_LIST_OPTION_SETTING(idProvider);
      if (data?.data) {
        setDetailCheck(data?.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    setP(name);
  }, [name]);

  const onChangeText = (e) => {
    let name = e.target.name;
    setDataOrder({ ...dataOrder, [name]: e.target.value });
  };

  const updateOrderInfo = async ({ body = {}, id_order = '' }) => {
    try {
      delete body.notificationItems;
      delete body.beforeConfirmDocumentSetting;
      const data = await ORDER.UPDATE_ORDER_INFO({ body, id_order, organizationId });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
      } else {
        dispatch(getDataHistory({ orderId: id_order, screenName: SCREEN_NAME.RECEIVE_INFO, organizationId }));
        pushToast('success', 'Update order success');
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onUpdateOrder = (name) => {
    updateOrderInfo({ body: _.omit({ ...dataOrder, updateTitle: name, companyName: localStorage.getItem('companyName') }, 'detailSetting', 'receiveInfoMemo', 'applicantCompanyName'), id_order: orderId });
  };

  const updateStatus = async ({ id_order = '', body = {} }) => {
    try {
      const data = await ORDER.UPDATE_ORDER_STATUS({
        id_order,
        body,
        organizationId
      });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
      } else {
        pushToast('success', 'Active order success');
        dispatch(getDataHistory({ orderId: id_order, screenName: SCREEN_NAME.RECEIVE_INFO, organizationId }));
        history.push(`${back === 'confirm' ? '/confirm-page' : '/list?p=receive_info'}`);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const requestConfirm = async ({ orderId = '', screenName = '', companyName = '', updateTitle = '' }) => {
    try {
      const data = await ORDER.REQUEST_CONFIRM_RECEIVE_INFO({ orderId, body: { screenName, companyName, updateTitle } });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
      } else {
        dispatch(getDataHistory({ orderId: orderId, screenName: SCREEN_NAME.RECEIVE_INFO, organizationId }));
        pushToast('success', 'Request confirm order success');
        history.push('/list?p=receive_info');
      }
    } catch (e) {
      console.log(e);
    }
  };

  const rejectRequestConfirm = async ({ orderId = '', screenName = '', receiveInfoMemo = '', companyName = '', updateTitle = '' }) => {
    try {
      const data = await ORDER.REJECT_REQUEST_RECEIVE_INFO({ orderId, body: { screenName, receiveInfoMemo, companyName, updateTitle }, organizationId });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
      } else {
        pushToast('success', 'Reject request order success');
        dispatch(getDataHistory({ orderId: orderId, screenName: SCREEN_NAME.RECEIVE_INFO, organizationId }));
        history.push(back === 'confirm' ? '/confirm-page' : '/list?p=receive_info');
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleAction = () => {
    setShowPopupConfirm(!showPopupConfirm);
  };

  const getOrderDetail = async ({ orderId = '' }) => {
    try {
      const data = await ORDER.GET_ORDER_DETAIL_APPOINT_INFO({ orderId, screenName: 'receive_info', organizationId });
      if (data) {
        let newData = { ...data, screenName: SCREEN_NAME.RECEIVE_INFO };
        setData(newData);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    const newRole = localStorage.getItem('role');
    setIdRole(newRole);

    if (orderId) {
      getOrderDetail({
        orderId
      });
    }
  }, [orderId]);

  useEffect(() => {
    if (data) {
      const preferredDate = { ...data?.preferredDate };
      Object.keys(preferredDate).forEach(key => {
        if (preferredDate[key]?.visitDateTo?.length === 5 && preferredDate[key].visitDate) {
          preferredDate[key].visitDateTo = moment(`${preferredDate[key].visitDate} ${preferredDate[key].visitDateTo}`).format('YYYY-MM-DD HH:mm:');
        }
      });
      data.preferredDate = preferredDate;

      setDataOrder({
        ...dataOrder,
        applicantCompanyName: data.applicantCompanyName,
        personInChargeOfApplication: data.personInChargeOfApplication,
        contactPersonForApplication: data.contactPersonForApplication,
        workerResponseRequired: data.workerResponseRequired,
        appointmentCategory: data.appointmentCategory,
        notificationItems: data.notificationItems,
        visitedSiteName: data.visitedSiteName,
        contactPersonInCharge: data.contactPersonInCharge,
        contactInformation: data.contactInformation,
        visitedPostalCode: data.visitedPostalCode,
        prefecturesVisited: data.prefecturesVisited,
        visitedBaseAddress: data.visitedBaseAddress,
        visitedBuildingName: data.visitedBuildingName,
        scheduledInstallationLocation: data.scheduledInstallationLocation,
        managementMattersToBeSent: data.managementMattersToBeSent,
        contactName: data.contactName,
        beforeConfirmDocumentSetting: data.beforeConfirmDocumentSetting || [],
        preferredDate: data?.preferredDate || {
          visitDate1: {},
          visitDate2: {},
          visitDate3: {},
          visitDate4: {},
          visitDate5: {}
        },
        detailSetting: data.detailSetting || [],
        monitoringMattersToBeSent: data.monitoringMattersToBeSent,
        erpMattersToBeSent: data.erpMattersToBeSent,
        alertStartDate: data.alertStartDate,
        applicationPeriodForAdmission: data.applicationPeriodForAdmission,
        priorConfirmationDeadline: data.priorConfirmationDeadline,
        visitDate: data.visitDate,
        visitDateFrom: data.visitDateFrom,
        receiveInfoMemo: data.receiveInfoMemo,
        visitDateTo: data?.visitDateTo ? (moment(`${formatInputSelectDate(data?.visitDateTo)}`, `${DATE_JP_FORMAT}`).isValid() ? data?.visitDateTo : `${data?.visitDate ? `${data?.visitDate} ${data?.visitDateTo}` : ''}`) : '',
        admissionApplicationDeadline: data.admissionApplicationDeadline,
      });
    }
  }, [data]);

  const onClickActive = (name) => {
    updateStatus({ id_order: orderId, body: { screenName: SCREEN_NAME.RECEIVE_INFO, statusReceiveInfo: false, updateTitle: name, companyName: localStorage.getItem('companyName') } });
  };

  const handleTextArea = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    const newData = { ...dataOrder, [name]: value };
    setDataOrder(newData);
  }

  const onRequestConfirm = (name) => {
    requestConfirm({ orderId, screenName: SCREEN_NAME.RECEIVE_INFO, updateTitle: name, companyName: localStorage.getItem('companyName') });
  };

  const onRejectRequest = (name) => {
    rejectRequestConfirm({ orderId, screenName: SCREEN_NAME.RECEIVE_INFO, receiveInfoMemo: dataOrder?.receiveInfoMemo, updateTitle: name, companyName: localStorage.getItem('companyName') });
  };

  const changeRadio = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    let newData = { ...dataOrder };
    newData = { ...newData, [name]: e.target.value };
    if (value === 'true') {
      newData = { ...newData, [name]: true };
    } else if (value === 'false') newData = { ...newData, [name]: false };
    setDataOrder(newData);
  };

  const onChangeRadioText = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    let newData = { ...dataOrder, [name]: value };
    setDataOrder(newData);
  };

  return (
    <div className="wrapper-describe-receive">
      <div className="main">
        {back === 'confirm' && ['2', 2, '3', 3, '5', 5, '6', 6].includes(idRole) ? <HeaderMasterSetting idRole={idRole} color={colorScreen?.code} colorText={colorScreen?.colorText} /> : <Header p={p} idRole={idRole} color={colorScreen?.code} colorText={colorScreen?.colorText} />}
        <div className="body-wrapper">
          <div className="logout_btn">
            <Link to={`${back === 'confirm' ? '/confirm-page' : back === 'mypage' ? '/my_page' : `list?p=${p}`}`}>
              <span className="mypage_ab_custom">戻る</span>
            </Link>
          </div>
          {_.sortBy(listDetailScreen, 'sortNum')?.map((item, index) => {
            if (item.status) {
              switch (item.name) {
                case 'clientCompany':
                  return <DetailOrder data={data} p={p} key={index} />;
                case 'applicationCompany':
                  return <DetailData data={data} p={p} link={false} key={index} />;
                case 'visitDate':
                  return (
                    <DetailVisitDate
                      dataVisitDate={_.pick(dataOrder, ['visitDate', 'visitDateFrom', 'visitDateTo', 'preferredDate'])}
                      onChangeVisitDate={dataVisitDate => {
                        setDataOrder({ ...dataOrder, ...dataVisitDate });
                      }}
                      key={index}
                    />
                  );
                case 'management':
                  return <DetailManagemment data={data} key={index} />;
                case 'operationSetting':
                  return (
                    <div className='group-setting' key={index}>
                      <div className='detail-data-wrapper'>
                        <div className='detail-cancel-table-wrapper'>
                          <div className='title-absolute'>個別物件設定変更</div>
                          <div className='group-setting__item'>
                            <span className='label'>作業員返答区分</span>
                            <div className='group-input'>
                              <input
                                type='radio'
                                value={true}
                                name='workerResponseRequired'
                                checked={dataOrder?.workerResponseRequired === true}
                                onChange={changeRadio}
                              />
                              <label htmlFor='worker_fg1'>必要</label>
                              <input
                                type='radio'
                                value={false}
                                name='workerResponseRequired'
                                checked={dataOrder?.workerResponseRequired === false}
                                onChange={changeRadio}
                              />
                              <label htmlFor='worker_fg0'>不要</label>
                            </div>
                          </div>
                          <div className='group-setting__item'>
                            <span className='label'>アポイント区分</span>
                            <div className='group-input'>
                              <input
                                type='radio'
                                value='センター'
                                name='appointmentCategory'
                                checked={dataOrder?.appointmentCategory === 'センター'}
                                onChange={onChangeRadioText}
                              />
                              <label htmlFor='appoint_user1'>{[5, 6, 7, '5', '6', '7'].includes(idRole) ? 'ManagementGroup' : '契約企業'}</label>
                              <input
                                type='radio'
                                value='パートナー'
                                name='appointmentCategory'
                                checked={dataOrder?.appointmentCategory === 'パートナー'}
                                onChange={onChangeRadioText}
                              />
                              <label htmlFor='appoint_user0'>協力企業</label>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* {
                        statusBeforeConfirm &&
                        <div className='detail-data-wrapper group-setting__appointment'>
                          <div className='detail-cancel-table-wrapper'>
                            <div className='title-absolute'
                              onClick={() => setIsOpenSettingAppointment(!isOpenSettingAppointment)}>
                              オプション
                              {isOpenSettingAppointment ? <i className='fas fa-arrow-circle-up ml-2'></i> :
                                <i className='fas fa-arrow-circle-down ml-2'></i>}
                            </div>
                            <Collapse isOpen={isOpenSettingAppointment}>
                              <div className='inline'>
                                <div className='group-setting__item'>
                                  <span className='label'>アラート起算日</span>
                                  <DateTimePicker
                                    date={dataOrder?.alertStartDate ? new Date(dataOrder.alertStartDate) : null}
                                    onChangeDate={date => setDataOrder({
                                      ...dataOrder,
                                      alertStartDate: date ? moment(date).format('YYYY/MM/DD') : null
                                    })}
                                  />
                                </div>
                                <div className='group-setting__item'>
                                  <span className='label'>入館申請期限</span>
                                  <input
                                    type='number'
                                    name='priorConfirmationDeadline'
                                    defaultValue={dataOrder?.beforeConfirmDocumentSetting?.find(e => e.name === '入館申請書類')?.priorConfirmationDeadline}
                                    onChange={onChangeText}
                                  />
                                </div>
                              </div>
                              <div className='group-setting__item'>
                                <span className='label'>事前確認期限</span>
                                <input
                                  type='number'
                                  name='admissionApplicationDeadline'
                                  defaultValue={dataOrder?.admissionApplicationDeadline}
                                  onChange={onChangeText}
                                />
                              </div>
                            </Collapse>
                          </div>
                        </div>
                      } */}

                    </div>
                  );
                case 'mattersSent':
                  return (
                    <MattersToBeSent
                      onChange={onChangeText}
                      dataOrder={dataOrder}
                      idRole={idRole}
                      screenName={SCREEN_NAME.RECEIVE_INFO}
                      key={index}
                    />
                  );
                case 'fileUpload':
                  return (
                    Object.keys(documentSetting).map((item, i) => {
                      const nameForm = documentSetting?.[item]?.[0]?.nameTypeChild;
                      return (
                        <DetailReportErp
                          p={p}
                          data={data} organizationId={organizationId}
                          orderId={orderId} partnerId={partnerId}
                          key={i} documentSetting={documentSetting?.[item]}
                          nameForm={nameForm}
                          type={'reportInformation'}
                          screenName={SCREEN_NAME.RECEIVE_INFO}
                        />
                      )
                    })
                  )
                case 'button':
                  return (
                    <Fragment key={index}>
                      {back !== 'confirm' && (
                        <ButtonDetail text="保存" onUpdateOrder={onUpdateOrder} disabled={data?.statusReceiveInfoRequest || ['2', 2, '3', 3, '5', 5, '6', 6].includes(idRole)} className="btn-handle-order" />
                      )}
                      <div className="grid__flex grid__flex--center">
                        {back !== 'confirm' && (<ButtonDetail text={data?.receiveInfoApproval && !(data?.statusReceiveInfoRequest || ['2', 2, '3', 3, '5', 5, '6', 6].includes(idRole)) ? '受注処理' : (data?.statusReceiveInfoRequest || ['2', 2, '3', 3, '5', 5, '6', 6].includes(idRole)) ? '承認待ち' : '受注処理'} disabled={data?.statusReceiveInfoRequest || ['2', 2, '3', 3, '5', 5, '6', 6].includes(idRole)} onUpdateOrder={!['2', 2, '3', 3, '5', 5, '6', 6].includes(idRole) && data?.receiveInfoApproval ? onRequestConfirm : onClickActive} className="btn-handle-order" />)}
                        {
                          back === 'confirm' && data?.receiveInfoApproval && (
                            <>
                              <ButtonDetail text="承認" onUpdateOrder={onClickActive} disabled={!data?.statusReceiveInfoRequest} className="btn-handle-order" />
                              <ButtonDetail text="差戻" onUpdateOrder={handleAction} disabled={!data?.statusReceiveInfoRequest} className="btn-handle-order" />
                            </>
                          )
                        }
                      </div>
                    </Fragment>
                  )
                case 'detailInfo':
                  return <DetailListAllData data={data} screenName={SCREEN_NAME.RECEIVE_INFO} key={index} />;
                case 'document':
                  return (
                    <DetailTreeDocumentSetting orderId={orderId} type={'reportInformation'} order={data} key={index} />
                  );
                case 'history':
                  return (
                    <DetailDataList
                      datePick={false}
                      screenName={SCREEN_NAME.RECEIVE_INFO}
                      orderId={orderId}
                      key={index}
                    />
                  );
                default:
                  return '';
              }
            }
          })}
        </div>
        <PopupRemarks
          headerTitle="差戻コメント"
          rederBody={() => {
            return (
              <textarea name='receiveInfoMemo' className="popup-confirm-custom__body--textarea" onChange={handleTextArea}>{dataOrder?.receiveInfoMemo}</textarea>
            );
          }}
          okText="登録"
          onConfirm={() => onRejectRequest('登録')}
          cancelText="キャンセル"
          onCancel={() => {
            setShowPopupConfirm(false);
          }}
          showPopup={showPopupConfirm}
          onToggle={() => setShowPopupConfirm(!showPopupConfirm)}
        />
        <Footer color={colorScreen?.code} colorText={colorScreen?.colorText} />
      </div>
    </div>
  );
};

export default translate('translations')(DescribeReceiveInfo);
