import React, { Fragment, useEffect, useState } from 'react';
import { translate } from 'react-i18next';
import { compose } from 'redux';
import Header from 'components/Header';
import Footer from 'components/Footer';
import { ORDER } from 'constants/endpoints';
import 'assets/scss/page/DetailTomorrowClient/style.scss';
import 'assets/scss/page/center/BeforeConfirm/style.scss';
import DetailData from 'components/DetailCenter/DetailData';
import DetailDataList from 'components/DetailCenter/DetailDataList';
import DetailOrder from 'components/DetailCenter/DetailOrder';
import DetailTreeDocumentSetting from 'components/DetailTreeDocumentSetting';
import BeforeConfirmDetail from 'components/BeforeConfirm/Erp/BeforeConfirmDetail';
import BeforeConfirmVisit from 'components/BeforeConfirm/Erp/BeforeConfirmVisit';
import BeforeConfirmInfo from 'components/BeforeConfirm/Erp/BeforeConfirmInfo';
import BeforeConfirmUpload from 'components/BeforeConfirm/Erp/BeforeConfirmUpload';
import BeforeConfirmComment from 'components/BeforeConfirm/Erp/BeforeConfirmComment';
import DetailListAllData from 'components/DetailCenter/DetailListAllData';
import { pushToast } from 'components/Toast';
import { SCREEN_NAME, PERMITTED_PARAM_BEFORE_CONFIRM, DATE_JP_FORMAT } from 'helpers/constants';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { useHistory, Link } from 'react-router-dom';
import Loading from 'components/Loading/Loading';
import { getRoleNew, formatInputSelectDate } from 'helpers/helpers';
import moment from 'moment';
import HeaderMasterSetting from 'components/HeaderMasterSetting';
import PopupRemarks from 'components/popupRemarks';
import RejectMemo from 'components/RejectMemo';

const DetailBeforeConfirmErp = ({ t }) => {
  const [role, setRole] = React.useState(null);
  const [idRole, setIdRole] = React.useState(null);
  const [dataOrders, setDataOrders] = useState({});
  const colorScreen = useSelector((store) => store.utils.colorMe);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [listDetailScreen, setListDetailScreen] = useState([]);
  const [documentSettingBeforeConfirm, setDocumentSettingBeforeConfirm] = useState([]);
  const [showPopupConfirm, setShowPopupConfirm] = useState(false);
  let back = process.browser && new URL(window.location.href).searchParams.get('back') || '';
  let orderId = process.browser && new URL(window.location.href).searchParams.get('orderId');
  let organizationId = process.browser && new URL(window.location.href).searchParams.get('organizationId') || '';
  const isActive = process.browser && new URL(window.location.href).searchParams.get('isActive');

  const getOrderDetail = async ({ orderId = '' }) => {
    try {
      setLoading(true);
      const data = await ORDER.GET_DETAIL_CANCEL_INFO({
        orderId,
        organizationId: organizationId || '',
        screenName: 'tomorrow_info'
      });
      if (data?.data) {
        setDataOrders({
          ...data?.data,
          visitDateTo: data?.data?.visitDateTo ? (moment(`${formatInputSelectDate(data?.data?.visitDateTo)}`, `${DATE_JP_FORMAT}`).isValid()
            ? data?.data?.visitDateTo
            : `${data?.data?.visitDate ? `${data?.data?.visitDate} ${data?.data?.visitDateTo}` : ''}`) : ''
        });
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const getListRoleChild = async ({ newRole }) => {
    try {
      const data = await ORDER.GET_LIST_DETAIL_SETTING({
        arrRole: newRole,
        nameScreen: 'tomorrow_info',
        orderId,
        organizationId
      });
      if (data) {
        setListDetailScreen(data?.data?.detailScreen.concat(
          { name: 'rejectMemo', sortNum: 7, status: true }, { name: 'button', sortNum: 7, status: true }
        ));
        setDocumentSettingBeforeConfirm(data?.data?.documentSettingBeforeConfirm);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getOrderDetail({ orderId: orderId });
    const newRole = localStorage.getItem('role');
    setIdRole(newRole);
    setRole(getRoleNew({ idRole: newRole }));
    getListRoleChild({ newRole });
  }, []);

  const changeData = ({ name, value }) => {
    const newData = { ...dataOrders, [name]: value };
    setDataOrders(newData);
  };

  const changeDate = ({ data }) => {
    const newData = { ...dataOrders, ...data };
    setDataOrders(newData);
  };

  const requestConfirm = async ({ body = {}, orderId = '' }) => {
    try {
      setLoading(true);
      let data;
      if (body.name) {
        data = await ORDER.UPDATE_STATUS_BEFORE_DOCUMENT({ body, orderId });
      } else {
        delete body.name;
        data = await ORDER.UPDATE_STATUS_BEFORE_CONFIRM({ body, orderId });
      }

      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
        setLoading(false);
      } else {
        pushToast('success', 'Request confirm success');
        const newData = { ...dataOrders };
        setDataOrders(_.merge(newData, _.omit(data?.data, ['updatedAt', 'updatedBy'])));
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const updateBeforeConfirm = async ({ body = '', orderId = '' }) => {
    try {
      setLoading(true);
      const data = await ORDER.UPDATE_ORDER_BEFORE_CONFIRM({ body, orderId, organizationId });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else if (data.message.errorMessage) {
          pushToast('error', data?.message?.errorMessage);
        } else {
          pushToast('error', data?.message);
        }
        setLoading(false);
      } else {
        setLoading(false);
        pushToast('success', 'Upload order before confirm success');
        history.push(back === 'confirm' ? '/confirm-page' : '/list?p=tomorrow_info');
      }
    } catch (errorMessage) {
      console.log('errorMessage: ', errorMessage);
      setLoading(false);
    }
  };

  const rejectRequestConfirm = async ({ orderId = '', screenName = '', beforeConfirmMemo = '', updateTitle = '', companyName = '' }) => {
    try {
      const data = await ORDER.REJECT_REQUEST_BEFORE_CONFIRM({ orderId, body: {screenName, beforeConfirmMemo, updateTitle, companyName}, organizationId });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
      } else {
        pushToast('success', 'Reject request order success');
        history.push(back === 'confirm' ? '/confirm-page' : '/list?p=tomorrow_info');
      }
    } catch (e) {
      console.log(e);
    }
  };

  const requestBeforeConfirm = async ({ orderId = '', body = '' }) => {
    try {
      const data = await ORDER.REQUEST_CONFIRM_BEFORE_CONFIRM({ orderId, body, organizationId });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
      } else {
        pushToast('success', 'Request confirm order success');
        history.push('/list?p=tomorrow_info');
      }
    } catch (e) {
      console.log(e);
    }
  };

  const submitData = () => {
    const newData = { ...dataOrders, screenName: SCREEN_NAME.TOMORROW_INFO,  updateTitle: `保存　事前確認状況：${t(`history_translation.${dataOrders?.statusMonitoring || ''}`)}　作業員対応状況：${t(`history_translation.${dataOrders?.statusWorker || ''}`)}`, companyName: localStorage.getItem('companyName') };
    updateBeforeConfirm({ body: _.pick(newData, PERMITTED_PARAM_BEFORE_CONFIRM), orderId: dataOrders?.orderId });
  };


  const handleAction = () => {
    setShowPopupConfirm(!showPopupConfirm);
  };

  const handleTextArea = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    const newData = {...dataOrders, [name]: value};
    setDataOrders(newData);
  };

  const onRejectRequest = () => {
    rejectRequestConfirm({ orderId, screenName: SCREEN_NAME.TOMORROW_INFO, beforeConfirmMemo: dataOrders?.beforeConfirmMemo, updateTitle: '差戻', companyName: localStorage.getItem('companyName') });
  };

  const onRequestConfirm = () => {
    if(dataOrders?.statusMonitoring === 'correspondenceCompleted' && dataOrders?.tomorrowInfoApproval) {
      const newData = { ...dataOrders, screenName: SCREEN_NAME.TOMORROW_INFO, updateTitle: `保存　事前確認状況：${t(`history_translation.${dataOrders?.statusMonitoring || ''}`)}　作業員対応状況：${t(`history_translation.${dataOrders?.statusWorker || ''}`)}`, companyName: localStorage.getItem('companyName') };
      requestBeforeConfirm({ orderId: dataOrders?.orderId, body: _.pick(newData, PERMITTED_PARAM_BEFORE_CONFIRM) });
    } else {
      const newData = { ...dataOrders, screenName: SCREEN_NAME.TOMORROW_INFO, updateTitle: `保存　事前確認状況：${t(`history_translation.${dataOrders?.statusMonitoring || ''}`)}　作業員対応状況：${t(`history_translation.${dataOrders?.statusWorker || ''}`)}`, companyName: localStorage.getItem('companyName') };
      updateBeforeConfirm({ body: _.pick(newData, PERMITTED_PARAM_BEFORE_CONFIRM), orderId: dataOrders?.orderId });
    }
  };

  return (
    <div className='wrapper-detail-cancel-info-custom'>
      <div className='main'>
        <Loading loading={loading} />
        {['2', 2, '3', 3, '5', 5, '6', 6].includes(idRole) ? <HeaderMasterSetting idRole={idRole} color={colorScreen?.code} colorText={colorScreen?.colorText} /> : <Header idRole={idRole} color={colorScreen?.code} colorText={colorScreen?.colorText} p={'tomorrow_info'} />}
        <div className="body-wrapper">
          <div className="logout_btn">
            <Link to={`${back === 'confirm' ? '/confirm-page' : back === 'mypage' ? '/my_page': `list?p=tomorrow_info`}`}>
              <span className='mypage_ab_custom'>戻る</span>
            </Link>
          </div>
          {
            _.sortBy(listDetailScreen, 'sortNum')?.map((item, index) => {
              if(item.status) {
                switch (item.name) {
                  case 'clientCompany':
                    return <DetailOrder p={'tomorrow_info'} data={dataOrders} key={index} />;
                  case 'applicationCompany':
                    return <DetailData p={'tomorrow_info'} data={dataOrders} link={false} key={index} />;
                  case 'priorConfirmationStatus':
                    return <BeforeConfirmDetail p={'tomorrow_info'} data={dataOrders} changeData={changeData}
                                                changeDate={changeDate} key={index} role={role} />;
                  case 'visitDateWorker':
                    return <BeforeConfirmVisit role={role} p={'tomorrow_info'} data={dataOrders} changeData={changeData}
                                               changeDate={changeDate} requestConfirm={requestConfirm}
                                               screenName={SCREEN_NAME.TOMORROW_INFO} key={index}
                                               getOrderDetail={getOrderDetail} />;
                  case 'workerSupportStatus':
                    return <BeforeConfirmInfo role={role} p={'tomorrow_info'} data={dataOrders} changeData={changeData}
                                              orderId={orderId} requestConfirm={requestConfirm}
                                              screenName={SCREEN_NAME.TOMORROW_INFO} isActive={isActive} isRole={'erp'}
                                              key={index} />;
                  case 'variousApplications':
                    return <BeforeConfirmUpload role={role} p={'tomorrow_info'} data={dataOrders}
                                                changeData={changeData} requestConfirm={requestConfirm}
                                                screenName={SCREEN_NAME.TOMORROW_INFO}
                                                documentSettingBeforeConfirm={documentSettingBeforeConfirm}
                                                key={index} />;
                  case 'commentSection':
                    return (
                      <BeforeConfirmComment key={index} p={'tomorrow_info'} data={dataOrders} changeData={changeData} screen={SCREEN_NAME.TOMORROW_INFO}/>
                    )
                  // case 'rejectMemo':
                  //   return (
                  //     !['2', 2, '3', 3, '5', 5, '6', 6].includes(idRole) && dataOrders?.tomorrowInfoApproval && (
                  //       <RejectMemo key={index} rejectMemo={dataOrders?.beforeConfirmMemo} />
                  //     )
                  //   )
                  case 'button':
                    return(
                      <div className="content__button--submit" key={index}>
                        {back !== 'confirm' && (
                          <button
                            className="btn content__button--custom content__button--w100 content__button--w10 content__button--mgr10"
                            disabled={
                              dataOrders?.statusBeforeConfirmRequest || ['2', 2, '3', 3, '5', 5, '6', 6].includes(idRole)
                            }
                            onClick={onRequestConfirm}
                          >
                            {dataOrders?.statusMonitoring === 'correspondenceCompleted' && dataOrders?.tomorrowInfoApproval && !dataOrders?.statusBeforeConfirmRequest
                              ? '保存'
                              : dataOrders?.tomorrowInfoApproval && dataOrders?.statusBeforeConfirmRequest ? '承認待ち' : '保存'}
                          </button>
                        )}
                        {back === 'confirm' && dataOrders?.tomorrowInfoApproval && (
                          <>
                            <button className="btn content__button--custom content__button--w100 content__button--w10 content__button--mgr10" disabled={!dataOrders?.statusBeforeConfirmRequest} onClick={submitData}>承認</button>
                            <button className="btn content__button--custom content__button--w100 content__button--w10" disabled={!dataOrders?.statusBeforeConfirmRequest} onClick={handleAction}>差戻</button>
                          </>
                        )
                        }
                      </div>
                    )
                  case 'detailInfo':
                    return <DetailListAllData data={dataOrders} screenName={SCREEN_NAME.TOMORROW_INFO} key={index} />;
                  case 'document':
                    return <DetailTreeDocumentSetting orderId={orderId} order={dataOrders} key={index} />;
                  case 'history':
                    return <DetailDataList datePick={false} screenName={SCREEN_NAME.TOMORROW_INFO} orderId={orderId}
                                           organizationId={organizationId} key={index} />;
                  default:
                    break;
                }
              }
            })
          }
        </div>
        <PopupRemarks
          headerTitle="差戻コメント"
          rederBody={() => {
            return (
              <textarea name='beforeConfirmMemo' className="popup-confirm-custom__body--textarea" onChange={handleTextArea}>{dataOrders?.beforeConfirmMemo}</textarea>
            );
          }}
          okText="登録"
          onConfirm={onRejectRequest}
          cancelText="キャンセル"
          onCancel={() => {
            setShowPopupConfirm(false);
          }}
          showPopup={showPopupConfirm}
          onToggle={() => setShowPopupConfirm(!showPopupConfirm)}
        />
        <Footer color={colorScreen?.code} colorText={colorScreen?.colorText} />
      </div>
    </div>
  );
};
export default compose(translate('translations'))(DetailBeforeConfirmErp);
