import React, { useState, useEffect, useCallback } from 'react';
import './style.scss';
import DateTimePicker from '../DateTimePicker';
import { SCREEN_NAME, ROLE, enumOrderStatus, SAVE_STATUS, EXTRA_DATA_STATUS, DATE_JP_FORMAT } from '../../helpers/constants';
import { ORDER, ORDER_PARTNER, ORDER_CLIENT } from '../../constants/endpoints';
import { pushToast } from '../Toast';
import moment from 'moment';
import MemoTable from '../MemoTable';
import { Collapse } from 'reactstrap';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

const DetailDataList = ({
  role,
  orderId,
  datePick = true,
  title_remark = '更新履歴',
  body,
  getDataBody,
  orderDataDetail,
  screenName,
  organizationId = null,
  client = false,
  reloadData
}) => {
  const [CompletionDay, setCompletionDay] = useState('');
  const [beforeConfirmation, setBeforeConfirmation] = useState('');
  const [approvalRequestText, setApprovalRequestText] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [disable, setDisable] = useState(true);
  const history = useHistory();
  const paramSearch = useSelector((store) => store.utils.paramSearch);

  const onClickShow = () => {
    setIsOpen(!isOpen);
  };
  const updateDetailStatus = async ({ body = {}, orderId = '', notify }) => {
    try {
      let data;
      if (role === ROLE.CENTER || role === ROLE.PARTNERGROUP) data = await ORDER.UPDATE_RECEIVE_ALL({ body: body, orderId: orderId });
      if (role === ROLE.PARTNER) data = await ORDER_PARTNER.UPDATE_ORDER_PARTNER_DETAIL({ body: body, orderId: orderId, organizationId: organizationId || '' });
      if (role === ROLE.CLIENT) data = await ORDER_CLIENT.UPDATE_CLIENT_TOMORROW({ body: body, orderId: orderId });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
      } else {
        pushToast('success', `${notify} tomorrow info success`);
        reloadData()
      }
    } catch (e) {
      pushToast('error', `${notify} tomorrow info error`);
    }
  };
  const autoSet = (newExtraData) => {
    // newExtraData?.use || newExtraData?.option
    if (newExtraData === EXTRA_DATA_STATUS.USE || newExtraData === EXTRA_DATA_STATUS.OPTION) {
      switch (true) {
        case (role === ROLE.PARTNER)
          && (orderDataDetail ?.tomorrowDetailStatus === enumOrderStatus.PARTNER_SAVE
            || orderDataDetail ?.tomorrowDetailStatus === enumOrderStatus.CLIENT_REJECT
            || orderDataDetail ?.tomorrowDetailStatus === enumOrderStatus.CENTER_REJECT
            || orderDataDetail ?.tomorrowDetailStatus === enumOrderStatus.MANAGEMENT_GROUP_REJECT
            || _.isEmpty(orderDataDetail ?.tomorrowDetailStatus)
            || orderDataDetail ?.tomorrowDetailStatus === enumOrderStatus.CENTER_SAVE
            || orderDataDetail ?.tomorrowDetailStatus === enumOrderStatus.MANAGEMENT_GROUP_SAVE
            && (orderDataDetail ?.statusOfSave == null
            || orderDataDetail ?.statusOfSave === SAVE_STATUS.NULL)):
          setDisable(false);
          break;
        case (role === ROLE.CENTER || role === ROLE.PARTNERGROUP) && (
          !(orderDataDetail ?.tomorrowDetailStatus === enumOrderStatus.CENTER_APPROVED
           || orderDataDetail ?.tomorrowDetailStatus === enumOrderStatus.MANAGEMENT_GROUP_APPROVED)): {
            setDisable(false)
        }
          break;
        case (role === ROLE.CLIENT) && (orderDataDetail ?.tomorrowDetailStatus === enumOrderStatus.CENTER_APPROVED
          || orderDataDetail ?.tomorrowDetailStatus === enumOrderStatus.MANAGEMENT_GROUP_APPROVED):
          setDisable(false);
          break;
        default:
          setDisable(true);
          break;
      }
      // newExtraData?.unused
    } else if (newExtraData === EXTRA_DATA_STATUS.UNUSED) {
      switch (true) {
        case (role === ROLE.PARTNER):
          setDisable(false);
          break;
        case (role === ROLE.CENTER || role === ROLE.PARTNERGROUP):
          setDisable(false);
          break;
        default:
          setDisable(true);
          break;
      }
    }
  }
  useEffect(() => {
    setBeforeConfirmation(orderDataDetail?.beforeConfirmationDeadline || '')
    setApprovalRequestText(orderDataDetail?.approvalRequestText || '')
    setCompletionDay(orderDataDetail?.confirmationCompletionDay || '')
  }, [role, orderDataDetail])
  useEffect(() => {
    autoSet(orderDataDetail ?.extra)
  }, [orderDataDetail]);

  const onChangeCompletionDay = (date) => {
    if(date) {
      setCompletionDay(moment(new Date(date)).format(DATE_JP_FORMAT));
      getDataBody({ confirmationCompletionDay: moment(new Date(date)).format(DATE_JP_FORMAT) });
    }
  };
  const beforeConfirmationDeadline = (e) => {
    setBeforeConfirmation(e.target.value);
    getDataBody({ beforeConfirmationDeadline: e.target.value });
  };
  const onUpdateOrder = async (e) => {
    let statusUser = '';
    if (role === ROLE.PARTNER) {
      statusUser = ROLE.PARTNER.toLowerCase() + '_' + e?.currentTarget?.value;
    }
    if (role === ROLE.CENTER) {
      statusUser = ROLE.CENTER.toLowerCase() + '_' + e?.currentTarget?.value;
    }
    if (role === ROLE.PARTNERGROUP) {
      // statusUser = ROLE.PARTNERGROUP.toLowerCase() + '_' + e?.currentTarget?.value;
      statusUser = 'managementGroup' + '_' + e?.currentTarget?.value;
    }
    if (role === ROLE.CLIENT) {
      statusUser = ROLE.CLIENT.toLowerCase() + '_' + e?.currentTarget?.value;
    }

    if (e?.currentTarget?.value) {
      await updateDetailStatus({
        body: {
          ...body,
          tomorrowDetailStatus: statusUser,
          screenName: SCREEN_NAME.TOMORROW_INFO,
        },
        orderId: orderId,
        notify: e?.currentTarget?.value,
      });
    };
    if (paramSearch?.searchCurrentPath != 'undefined') {
      history.push(`/list?p=` + paramSearch?.searchCurrentPath);
    }
  }
  const onChageRequestText = (e) => {
    setApprovalRequestText(e.target.value);
    getDataBody({ approvalRequestText: e.target.value });
  };
  return (
    <div className="wrapper-detail-data-list">
      {datePick && (
        <div className="detail-cancel-table-wrapper detail-cancel-table-wrapper-three detail-cancel-table-wrapper-four">
          <div className="title-absolute">前日確認期限</div>
          <div className="block-item">
            <div className="block-left mb-1">
              <label>前日確認期限</label>
              <div className="block-date">
                <DateTimePicker
                  date={
                    CompletionDay?.length > 0
                      ? new Date(CompletionDay && CompletionDay)
                      : null
                  }
                  onChangeDate={onChangeCompletionDay}
                />
              </div>
            </div>
          </div>
          <div className="block-item">
            <div className="block-left mb-1">
              <label>前日確認完了日</label>
              <input
                type="number"
                value={beforeConfirmation}
                onChange={beforeConfirmationDeadline}
              />
            </div>
          </div>
        </div>
      )}
      <div className="detail-cancel-table-wrapper detail-cancel-table-wrapper-three detail-cancel-table-wrapper-four">
        <div className="title-absolute">{title_remark}</div>
        <div className="block-item">
          <div className="block-left mb-1 w-100">
            <input className="w-100" type="text" value={approvalRequestText} onChange={onChageRequestText} />
          </div>
        </div>
      </div>
      {
        // role parner
      }
      {(role === ROLE.PARTNER) && <div className="block-btn-bottom pb-1 grid--end">
      {/* extraData?.unused || extraData?.option */}
        {(orderDataDetail ?.extra === EXTRA_DATA_STATUS.UNUSED
          || orderDataDetail ?.extra === EXTRA_DATA_STATUS.OPTION)
          && !(orderDataDetail ?.tomorrowDetailStatus === enumOrderStatus.CLIENT_APPROVED)
          && (
          <button
            className='btn-item'
            // className={
            //   !disable ?
            //     ((orderDataDetail ?.tomorrowDetailStatus === enumOrderStatus.PARTNER_SAVE
            //       || orderDataDetail ?.tomorrowDetailStatus === enumOrderStatus.CENTER_SAVE)
            //       ? 'btn-disable' : 'btn-item') : 'btn-disable'}
            // disabled={!disable ? (orderDataDetail ?.tomorrowDetailStatus
            //   === enumOrderStatus.PARTNER_SAVE || orderDataDetail ?.tomorrowDetailStatus
            //   === enumOrderStatus.CENTER_SAVE) : disable}
            value='save'
            onClick={(e) => onUpdateOrder(e)}>
            更新
          </button>
        )}
        {/* extraData?.use || extraData?.option */}
        {(orderDataDetail ?.extra === EXTRA_DATA_STATUS.USE
          || orderDataDetail ?.extra === EXTRA_DATA_STATUS.OPTION)
          && !(orderDataDetail ?.tomorrowDetailStatus === enumOrderStatus.CLIENT_APPROVED)
          && (
          <button className={disable ? 'btn-disable' : 'btn-item'}
            disabled={disable}
            value='approved'
            onClick={(e) => onUpdateOrder(e)}>
            承認依頼
          </button>
        )}
      </div>}
      {
        // role center
      }
      {(role === ROLE.CENTER || role === ROLE.PARTNERGROUP) && <div className="block-btn-bottom pb-1 grid--end">
      {/* extraData?.use || extraData?.option */}
        {(orderDataDetail ?.extra === EXTRA_DATA_STATUS.USE
          || orderDataDetail ?.extra === EXTRA_DATA_STATUS.OPTION)
          // && !(orderDataDetail ?.tomorrowDetailStatus === enumOrderStatus.CLIENT_REJECT)
          // && !(orderDataDetail ?.tomorrowDetailStatus === enumOrderStatus.CENTER_REJECT)
          && !(orderDataDetail ?.tomorrowDetailStatus === enumOrderStatus.CLIENT_APPROVED)
          && (
          <button
            className={disable ? 'btn-disable' : 'btn-item'}
            value="reject"
            disabled={disable}
            onClick={(e) => onUpdateOrder(e)}>
            差戻
          </button>
          )}
        {(orderDataDetail ?.extra === EXTRA_DATA_STATUS.USE
          || orderDataDetail ?.extra === EXTRA_DATA_STATUS.OPTION)
          // && !(orderDataDetail ?.tomorrowDetailStatus === enumOrderStatus.CLIENT_REJECT)
          // && !(orderDataDetail ?.tomorrowDetailStatus === enumOrderStatus.CENTER_REJECT)
          && !(orderDataDetail ?.tomorrowDetailStatus === enumOrderStatus.CLIENT_APPROVED)
          && (
          <button
            className={disable ? 'btn-disable' : 'btn-item'}
            value="approved"
            disabled={disable}
            // disabled={!disable ? !(orderDataDetail ?.tomorrowDetailStatus
            //   === enumOrderStatus.CENTER_APPROVED) : disable}
            onClick={(e) => onUpdateOrder(e)}>
            承認依頼
          </button>
        )}
        {/* extraData?.use || extraData?.option */}
        {(orderDataDetail ?.extra === EXTRA_DATA_STATUS.UNUSED
          || orderDataDetail ?.extra === EXTRA_DATA_STATUS.OPTION)
          && !(orderDataDetail ?.tomorrowDetailStatus === enumOrderStatus.CLIENT_APPROVED)
          && (
          <button
            className='btn-item'

            // className={disable ? ((orderDataDetail ?.tomorrowDetailStatus
            //   === enumOrderStatus.PARTNER_SAVE || orderDataDetail ?.tomorrowDetailStatus
            //   === enumOrderStatus.CENTER_SAVE) ? 'btn-item' : 'btn-disable') : 'btn-item'}
            // disabled={disable ? !(orderDataDetail ?.tomorrowDetailStatus
            //   === enumOrderStatus.CENTER_SAVE) : disable}
            value="save" onClick={(e) => onUpdateOrder(e)}>
            更新
          </button>
          )}
        {/* extraData?.unused || extraData?.option */}

      </div>}
      {
        // role monitoring
      }
      {role === ROLE.CLIENT && <div className="block-btn-bottom pb-1 grid--end">
      {/* extraData?.use || extraData?.option */}
        {(orderDataDetail ?.extra === EXTRA_DATA_STATUS.USE
          || orderDataDetail ?.extra === EXTRA_DATA_STATUS.OPTION)
          && (!_.isEmpty(orderDataDetail ?.tomorrowDetailStatus))
          && !(orderDataDetail ?.tomorrowDetailStatus === enumOrderStatus.CLIENT_REJECT)
          && !(orderDataDetail ?.tomorrowDetailStatus === enumOrderStatus.CENTER_REJECT)
          && !(orderDataDetail ?.tomorrowDetailStatus === enumOrderStatus.CLIENT_APPROVED)
          && !(orderDataDetail ?.tomorrowDetailStatus === enumOrderStatus.CENTER_SAVE)
          && !(orderDataDetail ?.tomorrowDetailStatus === enumOrderStatus.CLIENT_SAVE)
          && !(orderDataDetail ?.tomorrowDetailStatus === enumOrderStatus.MANAGEMENT_GROUP_REJECT)
          && !(orderDataDetail ?.tomorrowDetailStatus === enumOrderStatus.MANAGEMENT_GROUP_SAVE)
          && !(orderDataDetail ?.tomorrowDetailStatus === enumOrderStatus.PARTNER_APPROVED)
          && !(orderDataDetail ?.tomorrowDetailStatus === enumOrderStatus.PARTNER_SAVE)
          && (
          <button
            // className={disable ? 'btn-disable' : 'btn-item'}
            className={'btn-item'}
            value="reject"
            // disabled={disable}
            onClick={(e) => onUpdateOrder(e)}>
            差戻
          </button>
        )}
        {/* extraData?.use || extraData?.option */}
        {(orderDataDetail ?.extra === EXTRA_DATA_STATUS.USE
          || orderDataDetail ?.extra === EXTRA_DATA_STATUS.OPTION)
          && (!_.isEmpty(orderDataDetail ?.tomorrowDetailStatus))
          && !(orderDataDetail ?.tomorrowDetailStatus === enumOrderStatus.CLIENT_REJECT)
          && !(orderDataDetail ?.tomorrowDetailStatus === enumOrderStatus.CENTER_REJECT)
          && !(orderDataDetail ?.tomorrowDetailStatus === enumOrderStatus.CLIENT_APPROVED)
          && !(orderDataDetail ?.tomorrowDetailStatus === enumOrderStatus.CENTER_SAVE)
          && !(orderDataDetail ?.tomorrowDetailStatus === enumOrderStatus.CLIENT_SAVE)
          && !(orderDataDetail ?.tomorrowDetailStatus === enumOrderStatus.MANAGEMENT_GROUP_REJECT)
          && !(orderDataDetail ?.tomorrowDetailStatus === enumOrderStatus.MANAGEMENT_GROUP_SAVE)
          && !(orderDataDetail ?.tomorrowDetailStatus === enumOrderStatus.PARTNER_APPROVED)
          && !(orderDataDetail ?.tomorrowDetailStatus === enumOrderStatus.PARTNER_SAVE)
          && (
          <button
            // className={disable ? 'btn-disable' : 'btn-item'}
            className={'btn-item'}
            value="approved"
            // disabled={disable}
            onClick={(e) => onUpdateOrder(e)}>
            承認依頼
          </button>
        )}
      </div>}
    </div>
  );
};

export default DetailDataList;
