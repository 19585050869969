import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import 'react-datepicker/dist/react-datepicker.css';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import '../../../../assets/scss/page/CsvMaster/index.scss';
import { pushToast } from '../../../../components/Toast';
import { MASTER_SETTING } from '../../../../constants/endpoints';
import { SCREEN_NAME } from '../../../../helpers/constants';
import { EXPORT_SETTING_DEFAULT } from './dataDefault';
import Loading from 'components/Loading/Loading';
import _ from 'lodash';
import { getDataHistoryMasterSetting } from 'actions/authentication';
import { useDispatch } from 'react-redux';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const ModalExport = (props) => {
  const { toggle = () => {}, modal = false, fieldExport, nameRoleExport, productId, nameTitle } = props;
  const dispatch = useDispatch();
  const [listMockup, setListMockup] = useState([]);
  const [loading, setLoading] = useState(false);
  const dataDefault = EXPORT_SETTING_DEFAULT.filter(e => e.distinguish === 'stellaCloud').map(n => n.name);

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const arrTmp = reorder(listMockup?.fieldMaster, result.source.index, result.destination.index);
    setListMockup({ ...listMockup, fieldMaster: arrTmp });
  };

  const getListExportDisplay = async ({ nameRole = '', name = '' }) => {
    setLoading(true);
    try {
      const data = await MASTER_SETTING.GET_LIST_EXPORT_DISPLAY({
        nameRole,
        name,
        productId,
      });
      if (data) {
        setListMockup(data?.data);
      }
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!_.isEmpty(fieldExport)) {
      getListExportDisplay({ nameRole: nameRoleExport, name: fieldExport });
    }
  }, [fieldExport, nameRoleExport]);

  const handleChangeCheck = (e, index) => {
    let { value } = e.target;
    let isTrueSet = value == 'true';
    const tmp = listMockup?.fieldMaster?.map((item, eleI) => {
      if (eleI === index) {
        return { ...item, isUsed: !isTrueSet };
      }
      return item;
    });
    setListMockup({ ...listMockup, fieldMaster: tmp });
  };

  const handleChangeCheckAll = (e) => {
    const target = e.target;
    if (target.checked) {
      const tmp = listMockup?.fieldMaster?.map((item) => {
        return { ...item, isUsed: true };
      });
      setListMockup({ ...listMockup, fieldMaster: tmp });
    } else {
      const tmp = listMockup?.fieldMaster?.map((item) => {
        return { ...item, isUsed: false };
      });
      setListMockup({ ...listMockup, fieldMaster: tmp });
    }
  };

  const onSubmitChange = () => {
    const arrTmp = listMockup?.fieldMaster?.map((item, index) => {
      if (!['Worker', 'stellaCloud'].includes(item.distinguish)) {
        return {...item, sortNum: index + 1};
      } else {
        const stella = EXPORT_SETTING_DEFAULT.find(n => n.name === item.name);
        if (stella) {
          return {...item, sortNum: index + 1, nameInStella: stella?.nameInStella};
        } else if (item.isUsed) {
          return {...item, sortNum: index + 1};
        } else {
          return '';
        }
      }
    });

    updateExportListScreen({
      body: {
        productId,
        fieldMaster: _.compact(arrTmp),
        screenName: SCREEN_NAME.CSV_OUTPUT_SETTINGS,
        nameRole: nameRoleExport,
        name: fieldExport,
        updateTitle: `保存 設定 CSV出力設定 ${nameRoleExport} ${nameTitle || ''}`
      },
    });
  };

  const updateExportListScreen = async ({ body }) => {
    setLoading(true);
    try {
      const data = await MASTER_SETTING.CREATE_OR_UPDATE_EXPORT_SETTING({ body });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
          setLoading(false);
        } else {
          pushToast('error', data?.message?.errorMessage);
          setLoading(false);
        }
      } else {
        pushToast('success', 'Update success');
        getListExportDisplay({ nameRole: nameRoleExport, name: fieldExport })
        dispatch(getDataHistoryMasterSetting({ logId: '', screenName: SCREEN_NAME.CSV_OUTPUT_SETTINGS }));
        toggle();
        setLoading(false);
      }
    } catch (errorMessage) {
      setLoading(false);
      console.log('errorMessage: ', errorMessage);
    }
  };

  return (
    <Modal className="form__export" isOpen={modal} toggle={toggle}>
      <Loading loading={loading} />
      <ModalHeader toggle={toggle}></ModalHeader>
      <ModalBody>
        <table className="form__export--table">
          <thead>
            <th>
              <input type="checkbox" onChange={handleChangeCheckAll} checked={!listMockup?.fieldMaster?.find(ele => !ele?.isUsed)} />
              ALL
            </th>
            <th>基本項目</th>
            <th>区分</th>
            <th>出力項目</th>
            <th>サンプル</th>
          </thead>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <tbody {...provided.droppableProps} ref={provided.innerRef}>
                  {listMockup &&
                    listMockup?.fieldMaster?.map((item, index) => {
                      if(item.outputProject === '精算情報') return;

                      return (
                        <Draggable key={`${index + 1}`} draggableId={`${index + 1}`} index={index}>
                          {(provided) => (
                            <tr
                              key={index}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <td className="text-center form__export--checkbox">
                                <input
                                  type="checkbox"
                                  value={item.isUsed}
                                  checked={item.isUsed}
                                  onChange={(e) => handleChangeCheck(e, index)}
                                />
                              </td>
                              <td className="form__export--show text-left">{item?.distinguish}</td>
                              <td className="form__export--input ">{item?.outputProject}</td>
                              <td className="form__export--input ">{item?.name}</td>
                              <td className="form__export--input ">{item?.defaultValue}</td>
                            </tr>
                          )}
                        </Draggable>
                      );
                    })}
                </tbody>
              )}
            </Droppable>
          </DragDropContext>
        </table>
        <button className="btn-update-export" onClick={onSubmitChange}>
          保存
        </button>
      </ModalBody>
    </Modal>
  );
};

export default ModalExport;
