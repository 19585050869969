import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import Header from '../../../components/Header';
import 'assets/scss/page/ChatPage/style.scss';
import UserDefault from '../../../assets/img/user-default.png';
import { useSelector, useDispatch } from 'react-redux';
import { getListRoom, updateListRoom, getListChatUserByUser, updateListMessageInRoom, updateListMessageInUserByUser } from 'actions/chat';
import { socket } from '../../../helpers/callApi';
import ModalCreateRoomChat from '../../../components/ModalChatRoom/ModalCreateRoom';
import { CHAT } from 'constants/endpoints';
import Loading from 'components/Loading/Loading';
import { pushToast } from 'components/Toast';
import FormChatRoom from './FormChatRoom';
import FormChatUser from './FormChatUser';
import FooterMaintenance from "../../../components/FooterMaintenance/index.jsx"
import _ from 'lodash'

const ChatPage = () => {
	const dispatch = useDispatch();
  const [openModalAddRoom, setOpenModalAddRoom] = useState(false);
  const { listRoom, listChatUserByUser } = useSelector(state => state.chat);
  const userId = useSelector(state => state.authentication?.userMe?.data?.userId);

	const { loading } = useSelector((store) => store.utils);
	const [loadingPage, setLoading] = useState(false);
  const [roomActive, setRoomActive] = useState({});
  const [userActive, setUserActive] = useState({});

  const [listChatUser, setListChatUser] = useState([]);
  const [listChatUserOrigin, setListChatUserOrigin] = useState([]);

  const [listRoomChat, setListRoomChat] = useState([]);
  const [listRoomChatOrigin, setListRoomChatOrigin] = useState([]);

  const [dataSearchUser, setDataSearchUser] = useState('');
  const [dataSearchRoom, setDataSearchRoom] = useState('');
  const [idRole, setIdRole] = useState(null);
  const [messageSend, setMessageSend] = useState({});
  const [newReadRoom, setNewReadRoom] = useState({});
  const [newReadUser, setNewReadUser] = useState({});
  const [messageUser, setMessageUser] = useState({});
  const [textMessage, setTextMessage] = useState('');
  const [total, setTotal] = useState({});
  let messagesEndRef = useRef(null);
  const scrollToBottom = () => messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown, false);

    return () => {
      document.removeEventListener('keydown', handleKeyDown, false);
    };
  }, [dataSearchUser, dataSearchRoom]);

  const handleKeyDown = e => {
    const searchRoom = document.getElementById('search-room');
    const searchUser = document.getElementById('search-user');
    if(searchRoom === document.activeElement){
      if (e.key === 'Enter') {
        e.preventDefault();
        onSearchListRoom();
      }
    } else if(searchUser === document.activeElement){
      if (e.key === 'Enter') {
        e.preventDefault();
        onSearchListUser();
      }
    }
  };

  const onSearchListUser = () => {
    const format = /[ *()+\-=\[\]{}\\|\/?]/;
    const newData = [...listChatUserOrigin];
    if(format.test(dataSearchUser)) {
      setListChatUser(newData);
    } else {
      const data = _.filter(newData, function(elm) {
        return `${elm.firstName}${elm.lastName}`.search(new RegExp(`${dataSearchUser}`, "i")) !== -1
      });
      setListChatUser(data);
    }
  }

  const onSearchListRoom = () => {
    const format = /[ *()+\-=\[\]{}\\|\/?]/;
    const newData = [...listRoomChatOrigin];
    if(format.test(dataSearchRoom)) {
      setListRoomChat(newData);
    } else {
      const data = _.filter(newData, function(elm) {
        return elm.name.search(new RegExp(`${dataSearchRoom}`, "i")) !== -1
      });
      setListRoomChat(data);
    }
  }

  useEffect(() => {
    setListRoomChat(listRoom);
    setListRoomChatOrigin(listRoom);
    if (listRoom ?.length > 0) {
      socket.emit('join-room', { roomIds: listRoom ?.map(item => item.roomId)}, err => {
				pushToast('error', err?.errorMessage);
			});
    };

    if(_.isEmpty(listRoom) && _.isEmpty(listRoomChat)) return;

    if(listRoomChat?.length > listRoom?.length) {
      setTotal({roomId: listRoom?.[0]?.roomId, count: -(_.sumBy(_.differenceWith(listRoomChat, listRoom, (n, o) => {return n.roomId === o.roomId} ), 'countUnreadMessages') || 0)})
    }
  }, [listRoom]);

  useEffect(() => {
    if (listChatUserByUser?.length > 0) {
      setListChatUser(listChatUserByUser)
      setListChatUserOrigin(listChatUserByUser)
    }
  }, [listChatUserByUser]);

  useEffect(() => {
    dispatch(getListRoom());
    dispatch(getListChatUserByUser());
    const newRole = localStorage.getItem('role');
    setIdRole(newRole);
  }, []);

  const reloadListRoom = () => {
    setRoomActive({})
    dispatch(getListRoom());
  };

  const resetReadRoom = (data) => {
    const newData = [...listRoomChat];
    const count = newData.find(e => e.roomId === data?.roomId)?.countUnreadMessages;
    const newListRoom = listRoomChat.map(item => {
      if (item.roomId === data.roomId) {
        delete item.countUnreadMessages
        return item
      } else { return item}
    })
    setListRoomChat(newListRoom);
    setTotal({roomId: data?.roomId, count: -(count || 0)});
    setListRoomChatOrigin(newListRoom);
  };

  const resetReadUser = (data) => {
    const newData = [...listChatUser];
    const count = newData.find(e => e.userId === data?.userId)?.countUnreadMessages;
    const newListChatUser = listChatUser.map(item => {
      if (item.userId === data.userId) {
        delete item.countUnreadMessages
        return item
      } else { return item}
    })
    setListChatUser(newListChatUser);
    setTotal({roomId: data?.userId, count: -(count || 0)});
    setListChatUserOrigin(newListChatUser);
  }

  useEffect(() => {
    socket.on('message-room', data => {
      if (data) {
        setMessageSend(data);
      };
    });

    socket.on(`last-read-room-success`, data => {
      if (data) {
        setNewReadRoom(data);
      }
    });
  }, []);

  useEffect(() => {
    // listen people kicked room
    socket.on(`join-or-leave${userId}`, data => {
      if (data) {
        const payload = {roomId: data?.roomId, statusRoom: data?.statusRoom};
        socket.emit(`leave-room-finish`, payload, data => {
          reloadListRoom();
        })
      }
    });

    socket.on(userId, data => {
      setMessageUser(data || {});
    });

    socket.on(`last-read${userId}`, data => setNewReadUser(data) );
  }, [userId])

  useEffect(() => {
    if (messageSend.roomId === roomActive.roomId) {
      dispatch(updateListMessageInRoom(messageSend));
      setTotal({roomId: messageSend.roomId, count: messageSend.roomId ? +1 : +0});
      scrollToBottom();
    } else {
      setTotal({roomId: messageSend.roomId, count: messageSend.roomId ? +1 : +0});
    }

    const newListRoomChat = listRoomChat.map((item, index) => item.roomId === messageSend.roomId ?
      { ...item, countUnreadMessages: (item ?.countUnreadMessages || 0) + 1, index: 0 }
      : {...item, index: index + 1});

    const newListRoomChatOrigin = listRoomChatOrigin.map((item, index) => item.roomId === messageSend.roomId ?
      { ...item, countUnreadMessages: (item ?.countUnreadMessages || 0) + 1, index: 0 }
      : {...item, index: index + 1});
    setListRoomChat( _.sortBy(newListRoomChat, 'index') )
    setListRoomChatOrigin( _.sortBy(newListRoomChatOrigin, 'index') )
  }, [messageSend]);

  useEffect(() => {
    if (messageUser.fromUserId === userId) {
      setTextMessage('');
      setTotal({roomId: messageUser.fromUserId, count: messageUser.fromUserId ? +1 : +0});
      scrollToBottom();
    }else {
      setTotal({roomId: messageUser.fromUserId, count: messageUser.fromUserId ? +1 : +0});
    }
    if (messageUser.fromUserId === userId || messageUser.fromUserId === userActive.userId) {
      dispatch(updateListMessageInUserByUser(messageUser));
      setTotal({roomId: messageUser.fromUserId, count: messageUser.fromUserId ? +1 : +0});
      scrollToBottom();
    }else {
      setTotal({roomId: messageUser.fromUserId, count: messageUser.fromUserId ? +1 : +0});
    }

    const newListChatUser = listChatUser.map((item, index) => item.userId === messageUser.fromUserId ?
      { ...item, countUnreadMessages: (item ?.countUnreadMessages || 0) + 1, index: 0 }
      : { ...item, index: index + 1 });
    const newListChatUserOrigin = listChatUserOrigin.map((item, index) => item.userId === messageUser.fromUserId ?
      { ...item, countUnreadMessages: (item ?.countUnreadMessages || 0) + 1, index: 0}
      : { ...item, index: index + 1 });
    setListChatUser( _.sortBy(newListChatUser, 'index') )
    setListChatUserOrigin( _.sortBy(newListChatUserOrigin, 'index') )
  }, [messageUser]);

  useEffect(() => {
    if (!_.isEmpty(newReadRoom)) {
      resetReadRoom(newReadRoom);
    }
  }, [newReadRoom]);

  useEffect(() => {
    if (!_.isEmpty(newReadUser)) {
      resetReadUser(newReadUser);
    }
  }, [newReadUser]);

  const handleCreateRoomChat = async body => {
    setLoading(true);
    try {
      const data = await CHAT.CREATE_ROOM_CHAT({ body });
      if (data && data.code !== 0) {
        pushToast('error', data?.message?.extra || data?.message?.errorMessage);
        setLoading(false);
      } else {
        pushToast('success', 'Create Room chat success');
        dispatch(updateListRoom(data?.data));
        toggleModalAddRoom();
        setLoading(false);
      }
    } catch (errorMessage) {
      setLoading(false);
      console.log('errorMessage: ', errorMessage);
    }
  };

  const toggleModalAddRoom = () => {
    setOpenModalAddRoom(!openModalAddRoom);
  };

  const handleOpenModalAddRoom = () => {
    setOpenModalAddRoom(true);
  };

  return (
    <div className="main">
      <Loading loading={loading || loadingPage} />

      <Helmet>
        <title>チャット</title>
      </Helmet>
      <Header idRole={idRole} isChat={true} total={total} />
      <div className="row chat__form--row chat__form--border">
        <div className="col-4  chat__form--scAuto chat__form--bdRight chat__form--bgGray">
          <div>
            <div className="d-flex mt-3 align-items-center">
              <span>グループ</span>
              <div className="mx-2 chat__form--bgBlue">
                <i className="fa fa-plus p-2" onClick={() => handleOpenModalAddRoom()}></i>
              </div>
              <input
                id='search-room'
                type="text" className="mx-2" onChange={(e) => setDataSearchRoom(e.target.value)}
              />
              <div className="chat__form--bgBlue"
                onClick={onSearchListRoom}
              >
                <i className="fa fa-search p-2"></i>
              </div>
            </div>
            {listRoomChat ?.map((item, index) => {
              return (
                <div key={index}
                  className={`form-room-chat ${item.roomId === roomActive.roomId ? 'active-room' : ''}`}
                  onClick={() => {
                    setRoomActive(item)
                    setUserActive({})
                  }}
                >
                  {item.name}
                  {
                    item ?.countUnreadMessages > 0 &&
                      <div className="ml-2">
                        <p className='chat__form--totalCount'>
                        {item ?.countUnreadMessages}
                        </p>
                      </div>
                  }

                </div>
              );
            })}
          </div>

          <div>
            <div className="d-flex mt-3 align-items-center">
              <span>個別</span>
              <input
                id='search-user' type="text"
                className="mx-2" onChange={(e) => setDataSearchUser(e.target.value)}
              />
              <div className="chat__form--bgBlue"
                onClick={onSearchListUser}
              >
                <i className="fa fa-search p-2"></i>
              </div>
            </div>
            {
              listChatUser ?.map((item, index) => {
                const nameUser = `${item?.companyName} ${item?.firstName} ${item?.lastName}`;
                return (
                  <div key={index}
                    className={`form-room-chat ${item.userId === userActive.userId ? 'active-room' : ''}`}
                    onClick={() => {
                      setRoomActive({})
                      setUserActive(item)
                    }}
                  >
                    <div className="form-name-user">
                      <div className="avatar-user">
                        <img src={UserDefault} alt="tuyentc" className="avatar-image" />
                      </div>
                      {nameUser}
                    </div>

                    {
                    item ?.countUnreadMessages > 0 &&
                      <div className="ml-2">
                        <p className='chat__form--totalCount'>
                        {item ?.countUnreadMessages}
                        </p>
                      </div>
                    }
                  </div>
                )
              })
            }

          </div>

        </div>
        {
          !_.isEmpty(roomActive) &&
          <FormChatRoom
            roomActive={roomActive}
            reloadListRoom={reloadListRoom}
            messagesEndRef={messagesEndRef}
            scrollToBottom={scrollToBottom}
          />
        }

        {
          !_.isEmpty(userActive) &&
          <FormChatUser
            userActive={userActive}
            resetReadUser={resetReadUser}
            textMessage={textMessage}
            setTextMessage={setTextMessage}
          />
        }

      </div>

      {openModalAddRoom && (
        <ModalCreateRoomChat
          modal={openModalAddRoom} toggle={toggleModalAddRoom} handleCreateRoomChat={handleCreateRoomChat} />
      )}

      <FooterMaintenance/>
    </div>
  );
};

export default ChatPage;
