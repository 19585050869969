import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import 'assets/scss/components/ModalConfirmUpdateStatusUserAndStaff/style.scss';

const STATUS_STAFF = {
  ACITVE: 0,
  PENDING: 1,
  DELETED: 2
}

const ModalConfirmUpdateStatusUserAndStaff  = ({
  status,
  isOpen,
  handleUpdateStatus = () => {},
  toggle = () => {},
  ...props
  })  => {
  const [isShowPopoverConfirm, setIsShowPopoverConfirm] = useState(false);
  const [statusSelected, setStatusSelected] = useState(status);

  const onUpdateSuccess = () => {
    // setStatusSelected(status)
    setIsShowPopoverConfirm(false)
  }

  useEffect(() => {
    setStatusSelected(status)
  },[status]);

  const renderModalConfirm = () => {
    return (
      <Modal
        isOpen={isShowPopoverConfirm}
        toggle={() => setIsShowPopoverConfirm(!isShowPopoverConfirm)}
        className={`modal-confirm-update-status-user-staff ${props.className}`}
      >
        <ModalHeader toggle={() => setIsShowPopoverConfirm(!isShowPopoverConfirm)}>確認</ModalHeader>
        <ModalBody>
          {statusSelected === STATUS_STAFF.ACITVE &&
            <div>
              <p>アカウントの利用再開処理を行ってもよろしいですか？</p>
            </div>
          }
          {statusSelected === STATUS_STAFF.PENDING &&
            <div>
              <p>アカウント停止処理を行ってもよろしいですか？</p>
            </div>
          }
          {statusSelected === STATUS_STAFF.DELETED &&
            <div>
              <p>アカウント削除処理を行ってもよろしいですか？</p>
              <p>（利用者IDが削除され元に戻すことはできません）</p>
            </div>
          }
          <div>
            <button className="btn" onClick={() => handleUpdateStatus({ onUpdateSuccess, status: statusSelected })} >はい</button>
            <button className="btn" onClick={() => setIsShowPopoverConfirm(false)}>いいえ</button>
          </div>
        </ModalBody>
      </Modal>
    )
  }

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => toggle()}
      className="modal-update-status-user-staff"
    >
      <ModalHeader toggle={() => toggle()}>作業員利用状況</ModalHeader>
      <ModalBody>
       <div className="group-status">
        <label
          className="label-field"
        >
          ユーザー利用状況
        </label>
        <div className="group-status-input">
          <span className={`status-item ${status === STATUS_STAFF.ACITVE && 'disabled'}`}>
            <input checked={statusSelected === STATUS_STAFF.ACITVE} type="radio" name="status" id="status-active" onChange={() => setStatusSelected(STATUS_STAFF.ACITVE)}/>
            <label htmlFor="status-active">利用中</label>
          </span>
          <span className={`status-item ${status === STATUS_STAFF.PENDING && 'disabled'}`}>
            <input checked={statusSelected === STATUS_STAFF.PENDING} type="radio" name="status" id="status-pending" onChange={() => setStatusSelected(STATUS_STAFF.PENDING)} />
            <label htmlFor="status-pending">利用停止</label>
          </span>
          <span className={`status-item ${status === STATUS_STAFF.ACITVE && 'disabled'}`}>
            <input checked={statusSelected === STATUS_STAFF.DELETED} type="radio" name="status" id="status-deleted" onChange={() => setStatusSelected(STATUS_STAFF.DELETED)} />
            <label htmlFor="status-deleted">アカウント削除</label>
          </span>
        </div>
       </div>
       <div className="content-confirm-body">
          <button
            className="btn"
            onClick={() => setIsShowPopoverConfirm(!isShowPopoverConfirm) }
            disabled={statusSelected === null}
          >
            保存
          </button>
          {renderModalConfirm()}
       </div>
      </ModalBody>
    </Modal>
  )
}

export default ModalConfirmUpdateStatusUserAndStaff
