import React, { useEffect } from 'react';
import Header from 'components/Header';
import { HEADER_MENU } from 'helpers/constants';
import { compose } from 'redux';
import { translate } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import TableControl from './TableControl';
import 'assets/scss/page/Describe/style.scss';

const Describe = (props) => {
  let name = (process.browser && new URL(window.location.href).searchParams.get('p')) || 'receive_all';
  const [p, setP] = React.useState(name || HEADER_MENU.RECEIVE_ALL);

  useEffect(() => {
    setP(name);
  }, [name]);

  return (
    <div className="main_describe">
      <Header p={p} />
      <div className="body-wrapper">
        <TableControl />
      </div>
    </div>
  );
};

export default compose(translate('translations'), withRouter)(Describe);
