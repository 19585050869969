import React from 'react';
import 'assets/scss/page/AnkenMaster/table.scss';
import { getNoOrder } from 'helpers/helpers';
import { Link } from 'react-router-dom';

const data = {
  header: ['案件マスタID', '案件名', 'クライアントコード', 'クライアント名', '作成日', '更新日', '終了日', '操作'],
};

const renderItem = (item, index, fontSize, activePage) => {
  return (
    <tr key={index}>
      <td className="no-wrapper">
        <div className={`d-flex justify-content-center align-items-center font-weight-bolder no-style ${fontSize}`}>
          {getNoOrder({ index: index, limit: 10, activePage: activePage })}
        </div>
      </td>
      <td>{item?.productId}</td>
      <td>{item?.customerName}</td>
      <td>{item?.customerCode}</td>
      <td>{item?.name}</td>
      <td>{item?.startDate}</td>
      <td>{item?.updatedDate}</td>
      <td>{item?.endDate}</td>
      <td>
        {item?.isDeleted ? (
          <></>
        ) : (
          <div className="group-button d-flex flex-column">
            <button className={`btn button-user p-1 mb-1 ${fontSize}`}>
              <Link to="/anken_announce" style={{ color: 'black', textDecoration: 'none' }}>
                <i className="fa fa-wrench mr-3" />周 知
              </Link>
            </button>

            <button className={`btn button-user p-1 mb-1 ${fontSize}`}>
              <Link
                to={`/edit_anken?modify=1&productId=${item.productId}`}
                style={{ color: 'black', textDecoration: 'none' }}
              >
                <i className="fa fa-wrench mr-3" />更 新
              </Link>
            </button>
            <button className={`btn button-user-end p-1 mb-1 ${fontSize}`}>
              <Link
                to={`/edit_anken?modify=3&productId=${item.productId}&type=end`}
                style={{ color: 'black', textDecoration: 'none' }}
              >
                <i className="fa fa-wrench mr-3" />終 了
              </Link>
            </button>
          </div>
        )}
      </td>
    </tr>
  );
};

const TableListAnken = ({ allProduct = [], fontSize = 'small', activePage = 1 }) => {
  return (
    <div className="table-list-anken-wrapper table-responsive">
      <table className={`table table-striped mb-0 ${fontSize}`}>
        <thead>
          <tr>
            <th scope="col">No</th>
            {data.header.map((item, index) => (
              <th scope="col" key={index}>
                {item}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>{allProduct.map((listData, index) => renderItem(listData, index, fontSize, activePage))}</tbody>
      </table>
    </div>
  );
};

export default TableListAnken;
