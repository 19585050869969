import React, { useEffect, useState } from 'react';
import Header from 'components/Header';
import Footer from 'components/Footer';
import { ORDER } from 'constants/endpoints';
import { SCREEN_NAME, HEADER_MENU, ROLE } from 'helpers/constants';
import { pushToast } from 'components/Toast';
import { getRole } from 'helpers/helpers';
import { useSelector } from 'react-redux';
import Loading from 'components/Loading/Loading';
import 'assets/scss/page/DetaiPreparationInfo/style.scss';
import DetailData from 'components/DetailCenter/DetailData';
import DetailDataList from 'components/DetailCenter/DetailDataList';
import DetailOrder from 'components/DetailCenter/DetailOrder';
import DetailListAllData from 'components/DetailCenter/DetailListAllData';
import DetailFormUploadOrder from 'components/DetailFormUploadOrder';
import { Link, useHistory } from 'react-router-dom';
import DetailTreeDocumentSetting from 'components/DetailTreeDocumentSetting';
import DetailReportErp from '../../../components/DetailOrderErp/DetailReportErp';

import DetailTimeWorker from 'components/DetailOrderErp/DetailTimeWorker';
import DetailSent from 'components/DetailOrderErp/DetailSent';
import _ from 'lodash';

const DetailPreparationInfoPartner = () => {
  const [role, setRole] = React.useState(null);
  const colorScreen = useSelector((store) => store.utils.colorMe);
  const [idRole, setIdRole] = React.useState(null);
  const [data, setData] = useState([]);
  let orderId = process.browser && new URL(window.location.href).searchParams.get('orderId');
  let organizationId = process.browser && new URL(window.location.href).searchParams.get('organizationId');
  let partnerId = process.browser && new URL(window.location.href).searchParams.get('partnerId');
  let name = (process.browser && new URL(window.location.href).searchParams.get('p')) || 'preparation_info';
  const [p, setP] = React.useState(name || HEADER_MENU.RECEIVE_ALL);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [dataDetailTimeWorker, setDataDetailTimeWorker] = useState({});

  const [listDetailScreen, setListDetailScreen] = useState([]);
  const [documentSetting, setDocumentSetting] = useState([]);
  useEffect(() => {
    getListRoleChild();
  }, []);

  const getListRoleChild = async () => {
    const newRole = localStorage.getItem('role');
    try {
      const data = await ORDER.GET_LIST_DETAIL_SETTING({
        arrRole: newRole,
        nameScreen: 'preparation_info',
        orderId,
        organizationId
      });
      if (data) {
        setListDetailScreen(data?.data?.detailScreen.concat(
          { name: 'button', sortNum: 5, status: true }
        ));
        const newData = _.groupBy(data?.data?.documentSettingPreparationInfo, 'typeChildId')
        setDocumentSetting(newData);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    setP(name);
  }, [name]);

  const getOrderDetail = async ({ orderId = '' }) => {
    try {
      setLoading(true);
      const data = await ORDER.GET_DETAIL_CANCEL_INFO({ orderId, screenName: 'preparation_info', organizationId });
      if (data) {
        setData(data?.data);
        setDataDetailTimeWorker(data?.data);
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const getDataOrderForDetailTimeWorker = async () => {
    try {
      const data = await ORDER.GET_DETAIL_CANCEL_INFO({ orderId });
      if (data) {
        setDataDetailTimeWorker(data?.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    const newRole = localStorage.getItem('role');
    setIdRole(newRole);
    if (newRole) {
      setRole(getRole({ idRole: newRole }));
    }

    if (orderId) {
      getOrderDetail({
        orderId: orderId,
      });
    }
  }, [orderId]);

  const onChangeText = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    const newData = { ...data, [name]: value };
    setData(newData);
  };

  const updateData = () => {
    const newData = { ...data, screenName: SCREEN_NAME.PREPARATION_INFO_PARTNER,  companyName: localStorage.getItem('companyName'), updateTitle: '保存'  }
    updateOrderInfo({ body: _.pick(newData, ['managementMattersToBeSent', 'screenName' , 'companyName' , 'updateTitle']), id_order: orderId });
  }

  const updateOrderInfo = async ({ body = {}, id_order = '' }) => {
    try {
      setLoading(true);
      const data = await ORDER.UPDATE_PREPARATION_INFO({ body, id_order, organizationId });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
        setLoading(false);
      } else {
        pushToast('success', 'Update order success');
        setLoading(false);
        return history.push('/list?p=preparation_info');
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  return (
    <div className="wrapper-detail-preparation-info">
      <Loading loading={loading} />
      <div className="main">
        <Header p={p} idRole={idRole} color={colorScreen?.code} colorText={colorScreen?.colorText} />
        <div className="body-wrapper">
          <div className="logout_btn">
            <Link to={`list?p=${p}`}>
              <span className="mypage_ab_custom">戻る</span>
            </Link>
          </div>
          {listDetailScreen &&
            _.sortBy(listDetailScreen, 'sortNum').map((item, index) => {
              if (item.status) {
                switch (item.name) {
                  case 'clientCompany':
                    return <DetailOrder data={data} p={p} />;
                  case 'applicationCompany':
                    return <DetailData data={data} p={p} link={false} />;
                  case 'mattersSent':
                    return (
                      <>
                        <DetailSent data={data} onChangeText={onChangeText} isDisabledManagement={role === ROLE.CENTER} isDisabledErp={role === ROLE.PARTNER} />
                      </>
                    );
                  case 'visitDateWorker':
                    return (
                      <DetailTimeWorker data={dataDetailTimeWorker} getOrderDetail={getDataOrderForDetailTimeWorker} screenName={SCREEN_NAME.PREPARATION_INFO_PARTNER} />
                    );
                  case 'fileUpload':
                    return (
                      Object.keys(documentSetting).map((item, i) => {
                        const nameForm = documentSetting?.[item]?.[0]?.nameTypeChild;
                        return (
                          <DetailReportErp
                            p={p}
                            data={data} organizationId={organizationId}
                            orderId={orderId} partnerId={partnerId}
                            key={i} documentSetting={documentSetting?.[item]}
                            nameForm={nameForm}
                            type={'reportInformation'} />
                        )
                      })
                    );
                  case 'button':
                    return (
                      <div className="content__button--submit" key={index} >
                        <button onClick={() => updateData()} className="btn content__button--custom content__button--w100 content__button--w10">保存</button>
                      </div>
                    )
                  case 'detailInfo':
                    return <DetailListAllData data={data} screenName={SCREEN_NAME.PREPARATION_INFO_PARTNER}/>;
                  case 'document':
                    return (
                      <>
                        <DetailTreeDocumentSetting orderId={orderId} type={'reportInformation'} order={data} />
                      </>
                    );
                  case 'history':
                    return (
                      <DetailDataList datePick={false} screenName={SCREEN_NAME.PREPARATION_INFO_PARTNER} orderId={orderId} />
                    );

                  default:
                    return '';
                }
              }
            })}

          {/* <MemoTable orderId={orderId} screenName={SCREEN_NAME.REPORT_UPLOAD_INFO} /> */}
        </div>
        <Footer color={colorScreen?.code} colorText={colorScreen?.colorText} />
      </div>
    </div>
  );
};

export default DetailPreparationInfoPartner;
