import React, { useEffect, useState } from 'react';
import { TimeDispatch } from 'helpers/constants';
import { ORDER, DISPATCH } from 'constants/endpoints';
import { pushToast } from 'components/Toast';
import ModalConfirm from 'components/ModalConfirm';
import { combineTime, getTimeWorking } from '../../../helpers/helpers';
import _ from 'lodash';
import { SCREEN_NAME } from '../../../helpers/constants';
import { useDispatch } from 'react-redux';
import { getDataHistoryMasterSetting } from 'actions/authentication';

const WIDTH_OF_A_HOUR = 100; //100px

const TableDispatch = ({
  dataWorkingSchedule,
  organizationId,
  visitDate,
  onReload,
  pushDescription,
  serviceDrag,
  colors,
  taskActive}) => {
  const dispatch = useDispatch();
  const [modalConfirm, setModalConfirm] = useState(false);
  const [bodyRemove, setBodyRemove] = useState({});
  const valueWidthFormTask = WIDTH_OF_A_HOUR / 4;
  const [draggedTask, setDraggedTask] = useState({});
  const [dataWorking, setDataWorking] = useState(dataWorkingSchedule);

  const toggle = (body) => {
    setModalConfirm(!modalConfirm);
    setBodyRemove(body)
  };

  useEffect(() => {
    setDataWorking(dataWorkingSchedule)
  }, [dataWorkingSchedule])


  const renderContent = (itemTable, index, productId) => {
    const calendar = itemTable?.calendar;
    return (
      <div className="form-item-select-time" key={index} name={`table-dispatch`}>
        {
          TimeDispatch.map((item) => {
            const itemFormat = ('0' + item).slice(-2);
            return ['00', '15', '30', '45'].map((item, index) => {
              let checkTimeWorking = false;
              if (calendar) {
                const startTime = calendar?.startTime || '06:00';
                const endTime = calendar?.endTime || '34:45';
                if (startTime <= `${itemFormat}:${item}` && endTime > `${itemFormat}:${item}`) {
                  checkTimeWorking = true;
                }
              }
              return (
                <div
                  onDrop={() => onDrop({ productId, staffId: itemTable.staffId, visitTime: `${itemFormat}:${item}`})}
                  onDragOver={event => onDragOver(event)}
                  className={`item-select-time ${checkTimeWorking ? '' : 'item-select-time-disable'} ${index === 3 ? 'end': ''}`}
                  id={`${itemFormat}:${item}`}
                  key={index}
                  style={{ width: `${WIDTH_OF_A_HOUR / 4}px`}}
                />
              )
            })
          })
        }
      </div>
    );
  };

  const onRemoveTask = async ({ body = {} }) => {
    try {
      const data = await DISPATCH.REMOVE_TASK({body});
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
      } else {
        pushToast('success', 'Delete task success');
        setModalConfirm(false);
        onReload();
        dispatch(getDataHistoryMasterSetting({ logId: '', screenName: SCREEN_NAME.DISPATCH_SETTINGS_ORDER, idProvide: '' }));
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleConfirm = () => {
    onRemoveTask({body: bodyRemove})
  };

  const handleRemoveTask = ({ orderId, staffId }) => {
    const body = {
      orderId,
      staffId,
      organizationId,
      visitDate,
      screenName: SCREEN_NAME.DISPATCH_SETTINGS_ORDER,
      updateTitle: dataWorking?.caseName,
    };
    toggle(body);
  };

  const getDetailTaskJob = ({orderId, taskId, workTime, staffId}) => {
    getDetalOrder({orderId, taskId, workTime, staffId})
  };

  const getDetalOrder = async ({ orderId, taskId, workTime, staffId }) => {
    try {
      const data = await ORDER.GET_ORDER_DETAIL_APPOINT_INFO({ orderId });
      if (data) {
        pushDescription({data : data && data, taskId, workTime, staffId});
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onAddLead = async ({ body = {} }) => {
    try {
      const data = await DISPATCH.ADD_LEAD({body});
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
      } else {
        pushToast('success', 'Add Lead success');
        dispatch(getDataHistoryMasterSetting({ logId: '', screenName: SCREEN_NAME.DISPATCH_SETTINGS_ORDER, idProvide: '' }));
        onReload();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleChangeLead = ({ orderId, staffId }) => {
    const body = {
      orderId,
      staffId,
      visitDate,
      screenName: SCREEN_NAME.DISPATCH_SETTINGS_ORDER,
      updateTitle: dataWorking?.caseName
    };
    onAddLead({ body });
  };

  const updateDispatchTask = async ({ body = {}, idDispatchLog = '', staffId, visitTime }) => {
    try {
      const data = await DISPATCH.UPDATE_DISPATCH_TASK({body, idDispatchLog});
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
        setDraggedTask({});
      } else {
        onReload();
        updateData({ staffId, visitTime });
        setDraggedTask({});
        dispatch(getDataHistoryMasterSetting({ logId: '', screenName: SCREEN_NAME.DISPATCH_SETTINGS_ORDER, idProvide: '' }));
      }
    } catch (e) {
      setDraggedTask({});
      console.log(e);
    }
  };

  const onAddTask = async ({ body = '', staffId, visitTime }) => {
    try {
      const data = await DISPATCH.ADD_TASK({body});
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
      } else {
        onReload();
        updateData({ staffId, visitTime });
        dispatch(getDataHistoryMasterSetting({ logId: '', screenName: SCREEN_NAME.DISPATCH_SETTINGS_ORDER, idProvide: '' }));
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onDrag = (event, todo) => {
    event.preventDefault();
    setDraggedTask(todo);
  };

  const onDragOver = (event) => {
    event.preventDefault();
  };

  const updateData = ({ staffId, visitTime }) => {
    const newData = [...dataWorking.staffs];
    const newStaff = newData ?.find(item => item.staffId === staffId);
    if (!_.isEmpty(draggedTask)) {
      const currentStaff = newData.find(item => item.staffId === draggedTask.staffId);
      draggedTask.staffId = staffId;
      draggedTask.visitTime = `${visitTime}:00`;
      const currentSchedule = currentStaff.workingSchedule.filter(item => item.id !== draggedTask.id);
      currentStaff.workingSchedule = currentSchedule
      newStaff.workingSchedule.push(draggedTask);
      setDataWorking({ ...dataWorking, staffs: [...newData] });
      pushToast('success', 'Update Task success');
    } else {
      const serviceDragObj = {
        ...serviceDrag, staffId, visitTime: `${visitTime}:00`,
        visitTimeOld: serviceDrag.visitTime,
        workTime: getTimeWorking({ end: serviceDrag.endTime, start: serviceDrag.visitTime }),
        endTime: combineTime({ end: serviceDrag.endTime, visitTime: `${visitTime}:00`, start: serviceDrag.visitTime })
      };
      newStaff.workingSchedule.push(serviceDragObj);
      setDataWorking({ ...dataWorking, staffs: [...newData] });
      pushToast('success', 'Update Task success');
    }
  };

  const onDrop = ({ staffId, visitTime, productId }) => {
    if (!_.isEmpty(draggedTask)) {
      const body = {
        newStaffId: staffId,
        visitDate, startTime: visitTime,
        oldStaffId: draggedTask.staffId,
        screenName: SCREEN_NAME.DISPATCH_SETTINGS_ORDER,
        updateTitle: dataWorking?.caseName
      };
      updateDispatchTask({ body, idDispatchLog: draggedTask.id, staffId, visitTime });
    } else {
      const body = {
        visitDateFrom: visitTime,
        orderId: serviceDrag.orderId, staffId, organizationId,
        productId,
        isDrag: true,
        screenName: SCREEN_NAME.DISPATCH_SETTINGS_ORDER,
        updateTitle: dataWorking?.caseName
      }
      onAddTask({ body, staffId, visitTime });
    }
  };

  const renderTaskJob = (itemTable, item, index) => {
    const hourStart = +item?.visitTime?.split(':')[0];
    const minuteStart = +item?.visitTime?.split(':')[1];

    const hourWork = +item?.workTime?.split(':')[0];
    const minuteWork = +item ?.workTime ?.split(':')[1];

    const movingTime = +item ?.movingTime;

    let valueWidth = 0;
    valueWidth = ((((hourWork) * 4) + ((minuteWork + movingTime * 2) / 15)) * valueWidthFormTask);

    const valueLeft = ((((hourStart - 6) * 4) + ((minuteStart) / 15)) * valueWidthFormTask);

    const valueWidthMovingTime = (movingTime * valueWidthFormTask) / 15;

    const valueWidthWorkTime = (((hourWork * 4) + (minuteWork / 15)) * valueWidthFormTask);
    const textTime = () => {
      const order = dataWorking?.body?.find(i => i?.orderId === item?.orderId);
      return order ? `${order ?.visitDateFrom ?.substring(0, 5)} ~ ${order ?.visitDateTo ?.slice(-5)}` : null;
    }
    return (
      <div
        className={taskActive?.taskId === item.id ? 'active form-task-job' : 'form-task-job'}
        id={item.id}
        key={index}
        draggable
        onDrag={(event) => onDrag(event, item)}
        style={{ width: valueWidth, left: valueLeft }}
        onClick={() => getDetailTaskJob(
          {
            orderId: item ?.orderId,
            taskId: item ?.id,
            workTime: item ?.workTime,
            staffId: item?.staffId
          })}
      >
        <div className="content-task-job" style={{ backgroundColor: (item?.labelSetting || colors) }}>
          <div className="form-checkbox">
            <input
              type="checkbox"
              id={item.id}
              checked={item.isLead}
              disabled={item.isLead}
              onChange={() => {
                handleChangeLead({
                  orderId: item?.orderId,
                  staffId: item?.staffId
                })
              }}
            />
          </div>
          <div
            className="form-come"
            style={{ width: valueWidthMovingTime }}>
              <i className="fas fa-car"></i>
          </div>

          <div className="form-work"
            style={{ width: valueWidthWorkTime }}
          >
            <p title={textTime()}>{textTime()}</p>
            <p title={item?.managementCode || ''}>{item?.managementCode || ''}</p>
          </div>

          <div
            className="form-go"
            style={{ width: valueWidthMovingTime }}
          ><i className="fas fa-car"></i>
          </div>

          <div className="form-close"
            onClick={() => handleRemoveTask({
              orderId: item?.orderId,
              staffId: item?.staffId
            })}
          ><i className="fas fa-times"></i></div>
        </div>
      </div>
    )

  };


  const renderHeader = () => {
    return TimeDispatch.map((item, index) => {
      return (
        <div className="item-time-header" key={index} style={{ width: `${WIDTH_OF_A_HOUR}px`}}>
          <span className="circle-time">{item}</span>
        </div>
      )
    })
  };

  return (
    <div className="wrapper-table-dispatch">
      <div className="form-table">
        <div className="form-name-item">
          <div className="padding-header"></div>
          <div className="form-label-item">
            {
              _.sortBy(dataWorking ?.staffs, 'area') ?.map((itemTable, index) =>
                <div className='name-item-table row' key={index} >
                  <div title={`${itemTable?.area || ''} ${itemTable.branchAbbreviation || itemTable.abbreviationName || ''}`} className="col-12 truncate">{`${itemTable?.area || ''} ${itemTable.branchAbbreviation || itemTable.abbreviationName || ''}`}</div>
                  <div title={`${itemTable.surName} ${itemTable.name}`} className="col-12 truncate">{`${itemTable.surName} ${itemTable.name}`}</div>
                </div>)
            }
          </div>
        </div>

        <div className="form-content-table">
          <div className="header-table">
            {renderHeader()}
          </div>
          {
          dataWorking?.staffs?.length > 0 ?
          <div className="content-table">
            {
              _.sortBy(dataWorking?.staffs, 'area')?.map((itemTable, index) => {
                return (
                  <div className="row-form" key={index}>
                    {
                      itemTable?.workingSchedule?.map((item, index) => renderTaskJob(itemTable, item, index))
                    }
                    {renderContent(itemTable, index, dataWorking?.productId)}
                  </div>
                )
              })
            }
          </div> : <div className="form-no-data">No Data</div>
        }
        </div>
      </div>
      <ModalConfirm toggle={toggle} modal={modalConfirm} handleConfirm={handleConfirm}/>
    </div>
  );
};

export default TableDispatch;
