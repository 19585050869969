import React, { useEffect, useState } from 'react';
import Button from 'reactstrap/es/Button';
import TextField from '@material-ui/core/TextField';
import { compose } from 'redux';
import { translate } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Loading from '../../../components/Loading/Loading';
import Toast from 'components/Toast';
import { pushToast } from '../../../components/Toast';
import { USER_ENDPOINT } from 'constants/endpoints';
import { SECURITY_OPTION } from 'helpers/constants';
import _ from 'lodash';
import { MASTER_SETTING } from '../../../constants/endpoints';

const VerifyUser = (props) => {
  const { history } = props;
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [info, setInfo] = useState({ email: '' });
  const [security, setSecurity] = useState({});
  const code = process.browser && new URL(window.location.href).searchParams.get('code');
  const email = _.compact(
    _.compact(window.location.search.split(/&|\?/))?.map((e) => {
      if (e?.split(/=/)?.[0] === 'email') return e?.split(/=/)?.[1];
    })
  )?.[0];
  const loginId = (process.browser && new URL(window.location.href).searchParams.get('loginId')) || '';
  const [htmlString, setHtmlString] = useState('');

  const handleSubmit = () => {
    const password = document.getElementById('password')?.value;
    const confirmPassword = document.getElementById('confirmPassword')?.value;
    handleLogin(password, confirmPassword);
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPressForgot, false);
    window.myfunc = handleSubmit;
    return () => {
      document.removeEventListener('keydown', handleKeyPressForgot, false);
      window.myfunc = null;
      document.getElementById('username').value = loginId || email;
      document.getElementById('username').disabled = true;
    };
  }, [htmlString, info]);

  const handleLogin = (password, confirmPassword) => {
    const emailModify = email ? handleEmail(email) : '';
    if (password && confirmPassword) {
      setPasswordConfirm({
        body: { codeVerify: info?.codeVerify, password, passwordConfirm: confirmPassword, userId: info?.userId },
      });
    }
  };

  const handleKeyPressForgot = (e) => {
    if (e.key === 'Enter') {
      const password = document.getElementById('password')?.value;
      const confirmPassword = document.getElementById('confirmPassword')?.value;
      handleLogin(password, confirmPassword);
      e.preventDefault();
    }
  };

  const getUiLogin = async () => {
    setLoading(true);
    try {
      const data = await MASTER_SETTING.GET_UI_LOGIN();
      if (data) {
        setHtmlString(data?.data?.loginHtml);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  useEffect(() => {
    getUiLogin();
  }, []);

  useEffect(() => {
    if ((info?.email?.trim() || info?.loginId?.trim()) && info?.password?.trim() && info?.passwordConfirm?.trim()) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [info]);

  const handleEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase()) ? email : `${email}@dummyemail.com`;
  };

  const handlePassword = () => {
    if ((info?.email || info?.loginId) && info?.password && info?.passwordConfirm) {
      setPasswordConfirm({ body: _.pick(info, ['codeVerify', 'password', 'passwordConfirm', 'userId']) });
    }
  };

  useEffect(() => {
    verifyUser({ loginId, code, email });
  }, []);

  const verifyUser = async ({ loginId = '', code = '', email = '' }) => {
    try {
      setLoading(true);
      const data = await USER_ENDPOINT.USER_VERIFY({ body: { loginId, confirmationCode: code, email } });
      if (data?.data) {
        pushToast('success', 'Verify User Success');
        setLoading(false);
        if (loginId) {
          setInfo({ ...data?.data, loginId });
        } else {
          setInfo({ ...data?.data, email });
        }
        getSecurityUser({ email, loginId });
      } else {
        document.cookie = `STELLA.ACCESS_TOKEN=`;
        document.cookie = `STELLA.REFRESH_TOKEN=`;
        localStorage.removeItem('IS_LOGIN');
        history.push(`/login`);
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
      document.cookie = `STELLA.ACCESS_TOKEN=`;
      document.cookie = `STELLA.REFRESH_TOKEN=`;
      localStorage.removeItem('IS_LOGIN');
      history.push(`/login`);
    }
  };

  const getSecurityUser = async ({ email = '', loginId = '' }) => {
    try {
      setLoading(true);
      const data = await USER_ENDPOINT.GET_SECURITY_USER({ body: { email, loginId } });
      if (data?.data) {
        setLoading(false);
        setSecurity({ ...data?.data, email });
      } else {
        setLoading(false);
        setSecurity({ passwordSettingValue: 2, email });
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const onChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const newData = { ...info, [name]: value };
    setInfo(newData);
  };

  const setPasswordConfirm = async ({ body }) => {
    try {
      setLoading(true);
      const data = await USER_ENDPOINT.USER_SET_PASSWORD({ body });
      if (data?.data) {
        pushToast('success', 'Set Password Success');
        setLoading(false);
        document.cookie = `STELLA.ACCESS_TOKEN=`;
        document.cookie = `STELLA.REFRESH_TOKEN=`;
        localStorage.removeItem('IS_LOGIN');
        history.push(`/login`);
      } else {
        pushToast('error', data?.message?.errorMessage || data?.message);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      pushToast('error', 'Set Password Error');
      console.log(e);
    }
  };

  return (
    <div>
      <Loading loading={loading} />
      <Toast />
      {!_.isEmpty(htmlString) ? (
        <div dangerouslySetInnerHTML={{ __html: htmlString }} />
      ) : (
        <div id="login_body">
          <div id="login_background_img">
            <div className="inputarea">
              <div className="pcmenu col-12">
                <p className="login_table__title col-12">パスワードを設定してください</p>
                <table className="login_table col-12">
                  <tbody>
                    <tr>
                      <td className="login_td_1">ユーザーID:</td>
                    </tr>
                    <tr>
                      <td className="login_td_2">
                        <input
                          id="username"
                          type="email"
                          placeholder="ユーザーID"
                          className="login_text login_table--black login_table--email"
                          disabled
                          value={loginId || email?.split('@dummyemail.com')?.[0]}
                        />
                        <input type="hidden" name="stella_serial" value="salesdemo" />
                      </td>
                    </tr>
                    <tr>
                      <td className="login_td_1">設定用パスワード:</td>
                    </tr>
                    <tr>
                      <td className="login_td_2">
                        <TextField
                          id="password"
                          type="password"
                          name="password"
                          placeholder="設定用パスワード"
                          className="login_text"
                          onChange={onChange}
                          onKeyPress={handleKeyPressForgot}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="login_td_1">設定用パスワード（確認）:</td>
                    </tr>
                    <tr>
                      <td className="login_td_2">
                        <TextField
                          id="confirmPassword"
                          type="password"
                          name="passwordConfirm"
                          placeholder="設定用パスワード（確認）"
                          className="login_text"
                          onChange={onChange}
                          onKeyPress={handleKeyPressForgot}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="login_td_3">
                        <p className="login_table__security">
                          {SECURITY_OPTION?.[security?.passwordSettingValue || 2]}
                        </p>
                        <button
                          className="btn btn-secondary btn-large login_table__button"
                          disabled={isButtonDisabled}
                          onClick={handlePassword}
                        >
                          設定
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="spmenu">
                <p className="login_table__title login_table__title--black col-12">パスワードを設定してください</p>
                <table className="login_table">
                  <tbody>
                    <tr>
                      <td className="login_td_2">
                        <input
                          id="userName"
                          type="email"
                          placeholder="ユーザーID"
                          className="login_text login_table--email"
                          disabled
                          value={loginId || email?.split('@dummyemail.com')?.[0]}
                        />
                        <input type="hidden" name="stella_serial" value="salesdemo" />
                      </td>
                      <td rowSpan="3">
                        <Button
                          className="loginBtn_btn"
                          variant="contained"
                          size="large"
                          color="secondary"
                          onClick={handlePassword}
                          disabled={isButtonDisabled}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="login_td_2">
                        <TextField
                          id="password"
                          type="password"
                          name="password"
                          placeholder="設定用パスワード"
                          className="login_text"
                          onChange={onChange}
                          onKeyPress={handleKeyPressForgot}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="login_td_2">
                        <TextField
                          id="confirmPassword"
                          type="password"
                          name="passwordConfirm"
                          placeholder="設定用パスワード（確認）"
                          className="login_text"
                          onChange={onChange}
                          onKeyPress={handleKeyPressForgot}
                        />
                        <p className="login_table__security login_table__title--black">
                          {SECURITY_OPTION?.[security?.passwordSettingValue || 2]}
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td className="login_td_4" colSpan="2"></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default compose(translate('translations'), withRouter, connect(null))(VerifyUser);
