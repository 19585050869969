import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import TableSettingOrder from './TableSettingOrder';

const ModalSettingOrder = ({ idProduct ,modalNext = false, toggle, routeList, nameScreen, arrRole, idProvide, onBack = () => {}, onDetail = () => {}, nameTitle }) => {
  const Back = () => {
    toggle();
    onBack({ valueRole : routeList, productId: idProduct })
  };

  return (
    <div>
      <Modal className="modal-dialog-custom" isOpen={modalNext} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          <button onClick={Back} className="button__back">
          戻る
          </button>
        </ModalHeader>
        <ModalBody>
          <TableSettingOrder routeList={routeList} nameScreen={nameScreen} arrRole={arrRole} idProvide={idProvide} idProduct={idProduct} onDetail={onDetail} toggle={toggle} nameTitle={nameTitle} />
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ModalSettingOrder;
