import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import '../../../../../assets/scss/page/CsvMaster/index.scss';
import { SCREEN_NAME } from '../../../../../helpers/constants';

const ModalBillMaster = (props) => {
  const { toggle, modal = false, valueBilling, submitUpdate } = props;
  const [dataCreate, setDataCreate] = useState({ claim: '' });

  useEffect(() => {
    setDataCreate({ claim: valueBilling , screenName : SCREEN_NAME.CONTRACT_MANAGEMENT });
  }, [valueBilling]);

  const handleChange = (e) => {
    let value = e.target.value;
    setDataCreate({ ...dataCreate, claim: value });
  };

  const submitPayment = () => {
    submitUpdate(dataCreate);
  };

  return (
    <Modal className="form-modal-contract" isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle} cssModule={{ 'modal-title': 'w-100 text-center mb-0' }}>
        請求項目
      </ModalHeader>
      <ModalBody>
        <div className="block-content pb-0">
          <div className="item-content">
            <p className="title-money">50,000</p>
            <input type="number" className="input-money" onChange={handleChange} value={dataCreate?.claim} />
          </div>
        </div>
        <div className="text-center">
          <button className="btn-update float-none" onClick={() => submitPayment()}>
            更新
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalBillMaster;
