import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import { DATE_JP_FORMAT } from 'helpers/constants';
import { formatInputSelectDate } from 'helpers/helpers';

const DetailWorker = ({ data }) => {
  return (
    <div className="detail wrapper-work-list--mgt-100">
      <table cellSpacing="0" cellPadding="0">
        <colgroup>
          <col className="col__table" />
          <col className="col__value" />
          <col className="col__table" />
          <col className="col__value" />
        </colgroup>
        <tbody>
          <tr>
            <th>案件名</th>
            <td colSpan="3">{data?.caseName}</td>
          </tr>
          <tr>
            <th>パートナー名</th>
            <td colSpan="3">{data?.abbreviationName}</td>
          </tr>
          <tr>
            <th>稼働調整日</th>
            <td>{data?.timeAddPartner}</td>
            <th>工程</th>
            <td>{data?.serviceName}</td>
          </tr>
          <tr>
            <th width="129">
              申送り事項
              <br />
              パートナー伝達用
            </th>
            <td colSpan="3">{data?.managementMattersToBeSent}</td>
          </tr>
          <tr>
            <th>管理コード</th>
            <td colSpan="3">{data?.managementCode}</td>
          </tr>
          <tr>
            <th>訪問開始日時</th>
            <td>{`${!_.isEmpty(data?.visitDate) ? data?.visitDate : ''} ${
              !_.isEmpty(data?.visitDateFrom) ? data?.visitDateFrom : ''
            }`}</td>
            <th>訪問終了日時</th>
            <td>
              {data?.visitDateTo ? (moment(`${formatInputSelectDate(data?.visitDateTo)}`, `${DATE_JP_FORMAT}`).isValid()
                ? data?.visitDateTo
                : `${data?.visitDateTo && `${data?.visitDate} ${data?.visitDateTo}`}`) : ''}
            </td>
          </tr>
          <tr>
            <th>物件名</th>
            <td colSpan="3">{data?.installationSiteName}</td>
          </tr>
          <tr>
            <th>訪問先ご担当者</th>
            <td>{data?.contactPersonInCharge}</td>
            <th>訪問先ご担当者連絡先</th>
            <td>{data?.contactInformation}</td>
          </tr>
          <tr>
            <th rowSpan="2">訪問先住所</th>
            <td rowSpan="2" colSpan="3">
              {data?.visitedBaseAddress}
            </td>
          </tr>
          <tr></tr>
          <tr>
            <th>訪問先建物名</th>
            <td>{data?.visitedBuildingName}</td>
            <th>作業予定箇所</th>
            <td>{data?.scheduledInstallationLocation}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default DetailWorker;
