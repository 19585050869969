import { put, call, takeLatest } from 'redux-saga/effects';
import { ACTIONS } from '../actions/order';
import { showLoading, hideLoading } from '../actions/utils';
import { callApi } from '../helpers/callApi';

export function* getListOrder() {
  yield takeLatest(ACTIONS.GET_LIST_ORDER, function* ({ payload }) {
    const { router, page, limit } = payload;
    yield put(showLoading());
    try {
      let link_query = `list/${router}?page=${page}&limit=${limit}`;
      const listOrder = yield call(callApi, {}, { endpoint: link_query, method: 'GET' });
      yield put(hideLoading());
      yield put({ type: ACTIONS.SAVE_LIST_ORDER, listOrder });
    } catch (error) {
      yield put(hideLoading());
    }
  });
}
