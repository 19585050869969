import React, { useEffect, useState } from 'react';
import { getNoOrder } from 'helpers/helpers';
import { compose } from 'redux';
import { translate } from 'react-i18next';
import { Link } from 'react-router-dom';

const OrderList = ({ colors, activePage, headers = [], body = [], t }) => {
  const [header, setHeader] = useState(headers || data.header);

  const data = {
    header: [
      '利用者ID',
      '利用者名',
      'フリガナ',
      '連絡先',
      'email',
      '作成日',
      '更新日',
      '利用状況',
      '利用停止日',
      '操作',
    ],
  };

  const renderItem = (item, index) => {
    return (
      <tr key={index}>
        <td className="no-wrapper">
          <div>{getNoOrder({ index: index, limit: 10, activePage: activePage })}</div>
        </td>
        {
          header.map((i, id) => (
            <td key={id}>{item?.[i]}</td>
          ))
        }
        <td className='button--center'>
          <Link to={`/recreate/detail_recreate/${item?.orderId}`}>
            <div className="button__edit">編集</div>
          </Link>
        </td>
      </tr>
    );
  };

  return (
    <div className="table-list-wrapper table-responsive">
      <table className={`table table-striped`}>
        <thead>
          <tr style={{ backgroundColor: `${colors}` }}>
            <th scope="col">No</th>
            {header.map((item, index) => (
              <th scope="col" key={index}>
                {t(`table_order_list.${item}`)}
                <br />
                <div className="wrapper-sort">
                  <a>▲</a>
                  <a>▼</a>
                </div>
              </th>
            ))}
            <th scope="col">操作</th>
          </tr>
        </thead>
        <tbody>{body.map((listData, index) => renderItem(listData, index))}</tbody>
      </table>
    </div>
  );
};

export default compose(translate('translations'))(OrderList);
