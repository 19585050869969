import React, { useEffect, useState } from 'react';
import { Collapse } from 'reactstrap';
import { SCREEN_NAME } from '../../helpers/constants';
import Loading from 'components/Loading/Loading';
import { CONTRACT_ENDPOINTS } from 'constants/endpoints';
import { pushToast } from 'components/Toast';
import { useHistory } from 'react-router-dom';

const ParticipatingCompany = ({ recruitmentContractId, isPageContract = false, partnerId = '', organizationId = '' }) => {
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(true);
  const [dataUpdateInfoContract, setDataUpdateInfoContract] = useState({})
  const [recruitmentId, setRecruitmentId] = useState('')
  const [loading, setLoading] = useState(false);

  const onClickShow = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (recruitmentContractId) {
      getInfoContract({ recruitmentContractId, partnerId })
    }
  }, [recruitmentContractId, partnerId]);

  const getInfoContract = async ({ recruitmentContractId, partnerId }) => {
    setLoading(true);
    try {
      const data = await CONTRACT_ENDPOINTS.GET_INFO_CONTRACT({
        recruitmentId: recruitmentContractId,
        partnerId,
      });
      if (data?.data) {
        setDataUpdateInfoContract(data?.data);
        setRecruitmentId(data?.data?.recruitmentId);
      }
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const onChangeTextAndNumber = (e) => {
    const { value, name } = e.target;
    setDataUpdateInfoContract({ ...dataUpdateInfoContract, [name]: value });
  };

  const onHandleUpdate = (updateTitle) => {
    onUpdate({
      dataUpdate: {
        ...dataUpdateInfoContract,
        updateTitle,
        screenName: SCREEN_NAME.CONTRACT_MANAGEMENT,
      },
    });
  };

  const onUpdate = async ({ dataUpdate = '' }) => {
    setLoading(true);
    delete dataUpdate.recruitmentId;
    try {
      const data = await CONTRACT_ENDPOINTS.UPDATE_INFO_CONTRACT_PARTNER({ body: dataUpdate, recruitmentId, organizationId });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
      } else {
        pushToast('success', 'Update success.');
      }
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };


  const onHandleReject = () => {
    onRejectContract();
  };

  const onRejectContract = async () => {
    setLoading(true);
    try {
      const data = await CONTRACT_ENDPOINTS.UPDATE_REJECT_CONTRACT({ recruitmentId });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
      } else {
        pushToast('success', 'Reject success.');
        history.push(`/contract`);
      }
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="detail-registration-contract">
      <Loading loading={loading} />
      <div className="detail-basic-table-wrapper">
        <div className="title-absolute" onClick={onClickShow}>
          参加会社入力
          {isOpen ? (
            <i className="fas fa-arrow-circle-up ml-2 cursor-pointer"></i>
          ) : (
            <i className="fas fa-arrow-circle-down ml-2 cursor-pointer"></i>
          )}
        </div>
        <Collapse isOpen={isOpen}>
          <div className="row">
            <div className="col-3 text-center">
              <label className="label-contract">企業情報</label>
            </div>
            <div className="col-9">
              <input
                type="text"
                disabled={isPageContract}
                className={`input-contract`}
                name="corporateInformation"
                onChange={onChangeTextAndNumber}
                value={dataUpdateInfoContract?.corporateInformation}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-3 text-center">
              <label className="label-contract">担当部署</label>
            </div>
            <div className="col-9">
              <input
                type="text"
                className={`input-contract`}
                name="departmentInCharge"
                onChange={onChangeTextAndNumber}
                value={dataUpdateInfoContract?.departmentInCharge}
                disabled={isPageContract}
              />
            </div>
          </div><div className="row">
            <div className="col-3 text-center">
              <label className="label-contract">担当者
              </label>
            </div>
            <div className="col-9">
              <input
                type="text"
                className={`input-contract`}
                name="manager"
                onChange={onChangeTextAndNumber}
                value={dataUpdateInfoContract?.manager}
                disabled={isPageContract}
              />
            </div>
          </div><div className="row">
            <div className="col-3 text-center">
              <label className="label-contract">連絡先
              </label>
            </div>
            <div className="col-9">
              <input
                type="text"
                className={`input-contract`}
                name="contractAddress"
                onChange={onChangeTextAndNumber}
                value={dataUpdateInfoContract?.contractAddress}
                disabled={isPageContract}
              />
            </div>
          </div><div className="row">
            <div className="col-3 text-center">
              <label className="label-contract">メールアドレス
              </label>
            </div>
            <div className="col-9">
              <input
                type="text"
                className={`input-contract`}
                name="email"
                onChange={onChangeTextAndNumber}
                value={dataUpdateInfoContract?.email}
                disabled={isPageContract}
              />
            </div>
          </div><div className="row">
            <div className="col-3 text-center">
              <label className="label-contract">コメント
              </label>
            </div>
            <div className="col-9">
              <input
                type="text"
                className={`input-contract`}
                name="comment"
                onChange={onChangeTextAndNumber}
                value={dataUpdateInfoContract?.comment}
                disabled={isPageContract}
              />
            </div>
          </div>
          {!isPageContract && <div className="block-right text-center mb-3">
            <button
              className={`button-update`}
              onClick={() => onHandleUpdate(
                `参加`
              )}>
              {'参加'}
            </button>
            <button
              className={`button-update`}
              onClick={() => onHandleReject()}>
              {'見送り'}
            </button>
          </div>}
        </Collapse>
      </div>
    </div>
  )
}

export default ParticipatingCompany;