import React from 'react';
import _ from 'lodash';
import { HEADER_MENU, SCREEN_NAME } from 'helpers/constants';
import { COLOR_ENDPOINT, ORDER, STAFF_ENDPOINT, ORDER_PARTNER, ORDER_CLIENT } from 'constants/endpoints';
import { useEffect } from 'react';
import { getRole } from 'helpers/helpers';
import Header from 'components/Header';
import Footer from 'components/Footer';
import 'assets/scss/page/DetailCancelMenuClient/style.scss';
import { useState } from 'react';
import { pushToast } from 'components/Toast';
import DetailData from 'components/DetailCenter/DetailData';
import DetailDataList from '../../../components/DetailCenter/DetailDataList';
import DetailOrder from 'components/DetailCenter/DetailOrder';
import DetailListAllData from 'components/DetailCenter/DetailListAllData';
import { useHistory, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import DetailTreeDocumentSetting from 'components/DetailTreeDocumentSetting';
import Loading from 'components/Loading/Loading';

const DetailCancelMenuClient = () => {
  let name = (process.browser && new URL(window.location.href).searchParams.get('p')) || 'cancel_menu';
  let orderId = process.browser && new URL(window.location.href).searchParams.get('orderId');
  let organizationId = process.browser && new URL(window.location.href).searchParams.get('organizationId') || '';
  const [dateTime, setDateTime] = useState(new Date());
  const dataTimeNew =
    dateTime.toLocaleDateString('ja-JP') +
    ' ' +
    (dateTime.getHours() < 10 ? '0' : '') +
    dateTime.getHours() +
    ':' +
    (dateTime.getMinutes() < 10 ? '0' : '') +
    dateTime.getMinutes() +
    ':' +
    dateTime.getSeconds();

  const mockData = {
    canceledAt: dataTimeNew,
    reasonForCancellation: '',
    screenName: SCREEN_NAME.CANCEL_MENU_CLIENT,
  };
  const [p, setP] = React.useState(name || HEADER_MENU.RECEIVE_ALL);
  const [role, setRole] = React.useState(null);
  const [idRole, setIdRole] = React.useState(null);
  const colorScreen = useSelector((store) => store.utils.colorMe);
  const [data, setData] = React.useState('');
  const [dataOrder, setDataOrder] = useState(mockData);
  const [listDetailScreen, setListDetailScreen] = useState([]);
  const history = useHistory();
  const [statusCancel, setStatusCancel] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getListRoleChild();
  }, []);

  const getListRoleChild = async () => {
    setLoading(true);
    const newRole = localStorage.getItem('role');
    try {
      const data = await ORDER.GET_LIST_DETAIL_SETTING({
        arrRole: newRole,
        nameScreen: 'cancel_menu',
        orderId,
        organizationId
      });
      if (data) {
        setListDetailScreen(data?.data?.detailScreen);
        // setListDetailScreen(data ?.data ?.detailScreen.concat(
        //   {name: "documentSetting", sortNum: 99, status: true}
        // ));
      }
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (data) {
      setDataOrder({
        ...dataOrder,
        reasonForCancellation: data.reasonForCancellation,
      });
    }
  }, [data]);

  const getStatusCancel = async ({ serviceId, productId, orderId, type = 'cancelSetting' }) => {
    try {
      setLoading(true);
      const data = await ORDER.GET_STATUS_ORDER({ serviceId, productId, orderId, type, organizationId });
      if (data) {
        setStatusCancel(data?.data);
      };
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  useEffect(() => {
    setP(name);
  }, [name]);

  const getOrderDetail = async ({ orderId = '' }) => {
    try {
      setLoading(true);
      const data = await ORDER.GET_DETAIL_CANCEL_INFO({ orderId, screenName: 'cancel_menu', organizationId });
      if (data) {
        setData(data?.data);
        getStatusCancel({
          serviceId: data?.data?.serviceId,
          productId: data?.data?.productId,
          orderId: data?.data?.orderId,
        });
      }
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  useEffect(() => {
    const newRole = localStorage.getItem('role');
    setIdRole(newRole);
    if (newRole) {
      setRole(getRole({ idRole: newRole }));
    }
    if (orderId) {
      getOrderDetail({ orderId });
    }
  }, [orderId]);

  const handleChangeText = (e) => {
    let name = e.target.name;
    let newData = { ...dataOrder };
    newData = { ...newData, [name]: e.target.value };
    setDataOrder(newData);
  };

  const updateOrderInfo = async ({ body = {}, id_order = '' }) => {
    try {
      setLoading(true);
      const data = await ORDER_CLIENT.UPDATE_CANCEL_ORDER({ body, id_order });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
      } else {
        pushToast('success', 'Update order success');
        history.push(`/list?p=cancel_menu`);
      }
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (data) {
      setDataOrder({
        ...dataOrder,
        resultCancel: data.resultCancel,
        reasonForCancellation: data.reasonForCancellation,
      });
    }
  }, [data]);

  const handleChangeSelect = (e) => {
    const value = e.target.value;
    let newData = { ...dataOrder };
    const settingCancel = statusCancel.filter((i) => i.id === value);
    newData = { ...newData, resultCancel: settingCancel?.[0]?.pullDownDisplay };
    setDataOrder(newData);
  };

  const onUpdateOrder = () => {
    const dataCompany = { companyName: localStorage.getItem('companyName'), updateTitle: '保存' }
    updateOrderInfo({ body: {...dataOrder , ...dataCompany}, id_order: orderId });
  };

  return (
    <div className="wrapper-detail-cancel-menu-client-list">
      <Loading loading={loading} />
      <div className="main">
        <Header idRole={idRole} color={colorScreen?.code} colorText={colorScreen?.colorText} p="cancel_menu" />
        <div className="body-wrapper">
          <div className="logout_btn">
            <Link to={`list?p=cancel_menu`}>
              <span className="mypage_ab_custom">戻る</span>
            </Link>
          </div>
          {listDetailScreen?.map((item) => {
            if (item.status) {
              switch (item.name) {
                case 'clientCompany':
                  return <DetailOrder data={data} p={p} />;
                case 'applicationCompany':
                  return <DetailData data={data} p={p} link={false} />;
                case 'cancelMenu':
                  return (
                    <div className="detail-cancel-list-wrapper">
                      <div className="title-absolute">キャンセル依頼</div>
                      <table className="conditTab">
                        <tbody>
                          <tr>
                            <th width="150">依頼担当者</th>
                            <td>
                              <input value={data?.requestingPerson} disabled type="text" />
                            </td>
                          </tr>
                          <tr>
                            <th>依頼日</th>
                            <td>
                              <input value={data?.requestedDate} disabled type="text" />
                            </td>
                          </tr>
                          <tr>
                            <th>訪問日</th>
                            <td>
                              <input value={data?.visitDate} disabled type="text" />
                            </td>
                          </tr>
                          <tr>
                            <th>管理コード</th>
                            <td>
                              <input value={data?.managementCode} disabled type="text" />
                            </td>
                          </tr>
                          <tr>
                            <th>物件名</th>
                            <td>
                              <input value={data?.installationSiteName} disabled type="text" />
                            </td>
                          </tr>
                          <tr>
                            <th>都道府県</th>
                            <td>
                              <input value={data?.prefecturesVisited} disabled type="text" />
                            </td>
                          </tr>
                          <tr>
                            <th>訪問先拠点住所</th>
                            <td>
                              <input value={data?.visitedBaseAddress} disabled type="text" />
                            </td>
                          </tr>
                          <tr>
                            <th>訪問先建物名</th>
                            <td>
                              <input value={data?.visitedBuildingName} disabled type="text" />
                            </td>
                          </tr>
                          <tr>
                            <th>キャンセル日時</th>
                            <td>
                              <div className="discribeTabletame">{dataTimeNew}</div>
                              <font color="red">※実際の処理時間は、保存を行ったタイミングで確定されます</font>
                            </td>
                          </tr>
                          <tr>
                            <th>キャンセル理由</th>
                            <td>
                              <select
                                name="settingCancel"
                                onChange={handleChangeSelect}
                                value={dataOrder?.settingCancel}
                                style={{ display: 'block' }}
                              >
                                <option value=""></option>
                                {statusCancel.map((item, index) => {
                                  return (
                                    <option value={item.id} key={index}>
                                      {item.pullDownDisplay}
                                    </option>
                                  );
                                })}
                              </select>
                              <textarea
                                value={dataOrder?.reasonForCancellation}
                                onChange={handleChangeText}
                                name="reasonForCancellation"
                                cols="70"
                                rows="5"
                              >
                                {dataOrder?.reasonForCancellation}
                              </textarea>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div className="button_area">
                        <input type="submit" className="btn_submit" value="保存" onClick={() => onUpdateOrder()} />
                      </div>
                    </div>
                  );
                case 'detailInfo':
                  return <DetailListAllData screenName={SCREEN_NAME.CANCEL_MENU_CLIENT} data={data} />;
                case 'document':
                  return <DetailTreeDocumentSetting orderId={orderId} order={data} />;
                case 'history':
                  return <DetailDataList datePick={false} screenName={SCREEN_NAME.CANCEL_MENU_CLIENT} orderId={orderId} />;
                default:
                  return '';
              }
            }
          })}
        </div>
        {/* <Footer color={ } /> */}
        <Footer color={colorScreen?.code} colorText={colorScreen?.colorText} />
      </div>
    </div>
  );
};

export default DetailCancelMenuClient;
