import React, { useState, useEffect } from 'react';
import './style.scss';
import { DEFAULT_PARAMS, DEFAULT_OPTIONS, LIST_COLOR, time, PEOPLE } from 'helpers/constants';
import { PRODUCT_SERVICE } from 'constants/endpoints';
import { pushToast } from '../../../../components/Toast';
import { genId } from 'helpers/helpers';
import _ from 'lodash';
import { translate } from 'react-i18next';
import { compose } from 'redux';
import Loading from "components/Loading/Loading";
import { SCREEN_DISPATCH_SETTING } from '../../../../helpers/constants';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { getDataHistoryMasterSetting } from 'actions/authentication';
import { useDispatch } from 'react-redux';

const TableDetailList = ({ item, color, idProduct ,colorText, header={}, t, nameTitle }) => {
  const dispatch = useDispatch();
  const [dataTable, setDataTable] = useState([]);
  const [headers, setHeaders] = useState(header);
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(false);
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return dataResult(result);
  };

  const dataResult = (result) => result.map((i, index) => { i.sortNum = index; return i; });

  const getListByProductId = async (id) => {
    try {
        setLoading(true);
        const data = await PRODUCT_SERVICE.GET_LIST_BY_STAFF_ID({ productId: id });
        if (data && data.code !== 0) {
          if (data && data?.message && data?.message?.extra) {
            pushToast('error', data?.message?.extra);
          } else {
            pushToast('error', data?.message?.errorMessage);
          }
          setLoading(false);
        } else {
          setServices(data?.data?.services || []);
          setDataTable(_.sortBy((data?.data?.serviceOptions || initData), 'sortNum').map(v => {return {...v, key: genId()}}));
          setLoading(false);
        }
    } catch (e) {
        console.log(e);
    }
  };

  const initData = () => {
    return [{
      key: genId(),
      serviceId: '',
      option1: '',
      option2: '',
      option3: '',
      option4: '',
      option5: '',
      workTime: '',
      people: '',
      labelSetting: '',
      sortNum: 0,
    }]
  }

  useEffect(() => {
    if (idProduct) {
      getListByProductId(idProduct);
      setHeaders(item.serviceOptions);
    }
  }, [idProduct]);

  useEffect(() => {
    if(!_.isEmpty(header)){
      setHeaders(header);
    }
  }, [header]);

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const items = reorder(dataTable, result.source.index, result.destination.index);
    setDataTable(items);
  };

  const changePeople = (e, key) => {
    const newList = dataTable.map(item => {
      if (item.key === key) {
        return { ...item, people: e.target.value }
      }
      return item;
    })
    setDataTable(newList)
  };

  const changeWorkTime = (e, key) => {
    const newList = dataTable.map(item => {
      if (item.key === key) {
        return { ...item, workTime: e.target.value }
      }
      return item;
    })
    setDataTable(newList)
  };

  const changeService = (e, key) => {
    const newList = dataTable.map(item => {
      if (item.key === key) {
        return { ...item, serviceId: e.target.value }
      }
      return item;
    })
    setDataTable(newList)
  };

  const changeOption = (e, key, option) => {
    const newList = dataTable.map(item => {
      if (item.key === key) {
        return { ...item, [option]: e.target.value }
      }
      return item;
    })
    setDataTable(newList)
  };

  const changeLabel = (e, key) => {
    const newList = dataTable.map(item => {
      if (item.key === key) {
        return { ...item, labelSetting: e.target.value }
      }
      return item;
    })
    setDataTable(newList)
  };

  const updateListProduct = async () => {
    const dataPick = dataTable.map((i) => {return _.pick(i, DEFAULT_PARAMS)});
    try {
      const data = await PRODUCT_SERVICE.CREATE_PRODUCT_SERVICE({ body: {options: dataPick , screenName : SCREEN_DISPATCH_SETTING.orderInformation.label, updateTitle : `保存 サービス設定 ${nameTitle || ''}`}, productId: idProduct });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data ?.message ?.extra);
        } else {
          pushToast('error', data ?.message ?.errorMessage);
        }
      } else {
        if (idProduct) {
          getListByProductId(idProduct);
        }
        pushToast('success', 'Update Product success');
        dispatch(getDataHistoryMasterSetting({ logId : '', screenName : SCREEN_DISPATCH_SETTING.orderInformation.label }));
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleUpdate = () => {
    updateListProduct();
  };

  const addItem = () => {
    const data = [...dataTable, {
      key: genId(), serviceId: '', option1: '', option2: '', option3: '', option4: '', option5: '', workTime: '',
      people: '', labelSetting: ''
    }];
    setDataTable(dataResult(data));
  };

  const renderItem = (item, index) => {
    return (
      <Draggable key={`${index + 1}`} draggableId={`${index + 1}`} index={index}>
        {(provided) => (
          <tr
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            key={index}
          >
            <td>{index + 1}</td>
            <td>
              <select className="form-input" value={item?.serviceId} onChange={(e) => changeService(e, item?.key)}>
                <option value="">---</option>
                {
                  services?.map((item, index) =>
                    <option value={item.serviceId} key={index}>{item.name}</option>
                  )
                }
              </select>
            </td>
            <td>
              <select className="form-input" value={item?.option1} onChange={(e) => changeOption(e, item?.key, "option1")}>
                <option value="">---</option>
                {
                  headers?.option1?.options?.map((item, index) =>
                    <option value={item} key={index}>{item}</option>
                  )
                }
              </select>
            </td>
            <td>
              <select className="form-input" value={item?.option2} onChange={(e) => changeOption(e, item?.key, "option2")}>
                <option value="">---</option>
                {
                  headers?.option2?.options?.map((item, index) =>
                    <option value={item} key={index}>{item}</option>
                  )
                }
              </select>
            </td>
            <td>
              <select className="form-input" value={item?.option3} onChange={(e) => changeOption(e, item?.key, "option3")}>
                <option value="">---</option>
                {
                  headers?.option3?.options?.map((item, index) =>
                    <option value={item} key={index}>{item}</option>
                  )
                }
              </select>
            </td>
            <td>
              <select className="form-input" value={item?.option4} onChange={(e) => changeOption(e, item?.key, "option4")}>
                <option value="">---</option>
                {
                  headers?.option4?.options?.map((item, index) =>
                    <option value={item} key={index}>{item}</option>
                  )
                }
              </select>
            </td>
            <td>
              <select className="form-input" value={item?.option5} onChange={(e) => changeOption(e, item?.key, "option5")}>
                <option value="">---</option>
                {
                  headers?.option5?.options?.map((item, index) =>
                    <option value={item} key={index}>{item}</option>
                  )
                }
              </select>
            </td>
            <td>
              <select className="form-input" value={item.workTime} onChange={(e) => changeWorkTime(e, item?.key)}>
                <option value="">---</option>
                {
                  time.map((item, index) =>
                    <option value={item} key={index}>{item}</option>
                  )
                }
              </select>
            </td>
            <td>
              <select className="form-input" value={item.people} onChange={(e) => changePeople(e, item?.key)}>
                <option value="">---</option>
                {
                  PEOPLE.map((item, index) =>
                    <option value={item} key={index}>{item}人</option>
                  )
                }
              </select>
            </td>
            <td>
              <select className="form-input" style={{backgroundColor: item?.labelSetting}} value={item?.labelSetting} onChange={(e) => changeLabel(e, item?.key)}>
                <option value="">---</option>
                {
                  LIST_COLOR.map((item, index) =>
                    <option value={item.code} key={index} style={{backgroundColor: item.code}}>設定済</option>
                  )
                }
              </select>
            </td>
          </tr>
        )}
      </Draggable>
    );
  };

  return (
    <div className="table-list-wrapper table-responsive">
      <Loading loading={loading} />
      <table className="table table-striped mb-1">
        <thead>
          <tr style={{ backgroundColor: `${color}` }}>
            <th style={{color : `${colorText}`}}>No</th>
            <th style={{color : `${colorText}`}}>サービス名</th>
            {
              DEFAULT_OPTIONS.map((i, index) => {
                return <th key={index} style={{color : `${colorText}`}}>{headers?.[i]?.name || t(`dispatch.${i}`)}</th>
              })
            }
            <th style={{color : `${colorText}`}}>所要時間</th>
            <th style={{color : `${colorText}`}}>必要なトレーナー</th>
            <th style={{color : `${colorText}`}}>ラベル設定</th>
          </tr>
        </thead>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <tbody {...provided.droppableProps} ref={provided.innerRef} className="scroll-table">
                {
                  dataTable && _.sortBy(dataTable, 'sortNum').map((listData, index) => renderItem(listData, index))
                }
                {provided.placeholder}
            </tbody>
            )}
          </Droppable>
        </DragDropContext>
      </table>
      <div className="form__flex form__flex--space-between">
        <button className="button__edit button__edit--plus button__edit--w10" style={{ background: `${color}` }} onClick={addItem}>+</button>
        <button className="button__edit button__edit--plus button__edit--w10" style={{ background: `${color}` }} onClick={handleUpdate}>保存</button>
        </div>
    </div>
  );
};

export default compose(translate('translations'))(TableDetailList);
