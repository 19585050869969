import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { time, DATE_JP_FORMAT } from 'helpers/constants';
import 'assets/scss/page/CalendarMaster/style.scss';
import moment from 'moment';
import { getDate } from 'helpers/helpers';
import DatePicker from 'react-datepicker';

const TableSchedules = ({ workingSchedules, isCreate, startDate, handleChangeCalendar, handleChange, onUpdate, isSchedule }) => {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if(isSchedule && startDate && workingSchedules.dateChange) {
      countChecked(workingSchedules?.calendar || []);
    }
  }, [workingSchedules.dateChange])

  const countChecked = (calendars) => {
    const newCalendar = (calendars || []).find(e => !e.status);
    _.isEmpty(newCalendar) ? setChecked(true) : setChecked(false);
  }

  const onChangeData = ({e, item}) => {
    const value = e.target.name === 'status' ? !item?.status : e.target.value;
    const newCalendars = [...workingSchedules?.calendar];
    const index = (workingSchedules?.calendar || []).findIndex(x => item.date === x.date);
    newCalendars[index] = {...item, [e.target.name]: value};
    e.target.name === 'status' && countChecked(newCalendars);
    handleChangeCalendar({data: {calendar: newCalendars}});
  };

  const changePartner = (e) => {
    handleChangeCalendar({data: {[e.target.name]: e.target.value}})
  };

  const applyCommon = () => {
    const newCalendars = (workingSchedules?.calendar || []).map((e, i) => {
      if(e?.status) {
        return {
          ...e, workingStartTime: workingSchedules?.workingStartTime, workingEndTime: workingSchedules?.workingEndTime,
          breakStartTime: workingSchedules?.breakStartTime, breakEndTime: workingSchedules?.breakEndTime
        }
      } return e;
    });
    handleChangeCalendar({data: {calendar: newCalendars}});
  };

  const onCheckedAll = () => {
    setChecked(!checked);
    const newCalendars = (workingSchedules?.calendar || []).map((e, i) => {
      return { ...e, status: !checked };
    });
    handleChangeCalendar({data: {calendar: newCalendars}});
  }

  return (
    <>
    <div className="form__flex form__flex--mgt-10 form--mgl10">
      <div className="form-search form__flex--start">
        <DatePicker
          selected={startDate}
          onChange={(date) => handleChange(date)}
          locale="ja"
          dateFormat="yyyy/MM"
          dateFormatCalendar="yyyy年 MM月"
          showMonthYearPicker
          className="form-control w20 label__schedule--border"
        />

        <select className="w-25 form-control mb-1 table__calendar--sl form--mgl10" name="partnerId" onChange={changePartner} value={workingSchedules?.partnerId || workingSchedules?.partners?.[0]?.partnerId}>
          {
            (workingSchedules?.partners || []).map((item) => (
              <option value={item?.partnerId}>{item?.abbreviationName}</option>
            ))
          }
        </select>

        <div className="label__schedule">{isCreate ? '作成済み': '未作成'}</div>
      </div>
    </div>
    <div className="form__flex form__flex--mgt-10">
      <div className="form-search form__flex--start">
        <div className="label__schedule">稼働時間</div>
        <select className="w-25 form-control mb-1 table__calendar--sl form--mgl10" name="workingStartTime" value={workingSchedules?.workingStartTime} onChange={changePartner}>
          {
            time.map((item) => (
              <option value={item}>{item}</option>
            ))
          }
        </select>
        <select className="w-25 form-control mb-1 table__calendar--sl form--mgl10" name="workingEndTime" value={workingSchedules?.workingEndTime} onChange={changePartner}>
          {
            time.map((item) => (
              <option value={item}>{item}</option>
            ))
          }
        </select>
      </div>
      <div className="form-search form__flex--start">
        <div className="label__schedule">昼休憩</div>
        <select className="w-25 form-control mb-1 table__calendar--sl form--mgl10" name="breakStartTime" value={workingSchedules?.breakStartTime} onChange={changePartner}>
          {
            time.map((item) => (
              <option value={item}>{item}</option>
            ))
          }
        </select>
        <select className="w-25 form-control mb-1 table__calendar--sl form--mgl10" name="breakEndTime" value={workingSchedules?.breakEndTime} onChange={changePartner}>
          {
            time.map((item) => (
              <option value={item}>{item}</option>
            ))
          }
        </select>
      </div>
      <button className="btn-tree" onClick={applyCommon}>一括設定</button>
      <button className="btn-tree" onClick={onUpdate}>保存</button>
    </div>
    <div className="form__flex form__flex--mgt-10 form--mgl10">
      <table className="table__calendar--tb table">
        <thead>
          <tr>
            <th className="form--w5">
              <input type="checkbox" name="all" checked={checked} onChange={onCheckedAll} />
            </th>
            <th className="form--w10">日付</th>
            <th className="form--w5">曜日</th>
            <th className="form--w30">稼働時間</th>
            <th className="form--w30">昼休憩</th>
            <th className="form--w20">メモ</th>
          </tr>
        </thead>
        <tbody>
          {workingSchedules?.calendar?.map((item, index) => {
              return (
                <tr key={index} style={{ color: ` ${[2, 3, '2', '3'].includes(item?.type) ? 'red' : 'black'}` }}>
                  <td className="form--w5">
                    <input type="checkbox" name="status" checked={item?.status} onChange={(e) => onChangeData({e, item})} />
                  </td>
                  <td className="form--w10">{moment(item?.date).format(DATE_JP_FORMAT)}</td>
                  <td className="form--w5">{getDate(item?.date)}</td>
                  <td className="form--w30">
                    <div className="row col-12 label__schedule--col-resize">
                      <select className="col-5 w-25 form-control mb-1 table__calendar--sl form--mgl10" name="workingStartTime" value={item?.workingStartTime || ''} onChange={(e) => onChangeData({e, item})}>
                        <option value={''}>--:--</option>
                        {
                          time.map((item) => (
                            <option value={item}>{item}</option>
                          ))
                        }
                      </select>
                      <select className="col-5 w-25 form-control mb-1 table__calendar--sl form--mgl10" value={item?.workingEndTime || ''} name="workingEndTime" onChange={(e) => onChangeData({e, item})}>
                        <option value={''}>--:--</option>
                        {
                          time.map((item) => (
                            <option value={item}>{item}</option>
                          ))
                        }
                      </select>
                    </div>
                  </td>
                  <td className="form--w30">
                    <div className="row col-12 label__schedule--col-resize">
                      <select className="col-5 w-25 form-control mb-1 table__calendar--sl form--mgl10" value={item?.breakStartTime || ''} name="breakStartTime" onChange={(e) => onChangeData({e, item})}>
                        <option value={''}>--:--</option>
                        {
                          time.map((item) => (
                            <option value={item}>{item}</option>
                          ))
                        }
                      </select>
                      <select className="col-5 w-25 form-control mb-1 table__calendar--sl form--mgl10" value={item?.breakEndTime || ''} name="breakEndTime" onChange={(e) => onChangeData({e, item})}>
                        <option value={''}>--:--</option>
                        {
                          time.map((item) => (
                            <option value={item}>{item}</option>
                          ))
                        }
                      </select>
                    </div>
                  </td>
                  <td className="form--w20">
                    {item.remarks}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  </>
  )
}

export default TableSchedules;
