import React, { Fragment } from 'react';
import { HEADER_MENU, SCREEN_NAME } from 'helpers/constants';
import { ORDER, ORDER_PARTNER } from 'constants/endpoints';
import { useEffect } from 'react';
import Header from 'components/Header';
import Footer from 'components/Footer';
import 'assets/scss/page/DetailHonkadoList/style.scss';
import { useState } from 'react';
import { pushToast } from 'components/Toast';
import DetailData from 'components/DetailCenter/DetailData';
import DetailDataList from 'components/DetailCenter/DetailDataList';
import DetailOrder from 'components/DetailCenter/DetailOrder';
import DetailListAllData from 'components/DetailCenter/DetailListAllData';
import { useHistory, Link } from 'react-router-dom';
import DetailTreeDocumentSetting from 'components/DetailTreeDocumentSetting';
import moment from 'moment';
import { useSelector } from 'react-redux';
import DetailResult from '../../../components/DetailOrderErp/DetailResult';
import DetailSent from '../../../components/DetailOrderErp/DetailSent';
import DetailTimeWorker from '../../../components/DetailOrderErp/DetailTimeWorker';
import Loading from 'components/Loading/Loading';
import _ from 'lodash';

const mockData = {
  screenName: SCREEN_NAME.HONKADO_LIST,
  startTime: '',
  endTime: '',
  resultCancel: '',
  resultsPreparation: '',
  scheduledWorkerInformation: '',
  staffId: '',
  statusSiteInfo: false,
};

const DetailHonkadoList = () => {
  let name = (process.browser && new URL(window.location.href).searchParams.get('p')) || 'site_info';
  let orderId = process.browser && new URL(window.location.href).searchParams.get('orderId');
  let organizationId = process.browser && new URL(window.location.href).searchParams.get('organizationId') || '';
  const [p, setP] = React.useState(name || HEADER_MENU.RECEIVE_ALL);
  const [idRole, setIdRole] = React.useState(null);
  const [data, setData] = React.useState('');
  const [listDetailScreen, setListDetailScreen] = useState([]);
  const colorScreen = useSelector((store) => store.utils.colorMe);
  const [statusResult, setStatusResult] = useState([]);
  const [dataDetailTimeWorker, setDataDetailTimeWorker] = useState({});
  const [endDay, setEndDay] = useState('');
  const [loading, setLoading] = useState(false);
  const [dataOrder, setDataOrder] = useState(mockData);
  const [addHour, setAddHour] = useState('');
  const [addMinutes, setAddMinutes] = useState('');
  const [endHour, setEndHour] = useState('');
  const [endMinutes, setEndMinutes] = useState('');
  const history = useHistory();

  useEffect(() => {
    getListRoleChild();
  }, []);

  const getListRoleChild = async () => {
    const newRole = localStorage.getItem('role');
    try {
      setLoading(true);
      const data = await ORDER.GET_LIST_DETAIL_SETTING({
        arrRole: newRole,
        nameScreen: 'site_info',
        orderId,
        organizationId
      });
      if (data) {
        setListDetailScreen(data?.data?.detailScreen?.concat({ name: 'button', sortNum: 6, status: true }));
      }
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (data) {
      setDataOrder({
        ...dataOrder,
        startTime: data?.startTime,
        endTime: data?.endTime,
        resultCancel: data?.resultCancel,
        resultsPreparation: data?.resultsPreparation,
        scheduledWorkerInformation: data?.scheduledWorkerInformation,
        staffId: data?.staffId,
        completionResult: data.completionResult,
        settingCompletionResult: data.settingCompletionResult,
      });
    }
  }, [data]);

  useEffect(() => {
    if (dataOrder) {
      setAddHour(dataOrder?.startTime ? moment(dataOrder?.startTime, 'YYYY/MM/DD HH:mm').format('HH') : '');
      setAddMinutes(dataOrder?.startTime ? moment(dataOrder?.startTime, 'YYYY/MM/DD HH:mm').format('mm') : '');
      setEndHour(dataOrder?.endTime ? moment(dataOrder?.endTime, 'YYYY/MM/DD HH:mm').format('HH') : '');
      setEndMinutes(dataOrder?.endTime ? moment(dataOrder?.endTime, 'YYYY/MM/DD HH:mm').format('mm') : '');
      setEndDay(dataOrder?.endTime ? moment(dataOrder?.endTime, 'YYYY/MM/DD HH:mm').format('YYYY/MM/DD') : '');
    }
  }, [dataOrder]);

  useEffect(() => {
    setP(name);
  }, [name]);

  const getOrderDetail = async ({ orderId = '' }) => {
    try {
      setLoading(true);
      const data = await ORDER.GET_DETAIL_CANCEL_INFO({ orderId, organizationId, screenName: 'site_info' });
      if (data) {
        setData(data?.data);
        setDataDetailTimeWorker(data?.data);
        getStatusResult({
          serviceId: data?.data?.serviceId,
          productId: data?.data?.productId,
          orderId: data?.data?.orderId,
        });
      }
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const getDataOrderForDetailTimeWorker = async () => {
    try {
      setLoading(true);
      const data = await ORDER.GET_DETAIL_CANCEL_INFO({ orderId, organizationId });
      if (data) {
        setDataDetailTimeWorker(data?.data);
      }
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  useEffect(() => {
    const newRole = localStorage.getItem('role');
    setIdRole(newRole);
    if (orderId) {
      getOrderDetail({
        orderId: orderId,
      });
    }
  }, [orderId]);

  const HandleHour1 = () => {
    let d = new Date();
    let hour = (d.getHours() < 10 ? '0' : '') + d.getHours();
    let minutes = (d.getMinutes() < 10 ? '0' : '') + d.getMinutes();
    setAddHour(hour);
    setAddMinutes(minutes);
    let newData = { ...dataOrder };
    let d5 = moment(dataOrder?.startTime ? new Date(dataOrder?.startTime) : new Date()).set({
      hour: hour,
      minute: minutes,
    });
    newData = { ...newData, startTime: d5.format('YYYY/MM/DD HH:mm:ss') };
    setDataOrder(newData);
  };

  const HandleHour2 = () => {
    let d = new Date();
    let hour = (d.getHours() < 10 ? '0' : '') + d.getHours();
    let minutes = (d.getMinutes() < 10 ? '0' : '') + d.getMinutes();
    setEndHour(hour);
    setEndMinutes(minutes);
    let d5 = moment(dataOrder?.endTime ? new Date(dataOrder?.endTime) : new Date()).set({
      hour: hour,
      minute: minutes,
    });
    let newData = { ...dataOrder };
    newData = { ...newData, endTime: d5.format('YYYY/MM/DD HH:mm:ss') };
    setDataOrder(newData);
  };

  const handleChangeText = (e) => {
    let name = e.target.name;
    let newData = { ...dataOrder };
    newData = { ...newData, [name]: e.target.value };
    setDataOrder(newData);
  };

  const handleChangeSelect = (e) => {
    const value = e.target.value;
    let newData = { ...dataOrder };
    const settingCompletion = statusResult.filter((i) => i.id === value);
    newData = { ...newData, completionResult: value, settingCompletionResult: settingCompletion?.[0] };
    setDataOrder(newData);
  };

  const updateOrderInfo = async ({ body = {}, order_id = '' }) => {
    try {
      setLoading(true);
      const data = await ORDER_PARTNER.UPDATE_HONKADO_INFO({
        body: body,
        order_id: order_id,
        organizationId: organizationId || '',
      });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
      } else {
        pushToast('success', 'Update order success');
        getOrderDetail({ orderId: orderId });
        history.push(`/list?p=site_info`);
      }
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const onUpdateOrder = () => {
    const dataCompany = { companyName: localStorage.getItem('companyName'), updateTitle: `保存 結果：${dataOrder?.settingCompletionResult?.pullDownDisplay || ''}` }
    updateOrderInfo({ body: {...dataOrder , ...dataCompany} , order_id: orderId, screenName: SCREEN_NAME.HONKADO_LIST });
  };

  const getStatusResult = async ({ serviceId, productId, orderId, type = 'completionResultSetting' }) => {
    try {
      setLoading(true);
      const data = await ORDER.GET_STATUS_ORDER({ serviceId, productId, orderId, type, organizationId });
      if (data) {
        setStatusResult(data?.data);
      }
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  return (
    <div className="wrapper-detail-honkado-list">
      <Loading loading={loading} />
      <div className="main">
        <Header idRole={idRole} color={colorScreen?.code} colorText={colorScreen?.colorText} p={p} />
        <div className="body-wrapper">
          <div className="logout_btn">
            <Link to={`list?p=${p}`}>
              <span className="mypage_ab_custom">戻る</span>
            </Link>
          </div>
          {listDetailScreen &&
            _.sortBy(listDetailScreen, 'sortNum').map((item, index) => {
              if (item?.status) {
                switch (item.name) {
                  case 'clientCompany':
                    return <DetailOrder p={p} data={data} key={index}/>;
                  case 'applicationCompany':
                    return <DetailData p={p} data={data} link={false} key={index} />;
                  case 'mattersSent':
                    return (
                      <div>
                        <DetailSent
                          data={data}
                          onChangeText={handleChangeText}
                          isDisabledErp={true}
                          isDisabledManagement={true}
                          key={index}
                        />
                      </div>
                    );
                  case 'visitDateWorker':
                    return (
                      <DetailTimeWorker
                        screenName={SCREEN_NAME.HONKADO_LIST}
                        data={dataDetailTimeWorker}
                        getOrderDetail={getDataOrderForDetailTimeWorker}
                        staffId={data?.staffId}
                        key={index}
                        organizationId={organizationId}
                      />
                    );
                  case 'result':
                    return (
                      <div className="detail-erp-table-wrapper" key={index}>
                        <div className="title-absolute">作業結果</div>
                        <div className="detail__managerment">
                          <table className="detail__table">
                            <tbody>
                              <tr>
                                <th className="detail__table--color">入館・退館</th>
                                <td className="d-flex">
                                  <div className="detail__table--textI text-center  d-flex">
                                    <input
                                      className="detail__table--color"
                                      value={addHour && addMinutes ? `${addHour} : ${addMinutes}` : ``}
                                      disabled
                                    />
                                  </div>
                                  <button className="detail__table--button w-50" onClick={() => HandleHour1()}>
                                    入館
                                  </button>
                                  <div className="detail__table--textI text-center  d-flex">
                                    <input
                                      className="detail__table--color"
                                      value={endDay === '' ? '' : `(${endDay}) ${endHour}:${endMinutes}`}
                                      disabled
                                    />
                                  </div>
                                  <button className="detail__table--button w-50" onClick={() => HandleHour2()}>
                                    退館
                                  </button>
                                </td>
                                <th className="detail__table--color" rowSpan="2">
                                  結果備考
                                </th>
                                <td rowSpan="2">
                                  <textarea
                                    value={dataOrder?.resultCancel}
                                    onChange={handleChangeText}
                                    name="resultCancel"
                                    className="input__custom--w75"
                                  >
                                    {dataOrder?.resultCancel}
                                  </textarea>
                                </td>
                              </tr>
                              <tr>
                                <th className="detail__table--color">作業結果</th>
                                <td>
                                  <select
                                    name="completionResult"
                                    onChange={handleChangeSelect}
                                    value={dataOrder?.completionResult}
                                  >
                                    <option value=""></option>
                                    {statusResult &&
                                      statusResult.map((item, index) => {
                                        return (
                                          <option value={item.id} key={index}>
                                            {item.pullDownDisplay}
                                          </option>
                                        );
                                      })}
                                  </select>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    );
                  case 'acceptanceInformation1':
                    return (
                      <Fragment key={index}>
                        <DetailResult orderId={orderId} />
                      </Fragment>
                    );
                  case 'button':
                    return <div key={index} className="group-action-update-order mb-2">
                      <span className="btn-update-order__wrap">
                        <button className="btn-update-order" onClick={() => onUpdateOrder()}>
                          保存
                        </button>
                      </span>
                    </div>
                  case 'detailInfo':
                    return <DetailListAllData data={data}  screenName={SCREEN_NAME.HONKADO_LIST} key={index} />;
                  case 'document':
                    return (
                      <Fragment key={index}>
                        <DetailTreeDocumentSetting orderId={orderId} order={data} />{' '}
                      </Fragment>
                    );
                  case 'history':
                    return <DetailDataList key={index} datePick={false} screenName={SCREEN_NAME.HONKADO_LIST} orderId={orderId} />;
                  default:
                    return '';
                }
              }
            })}
        </div>
        <Footer color={colorScreen?.code} colorText={colorScreen?.colorText} />
      </div>
    </div>
  );
};

export default DetailHonkadoList;
