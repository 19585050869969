import React, { useState } from 'react';
import './style.scss';
import { Collapse } from 'reactstrap';
import { DATETIME_JP_FORMAT } from 'helpers/constants';
import moment from 'moment';
import _ from 'lodash';

const DetaiListAllData = ({ data, p = 'receive_info', title = 'CSV追加情報' }) => {
  const [isOpen, setIsOpen] = useState(false);

  const onClickShow = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="detail-data-wrapper">
      <div className="detail-cancel-table-wrapper">
        <div className="title-absolute cursor-pointer" onClick={onClickShow}>
          {title} {isOpen ? <i className="fas fa-arrow-circle-up ml-2"></i> : <i className="fas fa-arrow-circle-down ml-2"></i>}
        </div>
        <Collapse isOpen={isOpen}>
          <table className="conditTab">
            <tbody>
              <tr>
                <th>タイムスタンプ</th>
                <td>{moment(data?.createdAt).format(DATETIME_JP_FORMAT)}</td>
                <th>物件名</th>
                <td>{data?.installationSiteName}</td>
              </tr>
              <tr>
                <th>利用者名</th>
                <td>{data?.caseName}</td>
                <th>訪問先ご担当者</th>
                <td>{data?.contactPersonInCharge}</td>
              </tr>
              <tr>
                <th>管理コード</th>
                <td>{data?.managementCode}</td>
                <th>訪問先ご担当者連絡先</th>
                <td>{data?.contactPersonInCharge}</td>
              </tr>
              <tr>
                <th>担当者名</th>
                <td>{data?.contactName}</td>
                <th>訪問先郵便番号</th>
                <td>{data?.visitedPostalCode}</td>
              </tr>
              <tr>
                <th>担当者連絡先 携帯番号</th>
                <td>{data?.contactInformation}</td>
                <th>都道府県</th>
                <td>{data?.prefecturesVisited}</td>
              </tr>
              <tr>
                <th>担当者連絡先 メールアドレス</th>
                <td>{data?.visitedSiteName}</td>
                <th>訪問先拠点住所</th>
                <td>{ _.compact([1, 2, 3, 4, 5].map(e => { return data?.[`visitedBaseAddress${e}`] })).join('')}</td>
              </tr>
              <tr>
                <th>依頼日</th>
                <td>{data?.requestedDate}</td>
                <th>訪問先建物名</th>
                <td>{data?.visitedBuildingName}</td>
              </tr>
              <tr>
                <th>設置先拠点名</th>
                <td>{data?.installationSiteName}</td>
                <th>作業予定箇所</th>
                <td>{data?.scheduledInstallationLocation}</td>
              </tr>
              <tr>
                <th>申込会社名</th>
                <td>{data?.applicantCompanyName}</td>
                <th>希望訪問日時①</th>
                <td>{data?.desiredDateAndTimeOfVisit1}</td>
              </tr>
              <tr>
                <th>申込ご担当者</th>
                <td>{data?.contactPersonInCharge}</td>
                <th>希望訪問日時②</th>
                <td>{data?.desiredDateAndTimeOfVisitFrom2}</td>
              </tr>
              <tr>
                <th>申込ご担当者連絡先</th>
                <td>{data?.contactPersonForApplication}</td>
                <th>希望訪問日時③</th>
                <td>{data?.desiredDateAndTimeOfVisitFrom2}</td>
              </tr>
              <tr>
                <th>申し送り事項</th>
                <td>{data?.notificationItems}</td>
                <th>希望訪問日時④</th>
                <td>{data?.desiredDateAndTimeOfVisitTo4}</td>
              </tr>
              <tr>
                <th>希望訪問日時⑤</th>
                <td>{data?.desiredDateAndTimeOfVisitTo5}</td>
              </tr>
            </tbody>
          </table>
          <div>
            <span className="detail-data-wrapper">インポート情報</span>
          </div>
          <table className="conditTab">
            <tbody>
              <tr>
                <th>発注管理ID</th>
                <td>{data?.visitedSiteName}</td>
                <th>カスタマID</th>
                <td>{data?.visitedBuildingName}</td>
              </tr>
              <tr>
                <th>サービス</th>
                <td>{data?.visitedSiteName}</td>
                <th>サービスタイプ</th>
                <td>{data?.visitedBuildingName}</td>
              </tr>
              <tr>
                <th>D#</th>
                <td>{data?.visitedSiteName}</td>
                <th>案件状態</th>
                <td>{data?.visitedBuildingName}</td>
              </tr>
              <tr>
                <th>入館_立会者</th>
                <td>{data?.visitedSiteName}</td>
                <th>入館_NTT</th>
                <td>{data?.visitedBuildingName}</td>
              </tr>
              <tr>
                <th>入館_施工会社</th>
                <td>{data?.visitedSiteName}</td>
                <th>案件指示</th>
                <td>{data?.visitedBuildingName}</td>
              </tr>
              <tr>
                <th>NG情報</th>
                <td>{data?.visitedSiteName}</td>
                <th>受注前現調</th>
                <td>{data?.visitedBuildingName}</td>
              </tr>
            </tbody>
          </table>
        </Collapse>
      </div>
    </div>
  );
};

export default DetaiListAllData;
