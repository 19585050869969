import Swal from 'sweetalert2';

export const confirmDelete = () => {
  return Swal.fire({
    title: 'Are you sure to delete?',
    text: 'Information once deleted will not be recovered!',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes',
    cancelButtonText: 'No',
  });
};
