import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { Helmet } from 'react-helmet';
import FooterMaintenance from '../../../components/FooterMaintenance';
import 'assets/scss/page/BranchStaffMaster/style.scss';
import { BRANCH_MASTER_ENDPOINT } from '../../../constants/endpoints';
import PaginationCustom from '../../../components/PaginationCustom';
import HeaderMasterSetting from '../../../components/HeaderMasterSetting';
import TableStaffBranchMaster from './TableStaffBranchMaster';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const MockDataSearch = {
  page: '1',
  limit: '10',
  firstNameKana: '',
  lastNameKana: '',
  loginId: '',
  phoneNumber: '',
};
const BranchStaffMaster = () => {
  let branchIds = (process.browser && new URL(window.location.href).searchParams.get('branchId')) || '';
  const colorScreen = useSelector((store) => store.utils.colorMe);
  const [activePage, setActivePage] = React.useState(1);
  const [listStaff, setListStaff] = React.useState([]);
  const [dataSearch, setDataSearch] = React.useState(MockDataSearch);
  const fontSize = 'small';

  const getListStaffByBranch = async ({
    page = 1,
    limit = 10,
    firstNameKana = '',
    lastNameKana = '',
    loginId = '',
    phoneNumber = '',
    branchId = branchIds,
  }) => {
    try {
      const data = await BRANCH_MASTER_ENDPOINT.GET_LIST_STAFF_BY_BRANCH({
        page: page,
        limit: limit,
        firstNameKana: firstNameKana,
        lastNameKana: lastNameKana,
        loginId: loginId,
        phoneNumber: phoneNumber,
        branchId: branchId,
      });
      if (data) {
        setListStaff(data);
      }
    } catch (errorMessage) {
      console.log('errorMessage: ', errorMessage);
    }
  };

  useEffect(() => {
    getListStaffByBranch({ page: activePage });
  }, [activePage]);

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const handleChangeSearch = (e) => {
    const typeName = e.target.name;
    let newData = { ...dataSearch, [typeName]: e.target.value };
    setDataSearch(newData);
  };

  const onSubmit = () => {
    getListStaffByBranch(dataSearch);
  };

  return (
    <div className="wrapper-branch-staff-master">
      <Helmet>
        <title> </title>
      </Helmet>
      <HeaderMasterSetting checkName="/branch-master" color={colorScreen?.code} colorText={colorScreen?.colorText} />
      <div className="body">
        <div className="wrapper-main">
          <div className="flex-row-reverse group-button text-right">
            <Link
              to={`/branch-master/modify-branch-staff?branchId=${branchIds}`}
              style={{ color: '#00a63c', textDecoration: 'underline' }}
            >
              <button className="btn button-edit mr-2 green">
                <i className="fas fa-edit" />
                センター（閲覧ユーザー）
              </button>
            </Link>
          </div>
          <div className="title-label">
            <h3 className={`text-center mt-2 font-weight-bold ${fontSize}`}>検索条件</h3>
          </div>
          <div className="row search-wrapper">
            <div className="col-12 col-md-6 col-xl-4 d-flex mb-1 pr-md-0 ">
              <div className="col-4 h-100 p-2 label-input">
                <label className={`${fontSize}`}>利用者ID </label>
              </div>
              <div className="h-100 col-8 p-2 input-wrapper">
                <input
                  name="loginId"
                  value={dataSearch?.loginId}
                  onChange={handleChangeSearch}
                  className={`form-control ${fontSize}`}
                />
              </div>
            </div>
            <div className="col-12 col-md-6 col-xl-4 d-flex mb-1 pl-md-0 pr-md-3 px-xl-0 ">
              <div className="col-4 h-100 p-2 label-input">
                <label className={`${fontSize}`}>フリガナ </label>
              </div>
              <div className="h-100 col-8 p-0 input-wrapper">
                <div className="d-flex w-100 h-100">
                  <div className="w-50 h-100 p-2 d-flex align-items-center" style={{ borderRight: '1px solid #ccc' }}>
                    <label className={`mb-0 label-name ${fontSize}`}>セイ：</label>
                    <input
                      name="firstNameKana"
                      value={dataSearch?.firstNameKana}
                      onChange={handleChangeSearch}
                      className={`w-75 form-control ${fontSize}`}
                    />
                  </div>
                  <div className="w-50 h-100 p-2 d-flex align-items-center">
                    <label className={`mb-0 label-name ${fontSize}`}>メイ：</label>
                    <input
                      name="lastNameKana"
                      value={dataSearch?.lastNameKana}
                      onChange={handleChangeSearch}
                      className={`w-75 form-control ${fontSize}`}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-xl-4 d-flex mb-1 pr-md-0 pl-xl-0 pr-xl-3 ">
              <div className="col-4 h-100 p-2 label-input">
                <label className={`${fontSize}`}>ユーザー連絡先 </label>
              </div>
              <div className="h-100 col-8 p-2 input-wrapper">
                <input
                  name="phoneNumber"
                  value={dataSearch?.phoneNumber}
                  onChange={handleChangeSearch}
                  className={`form-control ${fontSize}`}
                />
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center align-items-center group-button">
            <button
              className="btn button-edit mr-2 green"
              style={{ paddingLeft: '1.5rem', paddingRight: '1.5rem' }}
              onClick={() => onSubmit()}
            >
              検索
            </button>
          </div>
          <div className="wrapper-result">
            <div className="result-title">
              <h3 className={`font-weight-bolder ${fontSize}`}>検索結果：{listStaff?.data?.count}件</h3>
            </div>
            <TableStaffBranchMaster fontSize={fontSize} listUser={listStaff?.data?.body} activePage={activePage} />
            {listStaff && listStaff?.data?.count > 10 && (
              <PaginationCustom
                activePage={activePage}
                itemsCountPerPage={10}
                totalItemsCount={listStaff?.data?.count}
                pageRangeDisplayed={5}
                handlePageChange={handlePageChange}
              />
            )}
          </div>
        </div>
      </div>
      <FooterMaintenance />
    </div>
  );
};

export default compose(withRouter)(BranchStaffMaster);
