import React, { useEffect, useState } from 'react';
import HeaderMasterSetting from '../../../components/HeaderMasterSetting';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { BRANCH_MASTER_ENDPOINT } from '../../../constants/endpoints';
import { SCREEN_NAME } from '../../../helpers/constants';
import 'assets/scss/page/BranchMaster/style.scss';
import PaginationCustom from '../../../components/PaginationCustom';
import SearchBranchMaster from './SearchBranchMaster';
import TableBranchMaster from './TableBranchMaster';
import { useSelector } from 'react-redux';
import BorderGroupForm from 'components/BorderGroupForm';
import MemoHistory from '../../../components/MemoHistory';
import Footer from '../../../components/Footer';

const BranchMaster = () => {
    const fontSize = 'small'
    const [listBranchMaster, setListBranchMaster] = useState(null);
    const [activePage, setActivePage] = useState(1);
    const colorScreen = useSelector((store) => store.utils.colorMe);

    const getListBranchMaster = async ({ page = 1, limit = 10, branchName = '', branchNameKana = '' }) => {
        try {
            const data = await BRANCH_MASTER_ENDPOINT.GET_LIST_BRANCH_MASTER({
                page: page,
                limit: limit,
                branchName: branchName,
                branchNameKana: branchNameKana
            });
            if (data) {
                setListBranchMaster(data?.data);
            }
        } catch (e) {
            console.log(e);
        }
    };

    useState(() => {
        getListBranchMaster({});
    }, []);

    useEffect(() => {
        getListBranchMaster({ page: activePage });
    }, [activePage]);


    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
    };


    return (
        <div className="main-staff-master">
            <HeaderMasterSetting color={colorScreen?.code} colorText={colorScreen?.colorText}/>
            <BorderGroupForm color={colorScreen?.code} >
                <div className="body-wrapper mb-1">
                    <SearchBranchMaster fontSize={fontSize} getListBranchMaster={getListBranchMaster} />
                    <TableBranchMaster fontSize={fontSize} listBranchMaster={listBranchMaster?.body} activePage={activePage}
                        count={listBranchMaster?.count}
                        />
                    <div className='pt-3'>
                        {listBranchMaster && listBranchMaster?.count > 10 && (
                            <PaginationCustom
                                activePage={activePage}
                                itemsCountPerPage={10}
                                totalItemsCount={listBranchMaster?.count}
                                pageRangeDisplayed={5}
                                handlePageChange={handlePageChange}
                            />
                        )}
                    </div>
                </div>
                <MemoHistory colors={colorScreen?.code} colorText={colorScreen?.colorText} screenName={SCREEN_NAME.BRANCH_MASTER}/>
                <Footer color={colorScreen?.code} colorText={colorScreen?.colorText}/>
            </BorderGroupForm>
        </div>
    );
};

export default compose(withRouter)(BranchMaster);
