import React, { memo, useState, useEffect } from 'react';
import './style.scss'
import { STAFF_ENDPOINT, ORDER_PARTNER } from 'constants/endpoints';
import { pushToast } from 'components/Toast';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ModalSignature from './ModalSignature';
import { time, ROLE, DATE_JP_FORMAT, WORKER_TYPE, OPTION_IMG_REPORT, SCREEN_NAME } from 'helpers/constants';
import moment from 'moment';
import { getRole } from 'helpers/helpers';
import _ from 'lodash';
import { formatInputSelectTime, formatInputSelectDate } from 'helpers/helpers';
import barcode_image from '../../../../assets/img/barcode_image.png';

const ReportAttachment = ({
  dataActive,
  pushContent,
  pushFile,
  getDetailAgain,
  pushBarcode,
  pushImage,
  alloweHandleReport = true,
  onTemporary = false,
  setTemporary = () => { },
  handleCheckImg = () => { },
  changeComment = () => { },
  onReportOrderSuccess = () => { },
  changeContentVisitDate,
  isChangeStep,
  setIsChangeStep,
  dataOrder
}) => {
  const [modalSignature, setModalSignature] = useState(false);
  const [nameSelected, setNameSelected] = useState('');
  const [role, setRole] = useState(null);
  const [statusDisableRequired, setStatusDisableRequired] = useState(true);
  const [menuItemSettingIdSelected, setMenuItemSettingIdSelected] = useState('');
  const [dataMenu, setDataMenu] = useState({});
  const orderId = (process.browser && new URL(window.location.href).searchParams.get('orderId')) || null;
  const organizationId = (process.browser && new URL(window.location.href).searchParams.get('organizationId')) || null;
  const menuName = (process.browser && new URL(window.location.href).searchParams.get('menuName')) || null;
  const screenName = (process.browser && new URL(window.location.href).searchParams.get('screenName')) || '';
  const [dataCurrent, setDataCurrent] = useState(null);

  const textDebounce = (name, menuItemSettingId, e, content, nameInStella = '') => {
    const value = e.target.value;
    changeDataCurrent(name, menuItemSettingId, {...content ,[nameInStella] : value });
  };

  useEffect(() => {
    if(isChangeStep) {
      setDataCurrent(null);
      setIsChangeStep(false);
    }
  }, [isChangeStep]);

  const changeDataCurrent = (name, menuItemSettingId, value) => {
    const newData = dataActive?.menuItemsSettings?.map((item) => {
      if(item?.itemType?.[0]?.name === name) {
        const newMenuItem = item?.itemType?.map((elm) => {
          if (elm.name === name) {
            return { ...elm, content: value };
          } else {
            return elm;
          }
        });
        return { ...item, itemType: newMenuItem };
      }
      else {
        return item;
      }
    });
    const newDataCurrent = { ...dataActive, menuItemsSettings: newData };
    if (newDataCurrent?.menuItemsSettings) {
      setDataCurrent(_.groupBy(newDataCurrent?.menuItemsSettings || [], 'titleName'));
    }
    changeContentVisitDate({ newData });
  };

  const changeContentData = (name, menuItemSettingId, value) => {
    const newData = dataActive?.menuItemsSettings?.map((item) => {
      if (item.menuItemSettingId === menuItemSettingId) {
        const newMenuItem = item?.itemType?.map((elm) => {
          if (elm.name === name) {
            return { ...elm, content: value };
          } else {
            return elm;
          }
        });
        return { ...item, itemType: newMenuItem };
      } else {
        return item;
      }
    });
    const newDataCurrent = { ...dataActive, menuItemsSettings: newData };
    if (newDataCurrent?.menuItemsSettings) {
      setDataCurrent(_.groupBy(newDataCurrent?.menuItemsSettings || [], 'titleName'));
    }
    pushContent({newData});
  };

  const changeContentDataTypeUpadate = (name, menuItemSettingId, value ,nameInStella) => {
    const newData = dataActive?.menuItemsSettings?.map((item) => {
      if (item.menuItemSettingId === menuItemSettingId) {
        const newMenuItem = item?.itemType?.map((elm) => {
          if (elm.name === name) {
            return { ...elm, content: {...elm.content , [nameInStella] : value } };
          } else {
            return elm;
          }
        });
        return { ...item, itemType: newMenuItem };
      } else {
        return item;
      }
    });
    const newDataCurrent = { ...dataActive, menuItemsSettings: newData };
    if (newDataCurrent?.menuItemsSettings) {
      setDataCurrent(_.groupBy(newDataCurrent?.menuItemsSettings || [], 'titleName'));
    }
    pushContent({newData});
  };

  const changeCommentChild = ({name, menuItemSettingId, value}) => {
    const newData = dataActive?.menuItemsSettings?.map((item) => {
      if (item.menuItemSettingId === menuItemSettingId) {
        const newMenuItem = item?.itemType?.map((elm) => {
          if (elm.name === name) {
            return { ...elm, content: {...elm.content, comment: value }};
          } else {
            return elm;
          }
        });
        return { ...item, itemType: newMenuItem };
      } else {
        return item;
      }
    });
    const newDataCurrent = { ...dataActive, menuItemsSettings: newData };
    if (newDataCurrent?.menuItemsSettings) {
      setDataCurrent(_.groupBy(newDataCurrent?.menuItemsSettings || [], 'titleName'));
    }
    pushContent({newData});
  };

  const toggleCloseModalSignature = (name, menuItemSettingId) => {
    setModalSignature(!modalSignature);
    setNameSelected(name);
    setMenuItemSettingIdSelected(menuItemSettingId);
  };

  useEffect(() => {
    if (onTemporary) {
      handleReportOrder({ confirm: false })
    }
  }, [onTemporary]);

  useEffect(() => {
    let arrCheck = [];
    for (let i = 0; i <= dataActive?.menuItemsSettings?.length; i++) {
      if (dataActive?.menuItemsSettings[i]?.required) {
        arrCheck = [...arrCheck, true]
        if (_.isEmpty(dataActive?.menuItemsSettings[i]?.itemType[0]?.content?.content)
          && _.isEmpty(dataActive?.menuItemsSettings[i]?.itemType[0]?.content?.option)
          && dataActive?.menuItemsSettings[i]?.itemType[0]?.name === WORKER_TYPE.IMAGE ){
          setStatusDisableRequired(true);
          break;
        }
        if (_.isEmpty(dataActive?.menuItemsSettings[i]?.itemType[0]?.content?.barCodeCustom)
          && _.isEmpty(dataActive?.menuItemsSettings[i]?.itemType[0]?.content?.option)
          && dataActive?.menuItemsSettings[i]?.itemType[0]?.name === WORKER_TYPE.BARCODE_IDENTIFICATION) {
          setStatusDisableRequired(true);
          break;
        }
        if (!_.isEmpty(dataActive?.menuItemsSettings[i]?.itemType[0]?.content)
          && dataActive?.menuItemsSettings[i]?.itemType[0]?.name === WORKER_TYPE.FORM_INPUT_DATETIME) {
            let checkFieldBarcode = true;
            let checkFieled = true;
            let dataCheck = dataActive?.menuItemsSettings[i]?.itemType[0]?.value?.map(ele => {
              if(ele?.typeUpdate === "barcode") {
                if(!_.isEmpty(dataActive?.menuItemsSettings[i]?.itemType[0]?.content?.[ele?.nameInStella || ele?.name]?.option)
                  || !_.isEmpty(dataActive?.menuItemsSettings[i]?.itemType[0]?.content?.[ele?.nameInStella || ele?.name]?.barCodeCustom) ) {
                  checkFieldBarcode = false
                  } else {
                  checkFieldBarcode = true
                  }
              } else {
                if(!_.isEmpty(dataActive?.menuItemsSettings[i]?.itemType[0]?.content?.[ele?.nameInStella || ele?.name])) {
                  checkFieled = false
                } else {
                  checkFieled = true
                }
              }
          })
          if(!checkFieldBarcode && !checkFieled) {
          setStatusDisableRequired(false)
          } else {
            setStatusDisableRequired(true)
          }
          break;
        }
        if ('content' in dataActive?.menuItemsSettings[i]?.itemType[0]
          && !_.isEmpty(dataActive?.menuItemsSettings[i]?.itemType[0]?.content)) {
          setStatusDisableRequired(false);
        } else {
          setStatusDisableRequired(true);
          break;
        }
      } else {
        arrCheck = [...arrCheck, false]
      }
    }
    arrCheck.every((currentValue) => currentValue === false) && setStatusDisableRequired(false);
    if (dataActive?.menuItemsSettings) {
      setDataMenu(_.groupBy(dataActive?.menuItemsSettings || [], 'titleName'));
    }
  }, [dataActive]);

  useEffect(() => {
    const newRole = localStorage.getItem('role');
    if (newRole) {
      setRole(getRole({ idRole: newRole }));
    }
  }, []);

  const handleChangeDate = (name, menuItemSettingId, date) => {
    if (date) {
      const valueDate = moment(new Date(date)).format(DATE_JP_FORMAT);
      changeContentData(name, menuItemSettingId, valueDate);
      // pushContent(name, menuItemSettingId, valueDate);
    } else {
      changeContentData(name, menuItemSettingId, '');
      // pushContent(name, menuItemSettingId, '');
    }
  };

  const handleChangeVisitDateTo = (name, menuItemSettingId, date, content, nameInStella) => {
    if (date) {
      const valueDate = moment(new Date(date)).format(DATE_JP_FORMAT);
      changeDataCurrent(name, menuItemSettingId, { ...content, [nameInStella]: valueDate });
      // changeContentVisitDate(name, menuItemSettingId, { ...content, [nameInStella]: valueDate });
    } else {
      changeDataCurrent(name, menuItemSettingId, { ...content, [nameInStella]: '' })
      // changeContentVisitDate(name, menuItemSettingId, { ...content, [nameInStella]: '' });
    }
  };

  const handleButtonTimeStamp = (name, menuItemSettingId, content, nameInStella = '') => {
    const value = moment(new Date()).format('YYYY/MM/DD HH:mm');
    changeDataCurrent(name, menuItemSettingId, {...content ,[nameInStella] : value });
    // changeContentVisitDate(name,menuItemSettingId, {...content ,[nameInStella] : value })
  }

  const handleSelectVisitDate = (name, menuItemSettingId, e, content, nameInStella = '') => {
    const value = e.target.value;
    changeDataCurrent(name, menuItemSettingId, {...content ,[nameInStella] : value });
    // changeContentVisitDate(name,menuItemSettingId, {...content ,[nameInStella] : value })
  };

  const changeContent = (name, menuItemSettingId, e) => {
    const value = e.target.value;
    changeContentData(name, menuItemSettingId, value);
    // pushContent(name, menuItemSettingId, value);
  };

  const changeContentTimeStamp = (name, menuItemSettingId, e) => {
    const value = moment(new Date()).format('YYYY/MM/DD HH:mm')
    changeContentData(name, menuItemSettingId, value);
    // pushContent(name, menuItemSettingId, value);
  };

  const changeContentRadio = ({ name, menuItemSettingId, value }) => {
    changeContentData(name, menuItemSettingId, value);
    // pushContent(name, menuItemSettingId, value);
  };

  const handleInputBarcode = (name, menuItemSettingId, e , content = {} , nameInStella) => {
    const value = e.target.value;
    if(name === WORKER_TYPE.BARCODE_IDENTIFICATION) {
      changeContentData(name, menuItemSettingId, {...content , barCodeCustom : value });
    } else {
      changeContentData(name, menuItemSettingId, {...content , [nameInStella] : {...content?.[nameInStella] , barCodeCustom : value} });
    }
  }

  const renderFormRadio = ({ name, content, menuItemSettingId, required, value }) => {
    return (
      value?.map((item, index) => {
        return (
          <div key={index} className="form-check-report">
            <input
              id={item.name}
              type="radio"
              checked={item.name === content}
              disabled={!alloweHandleReport}
              onChange={() => changeContentRadio({ name, menuItemSettingId, value: item.name })}
            />
            <p className={(required && _.isEmpty(content)) ? 'idRequiredText' : ''}>{item.name}</p>
          </div>
        )
      })
    )
  };

  const changeContentCheckbox = ({ name, menuItemSettingId, value, content }) => {
    let newContent = [];
    if (content.includes(value)) {
      newContent = content.filter(item => item !== value);
    } else {
      newContent = [...content, value];
    }
    const StringContent = JSON.stringify(newContent);
    changeContentData(name, menuItemSettingId, StringContent);
    // pushContent(name, menuItemSettingId, StringContent);
  };

  const renderFormCheckbox = ({ name, content, menuItemSettingId, required, value }) => {
    const ArrayContent = content && JSON.parse(content);
    return (
      value?.map((item, index) => {
        return (
          <div key={index} className="form-check-report">
            <input
              type="checkbox"
              id={item.name}
              checked={ArrayContent ? ArrayContent.includes(item.name) : false}
              disabled={!alloweHandleReport}
              onChange={() => changeContentCheckbox({ name, menuItemSettingId, value: item.name, content: ArrayContent ? ArrayContent : [] })}
            />
            <p className={(required && (!content || content === "[]")) ? 'idRequiredText' : ''}>{item.name}</p>
          </div>
        )
      })
    )
  };

  const renderItemTypeForm = ({ name, content, menuItemSettingId, required, value }) => {
    switch (name) {
      case WORKER_TYPE.RADIO_BUTTON:
        return renderFormRadio({ name, content, menuItemSettingId, required, value })
      case WORKER_TYPE.CHECKBOX:
        return renderFormCheckbox({ name, content, menuItemSettingId, required, value })
      case WORKER_TYPE.INTEGER:
        return <input
          type="number"
          className={(required && !content) ? 'idRequired type-content' : 'type-content'}
          id={menuItemSettingId}
          value={content ? content : ''}
          disabled={!alloweHandleReport}
          onChange={(e) => changeContent(name, menuItemSettingId, e)}
        />;
      case WORKER_TYPE.FLOAT:
        return <input
          type="number"
          className={(required && !content) ? 'idRequired type-content' : 'type-content'}
          id={menuItemSettingId}
          step="0.01"
          value={content ? content : ''}
          disabled={!alloweHandleReport}
          onChange={(e) => changeContent(name, menuItemSettingId, e)}
        />;
      case WORKER_TYPE.TEXT_FIELD:
        return <input
          className={(required && !content) ? 'idRequired type-content' : 'type-content'}
          value={content ? content : ''}
          id={menuItemSettingId}
          disabled={!alloweHandleReport}
          onChange={(e) => changeContent(name, menuItemSettingId, e)}
        />;
      case WORKER_TYPE.TEXT_AREA:
        return <textarea
          className={(required && !content) ? 'idRequired type-content' : 'type-content'}
          rows="6"
          disabled={!alloweHandleReport}
          value={content ? content : ''}
          onChange={(e) => changeContent(name, menuItemSettingId, e)}
        ></textarea>;
      case WORKER_TYPE.DATE:
        return <DatePicker
          selected={content ? new Date(content) : ''}
          onChange={(date) => handleChangeDate(name, menuItemSettingId, date)}
          locale="ja"
          id={menuItemSettingId}
          isClearable
          dateFormat="yyyy/MM/dd"
          dateFormatCalendar="yyyy年 MM月"
          className={(required && !content) ? 'idRequired type-content' : 'type-content'}
          disabled={!alloweHandleReport}
        />;
      case WORKER_TYPE.TIME:
        return <select name="time"
          id={menuItemSettingId}
          className={(required && !content) ? 'idRequired type-content' : 'type-content'}
          defaultValue={content ? content : ''}
          disabled={!alloweHandleReport}
          onChange={(e) => changeContent(name, menuItemSettingId, e)}>
          <option value="">---</option>
          {
            time.map((item, index) =>
              <option value={item} key={index}>{item}</option>
            )
          }
        </select>;
      case WORKER_TYPE.ATTACHMENT_FILE:
        return renderAttachmentFile(content, name, menuItemSettingId, required);
      case WORKER_TYPE.SIGNATURE:
        return renderSignature(content, name, menuItemSettingId, required);
      case WORKER_TYPE.IMAGE:
        return renderFormImage(content, name, menuItemSettingId, required);
      case WORKER_TYPE.BARCODE_IDENTIFICATION:
        return renderFormBarcode(content, name, menuItemSettingId, required);
        case WORKER_TYPE.SHOW_NAME:
          return (
            <div>
              {
                value?.map((item, k) => {
                  return (
                    <div className="d-flex my-1" key={k}>
                      <p className="col-4 col-sm-3 p-compare-name mr-1 mb-0">{item?.name}</p>
                      <input
                        className="type-content"
                        value={content?.[item?.nameInStella || item?.name] || ''}
                        disabled
                      />
                    </div>
                  )
                })
              }
            </div>
          )
        case WORKER_TYPE.FORM_INPUT_DATETIME:
          return (
            <div>
              {value?.map((item) => {
                if(item?.typeUpdate === "time") {
                  return (
                    <div className="d-flex my-1">
                      <p className="col-3 col-sm-2 pt-2 p-compare-name mr-1 mb-0">{item?.name}</p>
                      <select
                        name="time"
                        id={menuItemSettingId}
                        className={required && !content?.[item?.nameInStella || item?.name] ? "idRequired type-content" : "type-content"}
                        value={!_.isEmpty(content?.[item?.nameInStella || item?.name]) ? formatInputSelectTime(content?.[item?.nameInStella || item?.name]) : !_.isEmpty(dataOrder?.customData?.[item?.nameInStella || item?.name]) ? formatInputSelectTime(dataOrder?.customData?.[item?.nameInStella || item?.name]) : ''}
                        disabled={!alloweHandleReport}
                        onChange={(e) => handleSelectVisitDate(name, menuItemSettingId, e, content, item?.nameInStella || item?.name)}
                      >
                        <option value="">---</option>
                        {time.map((item, index) => (
                          <option value={item} key={index}>
                            {item}
                          </option>
                        ))}
                      </select>
                    </div>
                  )
                }
                if(item?.typeUpdate === "timestamp") {
                  return (
                    <div className="d-flex my-1">
                      <p className="col-3 col-sm-2 pt-2 p-compare-name mr-1 mb-0">{item?.name}</p>
                      <button className="btn btn-primary mr-2 button-timestamp" onClick={(e) => handleButtonTimeStamp(name, menuItemSettingId, content, item?.nameInStella || item?.name)}>
                        時間反映
                      </button>
                      <input
                        value={content?.[item?.nameInStella || item?.name] || dataOrder?.customData?.[item?.nameInStella || item?.name]}
                        className={required && !content?.[item?.nameInStella || item?.name] ? "idRequired type-content" : "type-content"}
                        />
                    </div>
                  )
                }
                if(item?.typeUpdate === "string") {
                  return (
                    <div className="d-flex my-1">
                      <p className="col-3 col-sm-2 pt-2 p-compare-name mr-1 mb-0">{item?.name}</p>
                      <input
                      className={required && !content?.[item?.nameInStella || item?.name] ? "idRequired type-content" : "type-content"}
                      type="text"
                      value={content?.[item?.nameInStella || item?.name] || dataOrder?.customData?.[item?.nameInStella || item?.name]}
                      onChange={(e) => textDebounce(name, menuItemSettingId, e, content , item?.nameInStella || item?.name)}
                      />
                    </div>
                  )
                }

                if(item?.typeUpdate === "date") {
                  return (
                    <div className="d-flex my-1">
                      <p className="col-3 col-sm-2 pt-2 p-compare-name mr-1 mb-0">{item?.name}</p>
                      <DatePicker
                      selected={!_.isEmpty(content?.[item?.nameInStella || item?.name]) ? new Date(content?.[item?.nameInStella || item?.name].slice(0,10)) : !_.isEmpty(dataOrder?.customData?.[item?.nameInStella || item?.name]) ? new Date(dataOrder?.customData?.[item?.nameInStella || item?.name].slice(0,10)) : ''}
                      onChange={(date) => handleChangeVisitDateTo(name, menuItemSettingId, date, content, item?.nameInStella || item?.name)}
                      locale="ja"
                      dateFormat="yyyy/MM/dd"
                      dateFormatCalendar="yyyy年 MM月"
                      className={required && !content?.[item?.nameInStella || item?.name] ? "idRequired type-content w-80" : "type-content w-80"}
                      />
                    </div>

                  )
                }
                if (item?.typeUpdate === 'barcode') {
                  return (
                    <div className="form-upload-img mb-1">
                      <div className="form-img">
                        <label htmlFor={`${menuItemSettingId}-barcode`} className="label-barcode">
                          <img src={barcode_image} className="img-barcode" />
                        </label>
                        <div className="form-checkbox">
                          <label>
                            <input
                              type="checkbox"
                              name={OPTION_IMG_REPORT.REMOVE}
                              checked={content?.[item?.nameInStella || item?.name]?.option?.includes(OPTION_IMG_REPORT.REMOVE) || false}
                              onChange={() =>
                                handleCheckImgInpage({ name, menuItemSettingId, value: OPTION_IMG_REPORT.REMOVE , nameInStella : item?.nameInStella || item?.name })
                              }
                            />
                            写真削除
                          </label>
                          <label>
                            <input
                              type="checkbox"
                              name={OPTION_IMG_REPORT.NOT_ALLOWED}
                              checked={content?.[item?.nameInStella || item?.name]?.option?.includes(OPTION_IMG_REPORT.NOT_ALLOWED) || false}
                              onChange={() =>
                                handleCheckImgInpage({ name, menuItemSettingId, value: OPTION_IMG_REPORT.NOT_ALLOWED , nameInStella : item?.nameInStella || item?.name })
                              }
                            />
                            写真撮影不可
                          </label>
                        </div>
                      </div>

                      <input
                        type="file"
                        id={`${menuItemSettingId}-barcode`}
                        accept="image/*"
                        disabled={!alloweHandleReport}
                        className="input-upload"
                        onChange={(e) => onChangeImgBarcode({ e, name, menuItemSettingId , nameInStella : item?.nameInStella || item?.name})}
                      />

                      {content ? (
                        <img id={`${menuItemSettingId}-barcode`} src={content?.[item?.nameInStella || item?.name]?.url} />
                      ) : (
                        <div className="emply-div"></div>
                      )}

                      <input
                        type="text"
                        id={`${menuItemSettingId}-barcode`}
                        disabled
                        value={content?.[item?.nameInStella || item?.name]?.barCode || ''}
                        className={`input-barcode w-80`}
                      />
                      {!_.isEmpty(content?.[item?.nameInStella || item?.name]?.barCodeCustom) ? (
                        ''
                      ) : (
                        <div className={`emply-div w-80 ${required ? 'idRequired' : ''}`}></div>
                      )}
                      <input
                        type="text"
                        id={`${menuItemSettingId}-barcode`}
                        disabled={!alloweHandleReport}
                        onChange={(e) => handleInputBarcode(name, menuItemSettingId, e, content , (item?.nameInStella || item?.name) )}
                        value={content?.[item?.nameInStella || item?.name]?.barCodeCustom || ''}
                        className={`input-barcode mt-1  w-80`}
                      />
                      {!_.isEmpty(content?.[item?.nameInStella || item?.name]?.barCodeCustom) ? (
                        ''
                      ) : (
                        <div className={`emply-div w-80 ${required ? 'idRequired' : ''}`}></div>
                      )}
                    </div>
                  );
                }
              })}
            </div>
          );
        case WORKER_TYPE.COMPARE_NAME:
          if (!!content?.find((ele) => ele?.value?.name === WORKER_TYPE.IMAGE)) {
            return (
              <div className="form-upload-img">
                {!_.isEmpty(content?.find((ele) => ele?.value?.name === WORKER_TYPE.IMAGE)?.value?.content) ? (
                  <img
                    id={`${menuItemSettingId}-img`}
                    src={content?.find((ele) => ele?.value?.name === WORKER_TYPE.IMAGE)?.value?.content}
                  />
                ) : (
                  <div className={`emply-div ${required ? 'idRequired' : ''}`}></div>
                )}
              </div>
            );
          } else {
            return (
              <div>
                {content?.map((elm, elmI) => {
                  return (
                    <div className="d-flex w-80" key={elmI}>
                      <p className="col-4 col-sm-3 pt-2 p-compare-name">入荷記録_{elm?.name}</p>
                      <input
                        className={required && !content ? 'idRequired type-content w-30' : 'type-content'}
                        value={!_.isEmpty(elm?.value?.barCodeCustom) ? elm?.value?.barCodeCustom : elm?.value }
                        disabled
                      />
                    </div>
                  );
                })}
                <div className="d-flex w-80">
                  <p className="col-4 col-sm-3 pt-2 p-compare-name">判定</p>
                  <input
                    value={
                      ((!_.isEmpty(content?.[0]?.value?.barCodeCustom) ? content?.[0]?.value?.barCodeCustom : content?.[0]?.value) ===
                      (!_.isEmpty(content?.[1]?.value?.barCodeCustom) ? content?.[1]?.value?.barCodeCustom : content?.[1]?.value))
                        ? 'OK'
                        : 'NG'
                    }
                    disabled
                    className={required && !content ? 'idRequired type-content w-30' : 'type-content'}
                  />
                </div>
              </div>
            );
          }

        case WORKER_TYPE.LINK_URL:
          return (
            <button className="btn btn-primary w-30" disabled={_.isEmpty(value[0].link)} onClick={() => window.open(`${value?.[0]?.link}`)}>
              {value?.[0]?.name}
            </button>
          );
        case WORKER_TYPE.TIME_STAMP:
          return (
            <div className="d-flex w-80">
              <button
                className="btn btn-primary mr-2 button-timestamp"
                onClick={(e) => changeContentTimeStamp(name, menuItemSettingId, e)}
              >
                時間反映
              </button>
              <input
                className={required && !content ? 'idRequired type-content' : 'type-content'}
                value={content ? content : ''}
                disabled={!alloweHandleReport}
                className="type-content"
              />
            </div>
          );
      // case WORKER_TYPE.FIELD_REFLECTION:
      //   return 'FIELD_REFLECTION';
      // case WORKER_TYPE.FIELD_REFERENCE:
      //   return 'FIELD_REFERENCE';
      default:
        return ''
    }
  };

  const onChangeImgBarcode = ({ e, name, menuItemSettingId , nameInStella = '' }) => {
    changeBarcode(name, menuItemSettingId, e , nameInStella);
  };

  const changeBarcode = async (name, menuItemSettingId, e , nameInStella) => {
    const fileName = e?.target?.files[0]?.name;
    const files = e?.target?.files[0];
    const body = {
      fileName,
      content: files ? await toBase64(files) : '',
    };
    getBarcode({ name, menuItemSettingId, body , nameInStella});
  };

  const getBarcode = async ({ name, menuItemSettingId, body , nameInStella }) => {
    try {
      const data = await ORDER_PARTNER.GET_BARCODE({ body });
      if (!_.isEmpty(data?.data)) {
        const value = { ...data?.data , barCodeCustom : data?.data?.barCode }
        if(name === WORKER_TYPE.BARCODE_IDENTIFICATION) {
          changeContentData(name, menuItemSettingId, value);
        } else {
          changeContentDataTypeUpadate(name , menuItemSettingId , value , nameInStella)
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const renderFormBarcode = (content, name, menuItemSettingId , required) => {
    return (
      <div className="form-upload-img">
        <div className="form-img">
          <label htmlFor={`${menuItemSettingId}-barcode`} className="label-barcode">
            <img src={barcode_image} className='img-barcode'/>
          </label>
          <div className="form-checkbox">
            <label>
              <input
                type="checkbox"
                name={OPTION_IMG_REPORT.REMOVE}
                checked={content?.option?.includes(OPTION_IMG_REPORT.REMOVE) || false}
                onChange={() => handleCheckImgInpage({ name, menuItemSettingId, value: OPTION_IMG_REPORT.REMOVE })}
              />
              写真削除
            </label>
            <label>
              <input
                type="checkbox"
                name={OPTION_IMG_REPORT.NOT_ALLOWED}
                checked={content?.option?.includes(OPTION_IMG_REPORT.NOT_ALLOWED) || false}
                onChange={() => handleCheckImgInpage({ name, menuItemSettingId, value: OPTION_IMG_REPORT.NOT_ALLOWED })}
              />
              写真撮影不可
            </label>
          </div>
        </div>

        <input
          type="file"
          id={`${menuItemSettingId}-barcode`}
          accept="image/*"
          disabled={!alloweHandleReport}
          className="input-upload"
          onChange={(e) => onChangeImgBarcode({ e, name, menuItemSettingId })}
        />

        {content ? <img id={`${menuItemSettingId}-barcode`} src={content?.url} /> : <div className="emply-div"></div>}

        <input
          type="text"
          id={`${menuItemSettingId}-barcode`}
          disabled
          value={content?.barCode || ''}
          className={`input-barcode w-100`}
        />
        {!_.isEmpty(content?.barCodeCustom) ? ''
          : <div className={`emply-div ${required ? 'idRequired' : ''}`}></div>}
        <input
          type="text"
          id={`${menuItemSettingId}-barcode`}
          disabled={!alloweHandleReport}
          onChange={(e) => handleInputBarcode(name, menuItemSettingId, e , content)}
          value={content?.barCodeCustom || ''}
          className={`input-barcode mt-1  w-100`}
        />
        {!_.isEmpty(content?.barCodeCustom) ? ''
          : <div className={`emply-div ${required ? 'idRequired' : ''}`}></div>}
      </div>
    );
  };

  const onChangeImg = (e, name, menuItemSettingId) => {
    changeImage(name, menuItemSettingId, e);
    // pushImage(name, menuItemSettingId, e);
  };

  const onChangeHandler = (e, name, menuItemSettingId) => {
    changeFile(name, menuItemSettingId, e);
    // pushFile(name, menuItemSettingId, e);
  };

  const changeFile = async (name, menuItemSettingId, e) => {
    const file = e?.target?.files[0];
    const fileName = e?.target?.files[0]?.name;
    const value = {
      url: await toBase64(file),
      fileNameNew: fileName,
    };

    const newData = dataActive?.menuItemsSettings?.map((item) => {
      if (item.menuItemSettingId === menuItemSettingId) {
        const newMenuItem = item?.itemType?.map((elm) => {
          if (elm.name === name) {
            return { ...elm, content: { ...elm.content, ...value } };
          } else {
            return elm;
          }
        });
        return { ...item, itemType: newMenuItem };
      } else {
        return item;
      }
    });
    const newDataCurrent = { ...dataActive, menuItemsSettings: newData };
    if (newDataCurrent?.menuItemsSettings) {
      setDataCurrent(_.groupBy(newDataCurrent?.menuItemsSettings || [], 'titleName'));
    }
    pushContent({ newData });
  };

  const changeImage = async (name, menuItemSettingId, e) => {
    const file = e?.target?.files[0];
    const fileName = e?.target?.files[0]?.name;
    const value = {
      content: await toBase64(file),
      fileName,
    };

    await changeContentData(name, menuItemSettingId, value);
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handleCheckImgInpage = ({ name, menuItemSettingId, value , nameInStella = '' }) => {
    if(name === 'フィールド反映') {
      handleCheckImgChildTypeUpdate({ name, menuItemSettingId, value , nameInStella });
    } else {
      handleCheckImgChild({ name, menuItemSettingId, value });
    }
    // handleCheckImg({ name, menuItemSettingId, value })
    // if (value === OPTION_IMG_REPORT.REMOVE) {
    //   Array.from(document.querySelectorAll('input')).forEach((input) => (input.value = ''));
    // }
  };

  const handleCheckImgChildTypeUpdate = ({ name, menuItemSettingId, value , nameInStella }) => {
    const newData = dataActive?.menuItemsSettings?.map((item) => {
      if (item.menuItemSettingId === menuItemSettingId) {
        const newMenuItem = item?.itemType?.map((elm) => {
          if (elm.name === name) {
            if (elm ?.content?.[nameInStella] ?.option ?.length > 0) {
              if (value === OPTION_IMG_REPORT.REMOVE) {
                if (elm ?.content?.[nameInStella] ?.option.includes(OPTION_IMG_REPORT.REMOVE)) {
                  const newOption = elm ?.content?.[nameInStella] ?.option ?.filter(e => e !== OPTION_IMG_REPORT.REMOVE);
                  return { ...elm, content: {...elm.content , [nameInStella] : { option : newOption }} };
                } else {
                  return { ...elm, content: {...elm.content , [nameInStella] : { option : [...elm?.content?.[nameInStella]?.option , value] }} };
                }
              } else {
                if (elm ?.content?.[nameInStella]?.option.includes(OPTION_IMG_REPORT.NOT_ALLOWED)) {
                  const newOption = elm ?.content?.[nameInStella] ?.option ?.filter(e => e !== OPTION_IMG_REPORT.NOT_ALLOWED);
                  return { ...elm, content: {...elm.content , [nameInStella] : {...elm?.content?.[nameInStella], option : newOption }} };
                } else {
                  return { ...elm, content: {...elm.content , [nameInStella] : { option : [...elm?.content?.[nameInStella]?.option , value] }} };
                }
              }
            } else {
              if (value === OPTION_IMG_REPORT.REMOVE) {
                return { ...elm, content: {...elm.content , [nameInStella] : { option : [value]}} };
              } else {
                return { ...elm, content: {...elm.content , [nameInStella] : { ...elm?.content?.[nameInStella] ,option : [value] }} };
              }
            }
          } else {
            return elm;
          }
        });
        return { ...item, itemType: newMenuItem };

      } else {
        return item;
      }
    });
    const newDataCurrent = { ...dataActive, menuItemsSettings: newData };
    if (newDataCurrent?.menuItemsSettings) {
      setDataCurrent(_.groupBy(newDataCurrent?.menuItemsSettings || [], 'titleName'));
    };
    pushContent({ newData });
  };

  const handleCheckImgChild = ({ name, menuItemSettingId, value }) => {
    const newData = dataActive?.menuItemsSettings?.map((item) => {
      if (item.menuItemSettingId === menuItemSettingId) {
        const newMenuItem = item?.itemType?.map((elm) => {
          if (elm.name === name) {
            if (elm ?.content ?.option ?.length > 0) {
              if (value === OPTION_IMG_REPORT.REMOVE) {
                if (elm ?.content ?.option.includes(OPTION_IMG_REPORT.REMOVE)) {
                  const newOption = elm ?.content ?.option ?.filter(e => e !== OPTION_IMG_REPORT.REMOVE);
                  if (elm?.name === 'バーコード識別') {
                    return {
                      ...elm,
                      content: {
                        option: newOption,
                      },
                    };
                  } else {
                    return { ...elm, content: { option: newOption, content: '' } };
                  }
                } else {
                  if (elm?.name === 'バーコード識別') {
                    return {
                      ...elm,
                      content: {
                        option: [...elm?.content?.option, value],
                      },
                    };
                  } else {
                    return { ...elm, content: { option: [...elm?.content?.option, value], content: '' } };
                  }
                }
              } else {
                if (elm ?.content ?.option.includes(OPTION_IMG_REPORT.NOT_ALLOWED)) {
                  const newOption = elm ?.content ?.option ?.filter(e => e !== OPTION_IMG_REPORT.NOT_ALLOWED);
                  return { ...elm, content: { ...elm?.content, option: newOption } };
                } else {
                  return { ...elm, content: { ...elm?.content, option: [...elm ?.content ?.option, value] } };
                }
              }
            } else {
              if (value === OPTION_IMG_REPORT.REMOVE) {
                if (elm?.name === 'バーコード識別') {
                  return {
                    ...elm,
                    content: {
                      option: [value],
                    },
                  };
                } else {
                  return { ...elm, content: { content: '', option: [value] } };
                }
              } else {
                return { ...elm, content: { ...elm?.content, option: [value] } };
              }
            }
          } else {
            return elm;
          }
        });
        return { ...item, itemType: newMenuItem };

      } else {
        return item;
      }
    });
    const newDataCurrent = { ...dataActive, menuItemsSettings: newData };
    if (newDataCurrent?.menuItemsSettings) {
      setDataCurrent(_.groupBy(newDataCurrent?.menuItemsSettings || [], 'titleName'));
    };
    pushContent({ newData });
  };

  const renderFormImage = (content, name, menuItemSettingId, required) => {
    return (
      <div className="form-upload-img">
        <div className="form-img">
          <label htmlFor={`${menuItemSettingId}-img`} ><i className="fas fa-camera"></i></label>

          <div className="form-checkbox">
            <label>
              <input type="checkbox" name={OPTION_IMG_REPORT.REMOVE}
                checked={content?.option?.includes(OPTION_IMG_REPORT.REMOVE) || false}
                onChange={() => handleCheckImgInpage({ name, menuItemSettingId, value: OPTION_IMG_REPORT.REMOVE })}
              />
              写真削除</label>
            <label>
              <input type="checkbox" name={OPTION_IMG_REPORT.NOT_ALLOWED}
                checked={content?.option?.includes(OPTION_IMG_REPORT.NOT_ALLOWED) || false}
                onChange={() => handleCheckImgInpage({ name, menuItemSettingId, value: OPTION_IMG_REPORT.NOT_ALLOWED })}
              />
            写真撮影不可</label>
          </div>
        </div>
        <input
          type="file"
          id={`${menuItemSettingId}-img`}
          accept="image/*"
          disabled={!alloweHandleReport}
          className="input-upload"
          onChange={(e) => onChangeImg(e, name, menuItemSettingId)}
        />
        <textarea onChange={e => changeCommentChild({ name, menuItemSettingId, value: e.target.value })} value={content?.comment}></textarea>
        {!_.isEmpty(content?.content) ? <img
          id={`${menuItemSettingId}-img`}
          src={content?.content} />
          : <div className={`emply-div ${required ? 'idRequired' : ''}`}></div>}
      </div>
    )
  };

  const renderSignature = (content, name, menuItemSettingId, required) => {
    return (
      <div className="form-signature">
        <label>
          当日のお立会者様にサインをいただいてください。
        </label>
        {content ? <img id={menuItemSettingId} src={content} />
          : <div className={`emply-div ${required ? 'idRequired' : ''}`}></div>}
        <button
          className="btn-open-signature"
          id={menuItemSettingId}
          disabled={!alloweHandleReport}
          onClick={() => toggleCloseModalSignature(name, menuItemSettingId)}>サインを書く
        </button>

        <label className="red">
          ※サイン実施前、変更内容を保存実施してください。
        </label>
        <label>
          ※サインが表示されない場合は、ブラウザをリロードして下さい。
        </label>
      </div>
    )
  };

  const renderAttachmentFile = (content, name, menuItemSettingId, required) => {
    return (
      <>
        {content?.fileName}<br />
        <a
          name="report_download"
          id={menuItemSettingId}
          href={content?.content}
          className="download_btn_report"
          data-role="none"
          download>ダウンロード
        </a>

        <div className="ui-input-text ui-body-inherit ui-corner-all ui-shadow-inset input-upload" style={{ width: '50%' }}>
          <input
            type="file"
            name="file"
            id={`${menuItemSettingId}-upload-csv`}
            className={(required && !content) ? 'idRequired' : ''}
            accept=""
            disabled={!alloweHandleReport}
            onChange={(e) => onChangeHandler(e, name, menuItemSettingId)} />
        </div>
      </>

    );
  };

  const pushTrimData = (data) => {
    setModalSignature(!modalSignature);
    changeContentData(nameSelected, menuItemSettingIdSelected, data);
    // pushContent(nameSelected, menuItemSettingIdSelected, data);
  };

  const handleReportOrder = ({ confirm }) => {
    const newDateMenuItemsSettings = dataActive.menuItemsSettings?.map((item) => {
      if (item?.itemType[0]?.name === WORKER_TYPE.ATTACHMENT_FILE) {
        return {
          ...item, itemType: [
            {
              ...item?.itemType[0],
              content: {
                content: item?.itemType[0]?.content?.url,
                fileName: item?.itemType[0]?.content?.fileNameNew,
                key: item?.itemType[0]?.content?.key,
                name: item?.itemType[0]?.content?.name,
              },
            }
          ],
        }
      }
      if(item?.itemType[0]?.name === WORKER_TYPE.FORM_INPUT_DATETIME) {
        return {
          ...item , itemType : [
            {
              ...item?.itemType[0],
              content: item?.itemType[0]?.content
            }
          ],
        }
      }
      else {
        return item;
      }
    });

    const newData = new FormData();
    newData.append('menuSettingId', dataActive.menuSettingId);
    newData.append('menuItemsSettings', JSON.stringify(newDateMenuItemsSettings));
    newData.append('menuName', menuName);
    newData.append('updateTitle', `完了 ${dataActive?.name || ''}`);
    newData.append('companyName', localStorage.getItem('companyName'));
    newData.append('screenName', screenName || '事前確認');

    if (confirm) {
      onReportOrder({ body: newData })
    } else {
      newData.append('statusSave', false);
      onReportOrderNotConfirm({ body: newData })
    }
  };

  const handleData = ({ data }) => {
    if (data && data.code !== 0) {
      if (data && data.message && data.message.extra) {
        pushToast('error', data?.message?.extra);
      } else {
        pushToast('error', data?.message?.errorMessage);
      }
    } else {
      pushToast('success', 'Report Order success');
      getDetailAgain()
      onReportOrderSuccess(data?.data);
    }
  };

  const onReportOrder = async ({ body }) => {
    try {
      const data = await STAFF_ENDPOINT.UPDATE_WORKER_REPORT({ body, id_order: orderId, organizationId });
      handleData({ data })
    } catch (e) {
      console.log(e);
    }
  };

  const onReportOrderNotConfirm = async ({ body }) => {
    setTemporary()
    try {
      const data = await STAFF_ENDPOINT.UPDATE_WORKER_REPORT_NOT_CONFIRM({ body, id_order: orderId, organizationId });
      handleData({ data })
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="detail-report-attacment">
      {
        Object.keys(dataCurrent || dataMenu)?.map(key => {
          return <div className='table__reponsive'>
            <table className="basictab " cellSpacing="0" cellPadding="0" width='100%'>
              <tbody>
                <tr>
                  <th>{key || ''}</th>
                </tr>
                <tr>
                  <td>
                    <table className="basictab" cellSpacing="0" cellPadding="0" width="100%">
                      <colgroup>
                        <col width="5%" />
                        <col width="15%" />
                        <col width="45%" />
                        <col width="25%" />
                      </colgroup>
                      <tbody>
                        <tr>
                          <th className="text-center" scope="col">No</th>
                          <th className="text-center" scope="col">区分</th>
                          <th className="text-center" scope="col">内容</th>
                          <th className="text-center" scope="col">指示内容</th>
                          <th className="text-center big-col" scope="col" >操作</th>
                        </tr>

                        {
                          (dataCurrent || dataMenu)?.[key]?.length > 0 ? (dataCurrent || dataMenu)?.[key]?.map((item, index) => {
                            if (item?.itemType?.length > 0) {
                              return (
                                item?.itemType?.map((elm, i) => {
                                  if (i === 0) {
                                    return (
                                      <tr key={i}>
                                        <td rowSpan={item?.itemType?.length}>{index + 1}</td>
                                        <td rowSpan={item?.itemType?.length}>{item?.type}</td>
                                        <td rowSpan={item?.itemType?.length}>{item?.content}</td>
                                        <td rowSpan={item?.itemType?.length}>{item?.itemName}</td>
                                        <td >{renderItemTypeForm({
                                          name: elm?.name,
                                          content: elm?.content,
                                          menuItemSettingId: item?.menuItemSettingId,
                                          required: item?.required,
                                          value: elm?.value,
                                        })}</td>
                                      </tr>
                                    )
                                  } else {
                                    return (
                                      <tr key={index + i}>
                                        <td>{renderItemTypeForm({
                                          name: elm?.name,
                                          content: elm?.content,
                                          menuItemSettingId: item?.menuItemSettingId,
                                          required: item?.required,
                                          value: elm?.value,
                                        })}</td>
                                      </tr>
                                    )
                                  }
                                }))
                            } else {
                              return (
                                <tr>
                                  <td>{index + 1}</td>
                                  <td>{item?.titleName}</td>
                                  <td>{item?.type}</td>
                                  <td>---</td>
                                  <td>{item?.itemName}</td>
                                  <td>{item?.content}</td>
                                  <td>{item?.required}</td>
                                </tr>
                              )
                            }
                          }) : ''}
                      </tbody>
                    </table>
                    <input type="hidden" name="ankenmaster_id" value="1" />
                  </td>
                </tr>
              </tbody>
            </table>
            <table className="table__iphone w-100 ">
              <tr>
                <th colSpan="3" className="table__iphone--background">
                  {key || ''}
                </th>
              </tr>
              {(dataCurrent || dataMenu)?.[key]?.length > 0 ? (dataCurrent || dataMenu)?.[key]?.map((item, index) => {
                if (item?.itemType?.length > 0) {
                  return item?.itemType?.map((elm, i) => {
                    return (
                      <>
                        <tr key={i} className='w-100'>
                          <td rowSpan="3" className="text-center table__iphone--background table__iphone--header">
                            {index + 1}
                          </td>
                          <td className='table__iphone--children table__iphone--td'>
                            <div className="text-center table__iphone--color w-100 ">区分</div>
                            <div>{item?.type}</div>
                          </td>
                          <td className='table__iphone--td' >
                            <div className="text-center table__iphone--color w-100">内容</div>
                            <div>{item?.content}</div>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="2" className='table__iphone--td'>
                            <div className="text-center table__iphone--color w-100 ">指示内容</div>
                            <div>{item?.itemName}</div>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="2" className='table__iphone--td'>
                            <div className="text-center table__iphone--color w-100 ">操作</div>
                            <div>{renderItemTypeForm({
                              name: elm?.name,
                              content: elm?.content,
                              menuItemSettingId: item?.menuItemSettingId,
                              required: item?.required,
                              value: elm?.value,
                            })}</div>
                          </td>
                        </tr>
                      </>
                    );
                  });
                }
              })
                : ''}
            </table>
          </div>
        })
      }
      <div align="center">
        {
          alloweHandleReport && (
            <input type="button" className="submitbutton btn-worker-report-order" value="完了"
              disabled={!dataActive?.menuSettingId || statusDisableRequired}
              onClick={() => handleReportOrder({ confirm: true })}
            />
          )
        }
      </div>
      {modalSignature && <ModalSignature
        modal={modalSignature}
        toggle={toggleCloseModalSignature}
        pushTrimData={pushTrimData} />}
    </div>
  )
};

export default ReportAttachment;
