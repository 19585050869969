import React, { useEffect, useState } from 'react';
import { translate } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useHistory, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import '../../../../assets/scss/page/contract/index.scss';
import Footer from '../../../../components/Footer';
import HeaderMasterSetting from '../../../../components/HeaderMasterSetting';
import PaginationCustom from '../../../../components/PaginationCustom';
import { CONTRACT_ENDPOINTS } from '../../../../constants/endpoints';
import { getNoOrder } from '../../../../helpers/helpers';
import _ from 'lodash';
const ListCompletion = ({ t }) => {
  const colorScreen = useSelector((store) => store.utils.colorMe);
  const history = useHistory();
  const [listData, setListData] = useState();
  const [activePage, setActivePage] = useState(1);

  const onReturn = () => {
    history.goBack();
  };

  const getListRecruitment = async ({ page = 1, limit = 10 }) => {
    try {
      const data = await CONTRACT_ENDPOINTS.GET_LIST_RECRUITMENT({
        page: page,
        limit: limit,
      });
      if (data) {
        setListData(data?.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getListRecruitment({ page: activePage });
  }, [activePage]);

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const renderItem = (item, index, activePage) => {
    return (
      <tr key={index}>
        <td>{getNoOrder({ index: index, limit: 10, activePage: activePage })}</td>
        <td>{item?.monitor}</td>
        <td>{item?.project}</td>
        <td>{item?.product}</td>
        <td>{item?.areas?.kansai?.[0]}</td>
        <td>{item?.createdAt}</td>
        <td>{item?.startTimeRecruitment}</td>
        <td>{item?.endTimeRecruitment}</td>
        <td>
          {item?.startTimeBusiness} - {item?.endTimeBusiness}
        </td>
        <td>{item?.status}</td>
      </tr>
    );
  };

  return (
    <>
      <div className="main">
        <HeaderMasterSetting color={colorScreen?.code} colorText={colorScreen?.colorText} checkName="/contract" />
        <div className="block-contract">
          <div className="list-table">
            <button className="btn-back" onClick={onReturn}>
              戻る
            </button>
            <div className="table-list-contract">
              <div className="title-recruitment">募集一覧</div>
              <div className="list-button-contact d-flex justify-content-end">
                <Link to="#" className="item">
                CSV出力
                </Link>
              </div>
              <div className="block-search">
                <label>検索条件</label>
                <input type="text" />
                <Link to="#" className="item ml-2">
                  検索
                </Link>
              </div>
              <table className="table table-bordered">
                <thead>
                  <tr>
                  <th>No</th>
                    <th>{t(`accounting_header.product`)}</th>
                    <th>{t(`accounting_header.project`)}</th>
                    <th>{t(`accounting_header.acceptance_month`)}</th>
                    <th>{t(`accounting_header.local_basic`)}</th>
                    <th>{t(`accounting_header.Local_follow_up`)}</th>
                    <th>{t(`accounting_header.local_department`)}</th>
                    <th>{t(`accounting_header.far_from_the_site`)}</th>
                    <th>{t(`accounting_header.order_number`)}</th>
                    <th>{t(`accounting_header.State`)}</th>
                  </tr>
                </thead>
                <tbody>{listData && listData?.data.map((list, index) => renderItem(list, index, activePage))}</tbody>
              </table>
            </div>
            {!_.isEmpty(listData?.data) && listData?.count > 10 && (
              <PaginationCustom
                activePage={activePage}
                itemsCountPerPage={10}
                totalItemsCount={listData?.count}
                pageRangeDisplayed={5}
                handlePageChange={handlePageChange}
              />
            )}
          </div>
        </div>
        <Footer color={colorScreen?.code} colorText={colorScreen?.colorText} />
      </div>
    </>
  );
};

export default compose(translate('translations'), withRouter)(ListCompletion);
