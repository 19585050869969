import ModalConfirm from 'components/ModalConfirm';
import { pushToast } from 'components/Toast';
import { PLUGIN_SETTING } from 'constants/endpoints';
import { genId } from 'helpers/helpers';
import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { SCREEN_NAME } from '../../../../helpers/constants';

const ModalConfirmItemSetting = (props) => {
  const [color, setColor] = useState('');
  const [idRole, setIdRole] = useState('');
  const [listItem, setListItem] = useState([]);
  const [listOptionsType, setListOptionsType] = useState([]);
  const [itemDelete, setItemDelete] = useState({});
  const [modalConfirm, setModalConfirm] = useState(false);
  const { dataSetting, colors, provider, idProvider, nameSetting, keyName, idProvide, dataOrigin, pluginSettingId, productId, nameProduct, } = props;
  const [dataOrigins, setDataOrigins] = useState(dataOrigin);
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const toggle = (item) => {
    setModalConfirm(!modalConfirm);
    setItemDelete(item);
  };

  const handleDeleteWorkerItemSettings = async (item) => {
    try {
      const data = await PLUGIN_SETTING.DELETE_DETAIL_SETTING(provider, idProvider, nameSetting, itemDelete?.detailId, idProvide, SCREEN_NAME.MY_CORP, productId);
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
      } else {
        pushToast('success', 'delete success');
        data.data.Attributes.pluginSettings.map(item => {
          (item.name === keyName.parentName) && item.extraData.map(i => {
            (i.name === keyName.chilName) && i.option.map(it => {
              if(it.name === nameSetting) {
                const newSettings = (it?.settingDetail || [])?.map((elm) => {
                  return { ...elm, keyId: genId() };
                });
                setListItem(sortableList(newSettings));
              }
            })
          })
        });
        toggle();
      }
    } catch (errorMessage) {
      console.log('errorMessage: ', errorMessage);
    }
  };

  const handleConfirm = () => {
    handleDeleteWorkerItemSettings();
  };

  const changeListSelect = (e, detailId, keyId='') => {
    const value = e.target.value;
    let newList = listItem?.map((item) => {
      const objValue = { name: value, sortNum: item?.type?.length > 0 ? item?.type?.length + 1 : 1 };
      if (item.keyId === keyId) {
        const listOptionChecked = item?.type?.map((item) => item.name);
        if (listOptionChecked?.length > 0) {
          let newItemtype = [];
          if (listOptionChecked.includes(objValue.name)) {
            newItemtype = item?.type?.filter((item) => item !== objValue.name);

            return { ...item, type: newItemtype };
          } else {
            return { ...item, type: [...item.type, objValue] };
          }
        } else {
          return { ...item, type: [objValue] };
        }
      } else {
        return item;
      }
    });
    setListItem(newList);
  };

  const deleteListItemSelect = (value, id) => {
    const newList = listItem?.map((item) => {
      if (item.detailId === id) {
        let newItemType = item?.type.filter((item) => item.name !== value);
        return { ...item, type: newItemType };
      } else {
        return item;
      }
    });
    setListItem(newList);
  };

  const getListWorkerItemSettings = async (dataSetting) => {
    try {
      const data = dataSetting
      if (data) {
        const newData = data?.map((item) => {
          return { ...item, keyId: genId() };
        });
        setListItem(sortableList(newData));
        setListOptionsType([{name: 'ドライバー', sortNum: 1}, {name: 'ラジオペンチ', sortNum: 2}, {name: '試験用端末', sortNum: 3}]);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    setColor(colors);
    localStorage.setItem('color', colors);
  }, [idRole]);

  useEffect(() => {
    setDataOrigins(dataOrigin);
  }, [dataOrigin]);

  useEffect(() => {
    const newRole = localStorage.getItem('role');
    setIdRole(newRole);
    getListWorkerItemSettings(dataSetting);
  }, []);


  const deleteListItem = (item) => {
    if (!item.hasOwnProperty('detailId')) {
      setListItem(listItem.filter(elm => elm.keyId !== item.keyId));
    } else {
      toggle(item);
    }
  };

  const addListItem = (index) => {
    const newList = [...listItem,
      {
        status: false,
        classification: '',
        type: [],
        name: '',
        content: '',
        temp: '',
        keyId: genId(),
      }];
    setListItem(newList);
  };

  const handleChange = (e, detailId, keyId='') => {
    const valueField = e.target.value;
    const nameField = e.target.name;
    const newData = listItem?.map((item) => {
      if (item.detailId && item.detailId === detailId) {
        return { ...item, [nameField]: valueField };
      } else if(!item.detailId && keyId === item?.keyId){
        return { ...item, [nameField]: valueField };
      } else {
        return item;
      }
    });
    setListItem(newData);
  };

  const handleChangeCheckBox = (detailId) => {
    const newData = listItem?.map((item) => {
      if (item.detailId === detailId) {
        return { ...item, status: !item.status };
      } else {
        return item;
      }
    });
    setListItem(newData);
  };

  const editConfirmItemSettings = async (body) => {
    try {
      const newBody = body?.settingDetail?.map(i => {
        delete i.keyId
        return i
      });
      const data = await PLUGIN_SETTING.UPDATE_DETAIL_SETTING({
        body: {settingDetail: newBody  , screenName : SCREEN_NAME.MY_CORP}, pluginSettingId, nameSetting, idProvide
      });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
      } else {
        data.data.Attributes.pluginSettings.map(item => {
          (item.name === keyName.parentName) && item.extraData.map(i => {
            (i.name === keyName.chilName) && i.option.map(it => {
              if(it.name === nameSetting) {
                const newSettings = (it?.settingDetail || [])?.map((elm) => {
                  return { ...elm, keyId: genId() };
                });
                setListItem(sortableList(newSettings));
              }
            })
          })
        });
        pushToast('success', 'Edit Detail Setting Success');
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleEditConfirmItemSettings = () => {
    const newListItem = listItem?.map((item, index) => {
      let newItemtype = item?.type?.map((elm, i) => {
        return {
          name: elm.name,
          sortNum: i + 1,
        };
      });
      if (!item.hasOwnProperty('detailId')) {
        delete item.detailId;
        return { ...item, sortNum: index + 1, type: newItemtype };
      } else {
        return {
          classification: item?.classification,
          name: item?.name,
          content: item?.content,
          status: item?.status,
          temp: item?.temp,
          sortNum: index + 1,
          type: newItemtype,
          detailId: item?.detailId,
        };
      }
    });

    const body = {
      settingDetail: newListItem,
      screenName : SCREEN_NAME.MY_CORP
    };
    editConfirmItemSettings(body);
  };

  const sortableList = (items) => {
    items.sort(function (a, b) {
      return a.sortNum - b.sortNum;
    });
    return items
  };

  const showListVersion = () => {
    return (
      listItem &&
      listItem.map((item, index) => {
        return (
          <Draggable key={`${index + 1}`} draggableId={`${index + 1}`} index={index}>
            {(provided, snapshot) => (
              <tr
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                className="item-worker"
                key={index}
              >
                <td className="table-setting__th-setting--resize-100">
                  <div
                    className="icon"
                    style={{ borderColor: `${color}`, color: `${color}` }}
                    onClick={() => deleteListItem(item)}
                  >
                    <i className="fas fa-times"></i>
                  </div>
                </td>
                <td className="table-setting__th-setting--resize-100">
                  <input
                    type="text"
                    className="text-input"
                    name="classification"
                    value={item.classification}
                    style={{ borderColor: `${color}`, color: `${color}` }}
                    onChange={(e) => handleChange(e, item.detailId, item?.keyId || '' )}
                  />
                </td>
                <td className="table-setting__th-setting--resize-100">
                  <input
                    type="text"
                    className="text-input"
                    name="temp"
                    value={item.temp}
                    style={{ borderColor: `${color}`, color: `${color}` }}
                    onChange={(e) => handleChange(e, item.detailId, item?.keyId || '')}
                  />
                </td>
                <td className="table-setting__th-setting--resize-100">
                  <input
                    type="text"
                    className="text-input"
                    name="name"
                    value={item.name}
                    style={{ borderColor: `${color}`, color: `${color}` }}
                    onChange={(e) => handleChange(e, item.detailId, item?.keyId || '')}
                  />
                </td>
                <td className="table-setting__th-setting--resize-100">
                  <DragDropContext onDragEnd={(e) => onDragEnd2(item, e)}>
                    <Droppable droppableId="droppable">
                      {(provided, snapshot) => (
                        <div className="list-select" {...provided.droppableProps} ref={provided.innerRef}>
                          <select
                            name="type"
                            className="text-input select"
                            id="type"
                            style={{ borderColor: `${color}`, color: `${color}` }}
                            value="--"
                            onChange={(e) => changeListSelect(e, item.detailId, item.keyId)}
                          >
                            {listOptionsType?.map((item, index) => (
                              <option value={item.name} key={index}>
                                {item.name}
                              </option>
                            ))}
                            <option value="--"></option>
                          </select>
                          {item?.type?.map((elm, index) => (
                            <Draggable key={`${index + 1}`} draggableId={`${index + 1}`} index={index}>
                              {(provided, snapshot) => (
                                <div
                                  key={index}
                                  className="form-selected"
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <div
                                    className="icon"
                                    style={{ borderColor: `${color}`, color: `${color}` }}
                                    onClick={() => deleteListItemSelect(elm.name, item.detailId)}
                                  >
                                    <i className="fas fa-times"></i>
                                  </div>
                                  <div className="item-selected" style={{ borderColor: `${color}`, color: `${color}` }}>
                                    {elm.name}
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          ))}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </td>
                <td className="table-setting__th-setting--resize-100">
                  <input
                    type="text"
                    className="text-input"
                    name="content"
                    value={item.content}
                    style={{ borderColor: `${color}`, color: `${color}` }}
                    onChange={(e) => handleChange(e, item.detailId, item?.keyId || '')}
                  />
                </td>

                <td className="table-setting__th-setting--resize-100">
                  <label className="container-checkbox">
                    <input
                      type="checkbox"
                      name="status"
                      defaultChecked={item.status}
                      onChange={() => handleChangeCheckBox(item.detailId)}
                    />
                    <span className="checkmark"></span>
                  </label>
                </td>
              </tr>
            )}
          </Draggable>
        );
      })
    );
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const items1 = reorder(listItem, result.source.index, result.destination.index);
    setListItem(items1);
  };

  const onDragEnd2 = (item, result) => {
    if (!result.destination) {
      return;
    }
    const arrTmp = listItem.map((itemList) => {
      if (itemList.detailId === item.detailId) {
        const items1 = reorder(itemList.type, result.source.index, result.destination.index);
        return { ...itemList, type: items1 };
      } else {
        return itemList;
      }
    });
    setListItem(arrTmp);
  };

  return (
    <div className="wrapper-master-setting wrapper-master-setting--max-width">
      <div className="body pb-4">
        <div className="wrapper-main">
          <div className="wrapper-result">
            <table className="form-worker">
              <thead>
                {listItem?.length > 0 && (
                  <tr>
                    <th className="text-center table-setting__th-setting--resize-100" scope="col"></th>
                    <th className="text-center table-setting__th-setting--resize-100" scope="col">見出名</th>
                    <th className="text-center table-setting__th-setting--resize-100" scope="col">区分</th>
                    <th className="text-center table-setting__th-setting--resize-100" scope="col">項目名</th>
                    <th className="text-center table-setting__th-setting--resize-100" scope="col">項目種類</th>
                    <th className="text-center table-setting__th-setting--resize-100" scope="col">内容</th>
                    <th className="text-center table-setting__th-setting--resize-100" scope="col">必須</th>
                  </tr>
                )}
              </thead>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <tbody {...provided.droppableProps} ref={provided.innerRef}>
                      {showListVersion()}
                      <tr className="item-worker">
                        <td className="item-worker--pdf0">
                          <div
                            className="icon"
                            style={{ borderColor: `${color}`, color: `${color}` }}
                            onClick={() => addListItem(listItem?.length)}
                          >
                            <i className="fas fa-plus"></i>
                          </div>
                        </td>
                      </tr>
                      {provided.placeholder}
                    </tbody>
                  )}
                </Droppable>
              </DragDropContext>
            </table>
          </div>
          <div className="d-flex justify-content-end align-items-center group-button">
            <button style={{ backgroundColor: `${color}` }} onClick={() => handleEditConfirmItemSettings()}>
            保存
            </button>
          </div>
        </div>
      </div>
      <ModalConfirm toggle={toggle} modal={modalConfirm} handleConfirm={handleConfirm} />
    </div>
  );
};

export default ModalConfirmItemSetting;
