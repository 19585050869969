import React, { useEffect, useState } from 'react';
import { translate } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useHistory, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import '../../../../assets/scss/page/contract/index.scss';
import Footer from '../../../../components/Footer';
import HeaderMasterSetting from '../../../../components/HeaderMasterSetting';
import Loading from '../../../../components/Loading/Loading';
import PaginationCustom from '../../../../components/PaginationCustom';
import { CONTRACT_ENDPOINTS } from '../../../../constants/endpoints';
import CollapItem from './CollapItem';
import { CSVLink } from 'react-csv';
import FooterMaintenance from "components/FooterMaintenance"

const QuotationContract = (props) => {
  const colorScreen = useSelector((store) => store.utils.colorMe);
  const history = useHistory();
  const [hideshow, setHideshow] = useState('');
  const [dataListEstimate, setDataListEstimate] = useState({});
  const [activePage, setActivePage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [monitorId, setMonitorId] = useState('');
  const [defaultCsv, setDefaultCsv] = useState('');

  const handleHide = (id) => {
    if (hideshow === id) {
      setHideshow('');
    } else {
      setHideshow(id);
    }
  };

  useEffect(() => {
    getListEstimate({ page: activePage, limit: 10, pageContract: 1, limitContract: 10, sortProductId : '' });
    setHideshow('');
  }, [activePage]);


  const getListEstimate = async ({ page, limit, pageContract, limitContract , sortProductId = '' }) => {
    setLoading(true);
    try {
      const data = await CONTRACT_ENDPOINTS.GET_LIST_CONTRACT_RECRUITMENT({
        page,
        limit,
        pageContract,
        limitContract,
        sortProductId : sortProductId
      });
      if (data?.data) {
        setDataListEstimate(data?.data);
      }
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const getList = async () => {
    setLoading(true);
    try {
      const data = await CONTRACT_ENDPOINTS.GET_DATA_CSV_CONTRACT_BY_PRODUCT();
      if (data?.data) {
        setDefaultCsv(data?.data)
        document.getElementById(`download-csv`).click();
      }
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const handleChangePage = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const onDownLoadCSV = (e) => {
    getList();
    e.stopPropagation();
  };

  const onDownload = (e) => {
    e.stopPropagation();
  };

  return (
    <>
      <div className="main">
        <Loading loading={loading} />
        <HeaderMasterSetting color={colorScreen?.code} colorText={colorScreen?.colorText} checkName="/contract" />
        <div className="block-contract">
          <div className="list-table">
            <Link to="/contract" className="btn-back">
              戻る
            </Link>
            <div className="table-list-contract">
              <div className="title-recruitment">見積一覧</div>
              <div className="list-button-contact d-flex justify-content-between">
                {/* <Link to="/contract/master-tarif" className="item">
                  タリフマスタ
                </Link> */}
                <div>
                </div>
                <div className="list-button-contact d-flex justify-content-end">
                  <div className="ml-2" style={{ color: `${colorScreen?.colorText}` }}>
                    <span className="item" onClick={onDownLoadCSV}>
                      CSV出力{' '}
                    </span>
                    <CSVLink className="text-decoration-none" data={defaultCsv} filename="default-csv.csv">
                      <span onClick={onDownload} id={`download-csv`}></span>
                    </CSVLink>
                  </div>
                </div>
              </div>
              <div className="block-search">
                <label>検索条件</label>
                <input type="text" />
                <Link to="#" className="item ml-2">
                  検索
                </Link>
              </div>
              <div className="list-table">
                {dataListEstimate?.data?.map((item, index) => {
                  return (
                    <CollapItem
                      item={item}
                      key={index}
                      colors={colorScreen?.code}
                      colorText={colorScreen?.colorText}
                      handleHide={handleHide}
                      hideshow={hideshow}
                      getListEstimate={getListEstimate}
                      activePageContract={activePage}
                    />
                  );
                })}
                {dataListEstimate?.count > 10 && (
                  <PaginationCustom
                    activePage={activePage}
                    itemsCountPerPage={10}
                    totalItemsCount={dataListEstimate?.count}
                    pageRangeDisplayed={5}
                    handlePageChange={handleChangePage}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <FooterMaintenance/>
      </div>
    </>
  );
};

export default compose(translate('translations'), withRouter)(QuotationContract);
