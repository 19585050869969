import { ACTIONS } from '../actions/order';

export const userState = {
  listOrder: null,
};

export default (state = userState, action) => {
  switch (action.type) {
    case ACTIONS.SAVE_LIST_ORDER:
      return {
        ...state,
        listOrder: action.listOrder,
      };
    default:
      return state;
  }
};
