import React from 'react';
import DateTimePicker from 'components/DateTimePicker';
import moment from 'moment';
import './style.scss'

const QualificationList = ({ userData, handleChangeQualificationText, handleRemote, addNewItem }) => {

  return (
    <ul className="qualification-list">
      <li className="qualification-list__item qualification-list__item-header">
        <div className="qualification first-header">資格</div>
        <div className="licenseNumber">免許番号</div>
        <div className="grade">等級</div>
        <div className="validity-period-from">有効期間From</div>
        <div className="validity-period-to">有効期間To</div>
      </li>
      {userData?.getQualified.map((item, index) => {
        return (
          <li className="qualification-list__item" key={index}>
            <div className="qualification">
              <button className="btn icon m-1" onClick={() => handleRemote(index)}>
                <i className="fas fa-times"></i>
              </button>
              <input
                name="qualifications"
                value={item.qualifications}
                onChange={(e) => handleChangeQualificationText({ e, item, index })}
              />
            </div>
            <div className="licenseNumber">
              <input
                name="licenseNumber"
                value={item.licenseNumber}
                onChange={(e) => handleChangeQualificationText({ e, item, index })}
              />
            </div>
            <div className="grade">
              <input
                name="grade"
                value={item.grade}
                onChange={(e) => handleChangeQualificationText({ e, item, index })}
              />
            </div>
            <div className="validity-period-from">
              <DateTimePicker
                date={item?.validityPeriodFrom ? new Date(item?.validityPeriodFrom) : null}
                onChangeDate={(date) => {
                  handleChangeQualificationText({
                    item,
                    index,
                    name: 'validityPeriodFrom',
                    value: date ? moment(date).format('YYYY/MM/DD') : '',
                  });
                }}
              />
            </div>
            <div className="validity-period-to">
              <DateTimePicker
                date={item?.validityPeriodTo ? new Date(item?.validityPeriodTo) : null}
                onChangeDate={(date) => {
                  handleChangeQualificationText({
                    item,
                    index,
                    name: 'validityPeriodTo',
                    value: date ? moment(date).format('YYYY/MM/DD') : '',
                  });
                }}
              />
            </div>
          </li>
        );
      })}
      <li>
        <button className="btn icon border rounded mx-1" onClick={addNewItem}>
          <i className="fas fa-plus"></i>
        </button>
      </li>
    </ul>
  );
};

export default QualificationList;
