import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import 'assets/scss/page/AnkenMasterNew/ModalCreateProduct.scss';

import { pushToast } from 'components/Toast';
import { ANKEN_MASTER_NEW, CLIENT_ENDPOINT } from 'constants/endpoints';
import { SCREEN_NAME } from '../../../../helpers/constants';
import _ from 'lodash';

const ModalCreateProduct = (props) => {
  const { toggle = () => { }, modal = false, createSuccess , idClientModal , listDataProject, nameTitle } = props;
  const [nameProduct, setNameProduct] = useState('');
  const [nameProject,setNameProject] = useState('');
  const [allClient, setAllClient] = useState([]);
  const [client, setClient] = useState('');

  useEffect(() => {
    getAllClient();
  }, []);

  useEffect(() => {
    if (!modal) {
      setNameProduct('');
    }
  }, [modal]);

  const getAllClient = async () => {
    try {
      const data = await CLIENT_ENDPOINT.GET_ALL_CLIENT_ORGANIZATION();
      if (data) {
        const tmpArr = data?.data?.map((item) => {
          return {
            clientId: item.clientId,
            clientName: item.nameKana,
            fullName: `${item.clientId} - ${item.officialName} - ${item.officialNameKana}`,
          };
        });
        setAllClient(tmpArr || []);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleChangeProject = (e) => {
    if (e.target.value !== '---') {
      setNameProject(e.target.value);
    }
  };

  const changeNameProduct = (e) => {
    const value = e.target.value;
    setNameProduct(value);
  };

  const createProduct = () => {
    const body = {
      name: nameProduct?.trim(),
      clientId: client || idClientModal,
      screenName : SCREEN_NAME.CASE_MASTA,
      projectCode: nameProject,
      updateTitle: `登録 工事名新規登録 ${nameTitle ? nameTitle : ''}`,
    }
    handleCreateProduct({ body: !_.isEmpty(nameProject) ? body : _.pick(body, ['name', 'clientId', 'screenName', 'updateTitle']) });
  };

  const handleChangeClient = (e) => {
    if (e.target.value !== '---') {
      setClient(e.target.value);
    }
  };

  const handleCreateProduct = async ({body}) => {
    try {
      const data = await ANKEN_MASTER_NEW.CREATE_PRODUCT({ body });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
      } else {
        pushToast('success', 'Create Product success');
        createSuccess();
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div>
      <Modal className="modal-dialog-custom" isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>{!_.isEmpty(idClientModal) ? '工事名追加登録' : '工事名新規登録'}</ModalHeader>
        <ModalBody>
          <div className="form-body-product">
            {!_.isEmpty(idClientModal) && (
              <div className="group-input-project form-get-code">
                <div className="title">プロジェクト名</div>
                <select value={nameProject} onChange={handleChangeProject}>
                <option value='---'>---</option>
                  {
                    listDataProject?.map((item, index) =>
                      <option value={item?.projectCode} key={index}>{item?.projectName}</option>
                    )
                  }
              </select>
              </div>
            )}
            <div className="group-input-project form-get-code">
              <div className="title">工事名</div>
              <input
                type="text"
                value={nameProduct}
                onChange={changeNameProduct}
              />
            </div>
            {_.isEmpty(idClientModal) && (
              <div className="group-input-project">
              <div className="title">発注企業選択</div>
              <select name="userId" id="userId" onChange={handleChangeClient}>
                <option value='---'>---</option>
                  {
                    allClient.map((item, index) =>
                      <option value={item?.clientId} key={index}>{item?.fullName}</option>
                    )
                  }
              </select>
            </div>
            )}
            <div className="form-btn-setting">
              <button
                onClick={() => createProduct()}
                disabled={!( nameProduct && (client || idClientModal))}
              >登録</button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ModalCreateProduct;
