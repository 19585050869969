import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import 'assets/scss/page/CreateStaffMaster/style.scss';
import BorderGroupForm from 'components/BorderGroupForm';
import {
  STAFF_ENDPOINT,
  BRANCH_MASTER_ENDPOINT,
  USER_ENDPOINT
} from '../../../constants/endpoints';
import moment from 'moment';
import AreaSelection from 'components/AreaSelection';
import DateTimePicker from 'components/DateTimePicker';
import AutocompleteAddress from 'components/AutocompleteAddress';
import { pushToast } from '../../../components/Toast';
import HeaderMasterSetting from '../../../components/HeaderMasterSetting';
import { useSelector } from 'react-redux';
import { DEFAULT_ADDRESS, SCREEN_NAME, PARAMS_REQUIRED_STAFF, DATE_JP_FORMAT } from '../../../helpers/constants';
import { convertFileToBase64 } from 'helpers/helpers';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { withNamespaces } from 'react-i18next';
import { generatePassword, validateRequried } from 'helpers/helpers';
import Loading from 'components/Loading/Loading';
import _ from 'lodash';
import { AREAS_PERMITTED } from 'helpers/areas';
import QualificationList from '../../../components/QualificationList/index.jsx';
import FooterMaintenance from "components/FooterMaintenance"

const getQualifiedMock = {
  grade: "",
  licenseNumber: "",
  qualifications: "",
  validityPeriodFrom: "",
  validityPeriodTo: ""
}

const mockData = {
  branchId: '',
  surNameKana: '',
  nameKana: '',
  surName: '',
  name: '',
  contact: '',
  email: '',
  activeArea: {},
  haveToEmail: true,
  getQualified: []
};

const CreateStaffMaster = () => {
  const colorScreen = useSelector((store) => store.utils.colorMe);
  const [staffData, setStaffData] = useState(mockData);
  const [listBranchMaster, setListBranchMaster] = useState([]);
  const [isShowModalUploadStaffImg, setIsShowModalUploadStaffImg] = useState(false);
  const [staffImgLink, setStaffImgLink] = useState('');
  const [isDisable, setIsDisable] = useState(true);
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState('');
  const [prefectureAreas, setPrefectureAreas] = useState({});
  const history = useHistory();
  const prefectureNameKanji = _.groupBy(AREAS_PERMITTED, 'prefectureNameKanji');

  const getListBranchMaster = async () => {
    try {
      const data = await BRANCH_MASTER_ENDPOINT.GET_LIST_BRANCH_MASTER({
        page: 0,
      });
      if (data) {
        setListBranchMaster(data?.data?.body);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getLoginIdToCreateNewStaff = async ({ typeUser = 'partner', typeId }) => {
    try {
      setLoading(true);
      const data = await USER_ENDPOINT.GET_CODE({ typeUser, typeId });
      if (data) {
        setCode(data?.data?.code);
        setStaffData({ ...staffData, loginId: data?.data?.code });
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  useEffect(() => {
    getListBranchMaster();
    getLoginIdToCreateNewStaff({ typeId: localStorage.getItem('permissionId') });
  }, []);

  useEffect(() => {
    if(staffData) {
      validateRequried({data: staffData, arrField: PARAMS_REQUIRED_STAFF, onDisabled: onDisabled});
    }
  }, [staffData]);

  const onDisabled = ({status}) => {
    if([true, '', null].includes(staffData?.haveToEmail) && !staffData?.email) {
      document.getElementById("email").classList.add('isRequired');
      setIsDisable(true);
    } else {
      document.getElementById("email").classList.remove('isRequired');
      setIsDisable(status);
    }
  }

  const createStaffMaster = async ({ body }) => {
    try {
      if (!body.facePhoto) delete body.facePhoto;

      const data = await STAFF_ENDPOINT.CREATE_STAFF_MASTER({ body });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
      } else {
        pushToast('success', 'Create info success');
        history.push('/staff_master');
      }
    } catch (errorMessage) {
      console.log('errorMessage: ', errorMessage);
    }
  };

  const Create = () => {
    if (staffData) {
      const passwordExpirationDate = moment(new Date()).add(1, 'months').format(DATE_JP_FORMAT);
      const body = {...staffData, prefectureAreas, primaryKeyType: 'Worker', screenName: SCREEN_NAME.STAFF_MASTER, updateTitle: `更新 Staffマスタ ${staffData?.loginId || ''}`, passwordExpirationDate};
      createStaffMaster({ body });
    }
  };

  const handleRemote = (index) => {
    const getQualified = [...staffData?.getQualified];
    getQualified.splice(index, 1);
    setStaffData({ ...staffData, getQualified });
  };

  const addNewItem = () => {
    let getQualified = [...staffData?.getQualified, { ...getQualifiedMock }];
    setStaffData({ ...staffData, getQualified });
  };


  const handleOnChangeStaffImg = async (e) => {
    const file = e.target ?.files[0];
    if(file) {
      const imgBase64 = await convertFileToBase64(file)
      setStaffImgLink(imgBase64)
      setStaffData({
        ...staffData,
        facePhoto: {
          fileName: file.name,
          content: imgBase64
        }
      })
    }
  }

  const onChangeText = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const newData = {...staffData, [name]: value};
    setStaffData(newData);
  };

  const handleChange = (e) => {
    const name = e.target.name;
    if(e.target.checked) {
      setStaffData({ ...staffData, [name]: !e.target.checked, email: ''});
    } else {
      setStaffData({ ...staffData, [name]: !e.target.checked});
    };
  }

  const handleChangeQualificationText = ({ e, item, index, name, value }) => {
    const fieldName = e && e.target.name || name;
    item[fieldName] = e && e.target.value || value;
    const getQualified = staffData ?.getQualified;
    getQualified[index] = item
    setStaffData({ ...staffData, getQualified })
  }

  const onClickMakePass = () => {
    let newPass = generatePassword();
    let newStaffData = { ...staffData };
    newStaffData = { ...newStaffData, loginPassword: newPass };
    setStaffData(newStaffData);
  };

  const handleRemoveStaffImg = () => {
    setStaffImgLink('/img/default-user.png')
    setStaffData({
      ...staffData,
      facePhoto: null
    })
  }

  const onChangePrefectureAreas = (data) => {
    setPrefectureAreas(data);
  }

  const onChangeAreas = (activeArea) => {
    const newData = { ...staffData, activeArea };
    setStaffData(newData);
    const values = _.flatten(Object.values(newData?.activeArea || {}));
    const dataHandler = values.map(e => {
      return Object.keys(prefectureAreas || {}).includes(e) ? {[e]: prefectureAreas[e]} : {[e]: _.compact(_.map(prefectureNameKanji[e] || [], 'cityNameKanji'))}
    })
    const newPrefectureAreas = _.isEmpty(dataHandler) ? {} : Object.assign(...dataHandler);
    setPrefectureAreas(newPrefectureAreas);
  }

  const renderModalUploadStaffImg = () => {
    return (
      <Modal
        isOpen={isShowModalUploadStaffImg}
        toggle={() => setIsShowModalUploadStaffImg(!isShowModalUploadStaffImg)}
        className="modal-upload-staff-img"
      >
        <ModalHeader toggle={() => setIsShowModalUploadStaffImg(!isShowModalUploadStaffImg)}>写真</ModalHeader>
        <ModalBody>
          <label
            className="btn"
            htmlFor="id-upload-face-photo"
          >
            写真選択
          </label>
          <input
            type="file" id="id-upload-face-photo"
            onChange={(e) => handleOnChangeStaffImg(e)}
          />
          <img className='staff-img' src={staffImgLink || '/img/default-user.png'} />
        </ModalBody>
        <ModalFooter>
          <button className="btn" onClick={() => handleRemoveStaffImg()} disabled={!staffImgLink} >写真削除</button>
          <button className="btn" onClick={() => setIsShowModalUploadStaffImg(!isShowModalUploadStaffImg)} disabled={!staffImgLink} >保存</button>
        </ModalFooter>
      </Modal>
    )
  }

  return (
    <div className="main-branch-master staff-master-form-screen">
      <Loading loading={loading} />
      <HeaderMasterSetting checkName={'/staff_master'} color={colorScreen?.code} colorText={colorScreen?.colorText} />
      <BorderGroupForm color={colorScreen?.code}>
        <div className="body-wrapper mb-1">
          <div align="center" className="search">
            <div>
              <table className="staff-master-table">
                <tr>
                  <th colSpan="4" className="header-title">
                    協力企業
                  </th>
                </tr>
                <tr>
                  <th>利用者ID</th>
                  <td>
                    <input name="loginId" value={staffData?.loginId} onChange={onChangeText} disabled={!!code} />
                  </td>
                  {/* <th>パスワード有効期限日</th>
                  <td>
                    <DateTimePicker
                      date={staffData?.passwordExpirationDate ? new Date(staffData?.passwordExpirationDate) : null}
                      name="passwordExpirationDate"
                      id="passwordExpirationDate"
                      isRequired={true}
                      onChangeDate={(date) => {
                        setStaffData({
                          ...staffData,
                          passwordExpirationDate: date ? moment(date).format('YYYY/MM/DD') : '',
                        });
                      }}
                    />
                  </td> */}
                </tr>
                <tr>
                  <th>拠点選択</th>
                  <td>
                    <select name="branchId" onChange={onChangeText} value={staffData?.branchId} id="branchId">
                      <option value="" selected>
                        ----
                      </option>
                      {listBranchMaster?.map((item, index) => {
                        return (
                          <option value={item.branchId} key={index}>
                            {item.branchName}
                          </option>
                        );
                      })}
                    </select>
                  </td>
                  <td colSpan="2" />
                </tr>
                <tr>
                  <th>利用者名</th>
                  <td className="inline">
                    <label>姓</label>
                    <input name="surName" value={staffData?.surName} onChange={onChangeText} id="surName" />
                    <label>名</label>
                    <input name="name" value={staffData?.name} onChange={onChangeText} id="name" />
                  </td>
                  <th>フリガナ</th>
                  <td className="inline">
                    <label>セイ</label>
                    <input name="surNameKana" value={staffData?.surNameKana} onChange={onChangeText} id="surNameKana" />
                    <label>メイ</label>
                    <input name="nameKana" value={staffData?.nameKana} onChange={onChangeText} id="nameKana" />
                  </td>
                </tr>
                <tr>
                  <th>携帯電話番号</th>
                  <td>
                    <input name="contact" id="contact" value={staffData?.contact} onChange={onChangeText} />
                  </td>
                  <td colSpan="2"></td>
                </tr>
                <tr>
                  <th>メールアドレス</th>
                  <td colSpan="3">
                    <div>
                      <div className="group-email-input">
                        <div>
                          <label className="email-label">不所持</label>
                          <input
                            checked={!staffData?.haveToEmail}
                            name="haveToEmail"
                            className="input-email-checkbox"
                            type="checkbox"
                            onChange={handleChange}
                          />
                        </div>
                        <input
                          name="email"
                          id="email"
                          value={staffData?.email}
                          disabled={!staffData?.haveToEmail}
                          onChange={onChangeText}
                        />
                      </div>
                      <p className="small-text">
                        メールアドレスを設定すると、ログインIDとして利用できます。送信ボタンでアカウント情報の通知をお願いします。<br />
                        メールアドレスを登録しない方は、PDF出力からアカウント情報を取得してください。
                      </p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>ログインパスワード</th>
                  <td className="inline">
                    <input
                      name="loginPassword"
                      value={staffData.loginPassword}
                      onChange={onChangeText}
                      id="loginPassword"
                    />
                    <label className="btn-generate-password" onClick={() => onClickMakePass()}>
                      pw自動生成
                    </label>
                  </td>
                  <td colSpan="2" />
                </tr>
                <tr>
                  <th>郵便番号</th>
                  <td className="inline">
                    <input name="postalCode" value={staffData?.postalCode} onChange={onChangeText} />
                  </td>
                  <th>都道府県</th>
                  <td>
                    <select name="area" value={staffData?.area} onChange={onChangeText}>
                      {['', ...DEFAULT_ADDRESS].map((item, index) => {
                        return (
                          <option value={item} key={index}>
                            {item}
                          </option>
                        );
                      })}
                    </select>
                  </td>
                </tr>
                <tr>
                  <th>住所</th>
                  <td colSpan="3">
                    <AutocompleteAddress
                      name="address"
                      address={staffData?.address}
                      onChange={(address) => {
                        setStaffData({ ...staffData, address });
                      }}
                      onSelectAddress={({ address, latitude, longitude }) => {
                        setStaffData({ ...staffData, address, latitude, longitude });
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <th>フリガナ</th>
                  <td colSpan="3">
                    <input value={staffData?.addressKana} onChange={onChangeText} name="addressKana" id="addressKana" />
                  </td>
                </tr>
                <tr>
                  <th>緯度</th>
                  <td>
                    <input value={staffData?.latitude} onChange={onChangeText} name="latitude" id="latitude" />
                  </td>
                  <th>経度</th>
                  <td>
                    <input value={staffData?.longitude} onChange={onChangeText} name="longitude" id="longitude" />
                  </td>
                </tr>
                <tr>
                  <th>移動速度</th>
                  <td className="inline">
                    <label>1時間</label>
                    <input
                      value={staffData?.movableDistance?.distance}
                      onChange={(e) => {
                        const distance = e.target.value?.toString();
                        setStaffData({
                          ...staffData,
                          movableDistance: { distance, movingDistance: '60' },
                        });
                      }}
                      name="movableDistance"
                      id="movableDistance"
                      type="number"
                    />
                    <label>Km</label>
                  </td>
                  <th>移動距離範囲</th>
                  <td className="inline">
                    <input
                      id="distanceRange"
                      name="distanceRange"
                      value={staffData?.distanceRange}
                      onChange={onChangeText}
                      type="number"
                    />
                    <label>Km</label>
                  </td>
                </tr>
                <tr>
                  <th>対応エリア設定</th>
                  <td colSpan="3">
                    <AreaSelection
                      activeArea={{ ...staffData?.activeArea }}
                      prefectureAreas={prefectureAreas}
                      onChangePrefectureAreas={onChangePrefectureAreas}
                      onChange={(activeArea) => {
                        onChangeAreas(activeArea);
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <th>顔写真</th>
                  <td>
                    <button
                      className="btn btn-upload-staff-img"
                      onClick={() => {
                        setIsShowModalUploadStaffImg(!isShowModalUploadStaffImg);
                      }}
                    >
                      アップロード済
                    </button>
                    {renderModalUploadStaffImg()}
                  </td>
                  <th>生年月日・血液型</th>
                  <td className="inline group-birthday-input">
                    <DateTimePicker
                      date={staffData?.dateOfBirth ? new Date(staffData?.dateOfBirth) : null}
                      onChangeDate={(date) => {
                        setStaffData({
                          ...staffData,
                          dateOfBirth: date ? moment(date).format('YYYY/MM/DD') : '',
                        });
                      }}
                    />
                    <input
                      name="bloodType"
                      value={staffData?.bloodType}
                      onChange={onChangeText}
                      className="bloodtype-input"
                    />
                    <label>型</label>
                  </td>
                </tr>
                <tr>
                  <th>取得資格</th>
                  <td colSpan="3">
                    <QualificationList
                      userData={staffData}
                      handleChangeQualificationText={handleChangeQualificationText}
                      handleRemote={handleRemote}
                      addNewItem={addNewItem}
                    />
                  </td>
                </tr>
              </table>
              <button className="btn btn-submit-staff" onClick={Create} disabled={isDisable}>
                更新
              </button>
            </div>
          </div>
        </div>
      </BorderGroupForm>
      <FooterMaintenance/>
    </div>
  );
};

export default withNamespaces('translations')(CreateStaffMaster);
