import React from 'react';
import 'assets/scss/page/BranchMaster/ButtonBranch.scss';
import { Link } from 'react-router-dom';

const ButtonBranch = ({ subTitle, color, icon, width, font }) => {
  return (
    <div className="wrapper-button-branch-master-all">
      <Link to={'/branch-master/modify-branch'} className="btn">
        {subTitle}
      </Link>
    </div>
  );
};

export default ButtonBranch;
