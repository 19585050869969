import React from 'react';
import { DATE_JP_FORMAT, HEADER_MENU, SCREEN_NAME, time, DATETIME_JP_FORMAT } from 'helpers/constants';
import { COLOR_ENDPOINT, ORDER, ORDER_CLIENT } from 'constants/endpoints';
import { useEffect } from 'react';
import Header from 'components/Header';
import Footer from 'components/Footer';
import 'assets/scss/page/DetailSportacceptList/style.scss';
import { useState } from 'react';
import { pushToast } from 'components/Toast';
import ConfirmStatus from 'components/ConfirmStatus';
import SupportStatus from 'components/SupportStatus';
import DetailData from 'components/DetailClient/DetailData';
import DetailDataList from '../../../components/DetailCenter/DetailDataList';
import DetailOrder from 'components/DetailClient/DetailOrder';
import DetailListAllData from 'components/DetailClient/DetailListAllData';
import { useHistory, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import DateTimePicker from '../../../components/DateTimePicker';
import DetailTreeDocumentSetting from 'components/DetailTreeDocumentSetting';
import moment from 'moment';
import { translate } from 'react-i18next';
import _ from 'lodash';
import { formatInputSelectDate , formatInputSelectTime } from 'helpers/helpers';

const DetailSportAcceptList = ({ t }) => {
  let name = (process.browser && new URL(window.location.href).searchParams.get('p')) || 'preparation_info';
  let orderId = process.browser && new URL(window.location.href).searchParams.get('orderId');
  let organizationId = process.browser && new URL(window.location.href).searchParams.get('organizationId') || '';
  const colorScreen = useSelector((store) => store.utils.colorMe);
  const [p, setP] = React.useState(name || HEADER_MENU.RECEIVE_ALL);
  const [idRole, setIdRole] = React.useState(null);
  const [data, setData] = React.useState('');
  const [dateTime, setDateTime] = useState(new Date());
  const dataTimeNew = dateTime.toLocaleDateString('ja-JP');
  const initialState = {
    // timeStamp:'',
    // username: '',
    //  caseName:'' ,
    managementCode: '',
    personInCharge: '',
    engineering: '',
    applicantCompanyName: '',
    personInChargeOfApplication: '',
    contactPersonForApplication: '',
    visitedSiteName: '',
    personInChargeOfVisiting: '',
    contactInformation: '',
    visitedPostalCode: '',
    prefecturesVisited: '',
    visitedBaseAddress: '',
    visitedBuildingName: '',
    scheduledInstallationLocation: '',
    visitDate: '',
    visitDateFrom: '',
    visitDateTo: '',
    notificationItems: '',
    requestedDate: dataTimeNew,
    screenName: SCREEN_NAME.SPORT_ACCEPT,
    preferredDate: { visiteDate1: {}, visiteDate2: {}, visiteDate3: {}, visiteDate4: {}, visiteDate5: {} },
  };
  const [dataOrder, setDataOrder] = useState(initialState);
  const [phoneNumber1, setPhoneNumber1] = useState('');
  const [phoneNumber2, setPhoneNumber2] = useState('');
  const [phoneNumber3, setPhoneNumber3] = useState('');
  const [phoneNumber01, setPhoneNumber01] = useState('');
  const [phoneNumber02, setPhoneNumber02] = useState('');
  const [phoneNumber03, setPhoneNumber03] = useState('');
  const [mailNumber1, setMailNumber1] = useState('');
  const [mailNumber2, setMailNumber2] = useState('');
  const history = useHistory();

  const [listDetailScreen, setListDetailScreen] = useState([
    { name: 'clientCompany', sortNum: 1, status: true },
    { name: 'applicationCompany', sortNum: 2, status: true },
    { name: 'preparationInfo', sortNum: 3, status: true },
    { name: 'detailInfo', sortNum: 4, status: true },
    { name: 'document', sortNum: 5, status: true },
    { name: 'history', sortNum: 6, status: true },
  ]);

  useEffect(() => {
    getListRoleChild();
  }, []);

  const getListRoleChild = async () => {
    const newRole = localStorage.getItem('role');
    try {
      const data = await ORDER.GET_LIST_DETAIL_SETTING({
        arrRole: newRole,
        nameScreen: 'cancel_info',
        orderId,
        organizationId
      });
      if (data) {
        // setListDetailScreen(data?.data?.detailScreen);
        // setListDetailScreen(data ?.data ?.detailScreen.concat(
        //   {name: "documentSetting", sortNum: 99, status: true}
        // ));
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    setP(name);
  }, [name]);

  const getOrderDetail = async ({ orderId = '' }) => {
    try {
      const data = await ORDER.GET_DETAIL_CANCEL_INFO({ orderId: orderId, organizationId });
      if (data) {
        setData(data?.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (data) {
      setDataOrder({
        ...dataOrder,
        managementCode: data.managementCode,
        personInCharge: data.personInCharge,
        engineering: data.engineering,
        requestedDate: data.requestedDate,
        applicantCompanyName: data.applicantCompanyName,
        personInChargeOfApplication: data.personInChargeOfApplication,
        contactPersonForApplication: data.contactPersonForApplication,
        visitedSiteName: data.visitedSiteName,
        personInChargeOfVisiting: data.personInChargeOfVisiting,
        contactInformation: data.contactInformation,
        visitedPostalCode: data.visitedPostalCode,
        prefecturesVisited: data.prefecturesVisited,
        visitedBaseAddress: data.visitedBaseAddress,
        visitedBuildingName: data.visitedBuildingName,
        scheduledInstallationLocation: data.scheduledInstallationLocation,
        visitDate: data.visitDate,
        visitDateFrom: data.visitDateFrom,
        visitDateTo: data.visitDateTo,
        notificationItems: data.notificationItems,
        screenName: SCREEN_NAME.SPORT_ACCEPT,
        preferredDate: data?.preferredDate || {
          visitDate1: {},
          visitDate2: {},
          visitDate3: {},
          visitDate4: {},
          visitDate5: {},
        },
      });
    }
  }, [data]);

  useEffect(() => {
    if (data) {
      setPhoneNumber1(data?.contactPersonForApplication?.split('-')[0] || '');
      setPhoneNumber2(data?.contactPersonForApplication?.split('-')[1] || '');
      setPhoneNumber3(data?.contactPersonForApplication?.split('-')[2] || '');
      setPhoneNumber01(data?.contactInformation?.split('-')[0] || '');
      setPhoneNumber02(data?.contactInformation?.split('-')[1] || '');
      setPhoneNumber03(data?.contactInformation?.split('-')[2] || '');
      setMailNumber1(data?.visitedPostalCode?.split('-')[0] || '');
      setMailNumber2(data?.visitedPostalCode?.split('-')[0] || '');
    }
  }, [data]);

  useEffect(() => {
    const newRole = localStorage.getItem('role');
    setIdRole(newRole);

    if (orderId) {
      getOrderDetail({
        orderId: orderId,
      });
    }
  }, [orderId]);

  const handleChangeText = (e) => {
    let name = e.target.name;
    let newData = { ...dataOrder };
    newData = { ...newData, [name]: e.target.value };
    setDataOrder(newData);
  };

  const handleChangePhoneNumber = (e) => {
    if (e.target.name === 'phoneNumber1') {
      setPhoneNumber1(e.target.value);
      let newData = { ...dataOrder };
      newData = { ...newData, contactPersonForApplication: e.target.value + '-' + phoneNumber2 + '-' + phoneNumber3 };
      setDataOrder(newData);
    } else if (e.target.name === 'phoneNumber2') {
      setPhoneNumber2(e.target.value);
      let newData = { ...dataOrder };
      newData = { ...newData, contactPersonForApplication: phoneNumber1 + '-' + e.target.value + '-' + phoneNumber3 };
      setDataOrder(newData);
    } else if (e.target.name === 'phoneNumber3') {
      setPhoneNumber3(e.target.value);
      let newData = { ...dataOrder };
      newData = { ...newData, contactPersonForApplication: phoneNumber1 + '-' + phoneNumber2 + '-' + e.target.value };
      setDataOrder(newData);
    }
  };

  const handleContactInformation = (e) => {
    if (e.target.name === 'phoneNumber01') {
      setPhoneNumber01(e.target.value);
      let newData = { ...dataOrder };
      newData = { ...newData, contactInformation: e.target.value + '-' + phoneNumber02 + '-' + phoneNumber03 };
      setDataOrder(newData);
    } else if (e.target.name === 'phoneNumber02') {
      setPhoneNumber02(e.target.value);
      let newData = { ...dataOrder };
      newData = { ...newData, contactInformation: phoneNumber01 + '-' + e.target.value + '-' + phoneNumber03 };
      setDataOrder(newData);
    } else if (e.target.name === 'phoneNumber03') {
      setPhoneNumber03(e.target.value);
      let newData = { ...dataOrder };
      newData = { ...newData, contactInformation: phoneNumber01 + '-' + phoneNumber02 + '-' + e.target.value };
      setDataOrder(newData);
    }
  };

  const handleVisitedPostalCode = (e) => {
    if (e.target.name === 'mailNumber1') {
      setMailNumber1(e.target.value);
      let newData = { ...dataOrder };
      newData = { ...newData, visitedPostalCode: e.target.value + '-' + mailNumber2 };
      setDataOrder(newData);
    } else if (e.target.name === 'mailNumber2') {
      setMailNumber2(e.target.value);
      let newData = { ...dataOrder };
      newData = { ...newData, visitedPostalCode: mailNumber1 + '-' + e.target.value };
      setDataOrder(newData);
    }
  };
  const onChangeTime = (e) => {
    let name = e.target.name;
    setDataOrder({ ...dataOrder, [name]: e.target.value });
  };

  const updateOrderInfo = async ({ body = {}, id_order = '' }) => {
    try {
      const data = await ORDER_CLIENT.UPDATE_ORDER_CLIENT({ body: body, id_order: id_order });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
      } else {
        pushToast('success', 'Update order success');
        history.push(`/list?p=preparation_info`);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onUpdateOrder = () => {
    const dataCompany = { updateTitle: 'アップロード', companyName: localStorage.getItem('companyName')}
    updateOrderInfo({ body: {...dataOrder ,...dataCompany}, id_order: orderId });
  };

  const handleReset = () => {
    Array.from(document.querySelectorAll('input')).forEach((input) => (input.value = ''));
    setDataOrder({
      itemvalues: [{}],
    });
  };

  const onChangeDate = (date, item) => {
    let valueTime = new Date(date).toDateString();
    let newVisitDate = {
      ...(dataOrder?.preferredDate?.[item] || {}),
      visitDate: !date ? '' : moment(new Date(valueTime)).format(DATE_JP_FORMAT),
    };
    if (!('visitDateFrom' in newVisitDate) || !newVisitDate.visitDateFrom) {
      newVisitDate = { ...newVisitDate, visitDateFrom: '00:00' };
    }
    if (!('visitDateTo' in newVisitDate) || !newVisitDate.visitDateTo) {
      newVisitDate = { ...newVisitDate, visitDateTo: newVisitDate.visitDate ? `${newVisitDate.visitDate} 00:00` : '' };
    }
    if(!date) {
      newVisitDate = { ...newVisitDate, visitDateFrom: '', visitDateTo: '' };
    }
    const newPreferredDate = { ...(dataOrder?.preferredDate || {}), [item]: newVisitDate };
    setDataOrder({ ...dataOrder, preferredDate: newPreferredDate });
  };

  const onChangeDateTo = ( date, item ) => {
    let valueTime = new Date(date).toDateString();
    let newVisitDate = {
      ...(dataOrder?.preferredDate?.[item] || {}),
      visitDateTo: !date ? '' : (dataOrder?.preferredDate?.[item]?.visitDateTo ? (moment(`${formatInputSelectDate(dataOrder?.preferredDate?.[item]?.visitDateTo)}`, `${DATE_JP_FORMAT}`).isValid()
        ? `${moment(new Date(valueTime)).format(DATE_JP_FORMAT)} ${formatInputSelectTime(dataOrder?.preferredDate?.[item]?.visitDateTo)}`
        : `${dataOrder?.preferredDate?.[item]?.visitDate ? `${dataOrder?.preferredDate?.[item]?.visitDate} ${dataOrder?.preferredDate?.[item]?.visitDateTo}` : ''}`) : `${moment(new Date(valueTime)).format(DATE_JP_FORMAT)} 00:00`),
    };
    const newPreferredDate = { ...(dataOrder?.preferredDate || {}), [item]: newVisitDate };
    setDataOrder({ ...dataOrder, preferredDate: newPreferredDate });
  }

  const handleSelectTimeFrom = (e, item) => {
    let newVisitDateFrom = { ...(dataOrder?.preferredDate?.[item] || {}), visitDateFrom: e.target.value };
    const newPreferredDate = { ...(dataOrder?.preferredDate || {}), [item]: newVisitDateFrom };
    setDataOrder({ ...dataOrder, preferredDate: newPreferredDate });
  };

  const handleSelectTimeTo = (e, item) => {
    let visitDateTo = dataOrder?.preferredDate?.[item]?.visitDateTo ? (moment(`${formatInputSelectDate(dataOrder?.preferredDate?.[item]?.visitDateTo)}`, `${DATE_JP_FORMAT}`).isValid()
      ? `${moment(new Date(dataOrder?.preferredDate?.[item]?.visitDateTo)).format(DATE_JP_FORMAT)} ${e.target.value}`
      : `${dataOrder?.preferredDate?.[item]?.visitDate ? `${dataOrder?.preferredDate?.[item]?.visitDate} ${dataOrder?.preferredDate?.[item]?.visitDateTo}` : ''}`) : ''
    let newVisitDateTo = { ...(dataOrder?.preferredDate?.[item] || {}), visitDateTo };
    const newPreferredDate = { ...(dataOrder?.preferredDate || {}), [item]: newVisitDateTo };
    setDataOrder({ ...dataOrder, preferredDate: newPreferredDate });
  };

  return (
    <div className="wrapper-detail-sport-accept-list">
      <div className="main">
        <Header idRole={idRole} color={colorScreen?.code} colorText={colorScreen?.colorText} p={p} />
        <div className="body-wrapper">
          <div className="logout_btn">
            <Link to={`list?p=${p}`}>
              <span className="mypage_ab_custom">戻る</span>
            </Link>
          </div>
          {listDetailScreen &&
            listDetailScreen.map((item) => {
              if (item.status) {
                switch (item.name) {
                  case 'clientCompany':
                    return <DetailOrder data={data} p={p} />;
                  case 'applicationCompany':
                    return <DetailData data={data} p={p} link={false} />;
                  case 'preparationInfo':
                    return (
                      <>
                        <div className="detail-sport-accept-wrapper">
                          <div className="title-absolute">前日確認期限</div>
                          <table className="conditTab" width="100%">
                            <tbody>
                              <tr>
                                <th>タイムスタンプ</th>
                                <td>{moment(data?.createdAt).format(DATETIME_JP_FORMAT)}</td>
                              </tr>
                              <tr>
                                <th>利用者名</th>
                                <td>{data?.updatedBy}</td>
                              </tr>
                              <tr>
                                <th>案件名</th>
                                <td>{data?.caseName}</td>
                              </tr>
                              <tr>
                                <th>管理コード</th>
                                <td>
                                  <input
                                    type="text"
                                    name="managementCode"
                                    onChange={handleChangeText}
                                    value={dataOrder?.managementCode}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <th>担当者名</th>
                                <td>{data?.contactName}</td>
                              </tr>
                              <tr>
                                <th>担当者連絡先_携帯番号</th>
                                <td>
                                  <input
                                    type="text"
                                    name="clientStaffTel"
                                    value={dataOrder?.clientStaffTel}
                                    onChange={handleChangeText}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <th>担当者連絡先_メールアドレス</th>
                                <td>
                                  <input
                                    type="text"
                                    name="clientStaffEmail"
                                    value={dataOrder?.clientStaffEmail}
                                    onChange={handleChangeText}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <th>工程</th>
                                <td>{data?.serviceName}</td>
                              </tr>
                              <tr>
                                <th>依頼日</th>
                                <td>{dataTimeNew}</td>
                              </tr>
                              <tr>
                                <th>申込会社名</th>
                                <td>
                                  <input
                                    size="50"
                                    type="text"
                                    name="applicantCompanyName"
                                    onChange={handleChangeText}
                                    value={dataOrder?.applicantCompanyName}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <th>申込ご担当者</th>
                                <td>
                                  <input
                                    size="50"
                                    type="text"
                                    name="personInChargeOfApplication"
                                    onChange={handleChangeText}
                                    value={dataOrder?.personInChargeOfApplication}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <th>申込ご担当者連絡先</th>
                                <td>
                                  <input
                                    type="text"
                                    maxLength="3"
                                    size="3"
                                    name="phoneNumber1"
                                    onChange={handleChangePhoneNumber}
                                    value={phoneNumber1}
                                  />
                                  -
                                  <input
                                    type="text"
                                    maxLength="4"
                                    size="4"
                                    name="phoneNumber2"
                                    onChange={handleChangePhoneNumber}
                                    value={phoneNumber2}
                                  />
                                  -
                                  <input
                                    type="text"
                                    maxLength="4"
                                    size="3"
                                    name="phoneNumber3"
                                    onChange={handleChangePhoneNumber}
                                    value={phoneNumber3}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <th>物件名</th>
                                <td>
                                  <input
                                    size="50"
                                    type="text"
                                    name="visitedSiteName"
                                    onChange={handleChangeText}
                                    value={dataOrder?.installationSiteName}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <th>訪問先ご担当者</th>
                                <td>
                                  <input
                                    size="50"
                                    type="text"
                                    name="personInChargeOfVisiting"
                                    onChange={handleChangeText}
                                    value={dataOrder?.personInChargeOfVisiting}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <th>訪問先ご担当者連絡先</th>
                                <td>
                                  <input
                                    type="text"
                                    maxLength="3"
                                    size="3"
                                    name="phoneNumber01"
                                    onChange={handleContactInformation}
                                    value={phoneNumber01}
                                  />
                                  -
                                  <input
                                    type="text"
                                    maxLength="4"
                                    size="4"
                                    name="phoneNumber02"
                                    onChange={handleContactInformation}
                                    value={phoneNumber02}
                                  />
                                  -
                                  <input
                                    type="text"
                                    maxLength="4"
                                    size="3"
                                    name="phoneNumber03"
                                    onChange={handleContactInformation}
                                    value={phoneNumber03}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <th>訪問先郵便番号</th>
                                <td>
                                  <input
                                    type="text"
                                    maxLength="3"
                                    size="3"
                                    name="mailNumber1"
                                    onChange={handleVisitedPostalCode}
                                    value={mailNumber1}
                                  />
                                  -
                                  <input
                                    type="text"
                                    maxLength="4"
                                    size="4"
                                    name="mailNumber2"
                                    onChange={handleVisitedPostalCode}
                                    value={mailNumber2}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <th>都道府県</th>
                                <td>
                                  <select name="prefecturesVisited" onChange={handleChangeText} className="">
                                    <option value=""></option>
                                    <option value="北海道">北海道</option>
                                    <option value="青森県">青森県</option>
                                    <option value="岩手県">岩手県</option>
                                    <option value="宮城県">宮城県</option>
                                    <option value="秋田県">秋田県</option>
                                    <option value="山形県">山形県</option>
                                    <option value="福島県">福島県</option>
                                    <option value="東京都">東京都</option>
                                    <option value="神奈川県">神奈川県</option>
                                    <option value="埼玉県">埼玉県</option>
                                    <option value="千葉県">千葉県</option>
                                    <option value="茨城県">茨城県</option>
                                    <option value="栃木県">栃木県</option>
                                    <option value="群馬県">群馬県</option>
                                    <option value="山梨県">山梨県</option>
                                    <option value="新潟県">新潟県</option>
                                    <option value="長野県">長野県</option>
                                    <option value="石川県">石川県</option>
                                    <option value="富山県">富山県</option>
                                    <option value="福井県">福井県</option>
                                    <option value="愛知県">愛知県</option>
                                    <option value="岐阜県">岐阜県</option>
                                    <option value="静岡県">静岡県</option>
                                    <option value="三重県">三重県</option>
                                    <option value="大阪府">大阪府</option>
                                    <option value="兵庫県">兵庫県</option>
                                    <option value="京都府">京都府</option>
                                    <option value="滋賀県">滋賀県</option>
                                    <option value="奈良県">奈良県</option>
                                    <option value="和歌山県">和歌山県</option>
                                    <option value="鳥取県">鳥取県</option>
                                    <option value="島根県">島根県</option>
                                    <option value="岡山県">岡山県</option>
                                    <option value="広島県">広島県</option>
                                    <option value="山口県">山口県</option>
                                    <option value="徳島県">徳島県</option>
                                    <option value="香川県">香川県</option>
                                    <option value="愛媛県">愛媛県</option>
                                    <option value="高知県">高知県</option>
                                    <option value="福岡県" selected="selected">
                                      福岡県
                                    </option>
                                    <option value="佐賀県">佐賀県</option>
                                    <option value="長崎県">長崎県</option>
                                    <option value="熊本県">熊本県</option>
                                    <option value="大分県">大分県</option>
                                    <option value="宮崎県">宮崎県</option>
                                    <option value="鹿児島県">鹿児島県</option>
                                    <option value="沖縄県">沖縄県</option>
                                  </select>
                                </td>
                              </tr>
                              <tr>
                                <th>訪問先拠点住所</th>
                                <td>
                                  <input
                                    size="50"
                                    type="text"
                                    name="visitedBaseAddress"
                                    onChange={handleChangeText}
                                    className=""
                                    value={dataOrder?.visitedBaseAddress}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <th>訪問先建物名</th>
                                <td>
                                  <input
                                    size="50"
                                    type="text"
                                    name="visitedBuildingName"
                                    onChange={handleChangeText}
                                    value={dataOrder?.visitedBuildingName}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <th>作業予定箇所</th>
                                <td>
                                  <input
                                    size="50"
                                    type="text"
                                    name="scheduledInstallationLocation"
                                    onChange={handleChangeText}
                                    value={dataOrder?.scheduledInstallationLocation}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <th>訪問日</th>
                                <td>
                                  {['visitDate1', 'visitDate2', 'visitDate3', 'visitDate4', 'visitDate5'].map(
                                    (item, index) => {
                                      return (
                                        <div className="d-flex align-items-center" name="visitDate" key={index}>
                                          <div className="label__background">{t(`table_order_list.${item}`)}</div>
                                          <DateTimePicker
                                            date={
                                              dataOrder?.preferredDate?.[item]?.visitDate
                                                ? new Date(dataOrder?.preferredDate?.[item]?.visitDate)
                                                : null
                                            }
                                            onChangeDate={(e) => onChangeDate(e, item)}
                                          />
                                          <select
                                            name="visit_time_from"
                                            className="time_select"
                                            disabled={!dataOrder?.preferredDate?.[item]?.visitDate}
                                            value={dataOrder?.preferredDate?.[item]?.visitDateFrom}
                                            onChange={(e) => handleSelectTimeFrom(e, item)}
                                          >
                                            <option value=''></option>
                                            {time.map((item, index) => (
                                              <option value={item} key={index}>
                                                {item}
                                              </option>
                                            ))}
                                          </select>
                                          ～
                                          <DateTimePicker
                                            date={
                                              dataOrder?.preferredDate?.[item]?.visitDateTo ? (moment(`${formatInputSelectDate(dataOrder?.preferredDate?.[item]?.visitDateTo)}`, `${DATE_JP_FORMAT}`).isValid()
                                                ? new Date(moment(new Date(dataOrder?.preferredDate?.[item]?.visitDateTo)).format(DATE_JP_FORMAT))
                                                : `${dataOrder?.preferredDate?.[item]?.visitDate ? new Date(`${dataOrder?.preferredDate?.[item]?.visitDate} ${dataOrder?.preferredDate?.[item]?.visitDateTo || '00:00'}`) : ''}`) : null
                                            }
                                            disabled={!dataOrder?.preferredDate?.[item]?.visitDate}
                                            onChangeDate={(e) => onChangeDateTo(e, item)}
                                          />
                                          <select
                                            name="visit_time_to"
                                            onChange={(e) => handleSelectTimeTo(e, item)}
                                            value={moment(`${formatInputSelectDate(dataOrder?.preferredDate?.[item]?.visitDateTo)}`, `${DATE_JP_FORMAT}`).isValid() ? formatInputSelectTime(dataOrder?.preferredDate?.[item]?.visitDateTo) : (dataOrder?.preferredDate?.[item]?.visitDateTo || '')}
                                            disabled={!dataOrder?.preferredDate?.[item]?.visitDate}
                                          >
                                            <option value=''></option>
                                            {time.map((item, index) => (
                                              <option value={item} key={index}>
                                                {item}
                                              </option>
                                            ))}
                                          </select>
                                        </div>
                                      );
                                    }
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <th>申し送り事項</th>
                                <td>
                                  <textarea
                                    value={dataOrder?.notificationItems}
                                    onChange={handleChangeText}
                                    name="notificationItems"
                                    cols="70"
                                    rows="5"
                                  >
                                    {dataOrder?.notificationItems}
                                  </textarea>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <div id="SearchButton" align="left">
                            <input
                              type="submit"
                              name="insertbtn"
                              className="btn_submit submitbutton"
                              value=""
                              onClick={() => onUpdateOrder()}
                            />
                            <input type="submit" className="btn_reset" value="" onClick={handleReset} />
                          </div>
                        </div>
                      </>
                    );
                  case 'detailInfo':
                    return <DetailListAllData data={data} screenName={SCREEN_NAME.SPORT_ACCEPT} />;
                  case 'document':
                    return <DetailTreeDocumentSetting orderId={orderId} order={data} />;
                  case 'history':
                    return <DetailDataList datePick={false} screenName={SCREEN_NAME.SPORT_ACCEPT} orderId={orderId} />;
                  default:
                    return '';
                }
              }
            })}
        </div>

        {/*<Footer color={color} />*/}
        <Footer color={colorScreen?.code} colorText={colorScreen?.colorText} />
      </div>
    </div>
  );
};

export default translate('translations')(DetailSportAcceptList);
