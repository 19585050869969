import React, { useState } from 'react';
import ModalQualification from '../ModalQualification';
import { Table } from 'reactstrap';

const PartnerCompanies = ({ dataQualificationContract, title }) => {
  const [showPopupQualifiCation, setShowPopupQualifiCation] = useState(false);
  const [dataQualification, setDataQualification] = useState([]);

  const toggleOpen = (item) => {
    setShowPopupQualifiCation(!showPopupQualifiCation);
    setDataQualification(item || []);
  };
  const toggleCloseModal = () => {
    setShowPopupQualifiCation(false);
  };
  const renderItem = (item, index) => {
    return (
      <tr key={index}>
        <td>{index + 1}</td>
        <td>{item?.partnerId}</td>
        <td>{item?.abbreviationName}</td>
        <td>{item?.updatedAt}</td>
        <td>{item?.contractEstimate?.contractStatus?.state}</td>
        <td>{item?.countStaffs}</td>
        <td>
          <button onClick={() => toggleOpen(item)}>取得資格</button>
        </td>
      </tr>
    );
  };

  return (
    <div className="detail-basic-information-wrapper">
      <div className="detail-basic-table-wrapper">
        <div className="title-absolute">協力会社・資格</div>
        <div className="table-export-list-partner">
          <div className="form--scroll_x form--h-360 mb-3">
            <table className="table table-bordered">
              <tr className="header-table-absolute">
                <th>No</th>
                <th>協力企業ID</th>
                <th>会社名</th>
                <th>更新日時</th>
                <th>状態</th>
                <th>作業員数</th>
                <th>資格</th>
              </tr>
              <tbody>
                {dataQualificationContract && dataQualificationContract?.map((item, index) => renderItem(item, index))}
              </tbody>
            </table>
            {showPopupQualifiCation && (
              <ModalQualification
                rederBody={() => {
                  return (
                    <table className="w-100 table table-bordered table_unise">
                      <tr>
                        <th className="text-center table--bg-white" colspan="2">
                          企業資格
                        </th>
                      </tr>
                      {dataQualification?.getQualified?.map((ele) => (
                        <tr>
                          <th colspan="2">{ele?.qualifications}</th>
                        </tr>
                      ))}
                      <tr>
                        <td className="text-center table--bg-white">個人資格</td>
                        <td className="text-center table--bg-white">取得人数</td>
                      </tr>
                      {dataQualification?.qualifields?.map((ele) => (
                        <tr>
                          <td>{ele?.qualifications}</td>
                          <td>{ele?.amount}</td>
                        </tr>
                      ))}
                    </table>
                  );
                }}
                modal={showPopupQualifiCation}
                toggle={toggleCloseModal}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartnerCompanies;
