import React, { useState, useEffect } from 'react';
import { DATE_JP_FORMAT, DATE_MONTH_YEAR, time, SCREEN_NAME } from 'helpers/constants';
import _ from 'lodash';
import { MASTER_SETTING } from 'constants/endpoints';
import moment from 'moment';
import { getDate } from 'helpers/helpers';
import { pushToast } from 'components/Toast';
import DatePicker from 'react-datepicker';
import PopupRemarks from 'components/popupRemarks';
import { Link, useHistory } from 'react-router-dom';

const RequestDetail = ({colors ,colorText, changeLoading, staffId, date}) => {
  const history = useHistory();
  const [startDate, setStartDate] = useState(new Date(date.replace("/", "-")) || new Date());
  const [workingSchedules, setWorkingSchedules] = useState([]);
  const [showPopupConfirm, setShowPopupConfirm] = useState(false);
  const [request, setRequest] = useState({});
  const [staffName, setStaffName] = useState('');

  useEffect(() => {
    if(startDate && staffId) {
      getRequestCalendarStaffs({ date: moment(startDate).format(DATE_MONTH_YEAR), staffId });
    }
  }, [startDate]);

  useEffect(() => {
    if(!showPopupConfirm && !_.isEmpty(request)) {
      const newData = [...workingSchedules];
      const index = newData.findIndex(i => i.date === request?.date);
      if(newData[index].comment !== request.comment) {
        newData[index].comment = request.comment;
        setWorkingSchedules(newData);
      }
    }
  }, [showPopupConfirm]);

  const getDaysInMonth = (month, year) => {
    let date = new Date(year, month, 1);
    let days = [];
    while (date.getMonth() === month) {
      let dateCheck = new Date(moment(date));
      let currentDay = dateCheck.getDay();
      let type = [0, 6].includes(currentDay) ? 2 : 1;
      const dateTime = type === 2 ? {breakEndTime: '',breakStartTime: '', workingEndTime: '', workingStartTime: ''} : {breakEndTime: '00:00', breakStartTime: '00:00', workingEndTime: '00:00', workingStartTime: '00:00'}
      days.push({ date: moment(dateCheck).format(DATE_JP_FORMAT), type, remarks: '', status: false, ...dateTime });
      date.setDate(date.getDate() + 1);
    }
    return days;
  };

  const getRequestCalendarStaffs = async ({ date, staffId }) => {
    try {
      changeLoading(true);
      const data = await MASTER_SETTING.GET_REQUEST_CALENDAR_STAFF({ date, staffId });
      if (data?.data) {
        const newData = {...data?.data};
        const calendarMaster = (_.isEmpty(newData?.calendarMaster) ? getDaysInMonth(new Date(startDate).getMonth(), new Date(startDate).getFullYear()) : newData?.calendarMaster).map(e => {
          return {...(_.omit(e, 'type')), typeMaster: e.type};
        });
        const schedules = _.orderBy(_.values(_.merge(_.keyBy(newData?.allRequest, 'date'), _.keyBy(calendarMaster, 'date'))), 'date')?.map(e => {
          if(!('startTime' in e)) {
            e.type === 'dateOff' ? e.startTime = '' : e.startTime = e.workingStartTime;
          };
          if(!('endTime' in e)) {
            e.type === 'dateOff' ? e.endTime = '' : e.endTime = e.workingEndTime;
          };
          return e
        })
        setWorkingSchedules(schedules);
        setStaffName(data?.data?.staffName);
      }
      changeLoading(false);
    } catch (e) {
      console.log(e);
      changeLoading(false);
    }
  };

  const updateRequestStaff = async ({ body, index, isApproval }) => {
    try {
      changeLoading(true);
      const data = await MASTER_SETTING.UPDATE_REQUEST_CALENDAR_STAFF({ body });
      if (data && data.code !== 0) {
        pushToast('error', data?.message?.extra || data?.message?.errorMessage);
      } else {
        pushToast('success', 'Create schedule success');
        getRequestCalendarStaffs({ date: moment(startDate).format(DATE_MONTH_YEAR), staffId });
        setShowPopupConfirm(false);
      }
      changeLoading(false);
    } catch (e) {
      changeLoading(false);
      console.log(e);
    }
  };

  const updateRequestStaffCalendar = async ({ body }) => {
    try {
      changeLoading(true);
      const data = await MASTER_SETTING.UPDATE_REQUEST_STAFF({ body });
      if (data && data.code !== 0) {
        pushToast('error', data?.message?.extra || data?.message?.errorMessage);
      } else {
        pushToast('success', 'Create schedule success');
        getRequestCalendarStaffs({ date: moment(startDate).format(DATE_MONTH_YEAR), staffId });
        setShowPopupConfirm(false);
      }
      changeLoading(false);
    } catch (e) {
      changeLoading(false);
      console.log(e);
    }
  };

  const handleChange = (date) => {
    setStartDate(date);
    history.push(`/working-schedule/staff-request?staffId=${staffId}&startDate=${moment(date).format(DATE_MONTH_YEAR)}`)
  };

  const handleTime = (item, key) => {
    if ((['shortDateOff', 'workDate', 'dateOff'].includes(item.type)) && item?.[key]) {
      return `${item?.[key] || ''}`;
    } else {
      return ''
    }
  };

  const handleTimeNormal = (item, key) => {
    if (item?.[key]) {
      return `${item?.[key] || ''}`;
    } else {
      return ''
    }
  };

  const checkRequest = ({ isApproval = false, item = {} }) => {
    let newData = [...workingSchedules];
    const index = workingSchedules.findIndex(i => i.date === (_.isEmpty(item) ? request : item).date);
    let body = {
      workerCalendarId: moment(startDate).format(DATE_MONTH_YEAR),
      staffId,
      screenName: SCREEN_NAME.WORKING_SCHEDULE,
      date: newData?.[index]?.date,
      updateTitle: `メモ 稼働予定 ${staffName}`
    };
    const data = isApproval ? {typeStatus: 'approval'} : (request?.isComment ? {comment: newData?.[index]?.comment} : {comment: newData?.[index]?.comment, typeStatus: 'reject'});
    newData[index] = {...newData?.[index], ...data, isChange: true};
    // setWorkingSchedules(newData);
    // updateRequestStaff({ body: {...body, ...data}, index, isApproval });
    onUpdate({data: newData, updateTitle: `メモ 稼働予定 ${staffName}`});
  };

  const handleAction = ({ item, isComment = false }) => {
    setShowPopupConfirm(!showPopupConfirm);
    setRequest({...item, isComment} || {isComment});
  };

  const handleTextArea = (e) => {
    let newData = [...workingSchedules];
    const index = workingSchedules.findIndex(i => i.date === request.date);
    newData[index].comment = e.target.value;
    setWorkingSchedules(newData);
  };

  const countWorkingDay = () => {
    return workingSchedules.filter(e => ["workDate", "shortDateOff"].includes(e.type) || (!e.type && ![2, 3, '2', '3'].includes(e.typeMaster))).length;
  };

  const onUpdate = ({data = '', updateTitle = ''}) => {
    let newData = data ? [...data] : [...workingSchedules];
    let body = {
      workerCalendarId: moment(startDate).format(DATE_MONTH_YEAR),
      staffId,
      screenName: SCREEN_NAME.WORKING_SCHEDULE,
      updateTitle: updateTitle || `送信 稼働予定 ${staffName}`,
      calendar: _.compact(newData?.map(e => {
        if(e.isChange) {
          return _.pick(e, ['date', 'startTime', 'endTime', 'comment', 'typeStatus']);
        }else {
          return ''
        }
      })),
    };
    updateRequestStaffCalendar({ body });
  }

  const changeSelection = ({ e, item }) => {
    let newData = [...workingSchedules];
    const index = workingSchedules.findIndex(i => i.date === item.date);
    newData[index][e.target.name] = e.target.value;
    newData[index]['isChange'] = true;
    setWorkingSchedules(newData);
  };

  const changeSelectionNew = ({ e, item, typeTime }) => {
    let newData = [...workingSchedules];
    const index = workingSchedules.findIndex(i => i.date === item.date);
    newData[index][e.target.name] = e.target.value;
    newData[index]['isChange'] = true;
    setWorkingSchedules(newData);
  };

  const handleTimeAllDate = ({ item }) => {
    return item.workingStartTime && item.workingEndTime ? `${item.workingStartTime} ~ ${item.workingEndTime}` : ([2, 3, '2', '3'].includes(item?.typeMaster) ? "--:-- ~ --:--" : "00:00 ~ 00:00")
  };

  return (
    <div className="setting-master-menu-wrapper wrapper-calendar-master">
      <div className="wrapper-btn-colappse">
        <div className="mb-1 searchbar d-flex justify-content-between align-items-center" style = {{background :`${colors}`}}>
          <div className="ml-2" style={{ color: `${colorText}` }}>稼働予定_{staffName}</div>
          <div className="mr-2" style={{ color: `${colorText}` }}>
          </div>
        </div>
      </div>
      <div>
        <div className="form__content">
          <div className="form__flex form__flex--mgt-10 form__flex--start">
            <div className="logout_btn">
              <Link to={`/working-schedule`}>
                <span className="mypage_ab_custom">戻る</span>
              </Link>
            </div>
          </div>
          <div className="form__flex form__flex--mgt-10 form__flex--start">
            <DatePicker
              selected={startDate}
              onChange={(date) => handleChange(date)}
              locale="ja"
              dateFormat="yyyy/MM"
              dateFormatCalendar="yyyy年 MM月"
              showMonthYearPicker
              className="form-control w20 label__schedule--border"
            />
            <div className="w-25 form-control table__calendar--sl form--mgl10">
            稼働日数：{countWorkingDay()}日
            </div>
          </div>
          <div className="form__flex form__flex--mgt-10">
            <table className="table__calendar--tb table">
              <thead>
                <tr>
                  <th className="form--w10">日付</th>
                  <th className="form--w5">曜日</th>
                  <th className="form--w30">稼働予定</th>
                  <th className="form--w30">稼働変更</th>
                  <th className="form--w20"></th>
                </tr>
              </thead>
              <tbody>
                {workingSchedules?.map((item, index) => {
                    return (
                      <tr key={index} style={{ color: `${[2, 3, '2', '3'].includes(item?.typeMaster) ? 'red' : 'black'}`, backgroundColor: !item?.type || item?.approval || item?.reject ? '#fff' : '#f1c155'}}>
                        <td className="form--w10">{moment(item?.date).format(DATE_JP_FORMAT)}</td>
                        <td className="form--w5">{getDate(item?.date)}</td>
                        <td className="form--w30 text-center">
                          <div className="col-12 label__schedule--col-resize">
                            {handleTimeAllDate({ item })}
                          </div>
                        </td>
                        <td className="form--w30">
                          {
                            item.type ? (
                              <div className="row col-12 label__schedule--col-resize grid--center">
                                <select className="col-5 w-25 form-control mb-1 table__calendar--sl form--mgl10" name="startTime" value={handleTime(item, 'startTime')} onChange={e => changeSelection({ e, item, typeTime: 'startTime' })} >
                                  <option value={''}>--:--</option>
                                  {
                                    time.map((item, index) => (
                                      <option key={index} value={item}>{item}</option>
                                    ))
                                  }
                                </select>
                                ~
                                <select className="col-5 w-25 form-control mb-1 table__calendar--sl form--mgl10" value={handleTime(item, 'endTime')} name="endTime" onChange={e => changeSelection({ e, item, typeTime: 'endTime' })}>
                                  <option value={''}>--:--</option>
                                  {
                                    time.map((item, index) => (
                                      <option key={index} value={item}>{item}</option>
                                    ))
                                  }
                                </select>
                              </div>
                            ) : (
                              <div className="row col-12 label__schedule--col-resize grid--center">
                                <select className="col-5 w-25 form-control mb-1 table__calendar--sl form--mgl10" name="startTime" value={handleTimeNormal(item, 'startTime')} onChange={e => changeSelectionNew({ e, item, typeTime: 'startTime' })} >
                                  <option value={''}>--:--</option>
                                  {
                                    time.map((item, index) => (
                                      <option key={index} value={item}>{item}</option>
                                    ))
                                  }
                                </select>
                                ~
                                <select className="col-5 w-25 form-control mb-1 table__calendar--sl form--mgl10" value={handleTimeNormal(item, 'endTime')} name="endTime" onChange={e => changeSelectionNew({ e, item, typeTime: 'endTime' })}>
                                  <option value={''}>--:--</option>
                                  {
                                    time.map((item, index) => (
                                      <option key={index} value={item}>{item}</option>
                                    ))
                                  }
                                </select>
                              </div>
                            )
                          }
                        </td>
                        <td className="form--w20">
                          {
                            !item?.type || (item?.approval || item?.reject) ? (
                              <button className="button-submit-calendar button--remarks" onClick={() => handleAction({item, isComment: true})}>メモ</button>
                            ) : (
                              <>
                                <button className="button-submit-calendar button--remarks" onClick={() => checkRequest({ isApproval: true, item })}>承認</button>
                                <button className="button-submit-calendar button--remarks" onClick={() => handleAction({item})}>差戻</button>
                              </>
                            )
                          }
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
          <div className="text-right">
            <button className="button-submit-calendar button-submit-calendar--h35" onClick={onUpdate}>送信</button>
          </div>
        </div>
      </div>
      <PopupRemarks
        headerTitle="変更コメント"
        rederBody={() => {
          return (
            <textarea className="popup-confirm-custom__body--textarea" onChange={handleTextArea}>{workingSchedules[workingSchedules.findIndex(i => i.date === request.date)]?.comment}</textarea>
          );
        }}
        okText="変更"
        onConfirm={() => {
          checkRequest({});
        }}
        cancelText="キャンセル"
        onCancel={() => {
          setShowPopupConfirm(false);
        }}
        showPopup={showPopupConfirm}
        onToggle={() => setShowPopupConfirm(!showPopupConfirm)}
      />
    </div>
  );
};

export default RequestDetail;
