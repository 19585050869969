import React from "react";

const TableInnovation = () => {
  return (
      <div>
        <div className="text-center mt-2">
          <span>更新履</span>
        </div>
        <table className="responsive editTbl mb40 template_memo_table">
          <colgroup>
            <col style={{ width: '5%' }} />
            <col style={{ width: '7%' }} />
            <col style={{ width: '7%' }} />
            <col style={{ width: '7%' }} />
            <col style={{ width: '7%' }} />
            <col style={{ width: '15%' }} />
          </colgroup>

          <tbody>
          <tr>
            <th>記入時間</th>
            <th>画面名</th>
            <th>連絡者</th>
            <th>カテゴリ</th>
            <th>記入者</th>
            <th>コメント</th>
          </tr>
          <tr className="template_memo_editrow">
            <td rowSpan="4">
              <div align="center">現在時刻</div>
            </td>
            <td rowSpan="4">
              <div align="left">現在画面</div>
            </td>
            <td style={{ backgroundColor: '#E9D26D', padding: 4, margin: 4, lineHeight: 0 }} id="select1">
              <input type="radio" name="memo_type" id="memo_type1" value="作業員" />
              <label htmlFor="memo_type1">作業員</label>
            </td>
            <td rowSpan="4" className="changeBgtd">
              <div id="discribe_1" style={{ backgroundColor: 'rgb(233, 210, 109)', display: 'none' }}>
                <label htmlFor="memo_type_discribe1_1">
                  <input type="radio" name="memo_type_discribe" id="memo_type_discribe1_1" value="入館催促" />
                  入館催促
                </label>
                <label htmlFor="memo_type_discribe1_2">
                  <input type="radio" name="memo_type_discribe" id="memo_type_discribe1_2" value="入館受付" />
                  入館受付
                </label>
                <label htmlFor="memo_type_discribe1_3">
                  <input type="radio" name="memo_type_discribe" id="memo_type_discribe1_3" value="作業時間超過確認" />
                  作業時間超過確認
                </label>
                <label htmlFor="memo_type_discribe1_4">
                  <input type="radio" name="memo_type_discribe" id="memo_type_discribe1_4" value="作業内容確認" />
                  作業内容確認
                </label>
                <label htmlFor="memo_type_discribe1_5">
                  <input type="radio" name="memo_type_discribe" id="memo_type_discribe1_5" value="退館受付" />
                  退館受付
                </label>
              </div>
              <div id="discribe_2" style={{ backgroundColor: 'rgb(140, 214, 161)', display: 'none' }}>
                <label htmlFor="memo_type_discribe2_1">
                  <input type="radio" name="memo_type_discribe" id="memo_type_discribe2_1" value="作業員変更" />
                  作業員変更
                </label>
                <label htmlFor="memo_type_discribe2_2">
                  <input type="radio" name="memo_type_discribe" id="memo_type_discribe2_2" value="作業員情報催促" />
                  作業員情報催促
                </label>
                <label htmlFor="memo_type_discribe2_3">
                  <input type="radio" name="memo_type_discribe" id="memo_type_discribe2_3" value="稼働調整催促" />
                  稼働調整催促
                </label>
                <label htmlFor="memo_type_discribe2_4">
                  <input type="radio" name="memo_type_discribe" id="memo_type_discribe2_4" value="報告書催促" />
                  報告書催促
                </label>
              </div>
              <div id="discribe_3" style={{ backgroundColor: 'rgb(137, 190, 243)', display: 'none' }}>
                <label htmlFor="memo_type_discribe3">
                  <input type="radio" name="memo_type_discribe" id="memo_type_discribe3" value="登録情報追加・変更" />
                  登録情報追加・変更
                </label>
              </div>
              <div id="discribe_4" style={{ backgroundColor: 'rgb(255, 204, 153)', display: 'none' }}>
                <label htmlFor="memo_type_discribe4_1">
                  <input type="radio" name="memo_type_discribe" id="memo_type_discribe4_1" value="アポイント" />
                  アポイント
                </label>
                <label htmlFor="memo_type_discribe4_2">
                  <input type="radio" name="memo_type_discribe" id="memo_type_discribe4_2" value="当日変更連絡" />
                  当日変更連絡
                </label>

                <label htmlFor="memo_type_discribe4_3">
                  <input type="radio" name="memo_type_discribe" id="memo_type_discribe4_3" value="その他" />
                  その他
                </label>
              </div>
            </td>
            <td rowSpan="4">
              DoVan Tuan <input type="hidden" name="staff_code" value="BAP02" />
              <input type="hidden" name="receive_order_id" value="8" />
            </td>
            <td rowSpan="4">
              <div style={{ display: 'none' }} className="site_info_checkDiv">
                <span style={{ color: 'red' }}>※必須</span>
                <br />
                <input type="radio" name="site_info_check" id="site_info2" value="2" />
                <label htmlFor="site_info2">連絡OK 予定時刻</label>
                <div className="timepicker_diy-wrapper" style={{ position: 'relative', display: 'inline-block' }}>
                  <input type="text" className="timepicker_diy" name="newtime" size="4" />
                </div>
                <br />
                <input type="radio" name="site_info_check" id="site_info1" value="1" />
                <label htmlFor="site_info1">連絡NG・エスカ有り</label>
              </div>
              <textarea name="memo_discribe" cols="50" rows="6" id="memo"></textarea>
              <br />
              <input type="submit" value="記入" className="submit_memo" />
            </td>
          </tr>
          <tr style={{ verticalAlign: 'top' }}>
            <td style={{ backgroundColor: '#8CD6A1', padding: 4, margin: 4, lineHeight: 0 }} id="select2">
              <input type="radio" name="memo_type" id="memo_type2" value="パートナー" />
              <label htmlFor="memo_type2">パートナー</label>
            </td>
          </tr>
          <tr style={{ verticalAlign: 'top' }}>
            <td style={{ lineHeight: 0 }} id="select3">
              <input type="radio" name="memo_type" id="memo_type3" value="クライアント" />
              <label htmlFor="memo_type3">クライアント</label>
            </td>
          </tr>
          <tr style={{ verticalAlign: 'top' }}>
            <td style={{ backgroundColor: '#FFCC99', padding: 4, margin: 4, lineHeight: 0 }} id="select4">
              <input type="radio" name="memo_type" id="memo_type4" value="訪問先担当者" />
              <label htmlFor="memo_type4">訪問先担当者</label>
            </td>
          </tr>
          <tr style={{ verticalAlign: 'top' }}>
            <td bgcolor="#ffffff">
              <div align="center">2020/07/13 11:36:39</div>
            </td>
            <td bgcolor="#ffffff">稼働調整情報</td>
            <td bgcolor="#ffffff">操作履歴</td>
            <td bgcolor="#ffffff"></td>
            <td bgcolor="#ffffff">DoVan Tuan</td>
            <td bgcolor="#ffffff">変更履歴:【稼働調整日】0000-00-00 00:00:00→2020-07-13 11:36:33【調整状況】0→1</td>
          </tr>
          <tr style={{ verticalAlign: 'top' }}>
            <td bgcolor="#ffffff">
              <div align="center">2020/04/15 15:50:11</div>
            </td>
            <td bgcolor="#ffffff">受注情報</td>
            <td bgcolor="#ffffff">操作履歴</td>
            <td bgcolor="#ffffff"></td>
            <td bgcolor="#ffffff">管理01</td>
            <td bgcolor="#ffffff">変更履歴:【訪問日】2020-04-18 09:00:00→2020/04/18 09:00:00</td>
          </tr>
          <tr style={{ verticalAlign: 'top' }}>
            <td bgcolor="#89BEF3">
              <div align="center">2020/04/15 11:47:48</div>
            </td>
            <td bgcolor="#89BEF3">新規依頼</td>
            <td bgcolor="#89BEF3">操作履歴</td>
            <td bgcolor="#89BEF3"></td>
            <td bgcolor="#89BEF3"></td>
            <td bgcolor="#89BEF3"></td>
          </tr>
          </tbody>
        </table>
      </div>
  )
};

export default TableInnovation;
