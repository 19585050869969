import React, { useState, useEffect } from 'react';
import { EMAIL_SETTING } from 'constants/endpoints';
import { pushToast } from 'components/Toast';
import { compose } from 'redux';
import { translate } from 'react-i18next';
import { SCREEN_NAME } from '../../../../helpers/constants';
import _ from 'lodash';
import Loading from 'components/Loading/Loading';
import '../../../../assets/scss/page/MasterSettings/ModalMailUpdate.scss';

const ModalEmailUpdate = (props) => {
  const {
    closeModal,
    productId,
    idService,
    listMailSettings,
    numOfUpdate,
    idProvide,
    idProvider,
    filter,
    t,
    getListMailSettings,
    idRole,
  } = props;

  const defaultMailSetting = [
    {
      localNum: 1,
      title: '受注承認　期限切れ',
      body: '受注承認の期限を超過しています。受注情報から承認の処理をお願いします。',
      linkUrl: 'URL',
      toERPFlg: 1,
      toManagementFlg: 0,
      toMonitorFlg: 0,
      toWorkerFlg: 0,
      active: 0,
      state: 0,
      tabname: '承認',
    },
    {
      localNum: 2,
      title: '訪問日確定　期限切れ',
      body: '訪問日確定期限を超過しています。アポイント情報から訪問日の確定をお願いします。',
      linkUrl: 'URL',
      toERPFlg: 1,
      toManagementFlg: 0,
      toMonitorFlg: 0,
      toWorkerFlg: 0,
      active: 0,
      state: 0,
      tabname: 'アポイント',
    },
    {
      localNum: 3,
      title: '稼働調整　回答依頼',
      body: '稼働調整依頼をしています。以下URLから稼働可否の回答をお願いします。',
      linkUrl: 'URL',
      toERPFlg: 0,
      toManagementFlg: 1,
      toMonitorFlg: 0,
      toWorkerFlg: 0,
      active: 0,
      state: 0,
      tabname: '稼動調整',
    },
    {
      localNum: 4,
      title: '稼働調整　稼働確定',
      body: '稼働が確定しました。',
      linkUrl: '-',
      toERPFlg: 0,
      toManagementFlg: 1,
      toMonitorFlg: 0,
      toWorkerFlg: 0,
      active: 0,
      state: 0,
      tabname: '稼動調整',
    },
    {
      localNum: 5,
      title: '稼働調整　稼働見送り',
      body: '稼働回答を頂きましたが、稼働見送りとなりました。',
      linkUrl: '-',
      toERPFlg: 0,
      toManagementFlg: 1,
      toMonitorFlg: 0,
      toWorkerFlg: 0,
      active: 0,
      state: 0,
      tabname: '稼動調整',
    },
    {
      localNum: 6,
      title: '稼働調整 　期限切れ',
      body: '稼働回答を頂きましたが、稼働見送りとなりました。',
      linkUrl: '-',
      toERPFlg: 0,
      toManagementFlg: 1,
      toMonitorFlg: 0,
      toWorkerFlg: 0,
      active: 0,
      state: 0,
      tabname: '稼動調整',
    },
    {
      localNum: 7,
      title: '作業員登録依頼',
      body: '以下URLから作業員登録をお願いします。',
      linkUrl: 'URL',
      toERPFlg: 0,
      toManagementFlg: 1,
      toMonitorFlg: 0,
      toWorkerFlg: 0,
      active: 0,
      state: 0,
      tabname: '作業員',
    },
    {
      localNum: 8,
      title: '作業員登録　期限切れ',
      body: '作業員登録期限を超過しています。以下URLから作業員登録をお願いします。',
      linkUrl: 'URL',
      toERPFlg: 0,
      toManagementFlg: 1,
      toMonitorFlg: 0,
      toWorkerFlg: 0,
      active: 0,
      state: 0,
      tabname: '作業員',
    },
    {
      localNum: 9,
      title: '事前確認　対応依頼',
      body: '以下URLから事前確認をお願いします。',
      linkUrl: 'URL',
      toERPFlg: 0,
      toManagementFlg: 1,
      toMonitorFlg: 0,
      toWorkerFlg: 0,
      active: 0,
      state: 0,
      tabname: '事前確認',
    },
    {
      localNum: 10,
      title: '事前確認　申請期限切れ',
      body: '申請の期限を超過しています。以下URLから事前確認をお願いします。',
      linkUrl: 'URL',
      toERPFlg: 1,
      toManagementFlg: 1,
      toMonitorFlg: 0,
      toWorkerFlg: 0,
      active: 0,
      state: 0,
      tabname: '事前確認',
    },
    {
      localNum: 11,
      title: '事前確認　確認期限切れ',
      body: '事前確認の期限を超過しています。以下URLから事前確認をお願いします。',
      linkUrl: 'URL',
      toERPFlg: 1,
      toManagementFlg: 1,
      toMonitorFlg: 0,
      toWorkerFlg: 0,
      active: 0,
      state: 0,
      tabname: '事前確認',
    },
    {
      localNum: 12,
      title: '当日稼働 　期限切れ',
      body: '当日稼働は切れた',
      linkUrl: '-',
      toERPFlg: 1,
      toManagementFlg: 0,
      toMonitorFlg: 0,
      toWorkerFlg: 0,
      active: 0,
      state: 0,
      tabname: '当日稼動',
    },
    {
      localNum: 13,
      title: '当日稼働 　アラート解消',
      body: '当日稼働は解消された。',
      linkUrl: '-',
      toERPFlg: 1,
      toManagementFlg: 0,
      toMonitorFlg: 0,
      toWorkerFlg: 0,
      active: 0,
      state: 0,
      tabname: '当日稼動',
    },
    {
      localNum: 14,
      title: '当日稼働　未完了',
      body: '結果が「未完了」となりました。',
      linkUrl: '-',
      toERPFlg: 1,
      toManagementFlg: 1,
      toMonitorFlg: 1,
      toWorkerFlg: 1,
      active: 0,
      state: 0,
      tabname: '当日稼動',
    },
    {
      localNum: 15,
      title: '当日結果　当日CAN',
      body: '結果が「当日CAN」となりました。',
      linkUrl: '-',
      toERPFlg: 1,
      toManagementFlg: 1,
      toMonitorFlg: 1,
      toWorkerFlg: 1,
      active: 0,
      state: 0,
      tabname: '当日稼動',
    },
    {
      localNum: 16,
      title: '報告書　提出連絡',
      body: '報告書が提出されています。 ',
      linkUrl: '-',
      toERPFlg: 1,
      toManagementFlg: 0,
      toMonitorFlg: 0,
      toWorkerFlg: 0,
      active: 0,
      state: 0,
      tabname: '報告書回収',
    },
    {
      localNum: 17,
      title: '報告書　差戻連絡',
      body: '報告書が差戻されています。内容確認後、再提出をお願いします。',
      linkUrl: '-',
      toERPFlg: 0,
      toManagementFlg: 1,
      toMonitorFlg: 0,
      toWorkerFlg: 0,
      active: 0,
      state: 0,
      tabname: '報告書回収',
    },
    {
      localNum: 18,
      title: '報告書　再提出連絡',
      body: '報告書が再提出されています。',
      linkUrl: '-',
      toERPFlg: 1,
      toManagementFlg: 0,
      toMonitorFlg: 0,
      toWorkerFlg: 0,
      active: 0,
      state: 0,
      tabname: '報告書回収',
    },
    {
      localNum: 19,
      title: '報告書　提出期限切れ',
      body: '報告書提出期限を超過しています。以下URLから報告書の提出をお願いします。',
      linkUrl: '-',
      toERPFlg: 1,
      toManagementFlg: 1,
      toMonitorFlg: 0,
      toWorkerFlg: 0,
      active: 0,
      state: 0,
      tabname: '報告書回収',
    },
    {
      localNum: 20,
      title: '報告書　納品連絡',
      body: '報告書が納品されています。',
      linkUrl: '-',
      toERPFlg: 0,
      toManagementFlg: 0,
      toMonitorFlg: 1,
      toWorkerFlg: 0,
      active: 0,
      state: 0,
      tabname: '報告書納品',
    },
    {
      localNum: 21,
      title: '報告書　差戻連絡',
      body: '報告書が差戻されています。内容確認後、再納品をお願いします。',
      linkUrl: '-',
      toERPFlg: 1,
      toManagementFlg: 0,
      toMonitorFlg: 0,
      toWorkerFlg: 0,
      active: 0,
      state: 0,
      tabname: '報告書納品',
    },
    {
      localNum: 22,
      title: '報告書　再納品連絡',
      body: '報告書が再納品されています。',
      linkUrl: '-',
      toERPFlg: 0,
      toManagementFlg: 0,
      toMonitorFlg: 1,
      toWorkerFlg: 0,
      active: 0,
      state: 0,
      tabname: '報告書納品',
    },
    {
      localNum: 23,
      title: '報告書　納品期限切れ',
      body: '報告書納品期限を超過しています。以下URLから報告書の納品をお願いします。',
      linkUrl: '-',
      toERPFlg: 1,
      toManagementFlg: 0,
      toMonitorFlg: 1,
      toWorkerFlg: 0,
      active: 0,
      state: 0,
      tabname: '報告書納品',
    },
    {
      localNum: 24,
      title: '事前キャンセル連絡',
      body: '事前キャンセル処理が完了しました。',
      linkUrl: '-',
      toERPFlg: 1,
      toManagementFlg: 1,
      toMonitorFlg: 1,
      toWorkerFlg: 1,
      active: 0,
      state: 0,
      tabname: 'キャンセル',
    },
    {
      localNum: 25,
      title: '前日キャンセル連絡',
      body: '前日キャンセル処理が完了しました。',
      linkUrl: '-',
      toERPFlg: 1,
      toManagementFlg: 1,
      toMonitorFlg: 1,
      toWorkerFlg: 1,
      active: 0,
      state: 0,
      tabname: 'キャンセル',
    },
    {
      localNum: 26,
      title: '契約管理　確認',
      body: 'の確認をしました。',
      linkUrl: '-',
      toERPFlg: 0,
      toManagementFlg: 1,
      toMonitorFlg: 0,
      toWorkerFlg: 0,
      active: 0,
      state: 0,
      tabname: '契約管理',
    },
    {
      localNum: 27,
      title: '契約管理　差戻',
      body: 'が差戻されています。',
      linkUrl: '-',
      toERPFlg: 0,
      toManagementFlg: 1,
      toMonitorFlg: 0,
      toWorkerFlg: 0,
      active: 0,
      state: 0,
      tabname: '契約管理',
    },
    {
      localNum: 28,
      title: '契約管理　完了',
      body: '契約手続きを完了しました。',
      linkUrl: '-',
      toERPFlg: 0,
      toManagementFlg: 1,
      toMonitorFlg: 0,
      toWorkerFlg: 0,
      active: 0,
      state: 0,
      tabname: '契約管理',
    },
    {
      localNum: 29,
      title: '契約管理　提出',
      body: 'が提出されています。',
      linkUrl: '-',
      toERPFlg: 1,
      toManagementFlg: 0,
      toMonitorFlg: 0,
      toWorkerFlg: 0,
      active: 0,
      state: 0,
      tabname: '契約管理',
    },
    {
      localNum: 30,
      title: '契約管理　再提出',
      body: 'が再提出されています。',
      linkUrl: '-',
      toERPFlg: 1,
      toManagementFlg: 0,
      toMonitorFlg: 0,
      toWorkerFlg: 0,
      active: 0,
      state: 0,
      tabname: '契約管理',
    },
  ];

  const [loading, setLoading] = useState(false);
  const [listMailUpdate, setListMailUpdate] = useState(listMailSettings);

  const getTitleMailUpdate = (e) => {
    let newMailListUpdate = [...listMailUpdate];
    let newMailUpdate = { ...newMailListUpdate[numOfUpdate - 1], title: e.target.value };
    newMailListUpdate[numOfUpdate - 1] = newMailUpdate;
    setListMailUpdate(newMailListUpdate);
  };

  const getBodyMailUpdate = (e) => {
    let newMailListUpdate = [...listMailUpdate];
    let newMailUpdate = { ...newMailListUpdate[numOfUpdate - 1], body: e.target.value };
    newMailListUpdate[numOfUpdate - 1] = newMailUpdate;
    setListMailUpdate(newMailListUpdate);
  };

  const getListMailUpdate = async () => {
    try {
      setLoading(true);
      const data = await EMAIL_SETTING.GET_LIST_EMAIL_SETTINGS({ idProvide, productId, serviceId: idService });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
        setLoading(false);
      } else {
        setListMailUpdate(data?.data?.map((mail) => _.pick(mail, filter)) || []);
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const handleUpdateOneMail = async () => {
    const body = {
      mailSettings: listMailUpdate,
      screenName: SCREEN_NAME.MY_CORP,
      productId: productId,
      serviceId: idService,
    };
    try {
      setLoading(true);
      const data = await EMAIL_SETTING.CREATE_EMAIL_SETTING({ body, id: idProvider, idProvide });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
        setLoading(false);
      } else {
        await getListMailUpdate();
        await getListMailSettings();
        pushToast('success', t(`modal_setting_order_email.update_success`));
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const handleRestoreMail = async () => {
    let newlistMailRestore = [...listMailSettings];
    let mailRestore = {
      ...listMailSettings[numOfUpdate - 1],
      body: defaultMailSetting[numOfUpdate - 1].body,
      title: defaultMailSetting[numOfUpdate - 1].title,
    };
    newlistMailRestore[numOfUpdate - 1] = mailRestore;

    const body = {
      mailSettings: newlistMailRestore,
      screenName: SCREEN_NAME.MY_CORP,
      productId: productId,
      serviceId: idService,
    };
    try {
      setLoading(true);
      const data = await EMAIL_SETTING.CREATE_EMAIL_SETTING({ body, id: idProvider, idProvide });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
        setLoading(false);
      } else {
        await getListMailUpdate();
        await getListMailSettings();
        pushToast('success', t(`modal_setting_order_email.restore`));
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const getPlaceholderTo = () => {
    let roleName = [5, 6, 7, '5', '6', '7'].includes(idRole) ? 'ManagementGroup' : 'ERP：契約企業';
    let placeholder = `${listMailUpdate[numOfUpdate - 1].toERPFlg == 1 ? `${roleName} ` : ''}${
      listMailUpdate[numOfUpdate - 1].toManagementFlg == 1 ? 'Partner：協力企業 ' : ''
    }${listMailUpdate[numOfUpdate - 1].toMonitorFlg == 1 ? 'Monitoring ' : ''}${
      listMailUpdate[numOfUpdate - 1].toWorkerFlg == 1 ? 'Worker ' : ''
    }`;
    if (placeholder.split(' ').length - 1)
      return (placeholder = `送信先: ${placeholder.split(' ').join(', ').slice(0, -2)}`);
    return `送信先: ${placeholder}`;
  };

  return (
    <>
      <Loading loading={loading} />
      <div className="popup-update">
        <div className="d-flex justify-content-between header mb-3">
          <h2 className="py-1 px-2">{defaultMailSetting[numOfUpdate - 1].tabname}_送信内容</h2>
          <button onClick={closeModal}>X</button>
        </div>
        <div className="d-flex flex-column px-2 container-body">
          <div className="d-flex flex-column">
            <input className="w-100 mb-1" disabled placeholder={getPlaceholderTo()}></input>
            <div className="d-flex w-100 mb-1 justify-content-between">
              <label className="label-input w-25 mr-1">件名</label>
              <input
                className="w-75"
                type="text"
                value={listMailUpdate[numOfUpdate - 1].title}
                onChange={getTitleMailUpdate}
              />
            </div>
            <div className="d-flex w-100 mb-1 justify-content-between input-detail">
              <label className="label-input w-25 mr-1 d-flex justify-content-center align-items-center">
                <span>本文</span>
              </label>
              <textarea
                className="w-75"
                type="text"
                value={listMailUpdate[numOfUpdate - 1].body}
                onChange={getBodyMailUpdate}
              />
            </div>
          </div>
          <div>
            <button onClick={closeModal}>戻る</button>
            <button className="right-button" onClick={handleRestoreMail}>
              初期設定に戻す
            </button>
            <button className="right-button" onClick={handleUpdateOneMail}>
              保存
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default compose(translate('translations'))(ModalEmailUpdate);
