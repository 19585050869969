import React, { useState } from 'react';
import './style.scss';
import HeaderMasterSetting from '../../../components/HeaderMasterSetting';
import Encoding from 'encoding-japanese';
import { PRODUCT_ENDPOINT } from '../../../constants/endpoints';
import { useEffect } from 'react';
import { pushToast } from '../../../components/Toast';
import { useSelector, useDispatch } from 'react-redux';
import { compose } from 'redux';
import { translate } from 'react-i18next';
import MemoHistory from '../../../components/MemoHistory';
import { SCREEN_NAME } from '../../../helpers/constants';
import { getDataHistoryMasterSetting } from 'actions/authentication';
import FooterMaintenance from "components/FooterMaintenance";

const dataMock = [
  {
    primaryKey: '',
    secondaryKey: '',
  },
  {
    primaryKey: '',
    secondaryKey: '',
  },
  {
    primaryKey: '',
    secondaryKey: '',
  }
];
const SecondarCsv = ({ history, t }) => {
  const dispatch = useDispatch();
  const productId = process.browser && new URL(window.location.href).searchParams.get('productId');
  const schemaId = process.browser && new URL(window.location.href).searchParams.get('schemaId');
  const productName = decodeURIComponent(new URL(window.location.href).searchParams.get('productName')) || '';
  const colorScreen = useSelector((store) => store.utils.colorMe);
  const [fileUTF8, setFileUTF8] = useState(null);
  const [showCsvShift, setshowCsvShift] = useState([]);
  const [dataType, setDataType] = useState('');
  const [fieldMasters, setDataFiledMaster] = useState('');
  const [statusImport] = useState(true);
  const [dataSelect, setDataSelect] = useState(dataMock);
  const [showCsvShift1, setshowCsvShift1] = useState([]);

  const onChangeHandler = (event) => {
    const file = event.target.files[0];
    let reader = new FileReader();
    reader.onload = function(e) {
      let codes = new Uint8Array(e.target.result);
      let encoding = Encoding.detect(codes);
      let unicodeString = Encoding.convert(codes, {
        to: 'UNICODE',
        from: ['SJIS', 'UTF8'].includes(encoding) ? encoding : 'SJIS',
        type: 'string',
      });
      const arr_show = unicodeString.split('\n');
      const str_code = Encoding.stringToCode(unicodeString);
      const sjis_array = Encoding.convert(str_code, 'UTF8', 'UNICODE');
      const unit8Array = new Uint8Array(sjis_array);
      const file_affer = new File([unit8Array], { type: 'text/csv;charset=utf-8' });
      setFileUTF8(file_affer);
      let [fieldName, defaultValue] = arr_show?.slice(0, 2);
      [fieldName, defaultValue] = [fieldName?.split(','), defaultValue?.split(',')];
      const array_tmp = fieldName?.map((item, index) => {
        return { customName: item, checker: true, isEdit: false, type: 'string', defaultValue: defaultValue?.[index] };
      });
      if (showCsvShift1.length > 0) {
        setshowCsvShift([...showCsvShift, ...array_tmp]);
      } else {
        setshowCsvShift(array_tmp);
      }
    };
    if (file) {
      reader.readAsArrayBuffer(file);
      Array.from(document.querySelectorAll('input')).forEach((input) => (input.value = ''));
    }
  };
  const getCsvData = async () => {
    try {
      const data = await PRODUCT_ENDPOINT.GET_IMPORT_CSV_DATA_TYPE({});
      if (data) {
        setDataType(data?.data?.dataTypes);
        setDataFiledMaster(data?.data?.fieldMasters?.Items);
      }
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    getCsvData({});
  }, []);

  const handleChangText = (e, indexCheck) => {
    let index = e.target.selectedIndex;
    let optionElement = e.target.childNodes[index];
    let label = optionElement.getAttribute('data-label');
    let code = optionElement.getAttribute('data-code');
    let name = e.target.name;
    let value = e.target.value;
    const dataNew =
      showCsvShift &&
      showCsvShift.map((items, indexs) => {
        if (indexs === indexCheck) {
          return { ...items, [name]: value, label, codeInStella: code };
        }
        return items;
      });
    setshowCsvShift(dataNew);
  };

  const handleChange = (e, index) => {
    let name = e.target.name;
    let value = e.target.value;
    const dataNew =
      showCsvShift &&
      showCsvShift.map((items, indexs) => {
        if (indexs === index) {
          return { ...items, [name]: value };
        }
        return items;
      });
    setshowCsvShift(dataNew);
  };
  const handleChangeChecked = (e, index) => {
    const newData =
      showCsvShift &&
      showCsvShift.map((items, indexs) => {
        if (indexs === index) {
          return { ...items, checker: e.target.checked };
        }
        return items;
      });

    setshowCsvShift(newData);
  };

  const ImportCSV = async ({ productId, body }) => {
    try {
      const data = await PRODUCT_ENDPOINT.IMPORT_CSV_PRODUCT_ID({ body, productId });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          if (schemaId === 'undefined') {
            pushToast('error', data?.message?.extra);
          } else {
            pushToast('error', data?.message?.extra);
          }
        } else {
          if (schemaId === 'undefined') {
            pushToast('error', data?.message?.errorMessage);
          } else {
            pushToast('error', data?.message?.errorMessage);
          }
        }
      } else {
        if (schemaId === 'undefined') {
          pushToast('success', 'Update success');
          history.push('/importcsv-pri-sco');
        } else {
          pushToast('success', 'Update success');
        }
        getImportCsvDetail({ productId, schemaId });
        dispatch(getDataHistoryMasterSetting({ screenName: SCREEN_NAME.ORDER_SETTINGS }));
      }
    } catch (errorMessage) {
      console.log('errorMessage: ', errorMessage);
    }
  };
  const getImportCsvDetail = async ({ productId = '', schemaId = '' }) => {
    try {
      const data = await PRODUCT_ENDPOINT.GET_IMPORT_CSV_DETAIL({ productId, schemaId });
      if (data) {
        const newData = data?.data?.masterSecondary.map((item) => {
          if (item) {
            item.checker = true;
            return item;
          }
        });
        if (newData !== undefined) {
          setshowCsvShift(newData);
          setshowCsvShift1(newData);
        } else {
          setshowCsvShift([]);
          setshowCsvShift1([]);
        }
        if (data?.data?.keys) {
          setDataSelect(data?.data?.keys);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };
  useState(() => {
    if (productId) {
      getImportCsvDetail({ productId, schemaId });
    }
  }, [productId]);

  const onCLickSubmit = () => {
    const newData =
      showCsvShift &&
      showCsvShift
        ?.filter((elm) => elm.checker === true)
        .map((item, index) => {
          if (item.checker === true) {
            // delete item.checker;
          }
          const field = fieldMasters.find(e => e.label === item.label);
          if (field) {
            return {
              nameInStella: field?.nameInStella,
              codeInStella: item?.codeInStella,
              label: item?.label,
              customName: item?.customName,
              isEdit: item?.isEdit,
              type: item?.type,
              defaultValue: item?.defaultValue,
              sortNum: index,
            };
          } else {
            return {
              nameInStella: '',
              codeInStella: '',
              label: '',
              customName: item?.customName,
              isEdit: item?.isEdit,
              type: item?.type,
              defaultValue: item?.defaultValue,
              sortNum: index,
            };
          }
        });

    const newDatas =
      dataSelect &&
      dataSelect?.filter((item) => {
        if (item.primaryKey !== '' || item.secondaryKey !== '') {
          return {
            primaryKey: item?.primaryKey,
            secondaryKey: item?.secondaryKey,
          };
        }
      });
    if (schemaId === 'undefined') {
      ImportCSV({
        productId,
        body: {
          schema: {
            fieldMaster: newData,
            status: statusImport,
            keys: newDatas,
          },
          type: 'secondary',
          screenName: SCREEN_NAME.ORDER_SETTINGS,
          updateTitle: `保存 Order設定 ${productName || ''}`
        },
      });
    }

    if (schemaId !== 'undefined') {
      ImportCSV({
        productId,
        body: {
          schema: {
            fieldMaster: newData,
            status: statusImport,
            keys: newDatas,
          },
          type: 'secondary',
          schemaId,
          screenName: SCREEN_NAME.ORDER_SETTINGS,
          updateTitle: `保存 Order設定 ${productName || ''}`
        },
      });
    }
  };

  const renderItem = (item, index) => {
    return (
      <tr key={index}>
        <td className="text-center">
          <label className="container-checkbox">
            <input
              type="checkbox"
              name="isPrepared"
              checked={item.checker}
              onClick={(e) => handleChangeChecked(e, index)}
            />
            <span className="checkmark"></span>
          </label>
        </td>
        <td className="text-center">{item.customName}</td>
        <td className="text-center">
          <select name="type" onChange={(e) => handleChange(e, index)} value={item?.type}>
            <option value="">...</option>
            {Object.keys(dataType && dataType).map((items, indexs) => {
              return (
                <option key={indexs} value={dataType[items]}>
                  {t(`import_primary.${items}`)}
                </option>
              );
            })}
          </select>
        </td>

        <td className="text-center">
          <select name="nameInStella" onChange={(e) => handleChangText(e, index)} value={item?.nameInStella}>
            <option value="">...</option>
            {fieldMasters &&
              fieldMasters.map((items, indexs) => {
                return (
                  <option
                    key={indexs}
                    data-label={items.label}
                    data-code={items.codeInStella}
                    value={items?.nameInStella}
                  >
                    {items.label}
                  </option>
                );
              })}
          </select>
        </td>
        {/* <td className="text-center">
          <select name="isEdit" onChange={(e) => handleChange(e, index)} value={item?.isEdit}>
            <option value="">...</option>
            <option value="false">不可</option>
            <option value="true">可</option>
          </select>
        </td> */}
        <td className="text-center">
          <input type="text" name="defaultValue" value={item?.defaultValue} onChange={(e) => handleChange(e, index)} />
        </td>
      </tr>
    );
  };
  return (
    <div className="wrapper-secondar-csv-setting">
      <HeaderMasterSetting
        checkName="/importcsv-pri-sco"
        color={colorScreen?.code}
        colorText={colorScreen?.colorText}
      />
      <div className="wrapper-main ">
        <label className="btn btn btn-primary p-2" htmlFor="file">
          ファイル取込
        </label>
        {/* <div className="float-right">
          <ButtonTrueFalse status={statusImport} onChange={onChangeOrderExecutionApproval} />
        </div> */}
        {/* <table className="table table-borderless">
          <thead>
            <tr>
              <th scope="col">Key設定</th>
              <th scope="col"></th>
              <th scope="col"></th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {dataSelect &&
              dataSelect.map((item, index) => {
                return (
                  <tr key={index}>
                    <th scope="row">primary</th>
                    <td>
                      <input
                        type="text"
                        name="primaryKey"
                        value={item?.primaryKey}
                        onChange={(e) => handleChangeSelect(e, index)}
                      />
                    </td>
                    <th>secondar</th>
                    <td>
                      <input
                        type="text"
                        name="secondaryKey"
                        value={item?.secondaryKey}
                        onChange={(e) => handleChangeSelect(e, index)}
                      />
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table> */}
        <input type="file" name="file" id="file" className="d-none" onChange={onChangeHandler} />
        <div className="table-primary-csv-setting mt-3 ">
          <table className="table mb-0">
            <thead>
              <tr>
                <th className="text-center" scope="col"></th>
                <th className="text-center" scope="col">
                  取込項目名
                </th>
                <th className="text-center" scope="col">
                  形式
                </th>
                <th className="text-center" scope="col">
                  システム項目名
                </th>
                {/* <th className="text-center" scope="col">
                  編集
                </th> */}
                <th className="text-center" scope="col">
                  サンプル
                </th>
              </tr>
            </thead>
            <tbody>{showCsvShift && showCsvShift?.map((listItem, index) => renderItem(listItem, index))}</tbody>
          </table>
        </div>
      </div>

      <div className="text-right ">
        <button className="btn btn btn-primary p-2" onClick={() => onCLickSubmit()}>
          保存
        </button>
      </div>
      <div className='mt-1'>
        <MemoHistory colors={colorScreen?.code} colorText={colorScreen?.colorText} screenName={SCREEN_NAME.ORDER_SETTINGS}/>
      </div>
      <FooterMaintenance />
    </div>
  );
};

export default compose(translate('translations'))(SecondarCsv);
