import React from 'react';

const LoadingNew = (props) => {
  const { loading } = props;

  return (
    loading && (
      <div className="wrapper-loading">
        <div className="overlay d-flex justify-content-center align-items-center">
          <div className="lds-spinner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    )
  );
};

export default LoadingNew;
