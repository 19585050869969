import React, { useState, useEffect } from 'react';
import '../../../../../assets/scss/page/contract/PastPerformance.scss';
import { PAST_PERFORMANCE } from '../../../../../constants/endpoints';
import PaginationCustom from '../../../../../components/PaginationCustom/index.jsx';
import Loading from '../../../../../components/Loading/Loading.jsx';
import ServicesButton from '../../../../../components/ServicesButton/index.jsx';
import moment from 'moment';
import { DATE_JP_FORMAT } from '../../../../../helpers/constants';

const PastPerformance = (props) => {
  const { recruitmentId, organizationId, partnerId = '' } = props;
  const [loading, setLoading] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [listDataPastPerformance, setListDataPastPerformance] = useState([]);

  useEffect(() => {
    if (partnerId) {
      getRecruitmentDoneByPartner({
        page: activePage,
        limit: 10,
        recruitmentId: recruitmentId,
        organizationId: organizationId,
        partnerId,
      });
    }
  }, [recruitmentId, partnerId]);
  const getRecruitmentDoneByPartner = async ({ page, limit, organizationId, partnerId }) => {
    try {
      setLoading(true);
      const data = await PAST_PERFORMANCE.GET_RECRUIMENT_DONE_BY_PARNER({ page, limit, partnerId, organizationId });
      if (data?.data) {
        setListDataPastPerformance(data?.data);
      }
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const handleChangePage = (pageNumber) => {
    setActivePage(pageNumber);
    getRecruitmentDoneByPartner({
      page: pageNumber,
      limit: 10,
      partnerId,
      organizationId: organizationId,
    });
  };

  const renderItem = (item, index) => {
    return (
      <tr key={index}>
        <td>{item?.contractStatus?.contractType}</td>
        <td>{item?.clientName}</td>
        <td>{item?.productName}</td>
        <td>{item?.projectName}</td>
        <td>{item?.services != '' && <ServicesButton services={item?.services} />}</td>
        <td>
          {item?.contractStatus?.contractPeriodStart
            ? moment(new Date(item?.contractStatus?.contractPeriodStart)).format(DATE_JP_FORMAT)
            : ''}
        </td>
        <td>
          {item?.contractStatus?.contractPeriodEnd
            ? moment(new Date(item?.contractStatus?.contractPeriodEnd)).format(DATE_JP_FORMAT)
            : ''}
        </td>
        <td>{item?.contractStatus?.state}</td>
      </tr>
    );
  };

  return (
    <div className="detail-basic-information-wrapper">
      <div className="detail-basic-table-wrapper">
        <div className="title-absolute">過去実績</div>
        <div className="table-export-list-partner d-flex flex-column justify-content-center align-items-center">
          <Loading loading={loading} />
          <table className="perfomance-table table table-bordered">
            <tr>
              <th>契約種別</th>
              <th>Monitor</th>
              <th>Product</th>
              <th>Project</th>
              <th>Service</th>
              <th>契約期間From</th>
              <th>契約期間To</th>
              <th>契約状態</th>
            </tr>
            <tbody>{listDataPastPerformance?.data?.map((item, index) => renderItem(item, index))}</tbody>
          </table>
          {listDataPastPerformance?.count > 10 && (
            <PaginationCustom
              activePage={activePage}
              itemsCountPerPage={10}
              totalItemsCount={listDataPastPerformance?.count}
              pageRangeDisplayed={5}
              handlePageChange={handleChangePage}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default PastPerformance;
