import React from 'react';
import { getNoOrder } from '../../../../helpers/helpers';
import { Link } from 'react-router-dom';
import { translate } from 'react-i18next';
import { compose } from 'redux';

const Order = ({ orders = [], colors, activePage, t }) => {
  const renderItem = (item, index) => {
    return (
      <tr key={index}>
        <td className="no-wrapper">
          <div> {getNoOrder({ index: index, limit: 10, activePage: activePage })}</div>
        </td>
        {
          orders?.header?.map((i, ix) => (
            <td key={ix}>{item[i]}</td>
          ))
        }
        <td className='button--center'>
          <Link to={`/payment-date/update/${item.orderId}`}>
            <div className="button__edit">{t('payment_date.edit')}</div>
          </Link>
        </td>
      </tr>
    );
  };

  return (
    <div className="table-list-wrapper table-responsive">
      <table className={`table table-striped`}>
        <thead>
          <tr style={{ backgroundColor: `${colors}` }}>
            <th scope="col">{t('payment_date.no')}</th>
            {orders?.header?.map((item, index) => (
              <th scope="col" key={index}>
                {t(`table_order_list.${item}`)}
                <br />
                <div className="wrapper-sort">
                  <a>▲</a>
                  <a>▼</a>
                </div>
              </th>
            ))}
            <th scope="col">{t('payment_date.operation')}</th>
          </tr>
        </thead>
        <tbody>{orders?.body?.map((listData, index) => renderItem(listData, index, activePage))}</tbody>
      </table>
    </div>
  );
};

export default compose(translate('translations'))(Order);
