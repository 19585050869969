import React from 'react';
import img from '../../../../assets/img/current3.gif'
import './style.scss'
const Table = () => {
  return (
    <div className="list" align="center">
      <table className="currentTable">
        <colgroup>
          <col width="80" span="11" />
        </colgroup>
        <tbody>
          <tr>
            <th colSpan="3" className="before">
              稼働前
            </th>
            <th colSpan="5" className="today">
              本日稼働
            </th>
            <th colSpan="3" className="deliver">
              稼働後
            </th>
            <th rowSpan="2" className="cansel">
              キャンセル
            </th>
          </tr>
          <tr>
            <th className="gray">稼働調整前</th>
            <th className="gray">稼働調整中</th>
            <th className="gray">稼働待ち</th>
            <th className="gray">入館待ち</th>
            <th className="gray">入館中</th>
            <th className="gray">完了</th>
            <th className="gray">未完了</th>
            <th className="gray">当日CAN</th>
            <th className="gray">
              報告書
              <br />
              未回収
            </th>
            <th className="gray">
              報告書
              <br />
              未納品
            </th>
            <th className="gray">
              報告書
              <br />
              納品済
            </th>
          </tr>
          <tr>
            <td className="gray2"></td>

            <td className="gray2"></td>

            <td className="gray2"></td>

            <td className="gray2"></td>

            <td className="gray2"></td>

            <td className="gray2"></td>

            <td className="gray2"></td>

            <td className="gray2"></td>

            <td className="gray2"></td>

            <td className="gray2"></td>
            <td className="white2">
              <img src={img} />
            </td>
            <td className="gray2"></td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Table;
