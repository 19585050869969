import React, { useEffect, useState } from 'react';
import { translate } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import '../../../../assets/scss/page/contract/index.scss';
import Footer from '../../../../components/Footer';
import HeaderMasterSetting from '../../../../components/HeaderMasterSetting';
import PaginationCustom from '../../../../components/PaginationCustom';
import { ACCOUNTING_ENDPOINTS } from '../../../../constants/endpoints';
import { getNoOrder } from '../../../../helpers/helpers';
import LoadingNew from '../../../../components/LoadingNew';
import { CSVLink } from 'react-csv';

const dataMock = {
  page: 1,
  limit: 10,
  paramsSearch: '',
};

const ListAcceptance = ({ t }) => {
  const colorScreen = useSelector((store) => store.utils.colorMe);
  const history = useHistory();
  const [listData, setListData] = useState();
  const [activePage, setActivePage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [dataSearch, setDataSearch] = useState(dataMock);
  const [defaultCsv, setDefaultCsv] = useState('');

  const onReturn = () => {
    history.goBack();
  };

  const getListRecruitment = async ({ page = 1, limit = 10, paramsSearch = '' }) => {
    setLoading(true);
    try {
      const data = await ACCOUNTING_ENDPOINTS.LIST_OF_ACCEPTANCE_MONTHS({
        page: page,
        limit: limit,
        paramsSearch: paramsSearch,
      });
      if (data) {
        setListData(data?.data);
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getList = async ({ page = 1, limit = 10, paramsSearch = '' }) => {
    setLoading(true);
    try {
      const data = await ACCOUNTING_ENDPOINTS.LIST_OF_ACCEPTANCE_MONTHS({
        page: page,
        limit: limit,
        paramsSearch: paramsSearch,
      });
      if (data) {
        setDefaultCsv(data?.data?.body);
        document.getElementById(`download-csv`).click();
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleChangeSearch = (e) => {
    setDataSearch({ ...dataSearch, paramsSearch: e.target.value });
  };

  const submitSearch = () => {
    getListRecruitment(dataSearch);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      getListRecruitment(dataSearch);
    }
  };

  useEffect(() => {
    getListRecruitment({ page: activePage });
  }, [activePage]);

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const onDownLoadCSV = (e) => {
    getList({ page: 0 });
    e.stopPropagation();
  };

  const onDownload = (e) => {
    e.stopPropagation();
  };

  const renderItem = (item, index, activePage) => {
    return (
      <tr key={index}>
        <td>{getNoOrder({ index: index, limit: 10, activePage: activePage })}</td>
        <td>{item?.companyName}</td>
        <td>{item?.projectName}</td>
        <td>{item?.productName}</td>
        <td>{item?.serviceName}</td>
        <td>{item?.acceptanceMonth}</td>
        <td>{item?.localBase}</td>
        <td>{item?.localFollowUp}</td>
        <td>{item?.localDepartment}</td>
        <td>{item?.farFromTheSite}</td>
        <td>{item?.orderNumber}</td>
        <td>{item?.state}</td>
      </tr>
    );
  };

  return (
    <>
      <div className="main">
        <LoadingNew loading={loading} />
        <HeaderMasterSetting color={colorScreen?.code} colorText={colorScreen?.colorText} checkName="/contract" />
        <div className="block-contract">
          <div className="list-table">
            <button className="btn-back" onClick={onReturn}>
              戻る
            </button>
            <div className="table-list-contract">
              <div className="title-recruitment">募集一覧</div>
              <div className="list-button-contact d-flex justify-content-end">
                <div className="ml-2" style={{ color: `${colorScreen?.colorText}` }}>
                  <span className="item" onClick={onDownLoadCSV}>
                    CSV出力{' '}
                  </span>
                  <CSVLink className="text-decoration-none" data={defaultCsv} filename="default-csv.csv">
                    <span onClick={onDownload} id={`download-csv`}></span>
                  </CSVLink>
                </div>
              </div>
              <div className="block-search">
                <label>検索条件</label>
                <input
                  type="text"
                  value={dataSearch?.paramsSearch}
                  onKeyPress={(e) => handleKeyPress(e)}
                  onChange={handleChangeSearch}
                />
                <button to="#" className="item ml-2" onClick={() => submitSearch()}>
                  検索
                </button>
              </div>
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>No</th>
                    {listData?.header?.map((item, index) => (
                      <th key={index}>{t(`accounting_header.${item}`)}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>{listData && listData?.body.map((list, index) => renderItem(list, index, activePage))}</tbody>
              </table>
            </div>
            {listData?.count > 10 && (
              <PaginationCustom
                activePage={activePage}
                itemsCountPerPage={10}
                totalItemsCount={listData?.count}
                pageRangeDisplayed={5}
                handlePageChange={handlePageChange}
              />
            )}
          </div>
        </div>
        <Footer color={colorScreen?.code} colorText={colorScreen?.colorText} />
      </div>
    </>
  );
};

export default compose(translate('translations'), withRouter)(ListAcceptance);
