import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import '../../../../assets/scss/page/CsvMaster/index.scss';
import { CONTRACT_ENDPOINTS } from '../../../../constants/endpoints';
import Loading from 'components/Loading/Loading';
import _ from 'lodash';

const mockData = {
  providerId: '',
  provider: 'product',
  monitorId: '',
  projectId: '',
};

const ModalProduct = (props) => {
  const { toggle = () => {}, modal = false, recruitmentContractId, monitorId, projectId, onSubmit, dataTree } = props;
  const [dataProduct, setDataProduct] = useState(mockData);
  const [listProduct, setListProduct] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let objectTmp = dataProduct;
    if (monitorId) {
      objectTmp = { ...objectTmp, monitorId };
    }
    if (recruitmentContractId) {
      objectTmp = { ...objectTmp, recruitmentContractId };
    }
    if (projectId) {
      objectTmp = { ...objectTmp, projectId };
    }
    setDataProduct(objectTmp);
    getListProduct({ projectId });
  }, []);

  const getListProduct = async ({ projectId }) => {
    try {
      setLoading(true);
      const data = await CONTRACT_ENDPOINTS.GET_LIST_PRODUCT({ projectId });
      if (data) {
        setListProduct(_.filter(data?.data, function(elm) {
          return !Object.keys(dataTree[monitorId].projects[projectId].products || {}).includes(elm.productId)
        }));
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const addProduct = () => {
    onProductSubmit(dataProduct);
  };

  const onProductSubmit = async (body) => {
    onSubmit(body, 'product');
  };

  const handleSelect = (e) => {
    let newData = { ...dataProduct, providerId: e.target.value, providerName: e.target.options[e.target.selectedIndex].text };
    setDataProduct(newData);
  };

  return (
    <Modal className="form-modal-contract" isOpen={modal} toggle={() => toggle('product')}>
      <Loading loading={loading} />
      <ModalHeader toggle={() => toggle('product')}>Product新規登録</ModalHeader>
      <ModalBody>
        <div className="block-content">
          <div className="item-content">
            <label>Product_ID</label>
            <input type="text" value={dataProduct?.providerId} disabled />
          </div>
          <div className="item-content">
            <label>工事名</label>
            <select onChange={handleSelect}>
              <option>---</option>
              {listProduct?.map((item, index) => {
                return (
                  <option key={index} value={item.productId}>
                    {item.name}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <button className="btn-update" onClick={() => addProduct()}>
          登録
        </button>
      </ModalBody>
    </Modal>
  );
};

export default ModalProduct;
