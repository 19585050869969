import React from 'react';
import { Redirect } from 'react-router-dom';

import { shallowEqual, useSelector } from 'react-redux';

const BaseCheckAuthen = (props) => {
  const userMeData = useSelector((store) => store.authentication.roleMe, shallowEqual);

  return (
    <React.Fragment>
      {!userMeData?.data && <Redirect to="/login" />}
      {props.children}
    </React.Fragment>
  );
};

export default BaseCheckAuthen;
