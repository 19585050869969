import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import './style.scss';
import { EMAIL_SETTING, PLUGIN_SETTING } from 'constants/endpoints';
import { pushToast } from 'components/Toast';
import { compose } from 'redux';
import { translate } from 'react-i18next';
import ModalConfirmItemSetting from '../ModalConfirmItemSetting';
import { SCREEN_NAME } from '../../../../helpers/constants';
import ModalMailSetting from '../ModalEmailSetting/index.jsx';
import Loading from 'components/Loading/Loading';
import _ from 'lodash';
import { getDataHistoryMasterSetting } from 'actions/authentication';
import { useDispatch } from 'react-redux';

const ModalSettingOrder = (props) => {
  const {
    toggle = () => {},
    modal = false,
    provider,
    idProvider,
    idService,
    isReload,
    dataFetch,
    colors,
    t,
    statusBeforeConfirm,
    productIdTree,
    idProvide,
    idRole,
    nameProduct
  } = props;
  const defaultLoad = [
    '訪問先確認',
    '車両情報',
    '持参品確認',
    '部材確認',
    '当日使用品',
    '当日撤去品',
    '立会い者連絡',
    '鍵借用確認',
  ];

  const filter = [
    'localNum',
    'mailSettingId',
    'title',
    'body',
    'linkUrl',
    'toERPFlg',
    'toManagementFlg',
    'toMonitorFlg',
    'toWorkerFlg',
    'active',
    'state',
  ];
  const dataFetchOrigin = [
    {
      name: 'create_new_order',
    },
    {
      isUsed: true,
      approval: false,
      name: 'order_execution_approval',
    },
    {
      isUsed: true,
      roles: {
        erp: false,
        management: false,
      },
      name: 'appointment',
    },
    {
      isUsed: true,
      approval: false,
      name: 'operation_adjustment',
    },
    {
      extraData: [
        {
          name: '利用時',
          option: [],
          state: {
            option: false,
            unused: true,
            use: false,
          },
        },
      ],
      isUsed: false,
      name: 'worker_allocation',
    },
    {
      extraData: [
        {
          name: '申請管理',
          state: {
            unused: false,
            use: true,
          },
          option: [
            {
              name: '入館申請',
              settingDetail: [],
              state: {
                unused: false,
                use: true,
              },
              approval: {
                unused: false,
                use: true,
                option: false,
              },
            },
            {
              name: '共架申請',
              settingDetail: [],
              state: {
                unused: false,
                use: true,
              },
              approval: {
                unused: false,
                use: true,
                option: false,
              },
            },
            {
              name: '道路使用許可申請',
              settingDetail: [],
              state: {
                unused: false,
                use: true,
              },
              approval: {
                unused: false,
                use: true,
                option: false,
              },
            },
            {
              name: 'GC局入局申請',
              settingDetail: [],
              state: {
                unused: false,
                use: true,
              },
              approval: {
                unused: false,
                use: true,
                option: false,
              },
            },
            {
              name: '鍵借用',
              settingDetail: [],
              state: {
                unused: false,
                use: true,
              },
              approval: {
                unused: false,
                use: true,
                option: false,
              },
            },
          ],
        },
        {
          name: '作業員対応管理',
          state: {
            unused: false,
            use: true,
          },
          approval: {
            unused: false,
            use: true,
            option: false,
          },
          option: [
            {
              name: '訪問先確認',
              settingDetail: [],
              state: {
                unused: false,
                use: true,
              },
            },
            {
              name: '車両情報',
              settingDetail: [],
              state: {
                unused: false,
                use: true,
              },
            },
            {
              name: '持参品確認',
              settingDetail: [],
              state: {
                unused: false,
                use: true,
              },
            },
            {
              name: '部材確認',
              settingDetail: [],
              state: {
                unused: false,
                use: true,
              },
            },
            {
              name: '当日使用品',
              settingDetail: [],
              state: {
                unused: false,
                use: true,
              },
            },
            {
              name: '当日撤去品',
              settingDetail: [],
              state: {
                unused: false,
                use: true,
              },
            },
            {
              name: '立会い者連絡',
              settingDetail: [],
              state: {
                unused: false,
                use: true,
              },
            },
            {
              name: '鍵借用確認',
              settingDetail: [],
              state: {
                unused: false,
                use: true,
              },
            },
          ],
        },
      ],
      isUsed: true,
      approval: true,
      name: 'confirmed_the_day_before',
    },
    {
      isUsed: true,
      name: 'confirmed',
    },
    {
      isUsed: true,
      approval: true,
      name: 'report_collection',
    },
    {
      isUsed: true,
      name: 'report_delivery',
    },
    {
      isUsed: true,
      approval: true,
      name: 'acceptance_confirmation',
    },
  ];
  const dispatch = useDispatch();
  const [listPlugin, setListPlugin] = useState([]);
  const [isDetail, setisDetail] = useState(isReload);
  const [objDetail, setobjDetail] = useState([]);
  const [nameSetting, setnameSetting] = useState([]);
  const [pluginSettingId, setPluginSettingId] = useState('');
  const [pluginSettingBefore, setPluginSettingBefore] = useState('');
  const [dataOrigin, setdataOrigin] = useState([]);
  const [keyName, setkeyName] = useState({});
  const [loading, setLoading] = useState(false);

  const [isSwitchModal, setIsSwitchModal] = useState(true);
  const [dataMailFetch, setDataMailFetch] = useState([]);
  const [isHidden, setIsHidden] = useState(false);

  useEffect(() => {
    setdataOrigin(dataFetch);
    setPluginSettingId(dataFetch?.pluginSettingId);
    setListPlugin(dataFetch?.pluginSettings?.length > 0 ? dataFetch?.pluginSettings : dataFetchOrigin);
    getDataMailSetting();
  }, [modal]);

  const getDataMailSetting = async () => {
    try {
      setLoading(true);
      const data = await EMAIL_SETTING.GET_LIST_EMAIL_SETTINGS({
        idProvide,
        productId: productIdTree,
        serviceId: idService,
      });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
        setLoading(false);
      } else {
        setDataMailFetch(data?.data?.map((mail) => _.pick(mail, filter)) || []);
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const changeExtra = (parentName, name, e) => {
    let value = e.target.value;
    const data = listPlugin.map((item) => {
      item.name === parentName &&
        item?.extraData &&
        item.extraData.map((i) => {
          if (i.name === name) {
            Object.keys(i.state).forEach(function (key) {
              i.state[key] = key === value;
            });
            return item;
          }
        });
      return item;
    });
    setListPlugin(data);
  };

  const getListPluginSettings = async () => {
    try {
      setLoading(true);
      const data = await PLUGIN_SETTING.GET_LIST_PLUGIN_SETTINGS({
        idProvide,
        providerId: idProvider,
        provider,
        productId: provider === 'product' ? '' : productIdTree,
      });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
        setLoading(false);
      } else {
        setListPlugin(data?.data[0]?.pluginSettings || []);
        setdataOrigin(data?.data[0]);
        setPluginSettingId(data?.data[0]?.pluginSettingId);
        setPluginSettingBefore(data?.data[0]?.confirmedTheDayBefore);
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const changeOption = (parentName, chilName, name, e) => {
    let value = e.target.value;
    const data = listPlugin.map((item) => {
      item.name === parentName &&
        item?.extraData &&
        item.extraData.map((i, idx) => {
          i.name === chilName &&
            i?.option &&
            i.option.map((opt) => {
              if (opt.name === name) {
                Object.keys(opt.state).forEach(function (key) {
                  opt.state[key] = key === value;
                });
                return item;
              }
            });
        });
      return item;
    });
    setListPlugin(data);
  };

  const switchToModalWorkflow = () => {
    setIsSwitchModal(true);
    setIsHidden(false);
    getListPluginSettings();
  };

  const switchToModalEmail = async () => {
    getDataMailSetting();
    setIsSwitchModal(false);
  };

  const handleCallApiUpdatePlugin = async () => {
    const body = {
      provider: provider,
      providerId: idProvider,
      pluginSettings: listPlugin,
      productId: provider === 'product' ? '' : productIdTree,
      screenName: SCREEN_NAME.MY_CORP,
      updateTitle : `設定を保存　ワークフロー設定 ${nameProduct ? nameProduct : ''}`
    };
    const id = idProvider;
    try {
      setLoading(true);
      const data = await PLUGIN_SETTING.CREATE_PLUGIN_SETTING(body, id, idProvide);
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
        setLoading(false);
      } else {
        getListPluginSettings();
        pushToast('success', t(`modal_setting_order.update_success`));
        dispatch(getDataHistoryMasterSetting({ logId : '', screenName: SCREEN_NAME.MY_CORP, idProvide }));
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const handleUpdatePlugin = () => {
    handleCallApiUpdatePlugin();
  };

  const sortOption = (state) =>
    _.sortBy(Object.keys(state), function (i) {
      return ['use', 'unused', 'option'].indexOf(i);
    });

  const showModalEmailUpdate = () => {
    setIsHidden(true);
  };

  const hideModalEmailUpdate = () => {
    setIsHidden(false);
  };

  const radioStatus = (parentName, chilName, item, isCheck) => {
    return (
      <>
        <li className={isCheck ? '' : 'check-item--list-style'}>
          <label>{item?.name}</label>
        </li>
        {sortOption(item?.state)?.map((k, index) => (
          <div className="check-item check-item--pl30" key={`option${index}`}>
            <input
              type="radio"
              name={item?.name}
              value={k}
              defaultChecked={item.state[k]}
              onChange={(e) =>
                isCheck ? changeOption(parentName, chilName, item.name, e) : changeExtra(parentName, item.name, e)
              }
            />
            <label>{t(`modal_setting_order.${k}`)}</label>
          </div>
        ))}
        {/* {
          !_.isEmpty(item?.approval) && (<div style={{paddingLeft: '15px'}}>承認機能</div>)
        } */}
        {/* {
          !_.isEmpty(item?.approval) && sortOption(item?.approval)?.map((k, index) => (
            <div className="check-item check-item--pl30" key={`approval${index}`}>
              <input
                type="radio"
                name={`approval_${item?.name}`}
                value={k}
                defaultChecked={item.approval[k]}
                onChange={(e) => isCheck ? changeApproval(parentName, chilName, item.name, e) : changeExtraApproval(parentName, item.name, e)}
              />
              <label>{t(`modal_setting_order.${k}`)}</label>
            </div>
          ))
        } */}
        {/* {isCheck && !!dataOrigin?.pluginSettingId && defaultLoad.includes(item?.name) && (
          <div className='form-btn-setting form-btn-setting--detail button__plugin--resize'>
            <button onClick={(e) => handleDetail(e, item, parentName, chilName)} className='form-btn-setting--min'>
              {t(`modal_setting_order.setting`)}
            </button>
          </div>
        )} */}
      </>
    );
  };

  const changeIsUsed = (name, status) => {
    const data = listPlugin.map((item) => {
      if (item.name === name) {
        if (typeof status === 'boolean') {
          return { ...item, isUsed: status };
        } else {
          return { ...item, isUsed: '' };
        }
      }
      return item;
    });
    setListPlugin(data);
  };

  const changeApprovalFunction = (name, status) => {
    const data = listPlugin.map((item) => {
      if (item.name === name) {
        if (typeof status === 'boolean') {
          return { ...item, approval: status };
        } else {
          return { ...item, approval: '' };
        }
      }
      return item;
    });
    setListPlugin(data);
  };

  const changeAppointmentClassification = (name, status) => {
    const data = listPlugin.map((item) => {
      if (item.name === name) {
        if (typeof status === 'boolean') {
          return {
            ...item,
            roles: {
              erp: status,
              management: !status,
            },
          };
        } else {
          return {
            ...item,
            roles: {
              erp: !status,
              management: status,
            },
          };
        }
      }
      return item;
    });
    setListPlugin(data);
  };

  const showListStatus = (item) => {
    if (item.hasOwnProperty('isUsed')) {
      return (
        <>
          <div className="form-checkbox">
            <div className="check-item check-item--pl30">
              <input
                type="radio"
                name={item.name}
                defaultChecked={item.isUsed === true}
                onChange={() => changeIsUsed(item.name, true)}
              />
              <label>利用</label>
            </div>

            <div className="check-item check-item--pl30">
              <input
                type="radio"
                name={item.name}
                defaultChecked={item.isUsed === false}
                onChange={() => changeIsUsed(item.name, false)}
              />
              <label>未使用</label>
            </div>
            {item.name == 'appointment' && (
              <>
                <p className="mb-0 ml-4">アポイント区分</p>
                <div className="check-item check-item--pl30">
                  <input
                    type="radio"
                    name={`${item.name}roles`}
                    defaultChecked={item.roles?.erp}
                    onChange={() => changeAppointmentClassification(item.name, true)}
                  />
                  <label>{[5, 6, 7, '5', '6', '7'].includes(idRole) ? 'ManagementGroup' : 'ERP'}</label>
                </div>

                <div className="check-item check-item--pl30">
                  <input
                    type="radio"
                    name={`${item.name}roles`}
                    defaultChecked={item.roles?.management}
                    onChange={() => changeAppointmentClassification(item.name, false)}
                  />
                  <label>Management</label>
                </div>
              </>
            )}

            {item.name == 'operation_adjustment' && (
              <>
                <p className="mb-0 ml-4">作業員返答区分</p>
                <div className="check-item check-item--pl30">
                  <input
                    type="radio"
                    name={`${item.name}approval`}
                    defaultChecked={item.approval === true}
                    onChange={() => changeApprovalFunction(item.name, true)}
                  />
                  <label>必須</label>
                </div>

                <div className="check-item check-item--pl30">
                  <input
                    type="radio"
                    name={`${item.name}approval`}
                    defaultChecked={item.approval === false}
                    onChange={() => changeApprovalFunction(item.name, false)}
                  />
                  <label>不要</label>
                </div>
              </>
            )}

            {(item.name == 'order_execution_approval' ||
              item.name == 'confirmed_the_day_before' ||
              item.name == 'report_collection' ||
              item.name == 'acceptance_confirmation') && (
              <>
                <p className="mb-0 ml-4">承認機能</p>
                <div className="check-item check-item--pl30">
                  <input
                    type="radio"
                    name={`${item.name}approval`}
                    defaultChecked={item.approval === true}
                    onChange={() => changeApprovalFunction(item.name, true)}
                  />
                  <label>利用</label>
                </div>

                <div className="check-item check-item--pl30">
                  <input
                    type="radio"
                    name={`${item.name}approval`}
                    defaultChecked={item.approval === false}
                    onChange={() => changeApprovalFunction(item.name, false)}
                  />
                  <label>未使用</label>
                </div>
              </>
            )}
            {item.name === 'worker_allocation' && (
              <div className="check-item check-item--pl30">
                {/* <input
                  type="radio"
                  name={item.name}
                  defaultChecked={item.isUsed === ''}
                  onChange={() => changeIsUsed(item.name, '')}
                /> */}
                {/* <label>{t(`modal_setting_order.option`)}</label> */}
              </div>
            )}
          </div>
          {item?.extraData &&
            item.isUsed &&
            item.name !== 'worker_allocation' &&
            item.extraData.map((i, index) => {
              if (
                i.name === '作業員対応管理' &&
                ((!pluginSettingId && !statusBeforeConfirm) || pluginSettingBefore === false)
              )
                return;
              return (
                <div key={index}>
                  <div className="form-check-child">{radioStatus(item.name, '', i, false)}</div>
                  {i?.option &&
                    i.state.use &&
                    i?.option.map((it, idx) => (
                      <div className="form-check-child form-check-child--sub-child" key={idx}>
                        {radioStatus(item.name, i.name, it, true)}
                      </div>
                    ))}
                </div>
              );
            })}
        </>
      );
    }
  };

  return (
    <div>
      <Loading loading={loading} />
      <Modal
        className="modal-dialog-custom modal-dialog-custom--resize"
        onClosed={switchToModalWorkflow}
        isOpen={modal}
        toggle={toggle}
      >
        <ModalHeader className={!isHidden ? `` : `hidden-item`} toggle={toggle}>
          {t(`modal_setting_order.${isDetail ? 'title_setting_status_order' : 'title_confirm_setting'}`)}
        </ModalHeader>
        <ModalBody className={!isHidden ? '' : `modal-body-after`}>
          <div className={!isHidden ? `group-btn-switch-modal` : `hidden-item`}>
            <button className={isSwitchModal ? 'focus' : ''} onClick={switchToModalWorkflow}>
              ワークフロー
            </button>
            <button className={!isSwitchModal ? 'focus' : ''} onClick={switchToModalEmail}>
              メール送信設定
            </button>
          </div>
          {isSwitchModal ? (
            <>
              {isDetail ? (
                <div className="form-body form--height-unset">
                  {listPlugin &&
                    listPlugin.map((item, index) => (
                      <div key={index} className="item-content plugin-list">
                        <div className="form-title">
                          <p>{index + 1}.</p>
                          <p className="text-capitalize">{t(`modal_setting_order.${item.name}`)}</p>
                        </div>
                        {showListStatus(item)}
                      </div>
                    ))}
                  <div className="form-btn-setting">
                    <button onClick={handleUpdatePlugin}>{t(`modal_setting_order.save`)}</button>
                  </div>
                </div>
              ) : (
                <ModalConfirmItemSetting
                  dataSetting={objDetail}
                  dataOrigin={dataOrigin}
                  colors={colors}
                  provider={provider}
                  idProvider={idProvider}
                  nameSetting={nameSetting}
                  keyName={keyName}
                  idProvide={idProvide}
                  pluginSettingId={pluginSettingId}
                  productId={productIdTree}
                  nameProduct={nameProduct}
                />
              )}
            </>
          ) : (
            <ModalMailSetting
              switchToModalEmail={switchToModalEmail}
              isSwitchModal={isSwitchModal}
              switchToModalWorkFlow={switchToModalWorkflow}
              provider={provider}
              idProvider={idProvider}
              idService={idService}
              isReload={isReload}
              modal={modal}
              t={t}
              toggle={toggle}
              isDetail={isDetail}
              productIdTree={productIdTree}
              idProvide={idProvide}
              dataMailFetch={dataMailFetch}
              filter={filter}
              isHidden={isHidden}
              showModalEmailUpdate={showModalEmailUpdate}
              hideModalEmailUpdate={hideModalEmailUpdate}
              idRole={idRole}
              nameProduct={nameProduct}
            />
          )}
        </ModalBody>
      </Modal>
    </div>
  );
};

export default compose(translate('translations'))(ModalSettingOrder);
