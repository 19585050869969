import React, { useEffect, useState } from 'react';
import Header from 'components/Header';
import Footer from 'components/Footer';
import { ORDER } from 'constants/endpoints';
import { SCREEN_NAME } from 'helpers/constants';
import { pushToast } from 'components/Toast';
import { getRole } from 'helpers/helpers';
import 'assets/scss/page/DetailCancelMenu/style.scss';
import DetailData from 'components/DetailCenter/DetailData';
import DetailDataList from 'components/DetailCenter/DetailDataList';
import DetailOrder from 'components/DetailCenter/DetailOrder';
import DetailListAllData from 'components/DetailCenter/DetailListAllData';
import { Link, useHistory } from 'react-router-dom';
import DetailTreeDocumentSetting from 'components/DetailTreeDocumentSetting';
import PopupConfirmCustom from 'components/popupConfirmCustom';
import DetailTimeWorker from 'components/DetailOrderErp/DetailTimeWorker';
import DetailResult from 'components/DetailOrderErp/DetailResult';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { Fragment } from 'react';

const mockData = {
  screenName: SCREEN_NAME.CANCEL_MENU,
  recycle: true,
  resultCancelInfo: '',
  reasonForCancellation: '',
  statusCancelMenu: false,
  settingCancel: '',
};

const DetailCancelMenu = () => {
  const [role, setRole] = React.useState(null);
  const [idRole, setIdRole] = React.useState(null);
  const [dataOrder, setDataOrder] = useState(mockData);
  const [data, setData] = useState([]);
  let orderId = process.browser && new URL(window.location.href).searchParams.get('orderId');
  let name = (process.browser && new URL(window.location.href).searchParams.get('p')) || 'cancel_menu';
  let organizationId = (process.browser && new URL(window.location.href).searchParams.get('organizationId')) || '';
  const [p, setP] = React.useState(name);
  const colorScreen = useSelector((store) => store.utils.colorMe);
  const [statusCancel, setStatusCancel] = useState([]);
  const [listDetailScreen, setListDetailScreen] = useState([]);
  const history = useHistory();
  const [showPopupConfirm, setShowPopupConfirm] = useState(false)
  const [dataDetailTimeWorker, setDataDetailTimeWorker] = useState({})

  useEffect(() => {
    getListRoleChild();
  }, []);

  const getListRoleChild = async () => {
    const newRole = localStorage.getItem('role');
    try {
      const data = await ORDER.GET_LIST_DETAIL_SETTING({
        arrRole: newRole,
        nameScreen: 'cancel_menu',
        orderId,
        organizationId
      });
      if (data) {
        setListDetailScreen(data ?.data ?.detailScreen.concat(
          {name: "documentSetting", sortNum: 99, status: true}
        ));
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    setP(name);
  }, [name]);

  const getOrderDetail = async ({ orderId = '' }) => {
    try {
      const data = await ORDER.GET_DETAIL_CANCEL_INFO({ orderId, screenName: 'cancel_menu', organizationId });
      if (data) {
        setData(data?.data);
        getStatusCancel({ serviceId: data?.data?.serviceId, productId: data?.data?.productId, orderId: data?.data?.orderId });
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (data) {
      setDataOrder({
        ...dataOrder,
        resultCancelInfo: data.resultCancelInfo,
        reasonForCancellation: data.reasonForCancellation,
        settingCancel: data.settingCancel,
      });
      setDataDetailTimeWorker(data)
    }
  }, [data]);

  useEffect(() => {
    const newRole = localStorage.getItem('role');
    setIdRole(newRole);
    if (newRole) {
      setRole(getRole({ idRole: newRole }));
    }

    if (orderId) {
      getOrderDetail({
        orderId: orderId,
      });
    }
  }, [orderId]);

  const handleChangeText = (e) => {
    let name = e.target.name;
    let newData = { ...dataOrder };
    if (name === 'reasonForCancellation') {
      newData = { ...newData, [name]: e.target.value, reasonForCancellation: e.target.value };
    } else {
      newData = { ...newData, [name]: e.target.value };
    }
    setDataOrder(newData);
  };

  const handleChangeSelect = (e) => {
    const value = e.target.value;
    let newData = { ...dataOrder };
    const settingCancel = statusCancel.filter(i => i.id === value);
    newData = { ...newData, settingCancel: value, settingCancelResult: settingCancel?.[0], resultCancelInfo: settingCancel?.[0]?.pullDownDisplay || "" };
    setDataOrder(newData);
  };

  const updateOrderInfo = async ({ body = {}, id_order = '' }) => {
    try {
      const data = await ORDER.UPDATE_CANCEL_MENU({ body: body, id_order: id_order, organizationId });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
      } else {
        pushToast('success', 'Update cancel info success');
        if(body?.recycle) {
          return history.push(`/recreate/detail_recreate/${id_order}`);
        } else {
          return history.push(`/list?p=cancel_menu`);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getStatusCancel = async ({ serviceId, productId, orderId, type = 'cancelSetting' }) => {
    try {
      const data = await ORDER.GET_STATUS_ORDER({ serviceId, productId, orderId, type, organizationId });
      if (data) {
        setStatusCancel(data?.data);
      }
    } catch (e) {
      console.log(e);
    }
  }

  const onUpdateOrder = (data = {}) => {
    updateOrderInfo({ body: { ...dataOrder, ...data, updateTitle: `保存 キャンセル結果：${dataOrder?.settingCancelResult?.pullDownDisplay || ''}`, companyName: localStorage.getItem('companyName') }, id_order: orderId });
  };

  const onPopup = (text) => {
    if(!_.isEmpty(dataOrder?.settingCancel)) {
      setShowPopupConfirm(!showPopupConfirm);
    } else {
      onUpdateOrder({ recycle: false , updateTitle : text})
    };
  }

  return (
    <div className="wrapper-detail-cancel-menu">
      <div className="main">
        <Header p={p} idRole={idRole} color={colorScreen?.code} colorText={colorScreen?.colorText}/>
        <div className="body-wrapper">
          <div className="logout_btn">
            <Link to={`list?p=${p}`}>
              <span className="mypage_ab_custom">戻る</span>
            </Link>
          </div>
          {listDetailScreen?.map((item, index) => {
            if (item.status) {
              switch (item.name) {
                case 'clientCompany':
                  return <DetailOrder data={data} p={p} key={index} />;
                case 'applicationCompany':
                  return <DetailData data={data} p={p} link={false} key={index} />;
                case 'visitDateWorker':
                  return <DetailTimeWorker screenName={SCREEN_NAME.CANCEL_MENU} data={dataDetailTimeWorker} key={index} />;
                case 'cancelResult':
                  return (
                    <Fragment key={index}>
                      <div key={index}>
                        <div className="detail-cancel-table-wrappers detail-cancel-table-wrappers--cancel-result">
                          <div className="title-absolute"> キャンセル結果 </div>
                          <div className="cancel-result-group">
                            <div className="label">キャンセル結果</div>
                            <select
                              name="settingCancel"
                              onChange={handleChangeSelect}
                              value={dataOrder?.settingCancel}
                              className="selection-reason"
                            >
                              <option value=""></option>
                              {
                                statusCancel?.map((item, index) => {
                                  return <option value={item.id} key={index}>{item.pullDownDisplay}</option>
                                })
                              }
                            </select>
                            <div className="label label--large">結果備考</div>
                            <textarea
                              value={dataOrder?.reasonForCancellation}
                              onChange={handleChangeText}
                              name="reasonForCancellation"
                              cols="100"
                              rows="2"
                              className="input-reason"
                            >
                              {dataOrder?.reasonForCancellation}
                            </textarea>
                          </div>
                        </div>
                        <div className="group-action-update-order">
                          <span className="btn-update-order__wrap">
                            <button className="btn-update-order" onClick={() => onPopup(`保存 キャンセル結果：${dataOrder?.settingCancelResult?.pullDownDisplay || ''}`)} >
                            キャンセル実行
                            </button>
                            <PopupConfirmCustom
                              headerTitle='確認'
                              rederBody={() => {
                                return (
                                  <>
                                    <div>キャンセルを受け付けました。</div>
                                    <div>この情報を複製して物件情報を作成しますか？</div>
                                  </>
                                )
                              }}
                              okText="はい"
                              onConfirm={() => {
                                onUpdateOrder({ recycle: true, updateTitle: `保存 キャンセル結果：${dataOrder?.settingCancelResult?.pullDownDisplay || ''}` })
                                setShowPopupConfirm(false)
                              }}
                              cancelText="いいえ"
                              onCancel={() => {
                                onUpdateOrder({ recycle: false, updateTitle: `保存 キャンセル結果：${dataOrder?.settingCancelResult?.pullDownDisplay || ''}` })
                                setShowPopupConfirm(false)
                              }}
                              showPopup={showPopupConfirm}
                              onToggle={() => setShowPopupConfirm(!showPopupConfirm)}
                            />
                          </span>
                        </div>
                      </div>
                      <DetailResult orderId={orderId} />
                    </Fragment>
                  );
                case 'detailInfo':
                  return <DetailListAllData data={data} screenName={SCREEN_NAME.CANCEL_MENU} key={index} />;
                case 'document':
                  return <DetailTreeDocumentSetting orderId={orderId} order={data} key={index} />;
                case 'history':
                  return <DetailDataList datePick={false} screenName={SCREEN_NAME.CANCEL_MENU} orderId={orderId} key={index} />;
                default:
                  break;
              }
            }
          })}
        </div>
        <Footer color={colorScreen?.code} colorText={colorScreen?.colorText} />
      </div>
    </div>
  );
};

export default DetailCancelMenu;
