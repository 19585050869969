import React, { useEffect, useState } from 'react';
import { pushToast } from '../../../../../components/Toast';
import { ORDER } from '../../../../../constants/endpoints';
import { getRole } from '../../../../../helpers/helpers';
import Loading from 'components/Loading/Loading';
import 'assets/scss/page/AnkenMasterNew/TableAddRole.scss';

const TableAddRole = ({ ModalAddRoleNextChild = () => { }, ModalDetailSetting = () => { }, routeList, namePermission, idProvide, idProduct, statusBeforeConfirm }) => {
  const checkRole = localStorage.getItem('role');

  const renderTableInfo = (ListHeaderRole = []) => {
    return (
      <table className="table table-bordered">
        <thead>
          <tr>
            <th colSpan="3">利用画面設定</th>
          </tr>
        </thead>
        <tbody>
          { ListHeaderRole?.data?.filter((item) => item.name !== "equipment-management").map((item, index) => {
              const arrRole = getRole({ idRole: item.roles[0] })?.toLowerCase();
              return (
                ![7, '7'].includes(checkRole) && item.name === 'orderGroup' ? '' : (
                  <tr key={index} className={routeList === '12' && item.name}>
                    <td>{item.label}</td>
                    <td>
                      <span className="input-check">
                        <input
                          type="radio"
                          name={`active-${index}`}
                          value={true}
                          disabled
                          checked={item.active}
                          onChange={(e) => handleOnChange(e, item)}
                        />
                        <label>表示</label>
                      </span>
                      <span className="input-check">
                        <input
                          type="radio"
                          name={`active-${index}`}
                          value={false}
                          disabled
                          checked={!item.active}
                          onChange={(e) => handleOnChange(e, item)}
                        />
                        <label>非表示</label>
                      </span>
                    </td>
                    <td>
                      <div className="form-btn-modal">
                        <button className="btn-customize-modal" onClick={() => ModalAddRoleNextChild(arrRole, item.name)}>
                        表示項目設定
                        </button>
                        <button className="btn-customize-modal"
                        onClick={() => ModalDetailSetting(arrRole, item.name)}
                        >
                        詳細設定
                        </button>
                      </div>
                    </td>
                  </tr>
                )
              );
            })}
        </tbody>
      </table>
    );
  };

  const [ListHeaderRole, setListHeaderRoleData] = useState({});
  const [menuId, setMenuId] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getListHeaderRole();
  }, []);

  useEffect(() => {
    getListHeaderRole();
  }, [idProvide]);

  const getListHeaderRole = async () => {
    try {
      setLoading(true);
      const data = await ORDER.GET_LIST_HEADER_FOLLOW_ROLE({
        route: routeList, idProvide: idProvide
      });
      if (statusBeforeConfirm) {
        setListHeaderRoleData(data ?.data);
      } else {
        setListHeaderRoleData({
          ...data ?.data,
          data: data ?.data ?.data ?.filter(item => item.name !== 'tomorrow_info')
        });
      }
      setMenuId(data?.data?.menuId);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const handleOnChange = (e, elm) => {
    const { value } = e.target;
    var isTrueSet = value === 'true';
    const ListHeaderRoleDataTmp =
      ListHeaderRole &&
      ListHeaderRole.data &&
      ListHeaderRole.data?.map((item) => {
        if (item.name === elm.name) {
          return { ...item, active: isTrueSet };
        }
        return item;
      });

    setListHeaderRoleData({ data: ListHeaderRoleDataTmp });
  };

  return (
    <div className="table-info-company ">
      <Loading loading={loading} />
      {renderTableInfo(ListHeaderRole)}
      <button className="btn-submit" disabled>
        保存
      </button>
    </div>
  );
};

export default TableAddRole;
