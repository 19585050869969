import React from 'react';
import './style.scss';

const DetailOrder = ({ data, p = 'receive_info', title='工事情報' }) => {
  return (
    <div className="detail-data-wrapper detail-order-center">
      <div className="detail-cancel-table-wrapper">
        <div className="title-absolute"> { title } </div>
        <table className="conditTab">
          <tbody>
          <tr>
              <th width="200">発注企業名</th>
              <td>{data?.clientName}</td>
              <th width="200">プロジェクト名</th>
              <td>{data?.projectName}</td>
            </tr>
            <tr>
              <th>工事名</th>
              <td>{data?.caseName}</td>
              <th>工程名</th>
              <td>{data?.serviceName}</td>
            </tr>
            {/* <tr>
              <th>管理コード</th>
              <td>{data?.managementCode}</td>
              <th>物件名</th>
              <td>{data?.installationSiteName}</td>
            </tr> */}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DetailOrder;
