import _ from 'lodash';
import React, { useState } from 'react';
import { Collapse } from 'reactstrap';
import './style.scss';

const BasicInformation = ({ title, handleModalMonitor, dataTree = {} }) => {
  const [isOpen, setIsOpen] = useState(true);
  const handleMonitor = (text, monitorId, projectId, productId) => {
    handleModalMonitor(text, monitorId, projectId, productId);
  };

  const onClickShow = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="detail-basic-information-wrapper">
      <div className="detail-basic-table-wrapper">
        <div className="title-absolute cursor-pointer" onClick={onClickShow}>
          {title}
          {isOpen ? (
            <i className="fas fa-arrow-circle-up ml-2 cursor-pointer"></i>
          ) : (
            <i className="fas fa-arrow-circle-down ml-2 cursor-pointer"></i>
          )}
        </div>
        <Collapse isOpen={isOpen}>
          <div className="content-view content-view-contract">
            {/* <button className="btn-monitor" onClick={() => handleMonitor('monitor')}>
              工事名追加登録
            </button> */}
            {Object.keys(dataTree)?.map((itemMonitor, indexMonitor) => {
              const monitor = dataTree?.[itemMonitor];
              return (
                <div>
                  <div className="mt-3 ml-3" key={indexMonitor}>
                    <p>
                      {monitor?.monitorName}
                      {/* <button className="btn-monitor" onClick={() => handleMonitor('project', itemMonitor)}>
                        Project追加
                      </button> */}
                    </p>
                  </div>
                  {(monitor?.projects !== undefined || monitor?.nonProjects !== undefined) && (
                    <ul>
                      {!_.isEmpty(monitor?.projects) &&
                        Object.keys(monitor?.projects)?.map((itemProject, indexProject) => {
                          const project = monitor?.projects?.[itemProject];
                          return (
                            <React.Fragment>
                              {!_.isEmpty(project?.products) &&
                                Object.keys(project?.products)?.map((itemProduct, indexProduct) => {
                                  const product = project?.products?.[itemProduct];
                                  return (
                                    <li key={indexProject}>
                                      <p>
                                        {project?.projectName && product.productName
                                          ? `${product?.productName}-${project.projectName}`
                                          : product?.productName || project.projectName}
                                        {/* <span
                                          onClick={() =>
                                            handleMonitor('service', itemMonitor, itemProject, itemProduct)
                                          }
                                        >
                                          工程設定
                                        </span> */}
                                      </p>
                                      {!_.isEmpty(product?.services) && (
                                        <ul>
                                          {Object.keys(product?.services)?.map((itemService, indexService) => {
                                            const service = product?.services?.[itemService];
                                            return (
                                              <li key={indexService}>
                                                <p>{service?.serviceName}</p>
                                              </li>
                                            );
                                          })}
                                        </ul>
                                      )}
                                    </li>
                                  );
                                })}
                            </React.Fragment>
                          );
                        })}
                      {!_.isEmpty(monitor?.nonProjects) &&
                        Object.keys(monitor?.nonProjects)?.map((itemNonProject, indexNonProject) => {
                          const product = monitor?.nonProjects?.[itemNonProject];
                          return (
                            <li key={indexNonProject}>
                              <p>
                                {product?.productName}
                                {/* <span
                                 onClick={() => handleMonitor('service', itemMonitor, '', itemNonProject)}
                                >
                                  工程設定
                                </span> */}
                              </p>
                              {!_.isEmpty(product?.services) && (
                                <ul>
                                  {Object.keys(product?.services)?.map((itemService, indexService) => {
                                    const service = product?.services?.[itemService];
                                    return (
                                      <li key={indexService}>
                                        <p>{service?.serviceName}</p>
                                      </li>
                                    );
                                  })}
                                </ul>
                              )}
                            </li>
                          );
                        })}
                    </ul>
                  )}
                </div>
              );
            })}
          </div>
        </Collapse>
      </div>
    </div>
  );
};

export default BasicInformation;
