import React, { useState, useEffect, Fragment } from 'react';
import './style.scss';
import { Collapse } from 'reactstrap';
import _ from 'lodash';
import {
  time,
  validateVistDateFromTo,
  validateVistDate,
  validateVistDateUpdate,
  validateVistDateUpdateTo,
  notUpdateCSV,
} from '../../../helpers/constants';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ORDER } from '../../../constants/endpoints';
import { pushToast } from '../../Toast';
import moment from 'moment';
import { getDataHistory } from 'actions/authentication';
import { useDispatch } from 'react-redux';

const DetaiListAllData = ({ data, screenName, title = 'CSV追加情報' }) => {
  const dispatch = useDispatch();
  let orderId = process.browser && new URL(window.location.href).searchParams.get('orderId');
  let organizationId = process.browser && new URL(window.location.href).searchParams.get('organizationId') || '';
  const [isOpen, setIsOpen] = useState(false);
  const [stellaCloud, setStellaCloud] = useState(data?.detailSetting?.filter((k) => k.type === 'stellaCloud') || []);
  const [csv, setCsv] = useState(data?.detailSetting?.filter((k) => k.type === 'csv') || []);
  const [dataCustom, setDataCustom] = useState([]);
  const [dataUpdate, setDataUpdate] = useState();
  const [arrayData, setArrayData] = useState(['preferredDate']);

  useEffect(() => {
    setDataUpdate({
      ...data,
      desiredVisitDate1From: data?.preferredDate?.visitDate1?.visitDate || '',
      desiredVisitDateAndTime1: data?.preferredDate?.visitDate1?.visitDateFrom || '',
      desiredDateAndTimeOfVisit1: data?.preferredDate?.visitDate1?.visitDateTo || '',
      desiredVisitDate2From: data?.preferredDate?.visitDate2?.visitDate || '',
      desiredDateAndTimeOfVisitFrom2: data?.preferredDate?.visitDate2?.visitDateFrom || '',
      desiredDateAndTimeOfVisitTo2: data?.preferredDate?.visitDate2?.visitDateTo || '',
      desiredVisitDate3From: data?.preferredDate?.visitDate3?.visitDate || '',
      desiredDateAndTimeOfVisitFrom3: data?.preferredDate?.visitDate3?.visitDateFrom || '',
      desiredDateAndTimeOfVisitTo3: data?.preferredDate?.visitDate3?.visitDateTo || '',
      desiredDateOfVisit4From: data?.preferredDate?.visitDate4?.visitDate || '',
      desiredDateAndTimeOfVisitFrom4: data?.preferredDate?.visitDate4?.visitDateFrom || '',
      desiredDateAndTimeOfVisitTo4: data?.preferredDate?.visitDate4?.visitDateTo || '',
      desiredVisitDate5From: data?.preferredDate?.visitDate5?.visitDate || '',
      desiredDateAndTimeOfVisitFrom5: data?.preferredDate?.visitDate5?.visitDateFrom || '',
      desiredDateAndTimeOfVisitTo5: data?.preferredDate?.visitDate5?.visitDateTo || '',
    });
  }, [data]);

  useEffect(() => {
    if (data) {
      let custom = {};
      _.forEach(data?.customData, function (val) {
        custom = { ...custom, ...val };
      });
      setStellaCloud(data?.detailSetting?.filter((k) => k.type === 'stellaCloud') || []);
      setCsv(data?.detailSetting?.filter((k) => k.type === 'csv') || []);
      setDataCustom(custom || []);
    }
  }, [data]);

  const onClickShow = () => {
    setIsOpen(!isOpen);
  };

  const handleData = (elm) => {
    switch (elm) {
      case '作業員1_ID':
        return data?.staffId?.[0] || null;
      case '作業員2_ID':
        return data?.staffId?.[1] || null;
      case '作業員3_ID':
        return data?.staffId?.[2] || null;
      case 'パートナーID':
        return data?.partnerId || null;
      default:
        return data?.[elm] || dataCustom?.[elm];
    }
  };

  const updateOrderByCsv = async ({ body = {}, id_order = '' }) => {
    try {
      const data = await ORDER.UPDATE_ODER_BY_CSV({ body, id_order, organizationId });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
      } else {
        pushToast('success', 'Update order success');
        dispatch(getDataHistory({ orderId, screenName: screenName, organizationId }));
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onChangeText = (e, check) => {
    if (check) {
      switch (e?.target?.name) {
        case 'desiredDateAndTimeOfVisit1':
          setDataUpdate({
            ...dataUpdate,
            [e?.target?.name]: e.target.value
              ? `${dataUpdate?.desiredVisitDate1From} ${e.target.value}`
              : e.target.value,
          });
          break;
        case 'desiredDateAndTimeOfVisitTo2':
          setDataUpdate({
            ...dataUpdate,
            [e?.target?.name]: e.target.value
              ? `${dataUpdate?.desiredVisitDate2From} ${e.target.value}`
              : e.target.value,
          });
          break;
        case 'desiredDateAndTimeOfVisitTo3':
          setDataUpdate({
            ...dataUpdate,
            [e?.target?.name]: e.target.value
              ? `${dataUpdate?.desiredVisitDate3From} ${e.target.value}`
              : e.target.value,
          });
          break;
        case 'desiredDateAndTimeOfVisitTo4':
          setDataUpdate({
            ...dataUpdate,
            [e?.target?.name]: e.target.value
              ? `${dataUpdate?.desiredVisitDate4From} ${e.target.value}`
              : e.target.value,
          });
          break;
        case 'desiredDateAndTimeOfVisitTo5':
          setDataUpdate({
            ...dataUpdate,
            [e?.target?.name]: e.target.value
              ? `${dataUpdate?.desiredVisitDate5From} ${e.target.value}`
              : e.target.value,
          });
          break;
      }
    } else {
      let newData = [...dataUpdate.customData];
      const indexData = newData.findIndex(x => e.target.name in x);
      if(indexData === -1) {
        newData.push({[e?.target?.name]: e?.target?.value});
      } else {
        newData[indexData] = {[e?.target?.name]: e?.target?.value};
      }
      let custom = {};
      _.forEach(newData, function (val) {
        custom = { ...custom, ...val };
      });

      setDataCustom(custom);
      setDataUpdate({ ...dataUpdate, customData: newData });
    }

    setArrayData([...arrayData, e?.target?.name]);
  };

  const handleChangeDate = async (date, name) => {
    setDataUpdate({ ...dataUpdate, [name]: date ? moment(date).format('YYYY/MM/DD') : '' });
    setArrayData([...arrayData, name]);
  };

  const formatInputSelectTime = (dateTime) => {
    if (!dateTime) return '';
    return dateTime.slice(-5);
  };

  const SubmitOrder = () => {
    // let dataNew = _.pick(dataCustom, arrayData);
    // dataNew = {
    //   ...dataNew,
    //   preferredDate: {
    //     visitDate5: {
    //       visitDate: dataUpdate?.desiredVisitDate5From || dataUpdate?.preferredDate?.visitDate5?.visitDate,
    //       visitDateFrom:
    //         dataUpdate?.desiredDateAndTimeOfVisitFrom5 || dataUpdate?.preferredDate?.visitDate5?.visitDateFrom,
    //       visitDateTo: dataUpdate?.desiredDateAndTimeOfVisitTo5 || dataUpdate?.preferredDate?.visitDate5?.visitDateTo,
    //     },
    //     visitDate4: {
    //       visitDate: dataUpdate?.desiredDateOfVisit4From || dataUpdate?.preferredDate?.visitDate4?.visitDate,
    //       visitDateFrom:
    //         dataUpdate?.desiredDateAndTimeOfVisitFrom4 || dataUpdate?.preferredDate?.visitDate4?.visitDateFrom,
    //       visitDateTo: dataUpdate?.desiredDateAndTimeOfVisitTo4 || dataUpdate?.preferredDate?.visitDate4?.visitDateTo,
    //     },
    //     visitDate3: {
    //       visitDate: dataUpdate?.desiredVisitDate3From || dataUpdate?.preferredDate?.visitDate3?.visitDate,
    //       visitDateFrom:
    //         dataUpdate?.desiredDateAndTimeOfVisitFrom3 || dataUpdate?.preferredDate?.visitDate3?.visitDateFrom,
    //       visitDateTo: dataUpdate?.desiredDateAndTimeOfVisitTo3 || dataUpdate?.preferredDate?.visitDate3?.visitDateTo,
    //     },
    //     visitDate2: {
    //       visitDate: dataUpdate?.desiredVisitDate2From || dataUpdate?.preferredDate?.visitDate2?.visitDate,
    //       visitDateFrom:
    //         dataUpdate?.desiredDateAndTimeOfVisitFrom2 || dataUpdate?.preferredDate?.visitDate2?.visitDateFrom,
    //       visitDateTo: dataUpdate?.desiredDateAndTimeOfVisitTo2 || dataUpdate?.preferredDate?.visitDate2?.visitDateTo,
    //     },
    //     visitDate1: {
    //       visitDate: dataUpdate?.desiredVisitDate1From || dataUpdate?.preferredDate?.visitDate1?.visitDate,
    //       visitDateFrom: dataUpdate?.desiredVisitDateAndTime1 || dataUpdate?.preferredDate?.visitDate1?.visitDateFrom,
    //       visitDateTo: dataUpdate?.desiredDateAndTimeOfVisit1 || dataUpdate?.preferredDate?.visitDate1?.visitDateTo,
    //     },
    //   },
    // };
    let dataUpdateOrder = Object.keys(dataCustom).map((ki, m) => {
      if (validateVistDateUpdate?.includes(ki)) {
        delete dataCustom[ki];
      } else {
        return {
          key: ki,
          value: dataCustom?.[ki],
        };
      }
    });

    const csvUpdateOrder = _.compact(csv.map(e => {
      const csvUpdate = dataUpdateOrder.find(i => i.key === e.nameInStella);
        if(!!csvUpdate) {
          return {
            key: csvUpdate.key, value: csvUpdate.value, label: e.label
          }
      }
    }));
    updateOrderByCsv({
      body: {
        data: csvUpdateOrder,
        companyName: localStorage.getItem('companyName'),
        screenName,
        updateTitle: `保存 ${title || ''}`
      },
      id_order: orderId
    });
  };

  return (
    <div className="detail-data-wrapper">
      <div className="detail-cancel-table-wrapper">
        <div className="title-absolute cursor-pointer" onClick={onClickShow}>
          {title}{' '}
          {isOpen ? <i className="fas fa-arrow-circle-up ml-2"></i> : <i className="fas fa-arrow-circle-down ml-2"></i>}
        </div>
        <Collapse isOpen={isOpen}>
          <table className="conditTab">
            <tbody>
              {_.chunk(stellaCloud, 2)?.map((elms, index) => {
                return (
                  <tr key={index}>
                    {elms?.map((elm, i) => {
                      return (
                        <Fragment key={i}>
                          <th>{elm.label}</th>
                          <td>{data?.[elm.nameInStella]}</td>
                        </Fragment>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
          {!_.isEmpty(csv) && (
            <div>
              <span className="detail-data-wrapper">インポート情報</span>
            </div>
          )}
          <table className="conditTab">
            <tbody>
              {_.chunk(csv, 2)?.map((elms, index) => {
                return (
                  <tr key={index}>
                    {elms?.map((elm, i) => {
                      if (validateVistDateFromTo?.includes(elm?.nameInStella)) {
                        elm = { ...elm, typeValidate: 'time' };
                      }
                      if (validateVistDate?.includes(elm?.nameInStella)) {
                        elm = { ...elm, typeValidate: 'date' };
                      }
                      if (validateVistDateUpdateTo?.includes(elm?.nameInStella)) {
                        elm = { ...elm, typeValidate: 'dateTime' };
                      }
                      return (
                        <Fragment key={i}>
                          <th>{elm.label}</th>
                          <td>
                            {elm?.status && !notUpdateCSV?.includes(elm?.nameInStella) ? (
                              elm?.typeValidate === 'string' ? (
                                <input
                                  className="w-100"
                                  type="text"
                                  name={elm.nameInStella}
                                  value={
                                    dataUpdate?.[elm.nameInStella] ||
                                    dataCustom?.[elm.nameInStella]
                                  }
                                  onChange={(e) => onChangeText(e)}
                                />
                              ) : elm?.typeValidate === 'time' ? (
                                <select
                                  className="w-100"
                                  name={elm.nameInStella}
                                  value={dataUpdate[elm.nameInStella] || dataCustom?.[elm.nameInStella]}
                                  onChange={(e) => onChangeText(e)}
                                >
                                  {time.map((alm, index) => (
                                    <option value={alm} key={index}>
                                      {alm}
                                    </option>
                                  ))}
                                </select>
                              ) : elm?.typeValidate === 'dateTime' ? (
                                <select
                                  className="w-100"
                                  name={elm.nameInStella}
                                  value={formatInputSelectTime(dataUpdate[elm.nameInStella] || dataCustom?.[elm.nameInStella])}
                                  onChange={(e) => onChangeText(e, true)}
                                >
                                  {time.map((alm, index) => (
                                    <option value={alm} key={index}>
                                      {alm}
                                    </option>
                                  ))}
                                </select>
                              ) : elm?.typeValidate === 'date' ? (
                                <div>
                                  <DatePicker
                                    selected={
                                      dataUpdate[elm.nameInStella] ? new Date(dataUpdate[elm.nameInStella]) : ''
                                    }
                                    onChange={(date) => handleChangeDate(date, elm.nameInStella)}
                                    locale="ja"
                                    dateFormat="yyyy/MM/dd"
                                    dateFormatCalendar="yyyy年 MM月"
                                    className="form-control w-100"
                                  />
                                </div>
                              ) : elm?.typeValidate === 'number' ? (
                                <input
                                  className="w-100"
                                  type="number"
                                  name={elm.nameInStella}
                                  value={dataUpdate?.[elm.nameInStella] || dataCustom?.[elm.nameInStella]}
                                  onChange={(e) => onChangeText(e)}
                                />
                              ) : (
                                ''
                              )
                            ) : (
                              <> {handleData(elm.nameInStella)} </>
                            )}
                          </td>
                        </Fragment>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="text-center mb-1">
            {
              (!_.isEmpty(stellaCloud) || !_.isEmpty(csv)) && (
                <button className="bt__update" onClick={() => SubmitOrder()}>
                  保存
                </button>
              )
            }
          </div>
        </Collapse>
      </div>
    </div>
  );
};

export default DetaiListAllData;
