import { call, takeLatest, put } from 'redux-saga/effects';
import { ACTIONS } from '../actions/authentication';
import { showLoading, hideLoading } from '../actions/utils';
import { callApi } from '../helpers/callApi';
import { pushToast } from '../components/Toast';

export function* logIn() {
  yield takeLatest(ACTIONS.USER_LOGIN, function* ({ payload }) {
    yield put(showLoading());
    const { username, password, history } = payload;
    try {
      const login = yield call(
        callApi,
        { username: username, password: password },
        { endpoint: 'auth-manager/user/login', method: 'POST' }
      );
      if (login && login.data && login.data.accessToken && login.data.refreshToken) {
        document.cookie = `STELLA.ACCESS_TOKEN=${login.data.accessToken}`;
        document.cookie = `STELLA.REFRESH_TOKEN=${login.data.refreshToken}`;
        localStorage.setItem('IS_LOGIN', true);
        const userMe = yield call(callApi, {}, { endpoint: 'auth-manager/user/me', method: 'GET' });
        yield put({ type: ACTIONS.SAVE_USER_ME, userMe });
        const roleMe = yield call(callApi, {}, { endpoint: 'auth-manager/user/select-role', method: 'GET' });
        yield put({ type: ACTIONS.SAVE_ROLE_ME, roleMe });
        // history.push('/list');
        history.push('/select-role');
        pushToast('success', 'Login Success');
      } else {
        if (login && login.message && login.message.extra) {
          pushToast('error', login?.message?.extra);
        } else {
          pushToast('error', login?.message?.errorMessage);
        }
      }
      yield put(hideLoading());
    } catch (error) {
      yield put(hideLoading());
    }
  });
}

export function* logOut() {
  yield takeLatest(ACTIONS.USER_LOG_OUT, function* () {
    try {
      const logout = yield call(callApi, {}, { endpoint: 'auth-manager/user/logout', method: 'POST' });
      if (logout) {
        document.cookie = `STELLA.ACCESS_TOKEN=`;
        document.cookie = `STELLA.REFRESH_TOKEN=`;
        window.location.href = '/login';
        localStorage.removeItem('IS_LOGIN');
        // localStorage.removeItem('role');
      }
    } catch (error) {
      console.log('error: ', error);
    }
  });
}

export function* getUserMe() {
  yield takeLatest(ACTIONS.GET_USER_ME, function* () {
    yield put(showLoading());
    try {
      const userMe = yield call(callApi, {}, { endpoint: 'auth-manager/user/me', method: 'GET' });
      if (userMe) {
        yield put({ type: ACTIONS.SAVE_USER_ME, userMe });
      }
      yield put(hideLoading());
    } catch (error) {
      yield put(hideLoading());
      console.log('error: ', error);
    }
  });
}


export function* getRoleMe() {
  yield takeLatest(ACTIONS.GET_ROLE_ME, function* () {
    yield put(showLoading());
    try {
      const roleMe = yield call(callApi, {}, { endpoint: 'auth-manager/user/select-role', method: 'GET' });
      if (roleMe) {
        yield put({ type: ACTIONS.SAVE_ROLE_ME, roleMe });
      }
      yield put(hideLoading());
    } catch (error) {
      yield put(hideLoading());
      console.log('error: ', error);
    }
  });
}

export function* getDataHistory() {
  yield takeLatest(ACTIONS.GET_DATA_HISTORY, function* (payload) {
    yield put(showLoading());
    try {
      const data = yield call(callApi, {}, { endpoint: `order-manager/order/get-all-log?page=0&limit=10&orderId=${payload?.payload?.orderId}&screenName=${payload?.payload?.screenName}&organizationId=${payload?.payload?.organizationId || ''}`, method: 'GET' });
      if (data) {
        yield put({ type: ACTIONS.SAVE_DATA_HISTORY, dataHistory: data.data });
      }
      yield put(hideLoading());
    } catch (error) {
      yield put(hideLoading());
      console.log('error: ', error);
    }
  })
  yield takeLatest(ACTIONS.GET_DATA_HISTORY_MASTER_SETTING, function* (payload) {
    yield put(showLoading());
    try {
      const data = yield call(callApi, {},
        { endpoint: `master-setting-manager/log/get-log-history-system/${payload?.payload?.screenName}?logId=${payload?.payload?.logId || ''}`,
        method: 'GET',
        idProvide: payload?.payload?.idProvide
      });
      if (data) {
        yield put({ type: ACTIONS.SAVE_DATA_HISTORY_MASTER_SETTING, dataHistoryMasterSetting: data?.data });
      }
      yield put(hideLoading());
    } catch (error) {
      yield put(hideLoading());
      console.log('error: ', error);
    }
  })
}

export function* getParamSearch(payload) {
  yield put({ type: ACTIONS.GET_PARAM_SEARCH, payload });
}
