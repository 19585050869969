import React, { useState } from 'react';
import { Collapse } from 'reactstrap';
import 'assets/scss/page/AnkenMasterNew/ModalDocumentSettings.scss';

const CollaMasterViewTree = ({ listTree}) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const itemTree = (array) => {
    return (
      <ul>
        {array &&
          array.map((item, index) => {
            return (
              <li key={index}>
                <p>
                  {item.serviceName}
                  {
                    item ?.materialType ?.map((elm, i) => {
                      return (
                        <button
                          style={{backgroundColor: colorBtn(elm), border: elm.materialTypeName === '共通資料' && !elm?.documentTitle?.url && '#0e5a8d 2px dotted'}}
                          key={i}
                          disabled={!elm?.documentTitle?.url}
                          onClick={() => window.open(`${elm?.documentTitle?.url}`)}
                        >{elm.numberOfMaterials}</button>
                      )
                    })
                  }
                </p>
              </li>
            );
          })}
      </ul>
    );
  };

  const colorBtn = (item) => {
    switch (item.materialTypeName) {
      case  '共通資料':
        return '#fec001';
      case '展開資料':
        return '#002060';
      case '作成資料':
        return '#0eb0f0';
      case '回収資料':
        return '#0fb050';
      default:
        return '#387ca9';
    }
  }

  return (
    <div className="setting-master-menu-wrapper">
      <Collapse isOpen={!isOpen}>
        <div
          className='form-title-modal'
          onClick={toggle}>
          ドキュメント設定状況
        </div>
      </Collapse>

      <div>
        <Collapse isOpen={isOpen}>
          <div className='form-tree' onClick={toggle}>
            <div className="form-tree-group">
              <div className="d-flex flex-direction-row">
                <div className="title-project">{listTree ?.projectName}</div>
                {
                  listTree ?.materialType ?.map((item, index) => {
                    return (
                      <button
                        style={{backgroundColor: colorBtn(item), border: item.materialTypeName === '共通資料' && !item?.documentTitle?.url && '#0e5a8d 2px dotted'}}
                        disabled={!item?.documentTitle?.url}
                        onClick={() => window.open(`${item?.documentTitle?.url}`)}
                        className="button-modal" key={index}>{item.numberOfMaterials}</button>
                    )
                  })
                }
              </div>
              {
              listTree ?.products?.length > 0 &&
                <ul>
                  {listTree && listTree ?.products.map((item, index) => {
                  return (
                    <li key={index}>
                      <p>
                        {item.productName}
                        {
                          item ?.materialType ?.map((elm, i) => {
                            return (
                              <button
                                style={{backgroundColor: colorBtn(elm), border: elm.materialTypeName === '共通資料' && !elm?.documentTitle?.url && '#0e5a8d 2px dotted'}}
                                key={i}
                                disabled={!elm?.documentTitle?.url}
                                onClick={() => window.open(`${elm?.documentTitle?.url}`)}
                              >{elm.numberOfMaterials}</button>
                            )
                          })
                        }
                      </p>

                      {item?.services?.length > 0 ? itemTree(item?.services) : ''}
                    </li>
                  );
                })}
                </ul>
            }

          </div>
          </div>
        </Collapse>
      </div>
    </div>
  );
};

export default CollaMasterViewTree;
