import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { translate } from 'react-i18next';
import './style.scss';
import Footer from '../../../components/Footer';
import { HEADER_MENU, SCREEN_NAME } from '../../../helpers/constants';
import { getListOrder } from '../../../actions/order';
import { connect, useSelector } from 'react-redux';
import { getRole } from '../../../helpers/helpers';
import TableListStaff from '../../../components/TableListStaff';
import HeaderMasterSetting from '../../../components/HeaderMasterSetting';
import MemoHistory from '../../../components/MemoHistory';

const SelectTableOrganization = (props) => {
  const { t } = props;
  let name = process.browser && new URL(window.location.href).searchParams.get('p');
  const colorScreen = useSelector((store) => store.utils.colorMe);
  const [p, setP] = React.useState(name || HEADER_MENU.RECEIVE_ALL);
  const [role, setRole] = React.useState(null);
  const [idRole, setIdRole] = React.useState(null);
  const [roleDetail, setRoleDetail] = useState('');

  useEffect(() => {
    if (idRole) {
      setRoleDetail(getRole({ idRole: idRole }));
    }
  }, [idRole]);

  useEffect(() => {
    setP(name);
  }, [name]);

  useEffect(() => {
    const newRole = localStorage.getItem('role');
    setIdRole(newRole);
    if (newRole) {
      setRole(getRole({ idRole: newRole }));
    }
  }, []);
  return (
    <>
      <div className="wrapper-table-select-master">
        <HeaderMasterSetting checkName= '/users' color={colorScreen?.code} colorText={colorScreen?.colorText}/>
        <div className="wrapper-main ">
          <div className="wrapper-table-select">
            <TableListStaff idRole={idRole} p={p} t={t} role={role} color={colorScreen?.code} roleDetail={roleDetail} />
          </div>
          <MemoHistory colors={colorScreen?.code} colorText={colorScreen?.colorText} screenName={SCREEN_NAME.USER_MASTER}/>
        </div>

        <Footer color={colorScreen?.code} />
      </div>
    </>
  );
};

const mapStateToProps = (store) => {
  return {
    listOrder: store.order.listOrder,
    loading: store.utils.loading,
  };
};

const mapActionToProps = {
  getListOrder,
};

export default compose(
  translate('translations'),
  withRouter,
  connect(mapStateToProps, mapActionToProps)
)(SelectTableOrganization);
