import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import 'assets/scss/page/AnkenMasterNew/ModalAddServiceInProduct.scss';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import { pushToast } from 'components/Toast';
import { ANKEN_MASTER_NEW } from 'constants/endpoints';
import _ from 'lodash';
import { SCREEN_NAME } from 'helpers/constants';

const ModalAddOptionDocument = (props) => {
  const { toggle = () => { }, modal = false, dataDocumentSetting } = props;
  const [listMaterial, setListMaterial] = useState([]);
  const [bodyCreate, setBodyCreate] = useState({});
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return dataResult(result);
  };

  const dataResult = (result) => result.map((i, index) => { i.sortNum = index; return i; });

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const items = reorder(listMaterial, result.source.index, result.destination.index);
    setListMaterial(items);
  };

  const getListMaterial = async ({ projectId = '', productId = '' }) => {
    try {
      const data = await ANKEN_MASTER_NEW.GET_LIST_MATERIAL({
        projectId, productId,
      });
      if (data) {
        setListMaterial(_.sortBy(data?.data), 'sortNum')
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getListMaterial({ ...dataDocumentSetting });
  }, []);

  const handleChangeValue = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setBodyCreate({ ...bodyCreate, [name]: value })
  };

  const handleCreate = () => {
    const body = {
      ...bodyCreate,
      ...dataDocumentSetting,
      screenName: SCREEN_NAME.CASE_MASTA,
    };
    handleCreateMaterial({ body })
  };

  const handleCreateMaterial = async ({ body }) => {
    try {
      const data = await ANKEN_MASTER_NEW.ADD_MATERIAL({ body });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
      } else {
        pushToast('success', 'Add material success');
        getListMaterial({ ...dataDocumentSetting });
        setBodyCreate({})
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleUpdate = () => {
    const body = {
      materialTypes: listMaterial,
      screenName: SCREEN_NAME.CASE_MASTA,
    };
    handleUpdateMaterial({ body })
  };

  const handleUpdateMaterial = async ({ body }) => {
    try {
      const data = await ANKEN_MASTER_NEW.UPDATE_LIST_MATERIAL({ body });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
      } else {
        pushToast('success', 'Update list material success');
        getListMaterial({ ...dataDocumentSetting });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const renderItem = (item, index) => {
    return (
      <Draggable key={`${index + 1}`} draggableId={`${index + 1}`} index={index}>
        {(provided) => (
          <tr
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            key={index}
          >
            <td>{item.name}</td>
            <td>{item.nameChild}</td>
            <td>{item.description}</td>
          </tr>
        )}
      </Draggable>
    );
  };

  return (
    <div>
      <Modal className="modal-dialog-custom" isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>資料種別追加</ModalHeader>
        <ModalBody>
          <div className="form-body-add-service">
            <div className="form-top-modal">
              <div className="form-left">
                <div className="group-input mb-1">
                  <div className="title">種別入力</div>
                  <input
                    type="text"
                    name="nameType"
                    onChange={handleChangeValue}
                    value={bodyCreate?.nameType || '' }
                  />
                </div>

                <div className="group-input mb-1">
                  <div className="title">資料種別入力</div>
                  <input
                    type="text"
                    name='nameChildType'
                    value={bodyCreate?.nameChildType || ''}
                    onChange={handleChangeValue}

                  />
                </div>
              </div>

              <div>
                <button
                  onClick={() => handleCreate()}
                >登録</button>
              </div>
            </div>

          <div className="form-get-code">
            <div className="group-input-search">
              <div className="title">資料種別メモ</div>
              <input
                type="text"
                name="description"
                value={bodyCreate?.description || ''}
                onChange={handleChangeValue}

              />
            </div>

            </div>
            <table className="table-modal">
              <thead>
                <tr>
                  <th>資料種別</th>
                  <th>資料種別</th>
                  <th>資料種別メモ</th>
                </tr>
              </thead>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided) => (
                    <tbody
                      {...provided.droppableProps} ref={provided.innerRef}
                    className="scroll-table">
                    {listMaterial?.map((item, index) => {
                      return renderItem(item, index);
                    })}
                  {provided.placeholder}
                  </tbody>
                  )}
                </Droppable>
              </DragDropContext>
            </table>
            <div className="form-btn-setting">
              <button
                onClick={() => handleUpdate()}
              >保存</button>
            </div>

          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ModalAddOptionDocument;
