import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import Loading from 'components/Loading/Loading';
import './style.scss';
import DatePicker from 'components/DateTimePickerThreeMonth';
import { DATE_JP_FORMAT, time } from 'helpers/constants';
import { STAFF_WORKING_SCHEDULE } from 'constants/endpoints';
import { useSelector } from 'react-redux';
import { pushToast } from 'components/Toast';
import { SCREEN_NAME } from 'helpers/constants';
import FooterMaintenance from 'components/FooterMaintenance';

const StaffWorkingSchedule = () => {
  const formatMonth = date => {
    return ('0' + (date.getMonth() + 1)).slice(-2);
  };
  const nowDate = new Date();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [valueMonth, setValueMonth] = useState(`${nowDate.getFullYear()}/${formatMonth(nowDate)}`);
  const [dataSchedule, setDataSchedule] = useState({});
  const [highlightList, setHighlightList] = useState([]);
  const [listCalendar, setListCalendar] = useState([]);
  const [listCalendarMaster, setListCalendarMaster] = useState([]);
  const [listAllDayInMonth, setListAllDayInMonth] = useState([]);
  const [listAllDayInMonthOrigin, setListAllDayInMonthOrigin] = useState([]);
  const [listCommentDate, setListCommentDate] = useState([]);
  const [datePush, setDatePush] = useState({});
  const [disableBtnUpdate, setDisableBtnUpdate] = useState(true);
  const [dataOrderStaff, setDataOrderStaff] = useState([]);
  const staffId = useSelector(state => state.authentication?.userMe?.data?.staffId);
  const [statusCalendarSettings, setStatusCalendarSettings] = useState(false);

  const getDaysInMonth = (month, year) => {
    let date = new Date(year, month, 1);
    let days = [];
    while (date.getMonth() === month) {
      let dateCheck = new Date(moment(date));
      let currentDay = dateCheck.getDay();
      let typeDate = [0, 6].includes(currentDay) ? 2 : 1;
      days.push({ date: moment(dateCheck).format(DATE_JP_FORMAT), typeDate, breakEndTime: '', breakStartTime: '', workingEndTime: '00:00', workingStartTime: '00:00', remarks: '', status: false });
      date.setDate(date.getDate() + 1);
    }
    return days;
  };

  const changeMonth = date => {
    setDisableBtnUpdate(true);
    const dateSelect = new Date(date);
    setValueMonth(`${dateSelect.getFullYear()}/${formatMonth(dateSelect)}`);
    setDatePush({});
  };

  const getOrderStaffWorkingSchedule = async ({ workerCalendarId = '', staffId = '' }) => {
    try {
      setLoading(true);
      const data = await STAFF_WORKING_SCHEDULE.GET_WORKING_SCHEDULE_STAFF({ workerCalendarId, staffId });
      setLoading(false);
      if (data?.data) {
        const newCalendar = data?.data?.calendar.map(item => {
          const newItem = { ...item, typePush: item.type };
          delete newItem.type;
          return newItem;
        });
        setListCalendar(newCalendar);
        setStatusCalendarSettings(data?.data?.isCalendarWorkingSchedule);
        setListCommentDate(data?.data?.listCommentDate || []);
        if (data?.data?.calendarMaster?.length > 0) {
          setListCalendarMaster(data?.data?.calendarMaster);
        } else {
          const valueDate = isNaN(new Date(workerCalendarId)) ? new Date(workerCalendarId.replace("/", "-")) : new Date(workerCalendarId);
          setListCalendarMaster(getDaysInMonth(valueDate.getMonth(), valueDate.getFullYear()));
        }
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const getListOrderByStaff = async ({ visitDate }) => {
    setLoading(true)
    try {
      const data = await STAFF_WORKING_SCHEDULE.GET_LIST_ORDER_BY_STAFF_AND_VISITDATE({ visitDate: visitDate })
      if (data) {
        setDataOrderStaff(data?.data)
      }
      setLoading(false)
    } catch (e) {
      console.log(e);
      setLoading(false)
    }
  }

  useEffect(() => {
    getListOrderByStaff({ visitDate: moment(new Date(nowDate)).format(DATE_JP_FORMAT) })
  }, [])

  const updateStaffWorking = async ({ body }) => {
    try {
      setLoading(true);
      const data = await STAFF_WORKING_SCHEDULE.CREATE_UPDATE_CALENDAR({ body });
      if (data && data.code !== 0) {
        if (data && data?.message && data?.message?.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
        setLoading(false);
      } else {
        pushToast('success', 'Update Staff Working Schedule Success');
        getOrderStaffWorkingSchedule({ workerCalendarId: valueMonth, staffId });
        setDisableBtnUpdate(true);
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const cancelStaffWorking = async ({ body }) => {
    try {
      setLoading(true);
      const data = await STAFF_WORKING_SCHEDULE.CANCEL_REQUEST_CALENDAR({ body });
      if (data && data.code !== 0) {
        if (data && data?.message && data?.message?.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
        setLoading(false);
      } else {
        pushToast('success', 'Cancel Staff Working Schedule Success');
        getOrderStaffWorkingSchedule({ workerCalendarId: valueMonth, staffId });
        setDisableBtnUpdate(true)
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const handleUpdate = () => {
    let newDate = {};
    if (datePush.typePush === 'dateOff') {
      newDate = { date: datePush.date, type: datePush.typePush };
    } else {
      newDate = {
        date: datePush.date,
        type: datePush.typePush,
        startTime: datePush.startTime,
        endTime: datePush.endTime
      };
    }

    const body = {
      workerCalendarId: valueMonth,
      staffId,
      screenName: SCREEN_NAME.STAFF_WORKING_SCHEDULE,
      calendar: newDate,
      updateTitle: '承認依頼 稼働予定',
    };

    updateStaffWorking({ body });
  };

  const handleCancel = () => {
    const body = {
      workerCalendarId: valueMonth,
      staffId,
      screenName: SCREEN_NAME.STAFF_WORKING_SCHEDULE,
      date: datePush.date,
      updateTitle: 'キャンセル 稼働予定',
    };
    cancelStaffWorking({ body });

  };

  useEffect(() => {
    if (listCalendarMaster?.length > 0) {
      const merged = _.values(_.merge(_.keyBy(listCalendarMaster, 'date'), _.keyBy(listCalendar, 'date')));
      const newList = merged.map(item => {
        item.typeDate = item?.typeDate || item?.type;
        if (!_.isEmpty(item.typePush)) {
          return { ...item, typeShow: item.typePush };
        } else {
          if (item.typeDate === 1) {
            return { ...item, typePush: 'workDate', typeShow: 'workDate' };
          } else {
            return { ...item, typePush: 'dateOff', typeShow: 'dateOff' };
          }
        }
      });
      const values = _.groupBy(newList, 'typeShow');
      setDatePush(newList.find(e => e.date === datePush?.date) || {});
      setDataSchedule(values);
      setListAllDayInMonth(newList);
      setListAllDayInMonthOrigin(newList);
    }
  }, [listCalendarMaster]);

  useEffect(() => {
    if (valueMonth && staffId) {
      getOrderStaffWorkingSchedule({ workerCalendarId: valueMonth, staffId });
    }
  }, [valueMonth]);

  const changeDate = date => {
    setDisableBtnUpdate(true);
    setListAllDayInMonth(listAllDayInMonthOrigin);
    setDatePush(listAllDayInMonthOrigin.find(item => item.date === moment(new Date(date)).format(DATE_JP_FORMAT)));
    getListOrderByStaff({ visitDate: moment(new Date(date)).format(DATE_JP_FORMAT) })
  };

  const onReturn = () => {
    history.push(`/workerList?&workName=today`);
  };

  const getListNewDate = list => {
    return list?.map(item => {
      return new Date(item.date);
    });
  };

  useEffect(() => {
    if (!_.isEmpty(dataSchedule)) {
      const highlightWithRanges = [
        {
          'react-datepicker__day--dateOff': getListNewDate(dataSchedule?.dateOff) || []
        },
        {
          'react-datepicker__day--shortDateOff': getListNewDate(dataSchedule?.shortDateOff) || []
        },
        {
          'react-datepicker__day--workDate': getListNewDate(dataSchedule?.workDate) || []
        },
        {
          'react-datepicker__day--waiting': getListNewDate(listCalendar?.filter(item => (!item?.approval && !item?.reject))) || []
        },
        {
          'react-datepicker__day--approval-rejected': getListNewDate(listCalendar?.filter(item => (item?.approval || item?.reject))) || []
        }
      ];
      setHighlightList(highlightWithRanges);
    }
  }, [dataSchedule]);

  const handleCheckItem = e => {
    setDisableBtnUpdate(false);
    const type = e.target.name;
    if (type === 'dateOff') {
      setDatePush({ date: datePush.date, typePush: type, typeDate: datePush.typeDate, typeShow: datePush.typeShow });
    } else {
      setDatePush({ ...datePush, typePush: type });
    }

    const newData = listAllDayInMonth.map(item => {
      if (item.date === datePush.date) {
        return { ...item, typePush: type };
      } else {
        return { ...item };
      }
    });
    setListAllDayInMonth(newData);
  };

  const handleChange = e => {
    setDisableBtnUpdate(false);
    const name = e.target.name;
    const value = e.target.value;
    setDatePush({ ...datePush, [name]: value });
  };
  const dateSelect = new Date(datePush?.date);
  const countWorkingDay = () => {
    return listAllDayInMonth.filter(e => ["workDate", "shortDateOff"].includes(e.typeShow)).length
  };

  return (
    <div className="staff-working-schedule">
      <Loading loading={loading} />
      <Helmet>
        <title>工事依頼詳細</title>
      </Helmet>
      <div className="wrapper-work-list pl-0 pr-0" style={{ minHeight: 0 }}>
        <div className="block-link row mr-0 ml-0 ">
          <div className="col-4 block-link-child text-left">
            <a onClick={onReturn} className="item">
              <span>戻る</span>
            </a>
          </div>
          <div className="block-link-child col-4">
            <span style={{ color: '#fff' }}>{SCREEN_NAME.STAFF_WORKING_SCHEDULE}</span>
          </div>
          <div className="block-feature col-4"></div>
        </div>
      </div>

      <div className="form-calendar">
        <div className="form-count">
          <div>{`稼働日数：${countWorkingDay() || 0}日`}</div>
        </div>
        <div className='row flex-column flex-md-row'>
          <div className="form-time col-12 col-md-8">
            <div className="date-picker">
              <DatePicker
                onChangeDate={date => changeDate(date)}
                highlightLists={highlightList}
                month={1}
                changeMonth={changeMonth}
                date={datePush.date ? new Date(datePush.date) : ''}
                statusCalendarSettings={statusCalendarSettings}
                listCalendarMaster={listCalendarMaster}
              />
              {datePush.date && (
                <div className="form-info">
                  {
                    ['dateOff'].includes(datePush?.typeShow) &&
                    <p className={`text-date color-off`}>{`${datePush.date} 休み`}</p>
                  }
                  {
                    (['shortDateOff', 'workDate'].includes(datePush?.typeShow)) &&
                    <p className={`text-date color-free`}>{`${datePush.date} 出勤`}</p>
                  }


                  <table className="table-calendar">
                    <tbody>
                      <tr>
                        {
                          ['dateOff'].includes(datePush?.typeShow) &&
                          <td className="table-calendar__group-item" rowSpan="2">
                            <input
                              type="radio"
                              className="table-calendar__radio"
                              id="workDate"
                              name="workDate"
                              onChange={handleCheckItem}
                              checked={datePush?.typePush === 'workDate'}
                            />
                            <label className="item" htmlFor="申込会社">
                              出勤
                            </label>
                          </td>
                        }
                        {
                          ['shortDateOff', 'workDate'].includes(datePush?.typeShow) &&
                          <td className="table-calendar__group-item">
                            <input
                              type="radio"
                              className="table-calendar__radio"
                              name="dateOff"
                              id="dateOff"
                              onChange={handleCheckItem}
                              checked={datePush?.typePush === 'dateOff'}
                            />
                            <label className="item" htmlFor="休み">
                              休み
                            </label>
                          </td>
                        }

                        <td className="table-calendar__item">日付</td>
                        <td className="table-calendar__item">開始時間</td>
                        <td className="table-calendar__item">終了時間</td>
                      </tr>

                      <tr>
                        {
                          ['shortDateOff', 'workDate'].includes(datePush?.typeShow) &&
                          <td className="table-calendar__group-item">
                            <input
                              type="radio"
                              className="table-calendar__radio"
                              name="shortDateOff"
                              id="shortDateOff"
                              onChange={handleCheckItem}
                              checked={datePush?.typePush === 'shortDateOff'}
                            />
                            <label className="item" htmlFor="時間変更">
                              時間変更
                            </label>
                          </td>
                        }

                        <td className="table-calendar__item">{dateSelect.getDate()}</td>
                        <td className="table-calendar__item">
                          <select
                            name="startTime"
                            value={datePush?.startTime || ''}
                            className="table-calendar__select"
                            disabled={
                              (datePush.typePush === 'dateOff'
                                && datePush.typeShow === 'dateOff')
                              || (datePush.typePush === 'dateOff'
                                && datePush.typeShow === undefined)
                              // || (datePush.typePush === 'workDate'
                              //   && datePush.typeShow === 'workDate')
                              // || datePush?.approval
                            }
                            onChange={handleChange}
                          >
                            <option value='' disabled>--:--</option>
                            {time.map((item, index) => (
                              <option value={item} key={index}>
                                {item}
                              </option>
                            ))}
                          </select>
                        </td>

                        <td className="table-calendar__item">
                          <select
                            name="endTime"
                            value={datePush?.endTime || ''}
                            className="table-calendar__select"
                            onChange={handleChange}
                            disabled={
                              (datePush.typePush === 'dateOff'
                                && datePush.typeShow === 'dateOff')
                              || (datePush.typePush === 'dateOff'
                                && datePush.typeShow === undefined)
                              // || (datePush.typePush === 'workDate'
                              //   && datePush.typeShow === 'workDate')
                              // || datePush?.approval
                            }
                          >
                            <option value='' disabled>--:--</option>
                            {time.map((item, index) => (
                              <option value={item} key={index} disabled={index <= time.indexOf(datePush?.startTime || 0)}>
                                {item}
                              </option>
                            ))}
                          </select>
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <div className="form-button-time mt-2">
                    <button
                      onClick={handleUpdate} disabled={disableBtnUpdate}
                      className="form-button-time__approve">
                      承認依頼
                    </button>
                    <button
                      onClick={handleCancel}
                      disabled={!listCalendar.find(item => item.date === datePush.date)
                        || listCalendar.find(item => item.date === datePush.date)?.approval}
                      className="form-button-time__cancel ml-2">キャンセル</button>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='col-12 col-md-4 form--mgt-15' >
            <div className='form--ovf-auto form--h-264'>
              <table className='w-100 form--bd-c form--po-rela'>
                <thead>
                  <tr>
                    <th className='form--tbTh text-center form--sticky0' >日付</th>
                    <th className='form--tbTh text-center form--sticky0' >メモ</th>
                  </tr>
                </thead>
                <tbody>
                  {listCommentDate?.map((item, index) => {
                    if (!_.isEmpty(item?.comment)) {
                      return (
                        <tr key={index}>
                          <td className='form--tbTh text-center'>{item?.date}</td>
                          <td className='form--tbTh text-center'>{item?.comment}</td>
                        </tr>
                      )
                    }
                  })}
                </tbody>
              </table>
            </div>
            <div className={`${dataOrderStaff?.length > 2 ? 'form--ovf-auto form--h-315' : ''}`}>
              <table className='w-100 form--bd-c'>
                <tr>
                  <th className='color-free font-weight-bold form--fz-20 form--sticky0'>
                    ■割付済Order情報
                  </th>
                </tr>
                <tbody>
                  {dataOrderStaff && dataOrderStaff?.map((item, index) => {
                    return (
                      < >
                        <tr>
                          <td className='font-weight-bold color-free'>訪問日: <span className='font-weight-normal form--color-b'>
                            {`${!_.isEmpty(item?.visitDate) ? `${item?.visitDate} ${item?.visitDateFrom} ~ ${item?.visitDateTo}` : ''}`}
                          </span> </td>
                        </tr>
                        <tr>
                          <td className='font-weight-bold color-free'>管理コード: <span className='font-weight-normal form--color-b'>{item?.managementCode}</span></td>
                        </tr>
                        <tr>
                          <td className='font-weight-bold color-free'>拠点名: <span className='font-weight-normal form--color-b'>{item?.installationSiteName}</span> </td>
                        </tr>
                        <tr>
                          <td className='font-weight-bold color-free pb-3'>作業員名:<span className='font-weight-normal form--color-b'>{item?.workerName?.join(',')}</span></td>
                        </tr>
                      </>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <FooterMaintenance/>
    </div>
  );
};

export default StaffWorkingSchedule;
