import { Collapse } from 'reactstrap';
import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { withLocalize } from 'react-localize-redux';
import { translate } from 'react-i18next';
import 'assets/scss/page/DispatchAll/style.scss';

const dataSearchMock = {
  workerName: '',
  partnerName: '',
  workerContact: '',
  workerId: '',
  freeKey: ''
};


const CollapseSearch = ({
  isOpen,
  color,
  colorText,
  handleSearch
}) => {

  const [dataSearch, setDataSearch] = useState(dataSearchMock);

  const handleReset = () => {
    Array.from(document.querySelectorAll('input')).forEach((input) => (input.value = ''));
    handleSearch({dataSearch: dataSearchMock})
  };

  const handleChangeSearch = (e) => {
    const typeName = e.target.name;
    let newData = { ...dataSearch, [typeName]: e.target.value };
    setDataSearch(newData);
  };

  const onSubmit = () => {
    handleSearch({dataSearch})
  };


  return (
      <Collapse isOpen={isOpen}>
        <table style={{ width: '100%' }} className="table-search">
          <tbody>
            <tr>
              <th style={{ backgroundColor: `${color}`, color: `${colorText}` }}>作業員名</th>
              <td>
                <input type="text" name="workerName" onChange={handleChangeSearch} />
              </td>

              <th style={{ backgroundColor: `${color}`, color: `${colorText}` }}>パートナー名</th>
              <td>
                <input type="text" name="partnerName" onChange={handleChangeSearch} />
              </td>
            </tr>

            <tr>
              <th style={{ backgroundColor: `${color}`, color: `${colorText}` }}>作業員連絡先</th>
              <td>
                <input type="text" name="workerContact" onChange={handleChangeSearch} />
              </td>

              <th style={{ backgroundColor: `${color}`, color: `${colorText}` }}>workerID</th>
              <td>
                <input type="text" name="workerId" onChange={handleChangeSearch} />
              </td>
            </tr>

            <tr>
              <th style={{ backgroundColor: `${color}`, color: `${colorText}` }}></th>
              <td></td>

              <th style={{ backgroundColor: `${color}`, color: `${colorText}` }}>フリー検索キーワード</th>
              <td>
                <input type="text" name="freeKey" onChange={handleChangeSearch} />
              </td>
            </tr>

          </tbody>
        </table>
        <div className="SearchButton" align="center">
          <button className="btn btnS btnSubmit mr-2" onClick={() => onSubmit()}>
            <i className="icon-search" />検 索
          </button>
          <button type="button" className="btn btnS btnReset" onClick={handleReset}>
            <i className="icon-arrows-cw" /> 一括解除
          </button>
        </div>
      </Collapse>
  );
};


export default compose(translate('translations'), withLocalize)(CollapseSearch);
