import { ACTIONS } from '../actions/authentication';

export const userMeState = {
  userMe: null,
  roleMe: null,
  dataHistory: null,
  dataHistoryMasterSetting: null,
};

export default (state = userMeState, action) => {
  switch (action.type) {
    case ACTIONS.SAVE_USER_ME:
      return {
        ...state,
        userMe: action.userMe,
      };
    case ACTIONS.SAVE_ROLE_ME:
      return {
        ...state,
        roleMe: action.roleMe,
      };
    case ACTIONS.SAVE_DATA_HISTORY:
      return {
        ...state,
        dataHistory: action.dataHistory,
      }
    case ACTIONS.SAVE_DATA_HISTORY_MASTER_SETTING:
      return {
        ...state,
        dataHistoryMasterSetting: action?.dataHistoryMasterSetting,
      }

    default:
      return state;
  }
};
