import React, { useEffect, useState } from 'react';
import FooterMaintenance from 'components/FooterMaintenance';
import HeaderMasterSetting from 'components/HeaderMasterSetting';
import { useSelector } from 'react-redux';
import Loading from 'components/Loading/Loading';
import RequestDetail from './RequestDetail';
import _ from 'lodash';
import 'assets/scss/page/center/Users/edit.scss'
import moment from 'moment';
import { DATE_MONTH_YEAR } from 'helpers/constants';

const StaffRequest = () => {
  const colorScreen = useSelector((store) => store.utils.colorMe);
  const [loading, setLoading] = useState(false);
  const staffId = process.browser && new URL(window.location.href).searchParams.get('staffId');
  const date = process.browser && new URL(window.location.href).searchParams.get('startDate') || moment(new Date).format(DATE_MONTH_YEAR);

  const changeLoading = (status) => {
    setLoading(status);
  }

  return (
    <div className="wrapper-master-setting">
      <Loading loading={loading} />
      <HeaderMasterSetting color={colorScreen?.code} colorText={colorScreen?.colorText} />
      {
        <>
          <div className="body pb-4">
            <div className="wrapper-main">
              <div className="wrapper-result">
                <div className="result-title">
                </div>
              </div>
              <RequestDetail colors={colorScreen?.code} colorText={colorScreen?.colorText} changeLoading={changeLoading} staffId={staffId} date={date} />
              <div className="d-flex justify-content-center align-items-center group-button"></div>
            </div>
          </div>
        </>
      }
      <FooterMaintenance />
    </div>
  );
};

export default StaffRequest;
