import GoogleMapReact from 'google-map-react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Geocode from 'react-geocode';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import { ORDER, ORDER_PARTNER } from 'constants/endpoints';
import { DATETIME_JP_FORMAT, DEFAULT_WORKER_DETAIL } from 'helpers/constants';
import DetailWorker from './DetailWorker';
import _ from 'lodash';
import { pushToast } from 'components/Toast';
import 'assets/scss/page/DetailWorkerList/style.scss';
import { SCREEN_NAME } from '../../../helpers/constants';
import { useSelector } from 'react-redux';
import Loading from 'components/Loading/Loading';

Geocode.setApiKey('AIzaSyB6rJOxJ5TvRMqXcZCv49Oy8EScg6nfKVQ');
Geocode.setLanguage('ja');
Geocode.setRegion('JP');
Geocode.enableDebug();

const getMapOptions = () => {
  return {
    disableDefaultUI: false,
    mapTypeControl: true,
    streetViewControl: false,
    styles: [{ featureType: 'poi', elementType: 'labels', stylers: [{ visibility: 'on' }] }]
  };
};

const mockData = {
  nameDate: ''
};
const DetailWorkerList = () => {
  const workName = (process.browser && new URL(window.location.href).searchParams.get('workName')) || null;
  const orderId = (process.browser && new URL(window.location.href).searchParams.get('orderId')) || null;
  const organizationId = (process.browser && new URL(window.location.href).searchParams.get('organizationId')) || '';
  const [data, setData] = useState([]);
  const [dataOrder, setDataOrder] = useState(mockData);
  const history = useHistory();
  const userMeData = useSelector(store => store.authentication.userMe);
  const [statusResult, setStatusResult] = useState([]);
  const [timeData, setTimeData] = useState('');
  const [timeData1, setTimeData1] = useState('');
  const [loading, setLoading] = useState(false);
  const [lat, setLat] = useState('');
  const [lng, setLng] = useState('');
  const [valueSettingCompletionResult, setValueSettingCompletionResult] = useState('');

  const getOrderDetail = async ({ orderId = '' }) => {
    setLoading(true);
    try {
      const res = await ORDER.GET_DETAIL_CANCEL_INFO({ orderId, organizationId: organizationId || '' });
      if (res?.data) {
        let address =
          res?.data?.prefecturesVisited + res?.data?.visitedBaseAddress + res?.data?.visitedBuildingName ||
          'Tokyo, Japan';
        getAddress(address)
        getStatusResult({
          serviceId: res?.data?.serviceId,
          productId: res?.data?.productId,
          orderId: res?.data?.orderId
        });
        setData(res?.data);
        setValueSettingCompletionResult(res?.data?.settingCompletionResult?.result);
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const getAddress = async (address) => {
    setLoading(true);
    try {
      const itemTmp = await Geocode.fromAddress(address);
      if (itemTmp) {
        let { lng, lat } = itemTmp?.results[0]?.geometry?.location;
        setLat(lat);
        setLng(lng);
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (orderId) {
      getOrderDetail({
        orderId
      });
    }
  }, [orderId]);

  const updateOrderPartner = async ({ body = {}, id_order = '', organizationId = '', type }) => {
    setLoading(true);
    try {
      const data = await ORDER_PARTNER.ADD_TIME_ORDER_STAFF({
        body,
        id_order,
        organizationId
      });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
        setLoading(false);
      } else {
        pushToast('success', 'Update order success');
        if (type === 'startTime') {
          setTimeData(moment(new Date()).format(DATETIME_JP_FORMAT));
        } else if (type === 'endTime') {
          setTimeData1(moment(new Date()).format(DATETIME_JP_FORMAT));
        }
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const updateStatus = async ({ id_order = '', state }) => {
    setLoading(true);
    try {
      const data = await ORDER_PARTNER.CHANGE_ORDER_REPORT_DATE_UPDATE({
        updateTitle: state === 'workOnTheDay' ? '作業内容送信' : '事前確認送信',
        companyName: localStorage.getItem('companyName'),
        screenName: SCREEN_NAME.WORKER_DETAIL,
        id_order,
        organizationId: organizationId || '',
        state
      });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
        setLoading(false);
      } else {
        pushToast('success', 'Success');
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (data) {
      setDataOrder({
        ...dataOrder,
        nameDate: data?.nameDate,
        staffId: data?.staffId,
        versionSetting: data?.versionSetting
      });
    }
  }, [data]);

  const onUpdateOrder = () => {
    if (workName === 'pre' || workName === 'nextday') return false;
    if (!data?.startTime) {
      let check = window.confirm('入館を行います。');
      if (check === true) {
        updateOrderPartner({
          body: {
            startTime: moment(new Date()).format(DATETIME_JP_FORMAT),
            screenName: SCREEN_NAME.WORKER_DETAIL,
            updateTitle: '入館日時送信',
            companyName: localStorage.getItem('companyName'),
          },
          id_order: orderId,
          organizationId,
          type: 'startTime'
        });
      }
    }
  };

  const onUpdateOrder1 = () => {
    if (workName === 'pre' || workName === 'nextday') return false;
    if (!data?.endTime) {
      let check = window.confirm('退館を行います。');
      if (check === true) {
        updateOrderPartner({
          body: {
            endTime: moment(new Date()).format(DATETIME_JP_FORMAT),
            screenName: SCREEN_NAME.WORKER_DETAIL,
            updateTitle: '退館日時送信',
            companyName: localStorage.getItem('companyName'),
          },
          id_order: orderId,
          organizationId,
          type: 'endTime'
        });
      }
    }
  };

  const onClickActive = state => {
    if (workName === 'pre' && state === 'workOnTheDay') return false;
    updateStatus({ id_order: orderId, state });
  };

  const onReturn = () => {
    history.push(`/workerList?&workName=${workName}`);
  };

  const AnyReactComponent = () =>
    (data?.data?.prefecturesVisited + ' ' + data?.data?.visitedBaseAddress + ' ' + data?.data?.visitedBuildingName ===
      '') !==
    '' ? (
      <i className="fas fa-map-marker-alt" style={{ color: 'red', fontSize: '40px' }}></i>
    ) : (
      ''
    );

  // const nextCancelDetailWorker = () => {
  //   history.push(`cancel-detail-worker?orderId=${orderId}`);
  // };

  const nextWriterReport = ({ menuName, screenName }) => {
    if (workName === 'pre' && menuName === 'workOnTheDay') return false;
    history.push(
      `/report-order?workName=${workName}&orderId=${orderId}&organizationId=${organizationId}&menuName=${menuName}&screenName=${screenName}`
    );
  };

  const handleChangeText = e => {
    let newData = { ...data };
    newData = { ...newData, resultCancel: e.target.value };
    setData(newData);
  };

  const onSubmitData = () => {
    const newData = {...data, companyName: localStorage.getItem('companyName'), updateTitle: `保存 結果 ${data?.settingCompletionResult?.pullDownDisplay || ''}`}
    updateOrderInfo({ body: _.pick(newData, DEFAULT_WORKER_DETAIL), orderId: data?.orderId });
  };

  const updateOrderInfo = async ({ body = {}, orderId = '' }) => {
    setLoading(true);
    try {
      const newbody = {
        ...body,
        screenName: SCREEN_NAME.WORKER_DETAIL,
        statusSiteInfo: false
      };
      if (newbody.completionResult === '') {
        delete newbody.completionResult;
      }
      const res = await ORDER.UPDATE_SITE_INFO({ body: newbody, id_order: orderId, organizationId });
      if (res && res.code !== 0) {
        if (res && res.message && res.message.extra) {
          pushToast('error', res?.message?.extra);
        } else {
          pushToast('error', res?.message?.errorMessage);
        }
        setLoading(false);
      } else {
        pushToast('success', 'Update order success');
        setValueSettingCompletionResult(data?.settingCompletionResult?.result);
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const getStatusResult = async ({ serviceId, productId, orderId, type = 'completionResultSetting' }) => {
    setLoading(true);
    try {
      const data = await ORDER.GET_STATUS_ORDER({ serviceId, productId, orderId, type, organizationId });
      if (data) {
        setStatusResult(data?.data);
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const handleChangeSelect = e => {
    const value = e.target.value;
    let newData = { ...data };
    const settingCompletion = statusResult.filter(i => i.id === value);
    newData = { ...newData, completionResult: value, settingCompletionResult: settingCompletion?.[0] };
    setData(newData);
  };

  const hideFeatureOnStatusIsNextDate = () => {
    if (workName === 'nextday') return false;

    return true;
  };

  return (
    <div className="detail-worker-wrapper">
      <Loading loading={loading} />
      <Helmet>
        <title>工事依頼詳細</title>
      </Helmet>
      <div className="wrapper-work-list pl-0 pr-0 wrapper-work-list--wrap" style={{ minHeight: 0 }}>
        <div className="block-link row mr-0 ml-0 wrapper-work-list--wrap-child wrapper-work-list--wrap-100">
          <div className="col-4 block-link-child text-left">
            <a onClick={onReturn} className="item">
              <span>戻る</span>
            </a>
          </div>
          <div className="block-link-child col-4">
            <span style={{ color: '#fff' }}>工事依頼詳細</span>
          </div>
          <div className="block-feature col-4"></div>
        </div>
      </div>
      <DetailWorker data={data} />
      <div>
        <table cellSpacing="0" cellPadding="0">
          <colgroup>
            <col className="cols__table" />
            <col className="cols__value" />
            <col className="cols__name" />
          </colgroup>
          <tbody>
            {data?.buttonStatus?.isUsed && (
              <tr>
                <td className="sp_none">事前確認</td>
                <td>
                  <input
                    onClick={() => nextWriterReport({ menuName: 'beforeConfirm', screenName: '事前確認登録' })}
                    type="button"
                    data-role="none"
                    className="ui-btn ui-btn-inline ui-corner-all btn_report_y input-left-button"
                    name="btn_report"
                    value="事前確認登録"
                  />
                </td>
                <td>
                  <input
                    type="button"
                    onClick={() => onClickActive('beforeConfirm')}
                    className={`ui-btn ui-btn-inline ui-corner-all input-left-button`}
                    value="事前確認送信"
                  />
                </td>
              </tr>
            )}
            {!_.isEmpty(dataOrder?.versionSetting?.find(elm => elm.name === '入館' && elm.isUsed)) &&
              hideFeatureOnStatusIsNextDate() && (
                <tr>
                  <td className="sp_none">入館日時</td>
                  <td>
                    <div className="ui-input-text ui-body-inherit ui-corner-all ui-shadow-inset">
                      <input
                        type="text"
                        size="18"
                        className="form-control input-left-button"
                        id="txt_enter"
                        readOnly="readonly"
                        value={data?.startTime ? data?.startTime : timeData}
                      />
                    </div>
                  </td>
                  <td>
                    <input
                      onClick={() => onUpdateOrder()}
                      type="button"
                      disabled={workName === 'pre' || !!data?.startTime || !!timeData}
                      className={`ui-btn ui-input-btn ui-corner-all ui-shadow input-left-button
                        ${(data?.startTime ||
                          workName === 'pre' ||
                          workName === 'nextday' ||
                          !!data?.startTime ||
                          !!timeData) &&
                          'btn-check-disable'}`}
                      value="入館日時送信"
                    />
                  </td>
                </tr>
              )}
            {hideFeatureOnStatusIsNextDate() && (
              <tr>
                <td className="sp_none">作業登録</td>
                <td>
                  <input
                    onClick={() => nextWriterReport({ menuName: 'workOnTheDay', screenName: '作業登録' })}
                    type="button"
                    data-role="none"
                    className={`ui-btn ui-btn-inline ui-corner-all btn_report_y input-left-button
                      ${workName === 'pre' && 'btn-check-disable'}
                    `}
                    name="btn_report"
                    value="作業登録"
                  />
                </td>
                <td>
                  <input
                    type="button"
                    onClick={() => onClickActive('workOnTheDay')}
                    disabled={workName === 'pre' || !['完了', '未完了', '当日CAN'].includes(valueSettingCompletionResult)}
                    className={`ui-btn ui-input-btn ui-corner-all ui-shadow input-left-button
                      ${(workName === 'pre' || !['完了', '未完了', '当日CAN'].includes(valueSettingCompletionResult)) &&
                        'btn-check-disable'}
                    `}
                    value="作業内容送信"
                  />
                </td>
              </tr>
            )}
            {!_.isEmpty(dataOrder?.versionSetting?.find(elm => elm.name === '退館' && elm.isUsed)) &&
              hideFeatureOnStatusIsNextDate() && (
                <tr>
                  <td className="sp_none">退館日時</td>
                  <td>
                    <div className="ui-input-text ui-body-inherit ui-corner-all ui-shadow-inset">
                      <input
                        className="form-control input-left-button"
                        type="text"
                        size="18"
                        id="txt_leave"
                        readOnly="readonly"
                        value={data?.endTime ? data?.endTime : timeData1}
                      />
                    </div>
                  </td>

                  <td>
                    <input
                      type="button"
                      onClick={() => onUpdateOrder1()}
                      disabled={workName === 'pre' || !!data ?.endTime || !!timeData1}
                      className={`ui-btn ui-input-btn ui-corner-all ui-shadow input-left-button
                        ${(data ?.endTime
                        || workName === 'pre'
                        || !!data ?.endTime
                        || !!timeData1) && 'btn-check-disable'}
                      `}
                      value="退館日時送信"
                    />
                  </td>
                </tr>
              )}
            {/* { hideFeatureOnStatusIsNextDate () && (
              <tr>
                <td className="sp_none">キャンセル</td>
                <td>
                  <input
                    onClick={nextCancelDetailWorker}
                    type="button"
                    data-role="none"
                    className={`ui-btn ui-btn-inline ui-corner-all btn-goto-cancel-detail input-left-button`}
                    name="btn_report"
                    value="キャンセル連絡"
                  />
                </td>
              </tr>
            )} */}
            {!_.isEmpty(dataOrder?.versionSetting?.find(elm => elm.name === '結果' && elm.isUsed)) &&
              hideFeatureOnStatusIsNextDate() && (
                <tr>
                  <td className="sp_none">結果</td>
                  <td>
                    <select
                      name="completionResult"
                      disabled={workName === 'pre'}
                      onChange={handleChangeSelect}
                      value={data?.completionResult}
                      className={`ui-btn ui-btn-inline ui-corner-all input-left-button ${(workName === 'pre') &&
                        'btn-check-disable'}`}
                    >
                      <option value=""></option>
                      {statusResult &&
                        statusResult.map((item, index) => {
                          return (
                            <option value={item.id} key={index}>
                              {item.pullDownDisplay}
                            </option>
                          );
                        })}
                    </select>
                  </td>
                </tr>
              )}
          </tbody>
        </table>
        {hideFeatureOnStatusIsNextDate() && (
          <div className="input__results">
            <div className="d-flex">
              <div className="input__textarea">結果備考</div>
              <textarea
                className="input__results--cancel"
                value={data?.resultCancel}
                disabled={workName === 'pre'}
                name="resultCancel"
                cols="25"
                rows="5"
                onChange={handleChangeText}
              >
                {data?.resultCancel}
              </textarea>
            </div>
            <div className=" text-center">
              <input
                type="button"
                data-role="none"
                className={`ui-btn ui-btn-inline ui-corner-all button__btn-load--blue_ward btn-update-order ${workName === 'pre' &&
                  'btn-check-disable'}`}
                name="btn_report"
                value="保存"
                onClick={onSubmitData}
                disabled={workName === 'pre'}
              />
            </div>
          </div>
        )}
        <div style={{ height: '100vh', width: '100%' }}>
          {data && lat && lng && (
            <GoogleMapReact
              bootstrapURLKeys={{ key: 'AIzaSyB6rJOxJ5TvRMqXcZCv49Oy8EScg6nfKVQ' }}
              defaultCenter={{ lat, lng }}
              defaultZoom={16}
              options={getMapOptions}
            >
              <AnyReactComponent lat={lat} lng={lng} />
            </GoogleMapReact>
          )}
        </div>
      </div>
    </div>
  );
};

export default DetailWorkerList;
