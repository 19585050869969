import React, { useEffect, useState } from 'react';
import { translate } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useHistory, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import '../../../../assets/scss/page/contract/index.scss';
import Footer from '../../../../components/Footer';
import HeaderMasterSetting from '../../../../components/HeaderMasterSetting';
import PaginationCustom from '../../../../components/PaginationCustom';
import { CONTRACT_ENDPOINTS } from '../../../../constants/endpoints';
import { getNoOrder } from '../../../../helpers/helpers';
import { CREATE_RECRUIMENT } from 'helpers/constants';
import moment from 'moment';
import _ from 'lodash';

const ListRecruitment = (props) => {
  const colorScreen = useSelector((store) => store.utils.colorMe);
  const history = useHistory();
  const [listData, setListData] = useState();
  const [activePage, setActivePage] = useState(1);
  const [idRole, setIdRole] = useState(null);

  const getListRecruitment = async ({ page = 1, limit = 10 }) => {
    try {
      const data = await CONTRACT_ENDPOINTS.GET_LIST_RECRUITMENT({
        page: page,
        limit: limit,
      });
      if (data) {
        setListData(data?.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    setIdRole(localStorage.getItem('role'));
    getListRecruitment({ page: activePage });
  }, [activePage]);

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const checkStatus = (item) => {
    switch (true) {
      case moment(new Date(item?.startTimeRecruitment)).isAfter(new Date()):
        return '募集前';
      case moment(new Date(item?.startTimeRecruitment)).isBefore(new Date()) &&
        moment(new Date(item?.endTimeRecruitment)).isAfter(new Date()):
        return '募集中';
      case moment(new Date(item?.endTimeRecruitment)).isBefore(new Date()):
        return '募集完了';
      case !item?.recruitmentId:
        return '';
      default:
        return '終了';
    }
  };

  const renderItem = (item, index, activePage) => {
    return (
      <tr key={index}>
        <td className="w5 pd0">{getNoOrder({ index: index, limit: 10, activePage: activePage })}</td>
        <td className="w10 pd0">
          <Link
            to={`${
              !_.isEmpty(item?.recruitment)
                ? `/contract/recruitment?id=${item?.recruitment?.recruitmentId}&productId=${item?.productId}&onBack=back&organizationId=${item?.organizationId}`
                : `/contract/recruitment?productId=${item?.productId}&onBack=back&organizationId=${item?.organizationId}`
            } `}
            className="cursor-pointer underline-pt"
          >
            {item?.clientName}
          </Link>
        </td>
        <td className="w15 pd0">{item?.productName}</td>
        <td className="w10 pd0">{item?.projectName}</td>
        <td className="w10 pd0">{item?.recruitment?.areas?.kansai?.[0]}</td>
        <td className="w10 pd0"></td>
        <td className="w10 pd0">{item?.recruitment?.startTimeRecruitment}</td>
        <td className="w10 pd0">{item?.recruitment?.endTimeRecruitment}</td>
        <td className="w10 pd0">
          {item?.recruitment?.startTimeBusiness} - {item?.recruitment?.endTimeBusiness}
        </td>
        <td className="w10 pd0">{checkStatus(item?.recruitment)}</td>
      </tr>
    );
  };

  return (
    <>
      <div className="main">
        <HeaderMasterSetting color={colorScreen?.code} colorText={colorScreen?.colorText} checkName="/contract" />
        <div className="block-contract">
          <div className="list-table">
            <Link to="/contract" className="btn-back">
              戻る
            </Link>
            <div className="table-list-contract">
              <div className="title-recruitment">募集一覧</div>
              <div className="block-search">
                <label>検索条件</label>
                <input type="text" />
                <Link to="#" className="item ml-2">
                  検索
                </Link>
              </div>
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th className="w5 pd5">No</th>
                    <th className="w10 pd5">Monitor</th>
                    <th className="w15 pd5">Product</th>
                    <th className="w10 pd5">Project</th>
                    <th className="w10 pd5">エリア</th>
                    <th className="w10 pd5">資格</th>
                    <th className="w10 pd5">募集開始</th>
                    <th className="w10 pd5">募集終了</th>
                    <th className="w10 pd5">業務期間</th>
                    <th className="w10 pd5">募集状況</th>
                  </tr>
                </thead>
                <tbody>{listData && listData?.data.map((list, index) => renderItem(list, index, activePage))}</tbody>
              </table>
            </div>
            {listData?.data.length > 0 && listData?.count > 10 && (
              <PaginationCustom
                activePage={activePage}
                itemsCountPerPage={10}
                totalItemsCount={listData?.count}
                pageRangeDisplayed={5}
                handlePageChange={handlePageChange}
              />
            )}
          </div>
        </div>
        <Footer color={colorScreen?.code} colorText={colorScreen?.colorText} />
      </div>
    </>
  );
};

export default compose(translate('translations'), withRouter)(ListRecruitment);
