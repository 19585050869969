import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Collapse } from 'reactstrap';
import { ORDER_MANANGER, SCREEN_SETTING } from '../../../constants/endpoints';
import ModalAdd from '../ModalAdd';
import './style.scss';

const dataSearchMockup = {
  name: '',
  projectTitle: '',
  managementCode: '',
  visitedBaseName: '',
  visitDateFrom: '',
  applicationCompanyName: '',
  visitDateTo: '',
};

const arrMockup = [
  {
    title: '未Order',
    name: 'notOrdered',
  },
  {
    title: '未承認',
    name: 'unapproved',
  },
  {
    title: '未打診',
    name: 'unconsulted',
  },
  {
    title: '未アポイント',
    name: 'notAnAppointment',
  },
  {
    title: '日程確定済み',
    name: 'scheduleConfirmed',
  },
  {
    title: '未納品',
    name: 'undelivered',
  },
  {
    title: '納品済み',
    name: 'completedDelivery',
  },
];

const CollapseSearch = ({ isOpen, color, idScreenSetting = '', handleSearch }) => {
  const [dataSearch, setDataSearch] = useState({});
  const [modalAdd, setModalAdd] = useState(false);
  const [listSearchSetting, setListSearchSetting] = useState([]);
  const [activeClass, setActiveClass] = useState('active-click');
  const [dataDefault ,setDataDefault] = useState([])
  const handleChangeSearch = (e) => {
    const name = e.target.name;
    let newData = { ...dataSearch, [name]: e.target.value };
    setDataSearch(newData);
  };

  const handleReset = () => {
    setDataSearch({...dataSearchMockup, services: dataDefault, screenSettingId: idScreenSetting });
    setActiveClass('');
  };

  const onSubmit = () => {
    handleSearch(dataSearch)
  };

  const openModalAdd = () => {
    setModalAdd(!modalAdd);
  };

  const toggleUpdate = () => {
    setModalAdd(!modalAdd);
    setDataSearch({...dataSearchMockup, services: dataDefault, screenSettingId: idScreenSetting });
    setActiveClass('');
    getListSearchSetting();
  };

  useEffect(() => {
    getListSearchSetting();
    getListDataSearch({ idScreenSetting });
  }, []);

  const getListDataSearch = async ({ idScreenSetting }) => {
    try {
      const data = await SCREEN_SETTING.GET_DATA_SEARCH({ idScreenSetting });
      if (data) {
        const detailService = data?.data?.services?.map(item => {
          return {
            notOrdered: false,
            unapproved: false,
            unconsulted: false,
            notAnAppointment: false,
            scheduleConfirmed: false,
            undelivered: false,
            completedDelivery: false,
            serviceId: item.serviceId,
            serviceName: item.name,
          }
        });
        setDataDefault(detailService)
        setDataSearch({...dataSearch, services: detailService, screenSettingId: idScreenSetting});
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getListSearchSetting = async () => {
    try {
      const data = await ORDER_MANANGER.GET_LIST_SEARCH_SETTING({idScreenSetting});
      if (data) {
        setListSearchSetting(data?.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleChangeDate = (date, name) => {
    let newData = { ...dataSearch, [name]: date };
    setDataSearch(newData);
  };

  const handleCheck = (e, serviceId) => {
    const { value, id } = e.target;

    const newListService = dataSearch.services ?.map(item => {
      let arrTmp = {
        notOrdered: false,
        unapproved: false,
        unconsulted: false,
        notAnAppointment: false,
        scheduleConfirmed: false,
        undelivered: false,
        completedDelivery: false,
      };
      var isTrueSet = value === 'true';
      if (serviceId === item.serviceId) {
        const object2 = Object.assign({}, arrTmp, { [id]: !isTrueSet });
        return {...item, ...object2 }
      } else {
        return item
      }
    });
    let newData = { ...dataSearch, services: newListService };
    setDataSearch(newData);
  };

  const onDetail = (id, e) => {
    if (id) {
      setActiveClass(e.target.id);
      getDetailSearch(id);
    }
  };

  const getDetailSearch = async (id) => {
    try {
      const data = await ORDER_MANANGER.GET_DETAIL_SEARCH_SETTING({ id });
      if (data) {
        let newData = data?.data;
        newData = { ...newData };
        delete newData.createdAt;
        delete newData.createdBy;
        delete newData.searchSettingId;
        setDataSearch(newData);
      }
    } catch (errorMessage) {
      console.log('errorMessage: ', errorMessage);
    }
  };

  return (
    <>
      <Collapse isOpen={isOpen}>
        <table style={{ width: '100%' }} className="table-search">
          <tbody>
            <tr>
              <th style={{ backgroundColor: `${color}` }}>工事名</th>
              <td>
                <input
                  type="text"
                  name="projectTitle"
                  value={dataSearch.projectTitle}
                  onChange={handleChangeSearch}
                  className="form-control"
                />
              </td>
              <th style={{ backgroundColor: `${color}` }}>管理コード</th>
              <td>
                <input
                  type="text"
                  name="managementCode"
                  value={dataSearch.managementCode}
                  onChange={handleChangeSearch}
                  className="form-control"
                />
              </td>
            </tr>
            <tr>
              <th style={{ backgroundColor: `${color}` }}>物件名</th>
              <td>
                <input
                  type="text"
                  name="visitedSiteName"
                  value={dataSearch.installationSiteName}
                  onChange={handleChangeSearch}
                  className="form-control"
                />
              </td>
              <th style={{ backgroundColor: `${color}` }}>指定訪問日時</th>
              <td>
                <div className="d-flex align-items-center">
                  <DatePicker
                    selected={dataSearch?.visitDateFrom ? new Date(dataSearch?.visitDateFrom) : ''}
                    onChange={(date) => handleChangeDate(date, 'visitDateFrom')}
                    locale="ja"
                    dateFormat="yyyy/MM/dd"
                    dateFormatCalendar="yyyy年 MM月"
                    className="form-control"
                  />{' '}
                  ～
                  <DatePicker
                    selected={dataSearch?.visitDateTo ? new Date(dataSearch?.visitDateTo) : ''}
                    onChange={(date) => handleChangeDate(date, 'visitDateTo')}
                    locale="ja"
                    dateFormat="yyyy/MM/dd"
                    dateFormatCalendar="yyyy年 MM月"
                    className="form-control"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <th style={{ backgroundColor: `${color}` }}>申込会社名</th>
              <td>
                <input
                  type="text"
                  name="applicationCompanyName"
                  value={dataSearch.applicationCompanyName}
                  onChange={handleChangeSearch}
                  className="form-control"
                />
              </td>
              <th style={{ backgroundColor: `${color}` }}></th>
              <td></td>
            </tr>

            {dataSearch ?.services ?.map((item, index) => {
              return (
                <tr key={index}>
                  <th style={{ backgroundColor: `${color}` }}>{item.serviceName}</th>
                  <td colSpan="3">
                    {arrMockup.map((item2, index2) => {
                      return (
                        <span key={index2}>
                          <input
                            type="radio"
                            name={item.name}
                            value={item?.services?.[item2.name]}
                            id={item2.name}
                            onChange={(e) => handleCheck(e, item.serviceId)}
                            checked={!!item?.[item2.name]}
                          />
                          <label htmlFor={item2.name} className="ml-2 mr-4">
                            {item2.title}
                          </label>
                        </span>
                      );
                    })}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="SearchButton" align="center">
          <button className="btn btnS btnSubmit mr-2" onClick={() => onSubmit()}>
            <i className="icon-search" />検 索
          </button>
          <button type="button" className="btn btnS btnReset mr-2" onClick={handleReset}>
            <i className="icon-arrows-cw" /> 一括解除
          </button>
          <button type="button" className="btn btnS btnReset" onClick={openModalAdd}>
            <i className="fas fa-cog"></i> 検索条件保存
          </button>
        </div>
        {modalAdd ? <ModalAdd modal={modalAdd} toggle={toggleUpdate} dataSearch={dataSearch} getListAction={getListSearchSetting}/> : ''}
      </Collapse>
      <div className="block-list-template-search">
          {listSearchSetting &&
            listSearchSetting.map((item, index) => {
              return (
                <span
                  key={index}
                  className={`item-list ${activeClass === `active-click-${index}` ? 'item-list-active' : ''}`}
                  id={`active-click-${index}`}
                  onClick={(e) => onDetail(item.searchSettingId, e)}
                >
                  {item.name}
                </span>
              );
          })}
      </div>
    </>
  );
};

export default CollapseSearch;
