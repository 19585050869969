import React, { useEffect, useState } from 'react';
import './style.scss';

const TableWorkerRight = ({ value = '', id = '' }) => {
  const [selectOption, setSelectOption] = useState(true);
  const handleOptionChange = (option) => {
    setSelectOption(option);
  };
  return (
    <div className="wrapper-table-worker-right d-flex align-items-center">
      <input className="form-control worker-input mr-3" value={value} disabled={!selectOption} />
      <div className="form-check form-check-inline">
        <input
          className="form-check-input "
          type="radio"
          name={`radioWorkerOption${id}`}
          id={`WorkerRadio1${id}`}
          value="all"
          checked={selectOption === true}
          onChange={() => handleOptionChange(true)}
        />
        <label className="form-check-label" htmlFor={`WorkerRadio1${id}`} style={{ width: '30px' }}>
          利用
        </label>
      </div>
      <div className="form-check form-check-inline mx-3">
        <input
          className="form-check-input"
          type="radio"
          name={`radioWorkerOption${id}`}
          id={`WorkerRadio2${id}`}
          value="process"
          checked={selectOption === false}
          onChange={() => handleOptionChange(false)}
        />
        <label className="form-check-label" htmlFor={`WorkerRadio2${id}`} style={{ width: '30px' }}>
          不要
        </label>
      </div>
      <button
        className="btn button-filedmaster d-flex justify-content-center align-items-center"
        disabled={!selectOption}
      >
        <i className="fas fa-pen-square mr-2" style={{ fontSize: '20px' }} />
        項目設定
      </button>
    </div>
  );
};

export default TableWorkerRight;
