import React, { useState, useEffect } from "react";
import { Collapse } from "reactstrap";
import TableDetailList from "../TableDetailList";
import ModalAddOption from '../ModalAddOption';
import ModalAddSelect from '../ModalAddSelect';

const CollapseTableSetting = (props) => {
  let { item, t, index, p, color, role, roleDetail, idRole, handleHide, hideshow, dataProduct, colorText } = props;
  const [modalOption, setModalOption] = useState(false);
  const [modalSelect, setModalSelect] = useState(false);
  const [idProduct, setIdProduct] = useState();
  const [headers, setHeaders] = useState(item?.serviceOptions || {});

  const toggleHide = (id) => {
    handleHide(id);
    setIdProduct(id);
  };

  const toggleModalOption = () => {
    setModalOption(!modalOption);
  }

  const toggleModalSelect = () => {
    setModalSelect(!modalSelect);
  }

  const handleHeaders = (data) => {
    setHeaders(data);
  }

  return (
    <>
      <div className="wrapper-btn-colappse" onClick={() => toggleHide(item.productId)}>
        <div
          data-id={index}
          className="mb-1 searchbar d-flex justify-content-between align-items-center "
          style={{ background: `${color}` }}
        >
          <div className="ml-2" style={{ color: `${colorText}` }}>
            {item.name}
          </div>
          <div className="mr-2 d-flex" style={{ color: `${colorText}` }}>
            {hideshow === item.productId ? (
              <i className="icon-up-open icon-searchbar" />
            ) : (
              <i className="icon-down-open icon-searchbar" />
            )}
          </div>
        </div>
      </div>
      <Collapse isOpen={hideshow === item.productId}>
        <div className='form__flex form__flex--space-evenly'>
          <button className="btn mt-2 form-btn" style={{ background: `${color}` }} onClick={toggleModalOption}>設定1</button>
          <button className="btn mt-2 form-btn" style={{ background: `${color}` }} onClick={toggleModalSelect}>設定2</button>
        </div>
        <TableDetailList
          idProduct={idProduct}
          dataProduct={dataProduct}
          colorText={colorText}
          p={p}
          t={t}
          color={color}
          roleDetail={roleDetail}
          idRole={idRole}
          role={role}
          item={item}
          header={headers}
          nameTitle={item.name}
        />
      </Collapse>
      <ModalAddOption
        modal={modalOption}
        toggle={toggleModalOption}
        color={color}
        productId={item?.productId}
        handleHeaders={handleHeaders}
        nameTitle={item.name}
      />
      <ModalAddSelect
        modal={modalSelect}
        toggle={toggleModalSelect}
        color={color}
        productId={item?.productId}
        handleHeaders={handleHeaders}
        nameTitle={item.name}
      />
    </>
  );
};

export default CollapseTableSetting;
