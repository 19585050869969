import React from 'react';
import './style.scss';
import TableProcessInfo from './TableProcessInfo';

const SiteInformation = () => {
  return (
    <div className="site-information-wrapper mt-3">
      <div className="wrapper-column" style={{ fontSize: '14px' }}>
        当日現場情報
      </div>
      <div className="d-flex">
        <div className="wrapper-column col-3">結果</div>
        <div className="col-9 table-right">
          <TableProcessInfo />
          {/*<div className="table-process-wrapper p-2 table-responsive">*/}
          {/*  <table>*/}
          {/*    <tbody>*/}
          {/*      <tr>*/}
          {/*        <th rowSpan="5" className="even-title" style={{ width: '60px' }}>*/}
          {/*          3*/}
          {/*        </th>*/}
          {/*        <th rowSpan="5" style={{ width: '100px' }}>*/}
          {/*          開通試験*/}
          {/*        </th>*/}
          {/*        <th rowSpan="1" style={{ width: '120px' }}>*/}
          {/*          完了*/}
          {/*        </th>*/}
          {/*        <td className="even">*/}
          {/*          <div className="d-flex">*/}
          {/*            <input type="text" className="form-control even-input" value="開通試験完了" />*/}
          {/*            <button className="btn button-delete ml-2">削除</button>*/}
          {/*          </div>*/}

          {/*          <div className="d-flex mt-2">*/}
          {/*            <input type="text" className="form-control even-input" value="開通試験完了" />*/}
          {/*            <button className="btn button-delete ml-2">削除</button>*/}
          {/*          </div>*/}

          {/*          <div className="d-flex mt-2">*/}
          {/*            <input type="text" className="form-control even-input" value="開通試験完了" />*/}
          {/*            <button className="btn button-delete ml-2">削除</button>*/}
          {/*          </div>*/}

          {/*          <button className="btn button-add mt-2">追加</button>*/}
          {/*        </td>*/}
          {/*      </tr>*/}
          {/*      <tr>*/}
          {/*        <th rowSpan="1">未完了</th>*/}
          {/*        <td className="even">*/}
          {/*          <div className="d-flex">*/}
          {/*            <input type="text" className="form-control even-input" value="開通試験完了" />*/}
          {/*            <button className="btn button-delete ml-2">削除</button>*/}
          {/*          </div>*/}
          {/*          <button className="btn button-add mt-2">追加</button>*/}
          {/*        </td>*/}
          {/*      </tr>*/}
          {/*      <tr>*/}
          {/*        <th rowSpan="1">前日CAN</th>*/}
          {/*        <td className="even">*/}
          {/*          <div className="d-flex">*/}
          {/*            <input type="text" className="form-control even-input" value="開通試験完了" />*/}
          {/*            <button className="btn button-delete ml-2">削除</button>*/}
          {/*          </div>*/}
          {/*          <button className="btn button-add mt-2">追加</button>*/}
          {/*        </td>*/}
          {/*      </tr>*/}
          {/*      <tr>*/}
          {/*        <th rowSpan="1">事前CAN</th>*/}
          {/*        <td className="even">*/}
          {/*          <div className="d-flex">*/}
          {/*            <input type="text" className="form-control even-input" value="開通試験完了" />*/}
          {/*            <button className="btn button-delete ml-2">削除</button>*/}
          {/*          </div>*/}
          {/*          <button className="btn button-add mt-2">追加</button>*/}
          {/*        </td>*/}
          {/*      </tr>*/}
          {/*      <tr>*/}
          {/*        <th rowSpan="1">当日CAN</th>*/}
          {/*        <td className="even">*/}
          {/*          <div className="d-flex">*/}
          {/*            <input type="text" className="form-control even-input" value="開通試験完了" />*/}
          {/*            <button className="btn button-delete ml-2">削除</button>*/}
          {/*          </div>*/}
          {/*          <button className="btn button-add mt-2">追加</button>*/}
          {/*        </td>*/}
          {/*      </tr>*/}
          {/*    </tbody>*/}
          {/*  </table>*/}
          {/*</div>*/}

          <div className="table-process-wrapper p-2 table-responsive">
            <table>
              <tbody>
                <tr>
                  <th rowSpan="5" className="even-title" style={{ width: '60px' }}>
                    3
                  </th>
                  <th rowSpan="5" style={{ width: '100px' }}>
                    開通試験
                  </th>
                  <th rowSpan="1" style={{ width: '120px' }}>
                    完了
                  </th>
                  <td className="even">
                    <div className="d-flex">
                      <input type="text" className="form-control even-input" value="開通試験完了" />
                      <button className="btn button-delete ml-2">削除</button>
                    </div>

                    <button className="btn button-add mt-2">追加</button>
                  </td>
                </tr>
                <tr>
                  <th rowSpan="1">未完了</th>
                  <td className="even">
                    <div className="d-flex">
                      <input type="text" className="form-control even-input" value="開通試験完了" />
                      <button className="btn button-delete ml-2">削除</button>
                    </div>
                    <button className="btn button-add mt-2">追加</button>
                  </td>
                </tr>
                <tr>
                  <th rowSpan="1">前日CAN</th>
                  <td className="even">
                    <div className="d-flex">
                      <input type="text" className="form-control even-input" value="開通試験完了" />
                      <button className="btn button-delete ml-2">削除</button>
                    </div>
                    <button className="btn button-add mt-2">追加</button>
                  </td>
                </tr>
                <tr>
                  <th rowSpan="1">事前CAN</th>
                  <td className="even">
                    <div className="d-flex">
                      <input type="text" className="form-control even-input" value="開通試験完了" />
                      <button className="btn button-delete ml-2">削除</button>
                    </div>
                    <button className="btn button-add mt-2">追加</button>
                  </td>
                </tr>
                <tr>
                  <th rowSpan="1">当日CAN</th>
                  <td className="even">
                    <div className="d-flex">
                      <input type="text" className="form-control even-input" value="開通試験完了" />
                      <button className="btn button-delete ml-2">削除</button>
                    </div>
                    <button className="btn button-add mt-2">追加</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="d-flex justify-content-center">
            <button className="btn btn button-anken-submit">登録</button>
          </div>
        </div>
      </div>

      <div className="d-flex">
        <div className="wrapper-column col-3">入退館アラート設定</div>
        <div className="col-9 table-right">
          <div className="table-process-wrapper p-2 table-responsive">
            <table>
              <tbody>
                <tr>
                  <th rowSpan="2" style={{ width: '60px' }}>
                    3
                  </th>
                  <th rowSpan="2" style={{ width: '100px' }}>
                    開通試験
                  </th>
                  <th style={{ width: '120px' }}>現場到着予定時間</th>
                  <td className="odd">
                    <div className="d-flex align-items-center">
                      <p className="mb-0 even-label">作業開始</p>
                      <select className="form-control even-select">
                        <option value="00" selected="">
                          00
                        </option>
                        <option value="01">01</option>
                        <option value="02">02</option>
                        <option value="03">03</option>
                        <option value="04">04</option>
                        <option value="05">05</option>
                        <option value="06">06</option>
                        <option value="07">07</option>
                        <option value="08">08</option>
                      </select>
                      <span className="mx-2"> ：</span>
                      <select className="form-control even-select">
                        <option value="00">00</option>
                        <option value="01">01</option>
                        <option value="02">02</option>
                        <option value="03">03</option>
                        <option value="04">04</option>
                        <option value="16">16</option>
                        <option value="17">17</option>
                        <option value="18">18</option>
                        <option value="19">19</option>
                        <option value="20">20</option>
                      </select>
                      <p className="mb-0 ml-2">前</p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>作業確認連絡</th>
                  <td className="odd">
                    <div className="d-flex align-items-center">
                      <p className="mb-0 even-label">作業開始</p>
                      <select className="form-control even-select">
                        <option value="00" selected="">
                          00
                        </option>
                        <option value="01">01</option>
                        <option value="02">02</option>
                        <option value="03">03</option>
                        <option value="04">04</option>
                        <option value="05">05</option>
                        <option value="06">06</option>
                        <option value="07">07</option>
                        <option value="08">08</option>
                      </select>
                      <span className="mx-2"> ：</span>
                      <select className="form-control even-select">
                        <option value="00">00</option>
                        <option value="01">01</option>
                        <option value="02">02</option>
                        <option value="03">03</option>
                        <option value="04">04</option>
                        <option value="16">16</option>
                        <option value="17">17</option>
                        <option value="18">18</option>
                        <option value="19">19</option>
                        <option value="20">20</option>
                      </select>
                      <p className="mb-0 ml-2">後</p>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="table-process-wrapper p-2 table-responsive">
            <table>
              <tbody>
                <tr>
                  <th rowSpan="2" style={{ width: '60px' }}>
                    2
                  </th>
                  <th rowSpan="2" style={{ width: '100px' }}>
                    宅内工事
                  </th>
                  <th style={{ width: '120px' }}>現場到着予定時間</th>
                  <td className="odd">
                    <div className="d-flex align-items-center">
                      <p className="mb-0 even-label">作業開始</p>
                      <select className="form-control even-select">
                        <option value="00" selected="">
                          00
                        </option>
                        <option value="01">01</option>
                        <option value="02">02</option>
                        <option value="03">03</option>
                        <option value="04">04</option>
                        <option value="05">05</option>
                        <option value="06">06</option>
                        <option value="07">07</option>
                        <option value="08">08</option>
                      </select>
                      <span className="mx-2"> ：</span>
                      <select className="form-control even-select">
                        <option value="00">00</option>
                        <option value="01">01</option>
                        <option value="02">02</option>
                        <option value="03">03</option>
                        <option value="04">04</option>
                        <option value="16">16</option>
                        <option value="17">17</option>
                        <option value="18">18</option>
                        <option value="19">19</option>
                        <option value="20">20</option>
                      </select>
                      <p className="mb-0 ml-2">前</p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>作業確認連絡</th>
                  <td className="odd">
                    <div className="d-flex align-items-center">
                      <p className="mb-0 even-label">作業開始</p>
                      <select className="form-control even-select">
                        <option value="00" selected="">
                          00
                        </option>
                        <option value="01">01</option>
                        <option value="02">02</option>
                        <option value="03">03</option>
                        <option value="04">04</option>
                        <option value="05">05</option>
                        <option value="06">06</option>
                        <option value="07">07</option>
                        <option value="08">08</option>
                      </select>
                      <span className="mx-2"> ：</span>
                      <select className="form-control even-select">
                        <option value="00">00</option>
                        <option value="01">01</option>
                        <option value="02">02</option>
                        <option value="03">03</option>
                        <option value="04">04</option>
                        <option value="16">16</option>
                        <option value="17">17</option>
                        <option value="18">18</option>
                        <option value="19">19</option>
                        <option value="20">20</option>
                      </select>
                      <p className="mb-0 ml-2">後</p>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="table-process-wrapper p-2 table-responsive">
            <table>
              <tbody>
                <tr>
                  <th rowSpan="2" style={{ width: '60px' }}>
                    6
                  </th>
                  <th rowSpan="2" style={{ width: '100px' }}>
                    現場調査
                  </th>
                  <th style={{ width: '120px' }}>現場到着予定時間</th>
                  <td className="odd">
                    <div className="d-flex align-items-center">
                      <p className="mb-0 even-label">作業開始</p>
                      <select className="form-control even-select">
                        <option value="00" selected="">
                          00
                        </option>
                        <option value="01">01</option>
                        <option value="02">02</option>
                        <option value="03">03</option>
                        <option value="04">04</option>
                        <option value="05">05</option>
                        <option value="06">06</option>
                        <option value="07">07</option>
                        <option value="08">08</option>
                      </select>
                      <span className="mx-2"> ：</span>
                      <select className="form-control even-select">
                        <option value="00">00</option>
                        <option value="01">01</option>
                        <option value="02">02</option>
                        <option value="03">03</option>
                        <option value="04">04</option>
                        <option value="16">16</option>
                        <option value="17">17</option>
                        <option value="18">18</option>
                        <option value="19">19</option>
                        <option value="20">20</option>
                      </select>
                      <p className="mb-0 ml-2">前</p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>作業確認連絡</th>
                  <td className="odd">
                    <div className="d-flex align-items-center">
                      <p className="mb-0 even-label">作業開始</p>
                      <select className="form-control even-select">
                        <option value="00" selected="">
                          00
                        </option>
                        <option value="01">01</option>
                        <option value="02">02</option>
                        <option value="03">03</option>
                        <option value="04">04</option>
                        <option value="05">05</option>
                        <option value="06">06</option>
                        <option value="07">07</option>
                        <option value="08">08</option>
                      </select>
                      <span className="mx-2"> ：</span>
                      <select className="form-control even-select">
                        <option value="00">00</option>
                        <option value="01">01</option>
                        <option value="02">02</option>
                        <option value="03">03</option>
                        <option value="04">04</option>
                        <option value="16">16</option>
                        <option value="17">17</option>
                        <option value="18">18</option>
                        <option value="19">19</option>
                        <option value="20">20</option>
                      </select>
                      <p className="mb-0 ml-2">後</p>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className='d-flex justify-content-center'>
            <button className="btn btn button-anken-submit">登録</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SiteInformation;
