import React, { useEffect, useState } from 'react';
import { translate } from 'react-i18next';
import { connect, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { getListOrder } from '../../../actions/order';
import Footer from '../../../components/Footer';
import Header from '../../../components/Header';
import TableListEquipment from './TableListEquipment';
import TableListGoodsMaster from './TableListGoodsMaster';
import TableListEnterGoods from './TableListEnterGoods';
import TableListGoodsMasterFinish from './TableListGoodsMasterFinish';
import TableListEnterGoodsFinish from './TableListEnterGoodsFinish';
import MemoHistory from '../../../components/MemoHistory';
import { SCREEN_NAME } from '../../../helpers/constants';

const renderButtonStep = ({
  key = 0,
  title = '',
  active = false,
  modify = 0,
  onClickActive = () => {},
}) => {
  return (
    <React.Fragment key={key}>
      <button
        className={`btn mr-2 form-btn form-btn-custom ${active ? 'button-table-active button-table-active-custom' : 'button-table'}`}
        key={key}
        onClick={() => onClickActive(modify)}
      >
        {title}
      </button>
    </React.Fragment>
  );
};

const WorkerMasterSetting = ({ history }) => {
  const [stepActive, setStepActive] = useState(null);
  const modify = (process.browser && new URL(window.location.href).searchParams.get('modify')) || null;
  const colorScreen = useSelector((store) => store.utils.colorMe);
  const [idRole, setIdRole] = React.useState(null);

  const listTableInformation = [
    { label: '入荷', value: '1', active: true },
    { label: '入庫', value: '2', active: false },
    { label: '出荷', value: '3', active: false },
    { label: '出庫', value: '4', active: false },
    { label: '物品マスタ', value: '5', active: false }
  ];

  useEffect(() => {
    if (modify) {
      setStepActive(modify);
    }
  }, [modify]);

  useEffect(() => {
    const newRole = localStorage.getItem('role');
    setIdRole(newRole);
  }, []);

  const onClickActive = (modify) => {
    setStepActive(modify);
    history.push(`/equipment-management?modify=${modify}`);
  };

  const renderInformationTable = (modify) => {
    switch (modify) {
      case '1':
        return <TableListGoodsMaster />;
      case '2':
        return <TableListGoodsMasterFinish />;
      case '3':
        return <TableListEnterGoods />;
      case '4':
        return <TableListEnterGoodsFinish />;
      default:
        return <TableListEquipment />;
    }
  };

  return (
    <>
      <div className="main">
        <Header p='equipment-management' idRole={idRole} color={colorScreen?.code} colorText={colorScreen?.colorText} />
        <div className=" my-1">
          {listTableInformation.map((item, index) => {
            return renderButtonStep({
              title: item.label,
              active: item.value === stepActive,
              modify: item.value,
              onClickActive,
              key: index,
            });
          })}
        </div>
        {stepActive && renderInformationTable(stepActive)}
        <MemoHistory colors={colorScreen?.code} colorText={colorScreen?.colorText} screenName={SCREEN_NAME.ITEM_MANAGEMENT} />
        <Footer color={colorScreen?.code} />
      </div>
    </>
  );
};

const mapStateToProps = (store) => {
  return {
    listOrder: store.order.listOrder,
    loading: store.utils.loading,
  };
};

const mapActionToProps = {
  getListOrder,
};

export default compose(
  translate('translations'),
  withRouter,
  connect(mapStateToProps, mapActionToProps)
)(WorkerMasterSetting);
