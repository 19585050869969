import React, { useEffect, useState } from 'react';
import { MASTER_SETTING, ORDER, ORDER_PARTNER } from '../../../constants/endpoints';
import { Collapse } from 'reactstrap';
import './../../../assets/scss/page/DetailErp/style.scss';
import ModalSelect from '../../ModalSelect';
import { useHistory } from 'react-router';
import { pushToast } from '../../Toast';
import { SCREEN_NAME, POPUP_ADD_PARTNER, PART_NAME_DETAIL_ADD_STAFF, DATE_JP_FORMAT } from '../../../helpers/constants';
import _ from 'lodash';
import { formatInputSelectDate } from '../../../helpers/helpers';
import { getDataHistory } from 'actions/authentication';
import moment from 'moment';
import { useDispatch } from 'react-redux';

const DetailTimeWorker = ({ data, getOrderDetail, screenNameLogic = '' ,screenName = ''}) => {
  const dispatch = useDispatch();
  const permissionId =
    localStorage.getItem('role') === '7'
      ? localStorage.getItem('permissionIdGroup')
      : localStorage.getItem('permissionId');

  let worker = {
    partnerName: data?.abbreviationName || '',
  };

  const history = useHistory();
  const isPreparation = PART_NAME_DETAIL_ADD_STAFF.includes(history.location.pathname);
  if (history.location.pathname === '/detail-receive-confirm') {
    worker = !_.isEmpty(data?.listConfirmStaff) ? data.listConfirmStaff.find((e) => e.partnerId === permissionId) : {};
    worker = {
      ...worker,
      partnerName: data?.abbreviationName,
    };
  } else {
    worker = {
      workerName: data?.workerName,
      staffId: data?.staffId,
      partnerName: data?.abbreviationName,
    };
  }

  const partnerId = isPreparation ? data?.partnerId : '';
  let orderId = process.browser && new URL(window.location.href).searchParams.get('orderId');
  let organizationId = process.browser && new URL(window.location.href).searchParams.get('organizationId');
  const [isOpen1, setIsOpen1] = useState(true);
  const [isOpenSelect, setIsOpenSelect] = useState(false);
  const [dataPartner, setDataPartner] = useState();
  const [submit, setSubmit] = useState(false);

  useEffect(() => {
    if (POPUP_ADD_PARTNER.includes(history.location.pathname)) {
      setSubmit(true);
    } else {
      setSubmit(false);
    }
  }, [history.location.pathname]);

  const onClickShow1 = () => {
    setIsOpen1(!isOpen1);
  };

  const handleModalSelect = () => {
    setIsOpenSelect(!isOpenSelect);
  };

  const toggleModalSelect = () => {
    setIsOpenSelect(!isOpenSelect);
  };

  const updateConfirmCondition = async ({ id_order = '', body = '', check = false }) => {
    body = {
      ...body,
      screenName: screenName,
    }
    try {
      const data = check
        ? await ORDER.UPDATE_WORKER_NAME({ body, id_order, organizationId })
        : await ORDER_PARTNER.ADD_STAFF_ORDER_PARTNER({
            body,
            id_order,
            organizationId,
            permissionIdGroup:
              localStorage.getItem('role') === '7' && history.location.pathname === '/detail-receive-confirm'
                ? localStorage.getItem('permissionIdGroup')
                : '',
          });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
      } else {
        dispatch(getDataHistory({ orderId: id_order, screenName: body.screenName, organizationId }));
        pushToast('success', 'Add worker success');
        getOrderDetail({ orderId: orderId, organizationId: organizationId });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const updateConfirm = async (updateStaff, leader) => {
    await updateConfirmCondition({
      id_order: orderId,
      body: { staffId: updateStaff, screenName, companyName: localStorage.getItem('companyName'), updateTitle: '作業員選択', leader: leader },
      check: isPreparation,
    });
  };

  const getPartnerListOr = async ({ orderId = '', organizationId = '', partnerId = '' }) => {
    try {
      const data = await MASTER_SETTING.GET_STAFF_BY_ORDER({
        orderId,
        organizationId,
        partnerId:
          localStorage.getItem('role') === '7' && history.location.pathname === '/detail-receive-confirm'
            ? localStorage.getItem('permissionIdGroup')
            : partnerId,
        screenName: screenNameLogic,
      });
      if (data?.data) {
        setDataPartner(data?.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  // useEffect(() => {
  //   POPUP_ADD_PARTNER.includes(history.location.pathname) && getPartnerListOr({ orderId, organizationId, partnerId });
  // }, [partnerId]);

  return (
    <div>
      <div className="detail-erp-table-wrapper">
        <div className="title-absolute" onClick={onClickShow1}>
          訪問日時・作業員
          {isOpen1 ? (
            <i className="fas fa-arrow-circle-up ml-2"></i>
          ) : (
            <i className="fas fa-arrow-circle-down ml-2"></i>
          )}
        </div>
        <Collapse isOpen={isOpen1}>
          <div className="detail__managerment">
            <table className="detail__table">
              <tbody>
                <tr>
                  <th>訪問日時</th>
                  <td className="detail__table--td">
                    <div className="d-flex detail__table--w">
                      <div className=" text-center detail__table--color mr-1">
                        <label className="detail__table--text detail__table--p">{data?.visitDate}</label>
                      </div>
                      <div className=" text-center detail__table--color mr-1">
                        <label className="detail__table--text detail__table--p">{data?.visitDateFrom}</label>
                      </div>
                      <div className=" text-center detail__table--color mr-1">
                        <label className="detail__table--text detail__table--label">
                          {data?.visitDateTo
                            ? moment(`${formatInputSelectDate(data?.visitDateTo)}`, `${DATE_JP_FORMAT}`).isValid()
                              ? data?.visitDateTo
                              : `${data?.visitDateTo && `${data?.visitDate} ${data?.visitDateTo}`}`
                            : ''}
                        </label>
                      </div>
                    </div>
                  </td>
                  <th>作業員</th>
                  <td className="d-flex">
                    <button
                      className="detail__table--button detail__table--model mr-1"
                      disabled={!submit}
                      onClick={() => handleModalSelect()}
                    >
                      登録
                    </button>
                    <div className=" text-center detail__table--color  mr-1 detail__table--partner">
                      <label className="detail__table--text">{worker?.partnerName}</label>
                    </div>
                    <div className=" d-flex detail__table--color detail__table--font">
                      <label className="detail__table--text">確定：{worker?.workerName?.length || 0}名</label>
                      {worker?.workerName?.length > 0 &&
                        worker?.workerName?.slice(0, 3).map((item, index) => {
                          return (
                            <button
                              key={index}
                              disabled
                              className="detail__table--submit detail__table--button detail__table--font--button"
                              title={item}
                            >
                              {item}
                            </button>
                          );
                        })}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            {isOpenSelect && (
              <ModalSelect
                modal={isOpenSelect}
                updateConfirm={updateConfirm}
                toggle={toggleModalSelect}
                orderId={orderId}
                staffId={worker?.staffId || []}
                partnerName={data?.partnerName}
                statusConditionInfo={data?.statusConditionInfo}
                visitDate={data?.visitDate}
                organizationId={organizationId}
              />
            )}
          </div>
        </Collapse>
      </div>
    </div>
  );
};

export default DetailTimeWorker;
