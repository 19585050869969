import React, { useEffect, useState } from 'react';
import Header from 'components/Header';
import Footer from 'components/Footer';
import { ORDER } from 'constants/endpoints';
import { SCREEN_NAME } from 'helpers/constants';
import { pushToast } from 'components/Toast';
import 'assets/scss/page/DetailSiteInfo/style.scss';
import DetailData from 'components/DetailCenter/DetailData';
import DetailDataList from 'components/DetailCenter/DetailDataList';
import DetailOrder from 'components/DetailCenter/DetailOrder';
import DetailListAllData from 'components/DetailCenter/DetailListAllData';
import { useHistory, Link } from 'react-router-dom';
import DetailTreeDocumentSetting from 'components/DetailTreeDocumentSetting';
import moment from 'moment';
import { useSelector } from 'react-redux';
import DetailResult from '../../../components/DetailOrderErp/DetailResult';
import DetailTimeWorker from '../../../components/DetailOrderErp/DetailTimeWorker';
import DetailSent from '../../../components/DetailOrderErp/DetailSent';
import { Collapse } from 'reactstrap';
import PopupConfirmCustom from 'components/popupConfirmCustom';
import _ from 'lodash';
import { getRoleNew } from '../../../helpers/helpers';

const mockData = {
  endTime: '',
  recycle: '',
  resultCancel: '',
  screenName: SCREEN_NAME.SITE_INFO,
  statusSiteInfo: false,
};

const DetailSiteInfo = () => {
  const colorScreen = useSelector((store) => store.utils.colorMe);
  const [idRole, setIdRole] = React.useState(null);
  const [nameRole, setNameRole] = React.useState(null);
  const [dataOrder, setDataOrder] = useState(mockData);
  const [addHour, setAddHour] = useState('');
  const [addMinutes, setAddMinutes] = useState('');
  const [endHour, setEndHour] = useState('');
  const [endMinutes, setEndMinutes] = useState('');
  const [data, setData] = useState([]);
  let orderId = process.browser && new URL(window.location.href).searchParams.get('orderId');
  let organizationId = process.browser && new URL(window.location.href).searchParams.get('organizationId') || '';
  let name = (process.browser && new URL(window.location.href).searchParams.get('p')) || 'site_info';
  const [p, setP] = React.useState(name);
  const history = useHistory();
  const [statusResult, setStatusResult] = useState([]);
  const [isOpen, setIsOpen] = useState(true);
  const [showPopupConfirm, setShowPopupConfirm] = useState(false);
  const [endDay, setEndDay] = useState('');
  const [dataDetailTimeWorker, setDataDetailTimeWorker] = useState({})

  const onClickShow = () => {
    setIsOpen(!isOpen);
  };

  const [listDetailScreen, setListDetailScreen] = useState([]);

  useEffect(() => {
    getListRoleChild();
  }, []);

  const getListRoleChild = async () => {
    const newRole = localStorage.getItem('role');
    try {
      const data = await ORDER.GET_LIST_DETAIL_SETTING({
        arrRole: newRole,
        nameScreen: 'site_info',
        orderId,
        organizationId
      });
      if (data) {
        setListDetailScreen(data?.data?.detailScreen?.concat({ name: 'button', sortNum: 6, status: true }, { name: 'documentSetting', sortNum: 99, status: true }));
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    setP(name);
  }, [name]);

  useEffect(() => {
    if (dataOrder) {
      setAddHour(dataOrder?.startTime ? moment(dataOrder?.startTime, 'YYYY/MM/DD HH:mm').format('HH') : '');
      setAddMinutes(dataOrder?.startTime ? moment(dataOrder?.startTime, 'YYYY/MM/DD HH:mm').format('mm') : '');
      setEndHour(dataOrder?.endTime ? moment(dataOrder?.endTime, 'YYYY/MM/DD HH:mm').format('HH') : '');
      setEndMinutes(dataOrder?.endTime ? moment(dataOrder?.endTime, 'YYYY/MM/DD HH:mm').format('mm') : '');
      setEndDay(dataOrder?.endTime ? moment(dataOrder?.endTime, 'YYYY/MM/DD HH:mm').format('YYYY/MM/DD') : '');
    }
  }, [dataOrder]);

  useEffect(() => {
    if (data) {
      setDataOrder({
        ...dataOrder,
        startTime: data.startTime,
        endTime: data.endTime,
        recycle: data.recycle,
        resultCancel: data.resultCancel,
        completionResult: data.completionResult,
        settingCompletionResult: data.settingCompletionResult,
      });
    }
  }, [data]);

  const getOrderDetail = async ({ orderId = '' }) => {
    try {
      const data = await ORDER.GET_DETAIL_CANCEL_INFO({ orderId, screenName: 'site_info', organizationId });
      if (data) {
        setData(data?.data);
        setDataDetailTimeWorker(data?.data)
        getStatusResult({
          serviceId: data?.data?.serviceId,
          productId: data?.data?.productId,
          orderId: data?.data?.orderId,
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getDataOrderForDetailTimeWorker = async () => {
    try {
      const data = await ORDER.GET_DETAIL_CANCEL_INFO({ orderId, organizationId });
      if (data) {
        setDataDetailTimeWorker(data?.data)
      }
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    const newRole = localStorage.getItem('role');
    setIdRole(newRole);
    if (newRole) {
      setNameRole(getRoleNew({ idRole: newRole }));
    }
    if (orderId) {
      getOrderDetail({
        orderId,
      });
    }
  }, [orderId]);

  const HandleHour1 = () => {
    let d = new Date();
    let hour = (d.getHours() < 10 ? '0' : '') + d.getHours();
    let minutes = (d.getMinutes() < 10 ? '0' : '') + d.getMinutes();
    setAddHour(hour);
    setAddMinutes(minutes);
    let newData = { ...dataOrder };
    let d5 = moment(dataOrder?.startTime ? new Date(dataOrder?.startTime) : new Date()).set({
      hour,
      minute: minutes,
    });
    newData = { ...newData, startTime: d5.format('YYYY/MM/DD HH:mm:ss') };
    setDataOrder(newData);
  };

  const HandleHour2 = () => {
    let d = new Date();
    let hour = (d.getHours() < 10 ? '0' : '') + d.getHours();
    let minutes = (d.getMinutes() < 10 ? '0' : '') + d.getMinutes();
    setEndHour(hour);
    setEndMinutes(minutes);
    let d5 = moment(dataOrder?.endTime ? new Date(dataOrder?.endTime) : new Date()).set({
      hour,
      minute: minutes,
    });
    let newData = { ...dataOrder };
    newData = { ...newData, endTime: d5.format('YYYY/MM/DD HH:mm:ss') };
    setDataOrder(newData);
  };

  const handleChangeText = (e) => {
    let name = e.target.name;
    let newData = { ...dataOrder };
    newData = { ...newData, [name]: e.target.value };
    setDataOrder(newData);
  };

  const handleChangeSelect = (e) => {
    const value = e.target.value;
    let newData = { ...dataOrder };
    const settingCompletion = statusResult.filter((i) => i.id === value);
    newData = { ...newData, completionResult: value, settingCompletionResult: settingCompletion?.[0] };
    setDataOrder(newData);
  };

  const updateOrderInfo = async ({ body = {}, id_order = '' }) => {
    try {
      const data = await ORDER.UPDATE_SITE_INFO({ body, id_order , organizationId });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
      } else {
        pushToast('success', 'Update order success');
        if (body?.recycle) {
          return history.push(`/recreate/detail_recreate/${orderId}`);
        } else {
          return history.push('/list?p=site_info');
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onUpdateOrder = (data) => {
    updateOrderInfo({ body: { ...dataOrder, ...data, companyName: localStorage.getItem('companyName') }, id_order: orderId });
  };

  const getStatusResult = async ({ serviceId, productId, orderId, type = 'completionResultSetting' }) => {
    try {
      const data = await ORDER.GET_STATUS_ORDER({ serviceId, productId, orderId, type, organizationId });
      if (data) {
        setStatusResult(data?.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onPopup = () => {
    if (dataOrder?.settingCompletionResult?.result === '当日CAN') {
      setShowPopupConfirm(!showPopupConfirm);
    } else {
      onUpdateOrder({ recycle: false , updateTitle : `保存 結果：${dataOrder?.settingCompletionResult?.pullDownDisplay || ''}` });
    }
  }

  return (
    <div className="wrapper-detail-site-info">
      <div className="main">
        <Header p={p} idRole={idRole} color={colorScreen?.code} colorText={colorScreen?.colorText} />
        <div className="body-wrapper">
          <div className="logout_btn">
            <Link to={'list?p=site_info'}>
              <span className="mypage_ab_custom">戻る</span>
            </Link>
          </div>
          {_.sortBy(listDetailScreen, 'sortNum')?.map((item, index) => {
            if (item.status) {
              switch (item.name) {
                case 'clientCompany':
                  return <DetailOrder data={data} p={p} key={index} />;
                case 'applicationCompany':
                  return <DetailData data={data} p={p} link={false} key={index} />;
                case 'mattersSent':
                  return <DetailSent data={data} isDisabledManagement={true} isDisabledErp={true} key={index} role={nameRole}/>;
                case 'visitDateWorker':
                  return <DetailTimeWorker screenName={SCREEN_NAME.SITE_INFO} data={dataDetailTimeWorker} getOrderDetail={getDataOrderForDetailTimeWorker} key={index} />;
                case 'result':
                  return (
                    <div className="detail-erp-table-wrapper" key={index} >
                      <div className="title-absolute" onClick={onClickShow}>
                        作業結果
                        {isOpen ? (
                          <i className="fas fa-arrow-circle-up ml-2"></i>
                        ) : (
                          <i className="fas fa-arrow-circle-down ml-2"></i>
                        )}
                      </div>
                      <Collapse isOpen={isOpen}>
                        <div className="detail__managerment">
                          <table className="detail__table">
                            <tbody>
                              <tr>
                                <th className="detail__table--color">入館・退館</th>
                                <td className="d-flex">
                                  <div className="detail__table--textI text-center  d-flex">
                                    <input
                                      className="detail__table--color"
                                      value={addHour && addMinutes ? `${addHour} : ${addMinutes}` : ''}
                                      disabled
                                    />
                                  </div>
                                  <button className="detail__table--button w-50" onClick={() => HandleHour1()}>
                                    入館
                                  </button>
                                  <div className="detail__table--textI text-center  d-flex">
                                    <input
                                      className="detail__table--color"
                                      value={endDay === '' ? '' : `(${endDay}) ${endHour}:${endMinutes}`}
                                      disabled
                                    />
                                  </div>
                                  <button className="detail__table--button w-50" onClick={() => HandleHour2()}>
                                    退館
                                  </button>
                                </td>
                                <th className="detail__table--color" rowSpan="2">
                                  結果備考
                                </th>
                                <td rowSpan="2">
                                  <textarea
                                    value={dataOrder?.resultCancel}
                                    onChange={handleChangeText}
                                    name="resultCancel"
                                    className="input__custom--w75"
                                  >
                                    {dataOrder?.resultCancel}
                                  </textarea>
                                </td>
                              </tr>
                              <tr>
                                <th className="detail__table--color">作業結果</th>
                                <td>
                                  <select
                                    name="completionResult"
                                    onChange={handleChangeSelect}
                                    value={dataOrder?.completionResult}
                                  >
                                    <option value=""></option>
                                    {statusResult &&
                                      statusResult.map((item, index) => {
                                        return (
                                          <option value={item.id} key={index}>
                                            {item.pullDownDisplay}
                                          </option>
                                        );
                                      })}
                                  </select>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </Collapse>
                    </div>
                  )
                case 'acceptanceInformation1':
                  return (
                    <DetailResult key={index} orderId={orderId} screenName={SCREEN_NAME.SITE_INFO} />
                  );
                case 'button':
                  return <div key={index} className="group-action-update-order mb-2">
                    <span className="btn-update-order__wrap">
                      <button className="btn-update-order" onClick={onPopup}>
                        保存
                      </button>
                      <PopupConfirmCustom
                        headerTitle="確認"
                        rederBody={() => {
                          return (
                            <>
                              <div>キャンセルを受け付けました。</div>
                              <div>この情報を複製して物件情報を作成しますか？</div>
                            </>
                          );
                        }}
                        okText="はい"
                        onConfirm={() => {
                          onUpdateOrder({ recycle: true, updateTitle: `保存 結果：${dataOrder?.settingCompletionResult?.pullDownDisplay || ''}` });
                          setShowPopupConfirm(false);
                        }}
                        cancelText="いいえ"
                        onCancel={() => {
                          onUpdateOrder({ recycle: false, updateTitle: `保存 結果：${dataOrder?.settingCompletionResult?.pullDownDisplay || ''}` });
                          setShowPopupConfirm(false);
                        }}
                        showPopup={showPopupConfirm}
                        onToggle={() => setShowPopupConfirm(!showPopupConfirm)}
                      />
                    </span>
                  </div>
                case 'detailInfo':
                  return <DetailListAllData data={data} screenName={SCREEN_NAME.SITE_INFO} key={index} />;
                case 'document':
                  return <DetailTreeDocumentSetting orderId={orderId} order={data} key={index} />;
                case 'history':
                  return <DetailDataList datePick={false} screenName={SCREEN_NAME.SITE_INFO} orderId={orderId} key={index} />;
                default:
                  break;
              }
            }
          })}

          {/* <MemoTable orderId={orderId} screenName={SCREEN_NAME.SITE_INFO} /> */}
        </div>
        <Footer color={colorScreen?.code} colorText={colorScreen?.colorText} />
      </div>
    </div>
  );
};

export default DetailSiteInfo;
