import React, { useState } from 'react';
import FooterMaintenance from 'components/FooterMaintenance';
import HeaderMasterSetting from 'components/HeaderMasterSetting';
import { useSelector } from 'react-redux';
import Loading from 'components/Loading/Loading';
import Schedules from './Schedules';
import _ from 'lodash';
import 'assets/scss/page/center/Users/edit.scss'
import { SCREEN_NAME } from '../../../helpers/constants';
import MemoHistory from '../../../components/MemoHistory';

const WorkingSchedule = () => {
  const colorScreen = useSelector((store) => store.utils.colorMe);
  const [loading, setLoading] = useState(false);

  const changeLoading = (status) => {
    setLoading(status);
  }

  return (
    <div className="wrapper-master-setting">
      <Loading loading={loading} />
      <HeaderMasterSetting color={colorScreen?.code} colorText={colorScreen?.colorText} />
      {
        <>
          <div className="body pb-4">
            <div className="wrapper-main">
              <div className="wrapper-result">
                <div className="result-title">
                </div>
              </div>
              <Schedules colors={colorScreen?.code} colorText={colorScreen?.colorText} changeLoading={changeLoading} />
              <div className="d-flex justify-content-center align-items-center group-button"></div>
            </div>
          </div>
        </>
      }
      <MemoHistory colors={colorScreen?.code} colorText={colorScreen?.colorText} screenName={SCREEN_NAME.WORKING_SCHEDULE} />
      <FooterMaintenance />
    </div>
  );
};

export default WorkingSchedule;
