import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import 'assets/scss/page/AnkenMasterNew/ModalCreateService.scss';

import { pushToast } from 'components/Toast';
import TableModalService from './TableModalService';
import { ANKEN_MASTER_NEW } from 'constants/endpoints';
import { SCREEN_NAME } from '../../../../helpers/constants';


const ModalCreateService = (props) => {
  const { toggle = () => { }, modal = false, createSuccess } = props;
  const [nameService, setNameService] = useState('');
  const [statusGetList, setStatusGetList] = useState(false);

  const changeNameService = (e) => {
    setNameService(e.target.value)
  };

  const createService = () => {
    const body = {name: nameService?.trim(), screenName : SCREEN_NAME.CASE_MASTA, updateTitle : '登録 工程管理'};
    handleCreateService({body});
  }

  const handleCreateService = async ({body}) => {
    try {
      const data = await ANKEN_MASTER_NEW.CREATE_SERVICE({ body });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
      } else {
        pushToast('success', 'Create Service success');
        setStatusGetList(true);
        createSuccess();
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div>
      <Modal className="modal-dialog-custom" isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>工程管理</ModalHeader>
        <ModalBody>
          <div className="form-body-service">
          <div className="form-get-code">
              <div className="group-input">
                <div className="title">工程名</div>
                <input
                  type="text"
                  value={nameService}
                  onChange={changeNameService}
                />
              </div>
              <button
                onClick={() => createService()}
              >登録</button>

            </div>
            <TableModalService
              statusGetList={statusGetList}
              setStatusGetList={() =>
                setStatusGetList(false)
              }
              createSuccess={createSuccess}
            />
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ModalCreateService;
