import React, { useEffect, useState, useRef } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { ROLE, ROLE_ENUM } from '../../helpers/constants';
//HEADER_MENU,
import { Helmet } from 'react-helmet';
import { logOut, getRoleMe } from '../../actions/authentication';
import { compose } from 'redux';
import { connect, shallowEqual, useSelector, useDispatch } from 'react-redux';
import './style.scss';
import { getLogo, getColorMe } from 'actions/utils';
import { getRole, getRoleColor } from '../../helpers/helpers';
import { COLOR_ENDPOINT, MASTER_SETTING, CHAT, ORDER } from '../../constants/endpoints';
import { useHistory } from 'react-router-dom';
import Loading from 'components/Loading/Loading';
import _ from 'lodash';

const Header = (props) => {
  const {
    p = '',
    logOut,
    idRole,
    color = '#1c2a53',
    showAll,
    showData,
    classCustom = '',
    colorText = '#000000',
    getRoleMe,
    roleDetail,
    detailCheck = {},
    isChat = false,
    total = null,
  } = props;

  const [activeMenu, setActiveMenu] = useState('全情報');
  const history = useHistory();
  const userMe = useSelector((store) => store.authentication.userMe, shallowEqual);
  const organizationArr = userMe?.data?.organization || [];
  const organnizationCurrent = localStorage.getItem('idProvide');
  let isUserRole2 =
    _.find((organizationArr || []), { idOrganization: `${organnizationCurrent}`, idRole: '3' }) ||
    _.find((organizationArr || []), { idOrganization: `${organnizationCurrent}`, idRole: '6' });

  const [role, setRole] = useState(null);
  const [listMenu, setListMenu] = useState('');
  const [totalMessages, setTotalMessages] = useState({ total: '' });
  const [totalConfirm, setTotalConfirm] = useState();
  const [toggleListAll, setToggleListAll] = useState(false);
  const [toggleShowData, setToggleShowData] = useState(true);
  const [listProductMain, setListProductMain] = useState([]);
  const url = props.history.location.pathname;
  const [loading, setLoading] = useState(!['/select-role', '/my_page', '/chat-page'].includes(url));
  const logo = useSelector((store) => store.utils.logo);
  const counter = useRef(0);
  const dispatch = useDispatch();
  const dispatchAddLogoToCart = (message) => dispatch(getLogo(message));
  const dispatchAddToCart = (message) => dispatch(getColorMe(message));

  const imageLoaded = () => {
    counter.current += 1;
    if (counter.current >= listMenu.length) {
      setLoading(false);
    }
  };

  const getListMenu = async ({ role = '' }) => {
    setLoading(true);
    try {
      const data = await COLOR_ENDPOINT.GET_LIST_MENU_BY_ROLE({ role: idRole });
      if (data && data?.code === 0) {
        const newData = [5, 6, 7, '5', '6', '7'].includes(idRole)
          ? data?.data
          : data?.data?.filter((e) => e.name !== 'orderGroup');
        setListMenu(newData);
      }
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const getColorInfo = async ({ idRole, idProvide }) => {
    setLoading(true);
    try {
      const data = await MASTER_SETTING.GET_LIST_COLOR_BY_ORGANIZATION(1, idProvide);
      if (data) {
        if (data) {
          const color = data?.data?.[getRoleColor({ idRole })];
          const colorMaster = {
            code: color?.codeColor,
            colorText: color?.colorText,
            colorId: data?.data?.colorId,
          };
          dispatchAddLogoToCart(data?.data);
          dispatchAddToCart(colorMaster);
        }
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const ChangeToggle = (status) => {
    setToggleListAll(status);
  };

  const ChangeToggleData = (status) => {
    setToggleShowData(status);
  };

  useEffect(() => {
    if (showAll) {
      showAll(toggleListAll);
    }
  }, [toggleListAll]);

  useEffect(() => {
    if (showData) {
      showData(toggleShowData);
    }
  }, [toggleShowData]);

  useEffect(() => {
    if (!_.isEmpty(total) && !_.isEmpty(totalMessages)) {
      setTotalMessages({
        total: +((totalMessages?.total || 0) + total?.count) > 0 ? +((totalMessages?.total || 0) + total?.count) : 0,
      });
    }
  }, [total]);

  useEffect(() => {
    if (idRole) {
      setRole(getRole({ idRole: ['/select-role', '/my_page', '/chat-page'].includes(url) ? '' : idRole }));
    }

    if (idRole && !['/select-role', '/my_page', '/chat-page'].includes(url)) {
      getListMenu({ role: ['/select-role', '/my_page', '/chat-page'].includes(url) ? '' : idRole });
    }
    if (idRole && !['/list', '/select-role', 'my_page', '/chat-page'].includes(url)) {
      const idProvide = localStorage.getItem('idProvide');
      getColorInfo({ idRole, idProvide });
    }

    if (idRole && !['/select-role'].includes(url)) {
      getTotalMessages({});
      if (!['1', 1, '14', 14].includes(idRole) && isUserRole2) {
        getTotalConfirm();
      }
    }
  }, [idRole]);

  const getLinkIcon = (role) => {
    switch (role) {
      case ROLE.PARTNER:
        return 'partner_';
      case ROLE.CLIENT:
        return 'client_';
      default:
        return '';
    }
  };

  useEffect(() => {
    !['/select-role', '/my_page', '/chat-page'].includes(url) && getListScreenSettingByProvider();
  }, []);

  const getListScreenSettingByProvider = async () => {
    try {
      const data = await MASTER_SETTING.GET_LIST_SCREEN_SETTING_BY_PROVIDER();
      if (data) {
        setListProductMain(data?.data);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const getTotalMessages = async () => {
    setLoading(true);
    try {
      const data = await CHAT.GET_TOTAL_MESSAGES({});
      if (data) {
        setTotalMessages(data?.data);
      }
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const getTotalConfirm = async () => {
    setLoading(true);
    try {
      const data = await ORDER.GET_TOTAL_CONFIRM({});
      if (data) {
        setTotalConfirm(data?.data);
      }
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (showAll) {
      showAll(toggleListAll, activeMenu);
    }
  }, [activeMenu]);

  useEffect(() => {
    if (url === '/recreate' || p === 'detail_recreate' || url.search('recreate/detail_recreate') !== -1) {
      setActiveMenu('物件再作成');
    } else if (url === '/read_import_csv') {
      setActiveMenu('新規物件作成');
    } else if (p === 'tomorrow_info' || url === '/detail-before-confirm-partner') {
      setActiveMenu('事前確認');
    } else if (url === '/dispatch-all' || url === '/dispatch') {
      setActiveMenu('ディスパッチ');
    } else {
      if (listMenu && p !== 'tomorrow_info') {
        const result = listMenu.filter((data) => data.name === p);
        const nameMenu = result[0]?.name;
        localStorage.setItem('nameMenu', nameMenu);
        setActiveMenu(result[0]?.label);
      }
    }
  });

  const onClickLogOut = () => {
    logOut();
  };

  const returnUrl = () => {
    history.push("/confirm-page");
    if(role !== 'WORKER') {
      localStorage.setItem('role', isUserRole2?.idRole);
    }
  }

  return (
    <div className={'header-wrapper ' + classCustom}>
      <Loading loading={loading} />
      <Helmet>
        <title>{activeMenu}</title>
      </Helmet>
      <div className="menu" style={{ backgroundColor: `${color}` }}>
        <span className="show-icon-custom">
          <img id="img-icon" src={_.isEmpty(logo) ? `/img/icons.png` : logo?.logo} />
        </span>
        {listMenu?.length > 0 &&
          listMenu?.map((item, index) => {
            return index === 0 ? (
              <div className="page_icon_first" key={index}>
                <div className="page_name" style={{ color: `${colorText}` }}>
                  {item.label}
                </div>
                <Link to={`/list?p=${item.name}`}>
                  <img
                    src={`/img/${getLinkIcon(role)}${item.name === p ? item.iconActive : item.icon}.png`}
                    alt={item.label}
                    onLoad={imageLoaded}
                  />
                </Link>
              </div>
            ) : (
              <div className="page_icon" key={index}>
                <div className="page_name" style={{ color: `${colorText}` }}>
                  {item.label}
                </div>
                {item.name === 'equipment-management' || item.name === 'orderGroup' ? (
                  <Link to={`/${item.name}`}>
                    <img
                      // src={`/img/${getLinkIcon(role)}${item.name === p ? item.iconActive : item.icon}.png`}
                      src={`/img/${getLinkIcon(role)}${
                        item.name === p ? 'preparation_info_active' : 'preparation_info'
                      }.png`}
                      alt={item.label}
                      onLoad={imageLoaded}
                    />
                  </Link>
                ) : (
                  <Link to={`/list?p=${item.name}`}>
                    <img
                      src={`/img/${getLinkIcon(role)}${
                        item.name === (p === 'tomorrow_info' ? 'preparation_info' : p) ? item.iconActive : item.icon
                      }.png`}
                      alt={item.label}
                      onLoad={imageLoaded}
                    />
                  </Link>
                )}
              </div>
            );
          })}
      </div>
      <div
        className="submenu form__flex form__flex--end"
        style={{ backgroundImage: `url(/img/${getLinkIcon(role)}submenu1.jpg)` }}
      >
        <div className="login_msg form__flex form__flex--btn-header form__flex--center">
          {url !== '/select-role' && (
            <>
              {!['1', 1, '14', 14].includes(idRole) && isUserRole2 && (
                <div className="form-icon-chat">
                  <Link onClick={() => returnUrl()} className="css-icon">
                    <span className="css-icon--cricle">{totalConfirm || 0}</span>
                    <span className="css-icon--text fz-10">承認依頼</span>
                  </Link>
                </div>
              )}
              <div className="form-icon-chat">
                <Link to="/chat-page" className="fas fa-comment-alt iconchat" target="_blank">
                  <span className="chat--num">
                    {totalMessages?.total ? (totalMessages?.total === 0 ? '' : totalMessages?.total) : ''}
                  </span>
                </Link>
              </div>
            </>
          )}
          <div className="form__flex--w130 truncate" title={ROLE_ENUM[idRole]}>
            {ROLE_ENUM[idRole]}
          </div>
          <div
            className="form__flex--w130 truncate"
            title={`${userMe && userMe.data && userMe?.data?.firstName + ' ' + userMe?.data?.lastName}様`}
          >
            {userMe && userMe.data && userMe?.data?.firstName + ' ' + userMe?.data?.lastName}様
          </div>
          {!isChat && (
            <div className="logout_btn form__flex--w20">
              <Link to="/select-role" onClick={() => getRoleMe()}>
                <span className="mypage_a">権限切替</span>
              </Link>
            </div>
          )}
          {url !== '/select-role' && (role === ROLE.CENTER || role === ROLE.PARTNERGROUP) ? (
            <div className="logout_btn form__flex--w20">
              <Link to="/my_page">
                <span className="mypage_a">マイページ</span>
              </Link>
            </div>
          ) : (
            <div className="logout_btn form__flex--w20"></div>
          )}
          {!isChat && (
            <div className="logout_btn form__flex--w20" onClick={() => onClickLogOut()}>
              <span className="logout_a cursor-pointer">ログアウト</span>
            </div>
          )}
        </div>
      </div>

      {listMenu?.length > 0 && (
        <>
          <div className="classMapPipe mb-1 mt-1" style={{ borderColor: `${color}`, color: `${colorText}` }}>
            <span>HOME ＞ {listMenu?.length > 0 && activeMenu}</span>
            <div className="login_btn1 form__flex--btn-instruction">
              <a href="#" className="wrapper-help" target="_brank" style={{ color: `${colorText}`, display:'none' }}>
                <i className="icon-help-circled" />
                マニュアルはこちら
              </a>
            </div>
          </div>

          <div className="ConditionTitle w-100" style={{ backgroundColor: `${color}` }}>
            <div className="form-btn-product">
              <h1 style={{ color: `${colorText}` }}>{listMenu?.length > 0 && activeMenu}</h1>
              <div className="grid-form">
                {p === 'receive_all' &&
                  (roleDetail === ROLE.CENTER || roleDetail === ROLE.PARTNERGROUP) &&
                  listProductMain &&
                  listProductMain.map((item, index) => {
                    return (
                      <div key={index} className="master_link_area" title={item.nameScreenSetting}>
                        <Link to={`/summary-receive-all?idScreenSetting=${item.screenSettingId}`}>
                          {item.nameScreenSetting}
                        </Link>
                      </div>
                    );
                  })}

                {p === 'receive_info' && (roleDetail === ROLE.CENTER || roleDetail === ROLE.PARTNERGROUP) && (
                  <div className="master_link_area">
                    <Link to="/read_import_csv">新規物件作成</Link>
                  </div>
                )}

                {p === 'preparation_info' &&
                  detailCheck.confirmedTheDayBefore &&
                  (roleDetail === ROLE.CENTER ||
                    roleDetail === ROLE.PARTNER ||
                    roleDetail === ROLE.PARTNERGROUP) && (
                    <div className="master_link_area">
                      <Link to="/list?p=tomorrow_info">事前確認</Link>
                    </div>
                  )}
                {detailCheck?.dispatch &&
                  p === 'condition_info' &&
                  (roleDetail === ROLE.CENTER ||
                    roleDetail === ROLE.PARTNER ||
                    roleDetail === ROLE.PARTNERGROUP ||
                    roleDetail === ROLE.CLIENT) && (
                    <div className="master_link_area">
                      <Link to="/dispatch-all">ディスパッチ</Link>
                    </div>
                  )}
              </div>
            </div>

            {url === '/orderGroup' && (
              <>
                <div className="form-btn-toggle">
                  <div className="btn-group btn-toggle">
                    <button
                      className={`btn px-2 ${toggleShowData ? 'btn-primary' : 'btn-light'}`}
                      onClick={() => ChangeToggleData(true)}
                    >
                      稼働調整
                    </button>
                    <button
                      className={`btn px-2 ${!toggleShowData ? 'btn-primary' : 'btn-light'}`}
                      onClick={() => ChangeToggleData(false)}
                    >
                      稼働不可一覧
                    </button>
                  </div>
                </div>
              </>
            )}

            {(url === '/list' || url === '/recreate' || url === '/orderGroup') && (
              <>
                <div className="form-btn-toggle">
                  <div className="btn-group btn-toggle">
                    <button
                      className={`btn ${toggleListAll ? 'btn-primary' : 'btn-light'}`}
                      onClick={() => ChangeToggle(true)}
                    >
                      ALL
                    </button>
                    <button
                      className={`btn ${!toggleListAll ? 'btn-primary' : 'btn-light'}`}
                      onClick={() => ChangeToggle(false)}
                    >
                      案件毎
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

const mapActionToProps = {
  logOut,
  getRoleMe,
};

export default compose(withRouter, connect(null, mapActionToProps))(Header);
