import React, { useEffect, useState } from 'react';
import { time } from '../../helpers/constants';
import '../../assets/scss/page/MemoHistoryTable/style.scss';
import { translate } from 'react-i18next';
import _ from 'lodash';
import { useSelector, shallowEqual } from 'react-redux';
import { getDataHistoryMasterSetting } from 'actions/authentication';
import { useDispatch } from 'react-redux';
import { pushToast } from 'components/Toast';
import { MASTER_SETTING } from '../../constants/endpoints';

const MemoTableMasterSetting = ({ screenName, t, logId = '', idProvide }) => {
  let organizationId = process.browser && new URL(window.location.href).searchParams.get('organizationId') || '';
  const dispatch = useDispatch();
  const dataHistoryMasterSetting = useSelector((store) => store.authentication.dataHistoryMasterSetting, shallowEqual);
  const [dataComment, setDataComment] = useState({});
  const [dataLogMasterSetting, setDataLogMasterSetting] = useState([]);
  const [isShow, setIsShow] = useState({});
  const role = localStorage.getItem('role');

  useEffect(() => {
    if (dataHistoryMasterSetting) {
      setDataLogMasterSetting(dataHistoryMasterSetting)
    }
  }, [dataHistoryMasterSetting])

  const onChangeText = (e) => {
    setDataComment({ ...dataComment, [e.target.name]: e.target.value });
  };

  const handleRadio = (e, key) => {
    setDataComment({ ...dataComment, contactLog: key });
  };

  const createLog = async ({ body }) => {
    try {
      const data = await MASTER_SETTING.CREATE_LOGS({ body, organizationId, idProvide });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
      } else {
        pushToast('success', 'Create log success');
        setDataComment({});
        dispatch(getDataHistoryMasterSetting({ logId, screenName, idProvide }));
      }
    } catch (errorMessage) {
      console.log('errorMessage: ', errorMessage);
    }
  };

  const onSubmit = () => {
    const body = {
      updateTitle: dataComment?.updateTitle || '登録',
      screenName,
      timeLog: dataComment?.timeLog,
      contactLog: dataComment?.contactLog,
      commentLog: dataComment?.commentLog,
      screenNameSub: dataComment?.layer,
      logId,
    }
    createLog({ body });
  };

  useEffect(() => {
    if (screenName) {
      dispatch(getDataHistoryMasterSetting({ logId, screenName, idProvide }));
    }
  }, [screenName]);

  const checkColor = (text = '') => {
    switch (text) {
      case '訪問先':
        return { color: '#fabf8e', data: ['アポイント', '当日変更連絡', '対応説明連絡', 'その他'] };
      case 'Monitoring':
        return { color: '#ccffff', data: ['登録情報追加・変更'] };
      case 'ERP':
      case 'ManagementGroup':
        return { color: '#fff', data: ['稼働調整確認', '作業進捗確認', '作業内容確認', '報告書確認'] };
      case 'Management':
        return { color: '#99ff99', data: ['作業員変更', '作業員情報催促', '稼働調整催促', '報告書催促'] };
      case 'Worker':
        return {
          color: '#fde9d8',
          data: ['入館催促', '入館受付', '作業進捗確認', '作業時間超過確認', '作業内容確認', '退館受付'],
        };
      default:
        return '';
    }
  };
  return (
    <div className="body__loghistory">
      <table className="table__history">
        <colgroup>
          <col className='w-20'></col>
          <col className='w-20'></col>
          <col className='w-60'></col>
        </colgroup>
        <tbody>
          <tr>
            <th className="table__history--color">連絡者</th>
            <th className="table__history--color">カテゴリ</th>
            <th className="table__history--color">コメント</th>
          </tr>
          <tr>
            <td className="table__history--color--td table__history--form--td p-0">
              {['訪問先', 'Monitoring', ['5', '6', '7'].includes(`${role}`) ? 'ManagementGroup' : 'ERP', 'Management', 'Worker'].map((item, index) => {
                return (
                  <div
                    key={index}
                    className="float-left w-100 table__history--form--td--check"
                    style={{
                      background: checkColor(item).color,
                    }}
                  >
                    <input
                      className="float-left table__history--input--radio ml-2"
                      id={`${item}1`}
                      name="layer"
                      type="radio"
                      value={item}
                      onChange={onChangeText}
                      checked={dataComment?.layer === item}
                    />
                    <label
                      style={{
                        background: checkColor(item).color,
                        marginTop: 7,
                      }}
                      className="float-left ml-1 table__history--click"
                      htmlFor={`${item}1`}
                    >
                      {item}
                    </label>
                  </div>
                );
              })}
            </td>
            <td
              className="table__history--color--td table__history--form--td"
              style={{
                background: checkColor(dataComment?.layer).color,
              }}
            >
              {checkColor(dataComment?.layer)?.data?.map((items, index) => {
                return (
                  <div className="float-left w-100 ml-2" key={index}>
                    <input
                      id={`${items}1`}
                      name="updateTitle"
                      className="float-left table__history--radio"
                      type="radio"
                      value={items}
                      onChange={onChangeText}
                      checked={dataComment?.updateTitle === items}
                    />
                    <label className="table__history--click float-left ml-1" htmlFor={`${items}1`}>
                      {items}
                    </label>
                  </div>
                );
              })}
            </td>
            <td className="table__history--color--td table__history--form--td">
              {(dataComment?.updateTitle === '入館催促' || dataComment?.updateTitle === '作業時間超過確認') && (
                <div className="w-100">
                  <div className="d-flex ml-3">
                    <input
                      type="radio"
                      name="contactLog"
                      className="float-left table__history--radio "
                      onChange={(e) => handleRadio(e, '連絡OK予定時刻')}
                      checked={dataComment?.contactLog === '連絡OK予定時刻'}
                      id="連絡OK予定時刻"
                    />
                    <label className="ml-1" htmlFor="連絡OK予定時刻">
                      連絡OK 予定時刻
                    </label>
                    <select
                      className="ml-1 w-25"
                      name="timeLog"
                      onChange={onChangeText}
                      value={dataComment?.timeLog || ''}
                    >
                      <option value=''></option>
                      {time.map((item, index) => (
                        <option value={item} key={index}>
                          {item}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="float-left ml-3">
                    <input
                      type="radio"
                      name="contactLog"
                      id="連絡NG・エスカ有り"
                      className="float-left table__history--radio"
                      onChange={(e) => handleRadio(e, '連絡NG・エスカ有り')}
                      checked={dataComment?.contactLog === '連絡NG・エスカ有り'}
                    />
                    <label className="ml-1" htmlFor={`連絡NG・エスカ有り`}>
                      連絡NG・エスカ有り
                    </label>
                  </div>
                </div>
              )}
              {/* )} */}
              <div className="w-100 ">
                <textarea
                  className="w-95"
                  name="commentLog"
                  onChange={onChangeText}
                  value={dataComment?.commentLog || ''}
                ></textarea>
              </div>
              <button
                className="float-right table__history--button mr-3"
                onClick={onSubmit}
              >
                登録
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <table className="table__history">
        <colgroup>
          <col className="w-10"></col>
          <col className="w-10"></col>
          <col className="w-10"></col>
          <col className="w-10"></col>
          <col className="w-10"></col>
          <col className="w-10"></col>
          <col className="w-40"></col>
        </colgroup>
        <tbody>
          <tr>
            <th className="table__history--color">更新時間</th>
            <th className="table__history--color">更新者</th>
            <th className="table__history--color">企業名</th>
            <th className="table__history--color">システム</th>
            <th className="table__history--color">Service</th>
            <th className="table__history--color">更新内容</th>
            <th className="table__history--color">更新詳細</th>
          </tr>
          {dataLogMasterSetting?.map((item, index) => {
            let newComment = {};
            try {
              newComment = JSON.parse(item?.updateDetail);
            } catch (e) {
              console.log(e)
            }
            return (
              <tr key={index}>
                <td
                  className="table__history--color--td"
                  style={{
                    background: checkColor(item?.screenNameSub).color,
                  }}
                >
                  {item?.createdAt}
                </td>
                <td
                  className="table__history--color--td"
                  style={{
                    background: checkColor(item?.screenNameSub).color,
                  }}
                >
                  {item?.createdBy}
                </td>
                <td
                  className="table__history--color--td"
                  style={{
                    background: checkColor(item?.screenNameSub).color,
                  }}
                >
                  {item?.companyName}
                </td>
                <td
                  className="table__history--color--td"
                  style={{
                    background: checkColor(item?.screenNameSub).color,
                  }}
                >
                  {item?.layer}
                </td>
                <td
                  className={`table__history--color--td ${!item?.serviceName ? 'text-center' : ''}`}
                  style={{
                    background: checkColor(item?.screenNameSub).color,
                  }}
                >
                  {item?.serviceName || '-'}
                </td>
                <td
                  className="table__history--color--td"
                  style={{
                    background: checkColor(item?.screenNameSub).color,
                  }}
                >
                  <p className="table__history--margin--p fz-14">{item?.screenNameSub || (item?.screenName === 'ディスパッチOrder' ? 'ディスパッチ' : item?.screenName)}</p>
                </td>
                <td
                  className="table__history--color--td text-left"
                  style={{
                    background: checkColor(item?.screenNameSub).color,
                  }}
                >
                  <div className="grid__flex grid__flex--space-between">
                    <p className='table__history--margin--p fz-14'> - {item?.updateTitle}</p>
                    <div onClick={() => setIsShow({ ...isShow ,[item.id]: !isShow?.[item.id] })} className="cursor-pointer">
                      {isShow?.[item.id] ? <i className="icon-up-open icon-searchbar fz-16" /> : <i className="icon-down-open icon-searchbar fz-16" />}
                    </div>
                  </div>
                  {isShow?.[item.id] && Object.keys(newComment?.value || {})?.map((ele, index) => {
                    if (ele === 'areas') return;
                    if (ele === 'カレンダ') {
                      return (
                        <>
                          {Object.keys(newComment?.value[ele] || {}).map((doc) => {
                            return (
                              <p className="table__history--margin--p fz-14">
                                {' '}
                                - {doc} : {newComment?.value[ele]?.[doc]}
                              </p>
                            );
                          })}
                        </>
                      );
                    }

                    if (ele === 'documentSettings') {
                      return newComment?.value[ele].map((document, k) => {
                        return (<>
                          {Object.keys(document || {}).map(doc => {
                            return (
                              <p className='table__history--margin--p fz-14' key={index}> - {doc} : {document?.[doc]}</p>
                            )
                          })}
                          {(newComment?.value[ele].length - 1) !== k && (<p className='table__history--margin--p fz-14'>----------------------------</p>)}
                        </>)

                      })
                    } else {
                      if (!_.isEmpty(newComment?.value?.[ele])) {
                        return (
                          <p className='table__history--margin--p fz-14' key={index}> - {ele} : {newComment?.value?.[ele]}</p>
                        )
                      }
                    }
                  })}
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  );
};

export default translate('translations')(MemoTableMasterSetting);
