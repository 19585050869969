import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import 'assets/scss/page/AnkenMasterNew/ModalCreateProject.scss';

import { pushToast } from 'components/Toast';
import { ANKEN_MASTER_NEW } from 'constants/endpoints';
import { SCREEN_NAME } from '../../../../helpers/constants';
import _ from 'lodash';

const ModalCreateProject = (props) => {
  const { toggle = () => {}, modal = false, createSuccess, idClientModal, nameTitle } = props;
  const [nameProject, setNameProject] = useState('');
  const [nameProduct, setNameProduct] = useState('');
  const [listTree, setListTree] = useState([]);
  const [loading, setLoading] = useState(false);
  const [listProductId, setListProductId] = useState([]);

  useEffect(() => {
    if (!modal) {
      setNameProject('');
    }
  }, [modal]);

  const changeNameProject = (e) => {
    const value = e.target.value;
    setNameProject(value);
  };

  const changeNameProduct = (e) => {
    const value = e.target.value;
    setNameProduct(value);
  };

  const createProject = () => {
    const body = {
      projectName: nameProject?.trim(),
      screenName: SCREEN_NAME.CASE_MASTA,
      productsAdd: !_.isEmpty(listProductId) ? listProductId : [{ productId: 'P0000', name: nameProduct }],
      clientId: idClientModal,
      updateTitle: `登録 プロジェクト追加登録 ${nameTitle ? nameTitle : ''}`,
    };
    handleCreateProject({ body });
  };

  const getTree = async ({ clientId }) => {
    try {
      setLoading(true);
      const data = await ANKEN_MASTER_NEW.GET_PRODUCT_NONE_PROJECT({ clientId });
      if (data) {
        setListTree(data?.data);
        // setListTreeOrigin(data?.data);
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const handleAddProductID = ({ item, id }) => {
    item = { productId: item?.productId, name: item?.name };
    if (listProductId?.map((elm) => elm.productId).includes(id)) {
      const newList = listProductId.filter((ale) => ale.productId !== id);
      setListProductId(newList);
    } else {
      setListProductId([...listProductId, item]);
    }
  };

  useEffect(() => {
    getTree({ clientId: idClientModal });
  }, [idClientModal]);

  const handleCreateProject = async ({ body }) => {
    try {
      const data = await ANKEN_MASTER_NEW.CREATE_PROJECT({ body });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
      } else {
        pushToast('success', 'Create Project success');
        createSuccess();
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div>
      <Modal className="modal-dialog-custom" isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>プロジェクト追加登録</ModalHeader>
        <ModalBody>
          <div className="form-body-project form-body-add-service">
            <div className="group-input-project mb-2">
              <div className="title">プロジェクト名</div>
              <input type="text" value={nameProject} onChange={changeNameProject} />
            </div>
            {_.isEmpty(listTree) && (
              <div className="group-input-project mb-2">
                <div className="title">Product</div>
                <input type="text" value={nameProduct} onChange={changeNameProduct} />
              </div>
            )}
            <div className="form-btn-setting">
              <button onClick={() => createProject()} disabled={!nameProject || _.isEmpty(listProductId)}>
                登録
              </button>
            </div>
            {listTree && listTree?.length > 0 && (
              <div className="form-tree">
                <p className="d-flex flex-direction-row">
                  <div className="title-project">一般</div>
                </p>
                <ul>
                  {listTree.map((item, index) => {
                    return (
                      <li key={index}>
                        <p>
                          {item.name}
                          <label className="container-checkbox">
                            <input
                              type="checkbox"
                              name="required"
                              checked={listProductId?.map((item) => item.productId).includes(item.productId)}
                              onChange={() => handleAddProductID({ item: item, id: item.productId })}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </p>
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ModalCreateProject;
