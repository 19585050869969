import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { ANKEN_MASTER_NEW } from 'constants/endpoints';
import { pushToast } from 'components/Toast';
import 'assets/scss/page/AnkenMasterNew/TableModal.scss';
import ButtonChecked from './ButtonChecked';
import { translate } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { DEFAULT_POSITIVE_NUM, DATA_POSITIVE_MIN, DEFAULT_POSITIVE_MINUTES, DATA_NEGATIVE_MIN, DEFAULT_NEGATIVE_MINUTES, DATA_NEGATIVE_NUM, DEFAULT_NEGATIVE_NUM } from 'helpers/constants';
import { genId } from 'helpers/helpers';
import Loading from 'components/Loading/Loading';
import _ from 'lodash';
import { SCREEN_NAME } from '../../../../../helpers/constants';
import { getDataHistoryMasterSetting } from 'actions/authentication';
import { useDispatch } from 'react-redux';

const TableModalAlerts = ({serviceAlert, productAlert, color, idRole = '', nameTitle }) => {
  const dispatch = useDispatch();
  const [dataTable, setDataTable] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getAlertSettings({ serviceId: serviceAlert, productId: productAlert })
  }, [serviceAlert, productAlert]);

  const getAlertSettings = async ({ serviceId, productId }) => {
    try {
      setLoading(true);
      const data = await ANKEN_MASTER_NEW.GET_ALERT_SETTINGS({ serviceId, productId });
      if (data) {
        setDataTable(data?.data?.alertSettings?.map((item) => {item.id = genId(); return item}));
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  }

  const addAlertSettings = async ({body, serviceId, productId}) => {
    try {
      const data = await ANKEN_MASTER_NEW.ADD_ALERT_SETTINGS({ body, serviceId, productId });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
      } else {
        pushToast('success', 'Create Alert Setting Success');
        dispatch(getDataHistoryMasterSetting({ logId: '', screenName: SCREEN_NAME.CASE_MASTA }));
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onChangeChecked = ({ status = false, index }) => {
    const newData = [...dataTable];
    let data = newData.find(i => i.id === index);
    data.isEnabled = status;
    setDataTable(newData);
  };

  const onChangeText = (e, id) => {
    const value = e.target.value;
    const name = e.target.name;
    const newData = [...dataTable];
    newData.filter((item) => item.id === id)[0][name] = value;
    setDataTable(newData);
  }

  const onUpdateAlertSettings = () => {
    addAlertSettings({ body: { alertSettings: dataTable.map((i) => _.omit(i, 'id')) , screenName: SCREEN_NAME.CASE_MASTA, updateTitle: `登録 アラート設定 ${nameTitle || ''}` }, serviceId: serviceAlert, productId: productAlert });
  }

  const optionData = (item) => {
    switch (true) {
      case item.screen === '当日現場情報' && DATA_POSITIVE_MIN.includes(item.reference):
        return DEFAULT_POSITIVE_MINUTES
      case item.screen === '当日現場情報' && DATA_NEGATIVE_MIN.includes(item.reference):
        return DEFAULT_NEGATIVE_MINUTES
      case DATA_NEGATIVE_NUM.includes(item.screen):
        return DEFAULT_NEGATIVE_NUM
      default:
        return DEFAULT_POSITIVE_NUM
    }
  }

  const renderItem = (item, index, data) => {
    return (
      <tr key={index} className="item-worker" >
        <td className="text-center">
          <input
            type="text" className="text-input input-result" disabled={true}
            style={{ borderColor: `${color}`, color: `${color}` }} value={item ?.screen || ''}
          />
        </td>
        <td className="text-center">
          <input type="text" className="text-input input-result" disabled={true}
            style={{ borderColor: `${color}`, color: `${color}` }} value={item ?.reference || ''}
          />
        </td>
        <td className="text-center">
          <select name="value" className="text-input select--result select--w100" onChange={e => onChangeText(e, item.id)} value={item ?.value || ''}>
            <option defaultValue=""></option>
            {
              (optionData(item) || [])?.map((it, i) => {
                return <option value={it.value} key={i}>{it.name}</option>
              })
            }
          </select>
        </td>
        <td>
          <ButtonChecked color={color} status={item?.isEnabled || false} index={item.id} onChange={onChangeChecked} />
        </td>
      </tr>
    );
  };
  const renderTableInfo = (key, data = null) => {
    return (
      <table className="form-worker">
      {
        <tr>
          <th className="text-center">メニュー画面</th>
          <th className="text-center">{data ? null : '参照項目'}</th>
          <th className="text-center">{data ? '設定時間' : '設定日数'}</th>
          <th className="text-center">表示／非表示</th>
        </tr>
      }
        <tbody className="scroll-table">
          {
            dataTable?.filter((it) => data ? it.role === key && it.screen === '当日現場情報' : it.role === key && it.screen !== '当日現場情報')?.map((item, index) => {
              return renderItem(item, index, data);
            })
          }
        </tbody>
      </table>
    );
  };

  return (
    <div className="wrapper-master-setting wrapper-master-setting--w100">
      <Loading loading={loading} />
      <div className="body pb-4">
        <div className="wrapper-main">
          <div className="d-flex justify-content-end align-items-center group-button">
            <button className="button--blue button--resize" onClick={onUpdateAlertSettings}>登録</button>
          </div>
          <div className="wrapper-result">
            {['5', '6', '7', 5, 6, 7].includes(idRole) ? 'ManagementGroup' : 'ERP：契約企業'}
            {renderTableInfo('ERP')}
            {renderTableInfo('ERP', '当日現場情報')}
            Partner：協力企業
            {renderTableInfo('Management')}
          </div>
        </div>
      </div>
    </div>
  );
};

export default compose(translate('translations'), withRouter)(TableModalAlerts);
