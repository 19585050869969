import React, { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { Collapse } from 'reactstrap';
import { pushToast } from '../../../../components/Toast';
import { MASTER_SETTING } from '../../../../constants/endpoints';
import { SCREEN_NAME } from '../../../../helpers/constants';
import { getDataHistoryMasterSetting } from 'actions/authentication';
import { useDispatch } from 'react-redux';
import './style.scss';

const CollaMasterListItem = ({ colors, idProvide ,colorText, idRole, pushListSetting }) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [dataOptionSetting, setDataOptionSetting] = useState({});
  const toggle = () => setIsOpen(!isOpen);
  const [mockupBody, setMockupBody] = useState({ screenName : SCREEN_NAME.MY_CORP });
  const [role, setRole] = useState(idRole);

  useEffect(() => {
    getListOptionSetting();
  }, []);

  useEffect(() => {
    if(idRole) {
      setRole(parseInt(idRole));
    }
  }, [idRole]);

  useEffect(() => {
    getListOptionSetting();
  }, [idProvide]);

  const getListOptionSetting = async () => {
    try {
      const data = await MASTER_SETTING.GET_LIST_OPTION_SETTING(idProvide);
      if (data) {
        setDataOptionSetting(data ?.data);
        pushListSetting(data ?.data)
      }
    } catch (e) {
      console.log(e);
    }
  };
  const handleOnClick = (e, status) => {
    if(role !== 1) return false;
    let name = e.target.name;
    setDataOptionSetting({ ...dataOptionSetting, [name]: !status });
    setMockupBody({ ...mockupBody, [name]: !status });
  };

  const onSubmit = () => {
    CreateListOption({ body: {...mockupBody, updateTitle: '保存 追加機能'} });
  };

  const CreateListOption = async ({ body }) => {
    try {
      const data = await MASTER_SETTING.CREATE_LIST_OPTION_SETTING({ body, idProvide });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
      } else {
        pushToast('success', 'Update success');
        dispatch(getDataHistoryMasterSetting({ logId: '', screenName: SCREEN_NAME.MY_CORP, idProvide }));
      }
    } catch (errorMessage) {
      console.log('errorMessage: ', errorMessage);
    }
  };

  return (
    <div className="setting-master-menu-wrapper">
      <div className="wrapper-btn-colappse">
        <div
          onClick={toggle}
          className="mb-1 searchbar d-flex justify-content-between align-items-center"
          style={{ background: `${colors}` }}
        >
          <div className="ml-2" style={{ color: `${colorText}` }}>追加機能</div>
          <div className="mr-2" style={{ color: `${colorText}` }}>
            {isOpen ? <i className="icon-up-open icon-searchbar" /> : <i className="icon-down-open icon-searchbar" />}
          </div>
        </div>
      </div>
      <div>
        <Collapse isOpen={isOpen}>
          <div className="content-view">
            <div className="row custom-row custom-row--unset">
              <div className="col-6 text-center">
                <input
                  type="checkbox"
                  disabled={role !== 1}
                  value={dataOptionSetting?.dispatch}
                  defaultChecked={dataOptionSetting?.dispatch}
                  name="dispatch"
                  onClick={(e) => handleOnClick(e, dataOptionSetting?.dispatch)}
                />
                <label className="text-left">ディスパッチ</label>
              </div>
              <div className="col-6 text-center">
                <input
                  type="checkbox"
                  value={dataOptionSetting?.confirmedTheDayBefore}
                  defaultChecked={dataOptionSetting?.confirmedTheDayBefore}
                  name="confirmedTheDayBefore"
                  disabled={role !== 1}
                  onClick={(e) =>
                    handleOnClick(e, dataOptionSetting?.confirmedTheDayBefore)
                  }
                />
                <label className="text-left">事前確認</label>
              </div>
              {/* <div className="col-6">
                <input
                  type="checkbox"
                  value={dataOptionSetting?.priorityDispatch}
                  defaultChecked={dataOptionSetting?.priorityDispatch}
                  name="priorityDispatch"
                  disabled={role !== 1}
                  onClick={(e) =>
                    handleOnClick(e, dataOptionSetting?.priorityDispatch)
                  }
                />
                <label>Priority Dispatch</label>
              </div> */}
              {/* <div className="col-6">
                <input
                  type="checkbox"
                  value={dataOptionSetting?.summarySetting}
                  defaultChecked={dataOptionSetting?.summarySetting}
                  name="summarySetting"
                  disabled={role !== 1}
                  onClick={(e) =>
                    handleOnClick(e, dataOptionSetting?.summarySetting)
                  }
                />
                <label>集計サマリ設定</label>
              </div> */}
              <button className="btn btn-submit" onClick={onSubmit}>
                保存
              </button>
            </div>
          </div>
        </Collapse>
      </div>
    </div>
  );
};

export default CollaMasterListItem;
