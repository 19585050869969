import React, {useState} from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { SCREEN_NAME } from '../../../helpers/constants';

const ModalCreateRoom = props => {
  const { toggle = () => { }, modal = false, handleCreateRoomChat = () => { } } = props;
  const [nameRoom, setNameRoom] = useState('')

  const onCLickSubmit = () => {
    const payload = {
      name: nameRoom,
      screenName: SCREEN_NAME.CHAT
    }
    handleCreateRoomChat(payload)
  };

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>チャットグループ作成</ModalHeader>
        <ModalBody>
          <div style={{ border: '2px solid #0e5a8d' }}>
            <div className="d-flex w-75 m-3 " style={{ background: '#0e5a8d', color: 'white' }}>
              <span className="w-50 pt-1 pl-2">グループ名</span>
              <input type="text" onChange={(e) => setNameRoom(e.target.value)}
                className="form-control h-100" style={{ borderRadius: 0 }} />
            </div>
            <div className="text-right font-weight-bold">
              <button
                className="btn m-3 btn-style"
                disabled={!nameRoom}
                onClick={() => onCLickSubmit()}
              >
                登録
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ModalCreateRoom;
