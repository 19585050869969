import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import HeaderMasterSetting from '../../../components/HeaderMasterSetting';
import 'assets/scss/page/CalendarMaster/style.scss';
import { DATE_JP_FORMAT, DATE_MONTH_YEAR, SCREEN_NAME } from '../../../helpers/constants';
import { getDate } from '../../../helpers/helpers';
import { CALENDAR_MASTER_ENDPOINTS } from '../../../constants/endpoints';
import Loading from 'components/Loading/Loading';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import FooterMaintenance from 'components/FooterMaintenance';
import _ from 'lodash';
import { pushToast } from '../../../components/Toast';
import MemoHistory from '../../../components/MemoHistory';
import { useDispatch } from 'react-redux';
import { getDataHistoryMasterSetting } from 'actions/authentication';

const CalenderMaster = () => {
  const dispatch = useDispatch();
  const colorScreen = useSelector((store) => store.utils.colorMe);
  const [listData, setListData] = useState([]);
  const [calendarMonth, setCalendarMonth] = useState(moment(new Date()).format(DATE_MONTH_YEAR));
  const [loading, setLoading] = useState(false);

  const getCalendar = async ({ calendarMasterId = '' }) => {
    try {
      setLoading(true);
      const data = await CALENDAR_MASTER_ENDPOINTS.GET_CALENDAR({
        calendarMasterId: calendarMasterId,
      });
      if (data) {
        setLoading(false);
        if (!_.isEmpty(data?.data?.calendar)) {
          setListData(data?.data?.calendar);
        }
      } else {
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const UpdateCalendarMaster = async ({ body }) => {
    setLoading(true);
    try {
      const data = await CALENDAR_MASTER_ENDPOINTS.CREATE_UPDATE_CALENDAR_MASTER({ body });
      if (data && data.code !== 0) {
        pushToast('error', data?.message?.extra || data?.message?.errorMessage);
        setLoading(false);
      } else {
        pushToast('success', 'Create calender success');
        setLoading(false);
        dispatch(getDataHistoryMasterSetting({ logId: '', screenName: SCREEN_NAME.WORKING_SCHEDULE_NEO, idProvide: '' }));
      }
    } catch (errorMessage) {
      setLoading(false);
      console.log('errorMessage: ', errorMessage);
    }
  };

  const onClickUpdate = () => {
    UpdateCalendarMaster({ body: { calendarMasterId: calendarMonth, screenName: SCREEN_NAME.WORKING_SCHEDULE_NEO, calendar: listData, updateTitle: '設定を保存 カレンダーマスタ' } });
  };

  const getDaysInMonth = (month, year) => {
    let date = new Date(year, month, 1);
    let days = [];
    while (date.getMonth() === month) {
      let dateCheck = new Date(moment(date));
      let currentDay = dateCheck.getDay();
      let type = [0, 6].includes(currentDay) ? 2 : 1;
      days.push({ date: moment(dateCheck).format(DATE_JP_FORMAT), type });
      date.setDate(date.getDate() + 1);
    }
    return days;
  };

  useEffect(() => {
    const month = new Date(calendarMonth).getMonth();
    const year = new Date(calendarMonth).getFullYear();
    let date = getDaysInMonth(month, year).map((ele) => {
      return {
        date: ele?.date,
        type: ele?.type,
        remarks: '',
      };
    });
    setListData(date);
  }, [calendarMonth]);

  useEffect(() => {
    if (calendarMonth) {
      getCalendar({ calendarMasterId: calendarMonth });
    }
  }, [calendarMonth]);

  const handleChangeText = (e, index) => {
    let data = listData?.map((ele, eleI) => {
      if (eleI === index) {
        return { ...ele, [e?.target?.name]: e?.target?.value };
      } else {
        return ele;
      }
    });
    setListData(data);
  };

  const handleChange = (date) => {
    setCalendarMonth(date === null ? '' : moment(new Date(date)).format(DATE_MONTH_YEAR));
  };

  return (
    <div className="wrapper-calendar-master">
      <Loading loading={loading} />
      <HeaderMasterSetting color={colorScreen?.code} colorText={colorScreen?.colorText} />
      <div className="table__calendar">
        <DatePicker
          selected={!_.isEmpty(calendarMonth) ? new Date(calendarMonth) : ''}
          locale="ja"
          dateFormat="yyyy/MM"
          dateFormatCalendar="yyyy年 MM月"
          showMonthYearPicker
          className="form-control w-25 table__calendar--sl"
          onChange={(date) => handleChange(date)}
        />
        <table className="table__calendar--tb table">
          <thead>
            <tr>
              <th>日付</th>
              <th>曜日</th>
              <th>区分</th>
              <th>メモ</th>
            </tr>
          </thead>
          <tbody>
            {listData &&
              listData?.map((item, index) => {
                return (
                  <tr
                    key={index}
                    style={{
                      color: `${[2, 3, '2', '3'].includes(item?.type) ? 'red' : 'black'}`,
                    }}
                  >
                    <td>{item?.date}</td>
                    <td>{getDate(item?.date)}</td>
                    <td>
                      <select
                        style={{
                          color: `${[2, 3, '2', '3'].includes(item?.type) ? 'red' : 'black'}`,
                        }}
                        className="form-control table__calendar--sl "
                        name="type"
                        onChange={(e) => handleChangeText(e, index)}
                        value={item?.type}
                      >
                        <option value="1">01_平日</option>
                        <option value="2">02_休日</option>
                        <option value="3">03_祝日</option>
                      </select>
                    </td>
                    <td>
                      <input
                        style={{
                          color: `${[2, 3, '2', '3'].includes(item?.type) ? 'red' : 'black'}`,
                        }}
                        className="form-control table__calendar--ip"
                        type="text"
                        value={item?.remarks}
                        name="remarks"
                        onChange={(e) => handleChangeText(e, index)}
                      />
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        <div className="text-center mb-3">
          <button className="button-submit-calendar" onClick={() => onClickUpdate()}>
            設定を保存
          </button>
        </div>
      </div>
      <MemoHistory colors={colorScreen?.code} colorText={colorScreen?.colorText} screenName={SCREEN_NAME.WORKING_SCHEDULE_NEO} />
      <FooterMaintenance/>
    </div>
  );
};

export default CalenderMaster;
