import React from 'react';
import { compose } from 'redux';
import ButtonMasterSetting from '../ButtonMasterSetting';
import { translate } from 'react-i18next';
import { PERMISSION } from '../../../helpers/constants';

const PartnerMenu = ({ idRole, t, colorText }) => {
  return (
    <>
      {
        idRole && PERMISSION.PARTNER_CONTRACT_MANAGER.includes(idRole) && (
          <>
            {/* <div className="page_icon">
              <div className="page_name page_name--nowrap" style = {{ color: `${colorText}` }}>{t('menu_partner.my_corp')}</div>
              <ButtonMasterSetting
                link="/master-setting"
                title="Master Setting"
                subTitle="集計表"
                color="dark-blue"
                icon="icon-list-alt"
              />
            </div>
            <div className="page_icon">
              <div className="page_name page_name--nowrap" style = {{ color: `${colorText}` }}>{t('menu_partner.user_master')}</div>
              <ButtonMasterSetting
                title="USER MASTER"
                subTitle="ユーザー"
                color="dark-blue"
                icon="icon-user"
                link="/users"
              />
            </div> */}
            <div className="page_icon">
              <div className="page_name page_name--nowrap" style = {{ color: `${colorText}` }}>{t('menu_partner.contract')}</div>
              <ButtonMasterSetting
                link="/contract"
                title="契約管理"
                subTitle="契約管理"
                color="dark-blue"
                icon="icon-list-alt"
              />
            </div>
          </>
        )
      }
      {
        idRole && PERMISSION.PARTNER_OPERATION_MANAGER.includes(idRole) && (
          <>
            <div className="page_icon">
              <div className="page_name page_name--nowrap" style = {{ color: `${colorText}` }}>{t('menu_partner.staff_master')}</div>
              <ButtonMasterSetting
                link="/staff_master"
                subTitle="作業員"
                title="STAFF MASTER"
                color="dark-blue"
                icon="icon-users"
              />
            </div>
            <div className="page_icon">
              <div className="page_name page_name--nowrap" style = {{ color: `${colorText}` }}>{t('menu_partner.branch_master')}</div>
              <ButtonMasterSetting
                link="/branch-master"
                subTitle=""
                title="BRANCH MASTER"
                color="dark-blue"
                icon="icon-phone-squared"
              />
            </div>

            <div className="page_icon">
              <div className="page_name page_name--nowrap" style = {{ color : `${colorText}` }}>{t('menu_partner.workingSchedule')}</div>
              <ButtonMasterSetting
                title="スケジュール"
                subTitle="スケジュール"
                color="dark-blue"
                icon="icon-file-excel"
                link="/working-schedule"
              />
            </div>
          </>
        )
      }
    </>
  );
};

export default compose(translate('translations'))(PartnerMenu);
