import React, { useState, useEffect } from 'react';
import HeaderMasterSetting from '../../../../components/HeaderMasterSetting';
import { useSelector } from 'react-redux';
import { compose } from 'redux';
import Footer from '../../../../components/Footer';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { CONTRACT_ENDPOINTS } from '../../../../constants/endpoints';
import _ from 'lodash';
import moment from 'moment';
import { DATE_JP_FORMAT, DATE_JP_RENDER } from 'helpers/constants';
import { statusPartnerContract } from 'helpers/constants';
import { pushToast } from 'components/Toast';
import Loading from 'components/Loading/Loading';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getDataHistoryMasterSetting } from 'actions/authentication';
import { SCREEN_NAME } from '../../../../helpers/constants';
import FooterMaintenance from "components/FooterMaintenance"

const dataOnOffParnet = [
  { name: 'ON', value: 0 },
  { name: 'OFF', value: 1 },
];

const AddContractRecrord = (props) => {
  const history = useHistory();
  let recruitmentId = (process.browser && new URL(window.location.href).searchParams.get('id')) || '';
  let productId = (process.browser && new URL(window.location.href).searchParams.get('productId')) || '';
  let organizationId = (process.browser && window.location.href.match(new RegExp("[\?\&]" + 'organizationId' + "=([^\&]*)(\&?)","i"))?.[1]) || '';
  let nameTitle = (process.browser && decodeURIComponent(new URL(window.location.href).searchParams.get('nameTitle'))) || '';
  const colorScreen = useSelector((store) => store.utils.colorMe);
  const [dataRecord, setDataRecord] = useState([]);
  const [buttonCheckData, setButtonCheckData] = useState(0);
  const [checkedPartner, setCheckedParnter] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch()

  const getListPartner = async ({ recruitmentId, organizationId }) => {
    setLoading(true);
    try {
      const data = await CONTRACT_ENDPOINTS.GET_LIST_PARTNERS({
        recruitmentId,
        organizationId,
      });
      if (data?.data) {
        setCheckedParnter(
          data?.data?.partnersAdded === 'ALL'
            ? data?.data?.partners?.map((ele) => ele?.partnerId)
            : data?.data?.partnersAdded || []
        );
        setButtonCheckData(data?.data?.option || 0);
        if (data?.data?.option === 1) {
          const dataUpdateStatus = data?.data?.partners?.map((item) => {
            return { ...item, statusPartner: '契約中', fromContractPeriod: '', toContractPeriod: '' };
          });
          setDataRecord({ ...data?.data, partners: dataUpdateStatus });
        } else {
          setDataRecord(data?.data);
        }
      }
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  useEffect(() => {
    getListPartner({ recruitmentId, organizationId });
  }, [recruitmentId, organizationId]);

  useEffect(() => {
    let dataRecordCheck = dataRecord?.partners?.map(elm => elm?.partnerId) || []
    let dataCheckPartner = checkedPartner?.filter(ele => dataRecordCheck.includes(ele))
    if (
      !_.isEmpty(dataRecord) &&
      !_.isEmpty(checkedPartner) &&
      dataRecord?.partners?.length === dataCheckPartner?.length
    ) {
      setIsChecked(true);
    }
  }, [dataRecord?.partners, checkedPartner]);

  const onUpdateContractPartner = async ({ body = '' }) => {
    setLoading(true);
    try {
      const data = await CONTRACT_ENDPOINTS.UPDATE_CONTRACT_PARTNER({ body });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
      } else {
        pushToast('success', 'Success.');
        if (data?.data?.recruitmentId) {
          if (_.isEmpty(recruitmentId)) {
            history.push(`/contract/contract-record?id=${data?.data?.recruitmentId}&productId=${productId}&organizationId=${organizationId}&nameTitle=${nameTitle}`);
          } else {
            getListPartner({ recruitmentId: data?.data?.recruitmentId, organizationId });
          }
        }
        dispatch(getDataHistoryMasterSetting({ logId:'', screenName: SCREEN_NAME.CONTRACT_MANAGEMENT }));
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const onClickUpate = (updateTitle) => {
    const newData = dataRecord?.partners
      ?.filter((elm) => checkedPartner?.includes?.(elm.partnerId))
      ?.map((item) => {
        return {
          partnerId: item?.partnerId,
          statusPartner: item?.statusPartner,
          fromContractPeriod: item?.fromContractPeriod,
          toContractPeriod: item?.toContractPeriod,
        };
      });
    onUpdateContractPartner({
      body: !_.isEmpty(recruitmentId)
        ? {
            recruitmentId,
            organizationId,
            partners: newData,
            option: buttonCheckData,
            screenName: 'Management追加',
            productId,
            updateTitle,
          }
        : {
            organizationId,
            partners: newData,
            option: buttonCheckData,
            screenName: 'Management追加',
            productId,
            updateTitle,
          },
    });
  };

  const handleCheckBox = (e) => {
    let target = e.target;
    let value = target.value;
    let arrTmp = checkedPartner || [];
    if (target.checked) {
      arrTmp.push(value);
      let dataCheck = arrTmp?.filter(ele => (dataRecord?.partners?.map(k => k?.partnerId)).includes(ele))
      if (dataCheck.length === dataRecord?.partners.length) {
        setIsChecked(true);
      }
    } else {
      arrTmp.splice(arrTmp.indexOf(value), 1);
      let dataCheck = arrTmp?.filter(ele => (dataRecord?.partners?.map(k => k?.partnerId)).includes(ele))
      if (dataCheck.length !== dataRecord?.partners.length) {
        setIsChecked(false);
      }
    }
    setCheckedParnter(arrTmp);
    setDataRecord({ ...dataRecord, partnersAdded: arrTmp });
  };

  const onChangeDate = (index, date, text) => {
    let dataUpdateDate = dataRecord?.partners?.map((item, indexs) => {
      if (indexs === index) {
        if (date) {
          return { ...item, [text]: moment(new Date(date)).format(DATE_JP_FORMAT) };
        } else {
          return { ...item, [text]: '' };
        }
      } else {
        return item;
      }
    });
    setDataRecord({ ...dataRecord, partners: dataUpdateDate });
  };

  const handleSelectContract = (e, index) => {
    const dataUpdateSelect = dataRecord?.partners?.map((item, indexs) => {
      if (indexs === index) {
        return { ...item, [e.target.name]: e?.target?.value };
      } else {
        return item;
      }
    });
    setDataRecord({ ...dataRecord, partners: dataUpdateSelect });
  };

  const handleONandOFF = (value) => {
    setButtonCheckData(value);
    if (value === 1) {
      const dataUpdateStatus = dataRecord?.partners?.map((item) => {
        return { ...item, statusPartner: '契約中', fromContractPeriod: '', toContractPeriod: '' };
      });
      setDataRecord({ ...dataRecord, partners: dataUpdateStatus });
    } else {
      const dataUpdateStatus = dataRecord?.partners?.map((item) => {
        return { ...item, statusPartner: '', fromContractPeriod: '', toContractPeriod: '' };
      });
      setDataRecord({ ...dataRecord, partners: dataUpdateStatus });
    }
  };

  const handleCheckAll = (e) => {
    if (e?.target?.checked) {
      const elm = dataRecord?.partners?.map((ele) => {
        return ele?.partnerId;
      });
      let add = elm.filter(ele => !(dataRecord?.partnersAdded || [])?.includes(ele))
      setCheckedParnter([...(dataRecord?.partnersAdded || []), ...add ]);
      setDataRecord({ ...dataRecord, partnersAdded: [...(dataRecord?.partnersAdded || []), ...add ]});
      setIsChecked(true);
    } else {
      const elm = dataRecord?.partners?.map((ele) => {
        return ele?.partnerId;
      });
      const check = dataRecord?.partnersAdded?.filter(ele => !elm?.includes(ele))
      setCheckedParnter(check);
      setDataRecord({ ...dataRecord, partnersAdded: check });
      setIsChecked(false);
    }
  };

  const renderItem = (item, index) => {
    return (
      <tr key={index}>
        <td>
          <input
            type="checkbox"
            value={item.partnerId}
            checked={checkedPartner && checkedPartner.includes(item.partnerId)}
            onChange={handleCheckBox}
          />
        </td>
        <td>
          <input type="text" value={item?.partnerName} className="form-control" />
        </td>
        <td>
          <select
            disabled={buttonCheckData === 1}
            className="form-control"
            name="statusPartner"
            onChange={(e) => handleSelectContract(e, index)}
            value={item?.statusPartner}
          >
            <option value="">...</option>
            {statusPartnerContract?.map((ele, eleI) => {
              return (
                <option value={ele} key={eleI}>
                  {ele}
                </option>
              );
            })}
          </select>
        </td>
        <td className="d-flex">
          <DatePicker
            disabled={buttonCheckData === 1}
            selected={!_.isEmpty(item?.fromContractPeriod) ? new Date(item?.fromContractPeriod) : ''}
            onChange={(date) => onChangeDate(index, date, 'fromContractPeriod')}
            locale="ja"
            dateFormat={DATE_JP_RENDER}
            dateFormatCalendar="yyyy年 MM月"
            className="form-control"
          />
          <DatePicker
            disabled={buttonCheckData === 1}
            selected={!_.isEmpty(item?.toContractPeriod) ? new Date(item?.toContractPeriod) : ''}
            onChange={(date) => onChangeDate(index, date, 'toContractPeriod')}
            locale="ja"
            dateFormat={DATE_JP_RENDER}
            dateFormatCalendar="yyyy年 MM月"
            className="form-control"
          />
        </td>
      </tr>
    );
  };

  return (
    <div className="main">
      <HeaderMasterSetting color={colorScreen?.code} colorText={colorScreen?.colorText} checkName="/contract" />
      <Loading loading={loading} />
      <div className="block-contract">
        <div className="list-table">
          <div className="table-list-record-contract">
            <div className="title-recruitment">募集一覧</div>
            <div className="mb-2 wrapper-button-contract">
              <button
                className="button-on button-back color-check"
                onClick={() => (window.location.href = '/contract')}
              >
                戻る
              </button>
            </div>
            <div className="mb-2 wrapper-button-contract">
              {dataOnOffParnet?.map((ele) => {
                return (
                  <button
                    value={ele?.value}
                    onClick={() => handleONandOFF(ele?.value)}
                    className={`button-on ${buttonCheckData === ele?.value ? 'color-check' : ''} `}
                  >
                    {ele?.name}
                  </button>
                );
              })}
            </div>
            <div className="form--scroll_x form--h-360 mb-3">
              <table className="table table-bordered table-collapse-unset">
                <thead>
                  <tr>
                    <th className="w-2 pd5">
                      <input type="checkbox" checked={isChecked} onChange={(e) => handleCheckAll(e)} />
                    </th>
                    <th className="w10 pd5">会社名</th>
                    <th className="w10 pd5">契約状態</th>
                    <th className="w15 pd5">最終契約期間</th>
                  </tr>
                </thead>
                <tbody>{dataRecord && dataRecord?.partners?.map((list, index) => renderItem(list, index))}</tbody>
              </table>
            </div>
            <div className="wrapper-button-contract text-right">
              <button
                className="button-on button-submit-data color-check"
                onClick={() => onClickUpate(`追加 募集一覧 ${nameTitle||''}`)}>
                追加
              </button>
            </div>
          </div>
        </div>
      </div>
      <FooterMaintenance/>
    </div>
  );
};

export default compose(AddContractRecrord);
