import React, { useEffect, useState } from 'react';
import { ROLE, ROLE_ORGANIZATION_CHECK, PARAMS_REQUIRED_ORRGANIZATION } from '../../helpers/constants';
import { validateRequried } from '../../helpers/helpers';
import AreaSelection from 'components/AreaSelection';
import _ from 'lodash';
import AutocompleteAddress from '../AutocompleteAddress';
import BaseRegistration from './BaseRegistration';
import { useHistory } from 'react-router';
import { AREAS_PERMITTED } from 'helpers/areas';
import QualificationList from '../QualificationList';
import { genId } from '../../helpers/helpers'
import { Link, withRouter } from 'react-router-dom';

const DetailOrganization = ({ dataOrganizationLogin, onClickSubmit, roleOrganization, data, nameRole = '', newRole = '', id = '' }) => {
  const history = useHistory();
  const [dataOrganization, setDataOrganization] = useState({
    getQualified: []
  });
  const [postalCode1, setPostalCode1] = useState('');
  const [postalCode2, setPostalCode2] = useState('');
  const [isDisable, setIsDisable] = useState(true);
  const [prefectureAreas, setPrefectureAreas] = useState({});
  const prefectureNameKanji = _.groupBy(AREAS_PERMITTED, 'prefectureNameKanji');
  const [origin, setOrigin] = useState(null);

  useEffect(() => {
    if (!_.isEmpty(data)) {
      setDataOrganization({
        ...data,
        getQualified: data?.getQualified || [],
      });
      const dataHandler = _.flatten(Object.values(data?.areas || {})).map((e) => {
        return !_.isEmpty(data?.prefectureAreas?.[e])
          ? { [e]: data?.prefectureAreas[e] }
          : { [e]: _.compact(_.map(prefectureNameKanji[e] || [], 'cityNameKanji')) };
      });
      const newPrefectureAreas = _.isEmpty(dataHandler) ? {} : Object.assign(...dataHandler);
      setPrefectureAreas(newPrefectureAreas || {});
      setOrigin(data?.abbreviation);
    }
  }, [data]);

  useEffect(() => {
    if(dataOrganization) {
      validateRequried({data: dataOrganization, arrField: PARAMS_REQUIRED_ORRGANIZATION, onDisabled});
    }
  }, [dataOrganization]);

  const onDisabled = ({status}) => {
    setIsDisable(status);
  }

  useEffect(() => {
    let newData = {
      ...dataOrganization,
      postalCode: postalCode1 + '-' + postalCode2,
    };
    setDataOrganization(newData);
  }, [postalCode1, postalCode2]);

  useEffect(() => {
    if (dataOrganization) {
      setPostalCode1(dataOrganization?.postalCode?.split('-')[0] || '');
      setPostalCode2(dataOrganization?.postalCode?.split('-')[1] || '');
    }
  }, [dataOrganization]);

  const handleChangeText = (e) => {
    let name = e.target.name;
    const value = name === 'abbreviation' ? e.target.value.toUpperCase() : e.target.value;
    let newDataClient = { ...dataOrganization, [name]: value };
    setDataOrganization(newDataClient);
  };

  const onChangePostalCode = (e) => {
    const type = e.target.name;
    if (type === 'postalCode1') {
      setPostalCode1(e.target.value);
    } else {
      setPostalCode2(e.target.value);
    }
  };

  const onChangePrefectureAreas = (data) => {
    setPrefectureAreas(data);
  }

  const onChangeAreas = (areas) => {
    const newData = { ...dataOrganization, areas };
    setDataOrganization(newData);
    const values = _.flatten(Object.values(newData?.areas || {}));
    const dataHandler = values.map(e => {
      return Object.keys(prefectureAreas || {}).includes(e) ? {[e]: prefectureAreas[e]} : {[e]: _.compact(_.map(prefectureNameKanji[e] || [], 'cityNameKanji'))}
    })
    const newPrefectureAreas = _.isEmpty(dataHandler) ? {} : Object.assign(...dataHandler);
    setPrefectureAreas(newPrefectureAreas);
  }

  const handleChangeQualificationText = ({ e, item, index, name, value }) => {
    const fieldName = (e && e.target.name) || name;
    item[fieldName] = (e && e.target.value) || value;
    const getQualified = dataOrganization?.getQualified;
    getQualified[index] = _.pick(item, ['grade', 'licenseNumber', 'qualifications', 'validityPeriodFrom', 'validityPeriodTo']);
    setDataOrganization({ ...dataOrganization, getQualified });
  };

  const handleRemote  = (index) => {
    const getQualified = [...dataOrganization?.getQualified];
    getQualified.splice(index, 1);
    setDataOrganization({ ...dataOrganization, getQualified });
  }

  const addNewItem = () => {
    let newGetQualifications = [...dataOrganization?.getQualified, { key: genId() }]
    setDataOrganization({ ...dataOrganization, getQualified: newGetQualifications });
  };

  return (
    <div className="modifly__organization">
      <table className="table__modifly">
        {(history.location.pathname === '/organizations/create-organization' ||
          (history.location.pathname === '/organizations/edit-organization' &&
            nameRole === ROLE_ORGANIZATION_CHECK.MONITORING)) && (
          <>
            <tr>
              <th className="table__modifly--color text-left pl-1" colSpan="4">
                {['5', '6', '7', 5, 6, 7].includes(newRole) ? 'ManagementGroup' : 'ERP'}
              </th>
            </tr>
            <tr>
              <th className="table__modifly--color">CloudID</th>
              <td colSpan="3">
                <input
                  type="text"
                  className="w-100 table__modifly--background"
                  disabled={roleOrganization === ROLE.PARTNERGROUP}
                  value={
                    roleOrganization === ROLE.PARTNERGROUP
                      ? dataOrganizationLogin?.organizationId || ''
                      : dataOrganization?.code
                  }
                />
              </td>
            </tr>
            <tr>
              <th className="table__modifly--color">企業名</th>
              <td colSpan="3">
                <input
                  type="text"
                  className="w-100 table__modifly--background"
                  disabled={roleOrganization === ROLE.PARTNERGROUP}
                  name="organizationName"
                  id="organizationName"
                  value={
                    roleOrganization === ROLE.PARTNERGROUP
                      ? dataOrganizationLogin?.officialName || ''
                      : dataOrganization?.officialName
                  }
                />
              </td>
            </tr>
            <tr>
              <th className="table__modifly--color">フリガナ</th>
              <td colSpan="3">
                <input
                  type="text"
                  className="w-100 table__modifly--background"
                  disabled={roleOrganization === ROLE.PARTNERGROUP}
                  id="companyName"
                  name="companyName"
                  value={
                    roleOrganization === ROLE.PARTNERGROUP
                      ? dataOrganizationLogin?.officialNameKana || ''
                      : dataOrganization?.officialNameKana
                  }
                />
              </td>
            </tr>
          </>
        )}
        <tr>
          <th colSpan="4" className="table__modifly--color text-left pl-1">
            {nameRole === ROLE_ORGANIZATION_CHECK.MONITORING ? '発注企業' : '協力企業'}
            <button className="btn modifly__organization--button modifly__organization--submit ml-5">基本情報</button>
            {nameRole !== ROLE_ORGANIZATION_CHECK.MONITORING && (
            <Link to={nameRole === 'management' ? `/contract/filters?partnerId=${id}&nameRole=${nameRole}` : `/contract/filters?clientId=${id}&nameRole=${nameRole}`} className="btn modifly__organization--button modifly__organization--submit">契約情報</Link>
            )}
            </th>
        </tr>
        <tr>
          <th className="table__modifly--color">{nameRole === ROLE_ORGANIZATION_CHECK.MONITORING ? '発注企業名' : '協力企業名'}</th>
          <td>
            <input
              type="text"
              className="w-100 table__modifly--input"
              name="officialName"
              id="officialName"
              value={dataOrganization?.officialName}
              onChange={handleChangeText}
            />
          </td>
          <th className="table__modifly--color">電話番号</th>
          <td>
            <input
              type="text"
              className="w-100 table__modifly--input"
              name="phoneNumber"
              value={dataOrganization?.phoneNumber}
              onChange={handleChangeText}
            />
          </td>
        </tr>
        {
          [ROLE_ORGANIZATION_CHECK.MONITORING, ROLE_ORGANIZATION_CHECK.MANAGERMENT].includes(nameRole) ? (
            <tr>
              <th className="table__modifly--color">フリガナ</th>
              <td>
                <input
                  type="text"
                  className="w-100 table__modifly--input"
                  name="officialNameKana"
                  id="officialNameKana"
                  value={dataOrganization?.officialNameKana}
                  onChange={handleChangeText}
                />
              </td>
              <th className="table__modifly--color">PDF出力パスワード</th>
              <td>
                <input
                  type="text"
                  className="w-100 table__modifly--input"
                  name="pdfPassword"
                  value={dataOrganization?.pdfPassword}
                  onChange={handleChangeText}
                />
              </td>
            </tr>
          ) : (
            <tr>
              <th className="table__modifly--color">フリガナ</th>
              <td colSpan="3">
                <input
                  type="text"
                  className="w-100 table__modifly--input"
                  name="officialNameKana"
                  id="officialNameKana"
                  value={dataOrganization?.officialNameKana}
                  onChange={handleChangeText}
                />
              </td>
            </tr>
          )
        }
        <tr>
          <th className="table__modifly--color">システム表示名称</th>
          <td>
            <input
              type="text"
              className="w-100 table__modifly--input"
              name="abbreviationName"
              id="abbreviationName"
              value={dataOrganization?.abbreviationName}
              onChange={handleChangeText}
            />
          </td>
          <th className="table__modifly--color">システム表示略称（英2～4文字）</th>
          <td>
            <input
              type="text"
              className="w-100 table__modifly--input"
              name="abbreviation"
              id="abbreviation"
              maxLength="4"
              disabled={!!origin && history.location.pathname === '/organizations/edit-organization'}
              value={dataOrganization?.abbreviation}
              onChange={handleChangeText}
            />
          </td>
        </tr>
        <tr>
          <th className="table__modifly--color">代表者</th>
          <td>
            <input
              type="text"
              className="w-100 table__modifly--input"
              name="name"
              id="name"
              value={dataOrganization?.name}
              onChange={handleChangeText}
            />
          </td>
          <th className="table__modifly--color">フリガナ</th>
          <td>
            <input
              type="text"
              className="w-100 table__modifly--input"
              name="nameKana"
              id="nameKana"
              value={dataOrganization?.nameKana}
              onChange={handleChangeText}
            />
          </td>
        </tr>
        <tr>
          <th className="table__modifly--color">郵便番号</th>
          <td style={{ width: '26%' }}>
            <div className="d-flex w-100">
              <input
                className="table__modifly--input w-50 mr-1"
                type="number"
                name="postalCode1"
                value={postalCode1}
                onChange={onChangePostalCode}
                maxLength={4}
              />
              <input
                className="table__modifly--input w-50"
                type="number"
                name="postalCode2"
                value={postalCode2}
                onChange={onChangePostalCode}
                maxLength="4"
              />
            </div>
          </td>
          <th className="table__modifly--color">都道府県</th>
          <td>
            <select
              className="w-100 table__modifly--input"
              value={dataOrganization?.prefecture}
              name="prefecture"
              onChange={handleChangeText}
            >
              <option value=""></option>
              <option value="北海道">北海道</option>
              <option value="青森県">青森県</option>
              <option value="岩手県">岩手県</option>
              <option value="宮城県">宮城県</option>
              <option value="秋田県">秋田県</option>
              <option value="山形県">山形県</option>
              <option value="福島県">福島県</option>
              <option value="東京都">東京都</option>
              <option value="神奈川県">神奈川県</option>
              <option value="埼玉県">埼玉県</option>
              <option value="千葉県">千葉県</option>
              <option value="茨城県">茨城県</option>
              <option value="栃木県">栃木県</option>
              <option value="群馬県">群馬県</option>
              <option value="山梨県">山梨県</option>
              <option value="新潟県">新潟県</option>
              <option value="長野県">長野県</option>
              <option value="石川県">石川県</option>
              <option value="富山県">富山県</option>
              <option value="福井県">福井県</option>
              <option value="愛知県">愛知県</option>
              <option value="岐阜県">岐阜県</option>
              <option value="静岡県">静岡県</option>
              <option value="三重県">三重県</option>
              <option value="大阪府">大阪府</option>
              <option value="兵庫県">兵庫県</option>
              <option value="京都府">京都府</option>
              <option value="滋賀県">滋賀県</option>
              <option value="奈良県">奈良県</option>
              <option value="和歌山県">和歌山県</option>
              <option value="鳥取県">鳥取県</option>
              <option value="島根県">島根県</option>
              <option value="岡山県">岡山県</option>
              <option value="広島県">広島県</option>
              <option value="山口県">山口県</option>
              <option value="徳島県">徳島県</option>
              <option value="香川県">香川県</option>
              <option value="愛媛県">愛媛県</option>
              <option value="高知県">高知県</option>
              <option value="福岡県">福岡県</option>
              <option value="佐賀県">佐賀県</option>
              <option value="長崎県">長崎県</option>
              <option value="熊本県">熊本県</option>
              <option value="大分県">大分県</option>
              <option value="宮崎県">宮崎県</option>
              <option value="鹿児島県">鹿児島県</option>
              <option value="沖縄県">沖縄県</option>
            </select>
          </td>
        </tr>
        <tr>
          <th className="table__modifly--color">住所</th>
          <td colSpan="3">
            <AutocompleteAddress
              address={dataOrganization?.address}
              onChange={(address) => {
                setDataOrganization({ ...dataOrganization, address });
              }}
              name="address"
              onSelectAddress={({ address, latitude, longitude }) => {
                setDataOrganization({ ...dataOrganization, address, latitude, longitude });
              }}
            />
          </td>
        </tr>
        <tr>
          <th className="table__modifly--color">フリガナ</th>
          <td colSpan="3">
            <input
              type="text"
              className="w-100 table__modifly--input"
              value={dataOrganization?.addressKana}
              onChange={handleChangeText}
              name="addressKana"
            />
          </td>
        </tr>
        {nameRole === ROLE_ORGANIZATION_CHECK.MANAGERMENT && (
          <>
            <tr>
              <th className="table__modifly--color">緯度</th>
              <td>
                <input
                  type="text"
                  className="w-100 table__modifly--input"
                  value={dataOrganization?.latitude}
                  onChange={handleChangeText}
                  name="latitude"
                  id="latitude"
                />
              </td>
              <th className="table__modifly--color">経度</th>
              <td>
                <input
                  type="text"
                  className="w-100 table__modifly--input"
                  value={dataOrganization?.longitude}
                  onChange={handleChangeText}
                  name="longitude"
                  id="longitude"
                />
              </td>
            </tr>
            <tr>
              <th className="table__modifly--color">対応エリア設定</th>
              <td className="table__modifly--background" colSpan="3">
                <AreaSelection
                  activeArea={{ ...dataOrganization?.areas }}
                  prefectureAreas={prefectureAreas}
                  onChangePrefectureAreas={onChangePrefectureAreas}
                  onChange={(areas) => { onChangeAreas(areas) }}
                />
              </td>
            </tr>
            {nameRole === ROLE_ORGANIZATION_CHECK.MANAGERMENT && (
              <tr>
                <th className="table__modifly--color">取得資格</th>
                <td colSpan="3">
                  <QualificationList
                    userData={dataOrganization}
                    handleChangeQualificationText={handleChangeQualificationText}
                    handleRemote={handleRemote}
                    addNewItem={addNewItem}
                  />
                </td>
              </tr>
            )}
          </>
        )}
      </table>
      {/* {history.location.pathname === '/organizations/edit-organization' &&
        nameRole === ROLE_ORGANIZATION_CHECK.MANAGERMENT && <BaseRegistration />} */}
      <div className="text-center">
        <button
          className="btn modifly__organization--button modifly__organization--submit--update"
          onClick={() => onClickSubmit({...dataOrganization, prefectureAreas})}
          disabled={isDisable}
        >
          更新
        </button>
      </div>
    </div>
  );
};

export default DetailOrganization;
