import React, { useState } from 'react';
import EvenInput from '../EvenInput';

const dataApi = {
  openingTest: {
    complete: [
      {
        id: 1,
        value: '開通試験完了',
      },
      {
        id: 2,
        value: '開通試験完了',
      },
    ],
    incomplete: [
      {
        id: 1,
        value: '開通試験未完了',
      },
      {
        id: 2,
        value: '開通試験未完了',
      },
    ],
    canBefore: ['開通試験前日CAN', '開通試験前日CAN'],
    canAdvance: ['開通試験事前CAN', '開通試験事前CAN'],
    canToday: ['開通試験当日CAN', '開通試験当日CAN'],
  },
};

const TableProcessInfo = () => {
  const [data, setData] = useState(dataApi);

  const onClickAdd = (type = 'complete') => {
    if (type && type === 'complete') {
      let newData = { ...data };
      newData.openingTest.complete.push({
        id: data.openingTest.complete[data.openingTest.complete.length - 1].id + 1,
        value: '開通試験完了',
      });
      setData(newData);
    } else if (type === 'incomplete') {
      let newData = { ...data };
      newData.openingTest.incomplete.push({ id: data.openingTest.incomplete.length + 1, value: '開通試験未完了' });
      setData(newData);
    }
  };

  const onClickDel = ({ type = 'complete', id = '' }) => {
    if (type && type === 'complete') {
      let newData = { ...data };
      newData.openingTest.complete = newData.openingTest.complete.filter((item) => item.id !== id);
      setData(newData);
    }
  };

  return (
    <div className="table-process-wrapper p-2 table-responsive">
      <table>
        <tbody>
          <tr>
            <th rowSpan="5" className="even-title" style={{ width: '60px' }}>
              3
            </th>
            <th rowSpan="5" style={{ width: '100px' }}>
              開通試験
            </th>
            <th rowSpan="1" style={{ width: '120px' }}>
              完了
            </th>
            <td className="even">
              {data.openingTest &&
                data.openingTest.complete.map((item, index) => (
                  <EvenInput key={index} value={item.value} onClickDel={onClickDel} id={item.id} type="complete" />
                ))}
              <button className="btn button-add mt-2" onClick={() => onClickAdd('complete')}>
                追加
              </button>
            </td>
          </tr>
          <tr>
            <th rowSpan="1">未完了</th>
            <td className="even">
              {data.openingTest &&
                data.openingTest.incomplete.map((item, index) => <EvenInput key={index} value={item.value} />)}
              <button className="btn button-add mt-2" onClick={() => onClickAdd('incomplete')}>
                追加
              </button>
            </td>
          </tr>
          <tr>
            <th rowSpan="1">前日CAN</th>
            <td className="even">
              <div className="d-flex">
                <input type="text" className="form-control even-input" value="開通試験完了" />
                <button className="btn button-delete ml-2">削除</button>
              </div>
              <button className="btn button-add mt-2">追加</button>
            </td>
          </tr>
          <tr>
            <th rowSpan="1">事前CAN</th>
            <td className="even">
              <div className="d-flex">
                <input type="text" className="form-control even-input" value="開通試験完了" />
                <button className="btn button-delete ml-2">削除</button>
              </div>
              <button className="btn button-add mt-2">追加</button>
            </td>
          </tr>
          <tr>
            <th rowSpan="1">当日CAN</th>
            <td className="even">
              <div className="d-flex">
                <input type="text" className="form-control even-input" value="開通試験完了" />
                <button className="btn button-delete ml-2">削除</button>
              </div>
              <button className="btn button-add mt-2">追加</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default TableProcessInfo;
