import React, { useEffect, useState } from 'react';
import { ORDER } from 'constants/endpoints';
import { shallowEqual, useSelector } from 'react-redux';
import { translate } from 'react-i18next';
import _ from 'lodash';
import './style.scss';
import { useHistory } from 'react-router-dom';

const MemoTable = ({ orderId, screenName, update, t, logId = '', visitDate }) => {
  const history = useHistory();
  const [dataComment, setDataComment] = useState(null);
  const [dataLog, setDataLog] = useState([]);
  const [dataLogOrder, setDataLogOrder] = useState([]);
  const [dataLogDispatch, setDataLogDispatch] = useState([]);
  const userMe = useSelector((store) => store.authentication.userMe, shallowEqual);

  useEffect(() => {
    if (orderId && screenName) {
      setDataComment({
        orderId,
        screenName,
        contactPerson: '',
        category: '',
        comment: '',
      });
      getAllLog({ page: 0, orderId, screenName });
    }
  }, [orderId, screenName]);

  const onChangeText = (e) => {
    let name = e.target.name;
    setDataComment({ ...dataComment, [name]: e.target.value });
  };

  const getAllLog = async ({ page = 1, limit = 10, orderId = '', screenName = '' }) => {
    try {
      const data = await ORDER.GET_ALL_LOG({ page, limit, orderId, screenName });
      if (data && data?.code === 0) {
        setDataLogOrder(data?.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getAllLogHistory = async ({ screenName = '', logId = '' }) => {
    try {
      const data = await ORDER.GET_LOG_HISTORY_SYSTEM({ screenName, logId });
      if (data && data?.code === 0) {
        setDataLog(data?.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getHistoryDispatch = async ({ visitDate = '' }) => {
    try {
      const data = await ORDER.GET_LOG_HISTORY_DISPATCH({ visitDate });
      if (data && data?.code === 0) {
        setDataLogDispatch(data?.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (screenName) {
      if (
        (localStorage.getItem('role') === '4' ||
          localStorage.getItem('role') === '10' ||
          localStorage.getItem('role') === '7' ||
          localStorage.getItem('role') === '13') &&
        history.location.pathname !== '/equipment-management' &&
        history.location.pathname !== `/payment-date/update/${logId}` &&
        history.location.pathname !== '/dispatch' &&
        history.location.pathname !== '/detail-worker-list'
      ) {
        getAllLog({ screenName, orderId });
      } else {
        getAllLogHistory({ screenName, logId });
      }
      if (
        localStorage.getItem('role') === '2' &&
        history.location.pathname !== '/equipment-management' &&
        history.location.pathname === `/payment-date/update/${logId}` &&
        history.location.pathname !== '/dispatch' &&
        history.location.pathname !== '/detail-worker-list'
      ) {
        getAllLog({ screenName, orderId: logId });
      }
      if (
        localStorage.getItem('role') === '10' &&
        history.location.pathname === '/equipment-management' &&
        history.location.pathname !== `/payment-date/update/${logId}` &&
        history.location.pathname !== '/dispatch' &&
        history.location.pathname !== '/detail-worker-list'
      ) {
        getAllLogHistory({ screenName, logId });
      }
    }
    if (visitDate) {
      if (localStorage.getItem('role') === '4' && history.location.pathname === '/dispatch') {
        getHistoryDispatch({ visitDate });
      }
    }
    if (localStorage.getItem('role') === '14' && history.location.pathname === '/detail-worker-list') {
      getAllLog({ screenName, orderId: logId });
    }
  }, [screenName, visitDate]);

  // const createLog = async (body) => {
  //   try {
  //     const data = await ORDER.CREATE_LOG(body);
  //     if (data && data.code !== 0) {
  //       if (data && data.message && data.message.extra) {
  //         pushToast('error', data?.message?.extra);
  //       } else {
  //         pushToast('error', data?.message?.errorMessage);
  //       }
  //     } else {
  //       pushToast('success', 'Create log success');
  //       setDataComment({
  //         orderId: orderId,
  //         screenName: screenName,
  //         contactPerson: '',
  //         category: '',
  //         comment: '',
  //       });
  //       getAllLog({ page: 0, orderId: orderId, screenName: screenName });
  //     }
  //   } catch (errorMessage) {
  //     console.log('errorMessage: ', errorMessage);
  //   }
  // };

  // const onSubmit = () => {
  //   createLog(dataComment);
  // };

  useEffect(() => {
    if (orderId) {
      getAllLog({ page: 0, orderId, screenName });
    }
  }, [update]);

  return (
    <div className="memo-table-wrapper-fix mb-4">
      <div className="text-center mt-2">
        <span>更新履歴</span>
      </div>
      <table className="responsive editTbl mb40 template_memo_table-fix">
        <colgroup>
          <col style={{ width: '5%' }} />
          <col style={{ width: '7%' }} />
          <col style={{ width: '10%' }} />
          <col style={{ width: '7%' }} />
          <col style={{ width: '7%' }} />
          <col style={{ width: 800 }} />
        </colgroup>

        <tbody>
          <tr>
            <th>記入時間</th>
            <th>画面名</th>
            <th>連絡者</th>
            <th>カテゴリ</th>
            <th>記入者</th>
            <th>コメント</th>
          </tr>
          <tr className="template_memo_editrow-fix">
            <td rowSpan="4">
              <div align="center">現在時刻</div>
            </td>
            <td rowSpan="4">
              <div align="left">現在画面</div>
            </td>
            <td style={{ backgroundColor: '#E9D26D', padding: 4, margin: 4, lineHeight: 0 }} id="select1">
              <input type="radio" name="contactPerson" id="memo_type1" value="作業員" onChange={onChangeText} />
              <label htmlFor="memo_type1">作業員</label>
            </td>
            <td rowSpan="4" className="changeBgtd">
            </td>
            <td rowSpan="4">
              {userMe?.data?.firstName + ' ' + userMe?.data?.lastName}
              <input type="hidden" name="staff_code" value="BAP02" />
              <input type="hidden" name="receive_order_id" value="8" />
            </td>
            <td rowSpan="4">
              <div style={{ display: 'none' }} className="site_info_checkDiv">
                <span style={{ color: 'red' }}>※必須</span>
                <br />
                <input type="radio" name="site_info_check" id="site_info2" value="2" />
                <label htmlFor="site_info2">連絡OK 予定時刻</label>
                <div className="timepicker_diy-wrapper" style={{ position: 'relative', display: 'inline-block' }}>
                  <input type="text" className="timepicker_diy" name="newtime" size="4" />
                </div>
                <br />
                <input type="radio" name="site_info_check" id="site_info1" value="1" />
                <label htmlFor="site_info1">連絡NG・エスカ有り</label>
              </div>
              <textarea
                name="comment"
                cols="40"
                rows="6"
                onChange={onChangeText}
                value={dataComment?.comment}
              ></textarea>
              <br />
            </td>
          </tr>
          <tr style={{ verticalAlign: 'top' }}>
            <td style={{ backgroundColor: '#8CD6A1', padding: 4, margin: 4, lineHeight: 0 }} id="select2">
              <input type="radio" name="contactPerson" id="memo_type2" value="パートナー" onChange={onChangeText} />
              <label htmlFor="memo_type2">パートナー</label>
            </td>
          </tr>
          <tr style={{ verticalAlign: 'top' }}>
            <td style={{ lineHeight: 0 }} id="select3">
              <input type="radio" name="contactPerson" id="memo_type3" value="クライアント" onChange={onChangeText} />
              <label htmlFor="memo_type3">クライアント</label>
            </td>
          </tr>
          <tr style={{ verticalAlign: 'top' }}>
            <td style={{ backgroundColor: '#FFCC99', padding: 4, margin: 4, lineHeight: 0 }} id="select4">
              <input type="radio" name="contactPerson" id="memo_type4" value="訪問先担当者" onChange={onChangeText} />
              <label htmlFor="memo_type4">訪問先担当者</label>
            </td>
          </tr>

          {(((localStorage.getItem('role') === '4' ||
            localStorage.getItem('role') === '10' ||
            localStorage.getItem('role') === '7' ||
            localStorage.getItem('role') === '13') &&
            history.location.pathname !== '/detail-worker-list' &&
            history.location.pathname !== '/equipment-management' &&
            history.location.pathname !== '/dispatch' &&
            history.location.pathname !== `/payment-date/update/${logId}`) ||
            (localStorage.getItem('role') === '2' &&
              history.location.pathname !== '/detail-worker-list' &&
              history.location.pathname !== '/equipment-management' &&
              history.location.pathname !== '/dispatch' &&
              history.location.pathname === `/payment-date/update/${logId}`) ||
            (localStorage.getItem('role') === '14' && history.location.pathname === '/detail-worker-list')) &&
            dataLogOrder?.body?.length > 0 &&
            dataLogOrder.body.map((item, index) => {
              let newComment = {}
              try {
                newComment = JSON.parse(item?.comment.slice(5));
              } catch(e) {
                console.log(e)
              }
              newComment = Object.keys(newComment).reduce((prev, curr) => {
                return {
                  ...prev,
                  [t(`id.${curr}`)]: !_.isObject(newComment[curr])
                    ? newComment[curr]
                    : newComment[curr].length > 0
                    ? newComment[curr].map((itemss) => {
                        return _.mapKeys(itemss, function(value, key) {
                          return t(`id.${key}`);
                        });
                      })
                    : _.map(newComment[curr], (valueMap, keys) => {
                        return {
                          [t(`id.${keys}`)]: !_.isObject(valueMap)
                            ? valueMap
                            : _.mapKeys(valueMap, function(va, ke) {
                                return t(`id.${ke}`);
                              }),
                        };
                      }),
                };
              }, {});
              return (
                <tr key={index} style={{ verticalAlign: 'top', backgroundColor: '#ebe6ad' }}>
                  <td bgcolor="#ebe6ad">
                    <div align="center">{item?.currentTime}</div>
                  </td>
                  <td bgcolor="#ebe6ad">{item?.screenName}</td>
                  <td bgcolor="#ebe6ad">{item?.contactPerson}</td>
                  <td bgcolor="#ebe6ad">{item?.category}</td>
                  <td bgcolor="#ebe6ad">{item?.writer}</td>
                  <td bgcolor="#ebe6ad" style={{ textAlign: 'left' }}>
                    {_.map(newComment, (v, k) => {
                      return (
                        <div key={`${index} ${k}`}>
                          - {JSON.stringify(k)} :{' '}
                          {!_.isObject(v)
                            ? JSON.stringify(v)
                            : v.length > 0
                            ? v.map((ele) => {
                                return Object.keys(ele).map((ke, e) => {
                                  return (
                                    <div className="pl-2" key={`${index} ${k} ${e}`}>
                                      - {JSON.stringify(ke)} : {JSON.stringify(ele[ke])}
                                    </div>
                                  );
                                });
                              })
                            : Object.keys(v).map((ki, m) => {
                                return (
                                  <div className="pl-2" key={`${index} ${k} ${m}`}>
                                    - {JSON.stringify(ki)} : {JSON.stringify(v[ki])}
                                  </div>
                                );
                              })}
                        </div>
                      );
                    })}
                  </td>
                </tr>
              );
            })}
          {localStorage.getItem('role') === '10' &&
            history.location.pathname !== '/detail-worker-list' &&
            history.location.pathname === '/equipment-management' &&
            history.location.pathname !== '/dispatch' &&
            dataLog &&
            dataLog?.map((item, index) => {
              let newComment = {};
              try {
                newComment = JSON.parse(item?.comment.slice(5));
              } catch(e) {
                console.log(e)
              }
              newComment = Object.keys(newComment).reduce((prev, curr) => {
                return {
                  ...prev,
                  [t(`id.${curr}`)]: !_.isObject(newComment[curr])
                    ? newComment[curr]
                    : newComment[curr].length > 0
                    ? newComment[curr].map((itemss) => {
                        return _.mapKeys(itemss, function(value, key) {
                          return t(`id.${key}`);
                        });
                      })
                    : _.map(newComment[curr], (valueMap, keys) => {
                        return {
                          [t(`id.${keys}`)]: !_.isObject(valueMap)
                            ? valueMap
                            : _.mapKeys(valueMap, function(va, ke) {
                                return t(`id.${ke}`);
                              }),
                        };
                      }),
                };
              }, {});
              return (
                <tr key={index} style={{ verticalAlign: 'top', backgroundColor: '#ebe6ad' }}>
                  <td bgcolor="#ebe6ad">
                    <div align="center">{item?.currentTime}</div>
                  </td>
                  <td bgcolor="#ebe6ad">{item?.screenName}</td>
                  <td bgcolor="#ebe6ad">{item?.contactPerson}</td>
                  <td bgcolor="#ebe6ad">{item?.category}</td>
                  <td bgcolor="#ebe6ad">{item?.writer}</td>
                  <td bgcolor="#ebe6ad">
                    {_.map(newComment, (v, k) => {
                      return (
                        <div key={`${index} -+ ${k}`}>
                          - {JSON.stringify(k)} :{' '}
                          {!_.isObject(v)
                            ? JSON.stringify(v)
                            : v.length > 0
                            ? v.map((ele) => {
                                return Object.keys(ele).map((ke, e) => {
                                  return (
                                    <div className="pl-2" key={`${index} -- ${e}`}>
                                      - {JSON.stringify(ke)} : {JSON.stringify(ele[ke])}
                                    </div>
                                  );
                                });
                              })
                            : Object.keys(v).map((ki, m) => {
                                return (
                                  <div className="pl-2" key={`${index} -- ${m}`}>
                                    - {JSON.stringify(ki)} : {JSON.stringify(v[ki])}
                                  </div>
                                );
                              })}
                        </div>
                      );
                    })}
                  </td>
                </tr>
              );
            })}

          {(localStorage.getItem('role') !== '4' ||
            localStorage.getItem('role') !== '10' ||
            localStorage.getItem('role') !== '7' ||
            localStorage.getItem('role') !== '13') &&
            dataLog &&
            dataLog?.map((item, index) => {
              let newComment

              try {
                newComment = JSON.parse(item?.comment.slice(5));
              } catch (e) {
                newComment = {}
              }

              newComment = Object.keys(newComment).reduce((prev, curr) => {
                return {
                  ...prev,
                  [t(`id.${curr}`)]: !_.isObject(newComment[curr])
                    ? newComment[curr]
                    : newComment[curr].length > 0
                    ? newComment[curr].map((itemss) => {
                        return _.mapKeys(itemss, function(value, key) {
                          return t(`id.${key}`);
                        });
                      })
                    : _.map(newComment[curr], (valueMap, keys) => {
                        return {
                          [t(`id.${keys}`)]: !_.isObject(valueMap)
                            ? valueMap
                            : _.mapKeys(valueMap, function(va, ke) {
                                return t(`id.${ke}`);
                              }),
                        };
                      }),
                  //  _.mapKeys(newComment[curr], function (value, key) {
                  //     return t(`log_history.${key}`);
                  //   })
                };
              }, {});
              // _.isArray(newComment) &&
              // let a =  newComment.map(ele => ele)
              return (
                <tr key={index} style={{ verticalAlign: 'top', backgroundColor: '#ebe6ad' }}>
                  <td bgcolor="#ebe6ad">
                    <div align="center">{item?.currentTime}</div>
                  </td>
                  <td bgcolor="#ebe6ad">{item?.screenName}</td>
                  <td bgcolor="#ebe6ad">{item?.contactPerson}</td>
                  <td bgcolor="#ebe6ad">{item?.category}</td>
                  <td bgcolor="#ebe6ad">{item?.writer}</td>
                  <td bgcolor="#ebe6ad">
                    {_.map(newComment, (v, k) => {
                      return (
                        <div key={`${index} -* ${k}`}>
                          - {JSON.stringify(k)} :{' '}
                          {!_.isObject(v)
                            ? JSON.stringify(v)
                            : v.length > 0
                            ? v.map((ele) => {
                                return Object.keys(ele).map((ke, e) => {
                                  return (
                                    <div className="pl-2" key={`${index} - ${e}`}>
                                      - {JSON.stringify(ke)} : {JSON.stringify(ele[ke])}
                                    </div>
                                  );
                                });
                              })
                            : Object.keys(v).map((ki, m) => {
                                return (
                                  <div className="pl-2" key={`${index} - ${m}`}>
                                    - {JSON.stringify(ki)} : {JSON.stringify(v[ki])}
                                  </div>
                                );
                              })}
                        </div>
                      );
                    })}
                    {/* <div style={{ width: 800, fontSize: 12 }}>{newComment}</div> */}
                  </td>
                </tr>
              );
            })}
          {localStorage.getItem('role') === '4' &&
            history.location.pathname === '/dispatch' &&
            dataLogDispatch &&
            dataLogDispatch?.map((item, index) => {
              const newComment = Object.keys(item).reduce((prev, curr) => {
                return {
                  ...prev,
                  [t(`id.${curr}`)]: !_.isObject(item[curr])
                    ? item[curr]
                    : item[curr].length > 0
                    ? item[curr].map((itemss) => {
                        return _.mapKeys(itemss, function(value, key) {
                          return t(`id.${key}`);
                        });
                      })
                    : _.map(item[curr], (valueMap, keys) => {
                        return {
                          [t(`id.${keys}`)]: !_.isObject(valueMap)
                            ? valueMap
                            : _.mapKeys(valueMap, function(va, ke) {
                                return t(`id.${ke}`);
                              }),
                        };
                      }),
                };
              }, {});
              return (
                <tr key={index} style={{ verticalAlign: 'top', backgroundColor: '#ebe6ad' }}>
                  <td bgcolor="#ebe6ad">
                    <div align="center">{item?.currentTime}</div>
                  </td>
                  <td bgcolor="#ebe6ad">{item?.screenName}</td>
                  <td bgcolor="#ebe6ad">{item?.contactPerson}</td>
                  <td bgcolor="#ebe6ad">{item?.category}</td>
                  <td bgcolor="#ebe6ad">{item?.writer}</td>
                  <td bgcolor="#ebe6ad">
                    {_.map(newComment, (v, k) => {
                      return (
                        <div>
                          - {JSON.stringify(k)} :{' '}
                          {!_.isObject(v)
                            ? JSON.stringify(v)
                            : v.length > 0
                            ? v.map((ele) => {
                                return Object.keys(ele).map((ke, e) => {
                                  return (
                                    <div className="pl-2" key={`${index} + ${e}`}>
                                      - {JSON.stringify(ke)} : {JSON.stringify(ele[ke])}
                                    </div>
                                  );
                                });
                              })
                            : Object.keys(v).map((ki, m) => {
                                return (
                                  <div className="pl-2" key={`${index} + ${m}`}>
                                    - {JSON.stringify(ki)} : {JSON.stringify(v[ki])}
                                  </div>
                                );
                              })}
                        </div>
                      );
                    })}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default translate('translations')(MemoTable);
