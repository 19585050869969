import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { translate } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import TableStaffMasterHeader from './TablieStaffMasterHeader';
import TableStaffMasterList from './TableStaffMasterList';
import Footer from '../../../components/Footer';
import { STAFF_ENDPOINT } from '../../../constants/endpoints';
import { getRole } from '../../../helpers/helpers';
import PaginationCustom from '../../../components/PaginationCustom';
import BorderGroupForm from 'components/BorderGroupForm';
import './style.scss';
import HeaderMasterSetting from '../../../components/HeaderMasterSetting';
import { useSelector } from 'react-redux';
import MemoHistory from '../../../components/MemoHistory';
import { SCREEN_NAME } from '../../../helpers/constants';

const StaffMaster = () => {
  const colorScreen = useSelector((store) => store.utils.colorMe);
  const [role, setRole] = React.useState(null);
  const [listStaff, setListStaff] = useState(null);
  const [activePage, setActivePage] = useState(1);


  useEffect(() => {
    const newRole = localStorage.getItem('role');
    if (newRole) {
      setRole(getRole({ idRole: newRole }));
    }
  }, []);

  const getListStaff = async ({ page = 1, limit = 10, searchName = '', contact = '', branchId = '' }) => {
    try {
      const data = await STAFF_ENDPOINT.GET_ALL_STAFF({
        page,
        limit,
        searchName,
        contact,
        branchId
      });
      if (data?.data) {
        setListStaff(data?.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useState(() => {
    getListStaff({});
  }, []);

  useEffect(() => {
    getListStaff({ page: activePage });
  }, [activePage]);


  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  return (
    <div className="main-branch-master">
      <HeaderMasterSetting color={colorScreen?.code} colorText={colorScreen?.colorText}/>
      <BorderGroupForm
        color={colorScreen?.code}
      >
      <div className="body-wrapper mb-1">
        <TableStaffMasterHeader getListStaff={getListStaff} />
        <>
          <div align="left" style={{ fontSize: 18, fontWeight: 700, paddingLeft: 10 }}>
            検索結果：{listStaff?.count} 件
          </div>
          <TableStaffMasterList listStaff={listStaff?.result} role={role} activePage={activePage}/>
        </>
        {listStaff && listStaff?.count > 10 && (
          <PaginationCustom
            activePage={activePage}
            itemsCountPerPage={10}
            totalItemsCount={listStaff?.count}
            pageRangeDisplayed={5}
            handlePageChange={handlePageChange}
          />
        )}
      </div>
      <MemoHistory colors={colorScreen?.code} colorText={colorScreen?.colorText} screenName={SCREEN_NAME.STAFF_MASTER}/>
      <Footer color={colorScreen?.code} colorText={colorScreen?.colorText}/>
      </BorderGroupForm>
    </div>
  );
};

export default compose(translate('translations'), withRouter)(StaffMaster);
