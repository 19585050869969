import React from 'react';
import './style.scss';
import TableUpdate from './TableUpdate';
import TableInformation from './TableInformation';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

const MondifyClient = () => {
  let user_id = (process.browser && new URL(window.location.href).searchParams.get('sid')) || '';

  return (
    <div className="body-modify-client">
      {user_id === 'new' ? (
        <TableInformation />
      ) : (
        <>
          <TableInformation />
          <TableUpdate />
        </>
      )}
    </div>
  );
};

export default compose(withRouter)(MondifyClient);
