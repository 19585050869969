import React from 'react';
import { Modal } from 'reactstrap';
import 'assets/scss/page/popupConfirmCustom/style.scss';

const ModalQualification = ({
  rederBody,
  modal = false,
  toggle = () => {},
}) => {
  return (
    <Modal isOpen={modal} centered toggle={toggle}>
      <div className={`popup-confirm-custom  ${modal && 'popup-confirm-custom--show h-100'}`}>
        <div className="popup-confirm-custom__body  h-100">{rederBody()}</div>
      </div>
    </Modal>
  );
};

export default ModalQualification;
