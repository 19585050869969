import React, { useEffect, useState } from 'react';
import { ORDER_CLIENT } from '../../../constants/endpoints';
import '../style.scss';

const ShukeiTableClient = ({ getListStatistic, p }) => {
  const [dataCount, setDataCount] = useState();
  const [nameSumary , setNameSummary] = useState('');

  const getDataCount = async ({ page = 1, limit = 10 }) => {
    try {
        const data = await ORDER_CLIENT.STATIC_ORDER_CLIENT({
          page,
          limit,
        });
        if (data) {
          setDataCount(data?.data);
        }
    } catch (e) {
        console.log(e);
    }
  }
  useEffect(() => {
    getDataCount({});
  }, []);

  const onSubmit = (name) => {
    if(nameSumary !== name) {
      let dataSearch  = {
        page: 1,
        limit: 10,
        pageProduct: 1,
        limitProduct: 10,
      }
      setNameSummary(name)
      getListStatistic({ dataSearch, totalScreen: name });
    }
  };

  return (
    <>
      <div align="center" className="count_num">
        <table className="shukeiTable" style={{ tableLayout: 'fixed' }}>
          <tbody>
            <tr>
              <th colSpan="4" className="before">
                稼働前
              </th>
              <th colSpan="5" className="today">
                本日稼働
              </th>
              <th colSpan="6" className="deliver">
                稼働後
              </th>
              <th rowSpan="3" className="cansel">
                キャンセル
              </th>
            </tr>
            <tr>
              <th rowSpan="2" className="gray">稼働調整前</th>
              <th rowSpan="2" className="gray">稼働調整中</th>
              <th rowSpan="2" className="gray" colSpan="2">
                稼働待ち
              </th>
              <th rowSpan="2" className="gray">入館待ち</th>
              <th rowSpan="2" className="gray">入館中</th>
              <th rowSpan="2" className="gray">完了</th>
              <th rowSpan="2" rowSpan="2" className="gray">未完了</th>
              <th rowSpan="2" className="gray">当日CAN</th>
              <th colSpan="2" className="gray">報告書未回収</th>
              <th colSpan="2" className="gray">報告書未納品</th>
              <th colSpan="2" className="gray">報告書納品済</th>
            </tr>
            <tr>
              <th className="gray">当日</th>
              <th className="gray">過去日</th>
              <th className="gray">当日</th>
              <th className="gray">過去日</th>
              <th className="gray">当日</th>
              <th className="gray">過去日</th>
            </tr>
            <tr>
              <td className="white" value="totalReceiveInfo" onClick={() => onSubmit('totalReceiveInfo')}>
                <a href="#">{dataCount?.totalReceiveInfo?.Count}</a>
              </td>

              <td className="white" onClick={() => onSubmit('totalAppoint')}>
                <a href="#">{dataCount?.totalAppoint?.Count}</a>
              </td>

              <td className="white" onClick={() => onSubmit('totalCondition')}>
                <div align="left" style={{ fontSize: '0.8em' }}>
                  アポ中
                </div>
                <a href="#">{dataCount?.totalCondition?.Count}</a>
              </td>

              <td className="white" onClick={() => onSubmit('totalPreparationInfo')}>
                <div align="left" style={{ fontSize: '0.8em' }}>
                  稼働待ち
                </div>
                <a href="#">{dataCount?.totalPreparationInfo?.Count}</a>
              </td>

              <td className="white" onClick={() => onSubmit('totalSiteInfoStartTime')}>
                <a href="#">{dataCount?.totalSiteInfoStartTime?.Count}</a>
              </td>

              <td className="white" onClick={() => onSubmit('totalSiteInfoEndTime')}>
                <a href="#">{dataCount?.totalSiteInfoEndTime?.Count}</a>
              </td>

              <td className="white" onClick={() => onSubmit('totalSiteInfoDone')}>
                <a href="#">{dataCount?.totalSiteInfoDone?.Count}</a>
              </td>

              <td className="white" onClick={() => onSubmit('totalSiteInfoIncomplete')}>
                <a href="#">{dataCount?.totalSiteInfoIncomplete?.Count}</a>
              </td>

              <td className="white" onClick={() => onSubmit('totalSiteInfoCAN')}>
                <a href="#">{dataCount?.totalSiteInfoCAN?.Count}</a>
              </td>

              <td className="white" onClick={() => onSubmit('totalNotReportUploadInfoCondition1')}>
                <a href="#">{dataCount?.totalNotReportUploadInfoCondition1?.Count}</a>
              </td>

              <td className="white" onClick={() => onSubmit('totalNotReportUploadInfoCondition2')}>
                <a href="#">{dataCount?.totalNotReportUploadInfoCondition2?.Count}</a>
              </td>

              <td className="white" onClick={() => onSubmit('totalReportUploadInfoCondition1')}>
                <a href="#">{dataCount?.totalReportUploadInfoCondition1?.Count}</a>
              </td>

              <td className="white" onClick={() => onSubmit('totalReportUploadInfoCondition2')}>
                <a href="#">{dataCount?.totalReportUploadInfoCondition2?.Count}</a>
              </td>

              <td className="white" onClick={() => onSubmit('reportDeliveredCondition1')}>
                <a href="#">{dataCount?.reportDeliveredCondition1?.Count}</a>
              </td>

              <td className="white" onClick={() => onSubmit('reportDeliveredCondition2')}>
                <a href="#">{dataCount?.reportDeliveredCondition2?.Count}</a>
              </td>

              <td className="white" onClick={() => onSubmit('totalCancelInfo')}>
                <a href="#">{dataCount?.totalCancelInfo?.Count}</a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default ShukeiTableClient;
