import { callApi, uploadFile, downloadFile } from '../helpers/callApi';
import { DescribeInfo } from '../models/DescribeInfo';
import { SCREEN_NAME } from '../helpers/constants';

export const ORDER = {
  async GET_DISPATCH_SHIFT_APPOINT_INFO({ visitDate = '', orderId = '', organizationId = '' }) {
    const result = callApi(
      {},
      {
        endpoint: `master-setting-manager/dispatch/get-dispatch-shift-appoint-info/${orderId}?visitDate=${visitDate}&organizationId=${organizationId}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },
  async UPDATE_STATUS_BEFORE_CONFIRM_DOCUMENT({ orderId = '', body = {}, organizationId = '' }) {
    const result = callApi(
      body,
      {
        endpoint: `order-manager/order-common/update-status-prior-before-confirm/${orderId}?organizationId=${organizationId}`,
        method: 'PUT',
      }
    );
    if (result) return result;
  },
  async REQUEST_CONFIRM_RECEIVE_INFO({ orderId = '', body = {}, organizationId = '' }) {
    const result = callApi(
      body,
      {
        endpoint: `order-manager/order/request-confirm-receive-info/${orderId}?organizationId=${organizationId}`,
        method: 'PUT',
      }
    );
    if (result) return result;
  },
  async REQUEST_CONFIRM_BEFORE_CONFIRM({ orderId = '', body = {}, organizationId = '' }) {
    const result = callApi(
      body,
      {
        endpoint: `order-manager/order/request-confirm-before-confirm/${orderId}?organizationId=${organizationId}`,
        method: 'PUT',
      }
    );
    if (result) return result;
  },
  async REQUEST_CONFIRM_REPORT_UPLOAD({ orderId = '', body = {}, organizationId = '' }) {
    const result = callApi(
      body,
      {
        endpoint: `order-manager/order/request-confirm-report-upload/${orderId}?organizationId=${organizationId}`,
        method: 'PUT',
      }
    );
    if (result) return result;
  },
  async REJECT_REQUEST_BEFORE_CONFIRM({ orderId = '', body = {}, organizationId = '' }) {
    const result = callApi(
      body,
      {
        endpoint: `order-manager/order/reject-before-confirm-request/${orderId}?organizationId=${organizationId}`,
        method: 'PUT',
      }
    );
    if (result) return result;
  },
  async REJECT_REQUEST_REPORT_UPLOAD_INFO({ orderId = '', body = {}, organizationId = '' }) {
    const result = callApi(
      body,
      {
        endpoint: `order-manager/order/reject-report-upload-request/${orderId}?organizationId=${organizationId}`,
        method: 'PUT',
      }
    );
    if (result) return result;
  },
  async REJECT_REQUEST_RECEIVE_INFO({ orderId = '', body = {}, organizationId = '' }) {
    const result = callApi(
      body,
      {
        endpoint: `order-manager/order/reject-receive-info-request/${orderId}?organizationId=${organizationId}`,
        method: 'PUT',
      }
    );
    if (result) return result;
  },
  async UPDATE_CANCEL_ORDER_BY_WORKRER({ body = {}, id_order = '', organizationId = '' }) {
    const result = callApi(body, {
      endpoint: `order-manager/worker/cancel-order-by-worker/${id_order}?organizationId=${organizationId}`,
      method: 'PUT',
    });
    if (result) return result;
  },

  async UPDATE_ODER_BY_CSV({ body = {}, id_order = '', organizationId = '' }) {
    const result = callApi(body, {
      endpoint: `order-manager/order-common/update-order-by-csv/${id_order}?organizationId=${organizationId}`,
      method: 'PUT',
    });
    if (result) return result;
  },

  async GET_ORDER_RECREATE({ body = {}, orderId = '' }) {
    const result = callApi(body, {
      endpoint: `order-manager/order/detail-renew/${orderId}`,
      method: 'GET',
    });
    if (result) return result;
  },

  async ORDER_RECREATE({ body = {} }) {
    const result = callApi(body, {
      endpoint: 'order-manager/order/create-renew-order',
      method: 'POST',
    });
    if (result) return result;
  },

  async GET_LIST_RECEIVE_ALL({
    page = 1,
    limit = 10,
    pageProduct = 1,
    limitProduct = 10,
    sortBy = '',
    sortProductId = '',
    sortWith = '',
    productName = '',
    caseName = '',
    managementCode = '',
    serviceName = '',
    productId = '',
    requestedDateTo = '',
    requestedDateFrom = '',
    operationAdjustmentDateTo = '',
    operationAdjustmentDateFrom = '',
    applicantCompanyName = '',
    resultsPreparation = '',
    visitDateTo = '',
    visitDateFrom = '',
    visitedBaseName = '',
    prefecturesVisited = '',
    partnerName = '',
    installationSiteName = '',
    timeAddPartnerFrom = '',
    timeAddPartnerTo = '',
    operationAdjustmentInfo = '',
    checkVisitDate = '',
    checkTimeAddPartner = '',
  }) {
    const result = callApi(
      {},
      {
        endpoint: `order-manager/order/get-receive-all?page=${page}&limit=${limit}&pageProduct=${pageProduct}&checkVisitDate=${checkVisitDate}&checkTimeAddPartner=${checkTimeAddPartner}&operationAdjustmentInfo=${operationAdjustmentInfo}&installationSiteName=${installationSiteName}&timeAddPartnerFrom=${timeAddPartnerFrom}&timeAddPartnerTo=${timeAddPartnerTo}&prefecturesVisited=${prefecturesVisited}&limitProduct=${limitProduct}&visitedBaseName=${visitedBaseName}&partnerName=${partnerName}&caseName=${caseName}&productName=${productName}&managementCode=${managementCode}&serviceName=${serviceName}&requestedDateTo=${requestedDateTo}&visitDateTo=${visitDateTo}&visitDateFrom=${visitDateFrom}&requestedDateFrom=${requestedDateFrom}&operationAdjustmentDateTo=${operationAdjustmentDateTo}&operationAdjustmentDateFrom=${operationAdjustmentDateFrom}&applicantCompanyName=${applicantCompanyName}&resultsPreparation=${resultsPreparation}&productId=${productId}&sortBy=${sortBy}&sortWith=${sortWith}&sortProductId=${sortProductId}
        `,
        method: 'GET',
      }
    );
    if (result) return result;
  },

  async GET_ORDERS_ACCEPTANCE({
    page = 1,
    limit = 10,
    pageProduct = 1,
    limitProduct = 10,
    sortBy = '',
    sortProductId = '',
    sortWith = '',
    productName = '',
    caseName = '',
    managementCode = '',
    serviceName = '',
    productId = '',
    requestedDateTo = '',
    requestedDateFrom = '',
    operationAdjustmentDateTo = '',
    operationAdjustmentDateFrom = '',
    applicantCompanyName = '',
    resultsPreparation = '',
    visitDateTo = '',
    visitDateFrom = '',
    visitedBaseName = '',
    partnerName = '',
    process = '',
  }) {
    const result = callApi(
      {},
      {
        endpoint: `order-manager/order/get-orders-acceptance-confirmation?page=${page}&limit=${limit}&pageProduct=${pageProduct}&limitProduct=${limitProduct}&visitedBaseName=${visitedBaseName}&partnerName=${partnerName}&caseName=${caseName}&productName=${productName}&managementCode=${managementCode}&serviceName=${serviceName}&requestedDateTo=${requestedDateTo}&visitDateTo=${visitDateTo}&visitDateFrom=${visitDateFrom}&requestedDateFrom=${requestedDateFrom}&operationAdjustmentDateTo=${operationAdjustmentDateTo}&operationAdjustmentDateFrom=${operationAdjustmentDateFrom}&applicantCompanyName=${applicantCompanyName}&resultsPreparation=${resultsPreparation}&productId=${productId}&sortBy=${sortBy}&sortWith=${sortWith}&sortProductId=${sortProductId}&process=${process}
        `,
        method: 'GET',
      }
    );
    if (result) return result;
  },

  async UPDATE_ACCEPTANCE({ body = {}, orderId = '' }) {
    const result = callApi(body, {
      endpoint: `order-manager/order/update-acceptance-confirmation/${orderId}`,
      method: 'PUT',
    });
    if (result) return result;
  },

  async UPDATE_RECEIVE_ALL({ body = {}, orderId = '' }) {
    const result = callApi(body, {
      endpoint: `order-manager/order/update-receive-all/${orderId}`,
      method: 'PUT',
    });
    if (result) return result;
  },
  async UPDATE_STATUS_ALL({ body = {}, orderId = '' }) {
    const result = callApi(body, {
      endpoint: `order-manager/order/update-approval-request/${orderId}`,
      method: 'PUT',
    });
    if (result) return result;
  },

  async UPLOAD_FILIE_RECEVICE_ALL({ body = {}, order_id = '', organizationId = '' }) {
    const result = callApi(body, {
      endpoint: `order-manager/order/upload-file-receive-all/${order_id}?organizationId=${organizationId}`,
      method: 'PUT',
    });
    if (result) return result;
  },

  async REMOVE_FILE_ADMISSION({ body, id = '' }) {
    const result = callApi(body, { endpoint: `order-manager/order/remove-file-admission/${id}`, method: 'PUT' });
    if (result) return result;
  },

  async GET_LIST_ORDER({ router = '', page = '1', limit = '10', idRole = '' }) {
    const link_query = `list/${router}?page=${page}&limit=${limit}&idRole=${idRole}`;
    const result = callApi({}, { endpoint: link_query, method: 'GET' });
    if (result) return result;
  },
  async GET_DEFAULT_CSV({ productId = '' }) {
    const result = callApi({}, { endpoint: `order-manager/order/default-csv-order/${productId}`, method: 'GET' });
    if (result) return result;
  },
  async IMPORT_CSV(body) {
    const result = uploadFile(body, { endpoint: 'order-manager/order/import-csv', method: 'POST' });
    if (result) return result;
  },
  async GET_ORDER_DETAIL({ orderId = '', getAll = false, screenName = '' }) {
    const result = await callApi(
      {},
      {
        endpoint: `order-manager/order/get-detail?orderId=${orderId}&screenName=${screenName}`,
        method: 'GET',
      }
    );
    if (result && getAll) return result;
    if (result) return new DescribeInfo(result.data);
  },
  async GET_ORDER_DETAIL_APPOINT_INFO({ orderId = '', screenName = '', organizationId = '' }) {
    const result = await callApi(
      {},
      {
        endpoint: `order-manager/order/get-detail?orderId=${orderId}&screenName=${screenName}&organizationId=${organizationId}`,
        method: 'GET',
      }
    );
    if (result) return result?.data;
  },
  async UPDATE_ORDER_INFO({ body = {}, id_order = '', organizationId = '' }) {
    const result = callApi(body, {
      endpoint: `order-manager/order/update/${id_order}?organizationId=${organizationId}`,
      method: 'PUT',
    });
    if (result) return result;
  },
  async UPDATE_ORDER_STATUS({ id_order = '', body = {}, organizationId = '' }) {
    const result = callApi(
      body,
      {
        endpoint: `order-manager/order/update-status/${id_order}?organizationId=${organizationId}`,
        method: 'PUT',
      }
    );
    if (result) return result;
  },
  async GET_LIST_ORDER_DESCRIBE({
    page = 1,
    limit = 10,
    pageProduct = 1,
    limitProduct = 10,
    sortBy = '',
    sortProductId = '',
    sortWith = '',
    productName = '',
    caseName = '',
    managementCode = '',
    serviceName = '',
    productId = '',
    requestedDateTo = '',
    requestedDateFrom = '',
    operationAdjustmentDateTo = '',
    operationAdjustmentDateFrom = '',
    applicantCompanyName = '',
    resultsPreparation = '',
    visitDateTo = '',
    visitDateFrom = '',
    visitedBaseName = '',
    prefecturesVisited = '',
    partnerName = '',
    installationSiteName = '',
    timeAddPartnerFrom = '',
    timeAddPartnerTo = '',
    operationAdjustmentInfo = '',
    checkVisitDate = '',
    checkTimeAddPartner = '',
  }) {
    const result = callApi(
      {},
      {
        endpoint: `order-manager/order/get-list-receive-info?page=${page}&limit=${limit}&pageProduct=${pageProduct}&limitProduct=${limitProduct}&checkVisitDate=${checkVisitDate}&checkTimeAddPartner=${checkTimeAddPartner}&operationAdjustmentInfo=${operationAdjustmentInfo}&installationSiteName=${installationSiteName}&timeAddPartnerFrom=${timeAddPartnerFrom}&timeAddPartnerTo=${timeAddPartnerTo}&prefecturesVisited=${prefecturesVisited}&visitedBaseName=${visitedBaseName}&partnerName=${partnerName}&caseName=${caseName}&productName=${productName}&managementCode=${managementCode}&serviceName=${serviceName}&requestedDateTo=${requestedDateTo}&visitDateTo=${visitDateTo}&visitDateFrom=${visitDateFrom}&requestedDateFrom=${requestedDateFrom}&operationAdjustmentDateTo=${operationAdjustmentDateTo}&operationAdjustmentDateFrom=${operationAdjustmentDateFrom}&applicantCompanyName=${applicantCompanyName}&resultsPreparation=${resultsPreparation}&productId=${productId}&sortBy=${sortBy}&sortWith=${sortWith}&sortProductId=${sortProductId}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },
  async GET_LIST_ALL_ORDER_DESCRIBE({ page = 1, limit = 10 }) {
    const result = callApi(
      {},
      {
        endpoint: `order-manager/order/get-list-all-receive-info?page=${page}&limit=${limit}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },
  async GET_LIST_ALL_ORDER_MY_PAGE({ page = 1, limit = 10 }) {
    const result = callApi(
      {},
      {
        endpoint: `order-manager/my-page/list-order?page=${page}&limit=${limit}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },

  async GET_LIST_ORDER_CONFIRM_FUNCTION({ page = 1, limit = 10 }) {
    const result = callApi(
      {},
      {
        endpoint: `order-manager/confirm-function/get-list-order?page=${page}&limit=${limit}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },

  async GET_TOTAL_CONFIRM({ body = {} }) {
    const result = callApi(body, { endpoint: `order-manager/confirm-function/get-total-order`, method: 'GET' });
    if (result) return result;
  },

  async GET_LIST_ORDER_TOMORROW({
    page = 1,
    limit = 10,
    pageProduct = 1,
    limitProduct = 10,
    sortBy = '',
    sortProductId = '',
    sortWith = '',
    productName = '',
    caseName = '',
    managementCode = '',
    serviceName = '',
    productId = '',
    requestedDateTo = '',
    requestedDateFrom = '',
    operationAdjustmentDateTo = '',
    operationAdjustmentDateFrom = '',
    applicantCompanyName = '',
    resultsPreparation = '',
    visitDateTo = '',
    visitDateFrom = '',
    visitedBaseName = '',
    partnerName = '',
    prefecturesVisited = '',
    installationSiteName = '',
    timeAddPartnerFrom = '',
    timeAddPartnerTo = '',
    operationAdjustmentInfo = '',
    checkVisitDate = '',
    checkTimeAddPartner = '',
  }) {
    const result = callApi(
      {},
      {
        endpoint: `order-manager/order/get-list-order-tomorrow?page=${page}&limit=${limit}&pageProduct=${pageProduct}&limitProduct=${limitProduct}&checkVisitDate=${checkVisitDate}&checkTimeAddPartner=${checkTimeAddPartner}&operationAdjustmentInfo=${operationAdjustmentInfo}&installationSiteName=${installationSiteName}&timeAddPartnerFrom=${timeAddPartnerFrom}&timeAddPartnerTo=${timeAddPartnerTo}&prefecturesVisited=${prefecturesVisited}&visitedBaseName=${visitedBaseName}&partnerName=${partnerName}&caseName=${caseName}&productName=${productName}&managementCode=${managementCode}&serviceName=${serviceName}&requestedDateTo=${requestedDateTo}&visitDateTo=${visitDateTo}&visitDateFrom=${visitDateFrom}&requestedDateFrom=${requestedDateFrom}&operationAdjustmentDateTo=${operationAdjustmentDateTo}&operationAdjustmentDateFrom=${operationAdjustmentDateFrom}&applicantCompanyName=${applicantCompanyName}&resultsPreparation=${resultsPreparation}&productId=${productId}&sortBy=${sortBy}&sortWith=${sortWith}&sortProductId=${sortProductId}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },

  async GET_LIST_STATISTIC({
    page = 1,
    limit = 10,
    pageProduct = 1,
    limitProduct = 10,
    productName = '',
    caseName = '',
    managementCode = '',
    serviceName = '',
    productId = '',
    totalScreen = '',
  }) {
    const result = callApi(
      {},
      {
        endpoint: `order-manager/order/orders-statistic?page=${page}&limit=${limit}&pageProduct=${pageProduct}&limitProduct=${limitProduct}&caseName=${caseName}&productName=${productName}&managementCode=${managementCode}&serviceName=${serviceName}&productId=${productId}&totalScreen=${totalScreen}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },

  async GET_LIST_ORDER_APPOINT_INFO({
    page = 1,
    limit = 10,
    pageProduct = 1,
    limitProduct = 10,
    sortBy = '',
    sortProductId = '',
    sortWith = '',
    productName = '',
    caseName = '',
    managementCode = '',
    serviceName = '',
    productId = '',
    requestedDateTo = '',
    requestedDateFrom = '',
    operationAdjustmentDateTo = '',
    operationAdjustmentDateFrom = '',
    applicantCompanyName = '',
    resultsPreparation = '',
    visitDateTo = '',
    visitDateFrom = '',
    visitedBaseName = '',
    partnerName = '',
    prefecturesVisited = '',
    installationSiteName = '',
    timeAddPartnerFrom = '',
    timeAddPartnerTo = '',
    operationAdjustmentInfo = '',
    checkVisitDate = '',
    checkTimeAddPartner = '',
  }) {
    const result = callApi(
      {},
      {
        endpoint: `order-manager/order/get-list-appoint-info?page=${page}&limit=${limit}&pageProduct=${pageProduct}&limitProduct=${limitProduct}&checkVisitDate=${checkVisitDate}&checkTimeAddPartner=${checkTimeAddPartner}&operationAdjustmentInfo=${operationAdjustmentInfo}&installationSiteName=${installationSiteName}&timeAddPartnerFrom=${timeAddPartnerFrom}&timeAddPartnerTo=${timeAddPartnerTo}&prefecturesVisited=${prefecturesVisited}&visitedBaseName=${visitedBaseName}&partnerName=${partnerName}&caseName=${caseName}&productName=${productName}&managementCode=${managementCode}&serviceName=${serviceName}&requestedDateTo=${requestedDateTo}&visitDateTo=${visitDateTo}&visitDateFrom=${visitDateFrom}&requestedDateFrom=${requestedDateFrom}&operationAdjustmentDateTo=${operationAdjustmentDateTo}&operationAdjustmentDateFrom=${operationAdjustmentDateFrom}&applicantCompanyName=${applicantCompanyName}&resultsPreparation=${resultsPreparation}&productId=${productId}&sortBy=${sortBy}&sortWith=${sortWith}&sortProductId=${sortProductId}
        `,
        method: 'GET',
      }
    );
    if (result) return result;
  },
  async GET_LIST_ALL_ORDER_APPOINT_INFO({ page = 1, limit = 10 }) {
    const result = callApi(
      {},
      {
        endpoint: `order-manager/order/get-list-all-appoint-info?page=${page}&limit=${limit}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },

  async GET_LIST_ORDER_CONDITION_INFO({
    page = 1,
    limit = 10,
    pageProduct = 1,
    limitProduct = 10,
    sortBy = '',
    sortProductId = '',
    sortWith = '',
    productName = '',
    caseName = '',
    managementCode = '',
    serviceName = '',
    productId = '',
    requestedDateTo = '',
    requestedDateFrom = '',
    operationAdjustmentDateTo = '',
    operationAdjustmentDateFrom = '',
    applicantCompanyName = '',
    resultsPreparation = '',
    visitDateTo = '',
    visitDateFrom = '',
    visitedBaseName = '',
    partnerName = '',
    prefecturesVisited = '',
    visitDate = '',
    staffLoginId = '',
    installationSiteName = '',
    timeAddPartnerFrom = '',
    timeAddPartnerTo = '',
    operationAdjustmentInfo = '',
    checkVisitDate = '',
    checkTimeAddPartner = '',
  }) {
    const params = {
      page,
      limit,
      pageProduct,
      limitProduct,
      installationSiteName,
      timeAddPartnerFrom,
      timeAddPartnerTo,
      operationAdjustmentInfo,
      sortBy,
      sortProductId,
      sortWith,
      productName,
      caseName,
      managementCode,
      serviceName,
      productId,
      requestedDateTo,
      requestedDateFrom,
      operationAdjustmentDateTo,
      operationAdjustmentDateFrom,
      applicantCompanyName,
      resultsPreparation,
      visitDateTo,
      visitDateFrom,
      visitedBaseName,
      partnerName,
      prefecturesVisited,
      visitDate,
      staffLoginId,
      checkVisitDate,
      checkTimeAddPartner,
    };

    const queryStringParams = Object.keys(params).reduce((output, key) => {
      const value = params[key];
      if (Array.isArray(value)) {
        return `${output}&${key}=${JSON.stringify(value)}`;
      }

      return `${output}&${key}=${value}`;
    }, '');

    const result = callApi(
      {},
      {
        endpoint: `order-manager/order/get-list-condition-info?${queryStringParams}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },

  async GET_LIST_ORDER_DISPATCH_MAP({
    page = 1,
    limit = 10,
    pageProduct = 1,
    limitProduct = 10,
    sortBy = '',
    sortProductId = '',
    sortWith = '',
    productName = '',
    caseName = '',
    managementCode = '',
    serviceName = '',
    productId = '',
    requestedDateTo = '',
    requestedDateFrom = '',
    operationAdjustmentDateTo = '',
    operationAdjustmentDateFrom = '',
    applicantCompanyName = '',
    resultsPreparation = '',
    visitDateTo = '',
    visitDateFrom = '',
    visitedBaseName = '',
    partnerName = '',
    prefecturesVisited = '',
    visitDate = '',
    staffLoginId = '',
  }) {
    const params = {
      page,
      limit,
      pageProduct,
      limitProduct,
      sortBy,
      sortProductId,
      sortWith,
      caseName,
      productName,
      managementCode,
      serviceName,
      productId,
      requestedDateTo,
      requestedDateFrom,
      operationAdjustmentDateTo,
      operationAdjustmentDateFrom,
      applicantCompanyName,
      resultsPreparation,
      visitDateTo,
      visitDateFrom,
      visitedBaseName,
      partnerName,
      prefecturesVisited,
      visitDate,
      staffLoginId,
    };

    const queryStringParams = Object.keys(params).reduce((output, key) => {
      const value = params[key];
      if (Array.isArray(value)) {
        return `${output}&${key}=${JSON.stringify(value)}`;
      }

      return `${output}&${key}=${value}`;
    }, '');

    const result = callApi(
      {},
      {
        endpoint: `master-setting-manager/dispatch/list-order-in-map?${queryStringParams}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },

  async GET_LIST_ALL_ORDER_CONDITION_INFO({ page = 1, limit = 10 }) {
    const result = callApi(
      {},
      {
        endpoint: `order-manager/order/get-list-all-condition-info?page=${page}&limit=${limit}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },

  async GET_LIST_REPORT_UPLOAD_INFO({
    page = 1,
    limit = 10,
    pageProduct = 1,
    limitProduct = 10,
    sortBy = '',
    sortProductId = '',
    sortWith = '',
    productName = '',
    caseName = '',
    managementCode = '',
    serviceName = '',
    productId = '',
    requestedDateTo = '',
    requestedDateFrom = '',
    operationAdjustmentDateTo = '',
    operationAdjustmentDateFrom = '',
    applicantCompanyName = '',
    resultsPreparation = '',
    visitDateTo = '',
    visitDateFrom = '',
    visitedBaseName = '',
    partnerName = '',
    prefecturesVisited = '',
    installationSiteName = '',
    timeAddPartnerFrom = '',
    timeAddPartnerTo = '',
    operationAdjustmentInfo = '',
    checkVisitDate = '',
    checkTimeAddPartner = '',
  }) {
    const result = callApi(
      {},
      {
        endpoint: `order-manager/order/get-report-upload?page=${page}&limit=${limit}&pageProduct=${pageProduct}&limitProduct=${limitProduct}&checkVisitDate=${checkVisitDate}&checkTimeAddPartner=${checkTimeAddPartner}&operationAdjustmentInfo=${operationAdjustmentInfo}&installationSiteName=${installationSiteName}&timeAddPartnerFrom=${timeAddPartnerFrom}&timeAddPartnerTo=${timeAddPartnerTo}&prefecturesVisited=${prefecturesVisited}&visitedBaseName=${visitedBaseName}&partnerName=${partnerName}&caseName=${caseName}&productName=${productName}&managementCode=${managementCode}&serviceName=${serviceName}&requestedDateTo=${requestedDateTo}&visitDateTo=${visitDateTo}&visitDateFrom=${visitDateFrom}&requestedDateFrom=${requestedDateFrom}&operationAdjustmentDateTo=${operationAdjustmentDateTo}&operationAdjustmentDateFrom=${operationAdjustmentDateFrom}&applicantCompanyName=${applicantCompanyName}&resultsPreparation=${resultsPreparation}&productId=${productId}&sortBy=${sortBy}&sortWith=${sortWith}&sortProductId=${sortProductId}
        `,
        method: 'GET',
      }
    );
    if (result) return result;
  },

  async UPDATE_DEFECTIVE_RETURN({ body = {}, id_order = '', organizationId = '' }) {
    const result = callApi(body, {
      endpoint: `order-manager/order/defective-return/${id_order}?organizationId=${organizationId}`,
      method: 'PUT',
    });
    if (result) return result;
  },

  async MONITORING_REJECT_REPORT({ body = {}, orderId = '' }) {
    const result = callApi(body, {
      endpoint: `order-manager/order-client/defective-return-monitoring/${orderId}`,
      method: 'PUT',
    });
    if (result) return result;
  },

  async UPDATE_CONDITION_INFO({ body = {}, id_order = '', organizationId = '' }) {
    const result = callApi(body, {
      endpoint: `order-manager/order/update-condition-info/${id_order}?organizationId=${organizationId}`,
      method: 'PUT',
    });
    if (result) return result;
  },

  async GET_LIST_SITE_INFO_ORDER({
    page = 1,
    limit = 10,
    pageProduct = 1,
    limitProduct = 10,
    sortBy = '',
    sortProductId = '',
    sortWith = '',
    productName = '',
    caseName = '',
    managementCode = '',
    serviceName = '',
    productId = '',
    requestedDateTo = '',
    requestedDateFrom = '',
    operationAdjustmentDateTo = '',
    operationAdjustmentDateFrom = '',
    applicantCompanyName = '',
    resultsPreparation = '',
    visitDateTo = '',
    visitDateFrom = '',
    visitedBaseName = '',
    partnerName = '',
    prefecturesVisited = '',
    installationSiteName = '',
    timeAddPartnerFrom = '',
    timeAddPartnerTo = '',
    operationAdjustmentInfo = '',
    checkVisitDate = '',
    checkTimeAddPartner = '',
  }) {
    const result = callApi(
      {},
      {
        endpoint: `order-manager/order/get-list-site-info?page=${page}&limit=${limit}&pageProduct=${pageProduct}&limitProduct=${limitProduct}&checkVisitDate=${checkVisitDate}&checkTimeAddPartner=${checkTimeAddPartner}&operationAdjustmentInfo=${operationAdjustmentInfo}&installationSiteName=${installationSiteName}&timeAddPartnerFrom=${timeAddPartnerFrom}&timeAddPartnerTo=${timeAddPartnerTo}&prefecturesVisited=${prefecturesVisited}&visitedBaseName=${visitedBaseName}&partnerName=${partnerName}&caseName=${caseName}&productName=${productName}&managementCode=${managementCode}&serviceName=${serviceName}&requestedDateTo=${requestedDateTo}&visitDateTo=${visitDateTo}&visitDateFrom=${visitDateFrom}&requestedDateFrom=${requestedDateFrom}&operationAdjustmentDateTo=${operationAdjustmentDateTo}&operationAdjustmentDateFrom=${operationAdjustmentDateFrom}&applicantCompanyName=${applicantCompanyName}&resultsPreparation=${resultsPreparation}&productId=${productId}&sortBy=${sortBy}&sortWith=${sortWith}&sortProductId=${sortProductId}
        `,
        method: 'GET',
      }
    );
    if (result) return result;
  },

  async GET_LIST_PREPARATION_INFO({
    page = 1,
    limit = 10,
    pageProduct = 1,
    limitProduct = 10,
    sortBy = '',
    sortProductId = '',
    sortWith = '',
    productName = '',
    caseName = '',
    managementCode = '',
    serviceName = '',
    productId = '',
    requestedDateTo = '',
    requestedDateFrom = '',
    operationAdjustmentDateTo = '',
    operationAdjustmentDateFrom = '',
    applicantCompanyName = '',
    resultsPreparation = '',
    visitDateTo = '',
    visitDateFrom = '',
    visitedBaseName = '',
    partnerName = '',
    prefecturesVisited = '',
    installationSiteName = '',
    timeAddPartnerFrom = '',
    timeAddPartnerTo = '',
    operationAdjustmentInfo = '',
    checkVisitDate = '',
    checkTimeAddPartner = '',
  }) {
    const result = callApi(
      {},
      {
        endpoint: `order-manager/order/get-list-preparation-info?page=${page}&limit=${limit}&pageProduct=${pageProduct}&limitProduct=${limitProduct}&checkVisitDate=${checkVisitDate}&checkTimeAddPartner=${checkTimeAddPartner}&operationAdjustmentInfo=${operationAdjustmentInfo}&installationSiteName=${installationSiteName}&timeAddPartnerFrom=${timeAddPartnerFrom}&timeAddPartnerTo=${timeAddPartnerTo}&prefecturesVisited=${prefecturesVisited}&visitedBaseName=${visitedBaseName}&partnerName=${partnerName}&caseName=${caseName}&productName=${productName}&managementCode=${managementCode}&serviceName=${serviceName}&requestedDateTo=${requestedDateTo}&visitDateTo=${visitDateTo}&visitDateFrom=${visitDateFrom}&requestedDateFrom=${requestedDateFrom}&operationAdjustmentDateTo=${operationAdjustmentDateTo}&operationAdjustmentDateFrom=${operationAdjustmentDateFrom}&applicantCompanyName=${applicantCompanyName}&resultsPreparation=${resultsPreparation}&productId=${productId}&sortBy=${sortBy}&sortWith=${sortWith}&sortProductId=${sortProductId}
        `,
        method: 'GET',
      }
    );
    if (result) return result;
  },

  async GET_LIST_ALL_PREPARATION_INFO({ page = 1, limit = 10 }) {
    const result = callApi(
      {},
      {
        endpoint: `order-manager/order/get-list-all-preparation-info?page=${page}&limit=${limit}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },

  async GET_LIST_DELIVERY_INFO({
    page = 1,
    limit = 10,
    pageProduct = 1,
    limitProduct = 10,
    sortBy = '',
    sortProductId = '',
    sortWith = '',
    productName = '',
    caseName = '',
    managementCode = '',
    serviceName = '',
    productId = '',
    requestedDateTo = '',
    requestedDateFrom = '',
    operationAdjustmentDateTo = '',
    operationAdjustmentDateFrom = '',
    applicantCompanyName = '',
    resultsPreparation = '',
    visitDateTo = '',
    visitDateFrom = '',
    visitedBaseName = '',
    partnerName = '',
    prefecturesVisited = '',
    installationSiteName = '',
    timeAddPartnerFrom = '',
    timeAddPartnerTo = '',
    operationAdjustmentInfo = '',
    checkVisitDate = '',
    checkTimeAddPartner = '',
  }) {
    const result = callApi(
      {},
      {
        endpoint: `order-manager/order/get-delivery-info?page=${page}&limit=${limit}&pageProduct=${pageProduct}&limitProduct=${limitProduct}&checkVisitDate=${checkVisitDate}&checkTimeAddPartner=${checkTimeAddPartner}&operationAdjustmentInfo=${operationAdjustmentInfo}&installationSiteName=${installationSiteName}&timeAddPartnerFrom=${timeAddPartnerFrom}&timeAddPartnerTo=${timeAddPartnerTo}&prefecturesVisited=${prefecturesVisited}&visitedBaseName=${visitedBaseName}&partnerName=${partnerName}&caseName=${caseName}&productName=${productName}&managementCode=${managementCode}&serviceName=${serviceName}&requestedDateTo=${requestedDateTo}&visitDateTo=${visitDateTo}&visitDateFrom=${visitDateFrom}&requestedDateFrom=${requestedDateFrom}&operationAdjustmentDateTo=${operationAdjustmentDateTo}&operationAdjustmentDateFrom=${operationAdjustmentDateFrom}&applicantCompanyName=${applicantCompanyName}&resultsPreparation=${resultsPreparation}&productId=${productId}&sortBy=${sortBy}&sortWith=${sortWith}&sortProductId=${sortProductId}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },

  async UPDATE_WORKER_NAME({ body = {}, id_order = '', organizationId = '' }) {
    const result = callApi(body, {
      endpoint: `order-manager/order/update-worker-name/${id_order}?organizationId=${organizationId}`,
      method: 'PUT',
    });
    if (result) return result;
  },

  async GET_ALL_PARTNER({ page = '0', limit = '10', name = '', orderId = '', organizationId = '' }) {
    const result = callApi(
      {},
      {
        endpoint: `order-manager/partner/get-all-partner?orderId=${orderId}&page=${page}&limit=${limit}&name=${name}&organizationId=${organizationId}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },

  async CONFIRM_CONDITION_INFO({ id_order = '', screenName = '', statusConditionInfo, partnerId, partnerName , companyName , updateTitle, organizationId = '' }) {
    const result = callApi(
      { screenName, statusConditionInfo, partnerId, partnerName, companyName, updateTitle },
      {
        endpoint: `order-manager/order/confirm-condition-info/${id_order}?organizationId=${organizationId}`,
        method: 'PUT',
      }
    );
    if (result) return result;
  },

  async UPDATE_SITE_INFO({ body = {}, id_order = '', organizationId = '' }) {
    const result = callApi(body, {
      endpoint: `order-manager/order/update-site-info/${id_order}?organizationId=${organizationId}`,
      method: 'PUT',
    });
    if (result) return result;
  },

  async GET_STATUS_ORDER({ serviceId = null, productId = null, orderId = null, type = 'cancelSetting', organizationId = '' }) {
    const result = callApi(
      {},
      {
        endpoint: `order-manager/order/screen-draft-setting?serviceId=${serviceId}&productId=${productId}&orderId=${orderId}&type=${type}&organizationId=${organizationId}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },

  async GET_LIST_CANCEL_INFO({
    page = 1,
    limit = 10,
    pageProduct = 1,
    limitProduct = 10,
    productName = '',
    caseName = '',
    managementCode = '',
    serviceName = '',
    productId = '',
    requestedDateTo = '',
    requestedDateFrom = '',
    operationAdjustmentDateTo = '',
    operationAdjustmentDateFrom = '',
    applicantCompanyName = '',
    resultsPreparation = '',
    visitDateTo = '',
    visitDateFrom = '',
    visitedBaseName = '',
    partnerName = '',
    checkVisitDate = '',
    checkTimeAddPartner = '',
  }) {
    const result = callApi(
      {},
      {
        endpoint: `order-manager/order/get-cancel-info?page=${page}&limit=${limit}&pageProduct=${pageProduct}&limitProduct=${limitProduct}&checkVisitDate=${checkVisitDate}&checkTimeAddPartner=${checkTimeAddPartner}&visitedBaseName=${visitedBaseName}&partnerName=${partnerName}&caseName=${caseName}&productName=${productName}&managementCode=${managementCode}&serviceName=${serviceName}&requestedDateTo=${requestedDateTo}&visitDateTo=${visitDateTo}&visitDateFrom=${visitDateFrom}&requestedDateFrom=${requestedDateFrom}&operationAdjustmentDateTo=${operationAdjustmentDateTo}&operationAdjustmentDateFrom=${operationAdjustmentDateFrom}&applicantCompanyName=${applicantCompanyName}&resultsPreparation=${resultsPreparation}&productId=${productId}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },

  async GET_LIST_SEARCH_CANCEL_INFO({
    page = 1,
    limit = 10,
    pageProduct = 1,
    limitProduct = 10,
    productName = '',
    caseName = '',
    managementCode = '',
    serviceName = '',
    productId = '',
    requestedDateTo = '',
    requestedDateFrom = '',
    operationAdjustmentDateTo = '',
    operationAdjustmentDateFrom = '',
    applicantCompanyName = '',
    resultsPreparation = '',
    visitDateTo = '',
    visitDateFrom = '',
    visitedBaseName = '',
    partnerName = '',
    installationSiteName = '',
    timeAddPartnerFrom = '',
    timeAddPartnerTo = '',
    operationAdjustmentInfo = '',
    checkVisitDate = '',
    checkTimeAddPartner = '',
  }) {
    const result = callApi(
      {},
      {
        endpoint: `order-manager/order/get-cancel-info?page=${page}&limit=${limit}&pageProduct=${pageProduct}&limitProduct=${limitProduct}&checkVisitDate=${checkVisitDate}&checkTimeAddPartner=${checkTimeAddPartner}&operationAdjustmentInfo=${operationAdjustmentInfo}&installationSiteName=${installationSiteName}&timeAddPartnerFrom=${timeAddPartnerFrom}&timeAddPartnerTo=${timeAddPartnerTo}&visitedBaseName=${visitedBaseName}&partnerName=${partnerName}&caseName=${caseName}&productName=${productName}&managementCode=${managementCode}&serviceName=${serviceName}&requestedDateTo=${requestedDateTo}&visitDateTo=${visitDateTo}&visitDateFrom=${visitDateFrom}&requestedDateFrom=${requestedDateFrom}&operationAdjustmentDateTo=${operationAdjustmentDateTo}&operationAdjustmentDateFrom=${operationAdjustmentDateFrom}&applicantCompanyName=${applicantCompanyName}&resultsPreparation=${resultsPreparation}&productId=${productId}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },

  async GET_LIST_RENEW({
    page = 1,
    limit = 10,
    pageProduct = 1,
    limitProduct = 10,
    productName = '',
    caseName = '',
    managementCode = '',
    serviceName = '',
    productId = '',
    requestedDateTo = '',
    requestedDateFrom = '',
    operationAdjustmentDateTo = '',
    operationAdjustmentDateFrom = '',
    applicantCompanyName = '',
    resultsPreparation = '',
    visitDateTo = '',
    visitDateFrom = '',
    visitedBaseName = '',
    partnerName = '',
  }) {
    const result = callApi(
      {},
      {
        endpoint: `order-manager/order/renew?page=${page}&limit=${limit}&pageProduct=${pageProduct}&limitProduct=${limitProduct}&visitedBaseName=${visitedBaseName}&partnerName=${partnerName}&caseName=${caseName}&productName=${productName}&managementCode=${managementCode}&serviceName=${serviceName}&requestedDateTo=${requestedDateTo}&visitDateTo=${visitDateTo}&visitDateFrom=${visitDateFrom}&requestedDateFrom=${requestedDateFrom}&operationAdjustmentDateTo=${operationAdjustmentDateTo}&operationAdjustmentDateFrom=${operationAdjustmentDateFrom}&applicantCompanyName=${applicantCompanyName}&resultsPreparation=${resultsPreparation}&productId=${productId}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },

  async GET_DETAIL_CANCEL_INFO({ orderId = '', organizationId = '', screenName = '' }) {
    const result = callApi(
      {},
      {
        endpoint: `order-manager/order/get-detail?orderId=${orderId}&organizationId=${organizationId}&screenName=${screenName}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },

  async GET_DETAIL_ACCEPTANCE({ orderId = '' }) {
    const result = callApi(
      {},
      {
        endpoint: `order-manager/acceptance-infor/detail?orderId=${orderId}`,
        method: 'GET',
      }
    );

    if (result) return result;
  },

  async SAVE_ACCEPTANCE_INFOR(data) {
    const result = callApi(data, {
      endpoint: 'order-manager/acceptance-infor/save-data',
      method: 'POST',
    });

    if (result) return result;
  },

  async GET_STATUS_BEFORE_VISIT({ orderId = '' }) {
    const result = callApi(
      {},
      { endpoint: `order-manager/order/get-status-before-visit?orderId=${orderId}`, method: 'GET' }
    );
    if (result) return result;
  },

  async UPDATE_CANCEL_INFO({ body = {}, id_order = '', organizationId = '' }) {
    const result = callApi(body, {
      endpoint: `order-manager/order/update-cancel-info/${id_order}?organizationId=${organizationId}`,
      method: 'PUT',
    });
    if (result) return result;
  },

  async UPDATE_STATUS_BEFORE_CONFIRM({ body = {}, orderId = '' }) {
    const result = callApi(body, {
      endpoint: `order-manager/order-common/update-status-before-confirm/${orderId}`,
      method: 'PUT',
    });
    if (result) return result;
  },

  async UPDATE_STATUS_BEFORE_DOCUMENT({ body = {}, orderId = '' }) {
    const result = callApi(body, {
      endpoint: `order-manager/order-common/confirm-status-before-document-setting/${orderId}`,
      method: 'PUT',
    });
    if (result) return result;
  },

  async UPDATE_ORDER_BEFORE_CONFIRM({ body = {}, orderId = '', organizationId = '' }) {
    const result = callApi(body, {
      endpoint: `order-manager/order-common/update-order-before-confirm/${orderId}?organizationId=${organizationId}`,
      method: 'PUT',
    });
    if (result) return result;
  },
  async UPLOAD_FILE_BEFORE_CONFIRM({ body = {}, orderId = '', organizationId = '' }) {
    const result = callApi(body, {
      endpoint: `order-manager/order-common/upload-file/${orderId}?organizationId=${organizationId}`,
      method: 'PUT',
    });
    if (result) return result;
  },

  async GET_LIST_CANCEL_MENU({
    page = 1,
    limit = 10,
    pageProduct = 1,
    limitProduct = 10,
    productName = '',
    caseName = '',
    managementCode = '',
    serviceName = '',
    productId = '',
    requestedDateTo = '',
    requestedDateFrom = '',
    operationAdjustmentDateTo = '',
    operationAdjustmentDateFrom = '',
    applicantCompanyName = '',
    resultsPreparation = '',
    visitDateTo = '',
    visitDateFrom = '',
    visitedBaseName = '',
    partnerName = '',
    installationSiteName = '',
    timeAddPartnerFrom = '',
    timeAddPartnerTo = '',
    operationAdjustmentInfo = '',
    checkVisitDate = '',
    checkTimeAddPartner = '',
  }) {
    const result = callApi(
      {},
      {
        endpoint: `order-manager/order/get-cancel-menu?page=${page}&limit=${limit}&pageProduct=${pageProduct}&limitProduct=${limitProduct}&checkVisitDate=${checkVisitDate}&checkTimeAddPartner=${checkTimeAddPartner}&operationAdjustmentInfo=${operationAdjustmentInfo}&installationSiteName=${installationSiteName}&timeAddPartnerFrom=${timeAddPartnerFrom}&timeAddPartnerTo=${timeAddPartnerTo}&visitedBaseName=${visitedBaseName}&partnerName=${partnerName}&caseName=${caseName}&productName=${productName}&managementCode=${managementCode}&serviceName=${serviceName}&requestedDateTo=${requestedDateTo}&visitDateTo=${visitDateTo}&visitDateFrom=${visitDateFrom}&requestedDateFrom=${requestedDateFrom}&operationAdjustmentDateTo=${operationAdjustmentDateTo}&operationAdjustmentDateFrom=${operationAdjustmentDateFrom}&applicantCompanyName=${applicantCompanyName}&resultsPreparation=${resultsPreparation}&productId=${productId}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },

  async GET_LIST_SEARCH_CANCEL_MENU({
    page = 1,
    limit = 10,
    pageProduct = 1,
    limitProduct = 10,
    productName = '',
    caseName = '',
    managementCode = '',
    serviceName = '',
    productId = '',
    requestedDateTo = '',
    requestedDateFrom = '',
    operationAdjustmentDateTo = '',
    operationAdjustmentDateFrom = '',
    applicantCompanyName = '',
    resultsPreparation = '',
    visitDateTo = '',
    visitDateFrom = '',
    visitedBaseName = '',
    partnerName = '',
    prefecturesVisited = '',
    sortBy = '',
    sortWith = '',
    sortProductId = '',
    installationSiteName = '',
    timeAddPartnerFrom = '',
    timeAddPartnerTo = '',
    operationAdjustmentInfo = '',
    checkVisitDate = '',
    checkTimeAddPartner = '',
  }) {
    const result = callApi(
      {},
      {
        endpoint: `order-manager/order/get-cancel-menu?page=${page}&limit=${limit}&pageProduct=${pageProduct}&limitProduct=${limitProduct}&checkVisitDate=${checkVisitDate}&checkTimeAddPartner=${checkTimeAddPartner}&operationAdjustmentInfo=${operationAdjustmentInfo}&installationSiteName=${installationSiteName}&timeAddPartnerFrom=${timeAddPartnerFrom}&timeAddPartnerTo=${timeAddPartnerTo}&visitedBaseName=${visitedBaseName}&prefecturesVisited=${prefecturesVisited}&partnerName=${partnerName}&caseName=${caseName}&productName=${productName}&managementCode=${managementCode}&serviceName=${serviceName}&requestedDateTo=${requestedDateTo}&visitDateTo=${visitDateTo}&visitDateFrom=${visitDateFrom}&requestedDateFrom=${requestedDateFrom}&operationAdjustmentDateTo=${operationAdjustmentDateTo}&operationAdjustmentDateFrom=${operationAdjustmentDateFrom}&applicantCompanyName=${applicantCompanyName}&resultsPreparation=${resultsPreparation}&productId=${productId}&sortBy=${sortBy}&sortWith=${sortWith}&sortProductId=${sortProductId}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },

  async UPDATE_CANCEL_MENU({ body = {}, id_order = '', organizationId }) {
    const result = callApi(body, {
      endpoint: `order-manager/order/cancel-info/${id_order}?organizationId=${organizationId}`,
      method: 'PUT',
    });
    if (result) return result;
  },

  async CREATE_LOG(body) {
    const result = callApi(body, {
      endpoint: 'order-manager/order/create-log',
      method: 'POST',
    });
    if (result) return result;
  },

  async GET_ALL_LOG({ page = '0', limit = '10', orderId = '', screenName = '' }) {
    const link_query = `order-manager/order/get-all-log?page=${page}&limit=${limit}&orderId=${orderId}&screenName=${screenName}`;
    const result = callApi({}, { endpoint: link_query, method: 'GET' });
    if (result) return result;
  },
  async GET_LOG_HISTORY_SYSTEM({ screenName = '', logId = '' }) {
    const link_query = `master-setting-manager/log/get-log-history-system/${screenName}?logId=${logId}`;
    const result = callApi({}, { endpoint: link_query, method: 'GET' });
    if (result) return result;
  },
  async GET_LOG_HISTORY_DISPATCH({ visitDate = '' }) {
    const link_query = `master-setting-manager/dispatch/get-log-dispatch?visitDate=${visitDate}`;
    const result = callApi({}, { endpoint: link_query, method: 'GET' });
    if (result) return result;
  },
  async UPDATE_APPOINTMENT({ body = {}, id_order = '' }) {
    const result = callApi(body, {
      endpoint: `order-manager/order/update-appointment/${id_order}`,
      method: 'PUT',
    });
    if (result) return result;
  },

  async UPDATE_APPOINT_INFO({ body = {}, id_order = '', organizationId = '' }) {
    const result = callApi(body, {
      endpoint: `order-manager/order-common/update-appoint-info/${id_order}?organizationId=${organizationId}`,
      method: 'PUT',
    });
    if (result) return result;
  },

  async UPLOAD_FILE_PREPARATION({ body = {}, id_order = '' }) {
    const result = callApi(body, {
      endpoint: `order-manager/order/upload-file-preparation/${id_order}`,
      method: 'PUT',
    });
    if (result) return result;
  },

  async UPDATE_PREPARATION_INFO({ body = {}, id_order = '', organizationId = '' }) {
    const result = callApi(body, {
      endpoint: `order-manager/order/update-preparation-info/${id_order}?organizationId=${organizationId}`,
      method: 'PUT',
    });
    if (result) return result;
  },

  async UPDATE_FILE_COMPLETED({ body = {}, order_id = '' }) {
    const result = callApi(body, {
      endpoint: `order-manager/worker/upload-file-completed/${order_id}`,
      method: 'PUT',
    });
    if (result) return result;
  },

  async STATIC_ORDER({ page = 1, limit = 10 }) {
    const result = callApi(
      {},
      {
        endpoint: `order-manager/order/statistical-order-center?page=${page}&limit=${limit}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },

  async GET_LIST_ORDER_EXAMPLE({
    page = 1,
    limit = 10,
    idScreenSetting = '',
    // applicationCompanyName = '',
    managementCode = '',
    projectTitle = '',
    services = '',
    visitDateFrom = '',
    visitDateTo = '',
    // visitedBaseName = ''
  }) {
    const result = callApi(
      {},
      {
        endpoint: `order-manager/order/get-list-by-order-setting/${idScreenSetting}?page=${page}&limit=${limit}&projectTitle=${projectTitle}&managementCode=${managementCode}&services=${services}&visitDateFrom=${visitDateFrom}&visitDateTo=${visitDateTo}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },

  async GET_LIST_HEADER_FOLLOW_ROLE({ route, idProvide = '' }) {
    const result = callApi(
      {},
      {
        endpoint: `master-setting-manager/menu/get-list-menu-by-role?role=${route}`,
        method: 'GET',
        idProvide,
      }
    );
    if (result) return result;
  },

  async GET_LIST_ROLE_FOLLOW_HEADER({ arrRole, nameScreen, idProvide }) {
    const result = callApi(
      {},
      {
        endpoint: `master-setting-manager/order-setting/get-order-setting-by-role?role=${arrRole}&screenName=${nameScreen}`,
        method: 'GET',
        idProvide,
      }
    );
    if (result) return result;
  },

  async GET_LIST_DETAIL_SETTING({ arrRole, nameScreen, idProvide, orderId = '', organizationId = '' }) {
    const result = callApi(
      {},
      {
        endpoint: `order-manager/detail-setting/list-by-menu?role=${arrRole}&screenName=${nameScreen}&orderId=${orderId}&organizationId=${organizationId}`,
        method: 'GET',
        idProvide,
      }
    );
    if (result) return result;
  },

  async GET_DETAIL_SETTING_CSV_STELLA({ role, nameScreen, productId = '' }) {
    const result = callApi(
      {},
      {
        endpoint: `order-manager/detail-setting/detail-csv?screenName=${nameScreen}&role=${role}&productId=${productId}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },
  async UPDATE_DETAIL_SETTING_CSV_STELLA({ body = {} }) {
    const result = callApi(body, {
      endpoint: 'order-manager/detail-setting/create-detail-csv',
      method: 'POST',
    });
    if (result) return result;
  },

  async GET_LIST_DETAIL_SETTING_BY_PRODUCT({ arrRole, nameScreen, idProvide, idProduct = '' }) {
    const result = callApi(
      {},
      {
        endpoint: `order-manager/detail-setting/list-detail-setting-by-product?role=${arrRole}&screenName=${nameScreen}&productId=${idProduct}`,
        method: 'GET',
        idProvide,
      }
    );
    if (result) return result;
  },

  async UPDATE_DETAIL_BY_PRODUCT({ body, nameScreen, idProvide, idProduct, nameRole, updateTitle, screenNameLog }) {
    const result = callApi(
      { screenName: nameScreen, nameRole, [nameScreen]: body, screenNameLog, updateTitle },
      {
        endpoint: `order-manager/detail-setting/update-detail-by-product/${idProduct}`,
        method: 'PUT',
        idProvide,
      }
    );
    if (result) return result;
  },

  async UPDATE_LIST_DETAIL_SETTING({ detailId, body, nameScreen, idProvide, updateTitle, screenNameLog }) {
    const result = callApi(
      { updateTitle, screenName: nameScreen, [nameScreen]: body, screenNameLog },
      {
        endpoint: `order-manager/detail-setting/update-detail-status/${detailId}`,
        method: 'PUT',
        idProvide,
      }
    );
    if (result) return result;
  },

  async UPDATE_LIST_ROLE_FOLLOW_HEADER({ orderSettingId, nameScreen, body, idProvide, updateTitle, screenName }) {
    const result = callApi(
      { [nameScreen]: body, updateTitle, screenName },
      {
        endpoint: `master-setting-manager/order-setting/setting-header-list-order/${orderSettingId}`,
        method: 'PUT',
        idProvide,
      }
    );
    if (result) return result;
  },

  async UPDATE_LIST_HEADER_FOLLOW_ROLE(body, namePermission, id, idProvide = '') {
    const result = callApi(
      { [namePermission]: body.data, screenName: SCREEN_NAME.MY_CORP , updateTitle : body.updateTitle},
      {
        endpoint: `master-setting-manager/menu/setting-menu-master/${id}`,
        method: 'PUT',
        idProvide,
      }
    );
    if (result) return result;
  },

  async UPLOAD_FILES_REPORT({ body, orderId = '', organizationId = '' }) {
    const result = callApi(body, { endpoint: `order-manager/order/upload-files-report/${orderId}?organizationId=${organizationId}`, method: 'PUT' });
    if (result) return result;
  },

  async UPDATE_REPORT_UPLOAD_INFO({ body, orderId = '', organizationId = '' }) {
    const result = callApi(body, { endpoint: `order-manager/order/update-report/${orderId}?organizationId=${organizationId}`, method: 'PUT' });
    if (result) return result;
  },

  async UPDATE_REPORT_ORDER_TEMP({ body, orderId = '', organizationId = '' }) {
    const result = callApi(body, { endpoint: `order-manager/order-common/update-report-temp/${orderId}?organizationId=${organizationId}`, method: 'PUT' });
    if (result) return result;
  },


  async MONITORING_CONFIRM_REPORT({ body, orderId = '' }) {
    const result = callApi(body, { endpoint: `order-manager/order-client/update-report-monitoring/${orderId}`, method: 'PUT' });
    if (result) return result;
  },

  async UPLOAD_FILES_REPORT_MANAGEMENT({ body, orderId = '', organizationId = '' }) {
    const result = callApi(body, {
      endpoint: `order-manager/order-partner/upload-file-report-upload-info/${orderId}?organizationId=${organizationId}`,
      method: 'PUT',
    });
    if (result) return result;
  },

  async GET_DETAIL_RECEIVE_ALL({ orderId = '', organizationId = '' }) {
    const result = callApi(
      {},
      {
        endpoint: `order-manager/order/get-detail-receive-all/${orderId}?organizationId=${organizationId}`,
        method: 'GET',
      }
    );

    if (result) return result;
  },

  async RESET_ADD_PARTNER_ORDER({ id_order = '', partnerId = '' , updateTitle = '' , companyName = '', organizationId = ''}) {
    const result = callApi(
      { partnerId, updateTitle, companyName },
      {
        endpoint: `order-manager/order/reset-add-partner-order/${id_order}?organizationId=${organizationId}`,
        method: 'PUT',
      }
    );
    if (result) return result;
  },
};

export const USER_ENDPOINT = {
  async USER_REGISTER(body) {
    const result = callApi(body, { endpoint: 'auth-manager/user/register', method: 'POST' });
    if (result) return result;
  },
  async GET_SECURITY_USER({ body }) {
    const result = callApi(body, { endpoint: `auth-manager/user/get-security-password`, method: 'POST' });
    if (result) return result;
  },
  async GET_BRANCH_MASTER({ partnerId }) {
    const result = callApi({}, { endpoint: `master-setting-manager/branch/list-branch-by-management/${partnerId}`, method: 'GET' });
    if (result) return result;
  },
  async GET_CONFIRM_CODE({ body }) {
    const result = callApi(
      body,
      { endpoint: `auth-manager/user/check-code-exist`, method: 'POST' }
    );
    if (result) return result;
  },
  async GET_CODE({ typeUser, typeId }) {
    const result = callApi(
      {},
      { endpoint: `auth-manager/user/get-code-user?typeUser=${typeUser}&typeId=${typeId}`, method: 'GET' }
    );
    if (result) return result;
  },
  async USER_VERIFY({ body }) {
    const result = callApi(
      body,
      { endpoint: `auth-manager/user/verify-user-sign-up`, method: 'POST' }
    );
    if (result) return result;
  },
  async USER_SET_PASSWORD({ body }) {
    const result = callApi(body, { endpoint: 'auth-manager/user/set-password', method: 'POST' });
    if (result) return result;
  },
  async USER_FORGOT_PASSWORD({ body }) {
    const result = callApi(body, { endpoint: `auth-manager/user/confirm-forgot-password`, method: 'POST' });
    if (result) return result;
  },
  async USER_CREATE(body) {
    const result = callApi(body, { endpoint: 'auth-manager/user/create', method: 'POST' });
    if (result) return result;
  },
  async USER_PDF({ userId = '', staffId = '' }) {
    const result = callApi(
      {},
      { endpoint: `auth-manager/user/get-code-confirm?userId=${userId}&staffId=${staffId}`, method: 'GET' }
    );
    if (result) return result;
  },
  async EXPORT_USER_PDF({ body = {}, fileName = '' }) {
    const result = downloadFile(
      body,
      { endpoint: `auth-manager/user/generate-pdf`, method: 'POST', fileName }
    );
    if (result) return result;
  },

  async GET_LIST_USER({ page = 1, limit = 10, firstNameKana = '', lastNameKana = '', loginId = '', phoneNumber = '' }) {
    const result = callApi(
      {},
      {
        endpoint: `auth-manager/user/get-all-user?page=${page}&limit=${limit}&firstNameKana=${firstNameKana}&lastNameKana=${lastNameKana}&loginId=${loginId}&phoneNumber=${phoneNumber}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },

  async GET_LIST_USERS() {
    const result = callApi({}, { endpoint: 'auth-manager/user/get-list-user-by-organization', method: 'GET' });
    if (result) return result;
  },

  async GET_PARTNER_AND_CLIENT({ type = '', page = 1, limit = 10, name = '' }) {
    const result = callApi(
      {},
      {
        endpoint: `master-setting-manager/organization/partner-and-client-list?type=${type}&page=${page}&limit=${limit}&name=${name}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },

  async GET_LIST_USER_ORGANIZATION({
    page = 1,
    limit = 10,
    pageOrganization = 1,
    limitOrganization = 10,
    organizationId = '',
    nameRole = 'erp',
    name = '',
  }) {
    const result = callApi(
      {},
      {
        endpoint: `auth-manager/user/get-user-list?nameRole=${nameRole}&page=${page}&limit=${limit}&pageOrganization=${pageOrganization}&limitOrganization=${limitOrganization}&organizationId=${organizationId}&name=${name}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },

  async GET_LIST_USER_PARTNER({
    page = 1,
    limit = 10,
    pageUser = 1,
    limitUser = 10,
    type = '',
    permissionId = '',
    name = '',
  }) {
    const result = callApi(
      {},
      {
        endpoint: `auth-manager/user/get-list-user?page=${page}&limit=${limit}&pageUser=${pageUser}&limitUser=${limitUser}&type=${type}&permissionId=${permissionId}&name=${name}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },

  async GET_USER_INFO({ userId = '' }) {
    const result = callApi({}, { endpoint: `auth-manager/user/info-user/${userId}`, method: 'GET' });
    if (result) return result;
  },
  async UP_DATE_USER_INFO({ body = {}, userId = '' }) {
    const result = callApi(body, { endpoint: `auth-manager/user/update-user/${userId}`, method: 'PUT' });
    if (result) return result;
  },
  async UPDATE_USER_INFO({ body = {}, userId = '' }) {
    const result = callApi(body, { endpoint: `auth-manager/user/update/${userId}`, method: 'PUT' });
    if (result) return result;
  },
  async UPDATE_STATUS({ body = {}, userId = '' }) {
    const result = callApi(body, { endpoint: `auth-manager/user/update-status/${userId}`, method: 'PUT' });
    if (result) return result;
  },
  async RESEND_CONFIRMATION_CODE({ body = {} }) {
    const result = callApi(body, { endpoint: 'auth-manager/user/resend-verify', method: 'POST' });
    if (result) return result;
  },
  async REGISTER_USER_COGNITO({ body = {}, userId = '' }) {
    const result = callApi(body, {
      endpoint: `auth-manager/user/register-cognito-or-resend-verify-user/${userId}`,
      method: 'PUT',
    });
    if (result) return result;
  },
  async REGISTER_STAFF_COGNITO({ body = {}, staffId = '' }) {
    const result = callApi(body, {
      endpoint: `order-manager/staff/register-cognito-or-resend-verify-staff/${staffId}`,
      method: 'PUT',
    });
    if (result) return result;
  },
  async GET_ALL_USER({ page = '0', limit = '10', role = 'center' }) {
    const result = callApi(
      {},
      {
        endpoint: `auth-manager/user/get-all-users?page=${page}&limit=${limit}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },
  async CHANGE_PASS(body) {
    const result = callApi(body, { endpoint: 'auth-manager/user/changepass', method: 'PUT' });
    if (result) return result;
  },
  async GET_ALL_ROLE({ idRole = '' }) {
    const result = callApi({}, { endpoint: `auth-manager/user/get-all-role?role=${idRole}`, method: 'GET' });
    if (result) return result;
  },
  async GET_ALL_ROLE_PRODUCT({ idRole = '' }) {
    const result = callApi({}, { endpoint: `auth-manager/user/get-all-role-product?role=${idRole}`, method: 'GET' });
    if (result) return result;
  },
  async GET_ALL_ROLE_SERVICE({ idRole = '' }) {
    const result = callApi({}, { endpoint: `auth-manager/user/get-all-role-service?role=${idRole}`, method: 'GET' });
    if (result) return result;
  },
  async GET_ALL_ROLE_ORGANIZATION({ idRole = '' }) {
    const result = callApi(
      {},
      { endpoint: `auth-manager/user/get-all-role-organization?role=${idRole}`, method: 'GET' }
    );
    if (result) return result;
  },
  async GET_LIST_VIEW_TREE(idProvide) {
    const result = callApi({}, { endpoint: 'auth-manager/user/get-tree-master-setting', method: 'GET', idProvide });
    if (result) return result;
  },

  async GET_SELECT_ROLE() {
    const result = callApi({}, { endpoint: 'auth-manager/user/select-role', method: 'GET' });
    if (result) return result;
  },
};

export const COLOR_ENDPOINT = {
  async GET_COLOR({ role = '1', idProvide = '' }) {
    const result = callApi({}, { endpoint: `ui-manager/color/get-color/${role}`, method: 'GET', idProvide });
    if (result) return result;
  },
  async CREATE_MENU(body) {
    const result = callApi(body, { endpoint: 'menu/create', method: 'POST' });
    if (result) return result;
  },
  async GET_LIST_MENU_BY_ROLE({ role = '1' }) {
    const result = callApi(
      {},
      { endpoint: `master-setting-manager/menu/list-menu-by-role?role=${role}`, method: 'GET' }
    );
    if (result) return result;
  },
};

export const PRODUCT_ENDPOINT = {
  async GET_LIST_STAFF_BY_PRODUCTT({ page = 1, limit = 10, productId = '', name = '' }) {
    const result = callApi(
      {},
      {
        endpoint: `master-setting-manager/product/get-staff-by-product?productId=${productId}&name=${name}&page=${page}&limit=${limit}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },

  async UPDATE_STAFF_SCHEMA({ body, productId = '' }) {
    const result = callApi(body, {
      endpoint: `master-setting-manager/product/update-staff-schema/${productId}`,
      method: 'PUT',
    });
    if (result) return result;
  },

  async GET_IMPORT_CSV_DETAIL({ productId = '', schemaId }) {
    const result = callApi(
      {},
      {
        endpoint: `master-setting-manager/product/get-import-csv-detail?productId=${productId}&schemaId=${schemaId}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },

  async GET_LIST_PRODUCT_TREE() {
    const result = callApi({}, { endpoint: 'master-setting-manager/product/product-tree', method: 'GET' });
    if (result) return result;
  },

  async GET_IMPORT_CSV_DATA_TYPE() {
    const result = callApi(
      {},
      { endpoint: `master-setting-manager/product/get-import-csv-data-type?page=${0}`, method: 'GET' }
    );
    if (result) return result;
  },
  async IMPORT_CSV_PRODUCT_ID({ body, productId }) {
    const result = callApi(
      { ...body },
      {
        endpoint: `master-setting-manager/product/import-csv-product/${productId}`,
        method: 'POST',
      }
    );
    if (result) return result;
  },
  async GET_PRODUCT_SETTING({ orderId = '', organizationId = '' }) {
    const result = callApi(
      {},
      {
        endpoint: `master-setting-manager/product-setting/get-setting-add-staff/${orderId}?organizationId=${organizationId}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },

  async GET_ORDER_OF_MANAGEMENT_GROUP({
    productId = '',
    serviceId = '',
    page = 1,
    limit = 10,
    managementCode = '',
    fieldSort = '',
    typeSort = '',
    optionSort = '',
  }) {
    const result = callApi(
      {},
      {
        endpoint: `master-setting-manager/management-group-admin/orders-of-management-group?productId=${productId}&serviceId=${serviceId}&page=${page}&limit=${limit}&managementCode=${managementCode}&fieldSort=${fieldSort}&typeSort=${typeSort}&optionSort=${optionSort}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },

  async UPDATE_ORDER_MANAGERMENT_GROUP_ADMIN({ body = {}, orderId = '' }) {
    const result = callApi(body, {
      endpoint: `master-setting-manager/management-group-admin/update-order/${orderId}`,
      method: 'PUT',
    });
    if (result) return result;
  },

  async DELETE_ORDER_MANAGERMENT_GROUP({ body = {} }) {
    const result = callApi(body, {
      endpoint: `master-setting-manager/management-group-admin/delete-orders`,
      method: 'PUT',
    });
    if (result) return result;
  },

  async UPDATE_BACK_STEP({ body = {}, orderId = '' }) {
    const result = callApi(body, {
      endpoint: `master-setting-manager/management-group-admin/back-step/${orderId}`,
      method: 'PUT',
    });
    if (result) return result;
  },

  async CREATE_PRODUCT_SETTING(body) {
    const result = callApi(body, { endpoint: 'master-setting-manager/product-setting/create', method: 'POST' });
    if (result) return result;
  },

  async UPDATE_PRODUCT_SETTING({ body, productSettingId = '' }) {
    const result = callApi(body, {
      endpoint: `master-setting-manager/product-setting/update/${productSettingId}`,
      method: 'PUT',
    });
    if (result) return result;
  },

  async DETAIL_PRODUCT_SETTING({ productSettingId = '' }) {
    const result = callApi(
      {},
      { endpoint: `master-setting-manager/product-setting/detail?productSettingId=${productSettingId}`, method: 'GET' }
    );
    if (result) return result;
  },

  async DETAIL_BY_PRODUCT({ productId = '' }) {
    const result = callApi(
      {},
      { endpoint: `master-setting-manager/product-setting/detail-by-product?productId=${productId}`, method: 'GET' }
    );
    if (result) return result;
  },

  async GET_ALL_PRODUCT({ page = 1, limit = 10, name = '', customerName = '' }) {
    const result = callApi(
      {},
      {
        endpoint: `master-setting-manager/product/get-all-product?page=${page}&limit=${limit}&name=${name}&customerName=${customerName}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },
  async CREATE_PRODUCT_STEP_ONE(body) {
    const result = callApi(body, { endpoint: 'master-setting-manager/product/step-one', method: 'POST' });
    if (result) return result;
  },
  async CREATE_PRODUCT_STEP_TWO(body) {
    const result = callApi(body, { endpoint: 'master-setting-manager/product/step-two', method: 'POST' });
    if (result) return result;
  },
  async CREATE_PRODUCT_STEP_THREE(body) {
    const result = callApi(body, { endpoint: 'master-setting-manager/product/step-three', method: 'POST' });
    if (result) return result;
  },
  async GET_ALL_FIELD_MASTER() {
    const result = callApi({}, { endpoint: 'master-setting-manager/product/get-all-field-master', method: 'GET' });
    if (result) return result;
  },
  async GET_ALL_SERVICE() {
    const result = callApi({}, { endpoint: 'master-setting-manager/service/get-all-service', method: 'GET' });
    if (result) return result;
  },
  async CREATE_PRODUCT_STEP_FOUR(body) {
    const result = callApi(body, { endpoint: 'master-setting-manager/product/step-four', method: 'POST' });
    if (result) return result;
  },
  async GET_PRODUCT_INFO({ productId = '' }) {
    const result = callApi({}, { endpoint: `master-setting-manager/product/get-info/${productId}`, method: 'GET' });
    if (result) return result;
  },
  async GET_PRODUCT_INFO_STEP_THREE({ productId = '' }) {
    const result = callApi(
      {},
      { endpoint: `master-setting-manager/product/get-info-step-three/${productId}`, method: 'GET' }
    );
    if (result) return result;
  },
  async GET_PRODUCT_INFO_STEP_FOUR({ productId = '' }) {
    const result = callApi(
      {},
      { endpoint: `master-setting-manager/product/get-info-step-four/${productId}`, method: 'GET' }
    );
    if (result) return result;
  },
  async GET_CLIENT_BY_PRODUCT({ productId = '' }) {
    const result = callApi(
      {},
      { endpoint: `master-setting-manager/product/get-client-by-product/${productId}`, method: 'GET' }
    );
    if (result) return result;
  },
  async UPDATE_PRODUCT_INFO({ body = {}, productId = '' }) {
    const result = callApi(body, { endpoint: `auth-manager/user/update-client/${productId}`, method: 'PUT' });
    if (result) return result;
  },
  async GET_ALL_PRODUCT_BY_USER({ page = '1', limit = '10' }) {
    const result = callApi(
      {},
      { endpoint: `master-setting-manager/product/get-all-product?page=${page}&limit=${limit}`, method: 'GET' }
    );
    if (result) return result;
  },
  async GET_LIST_OPTION_PRODUCT_BY_USER() {
    const result = callApi({}, { endpoint: `master-setting-manager/product/get-all-product?page=${0}`, method: 'GET' });
    if (result) return result;
  },
  async GET_PRODUCT_CSV() {
    const result = callApi({}, { endpoint: `master-setting-manager/product/get-product-csv?page=${0}`, method: 'GET' });
    if (result) return result;
  },
  async DELETE_PRODUCT({ productId = '', flag = true }) {
    const result = callApi(
      {},
      { endpoint: `master-setting-manager/product/delete?productId=${productId}&flag=${flag}`, method: 'DELETE' }
    );
    if (result) return result;
  },
  async GET_TRY_PRODUCT({ productId = '', userId = '' }) {
    const result = callApi(
      {},
      {
        endpoint: `master-setting-manager/product/get-tree-product?productId=${productId}&userId=${userId}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },
  async IMPORT_CSV_PRODUCT(body) {
    const result = uploadFile(body, {
      endpoint: 'master-setting-manager/product/import-csv-product-label',
      method: 'POST',
    });
    if (result) return result;
  },
  async GET_LIST_OPTION_PRODUCT({ productId = '' }) {
    const result = callApi(
      {},
      { endpoint: `master-setting-manager/product/get-product-label-by-product/${productId}`, method: 'GET' }
    );
    if (result) return result;
  },
  async UPDATE_PRODUCT_LABEL({ labels = [], productId = '' }) {
    const result = callApi(
      { productId, labels },
      { endpoint: 'master-setting-manager/product/update-product-label', method: 'PUT' }
    );
    if (result) return result;
  },
  async DOWNLOAD_CSV_PRODUCT_SETTING({
    screenName = '',
    productId = '',
    productName = '',
    caseName = '',
    managementCode = '',
    serviceName = '',
    requestedDateTo = '',
    requestedDateFrom = '',
    operationAdjustmentDateTo = '',
    operationAdjustmentDateFrom = '',
    applicantCompanyName = '',
    resultsPreparation = '',
    visitDateTo = '',
    visitDateFrom = '',
    partnerName = '',
    visitedBaseName = '',
    prefecturesVisited = '',
    reportReceiptDate = '',
    reportDeliveryDate = '',
    installationSiteName = '',
    timeAddPartnerFrom = '',
    timeAddPartnerTo = '',
    reportReceiptDateFrom = '',
    reportReceiptDateTo = '',
    timestampReportUploadInfoFrom = '',
    timestampReportUploadInfoTo = '',
    operationAdjustmentInfo = '',
    contactName = '',
    checkVisitDate = '',
    checkTimeAddPartner = '',
    checkReportReceiptDate = '',
    permissionIdGroup = '',
    totalScreen = '',
    organizationId = '',
  }) {
    const result = callApi(
      {},
      {
        endpoint: `master-setting-manager/product-setting/download-export-csv?screenName=${screenName}&organizationId=${organizationId}&totalScreen=${totalScreen}&productId=${productId}&contactName=${contactName}&operationAdjustmentInfo=${operationAdjustmentInfo}&checkVisitDate=${checkVisitDate}&checkTimeAddPartner=${checkTimeAddPartner}&checkReportReceiptDate=${checkReportReceiptDate}&installationSiteName=${installationSiteName}&timeAddPartnerFrom=${timeAddPartnerFrom}&timeAddPartnerTo=${timeAddPartnerTo}&timestampReportUploadInfoFrom=${timestampReportUploadInfoFrom}&timestampReportUploadInfoTo=${timestampReportUploadInfoTo}&reportReceiptDateFrom=${reportReceiptDateFrom}&reportReceiptDateTo=${reportReceiptDateTo}&visitedBaseName=${visitedBaseName}&partnerName=${partnerName}&prefecturesVisited=${prefecturesVisited}&reportReceiptDate=${reportReceiptDate}&reportDeliveryDate=${reportDeliveryDate}&caseName=${caseName}&productName=${productName}&managementCode=${managementCode}&serviceName=${serviceName}&requestedDateTo=${requestedDateTo}&visitDateTo=${visitDateTo}&visitDateFrom=${visitDateFrom}&requestedDateFrom=${requestedDateFrom}&operationAdjustmentDateTo=${operationAdjustmentDateTo}&operationAdjustmentDateFrom=${operationAdjustmentDateFrom}&applicantCompanyName=${applicantCompanyName}&resultsPreparation=${resultsPreparation}`,
        method: 'GET',
        permissionIdGroup: permissionIdGroup,
      }
    );
    if (result) return result;
  },
};

export const CLIENT_ENDPOINT = {
  async GET_NEW_CLIENT_ID() {
    const result = callApi({}, { endpoint: 'auth-manager/user/get-code-client', method: 'GET' });
    if (result) return result;
  },
  async GET_ALL_CLIENT() {
    const result = callApi({}, { endpoint: 'auth-manager/user/list-client', method: 'GET' });
    if (result) return result;
  },
  async GET_ALL_CLIENT_ORGANIZATION() {
    const result = callApi({}, { endpoint: 'master-setting-manager/client/get-all-client', method: 'GET' });
    if (result) return result;
  },
};

export const PROJECT_ENDPOINT = {
  async GET_NEW_CODE_PROJECT() {
    const result = callApi({}, { endpoint: 'master-setting-manager/project/get-code-project', method: 'GET' });
    if (result) return result;
  },
  async CREATE__PROJECT({ projectCode = '', projectName = '' }) {
    const result = callApi(
      { projectCode, projectName },
      {
        endpoint: 'master-setting-manager/project/create-project',
        method: 'POST',
      }
    );
    if (result) return result;
  },
  async GET_ALL_PROJECT({ page = '1', limit = '10' }) {
    const result = callApi(
      {},
      {
        endpoint: `master-setting-manager/project/get-all-project?page=${page}&limit=${limit}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },

  async GET_ALL_PROJECT_CONTRACT({ page = '1', organizationId = '' }) {
    const result = callApi(
      {},
      {
        endpoint: `master-setting-manager/project/get-all-project?page=${page}&organizationId=${organizationId}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },

  async GET_ALL_PRODUCT_CONTRACT({ organizationId = '' }) {
    const result = callApi(
      {},
      {
        endpoint: `contract-manager/recruitment/get-products-by-organization?organizationId=${organizationId}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },

  async UPDATE_PROJECT({ projectCode = '', projectName = '' }) {
    const result = callApi(
      { projectName },
      {
        endpoint: `master-setting-manager/project/update-project/${projectCode}`,
        method: 'PUT',
      }
    );
    if (result) return result;
  },
};

export const SERVICE_ENDPOINT = {
  async GET_ALL_SERVICE({ page = 1, limit = 10 }) {
    const result = callApi(
      {},
      { endpoint: `master-setting-manager/service/get-all-service?page=${page}&limit=${limit}`, method: 'GET' }
    );
    if (result) return result;
  },
  async ADD_NEW_SERVICE(body) {
    const result = callApi(body, { endpoint: 'master-setting-manager/service/create-service', method: 'POST' });
    if (result) return result;
  },
};

export const ORGANIZATION_ENDPOINT = {
  async GET_CODE() {
    const result = callApi({}, { endpoint: 'master-setting-manager/organization/get-code', method: 'GET' });
    if (result) return result;
  },

  async GET_ORAGINIZATION_LOGIN() {
    const result = callApi(
      {},
      { endpoint: 'master-setting-manager/organization/get-organization-login', method: 'GET' }
    );
    if (result) return result;
  },

  async CREATE_PARTNER(body) {
    const result = callApi(body, {
      endpoint: 'master-setting-manager/partner/create',
      method: 'POST',
    });
    if (result) return result;
  },

  async CREATE_CLIENT(body) {
    const result = callApi(body, {
      endpoint: 'master-setting-manager/client/create',
      method: 'POST',
    });
    if (result) return result;
  },

  async CREATE_ORAGINIZATION(body) {
    const result = callApi(body, {
      endpoint: 'master-setting-manager/organization/create-organization',
      method: 'POST',
    });
    if (result) return result;
  },

  async GET_LIST_ORAGINIZATION({ page = 0, limit = 10, name = '', nameKana = '' }) {
    const result = callApi(
      {},
      {
        endpoint: `master-setting-manager/organization/get-all-organization?page=${page}&limit=${limit}&name=${name}&nameKana=${nameKana}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },
  async GET_ORAGINIZATIONS({ page = 0, limit = 10, name = '', typeRole = '' }) {
    const result = callApi(
      {},
      {
        endpoint: `master-setting-manager/organization/organization-list?page=${page}&limit=${limit}&name=${name}&typeRole=${typeRole}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },
  async GET_DETAIL_ORGANIZATION({ id = '' }) {
    const result = callApi({}, { endpoint: `master-setting-manager/organization/${id}`, method: 'GET' });
    if (result) return result;
  },

  async GET_DETAIL_CLIENT({ id = '' }) {
    const result = callApi({}, { endpoint: `master-setting-manager/client/get-detail/${id}`, method: 'GET' });
    if (result) return result;
  },

  async GET_DETAIL_PARTNER({ id = '' }) {
    const result = callApi({}, { endpoint: `master-setting-manager/partner/get-detail/${id}`, method: 'GET' });
    if (result) return result;
  },

  async UPDATE_ORGANIZATION({ body, id = '' }) {
    const result = callApi(body, {
      endpoint: `master-setting-manager/organization/update-organization/${id}`,
      method: 'PUT',
    });
    if (result) return result;
  },

  async UPDATE_ORGANIZATION_CLIENT({ body, id = '' }) {
    const result = callApi(body, {
      endpoint: `master-setting-manager/client/update/${id}`,
      method: 'PUT',
    });
    if (result) return result;
  },

  async UPDATE_ORGANIZATION_PARTNER({ body, id = '' }) {
    const result = callApi(body, {
      endpoint: `master-setting-manager/partner/update/${id}`,
      method: 'PUT',
    });
    if (result) return result;
  },
};

export const STAFF_ENDPOINT = {
  async CREATE_STAFF(body) {
    const result = callApi(body, { endpoint: 'order-manager/staff/register', method: 'POST' });
    if (result) return result;
  },
  async GET_DETAIL({ id = '' }) {
    const result = callApi({}, { endpoint: `order-manager/staff/get-detail?staffId=${id}`, method: 'GET' });
    if (result) return result;
  },
  async CREATE_STAFF_MASTER({ body }) {
    const result = callApi(body, { endpoint: 'order-manager/staff/register', method: 'POST' });
    if (result) return result;
  },
  async GET_LOGIN_ID_TO_CREATE_NEW_STAFF() {
    const result = callApi({}, { endpoint: 'order-manager/staff/get-login-id-staff', method: 'GET' });
    if (result) return result;
  },
  async UPDATE_STAFF({ body, id = '' }) {
    const result = callApi(body, { endpoint: `order-manager/staff/update/${id}`, method: 'PUT' });
    if (result) return result;
  },
  async UPDATE_STATUS_OF_STAFF({ body, id = '' }) {
    const result = callApi(body, { endpoint: `order-manager/staff/update-status/${id}`, method: 'PUT' });
    if (result) return result;
  },
  async DELETE_STAFF({ body, id = '' }) {
    const result = callApi(body, { endpoint: `order-manager/staff/remove/${id}`, method: 'PUT' });
    if (result) return result;
  },
  async GET_LOG_BY_STAFF({ id = '' }) {
    const result = callApi({}, { endpoint: `order-manager/staff/get-log-by-staff?staffId=${id}`, method: 'GET' });
    if (result) return result;
  },
  async GET_ALL_STAFF({ page = 1, limit = 10, searchName = '', contact = '', branchId = '' }) {
    const result = callApi(
      {},
      {
        endpoint: `order-manager/staff/get-all-staff?page=${page}&limit=${limit}&searchName=${searchName}&contact=${contact}&branchId=${branchId}&isDispatchSetting=true`,
        method: 'GET',
      }
    );
    if (result) return result;
  },

  async GET_STAFF_GROUP_BY_PARTNER({ partnerId = '', limit = 10, page = 1, pagePartner = 1, limitPartner = 10 }) {
    const result = callApi(
      {},
      {
        endpoint: `order-manager/staff/get-staff-group-by-partner?partnerId=${partnerId}&limit=${limit}&page=${page}&pagePartner=${pagePartner}&limitPartner=${limitPartner}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },

  async GET_STAFF_BY_PARTNER({ page = '1', limit = '10' }) {
    const result = callApi(
      {},
      { endpoint: `order-manager/staff/get-staff-by-partner?page=${page}&limit=${limit}`, method: 'GET' }
    );
    if (result) return result;
  },
  async GET_DETAIL_STAFF_INFO({ idStaff = '' }) {
    const result = await callApi(
      {},
      {
        endpoint: `order-manager/staff/get-detail?staffId=${idStaff}`,
        method: 'GET',
      }
    );
    if (result) return result?.data;
  },
  async UPDATE_ADDRESS_STAFF({ body, id = '' }) {
    const result = callApi(body, { endpoint: `order-manager/staff/update-address/${id}`, method: 'PUT' });
    if (result) return result;
  },
  async UPDATE_STAFF_BRANCH_PARTNER({ body, id_order = '', organizationId = '' }) {
    const result = callApi(body, {
      endpoint: `order-manager/order-partner/add-staff-and-branch/${id_order}?organizationId=${organizationId}`,
      method: 'PUT',
    });
    if (result) return result;
  },
  async UPDATE_WORKER_REPORT({ body, id_order = '', organizationId = '' }) {
    const result = uploadFile(body, {
      endpoint: `order-manager/order-partner/worker-report/${id_order}?organizationId=${organizationId}`,
      method: 'POST',
    });
    if (result) return result;
  },
  async UPDATE_WORKER_REPORT_NOT_CONFIRM({ body, id_order = '', organizationId = '' }) {
    const result = uploadFile(body, {
      endpoint: `order-manager/order-partner/worker-report-update/${id_order}?organizationId=${organizationId}`,
      method: 'POST',
    });
    if (result) return result;
  },
};

export const WORKER_ENDPOINT = {
  async GET_WORKER_DETAIL({ workerId = '' }) {
    const result = callApi({}, { endpoint: `order-manager/worker/detail?workerId=${workerId}`, method: 'GET' });
    if (result) return result;
  },
  async CREATE_WORKER(body) {
    const result = callApi(body, { endpoint: 'order-manager/worker/step-one', method: 'POST' });
    if (result) return result;
  },
  async UPDATE_STEP_TWO({ body = {}, workerId = '' }) {
    const result = uploadFile(body, { endpoint: `order-manager/worker/step-two/${workerId}`, method: 'PUT' });
    if (result) return result;
  },
  async UPDATE_STEP_THREE({ body, workerId = '' }) {
    const result = callApi(body, { endpoint: `order-manager/worker/step-three/${workerId}`, method: 'PUT' });
    if (result) return result;
  },
  async UPDATE_STEP_FOUR({ body, workerId = '' }) {
    const result = callApi(body, { endpoint: `order-manager/worker/step-four/${workerId}`, method: 'PUT' });
    if (result) return result;
  },
};

export const ORDER_PARTNER = {
  async ADD_TIME_ORDER_STAFF({ body, id_order = '', organizationId = '' }) {
    const result = callApi(body, {
      endpoint: `order-manager/worker/add-time/${id_order}?organizationId=${organizationId}`,
      method: 'PUT',
    });
    if (result) return result;
  },

  async GET_LIST_BRANCH_PARTNER({ page = 0, limit = 10 }) {
    const result = callApi(
      {},
      { endpoint: `master-setting-manager/branch/list-branch-by-partner?page=${page}&limit=${limit}`, method: 'GET' }
    );
    if (result) return result;
  },
  async GET_WORKER_MENU({ orderId = '', organizationId = '', menuName = '' }) {
    const result = callApi(
      {},
      {
        endpoint: `order-manager/order-partner/get-worker-menu/${orderId}?organizationId=${organizationId}&menuName=${menuName}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },
  async GET_BARCODE({ body }) {
    const result = callApi(body, { endpoint: 'order-manager/order-partner/get-barcode', method: 'POST' });
    if (result) return result;
  },
  async UPDATE_ORDER_PARTNER_DETAIL({ body = {}, orderId = '', organizationId = '' }) {
    const result = callApi(body, {
      endpoint: `order-manager/order-partner/update-order-partner-tomorrow/${orderId}?organizationId=${organizationId}`,
      method: 'PUT',
    });
    if (result) return result;
  },

  async UPDATE_DATE_ORDER_STAFF({ body, id_order = '', organizationId = '' }) {
    const result = callApi(body, {
      endpoint: `order-partner/update-date-order-staff/${id_order}?organizationId=${organizationId}`,
      method: 'PUT',
    });
    if (result) return result;
  },

  async GET_LIST_BY_STAFF({ page = 1, limit = 10, day = '', visitDateFrom = '', visitDateTo = '' }) {
    const result = callApi(
      {},
      {
        endpoint: `order-manager/order-partner/get-order-by-staff?page=${page}&limit=${limit}&day=${day}&visitDateFrom=${visitDateFrom}&visitDateTo=${visitDateTo}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },
  async GET_LIST_RECEIVE_CONFIRM({
    page = 1,
    limit = 10,
    pageProduct = 1,
    limitProduct = 10,
    sortBy = '',
    sortProductId = '',
    sortWith = '',
    productName = '',
    caseName = '',
    managementCode = '',
    serviceName = '',
    productId = '',
    requestedDateTo = '',
    requestedDateFrom = '',
    operationAdjustmentDateTo = '',
    operationAdjustmentDateFrom = '',
    applicantCompanyName = '',
    resultsPreparation = '',
    visitDateTo = '',
    visitDateFrom = '',
    visitedBaseName = '',
    partnerName = '',
    prefecturesVisited = '',
    reportReceiptDate = '',
    reportDeliveryDate = '',
    installationSiteName = '',
    timeAddPartnerFrom = '',
    timeAddPartnerTo = '',
    reportReceiptDateFrom = '',
    reportReceiptDateTo = '',
    timestampReportUploadInfoFrom = '',
    timestampReportUploadInfoTo = '',
    checkReportReceiptDate = '',
    checkVisitDate = '',
    checkTimeAddPartner = '',
    operationAdjustmentInfo = '',
    permissionIdGroup = '',
  }) {
    const result = callApi(
      {},
      {
        endpoint: `order-manager/order-partner/get-list-order-partner?page=${page}&limit=${limit}&pageProduct=${pageProduct}&operationAdjustmentInfo=${operationAdjustmentInfo}&limitProduct=${limitProduct}&checkVisitDate=${checkVisitDate}&checkTimeAddPartner=${checkTimeAddPartner}&checkReportReceiptDate=${checkReportReceiptDate}&installationSiteName=${installationSiteName}&timeAddPartnerFrom=${timeAddPartnerFrom}&timeAddPartnerTo=${timeAddPartnerTo}&timestampReportUploadInfoFrom=${timestampReportUploadInfoFrom}&timestampReportUploadInfoTo=${timestampReportUploadInfoTo}&reportReceiptDateFrom=${reportReceiptDateFrom}&reportReceiptDateTo=${reportReceiptDateTo}&visitedBaseName=${visitedBaseName}&partnerName=${partnerName}&prefecturesVisited=${prefecturesVisited}&reportReceiptDate=${reportReceiptDate}&reportDeliveryDate=${reportDeliveryDate}&caseName=${caseName}&productName=${productName}&managementCode=${managementCode}&serviceName=${serviceName}&requestedDateTo=${requestedDateTo}&visitDateTo=${visitDateTo}&visitDateFrom=${visitDateFrom}&requestedDateFrom=${requestedDateFrom}&operationAdjustmentDateTo=${operationAdjustmentDateTo}&operationAdjustmentDateFrom=${operationAdjustmentDateFrom}&applicantCompanyName=${applicantCompanyName}&resultsPreparation=${resultsPreparation}&productId=${productId}&sortBy=${sortBy}&sortWith=${sortWith}&sortProductId=${sortProductId}`,
        method: 'GET',
        permissionIdGroup: permissionIdGroup,
      }
    );
    if (result) return result;
  },
  async UPDATE_ORDER_PARTNER({ body, id_order = '', organizationId = '', permissionIdGroup = '' }) {
    const result = callApi(body, {
      endpoint: `order-manager/order-partner/update-order-partner/${id_order}?organizationId=${organizationId}`,
      method: 'PUT',
      permissionIdGroup: permissionIdGroup,
    });
    if (result) return result;
  },

  async ADD_STAFF_ORDER_PARTNER({ body, id_order = '', organizationId = '', permissionIdGroup = '' }) {
    const result = callApi(body, {
      endpoint: `order-manager/order-partner/add-staff-partner/${id_order}?organizationId=${organizationId}`,
      method: 'PUT',
      permissionIdGroup: permissionIdGroup,
    });
    if (result) return result;
  },

  async UPDATE_ORDER_REPORT({ body = {}, order_id = '', organizationId = '' }) {
    const result = uploadFile(body, {
      endpoint: `order-manager/order-partner/update-order-report/${order_id}?organizationId=${organizationId}`,
      method: 'PUT',
    });
    if (result) return result;
  },

  async GET_ORDER_PARTNER_TOMORROW({
    page = 1,
    limit = 10,
    pageProduct = 1,
    limitProduct = 10,
    sortBy = '',
    sortProductId = '',
    sortWith = '',
    productName = '',
    caseName = '',
    managementCode = '',
    serviceName = '',
    productId = '',
    requestedDateTo = '',
    requestedDateFrom = '',
    operationAdjustmentDateTo = '',
    operationAdjustmentDateFrom = '',
    applicantCompanyName = '',
    resultsPreparation = '',
    visitDateTo = '',
    visitDateFrom = '',
    visitedBaseName = '',
    partnerName = '',
    prefecturesVisited = '',
    reportReceiptDate = '',
    reportDeliveryDate = '',
    installationSiteName = '',
    timeAddPartnerFrom = '',
    timeAddPartnerTo = '',
    reportReceiptDateFrom = '',
    reportReceiptDateTo = '',
    timestampReportUploadInfoFrom = '',
    timestampReportUploadInfoTo = '',
    checkReportReceiptDate = '',
    checkVisitDate = '',
    checkTimeAddPartner = '',
    operationAdjustmentInfo = '',
  }) {
    const result = callApi(
      {},
      {
        endpoint: `order-manager/order-partner/get-order-partner-tomorrow?page=${page}&limit=${limit}&pageProduct=${pageProduct}&limitProduct=${limitProduct}&operationAdjustmentInfo=${operationAdjustmentInfo}&checkVisitDate=${checkVisitDate}&checkTimeAddPartner=${checkTimeAddPartner}&checkReportReceiptDate=${checkReportReceiptDate}&installationSiteName=${installationSiteName}&timeAddPartnerFrom=${timeAddPartnerFrom}&timeAddPartnerTo=${timeAddPartnerTo}&timestampReportUploadInfoFrom=${timestampReportUploadInfoFrom}&timestampReportUploadInfoTo=${timestampReportUploadInfoTo}&reportReceiptDateFrom=${reportReceiptDateFrom}&reportReceiptDateTo=${reportReceiptDateTo}&visitedBaseName=${visitedBaseName}&partnerName=${partnerName}&prefecturesVisited=${prefecturesVisited}&reportReceiptDate=${reportReceiptDate}&reportDeliveryDate=${reportDeliveryDate}&caseName=${caseName}&productName=${productName}&managementCode=${managementCode}&serviceName=${serviceName}&requestedDateTo=${requestedDateTo}&visitDateTo=${visitDateTo}&visitDateFrom=${visitDateFrom}&requestedDateFrom=${requestedDateFrom}&operationAdjustmentDateTo=${operationAdjustmentDateTo}&operationAdjustmentDateFrom=${operationAdjustmentDateFrom}&applicantCompanyName=${applicantCompanyName}&resultsPreparation=${resultsPreparation}&productId=${productId}&sortBy=${sortBy}&sortWith=${sortWith}&sortProductId=${sortProductId}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },

  async CHANGE_ORDER_REPORT_DATE({ id_order = '', body, organizationId = '' }) {
    const result = callApi(body, {
      endpoint: `order-manager/order-partner/change-order-report-date/${id_order}?organizationId=${organizationId}`,
      method: 'PUT',
    });
    if (result) return result;
  },

  async CHANGE_ORDER_REPORT_DATE_UPDATE({ updateTitle = '', companyName = '', screenName = '', id_order = '', organizationId = '', state }) {
    const result = callApi(
      {},
      {
        endpoint: `order-manager/order-partner/submit-worker-report/${id_order}?organizationId=${organizationId}&name=${state}&screenName=${screenName}&companyName=${companyName}&updateTitle=${updateTitle}`,
        method: 'PUT',
      }
    );
    if (result) return result;
  },

  async GET_REPORT_COMPLETED({
    page = 1,
    limit = 10,
    pageProduct = 1,
    limitProduct = 10,
    productName = '',
    caseName = '',
    managementCode = '',
    serviceName = '',
    productId = '',
    requestedDateTo = '',
    requestedDateFrom = '',
    operationAdjustmentDateTo = '',
    operationAdjustmentDateFrom = '',
    applicantCompanyName = '',
    resultsPreparation = '',
    visitDateTo = '',
    visitDateFrom = '',
    visitedBaseName = '',
    partnerName = '',
    prefecturesVisited = '',
    reportReceiptDate = '',
    reportDeliveryDate = '',
    installationSiteName = '',
    timeAddPartnerFrom = '',
    timeAddPartnerTo = '',
    reportReceiptDateFrom = '',
    reportReceiptDateTo = '',
    timestampReportUploadInfoFrom = '',
    timestampReportUploadInfoTo = '',
    checkReportReceiptDate = '',
    checkVisitDate = '',
    checkTimeAddPartner = '',
    operationAdjustmentInfo = '',
  }) {
    const result = callApi(
      {},
      {
        endpoint: `order-manager/order-partner/get-report-completed?page=${page}&limit=${limit}&pageProduct=${pageProduct}&limitProduct=${limitProduct}&operationAdjustmentInfo=${operationAdjustmentInfo}&checkVisitDate=${checkVisitDate}&checkTimeAddPartner=${checkTimeAddPartner}&checkReportReceiptDate=${checkReportReceiptDate}&installationSiteName=${installationSiteName}&timeAddPartnerFrom=${timeAddPartnerFrom}&timeAddPartnerTo=${timeAddPartnerTo}&timestampReportUploadInfoFrom=${timestampReportUploadInfoFrom}&timestampReportUploadInfoTo=${timestampReportUploadInfoTo}&reportReceiptDateFrom=${reportReceiptDateFrom}&reportReceiptDateTo=${reportReceiptDateTo}&visitedBaseName=${visitedBaseName}&partnerName=${partnerName}&prefecturesVisited=${prefecturesVisited}&reportReceiptDate=${reportReceiptDate}&reportDeliveryDate=${reportDeliveryDate}&caseName=${caseName}&productName=${productName}&managementCode=${managementCode}&serviceName=${serviceName}&requestedDateTo=${requestedDateTo}&visitDateTo=${visitDateTo}&visitDateFrom=${visitDateFrom}&requestedDateFrom=${requestedDateFrom}&operationAdjustmentDateTo=${operationAdjustmentDateTo}&operationAdjustmentDateFrom=${operationAdjustmentDateFrom}&applicantCompanyName=${applicantCompanyName}&resultsPreparation=${resultsPreparation}&productId=${productId}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },

  async WORKER_DATE_RECEIPT({ id_order = '', screenName = '', organizationId = '' }) {
    const result = callApi(
      { screenName },
      {
        endpoint: `order-manager/order-partner/worker-date-receipt/${id_order}?organizationId=${organizationId}`,
        method: 'PUT',
      }
    );
    if (result) return result;
  },
  async GET_LIST_REPORT_DATE({ page = '1', limit = '10', orderId = '' }) {
    const link_query = `order-manager/order-partner/get-list-report-date?page=${page}&limit=${limit}&orderId=${orderId}`;
    const result = callApi({}, { endpoint: link_query, method: 'GET' });
    if (result) return result;
  },

  async GET_LIST_ORDER_PARTNER_HONKADO({
    page = 1,
    limit = 10,
    pageProduct = 1,
    limitProduct = 10,
    sortBy = '',
    sortProductId = '',
    sortWith = '',
    productName = '',
    caseName = '',
    managementCode = '',
    serviceName = '',
    productId = '',
    requestedDateTo = '',
    requestedDateFrom = '',
    operationAdjustmentDateTo = '',
    operationAdjustmentDateFrom = '',
    applicantCompanyName = '',
    resultsPreparation = '',
    visitDateTo = '',
    visitDateFrom = '',
    visitedBaseName = '',
    partnerName = '',
    prefecturesVisited = '',
    reportReceiptDate = '',
    reportDeliveryDate = '',
    installationSiteName = '',
    timeAddPartnerFrom = '',
    timeAddPartnerTo = '',
    reportReceiptDateFrom = '',
    reportReceiptDateTo = '',
    timestampReportUploadInfoFrom = '',
    timestampReportUploadInfoTo = '',
    checkReportReceiptDate = '',
    checkVisitDate = '',
    checkTimeAddPartner = '',
    operationAdjustmentInfo = '',
  }) {
    const result = callApi(
      {},
      {
        endpoint: `order-manager/order-partner/get-list-order-partner-honkado?page=${page}&limit=${limit}&pageProduct=${pageProduct}&limitProduct=${limitProduct}&operationAdjustmentInfo=${operationAdjustmentInfo}&checkVisitDate=${checkVisitDate}&checkTimeAddPartner=${checkTimeAddPartner}&checkReportReceiptDate=${checkReportReceiptDate}&installationSiteName=${installationSiteName}&timeAddPartnerFrom=${timeAddPartnerFrom}&timeAddPartnerTo=${timeAddPartnerTo}&timestampReportUploadInfoFrom=${timestampReportUploadInfoFrom}&timestampReportUploadInfoTo=${timestampReportUploadInfoTo}&reportReceiptDateFrom=${reportReceiptDateFrom}&reportReceiptDateTo=${reportReceiptDateTo}&visitedBaseName=${visitedBaseName}&partnerName=${partnerName}&prefecturesVisited=${prefecturesVisited}&reportReceiptDate=${reportReceiptDate}&reportDeliveryDate=${reportDeliveryDate}&caseName=${caseName}&productName=${productName}&managementCode=${managementCode}&serviceName=${serviceName}&requestedDateTo=${requestedDateTo}&visitDateTo=${visitDateTo}&visitDateFrom=${visitDateFrom}&requestedDateFrom=${requestedDateFrom}&operationAdjustmentDateTo=${operationAdjustmentDateTo}&operationAdjustmentDateFrom=${operationAdjustmentDateFrom}&applicantCompanyName=${applicantCompanyName}&resultsPreparation=${resultsPreparation}&productId=${productId}&sortBy=${sortBy}&sortWith=${sortWith}&sortProductId=${sortProductId}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },

  async GET_LIST_ORDER_PARTNER_APPOINT_INFO({
    page = 1,
    limit = 10,
    pageProduct = 1,
    limitProduct = 10,
    sortBy = '',
    sortProductId = '',
    sortWith = '',
    productName = '',
    caseName = '',
    managementCode = '',
    serviceName = '',
    productId = '',
    requestedDateTo = '',
    requestedDateFrom = '',
    operationAdjustmentDateTo = '',
    operationAdjustmentDateFrom = '',
    applicantCompanyName = '',
    resultsPreparation = '',
    visitDateTo = '',
    visitDateFrom = '',
    visitedBaseName = '',
    partnerName = '',
    prefecturesVisited = '',
    reportReceiptDate = '',
    reportDeliveryDate = '',
    installationSiteName = '',
    timeAddPartnerFrom = '',
    timeAddPartnerTo = '',
    reportReceiptDateFrom = '',
    reportReceiptDateTo = '',
    timestampReportUploadInfoFrom = '',
    timestampReportUploadInfoTo = '',
    checkReportReceiptDate = '',
    checkVisitDate = '',
    checkTimeAddPartner = '',
    operationAdjustmentInfo = '',
  }) {
    const result = callApi(
      {},
      {
        endpoint: `order-manager/order-partner/get-list-order-partner-appoint-info?page=${page}&limit=${limit}&pageProduct=${pageProduct}&operationAdjustmentInfo=${operationAdjustmentInfo}&limitProduct=${limitProduct}&checkVisitDate=${checkVisitDate}&checkTimeAddPartner=${checkTimeAddPartner}&checkReportReceiptDate=${checkReportReceiptDate}&installationSiteName=${installationSiteName}&timeAddPartnerFrom=${timeAddPartnerFrom}&timeAddPartnerTo=${timeAddPartnerTo}&timestampReportUploadInfoFrom=${timestampReportUploadInfoFrom}&timestampReportUploadInfoTo=${timestampReportUploadInfoTo}&reportReceiptDateFrom=${reportReceiptDateFrom}&reportReceiptDateTo=${reportReceiptDateTo}&visitedBaseName=${visitedBaseName}&partnerName=${partnerName}&prefecturesVisited=${prefecturesVisited}&reportReceiptDate=${reportReceiptDate}&reportDeliveryDate=${reportDeliveryDate}&caseName=${caseName}&productName=${productName}&managementCode=${managementCode}&serviceName=${serviceName}&requestedDateTo=${requestedDateTo}&visitDateTo=${visitDateTo}&visitDateFrom=${visitDateFrom}&requestedDateFrom=${requestedDateFrom}&operationAdjustmentDateTo=${operationAdjustmentDateTo}&operationAdjustmentDateFrom=${operationAdjustmentDateFrom}&applicantCompanyName=${applicantCompanyName}&resultsPreparation=${resultsPreparation}&productId=${productId}&sortBy=${sortBy}&sortWith=${sortWith}&sortProductId=${sortProductId}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },

  async UPDATE_HONKADO_INFO({ body = {}, order_id = '', organizationId = '' }) {
    const result = callApi(body, {
      endpoint: `order-manager/order-partner/update-honkado-info/${order_id}?organizationId=${organizationId}`,
      method: 'PUT',
    });
    if (result) return result;
  },

  async GET_PARTNER_UNOPERATION_LIST({
    page = 1,
    limit = 10,
    pageProduct = 1,
    limitProduct = 10,
    sortBy = '',
    sortProductId = '',
    sortWith = '',
    productName = '',
    caseName = '',
    managementCode = '',
    serviceName = '',
    productId = '',
    requestedDateTo = '',
    requestedDateFrom = '',
    operationAdjustmentDateTo = '',
    operationAdjustmentDateFrom = '',
    applicantCompanyName = '',
    resultsPreparation = '',
    visitDateTo = '',
    visitDateFrom = '',
    visitedBaseName = '',
    partnerName = '',
    prefecturesVisited = '',
    reportReceiptDate = '',
    reportDeliveryDate = '',
    installationSiteName = '',
    timeAddPartnerFrom = '',
    timeAddPartnerTo = '',
    reportReceiptDateFrom = '',
    reportReceiptDateTo = '',
    timestampReportUploadInfoFrom = '',
    timestampReportUploadInfoTo = '',
    checkReportReceiptDate = '',
    checkVisitDate = '',
    checkTimeAddPartner = '',
    operationAdjustmentInfo = '',
    permissionIdGroup = '',
  }) {
    const result = callApi(
      {},
      {
        endpoint: `order-manager/order-partner/get-partner-unoperation-list?page=${page}&limit=${limit}&pageProduct=${pageProduct}&limitProduct=${limitProduct}&operationAdjustmentInfo=${operationAdjustmentInfo}&checkVisitDate=${checkVisitDate}&checkTimeAddPartner=${checkTimeAddPartner}&checkReportReceiptDate=${checkReportReceiptDate}&installationSiteName=${installationSiteName}&timeAddPartnerFrom=${timeAddPartnerFrom}&timeAddPartnerTo=${timeAddPartnerTo}&timestampReportUploadInfoFrom=${timestampReportUploadInfoFrom}&timestampReportUploadInfoTo=${timestampReportUploadInfoTo}&reportReceiptDateFrom=${reportReceiptDateFrom}&reportReceiptDateTo=${reportReceiptDateTo}&visitedBaseName=${visitedBaseName}&partnerName=${partnerName}&prefecturesVisited=${prefecturesVisited}&reportReceiptDate=${reportReceiptDate}&reportDeliveryDate=${reportDeliveryDate}&caseName=${caseName}&productName=${productName}&managementCode=${managementCode}&serviceName=${serviceName}&requestedDateTo=${requestedDateTo}&visitDateTo=${visitDateTo}&visitDateFrom=${visitDateFrom}&requestedDateFrom=${requestedDateFrom}&operationAdjustmentDateTo=${operationAdjustmentDateTo}&operationAdjustmentDateFrom=${operationAdjustmentDateFrom}&applicantCompanyName=${applicantCompanyName}&resultsPreparation=${resultsPreparation}&productId=${productId}&sortBy=${sortBy}&sortWith=${sortWith}&sortProductId=${sortProductId}`,
        method: 'GET',
        permissionIdGroup: permissionIdGroup,
      }
    );
    if (result) return result;
  },

  async GET_LIST_PARTNER_CANCEL_INFO({
    page = 1,
    limit = 10,
    pageProduct = 1,
    limitProduct = 10,
    sortBy = '',
    sortProductId = '',
    sortWith = '',
    productName = '',
    caseName = '',
    managementCode = '',
    serviceName = '',
    productId = '',
    requestedDateTo = '',
    requestedDateFrom = '',
    operationAdjustmentDateTo = '',
    operationAdjustmentDateFrom = '',
    applicantCompanyName = '',
    resultsPreparation = '',
    visitDateTo = '',
    visitDateFrom = '',
    visitedBaseName = '',
    partnerName = '',
    prefecturesVisited = '',
    reportReceiptDate = '',
    reportDeliveryDate = '',
    installationSiteName = '',
    timeAddPartnerFrom = '',
    timeAddPartnerTo = '',
    reportReceiptDateFrom = '',
    reportReceiptDateTo = '',
    timestampReportUploadInfoFrom = '',
    timestampReportUploadInfoTo = '',
    checkReportReceiptDate = '',
    checkVisitDate = '',
    checkTimeAddPartner = '',
    operationAdjustmentInfo = '',
  }) {
    const result = callApi(
      {},
      {
        endpoint: `order-manager/order-partner/get-list-partner-cancel-info?page=${page}&limit=${limit}&pageProduct=${pageProduct}&limitProduct=${limitProduct}&operationAdjustmentInfo=${operationAdjustmentInfo}&checkVisitDate=${checkVisitDate}&checkTimeAddPartner=${checkTimeAddPartner}&checkReportReceiptDate=${checkReportReceiptDate}&installationSiteName=${installationSiteName}&timeAddPartnerFrom=${timeAddPartnerFrom}&timeAddPartnerTo=${timeAddPartnerTo}&timestampReportUploadInfoFrom=${timestampReportUploadInfoFrom}&timestampReportUploadInfoTo=${timestampReportUploadInfoTo}&reportReceiptDateFrom=${reportReceiptDateFrom}&reportReceiptDateTo=${reportReceiptDateTo}&visitedBaseName=${visitedBaseName}&partnerName=${partnerName}&prefecturesVisited=${prefecturesVisited}&reportReceiptDate=${reportReceiptDate}&reportDeliveryDate=${reportDeliveryDate}&caseName=${caseName}&productName=${productName}&managementCode=${managementCode}&serviceName=${serviceName}&requestedDateTo=${requestedDateTo}&visitDateTo=${visitDateTo}&visitDateFrom=${visitDateFrom}&requestedDateFrom=${requestedDateFrom}&operationAdjustmentDateTo=${operationAdjustmentDateTo}&operationAdjustmentDateFrom=${operationAdjustmentDateFrom}&applicantCompanyName=${applicantCompanyName}&resultsPreparation=${resultsPreparation}&productId=${productId}&sortBy=${sortBy}&sortWith=${sortWith}&sortProductId=${sortProductId}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },

  async GET_REPOST_LIST({
    page = 1,
    limit = 10,
    pageProduct = 1,
    limitProduct = 10,
    productName = '',
    caseName = '',
    managementCode = '',
    serviceName = '',
    productId = '',
    requestedDateTo = '',
    requestedDateFrom = '',
    operationAdjustmentDateTo = '',
    operationAdjustmentDateFrom = '',
    applicantCompanyName = '',
    resultsPreparation = '',
    visitDateTo = '',
    visitDateFrom = '',
    visitedBaseName = '',
    partnerName = '',
    prefecturesVisited = '',
    reportReceiptDate = '',
    reportDeliveryDate = '',
    installationSiteName = '',
    timeAddPartnerFrom = '',
    timeAddPartnerTo = '',
    reportReceiptDateFrom = '',
    reportReceiptDateTo = '',
    timestampReportUploadInfoFrom = '',
    timestampReportUploadInfoTo = '',
    checkReportReceiptDate = '',
    checkVisitDate = '',
    checkTimeAddPartner = '',
    operationAdjustmentInfo = '',
  }) {
    const result = callApi(
      {},
      {
        endpoint: `order-manager/order-partner/get-report-list?page=${page}&limit=${limit}&pageProduct=${pageProduct}&limitProduct=${limitProduct}&operationAdjustmentInfo=${operationAdjustmentInfo}&checkVisitDate=${checkVisitDate}&checkTimeAddPartner=${checkTimeAddPartner}&checkReportReceiptDate=${checkReportReceiptDate}&installationSiteName=${installationSiteName}&timeAddPartnerFrom=${timeAddPartnerFrom}&timeAddPartnerTo=${timeAddPartnerTo}&timestampReportUploadInfoFrom=${timestampReportUploadInfoFrom}&timestampReportUploadInfoTo=${timestampReportUploadInfoTo}&reportReceiptDateFrom=${reportReceiptDateFrom}&reportReceiptDateTo=${reportReceiptDateTo}&visitedBaseName=${visitedBaseName}&partnerName=${partnerName}&prefecturesVisited=${prefecturesVisited}&reportReceiptDate=${reportReceiptDate}&reportDeliveryDate=${reportDeliveryDate}&caseName=${caseName}&productName=${productName}&managementCode=${managementCode}&serviceName=${serviceName}&requestedDateTo=${requestedDateTo}&visitDateTo=${visitDateTo}&visitDateFrom=${visitDateFrom}&requestedDateFrom=${requestedDateFrom}&operationAdjustmentDateTo=${operationAdjustmentDateTo}&operationAdjustmentDateFrom=${operationAdjustmentDateFrom}&applicantCompanyName=${applicantCompanyName}&resultsPreparation=${resultsPreparation}&productId=${productId}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },

  async GET_LIST_ORDER_PARTNER_RECEIVE({
    page = 1,
    limit = 10,
    pageProduct = 1,
    limitProduct = 10,
    sortBy = '',
    sortProductId = '',
    sortWith = '',
    productName = '',
    caseName = '',
    managementCode = '',
    serviceName = '',
    productId = '',
    requestedDateTo = '',
    requestedDateFrom = '',
    operationAdjustmentDateTo = '',
    operationAdjustmentDateFrom = '',
    applicantCompanyName = '',
    resultsPreparation = '',
    visitDateTo = '',
    visitDateFrom = '',
    visitedBaseName = '',
    partnerName = '',
    prefecturesVisited = '',
    reportReceiptDate = '',
    reportDeliveryDate = '',
    installationSiteName = '',
    timeAddPartnerFrom = '',
    timeAddPartnerTo = '',
    reportReceiptDateFrom = '',
    reportReceiptDateTo = '',
    timestampReportUploadInfoFrom = '',
    timestampReportUploadInfoTo = '',
    checkReportReceiptDate = '',
    checkVisitDate = '',
    checkTimeAddPartner = '',
    operationAdjustmentInfo = '',
  }) {
    const result = callApi(
      {},
      {
        endpoint: `order-manager/order-partner/get-list-order-partner-receive?page=${page}&limit=${limit}&pageProduct=${pageProduct}&limitProduct=${limitProduct}&operationAdjustmentInfo=${operationAdjustmentInfo}&checkVisitDate=${checkVisitDate}&checkTimeAddPartner=${checkTimeAddPartner}&checkReportReceiptDate=${checkReportReceiptDate}&installationSiteName=${installationSiteName}&timeAddPartnerFrom=${timeAddPartnerFrom}&timeAddPartnerTo=${timeAddPartnerTo}&timestampReportUploadInfoFrom=${timestampReportUploadInfoFrom}&timestampReportUploadInfoTo=${timestampReportUploadInfoTo}&reportReceiptDateFrom=${reportReceiptDateFrom}&reportReceiptDateTo=${reportReceiptDateTo}&visitedBaseName=${visitedBaseName}&partnerName=${partnerName}&prefecturesVisited=${prefecturesVisited}&reportReceiptDate=${reportReceiptDate}&reportDeliveryDate=${reportDeliveryDate}&caseName=${caseName}&productName=${productName}&managementCode=${managementCode}&serviceName=${serviceName}&requestedDateTo=${requestedDateTo}&visitDateTo=${visitDateTo}&visitDateFrom=${visitDateFrom}&requestedDateFrom=${requestedDateFrom}&operationAdjustmentDateTo=${operationAdjustmentDateTo}&operationAdjustmentDateFrom=${operationAdjustmentDateFrom}&applicantCompanyName=${applicantCompanyName}&resultsPreparation=${resultsPreparation}&productId=${productId}&sortBy=${sortBy}&sortWith=${sortWith}&sortProductId=${sortProductId}
        `,
        method: 'GET',
      }
    );
    if (result) return result;
  },
};

export const ORDER_CLIENT = {
  async GET_LIST_CANCEL_MENU({
    page = 1,
    limit = 10,
    pageProduct = 1,
    limitProduct = 10,
    productName = '',
    caseName = '',
    managementCode = '',
    serviceName = '',
    productId = '',
    applicantCompanyName = '',
    requestedDateTo = '',
    requestedDateFrom = '',
    visitDateTo = '',
    visitDateFrom = '',
    visitedBaseName = '',
    contactName = '',
    installationSiteName = '',
  }) {
    const result = callApi(
      {},
      {
        endpoint: `order-manager/order-client/get-list-cancel-menu?page=${page}&limit=${limit}&applicantCompanyName=${applicantCompanyName}&installationSiteName=${installationSiteName}&contactName=${contactName}&visitedBaseName=${visitedBaseName}&pageProduct=${pageProduct}&limitProduct=${limitProduct}&requestedDateTo=${requestedDateTo}&requestedDateFrom=${requestedDateFrom}&visitDateTo=${visitDateTo}&visitDateFrom=${visitDateFrom}&caseName=${caseName}&productName=${productName}&managementCode=${managementCode}&serviceName=${serviceName}&productId=${productId}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },

  async GET_ORDER_CLIENT_TOMORROW({
    page = 1,
    limit = 10,
    pageProduct = 1,
    limitProduct = 10,
    sortBy = '',
    sortProductId = '',
    sortWith = '',
    productName = '',
    caseName = '',
    managementCode = '',
    serviceName = '',
    productId = '',
    requestedDateTo = '',
    requestedDateFrom = '',
    operationAdjustmentDateTo = '',
    operationAdjustmentDateFrom = '',
    applicantCompanyName = '',
    resultsPreparation = '',
    visitDateTo = '',
    visitDateFrom = '',
    visitedBaseName = '',
    partnerName = '',
    prefecturesVisited = '',
    reportReceiptDate = '',
    reportDeliveryDate = '',
    contactName = '',
    installationSiteName = '',
  }) {
    const result = callApi(
      {},
      {
        endpoint: `order-manager/order-client/client-orders-tomorrow?page=${page}&limit=${limit}&pageProduct=${pageProduct}&limitProduct=${limitProduct}&installationSiteName=${installationSiteName}&contactName=${contactName}&visitedBaseName=${visitedBaseName}&partnerName=${partnerName}&prefecturesVisited=${prefecturesVisited}&reportReceiptDate=${reportReceiptDate}&reportDeliveryDate=${reportDeliveryDate}&caseName=${caseName}&productName=${productName}&managementCode=${managementCode}&serviceName=${serviceName}&requestedDateTo=${requestedDateTo}&visitDateTo=${visitDateTo}&visitDateFrom=${visitDateFrom}&requestedDateFrom=${requestedDateFrom}&operationAdjustmentDateTo=${operationAdjustmentDateTo}&operationAdjustmentDateFrom=${operationAdjustmentDateFrom}&applicantCompanyName=${applicantCompanyName}&resultsPreparation=${resultsPreparation}&productId=${productId}&sortBy=${sortBy}&sortWith=${sortWith}&sortProductId=${sortProductId}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },

  async GET_LIST_CANCEL_INFO({
    page = 1,
    limit = 10,
    pageProduct = 1,
    limitProduct = 10,
    productName = '',
    caseName = '',
    managementCode = '',
    serviceName = '',
    productId = '',
    applicantCompanyName = '',
    requestedDateTo = '',
    requestedDateFrom = '',
    visitDateTo = '',
    visitDateFrom = '',
    visitedBaseName = '',
    contactName = '',
    installationSiteName = '',
  }) {
    const result = callApi(
      {},
      {
        endpoint: `order-manager/order-client/get-list-cancel-info?page=${page}&limit=${limit}&installationSiteName=${installationSiteName}&applicantCompanyName=${applicantCompanyName}&contactName=${contactName}&visitedBaseName=${visitedBaseName}&pageProduct=${pageProduct}&limitProduct=${limitProduct}&requestedDateTo=${requestedDateTo}&requestedDateFrom=${requestedDateFrom}&visitDateTo=${visitDateTo}&visitDateFrom=${visitDateFrom}&caseName=${caseName}&productName=${productName}&managementCode=${managementCode}&serviceName=${serviceName}&productId=${productId}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },
  async STATIC_ORDER_CLIENT({ page = 1, limit = 10 }) {
    const result = callApi(
      {},
      {
        endpoint: `order-manager/order-client/statisc-order-client?page=${page}&limit=${limit}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },
  async UPDATE_CANCEL_ORDER({ body, id_order = '' }) {
    const result = callApi(body, { endpoint: `order-manager/order-client/cancel-order/${id_order}`, method: 'PUT' });
    if (result) return result;
  },

  async GET_LIST_HONKADO({
    page = 1,
    limit = 10,
    pageProduct = 1,
    limitProduct = 10,
    sortBy = '',
    sortProductId = '',
    sortWith = '',
    productName = '',
    caseName = '',
    managementCode = '',
    serviceName = '',
    productId = '',
    requestedDateTo = '',
    requestedDateFrom = '',
    visitDateTo = '',
    visitDateFrom = '',
    applicantCompanyName = '',
    visitedBaseName = '',
    contactName = '',
    installationSiteName = '',
  }) {
    const result = callApi(
      {},
      {
        endpoint: `order-manager/order-client/get-list-honkando?page=${page}&limit=${limit}&installationSiteName=${installationSiteName}&applicantCompanyName=${applicantCompanyName}&contactName=${contactName}&visitedBaseName=${visitedBaseName}&pageProduct=${pageProduct}&limitProduct=${limitProduct}&requestedDateTo=${requestedDateTo}&requestedDateFrom=${requestedDateFrom}&visitDateTo=${visitDateTo}&visitDateFrom=${visitDateFrom}&caseName=${caseName}&productName=${productName}&managementCode=${managementCode}&serviceName=${serviceName}&productId=${productId}&sortBy=${sortBy}&sortWith=${sortWith}&sortProductId=${sortProductId}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },

  async GET_LIST_DELIVERY({
    page = 1,
    limit = 10,
    pageProduct = 1,
    limitProduct = 10,
    sortBy = '',
    sortProductId = '',
    sortWith = '',
    productName = '',
    caseName = '',
    managementCode = '',
    serviceName = '',
    productId = '',
    requestedDateTo = '',
    requestedDateFrom = '',
    visitDateTo = '',
    visitDateFrom = '',
    applicantCompanyName = '',
    visitedBaseName = '',
    contactName = '',
    installationSiteName = '',
  }) {
    const result = callApi(
      {},
      {
        endpoint: `order-manager/order-client/get-list-delivery?page=${page}&limit=${limit}&pageProduct=${pageProduct}&installationSiteName=${installationSiteName}&contactName=${contactName}&visitedBaseName=${visitedBaseName}&applicantCompanyName=${applicantCompanyName}&limitProduct=${limitProduct}&requestedDateTo=${requestedDateTo}&requestedDateFrom=${requestedDateFrom}&visitDateTo=${visitDateTo}&visitDateFrom=${visitDateFrom}&caseName=${caseName}&productName=${productName}&managementCode=${managementCode}&serviceName=${serviceName}&productId=${productId}&sortBy=${sortBy}&sortWith=${sortWith}&sortProductId=${sortProductId}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },

  async UPDATE_DEFECTIVE({ body, id_order = '' }) {
    const result = callApi(body, {
      endpoint: `order-manager/order-client/update-defective/${id_order}`,
      method: 'PUT',
    });
    if (result) return result;
  },

  async GET_LIST_COMPLETE_LIST({
    page = 1,
    limit = 10,
    pageProduct = 1,
    limitProduct = 10,
    sortBy = '',
    sortProductId = '',
    sortWith = '',
    productName = '',
    caseName = '',
    managementCode = '',
    serviceName = '',
    productId = '',
    requestedDateTo = '',
    requestedDateFrom = '',
    visitDateTo = '',
    visitDateFrom = '',
    applicantCompanyName = '',
    visitedBaseName = '',
    contactName = '',
    installationSiteName = '',
  }) {
    const result = callApi(
      {},
      {
        endpoint: `order-manager/order-client/get-list-complete-list?page=${page}&limit=${limit}&pageProduct=${pageProduct}&installationSiteName=${installationSiteName}&contactName=${contactName}&applicantCompanyName=${applicantCompanyName}&visitedBaseName=${visitedBaseName}&limitProduct=${limitProduct}&requestedDateTo=${requestedDateTo}&requestedDateFrom=${requestedDateFrom}&visitDateTo=${visitDateTo}&visitDateFrom=${visitDateFrom}&caseName=${caseName}&productName=${productName}&managementCode=${managementCode}&serviceName=${serviceName}&productId=${productId}&sortBy=${sortBy}&sortWith=${sortWith}&sortProductId=${sortProductId}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },

  async GET_LIST_JYUCYU({
    page = 1,
    limit = 10,
    pageProduct = 1,
    limitProduct = 10,
    sortBy = '',
    sortProductId = '',
    sortWith = '',
    productName = '',
    caseName = '',
    managementCode = '',
    serviceName = '',
    productId = '',
    requestedDateTo = '',
    requestedDateFrom = '',
    visitDateTo = '',
    visitDateFrom = '',
    applicantCompanyName = '',
    visitedBaseName = '',
    contactName = '',
    installationSiteName = '',
  }) {
    const result = callApi(
      {},
      {
        endpoint: `order-manager/order-client/get-list-jyucyu?page=${page}&limit=${limit}&pageProduct=${pageProduct}&contactName=${contactName}&installationSiteName=${installationSiteName}&visitedBaseName=${visitedBaseName}&applicantCompanyName=${applicantCompanyName}&limitProduct=${limitProduct}&requestedDateTo=${requestedDateTo}&requestedDateFrom=${requestedDateFrom}&visitDateTo=${visitDateTo}&visitDateFrom=${visitDateFrom}&caseName=${caseName}&productName=${productName}&managementCode=${managementCode}&serviceName=${serviceName}&productId=${productId}&sortBy=${sortBy}&sortWith=${sortWith}&sortProductId=${sortProductId}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },

  async GET_LIST_SPORT_ACCEPT({
    page = 1,
    limit = 10,
    pageProduct = 1,
    limitProduct = 10,
    sortBy = '',
    sortProductId = '',
    sortWith = '',
    productName = '',
    caseName = '',
    managementCode = '',
    serviceName = '',
    productId = '',
    requestedDateTo = '',
    requestedDateFrom = '',
    visitDateTo = '',
    visitDateFrom = '',
    applicantCompanyName = '',
    visitedBaseName = '',
    contactName = '',
    installationSiteName = '',
  }) {
    const result = callApi(
      {},
      {
        endpoint: `order-manager/order-client/get-list-sport-accept?page=${page}&limit=${limit}&pageProduct=${pageProduct}&contactName=${contactName}&installationSiteName=${installationSiteName}&visitedBaseName=${visitedBaseName}&applicantCompanyName=${applicantCompanyName}&limitProduct=${limitProduct}&requestedDateTo=${requestedDateTo}&requestedDateFrom=${requestedDateFrom}&visitDateTo=${visitDateTo}&visitDateFrom=${visitDateFrom}&caseName=${caseName}&productName=${productName}&managementCode=${managementCode}&serviceName=${serviceName}&productId=${productId}&sortBy=${sortBy}&sortWith=${sortWith}&sortProductId=${sortProductId}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },

  async IMPORT_CSV(body) {
    const result = uploadFile(body, { endpoint: 'order-manager/order-client/import-csv', method: 'POST' });
    if (result) return result;
  },
  async GET_DEFAULT_CSV({ productId = '' }) {
    const result = callApi(
      {},
      { endpoint: `order-manager/order-client/default-csv-order/${productId}`, method: 'GET' }
    );
    if (result) return result;
  },
  async UPDATE_ORDER_CLIENT({ body, id_order = '' }) {
    const result = callApi(body, {
      endpoint: `order-manager/order-client/update-order-client/${id_order}`,
      method: 'PUT',
    });
    if (result) return result;
  },
  async UPDATE_CLIENT_TOMORROW({ body = {}, orderId = '' }) {
    const result = callApi(body, {
      endpoint: `order-manager/order-client/update-order-client-tomorrow/${orderId}`,
      method: 'PUT',
    });
    if (result) return result;
  },
  async UPDATE_FILE_ORDER_DESCRIBE({ body = {}, order_id = '' }) {
    const result = callApi(body, {
      endpoint: `order-manager/order-client/update-file-order-describe/${order_id}`,
      method: 'PUT',
    });
    if (result) return result;
  },
};

export const ORDER_MANANGER = {
  async LIST_BY_MENU({ menuId = '' }) {
    const result = callApi(
      {},
      { endpoint: `order-manager/detail-setting/list-by-menu?menuId=${menuId}`, method: 'GET' }
    );
    if (result) return result;
  },
  async UPDATE_DETAIL_SETTING({ body }) {
    const result = callApi(body, { endpoint: 'order-manager/detail-setting/update-detail-status', method: 'PUT' });
    if (result) return result;
  },
  async LIST_ALL_MENU({ menuId = '' }) {
    const result = callApi(
      {},
      { endpoint: `order-manager/detail-setting/list-all-menu?menuId=${menuId}`, method: 'GET' }
    );
    if (result) return result;
  },
  async GET_LIST_SEARCH_SETTING({ idScreenSetting }) {
    const result = callApi({}, { endpoint: `order-manager/search-setting/list/${idScreenSetting}`, method: 'GET' });
    if (result) return result;
  },
  async GET_DETAIL_SEARCH_SETTING({ id = '' }) {
    const result = callApi({}, { endpoint: `order-manager/search-setting/detail/${id}`, method: 'GET' });
    if (result) return result;
  },

  async CREATE_SEARCH_SETTING(body) {
    const result = callApi(body, { endpoint: 'order-manager/search-setting/create', method: 'POST' });
    if (result) return result;
  },
  async DELETE_SEARCH_SETTING({ id = '' }) {
    const result = callApi(
      {},
      {
        endpoint: `order-manager/search-setting/delete/${id}`,
        method: 'DELETE',
      }
    );
    if (result) return result;
  },
};

export const MASTER_SETTING = {
  async CREATE_LOGS({ body , organizationId = '', idProvide = '' }) {
    const result = callApi(body, {
      endpoint: `master-setting-manager/log/create?organizationId=${organizationId}`,
      method: 'POST',
      idProvide,
    });
    if (result) return result;
  },
  async GET_TREE_BY_PROJECT(idProvide) {
    const result = callApi({}, { endpoint: 'master-setting-manager/client/get-tree-master-setting', method: 'GET', idProvide });
    if (result) return result;
  },
  async CREATE_COLOR({ body }) {
    const result = uploadFile(body, { endpoint: 'ui-manager/color/create', method: 'POST' });
    if (result) return result;
  },
  async CREATE_SECURITY_SETTINGS({ body, idProvide }) {
    const result = callApi(body, {
      endpoint: 'master-setting-manager/security-setting/create',
      method: 'POST',
      idProvide,
    });
    if (result) return result;
  },
  async UPDATE_SECURITY_SETTINGS({ body, idProvide, id = '' }) {
    const result = callApi(body, {
      endpoint: `master-setting-manager/security-setting/update/${id}`,
      method: 'PUT',
      idProvide,
    });
    if (result) return result;
  },
  async UPDATE_BLOCK_IPS({ body, idProvide, id = '' }) {
    const result = callApi(body, {
      endpoint: `master-setting-manager/security-setting/update-ips/${id}`,
      method: 'PUT',
      idProvide,
    });
    if (result) return result;
  },
  async GET_SECURITY_SETTINGS({ idProvide }) {
    const result = callApi(
      {},
      { endpoint: 'master-setting-manager/security-setting/get-by-organizationId', method: 'GET', idProvide }
    );
    if (result) return result;
  },
  async UPDATE_COLOR({ body, colorId, idProvide = '' }) {
    const result = callApi(body, { endpoint: `ui-manager/color/update/${colorId}`, method: 'PUT', idProvide });
    if (result) return result;
  },
  async UPDATE_MODULE_MANAGER({ body, colorId, idProvide = '' }) {
    const result = callApi(body, { endpoint: `ui-manager/color/add-module-management/${colorId}`, method: 'PUT', idProvide });
    if (result) return result;
  },
  async GET_MODULE_MANAGER({ colorId }) {
    const result = callApi({}, { endpoint: `ui-manager/color/get-module-management/${colorId}`, method: 'GET' });
    if (result) return result;
  },
  async GET_WORKING_SCHEDULE({ date, partnerId }) {
    const result = callApi(
      {},
      {
        endpoint: `master-setting-manager/working-schedule/get-working-schedule?date=${date}&partnerId=${partnerId}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },
  async GET_REQUEST_CALENDAR_STAFF({ date, staffId }) {
    const result = callApi(
      {},
      {
        endpoint: `master-setting-manager/worker-calendar/request-calendar-staff?date=${date}&staffId=${staffId}&partnerId=P0058`,
        method: 'GET',
      }
    );
    if (result) return result;
  },
  async UPDATE_REQUEST_CALENDAR_STAFF({ body }) {
    const result = callApi(body, {
      endpoint: `master-setting-manager/worker-calendar/update-request-calendar`,
      method: 'PUT',
    });
    if (result) return result;
  },
  async UPDATE_REQUEST_STAFF({ body }) {
    const result = callApi(body, {
      endpoint: `master-setting-manager/worker-calendar/update-request-by-erp-partner`,
      method: 'PUT',
    });
    if (result) return result;
  },
  async GET_PARTNER_ORGANIZATION() {
    const result = callApi({}, { endpoint: `order-manager/partner/get-partners-by-organization`, method: 'GET' });
    if (result) return result;
  },
  async CREATE_UPDATE_WORKING_SCHEDULE({ body }) {
    const result = callApi(body, {
      endpoint: `master-setting-manager/working-schedule/create-or-update`,
      method: 'POST',
    });
    if (result) return result;
  },
  async GET_LIST_COLOR_BY_ORGANIZATION(id, idProvide) {
    const result = callApi(
      { id },
      {
        endpoint: `ui-manager/color/get-color-by-organization?role=${id}`,
        method: 'GET',
        idProvide,
      }
    );
    if (result) return result;
  },

  async GET_UI_LOGIN() {
    const result = callApi(
      {},
      {
        endpoint: `ui-manager/setting-domain/get-ui-login`,
        method: 'GET',
      }
    );
    if (result) return result;
  },

  async GET_DETAIL_SETTING({ organizationId = "" }) {
    const result = callApi(
      {},
      {
        endpoint: `ui-manager/setting-domain/get-detail-setting/${organizationId}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },

  async UPLOAD_BACKGROUD({ body }) {
    const result = callApi(
      body,
      { endpoint: 'ui-manager/setting-domain/upload-background', method: 'POST' }
    );
    if (result) return result;
  },

  async CREATE_SETTING_DOMAIN({ body }) {
    const result = callApi(
      body,
      { endpoint: 'ui-manager/setting-domain/setting', method: 'POST' }
    );
    if (result) return result;
  },

  async GET_DETAIL_STATUS_BUTTON({ productId, serviceId, enumPluginSetting }) {
    const result = await callApi(
      {},
      {
        endpoint: `master-setting-manager/plugin-setting/get-plugin-options?serviceId=${serviceId}&productId=${productId}&enumPluginSetting=${enumPluginSetting}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },

  async GET_STAFF_BY_ORDER({ orderId = '', organizationId = '', partnerId = '', screenName = '' }) {
    const result = await callApi(
      {},
      {
        endpoint: `master-setting-manager/dispatch/get-staffs-by-order?orderId=${orderId}&organizationId=${organizationId}&partnerId=${partnerId}&screenName=${screenName}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },

  async GET_ALL_SUMMARY({ screenRole = '', screenName = '' }) {
    const result = callApi(
      {},
      {
        endpoint: `master-setting-manager/summary/get-all-summary-product-setting?screenName=${screenName}&screenRole=${screenRole}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },
  async GET_LIST_OPTION_SETTING(idProvide) {
    const result = callApi(
      {},
      { endpoint: 'master-setting-manager/option-setting/get-option-setting', method: 'GET', idProvide }
    );
    if (result) return result;
  },
  async CREATE_LIST_OPTION_SETTING({ body, idProvide }) {
    const result = callApi(
      { ...body },
      { endpoint: 'master-setting-manager/option-setting/add-option-setting', method: 'POST', idProvide }
    );
    if (result) return result;
  },
  async GET_LIST_VIEW_TREE() {
    const result = callApi({}, { endpoint: 'master-setting-manager/project/get-tree-project', method: 'GET' });
    if (result) return result;
  },
  async GET_DATA_SCREEN_SETTING() {
    const result = callApi({}, { endpoint: 'master-setting-manager/screen-setting/get-data', method: 'GET' });
    if (result) return result;
  },
  async GET_LIST_SCREEN_SETTING_BY_PROVIDER() {
    const result = callApi({}, { endpoint: 'master-setting-manager/screen-setting', method: 'GET' });
    if (result) return result;
  },
  async GET_DETAIL_SCREEN_SETTING({ id = '' }) {
    const result = callApi({}, { endpoint: `master-setting-manager/screen-setting/${id}`, method: 'GET' });
    if (result) return result;
  },
  async CREATE_LIST_SCREEN_SETTING(body) {
    const result = callApi(body, { endpoint: 'master-setting-manager/screen-setting', method: 'POST' });
    if (result) return result;
  },
  async UPDATE_LIST_SCREEN_SETTING({ dataMain, id }) {
    const result = callApi(dataMain, { endpoint: `master-setting-manager/screen-setting/${id}`, method: 'PUT' });
    if (result) return result;
  },
  async GET_LIST_EXPORT_SETTING({ page = 1, limit = 10 }) {
    const result = callApi(
      {},
      {
        endpoint: `master-setting-manager/product-setting/list-export-settings?page=${page}&limit=${limit}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },

  async GET_LIST_EXPORT_DISPLAY({ nameRole = '', name = '', productId = '' }) {
    const result = callApi(
      {},
      {
        endpoint: `master-setting-manager/product-setting/exports-display/${productId}?nameRole=${nameRole}&name=${name}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },

  async CREATE_OR_UPDATE_EXPORT_SETTING({ body = {} }) {
    const result = callApi(
      { ...body },
      { endpoint: 'master-setting-manager/product-setting/create-or-update-exports-setting', method: 'POST' }
    );
    if (result) return result;
  },

  async GET_LIST_EXPORT_CSV({ page = 1, limit = 10, nameRole = '', productId = '' }) {
    const result = callApi(
      {},
      {
        endpoint: `master-setting-manager/product-setting/get-list-export-csv?nameRole=${nameRole}&page=${page}&limit=${limit}&productId=${productId}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },

  async UPDATE_EXPORT_DISPLAY({ body = {}, productId = '' }) {
    const result = callApi(
      { ...body },
      { endpoint: `master-setting-manager/product-setting/exports-display/${productId}`, method: 'PUT' }
    );
    if (result) return result;
  },

  async UPDATE_OR_CREATE_EXPORT_SETTING({ body = {}, productId = '' }) {
    const result = callApi(
      { ...body },
      {
        endpoint: `master-setting-manager/product-setting/update-or-create-export-setting/${productId}`,
        method: 'POST',
      }
    );
    if (result) return result;
  },
  async GET_LIST_ADDRESS({ name = '' }) {
    const result = callApi({}, { endpoint: `master-setting-manager/master-setting?name=${name}`, method: 'GET' });
    if (result) return result;
  },
};

export const CALENDAR_MASTER_ENDPOINTS = {
  async CREATE_UPDATE_CALENDAR_MASTER({ body = {} }) {
    const result = callApi(
      { ...body },
      { endpoint: `master-setting-manager/calendar-master/create-update`, method: 'POST' }
    );
    if (result) return result;
  },

  async GET_CALENDAR({ calendarMasterId = '' }) {
    const result = callApi(
      {},
      {
        endpoint: `master-setting-manager/calendar-master/get-calendar?calendarMasterId=${calendarMasterId}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },

  async GET_REQUEST_CALENDAR({ date = '' }) {
    const result = callApi(
      {},
      {
        endpoint: `master-setting-manager/worker-calendar/request-calendar?date=${date}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },
};

export const ORDER_MANAGER_SHIFT_STAFF = {
  async GET_LIST_BY_STAFF_ID({ idStaff, dateOff }) {
    const d = new Date();
    const y = d.getFullYear();
    const result = callApi(
      {},
      {
        endpoint: `order-manager/shift-staff/list-by-staff-id?staffId=${idStaff}&dateOff=${dateOff + 1}/${y}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },
  async CREATE_SHIFT_STAFF({ body }) {
    const result = callApi(body, { endpoint: 'order-manager/shift-staff/create', method: 'POST' });
    if (result) return result;
  },
  async UPDATE_SHIFT_STAFF({ body, shiftStaffId }) {
    const result = callApi(body, { endpoint: `order-manager/shift-staff/update/${shiftStaffId}`, method: 'PUT' });
    if (result) return result;
  },

  async GET_LIST_BY_STAFF({ staffId = '' }) {
    const result = callApi(
      {},
      {
        endpoint: `order-manager/staff-service/list-by-staff?staffId=${staffId}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },

  async INSERT_OR_UPDATE({ staffId = '', staffServices = [] }) {
    const result = callApi(
      { staffId, staffServices },
      {
        endpoint: 'order-manager/staff-service/insert-or-update',
        method: 'POST',
      }
    );
    if (result) return result;
  },
  async UPDATE_STAFF_SERVICE({ body = {}, staffId }) {
    const result = callApi(body, { endpoint: `order-manager/staff/add-service/${staffId}`, method: 'PUT' });
    if (result) return result;
  },
};

export const PLUGIN_SETTING = {
  async CREATE_PLUGIN_SETTING(body, id, idProvide = '') {
    const result = callApi(body, {
      endpoint: 'master-setting-manager/plugin-setting/create-plugin-setting',
      method: 'POST',
      idProvide,
    });
    if (result) return result;
  },
  async GET_LIST_PLUGIN_SETTINGS({ idProvide = '', providerId, provider, productId }) {
    const result = callApi(
      {},
      {
        idProvide,
        endpoint: `master-setting-manager/plugin-setting/get-detail?providerId=${providerId}&provider=${provider}&productId=${productId}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },
  async GET_LIST_ORDER_PLUGIN_SETTINGS({ pluginSettingId, nameSetting }) {
    const result = callApi(
      {},
      {
        endpoint: `master-setting-manager/plugin-setting/get-detail-setting?pluginSettingId=${pluginSettingId}&nameSetting=${nameSetting}`,
        method: 'GET',
      }
    );

    if (result) return result;
  },
  async GET_DETAIL_PLUGIN_SETTING(pluginSettingId) {
    const result = callApi(
      {},
      {
        endpoint: `master-setting-manager/plugin-setting/get-detail/${pluginSettingId}`,
        method: 'GET',
      }
    );

    if (result) return result;
  },
  async UPDATE_DETAIL_SETTING({ body, pluginSettingId, idProvide, nameSetting }) {
    const result = callApi(body, {
      endpoint: `master-setting-manager/plugin-setting/update-detail-setting?pluginSettingId=${pluginSettingId}&nameSetting=${nameSetting}`,
      method: 'PUT',
      idProvide,
    });
    if (result) return result;
  },
  async DELETE_DETAIL_SETTING(
    provider,
    providerId,
    nameSetting,
    detailId,
    idProvide = '',
    screenName,
    productId = null
  ) {
    const query = productId ? `productId=${productId}` : '';
    const result = callApi(
      {},
      {
        endpoint: `master-setting-manager/plugin-setting/delete-detail-setting?providerId=${providerId}&provider=${provider}&nameSetting=${nameSetting}&detailId=${detailId}&screenName=${screenName}&${query}`,
        method: 'DELETE',
        idProvide,
      }
    );
    if (result) return result;
  },
};

export const PRODUCT_SERVICE = {
  async GET_LIST_BY_STAFF_ID({ productId }) {
    const result = callApi(
      {},
      {
        endpoint: `master-setting-manager/dispatch/get-service-option/${productId}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },

  async CREATE_PRODUCT_SERVICE({ body = {}, productId = '' }) {
    const result = callApi(body, {
      endpoint: `master-setting-manager/dispatch/create-update-service-option/${productId}`,
      method: 'POST',
    });
    if (result) return result;
  },

  async DELETE_PERMISSION_SETTING_PROVIDER({ userId = '' }) {
    const result = callApi(
      {},
      {
        endpoint: `master-setting-manager/permission-setting/delete-permission-setting-provider/${userId}?screenName=${SCREEN_NAME.USER_MASTER}`,
        method: 'DELETE',
      }
    );
    if (result) return result;
  },
  async PERMISSION_SETTING_PROVIDER({ body = {}, productId = '' }) {
    const result = callApi(
      { ...body },
      { endpoint: `master-setting-manager/permission-setting/permission-setting-provider/${productId}`, method: 'POST' }
    );
    if (result) return result;
  },

  async PERMISSION_SETTING({ body = {}, userId = '' }) {
    const result = callApi(
      { ...body },
      { endpoint: `master-setting-manager/permission-setting/permission-setting/${userId}`, method: 'POST' }
    );
    if (result) return result;
  },

  async GET_DETAIL({ userId }) {
    const result = callApi(
      {},
      {
        endpoint: `master-setting-manager/permission-setting/get-detail/${userId}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },
};

export const BRANCH_MASTER_ENDPOINT = {
  async CREATE_BRANCH_MASTER(body) {
    const result = callApi(body, { endpoint: 'master-setting-manager/branch/create-branch-master', method: 'POST' });
    if (result) return result;
  },
  async GET_LIST_BRANCH_MASTER({ page = 0, limit = 10, branchName = '', branchNameKana = '' }) {
    const result = callApi(
      {},
      {
        endpoint: `master-setting-manager/branch/list-branch-by-partner?page=${page}&limit=${limit}&branchName=${branchName}&branchNameKana=${branchNameKana}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },
  async GET_DETAIL_BRANCH({ branchId = '' }) {
    const result = callApi({}, { endpoint: `master-setting-manager/branch/get-branch/${branchId}`, method: 'GET' });
    if (result) return result;
  },
  async UPDATE_BRANCH_MASTER({ body, branchId = '' }) {
    const result = callApi(body, {
      endpoint: `master-setting-manager/branch/update-branch-master/${branchId}`,
      method: 'PUT',
    });
    if (result) return result;
  },
  async CREATE_STAFF_BRANCH_MASTER(body) {
    const result = callApi(body, { endpoint: 'master-setting-manager/staff/create-staff', method: 'POST' });
    if (result) return result;
  },
  async GET_LIST_STAFF_BY_BRANCH({
    page = 1,
    limit = 10,
    firstNameKana = '',
    lastNameKana = '',
    loginId = '',
    phoneNumber = '',
    branchId = '',
  }) {
    const result = callApi(
      {},
      {
        endpoint: `master-setting-manager/staff/list-staff-by-branch?page=${page}&limit=${limit}&firstNameKana=${firstNameKana}&lastNameKana=${lastNameKana}&loginId=${loginId}&phoneNumber=${phoneNumber}&branchId=${branchId}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },
  async GET_DETAIL_BRANCH_STAFF({ staffId = '' }) {
    const result = callApi({}, { endpoint: `master-setting-manager/staff/${staffId}`, method: 'GET' });
    if (result) return result;
  },
  async GET_LIST_LOG_UPDATE_STAFF({ staffId = '' }) {
    const result = callApi({}, { endpoint: `master-setting-manager/staff/staff-log/${staffId}`, method: 'GET' });
    if (result) return result;
  },
  async UPDATE_BRANCH_STAFF({ body, id = '' }) {
    const result = callApi(body, {
      endpoint: `master-setting-manager/staff/update-staff?staffBranchId=${id}`,
      method: 'PUT',
    });
    if (result) return result;
  },
};

export const IMPORT_GOODS_ENDPOINT = {
  async GET_LIST_IMPORT_GOODS_INFO({ page = 0, limit = 10 }) {
    const result = callApi(
      {},
      {
        endpoint: `order-manager/import-goods/list-import-goods-info?page=${page}&limit=${limit}&status=["in_progress","pending","cancel"]`,
        method: 'GET',
      }
    );
    if (result) return result;
  },
  async GET_LIST_IMPORT_GOODS_INFO_FINISH({ page = 0, limit = 10 }) {
    const result = callApi(
      {},
      {
        endpoint: `order-manager/import-goods/list-import-goods-info?page=${page}&limit=${limit}&status=["in_progress","finish"]`,
        method: 'GET',
      }
    );
    if (result) return result;
  },
  async CREATE_IMPORT_GOODS(body) {
    const result = callApi(body, { endpoint: 'order-manager/import-goods/create-import-goods', method: 'POST' });
    if (result) return result;
  },
  async GET_DETAIL({ id = '' }) {
    const result = callApi({}, { endpoint: `order-manager/import-goods/get-detail/${id}`, method: 'GET' });
    if (result) return result;
  },
  async UPDATE_IMPORT_GOODS({ body, id = '' }) {
    const result = callApi(body, { endpoint: `order-manager/import-goods/update-import-goods/${id}`, method: 'PUT' });
    if (result) return result;
  },
  async IMPORT_CSV(body) {
    const result = uploadFile(body, { endpoint: 'order-manager/import-goods/import-csv', method: 'POST' });
    if (result) return result;
  },
};

export const EXPORT_GOODS_ENDPOINT = {
  async GET_LIST_EXPORT_GOODS_INFO({ page = 0, limit = 10 }) {
    const result = callApi(
      {},
      {
        endpoint: `order-manager/export-goods/list-export-goods-info?page=${page}&limit=${limit}&status=["in_progress","pending","cancel"]`,
        method: 'GET',
      }
    );
    if (result) return result;
  },
  async GET_LIST_EXPORT_GOODS_INFO_FINISH({ page = 0, limit = 10 }) {
    const result = callApi(
      {},
      {
        endpoint: `order-manager/export-goods/list-export-goods-info?page=${page}&limit=${limit}&status=["in_progress","finish"]`,
        method: 'GET',
      }
    );
    if (result) return result;
  },
  async CREATE_EXPORT_GOODS(body) {
    const result = callApi(body, { endpoint: 'order-manager/export-goods/create-export-goods', method: 'POST' });
    if (result) return result;
  },
  async GET_DETAIL({ id = '' }) {
    const result = callApi({}, { endpoint: `order-manager/export-goods/get-detail/${id}`, method: 'GET' });
    if (result) return result;
  },
  async UPDATE_EXPORT_GOODS({ body, id = '' }) {
    const result = callApi(body, { endpoint: `order-manager/export-goods/update-export-goods/${id}`, method: 'PUT' });
    if (result) return result;
  },
  async IMPORT_CSV(body) {
    const result = uploadFile(body, { endpoint: 'order-manager/export-goods/import-csv', method: 'POST' });
    if (result) return result;
  },
};

export const DEVICE_ENDPOINT = {
  async GET_LIST_DEVICE_INFO({ page = 0, limit = 10 }) {
    const result = callApi(
      {},
      { endpoint: `order-manager/device/list-device?page=${page}&limit=${limit}`, method: 'GET' }
    );
    if (result) return result;
  },
  async GET_LIST_PRODUCT_FOR_ORDER({ page = 0 }) {
    const result = callApi({}, { endpoint: `order-manager/product/all-product-for-order?page=${page}`, method: 'GET' });
    if (result) return result;
  },
  async GET_LIST_PRODUCT_BY_PERMISSION({ page = 0 }) {
    const result = callApi({}, { endpoint: `order-manager/product/product-by-permission?page=${page}`, method: 'GET' });
    if (result) return result;
  },
  async CREATE_DEVICE(body) {
    const result = callApi(body, { endpoint: 'order-manager/device/create', method: 'POST' });
    if (result) return result;
  },
  async ADD_FIELD_CSV({ fields, id = '' }) {
    const result = callApi(fields, { endpoint: `order-manager/device/add-fields/${id}`, method: 'PUT' });
    if (result) return result;
  },
  async UPLOAD_FILE({ body = {}, id = '' }) {
    const result = uploadFile(body, { endpoint: `order-manager/device/upload-file/${id}`, method: 'PUT' });
    if (result) return result;
  },
  async GET_DETAIL_DEVICE({ id = '' }) {
    const result = callApi({}, { endpoint: `order-manager/device/detail-device?deviceId=${id}`, method: 'GET' });
    if (result) return result;
  },
  async UPDATE_DEVICE({ body, id = '' }) {
    const result = callApi(body, { endpoint: `order-manager/device/update/${id}`, method: 'PUT' });
    if (result) return result;
  },
  async GET_DEFAULT_CSV({ id = '' }) {
    const result = callApi({}, { endpoint: `order-manager/device/device-csv/${id}`, method: 'GET' });
    if (result) return result;
  },
};

export const VERSION_SETTING_ENDPOINT = {
  async MENU_BY_SERVICE({ id = '', serviceProductId = '' }) {
    const result = callApi(
      {},
      {
        endpoint: `master-setting-manager/version-menu-setting/menu-by-service?versionServiceId=${id}&serviceProductId=${serviceProductId}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },
  async CREATE_UPDATE(body) {
    const result = callApi(body, {
      endpoint: 'master-setting-manager/version-menu-setting/create-update',
      method: 'POST',
    });
    if (result) return result;
  },
  async DELETE_VERSION({ versionServiceId = '', menuSettingId = '', serviceProductId = '', menuName = '' }) {
    const result = callApi(
      {},
      {
        endpoint: `master-setting-manager/version-menu-setting/delete?screenName=${SCREEN_NAME.WORKER_SETTINGS}&versionServiceId=${versionServiceId}&menuSettingId=${menuSettingId}&serviceProductId=${serviceProductId}&menuName=${menuName}`,
        method: 'DELETE',
      }
    );
    if (result) return result;
  },
};
export const VERSION_SERVICE = {
  async GET_LIST_VERSION_SERVICE({ serviceId = '', productId = '' }) {
    const result = callApi(
      {},
      {
        endpoint: `master-setting-manager/version-service/get-version-by-service?serviceId=${serviceId}&productId=${productId}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },
  async EDIT_VERSION_SERVICE({ body }) {
    const result = callApi(body, {
      endpoint: 'master-setting-manager/version-service/create-or-update',
      method: 'POST',
    });
    if (result) return result;
  },
  async DELETE_VERSION_SERVICE({ sortKey = '', serviceProductId = '' }) {
    const result = callApi(
      {},
      {
        endpoint: `master-setting-manager/version-service/delete?screenName=${SCREEN_NAME.WORKER_SETTINGS}&sortKey=${sortKey}&serviceProductId=${serviceProductId}`,
        method: 'DELETE',
      }
    );
    if (result) return result;
  },
};

export const WORKER_ITEM_SETTINGS = {
  async GET_LIST_WORKER_ITEM_SETTINGS({
    menuSettingId = '',
    versionServiceId = '',
    serviceProductId = '',
    menuName = '',
  }) {
    const result = callApi(
      {},
      {
        endpoint: `master-setting-manager/version-menu-item-setting/get-version-by-service?menuSettingId=${menuSettingId}&versionServiceId=${versionServiceId}&serviceProductId=${serviceProductId}&menuName=${menuName}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },
  async EDIT_WORKER_ITEM_SETTINGS({ body }) {
    const result = callApi(body, {
      endpoint: 'master-setting-manager/version-menu-item-setting/create-or-update',
      method: 'POST',
    });
    if (result) return result;
  },

  async GET_CSV_EXPORT({ productId = '' }) {
    const result = callApi(
      {},
      { endpoint: `master-setting-manager/version-menu-item-setting/get-csv-export/${productId}`, method: 'GET' }
    );
    if (result) return result;
  },

  async DELETE_WORKER_ITEM_SETTINGS({
    menuSettingId = '',
    menuItemSettingId = '',
    versionServiceId = '',
    serviceProductId = '',
    menuName = '',
  }) {
    const result = callApi(
      {},
      {
        endpoint: `master-setting-manager/version-menu-item-setting/delete?screenName=${SCREEN_NAME.WORKER_SETTINGS}&menuSettingId=${menuSettingId}&menuItemSettingId=${menuItemSettingId}&versionServiceId=${versionServiceId}&serviceProductId=${serviceProductId}&menuName=${menuName}`,
        method: 'DELETE',
      }
    );
    if (result) return result;
  },
};

export const ANKEN_MASTER_NEW = {
  async GET_TREE_BY_PROJECT() {
    const result = callApi({}, { endpoint: 'master-setting-manager/project/get-tree-by-project', method: 'GET' });
    if (result) return result;
  },
  async GET_ORDER_SETTING_BY_PRODUCT({ role = '', nameScreen = '', idProduct = '' }) {
    const result = callApi(
      {},
      {
        endpoint: `master-setting-manager/order-setting/get-order-setting-by-product?role=${role}&screenName=${nameScreen}&productId=${idProduct}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },
  async SETTING_ORDER_HEADER_BY_PRODUCT({ body, idProduct = '' }) {
    const result = callApi(body, {
      endpoint: `master-setting-manager/order-setting/setting-order-header-by-product/${idProduct}`,
      method: 'POST',
    });
    if (result) return result;
  },
  async GET_PRODUCT_NONE_PROJECT({ clientId = '' }) {
    const result = callApi(
      {},
      { endpoint: `master-setting-manager/product/get-product-none-project?clientId=${clientId}`, method: 'GET' }
    );
    if (result) return result;
  },
  async GET_ACCESS_USER({ productId }) {
    const result = callApi(
      {},
      { endpoint: `master-setting-manager/permission-setting/get-user-set-access/${productId}`, method: 'GET' }
    );
    if (result) return result;
  },
  async ADD_ACCESS_USER({ body, productId }) {
    const result = callApi(body, {
      endpoint: `master-setting-manager/permission-setting/setting-permission-setting-by-product-id/${productId}`,
      method: 'POST',
    });
    if (result) return result;
  },
  async GET_RESULT_SETTINGS({ serviceId, productId }) {
    const result = callApi(
      {},
      {
        endpoint: `master-setting-manager/completion-result-setting/get-detail-completion-result-setting?serviceId=${serviceId}&productId=${productId}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },
  async ADD_RESULT_SETTINGS({ body, serviceId, productId }) {
    const result = callApi(body, {
      endpoint: `master-setting-manager/completion-result-setting/create-or-update-completion-result-setting?serviceId=${serviceId}&productId=${productId}`,
      method: 'POST',
    });
    if (result) return result;
  },
  async GET_CANCEL_SETTINGS({ serviceId, productId }) {
    const result = callApi(
      {},
      {
        endpoint: `master-setting-manager/completion-result-setting/get-detail-cancel-setting?serviceId=${serviceId}&productId=${productId}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },
  async ADD_CANCEL_SETTINGS({ body, serviceId, productId }) {
    const result = callApi(body, {
      endpoint: `master-setting-manager/completion-result-setting/create-or-update-cancel-setting?serviceId=${serviceId}&productId=${productId}`,
      method: 'POST',
    });
    if (result) return result;
  },
  async GET_ALERT_SETTINGS({ serviceId, productId }) {
    const result = callApi(
      {},
      {
        endpoint: `master-setting-manager/completion-result-setting/get-detail-alert-setting?serviceId=${serviceId}&productId=${productId}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },
  async ADD_ALERT_SETTINGS({ body, serviceId, productId }) {
    const result = callApi(body, {
      endpoint: `master-setting-manager/completion-result-setting/create-or-update-alert-setting?serviceId=${serviceId}&productId=${productId}`,
      method: 'POST',
    });
    if (result) return result;
  },
  async GET_CODE_PROJECT() {
    const result = callApi({}, { endpoint: 'master-setting-manager/project/get-code-project', method: 'GET' });
    if (result) return result;
  },
  async CREATE_PROJECT({ body }) {
    const result = callApi(body, { endpoint: 'master-setting-manager/project/create-project', method: 'POST' });
    if (result) return result;
  },
  async GET_CODE_PRODUCT() {
    const result = callApi({}, { endpoint: 'master-setting-manager/product/product-id', method: 'GET' });
    if (result) return result;
  },
  async CREATE_PRODUCT({ body }) {
    const result = callApi(body, { endpoint: 'master-setting-manager/product/create', method: 'POST' });
    if (result) return result;
  },
  async GET_ALL_SERVICE_BY_ORGANIZATION({ name = '' }) {
    const result = callApi(
      {},
      { endpoint: `master-setting-manager/service/get-service-by-organization?name=${name}`, method: 'GET' }
    );
    if (result) return result;
  },
  async GET_SERVICE_BY_ORGANIZATION({ name = '' }) {
    const result = callApi(
      {},
      { endpoint: `master-setting-manager/service/get-service-list-active?name=${name}`, method: 'GET' }
    );
    if (result) return result;
  },
  async CREATE_SERVICE({ body }) {
    const result = callApi(body, { endpoint: 'master-setting-manager/service/create-service', method: 'POST' });
    if (result) return result;
  },
  async UPDATE_SERVICE({ body }) {
    const result = callApi(body, { endpoint: 'master-setting-manager/service/update', method: 'PUT' });
    if (result) return result;
  },
  async ADD_PRODUCT_IN_PROJECT({ body, idProject = '' }) {
    const result = callApi(body, {
      endpoint: `master-setting-manager/product/add-project/${idProject}`,
      method: 'PUT',
    });
    if (result) return result;
  },
  async ADD_SERVICE_IN_PRODUCT({ body, productId = '' }) {
    const result = callApi(body, {
      endpoint: `master-setting-manager/product/add-service/${productId}`,
      method: 'PUT',
    });
    if (result) return result;
  },
  async GET_LIST_MATERIAL({ productId = '', projectId = '' }) {
    const result = callApi(
      {},
      {
        endpoint: `master-setting-manager/material-type/list-material?productId=${productId}&projectId=${projectId}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },
  async ADD_MATERIAL({ body }) {
    const result = callApi(body, { endpoint: 'master-setting-manager/material-type/add-material', method: 'POST' });
    if (result) return result;
  },
  async UPDATE_LIST_MATERIAL({ body }) {
    const result = callApi(body, { endpoint: 'master-setting-manager/material-type/update-material', method: 'PUT' });
    if (result) return result;
  },

  async GET_LIST_SELECT_MATERIAL({ productId = '', projectId = '' }) {
    const result = callApi(
      {},
      {
        endpoint: `master-setting-manager/material-type/list-select-material?productId=${productId}&projectId=${projectId}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },
  async ADD_DOCUMENT_SETTING({ body }) {
    const result = callApi(body, {
      endpoint: 'master-setting-manager/document-setting/add-document-setting',
      method: 'POST',
    });
    if (result) return result;
  },
  async GET_LIST_DOCUMENT_SETTING({ productId = '', projectId = '' }) {
    const result = callApi(
      {},
      {
        endpoint: `master-setting-manager/document-setting/list-document-setting?productId=${productId}&projectId=${projectId}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },
  async UPLOAD_FILE_DOCUMENT_SETTING({ body, documentSettingId }) {
    const result = callApi(body, {
      endpoint: `master-setting-manager/document-setting/upload-file/${documentSettingId}`,
      method: 'POST',
    });
    if (result) return result;
  },
  async DELETE_DOCUMENT_SETTING({ documentSettingId }) {
    const result = callApi(
      {},
      { endpoint: `master-setting-manager/document-setting/delete/${documentSettingId}`, method: 'DELETE' }
    );
    if (result) return result;
  },
  async GET_TREE_DOCUMENT_SETTING({ productId = '', projectId = '' }) {
    const result = callApi(
      {},
      {
        endpoint: `master-setting-manager/document-setting/get-tree-document?productId=${productId}&projectId=${projectId}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },

  async GET_TREE_DOCUMENT_DETAIL_ORDER({ orderId = '', organizationId = '' }) {
    const result = callApi(
      {},
      { endpoint: `order-manager/order-common/get-tree-document-setting/${orderId}?organizationId=${organizationId}`, method: 'GET' }
    );
    if (result) return result;
  },
  async UPDATE_DOCUMENT_SETTING({ body }) {
    const result = callApi(body, {
      endpoint: 'master-setting-manager/document-setting/update-document-setting',
      method: 'PUT',
    });
    if (result) return result;
  },
};

export const PERMISSION_ROLE = {
  async CHECK_ROLE({ roleId = '' }) {
    const result = callApi(
      {},
      {
        endpoint: `auth-manager/user/check-role/${roleId}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },
};

export const SCREEN_SETTING = {
  async GET_DATA_SEARCH({ idScreenSetting = '' }) {
    const result = callApi(
      {},
      {
        endpoint: `master-setting-manager/screen-setting/${idScreenSetting}/data-search`,
        method: 'GET',
      }
    );
    if (result) return result;
  },
};

export const CONTRACT_ENDPOINTS = {
  async DELETE_TARIFF_SETTING({ tariffSettingId = '' }) {
    const result = callApi(
      {},
      {
        endpoint: `contract-manager/tariff-setting/delete-tariff-setting/${tariffSettingId}`,
        method: 'DELETE',
      }
    );
    if (result) return result;
  },

  async GET_PROVIDER_CONTRACT({ projectId = '', productId = '' }) {
    const result = callApi(
      {},
      {
        endpoint: `contract-manager/tariff-setting/get-provider-contract?projectId=${projectId}&productId=${productId}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },

  async GET_CONTRACT_MANAGEMENTS({ partnerId, clientId, page = 1, limit = 10 }) {
    const result = callApi(
      {},
      {
        endpoint: `contract-manager/contract-estimate/get-contract-estimate-filter-id?page=${page}&limit=${limit}&partnerId=${partnerId || ''}&clientId=${clientId || ''}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },

  async CREATE_TARIFF_SETTING(body) {
    const result = callApi(body, { endpoint: 'contract-manager/tariff-setting/create-tariff-setting', method: 'POST' });
    if (result) return result;
  },

  async UPDATE_TARIFF_SETTING({ tariffId, body }) {
    const result = callApi(body, {
      endpoint: `contract-manager/tariff-setting/update-tariff-setting/${tariffId}`,
      method: 'PUT',
    });
    if (result) return result;
  },

  async UPDATE_TARIFF_SETTING_CLASSIFICATION({ tariffId, body }) {
    const result = callApi(body, {
      endpoint: `contract-manager/tariff-setting/update-tariff-setting-classification/${tariffId}`,
      method: 'PUT',
    });
    if (result) return result;
  },

  async UPDATE_TARIFF_SETTING_CLAIM({ tariffSettingId, body }) {
    const result = callApi(body, {
      endpoint: `contract-manager/tariff-setting/update-tariff-setting-claim/${tariffSettingId}`,
      method: 'PUT',
    });
    if (result) return result;
  },

  async UPDATE_TARIFF_SETTING_PAYMENT({ tariffSettingId, body }) {
    const result = callApi(body, {
      endpoint: `contract-manager/tariff-setting/update-tariff-setting-payment/${tariffSettingId}`,
      method: 'PUT',
    });
    if (result) return result;
  },

  async GET_TARIFF_SETTING_BILLING_MASTER({ tariffId = '' }) {
    const result = callApi(
      {},
      {
        endpoint: `contract-manager/tariff-setting/get-tariff-setting-billing-master?tariffId=${tariffId}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },

  async GET_TARIFF_SETTING_PAYMENT_MASTER({ tariffId = '' }) {
    const result = callApi(
      {},
      {
        endpoint: `contract-manager/tariff-setting/get-tariff-setting-payment-master?tariffId=${tariffId}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },

  async GET_TREE_PRODUCT_ID({ productId = '', organizationId = '' }) {
    const result = callApi(
      {},
      {
        endpoint: `contract-manager/recruitment/tree-by-productId/${productId}?organizationId=${organizationId}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },

  async GET_TARIFF_SETTING_LIST({ tariffId = '' }) {
    const result = callApi(
      {},
      {
        endpoint: `contract-manager/tariff-setting/get-tariff-setting-list?tariffId=${tariffId}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },

  async GET_TARIFF_SETTING_EDIT({ tariffId = '' }) {
    const result = callApi(
      {},
      {
        endpoint: `contract-manager/tariff-setting/get-tariff-setting-edit?tariffId=${tariffId}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },

  async GET_TARIFF_LIST({ page = 1, limit = 10 }) {
    const result = callApi(
      {},
      {
        endpoint: `contract-manager/tariff/get-tariff-list?page=${page}&limit=${limit}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },

  async UPDATE_TARIFF(body) {
    const result = callApi(body, { endpoint: 'contract-manager/tariff/update-tariff', method: 'POST' });
    if (result) return result;
  },

  async GET_ALL_CONTRACT({ page = 1, limit = 10 }) {
    const result = callApi(
      {},
      {
        endpoint: `contract-manager/recruitment-contract/get-all-contract?page=${page}&limit=${limit}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },

  async GET_LIST_RECRUITMENT({ page = 1, limit = 10 }) {
    const result = callApi(
      {},
      {
        endpoint: `contract-manager/recruitment/list-recruitment?page=${page}&limit=${limit}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },
  async GET_LIST_MONITOR({ recruitmentContractId = '' }) {
    const result = callApi(
      {},
      {
        endpoint: `contract-manager/recruitment-contract/get-monitors${recruitmentContractId ? `?recruitmentContractId=${recruitmentContractId}` : ''
          }`,
        method: 'GET',
      }
    );
    if (result) return result;
  },
  async GET_LIST_PROJECT({ recruitmentContractId = '' }) {
    const result = callApi(
      {},
      {
        endpoint: `contract-manager/recruitment-contract/get-projects${recruitmentContractId ? `?recruitmentContractId=${recruitmentContractId}` : ''
          }`,
        method: 'GET',
      }
    );
    if (result) return result;
  },
  async GET_LIST_PRODUCT({ projectId = '' }) {
    const result = callApi(
      {},
      {
        endpoint: `contract-manager/recruitment-contract/get-products?projectId=${projectId}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },
  async GET_LIST_SERVICE({ productId = '', projectId = '' }) {
    const result = callApi(
      {},
      {
        endpoint: `contract-manager/recruitment-contract/get-services?productId=${productId}&projectId=${projectId}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },
  async GET_LIST_ESTIMATE_DONE({ page = 1, limit = 10, pageMonitor = 1, limitMonitor = 10, sortRecruitment = '' }) {
    const result = callApi(
      {},
      {
        endpoint: `contract-manager/contract-estimate/get-contract-estimate-done?page=${page}&limit=${limit}&pageMonitor=${pageMonitor}&limitMonitor=${limitMonitor}&sortRecruitment=${sortRecruitment}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },

  async GET_CSV_CONTRACT_ESTIMATE() {
    const result = callApi(
      {},
      {
        endpoint: `contract-manager/contract-estimate/csv-contract-estimate`,
        method: 'GET',
      }
    );
    if (result) return result;
  },

  async CREATE_ADD_PROVIDER(body) {
    const result = callApi(body, { endpoint: 'contract-manager/recruitment-contract/add-provider', method: 'POST' });
    if (result) return result;
  },
  async GET_DETAIL_RECRUITMENT({ recruitmentContractId = '', organizationId = '' }) {
    const result = callApi(
      {},
      {
        endpoint: `contract-manager/recruitment/get-detail/${recruitmentContractId}?organizationId=${organizationId}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },

  async GET_INFO_CONTRACT({ recruitmentId = '', partnerId = '' }) {
    const result = callApi(
      {},
      {
        endpoint: `contract-manager/recruitment/get-info-contract/${recruitmentId}?partnerId=${partnerId}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },

  async UPDATE_INFO_CONTRACT_PARTNER({ body, recruitmentId, organizationId }) {
    const result = callApi(body, {
      endpoint: `contract-manager/recruitment/update-info-contract/${recruitmentId}?organizationId=${organizationId}`,
      method: 'PUT',
    });
    if (result) return result;
  },

  async UPDATE_REJECT_CONTRACT({ recruitmentId }) {
    const result = callApi({}, {
      endpoint: `contract-manager/contract-estimate/reject-contract/${recruitmentId}`,
      method: 'PUT',
    });
    if (result) return result;
  },

  async CREATE_RECRUITMENT_CONTRACT({ body }) {
    const result = callApi(body, { endpoint: 'contract-manager/recruitment/create-update', method: 'POST' });
    if (result) return result;
  },
  async GET_RECRUITMENT_DATA({ organizationId = '', recruitmentId = '' }) {
    const result = callApi(
      {},
      { endpoint: `contract-manager/recruitment-contract/get-recruitment-data?organizationId=${organizationId}&recruitmentId=${recruitmentId}`, method: 'GET' }
    );
    if (result) return result;
  },
  async UPLOAD_FILE_CONTRACT({ body, organizationId = '' }) {
    const result = callApi(body, { endpoint: `contract-manager/recruitment/upload?organizationId=${organizationId}`, method: 'POST' });
    if (result) return result;
  },
  async UPLOAD_FILE_RECRUITMENT_ESTIMATE({ body }) {
    const result = callApi(body, { endpoint: 'contract-manager/contract-estimate/upload', method: 'PUT' });
    if (result) return result;
  },
  async REMOVE_FILE({ body, recruitmentContractId = '' }) {
    const result = callApi(body, { endpoint: `contract-manager/recruitment/remove-file/${recruitmentContractId}`, method: 'PUT' });
    if (result) return result;
  },
  async REMOVE_FILE_ESTIMATE({ body, recruitmentEstimateId = '' }) {
    const result = callApi(body, { endpoint: `contract-manager/contract-estimate/remove-file/${recruitmentEstimateId}`, method: 'PUT' });
    if (result) return result;
  },
  async CREATE_CREDIT_IN_RECRUITMENT_CONTRACT({ body }) {
    const result = callApi(body, {
      endpoint: 'contract-manager/contract-estimate/create-update-credit',
      method: 'POST',
    });
    if (result) return result;
  },
  async UPDATE_CONTRACT_PARTNER({ body }) {
    const result = callApi(body, {
      endpoint: 'contract-manager/recruitment/update-contract-partner',
      method: 'POST',
    });
    if (result) return result;
  },
  async CREATE_ESTIMATES_IN_RECRUITMENT_CONTRACT({ body }) {
    const result = callApi(body, {
      endpoint: 'contract-manager/contract-estimate/create-update-estimate',
      method: 'POST',
    });
    if (result) return result;
  },
  async CREATE_NDA_IN_RECRUITMENT_CONTRACT({ body }) {
    const result = callApi(body, {
      endpoint: 'contract-manager/contract-estimate/create-update-nda',
      method: 'POST',
    });
    if (result) return result;
  },
  async CREATE_CONTRACT_STATUS_IN_RECRUITMENT_CONTRACT({ body }) {
    const result = callApi(body, {
      endpoint: 'contract-manager/contract-estimate/create-update-contract-status',
      method: 'POST',
    });
    if (result) return result;
  },
  async CREATE_CONTRACT_UPDATE_SERVICES({ body, contractEstimateId = '' }) {
    const result = callApi(body, {
      endpoint: `contract-manager/contract-estimate/update-services/${contractEstimateId}`,
      method: 'PUT',
    });
    if (result) return result;
  },
  async GET_DETAIL_ESTIMATE({ recruitmentEstimateId = '' }) {
    const result = callApi(
      {},
      {
        endpoint: `contract-manager/contract-estimate/get-detail/${recruitmentEstimateId}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },
  async GET_PARTNERS_BY_RECRUITMENT({ partnerId = '', organizationId = '' }) {
    const result = callApi(
      {},
      {
        endpoint: `contract-manager/recruitment/get-partners-by-recruitment?partnerId=${partnerId}&organizationId=${organizationId}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },
  async GET_LIST_ESTIMATES({ page = 1, limit = 10, pageMonitor = 1, limitMonitor = 10, monitorId = '' }) {
    const result = callApi(
      {},
      {
        endpoint: `contract-manager/contract-estimate/get-contract-estimate?page=${page}&limit=${limit}&pageMonitor=${pageMonitor}&limitMonitor=${limitMonitor}&monitorId=${monitorId}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },

  async GET_DATA_CSV_CONTRACT_BY_PRODUCT() {
    const result = callApi(
      {},
      {
        endpoint: `contract-manager/recruitment/get-data-csv`,
        method: 'GET',
      }
    );
    if (result) return result;
  },

  async GET_LIST_CONTRACTS({ page = 1, limit = 10, pageContract = 1, limitContract = 10, contractId = '' }) {
    const result = callApi(
      {},
      {
        endpoint: `contract-manager/recruitment-contract/list-contract-group?page=${page}&limit=${limit}&pageContract=${pageContract}&limitContract=${limitContract}&contractId=${contractId}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },

  async GET_LIST_PARTNERS({ recruitmentId = '', organizationId = '' }) {
    const result = callApi(
      {},
      {
        endpoint: `contract-manager/recruitment/list-partner?recruitmentId=${recruitmentId}&organizationId=${organizationId}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },

  async GET_LIST_CONTRACT_ESTIMATE_PARTNER({ page = 1, limit = 10, permissionIdGroup = '' }) {
    const result = callApi(
      {},
      {
        endpoint: `contract-manager/contract-estimate/get-contract-estimate-partner?page=${page}&limit=${limit}`,
        method: 'GET',
        permissionIdGroup: permissionIdGroup
      }
    );
    if (result) return result;
  },

  async GET_LIST_CONTRACT_RECRUITMENT({ page = 1, limit = 10, pageContract = 1, limitContract = 10, sortProductId = '' }) {
    const result = callApi(
      {},
      {
        endpoint: `contract-manager/recruitment/list-recruitment-by-product?page=${page}&limit=${limit}&pageEsitmate=${pageContract}&limitEstimate=${limitContract}&sortProductId=${sortProductId}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },
};

export const DISPATCH = {
  async GET_WORKING_SCHEDULE({
    visitDate = '',
    orderId = '',
    organizationId = '',
    page = 1,
    limit = 10,
    workerName = '',
    workerContact = '',
    workerId = '',
    partnerName = '',
    freeKey = '',
  }) {
    const result = callApi(
      {},
      {
        endpoint: `master-setting-manager/dispatch/working-schedule?visitDate=${visitDate}&orderId=${orderId}&organizationId=${organizationId}&workerName=${workerName}&workerContact=${workerContact}&workerId=${workerId}&partnerName=${partnerName}&freeKey=${freeKey}&pageProduct=${page}&limitProduct=${limit}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },
  async REMOVE_TASK({ body }) {
    const { orderId, organizationId, staffId, visitDate } = body;
    const result = callApi(
      {},
      {
        endpoint: `master-setting-manager/dispatch/remove-task?orderId=${orderId}&organizationId=${organizationId}&staffId=${staffId}&visitDate=${visitDate}`,
        method: 'DELETE',
      }
    );
    if (result) return result;
  },

  async ADD_TASK({ body }) {
    const result = callApi(body, { endpoint: 'master-setting-manager/dispatch/add-task', method: 'POST' });
    if (result) return result;
  },

  async ADD_LEAD({ body }) {
    const result = callApi(body, { endpoint: 'master-setting-manager/dispatch/add-lead', method: 'PUT' });
    if (result) return result;
  },

  async GET_ALL_SERVICE({ visitDate = '' }) {
    const result = callApi(
      {},
      { endpoint: `master-setting-manager/dispatch/all-service?visitDate=${visitDate}`, method: 'GET' }
    );
    if (result) return result;
  },

  async DISPATCH_ALL({ visitDate = '', screenName, updateTitle }) {
    const body = { visitDate, screenName, updateTitle };
    const result = callApi(body, { endpoint: 'master-setting-manager/dispatch/dispatch-all', method: 'POST' });
    if (result) return result;
  },

  async GET_OPTION_DISPATCH({ productId }) {
    const result = callApi(
      {},
      { endpoint: `master-setting-manager/dispatch/get-option-header/${productId}`, method: 'GET' }
    );
    if (result) return result;
  },

  async GET_PRODUCT_DISPATCH({ limit = 10, page = 1 }) {
    const result = callApi(
      {},
      { endpoint: `order-manager/staff/product-by-organization?limit=${limit}&page=${page}`, method: 'GET' }
    );
    if (result) return result;
  },

  async GET_SERVICE_BY_PRODUCT({ productId }) {
    const result = callApi({}, { endpoint: `order-manager/service/service-by-product/${productId}`, method: 'GET' });
    if (result) return result;
  },

  async CREATE_OPTION_DISPATCH({ body, productId }) {
    const result = callApi(body, {
      endpoint: `master-setting-manager/dispatch/create-update-option-header/${productId}`,
      method: 'POST',
    });
    if (result) return result;
  },

  async UPDATE_OPTION_DISPATCH({ body, productId }) {
    const result = callApi(body, {
      endpoint: `master-setting-manager/dispatch/create-update-option-content/${productId}`,
      method: 'POST',
    });
    if (result) return result;
  },

  async UPDATE_DISPATCH_TASK({ body, idDispatchLog }) {
    const result = callApi(body, {
      endpoint: `master-setting-manager/dispatch/update-task/${idDispatchLog}`,
      method: 'PUT',
    });
    if (result) return result;
  },

  async GET_ALL_AREA({ visitDate = '' }) {
    const result = callApi(
      {},
      { endpoint: `master-setting-manager/dispatch/get-area?visitDate=${visitDate}`, method: 'GET' }
    );
    if (result) return result;
  },

  async GET_DATA_BY_VISITDATE_AND_AREA({ visitDate = '', area = '' }) {
    const result = callApi(
      {},
      {
        endpoint: `master-setting-manager/dispatch/get-data-by-visitDate-and-area?visitDate=${visitDate}&area=${area}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },

  async UPDATE_POSITION_STAFF({ body, staffId }) {
    const result = callApi(body, {
      endpoint: `master-setting-manager/dispatch/update-position-staff/${staffId}`,
      method: 'PUT',
    });
    if (result) return result;
  },

  async EXPORT_CSV_DISPATCH({ type, visitDate, productId = '', screenName = '', updateTitle = '' }) {
    const result = callApi(
      { screenName, updateTitle },
      {
        endpoint: `master-setting-manager/dispatch/export-csv-dispatch?type=${type}&visitDate=${visitDate}&productId=${productId}`,
        method: 'PUT',
      }
    );
    if (result) return result;
  },

  async UPDATE_DISPATCH_SHIFT({ body }) {
    const result = callApi(body, {
      endpoint: 'master-setting-manager/dispatch/update-status-dispatch-shift',
      method: 'PUT',
    });
    if (result) return result;
  },

  async RESET_DISPATCH({ visitDate = '', body }) {
    const result = callApi(
      {...body},
      {
        endpoint: `master-setting-manager/dispatch/reset-dispatch?visitDate=${visitDate}`,
        method: 'PUT',
      }
    );
    if (result) return result;
  },
};

export const ACCOUNTING_ENDPOINTS = {
  async GET_LIST_ACCOUNTING({
    page = 1,
    limit = 10,
    pageAccount = 1,
    limitAccount = 10,
    prefecturesVisited = '',
    visitedBaseName = '',
    partnerName = '',
    productName = '',
    caseName = '',
    managementCode = '',
    serviceName = '',
    requestedDateTo = '',
    visitDateTo = '',
    visitDateFrom = '',
    requestedDateFrom = '',
    operationAdjustmentDateTo = '',
    operationAdjustmentDateFrom = '',
    applicantCompanyName = '',
    resultsPreparation = '',
    productId = '',
    sortBy = '',
    sortWith = '',
    sortProductId = '',
  }) {
    const result = callApi(
      {},
      {
        endpoint: `accounting-manager/settlement/get-accounts?page=${page}&limit=${limit}&pageAccount=${pageAccount}&limit=${limitAccount}&prefecturesVisited=${prefecturesVisited}&visitedBaseName=${visitedBaseName}&partnerName=${partnerName}&caseName=${caseName}&productName=${productName}&managementCode=${managementCode}&serviceName=${serviceName}&requestedDateTo=${requestedDateTo}&visitDateTo=${visitDateTo}&visitDateFrom=${visitDateFrom}&requestedDateFrom=${requestedDateFrom}&operationAdjustmentDateTo=${operationAdjustmentDateTo}&operationAdjustmentDateFrom=${operationAdjustmentDateFrom}&applicantCompanyName=${applicantCompanyName}&resultsPreparation=${resultsPreparation}&productId=${productId}&sortBy=${sortBy}&sortWith=${sortWith}&sortProductId=${sortProductId}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },

  async LIST_OF_ACCEPTANCE_MONTHS({ page = 1, limit = 10, paramsSearch = '' }) {
    const result = callApi(
      {},
      {
        endpoint: `accounting-manager/settlement/list-of-acceptance-months?page=${page}&limit=${limit}&paramsSearch=${paramsSearch}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },

  async LIST_OF_PAYMENT_MONTHS({ page = 1, limit = 10, paramsSearch = '' }) {
    const result = callApi(
      {},
      {
        endpoint: `accounting-manager/settlement/list-of-payment-months?page=${page}&limit=${limit}&paramsSearch=${paramsSearch}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },
};

export const COMMON_ENDPOINT = {
  async GET_LIST_AREA() {
    const result = callApi(
      {},
      {
        endpoint: 'master-setting-manager/branch/get-list-address',
        method: 'GET',
      }
    );

    if (result) return result;
  },
};

export const STAFF_WORKING_SCHEDULE = {
  async GET_WORKING_SCHEDULE_STAFF({ workerCalendarId = '', staffId = '' }) {
    const result = callApi(
      {},
      {
        endpoint: `master-setting-manager/worker-calendar/get-worker-calendar?workerCalendarId=${workerCalendarId}&staffId=${staffId}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },

  async GET_LIST_ORDER_BY_STAFF_AND_VISITDATE({ visitDate = '' }) {
    const result = callApi(
      {},
      {
        endpoint: `master-setting-manager/worker-calendar/list-order-by-staff-and-visitDate?visitDate=${visitDate}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },

  async CREATE_UPDATE_CALENDAR({ body }) {
    const result = callApi(body, { endpoint: `master-setting-manager/worker-calendar/create-update`, method: 'POST' });
    if (result) return result;
  },
  async CANCEL_REQUEST_CALENDAR({ body }) {
    const result = callApi(body, { endpoint: `master-setting-manager/worker-calendar/cancel-request`, method: 'PUT' });
    if (result) return result;
  },
};

export const CHAT = {
  async CREATE_ROOM_CHAT({ body }) {
    const result = callApi(body, { endpoint: `chat-manager/room/create-room`, method: 'POST' });
    if (result) return result;
  },

  async GET_TOTAL_MESSAGES({ body = {} }) {
    const result = callApi(body, { endpoint: `chat-manager/room/total-message-user`, method: 'GET' });
    if (result) return result;
  },
};

export const EMAIL_SETTING = {
  async CREATE_EMAIL_SETTING({ body, id, idProvide = '' }) {
    const result = callApi(body, {
      endpoint: 'master-setting-manager/mail-setting/create-or-update-multiple',
      method: 'POST',
      id,
      idProvide,
    });
    if (result) return result;
  },

  async GET_LIST_EMAIL_SETTINGS({ idProvide = '', productId, serviceId }) {
    const result = callApi(
      {},
      {
        idProvide,
        endpoint: `master-setting-manager/mail-setting/list-by-product?productId=${productId}&serviceId=${serviceId}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },

  async GET_EMAIL_SETTING(productId, localNum, serviceId) {
    const result = callApi(
      {},
      {
        endpoint: `master-setting-manager/mail-setting/get-detail?productId=${productId}&localNum=${localNum}&serviceId=${serviceId}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },
};

export const PAST_PERFORMANCE = {
  async GET_RECRUIMENT_DONE_BY_PARNER({ page, limit, partnerId, organizationId }) {
    const result = callApi(
      {},
      {
        endpoint: `contract-manager/recruitment/get-recruitment-done-by-partner?page=${page}&limit=${limit}&partnerId=${partnerId}&organizationId=${organizationId}`,
        method: 'GET',
      }
    );
    if (result) return result;
  },
};

export const ACCESS_SETTING = {
  async GET_LIST_ACCESS_SETTING({ userId }) {
    const result = callApi(
      {},
      {
        endpoint: `master-setting-manager/permission-setting/get-detail/${userId}`,
        method: 'GET',
      });
    if (result) return result;
  },

  async UPDATE_ACCESS_SETTING({ body, userId }) {
    const result = callApi(body, {
      endpoint: `master-setting-manager/permission-setting/permission-setting/${userId}`,
      method: 'POST',
    });
    if (result) return result;
  },
};

export const PREPARATION_INFO = {
  async GET_STAFF_BY_ORDER_PREPARATION({ orderId = '', organizationId = '' }) {
    const result = callApi(
      {},
      {
        endpoint: `order-manager/order/get-staffs-by-order-preparation/${orderId}?organizationId=${organizationId}`,
        method: 'GET',
      });
    if (result) return result;
  },
};
