import { pushToast } from 'components/Toast';
import { PLUGIN_SETTING } from '../../../../constants/endpoints';

import React, { useEffect, useState } from 'react';

const ModalConfirmListItemSetting = ({ colors, productId, serviceId, pluginSettingId = '', orderId, nameSetting='' }) => {
  const [color, setColor] = useState('');
  const [listPlugging, setlistPlugging] = useState([]);
  const getListdata = async ({ pluginSettingId, nameSetting }) => {
    try {
      const data = await PLUGIN_SETTING.GET_LIST_ORDER_PLUGIN_SETTINGS({ pluginSettingId, nameSetting })
      if (data?.data) {
        setlistPlugging(data?.data)
      }
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
      }
    } catch (e) {
      console.log(e);
    }
  }
  useEffect(() => {
    getListdata({ pluginSettingId, nameSetting });
    setColor(colors)
  }, [orderId]);

  return (
    <div className="wrapper-master-setting wrapper-master-setting--max-width">
      <div className="body pb-4">
        <div className="wrapper-main">
          <div className="wrapper-result">
            <table className="form-worker">
              <thead>
                {listPlugging.length ? (
                  <tr>
                    <th className="text-center table-setting__th-setting--resize-100" scope="col">見出名</th>
                    <th className="text-center table-setting__th-setting--resize-100" scope="col">区分</th>
                    <th className="text-center table-setting__th-setting--resize-100" scope="col">項目名</th>
                    <th className="text-center table-setting__th-setting--resize-100" scope="col">項目種類</th>
                    <th className="text-center table-setting__th-setting--resize-100" scope="col">内容</th>
                    <th className="text-center table-setting__th-setting--resize-100" scope="col">必須</th>
                  </tr>
                ) : null}
              </thead>
              <tbody>
                {
                  listPlugging?.length ? listPlugging.map((plugging, index) =>
                    (
                      <>
                        <tr key={index} className="item-worker">
                          <td className="table-setting__th-setting--resize-100" >
                            <input
                              type="text"
                              className="form-control text-input"
                              name="classification"
                              value={plugging?.classification}
                              style={{ borderColor: `${color}`, color: `${color}` }}
                              disabled={true}
                            />
                          </td>
                          <td className="table-setting__th-setting--resize-100">
                            <input
                              type="text"
                              className="form-control text-input"
                              name="temp"
                              value={plugging?.content}
                              style={{ borderColor: `${color}`, color: `${color}` }}
                              disabled={true}
                            />
                          </td>
                          <td className="table-setting__th-setting--resize-100">
                            <input
                              type="text"
                              className="form-control text-input"
                              name="name"
                              value={plugging?.name}
                              style={{ borderColor: `${color}`, color: `${color}` }}
                              disabled={true}
                            />
                          </td>
                          <td className="table-setting__th-setting--resize-100">
                            <input
                              type="text"
                              className="form-control text-input"
                              name="content"
                              value={plugging?.temp}
                              style={{ borderColor: `${color}`, color: `${color}` }}
                              disabled={true}
                            />
                          </td>
                          <td className="table-setting__th-setting--resize-100">
                            <div className="list-select">
                              {plugging?.type?.map((elm, index) => (
                                <div
                                  key={index}
                                  className="form-selected mt-0 mb-1"
                                >
                                  <div className="item-selected" style={{ borderColor: `${color}`, color: `${color}` }}>
                                    {elm.name}
                                  </div>
                                </div>
                              ))}
                            </div>
                          </td>
                          <td className="table-setting__th-setting--resize-100">
                            <label className="container-checkbox">
                              <input
                                type="checkbox"
                                name="status"
                                checked={plugging?.status}
                                disabled={true}
                              />
                              <span className="checkmark" disabled={true}></span>
                            </label>
                          </td>
                        </tr>
                      </>
                    )
                  ) : null
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div >
  );
};

export default ModalConfirmListItemSetting;
