import React, { useEffect, useState } from 'react';
import './style.scss';
// import { Link } from 'react-router-dom';
import { ORDER } from '../../../../constants/endpoints';
import PaginationCustom from 'components/PaginationCustom';
import { compose } from 'redux';
import { translate } from 'react-i18next';
import Loading from 'components/Loading/Loading';

const renderTable = (item, element) => {
  if (element === 'status') {
    return item[element] === true
      ? '確認済'
      : item[element] === false
        ? '未確認'
        : ''
  } else {
    return item[element]
  }
};

const renderLinkDetal = (item) => {
  switch (item.type) {
    case 'オーダー承認':
      return <td>
              <a className="btn-edit" href={`/describe-receive-info?orderId=${item?.orderId}&back=mypage`} target="_blank">
                更新
              </a>
            </td>;
    case '稼働調整の承認':
      return <td>
              <a className="btn-edit" href={`/detail-condition-info?orderId=${item?.orderId}&back=mypage`} target="_blank">
                更新
              </a>
            </td>;
    case '前日確認の承認':
      return <td>
              <a className="btn-edit" href={`/detail-list-order?orderId=${item?.orderId}&back=mypage`} target="_blank">
                更新
              </a>
            </td>;
    case '納品承認':
      return <td>
              <a className="btn-edit" href={`/detail-report-upload-info?orderId=${item?.orderId}&back=mypage`} target="_blank">
                更新
              </a>
            </td>;
    default:
      return '';

  }
};

// 　・オーダー承認　　⇒　link detail receiver info
// 　・稼働調整の承認　⇒　link detail condition info
// 　・前日確認の承認　⇒　tomorrow info	link detail tomorrow info
// 　・納品承認　　　　⇒  link detail report upload info

const TableListMyPage = ({ fontSize = 'small', db = 'center', listUser = [], t }) => {
  const [dateInfo, setDataInfo] = useState({});
  const [activePage, setActivePage] = React.useState(1);
  const [loading, setLoading] = useState(false);
  const renderItem = (item, index, fontSize, db) => {

    return (
      <tr key={index}>
        <td>{index + 1}</td>
        {dateInfo.header.map((element, key) => {
        return <td key={key}>{renderTable(item, element)}</td>;
        })}
        {renderLinkDetal(item)}
      </tr>
    );
  };


  const getListOrder= async ({
    page,
    limit,
  }) => {
    setLoading(true);
    try {
      const data = await ORDER.GET_LIST_ALL_ORDER_MY_PAGE({
        page: page,
        limit: limit,
      });
      setDataInfo(data?.data);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  useEffect(() => {
    getListOrder({ page: activePage, limit: 10 });
  }, [activePage]);

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  return (
    <>
      <Loading loading={loading} />
      <div className="table-list-my-page-wrapper table-responsive">
        <table className={`table table-striped ${fontSize}`}>
          <thead>
            <tr>
              <th scope="col">No</th>
              {dateInfo?.header?.map((item, index) => (
                <th scope="col" key={index}>
                  {t(`table_my_page.${item}`)}
                </th>
              ))}
              <th scope="col">操作</th>
            </tr>
          </thead>
          <tbody>{dateInfo?.body?.map((listData, index) => renderItem(listData, index, fontSize, db))}</tbody>
        </table>
      </div>
      {
        dateInfo ?.count > 10 ?
        <PaginationCustom
          activePage={activePage}
          itemsCountPerPage={10}
          totalItemsCount={dateInfo && dateInfo.count}
          pageRangeDisplayed={5}
          handlePageChange={handlePageChange}
        /> : ''
      }

    </>
  );
};

export default compose(translate('translations'))(TableListMyPage);
