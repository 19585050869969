import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { Helmet } from 'react-helmet';
// import ButtonMaster from '../../../components/ButtonMaster';
// import HeaderMaintenance from '../../../components/HeaderMaintenance';
// import BreadcrumbMaintenance from '../../../components/BreadMaintenance';

import FooterMaintenance from '../../../components/FooterMaintenance';
import './style.scss';
import TableListMyPage from './TableList';
import TableAuthority from './TableAuthority';

import { shallowEqual, useSelector } from 'react-redux';
import BaseCheckAuthen from '../../../components/BaseCheckAuthen';
import ModalChangePass from './ModalChangePass';
import Header from '../../../components/Header';
import Footer from '../../../components/Footer';


const MyPage = () => {
  const [fontSize, setFontSizeAll] = useState('small');
  const colorScreen = useSelector((store) => store.utils.colorMe);
  const userMeData = useSelector((store) => store.authentication.userMe, shallowEqual);
  const [modalChangePass, setModalChangePass] = useState(false);

  const toggle = () => {
    setModalChangePass(!modalChangePass);
  };

  return (
    <BaseCheckAuthen>
      <div className="main">
        <Helmet>
          <title>マイページ</title>
        </Helmet>
        <Header />
        {/* <HeaderMaintenance setFontSizeAll={setFontSizeAll}/> */}
        <ModalChangePass toggle={toggle} modal={modalChangePass}/>
        <div className="body">
          <div className="wrapper-main">
            <div className="pt-4">
              <div className="title">
                <label className={`font-weight-bold ${fontSize}`}>アカウント情報</label>
              </div>
              <div className="table-user-info" style={{ border: 'solid 1px black' }}>
                <div className="d-flex">
                  <div className="col-3" style={{ borderRight: 'solid 1px black' }}>
                    <label className={`${fontSize}`}>利用者ID</label>
                  </div>
                  <div className="col-9">
                    <label className={`${fontSize}`}>{userMeData?.data?.loginId}</label>
                  </div>
                </div>
                <div className="d-flex"
                     style={{ border: 'solid 1px black', borderLeft: 'none', borderRight: 'none' }}>
                  <div className="col-3" style={{ borderRight: 'solid 1px black' }}>
                    <label className={`${fontSize}`}>利用者名</label>
                  </div>
                  <div className="col-9">
                    <label className={`${fontSize}`}>
                      {userMeData?.data?.firstName + ' ' + userMeData?.data?.lastName}
                    </label>
                  </div>
                </div>

                <div
                  className="d-flex"
                  style={{ border: 'solid 1px black', borderLeft: 'none', borderRight: 'none', borderTop: 'none' }}
                >
                  <div className="col-3" style={{ borderRight: 'solid 1px black' }}>
                    <label className={`${fontSize}`}>フリガナ</label>
                  </div>
                  <div className="col-9">
                    <label className={`${fontSize}`}>
                      {' '}
                      {userMeData?.data?.firstNameKana + ' ' + userMeData?.data?.lastNameKana}
                    </label>
                  </div>
                </div>

                <div
                  className="d-flex"
                  style={{ border: 'solid 1px black', borderLeft: 'none', borderRight: 'none', borderTop: 'none' }}
                >
                  <div className="col-3" style={{ borderRight: 'solid 1px black' }}>
                    <label className={`${fontSize}`}>ユーザー連絡先</label>
                  </div>
                  <div className="col-9">
                    <label className={`${fontSize}`}>{userMeData?.data?.phoneNumber}</label>
                  </div>
                </div>

                <div className="d-flex">
                  <div className="col-3" style={{ borderRight: 'solid 1px black' }}>
                    <label className={`${fontSize}`}>ユーザーemail</label>
                  </div>
                  <div className="col-9">
                    <label className={`${fontSize}`}>{userMeData?.data?.email}</label>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center my-3 group-btn">
              <button className={`btn button-submit mx-3 ${fontSize}`} onClick={() => toggle()}>パスワード変更</button>
            </div>
            <FooterMaintenance/>
          </div>
        </div>
        </div>
    </BaseCheckAuthen>
  );
};

export default compose(withRouter)(MyPage);
