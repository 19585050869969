import React, { useState } from 'react';
import TableWorker from '../TableWorker';
import DateTimePicker from '../../../../../components/DateTimePicker';
import TableWorkerAll from '../TableWorkerAll';
import './style.scss';

const WorkerInformation = () => {
  const [selectOption, setSelectOption] = useState('process');
  const handleOptionChange = (option) => {
    setSelectOption(option);
  };

  return (
    <div className="wrapper-worker-information mt-3">
      <div className="wrapper-column" style={{ fontSize: '14px' }}>
        worker設定
      </div>
      <div className="d-flex h-100">
        <div className="wrapper-column col-2">メニュー設定 </div>
        <div className="wrapper-table-right">
          <div className="mb-1">
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="inlineRadioOptions"
                id="inlineRadio1"
                value="all"
                checked={selectOption === 'all'}
                onChange={() => handleOptionChange('all')}
              />
              <label className="form-check-label" htmlFor="inlineRadio1">
                案件共通
              </label>
            </div>
            <div className="form-check form-check-inline mx-3">
              <input
                className="form-check-input"
                type="radio"
                name="inlineRadioOptions"
                id="inlineRadio2"
                value="process"
                checked={selectOption === 'process'}
                onChange={() => handleOptionChange('process')}
              />
              <label className="form-check-label" htmlFor="inlineRadio2">
                工程別
              </label>
            </div>
          </div>

          <div>{selectOption === 'process' ? <TableWorker /> : <TableWorkerAll />}</div>
          <div className="d-flex justify-content-center align-items-center mt-3">
            <p className="mb-0">利用期間</p>
            <div className="mx-2 worker-date-wrapper">
              <DateTimePicker />
            </div>
            <div className="worker-date-wrapper">
              <DateTimePicker />
            </div>
            <button className="ml-2 btn button-anken-submit">登録</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkerInformation;
