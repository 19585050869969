import React, { useState, useEffect } from 'react';
import './style.scss'
import { WORKER_ENDPOINT, ORDER } from '../../../../constants/endpoints';
import { pushToast } from '../../../../components/Toast';

const mockdata = {
    fileWorker: '',
}
const ReportAttachment = ({ workerId }) => {
    const [dataWorker, setDataWorker] = useState('')
    const [data, setData] = useState('');
    const [selectedFile, setSelectedFile] = useState(mockdata);
    const [selectedName, setSelectedName] = useState('');
    const orderId = (process.browser && new URL(window.location.href).searchParams.get('orderId')) || null;

    const getWorkerDetail = async ({ workerId = '' }) => {
        try {
            const data = await WORKER_ENDPOINT.GET_WORKER_DETAIL({ workerId: workerId });
            if (data) {
                setData(data?.data)
            }
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {

        if (workerId) {
            getWorkerDetail({
                workerId: workerId
            });
        }

    }, [workerId]);

    const updateStepThree = async ({ body = '', workerId = '' }) => {
        try {
            const data = await WORKER_ENDPOINT.UPDATE_STEP_THREE({ body: body, workerId: workerId });
            if (data && data.code !== 0) {
                if (data && data.message && data.message.extra) {
                    pushToast('error', data?.message?.extra);
                } else {
                    pushToast('error', data?.message?.errorMessage);
                }
            } else {
                pushToast('success', 'Update order success');
            }
        } catch (e) {
            console.log(e);
        }
    };

    const onUpdateStepThree = () => {
        updateStepThree({ body: dataWorker, workerId: workerId });
    };

    const uploadCsv = async ({ body = '', order_id = '' }) => {
        try {
            const data = await ORDER.UPDATE_FILE_COMPLETED({ body: body, order_id: order_id });
            if (data) {
                let newData = { ...dataWorker };
                newData = { ...newData, fileWorker: data?.data };
                setDataWorker(newData);
            }
            if (data && data.code !== 0) {
                if (data && data.message && data.message.extra) {
                    pushToast('error', data?.message?.extra);
                } else if (data.message.errorMessage) {
                    pushToast('error', data?.message?.errorMessage);
                } else {
                    pushToast('error', data?.message);
                }
            } else {
                pushToast('success', 'Upload image success');
            }
        } catch (errorMessage) {
            console.log('errorMessage: ', errorMessage);
        }
    };

    const onChangeHandler = (e) => {
        setSelectedFile(e?.target?.files[0]);
        setSelectedName(e?.target?.files[0]?.name);
    };



    const onClickHandler = () => {
        const dataNew = new FormData();
        dataNew.append('file', selectedFile);
        dataNew.append('orderId', orderId);
        uploadCsv({ body: dataNew, order_id: orderId });
    };

    return (
        <div className="detail-report-attacment">
            <div align="center">
                <p style={{ fontSize: 50 }}>報告書</p>
            </div>
            <table className="basictab" cellSpacing="0" cellPadding="0" width="100%">
                <tbody><tr>
                    <th>報告書添付</th>
                </tr>
                    <tr>
                        <td>
                            <div style={{ fontSize: "2em", marginTop: "0.5em", marginBottom: "0.5em" }}>※報告書の添付が完了したら、【保存】ボタンを押下して下さい。</div>
                            <table className="basictab" cellSpacing="0" cellPadding="0" width="100%">
                                <colgroup><col width="5%" />
                                    <col width="15%" />
                                    <col width="45%" />
                                    <col width="25%" />
                                </colgroup><tbody><tr>
                                    <th>No</th>
                                    <th>区分</th>
                                    <th>内容</th>
                                    <th>操作</th>
                                </tr>
                                    <tr>
                                        <td>1</td>
                                        <td>{data?.visitedBuildingName}</td>
                                        <td><input type="hidden" name="receive_order_id" value="53" />
                                            {selectedName}<br />
                                            <input type="button" value="ダウンロード" name="report_download" onclick="document.DownloadForm1.submit();" className="report_download_btn" data-role="none" />

                                            <div className="ui-input-text ui-body-inherit ui-corner-all ui-shadow-inset" style={{ width: "50%" }}>
                                                <input type="file" name="file" id='upload-csv' accept="" onChange={onChangeHandler} />
                                                <button onClick={() => onClickHandler()}>インポート</button>
                                            </div>
                                        </td>
                                        <td><input type="button" value="添付" className="report_upload_btn" data-role="none" /></td>
                                    </tr>
                                </tbody></table>
                            <input type="hidden" name="ankenmaster_id" value="1" />
                        </td>
                    </tr>
                </tbody></table>
            <div align="center">
                <input type="button" className="submitbutton" value="保存"
                    onClick={() => onUpdateStepThree()}
                />
            </div>
        </div>
    )
};

export default ReportAttachment;
