import React, { useState, useEffect } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import './style.scss';
import { ROLE } from '../../helpers/constants';
import CollapseTableStaff from './CollapseTableStaff';

const mockData = [
  {
    staffId: '1',
    name: '参照権限設定',
  },
];
const TableListStaff = ({ role = ROLE.PARTNER, color, roleDetail, t, p, idRole }) => {
  const [listStaff, setListStaff] = useState(mockData);

  const [hideshow, setHideshow] = useState('');
  const handleHide = (id) => {
    if (hideshow === id) {
      setHideshow('');
    } else {
      setHideshow(id);
    }
  };

  return (
    <div className="table-list-staff-partner">
      {listStaff &&
        listStaff.map((item, index) => {
          return (
            <CollapseTableStaff
              role={role}
              key={index}
              item={item}
              color={color}
              handleHide={handleHide}
              hideshow={hideshow}
              roleDetail={roleDetail}
              idRole={idRole}
              t={t}
              p={p}
            />
          );
        })}
    </div>
  );
};

export default TableListStaff;
