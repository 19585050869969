import React, { useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import Header from '../../../components/Header';
import { translate } from 'react-i18next';
import Footer from '../../../components/Footer';
import { shallowEqual, useSelector } from 'react-redux';
import { getRole } from '../../../helpers/helpers';
import './style.scss';
import { ORDER, PRODUCT_ENDPOINT, COLOR_ENDPOINT } from '../../../constants/endpoints';
import { CSVLink } from 'react-csv';
import { pushToast } from '../../../components/Toast';
import Encoding from 'encoding-japanese';
import Loading from 'components/Loading/Loading';

const mock_default_csv = [
    {
      '管理コード（半角入力)': 'aaa12345',
      工程: '調査',
      申込会社名: 'ネオ商事',
      申込ご担当者: '廣瀬',
      申込ご担当者連絡先: '080-1111-1111',
      設置先拠点名: 'テスト設置先拠点名',
      訪問先ご担当者: 'テストご担当者',
      訪問先ご担当者連絡先: '097-573-3131',
      訪問先郵便番号: '870-0278',
      都道府県: '大分県',
      訪問先拠点住所: '大分市都町',
      訪問先建物名: 'ウォーカービル',
      作業予定箇所: '事務室',
      申し送り事項: '入館前に必ず受付に記名のうえ担当者呼び出しお願いします。',
      '訪問希望日①': '8/4/20',
      '希望訪問日時①（開始時間帯_from)': '09:00',
      '希望訪問日時①（開始時間帯_to)': '18:00',
      '訪問希望日②': '8/5/20',
      '希望訪問日時②（開始時間帯_from)': '09:15',
      '希望訪問日時②（開始時間帯_to)': '18:15',
    },
    {
      '管理コード（半角入力)': 'bbb34567',
      工程: '工事',
      申込会社名: 'ネオ商事',
      申込ご担当者: '廣瀬',
      申込ご担当者連絡先: '080-1111-1111',
      設置先拠点名: 'テスト設置先拠点名',
      訪問先ご担当者: 'テストご担当者',
      訪問先ご担当者連絡先: '097-573-3131',
      訪問先郵便番号: '870-0278',
      都道府県: '大分県',
      訪問先拠点住所: '大分市府内町',
      訪問先建物名: 'ウォーカービル',
      作業予定箇所: 'サーバー室',
      申し送り事項: '入館30分前に●●まで連絡の上訪問ください。',
      '訪問希望日①': '8/4/20',
      '希望訪問日時①（開始時間帯_from)': '09:00',
      '希望訪問日時①（開始時間帯_to)': '18:00',
      '訪問希望日②': '8/5/20',
      '希望訪問日時②（開始時間帯_from)': '09:15',
      '希望訪問日時②（開始時間帯_to)': '18:15',
    },
    {
      '管理コード（半角入力)': 'ccc89101',
      工程: '保守',
      申込会社名: 'ネオ商事',
      申込ご担当者: '岡田',
      申込ご担当者連絡先: '090-1111-1111',
      設置先拠点名: 'テスト設置先拠点名',
      訪問先ご担当者: 'テストご担当者',
      訪問先ご担当者連絡先: '03-5439-5722',
      訪問先郵便番号: '108-0014',
      都道府県: '東京都',
      訪問先拠点住所: '港区芝5-14-13',
      訪問先建物名: 'アセンド三田ビル',
      作業予定箇所: '受付',
      申し送り事項: 'サンプルサンプルサンプル',
      '訪問希望日①': '8/4/20',
      '希望訪問日時①（開始時間帯_from)': '09:00',
      '希望訪問日時①（開始時間帯_to)': '18:00',
      '訪問希望日②': '8/5/20',
      '希望訪問日時②（開始時間帯_from)': '09:15',
      '希望訪問日時②（開始時間帯_to)': '18:15',
    },
  ],
  ImportCsv = (props) => {
    const { t } = props;
    const [role, setRole] = React.useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [listProduct, setListProduct] = useState(null);
    const [fileUTF8, setFileUTF8] = useState(null);
    const [idProduct, setIdProduct] = useState(null);
    const [defaultCsv, setDefaultCsv] = useState([]);
    const [selectedName, setSelectedName] = useState('ファイルを選んで下さい...');
    const [color, setColor] = React.useState('#1c2a53');
    const [idRole, setIdRole] = React.useState(null);
    const [loading, setLoading] = useState(false);

    const getColor = async ({ role = '' }) => {
      try {
        const data = await COLOR_ENDPOINT.GET_COLOR({ role: idRole });
        if (data && data?.code === 0) {
          setColor(data?.data?.code);
        }
      } catch (e) {
        console.log(e);
      }
    };

    useEffect(() => {
      if (idRole) {
        getColor({ role: idRole });
      }
    }, [idRole]);

    useEffect(() => {
      const newRole = localStorage.getItem('role');
      setIdRole(newRole);
      if (newRole) {
        setRole(getRole({ idRole: newRole }));
      }
    }, []);

    const getAllProductByUser = async () => {
      try {
        const data = await PRODUCT_ENDPOINT.GET_PRODUCT_CSV();
        if (data) {
          setListProduct(data?.data?.body);
        }
      } catch (e) {
        console.log(e);
      }
    };

    const getDefaultCsv = async ({ productId = '' }) => {
      try {
        const data = await ORDER.GET_DEFAULT_CSV({ productId: productId });
        if (data) {
          setDefaultCsv(data?.data);
        }
      } catch (e) {
        setLoading(false);
        console.log(e);
      }
    };

    const uploadCsv = async (body) => {
      try {
        const data = await ORDER.IMPORT_CSV(body);
        if (data && data.code !== 0) {
          if (data && data.message && data.message.extra) {
            pushToast('error', data?.message?.extra);
          } else if (data.message.errorMessage) {
            pushToast('error', data?.message?.errorMessage);
          } else {
            pushToast('error', data?.message);
          }
        } else {
          pushToast('success', 'Upload CSV success');
        }
      } catch (errorMessage) {
        console.log('errorMessage: ', errorMessage);
      }
    };

    const [idNew, setIdNew] = useState();

    const getDataDefault = (idProduct) => {
      setIdNew(idProduct);
    };

    useEffect(() => {
      if (idProduct) {
        getDefaultCsv({ productId: idProduct });
      }
    }, [idProduct]);

    const onChangeHandler = (event) => {
      setSelectedFile(event?.target?.files[0]);
      setSelectedName(event?.target?.files[0]?.name);

      const file = event.target.files[0];

      let reader = new FileReader();
      reader.onload = function (e) {
        let codes = new Uint8Array(e.target.result);
        let encoding = Encoding.detect(codes);
        let unicodeString = Encoding.convert(codes, {
          to: 'UNICODE',
          from: ['SJIS', 'UTF8'].includes(encoding) ? encoding : 'SJIS',
          type: 'string',
        });
        const str_code = Encoding.stringToCode(unicodeString);
        const sjis_array = Encoding.convert(str_code, 'UTF8', 'UNICODE');
        const unit8Array = new Uint8Array(sjis_array);
        const file_affer = new File([unit8Array], { type: 'text/csv;charset=utf-8' });
        setFileUTF8(file_affer);
      };
      reader.readAsArrayBuffer(file);
    };

    const onClickHandler = () => {
      const data = new FormData();
      data.append('file', fileUTF8);
      data.append('productId', idProduct);
      uploadCsv(data);
    };

    const handleChangeProduct = (e) => {
      setIdProduct(e.target.value);
    };

    useEffect(() => {
      getAllProductByUser();

      const newRole = localStorage.getItem('role');
      if (newRole) {
        setRole(getRole({ idRole: newRole }));
      }
    }, []);

    return (
      <>
        {/*<BaseCheckAuthen>*/}
        <div className="wrapper-import-csv">
          <Loading loading={loading} />
          <div className="main">
            <Header idRole={idRole} color={color} />
            <div className="body-wrapper">
              <select className="ml-3 form-control client-select" onChange={handleChangeProduct} defaultValue={''}>
                <option disabled={true} value={''}>
                  ---案件を選択して下さい---
                </option>
                {listProduct?.length > 0 &&
                  listProduct.map((item, index) => (
                    <option key={index} value={item.productId}>
                      {item.name}
                    </option>
                  ))}
              </select>
              <p className="text-center mb-1 fz-10">一括登録</p>
              <div className="d-flex">
                <div className="col-3 csv-label-wrapper d-flex align-items-center justify-content-center">
                  <p className="mb-0">CSVサンプル</p>
                </div>
                <div className="col-9 csv-import-wrapper">
                  <CSVLink data={defaultCsv} filename="default-csv.csv">
                    <button onClick={() => getDataDefault(idProduct)} className="btn button-down-csv fz-11">
                      <i className="fa fa-download" />
                      サンプルダウンロード
                    </button>
                  </CSVLink>
                </div>
              </div>
              <div className="d-flex">
                <div className="col-3 csv-label-wrapper d-flex align-items-center justify-content-center">
                  <p className="mb-0">CSVインポート</p>
                </div>
                <div className="col-9 csv-import-wrapper">
                  <label className="btn btn-light fz-11 mb-0" htmlFor="file">
                    ファイルを選択
                  </label>
                  <input type="file" name="file" id="file" className="d-none" onChange={onChangeHandler} />
                  <input className="ml-2 fz-11" type="text" placeholder={selectedName} disabled="disabled" />
                  <button
                    className="ml-2 btn btn-light fz-11"
                    onClick={() => onClickHandler()}
                    disabled={selectedFile && idProduct ? false : true}
                  >
                    インポート
                  </button>
                </div>
              </div>
              <p className="text-center my-1 fz-10">物件再作成</p>
              <div className="d-flex">
                <div className="col-3 csv-label-wrapper d-flex align-items-center justify-content-center">
                  <p className="mb-0">物件再作成</p>
                </div>
                <div className="col-9 csv-import-wrapper">
                  <Link to="/list?p=cancel_info">
                    <p className="mb-0 fz-11"> 再作成ページへ </p>
                  </Link>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>

        {/*</BaseCheckAuthen>*/}
      </>
    );
  };

export default compose(translate('translations'), withRouter)(ImportCsv);
