import React, { useEffect, useState } from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { getListOrder } from 'actions/order';
import Footer from 'components/Footer';
import Header from 'components/Header';
import Loading from 'components/Loading/Loading';
import PaginationCustom from 'components/PaginationCustom';
import SearchMenuSummary from 'components/SearchMenuSummary';
import TableListMaintenance from 'components/TableListMaintenance';
import { COLOR_ENDPOINT, ORDER } from 'constants/endpoints';
import { HEADER_MENU, DATE_JP_FORMAT } from 'helpers/constants';
import { getRole } from 'helpers/helpers';
import moment from 'moment';

const INIT_AFFILIATE_PAGINATION = {
  page: 1,
  limit: 10,
};

const SummaryReceiveAll = (props) => {
  const { t } = props;
  let name = (process.browser && new URL(window.location.href).searchParams.get('p')) || 'receive_all';
  let idScreenSetting = (process.browser && new URL(window.location.href).searchParams.get('idScreenSetting')) || '';
  const [p, setP] = React.useState(name || HEADER_MENU.RECEIVE_ALL);
  const [activePage, setActivePage] = React.useState(1);
  const [role, setRole] = React.useState(null);
  const [idRole, setIdRole] = React.useState(null);
  const [color, setColor] = React.useState('#1c2a53');
  const [roleDetail, setRoleDetail] = useState('');
  const [ListExampleMonitor, setListExampleMonitor] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (idRole) {
      setRoleDetail(getRole({ idRole: idRole }));
    }
  }, [idRole]);
  const getColor = async ({ role = '' }) => {
    try {
      const data = await COLOR_ENDPOINT.GET_COLOR({ role: idRole });
      if (data && data?.code === 0) {
        setColor(data?.data?.code);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (idRole) {
      getColor({ role: idRole });
    }
  }, [idRole]);

  useEffect(() => {
    const newRole = localStorage.getItem('role');
    setIdRole(newRole);
    if (newRole) {
      setRole(getRole({ idRole: newRole }));
    }
  }, []);

  useEffect(() => {
    setP(name);
  }, [name]);

  useEffect(() => {
    getListExampleMonitor({ page: activePage, limit: 10 });
  }, [activePage]);

  const getListExampleMonitor = async ({
    page = INIT_AFFILIATE_PAGINATION.page,
    limit = INIT_AFFILIATE_PAGINATION.limit,
    applicationCompanyName = '',
    managementCode = '',
    projectTitle = '',
    screenSettingId = '',
    services = '',
    visitDateFrom = '',
    visitDateTo = '',
    visitedBaseName = '',

  }) => {
    setLoading(true);
    try {
      const data = await ORDER.GET_LIST_ORDER_EXAMPLE({
        page: page,
        limit: limit,
        idScreenSetting,
        applicationCompanyName,
        managementCode,
        projectTitle,
        screenSettingId,
        services,
        visitDateFrom,
        visitDateTo,
        visitedBaseName
      });
      setListExampleMonitor(data);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const handleSearch = (data) => {
    const newParamSearch = {
      ...data,
      services: encodeURIComponent(JSON.stringify(data.services)),
      visitDateFrom: data.visitDateFrom ? moment(new Date(data.visitDateFrom)).format(DATE_JP_FORMAT) : '',
      visitDateTo: data.visitDateTo ? moment(new Date(data.visitDateTo)).format(DATE_JP_FORMAT) : '',
    };
    getListExampleMonitor({ ...newParamSearch, page: activePage, limit: 10 })
  }
  return (
    <>
      <div className="main">
        <Loading loading={loading} />
        <Header p={p} idRole={idRole} color={color} />
        <SearchMenuSummary color={color} idScreenSetting={idScreenSetting} handleSearch={handleSearch}/>
        <TableListMaintenance data={ListExampleMonitor && ListExampleMonitor.data} t={t}/>
        {ListExampleMonitor && ListExampleMonitor.data && ListExampleMonitor.data.count > 10 && (
          <PaginationCustom
            activePage={activePage}
            itemsCountPerPage={10}
            totalItemsCount={ListExampleMonitor && ListExampleMonitor.data && ListExampleMonitor.data.count}
            pageRangeDisplayed={5}
            handlePageChange={handlePageChange}
          />
        )}
        <Footer color={color} />
      </div>
    </>
  );
};

const mapStateToProps = (store) => {
  return {
    listOrder: store.order.listOrder,
    loading: store.utils.loading,
  };
};

const mapActionToProps = {
  getListOrder,
};

export default compose(
  translate('translations'),
  withRouter,
  connect(mapStateToProps, mapActionToProps)
)(SummaryReceiveAll);
