import React, { useEffect, useState } from 'react';
import { translate } from 'react-i18next';
import { Collapse } from 'reactstrap';
import { compose } from 'redux';
import { CONTRACT_ENDPOINTS, PROJECT_ENDPOINT } from '../../constants/endpoints';
import './style.scss';
import Loading from 'components/Loading/Loading';
import _ from 'lodash';

const RequestDestination = ({
  title,
  t,
  onHandleRecruitmentType,
  dataDetailNotTree,
  onHandleChangePartner,
  onHandleChangePartnerAll,
  onHandleChangeArea,
  onhandleProjectAndProduct,
  organizationId,
  recruitmentContractId = '',
  isChecked,
  updateStep,
  check
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const [recruitmentData, setRecruitmentData] = useState(null);
  const [keyListArea, setKeyListArea] = useState('');
  const [listArea, setListArea] = useState([]);
  const [dataAreas, setDataAreas] = useState({});
  const [allProject, setAllProject] = useState([]);
  const [allProduct, setAllProduct] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isCheckPartner,setIsCheckPartner] = useState(false);

  useEffect(() => {
    const dataPartnerId = recruitmentData?.partners?.map(e => { return e.partnerId });
    let dataCheckPartner = dataDetailNotTree?.partners?.filter(ele => dataPartnerId?.includes(ele)) || []
    if (!_.isEmpty(dataCheckPartner) && dataPartnerId?.length === dataCheckPartner?.length) {
      setIsCheckPartner(true);
    } else {
      setIsCheckPartner(false)
    }
  },[dataDetailNotTree , recruitmentData?.partners])

  useEffect(() => {
    setIsCheckPartner(isChecked)
  },[isChecked])

  const onClickShow = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if(organizationId) {
      getRecruitmentData({ organizationId });
    }
  }, [organizationId, updateStep]);

  const getRecruitmentData = async ({ organizationId = '' }) => {
    setLoading(true);
    try {
      const data = await CONTRACT_ENDPOINTS.GET_RECRUITMENT_DATA({ organizationId, recruitmentId: recruitmentContractId || '' });
      if (data?.data) {
        if (data?.data?.areas) {
          const areas = data?.data?.areas;
          setDataAreas({
            hokkaidoAndTohoku: areas?.hokkaidoAndTohoku || [],
            kanto: areas?.kanto || [],
            koshinetsuAndHokuriku: areas?.koshinetsuAndHokuriku || [],
            tokai: areas?.tokai || [],
            kansai: areas?.kansai || [],
            china: areas?.china || [],
            shikoku: areas?.shikoku || [],
            kyushuAndOkinawa: areas?.kyushuAndOkinawa || [],
          })
        }
        setRecruitmentData(data?.data);
        setKeyListArea(Object.keys(data?.data?.areas)?.[0]);
        setListArea(data?.data?.areas?.[Object.keys(data?.data?.areas)?.[0]]);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const getAllProject = async () => {
    setLoading(true);
    try {
      const data = await PROJECT_ENDPOINT.GET_ALL_PROJECT_CONTRACT({ page: 0, organizationId });
      if (data && data.data && data.data.body) {
        setAllProject(data?.data?.body);
      }
      setLoading(false);
    } catch (errorMessage) {
      setLoading(false);
      console.log('errorMessage: ', errorMessage);
    }
  };

  const getAllProduct = async () => {
    setLoading(true);
    try {
      const data = await PROJECT_ENDPOINT.GET_ALL_PRODUCT_CONTRACT({ organizationId });
      if (data) {
        setAllProduct(data?.data);
      }
      setLoading(false);
    } catch (errorMessage) {
      setLoading(false);
      console.log('errorMessage: ', errorMessage);
    }
  };

  useEffect(() => {
    getAllProject();
    getAllProduct();
  }, []);

  const onChangeRecruitmentType = (e) => {
    const { value, name } = e.target;
    onHandleRecruitmentType(name, value);
  };

  const onChangePartner = (e) => {
    onHandleChangePartner(e, recruitmentData?.partners);
  };

  const onChangePartnerAll = (e) => {
    onHandleChangePartnerAll(e , recruitmentData?.partners);
  };

  const handleSelectProjectAndProduct = (e) => {
    const { value, name } = e.target;
    onhandleProjectAndProduct(name, value);
  };

  const onHandleArea = (e, key) => {
    console.log(e);
    const target = e.target;
    let areas = dataDetailNotTree.areas || {};
    console.log(target.checked);
    if (target.checked) {
      areas = {
        ...areas,
        [key]: [...dataAreas[key]],
      }
    } else {
      delete areas[key];
    }
    dataDetailNotTree.areas = areas;
    console.log(dataDetailNotTree.areas);
    setKeyListArea(key);
    const listAreasTmp = [];
    for (const area in areas) {
      listAreasTmp.push(...areas[area])
    }
    setListArea(listAreasTmp);
  };

  const onChangeArea = (e, key) => {
    onHandleChangeArea(e, key, dataAreas);
  };

  return (
    <div className="detail-registration-contract">
      <Loading loading={loading} />
      <div className="detail-basic-table-wrapper">
        <div className="title-absolute cursor-pointer" onClick={onClickShow}>
          {title}{' '}
          {isOpen ? (
            <i className="fas fa-arrow-circle-up ml-2 cursor-pointer"></i>
          ) : (
            <i className="fas fa-arrow-circle-down ml-2 cursor-pointer"></i>
          )}
        </div>
        <Collapse isOpen={isOpen}>
          <div className="row">
            <div className="col-3 text-center">
              <label className="label-contract">募集種別</label>
            </div>
            <div className="col-9">
              <input
                className={`${check ? 'button-disable-contract' : ''} input-contract`}
                type="text"
                name="recruitmentType"
                onChange={onChangeRecruitmentType}
                value={dataDetailNotTree?.recruitmentType}
                disabled={check}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-3 text-center">
              <label className="label-contract">ManagementG指定</label>
            </div>
            <div className="col-9">
              <div className={`${check ? 'button-disable-contract' : ''} block-list-check`}>
                <div className={`${check ? 'button-disable-contract' : ''} item-all`}>
                  <input
                    type="checkbox"
                    value="ALL"
                    name="partners"
                    onChange={onChangePartnerAll}
                    checked={isCheckPartner}
                    disabled={check}
                  />
                  <label>Check all</label>
                </div>
                  <div className="list-check-item">
                    {recruitmentData?.partners?.map((item, index) => {
                      return (
                        <div key={index} className={`${check ? 'button-disable-contract' : ''} item`}>
                          <input
                            value={item.partnerId}
                            type="checkbox"
                            name="partners"
                            onChange={onChangePartner}
                            checked={dataDetailNotTree?.partners?.map((elm) => elm)?.includes(item?.partnerId)}
                            disabled={check}
                          />
                          <label>{item.abbreviationName}</label>
                        </div>
                      );
                    })}
                  </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-3 text-center">
              <label className="label-contract">エリア指定</label>
            </div>
            <div className="col-3">
              <div className={`${check ? 'button-disable-contract' : ''} block-list-check`}>
                <div className="list-check-item">
                  {dataAreas &&
                    Object.keys(dataAreas)?.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className={`item ${check ? 'button-disable-contract' : ''}`}
                        >
                          <input
                            type="checkbox"
                            checked={dataDetailNotTree?.areas?.[item] ? true : false}
                            onChange={(e) => onHandleArea(e, item)}
                            value={item}
                            disabled={check}
                          />
                          <label>{t(`areas.${item}`)}</label>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className={`${check ? 'button-disable-contract' : ''} block-list-check`}>
                <div className="list-check-item">
                      {dataAreas &&
                          Object.keys(dataAreas)?.map((item, index) => {
                            return dataAreas[item].map((itemMap, indexMap) => {
                              return (dataDetailNotTree?.areas?.[item] &&
                                <div key={indexMap} className={`${check ? 'button-disable-contract' : ''} item`}>
                                  <input
                                    type="checkbox"
                                    checked={dataDetailNotTree?.areas?.[item]?.includes(itemMap) ? true : false}
                                    onChange={(e) => onChangeArea(e, item)}
                                    value={itemMap}
                                    disabled={check}
                                  />
                                  <label>{itemMap}</label>
                                </div>
                              );
                            });
                          })}
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className={`${check ? 'button-disable-contract' : ''} block-list-check`}>
                <div className="list-check-item">
                  {dataDetailNotTree?.areas && dataAreas &&
                    Object.keys(dataAreas)?.map((item, index) => {
                      if (dataDetailNotTree?.areas[item]) {
                        return dataDetailNotTree?.areas[item].map((itemMap, indexMap) => {
                          return (
                            <div key={indexMap} className={`${check ? 'button-disable-contract' : ''} item`}>
                              <span>{itemMap}</span>
                            </div>
                          );
                        });
                      }
                    })}
                </div>
              </div>
            </div>
          </div>
          {/*<div className="row mb-3">*/}
          {/*  <div className="col-3 text-center">*/}
          {/*    <label className="label-contract mb-0">過去実績</label>*/}
          {/*  </div>*/}
          {/*  <div className="col-3">*/}
          {/*    <select*/}
          {/*      className="select-item"*/}
          {/*      onChange={handleSelectProjectAndProduct}*/}
          {/*      name="projectIdChild"*/}
          {/*      value={dataDetailNotTree?.projectIdChild}*/}
          {/*    >*/}
          {/*      <option>---</option>*/}
          {/*      {allProject?.map((item, index) => {*/}
          {/*        return (*/}
          {/*          <option value={item?.projectCode} key={index}>*/}
          {/*            {item?.projectName}*/}
          {/*          </option>*/}
          {/*        );*/}
          {/*      })}*/}
          {/*    </select>*/}
          {/*  </div>*/}
            {/*<div className="col-3">*/}
            {/*  <select*/}
            {/*    className="select-item"*/}
            {/*    onChange={handleSelectProjectAndProduct}*/}
            {/*    name="productIdChild"*/}
            {/*    value={dataDetailNotTree?.productIdChild}*/}
            {/*  >*/}
            {/*    {allProduct &&*/}
            {/*      allProduct?.map((item, index) => {*/}
            {/*        return (*/}
            {/*          <option value={item?.productId} key={index}>*/}
            {/*            {item?.name}*/}
            {/*          </option>*/}
            {/*        );*/}
            {/*      })}*/}
            {/*  </select>*/}
            {/*</div>*/}
          {/*</div>*/}
        </Collapse>
      </div>
    </div>
  );
};

export default compose(translate('translations'))(RequestDestination);
