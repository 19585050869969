import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { DATE_JP_FORMAT, DATE_JP_RENDER, SCREEN_NAME, ROLE } from '../../../../../helpers/constants';
import { getRole } from '../../../../../helpers/helpers';

const NDAContract = ({
  dataDetailNda,
  onHandleSubmit,
  stepPermission,
  recruitmentEstimateId,
  submitUpload,
  dataUploadFile,
  removeFileStep,
  isChildCompany,
}) => {
  const organizationId = (process.browser && new URL(window.location.href).searchParams.get('organizationId')) || '';
  const idProvider = localStorage.getItem('idProvide');
  const [selectedName, setSelectedName] = useState('');
  const [selectedFile, setSelectedFile] = useState([]);
  const [selectedNameApplication, setSelectedNameApplication] = useState('');
  const [selectedFileApplication, setSelectedFileApplication] = useState([]);
  const [dataNDA, setDataNDA] = useState({});
  const [listFileNda, setListFileNda] = useState([]);
  const [listFileApplicationNda, setListFileApplicationNda] = useState([]);
  const checkRole = getRole({ idRole: localStorage.getItem('role') });
  const optionData = ['未契約', '契約対応中', '契約中', '契約終了', '契約停止'];
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (dataDetailNda) {
      setDataNDA({
        concludingDate: dataDetailNda?.concludingDate || '',
        contractPeriodStart: dataDetailNda?.contractPeriodStart || '',
        contractPeriodEnd: dataDetailNda?.contractPeriodEnd || '',
        alert: dataDetailNda?.alert || '',
        comment: dataDetailNda?.comment || '',
        state: dataDetailNda?.state || '',
        endDay: dataDetailNda?.endDay || '',
        addMessageERP: dataDetailNda?.addMessageERP || '',
        addMessagePartner: dataDetailNda?.addMessagePartner || '',
      });
      (checkRole === ROLE.PARTNER || (organizationId !== idProvider && isChildCompany)) &&
        setDisabled(['契約中', '契約終了', '契約停止'].includes(dataDetailNda?.state));
      setListFileNda(dataDetailNda?.files);
      setListFileApplicationNda(dataDetailNda?.applicantFiles);
    }
  }, [dataDetailNda, isChildCompany]);

  useEffect(() => {
    if (dataUploadFile?.nda) {
      setListFileNda(dataUploadFile?.nda?.files);
      setListFileApplicationNda(dataUploadFile?.nda?.applicantFiles);
      setSelectedFileApplication([]);
      setSelectedFile([]);
    }
  }, [dataUploadFile]);

  const onChangeTextNDA = (e) => {
    const { value, name } = e.target;
    setDataNDA({ ...dataNDA, [name]: value });
  };

  const onChangeOptionEstimates = (e) => {
    const { value, name } = e.target;
    setDataNDA({ ...dataNDA, [name]: value });
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const onChangeHandler = async (e) => {
    let name = e?.target?.name;
    const tmpFile = e?.target?.files;
    const base64Promises = [];
    Object.keys(tmpFile).map((item, index) => {
      base64Promises.push(toBase64(tmpFile[index]));
    });
    const base64s = await Promise.all(base64Promises);
    const tmpFileResult = Object.keys(tmpFile).map((item, index) => {
      return {
        fileName: tmpFile[index].name,
        buffer: base64s[index],
      };
    });

    if (name === 'files') {
      setSelectedFile(tmpFileResult);
      setSelectedName(tmpFileResult.map((item) => item.fileName));
    } else {
      setSelectedFileApplication(tmpFileResult);
      setSelectedNameApplication(tmpFileResult.map((item) => item.fileName));
    }
  };

  const onChangeDateNDA = (date, text) => {
    if (date) {
      let newData = { ...dataNDA, [text]: moment(new Date(date)).format(DATE_JP_FORMAT) };
      setDataNDA(newData);
    } else {
      let newData = { ...dataNDA, [text]: '' };
      setDataNDA(newData);
    }
  };

  const onCreateAndUpdate = (textSubmit, status, updateTitle) => {
    onHandleSubmit(
      status === 'statusDone'
        ? { ...dataNDA, screenName: SCREEN_NAME.CONTRACT_MANAGEMENT, isConfirm: true }
        : status === 'statusReject'
        ? { ...dataNDA, screenName: SCREEN_NAME.CONTRACT_MANAGEMENT, isReject: true }
        : { ...dataNDA, screenName: SCREEN_NAME.CONTRACT_MANAGEMENT },
      textSubmit,
      updateTitle
    );
  };

  const handleUploadFile = (name) => {
    const body = {
      screenName: SCREEN_NAME.CONTRACT_MANAGEMENT,
      files: name === 'applicantFiles' ? selectedFileApplication : selectedFile,
      step: 1,
      contractEstimateId: recruitmentEstimateId,
      fieldName: name,
    };
    submitUpload(body);
    setSelectedName('');
    document.getElementById('upload-csv-nda-file').value = '';
    setSelectedNameApplication('');
    document.getElementById('upload-csv-nda-file-application').value = '';
  };

  const handleDeleteFile = (key, name) => {
    const body = {
      screenName: SCREEN_NAME.CONTRACT_MANAGEMENT,
      step: 1,
      key,
      fileName: name,
    };
    !disabled && removeFileStep(body);
  };

  return (
    <div className="detail-registration-contract">
      <div className="detail-basic-table-wrapper">
        <div className="title-absolute">
          NDA{dataNDA?.addMessageERP || dataNDA?.addMessagePartner}
        </div>
        <div className="row">
          <div className="col-3">
            <label className="label-contract">締結日</label>
          </div>
          <div className="col-9">
            <div className="row">
              <div className="col-3">
                <div className="block-date-custom block-date-maintenance">
                  <DatePicker
                    disabled={checkRole === ROLE.PARTNER || (organizationId !== idProvider && isChildCompany) || disabled}
                    selected={!_.isEmpty(dataNDA?.concludingDate) ? new Date(dataNDA?.concludingDate) : ''}
                    onChange={(date) => onChangeDateNDA(date, 'concludingDate')}
                    locale="ja"
                    dateFormat={DATE_JP_RENDER}
                    dateFormatCalendar="yyyy年 MM月"
                    className={`${
                      checkRole === ROLE.PARTNER || (organizationId !== idProvider && isChildCompany) || disabled ? 'input-contract-disabled' : ''
                    } input-contract`}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-3">
            <label className="label-contract">契約期間</label>
          </div>
          <div className="col-9">
            <div className="row">
              <div className="col-3">
                <div className="block-date-custom block-date-maintenance">
                  <DatePicker
                    disabled={checkRole === ROLE.PARTNER || (organizationId !== idProvider && isChildCompany) || disabled}
                    selected={!_.isEmpty(dataNDA?.contractPeriodStart) ? new Date(dataNDA?.contractPeriodStart) : ''}
                    onChange={(date) => onChangeDateNDA(date, 'contractPeriodStart')}
                    locale="ja"
                    dateFormat={DATE_JP_RENDER}
                    dateFormatCalendar="yyyy年 MM月"
                    className={`${
                      checkRole === ROLE.PARTNER || (organizationId !== idProvider && isChildCompany) || disabled ? 'input-contract-disabled' : ''
                    } input-contract`}
                  />
                </div>
              </div>
              <div className="col-3">
                <div className="block-date-custom block-date-maintenance">
                  <DatePicker
                    disabled={checkRole === ROLE.PARTNER || (organizationId !== idProvider && isChildCompany) || disabled}
                    selected={!_.isEmpty(dataNDA?.contractPeriodEnd) ? new Date(dataNDA?.contractPeriodEnd) : ''}
                    onChange={(date) => onChangeDateNDA(date, 'contractPeriodEnd')}
                    locale="ja"
                    dateFormat={DATE_JP_RENDER}
                    dateFormatCalendar="yyyy年 MM月"
                    className={`${
                      checkRole === ROLE.PARTNER || (organizationId !== idProvider && isChildCompany) || disabled ? 'input-contract-disabled' : ''
                    } input-contract`}
                  />
                </div>
              </div>
              <div className="col-6 d-flex">
                <label className="label-contract">アラート</label>
                <input
                  type="text"
                  className={`${
                    checkRole === ROLE.PARTNER || (organizationId !== idProvider && isChildCompany) || disabled ? 'input-contract-disabled' : ''
                  } input-contract`}
                  name="alert"
                  value={dataNDA?.alert}
                  onChange={onChangeTextNDA}
                  disabled={checkRole === ROLE.PARTNER || (organizationId !== idProvider && isChildCompany) || disabled}
                />
                <span className="ml-2">前</span>
              </div>
            </div>
          </div>
          <div className="col-3">
            <label className="label-contract">応募者ファイル</label>
          </div>
          <div className="col-9">
            <div className="row">
              <div className="col-3">
                <label
                  htmlFor="upload-csv-nda-file-application"
                  className={`${disabled ? 'button-disable-contract' : ''} button-contract`}
                >
                  ファイル選択
                </label>
                <input
                  type="file"
                  id="upload-csv-nda-file-application"
                  name="applicantFiles"
                  multiple
                  disabled={disabled}
                  className="d-none input-contract"
                  onChange={onChangeHandler}
                />
              </div>
              <div className="col-6">
                <input
                  type="text"
                  className={`${disabled ? 'input-contract-disabled' : ''} input-contract`}
                  placeholder={selectedNameApplication}
                  disabled
                />
              </div>
              <div className="col-3">
                {!disabled ? (
                  <label className="button-contract" onClick={() => handleUploadFile('applicantFiles')}>
                    アップロード
                  </label>
                ) : (
                  <label
                    className={`${
                      checkRole === ROLE.PARTNER || (organizationId !== idProvider && isChildCompany) || disabled ? 'button-disable-contract' : ''
                    } button-contract`}
                  >
                    アップロード
                  </label>
                )}
              </div>
            </div>
          </div>
          {listFileApplicationNda?.map((item, index) => {
            return (
              <div key={index} className="col-12">
                <div className="row">
                  <div className="col-3"></div>
                  <div
                    onClick={() => window.open(`${item?.url}`)}
                    className={`${disabled ? 'disabled-text' : ''} col-4 text-left cursor-pointer`}
                  >
                    {item.name}
                  </div>
                  <div className={`${disabled ? 'disabled-text' : ''} col-4`} style={{ textAlign: 'end' }}>
                    {item.timeUpload}
                  </div>
                  <div
                    className={`${disabled ? 'disabled-text' : ''} col-1 cursor-pointer`}
                    onClick={() => handleDeleteFile(item?.key, 'applicantFiles')}
                  >
                    [X]
                  </div>
                </div>
              </div>
            );
          })}
          <div className="col-3">
            <label className="label-contract">ファイル</label>
          </div>
          <div className="col-9">
            <div className="row">
              <div className="col-3">
                <label
                  htmlFor="upload-csv-nda-file"
                  className={`${
                    checkRole === ROLE.PARTNER || (organizationId !== idProvider && isChildCompany) || disabled
                      ? 'button-disable-contract'
                      : ''
                  } button-contract`}
                >
                  ファイル選択
                </label>
                <input
                  type="file"
                  name="files"
                  id="upload-csv-nda-file"
                  multiple
                  className={`${
                    checkRole === ROLE.PARTNER || (organizationId !== idProvider && isChildCompany) || disabled
                      ? 'input-contract-disabled'
                      : ''
                  } input-contract d-none`}
                  onChange={onChangeHandler}
                  disabled={checkRole === ROLE.PARTNER || (organizationId !== idProvider && isChildCompany) || disabled}
                />
              </div>
              <div className="col-6">
                <input
                  type="text"
                  className={`${
                    checkRole === ROLE.PARTNER || (organizationId !== idProvider && isChildCompany) || disabled ? 'input-contract-disabled' : ''
                  } input-contract`}
                  placeholder={selectedName}
                  disabled
                />
              </div>
              <div className="col-3">
                {checkRole !== ROLE.PARTNER && !(organizationId !== idProvider && isChildCompany) && !disabled ? (
                  <label className="button-contract" onClick={() => handleUploadFile('files')}>
                    アップロード
                  </label>
                ) : (
                  <label
                    className={`${
                      checkRole === ROLE.PARTNER || (organizationId !== idProvider && isChildCompany) || disabled ? 'button-disable-contract' : ''
                    } button-contract`}
                  >
                    アップロード
                  </label>
                )}
              </div>
            </div>
          </div>
          {listFileNda?.map((item, index) => {
            return (
              <div key={index} className="col-12">
                <div className="row">
                  <div className="col-3"></div>
                  <div
                    onClick={() => window.open(`${item?.url}`)}
                    className={`${disabled ? 'disabled-text' : ''} col-4 text-left cursor-pointer`}
                  >
                    {item.name}
                  </div>
                  <div className={`${disabled ? 'disabled-text' : ''} col-4`} style={{ textAlign: 'end' }}>
                    {item.timeUpload}
                  </div>
                  {(checkRole !== ROLE.PARTNER || !(organizationId !== idProvider && isChildCompany)) && (
                    <div
                      className={`${disabled ? 'disabled-text' : ''} col-1 cursor-pointer`}
                      onClick={() => handleDeleteFile(item?.key, 'files')}
                    >
                      [X]
                    </div>
                  )}
                </div>
              </div>
            );
          })}
          <div className="col-3 text-center">
            <label className="label-contract">コメント欄</label>
          </div>
          <div className="col-9">
            <input
              disabled={disabled}
              className={`${disabled ? 'input-contract-disabled' : ''} input-contract`}
              type="text"
              name="comment"
              value={dataNDA?.comment}
              onChange={onChangeTextNDA}
            />
          </div>
          <div className="col-3 text-center">
            <label className="label-contract">状態</label>
          </div>
          <div className="col-3">
            <select
              name="state"
              disabled={checkRole === ROLE.PARTNER || (organizationId !== idProvider && isChildCompany) || disabled}
              value={dataNDA?.state}
              className="text-input select--result select--w100"
              onChange={onChangeOptionEstimates}
            >
              <option defaultValue=""></option>
              {optionData?.map((it, i) => {
                return (
                  <option value={it} key={i}>
                    {it}
                  </option>
                );
              })}
            </select>
            {/* <input
              type="text"
              className="input-contract"
              name="state"
              value={dataNDA?.state}
              onChange={onChangeTextNDA}
            /> */}
          </div>
          <div className="col-3 text-center">
            <label className="label-contract">完了日</label>
          </div>
          <div className="col-3">
            <div className="block-date-custom">
              <DatePicker
                disabled={checkRole === ROLE.PARTNER || (organizationId !== idProvider && isChildCompany) || disabled}
                selected={!_.isEmpty(dataNDA?.endDay) ? new Date(dataNDA?.endDay) : ''}
                onChange={(date) => onChangeDateNDA(date, 'endDay')}
                locale="ja"
                dateFormat={DATE_JP_RENDER}
                dateFormatCalendar="yyyy年 MM月"
                className={`${checkRole === ROLE.PARTNER || (organizationId !== idProvider && isChildCompany) || disabled ? 'input-contract-disabled' : ''} input-contract`}
              />
            </div>
          </div>
        </div>
        {checkRole === ROLE.CENTER || checkRole === ROLE.PARTNERGROUP ? (
          idProvider === organizationId || (idProvider !== organizationId && !isChildCompany) ? (
            <div className="justify-content-between align-items-center d-flex mb-2">
              <div>
                <button
                  disabled={disabled}
                  className={`${disabled ? 'button-disable-contract' : ''} button-submit-contract`}
                  onClick={() => {
                    let updateTitle = `完了 NDA ${dataNDA?.state}`;
                    onCreateAndUpdate('nda', 'statusDone', updateTitle);
                  }}
                >
                  完了
                </button>
              </div>
              <div>
                <button
                  disabled={disabled}
                  className={`${disabled ? 'button-disable-contract' : ''} button-submit-contract`}
                  onClick={() => {
                    let updateTitle = `差戻 NDA ${dataNDA?.state}`;
                    onCreateAndUpdate('nda', 'statusReject', updateTitle);
                  }}
                >
                  差戻
                </button>
              </div>
              <div>
                <button
                  disabled={disabled}
                  className={`${disabled ? 'button-disable-contract' : ''} button-submit-contract`}
                  onClick={() => {
                    let updateTitle = `保存 NDA ${dataNDA?.state}`;
                    onCreateAndUpdate('nda', '', updateTitle);
                  }}
                >
                  保存
                </button>
              </div>
            </div>
          ) : (
            <div className="justify-content-between align-items-center d-flex mb-2">
              <div></div>
              <div>
                {stepPermission === 1 ? (
                  <button
                    disabled={disabled}
                    className={`${disabled ? 'button-disable-contract' : ''} button-submit-contract`}
                    onClick={() => {
                      let updateTitle = `保存 NDA ${dataNDA?.state}`;
                      onCreateAndUpdate('nda', '', updateTitle);
                    }}
                  >
                    保存
                  </button>
                ) : (
                  <button
                    disabled={disabled}
                    className={`${disabled ? 'button-disable-contract' : ''} button-submit-contract`}
                    onClick={() => {
                      let updateTitle = `保存 NDA ${dataNDA?.state}`;
                      onCreateAndUpdate('nda', '', updateTitle);
                    }}
                  >
                    保存
                  </button>
                )}
              </div>
            </div>
          )
        ) : (
          <div className="justify-content-between align-items-center d-flex mb-2">
            <div></div>
            <div>
              {stepPermission === 1 ? (
                <button
                  disabled={disabled}
                  className={`${disabled ? 'button-disable-contract' : ''} button-submit-contract`}
                  onClick={() => {
                    let updateTitle = `保存 NDA ${dataNDA?.state}`;
                    onCreateAndUpdate('nda', '', updateTitle);
                  }}
                >
                  保存
                </button>
              ) : (
                <button
                  disabled={disabled}
                  className={`${disabled ? 'button-disable-contract' : ''} button-submit-contract`}
                  onClick={() => {
                    let updateTitle = `保存 NDA ${dataNDA?.state}`;
                    onCreateAndUpdate('nda', '', updateTitle);
                  }}
                >
                  保存
                </button>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default NDAContract;
