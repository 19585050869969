import ja from 'date-fns/locale/ja'; // the locale you want
import React, { useEffect, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { pushToast } from '../../../../../components/Toast';
import { DEVICE_ENDPOINT, EXPORT_GOODS_ENDPOINT, PRODUCT_ENDPOINT } from '../../../../../constants/endpoints';
import { SCREEN_NAME } from '../../../../../helpers/constants';
registerLocale('ja', ja);

const mockData = {
  productId: '',
  deviceId: '',
  managementCode: '',
  orderPlace: '',
  orderDate: '',
  expectedDate: '',
  unit: '',
  quantity: '',
  producer: '',
  screenName : SCREEN_NAME.ITEM_MANAGEMENT
};

const ModalAddListEnterGoods = (props) => {
  const { toggle = () => {}, modal = false, loadListStaff = () => {} } = props;
  const [dataGoods, setDataGoods] = useState(mockData);
  const [listProduct, setListProduct] = useState();
  const [listDevice, setListDevice] = useState([]);
  const [deviveSelected, setDeviveSelected] = useState('');

  useEffect(() => {
    // getAllProductByUser();
    getAllDeviceByUser();
  }, []);

  useEffect(() => {
    if (deviveSelected) {
      const infoDevice = listDevice?.filter(item => item.deviceId === deviveSelected)?.[0];
      const listProduct = infoDevice?.productSchema;
      setListProduct(listProduct)
    }
  }, [deviveSelected]);

  const getAllDeviceByUser = async () => {
    try {
      const data = await DEVICE_ENDPOINT.GET_LIST_DEVICE_INFO({ page: 0 });
      if (data) {
        setListDevice(data?.data?.body);
      }
    } catch (e) {
      console.log(e);
    }
  };

  // const getAllProductByUser = async () => {
  //   try {
  //     const data = await PRODUCT_ENDPOINT.GET_LIST_OPTION_PRODUCT_BY_USER();
  //     if (data) {
  //       setListProduct(data?.data?.body);
  //     }
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  const onClickSubmit = () => {
    createExportGoods(dataGoods);
  };

  const createExportGoods = async (body) => {
    try {
      const data = await EXPORT_GOODS_ENDPOINT.CREATE_EXPORT_GOODS(body);
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
      } else {
        pushToast('success', 'Create data success');
        toggle(toggle);
        setDataGoods(mockData);
        loadListStaff();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleChangeText = (e) => {
    let type = e.target.name;
    let newData = { ...dataGoods, [type]: e.target.value };
    setDataGoods(newData);
    if (type === 'deviceId') {
      setDeviveSelected(e.target.value);
    }
  };

  const handleChangeDate = async (date, name) => {
    let newData = await { ...dataGoods, [name]: date };
    setDataGoods(newData);
  };

  return (
    <Modal className="form-add-goods-master" isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle}>ADD</ModalHeader>
      <ModalBody>
        <form>
          <div className="form-group row ml-0">
            <label className="col-sm-5 col-form-label">品名</label>
            <div className="col-sm-7">
              <select
                className="form-control"
                name="deviceId"
                onChange={handleChangeText}
                value={dataGoods?.deviceId}
              >
                <option disabled value=''>---</option>
                {listDevice &&
                  listDevice.map((item, index) => {
                    return (
                      <option key={index} value={item.deviceId}>
                        {item.nameDevice}
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>

          <div className="form-group row ml-0">
            <label className="col-sm-5 col-form-label">案件名</label>
            <div className="col-sm-7">
              <select
                className="form-control"
                name="productId"
                onChange={handleChangeText}
                value={dataGoods ?.productId}
                disabled={!dataGoods.deviceId}>
                <option disabled value=''>---</option>
                {listProduct &&
                  listProduct.map((item, index) => {
                    return (
                      <option key={index} value={item.productId}>
                        {item.productName}
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>

          <div className="form-group row ml-0">
            <label className="col-sm-5 col-form-label">管理コード</label>
            <div className="col-sm-7">
              <input
                type="text"
                className="form-control"
                name="managementCode"
                onChange={handleChangeText}
                value={dataGoods?.managementCode}
              />
            </div>
          </div>

          <div className="form-group row ml-0">
            <label className="col-sm-5 col-form-label">発注元</label>
            <div className="col-sm-7">
              <input
                type="text"
                className="form-control"
                name="orderPlace"
                onChange={handleChangeText}
                value={dataGoods?.orderPlace}
              />
            </div>
          </div>

          <div className="form-group row ml-0">
            <label className="col-sm-5 col-form-label">発注日</label>
            <div className="col-sm-7">
              <DatePicker
                selected={dataGoods?.orderDate}
                onChange={(date) => handleChangeDate(date, 'orderDate')}
                locale="ja"
                dateFormat="yyyy/MM/dd"
                dateFormatCalendar="yyyy年 MM月"
                className="form-control"
              />
            </div>
          </div>

          <div className="form-group row ml-0">
            <label className="col-sm-5 col-form-label">入庫予定日</label>
            <div className="col-sm-7">
              <DatePicker
                selected={dataGoods?.expectedDate}
                onChange={(date) => handleChangeDate(date, 'expectedDate')}
                locale="ja"
                dateFormat="yyyy/MM/dd"
                dateFormatCalendar="yyyy年 MM月"
                className="form-control"
              />
            </div>
          </div>

          <div className="form-group row ml-0">
            <label className="col-sm-5 col-form-label">メーカー</label>
            <div className="col-sm-7">
              <input
                type="text"
                className="form-control"
                name="producer"
                onChange={handleChangeText}
                value={dataGoods?.producer}
              />
            </div>
          </div>

          <div className="form-group row ml-0">
            <label className="col-sm-5 col-form-label">入庫数量</label>
            <div className="col-sm-7">
              <input
                type="text"
                className="form-control"
                onChange={handleChangeText}
                name="quantity"
                placeholder="3000"
                value={dataGoods?.quantity}
              />
            </div>
          </div>

          <div className="form-group row ml-0">
            <label className="col-sm-5 col-form-label">単位</label>
            <div className="col-sm-7">
              <select className="form-control" name="unit" onChange={handleChangeText} value={dataGoods?.unit}>
                <option value="">----</option>
                <option value="package">package</option>
                <option value="kg">kg</option>
                <option value="box">box</option>
              </select>
            </div>
          </div>
        </form>
      </ModalBody>
      <ModalFooter>
        <Button className="btn btn-success" onClick={onClickSubmit}>
          更新
        </Button>
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalAddListEnterGoods;
