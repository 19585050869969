import React from 'react';
import './style.scss';
import TableUpdate from './TableUpdate';
import TableInformationPartner from './TableInformationPartner';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const data_left = [
  '5 ： Etherコネクト2',
  '5 ： Etherコネクト2',
  '6 ： Gigaコネクト',
  'Etherコネクト2',
  '7 ： Gigaコネクト2'
];

const data_right = [
  '4 ： Etherコネクト',
  '3 ： Etherアクセス',
  '2 ： ［IPR］光アクセス／プラス',
  '1 ： ［IPR］Etherアクセス（S）'
];

const ModifyPartner = () => {
  let user_id = (process.browser && new URL(window.location.href).searchParams.get('sid')) || '';
  return (
    <div className="body-modify-partner">
      <Helmet>
        <title>パートナーマスタ</title>
      </Helmet>
      {user_id === 'new' ? (
        <TableInformationPartner data_left={data_left} data_right={data_right} />
      ) : (
        <>
          <TableInformationPartner data_left={data_left} data_right={data_right} />
          <TableUpdate />
        </>
      )}
    </div>
  );
};

export default compose(withRouter)(ModifyPartner);
