import React from 'react';
import { compose } from 'redux';
import ButtonMasterSetting from '../ButtonMasterSetting';
import ButtonMasterSettingCustom from '../ButtonMasterSettingCustom';
import { translate } from 'react-i18next';
import { PERMISSION } from '../../../helpers/constants';

const ErpMenu = ({ idRole, t , colorText, detailCheck = {} }) => {
  return (
    <>
      {
        idRole && PERMISSION.NEOMARS.includes(idRole) && (
        <>
          <div className="page_icon">
            <div className="page_name page_name--nowrap" style = {{ color : `${colorText}` }}>{t('menu_partner.my_corp')}</div>
            <ButtonMasterSetting
              link="/master-setting"
              title="Master Setting"
              subTitle="集計表"
              color="dark-blue"
              icon="icon-list-alt"
            />
          </div>
          <div className="page_icon">
            <div className="page_name page_name--nowrap" style = {{ color : `${colorText}` }}>{t('menu_partner.organization_master')}</div>
            <ButtonMasterSetting
              link="/organizations"
              subTitle=""
              title="ORGANIZATION MASTER"
              color="dark-blue"
              icon="fa-user-secret"
            />
          </div>
          <div className="page_icon">
            <div className="page_name page_name--nowrap" style = {{ color : `${colorText}` }}>{t('menu_partner.user_master')}</div>
            <ButtonMasterSetting
              title="USER MASTER"
              subTitle="ユーザー"
              color="dark-blue"
              icon="icon-user"
              link="/users"
            />
          </div>
          <div className="page_icon" style = {{display : 'none'}}>
            <div className="page_name page_name--nowrap" style = {{ color : `${colorText}` }}>{t('menu_partner.accounting')}</div>
            <ButtonMasterSetting
              link="/accounting"
              title="accounting"
              subTitle="accounting"
              color="dark-blue"
              icon="icon-list-alt"
            />
          </div>
          <div className="page_icon">
            <div className="page_name page_name--nowrap" style = {{ color : `${colorText}` }}>{t('menu_partner.calendar_master')}</div>
            <ButtonMasterSetting
              link="/calendar_master"
              title="calendar_master"
              subTitle="calendar_master"
              color="dark-blue"
              icon="icon-list-alt"
            />
          </div>
        </>
        )
      }
      {
        idRole && PERMISSION.ERP_CONTRACT_MANAGER.includes(idRole) && (
        <>
          <div className="page_icon">
            <div className="page_name page_name--nowrap" style = {{ color : `${colorText}` }}>{t('menu_partner.my_corp')}</div>
            <ButtonMasterSetting
              link="/master-setting"
              title="Master Setting"
              subTitle="集計表"
              color="dark-blue"
              icon="icon-list-alt"
            />
          </div>
          <div className="page_icon">
            <div className="page_name page_name--nowrap" style = {{ color : `${colorText}` }}>{t('menu_partner.organization_master')}</div>
            <ButtonMasterSetting
              link="/organizations"
              subTitle=""
              title="ORGANIZATION MASTER"
              color="dark-blue"
              icon="fa-user-secret"
            />
          </div>
          <div className="page_icon">
            <div className="page_name page_name--nowrap" style = {{ color : `${colorText}` }}>{t('menu_partner.user_master')}</div>
            <ButtonMasterSetting
              title={t('menu_partner.user_master')}
              subTitle={t('menu_partner.user_master')}
              color="dark-blue"
              icon="icon-user"
              link="/users"
            />
          </div>
          <div className="page_icon">
            <div className="page_name page_name--nowrap" style = {{ color : `${colorText}` }}>{t('menu_partner.anken_master')}</div>
            <ButtonMasterSetting
              link="/anken-master"
              title="Master Monitor"
              subTitle="集計表"
              color="dark-blue"
              icon="icon-list-alt"
            />
          </div>
          <div className="page_icon">
            <div className="page_name page_name--nowrap" style = {{ color : `${colorText}` }}>{t('menu_partner.contract')}</div>
            <ButtonMasterSetting
              link="/contract"
              title={t('menu_partner.contract')}
              subTitle={t('menu_partner.contract')}
              color="dark-blue"
              icon="icon-list-alt"
            />
          </div>
          <div className="page_icon" style = {{display : 'none'}}>
            <div className="page_name page_name--nowrap" style = {{ color : `${colorText}` }}>{t('menu_partner.accounting')}</div>
            <ButtonMasterSetting
              link="/accounting"
              title="accounting"
              subTitle="accounting"
              color="dark-blue"
              icon="icon-list-alt"
            />
          </div>
          <div className="page_icon">
            <div className="page_name page_name--nowrap"  style = {{ color : `${colorText}` }}>{t('menu_partner.csv_master')}</div>
            <ButtonMasterSetting
              title={t('menu_partner.csv_master')}
              subTitle={t('menu_partner.csv_master')}
              color="dark-blue"
              icon="icon-file-excel"
              link="/csv-master"
            />
          </div>
          {/* <div className="page_icon">
            <div className="page_name page_name--nowrap"  style = {{ color : `${colorText}` }}>{t('menu_partner.payment_date')}</div>
            <ButtonMasterSetting
              title={t('menu_partner.payment_date')}
              subTitle={t('menu_partner.payment_date')}
              color="dark-blue"
              icon="icon-list-alt"
              link="/payment-date"
            />
          </div> */}
        </>
        )
      }
      {
        idRole && PERMISSION.ERP_OPERATION_MANAGER.includes(idRole) && (
          <>
            <div className="page_icon">
              <div className="page_name page_name--nowrap" style = {{ color : `${colorText}` }}>{t('menu_partner.organization_master')}</div>
              <ButtonMasterSetting
                link="/organizations"
                subTitle=""
                title="ORGANIZATION MASTER"
                color="dark-blue"
                icon="fa-user-secret"
              />
            </div>

            <div className="page_icon">
              <div className="page_name page_name--nowrap" style = {{ color : `${colorText}` }}>{t('menu_partner.user_master')}</div>
              <ButtonMasterSetting
                title="USER MASTER"
                subTitle="ユーザー"
                color="dark-blue"
                icon="icon-user"
                link="/users"
              />
            </div>

            <div className="page_icon">
              <div className="page_name page_name--nowrap" style = {{ color : `${colorText}` }}>{t('menu_partner.anken_master')}</div>
              <ButtonMasterSetting
                link="/anken-master"
                title="Master Monitor"
                subTitle="集計表"
                color="dark-blue"
                icon="icon-list-alt"
              />
            </div>

            <div className="page_icon">
              <div className="page_name page_name--nowrap" style = {{ color : `${colorText}` }}>{t('menu_partner.setting_order')}</div>
              <ButtonMasterSetting
                title="工事物件取込設定"
                subTitle="工程"
                color="dark-blue"
                icon="icon-file-excel"
                link="/importcsv-pri-sco"
              />
            </div>

            <div className="page_icon">
              <div className="page_name page_name--nowrap" style = {{ color : `${colorText}` }}>{t('menu_partner.setting_worker')}</div>
              <ButtonMasterSetting
                link="/configuration-worker"
                subTitle=""
                title="Configuration Worker"
                color="dark-blue"
                icon="fa-user-secret"
              />
            </div>

            <div className="page_icon">
              <div className="page_name page_name--nowrap" style = {{ color : `${colorText}` }}>{t('menu_partner.csv_master')}</div>
              <ButtonMasterSetting
                title="USER MASTER"
                subTitle="CSV出力設定"
                color="dark-blue"
                icon="icon-file-excel"
                link="/csv-master"
              />
            </div>

            {/* {
              detailCheck?.summarySetting && (
              <div className="page_icon">
                <div className="page_name page_name--nowrap" style = {{ color : `${colorText}` }}>{t('menu_partner.aggregate')}</div>
                <ButtonMasterSettingCustom
                  link="/aggregate"
                  subTitle=""
                  title="aggregate"
                  color="dark-blue"
                  icon="fa-user-secret"
                />
              </div>
              )
            } */}
            <div className="page_icon">
              <div className="page_name page_name--nowrap" style = {{ color : `${colorText}` }}>{t('menu_partner.workingSchedule')}</div>
              <ButtonMasterSetting
                title="スケジュール"
                subTitle="スケジュール"
                color="dark-blue"
                icon="icon-file-excel"
                link="/working-schedule"
              />
            </div>

            {
              detailCheck?.dispatch && (
                <div className="page_icon">
                  <div className="page_name page_name--nowrap" style = {{ color : `${colorText}` }}>{t('menu_partner.dispatch')}</div>
                  <ButtonMasterSetting
                    title="USER MASTER"
                    subTitle="ディスパッチ"
                    color="dark-blue"
                    icon="icon-user"
                    link="/dispatch-settings"
                  />
                </div>
              )
            }
            {/* <div className="page_icon">
              <div className="page_name" style = {{ color : `${colorText}` }}>物品管理</div>
              <ButtonMasterSetting
                link="/equipment-management?modify=1"
                subTitle=""
                title="Equipment Management"
                color="dark-blue"
                icon="icon-list-alt"
              />
            </div> */}
          </>
        )
      }
      {
        idRole && PERMISSION.PARTNER_CONTRACT_MANAGER.includes(idRole) && (
          <>
            <div className="page_icon">
              <div className="page_name page_name--nowrap" style = {{ color : `${colorText}` }}>{t('menu_partner.my_corp')}</div>
              <ButtonMasterSetting
                link="/master-setting"
                title="Master Setting"
                subTitle="集計表"
                color="dark-blue"
                icon="icon-list-alt"
              />
            </div>
            <div className="page_icon">
              <div className="page_name page_name--nowrap" style = {{ color : `${colorText}` }}>{t('menu_partner.user_master')}</div>
              <ButtonMasterSetting
                title="USER MASTER"
                subTitle="ユーザー"
                color="dark-blue"
                icon="icon-user"
                link="/users"
              />
            </div>
            <div className="page_icon">
              <div className="page_name page_name--nowrap" style = {{ color : `${colorText}` }}>{t('menu_partner.contract')}</div>
              <ButtonMasterSetting
                link="/contract"
                title="契約管理"
                subTitle="契約管理"
                color="dark-blue"
                icon="icon-list-alt"
              />
            </div>
          </>
        )
      }
    </>
  );
};

export default compose(translate('translations'))(ErpMenu);
