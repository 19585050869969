import React, { useState } from 'react';
import { Collapse } from 'reactstrap';
import 'react-datepicker/dist/react-datepicker.css';
import './style.scss';
import DateTimePicker from '../DateTimePicker';
import { ROLE } from '../../helpers/constants';
import CollapseCenter from './CollapseCenter';


const ListInformation = ({ color ,dataOrder}) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const toggleHide = () => setHideshow2(!hideshow2);
  const [hideshow2, setHideshow2] = useState(false);

  return (
    <div className="wrapper-list-information">
      <div className="wrapper-btn-colappse">
        <div onClick={toggle} className="mb-1 searchbar d-flex justify-content-between align-items-center">
          <div></div>
          <div>物件情報</div>
          <div className="mr-2" style={{ color: `#ddd`}}>
            {isOpen ? <i className="icon-up-open icon-searchbar"/> : <i className="icon-down-open icon-searchbar"/>}
          </div>
        </div>
      </div>
       <CollapseCenter dataOrder={dataOrder} isOpen={isOpen} />
    </div>
  );
};

export default ListInformation;
