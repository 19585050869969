import React, { useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import CollapseSearch from './CollapseSearch';

const SearchMenuSummary = ({ color, idScreenSetting, handleSearch }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  return (
    <div className="search-menu-wrapper">
      <div className="wrapper-btn-colappse">
        <div onClick={toggle} className="mb-1 searchbar d-flex justify-content-between align-items-center">
          <div></div>
          <div style={{ color: `${color}` }}>
            <i className="icon-search" />
            検索条件を表示
          </div>
          <div className="mr-2" style={{ color: `${color}` }}>
            {isOpen ? <i className="icon-up-open icon-searchbar" /> : <i className="icon-down-open icon-searchbar" />}
          </div>
        </div>
      </div>
      <CollapseSearch isOpen={isOpen} color={color} idScreenSetting={idScreenSetting} handleSearch={handleSearch}/>
    </div>
  );
};

export default SearchMenuSummary;
