import React, { useState, useEffect } from 'react';
import { withNamespaces } from 'react-i18next';
import { COMMON_ENDPOINT } from '../../constants/endpoints';
import { AREAS_PERMITTED } from 'helpers/areas';
import 'assets/scss/components/AreaSelection/style.scss';
import PopupAreas from 'components/popupAreas';
import _ from 'lodash';

const AreaSelectionNew = ({
  activeArea = {},
  onChange = () => {},
  prefectureAreas = {},
  onChangePrefectureAreas = () => {},
  t,
  listArea
}) => {
  const [areaSelected, setAreaSelected] = useState('')
  const [showPopupConfirm, setShowPopupConfirm] = useState(false);
  const [selected, setSelected] = useState('');
  const [dataPrefecture, setDataPrefecture] = useState({});

  const handleChangeArea = (e) => {
    let name = e.target.name;
    const activeAreaNew = { ...activeArea };
    activeAreaNew[name] = [...listArea[name]] || [];

    if (!e.target.checked) delete activeAreaNew[name];
    const activeAreaSort = {};
    Object.keys(listArea).map(e => {
      if (activeAreaNew[e]) {
        activeAreaSort[e] = activeAreaNew[e];
      }
    });
    onChange(activeAreaSort);
    setAreaSelected(name);
  };

  useEffect(() => {
    if (activeArea) {
      console.log(activeArea);
    }
  })

  useEffect(() => {
    if(showPopupConfirm && selected) {
      setDataPrefecture({[selected]: prefectureAreas?.[selected] || []});
    } else {
      setDataPrefecture({});
    }
  }, [showPopupConfirm])

  const handleSelectProvince = (area, e) => {
    let name = e.target.name;
    const activeAreaNew = { ...activeArea };

    if (!e.target.checked) {
      const index = activeAreaNew[area].indexOf(name);
      if (index > -1) activeAreaNew[area].splice(index, 1);
    } else {
      activeAreaNew[area].push(name);
    }

    const activeAreaSort = {};
    Object.keys(listArea).map(e => {
      if (activeAreaNew[e]) {
        activeAreaSort[e] = listArea[e].filter(elm => activeAreaNew[e].includes(elm));
      }
    });
    onChange(activeAreaSort);
  };

  const handlePopup = (province) => {
    setSelected(province);
    setShowPopupConfirm(!showPopupConfirm);
  }

  const handlePrefecture = ({k, city}) => {
    const newData = [...(dataPrefecture?.[selected] || [])];
    const checked = k.target.checked;
    if(checked) {
      newData.push(city);
    }else {
      const index = newData.findIndex(e => e === city);
      newData.splice(index, 1);
    }
    setDataPrefecture({...dataPrefecture, [selected]: _.uniq(newData)});
  }

  return (
    <div id="group-arial-wraper" className="areas">
      <div className="group-arial">
        <div className="group-arial__title">エリア</div>
        <ul className="group-arial__list">
          {Object.keys(listArea || [])?.map((item, index) => {
            return (
              <li onClick={() => setAreaSelected(item)} className={`cursor-pointer group-arial__list__item ${areaSelected === item && 'area-selected'}`} key={`${index}+${item}`}>
                <input
                  type="checkbox"
                  onChange={handleChangeArea}
                  name={item}
                  checked={item in activeArea}
                />
                <label
                  className="cursor-pointer"
                >
                  {t(`areas.${item}`)}
                </label>
              </li>
            )
          })}
        </ul>
      </div>
      <div className="group-arial">
        <div className="group-arial__title">都道府県</div>
        <ul className="group-arial__list">
          {
            listArea && Object.keys(listArea || {})?.map((k, v) => {
              if (activeArea[k]) {
                return listArea?.[k]?.map((province, index) => {
                  return (
                    <li className="group-arial__list__item" key={`${province} - ${index}`}>
                      <input
                        type="checkbox"
                        checked={activeArea[k]?.includes(province)}
                        onChange={(e) => handleSelectProvince(k, e)}
                        name={province}
                      />
                      <label> {province} </label>
                    </li>
                  )
                })
              }
            })
          }
        </ul>
      </div>
      <div className="group-arial">
        <div className="group-arial__title">対応可能都道府県</div>
        <ul className="group-arial__list">
          {
            activeArea && Object.keys(activeArea).map((area) => {
              return (
                activeArea?.[area]?.map((province, index) => {
                  return (
                    <li className="group-arial__list__item" key={`${province} x ${index}`} onClick={() => handlePopup(province)}>
                      <label className="show-label group-arial__list--pointer">
                        { province }
                      </label>
                    </li>
                  )
                })
              )
            })
          }
        </ul>
      </div>
      <PopupAreas
        headerTitle="市区町村選択"
        rederBody={() => {
          return (
            <div className="table--w70">
              <table>
                <tbody>
                {
                  AREAS_PERMITTED.map((e, index) => {
                    return (
                      e.prefectureNameKanji === selected && (
                        <tr key={index}>
                          <td className="table--w10 table--pd15">
                            <input type="checkbox" checked={_.flatten(Object.values(dataPrefecture)).includes(e.cityNameKanji)} value={e.cityNameKanji} onChange={(k) => handlePrefecture({k, city: e.cityNameKanji})} />
                          </td>
                          <td className="table--w90">{e.cityNameKanji}</td>
                        </tr>
                      )
                    )
                  })
                }
                </tbody>
              </table>
            </div>
          );
        }}
        okText="OK"
        onConfirm={() => {
          onChangePrefectureAreas({...prefectureAreas, ...dataPrefecture});
          setShowPopupConfirm(false);
        }}
        cancelText="キャンセル"
        onCancel={() => {
          setShowPopupConfirm(false);
        }}
        showPopup={showPopupConfirm}
        onToggle={() => setShowPopupConfirm(!showPopupConfirm)}
      />
    </div>
  )
}

export default withNamespaces('translations')(AreaSelectionNew)
