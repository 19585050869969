import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { translate } from 'react-i18next';
import { compose } from 'redux';
import { pushToast } from 'components/Toast';
import { MASTER_SETTING } from 'constants/endpoints';
import Loading from 'components/Loading/Loading';

const fieldUpdate = (securityRole) => {
  switch (securityRole) {
    case 'ERP設定':
      return 'erpSettings';
    case 'Management設定':
      return 'managementSettings';
    default:
      return 'monitoringSettings';
  }
}

const ModalBlockIp = ({toggle = () => {},  modal = false, securitySettings={}, color='', t, securityRole='', idProvide, getSecuritySettings, permissionId = ''}) => {
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState([]);

  useEffect(() => {
    if(modal){
      let ips = [];
      if (permissionId) {
        const setIpAddress = securitySettings?.howToLogIn?.setIpAddress?.[fieldUpdate(securityRole)] || []
        setIpAddress.map(e => {
          e.permissionId === permissionId && ips.push(e.ip);
        })
      } else {
        ips = securitySettings?.howToLogIn?.setIpAddress?.[fieldUpdate(securityRole)] || [];
      }
      setOptions(ips);
    }
  }, [modal]);

  useEffect(() => {
    let ips = [];
    if (permissionId) {
      const setIpAddress = securitySettings?.howToLogIn?.setIpAddress?.[fieldUpdate(securityRole)] || []
      setIpAddress.map(e => {
        e.permissionId === permissionId && ips.push(e.ip);
      })
    } else {
      ips = securitySettings?.howToLogIn?.setIpAddress?.[fieldUpdate(securityRole)];
    }

    setOptions(ips);
  }, [securitySettings])
  const removeElm = (index) => {
    const newOptions = [...options];
    newOptions.splice(index, 1);
    setOptions(newOptions);
  };

  const addElm = () => {
    const newOptions = [...options];
    newOptions.push("");
    setOptions(newOptions);
  };

  const onChange = (e, index) => {
    const newOptions = [...options];
    newOptions[index] = e.target.value;
    setOptions(newOptions);
  };

  const UpdateBlockIps = async ({ body }) => {
    setLoading(true);
    try {
      const data = await (MASTER_SETTING.UPDATE_BLOCK_IPS({ body, id: securitySettings?.securitySettingId, idProvide }))
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
      } else {
        pushToast('success', 'Update Security Settings Success');
        getSecuritySettings();
      }
      setLoading(false);
    } catch (errorMessage) {
      console.log('errorMessage: ', errorMessage);
      setLoading(false);
    }
  };

  const onUpdate = () => {
    UpdateBlockIps({ body: {screenName: "基本情報", [fieldUpdate(securityRole)]: options} })
  };

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle}>
      <Loading loading={loading} />
      <ModalHeader>IPアドレス登録</ModalHeader>
        <ModalBody className="form__flex form__flex--col">
          {
            options?.map((key, index) => {
              return (
                <div className="col-12 row form--mgb15" key={index}>
                  <button className="button__edit col-2 form--mgr15" onClick={() => removeElm(index)} style={{ background: `${color}` }}>x</button>
                  <input className="col-9" type="text" value={key} onChange={e => onChange(e, index)} />
                </div>
              )
            })
          }
          <div className="col-12 row">
            <button className="button__edit col-2 form--pd" onClick={addElm} style={{ background: `${color}` }}>+</button>
            <div className="col-9"></div>
          </div>
          <button className="button__edit button__edit--resize" onClick={onUpdate} style={{ background: `${color}` }}>{t(`dispatch.save`)}</button>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>{t(`dispatch.cancel`)}</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default compose(translate('translations'))(ModalBlockIp);
