import React, { useEffect, useState } from 'react';
import FooterMaintenance from '../../../components/FooterMaintenance';
import HeaderMasterSetting from '../../../components/HeaderMasterSetting';
import { COLOR_ENDPOINT } from '../../../constants/endpoints';
import 'assets/scss/page/ImportCsvPrimary/style.scss';
import TreeViewPriSco from './TreeViewPriSco';
import { useSelector } from 'react-redux';
import MemoHistory from '../../../components/MemoHistory';
import { SCREEN_NAME } from '../../../helpers/constants';
// import CollapseViewTree from './CollapseViewTree';
// import CollapseView from './CollapseView';

const ImportCsvPri = (props) => {
  const colorScreen = useSelector((store) => store.utils.colorMe);
  const [statusBeforeConfirm,setStatusBeforeConfirm] = useState(true);

  const getListOption = ({ option }) => {
    setStatusBeforeConfirm(!!option ?.confirmedTheDayBefore)
  };

  return (
    <div className="wrapper-import-csv-pri-sco-setting">
      <HeaderMasterSetting color={colorScreen?.code} colorText={colorScreen?.colorText} getListOption={getListOption}/>
      <div className="body pb-4">
        <div className="wrapper-main">
          <div className="wrapper-result">
            <TreeViewPriSco colors={colorScreen?.code} colorText={colorScreen?.colorText}  statusBeforeConfirm={statusBeforeConfirm}/>
            {/* <CollapseView colors={color} title={'詳細情報表示項目設定'} />
            <CollapseView colors={color} title={'追加情報表示項目設定'} />
            <CollapseView colors={color} title={'ZZZZ設定'} /> */}
          </div>
        </div>
        <MemoHistory colors={colorScreen?.code} colorText={colorScreen?.colorText} screenName={SCREEN_NAME.ORDER_SETTINGS}/>
      </div>
      <FooterMaintenance />
    </div>
  );
};

export default ImportCsvPri;
