import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import Loading from "components/Loading/Loading";
import { translate } from 'react-i18next';
import { compose } from 'redux';
import { pushToast } from "components/Toast";
import { DISPATCH } from "constants/endpoints";
import Select from 'react-select';
import _ from 'lodash';
import { SCREEN_DISPATCH_SETTING } from '../../../../helpers/constants';

const ModalAddSelect = ({toggle = () => {},  modal = false, color='', productId='', handleHeaders, t, nameTitle }) => {
  const [options, setOptions] = useState([]);
  const [optionData, setOptionData] = useState({});
  const [selection, setSelection] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if(modal){
      getOptionDispatch({productId});
    }
  }, [modal]);

  const getOptionDispatch = async ({ productId }) => {
    try {
      setLoading(true);
      const data = await DISPATCH.GET_OPTION_DISPATCH({ productId });
      if (data) {
        setOptions(
          _.map(data?.data, (val, key) =>
            {
              return {value: key, label: val.name}
            }
          )
        );
        setOptionData(data?.data);
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const trimData = (data) => {
    return _.mapValues(data, function(o) {return (o.map(i => i?.trim()))});
  };

  const updateOptionDispatch = async ({ body, productId }) => {
    const newData = trimData(body);
    try {
      setLoading(true);
      const data = await DISPATCH.UPDATE_OPTION_DISPATCH({ body : { ...newData , screenName : SCREEN_DISPATCH_SETTING.orderInformation.label }, productId });
      if (data && data.code !== 0) {
        if (data && data?.message && data?.message?.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
        setLoading(false);
      } else {
        getOptionDispatch({productId});
        pushToast("success", "Update Option Dispatch Success");
        handleHeaders(data?.data?.Attributes?.serviceOptions);
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      pushToast("error", "Update Option Dispatch Fail");
      setLoading(false);
    }
  };

  const updateOption = () => {
    updateOptionDispatch({body: _.mapValues(optionData, (val, key) => {return val.options}), productId});
  }

  const handleChange = (e) => {
    setSelection(e);
  }

  const addItem = () => {
    if(!_.isEmpty(selection)){
      const arr = [...optionData[selection.value].options, ""];
      const tmp = {...optionData, [selection.value]: {name: selection.label, options: arr}};
      setOptionData(tmp);
    }
  }

  const removeItem = (val, item, key) => {
    let tmpOption = [...val.options];
    tmpOption.splice(_.findIndex(tmpOption, function(v) { return v == item; }), 1);
    const tmp = {...optionData, [key]: {...val, options: tmpOption}};
    setOptionData(tmp);
  }

  const changeText = (e, index, key, val) => {
    const value = e.target.value;
    let tmpOption = [...val.options];
    tmpOption[index] = value;
    const tmp = {...optionData, [key]: {...val, options: tmpOption}};
    setOptionData(tmp);
  }

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle}>
        <Loading loading={loading} />
        <ModalBody className="form__flex form__flex--col">
          <label className="label__custom label__custom--w45" style={{ background: `${color}` }}>{t(`dispatch.label`)}</label>
          <div className="form__flex form__flex--center form__flex--mgb-10">
            <Select
              name="colors"
              options={Object.values(options)}
              className="select__custom--w60"
              classNamePrefix="select"
              onChange={(e) => handleChange(e)}
            />
            <button className="button__edit button__edit--resize button__edit--w10" style={{ background: `${color}` }} onClick={addItem} >+</button>
          </div>
          {
            _.map(optionData, (val, key) =>{
              return val.options.map((item, index) => {
                return (
                  <div className="form__flex form__flex--center form__flex--mgb-10" key={index}>
                    <label className="label__custom truncate" style={{ background: `${color}` }} title={val.name}>{val.name}</label>
                    <input className="input--resize" type="text" value={item} onChange={e => changeText(e, index, key, val)} />
                    <button className="button__edit button__edit--resize button__edit--w10" style={{ background: `${color}` }} onClick={() => removeItem(val, item, key)} >-</button>
                  </div>
                )
              })
            })
          }
          <button className="button__edit button__edit--resize" style={{ background: `${color}` }} onClick={updateOption}>{t(`dispatch.save`)}</button>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>{t(`dispatch.cancel`)}</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default compose(translate('translations'))(ModalAddSelect);
