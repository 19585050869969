import React, { useEffect, useState } from 'react';
import { ANKEN_MASTER_NEW } from 'constants/endpoints';
import { pushToast } from 'components/Toast';
import 'assets/scss/page/AnkenMasterNew/TableModal.scss';
import { translate } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import Loading from 'components/Loading/Loading';
import _ from 'lodash';
import { getDataHistoryMasterSetting } from 'actions/authentication';
import { useDispatch } from 'react-redux';
import { SCREEN_NAME } from '../../../../../helpers/constants';

const TableModalUsers = ({productAccess, color, nameTitle}) => {
  const dispatch = useDispatch();
  const [dataTable, setDataTable] = useState([]);
  const [dataChange, setDataChange] = useState([]);
  const [initData, setInitData] = useState([]);
  const [dataSearch, setDataSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    getAccessUsers({ productId: productAccess })
  }, [productAccess]);

  const getAccessUsers = async ({ productId }) => {
    try {
      setLoading(true);
      const data = await ANKEN_MASTER_NEW.GET_ACCESS_USER({ productId });
      if (data) {
        setDataTable(data?.data);
        setInitData(data?.data);
        setDataSearch(data?.data);
        changeChecked(data?.data);
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  }

  const addAccessUsers = async ({body, productId}) => {
    try {
      setLoading(true);
      const data = await ANKEN_MASTER_NEW.ADD_ACCESS_USER({ body, productId });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
        setLoading(false);
      } else {
        pushToast('success', 'Create Product success');
        dispatch(getDataHistoryMasterSetting({ logId: '', screenName: SCREEN_NAME.CASE_MASTA }));
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const onChangeUsers = (e, item) => {
    const value = e.target.checked;
    const newData = [...dataChange]
    newData.push({userId: item.userId, status: value});
    setDataChange(_.uniqBy(_.reverse(newData), 'userId'));
    const newDataTable = [...dataTable];
    const dataFilter = newDataTable.map(i => {
      i.statusPermisstionProviderSetting = i.userId === item.userId ? value : i.statusPermisstionProviderSetting
      return i
    });
    const newDataInitTable = [...initData];
    const dataInitFilter = newDataInitTable.map(i => {
      i.statusPermisstionProviderSetting = i.userId === item.userId ? value : i.statusPermisstionProviderSetting
      return i
    });
    setDataTable(dataFilter);
    setInitData(dataInitFilter);
    changeChecked(dataFilter);
  }

  const changeChecked = (dataFilter) => {
    setIsChecked(!dataFilter.find(item => !item.statusPermisstionProviderSetting ));
  }

  const onUpdateResultSettings = () => {
    addAccessUsers({ body: {userIds: dataChange, updateTitle: `登録 案件アクセス設定 ${nameTitle ? nameTitle : ''}`, screenName: SCREEN_NAME.CASE_MASTA}, productId: productAccess})
  }

  const onTypeSearch = (e) => {
    const search = e.target.value;
    setDataSearch(search)
  }

  const onSearch = () => {
    const format = /[ *()+\-=\[\]{}\\|\/?]/;
    const newData = [...initData];
    if(format.test(dataSearch)) {
      setDataTable(newData);
    } else {
      const data = _.filter(newData, function(elm) {
        return `${elm.firstName}${elm.lastName}`.search(new RegExp(`${dataSearch}`, "i")) !== -1
      });
      setDataTable(data);
    }
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      onSearch();
    }
  }

  const handleCheckAll = (e) => {
    const value = e.target.checked;
    let newData = [...dataTable];
    if(value) {
      let arrChecked = []
      const data = newData.map(item => {
        arrChecked.push({userId: item.userId, status: value})
        return {...item, statusPermisstionProviderSetting: value}
      });
      setDataTable(data);
      setDataChange(_.uniqBy(arrChecked, 'userId'));
    } else {
      let arrUnchecked = []
      const data = newData.map(item => {
        arrUnchecked.push({userId: item.userId, status: value})
        return {...item, statusPermisstionProviderSetting: value}
      });
      setDataTable(data);
      setDataChange(_.uniqBy(arrUnchecked, 'userId'));
    }
    setIsChecked(value);
  }

  const renderTableInfo = (data) => {
    return (
      <table className="table table-modal-user" align="center">
        <thead>
          <tr>
            <th className="pl-3">
              <input
                type="checkbox"
                onChange={handleCheckAll}
                checked={isChecked}
              />
              All
            </th>
            <th>利用者名</th>
            <th>発注企業名</th>
            <th>システム</th>
            <th>利用者権限</th>
          </tr>
        </thead>
        <tbody >
          {dataTable?.map((item, index) => {
            return (
              <tr key={index}>
                <td className="pl-3">
                  <input
                    name="userIds[]" type="checkbox"
                    onChange={e => onChangeUsers(e, item)}
                    checked={item?.statusPermisstionProviderSetting}
                  />
                </td>
                <td>{item?.firstName}{item?.lastName}</td>
                <td>{item?.companyName}</td>
                <td>{item?.layer}</td>
                <td>{item?.mainRole}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  return (
    <div className="wrapper-master-setting wrapper-master-setting--w100">
      <Loading loading={loading} />
      <div className="body pb-4">
        <div className="wrapper-main">
          <div className="d-flex justify-content-end align-items-center group-button">
            <button className="button--blue button--resize" onClick={onUpdateResultSettings}>登録</button>
          </div>
          <div className="wrapper-result">
          <div className="form-search">
            <div className="group-input">
              <div className="title">検索条件</div>
              <input type="text" onChange={onTypeSearch} onKeyDown={handleKeyDown}/>
            </div>
            <button className="btn-tree" onClick={onSearch}>検索</button>
          </div>
            {renderTableInfo()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default compose(translate('translations'), withRouter)(TableModalUsers);
