import React, { useState, useEffect } from 'react';
import './style.scss';
import ModalDetailSettingOrder from '../../containers/page/MasterSetting/ModalDetailSettingOrder';

const SupportStatus = ({ getDataBody, getStatusBody, orderDataDetail, orderId, color }) => {
  const [statusRequiredWorker, setStatusRequiredWorker] = useState(false);
  const [allocation, setAllocation] = useState('');
  const [statusWorker, setstatusWorker] = useState('');
  const [isModal, setisModal] = useState(false);
  const [modal, setModal] = useState(false);
  const [buttonNumber, setButtonNumber] = useState(null);
  useEffect(() => {
    setStatusRequiredWorker(orderDataDetail?.statusRequiredWorker || false);
    setAllocation(orderDataDetail?.allocation || '');
    setstatusWorker(orderDataDetail?.statusWorker || '');
  }, [orderDataDetail, orderId]);
  const setStatusRequiredOfWorker = (e) => {
    let value = e.target?.value;
    if (value === 'true' || value === true) {
      value = true;
    } else {
      value = false;
    }
    setStatusRequiredWorker(value);
    getStatusBody({ statusRequiredWorker: value });
  };
  const getAllocation = (e) => {
    setAllocation(e.target.value);
    getDataBody({ allocation: e.target.value });
  };
  const getStatusWorker = (e) => {
    setstatusWorker(e.target.value);
    getDataBody({ statusWorker: e.target.value });
  };

  const toggleModal = (e) => {
    setButtonNumber(e.target.value);
    setModal(!modal);
    setisModal(true);
  };
  const options = [
    {
      label: '対応待ち',
      value: 'workerUnallocated',
    },
    {
      label: '対応中',
      value: 'workersAreBeingAssigned',
    },
    {
      label: '対応完了',
      value: 'preConfirmationCompleted',
    },
  ];
  return (
    <div className="wrapper-support-status">
      <div className="detail-cancel-table-wrapper detail-cancel-table-wrapper-three">
        <div className="title-absolute">作業員対応状況</div>
        <div className="block-item">
          <div className="block-left mb-1">
            <label>割当</label>
            <input type="text" name="allocation" value={allocation} onChange={getAllocation} />
          </div>
          <div className="block-right">
            <button className="block-btn block-btn-gray" value={1} onClick={toggleModal}>
              訪問日時確認
            </button>
            <button className="block-btn block-btn-yellow" value={2} onClick={toggleModal}>
              持参品確認
            </button>
            <button className="block-btn block-btn-yellow" value={3} onClick={toggleModal}>
              部材確認
            </button>
          </div>
        </div>
        <div className="block-item">
          <div className="block-left">
            <label>状態</label>
            <select value={statusWorker} onChange={getStatusWorker}>
              <option value=""></option>
              {options.map((option, index) => (
                <option value={option.value} key={index}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
          <div className="block-right">
            <span className="title">承認依頼</span>
            <span>
              <label>必要</label>
              <input
                type="radio"
                value="true"
                checked={statusRequiredWorker === true}
                onChange={setStatusRequiredOfWorker}
                name="statusRequiredWorker"
              />
            </span>
            <label>不要</label>
            <span>
              <input
                type="radio"
                value="false"
                checked={statusRequiredWorker === false}
                onChange={setStatusRequiredOfWorker}
                name="statusRequiredWorker"
              />
            </span>
          </div>
        </div>
      </div>
      {isModal && (
        <ModalDetailSettingOrder
          orderId={orderId}
          productId={orderDataDetail?.productId || ''}
          serviceId={orderDataDetail?.serviceId || ''}
          buttonNumber={buttonNumber}
          modal={modal}
          toggle={toggleModal}
          color={color}
        />
      )}
    </div>
  );
};

export default SupportStatus;
