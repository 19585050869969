import React, { useEffect, useState } from 'react';
import Header from 'components/Header';
import { ORDER, MASTER_SETTING } from 'constants/endpoints';
import { HEADER_MENU, NAME_MASTER_SETTING } from 'helpers/constants';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { translate } from 'react-i18next';
import DetailData from 'components/DetailCenter/DetailData';
import FormOrder from '../FormOrder';
import Footer from "../../../../components/Footer/index.jsx"
import _ from 'lodash';

const RecreateOrder = ({ match }) => {
  const [idRole, setIdRole] = React.useState(null);
  let name = (process.browser && new URL(window.location.href).searchParams.get('p')) || HEADER_MENU.RECEIVE_INFO;
  const [p] = React.useState(name || HEADER_MENU.RECEIVE_INFO);
  const colorScreen = useSelector((store) => store.utils.colorMe);
  const [data, setData] = useState('');
  const [listAddress, setListAddress] = useState('');
  const orderId = match.params.id;

  useEffect(() => {
    getListAddress({ name: NAME_MASTER_SETTING.ADDRESS_JAPAN });
    getOrderDetail({ orderId });
  }, []);

  const getListAddress = async ({ name = '' }) => {
    try {
      const data = await MASTER_SETTING.GET_LIST_ADDRESS({ name: name });
      if (data) {
        setListAddress(_.flatten(Object.values(data?.data?.values || [])));
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getOrderDetail = async ({ orderId = '' }) => {
    try {
      const data = await ORDER.GET_ORDER_RECREATE({ orderId });
      if (data) {
        setData({...data?.data, ...{visitDate: '', visitDateFrom: '', visitDateTo: '', preferredDate: {}}});
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    const newRole = localStorage.getItem('role');
    setIdRole(newRole);

    if (orderId) {
      getOrderDetail({ orderId });
    }
  }, [orderId]);

  return (
    <div className="wrapper-describe-receive">
      <div className="main">
        <Header p={p} idRole={idRole} color={colorScreen?.code} colorText={colorScreen?.colorText} />
        <div className="body-wrapper">
          <div className="logout_btn">
            <Link to={`/recreate`}>
              <span className="mypage_ab_custom">戻る</span>
            </Link>
          </div>
          <DetailData data={data} p={p} link={false} />
          <FormOrder data={data} p={p} link={false} listAddress={listAddress} />
        </div>
        <Footer color={colorScreen?.code} colorText={colorScreen?.colorText} />
      </div>
    </div>
  );
};

export default translate('translations')(RecreateOrder);
