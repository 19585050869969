import React, { useState } from 'react';
import HeaderMaintenance from '../../../components/HeaderMaintenance';
import BreadcrumbMaintenance from '../../../components/BreadMaintenance';
import TableSelectMaster from '../PageMaster/TableSelect';
import './style.scss';
import FooterMaintenance from '../../../components/FooterMaintenance';

const OutputConf = () => {
  const dataAllSelect = [
    { label: '訪問先拠点住所', value: '訪問先拠点住所', type: 'basic-select' },
    { label: '申し送り事項', value: '申し送り事項', type: 'basic-select' },
    {
      label: 'パートナー伝達用申し送り事項',
      value: 'パートナー伝達用申し送り事項',
      type: 'basic-select',
    },
    { label: '担当者名', value: '担当者名', type: 'basic-select' },
    { label: '稼働調整日', value: '稼働調整日', type: 'basic-select' },
  ];

  const dataBasicSelect = [
    { label: '1', value: '1', type: 'basic-select' },
    { label: '2', value: '2', type: 'basic-select' },
    { label: '3', value: '3', type: 'basic-select' },
    { label: '4', value: '4', type: 'basic-select' },
    { label: '5', value: '5', type: 'basic-select' },
  ];

  const item = [
    { value: 'receive_info', label: '受注情報' },
    { value: 'appoint_info', label: 'アポイント情報' },
    { value: 'condition_info', label: '稼働調整情報' },
  ];

  const [fontSize, setFontSizeAll] = useState('small');
  const [dataBasic, setDataBasic] = useState(dataBasicSelect);
  const [dataAll, setDataAll] = useState(dataAllSelect);
  const [basicSelect, setBasicSelect] = useState(null);
  const [allSelect, setAllSelect] = useState(null);

  const handleChangeBasic = (event) => {
    setBasicSelect(event.target.selectedIndex);
    setAllSelect(null);
  };

  const handleChangeAll = (event) => {
    setAllSelect(event.target.selectedIndex);
    setBasicSelect(null);
  };

  const onClickInsert = (index) => {
    if (index != null) {
      let newdata = [...dataAll];
      let itemValue = { ...dataBasic[index] };
      if (itemValue) {
        newdata.push(itemValue);
      }
      setDataAll(newdata);
      let newDataBasic = [...dataBasic];
      newDataBasic.splice(index, 1);
      setDataBasic(newDataBasic);
      setBasicSelect(null);
      setAllSelect(null);
    }
  };

  const onClickDel = (index) => {
    if (index !== null) {
      let newBasicData = [...dataBasic];
      let itemValue = { ...dataAll[index] };
      newBasicData.push(itemValue);
      setDataBasic(newBasicData);
      let newAllData = [...dataAll];
      newAllData.splice(index, 1);
      setDataAll(newAllData);
      setAllSelect(null);
      setBasicSelect(null);
    }
  };

  function array_move(arr, old_index, new_index) {
    while (old_index < 0) {
      old_index += arr.length;
    }
    while (new_index < 0) {
      new_index += arr.length;
    }
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    setDataAll(arr);
    return arr; // for testing purposes
  }

  const onClickUp = () => {
    if (allSelect > 0) {
      array_move(dataAll, allSelect, allSelect - 1);
      setAllSelect(allSelect - 1);
    }
  };

  const onClickDown = () => {
    if (allSelect < dataAll.length - 1) {
      array_move(dataAll, allSelect, allSelect + 1);
      setAllSelect(allSelect + 1);
    }
  };

  const onClickUpFirst = () => {
    if (allSelect !== 0) {
      array_move(dataAll, allSelect, 0);
      setAllSelect(0);
    }
  };

  const onClickDownLast = () => {
    if (allSelect !== dataAll.length) {
      array_move(dataAll, allSelect, dataAll.length - 1);
      setAllSelect(dataAll.length - 1);
    }
  };
  const [select, setSelect] = React.useState('');
  const handleSelect = (e) => {
    let index = e.target.selectedIndex;
    let text = e.target[index].text;
    setSelect(text);
  };

  const breadcrumb = [
    { name: 'CENTER MASTER', link: '/center_master_all' },
    { name: 'CSV出力設定', link: '/csv_output_conf' },
  ];
  return (
    <div className="wrapper-output-conf">
      <HeaderMaintenance setFontSizeAll={setFontSizeAll} />
      <div className="body pb-4">
        <div className="wrapper-main">
          <BreadcrumbMaintenance breadcrumb={breadcrumb} fontSize={fontSize} />
          <div className="wrapper-result">
            <div className="result-title">
              <h3 className={`font-weight-bolder ${fontSize}`}>CSV出力設定（{select ? select : item[0].label} ）</h3>
            </div>
            <table cellSpacing="0" cellPadding="0" width="900px" align="center" border="0">
              <tbody>
                <tr>
                  <td>
                    <select name="screen_name_combo" className="p-1" id="screen_name_combo" onChange={handleSelect}>
                      {item.map((item) => (
                        <option value={item.value} defaultValue={item[0]}>
                          {item.label}
                        </option>
                      ))}
                    </select>
                  </td>
                  <td></td>
                  <td>
                    <input
                      type="button"
                      value="サンプルCSV出力"
                      id="output_csv"
                      name="output_csv"
                      className="button-submit btn btnS btn_submit btnSuccess"
                    />
                  </td>
                  <td></td>
                </tr>
              </tbody>
            </table>
            <div className="d-flex pb-4" style={{ height: '500px' }}>
              <div className="col-5 d-flex flex-column wraper-text">
                <div className="h-100">
                  <label className={`m-0 p-0 ${fontSize}`}>基本フィールド</label>
                  <TableSelectMaster
                    data={dataBasic}
                    fontSize={fontSize}
                    selected={basicSelect}
                    handleChange={handleChangeBasic}
                  />
                </div>
              </div>
              <div className="col-1 d-flex flex-column align-items-center justify-content-center group-button">
                <button
                  className={`btn button-onclick button-insert mb-1 ${fontSize}`}
                  onClick={() => onClickInsert(basicSelect)}
                >
                  追加→
                </button>
                <button
                  className={`btn button-onclick button-insert ${fontSize}`}
                  onClick={() => onClickDel(allSelect)}
                >
                  ←削除
                </button>
              </div>
              <div className="col-5 wraper-text">
                <label className={`m-0 p-0 ${fontSize}`}>表示フィールド</label>
                <TableSelectMaster
                  data={dataAll}
                  fontSize={fontSize}
                  handleChange={handleChangeAll}
                  selected={allSelect}
                />
                <div className=" d-flex justify-content-center">
                  <button className={`btn button-onclick  mt-3 ${fontSize}`}>登録</button>
                </div>
              </div>
              <div className="col-1 d-flex flex-column align-items-center justify-content-center">
                <input type="button" className="topbtn my-1" onClick={() => onClickUpFirst()} />
                <input type="button" className="upbtn my-1" onClick={() => onClickUp()} />
                <input type="button" className="downbtn my-1" onClick={() => onClickDown()} />
                <input type="button" className="bottombtn my-1" onClick={() => onClickDownLast()} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterMaintenance />
    </div>
  );
};

export default OutputConf;
