import React, { useEffect, useState } from 'react';
import FooterMaintenance from '../../../components/FooterMaintenance';
import HeaderMasterSetting from '../../../components/HeaderMasterSetting';
import { useSelector } from 'react-redux';
import { ORDER } from '../../../constants/endpoints';
import PaginationCustom from '../../../components/PaginationCustom';
import Orders from './Orders';
import Loading from 'components/Loading/Loading';
import _ from 'lodash';

const PaymentDate = () => {
  const colorScreen = useSelector((store) => store.utils.colorMe);
  const [listOrder, setListOrder] = useState([]);
  const [activePage, setActivePage] = React.useState(1);
  const [isShow, setIsShow] = useState('');
  const [loading, setLoading] = useState(false);

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    getListOrders({pageProduct: pageNumber});
  };

  const getListOrders = async ({
      page = 1, limit = 10, pageProduct = 1, limitProduct = 10
    }) => {
    try {
      setLoading(true);
      const data = await ORDER.GET_ORDERS_ACCEPTANCE({
        page, limit,pageProduct, limitProduct,
      });
      if (data) {
        setLoading(false);
        setListOrder(data?.data || []);
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const changeLoading = (isLoading = false) => {
    setLoading(isLoading);
  }

  const handleCollap = (key) => {
    if (isShow === key) {
      setIsShow('');
    } else {
      setIsShow(key);
    }
  }

  useEffect(() => {
    getListOrders({});
  }, []);

  return (
    <div className="wrapper-master-setting">
      <Loading loading={loading} />
      <HeaderMasterSetting color={colorScreen?.code} colorText={colorScreen?.colorText} />
      {
        <>
          <div className="body pb-4">
            <div className="wrapper-main">
              <div className="wrapper-result">
                <div className="result-title">
                </div>
              </div>
              {
                listOrder?.products?.map((item, index) => {
                  return <Orders key={index} colors={colorScreen?.code} colorText={colorScreen?.colorText} item={item} index={index} isShow={isShow} handleCollap={handleCollap} changeLoading={changeLoading} />
                })
              }
              <div className="d-flex justify-content-center align-items-center group-button"></div>
            </div>
            {
              !_.isEmpty(listOrder) && listOrder?.count > 10 && (
                <PaginationCustom
                activePage={activePage}
                itemsCountPerPage={10}
                totalItemsCount={listOrder && listOrder?.count}
                pageRangeDisplayed={5}
                handlePageChange={handlePageChange} />
              )
            }
          </div>
        </>
      }
      {/* <MemoHistory colors={colorScreen?.code} colorText={colorScreen?.colorText} screenName={SCREEN_NAME.PAYMENT_DATE}/> */}
      <FooterMaintenance />
    </div>
  );
};

export default PaymentDate;
