import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import HeaderMasterSetting from '../../../components/HeaderMasterSetting';
import { translate } from 'react-i18next';
import './style.scss';
import Footer from '../../../components/Footer';
import { HEADER_MENU, SCREEN_DISPATCH_SETTING } from '../../../helpers/constants';
import { getListOrder } from '../../../actions/order';
import { connect, useSelector } from 'react-redux';
import { COLOR_ENDPOINT, MASTER_SETTING } from '../../../constants/endpoints';
import { getRole } from '../../../helpers/helpers';
import ButtonActiveSetting from './ButtonActiveSetting';
import TableListProduct from './TableListProduct';
import SettingPriorityStaff from './SettingPriorityStaff';
import TableListStaff from './TableListStaff';
import TableListAdress from './TableListAdress';
import TableStaffSkill from './TableStaffSkill';
import WorkerPriority from '../WorkerPriority';
import MemoHistory from '../../../components/MemoHistory';

const DispatchSetting = (props) => {
  const { t } = props;
  let name = (process.browser && new URL(window.location.href).searchParams.get('p')) || 'condition_info';
  const [p, setP] = React.useState(name || HEADER_MENU.RECEIVE_ALL);
  const [role, setRole] = React.useState(null);
  const [idRole, setIdRole] = React.useState(null);
  const [color, setColor] = React.useState('#1c2a53');
  const [roleDetail, setRoleDetail] = useState('');
  const [isDispatch, setIsDispatch] = useState(false);
  const [informationActive, setInformationActive] = useState(SCREEN_DISPATCH_SETTING.orderInformation);
  const colorScreen = useSelector((store) => store.utils.colorMe);
  const onClickActive = (active) => {
    setInformationActive(active);
  };

  const renderInformationTable = (active) => {
    switch (active) {
      case SCREEN_DISPATCH_SETTING.orderInformation.value:
        return (
          <div className="body-wrapper">
            <TableListProduct
              idRole={idRole}
              p={p}
              t={t}
              role={role}
              color={colorScreen?.code}
              colorText={colorScreen?.colorText}
              roleDetail={roleDetail}
            />
          </div>
        );
      case SCREEN_DISPATCH_SETTING.siteInformation.value:
        return (
          <div className="body-wrapper">
            <TableListAdress
              active={active}
              idRole={idRole}
              p={p}
              t={t}
              role={role}
              color={colorScreen?.code}
              colorText={colorScreen?.colorText}
              roleDetail={roleDetail}
            />
          </div>
        );
      case SCREEN_DISPATCH_SETTING.workerInformation.value:
        return (
          <div className="body-wrapper">
            <TableStaffSkill
              active={active}
              idRole={idRole}
              p={p}
              t={t}
              role={role}
              color={colorScreen?.code}
              colorText={colorScreen?.colorText}
              roleDetail={roleDetail}
            />
          </div>
        );
      // case SCREEN_DISPATCH_SETTING.staffInformation.value:
      //   return (
      //     <div className="body-wrapper">
      //       <TableListStaff
      //         active={active}
      //         idRole={idRole}
      //         p={p}
      //         t={t}
      //         role={role}
      //         color={colorScreen?.code}
      //         colorText={colorScreen?.colorText}
      //         roleDetail={roleDetail}
      //       />
      //     </div>
      //   );
      case SCREEN_DISPATCH_SETTING.priorityPattern.value:
        return (
          <div className="body-wrapper">
            <SettingPriorityStaff />
          </div>
        );
      default:
        return (
          <div className="body-wrapper">
            <WorkerPriority colorScreen={colorScreen} />
          </div>
        );
    }
  };

  useEffect(() => {
    if (idRole) {
      setRoleDetail(getRole({ idRole: idRole }));
    }
  }, [idRole]);

  const getColor = async ({ role = '' }) => {
    try {
      const data = await COLOR_ENDPOINT.GET_COLOR({ role: idRole });
      if (data && data?.code === 0) {
        setColor(data?.data?.code);
      }
    } catch (e) {
      console.log(e);
    }
  };

const getListOptionSetting = async () => {
  try {
    const data = await MASTER_SETTING.GET_LIST_OPTION_SETTING(localStorage.getItem('idProvide'));
    if (data && data?.code === 0) {
      setIsDispatch(data?.data?.dispatch);
    }
  } catch (e) {
    console.log(e);
  }
};

  useEffect(() => {
    if (idRole) {
      getColor({ role: idRole });
    }
  }, [idRole]);

  useEffect(() => {
    setP(name);
  }, [name]);

  useEffect(() => {
    const newRole = localStorage.getItem('role');
    getListOptionSetting();
    setIdRole(newRole);
    if (newRole) {
      setRole(getRole({ idRole: newRole }));
    }
  }, []);
  return (
    <>
      <div className="main">
        <HeaderMasterSetting color={colorScreen?.code} colorText={colorScreen?.colorText} />
      {isDispatch && (
        <>
          <div>
            <ButtonActiveSetting color={color} active={informationActive.value} onClickActive={onClickActive} />
            {renderInformationTable(informationActive.value)}
          </div>
          <MemoHistory colors={colorScreen?.code} colorText={colorScreen?.colorText} screenName={informationActive.label}/>
          <Footer color={color} />
        </>
        )
      }
      </div>
    </>
  );
};

const mapStateToProps = (store) => {
  return {
    listOrder: store.order.listOrder,
    loading: store.utils.loading,
  };
};

const mapActionToProps = {
  getListOrder,
};

export default compose(
  translate('translations'),
  withRouter,
  connect(mapStateToProps, mapActionToProps)
)(DispatchSetting);
