export const ACTIONS = {
  SHOW_LOADING: 'SHOW_LOADING',
  HIDE_LOADING: 'HIDE_LOADING',
  GET_PARAM_SEARCH: 'GET_PARAM_SEARCH',
  GET_COLOR_ME:'GET_COLOR_ME',
  GET_LOGO: 'GET_LOGO',
  GET_DATA_CHAT: 'GET_DATA_CHAT'
};

export const showLoading = () => {
  return { type: ACTIONS.SHOW_LOADING };
};

export const hideLoading = () => {
  return { type: ACTIONS.HIDE_LOADING };
};

export const getParamSearch = (payload) => {
  return { type: ACTIONS.GET_PARAM_SEARCH, payload };
};

export const getColorMe = (payload) => {
  return { type: ACTIONS.GET_COLOR_ME, payload };
};

export const getLogo = (payload) => {
  return { type: ACTIONS.GET_LOGO, payload };
};

export const getDataChat = (payload) => {
  return { type: ACTIONS.GET_DATA_CHAT, payload };
};
