import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { Helmet } from 'react-helmet';
import FooterMaintenance from '../../../components/FooterMaintenance';
import './style.scss';
import TableProcessMaster from './TableProcessMaster';
import { SERVICE_ENDPOINT } from '../../../constants/endpoints';
import { pushToast } from '../../../components/Toast';
import HeaderMasterSetting from '../../../components/HeaderMasterSetting';

const INIT_PAGINATION = {
  page: 1,
  limit: 10
};

const ProcessMaster = () => {
  const fontSize = 'small';
  const [allService, setAllService] = useState([]);
  const [newService, setNewService] = useState(null);

  const handleChange = (e) => {
    setNewService(e.target.value);
  };

  const getAllService = async ({ page = 0, limit = INIT_PAGINATION.limit }) => {
    try {
      const data = await SERVICE_ENDPOINT.GET_ALL_SERVICE({ page: page, limit: limit });
      setAllService(data?.data?.body);
    } catch (e) {
      console.log(e);
    }
  };

  const onClickAddService = () => {
    if (newService && newService.length > 0) {
      addNewService({ name: newService });
    }
  };

  const addNewService = async (body) => {
    try {
      const data = await SERVICE_ENDPOINT.ADD_NEW_SERVICE(body);
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
      } else {
        getAllService({});
        setNewService('');
        pushToast('success', 'Create service success');
      }
    } catch (errorMessage) {
      console.log('errorMessage: ', errorMessage);
    }
  };


  useEffect(() => {
    getAllService({});
  }, []);

  return (
    <div className="wrapper-process-master">
      <Helmet>
        <title>基本工程設定</title>
      </Helmet>
      <HeaderMasterSetting/>
      <div className="body pb-4">
        <div className="wrapper-main">
          <div className="wrapper-result">
            <div className="result-title">
              <h3 className={`font-weight-bolder ${fontSize}`}>基本工程登録</h3>
            </div>
            <TableProcessMaster fontSize={fontSize} allService={allService} handleChange={handleChange}
                                newService={newService}/>
          </div>
          <div className="d-flex justify-content-center align-items-center group-button my-1">
            <button className={`btn button-submit mr-2 px-4 ${fontSize}`} onClick={() => onClickAddService()}>検索
            </button>
          </div>
        </div>
      </div>
      <FooterMaintenance/>
    </div>
  );
};

export default compose(withRouter)(ProcessMaster);
