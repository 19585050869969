import moment from 'moment';
import React, { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import PaginationCustom from '../../../../components/PaginationCustom';
import { IMPORT_GOODS_ENDPOINT } from '../../../../constants/endpoints';
import { ROLE, DATETIME_JP_FORMAT } from '../../../../helpers/constants';
import ModalUpdateGoodMaster from './ModalUpdateGoodMaster';

const TableListGoodsMasterFinish = ({ role = ROLE.PARTNER }) => {
  const [listImportGoods, setListImportGoods] = useState();
  const [activePage, setActivePage] = useState(1);
  const [modalAdds, setModalAdds] = useState(false);
  const [idImportGoods, setIdImportGoods] = useState();

  const getListStaff = async ({ page = 1, limit = 10 }) => {
    try {
      const data = await IMPORT_GOODS_ENDPOINT.GET_LIST_IMPORT_GOODS_INFO_FINISH({
        page: page,
        limit: limit,
      });
      if (data) {
        setListImportGoods(data?.data);
      }
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    getListStaff({ page: activePage });
  }, [activePage]);

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const renderItem = (item, index) => {
    return (
      <tr key={index}>
        <td>{index + 1}</td>
        <td>{item?.productName}</td>
        <td>{item?.managementCode}</td>
        <td>{item?.producer}</td>
        <td>{moment(item?.orderDate).format(DATETIME_JP_FORMAT)}</td>
        <td>{moment(item?.expectedDate).format(DATETIME_JP_FORMAT)}</td>
        <td>{moment(item?.receiptDate).format(DATETIME_JP_FORMAT)}</td>
        <td>{item?.receivedQuantity}</td>
        <td>{item?.status}</td>
        <td>
          <button onClick={() => openModals(item?.importGoodsInfoId)}>編集</button>
        </td>
      </tr>
    );
  };

  const toggleAdds = () => {
    setModalAdds(!modalAdds);
  };
  const openModals = (id) => {
    setIdImportGoods(id);
    setModalAdds(!modalAdds);
  };

  const loadListStaff = () => {
    getListStaff({ page: activePage });
  };

  return (
    <div className="table-menu-wrapper-goods-master">
      {
        modalAdds &&
        <ModalUpdateGoodMaster
          modal={modalAdds}
          idImportGoods={idImportGoods}
          toggle={toggleAdds}
          loadListStaff={loadListStaff}
        />
      }

      <div className="table-list-goods-master">
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>No</th>
              <th>品名</th>
              <th>管理コード</th>
              <th>発注元</th>
              <th>発注日</th>
              <th>入庫予定日</th>
              <th>入庫日</th>
              <th>数量</th>
              <th>ステータス</th>
              <th>編集</th>
            </tr>
          </thead>
          <tbody>
            {listImportGoods && listImportGoods?.body.map((listData, index) => renderItem(listData, index, role))}
          </tbody>
        </table>
      </div>
      {
        listImportGoods?.count > 10 ?
        <PaginationCustom
          activePage={activePage}
          itemsCountPerPage={10}
          totalItemsCount={listImportGoods?.count}
          pageRangeDisplayed={5}
          handlePageChange={handlePageChange}
        /> : ''
      }
    </div>
  );
};

export default TableListGoodsMasterFinish;
