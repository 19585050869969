import React, { useEffect, useState } from 'react';
import FooterMaintenance from '../../../components/FooterMaintenance';
import HeaderMasterSetting from '../../../components/HeaderMasterSetting';
import CollapseViewTree from './CollapseViewTree';
import CollapseView from './CollapseView';
import { useSelector } from 'react-redux';
import { SCREEN_NAME } from '../../../helpers/constants';
import MemoHistory from '../../../components/MemoHistory';

const ConfigWorker = (props) => {
  const colorScreen = useSelector((store) => store.utils.colorMe);

  return (
    <div className="wrapper-master-setting">
      <HeaderMasterSetting color={colorScreen?.code} colorText={colorScreen?.colorText}/>
      <div className="body">
        <div className="wrapper-main">
          <div className="wrapper-result">
            <div className="result-title"></div>
            <CollapseViewTree colors={colorScreen?.code} />
            {/* <CollapseView colors={colorScreen?.code} title={'詳細情報表示項目設定'} />
            <CollapseView colors={colorScreen?.code} title={'追加情報表示項目設定'} />
            <CollapseView colors={colorScreen?.code} title={'ZZZZ設定'} /> */}
          </div>
        </div>
      </div>
      <MemoHistory colors={colorScreen?.code} colorText={colorScreen?.colorText} screenName={SCREEN_NAME.WORKER_SETTINGS} />
      <FooterMaintenance />
    </div>
  );
};

export default ConfigWorker;
