import React from 'react';
import 'assets/scss/page/BranchMaster/TableBranchMaster.scss';
import { getNoOrder } from 'helpers/helpers';
import { Link } from 'react-router-dom';

const data = {
  header: ['拠点ID', '拠点名略称', '拠点名', 'フリガナ', '代表者名', 'フリガナ', '作成日', '操作'],
};

const renderItem = (item, index, fontSize, activePage) => {
  return (
    <tr key={index}>
      <td className="no-wrapper-client">
        <div className={`d-flex justify-content-center align-items-center font-weight-bolder no-style ${fontSize}`}>
          {getNoOrder({ index: index, limit: 10, activePage: activePage })}
        </div>
      </td>
      <td>{item?.branchId}</td>
      <td>{item?.branchAbbreviation}</td>
      <td>{item?.branchName}</td>
      <td>{item?.branchNameKana}</td>
      <td>{item?.representativeName}</td>
      <td>{item?.representativeNameKana}</td>
      <td>{item?.createdAt}</td>
      <td>
        <Link
          to={`/branch-master/edit-modify-branch?branchId=${item.branchId}`}
          style={{ color: '#00a63c', textDecoration: 'underline' }}
        >
          <button className="user_btn">
            編集
          </button>
        </Link>

        {/* <Link
          to={`branch-master/branch-staff-master?branchId=${item.branchId}`}
          style={{ color: '#00a63c', textDecoration: 'underline' }}
        >
          <button className="user_btn">
            <i className="fa fa-user" />
            ユーザー情報
          </button>
        </Link> */}
      </td>
    </tr>
  );
};

const TableBranchMaster = ({ fontSize, listBranchMaster = [], activePage = 1, count = 1 }) => {
  return (
    <div id="Result-branch-master">
      <div className="text-banner">
        <h3 className={`${fontSize} font-weight-bolder`}>検索結果：{count}件</h3>
      </div>
      <table className={`listTbl ${fontSize} table-branch-list`}>
        <thead>
          <tr>
            <th scope="col">No .</th>
            {data.header.map((item, index) => (
              <th scope="col" key={index}>
                {item}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {listBranchMaster?.length > 0 &&
            listBranchMaster.map((listData, index) => renderItem(listData, index, fontSize, activePage))}
        </tbody>
      </table>
    </div>
  );
};

export default TableBranchMaster;
