import React, { useEffect, useState } from 'react';
import './style.scss';
import FooterMaintenance from '../../../components/FooterMaintenance';
import CollaMasterListItem from './CollaMasterListItem';
import CollaSecurity from './CollaSecurity';
import CollaMasterViewTree from './CollaMasterViewTree';
import TableOrganizations from './TableOrganizations';
import CollaMasterSetting from './CollaMasterSetting';
import HeaderMasterSetting from '../../../components/HeaderMasterSetting';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { MASTER_SETTING } from '../../../constants/endpoints';
import MemoHistory from '../../../components/MemoHistory';
import { SCREEN_NAME } from '../../../helpers/constants';
import { getLogo, getColorMe } from 'actions/utils';
import Loading from 'components/Loading/Loading';
import { getRoleColor } from 'helpers/helpers';
import CollaAddFileHtml from './CollaAddFileHtml';

const MasterSetting = () => {
  const dispatch = useDispatch();
  const colorScreen = useSelector((store) => store.utils.colorMe);
  const [idRole, setIdRole] = useState('');
  const [idProvide, setIdProvide] = useState(localStorage.getItem('idProvide'));
  const [isChoose, setIsChoose] = useState(true);
  const [dataColorOrg, setDataColorOrg] = useState('');
  const [moduleManager, setModuleManager] = useState({});
  const dispatchAddLogoToCart = (message) => dispatch(getLogo(message));
  const dispatchAddToCart = (message) => dispatch(getColorMe(message));
  const [loading, setLoading] = useState(false);
  const [statusBeforeConfirm, setStatusBeforeConfirm] = useState(true);
  const permissionId = localStorage.getItem('permissionId');

  const getColorInfo = async ({ idRole, idProvide }) => {
    setLoading(true);
    try {
      const data = await MASTER_SETTING.GET_LIST_COLOR_BY_ORGANIZATION(1, idProvide);
      if (data) {
        setDataColorOrg(data?.data);
        if (data) {
          const color = data?.data?.[getRoleColor({ idRole })];
          const colorMaster = {
            code: color?.codeColor,
            colorText: color?.colorText,
            colorId: data?.data?.colorId,
          }
          if (idProvide === localStorage.getItem('idProvide')) {
            dispatchAddLogoToCart(data?.data);
            dispatchAddToCart(colorMaster);
          }
        }
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const getModuleManager = async ({ colorId }) => {
    setLoading(true);
    try {
      const data = await MASTER_SETTING.GET_MODULE_MANAGER({ colorId });
      if (data) {
        setLoading(false);
        setModuleManager(data?.data);
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  useEffect(() => {
    if (dataColorOrg) {
      getModuleManager({ colorId: dataColorOrg?.colorId });
    }
  }, [dataColorOrg]);

  useEffect(() => {
    const newRole = localStorage.getItem('role');
    getColorInfo({ idRole: newRole, idProvide });
    setIdRole(newRole);
  }, []);

  const changeProvider = (idProvide) => {
    setIdProvide(idProvide);
    setIsChoose(false);
    getColorInfo({ idRole, idProvide });
  }

  const onReturn = () => {
    setIsChoose(true);
  };

  const onLoading = (status) => {
    setLoading(status);
  };

  const pushListSetting = (option) => {
    setStatusBeforeConfirm(!!option?.confirmedTheDayBefore)
  };

  return (
    <div className="wrapper-master-setting">
      <Loading loading={loading} />
      <HeaderMasterSetting color={colorScreen?.code} colorText={colorScreen?.colorText} />
      {
        isChoose && idRole === '1' ? (<TableOrganizations color={colorScreen?.code} colorText={colorScreen?.colorText} changeProvider={changeProvider} onLoading={onLoading} />) : (
          <>
            {
              idRole === '1' && (
                <div className="detail-data-wrapper">
                  <div className="logout_btn">
                    <Link onClick={onReturn} to='#'>
                      <span className="mypage_ab">戻る</span>
                    </Link>
                  </div>
                </div>
              )
            }
            <div className="body pb-4">
              <div className="wrapper-main">
                <div className="wrapper-result">
                  <div className="result-title">
                  </div>
                  <CollaMasterSetting
                    idRole={idRole} colors={colorScreen?.code} colorText={colorScreen?.colorText}
                    idProvide={idProvide} dataColorOrg={dataColorOrg} moduleManager={moduleManager}
                    statusBeforeConfirm={statusBeforeConfirm} />
                  <CollaMasterViewTree
                    colors={colorScreen?.code} colorText={colorScreen?.colorText} idRole={idRole}
                    idProvide={idProvide} statusBeforeConfirm={statusBeforeConfirm} />
                  <CollaMasterListItem
                    colors={colorScreen?.code} colorText={colorScreen?.colorText}
                    idProvide={idProvide} idRole={idRole} pushListSetting={pushListSetting} />
                  <CollaSecurity colors={colorScreen?.code} colorText={colorScreen?.colorText} idProvide={idProvide} idRole={idRole} permissionId={permissionId} />
                  {+idRole === 1 && ( <CollaAddFileHtml colors={colorScreen?.code} colorText={colorScreen?.colorText} idProvide={idProvide} idProvide={idProvide}/> )}
                  <MemoHistory colors={colorScreen?.code} colorText={colorScreen?.colorText} screenName={SCREEN_NAME.MY_CORP} idProvide={idProvide}/>
                </div>
                <div className="d-flex justify-content-center align-items-center group-button"></div>
              </div>
            </div>
          </>
        )
      }
      <FooterMaintenance />
    </div>
  );
};

export default MasterSetting;
