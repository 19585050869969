import React from 'react';
import 'assets/scss/page/Describe/TableControl.scss';
import TableInnovation from "./TableInnovation";
const TableControl = () => {
  return (
    <div className="list">
      <table className="conditTab">
        <tbody>
          <tr>
            <th width="150">案件名</th>
            <td>［IPR］Etherアクセス（S）</td>
            <th width="150">管理コード</th>
            <td>OMS-C-05272165</td>
          </tr>
          <tr>
            <th>工程</th>
            <td>宅内工事</td>
            <th>都道府県</th>
            <td>埼玉県</td>
          </tr>
          <tr>
            <th>申込会社名</th>
            <td>大分西税務署</td>
            <th>訪問先拠点住所</th>
            <td>さいたま市大宮区大宮町１丁目１－４</td>
          </tr>
          <tr>
            <th>物件名</th>
            <td>税務相談室</td>
            <th>訪問先建物名</th>
            <td>大分西税務署</td>
          </tr>
        </tbody>
      </table>

      <table className="conditTab">
        <tbody>
          <tr>
            <th width="150px">
              パートナー伝達用
              <br />
              申し送り事項
            </th>
            <td>
              <textarea name="arrange_report" id="" cols="50" rows="10"></textarea>
            </td>
          </tr>
          <tr>
            <th>パートナー名</th>
            <td>
              <input name="partner_name_show" type="text" id="partner_name" value="" />
              <input type="hidden" name="partner_id" id="partner_id" value="" />
              {/*<a*/}
              {/*  href="javascript:void(0)"*/}
              {/*  onClick="window.open(&#39;partnerlistos.php?aid=1&#39;,&#39;search&#39;,&#39;menubar=no,scrollbars=yes,resizable=yes,height=500,width=600&#39;);"*/}
              {/*>*/}
              パートナー検索から自動入力（新規ウィンドウが開きます）
              {/*</a>*/}
              <div className="pt-1">
                <button
                  type="button"
                  // onClick="if(confirm(&#39;打診中パートナーで確保しますか？&#39;)){$(&#39;#form&#39;).submit()}"
                >
                  確保
                </button>
              </div>
            </td>
          </tr>
          <tr>
            <th>作業員返答必須</th>
            <td>
              <input type="radio" name="worker_fg" value="1" />
              作業員返答必須
              <input type="radio" name="worker_fg" value="0" checked="checked" />
              作業員返答不要
            </td>
          </tr>
        </tbody>
      </table>

      <div className="text-center">
        <input type="submit" className="btn_submit" value="保存" />
      </div>
      <TableInnovation/>
    </div>
  );
};

export default TableControl;
