import React from 'react';
import './style.scss';

const data = {
  header: ['工程', '使用状況', '並び順']
};

const renderItem = (item, index, fontSize) => {
  return (
    <tr key={index}>
      <td className="no-wrapper">
        <div className={`d-flex justify-content-center align-items-center font-weight-bolder ${fontSize}`}>
          {index + 1}
        </div>
      </td>
      <td>
        {
          item?.name
        }
      </td>
      <td>
        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="radio"
            value="option1"
            id={`inlineRadio1${index}`}
            name={`group${index}`}
            checked={item?.status}
          />
          <label className="form-check-label" htmlFor={`inlineRadio1${index}`}>
            利用中
          </label>
        </div>
        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="radio"
            value="option2"
            name={`group${index}`}
            id={`inlineRadio2${index}`}
            checked={!item?.status}
          />
          <label className="form-check-label" htmlFor={`inlineRadio2${index}`}>
            利用停止中
          </label>
        </div>
      </td>
      <td align="center">
        <button className="btn order-button mx-2">
          <i className="fa fa-angle-double-up"/>
          先頭
        </button>
        <button className="btn order-button mx-2">
          <i className="fa fa-angle-up"/>
          一つ上
        </button>
        <button className="btn order-button mx-2">
          <i className="fa fa-angle-down"/>
          一つ下
        </button>
        <button className="btn order-button mx-2">
          <i className="fa fa-angle-double-down"/>
          最後
        </button>
      </td>
    </tr>
  );
};

const TableProcessMaster = ({ fontSize = 'small', allService = [], handleChange, newService = '' }) => {

  return (
    <div className="table-list-process-wrapper table-responsive">
      <table className={`table table-striped mb-0 ${fontSize}`}>
        <thead>
        <tr>
          <th scope="col">No</th>
          {data.header.map((item, index) => (
            <th scope="col" key={index}>
              {item}
            </th>
          ))}
        </tr>
        </thead>
        <tbody>
        {allService.map((listData, index) => renderItem(listData, index, fontSize))}
        <tr>
          <th className="add-new">NEW</th>
          <td colSpan="3">
            <input className="form-control col-2" value={newService} onChange={handleChange}/>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  );
};

export default TableProcessMaster;
