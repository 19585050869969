import React from 'react';
import { HEADER_MENU, SCREEN_NAME } from 'helpers/constants';
import { ORDER, ORDER_CLIENT } from 'constants/endpoints';
import { useEffect } from 'react';
import { getRole } from 'helpers/helpers';
import Header from 'components/Header';
import Footer from 'components/Footer';
import 'assets/scss/page/DetailDeliveryClient/style.scss';
import { useState } from 'react';
import { pushToast } from 'components/Toast';
import DetailData from 'components/DetailCenter/DetailData';
import DetailDataList from '../../../components/DetailCenter/DetailDataList';
import DetailOrder from 'components/DetailCenter/DetailOrder';
import DetailListAllData from 'components/DetailCenter/DetailListAllData';
import DetailTreeDocumentSetting from 'components/DetailTreeDocumentSetting';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Loading from 'components/Loading/Loading';
import TableUploadReportUploadInfoMonitoring from 'components/TableUploadReportUploadInfoMonitoring';
import _ from 'lodash';

const mockData = {
  screenName: SCREEN_NAME.DELIVERED_CLIENT,
  defectiveReturnContents: '',
};

const DetailDeliveryClient = () => {
  let name = (process.browser && new URL(window.location.href).searchParams.get('p')) || 'delivered_info';
  let orderId = process.browser && new URL(window.location.href).searchParams.get('orderId');
  let organizationId = process.browser && new URL(window.location.href).searchParams.get('organizationId') || '';
  const [p, setP] = React.useState(name || HEADER_MENU.RECEIVE_ALL);
  const [role, setRole] = React.useState(null);
  const [idRole, setIdRole] = React.useState(null);
  const [data, setData] = React.useState('');
  const [dataOrder, setDataOrder] = useState(mockData);
  const colorScreen = useSelector((store) => store.utils.colorMe);
  const [loading, setLoading] = useState(false);
  const [documentSetting, setDocumentSetting] = useState([]);
  const [listDetailScreen, setListDetailScreen] = useState([]);
  const [dataTmp, setDataTmp] = useState([]);

  useEffect(() => {
    getListRoleChild();
  }, []);

  const getListRoleChild = async () => {
    setLoading(true);
    const newRole = localStorage.getItem('role');
    try {
      const data = await ORDER.GET_LIST_DETAIL_SETTING({
        arrRole: newRole,
        nameScreen: 'delivered_info',
        orderId,
        organizationId
      });
      if (data) {
        setListDetailScreen(data ?.data ?.detailScreen);
        setDocumentSetting(data?.data?.documentSettingDelivery);
      }
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (data) {
      setDataOrder({
        ...dataOrder,
        defectiveReturnContents: data.defectiveReturnContents,
      });
    }
  }, [data]);

  useEffect(() => {
    setP(name);
  }, [name]);

  const getOrderDetail = async ({ orderId = '' }) => {
    setLoading(true);
    try {
      const data = await ORDER.GET_DETAIL_CANCEL_INFO({ orderId, screenName: 'delivered_info', organizationId });
      if (data) {
        setData(data?.data);
      }
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  useEffect(() => {
    const newRole = localStorage.getItem('role');
    setIdRole(newRole);
    if (newRole) {
      setRole(getRole({ idRole: newRole }));
    }
    if (orderId) {
      getOrderDetail({ orderId });
    }
  }, [orderId]);

  const handleChangeText = (e) => {
    let name = e.target.name;
    let newData = { ...dataOrder };
    newData = { ...newData, [name]: e.target.value };
    setDataOrder(newData);
  };
  const updateOrderInfo = async ({ body = {}, id_order = '' }) => {
    setLoading(true);
    try {
      const data = await ORDER_CLIENT.UPDATE_DEFECTIVE({ body, id_order });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
      } else {
        pushToast('success', 'Update order success');
      }
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const onUpdateOrder = () => {
    updateOrderInfo({ body: dataOrder, id_order: orderId });
  };

  const onReload = () => {
    getOrderDetail({ orderId });
  };

  const handleSave = () => {
    handleUpdateReport({body: dataTmp, orderId});
  };

  const onChangeData = (tmp) => {
    setDataTmp(tmp);
  };

  const handleUpdateReport = async ({ body, orderId }) => {
  };

  return (
    <div className="wrapper-detail-delivery-client-list">
      <Loading loading={loading} />
      <div className="main">
        <Header idRole={idRole} color={colorScreen?.code} colorText={colorScreen?.colorText} p={p} />
        <div className="body-wrapper">
          <div className="logout_btn">
            <Link to={`list?p=${p}`}>
              <span className="mypage_ab_custom">戻る</span>
            </Link>
          </div>
          {_.sortBy(listDetailScreen, 'sortNum')?.map((item, index) => {
            if (item.status) {
              switch (item.name) {
                case 'clientCompany':
                  return <DetailOrder data={data} p={p} />;
                case 'applicationCompany':
                  return <DetailData data={data} p={p} link={false} />;
                case 'deliveredClient':
                  return (
                    <div className="detail-delivery-list-wrapper">
                      <div className="title-absolute">日付</div>
                      <table className="conditTab">
                        <tbody>
                          <tr>
                            <th width="150">依頼日</th>
                            <td>
                              <input type="text" value={data?.requestedDate} disabled />
                            </td>
                          </tr>
                          <tr>
                            <th>訪問日</th>
                            <td>
                              <input type="text" value={data?.visitDate} disabled />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  );
                case 'report':
                  return <TableUploadReportUploadInfoMonitoring data={data} onReload={onReload} key={index} documentSetting={documentSetting} onChangeData={onChangeData} screenName={SCREEN_NAME.DELIVERED_CLIENT}/>;
                case 'detailInfo':
                  return <DetailListAllData data={data} screenName={SCREEN_NAME.DELIVERED_CLIENT} />;
                case 'document':
                  return <DetailTreeDocumentSetting orderId={orderId} order={data} />;
                case 'history':
                  return (
                    <DetailDataList
                      role={role}
                      orderId={orderId}
                      datePick={false}
                      screenName={SCREEN_NAME.DELIVERED_CLIENT}
                    />
                  );
                default:
                  return '';
              }
            }
          })}
        </div>
        {/*<Footer color={color} />*/}
        <Footer color={colorScreen?.code} colorText={colorScreen?.colorText} />
      </div>
    </div>
  );
};

export default DetailDeliveryClient;
