import React from 'react';
import './style.scss';

const data = {
  // header: ['', '現地調査', '音声現地調査', '宅内工事', '開通試験', '現場調査', '音声宅内工事', 'a'],
  header: ['', '現地調査', '音声現地調査', '宅内工事', '開通試験', '現場調査', '音声宅内工事', 'a'],
  body: [
    // { '': '', 現地調査: '', 音声現地調査: '', 宅内工事: '', 開通試験: '', 現場調査: '', 音声宅内工事: '', a: '' },
    {
      '': '稼働調整前',
      現地調査: '1',
      音声現地調査: '2',
      宅内工事: '3',
      開通試験: '4',
      現場調査: '5',
      音声宅内工事: '6',
      a: '7'
    },
    {
      '': '稼働調整中',
      現地調査: '1',
      音声現地調査: '2',
      宅内工事: '3',
      開通試験: '4',
      現場調査: '5',
      音声宅内工事: '6',
      a: '7'
    },
    {
      '': '稼働待ち（アポ中）',
      現地調査: '1',
      音声現地調査: '2',
      宅内工事: '3',
      開通試験: '4',
      現場調査: '5',
      音声宅内工事: '6',
      a: '7'
    },
    {
      '': '稼働待ち（稼働待ち)',
      現地調査: '1',
      音声現地調査: '2',
      宅内工事: '3',
      開通試験: '4',
      現場調査: '5',
      音声宅内工事: '6',
      a: '7'
    },
    {
      '': '本日稼働',
      現地調査: '1',
      音声現地調査: '2',
      宅内工事: '3',
      開通試験: '4',
      現場調査: '5',
      音声宅内工事: '6',
      a: '7'
    },
    {
      '': '報告書未回収',
      現地調査: '1',
      音声現地調査: '2',
      宅内工事: '3',
      開通試験: '4',
      現場調査: '5',
      音声宅内工事: '6',
      a: '7'
    },
    {
      '': '報告書未納品',
      現地調査: '1',
      音声現地調査: '2',
      宅内工事: '3',
      開通試験: '4',
      現場調査: '5',
      音声宅内工事: '6',
      a: '7'
    },
    {
      '': '報告書納品済',
      現地調査: '1',
      音声現地調査: '2',
      宅内工事: '3',
      開通試験: '4',
      現場調査: '5',
      音声宅内工事: '6',
      a: '7'
    }
  ]
};

const renderItem = (item, index) => {
  return (
    <tr key={index}>
      {data.header.map((element, key) => {
        return (
          <>
            <td className="text-center" style={{fontSize:11}} key={key}>{item[element]}</td>
          </>
        );
      })}
    </tr>
  );
};
const TableMonitor = () => {
  return (
    <div className="wrapper-monitor">
      <div className="list">
        <table className={'monitor'}>
          <thead>
            <tr>
              {data.header.map((item, index) => (
                <th style={{fontSize:11}} className="green text-center" scope="col" key={index}>
                  {item}
                </th>
              ))}
            </tr>
            <tr>
              <th className="green"></th>
              <th className="green"></th>
              <th className="green"></th>
              <th className="green"></th>
              <th className="green"></th>
              <th className="green"></th>
              <th className="green"></th>
              <th className="green"></th>
            </tr>
          </thead>
          <tbody>{data.body.map((listData, index) => renderItem(listData, index))}</tbody>
        </table>
      </div>
    </div>
  );
};

export default TableMonitor;
