import React from 'react';
import { HEADER_MENU, SCREEN_NAME, DATE_JP_FORMAT } from 'helpers/constants';
import { convertFileToBase64 , formatInputSelectDate } from 'helpers/helpers';
import { callApi } from 'helpers/callApi';
import { ORDER, ORDER_PARTNER } from 'constants/endpoints';
import { useEffect } from 'react';
import Header from 'components/Header';
import Footer from 'components/Footer';
import 'assets/scss/page/DetailReceiveConfirm/style.scss';
import { useState } from 'react';
import { pushToast } from 'components/Toast';
import DetailData from 'components/DetailCenter/DetailData';
import DetailDataList from 'components/DetailCenter/DetailDataList';
import DetailOrder from 'components/DetailCenter/DetailOrder';
import DetailListAllData from 'components/DetailCenter/DetailListAllData';
import { useHistory, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import DetailTreeDocumentSetting from 'components/DetailTreeDocumentSetting';
import DetailVisitDate from 'components/DetailOrderErp/DetailVisitDate';
import MattersToBeSent from 'components/MattersToBeSent';
import _ from 'lodash';
import moment from 'moment';
import DetailTimeWorker from 'components/DetailOrderErp/DetailTimeWorker';
import DetailReportErp from '../../../components/DetailOrderErp/DetailReportErp';
import { Fragment } from 'react';
import { getDataHistory } from 'actions/authentication';
import { useDispatch } from 'react-redux';

const mockData = {
  screenName: SCREEN_NAME.RECEIVE_CONFIRM_LIST,
  scheduledWorkerInformation: '',
};

const DetailReceiveConfirm = () => {
  const dispatch = useDispatch();
  let name = (process.browser && new URL(window.location.href).searchParams.get('p')) || 'receive_all';
  let orderId = process.browser && new URL(window.location.href).searchParams.get('orderId');
  let organizationId = process.browser && new URL(window.location.href).searchParams.get('organizationId') || '';
  let partnerId = process.browser && new URL(window.location.href).searchParams.get('partnerId');
  // let partnerId = 'P0003'
  const [p, setP] = React.useState(name || HEADER_MENU.RECEIVE_ALL);
  const [idRole, setIdRole] = React.useState(null);
  const [data, setData] = React.useState('');
  const [dataOrder, setDataOrder] = useState(mockData);
  const [listDetailScreen, setListDetailScreen] = useState([]);
  const colorScreen = useSelector((store) => store.utils.colorMe);
  const [isOpenDownLoadFileGroup, setIsOpenDownLoadFileGroup] = useState(true);
  const [receiveFile, setReceiveFile] = useState(null);
  const [dataDetailTimeWorker, setDataDetailTimeWorker] = useState({});
  const [documentSetting, setDocumentSetting] = useState([]);

  const history = useHistory();

  useEffect(() => {
    getListRoleChild();
  }, []);

  const getListRoleChild = async () => {
    const newRole = localStorage.getItem('role');
    try {
      const data = await ORDER.GET_LIST_DETAIL_SETTING({
        arrRole: newRole === '7' ? '10' : newRole,
        nameScreen: newRole === '7' ? 'orderGroup' : 'receive_all',
        orderId,
        organizationId,
      });
      if (data) {
        setListDetailScreen(data?.data?.detailScreen);
        const newData = _.groupBy(data ?.data ?.documentSettingReceiveAll, 'typeChildId')
        setDocumentSetting(newData);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (data) {
      const preferredDate = { ...data?.preferredDate };
      Object.keys(preferredDate).forEach((key) => {
        if (preferredDate[key]?.visitDateTo?.length === 5) {
          preferredDate[key].visitDateTo = moment(
            `${preferredDate[key].visitDate} ${preferredDate[key].visitDateTo}`
          ).format('YYYY/MM/DD HH:mm');
        }
      });
      data.preferredDate = preferredDate;

      setDataOrder({
        ...dataOrder,
        scheduledWorkerInformation: data?.scheduledWorkerInformation,
        visitDate: data?.visitDate,
        visitDateFrom: data?.visitDateFrom,
        visitDateTo: data?.visitDateTo ? (moment(`${formatInputSelectDate(data?.visitDateTo)}`, `${DATE_JP_FORMAT}`).isValid()
          ? data?.visitDateTo
          : `${data?.visitDate ? `${data?.visitDate} ${data?.visitDateTo}` : ''}`) : '',
        preferredDate: data.preferredDate || {},
        quoteFile: data?.dataPartner?.quoteFile,
        estimate: data?.dataPartner?.estimate,
        quoteMemo: data?.dataPartner?.quoteMemo,
        managementMattersToBeSent: data?.dataPartner?.managementMattersToBeSent,
        erpMattersToBeSent: data?.erpMattersToBeSent,
        detailSetting: data?.detailSetting,
        workerResponseRequired: data?.workerResponseRequired
      });
    }
  }, [data]);

  useEffect(() => {
    setP(name);
  }, [name]);

  const onChangeText = (e) => {
    let name = e.target.name;
    setDataOrder({ ...dataOrder, [name]: e.target.value });
  };

  const checkDisable = () => {
    if(+idRole === 7 && (!data?.partnerConfirm?.[data?.partnerLogin])) return true
  }

  const getOrderDetail = async ({ orderId = '' }) => {
    try {
      const data = await ORDER.GET_DETAIL_CANCEL_INFO({
        orderId,
        organizationId: organizationId,
        screenName: 'receive_all',
      });
      if (data) {
        const loginId = localStorage.getItem('role') === '7' ? partnerId : localStorage.getItem('permissionId');
        setDataDetailTimeWorker(data?.data);
        let dataPartner = data?.data?.listConfirmStaff?.find((elm) => elm.partnerId === loginId) || {};
        dataPartner = {
          ...dataPartner,
          estimate: dataOrder?.estimate,
          quoteMemo: dataOrder?.quoteMemo,
        };
        const newDataOrder = { ...dataOrder, ...data?.data, dataPartner };
        setData(newDataOrder);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getDataOrderForDetailTimeWorker = async () => {
    try {
      const data = await ORDER.GET_DETAIL_CANCEL_INFO({ orderId, organizationId });
      if (data) {
        setDataDetailTimeWorker(data?.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    const newRole = localStorage.getItem('role');
    setIdRole(newRole);
    if (orderId) {
      getOrderDetail({
        orderId: orderId,
      });
    }
  }, [orderId]);

  const updateOrderPartner = async ({ body = {}, id_order = '' }) => {
    body = _.pick(body, ['screenName', 'statusConfirmPartner', 'estimate', 'quoteMemo', 'managementMattersToBeSent',
      'preferredDate', 'visitDate', 'visitDateFrom', 'visitDateTo', 'workerResponseRequired','companyName', 'updateTitle'
    ]);

    try {
      const data = await ORDER_PARTNER.UPDATE_ORDER_PARTNER({
        body: body,
        id_order: id_order,
        organizationId: organizationId || '',
        permissionIdGroup : localStorage.getItem('role') === '7' ? partnerId : '',
      });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
      } else {
        pushToast('success', 'Update order success');
        getOrderDetail({
          orderId: orderId,
        });
        history.push( localStorage.getItem('role') === '7' ? '/orderGroup' : `/list?p=receive_all`);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onUpdateOrder = (text) => {
    let newData = { ...dataOrder };
    newData = { ...newData, statusConfirmPartner: true, companyName: localStorage.getItem('companyName'), updateTitle : text };
    updateOrderPartner({ body: _.omit(newData, 'detailSetting'), id_order: orderId });
  };

  const UploadRunningNotAllowed = (text) => {
    let newData = { ...dataOrder };
    newData = { ...newData, statusConfirmPartner: false ,companyName: localStorage.getItem('companyName'), updateTitle : text};
    updateOrderPartner({ body: _.omit(newData, 'detailSetting'), id_order: orderId });
  };

  const uploadReceiveFile = async (nameApplication , updateTitle , numberOfMaterials) => {
    try {
      const res = await callApi(
        {
          content: receiveFile ? await convertFileToBase64(receiveFile) : '',
          screenName: SCREEN_NAME.RECEIVE_CONFIRM_LIST,
          fileName: receiveFile ? receiveFile.name : '',
          nameApplication,
          updateTitle,
          companyName : localStorage.getItem('companyName'),
          numberOfMaterials,
        },
        {
          endpoint: `order-manager/order-partner/upload-file-receive-all/${orderId}?organizationId=${organizationId}`,
          method: 'POST',
        }
      );

      if (res && res.code !== 0) {
        if (res && res.message && res.message.extra) {
          pushToast('error', res?.message?.extra);
        } else if (res.message.errorMessage) {
          pushToast('error', res?.message?.errorMessage);
        } else {
          pushToast('error', res?.message);
        }
      } else {
        const dataPartner = {
          ...data?.dataPartner,
          quoteFile: {
            ...res?.data,
          },
          quoteMemo: dataOrder?.quoteMemo,
          estimate: dataOrder?.estimate,
        };
        let newData = { ...dataOrder, ...data, dataPartner, quoteFile: res?.data };
        setData(newData);
        dispatch(getDataHistory({ orderId, screenName: SCREEN_NAME.RECEIVE_CONFIRM_LIST, organizationId }));
        pushToast('success', 'Upload success');
      }
    } catch (errorMessage) {
      console.log('errorMessage: ', errorMessage);
    }
  };

  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    let newData = { ...dataOrder };
    newData = { ...newData, [name]: e.target.value };
    if (value === 'true') {
      newData = { ...newData, [name]: true };
    } else if (value === 'false') newData = { ...newData, [name]: false };
    setDataOrder(newData);
  };

  return (
    <div className="wrapper-detail-receive-confirm">
      <div className="main">
       <Header
          idRole={idRole}
          color={colorScreen?.code}
          colorText={colorScreen?.colorText}
          p={localStorage.getItem('role') === '7' ? 'orderGroup' : p}
        />
        <div className="body-wrapper">
          <div className="logout_btn">
            <Link to={localStorage.getItem('role') === '7'?'orderGroup':`list?p=receive_all`}>
              <span className="mypage_ab_custom">戻る</span>
            </Link>
          </div>
          {listDetailScreen &&
            listDetailScreen.map((item, index) => {
              if (item?.status) {
                switch (item.name) {
                  case 'clientCompany':
                    return <DetailOrder p={p} data={data} />;
                  case 'applicationCompany':
                    return <DetailData p={p} data={data} link={false} />;
                  case 'visitDate':
                    return (
                      <Fragment key={index}>
                        <DetailVisitDate
                          disabled = {true}
                          dataVisitDate={_.pick(dataOrder, [
                            'visitDate',
                            'visitDateFrom',
                            'visitDateTo',
                            'preferredDate',
                          ])}
                          onChangeVisitDate={(dataVisitDate) => {
                            setDataOrder({ ...dataOrder, ...dataVisitDate });
                          }}
                        />
                        <div className="detail-receive-confirm-wrapper">
                          <div className="title-absolute">作業員返答区分</div>
                          <div className="work-workerResponseRequired-group">
                            <input
                              type="radio"
                              value={true}
                              name="workerResponseRequired"
                              id="workerResponseRequired_true"
                              checked={dataOrder?.workerResponseRequired === true}
                            />
                            <label htmlFor="workerResponseRequired_true">必須</label>
                            <input
                              type="radio"
                              value={false}
                              name="workerResponseRequired"
                              id="workerResponseRequired_false"
                              checked={dataOrder?.workerResponseRequired === false}
                            />
                            <label htmlFor="workerResponseRequired_false">不要</label>
                          </div>
                        </div>
                      </Fragment>
                    );
                  case 'visitDateWorker':
                    return (
                      <DetailTimeWorker
                        screenName={SCREEN_NAME.RECEIVE_CONFIRM_LIST}
                        data={dataDetailTimeWorker}
                        getOrderDetail={getDataOrderForDetailTimeWorker}
                        staffId={data?.staffId}
                        screenNameLogic={'receive_confirm'}
                      />
                    );
                  case 'mattersSent':
                    return (
                      <MattersToBeSent
                        onChange={onChangeText}
                        dataOrder={dataOrder}
                        idRole={idRole}
                        screenName={SCREEN_NAME.RECEIVE_CONFIRM_LIST}
                      />
                    );

                  case 'fileDownload':
                    return (
                      Object.keys(documentSetting).map((item, i) => {
                        const nameForm = documentSetting?.[item]?.[0]?.nameTypeChild;
                        return (
                          <DetailReportErp
                            p={p}
                            data={data} organizationId={organizationId}
                            orderId={orderId} partnerId={partnerId}
                            key={i} documentSetting={documentSetting?.[item]}
                            nameForm={nameForm}
                            type={'reportInformation'} />
                        )
                      })
                      /* <div className="detail-receive-confirm-wrapper">
                        <div
                          className="title-absolute"
                          onClick={() => setIsOpenDownLoadFileGroup(!isOpenDownLoadFileGroup)}
                        >
                          ファイルダウンロード
                          {isOpenDownLoadFileGroup ? (
                            <i className="fas fa-arrow-circle-up ml-2"></i>
                          ) : (
                            <i className="fas fa-arrow-circle-down ml-2"></i>
                          )}
                        </div>
                        <Collapse isOpen={isOpenDownLoadFileGroup}>
                          <div className="download-file-group">
                            <div className="label">添付資料</div>
                            {data?.preparationFile?.[0] && (
                              <>
                                <div> {data?.preparationFile?.[0]?.name} </div>
                                <button
                                  className="btn__receive-confirm"
                                  onClick={() => window.open(`${data?.preparationFile?.[0]?.url}`)}
                                >
                                  ダウンロード
                                </button>
                              </>
                            )}
                          </div>
                        </Collapse>
                      </div> */
                    );
                  case 'workEstimate':
                    return (
                      <Fragment key={index}>
                        <div className="detail-receive-confirm-wrapper">
                          <div className="title-absolute">作業見積</div>
                          <div className="work-estimate-group">
                            <div className="work-estimate__item">
                              <div className="label">見積金額</div>
                              <input
                                name="estimate"
                                onChange={onChangeText}
                                value={dataOrder?.estimate || ''}
                                type="text"
                                className="input-receive-confirm"
                              />
                              <div className="label">見積メモ</div>
                              <input
                                type="text"
                                name="quoteMemo"
                                value={dataOrder?.quoteMemo || ''}
                                onChange={onChangeText}
                                className="input-receive-confirm quoteMemo-input"
                              />
                            </div>
                            <div className="work-estimate__item">
                              <div className="label">見積ファイル</div>
                              <label htmlFor="input-select-file" className="btn__receive-confirm cursor-pointer">
                                ファイルを選択
                              </label>
                              <input
                                type="file"
                                id="input-select-file"
                                style={{ display: 'none' }}
                                onChange={(e) => {
                                  if (e.target.files[0]) setReceiveFile(e.target.files[0]);
                                }}
                              />
                              <input
                                className="input-receive-confirm"
                                type="text"
                                value={receiveFile?.name}
                                disabled="disabled"
                              />
                              <button className="btn__receive-confirm" onClick={() => uploadReceiveFile('作業見積','アップロード','見積ファイル')}>
                                アップロード
                              </button>
                              <div
                                className="cursor-pointer"
                                onClick={() => window.open(`${dataOrder?.quoteFile?.url}`)}
                              >
                                {dataOrder?.quoteFile?.name}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="action-group pb-2">
                            <button disabled={checkDisable()} className={`${checkDisable() ? 'disable__receive-confirm' : '' } btn__receive-confirm`} onClick={() => onUpdateOrder('稼働可')}>
                              稼働可
                            </button>
                          <button className="btn__receive-confirm" onClick={() => UploadRunningNotAllowed('稼働不可')}>
                            稼働不可
                          </button>
                        </div>
                      </Fragment>
                    );

                  case 'detailInfo':
                    return <DetailListAllData data={data} screenName={SCREEN_NAME.RECEIVE_CONFIRM_LIST} />;
                  // case 'documentSetting':
                  //   return <DetailTreeDocumentSetting orderId={orderId}/>;
                  case 'document':
                    return (
                      <DetailTreeDocumentSetting orderId={orderId} type={'reportInformation'} order={data} />
                    );
                  case 'history':
                    return (
                      <DetailDataList
                        datePick={false}
                        screenName={SCREEN_NAME.RECEIVE_CONFIRM_LIST}
                        orderId={orderId}
                        organizationId={organizationId}
                      />
                    );

                  default:
                    return '';
                }
              }
            })}
        </div>
        <Footer color={colorScreen?.code} colorText={colorScreen?.colorText} />
      </div>
    </div>
  );
};

export default DetailReceiveConfirm;
