import React, { useEffect, useState } from 'react';
import 'assets/scss/page/AppointInfoDetail/FormAppoint.scss';
import { SCREEN_NAME, DATE_JP_FORMAT, ROLE } from '../../../../helpers/constants';
import DateTimePicker from '../../../../components/DateTimePickerThreeMonth';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';
import DetailUpdate from './DetailUpdate';
import { getRole , formatInputSelectDate , formatInputSelectTime } from 'helpers/helpers';

const headerTable = [
  '架電先', '会社名',
  '氏名', '連絡先',
  'メール'
];

const mockData = {
  screenName: SCREEN_NAME.APPOINT_INFO,
  personInChargeAppoint: [],
}

const FormAppoint = ({ updateOrder = () => {}, name = '', orderId = '', onUpdate = () => {
  }, dataOrder = {}, masterSend = {}, onChangeTextMasterSend = () => {}
}) => {
  let today = new Date();
  let stringToday = moment(today).format(DATE_JP_FORMAT);
  const newRole = localStorage.getItem('role');
  const nameRole = getRole({ idRole: newRole });
  const [data, setData] = useState(mockData);
  const [visitDate, setVisitDate] = useState(stringToday);
  const [nameStaff, setNameStaff] = useState('');
  const [nameState, setNameState] = useState('');
  const [dateTo, setDateTo] = useState('')
  const history = useHistory();

  const changeDate = (date) => {
    setVisitDate(moment(new Date(date)).format(DATE_JP_FORMAT));
  };

  useEffect(() => {
    setNameState(name);
  }, [name]);

  useEffect(() => {
    if (dataOrder) {
      setData({
        ...data,
        messageForPartnerCommunication: dataOrder?.messageForPartnerCommunication,
        callStatus: dataOrder?.callStatus,
        newCallDate: dataOrder?.newCallDate,

        visitDate: dataOrder?.visitDate,
        visitDateFrom: dataOrder?.visitDateFrom || "00:00",
        visitDateTo: dataOrder?.visitDateTo,
        visitDateTemp: dataOrder?.visitDateTemp,
        visitDateFromTemp: dataOrder?.visitDateFromTemp || "00:00",
        visitDateToTemp: dataOrder?.visitDateToTemp,
        contactPersonInChargeStatus: dataOrder?.contactPersonInChargeStatus,
        personInChargeOfApplicationStatus: dataOrder?.personInChargeOfApplicationStatus,
        personInChargeAppoint: dataOrder?.personInChargeAppoint || [],
        statusAppointInfo: false,
        applicantCompanyName: dataOrder?.applicantCompanyName,
        personInChargeOfApplication: dataOrder?.personInChargeOfApplication,
        contactPersonForApplication: dataOrder?.contactPersonForApplication,
        applicantEmailAddress: dataOrder?.applicantEmailAddress,
        installationSiteName: dataOrder?.installationSiteName,
        contactPersonInCharge: dataOrder?.contactPersonInCharge,
        contactInformation: dataOrder?.contactInformation,
        visitEmailAddress: dataOrder?.visitEmailAddress,
        confirm1: dataOrder?.confirm1,
        confirm2: dataOrder?.confirm2,
        confirm3: dataOrder?.confirm3,
        confirm4: dataOrder?.confirm4,
        newCallDateTo: dataOrder?.newCallDateTo || "00:00",
        newCallDateFrom: dataOrder?.newCallDateFrom || "00:00",
        preferredDate: dataOrder?.preferredDate,
        visitedCompanyName: dataOrder?.visitedCompanyName,
        witnessCompany: dataOrder?.witnessCompany,
        witnessContact: dataOrder?.witnessContact,
        witnessEmail: dataOrder?.witnessEmail,
        witnessName: dataOrder?.witnessName,
      });
      setDateTo(moment(`${formatInputSelectDate(dataOrder?.visitDateTo)}`, `${DATE_JP_FORMAT}`).isValid() ? dataOrder?.visitDateTo?.slice(-16 , -6) || '' : '')
    }
  }, [dataOrder]);

  const onChangeDate = (date) => {
    if (data?.callStatus === '対応中') {
      let newData = { ...data, newCallDate: date ? moment(new Date(date)).format(DATE_JP_FORMAT) : '' };
      delete newData['visitDate'];
      delete newData['visitDateFrom'];
      delete newData['visitDateTo'];
      delete newData['visitDateTemp'];
      delete newData['visitDateFromTemp'];
      delete newData['visitDateToTemp'];
      updateOrder(newData);
    } else if (data?.callStatus === '完了') {
      let newData = { ...data, visitDate: date ? moment(new Date(date)).format(DATE_JP_FORMAT) : '', visitDateTemp: date ? moment(new Date(date)).format(DATE_JP_FORMAT) : '' };
      newData = {...newData, visitDateTo: `${moment(new Date(date)).format(DATE_JP_FORMAT)} ${formatInputSelectTime(data?.visitDateTo) || '00:00'}`, visitDateToTemp: `${moment(new Date(date)).format(DATE_JP_FORMAT)} ${formatInputSelectTime(data?.visitDateTo) || '00:00'}`};
      delete newData['newCallDate'];
      delete newData['newCallDateFrom'];
      delete newData['newCallDateTo'];
      updateOrder(newData);
    }
  };

  const onChangeVisitDateTo = (date) => {
    setDateTo(date ? moment(new Date(date)).format(DATE_JP_FORMAT) : '');
    updateOrder({
      ...data,
      visitDateTo: date
        ? `${moment(new Date(date)).format(DATE_JP_FORMAT)} ${formatInputSelectTime(data?.visitDateTo) || '00:00'}`
        : '',
      visitDateToTemp: date
        ? `${moment(new Date(date)).format(DATE_JP_FORMAT)} ${formatInputSelectTime(data?.visitDateTo) || '00:00'}`
        : '',
    });
  };

  useEffect(() => {
    if (data?.callStatus?.length > 0) {
      if (data?.callStatus === '対応中') {
        let newData = { ...data };
        delete newData['visitDate'];
        delete newData['visitDateFrom'];
        delete newData['visitDateTo'];
        delete newData['visitDateTemp'];
        delete newData['visitDateFromTemp'];
        delete newData['visitDateToTemp'];
        updateOrder(newData);
      } else if (data?.callStatus === '完了') {
        let newData = { ...data };
        delete newData['newCallDate'];
        delete newData['newCallDateFrom'];
        delete newData['newCallDateTo'];
        updateOrder(newData);
      } else if (data?.callStatus !== '対応中' && data?.callStatus !== '完了') {
        let newData = { ...data };
        delete newData['visitDate'];
        delete newData['visitDateFrom'];
        delete newData['visitDateTo'];
        delete newData['visitDateTemp'];
        delete newData['visitDateToTemp'];
        delete newData['visitDateFromTemp'];
        delete newData['newCallDate'];
        delete newData['newCallDateFrom'];
        delete newData['newCallDateTo'];
        updateOrder(newData);
      }
    }
  }, [data?.callStatus]);

  const onChangeText = (e) => {
    let name = e.target.name;
    updateOrder({ ...data, [name]: e.target.value });
  };

  const onChangeVisitDate = ({key, keyTemp, e}) => {
    if(key === 'visitDateTo' || key === 'visitDateToTemp') {
      updateOrder({ ...data, [key]: !_.isEmpty(dateTo) ? `${dateTo} ${e.target.value}` : '', [keyTemp]: !_.isEmpty(dateTo) ? `${dateTo} ${e.target.value}` : '' });
    } else {
      updateOrder({ ...data, [key]: e.target.value, [keyTemp]: e.target.value });
    }
  };

  const handleCheckItem = (e) => {
    let name = e.target.name;
    const item = { name };
    updateOrder({ ...data, personInChargeAppoint: [item] });
  }

  return (
    <>
    {
      (nameState === 'appointmentInfo' || (nameState === 'appointInfo' && nameRole === 'PARTNER')) && (
        <div className='form-appoint-wrapper'>
          <div className="title-absolute">アポイント情報</div>
          <div className="form-content-appoint">
            <div className="content form-table-calendar">
              <table className="detail__form">
                <thead>
                  <tr>
                    {
                      headerTable.map((elm, i) =>
                        <th key={i}>{elm}</th>
                      )
                    }
                  </tr>
                </thead>

                <tbody>
                  {/* 申込会社 */}
                  <tr>
                    <td className="detail__form--group-item flex-fill">
                      <input
                        type="radio"
                        className="detail__form--radio"
                        name='申込会社'
                        onChange={handleCheckItem}
                        checked={ data?.personInChargeAppoint[0]?.name === '申込会社' }/>
                      <div className="item">申込会社</div>
                    </td>

                    <td className="detail__form--item">
                      <input type="text" name='applicantCompanyName' className='input'
                        onChange={onChangeText}
                        disabled
                        value={data?.applicantCompanyName} />
                    </td>

                    <td className="detail__form--item">
                      <input type="text" name='personInChargeOfApplication' className='input'
                        onChange={onChangeText}
                        disabled
                        value={data?.personInChargeOfApplication} />
                    </td>

                    <td className="detail__form--item">
                      <input type="text" name='contactPersonForApplication' className='input'
                        onChange={onChangeText}
                        disabled
                        value={data?.contactPersonForApplication} />
                    </td>

                    <td className="detail__form--item">
                      <input type="text" name='applicantEmailAddress' className='input'
                        onChange={onChangeText}
                        disabled
                        value={data?.applicantEmailAddress} />
                    </td>
                  </tr>

                  {/* 訪問先 */}
                  <tr>
                    <td className="detail__form--group-item flex-fill">
                      <input
                        type="radio"
                        className="detail__form--radio"
                        name='訪問先'
                        onChange={handleCheckItem}
                        checked={ data?.personInChargeAppoint[0]?.name === '訪問先' }
                        />
                      <div className="item">訪問先</div>
                    </td>

                    <td className="detail__form--item">
                      <input type="text" name='visitedCompanyName' className='input'
                        onChange={onChangeText}
                        disabled
                        value={data?.visitedCompanyName} />
                    </td>

                    <td className="detail__form--item">
                      <input type="text" name='contactPersonInCharge' className='input'
                        onChange={onChangeText}
                        disabled
                        value={data?.contactPersonInCharge} />
                    </td>

                    <td className="detail__form--item">
                      <input type="text" name='contactInformation' className='input'
                        onChange={onChangeText}
                        disabled
                        value={data?.contactInformation} />
                    </td>

                    <td className="detail__form--item">
                      <input type="text" name='visitEmailAddress' className='input'
                        onChange={onChangeText}
                        disabled
                        value={data?.visitEmailAddress} />
                    </td>

                  </tr>

                  {/* 立会者 */}
                  <tr>
                    <td className="detail__form--group-item flex-fill">
                      <input
                        type="radio"
                        className="detail__form--radio"
                        name='立会者'
                        onChange={handleCheckItem}
                        checked={ data?.personInChargeAppoint[0]?.name === '立会者' }/>
                      <div className="item">立会者</div>
                    </td>

                    <td className="detail__form--item">
                      <input type="text" name='witnessCompany' className='input'
                        onChange={onChangeText}
                        disabled
                        value={data?.witnessCompany} />
                    </td>

                    <td className="detail__form--item">
                      <input type="text" name='witnessName' className='input'
                        onChange={onChangeText}
                        disabled
                        value={data?.witnessName} />
                    </td>

                    <td className="detail__form--item">
                      <input type="text" name='witnessContact' className='input'
                        onChange={onChangeText}
                        disabled
                        value={data?.witnessContact} />
                    </td>

                    <td className="detail__form--item">
                      <input type="text" name='witnessEmail' className='input'
                        onChange={onChangeText}
                        disabled
                        value={data?.witnessEmail} />
                    </td>
                  </tr>
                </tbody>
              </table>

            </div>
            <div className="content">
              <DateTimePicker
                onChangeDate={(date) => changeDate(date)}
                selected={new Date(data?.visitDate)}
                locale="ja"
                month={3}
                dateFormat="yyyy/MM/dd"
                dateFormatCalendar="yyyy年 MM月"/>
            </div>
          </div>
          <DetailUpdate
            data={data}
            visitDate={visitDate}
            handleChangeValue={onChangeText}
            handleChangeVisitDate={onChangeVisitDate}
            onChangeDate={onChangeDate}
            nameStaff={nameStaff}
            nameRole={nameRole}
            masterSend={masterSend}
            onChangeCallLog={onChangeTextMasterSend}
            onChangeVisitDateTo={onChangeVisitDateTo}
            dateTo={dateTo}
          />
        </div>
      )
    }
    </>
  );
};

export default FormAppoint;
