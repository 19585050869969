import React, { useEffect, useState } from 'react';
import './style.scss';
import { getNoOrder } from '../../../../helpers/helpers';
import PaginationCustom from '../../../../components/PaginationCustom';
import _ from 'lodash';

const renderItem = (item, index, fontSize, activePagess) => {
  item = csvToArray(item);
  return (
    <tr key={index}>
      <td className="no-wrapper-client">
        <div className={`d-flex justify-content-center align-items-center font-weight-bolder no-style ${fontSize}`}>
          {getNoOrder({ index, limit: 10, activePage: activePagess })}
        </div>
      </td>
      {
        !_.isEmpty(item) && item.map((e, index) => {
          return <td key={index}>{e ? e.trim() : null}</td>
        })
      }
    </tr>
  );
};

const csvToArray = (text) => {
  const re_valid = /^\s*(?:'[^'\\]*(?:\\[\S\s][^'\\]*)*'|"[^"\\]*(?:\\[\S\s][^"\\]*)*"|[^,'"\s\\]*(?:\s+[^,'"\s\\]+)*)\s*(?:,\s*(?:'[^'\\]*(?:\\[\S\s][^'\\]*)*'|"[^"\\]*(?:\\[\S\s][^"\\]*)*"|[^,'"\s\\]*(?:\s+[^,'"\s\\]+)*)\s*)*$/;
  const re_value = /(?!\s*$)\s*(?:'([^'\\]*(?:\\[\S\s][^'\\]*)*)'|"([^"\\]*(?:\\[\S\s][^"\\]*)*)"|([^,'"\s\\]*(?:\s+[^,'"\s\\]+)*))\s*(?:,|$)/g;
  if (!re_valid.test(text)) return null;
  let a = [];
  text.replace(re_value,
      function(m0, m1, m2, m3) {
          if (m1 !== undefined) a.push(m1.replace(/\\'/g, "'"));
          else if (m2 !== undefined) a.push(m2.replace(/\\"/g, '"'));
          else if (m3 !== undefined) a.push(m3);
          return '';
      });
  if (/,\s*$/.test(text)) a.push('');
  return a;
}

const TableImportCSV = ({ showCsv, showCsvShift, fontSize }) => {
  showCsvShift && showCsvShift.map((e, index) => {
    if (e === '') {
      showCsvShift = showCsvShift.splice(index, 1);
    }
  })
  const [activePagess, setActivePagess] = useState(1);
  const [dataCsv, setDataCsv] = useState(showCsvShift);
  const handlePageChangess = (pageNumber) => {
    setActivePagess(pageNumber);
  };

  useEffect(() => {
    getListCsv(showCsvShift, 10, activePagess);
  }, [activePagess, showCsvShift]);

  const getListCsv = (array, page_size = 10, page_number) => {
    const returnData = array.slice((page_number - 1) * page_size, page_number * page_size);
    if (returnData) setDataCsv(returnData);
  }

  return (
    <div id="Result-client">
      <div className="text-banner">
        <h3 className={`${fontSize} font-weight-bolder`}>検索結果：{showCsvShift?.length}件</h3>
      </div>
      <div className="scrollleft">
      <table className={`listTbl ${fontSize}`}>
        <thead>
          <tr>
            { showCsv ? <th scope="col">No .</th> : null }
            {
              showCsv && showCsv?.split(',').map((e, index) => {
                e = e.split('"').join('');
                return <th scope="col" key={index}>{e ? e : null}</th>
              })
            }
          </tr>
        </thead>
        <tbody>
          {dataCsv?.length > 0 &&
            dataCsv.map((listData, index) => renderItem(listData, index, fontSize, activePagess))}
        </tbody>
      </table>
      </div>
        {!_.isEmpty(showCsvShift) && showCsvShift?.length > 10 && (
          <PaginationCustom
            activePage={activePagess}
            itemsCountPerPage={10}
            totalItemsCount={showCsvShift?.length}
            pageRangeDisplayed={5}
            handlePageChange={handlePageChangess}
          />
        )}
    </div >

  );

};

export default TableImportCSV;
