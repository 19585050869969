import React, { useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import 'assets/scss/page/AnkenMasterNew/ModalCreateService.scss';
import TableModalUsers from './TableModalUsers';

const ModalAccessUsers = (props) => {
  const { toggle = () => { }, modal = false, productAccess = null , nameTitle } = props;
  const [statusGetList, setStatusGetList] = useState(false);

  return (
    <div>
      <Modal className="modal-dialog-custom" isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>参照権限設定</ModalHeader>
        <ModalBody>
          <div className="form-body-service">
            <TableModalUsers
              statusGetList={statusGetList}
              setStatusGetList={() =>
                setStatusGetList(false)
              }
              productAccess={productAccess}
              nameTitle={nameTitle}
            />
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ModalAccessUsers;
