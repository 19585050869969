import React, { useEffect, useState } from 'react';
import FooterMaintenance from 'components/FooterMaintenance';
import Header from 'components/Header';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { pushToast } from 'components/Toast';
import { DISPATCH } from 'constants/endpoints';
import Loading from 'components/Loading/Loading';
import { withRouter } from 'react-router-dom';
import { DATE_JP_FORMAT, DATETIME_EXPORT_JP_FORMAT, NAME_FILE_EXPORT_DISPATCH } from 'helpers/constants';
import { CSVLink } from 'react-csv';
import Search from './Search';
import _ from 'lodash';
import { getDataHistoryMasterSetting } from 'actions/authentication';
import { useDispatch } from 'react-redux';

import 'assets/scss/page/DispatchAll/style.scss';
import { useSelector } from 'react-redux';
import CollapseDispatch from './CollapseDispatch';
import { ORDER } from '../../../constants/endpoints';
import MemoHistory from '../../../components/MemoHistory';
import { SCREEN_NAME } from '../../../helpers/constants';

const dataSearchMock = {
  workerName: '',
  partnerName: '',
  workerContact: '',
  workerId: '',
  freeKey: '',
};

const DispatchAll = ({ history }) => {
  const dispatch  = useDispatch();
  let today = new Date();
  let stringToday = moment(today).format(DATE_JP_FORMAT);
  const organizationId = localStorage.getItem('idProvide');
  const colorScreen = useSelector((store) => store.utils.colorMe);
  const dateBack = history?.location?.state?.visitDate;

  const [idRole, setIdRole] = useState('');
  const [dateSelect, setDateSelect] = useState('');
  const [dataWorkingSchedule, setDataWorkingSchedule] = useState([]);
  const [taskDescription, setTaskDescription] = useState({});
  const [taskActive, setTaskActive] = useState({});
  const [loading, setLoading] = useState(false);
  const [allService, setAllService] = useState([]);
  const [totalOrder, setTotalOrder] = useState(0);
  const [defaultCsv, setDefaultCsv] = useState([]);
  const [isShow, setIsShow] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [activePage] = useState(1);
  const [dataSearch, setDataSearch] = useState(dataSearchMock);
  const [serviceDrag, setServiceDrag] = useState({});

  const toggle = () => setIsOpen(!isOpen);

  useEffect(() => {
    setDateSelect(stringToday);
    const newRole = localStorage.getItem('role');
    setIdRole(newRole);
    if (dateBack) {
      setDateSelect(dateBack);
      let state = { ...history.location.state };
      delete state.visitDate;
      history.replace({ ...history.location, state });
    }
  }, []);

  useEffect(() => {
    if (dateSelect) {
      handleGetListWorkerSchedule({
        visitDate: dateSelect,
        organizationId,
        page: activePage,
        ...dataSearch,
      })
      handleGetAllService({ visitDate: dateSelect });
      dispatch(getDataHistoryMasterSetting({ logId: dateSelect, screenName: SCREEN_NAME.DISPATCH_SETTINGS_ORDER, idProvide: '' }));
    }
  }, [dateSelect]);

  const handleGetAllService = async ({ visitDate }) => {
    try {
      const data = await DISPATCH.GET_ALL_SERVICE({ visitDate });
      if (data) {
        setAllService(data?.data?.body);
        setTotalOrder(data?.data?.totalOrder)
      }
    } catch (errorMessage) {
      console.log('errorMessage: ', errorMessage);
    }
  };

  const handleGetListWorkerSchedule = async ({
    visitDate, organizationId,
    page, limit = 10,
    workerName = '', workerContact = '',
    workerId = '', partnerName = '', freeKey = '',
   }) => {
    setLoading(true);
    try {
      const data = await DISPATCH.GET_WORKING_SCHEDULE({
        visitDate, organizationId,
        page, limit,
        workerName, workerContact,
        workerId, partnerName, freeKey });
      if (data) {
        setDataWorkingSchedule(data?.data?.products);
        setLoading(false);
      }
    } catch (errorMessage) {
      console.log('errorMessage: ', errorMessage);
      setLoading(false);
    }
  };

  const changeDate = (date) => {
    setDateSelect(moment(new Date(date)).format(DATE_JP_FORMAT));

    //setLoading(false);
    //handleReload();
  };

  // const handleSetToday = () => {
  //   setDateSelect(stringToday)
  // };

  // const handleMoreDay = (value) => {
  //   setDateSelect(moment(dateSelect).add(value, 'day').format(DATE_JP_FORMAT))
  // };

  const handleReload = () => {
    handleGetListWorkerSchedule({
      visitDate: dateSelect,
      organizationId,
      page: activePage,
      ...dataSearch,
    });
    handleGetAllService({ visitDate: dateSelect })
  };

  const handleDispatchHandmade = ({ orderId }) => {
    // history.push(`/dispatch?orderId=${orderId}&visitDate=${visitDate}`);
    getDetalOrder({ orderId })
  };

  const getDetalOrder = async ({ orderId }) => {
    try {
      const data = await ORDER.GET_ORDER_DETAIL_APPOINT_INFO({ orderId });
      if (data) {
        pushDescription({ data });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleDispatchAll = () => {
    onDispatchAll({ visitDate: dateSelect })
  };

  const onDispatchAll = async ({ visitDate }) => {
    setLoading(true);
    try {
      const data = await DISPATCH.DISPATCH_ALL({ visitDate, screenName: SCREEN_NAME.DISPATCH_SETTINGS_ORDER, updateTitle: 'ディスパッチ' });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
          setLoading(false);
        } else {
          pushToast('error', data?.message?.errorMessage);
          setLoading(false);
        }
      } else {
        dispatch(getDataHistoryMasterSetting({ logId: visitDate, screenName: SCREEN_NAME.DISPATCH_SETTINGS_ORDER, idProvide: '' }));
        pushToast('success', 'Dispatch All success');
        setLoading(false);
        handleReload();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleOpenMap = () => {
    history.push(`/dispatch-map?visitDate=${dateSelect}`);

  };

  const pushDescription = ({ data, taskId, workTime, staffId }) => {
    setTaskDescription(data);
    setTaskActive({ taskId, workTime, staffId })
  };

  const handleExport = ({ e, type }) => {
    e.stopPropagation();
    handleExportCSV({ type, visitDate: dateSelect, screenName: SCREEN_NAME.DISPATCH_SETTINGS_ORDER, updateTitle: '移動距離CSV' })
  };

  const handleExportCSV = async ({ type, visitDate, screenName, updateTitle }) => {
    try {
      setLoading(true);
      const data = await DISPATCH.EXPORT_CSV_DISPATCH({ type, visitDate, screenName, updateTitle });
      if (data?.data) {
        setDefaultCsv(data?.data);
        document.getElementById(`download-csv-${type}`).click();
        pushToast('success', 'Download CSV all success');
        setLoading(false);
        dispatch(getDataHistoryMasterSetting({ logId: visitDate, screenName: SCREEN_NAME.DISPATCH_SETTINGS_ORDER, idProvide: '' }));
      }
    } catch (errorMessage) {
      setLoading(false);
      console.log('errorMessage: ', errorMessage);
    }
  };

  const onDownload = (e) => {
    e.stopPropagation();
  };

  const handleUpdateStatus = (status) => {
    const body = {
      statusDispatch: status,
      visitDate: dateSelect,
      screenName: SCREEN_NAME.DISPATCH_SETTINGS_ORDER,
      updateTitle: +status === 2 ? '保存' : '確定'
    };

    handleUpdateStatusShift({ body, staffId: taskActive.staffId })
  }

  const handleUpdateStatusShift = async ({ body }) => {
    setLoading(true);
    try {
      const data = await DISPATCH.UPDATE_DISPATCH_SHIFT({ body });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
          setLoading(false);
        } else {
          pushToast('error', data?.message?.errorMessage);
          setLoading(false);
        }
      } else {
        pushToast('success', 'Update Dispatch Shift Success');
        dispatch(getDataHistoryMasterSetting({ logId: body.visitDate, screenName: SCREEN_NAME.DISPATCH_SETTINGS_ORDER, idProvide: '' }));
        setLoading(false);
        handleReload();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleResetDispatch = () => {
    onResetDispatch({ visitDate: dateSelect });
  };

  const onResetDispatch = async ({ visitDate = '' }) => {
    setLoading(true);
    try {
      const data = await DISPATCH.RESET_DISPATCH({ visitDate, body: { screenName: SCREEN_NAME.DISPATCH_SETTINGS_ORDER, updateTitle: '一括解除' } });
      if (data && data.code !== 0) {
        setLoading(false);
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
      } else {
        pushToast('success', 'Reset Dispatch success');
        dispatch(getDataHistoryMasterSetting({ logId: visitDate, screenName: SCREEN_NAME.DISPATCH_SETTINGS_ORDER, idProvide: '' }));
        setLoading(false);
        handleReload();
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const handleCollap = (key) => {
    if (isShow === key) {
      setIsShow('');
    } else {
      setIsShow(key);
    }
  };

  const handleSearch = ({ dataSearch }) => {
    setDataSearch({ ...dataSearch, isSearch: true })
  };

  useEffect(() => {
    if (dataSearch.isSearch) {
      delete dataSearch.isSearch
      handleGetListWorkerSchedule({
        visitDate: dateSelect,
        organizationId,
        page: activePage,
        ...dataSearch,
      });
    }
  }, [dataSearch]);

  const onDrag = (event, item) => {
    event.preventDefault();
    setServiceDrag(item);
  };

  const address = `${taskDescription?.prefecturesVisited || ''} - ${taskDescription?.visitedBaseAddress || ''} - ${taskDescription?.visitedBuildingName || ''}`

  // const getWorkContent = () => {
  //   if (!allService || !taskDescription) return '';

  //   const service = allService.find(item => item.orderId === taskDescription.orderId);

  //   if (!service) return '';

  //   const content = [];

  //   for(let i = 0; i < 5; i++) {
  //     const optionKey = `option${i+1}`;
  //     if (service[optionKey]) content.push(service[optionKey]);
  //   }

  //   return content.join(' ');
  // }

  return (
    <div className="wrapper-dispatch-all">
      <Loading loading={loading} />
      <Header p='condition_info' idRole={idRole} color={colorScreen?.code} colorText={colorScreen?.colorText} />
      <div>
        <div className="wrapper-btn-colappse">
          <div onClick={toggle} className="mb-1 search bar d-flex justify-content-between align-items-center">
            <div></div>
            <div style={{ color: `${colorScreen.colorText}` }}>
              <i className="icon-search"/>
              検索条件を表示
            </div>
            <div className="mr-2" style={{ color: `${colorScreen.colorText}` }}>
              {isOpen ? <i className="icon-up-open icon-searchbar"/> : <i className="icon-down-open icon-searchbar"/>}
            </div>
          </div>
        </div>
        <Search
          isOpen={isOpen}
          color={colorScreen?.code}
          colorText={colorScreen?.colorText}
          handleSearch={handleSearch}
        />
      </div>

      <div className="body pb-4">
        <div className="wrapper-main-dispatch">
          <div className='form-select-date'>

            {/* <div className="form-btn-select-date">
              <button
                style={{ backgroundColor: `${colorScreen.code}` }}
                onClick={()=> handleMoreDay(-1)}
                className="btn-select-date">
                <i className="fas fa-chevron-left"></i>
              </button>
              <button
                style={{ backgroundColor: `${colorScreen.code}` }}
                onClick={()=> handleSetToday()}
                className="btn-select-date today">今日</button>
              <button
                style={{ backgroundColor: `${colorScreen.code}` }}
                onClick={()=> handleMoreDay(1)}
                className="btn-select-date">
                <i className="fas fa-chevron-right"></i>
              </button>
            </div> */}

            <div className="form-input-and-reload">
              <DatePicker
                style={{ backgroundColor: `${colorScreen.code}` }}
                onChange={(date) => {
                  changeDate(date)
                  setTaskDescription(null)
                }}
                selected={dateSelect ? new Date(dateSelect) : ''}
                locale="ja"
                dateFormat="yyyy/MM/dd"
                dateFormatCalendar="yyyy年 MM月"
              />
              {/* <div className="button-reload"
                onClick={() => handleReload()}
              >
                <i className="fas fa-sync-alt"></i>
              </div> */}
            </div>

            <div className='form-total-order'>
              {
                `Total Order: ${totalOrder ? totalOrder : 0}`
              }
            </div>

            <button
              style={{ backgroundColor: `${colorScreen.code}` }}
              onClick={() => handleDispatchAll()}
              className="btn-dispatch">
              ディスパッチ
            </button>

            <div>
              {/* <button
                style={{ backgroundColor: `${colorScreen.code}` }}
                onClick={(e) => handleExport({e, type: `dispatchResult`})}
                name={`dispatchResult`}
                className="btn-dispatch">
                ディスパッチ結果CSV
              </button> */}

              <CSVLink
                className="text-decoration-none"
                data={defaultCsv}
                filename={`${NAME_FILE_EXPORT_DISPATCH.dispatchResult}${moment().format(DATETIME_EXPORT_JP_FORMAT)}.csv`}
              >
                <span onClick={onDownload} id={'download-csv-dispatchResult'}></span>
              </CSVLink>
            </div>

            <div>
              <button
                style={{ backgroundColor: `${colorScreen.code}` }}
                onClick={(e) => handleExport({ e, type: 'movingDistance' })}
                name={'movingDistance'}
                className="btn-dispatch">
                移動距離CSV
              </button>

              <CSVLink
                className="text-decoration-none"
                data={defaultCsv}
                filename={`${NAME_FILE_EXPORT_DISPATCH.movingDistance}${moment().format(DATETIME_EXPORT_JP_FORMAT)}.csv`}
              >
                <span onClick={onDownload} id={'download-csv-movingDistance'}></span>
              </CSVLink>
            </div>

            <button
              style={{ backgroundColor: `${colorScreen.code}` }}
              onClick={() => handleResetDispatch()}
              className="btn-dispatch">
              一括解除
            </button>

            <button
              style={{ backgroundColor: `${colorScreen.code}` }}
              onClick={() => handleOpenMap()}
              className="btn-dispatch map">
              <i className="fas fa-map-marked"></i>
              MAP
            </button>
          </div>

          {
            !_.isEmpty(taskDescription) ?
              <div className="form-description">
                <div className="row-description">
                  <div className="item-description flex-fill">
                    <div className="label-form">案件名</div>
                    <div className="content-form">{taskDescription?.projectTitle}</div>
                  </div>

                  <div className="item-description flex-fill">
                    <div className="label-form">管理 IＤ</div>
                    <div className="content-form">{taskDescription?.managementCode}</div>
                  </div>

                  <div className="item-description flex-fill">
                    <div className="label-form">物件名</div>
                    <div className="content-form">{taskDescription?.installationSiteName}</div>
                  </div>

                  <div className="item-description flex-fill">
                    <div className="label-form">訪問時間</div>
                    <div className="content-form">{`${taskDescription?.visitDate || ''} ${taskDescription?.visitDateFrom || ''} ~ ${taskDescription?.visitDateTo || ''}`}</div>
                  </div>

                  <div className="item-description flex-fill">
                    <div className="label-form">所要時間</div>
                      <div className="content-form">{taskDescription?.workTimeProductService}</div>
                  </div>
                </div>

                <div className="row-description">
                  <div className="item-description flex-fill">
                    <div className="label-form">場所</div>
                    <div className="content-form">{address}</div>
                  </div>

                  <div className="item-description flex-fill">
                    <div className="label-form">マンション名</div>
                    <div className="content-form">{taskDescription?.visitedBuildingName}</div>
                  </div>

                  <div className="item-description flex-fill">
                    <div className="label-form">施工区分</div>
                    <div className="content-form">{taskDescription?.constructionClassification}</div>
                  </div>

                  <div className="item-description flex-fill">
                    <div className="label-form">作業内容</div>
                    <div className="content-form">{_.compact(Object.values(taskDescription?.options || [])).toString()}</div>
                  </div>
                </div>
              <div className="row-description">
                <div className="item-description flex-fill">
                  <div className="label-form">申し送り事項</div>
                  <div className="content-form">{taskDescription?.notificationItems}</div>
                </div>
              </div>
            </div> : <div className="form-description"></div>
          }

          <div className="form-dispatch-all">
            <div className="form-all-service">
              {
                _.sortBy((allService || []), 'sortNum')?.map((item, index) => {
                  const classStatus = item?.status?.replace(/\s/g, '');
                  return (
                    <div
                      className={`item-service ${classStatus}`}
                      key={index}
                      style={{ backgroundColor: (item?.labelSetting || colorScreen?.code) }}
                      draggable
                      onDrag={(event) => onDrag(event, item)}
                      onClick={() => handleDispatchHandmade({ orderId: item.orderId, visitDate: item.visitDate })}
                    >
                      <div className="form-info-service">
                        <p>{item.serviceName}</p>
                        <p>{item.managementCode}</p>
                        <p>{item.option1}</p>
                        <p>{item.option2}</p>
                        <p>{item.option3}</p>
                        <p>{item.option4}</p>
                        <p>{item.option5}</p>
                      </div>
                      <i className="fas fa-caret-right"></i>
                    </div>
                  )
                })
              }
            </div>
            <div className="form-table-dispatch-all">
              {
                dataWorkingSchedule?.map((item, index) => {
                  return (
                    <CollapseDispatch
                      dataWorkingSchedule={item}
                      k={index}
                      key={index}
                      organizationId={organizationId}
                      visitDate={dateSelect}
                      onReload={handleReload}
                      pushDescription={pushDescription}
                      taskActive={taskActive}
                      isShow={isShow}
                      handleCollap={handleCollap}
                      colors={colorScreen?.code}
                      colorText={colorScreen?.colorText}
                      serviceDrag={serviceDrag}
                    />
                  )
                })
              }
              {/* {
                !_.isEmpty(dataWorkingSchedule) && dataWorkingSchedule?.count > 10 && (
                  <PaginationCustom
                  activePage={activePage}
                  itemsCountPerPage={10}
                  totalItemsCount={dataWorkingSchedule && dataWorkingSchedule?.count}
                  pageRangeDisplayed={5}
                  handlePageChange={handlePageChange} />
                )
              } */}
            </div>
          </div>
          <div className="form-btn-group">
            <button
              className="btn-save"
              onClick={() => handleUpdateStatus(2)}
            >保存</button>
            <button
              className="btn-confirm"
              onClick={() => handleUpdateStatus(1)}
            >確定</button>
          </div>
        </div>
      </div>
      <MemoHistory colors={colorScreen?.code} colorText={colorScreen?.colorText} screenName={SCREEN_NAME.DISPATCH_SETTINGS_ORDER} />
      <FooterMaintenance />
    </div>
  );
};

export default withRouter(DispatchAll);
