import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { translate } from 'react-i18next';
import './style.scss';
import { HEADER_MENU } from '../../../helpers/constants';
import TableMonitor from './TableMonitor';
import HeaderMasterSetting from '../../../components/HeaderMasterSetting';

const Monitor = () => {
  let name = (process.browser && new URL(window.location.href).searchParams.get('p')) || 'receive_all';
  const [p, setP] = React.useState(name || HEADER_MENU.RECEIVE_ALL);

  useEffect(() => {
    setP(name);
  }, [name]);

  return (
      <div className="main">
          <HeaderMasterSetting/>
          <div className="body-wrapper">
              <TableMonitor name={p} />
          </div>
      </div>
  );
};

export default compose(translate('translations'), withRouter)(Monitor);
