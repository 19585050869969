import React, { useEffect, useState } from 'react';
import { ORGANIZATION_ENDPOINT } from '../../../../constants/endpoints';
import './style.scss';
import { pushToast } from '../../../../components/Toast';
import { getRoleOrganization, validateRequried } from '../../../../helpers/helpers';
import _ from 'lodash';
import { PARAMS_REQUIRED_ORGANIZATION, ROLE, SCREEN_NAME } from '../../../../helpers/constants';
import { useHistory } from 'react-router-dom';
import AutocompleteAddress from 'components/AutocompleteAddress';
import AreaSelection from 'components/AreaSelection';
import { AREAS_PERMITTED } from 'helpers/areas';
import { Link, withRouter } from 'react-router-dom';
import FooterMaintenance from "components/FooterMaintenance"

const mockDataClient = {
  screenName: SCREEN_NAME.ORGANIZATION,
  screenNamePermission: SCREEN_NAME.ORGANIZATION,
  isAllAnkenJoin: false,
};

const TableModiflyOrganization = ({typeRole = ''}) => {
  const history = useHistory();
  const [dataOrganization, setDataOrganization] = useState(mockDataClient);
  const [postalCode1, setPostalCode1] = useState('');
  const [postalCode2, setPostalCode2] = useState('');
  const [roleOrganization, setRoleOrganization] = useState();
  const [isDisable, setIsDisable] = useState(false);
  const [prefectureAreas, setPrefectureAreas] = useState({});
  const prefectureNameKanji = _.groupBy(AREAS_PERMITTED, 'prefectureNameKanji');

  useEffect(() => {
    const newRole = localStorage.getItem('role');
    if (newRole) {
      setRoleOrganization(getRoleOrganization({ idRole: newRole }));
    };
    let newDataClient = { ...dataOrganization, type: 'all', typeRole };
    setDataOrganization(newDataClient);
  }, []);

  const getCode = async () => {
    try {
      const data = await ORGANIZATION_ENDPOINT.GET_CODE();
      let newData = { ...dataOrganization, code: data?.data?.code };
      setDataOrganization(newData);
    } catch (e) {
      console.log(e);
    }
  };

  const createOrganization = async ({ body }) => {
    try {
      const data = await (ORGANIZATION_ENDPOINT.CREATE_ORAGINIZATION(body));
      if (data && data.code !== 0) {
        pushToast('error', data?.message?.extra || data?.message?.errorMessage);
      } else {
        pushToast('success', 'Create Organization success');
        history.push('/organizations');
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleText = (typeRole) => {
    switch (typeRole) {
      case 'erp':
        return 'ERP_新規作成';
      case 'management-group':
        return 'ManagementGroup_新規作成';
      default:
        return '';
    }
  }

  const onClickSubmit = () => {
    if (roleOrganization === ROLE.NEOMARS) {
      const body = {...dataOrganization, prefectureAreas, officialName: dataOrganization?.companyName, organizationName: dataOrganization?.companyName, updateTitle: `更新 ${handleText(typeRole)}`};
      createOrganization({ body });
    }
  };

  const onChangePostalCode = (e) => {
    const type = e.target.name;
    if (type === 'postalCode1') {
      setPostalCode1(e.target.value);
    } else {
      setPostalCode2(e.target.value);
    }
  };

  const handleChangeText = (e) => {
    let type = e.target.name;
    if (roleOrganization === ROLE.NEOMARS) {
      let newDataClient = { ...dataOrganization, [type]: e.target.value };
      setDataOrganization(newDataClient);
    }
  };

  useEffect(() => {
    if (roleOrganization === ROLE.NEOMARS) {
      let newData = {
        ...dataOrganization,
        postalCode: postalCode1 + '-' + postalCode2,
      };
      setDataOrganization(newData);
    }
  }, [postalCode1, postalCode2]);

  useEffect(() => {
    if(dataOrganization) {
      validateRequried({data: dataOrganization, arrField: PARAMS_REQUIRED_ORGANIZATION, onDisabled});
    }
  }, [dataOrganization]);

  const onDisabled = ({status}) => {
    setIsDisable(status);
  }

  const onChangePrefectureAreas = (data) => {
    setPrefectureAreas(data);
  }

  const onChangeAreas = (areas) => {
    const newData = { ...dataOrganization, areas };
    setDataOrganization(newData);
    const values = _.flatten(Object.values(newData?.areas || {}));
    const dataHandler = values.map(e => {
      return Object.keys(prefectureAreas || {}).includes(e) ? {[e]: prefectureAreas[e]} : {[e]: _.compact(_.map(prefectureNameKanji[e] || [], 'cityNameKanji'))}
    })
    const newPrefectureAreas = _.isEmpty(dataHandler) ? {} : Object.assign(...dataHandler);
    setPrefectureAreas(newPrefectureAreas);
  }

  return (
    <div className="modifly__organization">
    <table className="table__modifly">
      <tr>
        <th colSpan="4" className="table__modifly--color text-left pl-1">
          {typeRole === 'management-group' ? 'ManagementGroup' : 'ERP'}
          <button className="btn modifly__organization--button modifly__organization--submit ml-5">基本情報</button>
          <Link to="/contract" className="btn modifly__organization--button modifly__organization--submit">契約情報</Link>
        </th>
      </tr>
      <tr>
        <th className="table__modifly--color modifly__organization--pd-unset">CloudID</th>
        <td className="modifly__organization--pd-unset">
          <input
            type="text"
            className="w-35"
            disabled={roleOrganization === ROLE.PARTNERGROUP}
            value={dataOrganization?.code}
            id="code"
            name="code"
          />
          <button
            className="ml-2 btn button-get-code btn modifly__organization--button modifly__organization--submit modifly__organization--btn-code"
            onClick={() => getCode()} >
            新規コードを取得
        </button>
        </td>
        <th className="table__modifly--color modifly__organization--pd-unset">募集案件参加</th>
        <td className="modifly__organization--pd-unset">
          <input type="checkbox" checked={dataOrganization?.isAllAnkenJoin} className="w-10 mb-0"  onClick={() => setDataOrganization({ ...dataOrganization , isAllAnkenJoin: !dataOrganization?.isAllAnkenJoin })}/>
        </td>
      </tr>

      {/* <tr>
        <th className="table__modifly--color modifly__organization--pd-unset">組織名</th>
        <td colSpan="3" className="modifly__organization--pd-unset">
          <input
            type="text"
            className="w-100 table__modifly--input"
            name="organizationName"
            id="organizationName"
            value={dataOrganization?.organizationName}
            onChange={handleChangeText}
          />
        </td>
      </tr> */}
      <tr>
        <th className="table__modifly--color modifly__organization--pd-unset">会社名</th>
        <td className="modifly__organization--pd-unset">
          <input
            type="text"
            className="w-100 table__modifly--input"
            name="companyName"
            id="companyName"
            value={dataOrganization?.companyName}
            onChange={handleChangeText}
          />
        </td>
        <th className="table__modifly--color modifly__organization--pd-unset">電話番号</th>
        <td className="modifly__organization--pd-unset">
          <input
            type="text"
            className="w-100 table__modifly--input"
            name="phoneNumber"
            id="phoneNumber"
            value={dataOrganization?.phoneNumber}
            onChange={handleChangeText}
          />
        </td>
      </tr>
      <tr>
        <th className="table__modifly--color modifly__organization--pd-unset">フリガナ</th>
        <td className="modifly__organization--pd-unset" colSpan="3">
          <input
            type="text"
            className="w-100 table__modifly--input"
            name="officialNameKana"
            id="officialNameKana"
            value={dataOrganization?.officialNameKana}
            onChange={handleChangeText}
          />
        </td>
      </tr>
      <tr>
        <th className="table__modifly--color modifly__organization--pd-unset">システム表示名称</th>
        <td className="modifly__organization--pd-unset">
          <input
            type="text"
            className="w-100 table__modifly--input"
            name="abbreviationName"
            id="abbreviationName"
            value={dataOrganization?.abbreviationName}
            onChange={handleChangeText}
          />
        </td>
        <th className="table__modifly--color modifly__organization--pd-unset">システム表示略称（英2～4文字）</th>
        <td className="modifly__organization--pd-unset">
          <input
            type="text"
            className="w-100 table__modifly--input"
            name="abbreviation"
            id="abbreviation"
            maxLength="4"
            value={dataOrganization?.abbreviation}
            onChange={handleChangeText}
            disabled={history.location.pathname === '/organizations/edit-organization'}
          />
        </td>
      </tr>
      <tr>
        <th className="table__modifly--color modifly__organization--pd-unset">代表者</th>
        <td className="modifly__organization--pd-unset">
          <input
            type="text"
            className="w-100 table__modifly--input"
            name="name"
            id="name"
            value={dataOrganization?.name}
            onChange={handleChangeText}
          />
        </td>
        <th className="table__modifly--color modifly__organization--pd-unset">フリガナ</th>
        <td className="modifly__organization--pd-unset">
          <input
            type="text"
            className="w-100 table__modifly--input"
            name="nameKana"
            id="nameKana"
            value={dataOrganization?.nameKana}
            onChange={handleChangeText}
          />
        </td>
      </tr>
      <tr>
        <th className="table__modifly--color modifly__organization--pd-unset">郵便番号</th>
        <td className="modifly__organization--pd-unset" style={{ width: '26%' }}>
          <div className="d-flex w-100">
            <input
              className="table__modifly--input w-50 mr-1"
              type="number"
              name="postalCode1"
              maxLength={4}
              value={postalCode1}
              onChange={onChangePostalCode}
            />
            <input
              className="table__modifly--input w-50"
              type="number"
              name="postalCode2"
              maxLength="4"
              value={postalCode2}
              onChange={onChangePostalCode}
            />
          </div>
        </td>
        <th className="table__modifly--color modifly__organization--pd-unset">都道府県</th>
        <td className="modifly__organization--pd-unset">
          <select
            className="w-100 table__modifly--input"
            name="prefecture"
            value={dataOrganization?.prefecture}
            onChange={handleChangeText}
          >
            <option value=""></option>
            <option value="北海道">北海道</option>
            <option value="青森県">青森県</option>
            <option value="岩手県">岩手県</option>
            <option value="宮城県">宮城県</option>
            <option value="秋田県">秋田県</option>
            <option value="山形県">山形県</option>
            <option value="福島県">福島県</option>
            <option value="東京都">東京都</option>
            <option value="神奈川県">神奈川県</option>
            <option value="埼玉県">埼玉県</option>
            <option value="千葉県">千葉県</option>
            <option value="茨城県">茨城県</option>
            <option value="栃木県">栃木県</option>
            <option value="群馬県">群馬県</option>
            <option value="山梨県">山梨県</option>
            <option value="新潟県">新潟県</option>
            <option value="長野県">長野県</option>
            <option value="石川県">石川県</option>
            <option value="富山県">富山県</option>
            <option value="福井県">福井県</option>
            <option value="愛知県">愛知県</option>
            <option value="岐阜県">岐阜県</option>
            <option value="静岡県">静岡県</option>
            <option value="三重県">三重県</option>
            <option value="大阪府">大阪府</option>
            <option value="兵庫県">兵庫県</option>
            <option value="京都府">京都府</option>
            <option value="滋賀県">滋賀県</option>
            <option value="奈良県">奈良県</option>
            <option value="和歌山県">和歌山県</option>
            <option value="鳥取県">鳥取県</option>
            <option value="島根県">島根県</option>
            <option value="岡山県">岡山県</option>
            <option value="広島県">広島県</option>
            <option value="山口県">山口県</option>
            <option value="徳島県">徳島県</option>
            <option value="香川県">香川県</option>
            <option value="愛媛県">愛媛県</option>
            <option value="高知県">高知県</option>
            <option value="福岡県">福岡県</option>
            <option value="佐賀県">佐賀県</option>
            <option value="長崎県">長崎県</option>
            <option value="熊本県">熊本県</option>
            <option value="大分県">大分県</option>
            <option value="宮崎県">宮崎県</option>
            <option value="鹿児島県">鹿児島県</option>
            <option value="沖縄県">沖縄県</option>
          </select>
        </td>
      </tr>
      <tr>
        <th className="table__modifly--color modifly__organization--pd-unset">住所</th>
        <td className="modifly__organization--pd-unset" colSpan="3">
          <AutocompleteAddress
            address={dataOrganization?.address}
            onChange={(address) => {
              setDataOrganization({ ...dataOrganization, address });
            }}
            name="address"
            onSelectAddress={({ address, latitude, longitude }) => {
              setDataOrganization({ ...dataOrganization, address, latitude, longitude });
            }}
          />
        </td>
      </tr>
      <tr>
        <th className="table__modifly--color modifly__organization--pd-unset">フリガナ</th>
        <td className="modifly__organization--pd-unset" colSpan="3">
          <input
            type="text"
            className="w-100 table__modifly--input"
            name="addressKana"
            value={dataOrganization?.addressKana}
            onChange={handleChangeText}
          />
        </td>
      </tr>
        <tr>
          <th className="table__modifly--color modifly__organization--pd-unset">緯度</th>
          <td className="modifly__organization--pd-unset">
            <input
              type="text"
              className="w-100 table__modifly--input"
              value={dataOrganization?.latitude}
              name="latitude"
              id="latitude"
            />
          </td>
          <th className="table__modifly--color modifly__organization--pd-unset">経度</th>
          <td className="modifly__organization--pd-unset">
            <input
              type="text"
              className="w-100 table__modifly--input"
              value={dataOrganization?.longitude}
              name="longitude"
              id="longitude"
            />
          </td>
        </tr>
        <tr>
          <th className="table__modifly--color modifly__organization--pd-unset">対応エリア設定</th>
          <td className="table__modifly--background modifly__organization--pd-unset" colSpan="3">
            <AreaSelection
              activeArea={{ ...dataOrganization?.areas }}
              prefectureAreas={prefectureAreas}
              onChangePrefectureAreas={onChangePrefectureAreas}
              onChange={(areas) => { onChangeAreas(areas) }}
            />
          </td>
        </tr>
    </table>
    <div className="text-center mb-3">
      <button
        className="btn modifly__organization--button modifly__organization--submit--update"
        onClick={onClickSubmit}
        disabled={isDisable}
      >
        更新
      </button>
    </div>
    <FooterMaintenance/>
  </div>
  );
};

export default TableModiflyOrganization;
