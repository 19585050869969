import React, { useState, useEffect } from 'react';
import { Collapse } from 'reactstrap';
import { SCREEN_NAME } from 'helpers/constants';

const BeforeConfirmComment = ({ data = {}, changeData, screen = '' }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [dataDetail, setDataDetail] = useState(data);
  const [textCallLog, setTextCallLog] = useState('');
  const [approvalRequestMemo, setApprovalRequestMemo] = useState('');

  const onClickShow = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    setDataDetail(data);
    setTextCallLog(data?.callLog);
    setApprovalRequestMemo(screen === SCREEN_NAME.TOMORROW_INFO ? data?.beforeConfirmMemo : data?.approvalRequestMemo);
  }, [data]);

  const changeText = (e) => {
    const value = e.target.value;
    let name = e.target.name;
    if(name === 'callLog') {
      setTextCallLog(value);
    } else if (name === 'approvalRequestMemo') {
      console.log({ value, name })
      name = screen === SCREEN_NAME.TOMORROW_INFO ? 'beforeConfirmMemo' : name;
      setApprovalRequestMemo(value);
    }
    changeData({name, value});
  };

  return (
    <div className="wrapper-comfirm-status">
      <div className="content__wrapper">
        <div className="title-absolute cursor-pointer" onClick={onClickShow}>
        コメント欄 {isOpen ? <i className="fas fa-arrow-circle-up ml-2"></i> : <i className="fas fa-arrow-circle-down ml-2"></i>}
        </div>
        <Collapse isOpen={isOpen}>
          <div className="row col-12">
            <div className="row col-6">
              <label className="col-4 content__label content__label--mgb content__label--main">架電メモ</label>
              <textarea className="col-8 content__label--mgb" name="callLog" value={textCallLog} onChange={changeText}>
              </textarea>
            </div>
            <div className="row col-6">
              <label className="col-4 content__label content__label--mgb content__label--main">承認依頼メモ</label>
              <textarea disabled={screen === SCREEN_NAME.TOMORROW_INFO} className="col-8 content__label--mgb" name="approvalRequestMemo" value={approvalRequestMemo} onChange={changeText}>
              </textarea>
            </div>
          </div>
        </Collapse>
      </div>
    </div>
  );
};

export default BeforeConfirmComment;
