import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import ButtonDetail from 'components/ButtonDetail';
import { PERMITTED_PARAM_RECREATE, time, DATE_JP_FORMAT, DATETIME_JP_FORMAT } from 'helpers/constants';
import { ORDER } from 'constants/endpoints';
import _ from 'lodash';
import DateTimePicker from 'components/DateTimePicker';
import moment from 'moment';
import { pushToast } from 'components/Toast';
import { formatInputSelectTime  , formatInputSelectDate} from 'helpers/helpers';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ja from 'date-fns/locale/ja';
registerLocale('ja', ja);

const FormOrder = ({ data = {}, p = 'recreate', link = true, title='申込会社', listAddress = [] }) => {
  const [address, setAddress] = useState(listAddress || []);
  const [dataTable, setDataTable] = useState(data);
  const [visitDateTo,setVisitDateTo] = useState('')
  const history = useHistory();

  useEffect(() => {
    if(listAddress) {
      setAddress(listAddress);
    }
  }, [listAddress]);

  useEffect(() => {
    if (data) {
      setDataTable({
        ...data,
        usePartner: !_.isEmpty(dataTable?.partnerName) ? true : false,
        visitDateTo: data?.visitDateTo ? (moment(`${formatInputSelectDate(data?.visitDateTo)}`, `${DATE_JP_FORMAT}`).isValid()
          ? data?.visitDateTo
          : `${data?.visitDate ? `${data?.visitDate} ${data?.visitDateTo}` : ''}`) : '',
      });
      setVisitDateTo(
        moment(`${formatInputSelectDate(data?.visitDateTo)}`, `${DATE_JP_FORMAT}`).isValid()
          ? dataTable?.visitDateTo?.slice(-16, -6) || ''
          : ''
      );
    }
  }, [data]);

  const onUpdateOrder = () => {
    reCreateOrder({ body: _.pick(dataTable, PERMITTED_PARAM_RECREATE) })
  }

  const onChangeInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const newData = {...dataTable, [name]: value};
    setDataTable(newData);
  }

  const reCreateOrder = async ({ body = {} }) => {
    try {
      const data = await ORDER.ORDER_RECREATE({ body });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
      } else {
        pushToast('success', 'Recreate order success');
        history.push(`/recreate`);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleVisitDateTo = (date) => {
    setVisitDateTo(date ? moment(new Date(date)).format(DATE_JP_FORMAT) : '');
    setDataTable({
      ...dataTable,
      visitDateTo: date
        ? `${moment(new Date(date)).format(DATE_JP_FORMAT)} ${formatInputSelectTime(dataTable?.visitDateTo) || '00:00'}`
        : '',
    });
  };

  const handleChangeSelection = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const newData =
    name === 'visitDateTo'
      ? { ...dataTable, [name]: !_.isEmpty(visitDateTo) ? `${visitDateTo} ${value}` : '' }
      : { ...dataTable, [name]: value };
    setDataTable(newData);
  };

  const handleChangeDateTime = (e) => {
    setDataTable({ ...dataTable, visitDate: e === null ? '' : moment(new Date(e)).format(DATE_JP_FORMAT) , visitDateFrom : dataTable?.visitDateFrom || '00:00' });
  };

  const onChangeCheckbox = (e) => {
    const name = e.target.name;
    const value = e.target.checked;
    const newData = {...dataTable, [name]: value};
    setDataTable(newData);
  };

  return (
    <div className="detail-data-wrapper">
      {link && <div className="logout_btn">
        <Link to={`list?p=${p}`}>
          <span className="mypage_ab">戻る</span>
        </Link>
      </div>}
      <div className="detail-cancel-table-wrapper">
        <div className="title-absolute">{title}</div>
        <table className="conditTab table__custom">
          <tbody>
            <tr>
              <th>タイムスタンプ</th>
              <td>{moment(dataTable?.createdAt).format(DATETIME_JP_FORMAT)}</td>
            </tr>
            <tr>
              <th>利用者名</th>
              <td>{dataTable?.updatedBy}</td>
            </tr>
            <tr>
              <th>案件名</th>
              <td>{dataTable?.caseName}</td>
            </tr>
            <tr>
              <th>管理コード</th>
              <td><input type="text" value={dataTable?.managementCode} name={"managementCode"} onChange={e => onChangeInput(e)} /></td>
            </tr>
            <tr>
              <th>担当者名</th>
              <td>{dataTable?.contactName}</td>
            </tr>
            <tr>
              <th>担当者連絡先_携帯番号</th>
              <td><input type="text" value={dataTable?.clientStaffTel} name={"clientStaffTel"} onChange={e => onChangeInput(e)} /></td>
            </tr>
            <tr>
              <th>担当者連絡先_メールアドレス</th>
              <td><input type="text" value={dataTable?.clientStaffEmail} name={"clientStaffEmail"} onChange={e => onChangeInput(e)}/></td>
            </tr>
            <tr>
              <th>工程</th>
              <td>
                <select name="serviceId" value={dataTable?.serviceId} id="serviceName" onChange={e => handleChangeSelection(e)}>
                    <option value="">--</option>
                    {dataTable?.services && dataTable?.services?.map((item) =>
                      <option value={item?.serviceId}>{item?.name}</option>
                    )}
                </select>
              </td>
            </tr>
            <tr>
              <th>依頼日</th>
              <td>{dataTable?.requestedDate}</td>
            </tr>
            <tr>
              <th>申込会社名</th>
              <td><input type="text" value={dataTable?.applicantCompanyName} name={"applicantCompanyName"} onChange={e => onChangeInput(e)} /></td>
            </tr>
            <tr>
              <th>申込ご担当者</th>
              <td><input type="text" value={dataTable?.personInChargeOfApplication} name={"personInChargeOfApplication"} onChange={e => onChangeInput(e)} /></td>
            </tr>

            <tr>
              <th>申込ご担当者連絡先</th>
              <td><input type="text" value={dataTable?.phoneNumber1} name={"phoneNumber1"} onChange={e => onChangeInput(e)} /></td>
            </tr>
            <tr>
              <th>物件名</th>
              <td><input type="text" value={dataTable?.installationSiteName} name={"visitedSiteName"} onChange={e => onChangeInput(e)} /></td>
            </tr>
            <tr>
              <th>訪問先ご担当者</th>
              <td><input type="text" value={dataTable?.personInChargeOfVisiting} name={"personInChargeOfVisiting"} onChange={e => onChangeInput(e)} /></td>
            </tr>
            <tr>
              <th>訪問先ご担当者連絡先</th>
              <td><input type="text" value={dataTable?.phoneNumber01} name={"phoneNumber01"} onChange={e => onChangeInput(e)} /></td>
            </tr>
            <tr>
              <th>訪問先郵便番号</th>
              <td><input type="text" value={dataTable?.mailNumber1} name={"mailNumber1"} onChange={e => onChangeInput(e)} /></td>
            </tr>
            <tr>
              <th>都道府県</th>
              <td>
                <select name="prefecturesVisited" value={dataTable?.prefecturesVisited} id="prefecturesVisited" onChange={e => handleChangeSelection(e)}>
                  <option value="">--</option>
                  {address?.map((item) =><option value={item}>{item}</option>)}
                </select>
              </td>
            </tr>
            <tr>
              <th>訪問先拠点住所</th>
              <td><input type="text" value={dataTable?.visitedBaseAddress} name={"visitedBaseAddress"} onChange={e => onChangeInput(e)} /></td>
            </tr>
            <tr>
              <th>訪問先建物名</th>
              <td><input type="text" value={dataTable?.visitedBuildingName} name={"visitedBuildingName"} onChange={e => onChangeInput(e)} /></td>
            </tr>
            <tr>
              <th>作業予定箇所</th>
              <td><input type="text" value={dataTable?.scheduledInstallationLocation} name={"scheduledInstallationLocation"} onChange={e => onChangeInput(e)} /></td>
            </tr>
            <tr>
              <th>訪問日</th>
              <td>
                <div className="d-flex align-items-center" name="visitDate">
                  <DateTimePicker
                    date={dataTable?.visitDate ? new Date(dataTable?.visitDate) : null}
                    onChangeDate={handleChangeDateTime}
                  />
                  <select
                    name="visitDateFrom"
                    value={dataTable?.visitDateFrom}
                    onChange={handleChangeSelection}
                  >
                    {time.map((item, index) => (
                      <option value={item} key={index}>
                        {item}
                      </option>
                    ))}
                  </select>
                  ～
                  <div className='w-10 mr-1'>
                    <DatePicker
                      selected={visitDateTo ? new Date(visitDateTo) : null}
                      onChange={(date) => handleVisitDateTo(date)}
                      locale="ja"
                      dateFormat="yyyy/MM/dd"
                      dateFormatCalendar="yyyy年 MM月"
                      className='w-100 '
                    />
                  </div>
                  <select
                    disabled={_.isEmpty(visitDateTo)}
                    name="visitDateTo"
                    onChange={handleChangeSelection}
                    value={formatInputSelectTime(dataTable?.visitDateTo)}
                  >
                    {time.map((item, index) => (
                      <option value={item} key={index}>
                        {item}
                      </option>
                    ))}
                  </select>
                </div>
              </td>
            </tr>
            <tr>
              <th>申し送り事項</th>
              <td><textarea value={dataTable?.erpMattersToBeSent} name={"erpMattersToBeSent"} onChange={e => onChangeInput(e)} /></td>
            </tr>
            <tr>
              <th>前回確保パートナー</th>
              <td>
                {dataTable?.partnerName}
                {!_.isEmpty(dataTable?.partnerName) && (
                  <input
                    type="checkbox"
                    name="usePartner"
                    className="checkbox__custom--unset checkbox__custom--partner"
                    checked={dataTable?.usePartner}
                    onChange={(e) => onChangeCheckbox(e)}
                  />
                )}
              </td>
            </tr>
          </tbody>
        </table>
        <div className="detail-data-wrapper">
          <ButtonDetail text="保存" onUpdateOrder={onUpdateOrder} />
        </div>
      </div>
    </div>
  );
};

export default FormOrder;
