import React, { useState, useEffect, Fragment } from 'react';
import DateTimePicker from 'components/DateTimePicker';
import { Collapse } from 'reactstrap';
import { DATE_JP_FORMAT, SCREEN_NAME, DATETIME_JP_FORMAT } from 'helpers/constants';
import moment from 'moment';
import { pushToast } from 'components/Toast';
import { ORDER } from 'constants/endpoints';
// import ButtonRequest from '../ButtonRequest';
import _ from 'lodash';
import { getDate } from 'helpers/helpers';
import { subDays } from 'helpers/helpers';
import Loading from 'components/Loading/Loading';
import { getDataHistory } from 'actions/authentication';
import { useDispatch } from 'react-redux';

const BeforeConfirmUpload = ({
  data = {},
  changeData,
  screenName,
  requestConfirm,
  role,
  documentSettingBeforeConfirm = [],
}) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [dataDetail, setDataDetail] = useState({});
  const [selectedFile, setSelectedFile] = useState({});
  const [selectedName, setSelectedName] = useState({});
  const [documentSetting, setSetDocumentSetting] = useState({});
  const [nameTypeChild, setNameTypeChild] = useState([]);
  const [isChangeDate, setIsChangeDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const organizationId = data?.organizationId || '';

  const onClickShow = () => {
    setIsOpen(!isOpen);
  };

  const changeVal = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    changeData({ name, value });
  };

  useEffect(() => {
    setDataDetail(data);
    const name = {};
    documentSettingBeforeConfirm.map((e) => {
      name[`beforeConfirm${e.timestamp}`] = data?.[`beforeConfirm${e.timestamp}`]?.name;
    });
    setSelectedName({
      admissionApplicationDocuments: data?.admissionApplicationDocuments?.name,
      workConfirmation: data?.workConfirmation?.name,
      permitApplication: data?.permitApplication?.name,
      forSubmission: data?.forSubmission?.name,
      ...name
    });
    setIsChangeDate(data?.beforeConfirmDocumentSetting);
  }, [data]);

  useEffect(() => {
    const groupByDocumentSetting = _.groupBy(documentSettingBeforeConfirm, (result) => result.nameTypeChild);
    setSetDocumentSetting(groupByDocumentSetting);
    setNameTypeChild(Object.keys(groupByDocumentSetting));
  }, [documentSettingBeforeConfirm]);

  const confirmStatus = (key, status, name = '') => {
    if (status) {
      requestConfirm({
        orderId: dataDetail?.orderId,
        body: { screenName, statusBeforeConfirm: status, namePlugin: key, name }
      });
    }
  };

  const changeDate = (date, name) => {
    const beforeConfirmDocumentSetting = data?.beforeConfirmDocumentSetting || [];
    let isExist = false;
    beforeConfirmDocumentSetting.map((e) => {
      if (e.name === name) {
        e.priorConfirmationDate = date ? moment(new Date(date)).format(DATE_JP_FORMAT) : '';
        isExist = true;
      }
    });
    if (!isExist) {
      beforeConfirmDocumentSetting.push({
        priorConfirmationDate: date ? moment(new Date(date)).format(DATE_JP_FORMAT) : '',
        name
      });
    }

    changeData({ name: 'beforeConfirmDocumentSetting', value: beforeConfirmDocumentSetting });
    setIsChangeDate(dataDetail?.beforeConfirmDocumentSetting);
  };
  const changeDeadLine = (val, name) => {
    const beforeConfirmDocumentSetting = data?.beforeConfirmDocumentSetting || [];
    let isExist = false;
    beforeConfirmDocumentSetting.map((e) => {
      if (e.name === name) {
        e.priorConfirmationDeadline = +val.target.value || '';
        isExist = true;
      }
    });
    if (!isExist) {
      beforeConfirmDocumentSetting.push({ priorConfirmationDeadline: +val.target.value || '', name });
    }

    changeData({ name: 'beforeConfirmDocumentSetting', value: beforeConfirmDocumentSetting });
    setIsChangeDate(dataDetail?.beforeConfirmDocumentSetting);
  };

  const uploadFile = async ({ body = '', orderId = '' }) => {
    try {
      const data = await ORDER.UPLOAD_FILE_BEFORE_CONFIRM({ body, orderId, organizationId });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else if (data.message.errorMessage) {
          pushToast('error', data?.message?.errorMessage);
        } else {
          pushToast('error', data?.message);
        }
      } else {
        pushToast('success', 'Upload success');
        setDataDetail({ ...dataDetail, ...data?.data });
        dispatch(getDataHistory({ orderId, screenName: screenName, organizationId }));
      }
    } catch (errorMessage) {
      console.log('errorMessage: ', errorMessage);
    }
  };

  const updateStatus = async ({ body = '', orderId = '' }) => {
    try {
      setLoading(true);
      const dataStatus = await ORDER.UPDATE_STATUS_BEFORE_CONFIRM_DOCUMENT({ body, orderId, organizationId });
      if (dataStatus && dataStatus.code !== 0) {
        if (dataStatus && dataStatus.message && dataStatus.message.extra) {
          pushToast('error', dataStatus?.message?.extra);
        } else if (dataStatus.message.errorMessage) {
          pushToast('error', dataStatus?.message?.errorMessage);
        } else {
          pushToast('error', dataStatus?.message);
        }
        setLoading(false);
      } else {
        const beforeConfirmDocumentSetting = dataStatus?.data?.beforeConfirmDocumentSetting || [];
        changeData({ name: 'beforeConfirmDocumentSetting', value: beforeConfirmDocumentSetting || [] });
        setIsChangeDate(dataDetail?.beforeConfirmDocumentSetting || []);
        pushToast('success', 'Update status success');
        dispatch(getDataHistory({ orderId, screenName: screenName, organizationId }));
        setLoading(false);
      }
    } catch (errorMessage) {
      console.log('errorMessage: ', errorMessage);
      setLoading(false);
    }
  };

  const handleUpdateStatus = ({ elm  , updateTitle}) => {
    const beforeConfirmDocumentSetting = data?.beforeConfirmDocumentSetting || [];
    const documentSetting = beforeConfirmDocumentSetting.find((e) => e.name === elm);
    const body = {
      ...documentSetting, statusPrior: true, screenName, name: elm,
      priorConfirmationDeadline: documentSetting?.priorConfirmationDeadline?.toString() || '',
      priorConfirmationDate: moment(new Date()).format(DATETIME_JP_FORMAT),
      updateTitle,
      companyName : localStorage.getItem('companyName')
    };
    updateStatus({ body, orderId: dataDetail?.orderId });
  };

  const handleDataDeadLine = (name, value) => {
    const beforeConfirmDocumentSetting = data?.beforeConfirmDocumentSetting || [];
    const documentSetting = beforeConfirmDocumentSetting.find((e) => e.name === name);
    if (value === 'priorConfirmationDate') {
      return documentSetting?.[value] ? new Date(documentSetting?.[value]) : null;
    }

    return documentSetting?.[value] ? documentSetting?.[value] : null;
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const onClickHandler = async (e, numberOfMaterials, nameApplication) => {

    const key = e.target.name;
    if (selectedFile[key]) {
      const body = {
        content: selectedFile[key] ? await toBase64(selectedFile[key]) : '',
        screenName: SCREEN_NAME.TOMORROW_INFO,
        fileName: selectedName[key] ? selectedName[key] : '',
        keyFile: key,
        numberOfMaterials,
        nameApplication,
        companyName : localStorage.getItem('companyName'),
        updateTitle : 'アップロード',
      };
      uploadFile({ body, orderId: dataDetail?.orderId });
    }
  };

  const onChangeHandler = (e) => {
    setSelectedFile({ ...selectedFile, [e.target.name]: e.target.files[0] });
    setSelectedName({ ...selectedName, [e.target.name]: e?.target?.files[0]?.name });
  };

  const isRequired = ({ e }) => {
    return (
      e?.isRequired &&
      !(selectedName?.[`beforeConfirm${e.timestamp}`] === ''
        ? dataDetail?.[`beforeConfirm${e.timestamp}`]?.name
        : selectedName?.[`beforeConfirm${e.timestamp}`])
    );
  };

  return (
    <div className='wrapper-comfirm-status'>
      <Loading loading={loading} />
      <div className='content__wrapper'>
        <div className='title-absolute cursor-pointer' onClick={onClickShow}>
          各種申請{' '}
          {isOpen ? <i className='fas fa-arrow-circle-up ml-2'></i> : <i className='fas fa-arrow-circle-down ml-2'></i>}
        </div>
        <Collapse isOpen={isOpen}>
          {nameTypeChild?.map((elm, index) => {
            return (
              <Fragment key={index}>
                <div className='row col-12'>
                  <div className='row col-2 text-center'>
                    <label className='content__label content__label--w100 content__label--main'>{elm}</label>
                  </div>
                  <div className='col-2'>
                    <label className='content__label content__label--w100'>期限</label>
                    {documentSetting[elm]?.map((e, index) => {
                      return (
                        <>
                          <label key={index} className='content__label content__label--w100'>
                            {e.numberOfMaterials}
                          </label>
                        </>
                      );
                    })}
                  </div>
                  <div className='col-8'>
                    <div className='row col-12 content--pd0'>
                      <div className='col-3'>
                        <input
                          type='number'
                          className='content__input--w35'
                          value={handleDataDeadLine(elm, 'priorConfirmationDeadline')}
                          onChange={(e) => changeDeadLine(e, elm)}
                        />{' '}
                        日前
                      </div>
                      <div className='col-3'>
                        {
                          (isChangeDate?.find(e => e.name === elm)?.priorConfirmationDeadline && dataDetail?.visitDate) ? moment(new Date(subDays(dataDetail?.visitDate, isChangeDate?.find(e => e.name === elm)?.priorConfirmationDeadline))).format(DATE_JP_FORMAT)
                            + '（' + getDate(subDays(dataDetail?.visitDate, isChangeDate?.find(e => e.name === elm)?.priorConfirmationDeadline)) + '）' : ''
                        }
                      </div>
                      <div className='row col-5 content--pd0'>
                        <div className='col-3'>
                          <label className='content__label content__label--w100'>完了日</label>
                        </div>
                        <div className='col-5 content__datepicker content--pd0'>
                          {/* <DateTimePicker
                            date={handleDataDeadLine(elm, 'priorConfirmationDate')}
                            onChangeDate={(e) => changeDate(e, elm)}
                          /> */}
                          {isChangeDate && (<div>{isChangeDate.find(e => e.name === elm) ?  moment(new Date(isChangeDate.find(e => e.name === elm)?.priorConfirmationDate)).format(DATETIME_JP_FORMAT) : ''}</div>)}
                        </div>
                        <div className='col-4'>
                          <button className='btn content__button--custom content__button--w100'
                                  onClick={(e) => handleUpdateStatus({ elm , updateTitle :'完了' })}>完了
                          </button>
                        </div>
                      </div>
                    </div>
                    {documentSetting?.[elm]?.map((e, i) => {
                      return (
                        <Fragment key={i}>
                          <div className='row col-12 content--pd0'>
                            {e.uploadFile ? (
                              <>
                                <div className='col-3'>
                                  <label
                                    className='cursor-pointer content__label content__label--w100'
                                    htmlFor={`beforeConfirm${e.timestamp}`}
                                  >
                                    ファイルを指定
                                  </label>
                                  <input
                                    type='file'
                                    name={`beforeConfirm${e.timestamp}`}
                                    id={`beforeConfirm${e.timestamp}`}
                                    className='d-none'
                                    onChange={onChangeHandler}
                                  />
                                </div>
                                <div className='col-3 content--pd0'>
                                  <input
                                    className={`content__input--w100 ${
                                      isRequired({ e }) && 'content__input--required'
                                    }`}
                                    type='text'
                                    disabled
                                    value={
                                      selectedName?.[`beforeConfirm${e.timestamp}`] === ''
                                        ? dataDetail?.[`beforeConfirm${e.timestamp}`]?.name
                                        : selectedName?.[`beforeConfirm${e.timestamp}`]
                                    }
                                  />
                                </div>
                                <div className='col-3'>
                                  <button
                                    className='btn content__button--custom content__button--w100'
                                    name={`beforeConfirm${e.timestamp}`}
                                    onClick={(data) => onClickHandler(data, e?.numberOfMaterials, elm)}
                                  >
                                    アップロード
                                  </button>
                                </div>
                                {e.uploadFromAndTo ? (
                                  <div className='col-3'>
                                    <button
                                      className='btn content__button--custom content__button--w100'
                                      disabled={!dataDetail?.[`beforeConfirm${e.timestamp}`]?.url}
                                      onClick={() => window.open(`${dataDetail?.[`beforeConfirm${e.timestamp}`]?.url}`)}
                                    >
                                      ダウンロード
                                    </button>
                                  </div>
                                ) : (
                                  <div className='col-3'></div>
                                )}
                              </>
                            ) : (
                              <>
                                <div className='col-9 content--pd0'>
                                  <input
                                    className='content__input--w100'
                                    type='text'
                                    disabled
                                    value={
                                      selectedName?.[`beforeConfirm${e.timestamp}`] === ''
                                        ? dataDetail?.[`beforeConfirm${e.timestamp}`]?.name
                                        : selectedName?.[`beforeConfirm${e.timestamp}`]
                                    }
                                  />
                                </div>
                                <div className='col-3'>
                                  <button
                                    className='btn content__button--custom content__button--w100'
                                    disabled={!dataDetail?.[`beforeConfirm${e.timestamp}`]?.url}
                                    onClick={() => window.open(`${dataDetail?.[`beforeConfirm${e.timestamp}`]?.url}`)}
                                  >
                                    ダウンロード
                                  </button>
                                </div>
                              </>
                            )}
                          </div>
                        </Fragment>
                      );
                    })}
                  </div>
                  {/* <ButtonRequest data={dataDetail} state='statusBeforeAdmissionApplication' confirmStatus={confirmStatus} role={role} name={elm} action='admissionApplication' classCustom='col-2 content--pd0' /> */}
                </div>
              </Fragment>
            );
          })}
        </Collapse>
      </div>
    </div>
  );
};

export default BeforeConfirmUpload;
