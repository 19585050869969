import React from 'react';
import './style.scss';

const TableSelectMaster = ({
  fontSize = 'small',
  tableTop = '',
  data = [],
  selected = '',
  handleChange = () => {},
}) => {
  return (
    <div className="form-group h-100 w-100 wrapper-table-select-master">
      <select multiple className={`form-control w-100 h-100 ${tableTop} ${fontSize}`} onChange={handleChange}>
        {data &&
          data.map((item, index) =>
            index === selected ? (
              <option value={item.value} key={index} selected={true}>
                {item.label}
              </option>
            ) : (
              <option value={item.value} key={index}>
                {item.label}
              </option>
            )
          )}
      </select>
    </div>
  );
};

export default TableSelectMaster;
