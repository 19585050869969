import React, { useEffect, useState } from 'react';
import { Collapse } from 'reactstrap';
import { MASTER_SETTING } from '../../../../constants/endpoints';
import { withRouter } from 'react-router-dom';

const CollapseViewTree = ({ colors, history, title='詳細情報表示項目設定'}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);


  return (
    <div className="setting-master-menu-wrapper">
      <div className="wrapper-btn-colappse">
        <div
          onClick={toggle}
          className="mb-1 searchbar d-flex justify-content-between align-items-center "
          style={{ background: `${colors}` }}
        >
          <div className="ml-2">{title}</div>
          <div className="mr-2" style={{ color: `black` }}>
            {isOpen ? <i className="icon-up-open icon-searchbar" /> : <i className="icon-down-open icon-searchbar" />}
          </div>
        </div>
      </div>
      <div>
        <Collapse isOpen={isOpen}>
        </Collapse>
      </div>
    </div>
  );
};

export default withRouter(CollapseViewTree);
