import React, { useEffect, useState } from 'react';
import { translate } from 'react-i18next';
import { compose } from 'redux';
import DateTimePicker from 'components/DateTimePicker';
import Header from 'components/Header';
import Footer from 'components/Footer';
import { ORDER, MASTER_SETTING } from 'constants/endpoints';
import { getRole } from 'helpers/helpers';
import 'assets/scss/page/DetailTomorrowClient/style.scss';
import ClientDetailData from 'components/ClientDetailData';
import ClientDetailDataList from 'components/ClientDetailDataList';
import ClientConfirmStatus from 'components/ClientConfirmStatus';
import ClientSupportStatus from 'components/ClientSupportStatus';
import { pushToast } from 'components/Toast';
import moment from 'moment';
import { SCREEN_NAME, ROLE, enumPluginSetting, EXTRA_DATA_STATUS, DATE_JP_FORMAT } from 'helpers/constants';
import { useSelector } from 'react-redux';

const mockData = {
  files: '',
};

const DetailTomorrowClient = () => {
  const [role, setRole] = React.useState(null);
  const [idRole, setIdRole] = React.useState(null);
  const [selectedFile, setSelectedFile] = useState([]);
  const [selectedName, setSelectedName] = useState('');
  const [removeData, setRemoveData] = useState(mockData);
  const [deadlineDate, setDeadlineDate] = useState('');
  const [completionDate, setCompletionDate] = useState('');
  const [requiredDeadline, setRequiredDeadline] = useState('');
  const [body, setBody] = useState({});
  const [orderDataDetail, setOrderDataDetail] = useState();
  const colorScreen = useSelector((store) => store.utils.colorMe);

  let orderId = process.browser && new URL(window.location.href).searchParams.get('orderId');
  let organizationId = process.browser && new URL(window.location.href).searchParams.get('organizationId');

  const [datas, setDatas] = useState('');
  const [listFiles, setlistFiles] = useState('');
  const [disable, setDisable] = useState(false);
  const [disableThreeButton, setDisableThreeButton] = useState(false)
  useEffect(() => {
    const newRole = localStorage.getItem('role');
    setIdRole(newRole);
    if (newRole) {
      setRole(getRole({ idRole: newRole }));
    }
  });
  useEffect(() => {
    const newRole = localStorage.getItem('role');
    if (getRole({ idRole: newRole }) === ROLE.CLIENT) {
      setDisable(true);
    }
  }, [orderId]);

  const getOrderDetail = async ({ orderId = '' }) => {
    try {
      const data = await ORDER.GET_STATUS_BEFORE_VISIT({ orderId });
      if (data?.data) {
        setlistFiles(data?.data?.receiveAllFiles);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getOrderDetailByOrderId = async ({ orderId = '' }) => {
    try {
      const data = await ORDER.GET_DETAIL_CANCEL_INFO({ orderId, organizationId: organizationId || '' });
      if (data?.data) {
        setDatas(data?.data);
        setOrderDataDetail(data?.data);
        setlistFiles(data?.data?.receiveAllFiles);
      }
      const dataStatus = await MASTER_SETTING.GET_DETAIL_STATUS_BUTTON({ productId: data?.data?.productId, serviceId: data?.data?.serviceId, enumPluginSetting: enumPluginSetting.WORKER_ALLOCATION });
      if (dataStatus?.data) {
        dataStatus?.data?.disableThreeButton && setDisableThreeButton(dataStatus?.data?.disableThreeButton)
        // dataStatus?.data?.extraData?.length && setExtraData(dataStatus?.data?.extraData?.length && dataStatus?.data.extraData[0].state);
      }
    } catch (e) {
      console.log(e);
    }
  };
  // const getDetailOrder = async ({ orderId = '' }) => {
  //   try {
  //     const data = await ORDER.GET_ORDER_DETAIL({ orderId: orderId });
  //     if (data) {
  //       setlistFiles(data?.data?.receiveAllFiles);
  //       // setExtraData(data?.data?.workerAllocation?.extraData?.option);
  //     }
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  // const getOrderDetailss = async ({ orderId = '' }) => {
  //   console.log('123')
  //   try {
  //     const data = await ORDER.GET_DETAIL_CANCEL_INFO({ orderId: orderId, organizationId: organizationId || ''});
  //     if (data) {
  //       setDatas(data?.data);
  //     }
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  useEffect(() => {
    getOrderDetail({ orderId });
    // getOrderDetailss({ orderId: orderId });
    getOrderDetailByOrderId({ orderId });
  }, [orderId]);

  useEffect(() => {
    setRequiredDeadline(orderDataDetail?.statusRequiredDeadline || '');
    setDeadlineDate(orderDataDetail?.admissionApplicationDeadline);
    setCompletionDate(orderDataDetail?.admissionApplicationCompletionDate)
  }, [orderDataDetail, orderId]);

  const uploadCsv = async ({ body = '', order_id = '' }) => {
    try {
      const data = await ORDER.UPLOAD_FILIE_RECEVICE_ALL({ body, order_id, organizationId: organizationId || '' });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else if (data.message.errorMessage) {
          pushToast('error', data?.message?.errorMessage);
        } else {
          pushToast('error', data?.message);
        }
      } else {
        pushToast('success', 'Upload success');
        getOrderDetail({ orderId });
        setSelectedName('');
      }
    } catch (errorMessage) {
      console.log('errorMessage: ', errorMessage);
    }
  };

  const onChangeHandler = async (e) => {
    const tmpFile = e?.target?.files;
    const base64Promises = [];
    Object.keys(tmpFile).map((item, index) => {
      base64Promises.push(toBase64(tmpFile[index]));
    });
    const base64s = await Promise.all(base64Promises);
    const tmpFileResult = Object.keys(tmpFile).map((item, index) => {
      return {
        fileName: tmpFile[index].name,
        file: base64s[index],
      };
    });
    setSelectedFile(tmpFileResult);
    setSelectedName(tmpFileResult.map(item => item.fileName));
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const onClickHandler = () => {
    const body = {
      files: selectedFile,
      screenName: '入館申請期限',
    }
    uploadCsv({ body, order_id: orderId });
  };

  const removeFile = async ({ body, id = '' }) => {
    try {
      const data = await ORDER.REMOVE_FILE_ADMISSION({ body, id });
      if (data && data.code !== 0) {
        if (data && data.message && data.message.extra) {
          pushToast('error', data?.message?.extra);
        } else {
          pushToast('error', data?.message?.errorMessage);
        }
      } else {
        pushToast('success', 'delete success');
        setlistFiles(data?.data?.Attributes?.receiveAllFiles);
        unCheckedAll();
      }
    } catch (errorMessage) {
      console.log('errorMessage: ', errorMessage);
    }
  };

  const handleRemove = () => {
    if (orderId) {
      removeFile({ body: removeData, id: orderId });
    }
  };

  const unCheckedAll = () => {
    document.querySelectorAll('input[type="checkbox"]').forEach((item) => {
      item.checked = false;
    });
  };
  const onChangeDeadline = (date) => {
    if (date) {
      setDeadlineDate(moment(new Date(date)).format(DATE_JP_FORMAT));
      setBody({ ...body, admissionApplicationDeadline: moment(new Date(date)).format(DATE_JP_FORMAT) });
    }
  };
  const onChangeompletionDate = (date) => {
    if (date) {
    setCompletionDate(moment(new Date(date)).format(DATE_JP_FORMAT));
    setBody({ ...body, admissionApplicationCompletionDate: moment(new Date(date)).format(DATE_JP_FORMAT) });
    }
  };

  const handleChangeCheck = (e) => {
    let target = e.target;
    let value = target.value;
    let newData = { ...removeData, files: [...removeData.files, { key: value }] };
    setRemoveData(newData);
  };
  const setStatusRequiredOfDeadline = (e) => {
    const value = e.target?.value;
    setRequiredDeadline(value);
    setBody({ ...body, statusRequiredDeadline: value });
  };

  const getDataBody = (value) => {
    setBody({ ...body, ...value });
  };
  const reloadData = () => {
    getOrderDetailByOrderId({ orderId })
  }
  return (
    <div className="wrapper-detail-cancel-info-custom">
      <div className="main">
        <Header idRole={idRole} color={colorScreen?.code} colorText={colorScreen?.colorText} p='preparation_info' classCustom={'header-wrapper--mgbt-30'} />
        <div className="body-wrapper">
          <ClientDetailData data={datas} p={'tomorrow_info'} link={true} title={'現状情報'} />
          <ClientConfirmStatus disable={disable} role={role} getDataBody={getDataBody} orderDataDetail={orderDataDetail && orderDataDetail} orderId={orderId} />
          <ClientSupportStatus disable={disable} disableThreeButton={disableThreeButton} role={role} getDataBody={getDataBody} orderDataDetail={orderDataDetail} orderId={orderId} color={colorScreen?.code} />
          <div className="detail-cancel-table-wrapper detail-cancel-table-wrapper-three detail-cancel-table-wrapper-four">
            <div className="title-absolute">入館申請期限</div>
            <div className="block-item">
              <div className="block-left mb-1">
                <label>前日確認期限</label>
                <div className="block-date">
                  <DateTimePicker
                    onChangeDate={onChangeDeadline}
                    date={
                      deadlineDate?.length > 0
                        ? new Date(deadlineDate && deadlineDate)
                        : null
                    }
                  />
                </div>
              </div>
            </div>
            <div className="block-item">
              <div className="block-left mb-1">
                <label>前日確認完了日</label>
                <div className="block-date">
                  <DateTimePicker
                    onChangeDate={onChangeompletionDate}
                    date={
                      completionDate?.length > 0
                        ? new Date(completionDate && completionDate)
                        : null
                    }
                  />
                </div>
              </div>
            </div>
            <div className="block-item">
              <div className="block-left mb-1">
                <label className="cursor-pointer title-file title-file-custom" htmlFor="upload-csv">
                  ファイルを指定
                </label>
                <input type="file" name="file" id="upload-csv" multiple className="d-none" onChange={onChangeHandler} />
                <input
                  className="dummy_file input-file-custom"
                  type="text"
                  placeholder={selectedName}
                  disabled="disabled"
                />
              </div>
              <div className="block-right">
                <button className="button-upload" onClick={() => onClickHandler()}>
                  UPLOAD
                </button>
              </div>
            </div>
            <div className="block-item">
              <div className="block-left d-flex mb-1">
                <label className="text-center">UPLOAD FILE</label>
                <div>
                  {listFiles &&
                    listFiles.map((item, index) => {
                      return (
                        <div className="mb-1" key={index}>
                          <input type="checkbox" value={item.key} onChange={handleChangeCheck} />
                          {item.name}
                        </div>
                      );
                    })}
                </div>
              </div>
              <div className="block-right">
                <button className="button-upload" onClick={() => handleRemove()}>
                  DELETE
                </button>
                {
                  //role parner
                }
                {/* extraData?.unused */}
                {(orderDataDetail?.extra === EXTRA_DATA_STATUS.UNUSED && (role === ROLE.PARTNER || role === ROLE.PARTNERGROUP || role === ROLE.CENTER || role === ROLE.CLIENT)) && <div className="mt-2">
                  <span className="title">承認依頼</span>
                  <span>
                    <label>要</label>
                    <input
                      type="radio"
                      value="on"
                      checked={requiredDeadline === 'on'}
                      onChange={setStatusRequiredOfDeadline}
                      name="statusRequiredOfDeadline"
                      disabled={disable}
                    />
                  </span>
                  <label>不要</label>
                  <span>
                    <input
                      type="radio"
                      value="off"
                      checked={requiredDeadline === 'off'}
                      onChange={setStatusRequiredOfDeadline}
                      name="statusRequiredOfDeadline"
                      disabled={disable}
                    />
                  </span>
                </div>}
                {/* extraData?.option */}
                {(orderDataDetail?.extra === EXTRA_DATA_STATUS.OPTION && (role === ROLE.PARTNER || role === ROLE.PARTNERGROUP || role === ROLE.CENTER || role === ROLE.CLIENT)) && <div className="mt-2">
                  <span className="title">承認依頼</span>
                  <span>
                    <label>要</label>
                    <input
                      type="radio"
                      value="on"
                      checked={requiredDeadline === 'on'}
                      onChange={setStatusRequiredOfDeadline}
                      name="statusRequiredOfDeadline"
                      disabled={disable}
                    />
                  </span>
                  <label>不要</label>
                  <span>
                    <input
                      type="radio"
                      value="off"
                      checked={requiredDeadline === 'off'}
                      onChange={setStatusRequiredOfDeadline}
                      name="statusRequiredOfDeadline"
                      disabled={disable}
                    />
                  </span>
                  <label>確認中</label>
                  <span>
                    <input
                      type="radio"
                      value="option"
                      checked={requiredDeadline === 'option'}
                      onChange={setStatusRequiredOfDeadline}
                      name="statusRequiredOfDeadline"
                      disabled={disable}
                    />
                  </span>
                  {/* extraData?.option */}
                  {(orderDataDetail?.extra === EXTRA_DATA_STATUS.OPTION && (role === ROLE.CENTER || role === ROLE.PARTNERGROUP)) && (
                    <button className="btn-item">
                      確定
                    </button>
                  )}
                </div>}
              </div>
            </div>
          </div>
          <ClientDetailDataList
            disable={disable}
            role={role}
            orderId={orderId}
            datePick={true}
            title_remark="承認依頼"
            body={body}
            getDataBody={getDataBody}
            orderDataDetail={orderDataDetail}
            screenName={SCREEN_NAME.TOMORROW_INFO}
            reloadData={reloadData}
          />
        </div>
        <Footer color={colorScreen?.code} colorText={colorScreen?.colorText} />
      </div>
    </div>
  );
};
export default compose(translate('translations'))(DetailTomorrowClient);
