import React, { useEffect, useState } from 'react';
import { Collapse } from 'reactstrap';
import { MASTER_SETTING } from '../../../../constants/endpoints';
import { withRouter } from 'react-router-dom';
import Loading from 'components/Loading/Loading';
import _ from 'lodash';

const CollapseViewTree = ({ colors, history }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [listViewTree, setListViewTree] = useState([]);
  const [loading, setLoading] = useState(false);
  const [idClientShow, setIdClientShow] = useState();

  const OpenClient = (id) => {
    if (idClientShow.includes(id)) {
      setIdClientShow(idClientShow?.filter((ele) => ele !== id));
    } else {
      setIdClientShow([...idClientShow, id]);
    }
  };

  const toggle = () => setIsOpen(!isOpen);

  const showDetailVersionService = ({ productId, serviceId, serviceName, productName }) => {
    history.push(`/configuration-worker/version-service?productId=${productId}&serviceId=${serviceId}&serviceName=${serviceName}&productName=${productName}`);
  };

  useEffect(() => {
    getListViewTree();
  }, []);

  const getListViewTree = async () => {
    setLoading(true);
    try {
      const data = await MASTER_SETTING.GET_LIST_VIEW_TREE()
      if (data) {
        setListViewTree(data?.data?.clients);
        let dataClientId = data?.data?.clients?.map((ele) => {
          return ele?.clientId;
        });
        setIdClientShow(_.compact(dataClientId));
      }
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const itemTrees = (array, productId, productName = '') => {
    return (
      <ul>
        {array &&
          array.map((item, index) => {
            return (
              <li key={index}>
                <p>
                  {item?.name}
                  <span
                    className={item && item.versionStatus ? 'btn-yellow' : ''}
                    onClick={() => showDetailVersionService({ productId, serviceId: item.serviceId, serviceName: item?.name, productName })}
                  >
                    作業手順設定
                  </span>
                </p>
              </li>
            );
          })}
      </ul>
    );
  };

  return (
    <div className="setting-master-menu-wrapper">
      <Loading loading={loading} />
      <div className="wrapper-btn-colappse">
        <div
          onClick={toggle}
          className="mb-1 searchbar d-flex justify-content-between align-items-center "
          style={{ background: `${colors}` }}
        >
          <div className="ml-2">工事名一覧</div>
          <div className="mr-2" style={{ color: `black` }}>
            {isOpen ? <i className="icon-up-open icon-searchbar" /> : <i className="icon-down-open icon-searchbar" />}
          </div>
        </div>
      </div>
      <div>
        <Collapse isOpen={isOpen}>
          <div className="content-view">
            {listViewTree &&
              listViewTree.map((client, i) => {
                return (
                  <div className="form-tree form-tree--left" key={i}>
                    <div className="d-flex flex-direction-row form-tree--top">
                      <p className="title-project">{client?.abbreviationName}</p>
                    </div>
                    <div className="form-tree--bottom">
                      <div className="form-tree--show">
                        <div className="form-tree--on cursor-pointer" onClick={() => OpenClient(client?.clientId)}>
                          {idClientShow?.includes(client?.clientId) ? '-' : '+'}
                        </div>
                        {idClientShow?.includes(client?.clientId) && (
                          <ul>
                            {client &&
                              client?.projects.map((item, index) => {
                                return (
                                  <>
                                    {item?.product?.map((ele) => {
                                      return (
                                        <li key={index}>
                                          <p>
                                            {ele?.name && item.projectName ? `${ele?.name}-${item.projectName}` : ele?.name || item.projectName}
                                          </p>
                                          {ele?.service?.length > 0 ? itemTrees(ele?.service, ele.productId, ele?.name && item.projectName ? `${ele?.name}-${item.projectName}` : ele?.name || item.projectName) : ''}
                                        </li>
                                      );
                                    })}
                                  </>
                                );
                              })}
                            {client &&
                              client?.nonProjects?.map((ele, elex) => {
                                return (
                                  <li key={elex}>
                                    <p>{ele?.name}</p>
                                    {ele?.service?.length > 0 ? itemTrees(ele?.service, ele.productId, ele?.name) : ''}
                                  </li>
                                );
                              })}
                          </ul>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </Collapse>
      </div>
    </div>
  );
};

export default withRouter(CollapseViewTree);
