import React, { useState } from 'react';
import HeaderMaintenance from '../../../components/HeaderMaintenance';
import BreadcrumbMaintenance from '../../../components/BreadMaintenance';
import './style.scss';
import TableOutputList from './TableOutputList';
import FooterMaintenance from '../../../components/FooterMaintenance';
import HeaderMasterSetting from '../../../components/HeaderMasterSetting';

const OutputList = () => {
  const breadcrumb = [
    { name: 'CENTER MASTER', link: '/center_master_all' },
    { name: 'CSV出力項目画面選択', link: 'csv_output_conf_list' },
  ];
  const [fontSize, setFontSizeAll] = useState('small');
  return (
    <div className="wrapper-output-list">
      {/* <HeaderMaintenance setFontSizeAll={setFontSizeAll} /> */}
      <HeaderMasterSetting/>
      <div className="body pb-4">
        <div className="wrapper-main">
          {/* <BreadcrumbMaintenance breadcrumb={breadcrumb} fontSize={fontSize} /> */}
          <div className="wrapper-result">
            <div className="result-title">
              <h3 className={`font-weight-bolder ${fontSize}`}>CSV出力項目画面選択</h3>
            </div>
            <TableOutputList fontSize={fontSize} />
          </div>
          <div className="d-flex justify-content-center align-items-center group-button ">
            <button className={`btn button-submit mr-2 px-4 ${fontSize}`}>検索</button>
          </div>
        </div>
      </div>
      <FooterMaintenance />
    </div>
  );
};

export default OutputList;
