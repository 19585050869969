import React, { useState, useEffect } from 'react';
import './style.scss'
import { WORKER_ENDPOINT } from '../../../../constants/endpoints';
import { pushToast } from '../../../../components/Toast';

const mockData = {
    resultSelection :'',
    leavingWorker:'',
    resultCancel:'',
}

const LeavePage = ({ workerId }) => {
    const [dataWorker, setDataWorker] = useState(mockData)
    const [data, setData] = useState('')

    const getWorkerDetail = async ({ orderId = '' }) => {
        try {
            const data = await WORKER_ENDPOINT.GET_WORKER_DETAIL({ workerId: workerId });
            if (data) {
                setData(data?.data)
            }
        } catch (e) {
            console.log(e);
        }
    };
    useEffect(() => {
        if (workerId) {
            getWorkerDetail({
                workerId: workerId
            });
        }

    }, [workerId]);

    const handleChangeText = (e) => {
        let name = e.target.name;
        let newData = { ...dataWorker };
        newData = { ...newData, [name]: e.target.value };
        setDataWorker(newData);
    };

    const HandleHour1 = (e) => {
        let d = new Date();
        let hour = d.getHours();
        let minutes = (d.getMinutes() < 10 ? '0' : '') + d.getMinutes();
        // var seconds = d.getSeconds();
        let newData = { ...dataWorker }
        newData = { ...newData, leavingWorker: hour + ':' + minutes }
        setDataWorker(newData);
    }

    const updateStepFour = async ({ body = {}, workerId = '' }) => {
        try {
            const data = await WORKER_ENDPOINT.UPDATE_STEP_FOUR({ body: body, workerId: workerId });
            if (data && data.code !== 0) {
                if (data && data.message && data.message.extra) {
                    pushToast('error', data?.message?.extra);
                } else {
                    pushToast('error', data?.message?.errorMessage);
                }
            } else {
                pushToast('success', 'Update order success');
            }
        } catch (e) {
            console.log(e);
        }
    };

    const onUpdateStepFour = () => {
        updateStepFour({ body: dataWorker, workerId: workerId });
    };

    return (
        <>
            <div className="detail-leave-page">
                <div align="center">
                    <p style={{ fontSize: 50 }}>結果・退館</p>
                </div>
                <table className="basictab" cellSpacing="0" cellPadding="0" width="100%">
                    <tbody><tr>
                        <th colSpan="3">結果入力</th>
                    </tr>
                        <tr>
                            <td style={{ textAlign: "center" }}>
                                <table id="noborder_table">
                                    <colgroup><col width="30%" />
                                        <col width="20%" />
                                        <col width="20%" />
                                        <col width="30%" />
                                    </colgroup><tbody><tr>
                                        <td style={{ textAlign: "center" }}>結果選択
                                        </td>
                                        <td colSpan="">
                                            <div className="ui-select">
                                                <select name="resultSelection" onChange={handleChangeText} >
                                                    <option value="選択してください。">選択してください。</option>
                                                    <option value="宅内工事完了" selected="">宅内工事完了</option>
                                                    <option value="宅内工事未完了">宅内工事未完了</option>
                                                </select><div style={{ display: "none" }} id="res1-listbox-placeholder"></div></div>
                                        </td>
                                        <td style={{ textAlign: "center" }} colSpan="">
                                        </td>
                                    </tr>
                                        <tr>
                                            <td style={{ textAlign: "center" }}>結果判定</td>
                                            <td style={{ textAlign: "center" }}>
                                                <input type="hidden" name="site_result" id="site_result" value="1" />
                                                <div className="ui-input-text ui-body-inherit ui-corner-all ui-shadow-inset" style={{ width: "50%" }}>
                                                    <input type="text"
                                                        name="resultCancel"
                                                        value={dataWorker?.resultCancel}
                                                        onChange={handleChangeText} />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ textAlign: "center" }}>結果コメント</td>
                                            <td colSpan="3">
                                                <textarea name="" id="site_result_etc" cols="40" colbasics="10"
                                                    className="ui-input-text ui-shadow-inset ui-body-inherit ui-corner-all ui-textinput-autogrow"
                                                    style={{ height: 52, width: "50%" }}>

                                                </textarea>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                            </td>
                                            <td>
                                                <input type="button"
                                                    id="result_save_btn"
                                                    className="result_save_btn"
                                                    data-role="none"
                                                    value="結果保存"
                                                    onClick={() => onUpdateStepFour()} />
                                            </td>
                                        </tr>
                                    </tbody></table>
                            </td>
                        </tr>

                    </tbody></table>
            </div>
            <div className="detail-enter-page">
                <table className="basictab" width="100%">
                    <colgroup><col width="5%" />
                        <col width="15%" />
                        <col width="45%" />
                        <col width="25%" />
                    </colgroup><tbody><tr>
                        <th colSpan="4" className="font16">入館</th>
                    </tr>
                        <tr className="pcmenu">
                            <th>No</th>
                            <th>区分</th>
                            <th>内容1</th>
                            <th>操作</th>
                        </tr>
                        <tr>
                            <td className="rightalign">1</td>
                            <td>入館</td>
                            <td>
                                <div className="anken003_wrap">
                                    <div className="text-center">
                                        <table id="responsive02" className="noborder_tab" style={{ fontSize: 20 }}>
                                            <tbody><tr>
                                                <td>
                                                    <div className="ui-btn ui-input-btn ui-corner-all ui-shadow ui-state-disabled">入館
                                                <input type="button" onClick={() => HandleHour1()} name="btn_enter" disabled="" value="入館" />
                                                    </div>
                                                </td>
                                                <td style={{ verticalAlign: "middle" }}><p id="responsive01" style={{ fontSize: 20 }}>{dataWorker?.leavingWorker}</p>
                                                    <input type="hidden" name="enter_time_old" id="responsive03" value="2020-08-27 16:44:40" />
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </td>
                            <td></td>
                        </tr>
                    </tbody></table>
                <div align="center">
                    <input type="button" id="report_anken_b0001" className="submitbutton" data-role="none" value="保存"
                        onClick={() => onUpdateStepFour()}
                    />
                </div>
            </div>
        </>
    )
};

export default LeavePage;
