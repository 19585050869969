import React from 'react';
import './style.scss';

const data = {
  header: ['メニュー', 'ボタン表示', 'CSV出力設定'],
  body: [
    {
      メニュー: '全情報',
      ボタン表示: '利用停止中',
      CSV出力設定: '並び順'
    },
    {
      メニュー: '受注情報',
      ボタン表示: '利用中',
      CSV出力設定: '並び順'
    }
  ]
};

const renderItem = (item, index, fontSize) => {
  return (
    <tr key={index}>
      <td className="no-wrapper">
        <div className={`d-flex justify-content-center number align-items-center font-weight-bolder ${fontSize}`}>
          {index + 1}
        </div>
      </td>
      {data.header.map((element, key) => {
        return element === 'ボタン表示' ? (
          <td key={key}>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                value="option1"
                id={`inlineRadio1${index}`}
                name={`group${index}`}
              />
              <label className="form-check-label" htmlFor={`inlineRadio1${index}`}>
                表示
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                value="option2"
                name={`group${index}`}
                id={`inlineRadio2${index}`}
              />
              <label className="form-check-label" htmlFor={`inlineRadio2${index}`}>
                非表示
              </label>
            </div>
          </td>
        ) : element === 'CSV出力設定' ? (
          <td align="center">
            <button
              className="btn order-button mx-2"
              onClick={() => (window.location.href = '/csv_output_conf')}
            >
              先頭
            </button>
          </td>
        ) : (
          <td key={key}>{item[element]}</td>
        );
      })}
    </tr>
  );
};

const TableOutputList = ({ fontSize }) => {
  return (
    <div className="table-list-output table-responsive">
      <table className={`table table-striped mb-0 ${fontSize}`}>
        <thead>
          <tr>
            <th scope="col">No</th>
            {data.header.map((item, index) => (
              <th scope="col" key={index}>
                {item}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>{data.body.map((listData, index) => renderItem(listData, index, fontSize))}</tbody>
      </table>
    </div>
  );
};

export default TableOutputList;
